import { helper } from '@ember/component/helper';

export function capitalize(values) {
  if (!Array.isArray(values)) {
    throw Error('Invalid type assumption');
  }

  if (values.length === 0) {
    return values;
  }
  let value = values[0];

  if (value.string) {
    value = value.string;
  }
  // Handle htmlSafe strings.
  // NOTE: this means we will pass any html tags through - this is unsafe for
  // user generated content.
  if (value.toString) {
    value = value.toString();
  }

  if (value.length === 0) {
    return value;
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
}

export default helper(capitalize);
