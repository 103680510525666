import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
@classic
@tagName('div')
@classNames('recent-bookings')
export default class AppRecentBookings extends Component {
  layoutName = 'components/app-recent-bookings';
  listing = null;
  bookings = [];

  @computed('listing.stats.currency')
  get currency() {
    return this.get('listing.stats.currency');
  }
}
