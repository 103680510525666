import { helper } from '@ember/component/helper';

export default helper(function (params, namedArgs) {
  let value = params[0];
  let out = null;

  if (value > namedArgs.ideal + 10) {
    out = 'common.tooHigh';
  } else if (value < namedArgs.ideal - 10) {
    out = 'common.tooLow';
  } else {
    out = 'common.justRight';
  }
  return out;
});
