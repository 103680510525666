import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { action, computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

const BULK_UPDATE_CONFIGURATION = {
  free_form_default: {
    helpText:
      'Free Form Default Setting Updated, based on header names<br>' +
      'Format of the CSV contains any of: ' +
      '<pre>  <b>User or Account Info</b>                     <b>Listing Identifier</b>' +
      '                     <b>Title\n</b>' +
      '[user_id, managed_account_id] [listing_id, channel_listing_id, channel_id_link]  [title]\n ' +
      '                       <b>Desired Updates</b>\n' +
      '[base_price, min_price, max_price, min_stay, max_stay, orphan_min_stay, orphan_min_stay_buffer, extra_guest_fee, extra_guest_threshold, ' +
      '<br>enabled, notes, pm_notes, monthly_min_price, dynamic_gap_price_adjustment, min_booking_value, on_program, is_orphan_changeover_days_enabled]</pre>',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
  free_form_seasonal: {
    helpText:
      'Free Form Seasonal Setting Updated, based on header names<br>' +
      'Format of the CSV contains any of: ' +
      '<pre>   <b>User or Account Info</b>                     <b>Listing Identifier</b>                     <b>Title and Start/End Dates</b>                <b>Desired Updates</b>\n' +
      '[user_id, managed_account_id] [listing_id, channel_listing_id, channel_id_link], [title, start_date, end_date], [base_price, min_price, max_price, min_stay, max_stay]</pre>',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
  per_listing_default_base_price: {
    helpText:
      'Per Listing Default Base Price Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, base_price</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_default_min_price: {
    helpText:
      'Per Listing Default Min Price Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, min_price</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_default_max_price: {
    helpText:
      'Per Listing Default Max Price Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, max_price</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_default_min_stay: {
    helpText:
      'Per Listing Default Min Stay Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, min_stay</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_annual_min_price_dow: {
    helpText:
      'Per Listing Annual Min Price Day of Week Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, monday, tuesday, wednesday, thursday, friday, saturday, sunday</code>' +
      '<br><code>- to remove an existing value for a day leave it blank or put "none" or "null"</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_annual_min_stay_dow: {
    helpText:
      'Per Listing Annual Min Stay Day of Week Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, monday, tuesday, wednesday, thursday, friday, saturday, sunday</code>' +
      '<br><code>- to remove an existing value for a day leave it blank or put "none" or "null"</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_reservation_tax_status: {
    helpText:
      'Per Listing Reservation Tax Status Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>id, reservation_tax_status</code>' +
      '<br><code>reservation_tax_status: "taxable", "tax_exempt", "mixed"</code>',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_min_price: {
    helpText:
      'Per Listing Seasonal Min Price Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, min_price</code>' +
      '<br><code>- use "null" or "none" to remove an existing value</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_min_price_dow: {
    helpText:
      'Per Listing Seasonal Day of Week Min Price Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, monday, tuesday, wednesday, thursday, friday, saturday, sunday</code>' +
      '<br><code>- to remove an existing value from a day leave its cell empty</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_monthly_min_price: {
    helpText:
      'Per Listing Seasonal Monthly Min Price Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, monthly_min_price</code>' +
      '<br><code>- use "null" or "none" to remove an existing value</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_monthly_max_price: {
    helpText:
      'Per Listing Seasonal Monthly Max Price Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, monthly_max_price</code>' +
      '<br><code>- use "null" or "none" to remove an existing value</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_max_price: {
    helpText:
      'Per Listing Seasonal Max Price Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, max_price</code>' +
      '<br><code>- use "null" or "none" to remove an existing value</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_price: {
    helpText:
      'Per Listing Seasonal Price Override Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, price</code>' +
      '<br><code>- use "null" or "none" to remove an existing value</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_min_stay: {
    helpText:
      'Per Listing Seasonal Min Stay Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, min_stay, locked</code>' +
      '<br><code>- use "null" or "none" to remove an existing value</code>' +
      '<br><code>-date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_min_stay_dow: {
    helpText:
      'Per Listing Seasonal Day of Week Min Stays Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, monday, tuesday, wednesday, thursday, friday, saturday, sunday</code>' +
      '<br><code>- to remove an existing value from a day leave its cell empty</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_gap_fills: {
    helpText:
      'Per Listing Seasonal Gap Fills Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, enabled, gap_min_stay, flexibility_window</code>' +
      '<br><code>- to remove an existing value leave columns "enabled, gap_min_stay, flexibility_window" blank</code>' +
      '<br><code>- use "true" to enable gap fills, use "false" to disable gap fills</code>' +
      '<br><code>-date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_changeover_days: {
    helpText:
      'Per Listing Seasonal Changeover Days Updater' +
      '<br>(This will update both the checkin and checkout days with the same value.)' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, changeover_days</code>' +
      '<br><code>- changeover_days format should be a day or "any" (multiple days are not allowed)</code>' +
      '<br><code>- use "null" or "none" to remove an existing value</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_checkin_days: {
    helpText:
      'Per Listing Seasonal Checkin Days Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, checkin_days</code>' +
      '<br><code>- checkin_days format should be a list of days separated by a comma or "any"</code>' +
      '<br><code>- use "null" or "none" to remove an existing value</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_checkout_days: {
    helpText:
      'Per Listing Seasonal Checkout Days Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, checkout_days</code>' +
      '<br><code>- checkout_days format should be a list of days separated by a comma or "any"</code>' +
      '<br><code>- use "null" or "none" to remove an existing value</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_seasonal_min_booking_value: {
    helpText:
      'Per Listing Seasonal Min Booking Value Updater' +
      '<br><br>Format of the CSV: ' +
      '<code>channel_id_link, title, start_date, end_date, min_booking_value</code>' +
      '<br><code>- use "null" or "none" to remove an existing value</code>' +
      '<br><code>- date format should be YYYY-MM-DD</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  copy_last_minute_discounts_from_listing: {
    helpText:
      'Copy Last Minute Discounts from listing' +
      '<br>- it copies the Last Minute Discounts (LMD) from one listing to another' +
      '<br>- both listings must belong to the same User' +
      '<br>- it will remove any current values on the listing you want to copy LMD to' +
      '<br><br>Format of the CSV: ' +
      '<code>source_listing_id, destination_listing_id</code>',
    requireManagedAccount: false,
    requireUser: true,
    showTemplate: true,
  },
  per_listing_last_minute_discounts_db_format: {
    helpText:
      'Per Listing Last Minute Discounts (DB format)' +
      '<br>- To update or remove a single rule, use in-app Exports to download the current settings, ' +
      'update or remove the rules you need to, and import back in using this format' +
      '<br><br>Format of the CSV: ' +
      '<br><code>channel_id, title, discount_percentage, discount_direction, discount_days</code>' +
      '<br><code>- discount_percentage must be a decimal, e.g: 0.25 (a positive number is a discount, a negative number is an increase)</code>' +
      '<br><code>- discount_direction must be either "in" (=after) or "out" (=for the next)</code>' +
      '<br><code>- discount_days must be a positive integer</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_last_minute_discounts_screen_format: {
    helpText:
      'Per Listing Last Minute Discounts (screen format)' +
      '<br>- To update or remove a single rule, use in-app Exports to download the current settings, ' +
      'update or remove the rules you need to, and import back in using this format' +
      '<br><br>Format of the CSV: ' +
      '<br><code>channel_id, title, discount_type, discount_percentage, discount_direction, discount_days</code>' +
      '<br><code>- discount_type must be either "increase" or "decrease"</code>' +
      '<br><code>- discount_percentage must be a positive decimal, e.g: 0.25 for 25%</code>' +
      '<br><code>- discount_direction must be either "for the next" or "after"</code>' +
      '<br><code>- discount_days must be a positive integer</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  per_listing_time_based_min_stays_db_format: {
    helpText:
      'Per Listing Time Based Min Stays (DB format)' +
      '<br>- To update or remove a single rule, use in-app Exports to download the current settings, ' +
      'update or remove the rules you need to, and import back in using this format' +
      '<br><br>Format of the CSV: ' +
      '<br><code>channel_id, title, days_away, direction, min_days</code>' +
      '<br><code>- days_away must be a integer, e.g: 14</code>' +
      '<br><code>- direction must be either "in" (=within) or "out" (=after)</code>' +
      '<br><code>- min_days (=min_stays) must be a positive integer</code>',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  copy_last_minute_min_stays_from_listing: {
    helpText:
      'Copy Last Minute Min Stays from listing' +
      '<br>- it copies the Last Minute Min Stays (LMMS) from one listing to another' +
      '<br>- both listings must belong to the same User' +
      '<br>- it will remove any current values on the listing you want to copy LMMS to' +
      '<br><br>Format of the CSV: ' +
      '<code>source_listing_id, destination_listing_id</code>' +
      '<p>Need help mapping listings? <a href="https://app.mode.com/beyondpricing/reports/4564d6494a44" target="_blank" rel="noopener noreferrer">Check out the PMS migration listings mapper</a></p>',
    requireManagedAccount: false,
    requireUser: true,
    showTemplate: false,
  },
  set_reservation_non_billable: {
    helpText:
      'Mark Reservations as Non-Billable' +
      '<br><br>Format of the CSV: ' +
      '<code>reservation_id, reason</code>' +
      '<br>Valid values for reason include: ' +
      '<code>owner_booking, long_term_stay, repeat_guest, other, direct_booking, pms_migration</code>',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
  mef_imports: {
    helpText:
      'Import MEFs' +
      '<br>Format of the CSV: ' +
      '<code>cluster_id, event_id, start_date, end_date, min_magnitude, max_magnitude, is_decay_enabled, disabled, exclude_users,dow_disabled,source,is_tentative</code>' +
      '<br><code>exclude_users: semi-colon separated list of user ids</code>',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
  mefs_occurrences_imports: {
    helpText:
      'Edit Events Occurrences' +
      '<br>Format of the CSV:' +
      '<code>id, cluster_id,  event_id, start_date, end_date, min_magnitude, max_magnitude, is_decay_enabled, disabled, delete, exclude_users,dow_disabled,source,is_tentative</code></code>' +
      '<br><code>exclude_users: semi-colon separated list of user ids</code>',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
  cluster_imports: {
    helpText:
      'Import Clusters' +
      '<br>Format of the CSV: ' +
      '<code>id, name, latitude, longitude, neighborhoods, market_id, dow_multiplier, event_multiplier, seasonality_multiplier, parent_cluster_id, polygon, event_distance_multiplier, seasonality_override, comments</code>',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
  hurricane_factor_imports: {
    helpText:
      'Import/Delete Hurricane Factors' +
      '<br>Format of the CSV:' +
      '<code>region_type, region_id, start_date, end_date, percentage, display_text, hurrican_factor_id (optional - needed when deleting), delete (optional - TRUE - needed when deleting)</code>',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
  copy_any_settings_from_one_listing_to_another: {
    helpText:
      'Copy any settings from one listing to another within the same user' +
      '<br>Format of the CSV: ' +
      '<code>source_channel_listing_id, destination_channel_listing_id</code>' +
      '<p>Need help mapping listings?<a href="https://app.mode.com/beyondpricing/reports/4564d6494a44" target="_blank" rel="noopener noreferrer">Check out the PMS migration listings mapper</a></p>',
    requireManagedAccount: false,
    requireUser: true,
    requireRegularFields: true,
    requireHistoricalFields: true,
    showTemplate: false,
  },
  loft_imports: {
    helpText:
      'Import/Edit/Delete LOFT' +
      '<br>Format of the CSV: ' +
      '<code>listing_id, start_date, end_date, occupancy_direction, occupancy_threshold, percentage, action_direction, action_date, display_text, loft_id, delete</code>',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
  create_filter_groups: {
    helpText:
      'Create filter groups' +
      '<br>Format of the CSV: user_id, listing_id, category' +
      '<br>Creates filter group and adds listings to the created filter' +
      '<br>If skip invalid listings checked, if listing does not exist it does not fail' +
      '<br>listing_id_column and category column should match with csv headers',
    requireManagedAccount: false,
    requireUser: true,
    showTemplate: true,
  },
  update_revenue_goals: {
    helpText:
      'Create/Update Revenue Goals' +
      '<br>Format of the CSV: user_id, listing_id, year, target, notes' +
      '<br>Please check <a href="https://app.mode.com/beyondpricing/reports/e4dd158162a6" target="_blank">this mode report</a> for a user’s current revenue goals, and to get the csv format for upload.' +
      '<br>Creates or updates (if exists) revenue goals ',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
  per_listing_bed_and_bath_count: {
    helpText:
      'Per Listing Bedroom and Bathroom values' +
      '<br>Format of the CSV: channel_id_link, title, bedrooms, bathrooms' +
      '<br>Updates the number of bedrooms and bathrooms for a listing ',
    requireManagedAccount: true,
    requireUser: false,
    showTemplate: true,
  },
  add_god_mode_factors: {
    helpText:
      'Add God Mode Factors' +
      '<br>Format of the CSV: start_date,end_date,percentage,display_text,notes,markets,markets_include,clusters,clusters_include,bedroom_sizes,bedroom_sizes_include,dow,dow_include,users,users_include,listings,listings_include' +
      '<br>Creates a new God Mode Factor ',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
  update_dynamic_tbas: {
    helpText:
      'Enable/Disable Dynamic TBAs' +
      '<br>Format of the CSV: user_id,listing_id,enable' +
      '<br>Enables or disables the dynamic TBAs for a listing' +
      '<br>Please use <a href="https://app.mode.com/beyondpricing/reports/70f0a8427301" target="_blank">this mode report</a> to download listings & the correct template for a user.',
    requireManagedAccount: false,
    requireUser: false,
    showTemplate: false,
  },
};

@classic
export default class BulkUpdateController extends Controller {
  @tracked selectedRegularListingFields = [];
  @tracked selectedHistoricalListingFields = [];
  @tracked progressData = '';
  @tracked importedData = '';
  @tracked ignoredData = '';
  @tracked isAsync = false;
  @tracked errorData = A([]);
  @tracked jobId = '';
  updateType = 'none';
  listingsType = 'all';
  managedAccount = null;
  managedAccountDetails = 'No Managed Account Checked';
  user = null;
  userDetails = 'No User Checked';
  uploadResponse = null;
  fields = null;
  regularFieldsDetails = 'No Regular Fields Checked';
  historicalFieldsDetails = 'No Historical Fields Checked';
  isEnableListings = false;
  isListingDatesDeletionAccepted = false;
  isAvailabilityEnabledChecked = false;

  // create filter groups specific data
  listingIdColumn = 'listing_id';
  categoryColumn = 'category';
  skipInvalidListings = false;

  // Action when the update type changes
  // eslint-disable-next-line ember/no-observers
  @observes('updateType')
  watchUpdateType() {
    // Reset the results to avoid ambiguity
    this.set('uploadResponse', null);

    // Reset if not required anymore
    if (!this.requireUser) {
      this.set('user', null);
      this.set('userDetails', 'No User Checked');
    }
    // Reset if not required anymore
    if (!this.requireManagedAccount) {
      this.set('managedAccount', null);
      this.set('managedAccountDetails', 'No Managed Account Checked');
    }

    // Reset if not required anymore
    if (!this.requireRegularFields) {
      this.set('fields', null);
      this.set('regularFieldsDetails', 'No Regular Fields Checked');
    }
    // Reset if not required anymore
    if (!this.requireHistoricalFields) {
      this.set('fields', null);
      this.set('historicalFieldsDetails', 'No Historical Fields Checked');
    }
  }

  @computed('updateType')
  get helpText() {
    let updateType = this.updateType;

    if (updateType === 'none') {
      return 'None';
    }

    if (updateType in BULK_UPDATE_CONFIGURATION) {
      return htmlSafe(BULK_UPDATE_CONFIGURATION[updateType]['helpText']);
    }

    return 'Error';
  }

  @computed('updateType')
  get requireUser() {
    let updateType = this.updateType;

    if (updateType === 'none') {
      return false;
    }

    if (updateType in BULK_UPDATE_CONFIGURATION) {
      return BULK_UPDATE_CONFIGURATION[updateType]['requireUser'];
    }

    return false;
  }

  @computed('updateType')
  get requireRegularFields() {
    let updateType = this.updateType;

    if (updateType === 'none') {
      return false;
    }

    if (updateType in BULK_UPDATE_CONFIGURATION) {
      return BULK_UPDATE_CONFIGURATION[updateType]['requireRegularFields'];
    }

    return false;
  }

  @computed('updateType')
  get showTemplate() {
    let updateType = this.updateType;
    if (updateType === 'none') {
      return false;
    }

    if (updateType in BULK_UPDATE_CONFIGURATION) {
      return BULK_UPDATE_CONFIGURATION[updateType]['showTemplate'];
    }

    return false;
  }

  @computed('updateType')
  get requireHistoricalFields() {
    let updateType = this.updateType;

    if (updateType === 'none') {
      return false;
    }

    if (updateType in BULK_UPDATE_CONFIGURATION) {
      return BULK_UPDATE_CONFIGURATION[updateType]['requireHistoricalFields'];
    }

    return false;
  }

  @computed('updateType')
  get requireManagedAccount() {
    let updateType = this.updateType;

    if (updateType === 'none') {
      return false;
    }

    if (updateType in BULK_UPDATE_CONFIGURATION) {
      return BULK_UPDATE_CONFIGURATION[updateType]['requireManagedAccount'];
    }

    return false;
  }

  get allFields() {
    return [
      {
        regular: [
          'base_price',
          'min_price',
          'max_price',
          'min_stay',
          'max_stay',
          'orphan_min_stay',
          'orphan_min_stay_buffer',
          'checkin_days',
          'checkout_days',
          'extra_guest_fee',
          'extra_guest_threshold',
          'pm_notes',
          'first_active_at',
        ],
        historical: [
          'base_price_history_v2',
          'base_price_updated_at',
          'min_price_history_v2',
          'min_price_updated_at',
          'min_stay_history_v2',
          'min_stay_updated_at',
          'max_price_history_v2',
          'max_price_updated_at',
          'max_stay_history',
          'max_stay_updated_at',
          'checkin_days_history_v2',
          'checkin_days_updated_at',
          'checkout_days_history_v2',
          'checkout_days_updated_at',
          'extra_guest_fee_history_v2',
          'extra_guest_fee_updated_at',
          'extra_guest_threshold_history_v2',
          'extra_guest_threshold_updated_at',
        ],
      },
    ];
  }

  @action
  onSelectedRegularFieldsChanged(selectedRegularListingFields) {
    this.selectedRegularListingFields = selectedRegularListingFields ?? [];
    this.set(
      'regularFieldsDetails',
      this.selectedRegularListingFields.map(e => ` ${e}`)
    );
  }

  @action
  onSelectedHistoricalFieldsChanged(selectedHistoricalListingFields) {
    this.selectedHistoricalListingFields = selectedHistoricalListingFields ?? [];
    this.set(
      'historicalFieldsDetails',
      this.selectedHistoricalListingFields.map(e => ` ${e}`)
    );
  }

  @action
  onEnabledListingsChange(isEnableListings) {
    let enabledStatus = this.isEnableListings;
    enabledStatus
      ? this.set('isEnableListings', !isEnableListings)
      : this.set('isEnableListings', isEnableListings);
  }

  @action
  onAvailabilityEnabledChange(isAvailabilityEnabledChecked) {
    let availabilityEnabledStatus = this.isAvailabilityEnabledChecked;
    availabilityEnabledStatus
      ? this.set('isAvailabilityEnabledChecked', !isAvailabilityEnabledChecked)
      : this.set('isAvailabilityEnabledChecked', isAvailabilityEnabledChecked);
  }

  @action
  onIsListingDatesDeletionAccepted(isListingDatesDeletionAccepted) {
    let acceptedStatus = this.isListingDatesDeletionAccepted;
    acceptedStatus
      ? this.set('isListingDatesDeletionAccepted', !isListingDatesDeletionAccepted)
      : this.set('isListingDatesDeletionAccepted', isListingDatesDeletionAccepted);
  }

  @action
  checkManagedAccount() {
    let managedAccountId = this.managedAccount;
    let url = `/api/bulk_updates/check_managed_account/${managedAccountId}`;
    this.ajax._get(url).then(
      data => {
        let text = `ID: ${data.id} Channel: ${data.channel} Channel_ID: ${data.channelId} User: ${data.user}`;
        this.set('managedAccountDetails', text);
      },
      () => {
        alert('Managed Account lookup failed.');
        this.set('managedAccountDetails', 'Managed Account lookup failed.');
      }
    );
  }

  @action
  checkUser() {
    let userId = this.user;
    let url = `/api/bulk_updates/check_user/${userId}`;
    this.ajax._get(url).then(
      data => {
        let text = `ID: ${data.id} Email: ${data.email}`;
        this.set('userDetails', text);
      },
      () => {
        alert('User lookup failed.');
        this.set('userDetails', 'User lookup failed.');
      }
    );
  }

  @action
  getTemplate() {
    let updateType = this.updateType;
    let managedAccountId = this.managedAccount;
    let listingsType = this.listingsType;
    let userId = this.user;
    let url =
      '/api/bulk_updates/download_template?updateType=' +
      updateType +
      '&managedAccount=' +
      managedAccountId +
      '&listingsType=' +
      listingsType +
      '&userId=' +
      userId;

    this.ajax._get(url).then(
      data => {
        let a = document.createElement('a');
        a.href = data;
        a.click();
      },
      () => {
        alert('Failed to download template.');
      }
    );
  }

  @action
  selectAllRegularFields() {
    this.set('selectedRegularListingFields', this.allFields[0].regular);
    this.set(
      'regularFieldsDetails',
      this.selectedRegularListingFields.map(e => ` ${e}`)
    );
  }

  @action
  selectAllHistoricalFields() {
    this.set('selectedHistoricalListingFields', this.allFields[0].historical);
    this.set(
      'historicalFieldsDetails',
      this.selectedHistoricalListingFields.map(e => ` ${e}`)
    );
  }

  getFields() {
    let fields = [].concat(
      this.selectedRegularListingFields,
      this.selectedHistoricalListingFields
    );
    if (this.isEnableListings) {
      fields.push('enabled');
    }
    if (this.isAvailabilityEnabledChecked) {
      fields.push('availability_enabled');
    }
    return fields;
  }

  @action
  async submitForm() {
    let controller = this;
    controller.progressData = '';
    controller.ignoredData = '';
    controller.importedData = '';
    controller.jobId = '';
    let updateType = this.updateType;
    let requireUser = this.requireUser;
    let userId = this.user;
    let requireManagedAccount = this.requireManagedAccount;
    let managedAccountId = this.managedAccount;
    let file = document.getElementsByName('fileUpload')[0].files[0];
    let formData = new FormData();
    let fields = this.getFields();
    let url = '/api/bulk_updates';
    let result;
    controller.isAsync = false;
    controller.errorData = A([]);

    function getMessage(message) {
      return message.substring(message.indexOf(':') + 1);
    }

    async function monitorResults(jobUrl) {
      try {
        await controller.ajax.stream(jobUrl, {}, responseData => {
          if (responseData.message.startsWith('progress:')) {
            controller.progressData = getMessage(responseData.message);
          } else if (responseData.message.startsWith('imported:')) {
            controller.importedData = getMessage(responseData.message);
          } else if (responseData.message.startsWith('ignored:')) {
            controller.ignoredData = getMessage(responseData.message);
          } else if (responseData.message.startsWith('errors:')) {
            for (const message of responseData.message
              .substring(responseData.message.indexOf(':') + 1)
              .split('~')) {
              controller.errorData.pushObject(message);
            }
          }
        });
      } catch (errors) {
        if (Array.isArray(errors)) {
          errors.forEach(e => {
            controller.errorData.pushObject(e.message);
          });
        } else {
          controller.errorData.pushObject(`Something went wrong: ${errors}`);
        }
      }
    }

    if (!this.staffUserId) {
      alert('Submitter User ID is required. Aborting.');
      return;
    }

    if (requireUser && !userId) {
      alert('User ID is required. Aborting.');
      return;
    }
    if (requireManagedAccount && !managedAccountId) {
      alert('ManagedAccount ID is required. Aborting.');
      return;
    }
    if (updateType === 'none') {
      alert('No update selected. Aborting.');
      return;
    }
    if (!file) {
      alert('No file. Aborting.');
      return;
    }
    if (
      !this.isListingDatesDeletionAccepted &&
      updateType === 'copy_any_settings_from_one_listing_to_another'
    ) {
      alert(
        'Must accept Targeted Listing Dates will be deleted and new will be created.'
      );
      return;
    }
    if (
      fields.length === 0 &&
      updateType === 'copy_any_settings_from_one_listing_to_another'
    ) {
      alert('Must select regular and/or historical fields.');
      return;
    }

    formData.append('updateType', updateType);
    if (requireManagedAccount) {
      formData.append('managedAccount', managedAccountId);
    }
    formData.append('user', userId);
    formData.append('staff_user_id', this.staffUserId);
    formData.append('file', file);
    formData.append('fields', fields);

    if (updateType === 'create_filter_groups') {
      formData.append('listing_id_column', this.listingIdColumn);
      formData.append('category_column', this.categoryColumn);
      formData.append('skip_invalid_listings', this.skipInvalidListings);
    }
    try {
      result = await this.ajax._postWithFiles(url, formData);
      if (result.jobId) {
        this.isAsync = true;
        this.jobId = result.jobId;
        let jobUrl = `/v2/job/${result.jobId}/status`;
        monitorResults(jobUrl);
      } else {
        controller.set('uploadResponse', htmlSafe(result.message));
      }
    } catch (errors) {
      if (Array.isArray(errors) && errors.length > 0) {
        controller.set(
          'uploadResponse',
          errors.map(e => htmlSafe(e.message)).join('\n')
        );
      } else {
        controller.set('uploadResponse', htmlSafe(errors));
      }
    }
  }
}
