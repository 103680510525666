import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    actions=(hash\n      onMergeListings=this.onMergeListings\n      onUnmergeListings=this.onUnmergeListings\n      onSavePreferences=this.onSavePreferences\n    )\n  )\n}}", {"contents":"{{yield\n  (hash\n    actions=(hash\n      onMergeListings=this.onMergeListings\n      onUnmergeListings=this.onUnmergeListings\n      onSavePreferences=this.onSavePreferences\n    )\n  )\n}}","moduleName":"appkit/components/relay/merge-container.hbs","parseOptions":{"srcName":"appkit/components/relay/merge-container.hbs"}});
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default class RelayMergeContainerContainer extends Component {
  @service intl;
  @service alert;
  @service ajax;
  @service bpStore;
  @service featureFlag;

  get markupIsSupported() {
    return !this.args.user.isGrandfatheredRelayUser;
  }

  @action
  async onSavePreferences(
    closeSidebarfn,
    selectedListings,
    {
      syncPricing,
      syncAvailability,
      syncReservations,
      markups,
      reservationTaxStatus,
      dynamicMarkupsEnabled,
    }
  ) {
    if (
      selectedListings.length === 0 ||
      (syncPricing === null &&
        syncAvailability === null &&
        syncReservations === null &&
        Object.keys(markups).length === 0)
    ) {
      return;
    }

    if (!this.markupIsSupported && syncReservations == true) {
      this.alert.error(this.intl.t('relay.forResSyncContactSupport'), {
        timeout: 20000,
      });
      return;
    }
    /* PREFERENCES VALIDATION ------------------------------------------------------------ */

    if (!this.markupIsSupported && Object.keys(markups).length > 0) {
      if (this.args.user.hasUsedMarkups) {
        this.alert.error(
          this.intl.t('relay.forChannelMarkupsContactSupportMarkupsUsed'),
          {
            timeout: 20000,
          }
        );
      } else {
        // User is grandfathered (markupIsSupported == false) and has never used markups.
        this.alert.error(
          this.intl.t('relay.forChannelMarkupsContactSupportMarkupsNotUsed'),
          {
            timeout: 20000,
          }
        );
      }
      return;
    }

    let url = '/api/listings/bulk/customize'; // bulk update endpoint
    /* MAPPING TO API ------------------------------------------------------------ */

    const data = {
      listingIds: selectedListings.map(listing => listing.id),
      dateAndTime: moment().format(),
      reservationTaxStatus: reservationTaxStatus,
      dynamicMarkupsEnabled: dynamicMarkupsEnabled,
    };
    if (syncPricing !== null) {
      data.directEnabled = syncPricing;
    }

    if (syncAvailability !== null) {
      data.availabilityEnabled = syncAvailability;
    }

    if (syncReservations !== null) {
      data.reservationsEnabled = syncReservations;
    }

    if (Object.keys(markups).length) {
      data.markups = markups;
    }
    /* PERSISTENCE ------------------------------------------------------------ */

    try {
      await this.ajax._post(url, data);
      this.alert.success(this.intl.t('relay.updatingCalendar'));
      /* MUTATION of SelectedListings (apply preferences) ---------------------- */

      for (let sl of selectedListings) {
        // price sync
        if (syncPricing !== null) {
          set(sl, 'enabled', syncPricing);
        }

        // availability sync
        // set availabilityEnabled only if availability sync is supported
        if (sl.availabilityIsSupported && syncAvailability !== null) {
          set(sl, 'availabilityEnabled', syncAvailability);
        }

        // reservation sync
        if (sl.reservationIsSupported && syncReservations !== null) {
          set(sl, 'reservationsEnabled', syncReservations);
        }

        // markups
        Object.keys(markups).forEach(channel => {
          sl.channelListings
            .filter(cl => cl.channel === channel)
            .forEach(cl => {
              if (typeof markups[channel] === 'object') {
                set(cl, 'minMarkup', markups[channel].minMarkup);
                set(cl, 'maxMarkup', markups[channel].maxMarkup);
                set(cl, 'dynamicMarkupsEnabled', true);
              } else {
                set(cl, 'markup', markups[channel]);
                set(cl, 'dynamicMarkupsEnabled', false);
              }
            });
        });
      }
      /* CLEANUP callbacks ---------------------- */
      closeSidebarfn();
    } catch (errors) {
      /* API Error ---------------------- */

      this.alert.error(this.intl.t('relay.errorTryAgain'));
    }
  }

  @action
  async onMergeListings(clearSelectionFn, selectedListings, settings = {}) {
    if (!selectedListings.length) {
      return;
    }
    const selectedListingsIds = selectedListings.map(listing => listing.id);

    const loadingMessage = this.intl.t('relay.mergingWait');
    const loadingSnackbar = this.alert.info(loadingMessage, {
      timeout: 20000,
      cssPanelClass: '-mt-16',
    });

    try {
      const data = await this.ajax._post('/api/listings/merge', {
        listingIds: [selectedListingsIds],
        ...settings,
      });
      loadingSnackbar.dismiss();
      this.alert.success(data.message, { timeout: 6000 });
      this.args.refreshListings();
    } catch (errors) {
      let err = errors[0];
      this.alert.error(err.message, { timeout: 10000 });
    } finally {
      // reset selections
      clearSelectionFn();
      this.alert.clearPanelModifier();
    }
  }

  @action
  async onUnmergeListings(clearSelectionFn, selectedListings) {
    if (!selectedListings.length) {
      return;
    }
    const selectedListingsIds = selectedListings.map(listing => listing.id);

    const loadingMessage = this.intl.t('relay.unmergingWait');
    const snackbar = this.alert.info(loadingMessage, {
      timeout: 20000,
      cssPanelClass: '-mt-16',
    });

    try {
      const data = await this.ajax._post('/api/listings/unmerge', {
        listingIds: selectedListingsIds,
      });
      // Prevent keeping the 'loading' and 'loaded' messages showing together
      snackbar.dismiss();

      this.alert.success(data.message, { timeout: 6000 });
      this.args.refreshListings();
    } catch (errors) {
      let err = errors[0];
      this.alert.error(err.message, { timeout: 10000 });
    } finally {
      // reset selections
      clearSelectionFn();
      this.alert.clearPanelModifier();
    }
  }
}
