import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';
@classic
export default class RegionsController extends Controller {
  @computed('polygon.activatedAt')
  get isActive() {
    if (this.get('polygon.activatedAt') !== null) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  @action
  updatePoints(pathPoints) {
    this.set('pathPoints', pathPoints);
  }

  @action
  setActivePolygon(polygon) {
    let pathPoints = polygon.points.map(point => {
      return [point.longitude, point.latitude];
    });
    this.set('pathPoints', pathPoints);
    this.set('polygon', polygon);
  }
}
