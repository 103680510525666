import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Controller from '@ember/controller';

@classic
export default class SearchListingController extends Controller {
  // -- Parameters ---------------------------------------------------------------------

  // -- Computed Properties ------------------------------------------------------------

  // -- Lifecycle Hooks ----------------------------------------------------------------

  init() {
    super.init();
  }

  @action
  closeDetailPage() {
    this.set('model.hideRightPanel', true);
    setTimeout(() => {
      this.transitionToRoute('search');
    }, 500);
  }
}
