import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { ChannelUtil } from 'appkit/lib/channel';

// We want to display the pms channel as the first icon, to indicate that it's
// the primary channel and then show the remaining channels.
function sortChannelListings(cl1, cl2) {
  if (ChannelUtil.isPms(cl1.channel)) {
    return -1;
  } else if (ChannelUtil.isPms(cl2.channel)) {
    return 1;
  } else {
    return cl1.channel.localeCompare(cl2.channel);
  }
}

export default class AppChannelIcons extends Component {
  @computed('args.channelListings')
  get sortedChannelListings() {
    let channelListings = this.args.channelListings
      ? [...this.args.channelListings]
      : [];

    return channelListings.sort(sortChannelListings);
  }
}
