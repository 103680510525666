import moment from 'moment';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';
import { buildDailyCalendar, buildWeeklyCalendar } from 'appkit/lib/relay/adapters';
import { AVAILABILITY_SYNC_MOCK_USE_CASES } from 'appkit/lib/relay/availability/mocking';
import { getAvailabilityCounters } from '../../../../lib/relay/availability/filters';

export default class RelayAvailabilityCalendarRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service ajax;

  title() {
    const title = `Relay / ${this.context.listing.name} / Staff - Availability Calendar`;
    this.globalNav.setTitle(title);
    return title;
  }

  async model() {
    const { listing, userPermissions } = this.modelFor(
      'dashboard.relay.merged-listing-detail'
    );

    // Generates 365 days of a random availability use cases for each day.
    // View models should be the same after integration with API (using an adapter API=> view)
    const mockedDailyAvailabilityCalendar = buildDailyCalendar().map(calendarDay =>
      buildAvailabilityDateViewData(
        calendarDay,
        AVAILABILITY_SYNC_MOCK_USE_CASES.random(calendarDay.date)
      )
    );

    const calendar = buildWeeklyCalendar(mockedDailyAvailabilityCalendar);

    const model = {
      listing,
      userPermissions,
      calendar,
      calendarLegend: availabilityCalendarLegend,
      _listingDates: mockedDailyAvailabilityCalendar,
      meta: {
        availabilityCounters: getAvailabilityCounters(mockedDailyAvailabilityCalendar),
      },
    };
    return model;
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    // Define initial state (Make sure counters are up to date to reflect most recent fetch!)
    controller.set('isDashboardTabOpen', false);
    controller.set('selectedAvailabilityTab', 0);
    controller.setAvailabilityCounters(model.meta.availabilityCounters);
    controller.resetFilters();
  }
}

const availabilityCalendarLegend = [
  { id: 'available', name: 'available', squareClass: 'bg-brand-400' },
  { id: 'blocked', name: 'blocked', squareClass: 'bg-gray-200' },
  { id: 'mismatch', name: 'not synced', squareClass: 'bg-warning-400 bg-opacity-25' },
];

function buildAvailabilityDateViewData(calendarDay, chanelListingsDateData) {
  const [PMS, directChannels] = chanelListingsDateData;

  // Availability card formatting --------------------------------------------------------------
  const joinedUpdates = joinAvailabilityUpdates(PMS, ...directChannels).map(
    addMarkerColors
  );
  // status metadata: ¿is PMS outdated? ¿Is DC outdated?
  const isSynced = isSyncedAvailability({ PMS, directChannels });

  const { availability: lastAvailability, timestamp } = joinedUpdates[0];
  const { availability: pmsAvailability, lastStatusUpdatedAt: lastPMSUpdateAt } = PMS;

  const isPMSOutdated =
    lastAvailability !== pmsAvailability && moment(timestamp).isAfter(lastPMSUpdateAt);

  const availabilitySyncMetadata = getAvailabilitySyncMetadata(isSynced, isPMSOutdated);
  const timeline = groupAvailabilityUpdatesByDay(joinedUpdates);

  // Calendar formatting ----------------------------------------------------------------------
  const availability = getAvailabilityCalendarColor(PMS.availability, isSynced);

  return {
    ...calendarDay,
    isSynced,
    PMS,
    directChannels,
    availability,
    // Availability card
    mostRecentUpdate: getLastUpdateData(timeline),
    syncStatus: getcardBorderColor(availabilitySyncMetadata),
    availabilityUpdatesByDay: timeline,
    metadata: [getAvailabilitySyncMetadata(isSynced, isPMSOutdated)].flat(),
  };
}

export function addMarkerColors(updateStory) {
  const isSynced = updateStory.type === 'synced';
  return {
    ...updateStory,
    isSynced,
    markerColor:
      updateStory.type === 'synced'
        ? 'border-brand-400 bg-[#b4e2e6]'
        : 'border-success-400 bg-[#bef7cd]',
  };
}

export function getLastUpdateData(timeline) {
  const mostRecentUpdate = timeline[0].stories[0];

  return {
    ...mostRecentUpdate,
    relativeLastUpdateAt: moment(mostRecentUpdate).fromNow(),
  };
}

export function getcardBorderColor(availabilitySyncMetadata) {
  if (availabilitySyncMetadata.some(x => x.id === 'outdatedPMS')) {
    return { color: 'border-l-warning-400' };
  }
  if (availabilitySyncMetadata.some(x => x.id === 'notSynced')) {
    return { color: 'border-l-brand-300' };
  }
  return { color: 'border-l-success-600' };
}

export function getAvailabilitySyncMetadata(isSynced, isPMSOutdated) {
  const syncMetadata = [];

  if (isSynced) return syncMetadata;

  if (isPMSOutdated) {
    syncMetadata.push({
      id: 'outdatedPMS',
      label: 'status: outdated',
      bg: 'bg-warning-400 bg-opacity-10',
      textColor: 'text-warning-400',
    });
  } else {
    syncMetadata.push({
      id: 'notSynced',
      label: 'status: sync pending',
      bg: 'bg-warning-400 bg-opacity-10',
      textColor: 'text-warning-400',
    });
  }

  return syncMetadata;
}

export function getAvailabilityCalendarColor(PMSAvailability, isSynced) {
  if (isSynced) {
    switch (PMSAvailability) {
      case 'available':
        return { id: 'available', bg: 'bg-brand-400' };
      case 'blocked':
        return { id: 'blocked', bg: 'bg-gray-200' };
    }
  }
  return {
    id: 'issue',
    label: 'issue',
    bg: 'bg-warning-400 bg-opacity-50',
    textColor: 'text-warning-400',
  };
}

function isSyncedAvailability(data) {
  const { PMS, directChannels } = data;
  return directChannels.every(dc => dc.availability === PMS.availability);
}

/**
 * UTILS -----------------------------------------------------------------------------
 * Operations based on FE models
 */
function groupAvailabilityUpdatesByDay(allUpdates) {
  const updatesGroupedByDay = [];
  let lastDayTracked = null;
  let i = -1;

  for (const story of allUpdates) {
    const { timestamp } = story;
    const dayOfYear = moment(timestamp).dayOfYear();
    const lastDayOfYearTracked = moment(lastDayTracked).dayOfYear();

    const shouldAddItemToGroup = lastDayTracked && dayOfYear === lastDayOfYearTracked;

    if (shouldAddItemToGroup) {
      updatesGroupedByDay[i].stories.push(story);
      continue;
    }
    // Create new day group
    updatesGroupedByDay.push({
      date: story.timestamp,
      stories: [story],
    });
    lastDayTracked = timestamp;
    i++;
  }

  return updatesGroupedByDay;
}

/**
 * Joins all the PMS and Direct Channels update stories for a
 * calendar day
 */
function joinAvailabilityUpdates(pmsDates, ...dcDates) {
  return [pmsDates, dcDates]
    .flat()
    .map(clDate => clDate.updateStamps)
    .flat()
    .sort((a, b) => (moment(a.timestamp).isSameOrAfter(b.timestamp) ? -1 : 1));
}
