import classNames from 'classnames';
import flatten from 'lodash.flatten';
import { VARIANTS, SIZES, COLORS } from './constants';

export * from './constants';
export * from './utils';
export { default as baseTheme } from './base.theme';
export { default as Themeable } from './themeable';

function getClassesForSize(options, size) {
  if (!options) {
    return [];
  }

  return flatten([options[SIZES.ALL], options[size]]).filter(id => id);
}

function getClassesForVariant(options, variant, color) {
  if (!options) {
    return [];
  }

  const allVariants = getClassesForColor(options[VARIANTS.ALL], color);
  const currentVariant = getClassesForColor(options[variant], color);

  return flatten([allVariants, currentVariant]).filter(id => id);
}

function getClassesForColor(options, color) {
  if (!options) {
    return [];
  }

  return flatten([options[COLORS.ALL], options[color]]);
}

export function getClassesForTheme(theme) {
  return classNames(
    theme.defaultClasses,
    theme.variantClasses,
    theme.spacingClasses,
    theme.fontSizeClasses,
    theme.iconSizeClasses
  );
}

export default function getThemeForOptions(theme, options = {}) {
  const { size, variant, color } = options;

  const themeClasses = {};

  Object.entries(theme).forEach(([key, theme]) => {
    const borders = theme.borders ?? [VARIANTS.OUTLINED];
    const opts = {
      ...options,
      border: borders.includes(VARIANTS.ALL) || borders.includes(variant),
    };

    const classes = {
      defaultClasses: theme.defaultClasses?.(opts),
      spacingClasses: getClassesForSize(theme.spacingClasses?.(opts), size),
      fontSizeClasses: getClassesForSize(theme.fontSizeClasses?.(opts), size),
      iconSizeClasses: getClassesForSize(theme.iconSizeClasses?.(opts), size),
      variantClasses: getClassesForVariant(
        theme.variantClasses?.(opts),
        variant,
        color
      ),
    };

    themeClasses[key] = classes;
  });

  return themeClasses;
}
