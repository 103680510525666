import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='register-interest-form' {{did-insert this.initForm}}>\n</div>", {"contents":"<div class='register-interest-form' {{did-insert this.initForm}}>\n</div>","moduleName":"appkit/components/relay/register-interest-form.hbs","parseOptions":{"srcName":"appkit/components/relay/register-interest-form.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { getPMSList } from '../../lib/show-relay-register-interest';
import { hideForm, hideBanner } from '../../lib/upsell/relay';
import { CHANNEL } from '../../lib/channel_configuration';

export default class RegisterInterestForm extends Component {
  constructor() {
    super(...arguments);
  }

  @action
  initForm() {
    if (localStorage.getItem('relayRegisterInterestSubmitted') !== this.args.user.id) {
      // eslint-disable-next-line
      hbspt.forms.create({
        region: 'na1',
        portalId: '4339927',
        formId: '27ceaf04-ffcf-4dcc-875e-cae6b0929c5c',
        sfdcCampaignId: '701f4000000Rj1yAAC',
        target: '.register-interest-form',
        onFormReady: () => {
          let ev = new Event('change');
          const email = document.querySelector('[name=email]');
          email.value = this.args.user.email;
          email.dispatchEvent(ev);

          const select = document.querySelector('[name=property_management_software]');
          const PMS = getPMSList(
            this.args.supercontrolFFEnabled,
            this.args.trackFFEnabled
          );
          for (let i = 0; i < this.args.accounts.length; i++) {
            if (PMS.indexOf(this.args.accounts[i].channel) > -1) {
              if (this.args.accounts[i].channel === CHANNEL.LIVEREZ_DIRECT) {
                select.value = 'Liverez';
              } else {
                select.value = this.args.accounts[i].channelConfig.label;
              }
              select.dispatchEvent(ev);
              break;
            }
          }
        },
        onFormSubmit: () => {
          document
            .querySelector('.register-interest-container')
            .classList.add('success');

          hideForm(this.args.user.id);
          hideBanner(this.args.user.id);
        },
      });
    }
  }
}
