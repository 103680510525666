import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';
import { tracked } from '@glimmer/tracking';

@classic
export default class RelayRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service featureFlag;
  @tracked isRelayV3Enabled;

  async init() {
    super.init(...arguments);
  }

  title() {
    let title = 'Signal Dashboard';
    this.globalNav.setTitle(title);
    return title;
  }
}
