import moment from 'moment';

export default {
  name: 'moment',

  initialize: function (_app) {
    moment.defineLocale('es-mx', {
      parentLocale: 'es',
      week: {
        dow: 0, // Sunday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
      },
    });
  },
};
