import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
/*
 * Keycode based validation is a bad idea because it will break on foreign
 * keyboards, specifically AZERTY keyboards need to hold shift to get to the
 * numbers.
 *
 * Number inputs restrict the input to numbers, but it's impossible to deal
 * with the scroll events in a sane manner - it catches the scroll and then
 * starts adjusting the number. Also the step buttons are shadow elements and
 * so hard to style across browsers. Tel inputs give a nice display on mobile,
 * but do no validation.
 *
 * Before observers are being phased out of ember, so before I would have just
 * prevented any change to the value directly. Now when I try and do that in a
 * regular observer, there has to be hacks to stop the value observer getting
 * called infinitely. Also we're setting a value, so we have to deal with caret
 * problems anyway.
 *
 * So we're left with dealing with events on the textbox directly. keyDown
 * doesn't allow a consistent method of getting the actual character, and also
 * allows paste. Keyup means things like paste will go through. Input is a nice
 * event as it allows for value validation and is called before the actual
 * insert, but it's not cancelable. So we're back to storing the caret
 * location.
 */
import Component from '@ember/component';

@classic
@tagName('div')
@classNames('app-percent-input')
export default class AppPercentInput extends Component {}
