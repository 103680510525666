import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class='h-full flex flex-col'\n  {{did-insert this.loadIpFilters}}\n  {{did-update this.loadIpFilters}}\n>\n  {{yield\n    (hash\n      total=this.total\n      ip=this.ip\n      orderedIps=this.orderedIps\n      showAddIp=this.showAddIp\n      ipErrorMessage=this.ipErrorMessage\n      ipHasError=this.ipHasError\n      isIPAlreadyUsed=this.isIPAlreadyUsed\n      isValid=this.isValid\n      actions=(hash\n        createIpFilter=this.createIpFilter\n        deleteIpFilter=this.deleteIpFilter\n        toggleShowAddIp=this.toggleShowAddIp\n        onCancel=this.onCancel\n        onIPChanged=this.onIPChanged\n        toggleIPIsFocused=this.toggleIPIsFocused\n      )\n    )\n  }}\n</div>", {"contents":"<div\n  class='h-full flex flex-col'\n  {{did-insert this.loadIpFilters}}\n  {{did-update this.loadIpFilters}}\n>\n  {{yield\n    (hash\n      total=this.total\n      ip=this.ip\n      orderedIps=this.orderedIps\n      showAddIp=this.showAddIp\n      ipErrorMessage=this.ipErrorMessage\n      ipHasError=this.ipHasError\n      isIPAlreadyUsed=this.isIPAlreadyUsed\n      isValid=this.isValid\n      actions=(hash\n        createIpFilter=this.createIpFilter\n        deleteIpFilter=this.deleteIpFilter\n        toggleShowAddIp=this.toggleShowAddIp\n        onCancel=this.onCancel\n        onIPChanged=this.onIPChanged\n        toggleIPIsFocused=this.toggleIPIsFocused\n      )\n    )\n  }}\n</div>","moduleName":"appkit/components/signal/dashboard/ip-filters/container.hbs","parseOptions":{"srcName":"appkit/components/signal/dashboard/ip-filters/container.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class SignalContainer extends Component {
  @service ajax;
  @service store;
  @service alert;

  @tracked loading = false;
  @tracked ips = [];
  @tracked showAddIp = false;
  @tracked ip = '';
  @tracked isIPFocused = false;
  @tracked isIPValid = false;

  constructor() {
    super(...arguments);
  }

  ipToNumber(ip) {
    return Number(
      ip
        .split('.')
        .map(p => p.padStart(3, '0'))
        .join('')
    );
  }

  get total() {
    return this.ips.length;
  }

  get orderedIps() {
    return this.ips.sort((a, b) => this.ipToNumber(a.ip) - this.ipToNumber(b.ip));
  }

  get isValid() {
    return this.isIPValid && !this.isIPAlreadyUsed;
  }

  get isIPAlreadyUsed() {
    return this.ips.find(i => i.ip.toLowerCase() === this.ip.toLowerCase());
  }

  get ipHasError() {
    return (
      !this.isIPFocused &&
      this.ip.length > 0 &&
      (!this.isIPValid || this.isIPAlreadyUsed)
    );
  }

  get ipErrorMessage() {
    if (this.ip.length > 0 && !this.isIPValid) return 'Please enter a new valid ip';

    if (this.ip.length > 0 && this.isIPAlreadyUsed)
      return 'This ip is already being used';

    return 'Unknown error';
  }

  @action
  toggleIPIsFocused() {
    this.isIPFocused = !this.isIPFocused;
  }

  @action
  async onIPChanged(evt) {
    this.ip = evt.target.value;
    const validIpRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    this.isIPValid = this.ip.match(validIpRegex);
  }

  @action
  onCancel() {
    this.ip = '';

    this.toggleShowAddIp();
  }

  @action
  async loadIpFilters() {
    this.loading = true;
    let ipFilters;

    try {
      ipFilters = await this.store.query('search_history_ip_filter', {});
    } catch (e) {
      console.error(e);
      return;
    } finally {
      this.loading = false;
    }

    this.ips = ipFilters.toArray();
  }

  @action toggleShowAddIp() {
    this.showAddIp = !this.showAddIp;
  }

  @action
  async createIpFilter() {
    if (!this.isValid) {
      return;
    }

    this.loading = true;
    let ipFilter;

    try {
      ipFilter = this.store.createRecord('search_history_ip_filter');
      ipFilter.ip = this.ip;
      await ipFilter.save();
    } catch (e) {
      this.alert.error('signal.errors.createIPFilter');
      console.error(e);
      return;
    } finally {
      this.loading = false;
    }

    this.ips = [...this.ips, ipFilter];
    this.alert.success(`Added ${this.ip} to the list of filtered ips`);
    this.ip = '';
  }

  @action
  async deleteIpFilter(ipFilter) {
    if (!confirm(`Are you sure you want to remove ${ipFilter.ip}?`)) {
      return;
    }

    this.loading = true;

    try {
      await ipFilter.destroyRecord();
    } catch (e) {
      this.alert.error('signal.errors.deleteIPFilter');
      console.error(e);
      return;
    } finally {
      this.loading = false;
    }

    this.ips = this.ips.filter(ip => ip.id !== ipFilter.id);
    this.alert.success(`Removed ${ipFilter.ip} from the list of filtered ips`);
  }
}
