import { helper } from '@ember/component/helper';

export default helper(function lensBlockedPercentage(params) {
  let [ownerCount, regularCount] = params;

  if (ownerCount == null || regularCount == null) {
    return null;
  }

  let totalCount = ownerCount + regularCount;

  return Math.round((ownerCount * 100) / totalCount);
});
