import moment from 'moment';

/**
 * Updates markups and taxes for a group of merged listings
 */
export async function SaveMergedListingsPreferences(listings, preferences, user, ajax) {
  const isMarkupSupported = !user.isGrandfatheredRelayUser;
  const hasUserUsedMarkups = user.hasUsedMarkups;

  validateMergedListingPreferences(listings, isMarkupSupported, hasUserUsedMarkups);

  const API_data = adaptListingPreferencesToAPI(listings, preferences);
  const endpoint = '/api/listings/bulk/customize';

  try {
    await ajax._post(endpoint, API_data);
  } catch (e) {
    throw new Error('relay.errorTryAgain');
  }
}

function validateMergedListingPreferences(
  preferences,
  isMarkupSupported,
  hasUserUsedMarkups
) {
  const { syncReservations, markups } = preferences;

  if (!isMarkupSupported && syncReservations == true) {
    throw new Error('relay.forResSyncContactSupport');
  }
  if (!markups) {
    return;
  }
  // This condition will never be applied now, as the view won't show the editor/send markups as payload, if user doesn't have permissions.
  // For now this, stays here as reference.
  if (!isMarkupSupported && Object.keys(markups).length > 0) {
    if (hasUserUsedMarkups) {
      throw new Error('relay.forChannelMarkupsContactSupportMarkupsUsed');
    } else {
      throw new Error('relay.forChannelMarkupsContactSupportMarkupsNotUsed');
    }
  }
}

function adaptListingPreferencesToAPI(listings, preferences) {
  const {
    syncPricing,
    syncAvailability,
    syncReservations,
    syncContent,
    markups,
    reservationTaxStatus,
    dynamicMarkupsEnabled,
  } = preferences;

  const data = {
    listingIds: listings.map(listing => listing.id),
    dateAndTime: moment().format(),
    reservationTaxStatus: reservationTaxStatus,
  };
  // This should be resolved in a diferent way, a boolean with a null having a 3rd meaning is confusing.
  // Atm null value means that not every listing to be merged has the same value for sync.
  // Therefore, the group config can´t base saved (without overriding an individual config).

  // We should force the user to resolve this in the merge-modal, probably, so we can skip this logic,
  // and simplify the logic in the bulk edit preferences.
  if (syncPricing != null) {
    data.directEnabled = syncPricing;
    // data.enabled = syncPricing; // originally in merge-container this was never sent.
    // was part of the conversations and initial attempt to solve the extrange behavior in the syncButton.
  }

  if (syncAvailability != null) {
    data.availabilityEnabled = syncAvailability;
  }

  if (syncReservations != null) {
    data.reservationsEnabled = syncReservations;
  }

  if (syncContent != null) {
    data.contentSyncingEnabled = syncContent;
  }

  if (markups && Object.keys(markups).length) {
    data.markups = markups;
    data.dynamicMarkupsEnabled = dynamicMarkupsEnabled;
  }

  return data;
}
