import Route from '@ember/routing/route';

export default class DashboardPricingIndexBulkSuggestionRoute extends Route {
  async beforeModel() {
    super.beforeModel(arguments);
    let pricingController = this.controllerFor('dashboard.pricing.index');
    if (!pricingController.model?.listings.filter(listing => listing.selected)) {
      return this.transitionTo('dashboard.pricing.index');
    }
  }

  renderTemplate() {
    this.render('dashboard/pricing/index/bulk-suggestion', {
      into: 'dashboard/pricing/index',
      outlet: 'side-panel',
    });
  }
}
