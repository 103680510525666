import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class IndexRoute extends AuthRoute {
  afterModel(user) {
    if (user.credential.get('isAdministrator') === true) {
      return this.replaceWith('dashboard.user.billing');
    } else {
      return this.replaceWith('dashboard.user.summary');
    }
  }
}
