import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { action, computed, set } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
@classic
@classNames('app-last-minute-min-stays')
export default class AppLastMinuteMinStays extends Component {
  @service intl;

  _hasErrors() {
    return this.model.any(elem => elem.minDaysError || elem.daysAwayError);
  }

  _hasEmptys() {
    return this.model.any(elem => !elem.minDays || !elem.daysAway);
  }

  @computed('model.@each.daysAway')
  get hasRepeatedDaysAway() {
    let data = this.model;
    let saw = new Set();

    let filteredList = data.filter(e => {
      if (!saw.has(e.daysAway)) {
        saw.add(e.daysAway);
        return true;
      }
    });

    return filteredList.length < data.length;
  }

  sendFormStatus() {
    if (this._hasErrors() || this.hasRepeatedDaysAway || this._hasEmptys()) {
      this.dataIsInvalid();
    } else {
      this.dataIsValid(this.lastMinuteMinStays);
    }
  }

  @action
  sendUpdate() {
    this.sendFormStatus();
  }

  @action
  addLastMinuteMinStay() {
    set(this, 'model', [...this.model, { direction: 'in', minDays: null }]);
  }

  @action
  removeLastMinuteMinStay(lastMinuteMinStay) {
    this.set(
      'model',
      this.model.filter(lm => lm != lastMinuteMinStay)
    );
    this.sendFormStatus();
  }

  @computed('model.[]', 'model.@each.{daysAway,direction,minDays}')
  get lastMinuteMinStays() {
    let forTheNextRanges = [
      ...this.model
        .filter(c => c.direction == 'in')
        .sort((a, b) => a.daysAway - b.daysAway),
    ];
    let afterRanges = [
      ...this.model
        .filter(c => c.direction == 'out')
        .sort((a, b) => a.daysAway - b.daysAway),
    ];
    const minStays = [...forTheNextRanges, ...afterRanges];
    return minStays;
  }

  @computed('model.[]', 'model.@each.{daysAway,minDays,direction}')
  get chartMinStayOptions() {
    return chartOptions(getXAxisDays(this.model), this.intl);
  }

  @computed('model.[]', 'model.@each.{daysAway,minDays,direction}')
  get isChartAvailable() {
    return this.model.length > 0;
  }

  @computed(
    'model.[]',
    'model.@each.{daysAway,minDays,direction}',
    'lastMinuteMinStays'
  )
  get chartMinStayData() {
    // Sort min stay rules in such a way that:
    // IN rules come first, and they're ordered ascending on daysAway
    // OUT rules come after, nd they're ordered descending on daysAway

    const minStaysX = getXAxisDays(this.lastMinuteMinStays);
    return [
      {
        data: minStaysX.map(day =>
          getAppliableMinStayForDate(day, this.lastMinuteMinStays)
        ),
      },
    ];
  }
}

function getAppliableMinStayForDate(day, minStayRules) {
  let minStayControl = [
    ...minStayRules.filter(c => c.direction == 'in'),
    ...minStayRules
      .filter(c => c.direction == 'out')
      .sort((a, b) => b.daysAway - a.daysAway),
  ];

  for (let rule of minStayControl) {
    if (
      (rule.direction == 'in' && day <= rule.daysAway) ||
      (rule.direction == 'out' && day >= rule.daysAway)
    ) {
      return rule.minDays;
    }
  }
  // Return 0 if no min stay rule applies
  return 0;
}

function chartOptions(discountsX, intl) {
  /**
   * Chart options copied from app-discounts.js
   */
  const out = {
    chart: {
      type: 'areaspline',
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          font: '14px Europa',
        },
        format: '{value}',
      },
      gridLineColor: 'transparent',
      tickColor: 'transparent',
      allowDecimals: false,
    },
    xAxis: {
      minStep: 1,
      categories: discountsX,
      title: {
        text: intl.t('common.days'),
      },
      tickColor: 'transparent',
      labels: {
        style: {
          font: '14px Europa',
        },
        format: `{value}`, //`{value} ${intl.t('common.days')}`,
        //step: discountsX.length % 10,
      },
      gridLineColor: 'rgba(185, 202, 210, 0.2)',
      gridLineWidth: 1,
      tickmarkPlacement: 'on',
      gridLineDashStyle: 'dot',
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        lineColor: 'rgba(37, 129, 137, 0.4)',
        fillColor: 'rgba(67, 182, 192, 0.4)',
        lineWidth: 2,
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      formatter: function () {
        const k = this.y !== 0 ? `Minimum stay of ${Math.abs(this.y)}d` : 'No rule';
        return `${k} on day ${this.x}`;
      },
    },
  };

  return out;
}

function getXAxisDays(minStayRules) {
  // Choose how many days to show on the X axis.
  // The algo outputs the number of altered days plus an extra day for visibility & insight
  const maxDay = Math.max(...minStayRules.map(c => c.daysAway)) + 2 || 0;
  return maxDay > 0 ? [...Array(maxDay).keys()] : [];
}
