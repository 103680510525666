import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

// Max milliseconds supported by JS Date
// Used to simulate infinity
const MAX_DATE_MS = 8640000000000000;

export default class AppDateRangePicker extends Component {
  @tracked
  lastDateSelected = null;

  @tracked
  calendarValue = this.args.initialValue || moment();

  @tracked
  center = this.args.initialValue || moment();

  @tracked
  inputValue = (this.args.initialValue || moment()).format('L');

  get minDate() {
    return this.args.minDate ?? moment(-MAX_DATE_MS);
  }

  get maxDate() {
    return this.args.maxDate ?? moment(MAX_DATE_MS);
  }

  get isDateDirty() {
    return !this.calendarValue.isSame(this.lastDateSelected);
  }

  isBetween(date, minDate, maxDate) {
    return date.isBetween(minDate, maxDate, '[]');
  }

  @action
  onClose() {
    // This happens if the user types a date in the input field and clicks outside the calendar
    if (this.isDateDirty) {
      this.dateSelected(this.calendarValue);
    }
  }

  @action
  keyUp(value) {
    // Re-center the calendar as the user types in dates
    let parsed = moment(value, 'L');
    if (!(parsed.isValid() && parsed.isBetween(this.minDate, this.maxDate, '[]'))) {
      return;
    }

    this.center = parsed;
    this.calendarValue = parsed;
  }

  @action
  keyDown(value, e) {
    // Explicitly handle tab to remove focus. Not sure I love this...
    if (e.originalEvent.keyCode !== 9) {
      return;
    }
    this.dateSelected(this.calendarValue);
  }

  @action
  blur(value) {
    // Blur could be caused by
    //  * clicking over to the calendar
    //  * clicking on the document
    //  * typing a date and hitting tab
    //  * tabbing to the calendar
    //  * shift-tab to a prior input
    const parsed = moment(value, 'L');
    if (!(parsed.isValid() && parsed.isBetween(this.minDate, this.maxDate, '[]'))) {
      console.log('Invalid date', parsed);
      this.inputValue = this.calendarValue.format('L');
      return;
    }
    this.calendarValue = parsed;
    // Reset to the fully formatted date - e.g. 4/20 -> 4/20/2020
    this.inputValue = parsed.format('L');
  }

  @action
  calendarClick(value) {
    this.dateSelected(value.moment);
  }

  @action
  selectToday() {
    this.dateSelected(moment());
  }

  dateSelected(value) {
    if (!value._isAMomentObject) {
      value = moment(value, 'L');
    }
    this.calendarValue = value;
    this.lastDateSelected = value;
    this.inputValue = value.format('L');
    this.args.onDateSelected(value);
  }
}
