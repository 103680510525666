import { on } from '@ember/object/evented';
import { computed } from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';
import { ChannelUtil } from 'appkit/lib/channel';
import { CHANNEL } from 'appkit/lib/channel_configuration';
import moment from 'moment';

export default BaseModel.extend({
  account: null,
  masterListing: null,

  //TODO: to review, remove what is not for ChannelListing

  id: null,
  url: computed('id', function () {
    return `/api/channellistings/${this.id}`;
  }),

  channel: null,
  channelLabel: computed('channel', function () {
    return ChannelUtil.getLabel(this.channel);
  }),
  channelId: null,
  channelUrl: null,

  basePrice: 0,
  image: '',
  lastUpdated: null,
  pricedAt: null,
  minPrice: 0,
  listed: true,
  notify: false,
  syncing: false,
  markup: null,

  init() {
    this._super(arguments);
    this._EDITABLE = [];
    this.set('nearbyListings', this.nearbyListings ?? []);
    this.set('calendar', this.calendar ?? []);
  },

  activateUrl: computed('channel', 'channelId', function () {
    let channel = this.channel;
    let url = null;
    if (channel === CHANNEL.AIRBNB) {
      let id = this.channelId;
      url = `https://www.airbnb.com/manage-listing/${id}/calendar`;
    }
    return url;
  }),

  title: '',
  currency: 'USD',

  // Stats
  stats: null,

  // Only offer to save prices when things have changed.
  pricesDirty: computed(
    'basePrice',
    '_dirty.{basePrice,minPrice}',
    'minPrice',
    function () {
      if (this.basePrice !== this.get('_dirty.basePrice')) {
        return true;
      }
      if (this.minPrice !== this.get('_dirty.minPrice')) {
        return true;
      }
      return false;
    }
  ),

  _momentCasts: on('init', function () {
    this.set('calendar', []);
    let pricedAt = this.pricedAt;
    if (pricedAt) {
      this.set('pricedAt', moment(pricedAt));
    }

    let lastUpdated = this.lastUpdated;
    if (lastUpdated) {
      this.set('lastUpdated', moment(lastUpdated));
    }
  }),

  imageOrDefault: computed('image', function () {
    return this.image || 'assets/home-blue.svg';
  }),

  appliedMarkup: computed('markup', 'account.markup', function () {
    return this.markup ?? this.account.markup;
  }),
});
