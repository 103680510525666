import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (gt this.numPages 1)}}\n  <div class='flex py-4 px-4 space-x-1'>\n    <Bp::Button\n      @color='brand'\n      disabled={{this.gotoFirstPageDisabled}}\n      @onClick={{this.gotoPreviousPage}}\n    >\n      &lt;\n    </Bp::Button>\n\n    {{#each this.pages as |page|}}\n      {{#if (eq page '...')}}\n        <div>\n          ...\n        </div>\n      {{else}}\n        <Bp::Button\n          @variant='outlined'\n          @color={{if (eq page @page) 'brand' 'gray'}}\n          @onClick={{fn this.gotoPage page}}\n        >\n          {{page}}\n        </Bp::Button>\n      {{/if}}\n    {{/each}}\n\n    <Bp::Button\n      @color='brand'\n      disabled={{this.gotoLastPageDisabled}}\n      @onClick={{this.gotoNextPage}}\n    >\n      &gt;\n    </Bp::Button>\n  </div>\n{{/if}}", {"contents":"{{#if (gt this.numPages 1)}}\n  <div class='flex py-4 px-4 space-x-1'>\n    <Bp::Button\n      @color='brand'\n      disabled={{this.gotoFirstPageDisabled}}\n      @onClick={{this.gotoPreviousPage}}\n    >\n      &lt;\n    </Bp::Button>\n\n    {{#each this.pages as |page|}}\n      {{#if (eq page '...')}}\n        <div>\n          ...\n        </div>\n      {{else}}\n        <Bp::Button\n          @variant='outlined'\n          @color={{if (eq page @page) 'brand' 'gray'}}\n          @onClick={{fn this.gotoPage page}}\n        >\n          {{page}}\n        </Bp::Button>\n      {{/if}}\n    {{/each}}\n\n    <Bp::Button\n      @color='brand'\n      disabled={{this.gotoLastPageDisabled}}\n      @onClick={{this.gotoNextPage}}\n    >\n      &gt;\n    </Bp::Button>\n  </div>\n{{/if}}","moduleName":"appkit/components/bp/paginator/index.hbs","parseOptions":{"srcName":"appkit/components/bp/paginator/index.hbs"}});
import Component from '@glimmer/component';
import { computed } from '@ember/object';
// import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BpCalendarComponent extends Component {
  @computed('args.{total,pageSize}')
  get numPages() {
    let numPages = Math.floor(this.args.total / this.args.pageSize);
    return numPages;
  }

  @computed('numPages', 'args.page')
  get pages() {
    let pagesList = [];

    if (this.numPages < 7) {
      for (let i = 0; i < this.numPages; i++) {
        pagesList[i] = i + 1;
      }

      return pagesList;
    }

    if (this.args.page < 6) {
      for (let i = 0; i < 5; i++) {
        pagesList[i] = i + 1;
      }
      pagesList[5] = '...';
      pagesList[6] = this.numPages;

      return pagesList;
    }

    if (this.args.page > this.numPages - 4) {
      pagesList[0] = 1;
      pagesList[1] = '...';

      for (let i = 2; i < 6; i++) {
        pagesList[i] = this.numPages - 5 + i;
      }

      return pagesList;
    }

    pagesList[0] = 1;
    pagesList[1] = '...';

    pagesList[2] = this.args.page - 1;
    pagesList[3] = this.args.page;
    pagesList[4] = this.args.page + 1;

    pagesList[5] = '...';
    pagesList[6] = this.numPages;

    return pagesList;
  }

  @computed('args.page', 'numPages')
  get gotoFirstPageDisabled() {
    return this.args.page == 1;
  }

  @computed('args.page', 'numPages')
  get gotoLastPageDisabled() {
    return this.args.page == this.numPages;
  }

  @action
  gotoPreviousPage() {
    let { page, gotoPage } = this.args;

    if (page > 1) {
      page -= 1;
    }

    gotoPage(page);
  }

  @action
  gotoNextPage() {
    let { page, gotoPage } = this.args;

    if (page < this.numPages) {
      page += 1;
    }

    gotoPage(page);
  }

  @action
  gotoPage(page) {
    this.args.gotoPage(page);
  }
}
