import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class AppLoadingIndicator extends Component {
  isFullRefresh = false;
  size = 40;
  color = '#10304a';
}
