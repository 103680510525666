import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield}}", {"contents":"{{yield}}","moduleName":"appkit/components/chart-print.hbs","parseOptions":{"srcName":"appkit/components/chart-print.hbs"}});
import Component from '@glimmer/component';
import { action, computed } from '@ember/object';

export default class ChartPrint extends Component {
  chartHeight;
  chartWidth;

  constructor() {
    super(...arguments);

    window.addEventListener('beforeprint', this.reflowChartBeforePrint);
    window.addEventListener('afterprint', this.reflowChartAfterPrint);
  }

  willDestroy() {
    window.removeEventListener('beforeprint', this.reflowChartBeforePrint);
    window.removeEventListener('afterprint', this.reflowChartAfterPrint);
  }

  @computed('args.chart')
  get chart() {
    return this.args.chart;
  }

  @action
  reflowChartBeforePrint() {
    this.chartHeight = this.chart.chartHeight;
    this.chartWidth = this.chart.chartWidth;
    this.chart.setSize(this.args.width, this.args.height ?? this.chartHeight);
    this.chart.reflow();
  }

  @action
  reflowChartAfterPrint() {
    this.chart.setSize(this.chartWidth, this.chartHeight);
    this.chart.reflow();
    this.chartHeight = null;
    this.chartWidth = null;
  }
}
