import classic from 'ember-classic-decorator';
import Service, { inject as service } from '@ember/service';
import Util from 'appkit/lib/util';
import { action, computed } from '@ember/object';
import moment from 'moment';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/application';

const MAX_DATE_MS = 8640000000000000;
const today = moment();
const twoWeeksAgo = moment().subtract(2, 'weeks');
const oneYearBack = moment().subtract(1, 'year');
const twoYearsBack = moment().subtract(2, 'year');

@classic
export default class BookingReviewService extends Service {
  @service bpStore;
  @service ajax;
  @service alert;
  @service featureFlag;
  @tracked advancedBartSettingsVisible = false;
  @tracked bookingReviewOptionSelected = null;
  @tracked BARTCustomizationModalVisible = false;
  @tracked isBartRunning = false;
  @tracked isNotBartRunning = true;
  @tracked comparisonYearHighSeason = oneYearBack;
  @tracked isBookingReviewActive = false;
  @tracked _isRunningReview = false; // true means submitter experience is on
  @tracked showCustomerFacingBartFeature = false;
  @service currentUser;
  @service beyondI18n;
  @service intl;
  @service embed;

  refreshingStats = false;
  listingStatsSynced = false;
  isBookingReviewEnabled = false;
  refreshingStats = false;
  listingStatsSynced = false;
  isBookingReviewHeaderCollapsed = false;
  displayBookingReviewSubHeader = false;
  progressbarWidth = 20;
  minDate = moment(-MAX_DATE_MS);
  maxDate = moment(MAX_DATE_MS);
  saveBartCustomSettings = false;
  enableMinCheckBoxValue = 'year';
  disableMinCheckBoxValue = null;
  @tracked forceCompleteSuggestion = false;

  reportDates = {
    start: twoWeeksAgo,
    end: today,
  };
  statsStartDate = today;
  statsDaysOut = 90;
  statsDates = {
    period1: {
      start: null,
      end: null,
    },
    period2: {
      start: null,
      end: null,
    },
    period3: {
      start: null,
      end: null,
    },
  };

  recommendationType = 'base_price';
  lowAvailabilityCommentTrigger = true;
  highSeasonStatsCommentTrigger = true;
  splitComments = false;
  bpReferenceValue = 3;
  lmReferenceValue = 10;
  blocksReferenceValue = 50;
  lowAvailabilityReferenceValue = 70;
  overridesReferenceValue = 50;
  minReferenceValue = 40;
  shortTermReferenceValue = 30;
  mediumTermReferenceValue = 90;

  get localesOptions() {
    return [
      { field: 'en', label: 'English' },
      { field: 'fr', label: 'Français' },
      { field: 'es', label: 'Español' },
      { field: 'it', label: 'Italiano' },
      { field: 'pt', label: 'Português' },
    ];
  }

  @computed('beyondI18n.locale')
  get lang() {
    const locale = this.beyondI18n.selectedLocale?.field.split('-');
    if (locale) {
      if (this.localesOptions.find(l => l?.field === locale[0])) {
        return locale[0];
      }
    }

    return 'en';
  }

  yearOptions = [
    { date: oneYearBack, name: oneYearBack.year() },
    { date: twoYearsBack, name: twoYearsBack.year() },
  ];

  bookingReviewActionOptions = [
    { action: 'customBart', name: 'Customize Auto Booking Review settings' },
    { action: 'reviewBookings', name: 'Add individual suggestions' },
  ];

  customerBookingReviewActionOptions = [
    {
      action: 'customCustomerBart',
      name: this.intl.t('pricing.bookingReviews.customCustomerBart'),
    },
  ];

  async init() {
    super.init(...arguments);
    // Always show on dev / test. Only show on live if they are CSM managed.
    this.set('isBookingReviewEnabled', this.currentUser.globalPermission !== 'view');
    this.updateBookingReviewStatus();

    this.featureFlag.evaluate('customer-facing-bart', false).then(value => {
      this.showCustomerFacingBartFeature = value;
    });
  }

  updateBookingReviewStatus() {
    let bookingReviewRecords = this.bpStore.all('bookingReview');

    if (bookingReviewRecords.length > 0) {
      let bookingReview = bookingReviewRecords.reduce((a, b) =>
        a.createdAt < b.createdAt ? b : a
      );

      let expiresAt = bookingReview.expiresAt
        ? moment(bookingReview.expiresAt)
        : moment(bookingReview.createdAt).subtract(14, 'days');

      let isBookingReviewExpired = expiresAt.isBefore(moment());
      let isBookingReviewActive =
        !isBookingReviewExpired &&
        bookingReview.submittedAt &&
        !bookingReview.completedAt;
      this.set('isBookingReviewActive', isBookingReviewActive);

      let isBookingReviewRunning =
        bookingReview.createdAt &&
        !bookingReview.expiresAt &&
        !bookingReview.submittedAt &&
        !bookingReview.completedAt;

      this.set('isRunningReview', isBookingReviewRunning);
    }
  }

  get isRunningReview() {
    if (this.showCustomerFacingBartFeature) return this._isRunningReview;
    return this.currentUser.isBookingReviewSubmitterEnabled && this._isRunningReview;
  }

  set isRunningReview(value) {
    this._isRunningReview = value;
  }

  @action
  onChangeYearSelected(value) {
    if (value === 'enable') {
      this.comparisonYearHighSeason = this.yearOptions[0].date;
      this.set('enableCheckBoxValue', 'year');
      this.set('disableCheckBoxValue', null);
    } else if (value === 'disable') {
      this.comparisonYearHighSeason = this.yearOptions[1].date;
      this.set('enableCheckBoxValue', null);
      this.set('disableCheckBoxValue', 'year');
    }
  }

  @action
  onRecommendationTypeChange(value) {
    this.set('recommendationType', value);
  }

  @action
  onReportDatesRangeSelected(dates) {
    this.set('reportDates.start', dates.start.toDate());
    this.set('reportDates.end', dates.end?.toDate());
  }

  @action
  onStatsDatesRangeSelected(number, dates) {
    this.set(`statsDates.period${number}.start`, dates.start.toDate());
    this.set(`statsDates.period${number}.end`, dates.end?.toDate());
  }

  @action
  onStatsStartDateSelected(date) {
    this.set('statsStartDate', date.toDate());
  }

  @action
  onInputValueChange(property, evt) {
    this.set(property, evt.target.value);
  }

  @action
  onBookingReviewActionOptionSelected(value) {
    if (value.action === 'reviewBookings') {
      this.toggleRunReviewState();
    } else if (value.action === 'customBart') {
      this.BARTCustomizationModalVisible = true;
    } else if (value.action === 'customCustomerBart') {
      this.BARTCustomizationModalVisible = true;
    }
  }

  @action
  toggleAdvancedBartSettings() {
    this.advancedBartSettingsVisible = !this.advancedBartSettingsVisible;
  }

  @action
  closeBartModal() {
    this.clearForm();
  }

  refreshStats() {
    this.set('refreshingStats', true);
    let stream = this.ajax.stream(
      '/api/booking_reviews/update_statistics',
      {},
      streamData => {
        let parsed;

        try {
          parsed = Util.camelizer(streamData.message);
        } catch (err) {
          console.log('Message', streamData.message);
          return;
        }

        let listing = this.bpStore.peekRecord('listing', parsed.id);
        listing.set('statistics', parsed.statistics);
        listing.set('statisticsUpdatedAt', parsed.statisticsUpdatedAt);
      }
    );
    stream.catch(_errors => {
      this.set('refreshingStats', false);
      this.alert.error('validation.genericWithTryAgain');
      return;
    });
    stream.then(_responseData => {
      this.alert.success('Listing stats successfully loaded');
      this.set('refreshingStats', false);
      this.set('listingStatsSynced', true);
    });
  }

  toggleRunReviewState() {
    this.set('isRunningReview', !this.isRunningReview);
  }

  clearForm() {
    this.set('reportDates.start', this.reportDates.start);
    this.set('reportDates.end', this.reportDates.end);
    this.set('statsStartDate', today);
    this.set('statsDaysOut', 90);
    this.set('recommendationType', 'base_price');
    this.set('statsDates.period1.start', null);
    this.set('statsDates.period1.end', null);
    this.set('statsDates.period2.start', null);
    this.set('statsDates.period2.end', null);
    this.set('statsDates.period3.start', null);
    this.set('statsDates.period3.end', null);
    this.set('bpReferenceValue', 3);
    this.set('lmReferenceValue', 10);
    this.set('blocksReferenceValue', 50);
    this.set('lowAvailabilityReferenceValue', 70);
    this.set('overridesReferenceValue', 50);
    this.set('minReferenceValue', 40);
    this.set('shortTermReferenceValue', 30);
    this.set('mediumTermReferenceValue', 90);
    this.set('saveBartCustomSettings', null);
    this.set('advancedBartSettingsVisible', false);
    this.set('bookingReviewOptionSelected', null);
    this.set('BARTCustomizationModalVisible', false);
    this.set('isBartRunning', false);
    this.set('isNotBartRunning', true);
    this.set('saveBartCustomSettings', false);
    this.set('progressbarWidth', 20);
    this.set('comparisonYearHighSeason', this.comparisonYearHighSeason);
    this.set('lowAvailabilityCommentTrigger', true);
    this.set('highSeasonStatsCommentTrigger', true);
    this.set('splitComments', false);
  }

  _parseDate(date) {
    return date ? moment(date).format('YYYY-MM-DD') : null;
  }

  submitBartReport(use_custom_values) {
    let params = {
      basePriceCommentTrigger: true,
      bookingsCommentTrigger: true,
      minPriceCommentTrigger: true,
      blocksCommentTrigger: true,
      overridesCommentTrigger: true,
      newListingsCommentTrigger: true,
      vsMaxCommentTrigger: true,
      vsAvgCommentTrigger: true,
      reportStartDate: this._parseDate(this.reportDates.start),
      reportEndDate: this._parseDate(this.reportDates.end),
      statsStartDate: this._parseDate(this.statsStartDate),
      statsDaysOut: this.statsDaysOut,
      recommendationType: this.recommendationType,
      addStatsStartDate_1: this._parseDate(this.statsDates.period1.start),
      addStatsEndDate_1: this._parseDate(this.statsDates.period1.end),
      addStatsStartDate_2: this._parseDate(this.statsDates.period2.start),
      addStatsEndDate_2: this._parseDate(this.statsDates.period2.end),
      addStatsStartDate_3: this._parseDate(this.statsDates.period3.start),
      addStatsEndDate_3: this._parseDate(this.statsDates.period3.end),
      bpReferenceValues: this.bpReferenceValue,
      lmReferenceValues: this.lmReferenceValue,
      blocksReferenceValues: this.blocksReferenceValue,
      lowAvailabilityReferenceValues: this.lowAvailabilityReferenceValue,
      overridesReferenceValues: this.overridesReferenceValue,
      minReferenceValues: this.minReferenceValue,
      shortTermReferenceValues: this.shortTermReferenceValue,
      mediumTermReferenceValues: this.mediumTermReferenceValue,
      saveDefaultSettings: this.saveBartCustomSettings,
      comparisonYearHighSeason: this._parseDate(this.comparisonYearHighSeason),
      lowAvailabilityCommentTrigger: this.lowAvailabilityCommentTrigger,
      highSeasonStatsCommentTrigger: this.highSeasonStatsCommentTrigger,
      splitComments: this.splitComments,
      lang: this.lang,
    };
    if (!use_custom_values) {
      // customization window only have recommendation type selection.
      // if customization window is not used, always send recommendation type all to retrieve all
      params.recommendationType = 'all';
    }

    this.set('isBartRunning', true);
    this.set('isNotBartRunning', false);
    this.set('progressbarWidth', 30);
    this.toggleRunReviewState();
    this.set('displayBookingReviewSubHeader', !this.displayBookingReviewSubHeader);

    if (!this.currentUser.isStaff) {
      this.embed.czCaptureEvent('auto-booking-review', 'Auto booking review');
    }
    let stream = this.ajax.stream(
      '/api/booking_reviews/run_bart',
      params,
      streamData => {
        try {
          Util.camelizer(streamData.message);
          this.set('progressbarWidth', this.progressbarWidth + 30);
        } catch (err) {
          this.set('isBartRunning', true);
          this.set('isNotBartRunning', false);
          console.log('Message', streamData.message);
          return;
        }
      }
    );
    stream.catch(_errors => {
      let errorMessageToDisplay = 'validation.genericWithTryAgain';
      if (_errors !== 'Error in stream response') {
        errorMessageToDisplay = _errors;
      }
      this.set('isBartRunning', false);
      this.set('isNotBartRunning', true);
      this.toggleRunReviewState();
      this.alert.error(errorMessageToDisplay);

      return;
    });
    stream.then(_responseData => {
      this.set('progressbarWidth', 100);
      this.alert.success(
        this.showCustomerFacingBartFeature
          ? this.intl.t('pricing.bookingReviews.autoBRSuccessfullyFinished')
          : this.intl.t('pricing.bookingReviews.bartSuccessfullyFinished')
      );
      this.set('isBartRunning', false);
      this.set('isNotBartRunning', true);
      getOwner(this).lookup('route:dashboard').refresh();
      if (this.showCustomerFacingBartFeature) {
        if (this.currentUser.isImpersonating) {
          this.forceCompleteSuggestion = !this.currentUser.isStaff;
        } else {
          this.updateBookingReviewStatus();
        }
      }
    });
    this.closeBartModal();
  }
}
