import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='listing-booking-analysis'>\n  <div class='bp-grid stat px-1 text-center'>\n    {{#if (and this.lastBookingReview this.lastBookingReview.bookingAnalysis)}}\n      <span\n        class='block w-full text-sm text-center truncate'\n        role='button'\n        onmouseenter={{action (mut @bookingAnalysisHover) @listing.id}}\n        onmouseleave={{action (mut @bookingAnalysisHover) null}}\n      >\n        {{this.lastBookingReview.bookingAnalysis}}\n      </span>\n    {{else}}\n      <span class='block w-full text-sm text-center'>\n        —\n      </span>\n    {{/if}}\n    {{#if (eq @bookingAnalysisHover @listing.id)}}\n      <EmberPopperTargetingParent @class='popper w-64 p-0' @placement='bottom'>\n        <div class='p-4'>\n          {{this.lastBookingReview.bookingAnalysis}}\n        </div>\n      </EmberPopperTargetingParent>\n    {{/if}}\n  </div>\n</div>", {"contents":"<div class='listing-booking-analysis'>\n  <div class='bp-grid stat px-1 text-center'>\n    {{#if (and this.lastBookingReview this.lastBookingReview.bookingAnalysis)}}\n      <span\n        class='block w-full text-sm text-center truncate'\n        role='button'\n        onmouseenter={{action (mut @bookingAnalysisHover) @listing.id}}\n        onmouseleave={{action (mut @bookingAnalysisHover) null}}\n      >\n        {{this.lastBookingReview.bookingAnalysis}}\n      </span>\n    {{else}}\n      <span class='block w-full text-sm text-center'>\n        —\n      </span>\n    {{/if}}\n    {{#if (eq @bookingAnalysisHover @listing.id)}}\n      <EmberPopperTargetingParent @class='popper w-64 p-0' @placement='bottom'>\n        <div class='p-4'>\n          {{this.lastBookingReview.bookingAnalysis}}\n        </div>\n      </EmberPopperTargetingParent>\n    {{/if}}\n  </div>\n</div>","moduleName":"appkit/components/dashboard/columns/booking-analysis.hbs","parseOptions":{"srcName":"appkit/components/dashboard/columns/booking-analysis.hbs"}});
import ListingTableViewComponent from 'appkit/components/dashboard/listing-table-view';
import { computed } from '@ember/object';

export default class BookingAnalysisComponent extends ListingTableViewComponent {
  @computed(
    'args.listing.lastBookingReviewForTableView',
    'currentUser.currentUser.isBookingReviewSubmitterEnabled',
    'args.listing.bookingReviewListings.@each.{status}'
  )
  get lastBookingReview() {
    const bookingReview = this.args.listing.lastBookingReviewForTableView;

    if (bookingReview?.status == 'canceled') return null;
    if (
      !this.currentUser.isBookingReviewSubmitterEnabled &&
      bookingReview &&
      bookingReview.hasPendingSuggestion
    ) {
      return null;
    }
    return bookingReview;
  }
}
