import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';

import Component from '@ember/component';
@classic
@tagName('')
export default class AppSvg extends Component {
  // PUBLIC
  src = null;

  // Apparently you can just leave the template empty, even with a yield in the
  // layout. Specifying a template on a component is deprecated (which seems odd
  // to me - how are routable components going to work?), but it doesn't
  // complain if we leave it empty.
  //   templateName: 'components/simple-yield'

  // Ideally we could just have this as the layout, however binding to the
  // layout broke at some point - bindings don't hold. And even updating the
  // layout manually doesn't work in 1.13. Fortunately the render helpers still
  // work, so we can just use that in the layout. See:
  //  * https://github.com/emberjs/ember.js/issues/11186
  @computed('src')
  get srcTemplate() {
    return `svg/${this.src}`;
  }
}
