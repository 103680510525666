/* eslint-disable ember/no-observers */
// DebugAdapter inherits from DataAdapter. This acts as the interface to the
// ember-inspector. This is pretty much copied straight from ember-data.
//
// See:
// https://github.com/emberjs/ember.js/blob/master/packages/ember-extension-support/lib/data_adapter.js
// https://github.com/emberjs/data/blob/master/packages/ember-data/lib/system/debug/debug_adapter.js
import { addObserver, removeObserver } from '@ember/object/observers';

import { get } from '@ember/object';
import { capitalize, underscore } from '@ember/string';
import DataAdapter from '@ember/debug/data-adapter';
import BaseModel from 'appkit/bp-models/base_model';

// eslint-disable-next-line ember/no-classic-classes
export default DataAdapter.extend({
  getModelTypes() {
    let out = [];
    for (let type of this.bpStore.types()) {
      out.push({
        klass: type,
        name: type.toString(),
      });
    }
    return out;
  },

  getFilters() {
    return [
      { name: 'isNew', desc: 'New' },
      { name: 'isModified', desc: 'Modified' },
      { name: 'isClean', desc: 'Clean' },
    ];
  },

  detect(klass) {
    return klass !== BaseModel && BaseModel.detect(klass);
  },

  columnsForType(type) {
    let columns = [{ name: 'id', desc: 'Id' }];
    for (let name of type.attributes()) {
      let desc = capitalize(underscore(name).replace('_', ' '));
      columns.push({ name: name, desc: desc });
    }
    return columns;
  },

  getRecords(type) {
    // No clue what is actually getting passed in here.
    type = type.toString().split(':')[1];
    this.bpStore.all(type);
  },

  getRecordColumnValues(record) {
    let columnValues = { id: get(record, 'id') };

    record.eachAttribute(key => {
      let value = get(record, key);
      columnValues[key] = value;
    });
    return columnValues;
  },

  getRecordKeywords(record) {
    let keywords = [];
    let keys = ['id'];
    record.eachAttribute(key => keys.push(key));

    keys.forEach(key => keywords.push(get(record, key)));
    return keywords;
  },

  getRecordFilterValues(record) {
    return {
      isNew: record.get('isNew'),
      isModified: record.get('isDirty') && !record.get('isNew'),
      isClean: !record.get('isDirty'),
    };
  },

  getRecordColor(record) {
    if (record.get('isNew')) {
      return 'green';
    }
    if (record.get('isDirty')) {
      return 'blue';
    }
    return 'black';
  },

  observeRecord(record, recordUpdated) {
    let releaseMethods = [];
    let keysToObserve = ['id', 'isNew', 'isDirty'];

    record.eachAttribute(key => keysToObserve.push(key));

    keysToObserve.forEach(key => {
      let handler = () => recordUpdated(this.wrapRecord(record));
      addObserver(record, key, handler);
      releaseMethods.push(() => removeObserver(record, key, handler));
    });

    return () => {
      releaseMethods.forEach(fn => fn());
    };
  },
});
