import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';

export default class DashboardPricingMapRoute extends AuthRoute {
  @service() featureFlag;

  async beforeModel() {
    const isMapViewEnabled = await this.featureFlag.evaluate('map-view', false);

    if (!(isMapViewEnabled || this.currentUser.isImpersonating)) {
      return this.transitionTo('dashboard.pricing.index');
    }
  }
}
