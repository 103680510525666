import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

class Errors {
  @tracked _cache = {};

  set(key, value) {
    this._cache[key] = value;
    this._cache = this._cache;
  }

  get(key) {
    return this._cache[key];
  }
}

export default class PostedPricesController extends Controller {
  today = new Date();
  @tracked saving = false;
  @tracked channelId = '';
  @tracked startDate = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 1);
  @tracked endDate = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 10);
  @tracked errors = new Errors();
  @tracked responseData = [];
  @tracked chartData = [];

  chartOptions = {
    title: {
      text: 'Pushed Price by Pushed ON Day',
    },

    yAxis: {
      title: {
        text: 'Average pushed price',
      },
    },

    xAxis: {
      title: 'Pushed date',
      type: 'datetime',
    },
    tooltip: {
      formatter: function () {
        return (
          new Date(this.x).toDateString() + '<br />' + this.series.name + ': ' + this.y
        );
      },
    },
  };

  get hasErrors() {
    return Object.keys(this.errors).length > 0;
  }

  validate() {
    this.errors = new Errors();

    if (!this.channelId) {
      this.errors.objectType = 'Channel ID is required';
    }
    if (!this.startDate) {
      this.errors.objectType = 'Start date required';
    }
    if (!this.endDate) {
      this.errors.objectType = 'End date required';
    }

    return Object.keys(this.errors).length == 0;
  }

  @action
  async setRange(value) {
    this.startDate = value.start;
    this.endDate = value.end;
  }

  @action
  async submit() {
    if (!this.validate()) {
      return;
    }
    this.saving = true;
    const url = `/api/queries/posted-prices`;
    const params = {
      channelId: this.channelId,
      startDate: this.startDate.format('YYYY-MM-DD'),
      endDate: this.endDate.format('YYYY-MM-DD'),
    };

    let response;
    try {
      response = await this.ajax._post(url, params);
    } catch (errors) {
      console.log(errors);
      let text = `Error: ${errors[0].title} - ${errors[0].source.message}`;
      this.responseData = text;
      return;
    } finally {
      this.saving = false;
    }
    this.responseData = response.pricePosted;
    this.chartData = [
      {
        name: 'Average pushed price',
        data: this.responseData.slice(1).map(row => {
          const minRow = row.slice(1).filter(r => !!r);
          return [
            Date.parse(row[0]),
            Math.round(
              minRow.reduce((a, b) => parseInt(a) + parseInt(b)) / minRow.length
            ),
          ];
        }),
      },
    ];
  }
}
