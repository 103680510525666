import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import EmberObject, { setProperties } from '@ember/object';
import logger from 'appkit/lib/logger';

export default class PopulateAccountService extends Service {
  @service ajax;
  @service router;
  @tracked syncingIsDone = true;
  @tracked collectedLogs = [];
  @tracked accountsInProcess = [];
  @tracked listingsSynced = 0;
  @tracked callbacks = [];

  start(accountId, channel) {
    let syncingStatus = 0;

    let status = EmberObject.create({
      progress: syncingStatus,
      message: 'Starting account listings sync.',
      syncingIsDone: false,
      logs: this.collectedLogs.filter(log => log.accountId == accountId),
    });

    this.accountsInProcess = this.accountsInProcess.concat({
      id: accountId,
      channel: channel,
      status: status,
    });

    let currentAccount = this.accountsInProcess
      .map(account => account.id)
      .indexOf(accountId);

    this.syncingIsDone = this.accountsInProcess
      .map(account => account.status.syncingIsDone)
      .every(el => el);

    let url = `/api/accounts/${accountId}/sync/force`;

    this.ajax
      .stream(url, {}, data => {
        if (data.message) {
          if (data.message.match(/^Syncing\slisting\s\d{7}$/)) {
            syncingStatus++;
            this.collectedLogs.pushObject({ accountId: accountId, log: data.message });
          }

          status = {
            progress: syncingStatus,
            message: `${data.message}...`,
            syncingIsDone: false,
            logs: this.collectedLogs.filter(log => log.accountId == accountId),
          };
        }

        setProperties(this.accountsInProcess[currentAccount], {
          status: status,
          message: status.message,
        });

        this.listingsSynced = this.accountsInProcess
          .map(account => account.status.progress)
          .reduce((a, b) => a + b, 0);
      })
      .then(
        () => {
          logger.info('Promise stream done', arguments);
          status = {
            ...status,
            message: 'Account listings sync is finished.',
            syncingIsDone: true,
          };

          setProperties(this.accountsInProcess[currentAccount], {
            status: status,
            message: status.message,
          });
          this.syncingIsDone = this.accountsInProcess
            .map(account => account.status.syncingIsDone)
            .every(el => el);

          if (!this.syncingIsDone) return;

          this.callbacks.forEach(cb => cb());
          this.callbacks = [];
        },
        err => {
          this.syncingIsDone = true;
          logger.error('Promise stream error', err);
          if (err.constructor === ProgressEvent) {
            this.render('error.timeout', { into: 'application' });
          } else {
            this.render('error.server', { into: 'application' });
          }
        }
      );
  }

  onFinish(callback) {
    if (callback in this.callbacks) return;
    this.callbacks = [...this.callbacks, callback];
  }

  preventFinishDetails(callback) {
    this.callbacks = this.callbacks.filter(cb => cb != callback);
  }
}
