import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import PAYMENT_METHOD from 'appkit/lib/payment-method';

export default class ForcePaymentMethodService extends Service {
  @service router;
  @service alert;
  @service featureFlag;
  @service intl;

  @tracked addPaymentMethodBeforeTrialStart = false;
  @tracked user = null;

  constructor() {
    super(...arguments);
    this.featureFlag
      .evaluate('add-payment-method-before-trial-start', false)
      .then(enabled => {
        this.addPaymentMethodBeforeTrialStart = enabled;
      });
  }

  setup(user) {
    this.user = user;
    this.user.paymentMethodExists = null;
  }

  userRequirements() {
    const paymentMethodNotPayPalOrAch = ![
      PAYMENT_METHOD.PAYPAL,
      PAYMENT_METHOD.ACH,
      PAYMENT_METHOD.CHECK,
      PAYMENT_METHOD.DIRECT_DEBIT,
    ].includes(this.user.defaultPaymentMethod);

    const noPrimaryPaymentMethodAvailable = !(
      this.user.primaryCardId || this.user.paymentMethodExists
    );

    return (
      paymentMethodNotPayPalOrAch &&
      noPrimaryPaymentMethodAvailable &&
      this.addPaymentMethodBeforeTrialStart
    );
  }

  redirect() {
    localStorage.setItem(
      'routeToReturnAfterCreditCard',
      JSON.stringify({
        route: this.router.currentURL,
      })
    );
    this.alert.info(this.intl.t('pricing.addCreditCardBeforeOnboardingCredit'), {
      timeout: 120000,
    });
    this.router.transitionTo('/dashboard/user/billing');
  }

  resume() {
    let backRoute = '/dashboard/user/billing';

    if (
      !this.user.trialStartDate &&
      !this.user.hasEnabledListings &&
      this.addPaymentMethodBeforeTrialStart
    ) {
      const route = localStorage.getItem('routeToReturnAfterCreditCard');
      if (route) {
        let parsedRoute = JSON.parse(route);
        backRoute = parsedRoute.route;
      }
    }

    this.user.paymentMethodExists = true;

    this.router.transitionTo(backRoute);

    if (backRoute !== '/dashboard/user/billing') {
      localStorage.removeItem('routeToReturnAfterCreditCard');
    }
  }
}
