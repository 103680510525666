import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Controller from '@ember/controller';
@classic
export default class MarketController extends Controller {
  clustersVisible = false;

  @action
  toggleVisible() {
    this.toggleProperty('clustersVisible');
    return false;
  }
}
