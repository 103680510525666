import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class PricingRoute extends AuthRoute {
  @service('-routing')
  router;

  @service intl;

  @service('global-nav')
  globalNav;

  title(tokens) {
    let title = null;
    if (!tokens || !tokens.length) {
      title = this.intl.t('glossary.dynamicPricing');
    } else {
      title = `${this.intl.t('glossary.dynamicPricing')} / ${tokens}`;
    }

    this.globalNav.setTitle(title);
    return title;
  }
}
