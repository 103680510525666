import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';
import { displayErrors } from 'appkit/lib/display_errors';

@classic
export default class SeasonalityRoute extends AuthRoute {
  @service
  alert;

  templateName = 'data.highcharts-graph';
  controllerName = 'data.seasonality';

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('customizable', true);

    let cluster = this.modelFor('data.cluster');
    controller.set(
      'pageTitle',
      `Seasonality -- ${cluster.get('market.name')} / ${cluster.get('name')}`
    );
    let url = `${location.origin}/admin/api/clusters/${cluster.get(
      'id'
    )}/airbnb/seasonality.csv?token=${this.currentUser.get('token')}`;
    this.controllerFor('data.cluster').set('csvUrl', url);
    let csvFilename = `airbnb-seasonality_${cluster.get('market.name')}_${cluster.get(
      'name'
    )}.csv`;
    this.controllerFor('data.cluster').set('csvFilename', csvFilename);
  }

  deactivate() {
    this.controllerFor('data.cluster').set('csvUrl', false);
  }

  model() {
    let id = this.modelFor('data.cluster').get('id');
    let url = `/admin/api/clusters/${id}/seasonality`;

    return this.ajax._get(url).then(data => {
      let out = {
        airbnb: [],
        homeaway: [],
        custom: [],
      };
      Object.keys(data)
        .sort()
        .forEach(key => {
          let month = Math.floor(key / 100);
          let day = key % 100;
          // JS months are 0 based, days are not.
          let date = Date.UTC(2016, month - 1, day);
          out.airbnb.push([date, 100 * data[key][0]]);
          out.homeaway.push([date, 100 * data[key][1]]);
          if (data[key].length > 2) {
            out.custom.push([date, 100 * data[key][2]]);
          }
        });

      return out;
    });
  }

  @action
  compareCluster(id) {
    this.controller.send('compareCluster', id);
  }

  @action
  compareMarket(id) {
    this.controller.send('compareMarket', id);
  }

  @action
  saveCustom(custom) {
    const alert = this.alert;
    let id = this.modelFor('data.cluster').get('id');
    let url = `/admin/api/clusters/${id}/seasonality`;

    let data = {
      custom: custom.map(row => row[1] / 100),
    };

    return this.ajax
      ._post(url, data)
      .then(() => {
        alert.success('Saved', { timeout: 10000 });
      })
      .catch(errors => {
        displayErrors({ errors: errors, alert: alert });
      });
  }
}
