// Store all query params into a `utm` cookie. If there is already a value in the
// cookie, move it into the `first` field so we can track first vs last attribution.
// This is useful so we can track utm query paramters from things like adwords, emails,
// campaigns, etc. This cookie is parsed and passed on signup.
//
// Usually we'd store something like this in localstorage, but we need subdomain support
// so we can move from beyondpricing.com to app.beyondpricing.com.
//
// This is stored on the user under tracking_params, but we call it utm here to minimize
// any potential issues with people thinking they're being tracked.
import moment from 'moment';

export default {
  name: 'tracking-params',

  initialize() {
    if (window.location.search.length < 2) {
      return;
    }

    const cookies = document.cookie
      .split(';')
      .filter(cookie => cookie)
      .reduce((cookies, row) => {
        const [key, value] = row.trim().split('=');
        cookies[key] = value;
        return cookies;
      }, {});

    const utm = {
      last: {
        stamp: moment().toISOString(),
      },
    };

    if (cookies.utm) {
      let parsed;
      try {
        parsed = JSON.parse(decodeURIComponent(cookies.utm));
      } catch {
        console.log('Error parsing cookie - resetting');
      }

      if (parsed?.first) {
        // We already have a first touch - keep it
        utm.first = parsed.first;
      } else if (parsed?.last) {
        // We have a previous last touch - move it to first
        utm.first = parsed.last;
      }
    }

    // Track everything new in utm.last
    let query = window.location.search.slice(1);
    for (let pair of query.split('&')) {
      let key = pair.split('=')[0];
      let value = pair.split('=')[1];
      key = decodeURIComponent(key);
      value = decodeURIComponent(value);
      utm.last[key] = value;
    }

    // Save back into cookies
    const cookieValue = encodeURIComponent(JSON.stringify(utm));
    const expiry = moment().add(1, 'years').toDate().toUTCString();
    document.cookie = `utm=${cookieValue}; domain=.beyondpricing.com; path=/; expires=${expiry}; SameSite=lax`;

    // Remove query params. Should we do this?
    // let url = window.location.pathname;
    // history.replaceState('', document.title, url);
  },
};
