import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

@classic
export default class ChannelController extends Controller {
  queryParams = ['id', 'idSecondary', 'code', 'context'];
  id = null;
  idSecondary = null;

  // Query param sent by Homeaway for the oauth code
  // TODO: review to have maybe a subroute because we can't add all the
  // query params required for every channels here, it would be a mess.
  // For now, it's ok because we have only for Homeaway.
  code = null;

  @tracked product = null;

  // Gather the query params
  @computed('code')
  get extraParams() {
    let params = {};
    let keys = ['code'];
    for (let k of keys) {
      params[k] = this.get(k);
    }
    return params;
  }

  @action
  accountAdded(accountId) {
    this.transitionToRoute('dashboard.populate.account', accountId);
    return false;
  }
}
