import BaseModel from 'appkit/bp-models/base_model';
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';

export default BaseModel.extend({
  init() {
    this._super(arguments);
    this._EDITABLE = ['days', 'percentage', 'ignore_on_weekdays'];
  },

  url: computed('listingId', 'id', function () {
    return `/v1/listings/${this.listingId}/orphan_days/${this.id || ''}`;
  }),

  id: null,
  days: 0,
  percentage: 0,
  listingId: 0,
  ignoreOnWeekdays: null,
  isDecrease: true,
  isIncrease: not('isDecrease'),

  error: '',

  validate() {
    const percentage = parseFloat(this.percentage);
    const days = parseFloat(this.days);

    if (percentage === 0 || !percentage || Number.isNaN(percentage)) {
      this.set('error', 'Please choose a value for percentage');
      return false;
    }

    if (days === 0 && percentage === 0) {
      // Ignore new lines
      return true;
    }

    if (days === 0 || !days || Number.isNaN(days)) {
      this.set('error', 'Please choose a value for days length');
      return false;
    }

    if (percentage <= -1.0) {
      this.set('error', 'Cannot have a decrease in price of 100% or more');
      return false;
    }

    return true;
  },
});
