// TODO: remove this file once production db copy is avairable
// this data is from:
// https://beyondpricing.com/api/quote/search?startDate=2019-03-01&endDate=2019-03-07&lat=37.778718&lng=-122.412460
export const FAKE_QUOTE_ENGINE_RESULT = {
  errors: [],
  listings: [
    {
      id: 831,
      title: 'Loft in SOMA! Walk to Moscone!',
      image:
        'https://a0.muscache.com/im/pictures/34806591/353dd545_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.50',
      base_price: 110,
      base_price_updated_at: '2015-11-27T20:48:50.529510+00:00',
      base_price_history: {
        '2014-12-03T10:15:11.534341+00:00': 125.0,
        '2014-12-24T17:53:39.868039+00:00': 100.0,
        '2015-04-06T06:08:11.931530+00:00': 110.0,
        '2015-05-04T08:13:48.824014+00:00': 100.0,
        '2015-06-03T21:46:29.719738+00:00': 120.0,
        '2015-11-03T17:41:53.016343+00:00': 110.0,
        '2015-11-18T18:10:18.382683+00:00': 120.0,
        '2015-11-27T20:48:50.529510+00:00': 110.0,
      },
      min_price: 75,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77762600',
      longitude: '-122.40230600',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '950 Harrison Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1511,
      channel_listings_ids: [1511],
      price: null,
    },
    {
      id: 1072,
      title: 'Jackson Square 1BR w/striking views',
      image:
        'https://a0.muscache.com/im/pictures/7470177/c08ae066_original.jpg?aki_policy=large',
      neighborhood: 'Financial District',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 287,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 143,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79636400',
      longitude: '-122.40050500',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '550 Battery St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1868,
      channel_listings_ids: [1868],
      price: null,
    },
    {
      id: 1156,
      title: 'Large comfortable downtown room',
      image:
        'https://a0.muscache.com/im/pictures/23268715/633aaa07_original.jpg?aki_policy=large',
      neighborhood: 'Downtown',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 110,
      base_price_updated_at: '2014-12-03T10:21:52.340646+00:00',
      base_price_history: { '2014-12-03T10:21:52.340646+00:00': 110.0 },
      min_price: 95,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 4,
      booked_thirty: 0,
      booked_ninety: 1,
      latitude: '37.78802100',
      longitude: '-122.41379500',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '729 Jones Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 2042,
      channel_listings_ids: [2042],
      price: null,
    },
    {
      id: 1292,
      title: 'Co-working space: work at Airbnb HQ',
      image:
        'https://a0.muscache.com/im/pictures/588467/75eb7f5e_original.jpg?aki_policy=large',
      neighborhood: 'Soma',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 24,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 12,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77464700',
      longitude: '-122.41532900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '142 10th St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 2296,
      channel_listings_ids: [2296],
      price: null,
    },
    {
      id: 1536,
      title: 'Lively and Convenient SOMA',
      image:
        'https://a0.muscache.com/im/pictures/195696/e6111c14_original.jpg?aki_policy=large',
      neighborhood: 'Soma',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 110,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 55,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77504700',
      longitude: '-122.41116300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '724 Clementina St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 2667,
      channel_listings_ids: [2667],
      price: null,
    },
    {
      id: 1537,
      title: 'Private 1bd Downtown SoMa Apartment',
      image:
        'https://a0.muscache.com/im/pictures/386147/cfee8b26_original.jpg?aki_policy=large',
      neighborhood: 'Soma',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 136,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 68,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77504700',
      longitude: '-122.41116300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '724 Clementina St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 2668,
      channel_listings_ids: [2668],
      price: null,
    },
    {
      id: 1538,
      title: 'SoMa Edwardian near Civic Center',
      image:
        'https://a0.muscache.com/im/pictures/4694546/eacf501f_original.jpg?aki_policy=large',
      neighborhood: 'Soma',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 232,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 116,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77505100',
      longitude: '-122.41111800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '726 Clementina St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 2669,
      channel_listings_ids: [2669],
      price: null,
    },
    {
      id: 2503,
      title: 'Luxury Loft in SOMA',
      image:
        'https://a0.muscache.com/im/pictures/52565021/cc4dacc3_original.jpg?aki_policy=large',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 243,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 121,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78632000',
      longitude: '-122.39465300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '56 Guy Place',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 4112,
      channel_listings_ids: [4112],
      price: null,
    },
    {
      id: 2555,
      title: 'Awesome Location - Master Bedroom',
      image:
        'https://a0.muscache.com/im/pictures/55622675/4a792831_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 135,
      base_price_updated_at: '2015-02-10T15:38:30.896150+00:00',
      base_price_history: {
        '2015-01-29T23:53:37.936897+00:00': 129.0,
        '2015-01-29T23:56:43.501695+00:00': 154.0,
        '2015-01-30T00:01:46.542170+00:00': 149.0,
        '2015-02-07T20:22:49.309896+00:00': 144.0,
        '2015-02-10T15:38:30.896150+00:00': 135.0,
      },
      min_price: 115,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77607700',
      longitude: '-122.41754200',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '8 10th Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 4164,
      channel_listings_ids: [4164],
      price: null,
    },
    {
      id: 2556,
      title: 'Modern and Chic Loft',
      image:
        'https://a0.muscache.com/im/pictures/47821397/36f84a0c_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '2.00',
      base_price: 225,
      base_price_updated_at: '2014-11-24T10:04:55.175338+00:00',
      base_price_history: { '2014-11-24T10:04:55.175338+00:00': 225.0 },
      min_price: 215,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77354800',
      longitude: '-122.39904000',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 4165,
      channel_listings_ids: [4165],
      price: null,
    },
    {
      id: 2724,
      title: 'Share a sofa with our cat!',
      image:
        'https://a0.muscache.com/im/pictures/56899403/8d6b8701_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Shared room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77703500',
      longitude: '-122.41526000',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '55 9th Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 2943,
      title: 'Clean, Private Room in SOMA',
      image:
        'https://a0.muscache.com/im/pictures/58394813/af43035a_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 121,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 60,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77582600',
      longitude: '-122.41239900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '1242 Howard Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 4561,
      channel_listings_ids: [4561],
      price: null,
    },
    {
      id: 2944,
      title: 'Private Room w/ Private Bath - SOMA',
      image:
        'https://a0.muscache.com/im/pictures/58398303/1a9fc41a_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 104,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 52,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77582600',
      longitude: '-122.41239900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '1242 Howard Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 4562,
      channel_listings_ids: [4562],
      price: null,
    },
    {
      id: 3160,
      title: 'Spacious room in a unique Loft',
      image:
        'https://a0.muscache.com/im/pictures/49827031/5df2ca37_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '2.00',
      base_price: 148,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 74,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77657700',
      longitude: '-122.40785200',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '123 Langton St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 4779,
      channel_listings_ids: [4779],
      price: null,
    },
    {
      id: 3161,
      title: 'Sunny room in a unique space',
      image:
        'https://a0.muscache.com/im/pictures/46424016/6ae28fc6_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '2.00',
      base_price: 148,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 74,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77610400',
      longitude: '-122.40785200',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '123 Langton Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 4780,
      channel_listings_ids: [4780],
      price: null,
    },
    {
      id: 5310,
      title: 'Great 2bd 2 bath waterfront condo',
      image:
        'https://a0.muscache.com/im/pictures/27885219/c04bd86f_original.jpg?aki_policy=large',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 203,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 101,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78955800',
      longitude: '-122.39059400',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '338 Spear Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 6929,
      channel_listings_ids: [6929],
      price: null,
    },
    {
      id: 5908,
      title: 'Luxury Condo, Great View, close to Moscone Center',
      image:
        'https://a0.muscache.com/im/pictures/69892860/0ae71a2f_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 218,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 109,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77877400',
      longitude: '-122.41148400',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '1160 Mission Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 7527,
      channel_listings_ids: [7527],
      price: null,
    },
    {
      id: 6855,
      title: 'Private room for Airfam near HQ',
      image:
        'https://a0.muscache.com/im/pictures/69124483/2cce9f83_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 197,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 98,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77703500',
      longitude: '-122.41526000',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '55 9th Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 8474,
      channel_listings_ids: [8474],
      price: null,
    },
    {
      id: 6991,
      title: '4-Level Townhome near Moscone',
      image:
        'https://a0.muscache.com/im/pictures/25729813/bc4d1349_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '1.00',
      base_price: 600,
      base_price_updated_at: '2015-10-08T19:26:43.135752+00:00',
      base_price_history: { '2015-10-08T19:26:43.135752+00:00': 600.0 },
      min_price: 420,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78002500',
      longitude: '-122.40237400',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '4 Jennifer Place',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 8610,
      channel_listings_ids: [8610],
      price: null,
    },
    {
      id: 6992,
      title: ':::Huge:City Views:Modern Luxury:::',
      image:
        'https://a0.muscache.com/im/pictures/12698217/deb9c493_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 431,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 215,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77777500',
      longitude: '-122.39334900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '250 King Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 8611,
      channel_listings_ids: [8611],
      price: null,
    },
    {
      id: 6993,
      title: 'Modern SOMA Luxury w/ City Views ',
      image:
        'https://a0.muscache.com/im/pictures/11145353/e73722df_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 154,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 77,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77776700',
      longitude: '-122.39334900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '250 King St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 8612,
      channel_listings_ids: [8612],
      price: null,
    },
    {
      id: 7671,
      title: 'Open Loft in Downtown San Francisco',
      image:
        'https://a0.muscache.com/im/pictures/10379303/5ae28b0b_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 606,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 303,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77753100',
      longitude: '-122.39765900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '548 Brannan St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 9290,
      channel_listings_ids: [9290],
      price: null,
    },
    {
      id: 7672,
      title: 'Private Room in Amazing SOMA Loft',
      image:
        'https://a0.muscache.com/im/pictures/10381060/2c32270b_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 110,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 55,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77753100',
      longitude: '-122.39765900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '548 Brannan St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 9291,
      channel_listings_ids: [9291],
      price: null,
    },
    {
      id: 7844,
      title: 'Bedroom in SOMA District',
      image:
        'https://a0.muscache.com/im/pictures/66444243/7cf9c0b6_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 150,
      base_price_updated_at: '2015-03-27T19:02:38.917721+00:00',
      base_price_history: { '2015-03-27T19:02:38.917721+00:00': 150.0 },
      min_price: 120,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77703500',
      longitude: '-122.41526000',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '55 9th Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 9463,
      channel_listings_ids: [9463],
      price: null,
    },
    {
      id: 8380,
      title: 'Splendid Room on Lombard',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '2.00',
      base_price: 19,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 9,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 9999,
      channel_listings_ids: [9999],
      price: null,
    },
    {
      id: 8483,
      title: 'Delightful Room on Lombard ',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '2.00',
      base_price: 19,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 9,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 10102,
      channel_listings_ids: [10102],
      price: null,
    },
    {
      id: 9629,
      title: 'Spare Bedroom in SoMa Loft',
      image:
        'https://a0.muscache.com/im/pictures/78713728/f1f847f4_original.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 734,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 367,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77660400',
      longitude: '-122.39671300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '53 Bluxome Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 11248,
      channel_listings_ids: [11248],
      price: null,
    },
    {
      id: 10936,
      title: 'Amazing Ocean View 1BD Apt, 20th Fl',
      image:
        'https://a0.muscache.com/im/pictures/80035857/b72afcb9_original.jpg?aki_policy=large',
      neighborhood: 'Financial District',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 12,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 6,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 5,
      booked_thirty: 0,
      booked_ninety: 2,
      latitude: '37.79650900',
      longitude: '-122.39727800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '460 Davis Ct',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 12555,
      channel_listings_ids: [12555],
      price: null,
    },
    {
      id: 11304,
      title: 'Amazing Embarcadero 1Bd view',
      image:
        'https://a0.muscache.com/im/pictures/84974321/45a4df9e_original.jpg?aki_policy=large',
      neighborhood: 'Financial District',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 212,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 106,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79650900',
      longitude: '-122.39727800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '460 Davis Ct',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 12923,
      channel_listings_ids: [12923],
      price: null,
    },
    {
      id: 13175,
      title: 'Spark',
      image:
        'https://a0.muscache.com/im/pictures/93542750/3dfd330a_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 0,
      bathrooms: null,
      base_price: 98,
      base_price_updated_at: '2015-07-16T08:09:29.325028+00:00',
      base_price_history: { '2015-07-16T08:09:29.325028+00:00': 98.0 },
      min_price: 68,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77696200',
      longitude: '-122.41422300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '1254 Mission Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 14794,
      channel_listings_ids: [14794],
      price: null,
    },
    {
      id: 14491,
      title: 'Spacious Loft in heart of the City',
      image:
        'https://a0.muscache.com/im/pictures/105309369/66261b15_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '2.50',
      base_price: 210,
      base_price_updated_at: '2015-08-16T20:37:13.263342+00:00',
      base_price_history: {
        '2015-08-16T17:23:19.794392+00:00': 250.0,
        '2015-08-16T20:37:13.263342+00:00': 210.0,
      },
      min_price: 200,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 5,
      booked_thirty: 0,
      booked_ninety: 2,
      latitude: '37.77844600',
      longitude: '-122.40398400',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '260 Clara Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 16111,
      channel_listings_ids: [16111],
      price: null,
    },
    {
      id: 14870,
      title: 'Fabulous South Park Studio',
      image:
        'https://a0.muscache.com/im/pictures/102600741/69173791_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 0,
      bathrooms: '1.00',
      base_price: 250,
      base_price_updated_at: '2016-01-12T22:57:35.013832+00:00',
      base_price_history: {
        '2015-08-25T21:26:04.949396+00:00': 250.0,
        '2015-09-20T06:08:29.079226+00:00': 225.0,
        '2015-12-07T22:00:18.304531+00:00': 199.0,
        '2016-01-06T19:20:03.134419+00:00': 225.0,
        '2016-01-12T22:57:35.013832+00:00': 250.0,
      },
      min_price: 175,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78457300',
      longitude: '-122.39553100',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '2nd Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 16490,
      channel_listings_ids: [16490],
      price: null,
    },
    {
      id: 17470,
      title: 'Chic South Beach 1 BR / 1 Private Bath',
      image:
        'https://a0.muscache.com/im/pictures/9ea06f16-f838-4027-b43e-9bbcc3b40d42.jpg?aki_policy=large',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 205,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 102,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 4,
      booked_thirty: 0,
      booked_ninety: 1,
      latitude: '37.78605700',
      longitude: '-122.39189100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '50 Lansing Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 19090,
      channel_listings_ids: [19090],
      price: null,
    },
    {
      id: 17905,
      title: 'Modern Room in Elegant Apartment',
      image:
        'https://a0.muscache.com/im/pictures/4168be86-430d-40c0-9aae-477703262bad.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 200,
      base_price_updated_at: '2015-10-22T19:12:09.166836+00:00',
      base_price_history: {
        '2015-10-22T19:10:42.675251+00:00': 76.0,
        '2015-10-22T19:12:09.166836+00:00': 200.0,
      },
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78070800',
      longitude: '-122.40399200',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '400 Clementina Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 19525,
      channel_listings_ids: [19525],
      price: null,
    },
    {
      id: 19138,
      title: 'Sunny 2 bedroom rental in SoMa SF!',
      image:
        'https://a0.muscache.com/im/pictures/baa744e1-64e3-4e7e-abb8-2fa197ed371d.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 183,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 91,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 4,
      booked_thirty: 0,
      booked_ninety: 1,
      latitude: '37.77652000',
      longitude: '-122.39405100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '300 King Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 20758,
      channel_listings_ids: [20758],
      price: null,
    },
    {
      id: 19139,
      title: '1 bedroom luxury corporate rental',
      image:
        'https://a0.muscache.com/im/pictures/05a4cda6-76c8-4638-b4ed-e1a2e532177c.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 196,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 98,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 4,
      booked_thirty: 0,
      booked_ninety: 1,
      latitude: '37.77506300',
      longitude: '-122.40100100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '300 King Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 20759,
      channel_listings_ids: [20759],
      price: null,
    },
    {
      id: 23728,
      title: 'China Basin 1 BR Amazing City Views',
      image:
        'https://a0.muscache.com/im/pictures/9fb3296a-7256-46e1-bcf8-cb527d073462.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 655,
      base_price_updated_at: '2016-01-18T19:59:28.780735+00:00',
      base_price_history: { '2016-01-18T19:59:28.780735+00:00': 655.0 },
      min_price: 327,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77645500',
      longitude: '-122.39329500',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '255 King Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 25348,
      channel_listings_ids: [25348],
      price: null,
    },
    {
      id: 24744,
      title: 'NEW 1br DOWNTOWN HIGHRISE POOL/GYM',
      image:
        'https://a0.muscache.com/im/pictures/926e38a3-a1cf-4074-ac18-f0f64e01f46d.jpg?aki_policy=large',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 306,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 153,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78561400',
      longitude: '-122.39363100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '514 Harrison Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 26364,
      channel_listings_ids: [26364],
      price: null,
    },
    {
      id: 24745,
      title: 'Brand new modern 1bd SOMA Parking',
      image:
        'https://a0.muscache.com/im/pictures/103432179/099a2a39_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 275,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 137,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78006400',
      longitude: '-122.40408300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '900 Folsom St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 26365,
      channel_listings_ids: [26365],
      price: null,
    },
    {
      id: 25228,
      title: 'South Beach Stunning City Location',
      image:
        'https://a0.muscache.com/im/pictures/3af2d821-31d8-4322-bcf4-fc425ebcbed2.jpg?aki_policy=large',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: '2016-03-16T23:59:36.919957+00:00',
      base_price_history: {
        '2016-01-30T05:34:38.504712+00:00': 295.0,
        '2016-01-30T05:37:16.261636+00:00': 250.0,
        '2016-01-30T08:12:59.724206+00:00': 299.0,
        '2016-01-30T19:13:43.891735+00:00': 285.0,
        '2016-01-31T01:01:57.782747+00:00': 300.0,
        '2016-02-12T15:41:45.845000+00:00': 225.0,
        '2016-02-13T07:37:31.565308+00:00': 200.0,
        '2016-02-15T16:25:23.499435+00:00': 190.0,
        '2016-02-20T00:12:41.336565+00:00': 225.0,
        '2016-03-13T16:05:45.188426+00:00': 205.0,
        '2016-03-16T23:59:36.919957+00:00': 200.0,
      },
      min_price: 167,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78767400',
      longitude: '-122.39270000',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '333 Fremont Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 26848,
      channel_listings_ids: [26848],
      price: null,
    },
    {
      id: 25608,
      title: 'Test: SF baby',
      image:
        'https://a0.muscache.com/im/pictures/3442f69e-f12b-4b5b-acbf-3fc43cecae14.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 202,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 101,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77212100',
      longitude: '-122.40529600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '888 Brannan Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 27228,
      channel_listings_ids: [27228],
      price: null,
    },
    {
      id: 25620,
      title: 'Beautiful 1 Bedroom in Fin District',
      image:
        'https://a0.muscache.com/im/pictures/106173882/160f4a71_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 314,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 157,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78993600',
      longitude: '-122.39896400',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '16',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 27240,
      channel_listings_ids: [27240],
      price: null,
    },
    {
      id: 25640,
      title: 'Contemporary Comfort -heart of FiDi',
      image:
        'https://a0.muscache.com/im/pictures/103260623/4328b3f5_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 371,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 185,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78660600',
      longitude: '-122.39910100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '199 New Montgomery Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 27260,
      channel_listings_ids: [27260],
      price: null,
    },
    {
      id: 25641,
      title: 'Spacious 1 bedroom with Loft Office',
      image:
        'https://a0.muscache.com/im/pictures/59243292/3df392b6_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 277,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 138,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77115200',
      longitude: '-122.42205800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '95 McCoppin Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 27261,
      channel_listings_ids: [27261],
      price: null,
    },
    {
      id: 25645,
      title: 'South Beach Loft with Parking',
      image:
        'https://a0.muscache.com/im/pictures/103382495/fbb86d8a_original.jpg?aki_policy=large',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 372,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 186,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78840600',
      longitude: '-122.38927500',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '400 Spear Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 27265,
      channel_listings_ids: [27265],
      price: null,
    },
    {
      id: 25651,
      title: 'Stare in Awe at the SF Skyline',
      image:
        'https://a0.muscache.com/im/pictures/86448626/a45f3878_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 311,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 155,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78660600',
      longitude: '-122.39910100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '199 New Montgomery Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 27271,
      channel_listings_ids: [27271],
      price: null,
    },
    {
      id: 25653,
      title: 'Location!! Executive Penthouse',
      image:
        'https://a0.muscache.com/im/pictures/107127202/03b976e1_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 618,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 309,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78660600',
      longitude: '-122.39910100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '199 New Montgomery Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 27273,
      channel_listings_ids: [27273],
      price: null,
    },
    {
      id: 29634,
      title: "It's a great place!",
      image:
        'https://a0.muscache.com/im/pictures/9294c27b-6f98-412a-9bdb-1174aa91d82e.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 3,
      bathrooms: null,
      base_price: 88,
      base_price_updated_at: '2016-02-26T21:00:35.121827+00:00',
      base_price_history: { '2016-02-26T21:00:35.121827+00:00': 88.0 },
      min_price: 44,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79176300',
      longitude: '-122.39434100',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '123 Main Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 31254,
      channel_listings_ids: [31254],
      price: null,
    },
    {
      id: 30991,
      title: 'Newly Remodeled Loft Lower Nob Hill',
      image:
        'https://a0.muscache.com/im/pictures/2626417d-d525-4fdc-9c87-36b15a3f1d5d.jpg?aki_policy=large',
      neighborhood: 'Downtown',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 666,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 333,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 82,
      booked_thirty: 96,
      booked_ninety: 37,
      latitude: '37.78779200',
      longitude: '-122.42071500',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '1232 Sutter Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 32611,
      channel_listings_ids: [32611],
      price: '424.00',
    },
    {
      id: 32516,
      title: 'SOMA Executive Penthouse Living 2BR',
      image:
        'https://a0.muscache.com/im/pictures/99619091/d5bf8fd2_original.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 812,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 406,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77811800',
      longitude: '-122.40340400',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '960 Harrison Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 34136,
      channel_listings_ids: [34136],
      price: null,
    },
    {
      id: 32975,
      title: 'Kennett Place',
      image:
        'https://a0.muscache.com/im/pictures/25627708/12db145f_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 562,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 281,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77822100',
      longitude: '-122.40305300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '950 Harrison Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 34595,
      channel_listings_ids: [34595],
      price: null,
    },
    {
      id: 36332,
      title: 'Executive Living in Modern SoMa 2BR',
      image:
        'https://a0.muscache.com/im/pictures/4c81ae68-732e-4096-9a01-dcb9b4fe75e7.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 399,
      base_price_updated_at: '2016-04-01T15:54:57.394565+00:00',
      base_price_history: { '2016-04-01T15:54:57.394565+00:00': 399.0 },
      min_price: 249,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77737000',
      longitude: '-122.40404500',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '960 Harrison Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 37952,
      channel_listings_ids: [37952],
      price: null,
    },
    {
      id: 43719,
      title: 'Test Listing',
      image:
        'https://a0.muscache.com/im/pictures/368b9333-e718-457f-aa89-2fb06cc66595.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 0,
      bathrooms: '1.00',
      base_price: 160,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 80,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 4,
      booked_thirty: 0,
      booked_ninety: 1,
      latitude: '37.78205500',
      longitude: '-122.40473900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '888 Howard Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 45340,
      channel_listings_ids: [45340],
      price: null,
    },
    {
      id: 47393,
      title: 'Test Unit 1',
      image: 'http://images.ciirus.com/properties/38635/108315/images/ccpdemo1.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'USA',
      room_type: 'Apartment',
      bedrooms: 3,
      bathrooms: '2.00',
      base_price: 400,
      base_price_updated_at: '2016-10-26T21:51:31.881189+00:00',
      base_price_history: { '2016-10-26T21:51:31.881189+00:00': 400.0 },
      min_price: 300,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77574800',
      longitude: '-122.40256800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '500 6th St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 52388,
      title: 'TEST DO NOT CONTACT',
      image:
        'https://a0.muscache.com/im/pictures/4badfce2-b717-4dc6-9cce-9a846f773fec.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 280,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 140,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77212100',
      longitude: '-122.40529600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '888 Brannan Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 54044,
      channel_listings_ids: [54044],
      price: null,
    },
    {
      id: 56337,
      title: 'Next to Moscone Center: Modern Downtown Living',
      image:
        'https://a0.muscache.com/im/pictures/915f4f54-1d30-4abd-9574-0f90264fec6e.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Shared room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 130,
      base_price_updated_at: '2016-11-28T02:32:48.643564+00:00',
      base_price_history: { '2016-11-28T02:32:48.643564+00:00': 130.0 },
      min_price: 85,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 4,
      booked_thirty: 0,
      booked_ninety: 1,
      latitude: '37.78402700',
      longitude: '-122.39839900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '1 Saint Francis Place',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 58004,
      channel_listings_ids: [58004],
      price: null,
    },
    {
      id: 56338,
      title: 'Next to Moscone Center: Large Private Room',
      image:
        'https://a0.muscache.com/im/pictures/1849d4ad-19d6-430b-8e55-99f4c8e60f00.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 170,
      base_price_updated_at: '2016-11-28T02:34:02.784827+00:00',
      base_price_history: { '2016-11-28T02:34:02.784827+00:00': 170.0 },
      min_price: 125,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78397800',
      longitude: '-122.39856700',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '1 Saint Francis Place',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 58005,
      channel_listings_ids: [58005],
      price: null,
    },
    {
      id: 57167,
      title: '2 BR 2BA condo with great view of city',
      image:
        'https://a0.muscache.com/im/pictures/47629d1f-3a64-4f56-ade8-c57991856b0d.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 325,
      base_price_updated_at: '2017-12-08T17:23:41.384960+00:00',
      base_price_history: {
        '2016-09-21T04:27:35.952687+00:00': 500.0,
        '2016-09-21T04:32:01.135770+00:00': 450.0,
        '2016-09-21T06:06:13.419721+00:00': 425.0,
        '2016-09-22T18:22:16.963440+00:00': 400.0,
        '2016-11-03T22:46:26.749739+00:00': 350.0,
        '2016-11-07T18:41:01.673861+00:00': 375.0,
        '2016-11-08T07:43:13.006363+00:00': 350.0,
        '2016-11-12T15:16:05.447447+00:00': 300.0,
        '2016-11-15T15:55:17.606255+00:00': 325.0,
        '2016-11-18T16:18:35.592473+00:00': 300.0,
        '2016-11-29T14:57:38.661210+00:00': 325.0,
        '2016-11-29T14:58:57.532876+00:00': 300.0,
        '2016-12-19T23:15:37.694805+00:00': 325.0,
        '2017-01-26T18:44:44.900606+00:00': 300.0,
        '2017-03-31T01:51:49.344275+00:00': 275.0,
        '2017-03-31T01:52:22.570625+00:00': 300.0,
        '2017-05-02T16:18:20.740806+00:00': 250.0,
        '2017-05-02T16:19:17.235038+00:00': 225.0,
        '2017-05-08T16:06:33.529951+00:00': 250.0,
        '2017-05-08T17:01:30.726120+00:00': 225.0,
        '2017-07-31T16:13:13.907960+00:00': 200.0,
        '2017-08-01T16:17:04.376207+00:00': 225.0,
        '2017-08-01T16:17:38.252127+00:00': 250.0,
        '2017-08-05T05:47:12.745572+00:00': 300.0,
        '2017-09-18T04:35:08.521309+00:00': 325.0,
        '2017-10-29T17:12:23.651240+00:00': 300.0,
        '2017-11-16T16:05:50.484718+00:00': 325.0,
        '2017-11-16T16:12:06.962733+00:00': 350.0,
        '2017-12-08T17:18:55.705144+00:00': 300.0,
        '2017-12-08T17:23:41.384960+00:00': 325.0,
      },
      min_price: 300,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 86,
      booked_thirty: 76,
      booked_ninety: 25,
      latitude: '37.77528000',
      longitude: '-122.41144600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '725 Tehama Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 57168,
      title: 'Master bedroom w/ in-suite bathroom',
      image:
        'https://a0.muscache.com/im/pictures/107889277/8b7d8e78_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 175,
      base_price_updated_at: '2017-10-01T14:44:34.274391+00:00',
      base_price_history: {
        '2016-09-21T04:10:49.862209+00:00': 250.0,
        '2016-09-21T14:39:08.920069+00:00': 225.0,
        '2016-10-28T00:27:52.667045+00:00': 200.0,
        '2016-10-28T00:28:20.940966+00:00': 225.0,
        '2016-11-03T15:00:40.617089+00:00': 200.0,
        '2016-11-26T18:27:02.145015+00:00': 225.0,
        '2017-05-09T14:35:57.971044+00:00': 175.0,
        '2017-05-09T15:43:47.616364+00:00': 150.0,
        '2017-05-09T15:44:17.090065+00:00': 175.0,
        '2017-05-10T17:46:12.698501+00:00': 160.0,
        '2017-05-10T17:46:29.855330+00:00': 155.0,
        '2017-05-10T17:47:44.564431+00:00': 150.0,
        '2017-08-05T05:48:04.105550+00:00': 200.0,
        '2017-08-05T05:52:46.751496+00:00': 175.0,
        '2017-08-05T16:30:28.233900+00:00': 150.0,
        '2017-09-18T04:34:14.762212+00:00': 200.0,
        '2017-10-01T14:44:34.274391+00:00': 175.0,
      },
      min_price: 175,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 87,
      booked_thirty: 76,
      booked_ninety: 26,
      latitude: '37.77528000',
      longitude: '-122.41144600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '725 Tehama Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 57169,
      title: 'Private room & bath in sunny SOMA',
      image:
        'https://a0.muscache.com/im/pictures/107887400/2788ed13_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 130,
      base_price_updated_at: '2016-09-21T18:48:21.368134+00:00',
      base_price_history: { '2016-09-21T18:48:21.368134+00:00': 130.0 },
      min_price: 125,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77528000',
      longitude: '-122.41144600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '725 Tehama Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 70564,
      title: 'Vacation Rental Frances',
      image:
        'https://res-2.cloudinary.com/bookingsync/image/upload/c_fill,f_auto,fl_progressive,g_center,h_1024,q_90,w_1536/v1480628376/normal_1e4bc47e23f7e29ed2c23d12e58ad5f2_k2s_idr2ex.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: null,
      country: 'US',
      room_type: 'villa',
      bedrooms: 0,
      bathrooms: '1.00',
      base_price: 121,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 60,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79433860',
      longitude: '-122.39504570',
      currency: 'EUR',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '42 Market street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 72237,
      channel_listings_ids: [72237],
      price: null,
    },
    {
      id: 81235,
      title: 'Kelsoma Modern 2br / 2ba In Soma',
      image:
        'https://imagesus-ssl.homeaway.com/mda01/39bd04b6-d4a1-4404-8a0a-eab7c9615c4d.1.10',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 3000,
      base_price_updated_at: '2017-07-20T06:34:48.039691+00:00',
      base_price_history: { '2017-07-20T06:34:48.039691+00:00': 3000.0 },
      min_price: 835,
      max_price: null,
      min_stay: 30,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77777260',
      longitude: '-122.40688000',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 82908,
      channel_listings_ids: [82908],
      price: null,
    },
    {
      id: 48,
      title: 'KelSoMa 1 Bed & Bath in SoMa',
      image:
        'https://a0.muscache.com/im/pictures/51350567/0e2f6281_original.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Shared room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 65000,
      base_price_updated_at: '2019-01-07T17:53:57.467659+00:00',
      base_price_history: {
        '2014-12-03T10:13:40.990901+00:00': 125.0,
        '2014-12-03T12:09:48.857618+00:00': 160.0,
        '2014-12-18T01:46:46.149984+00:00': 180.0,
        '2015-07-16T01:42:53.776612+00:00': 500.0,
        '2015-10-29T00:07:42.399580+00:00': 200.0,
        '2016-03-10T19:49:03.171422+00:00': 600.0,
        '2016-03-23T08:09:08.382034+00:00': 800.0,
        '2016-03-24T21:45:28.540157+00:00': 900.0,
        '2016-03-26T02:45:39.623267+00:00': 905.0,
        '2016-06-29T21:44:29.651951+00:00': 1000.0,
        '2016-07-06T05:17:56.082112+00:00': 1100.0,
        '2016-07-06T18:16:23.116863+00:00': 1200.0,
        '2016-07-31T18:17:47.441981+00:00': 1500.0,
        '2016-08-11T07:34:16.464739+00:00': 2000.0,
        '2016-10-11T02:30:24.596077+00:00': 17.32,
        '2016-10-11T17:35:52.191763+00:00': 2000.0,
        '2016-11-08T05:57:05.073364+00:00': 2200.0,
        '2016-11-09T08:41:22.576575+00:00': 3000.0,
        '2016-11-11T02:35:18.945923+00:00': 3001.0,
        '2016-11-17T02:06:36.550493+00:00': 3003.0,
        '2016-12-21T23:25:49.778934+00:00': 2000.0,
        '2018-05-20T20:13:17.231986+00:00': 51362.746716,
        '2019-01-06T06:19:40.129229+00:00': 60000.0,
        '2019-01-07T17:53:57.467659+00:00': 65000.0,
      },
      min_price: 46226,
      max_price: 3000000,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 6,
      booked_thirty: 0,
      booked_ninety: 3,
      latitude: '37.77913300',
      longitude: '-122.40911100',
      currency: 'CZK',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '191 Russ Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1000110,
      channel_listings_ids: [1000110],
      price: null,
    },
    {
      id: 146,
      title: 'Luxury Waterfront Apartment w/Views',
      image:
        'https://a0.muscache.com/im/pictures/38149003/0aa5dfe3_original.jpg?aki_policy=large',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 0,
      bathrooms: '1.00',
      base_price: 395,
      base_price_updated_at: '2014-11-04T07:12:57.748836+00:00',
      base_price_history: { '2014-11-04T07:12:57.748836+00:00': 395.0 },
      min_price: 197,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79174400',
      longitude: '-122.39219700',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '88 Howard Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1000247,
      channel_listings_ids: [1000247],
      price: null,
    },
    {
      id: 314461,
      title: 'Persevering context-sensitive moderator',
      image: '/assets/hero/hero-noe-3.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'Oregon',
      country: 'United States',
      room_type: null,
      bedrooms: 2,
      bathrooms: '5.00',
      base_price: 130,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 65,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79403400',
      longitude: '-122.39510100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '3800 James Radial Suite 149',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1327049,
      channel_listings_ids: [1000975, 1327049],
      price: null,
    },
    {
      id: 708,
      title: 'Get Fit!',
      image:
        'https://a0.muscache.com/im/pictures/13373/d034372d_original.jpg?aki_policy=large',
      neighborhood: 'Soma',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Shared room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 49,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 24,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77679800',
      longitude: '-122.41000400',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '19 Rausch St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1001208,
      channel_listings_ids: [1001208],
      price: null,
    },
    {
      id: 717,
      title: 'Master ensuite Room in modern Condo',
      image:
        'https://a0.muscache.com/im/pictures/14887346/ae96540e_original.jpg?aki_policy=large',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 0,
      bathrooms: null,
      base_price: 203,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 101,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79011900',
      longitude: '-122.39009900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '338 Spear Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1001221,
      channel_listings_ids: [1001221],
      price: null,
    },
    {
      id: 101704,
      title: 'Bright and Modern Luxury Apartment to call Home',
      image:
        'https://a0.muscache.com/im/pictures/77fcb7d6-a199-464e-8085-a3e45ccae5a2.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 306,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 153,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 86,
      booked_thirty: 73,
      booked_ninety: 55,
      latitude: '37.77711100',
      longitude: '-122.41515400',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '55 9th Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 110806,
      title: 'Modern Loft in SOMA',
      image:
        'https://a0.muscache.com/im/pictures/42962540/12d39c6b_original.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 259,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 129,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77909900',
      longitude: '-122.40277900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '221 Clara Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1128989,
      channel_listings_ids: [1128989],
      price: null,
    },
    {
      id: 115775,
      title: 'sss',
      image:
        'https://a0.muscache.com/im/pictures/f3e58d8e-93d7-4ac6-82f2-645daa724419.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 0,
      bathrooms: '1.00',
      base_price: 164,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 82,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77212100',
      longitude: '-122.40529600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '888 Brannan Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1133849,
      channel_listings_ids: [1133849],
      price: null,
    },
    {
      id: 117726,
      title: 'SOMA - Market St - 1BED - 1 BATH with Parking',
      image:
        'https://a0.muscache.com/im/pictures/7795c03c-0805-43bc-a98d-4a7de2e878db.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 163,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 81,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77936200',
      longitude: '-122.40949200',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '563 Minna Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1135800,
      channel_listings_ids: [1135800],
      price: null,
    },
    {
      id: 128798,
      title: 'Folsom St - SOMA Loft with Parking',
      image:
        'https://a0.muscache.com/im/pictures/e4953974-0d73-49a4-b1eb-0b3bceba7e2c.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 195,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 97,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 22,
      booked_thirty: 0,
      booked_ninety: 8,
      latitude: '37.77811800',
      longitude: '-122.40648700',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '1026 Folsom Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1146834,
      channel_listings_ids: [1146834],
      price: null,
    },
    {
      id: 133816,
      title: 'Listing #11816906',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1151852,
      channel_listings_ids: [1151852],
      price: null,
    },
    {
      id: 133874,
      title: 'Listing #10594550',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: null,
      bathrooms: null,
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1151910,
      channel_listings_ids: [1151910],
      price: null,
    },
    {
      id: 133895,
      title: 'Listing #8687271',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: null,
      bathrooms: null,
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1151931,
      channel_listings_ids: [1151931],
      price: null,
    },
    {
      id: 134083,
      title: 'Listing #5938110',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '2.00',
      base_price: 19,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 9,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1152119,
      channel_listings_ids: [1152119],
      price: null,
    },
    {
      id: 134343,
      title: 'Listing #1647071',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Shared room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 162,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 81,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1152379,
      channel_listings_ids: [1152379],
      price: null,
    },
    {
      id: 134514,
      title: 'Listing #7388198',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1152550,
      channel_listings_ids: [1152550],
      price: null,
    },
    {
      id: 134598,
      title: 'Listing #8904946',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1152634,
      channel_listings_ids: [1152634],
      price: null,
    },
    {
      id: 134651,
      title: 'Listing #10812030',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: null,
      bathrooms: null,
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1152687,
      channel_listings_ids: [1152687],
      price: null,
    },
    {
      id: 134684,
      title: 'Listing #7808095',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1152720,
      channel_listings_ids: [1152720],
      price: null,
    },
    {
      id: 134713,
      title: 'Listing #5883253',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 19,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 9,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1152749,
      channel_listings_ids: [1152749],
      price: null,
    },
    {
      id: 134714,
      title: 'Listing #5883252',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 19,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 9,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1152750,
      channel_listings_ids: [1152750],
      price: null,
    },
    {
      id: 134837,
      title: 'Listing #5387667',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: null,
      bathrooms: null,
      base_price: 19,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 9,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1152873,
      channel_listings_ids: [1152873],
      price: null,
    },
    {
      id: 134838,
      title: 'Listing #4127391',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: null,
      bathrooms: null,
      base_price: 19,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 9,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1152874,
      channel_listings_ids: [1152874],
      price: null,
    },
    {
      id: 138148,
      title: 'Listing #20636511',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77212100',
      longitude: '-122.40529600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '888 Brannan Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1156183,
      channel_listings_ids: [1156183],
      price: null,
    },
    {
      id: 149334,
      title: 'unlisted',
      image:
        'https://a0.muscache.com/im/pictures/683929cb-72ac-411f-8e1c-449640be63a8.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 117,
      base_price_updated_at: '2017-09-29T06:14:42.386571+00:00',
      base_price_history: {
        '2017-09-21T06:49:38.974344+00:00': 142.0,
        '2017-09-22T18:05:43.427039+00:00': 125.0,
        '2017-09-23T05:16:18.163412+00:00': 130.0,
        '2017-09-28T20:16:24.732728+00:00': 110.0,
        '2017-09-28T20:18:02.782497+00:00': 108.0,
        '2017-09-29T06:14:42.386571+00:00': 117.0,
      },
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77454400',
      longitude: '-122.39572100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '383 King Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1167368,
      channel_listings_ids: [1167368],
      price: null,
    },
    {
      id: 152877,
      title: '201HARR \u00b7 Luxury South Beach Condo-full amenities & Parking',
      image:
        'https://a0.muscache.com/im/pictures/28aaa6cc-97dc-47bc-98f9-f1208c6facd7.jpg?aki_policy=large',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 259,
      base_price_updated_at: '2017-10-31T19:37:40.125407+00:00',
      base_price_history: {
        '2017-10-31T18:58:36.617969+00:00': 211.0,
        '2017-10-31T19:37:40.125407+00:00': 259.0,
      },
      min_price: 169,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 65,
      booked_thirty: 46,
      booked_ninety: 15,
      latitude: '37.78765900',
      longitude: '-122.39051800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '201 Harrison Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1170911,
      channel_listings_ids: [1170911],
      price: null,
    },
    {
      id: 154237,
      title: 'Room w/ Private Bath & Water View',
      image:
        'https://a0.muscache.com/im/pictures/23504412/2d1a024e_original.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 174,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 87,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79117600',
      longitude: '-122.39597300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '388 Beale Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1172271,
      channel_listings_ids: [1172271],
      price: null,
    },
    {
      id: 154240,
      title: 'Room w/own Bathroom & Water View ',
      image:
        'https://a0.muscache.com/im/pictures/25852292/f71f3b42_original.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 182,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 91,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78872700',
      longitude: '-122.39276900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: 'Beale Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1172274,
      channel_listings_ids: [1172274],
      price: null,
    },
    {
      id: 154498,
      title: 'Modern Master Suite/Private Bath. Work Desk. SOMA.',
      image:
        'https://a0.muscache.com/im/pictures/64e2588b-21aa-4427-b9df-1b89383049f4.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 139,
      base_price_updated_at: '2017-10-07T20:26:17.220381+00:00',
      base_price_history: { '2017-10-07T20:26:17.220381+00:00': 139.0 },
      min_price: 139,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 53,
      booked_thirty: 26,
      booked_ninety: 28,
      latitude: '37.77550500',
      longitude: '-122.39720200',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '101 Berry Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1172532,
      channel_listings_ids: [1172532],
      price: null,
    },
    {
      id: 158721,
      title:
        'Luxury Residential Apartments in Downtown San Francisco with just a short drive from Fishermans Whar',
      image:
        'https://imagesus-ssl.homeaway.com/mda01/a298a48f-d839-44cf-855f-b88bf37b079b.1.10',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '1.00',
      base_price: 399,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 199,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79174000',
      longitude: '-122.39224660',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1176755,
      channel_listings_ids: [1176755],
      price: null,
    },
    {
      id: 158798,
      title:
        'Luxury 1 Bedroom Apartments in SoMa with Rooftop Bay Bridge Terrace with BBQ Dining',
      image:
        'https://imagesus-ssl.homeaway.com/mda01/1dd0a237-b5cc-4cd5-98c7-e688312535a6.1.10',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 324,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 162,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78676000',
      longitude: '-122.40197000',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1176832,
      channel_listings_ids: [1176832],
      price: null,
    },
    {
      id: 159927,
      title: 'Stylish Roomy 1BR, Panoramic View, Superb Location',
      image:
        'https://a0.muscache.com/im/pictures/33c36a6d-5ca2-4330-9477-66f762034a24.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Shared room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 339,
      base_price_updated_at: '2017-12-20T22:06:58.274934+00:00',
      base_price_history: {
        '2017-11-01T05:29:31.515872+00:00': 314.0,
        '2017-12-20T22:06:58.274934+00:00': 339.0,
      },
      min_price: 179,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77454400',
      longitude: '-122.39572100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '383 King Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1177960,
      channel_listings_ids: [1177960],
      price: null,
    },
    {
      id: 162649,
      title: 'Listing 5346091',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 3,
      bathrooms: null,
      base_price: 1063,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 531,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77459700',
      longitude: '-122.39992500',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '178 Bluxome Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 162650,
      title: 'Listing 4462772',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 887,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 443,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78355000',
      longitude: '-122.39242600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '355 Bryant Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172182,
      title: 'Into the Green',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/3e31bdf0-3af2-4560-b5c9-b8a0c3575132.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 0,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78635550',
      longitude: '-122.39119880',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '333 Harrison Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172183,
      title: 'My Mosso',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/75bebc33-c36b-4ea1-a215-dd3403d092b4.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78012550',
      longitude: '-122.40419010',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '900 Folsom Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172200,
      title: 'Mosso Studio',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/b48d16f4-11d5-44cf-88a6-c0f242076220.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 0,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78012550',
      longitude: '-122.40419010',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '900 Folsom Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172201,
      title: 'Mo Mosso',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/f57096c5-83b1-4a40-aec1-73881f8a9ff6.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78012550',
      longitude: '-122.40419010',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '900 Folsom Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172202,
      title: 'EverGreen',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/4b833af0-a697-4c94-98e0-5249ad5b7de3.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78635550',
      longitude: '-122.39119880',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '333 Harrison Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172203,
      title: 'Green Keeper',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/4ed739fd-c3cf-4353-b752-e96608fd63f5.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78635550',
      longitude: '-122.39119880',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '333 Harrison Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172206,
      title: 'Mod Mission Studio',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/a780e52a-46c2-451c-8d82-951af515ab56.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77423880',
      longitude: '-122.39108470',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '185 Channel Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172207,
      title: 'Mod Mission',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/eea5e743-f656-4f0a-9fc2-74b9d72a7c87.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77423880',
      longitude: '-122.39108470',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '185 Channel Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172208,
      title: 'Mod Mission Deux',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/82618057-8d76-4c6e-b9f1-c1e8e4476325.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77423880',
      longitude: '-122.39108470',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '185 Channel Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172589,
      title: 'Stadium Heights',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/fb8db146-16b4-4e28-adb8-194273d1d6f1.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 612,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 306,
      max_price: null,
      min_stay: 2,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77661390',
      longitude: '-122.39245730',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '107 Berry Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172597,
      title: 'Sapphire Nights',
      image: null,
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78568620',
      longitude: '-122.39407640',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '45 Lansing Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172599,
      title: 'Nouveau Elegance',
      image: null,
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78568620',
      longitude: '-122.39407640',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '45 Lansing Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172600,
      title: 'Luxedo',
      image: null,
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78568620',
      longitude: '-122.39407640',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '45 Lansing Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172601,
      title: 'Sunrise Peak',
      image: null,
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79174430',
      longitude: '-122.39219550',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '88 Howard Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172690,
      title: 'Blanco Suite',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/fd170478-d41d-41e4-beeb-fc4429a97977.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 620,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 310,
      max_price: null,
      min_stay: 30,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79174430',
      longitude: '-122.39219550',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '88 Howard Street, Floor: 8 - Unit: 803',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 172769,
      title: 'Indy Loft',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/c67e468e-1c07-4179-a7bd-45c0d8235979.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 905,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 452,
      max_price: null,
      min_stay: 30,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77614230',
      longitude: '-122.40969510',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '60 Rausch Street, Floor: 3 - Unit: 302',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1190602,
      channel_listings_ids: [1190602],
      price: null,
    },
    {
      id: 173082,
      title: 'King Bee',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/3aa933cd-b8b7-404c-9001-01c05601ee4e.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 264,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 132,
      max_price: null,
      min_stay: 30,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77470160',
      longitude: '-122.39527940',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '383 King Street, Floor: 7 - Unit: 712',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1190915,
      channel_listings_ids: [1190915],
      price: null,
    },
    {
      id: 173611,
      title: 'Menlo Dream',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/40ad4982-3deb-46de-b31a-c7ddb478f2e5.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79211540',
      longitude: '-122.40300450',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '315 Montgomery Street, Floor:  - Unit: 8',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 173678,
      title: 'Redwood Dream',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/974a208a-e276-47da-a890-4fff09ecf832.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79211540',
      longitude: '-122.40300450',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '315 Montgomery Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 174559,
      title: 'The Llandudno',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/b7fa212e-afd1-4e7d-91f0-75987a67162b.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '2.50',
      base_price: 288,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 144,
      max_price: null,
      min_stay: 30,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77853030',
      longitude: '-122.39803560',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '139 Welsh Street, Floor:  - Unit: 2',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1192392,
      channel_listings_ids: [1192392],
      price: null,
    },
    {
      id: 174787,
      title: 'Mint Market',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/7eb83dbb-3d4e-4049-b0ab-81e7b864bfb8.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 266,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 133,
      max_price: null,
      min_stay: 30,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78312800',
      longitude: '-122.40776330',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '2 Mint Plaza, Floor:  - Unit: 402',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1192620,
      channel_listings_ids: [1192620],
      price: null,
    },
    {
      id: 176132,
      title: '271 Shipley St.  \u00b7 Spacious, Sunny SOMA Loft with backyard!',
      image:
        'https://a0.muscache.com/im/pictures/cfa45cf7-6f2f-415d-b3f5-d2ca23888f67.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 188,
      base_price_updated_at: '2017-11-23T20:45:20.383545+00:00',
      base_price_history: { '2017-11-23T20:45:20.383545+00:00': 188.0 },
      min_price: 150,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 9,
      booked_thirty: 0,
      booked_ninety: 7,
      latitude: '37.77858400',
      longitude: '-122.40417500',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '271 Shipley Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1193866,
      channel_listings_ids: [1193866],
      price: null,
    },
    {
      id: 202787,
      title: 'Tastefully decorated luxury apartment',
      image:
        'https://a0.muscache.com/im/pictures/0d92df1e-bb6f-4421-b3aa-59ccffb695b1.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 199,
      base_price_updated_at: '2018-03-09T06:59:26.656622+00:00',
      base_price_history: {
        '2018-02-16T00:48:24.531024+00:00': 201.0,
        '2018-02-16T00:51:11.230933+00:00': 179.0,
        '2018-02-19T18:33:48.058065+00:00': 189.0,
        '2018-03-09T06:59:26.656622+00:00': 199.0,
      },
      min_price: 179,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77207900',
      longitude: '-122.40381600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '855 Brannan Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1219780,
      channel_listings_ids: [1219780],
      price: null,
    },
    {
      id: 203064,
      title: 'Property 1',
      image:
        'https://res-3.cloudinary.com/bookingsync/image/upload/c_fill,f_auto,fl_progressive,g_center,h_1024,q_90,w_1536/v1466628036/Best-duplex-apartments_Chennai-Interior-Decors_f36_cfhx4c.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 3,
      bathrooms: '1.00',
      base_price: 2186000,
      base_price_updated_at: '2018-11-20T01:27:56.945711+00:00',
      base_price_history: { '2018-11-20T01:27:56.945711+00:00': 2186000.0 },
      min_price: 1093,
      max_price: null,
      min_stay: 1,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78405680',
      longitude: '-122.40412060',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '120 4th St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1220053,
      channel_listings_ids: [1220053],
      price: null,
    },
    {
      id: 203389,
      title: 'Fernet',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/3f17cd01e9f1037a/16jessie-1bath.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 325,
      base_price_updated_at: '2018-05-09T16:42:32.926736+00:00',
      base_price_history: {
        '2018-03-01T22:32:18.907846+00:00': 376.0,
        '2018-03-07T03:53:33.222272+00:00': 300.0,
        '2018-03-14T22:36:59.223073+00:00': 350.0,
        '2018-03-14T22:39:09.133468+00:00': 375.0,
        '2018-05-09T16:42:20.825303+00:00': 350.0,
        '2018-05-09T16:42:32.926736+00:00': 325.0,
      },
      min_price: 295,
      max_price: null,
      min_stay: 30,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78990620',
      longitude: '-122.39895610',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '16 Jessie St., 305',
      notify: false,
      syncing: true,
      primary_channel_listing_id: 1220372,
      channel_listings_ids: [1220372],
      price: '325.00',
    },
    {
      id: 206612,
      title: 'Test',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 518,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 259,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78382100',
      longitude: '-122.39771300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '1 Saint Francis Place',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1223530,
      channel_listings_ids: [1223530],
      price: null,
    },
    {
      id: 212291,
      title: 'City Pellegrino',
      image:
        'https://image.myvr.com/3b1e7ce00df48007/1df7b8e298da456d/820npoint-2bath.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 3,
      bathrooms: '2.00',
      base_price: 625,
      base_price_updated_at: '2018-03-26T18:33:13.515446+00:00',
      base_price_history: { '2018-03-26T18:33:13.515446+00:00': 625.0 },
      min_price: 625,
      max_price: null,
      min_stay: 30,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78990620',
      longitude: '-122.39895610',
      currency: 'USD',
      in_active_market: true,
      enabled: true,
      is_owner: true,
      address: '820 North Point St.',
      notify: false,
      syncing: true,
      primary_channel_listing_id: 1229160,
      channel_listings_ids: [1229160],
      price: '625.00',
    },
    {
      id: 215481,
      title: 'Luxury One Bedroom Apartments',
      image:
        'https://imagesus-ssl.homeaway.com/mda01/723d8555-1ae9-48db-8b23-6ec19f77e4c0.1.10',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'US',
      room_type: 'apartment',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77300840',
      longitude: '-122.39133370',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1232306,
      channel_listings_ids: [1232306],
      price: null,
    },
    {
      id: 215773,
      title: 'Upscale master bedroom in the heart of SF',
      image:
        'https://a0.muscache.com/im/pictures/dce057e5-fded-4024-adc1-a6e2bc9fcad8.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 154,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 77,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 4,
      booked_thirty: 0,
      booked_ninety: 1,
      latitude: '37.77201800',
      longitude: '-122.40345800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '855 Brannan Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1232592,
      channel_listings_ids: [1232592],
      price: null,
    },
    {
      id: 217180,
      title: 'Great 1br Apt Near Lake Merritt, BART, & SF',
      image:
        'https://a0.muscache.com/im/pictures/72d78191-cc3a-47b4-a01d-fc15d158bffd.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 3,
      bathrooms: null,
      base_price: 109,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 54,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78947800',
      longitude: '-122.40146600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '580 Market Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 217182,
      title: 'Cozy Capitol Hill Apt with Rooftop Views',
      image:
        'https://a0.muscache.com/im/pictures/44a3d90d-8325-4979-a376-51974b2364cf.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 108,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 54,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78947800',
      longitude: '-122.40146600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '580 Market Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 217183,
      title: 'Cozy Capitol Hill Apt with Rooftop Views',
      image:
        'https://a0.muscache.com/im/pictures/52cd9598-b731-48f8-a540-ce80aa94d200.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 3,
      bathrooms: null,
      base_price: 108,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 54,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78947800',
      longitude: '-122.40146600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '580 Market Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 221324,
      title: '\u65e0\u654c\u6d77\u666f\u6d6a\u6f2b\u60c5\u6000',
      image:
        'https://a0.muscache.com/im/pictures/cb494d58-ee43-4618-a064-4f4fd085bc90.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Shared room',
      bedrooms: 1,
      bathrooms: null,
      base_price: 108,
      base_price_updated_at: '2018-04-16T10:31:54.376381+00:00',
      base_price_history: { '2018-04-16T10:31:54.376381+00:00': 108.0 },
      min_price: 54,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78721200',
      longitude: '-122.39195300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '399 Fremont Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: '251.00',
    },
    {
      id: 221325,
      title: '\u68a6\u5e7b\u5927\u5e8a\u623f',
      image:
        'https://a0.muscache.com/im/pictures/38647ef4-0dd5-4892-90d9-eb9e9af73ffe.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 0,
      bathrooms: null,
      base_price: 142,
      base_price_updated_at: '2018-04-16T10:31:55.422578+00:00',
      base_price_history: { '2018-04-16T10:31:55.422578+00:00': 142.0 },
      min_price: 71,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 60,
      booked_thirty: 33,
      booked_ninety: 11,
      latitude: '37.78721200',
      longitude: '-122.39195300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '399 Fremont Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: '330.00',
    },
    {
      id: 221326,
      title: '\u8c6a\u534e\u6d77\u666f\u623f',
      image:
        'https://a0.muscache.com/im/pictures/2936bd83-fea8-4e2b-9243-c10bbed529d2.jpg?aki_policy=large',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 0,
      bathrooms: null,
      base_price: 207,
      base_price_updated_at: '2018-04-16T10:31:57.532611+00:00',
      base_price_history: { '2018-04-16T10:31:57.532611+00:00': 207.0 },
      min_price: 103,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 14,
      booked_thirty: 10,
      booked_ninety: 3,
      latitude: '37.78721200',
      longitude: '-122.39195300',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '399 Fremont Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: '481.00',
    },
    {
      id: 221677,
      title: '211 Sutter St, San Francisco',
      image:
        'https://a0.muscache.com/im/pictures/1f22c8e3-60bc-447d-89d0-6e5d17162351.jpg?aki_policy=large',
      neighborhood: 'Downtown',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Shared room',
      bedrooms: 1,
      bathrooms: '0.00',
      base_price: 2007,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 1003,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 9,
      booked_thirty: 0,
      booked_ninety: 7,
      latitude: '37.78966100',
      longitude: '-122.40406800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '211 Sutter Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1238391,
      channel_listings_ids: [1238391],
      price: null,
    },
    {
      id: 224460,
      title: 'Listing #24714700',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 161,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 80,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77264000',
      longitude: '-122.40991200',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1241119,
      channel_listings_ids: [1241119],
      price: null,
    },
    {
      id: 227249,
      title: 'Charming apartment Soma',
      image: null,
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'California',
      country: 'United States',
      room_type: 'Apartment',
      bedrooms: 0,
      bathrooms: '0.00',
      base_price: 1369,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 684,
      max_price: null,
      min_stay: 1,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79048340',
      longitude: '-122.39320780',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: 'Main Street 207',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1243747,
      channel_listings_ids: [1243747],
      price: null,
    },
    {
      id: 241003,
      title: 'Modern Chic Home in Bernal Heights',
      image:
        'https://a0.muscache.com/im/pictures/2bd9e316-c154-42bd-9540-dbffaa94c63c.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '1.50',
      base_price: 296,
      base_price_updated_at: '2018-06-20T22:00:32.453516+00:00',
      base_price_history: { '2018-06-20T22:00:32.453516+00:00': 296.0 },
      min_price: 199,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 4,
      booked_thirty: 0,
      booked_ninety: 1,
      latitude: '37.77492900',
      longitude: '-122.41941800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: null,
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: '404.00',
    },
    {
      id: 246160,
      title: 'Luxury Bay Bridge flat with pool',
      image: 'https://a0.muscache.com/pictures/5571474/large.jpg',
      neighborhood: 'South Beach',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 230,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 115,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78571617',
      longitude: '-122.39174438',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '1st St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 246950,
      title: 'South Beach Apartment',
      image: 'https://a0.muscache.com/pictures/2935557/large.jpg',
      neighborhood: 'Soma',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 179,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 89,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77813969',
      longitude: '-122.39396480',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: 'King St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 251037,
      title: 'cozy 2b/2b',
      image:
        'https://a0.muscache.com/im/pictures/9927f6fa-51c4-45d3-ad31-a3b8158f9daa.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: null,
      base_price: 155,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 77,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 2,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77825500',
      longitude: '-122.39588900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '601 4th Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 251912,
      title: 'Listing #27170855',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77825500',
      longitude: '-122.39588900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '601 4th Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1267024,
      channel_listings_ids: [1267024],
      price: null,
    },
    {
      id: 255186,
      title: 'Fantastic super Central condo',
      image:
        'https://a0.muscache.com/im/pictures/ad9374d7-77a9-4867-896d-bc0d887b0621.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 94,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 47,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77984600',
      longitude: '-122.40818800',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '518 Natoma Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1270194,
      channel_listings_ids: [1270194],
      price: null,
    },
    {
      id: 255725,
      title: 'San Francisco Apartment - TEST',
      image:
        'https://a0.muscache.com/im/pictures/0d1842ad-61e6-4985-aa3c-fe3b17633ae4.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 137,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 68,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77531800',
      longitude: '-122.40281700',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '314 Harriet Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1270704,
      channel_listings_ids: [1270704],
      price: null,
    },
    {
      id: 266230,
      title: 'Listing #28439972',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'Downtown',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 0,
      bathrooms: '1.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.79049300',
      longitude: '-122.40705900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '590 Bush Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1280866,
      channel_listings_ids: [1280866],
      price: null,
    },
    {
      id: 271421,
      title: 'San Francisco House',
      image:
        'https://a0.muscache.com/im/pictures/45345d5e-e6e5-4aa5-bedf-acfb4a56476c.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 0,
      bathrooms: '1.00',
      base_price: 27,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 13,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77738600',
      longitude: '-122.41382600',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '1236 Mission Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1285605,
      channel_listings_ids: [1285605],
      price: null,
    },
    {
      id: 285484,
      title: '1BR GREAT VIEW',
      image:
        'https://a0.muscache.com/im/pictures/57c2d9b2-5b54-42db-8872-0bfbaf51c7d0.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.00',
      base_price: 5982,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 2991,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77587500',
      longitude: '-122.39387500',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '301 King Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1298686,
      channel_listings_ids: [1298686],
      price: null,
    },
    {
      id: 293753,
      title: 'Ameliorated disintermediate neural-net',
      image: '/assets/hero/hero-tehama-3.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'Massachusetts',
      country: 'United States',
      room_type: null,
      bedrooms: 7,
      bathrooms: '3.00',
      base_price: 150,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 75,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 67,
      booked_thirty: 30,
      booked_ninety: 34,
      latitude: '37.79403400',
      longitude: '-122.39510100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '94173 Melissa Springs',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1306773,
      channel_listings_ids: [1306773],
      price: null,
    },
    {
      id: 305883,
      title: 'Sunny, Spacious Studio in Union Sq',
      image:
        'https://a0.muscache.com/im/pictures/8546468/5e3337a5_original.jpg?aki_policy=large',
      neighborhood: 'Downtown',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: null,
      base_price: 183,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 91,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78688800',
      longitude: '-122.41523700',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '625 Leavenworth St',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1318664,
      channel_listings_ids: [1318664],
      price: null,
    },
    {
      id: 309241,
      title: 'Posh Urban Loft near Moscone Center',
      image:
        'https://a0.muscache.com/im/pictures/a31905e4-d53e-4564-b159-a769aacef542.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 1,
      bathrooms: '1.50',
      base_price: 254,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 127,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78092600',
      longitude: '-122.39719400',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '139 Stillman Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1321956,
      channel_listings_ids: [1321956],
      price: null,
    },
    {
      id: 309242,
      title: 'Listing #30960384',
      image:
        'https://a0.muscache.com/im/pictures/a2209072-aeb8-4045-b1c2-57ed01a9dd6b.jpg?aki_policy=large',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Private room',
      bedrooms: 1,
      bathrooms: '1.50',
      base_price: 199,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 99,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.78092600',
      longitude: '-122.39717900',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '139 Stillman Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1321957,
      channel_listings_ids: [1321957],
      price: null,
    },
    {
      id: 314455,
      title: 'Implemented 6thgeneration customer loyalty',
      image: '/assets/hero/hero-tehama-2.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'Delaware',
      country: 'United States',
      room_type: null,
      bedrooms: 5,
      bathrooms: '3.00',
      base_price: 130,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 65,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 68,
      booked_thirty: 33,
      booked_ninety: 38,
      latitude: '37.79403400',
      longitude: '-122.39510100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '71345 Banks Lake Suite 087',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1327043,
      channel_listings_ids: [1327043],
      price: null,
    },
    {
      id: 314458,
      title: 'Enhanced solution-oriented structure',
      image: '/assets/hero/hero-mason-1.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'Colorado',
      country: 'United States',
      room_type: null,
      bedrooms: 6,
      bathrooms: '1.00',
      base_price: 130,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 65,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 58,
      booked_thirty: 20,
      booked_ninety: 44,
      latitude: '37.79403400',
      longitude: '-122.39510100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '5615 Erickson Forest Suite 373',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1327046,
      channel_listings_ids: [1327046],
      price: null,
    },
    {
      id: 314908,
      title: 'Automated dedicated help-desk',
      image: '/assets/hero/hero-greenwich-1.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'Virginia',
      country: 'United States',
      room_type: null,
      bedrooms: 6,
      bathrooms: '1.00',
      base_price: 131,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 65,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 70,
      booked_thirty: 96,
      booked_ninety: 64,
      latitude: '37.79403400',
      longitude: '-122.39510100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '404 Heather Mall',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 318030,
      title: 'Listing #31429715',
      image: 'page3/v3/room_default_no_photos.png',
      neighborhood: 'SoMa',
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'CA',
      country: 'United States',
      room_type: 'Entire home/apt',
      bedrooms: 2,
      bathrooms: '2.00',
      base_price: 200,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 100,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 0,
      booked_thirty: 0,
      booked_ninety: 0,
      latitude: '37.77716400',
      longitude: '-122.41518400',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '55 9th Street',
      notify: false,
      syncing: false,
      primary_channel_listing_id: null,
      channel_listings_ids: [],
      price: null,
    },
    {
      id: 322215,
      title: 'Self-enabling bandwidth-monitored circuit',
      image: '/assets/hero/hero-tehama-1.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'Oklahoma',
      country: 'United States',
      room_type: null,
      bedrooms: 5,
      bathrooms: '7.00',
      base_price: 121,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 60,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 70,
      booked_thirty: 33,
      booked_ninety: 37,
      latitude: '37.79403400',
      longitude: '-122.39510100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '5747 Joseph Fords',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1334575,
      channel_listings_ids: [1334575],
      price: null,
    },
    {
      id: 322216,
      title: 'Digitized explicit superstructure',
      image: '/assets/hero/hero-noe-3.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'Massachusetts',
      country: 'United States',
      room_type: null,
      bedrooms: 6,
      bathrooms: '4.00',
      base_price: 121,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 60,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 81,
      booked_thirty: 56,
      booked_ninety: 64,
      latitude: '37.79403400',
      longitude: '-122.39510100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '5104 Garcia Plain',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1334576,
      channel_listings_ids: [1334576],
      price: null,
    },
    {
      id: 322218,
      title: 'Sharable bottom-line capability',
      image: '/assets/hero/hero-noe-2.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'North Carolina',
      country: 'United States',
      room_type: null,
      bedrooms: 2,
      bathrooms: '8.00',
      base_price: 121,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 60,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 62,
      booked_thirty: 26,
      booked_ninety: 28,
      latitude: '37.79403400',
      longitude: '-122.39510100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '523 Kevin Mountains Suite 877',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1334578,
      channel_listings_ids: [1334578],
      price: null,
    },
    {
      id: 323744,
      title: 'User-centric encompassing workforce',
      image: '/assets/hero/hero-noe-2.jpg',
      neighborhood: null,
      city: 'San Francisco',
      market: 'San Francisco',
      state: 'Washington',
      country: 'United States',
      room_type: null,
      bedrooms: 4,
      bathrooms: '1.00',
      base_price: 122,
      base_price_updated_at: null,
      base_price_history: {},
      min_price: 61,
      max_price: null,
      min_stay: null,
      extra_guest_fee: null,
      extra_guest_threshold: null,
      round_to_nearest_number: null,
      health_score: 81,
      booked_thirty: 50,
      booked_ninety: 48,
      latitude: '37.79403400',
      longitude: '-122.39510100',
      currency: 'USD',
      in_active_market: true,
      enabled: false,
      is_owner: true,
      address: '314 Robinson Centers Apt. 263',
      notify: false,
      syncing: false,
      primary_channel_listing_id: 1336093,
      channel_listings_ids: [1336093],
      price: null,
    },
  ],
};
