import classic from 'ember-classic-decorator';
import Service from '@ember/service';

const DEFAULT_TITLE = 'Dynamic Pricing / Dashboard';

@classic
export default class GlobalNavService extends Service {
  /** @type {String} */
  currentTitle = null;

  init() {
    super.init(...arguments);
    this.set('currentTitle', DEFAULT_TITLE);
    return this;
  }

  /**
   * @function setTitle
   * @param {String} title
   */
  setTitle(title) {
    if (!title) {
      this.set('currentTitle', DEFAULT_TITLE);
    } else {
      this.set('currentTitle', title);
    }
  }
}
