import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@classNames('app-search-input')
export default class AppSearchInput extends Component {
  // -- Parameters ---------------------------------------------------------------------
  @service('list-control')
  listControl;

  search = null; //@type {String}

  @action
  updatedSearch() {
    this.listControl.updatedSearch();
  }
}
