import classic from 'ember-classic-decorator';
// import { action } from '@ember/object';
// import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
// import moment from 'moment';
// import logger from 'appkit/lib/logger';
// import Ember from 'ember';
import { getOwner } from '@ember/application';

@classic
export default class MarketMapLocatorRoute extends Route {
  beforeModel() {
    getOwner(this).lookup('route:application').templateName = 'market-map-locator';
  }
}
