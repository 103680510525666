import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';
import { ChannelUtil } from 'appkit/lib/channel';
import { action } from '@ember/object';

@classic
export default class RelayRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service router;
  @service featureFlag;
  @service currentUser;

  isSurfaceRelayInAppEnabled = false;
  isRelayv2Enabled = false;

  isReservationsRoute = false;
  hasReservationsAvailable = false;

  title(tokens) {
    let title = null;

    if (!tokens || !tokens.length) {
      title = 'Relay';
    } else {
      title = `Relay / ${tokens}`;
    }

    this.globalNav.setTitle(title);
    return title;
  }

  async beforeModel(transition) {
    this.isSurfaceRelayInAppEnabled = await this.featureFlag.evaluate(
      'surface-relay-in-app',
      false
    );
    this.isRelayv2Enabled = await this.featureFlag.evaluate(
      'use-relay-v2-controller',
      false
    );

    this.hasReservationsAvailable = await this.featureFlag.evaluate(
      'relay-reservations-list-view',
      false
    );

    this.flag_USER_LISTING_VIEW_ENABLED = await this.featureFlag.evaluate(
      'relay-listing-view',
      false
    );

    this.flag_FEATURE_MOCKS_ENABLED =
      (await this.featureFlag.evaluate('relay-feature-mocks', false)) &&
      this.currentUser.isStaff;

    this.flag_RELAY_SETTINGS_PAGE_ENABLED =
      (await this.featureFlag.evaluate('relay-settings-page', false)) &&
      this.currentUser.isStaff;

    if (transition.to.name === 'dashboard.relay.index.index') {
      this.transitionTo('dashboard.relay.index.merged-listings');
    }
  }

  afterModel(model, transition) {
    if (transition.to.localName === 'reservations') this.isReservationsRoute = true;

    model.isSurfaceRelayInAppEnabled = this.isSurfaceRelayInAppEnabled;
    // PMS users with relay enabled can see relay
    const hasPms = model.accounts.some(ac => ChannelUtil.isPms(ac.channel));
    const hasDirect = model.accounts.some(ac => !ChannelUtil.isPms(ac.channel));
    const hasSupportedPms = model.accounts.some(ac =>
      ChannelUtil.supportsRelay(ac.channel)
    );

    if (this.isRelayv2Enabled) {
      console.log('Redirecting to Relay v2', this.isRelayv2Enabled);

      this.transitionTo('dashboard.relay.v2');
    }

    if ((hasPms && hasDirect) || hasSupportedPms) {
      return model;
    }

    const currURL = transition.to.name;

    if (this.isSurfaceRelayInAppEnabled) {
      if (currURL === 'dashboard.relay.index') {
        this.transitionTo('dashboard');
      }
    } else {
      this.transitionTo('dashboard');
    }
  }

  getActiveTabFromPath(pathname) {
    if (pathname === 'merged-listings') return 0;
    if (pathname === 'unmerged-listings') return 1;
    if (pathname === 'reservations') return 2;
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    // Update visible options in the navigation menu (~permissions)
    controller.setNavMenuOptionVisibility(
      'settings',
      this.flag_RELAY_SETTINGS_PAGE_ENABLED
    );

    // Define the initial page content
    const activeTab = this.getActiveTabFromPath(transition.to.localName);
    controller.set('selectedTab', activeTab);

    // Update the nav power-select to reflect the active tab
    const tabOptions = controller.get('relayDashboardOptions');
    controller.set(
      'selectedDashboardOption',
      tabOptions.find(opt => opt.id === activeTab)
    );

    controller.set(
      'flag_USER_LISTING_VIEW_ENABLED',
      this.flag_USER_LISTING_VIEW_ENABLED
    );

    if (
      this.isReservationsRoute &&
      (!this.hasReservationsAvailable || !this.currentUser.isStaff)
    ) {
      this.replaceWith('dashboard.relay.index.reservations');
    }
  }

  @action
  willTransition(transition) {
    let indexController = this.controllerFor('dashboard.relay.index');

    if (transition.to.localName === 'merged-listings') {
      indexController.set('selectedTab', 0);
      indexController.set(
        'selectedDashboardOption',
        indexController.relayDashboardOptions.find(m => m.id === 0)
      );
    }
    if (transition.to.localName === 'unmerged-listings') {
      indexController.set('selectedTab', 1);
      indexController.set(
        'selectedDashboardOption',
        indexController.relayDashboardOptions.find(m => m.id === 1)
      );
    }

    if (transition.to.name === 'dashboard.relay.index.reservations') {
      indexController.set('selectedTab', 2);
      indexController.set(
        'selectedDashboardOption',
        indexController.relayDashboardOptions.find(m => m.id === 2)
      );
    }
  }
}
