import BpSelectBaseComponent from '../base';
import { action } from '@ember/object';

export default class BpSelectMultipleComponent extends BpSelectBaseComponent {
  get powerSelectComponent() {
    return 'power-select-multiple';
  }

  // Enable search on dropdown if no wrap option is set
  get beforeOptionsComponent() {
    return (
      this.args.beforeOptionsComponent ??
      (this.args.noWrap && 'bp/select/parts/before-options')
    );
  }

  get triggerComponent() {
    return this.args.triggerComponent ?? 'bp/select/parts/trigger/multiple';
  }

  get selectedItemComponent() {
    return this.args.selectedItemComponent ?? 'bp/select/parts/selected-item/multiple';
  }

  get extra() {
    return {
      ...super.extra,
      selectedItemNoWrap: this.args.noWrap ?? false,
    };
  }

  @action
  handleKeydown(select, e) {
    if (this.args.onKeydown && this.args.onKeydown(select, e) === false) {
      e.stopPropagation();
      return false;
    }
    if (e.keyCode === 13 && select.isOpen) {
      e.stopPropagation();
      if (
        select.highlighted !== undefined &&
        // Make sure the highlighted option exists
        select.options.indexOf(select.highlighted) !== -1
      ) {
        if (!select.selected || select.selected.indexOf(select.highlighted) === -1) {
          select.actions.choose(select.highlighted, e);
          // Clear the search
          select.actions.search('');
          return false;
        } else {
          // Unselect option
          select.actions.choose(select.highlighted, e);
          select.actions.close(e);
          return false;
        }
      } else {
        select.actions.close(e);
        return false;
      }
    }
  }
}
