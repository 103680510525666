import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import OrphanDayModel from 'appkit/bp-models/orphan_day';
import Component from '@ember/component';

@classic
@classNames('app-orphan-days')
export default class AppOrphanDays extends Component {
  orphanDays = null; // @type {Array}

  @computed('orphanDays.[]')
  get noOrphanDays() {
    return this.orphanDays.length === 0;
  }

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    this.set(
      'orphanDays',
      this.customizeOrphanDays.map(orphan => {
        let orphanDay = OrphanDayModel.create(orphan);
        orphanDay.set('isDecrease', orphanDay.get('percentage') <= 0);
        return orphanDay;
      })
    );
  }

  validate() {
    let isValid = true;
    const validOrphanDays = this.orphanDays
      .filter(orphan => orphan.get('days') !== 0)
      .sort((orphan_a, orphan_b) => orphan_a.get('days') - orphan_b.get('days'));
    const errorMessage = 'Reservation gaps settings must have different days length';
    let orphanDayAtDays = {};

    if (this.noOrphanDays) return true;

    validOrphanDays.forEach(orphanDay => {
      orphanDay.set('error', '');
      if (orphanDayAtDays[orphanDay.days]) {
        orphanDayAtDays[orphanDay.days].push(orphanDay);
        orphanDayAtDays[orphanDay.days].forEach(orphanDay => {
          orphanDay.set('error', errorMessage);
        });
        isValid = false;
      } else {
        orphanDayAtDays[orphanDay.days] = [orphanDay];
      }
      isValid = isValid && orphanDay.validate();
    });

    return isValid;
  }

  sendOrphanDays() {
    let orphanDaysEmpty = this.orphanDaysEmpty;
    if (orphanDaysEmpty) {
      return this.dataIsValid([]);
    }
    if (!this.validate()) {
      return this.dataIsInvalid();
    }

    const validOrphanDays = this.orphanDays
      .map(orphanDay => {
        let orphan = orphanDay.getProperties(
          'id',
          'days',
          'percentage',
          'ignoreOnWeekdays'
        );
        return orphan;
      })
      .filter(orphanDay => (orphanDay.id && orphanDay.days !== 0) || !orphanDay.id);

    return this.dataIsValid(validOrphanDays);
  }

  @action
  addOrphanDay() {
    this.orphanDays.pushObject(OrphanDayModel.create({}));
    this.sendOrphanDays();
  }

  @action
  removeOrphanDay(orphanDay) {
    this.orphanDays.removeObject(orphanDay);
    this.sendOrphanDays();
  }

  @action
  orphanDayUpdate(attr, val, orphanDay) {
    orphanDay.set(attr, val);
    this.sendOrphanDays();
  }
}
