import Service from '@ember/service';
import Util, { uuidv4 } from 'appkit/lib/util';
import ENV from 'appkit/config/environment';
import logger from 'appkit/lib/logger';

export default class ImpactService extends Service {
  actions = {
    Identify: 'identify',
    TrackConversion: 'trackConversion',
  };

  accountTrackerIds = {
    Signup: 25908,
    AddCreditCard: 25914,
    AddListing: 25912,
    ConnectAccount: 25911,
    Trial: 25913,
  };

  /**
   * Sends customer identity information to Impact affiliate partner on each page load
   * @param {User} user a User object containing email and userId, used to idenfity users as they navigate the app
   */
  async identifyUser(user) {
    const impactAPIDisabled = ENV.environment === 'test';
    if (impactAPIDisabled) {
      console.log(
        `WARNING: Impact Integration disabled in test environment for ${user.email}.`
      );
      return;
    }

    try {
      /* eslint-disable-next-line */
      return ire(
        this.actions.Identify,
        {
          customerId: user.userId,
          customerEmail: Util.SHA1Encode(user.email),
        },
        {
          // TODO: This bypasses some domain-related security check which unblocks local testing
          verifySiteDefinitionMatch: false,
        }
      );
    } catch (e) {
      throw new Error(`Error loading Impact Integration: ${e}`);
    }
  }

  /**
   * Submits user attributes for a particular eventType to Impact affiliate partner
   ** @param {String} eventType Signup, AddCreditCard, AddListing, ConnectAccount, or Trial
   ** @param {User} user the currently logged in user (should contain email and userId)
   */
  async trackConversion(eventType, user) {
    const impactAPIDisabled = ENV.environment === 'test';
    const orderId = uuidv4();
    logger.info(`BEGIN trackConversion, orderId: ${orderId}`);

    if (impactAPIDisabled) {
      console.log(
        `WARNING: Impact Integration disabled in test environment or for ${user.email}.`
      );
      return;
    }
    try {
      /* eslint-disable-next-line */
      return ire(
        this.actions.TrackConversion,
        this.accountTrackerIds[eventType],
        {
          orderId: orderId,
          customerId: user.userId,
          customerEmail: Util.SHA1Encode(user.email),
        },
        {
          verifySiteDefinitionMatch: false,
        }
      );
    } catch (e) {
      logger.error(`Error loading Impact Integration: ${e},  orderId: ${orderId}`);
      throw new Error(`Error loading Impact Integration: ${e}`);
    }
  }
}
