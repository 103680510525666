import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import EmberObject from '@ember/object';

@classic
export default class StatementsRoute extends AuthRoute {
  titleToken = 'Billing';

  setupController(controller) {
    let user = this.modelFor('dashboard.user');
    controller.set('model', user);
    controller.set('selectedBillingCurrency', user.billingCurrency);
  }

  async model() {
    let user = this.modelFor('dashboard.user');

    await this.getBillingPeriods(user);

    return this.ajax._get('/api/user/statements').then(responseData => {
      let statements = responseData.statements.map(statementData => {
        let obj = this.bpStore.createRecord('statement', statementData);
        // statement items and payments aren't real client-side objects, so
        // they don't use the store. That means we don't get a peekRecord and
        // so we'll end up with duplicate payment / statement item info when
        // leaving and re-entering this route a few times.
        obj.set('statementItems', []);
        obj.set('payments', []);
        return obj;
      });

      user.set('statements', statements);

      // Statement items
      responseData.statementItems.map(statementItemData => {
        let obj = EmberObject.create(statementItemData);
        let statement = this.bpStore.peekRecord('statement', obj.get('statementId'));
        obj.set('statement', statement);
        statement.get('statementItems').pushObject(obj);
        return obj;
      });

      // Payments
      responseData.payments.map(paymentData => {
        let obj = EmberObject.create(paymentData);
        let statement = this.bpStore.peekRecord('statement', obj.get('statementId'));
        obj.set('statement', statement);
        statement.get('payments').pushObject(obj);
        return obj;
      });
    });
  }

  getBillingPeriods(user) {
    return this.ajax._get('/api/billing/periods').then(r => {
      let periods = r.map(period =>
        this.bpStore.createRecord('billing-period', period)
      );

      user.set('periods', periods);
    });
  }
}
