import { fontSizeClasses } from '../../../../../bp/select/parts/trigger/index.theme';

export default {
  root: {
    defaultClasses: () => ['flex', 'max-w-full'],
  },

  tagText: {
    defaultClasses: () => ['truncate'],
    fontSizeClasses: fontSizeClasses,
  },
};
