import BpSelectBaseComponent from './base';
import { action } from '@ember/object';
import classNames from 'classnames';

export default class BpSelectComponent extends BpSelectBaseComponent {
  get classes() {
    return classNames(super.classes, 'cursor-default');
  }

  get powerSelectComponent() {
    return 'power-select';
  }

  get triggerComponent() {
    return 'bp/select/parts/trigger/typeahead';
  }

  @action
  handleKeydown() {
    return this.onKeyDown(...arguments);
  }

  @action
  onKeyDown(select, e) {
    let onKeydown = this.args.onKeydown;

    // if user passes `onkeydown` onKeydown
    if (onKeydown && onKeydown(select, e) === false) {
      return false;
    } else {
      // if escape, then clear out selection
      if (e.keyCode === 27) {
        select.actions.choose(null);
      }
    }
  }
}
