import { helper } from '@ember/component/helper';

export default helper(function (params) {
  let brand = params[0];
  if (brand) {
    brand = brand.toLowerCase().replace(/ /g, '-');
  } else {
    brand = 'unknown';
  }
  return `cards/card-${brand}`;
});
