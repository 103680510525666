import BaseModel from 'appkit/bp-models/base_model';
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';

export default BaseModel.extend({
  init() {
    this._super(arguments);
    this._EDITABLE = ['direction', 'percentage', 'days'];
  },

  url: computed('listingId', 'id', function () {
    return `/v1/listings/${this.listingId}/discounts/${this.id || ''}`;
  }),

  id: null,
  direction: 'out',
  percentage: 0,
  listingId: 0,
  days: 0,
  isIncrease: false,
  isDecrease: not('isIncrease'),

  error: '',

  validate() {
    const percentage = parseFloat(this.percentage);
    const days = parseFloat(this.days);

    if (days === 0 && percentage === 0) {
      // Ignore new lines
      return true;
    }

    if (Number.isNaN(days) || Number.isNaN(percentage) || !days) {
      this.set('error', 'Invalid data');
      return false;
    }

    if (percentage >= 1.0) {
      this.set('error', 'Cannot have a decrease in price of 100% or more');
      return false;
    }

    return true;
  },
});
