import Adapter, { BuildURLMixin } from '@ember-data/adapter';
import { inject as service } from '@ember/service';

// https://github.com/emberjs/data/blob/v3.18.0/packages/adapter/addon/-private/build-url-mixin.js#L12
export default class ApplicationAdapter extends Adapter.extend(BuildURLMixin) {
  @service('ajax') ajaxService;
  namespace = 'api';
  host;
  headers = {};

  // Required for ember-api-actions
  ajax(url, type, options = {}) {
    return this.ajaxService.request(type, url, options.data, this.headers).promise;
  }

  createRecord(_store, type, snapshot, options = {}) {
    const data = this.serialize(snapshot, { includeId: true });
    const url =
      options.url ?? this.buildURL(type.modelName, null, null, 'createRecord');
    const method = options.method ?? 'POST';

    return this.ajaxService.request(method, url, data, this.headers).promise;
  }

  updateRecord(_store, type, snapshot, options = {}) {
    const data = this.serialize(snapshot, { includeId: true });
    const id = snapshot.id;
    const url =
      options.url ?? this.buildURL(type.modelName, id, snapshot, 'updateRecord');
    const method = options.method ?? 'PUT';

    return this.ajaxService.request(method, url, data, this.headers).promise;
  }

  deleteRecord(_store, type, snapshot, options = {}) {
    const data = this.serialize(snapshot, { includeId: true });
    const id = snapshot.id;
    const url =
      options.url ?? this.buildURL(type.modelName, id, snapshot, 'deleteRecord');
    const method = options.method ?? 'DELETE';

    return this.ajaxService.request(method, url, data, this.headers).promise;
  }

  findAll(_store, type, options = {}) {
    const url = options.url ?? this.buildURL(type.modelName, null, null, 'findAll');
    const method = options.method ?? 'GET';

    return this.ajaxService.request(method, url, null, this.headers).promise;
  }

  findMany(_store, type, ids, snapshot, options = {}) {
    const url = options.url ?? this.buildURL(type.modelName, ids, snapshot, 'findMany');
    const method = options.method ?? 'GET';

    return this.ajaxService.request(
      method,
      url,
      { filter: { id: ids.join(',') } },
      this.headers
    );
  }

  findRecord(_store, type, id, snapshot, options = {}) {
    const url =
      options.url ?? this.buildURL(type.modelName, id, snapshot, 'findRecord');
    const method = options.method ?? 'GET';

    return this.ajaxService.request(method, url, null, this.headers).promise;
  }

  query(_store, type, query, options = {}) {
    const url =
      options.url ?? this.buildURL(type.modelName, null, null, 'query', query);
    const method = options.method ?? 'GET';

    return this.ajaxService.request(method, url, query, this.headers).promise;
  }
}
