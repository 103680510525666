import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    price=this.price\n    availability=this.availability\n    reservations=this.reservations\n    contentSync=this.contentSync\n  )\n}}", {"contents":"{{yield\n  (hash\n    price=this.price\n    availability=this.availability\n    reservations=this.reservations\n    contentSync=this.contentSync\n  )\n}}","moduleName":"appkit/components/relay/stats-card/container.hbs","parseOptions":{"srcName":"appkit/components/relay/stats-card/container.hbs"}});
import Component from '@glimmer/component';
import { computed } from '@ember/object';

export default class StatsCardContainer extends Component {
  @computed('args.mergedListings.@each')
  get mergedListings() {
    return this.args.mergedListings ?? [];
  }

  @computed('mergedListings.@each.enabled')
  get price() {
    return {
      synced: this.mergedListings.filter(ml => ml.enabled).length,
      unsynced: this.mergedListings.filter(ml => !ml.enabled).length,
    };
  }

  @computed('mergedListings.@each.availabilityEnabled')
  get availability() {
    return {
      synced: this.mergedListings.filter(ml => ml.availabilityEnabled).length,
      unsynced: this.mergedListings.filter(ml => !ml.availabilityEnabled).length,
    };
  }

  @computed('mergedListings.@each.reservationsEnabled')
  get reservations() {
    return {
      synced: this.mergedListings.filter(ml => ml.reservationsEnabled).length,
      unsynced: this.mergedListings.filter(ml => !ml.reservationsEnabled).length,
    };
  }

  @computed('mergedListings.@each.contentSyncingEnabled')
  get contentSync() {
    return {
      synced: this.mergedListings.filter(ml => ml.contentSyncingEnabled).length,
      unsynced: this.mergedListings.filter(ml => !ml.contentSyncingEnabled).length,
    };
  }
}
