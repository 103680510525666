import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

// TODO: Implementation notes: We will need to receive the country for the markets
const TRANSLATED_COUNTRY_MARKETS = new Map();
TRANSLATED_COUNTRY_MARKETS.set('Spain', { translationPath: 'spain' });

/**
 * Recovers the market name from the translation file using its Id and the country name
 * 
 * If it doesn't exist, it will use the API untranslated version
 *
 * @Ussage
 * {{translations/market-name countryName marketId marketName}}

 */
export default class MarketName extends Helper {
  @service intl;

  compute(payload) {
    try {
      const [countryName, marketId, sourceMarketName] = payload;
      const fallbackEventName = sourceMarketName || '';

      if (!countryName && !marketId) return '';

      const countryFile = TRANSLATED_COUNTRY_MARKETS.get(countryName).translationPath;
      const TRANSLATION_PATH = `country-markets.${countryFile}.${marketId}`;
      const isMarketTranslated = this.intl.exists(TRANSLATION_PATH);

      if (isMarketTranslated) return this.intl.t(TRANSLATION_PATH);

      return fallbackEventName;
    } catch (e) {
      const fallbackEventName = payload?.sourceEventName || '';
      return fallbackEventName;
    }
  }
}
