import triggerTheme from '../trigger/index.theme';
import selectedItemMultipleTheme from '../selected-item/multiple.theme';
import { VARIANTS, COLORS } from '../../../theme';

const searchVariantClasses = ({ active }) => ({
  [VARIANTS.OUTLINED]: {
    [COLORS.ALL]: ['placeholder-gray-600'],
  },
  [VARIANTS.CONTAINED]: {
    [COLORS.BRAND]: [
      'text-white',
      'placeholder-white',
      !active && 'bg-brand-400',
      active && 'bg-brand-500',
    ],
  },
});

const counterVariantClasses = ({ highlight }) => ({
  [VARIANTS.ALL]: {
    [COLORS.ALL]: ['text-brand-500', highlight ? 'bg-white' : 'bg-brand-200'],
  },
});

export default {
  root: {
    ...triggerTheme.root,
  },
  chevron: {
    ...triggerTheme.chevron,
  },
  tagsContainer: {
    defaultClasses: () => [
      'flex',
      'items-center',
      'flex-wrap',
      'w-full',
      'overflow-hidden',
      '-mt-1',
    ],
  },
  tag: {
    defaultClasses: () => ['flex', 'items-center', 'mt-1', 'max-w-full'],
  },
  placeholder: {
    defaultClasses: () => ['mt-1'],
  },
  search: {
    defaultClasses: () => [
      'border-0',
      'p-0',
      'appearance-none',
      'outline-none',
      'mt-1',
      'transition-none',
    ],
    fontSizeClasses: triggerTheme.root.fontSizeClasses,
    variantClasses: searchVariantClasses,
  },
  counter: {
    defaultClasses: () =>
      selectedItemMultipleTheme.tagText.defaultClasses({ noWrap: false }),
    variantClasses: counterVariantClasses,
    fontSizeClasses: selectedItemMultipleTheme.tagText.fontSizeClasses,
    spacingClasses: selectedItemMultipleTheme.tagText.spacingClasses,
  },
};
