import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    company=this.data.company\n    firstName=this.data.firstName\n    lastName=this.data.lastName\n    email=this.data.email\n    phone=this.data.phone\n    city=this.data.city\n    listings=this.data.listings\n    textFields=this.textFields\n    formErrors=this.formErrors\n    checkErrors=this.checkErrors\n    canSubmit=this.canSubmit\n    isSubmitted=this.isSubmitted\n    listingOptions=this.listingOptions\n    actions=(hash\n      onFieldChange=this.onFieldChange onGoBack=this.onGoBack onSubmit=this.onSubmit\n    )\n  )\n}}", {"contents":"{{yield\n  (hash\n    company=this.data.company\n    firstName=this.data.firstName\n    lastName=this.data.lastName\n    email=this.data.email\n    phone=this.data.phone\n    city=this.data.city\n    listings=this.data.listings\n    textFields=this.textFields\n    formErrors=this.formErrors\n    checkErrors=this.checkErrors\n    canSubmit=this.canSubmit\n    isSubmitted=this.isSubmitted\n    listingOptions=this.listingOptions\n    actions=(hash\n      onFieldChange=this.onFieldChange onGoBack=this.onGoBack onSubmit=this.onSubmit\n    )\n  )\n}}","moduleName":"appkit/components/connect/assisted/container.hbs","parseOptions":{"srcName":"appkit/components/connect/assisted/container.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Util from 'appkit/lib/util';
import { ChannelUtil } from 'appkit/lib/channel';

class FormData {
  @tracked company;
  @tracked firstName;
  @tracked lastName;
  @tracked email;
  @tracked phone;
  @tracked city;
  @tracked listings;
}

const listingOptions = ['1-10', '11-25', '26-50', '51-100', '101-500', '501+'];

const validators = {
  required: {
    fn: value => !!value,
    msg: () => 'This field is required',
  },
  email: {
    fn: value => !Util.isEmailInvalid(value, false),
    msg: value => Util.isEmailInvalid(value, false),
  },
};

export default class InsightsConnectAssistedContainer extends Component {
  @service router;
  @service contactApi;
  @tracked data = new FormData();
  @tracked formErrors = {};

  textFields = [
    {
      name: 'company',
      label: 'glossary.company',
      type: 'text',
      placeholder: 'glossary.company',
      validations: [validators.required],
    },
    {
      name: 'firstName',
      label: 'glossary.firstName',
      type: 'text',
      placeholder: 'glossary.firstName',
      validations: [validators.required],
    },
    {
      name: 'lastName',
      label: 'glossary.lastName',
      type: 'text',
      placeholder: 'glossary.lastName',
      validations: [validators.required],
    },
    {
      name: 'email',
      label: 'glossary.email',
      type: 'text',
      placeholder: 'glossary.email',
      validations: [validators.required, validators.email],
    },
    {
      name: 'phone',
      label: 'glossary.phone',
      type: 'text',
      placeholder: 'glossary.phone',
      validations: [validators.required],
    },
    {
      name: 'city',
      label: 'glossary.city',
      type: 'text',
      placeholder: 'glossary.city',
      validations: [validators.required],
    },
    {
      name: 'listings',
      label: 'glossary.listings',
      type: 'select',
      options: listingOptions,
      placeholder: 'connect.selectRange',
      validations: [validators.required],
    },
  ];

  get product() {
    return this.args.product ?? 'Pricing';
  }

  get canSubmit() {
    return Object.keys(this.formErrors).reduce((acc, cur) => {
      return acc && this.formErrors[cur] == null;
    }, true);
  }

  @action
  async checkErrors(fields, isFocused) {
    if (isFocused) return;

    fields = Array.isArray(fields) ? fields : [fields];

    for (const field of fields) {
      const fieldValue = this.data[field];

      const { validations } = this.textFields.find(f => f.name === field);

      for (const v of validations) {
        if (!(await v.fn(fieldValue))) {
          this.formErrors[field] = await v.msg(fieldValue);
          break;
        } else {
          this.formErrors[field] = null;
        }
      }
    }

    // trigger update
    this.formErrors = this.formErrors;
  }

  @action
  onFieldChange(field, evt) {
    this.data[field] = evt.target ? evt.target.value : evt;
  }

  @action
  onGoBack() {
    this.router.transitionTo(this.args.backToRoute ?? 'dashboard.connect');
  }

  @action
  async onSubmit() {
    await this.checkErrors(this.textFields.map(f => f.name));

    if (this.canSubmit) {
      const payload = {
        product: this.product,
        company: this.data.company,
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        phoneNumber: this.data.phone,
        city: this.data.city,
        numListings: this.data.listings,
        PMS: ChannelUtil.getChannelLabel(this.args.channel),
      };

      try {
        await this.contactApi.submit('Connect PMS', this.data.email, payload);
        this.args.onSubmitted();
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  }
}
