import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
export default class ForgotRoute extends Route {
  @service
  alert;

  @action
  onSubmit() {
    const alert = this.alert;
    this.controller.set('posting', true);
    let email = this.get('controller.email');

    this.ajax
      ._post('/api/recover', { email: email })
      .then(() => {
        alert.success('Check your email for a link to reset your password.');
        this.transitionTo('index');
      })
      .finally(() => {
        this.controller.set('posting', false);
      });

    return false;
  }
}
