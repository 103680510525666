import ButtonComponent from './button';
import { Themeable, VARIANTS } from '../theme';
import toggleableButtonTheme from './toggleable-button.theme';

export default class BpToggleableButtonGroupButtonComponent extends Themeable(
  ButtonComponent,
  {
    theme: toggleableButtonTheme,
    variant: VARIANTS.OUTLINED,
  }
) {
  get themeOptions() {
    return {
      ...super.themeOptions,
      isToggled: this.isToggled,
    };
  }

  get toggled() {
    return this.args.toggled ?? [];
  }

  get isToggled() {
    return this.toggled.includes(this.args.toggleKey);
  }
}
