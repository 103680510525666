/* eslint-disable ember/no-observers */
import { computed, action, set, get } from '@ember/object';
import { observes } from '@ember-decorators/object';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-mixins
import flatListingsMixin from 'appkit/mixins/flat-listings';
// eslint-disable-next-line ember/no-mixins
import dashboardSearchParamsMixin from 'appkit/mixins/dashboard-search-params';
// eslint-disable-next-line ember/no-mixins
import filtersMixin from 'appkit/mixins/dashboard-filter';
import { getOwner } from '@ember/application';
import $ from 'jquery';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { STATUS_MAPPING } from 'appkit/bp-models/booking_review_listing';
import { UserUtil } from 'appkit/lib/user-util';
import { CurrencyUtil } from 'appkit/lib/currency';
import { displayErrors } from 'appkit/lib/display_errors';
import moment from 'moment';
import { htmlSafe } from '@ember/template';

export default class IndexController extends Controller.extend(
  flatListingsMixin,
  dashboardSearchParamsMixin,
  filtersMixin
) {
  @service alert;
  @service('list-control') listControl;
  @service router;
  @service intl;
  @service ajax;

  @service('booking-review') bookingReview;
  @service savedFiltersApi;
  @service featureFlag;
  @service currentUser;
  @service tableView;
  @service hog;
  @service embed;

  preserveScrollPosition = true;
  @controller('dashboard.pricing.index.bulk-edit') bulkEditController;

  /**  account */
  @tracked allListingsSelected = false;
  @tracked isFilterVisible = false;
  @tracked confirmBulkActions = false;
  @tracked isBulkUpdatesOngoing = null;
  @tracked isInUsabilityRenewalSurveyGroup = false;

  completingSuggestion = false;
  completedSuggestion = false;
  completingApproveAll = false;
  completedApproveAll = false;
  isBulkUpdatesEnabled = true;
  @tracked showCancelRevewModal = false;
  @tracked customBookingReviewExpirationDate = null;

  @tracked editingListing = null;
  @tracked editingMinListing = null;
  @tracked editingListingRev = null;

  @tracked showBulkSuggestion = false;
  @tracked showMapView = false;
  @tracked showListingSyncFeedbackLoop = false;
  @tracked selectedListingsIds = [];
  @tracked monthlyPricing = false;
  @tracked hasSpecialRequirementsEnabled = false;

  @tracked isAddFirstPaymentMethodBannerAvailable = false;
  @tracked isReferralCodeBannerVisible = true;
  @tracked isReferralLinkProgramBannerAvailable = false;
  @tracked customTableViewEnabled = false;
  @tracked moreTableViewOptsIsVisible = false;
  @tracked tableViewColumnsSetupIsVisible = false;

  @tracked tableViewPreferences = this.tableView.preferences;
  @tracked tableViewLoadedPreferences = null;
  @tracked tableViewColumnsOrder = null;
  @tracked tableViewOffset = htmlSafe('transform: translateX(0px)');
  @tracked tableViewOffsetValue = 0;
  @tracked customTableViewNewFeaturePopup = false;
  @tracked showRejectBookingReviewModal = false;
  @tracked showAcceptBookingReviewModal = false;

  @tracked approverNotes = null;
  @tracked approverNotesOther = null;
  @tracked completingRejectAll = false;

  @tracked isAutoBookingReviewAvailable = false;
  @tracked revenueGoals = this.model.revenueGoals;
  @tracked isCardViewDeprecationBannerDismissed =
    JSON.parse(sessionStorage.getItem('dismissCardViewDeprecationBanner')) ?? false;

  @action
  goToMap() {
    this.transitionToRoute('dashboard.pricing.map');
  }

  @computed('model.listings.@each.permissions')
  get allListingsAreRestrictedToView() {
    return !(this.model.listings.filter(l => l.permissions != 'view').length > 0);
  }

  @computed('customTableViewNewFeaturePopup', 'model.listings.[]', 'appearance')
  get isCustomTableViewNewFeaturePopupVisible() {
    if (!this.model.listings.some(l => l.enabled)) return false;
    if (this.appearance != 'table-view') return false;
    return this.customTableViewNewFeaturePopup;
  }

  @computed('model.listings.[]')
  get enabledListingsAmount() {
    return this.model.listings.filter(l => l.enabled).length;
  }

  @computed('flatListings')
  get listings() {
    return this.flatListings.filter(listing => !listing.isSubUnit);
  }

  @computed('tableViewPreferences.@each.visibility', 'tableViewOffsetValue')
  get tableViewOffsetLimit() {
    let header = document.getElementById('table-view-header');
    let headerChild = header.firstElementChild;

    let headerWidth = header.getBoundingClientRect().width;
    let columnWidth = headerChild.firstElementChild.getBoundingClientRect().width;
    let navUIWidth = header.lastElementChild.getBoundingClientRect().width;
    let columnsGapWidth = Number(getComputedStyle(headerChild).gap.split('p')[0]);

    let columns = this.customColumns.filter(c => c.visibility).length;

    return (columnWidth + columnsGapWidth) * columns + navUIWidth * 2 - headerWidth;
  }

  @computed('tableViewPreferences.@each.{visibility,sortOrder}')
  get canConfirmUpdateTableViewPreferences() {
    let control = this.tableViewPreferences
      .map(c => (c.visibility ? c.key : null))
      .filter(c => c);

    let checkForIndexes =
      this.tableViewColumnsOrder.length == this.tableViewLoadedPreferences.length
        ? this.tableViewColumnsOrder
            .map(
              i =>
                this.tableViewColumnsOrder.indexOf(i) ==
                this.tableViewLoadedPreferences.indexOf(i)
            )
            .some(i => i == false)
        : false;

    if (checkForIndexes) return false;

    let comparisonCheck =
      control.length >= this.tableViewLoadedPreferences.length
        ? control.map(i => this.tableViewLoadedPreferences.includes(i))
        : this.tableViewLoadedPreferences.map(i => control.includes(i));

    return !comparisonCheck.some(i => i == false);
  }

  @computed(
    'tableViewPreferences.@each.{visibility,sortOrder,selected}',
    'currentUser.isStaff'
  )
  get customColumns() {
    return this.tableViewPreferences
      .map(column => {
        if (['booking-pace'].includes(column.identifier)) {
          return { ...column, visibility: this.currentUser.isStaff };
        }
        return {
          ...column,
        };
      })
      .filter(column => column.visibility == true)
      .sort((a, b) => a.sortOrder - b.sortOrder);
  }

  /**  account */

  @computed('model.accountErrors')
  get accountBlocked() {
    return !!this.model.accountErrors;
  }

  @computed('model.accountErrors')
  get errorMessage() {
    const errors = this.model.accountErrors;
    if (!errors) {
      return '';
    }
    if (!errors.length) {
      return '';
    }

    return errors.map(errorMessage => `${errorMessage.message}: ${errorMessage.title}`);
  }

  /**  bulk edit */

  // @type {Array}
  @computed('selectedListingsIds.[]', 'model.listings.[]')
  get selectedListings() {
    return this.model.listings.filter(l => this.selectedListingsIds.includes(l.id));
  }

  @computed('selectedListings.[]')
  get selectedListingsBookingReview() {
    const bookingReviewListingIds = this.model.bookingReviewListings
      .filter(brl => brl.status === STATUS_MAPPING.SUGGESTION_COMPLETED)
      .map(brl => brl.id);
    return this.selectedListings.filter(
      l =>
        l.bookingReviewListingIds.filter(br => bookingReviewListingIds.includes(br))
          .length > 0
    );
  }

  @computed(
    'selectedListings.[]',
    'bulkEditController.excludeSpecialRequirements',
    'hasSpecialRequirementsEnabled'
  )
  get showSpecialRequirementsMsg() {
    return (
      this.hasSpecialRequirementsEnabled &&
      this.selectedListings.find(l => l.specialRequirements) &&
      !this.bulkEditController.excludeSpecialRequirements
    );
  }

  @computed('selectedListings.[]')
  get selectedListingsWithoutSR() {
    return this.selectedListings.filter(l => !l.specialRequirements);
  }
  @computed('selectedListings.[]', 'selectedListingsWithoutSR.[]')
  get srExplanationStar() {
    return this.selectedListings.length > this.selectedListingsWithoutSR.length
      ? '*'
      : '';
  }

  @computed('selectedListings.[]')
  get selectedListingsIncludeMultiUnit() {
    return this.selectedListings.some(l => l.channelListings[0].subUnits.length > 0);
  }

  @tracked bulkEditDropdownOptionsIsVisible = false;
  bulkEditDropdownOptions = [
    {
      src: 'price-sync',
      name: 'Base Price',
      route: 'dashboard.pricing.index.bulk-edit.min-stays',
    },
  ];

  /**  booking review */

  _getMostRecentBookingReview(bookingReviews) {
    const bookingReviewsSorted = bookingReviews
      .slice()
      .map(br => {
        const date = moment(br.createdAt).format('YYYY-MM-DD HH:mm:ss');
        br['createdAt'] = date;
        return br;
      })
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return bookingReviewsSorted[0];
  }

  @computed('model.bookingReviews.[]')
  get mostRecentBookingReview() {
    if (!this.model.bookingReviews?.length) {
      return null;
    }
    return this._getMostRecentBookingReview(this.model.bookingReviews);
  }

  @computed('mostRecentBookingReview')
  get mostRecentBookingReviewExpiration() {
    let date =
      this.mostRecentBookingReview?.expiresAt ||
      this.customBookingReviewExpirationDate ||
      moment().add(7, 'days');
    return date ? moment(date).fromNow() : null;
  }

  // @computed('model.bookingReviews.[]')
  // get mostRecentBookingReview() {
  //   if (!this.model.bookingReviews.length) {
  //     return null;
  //   }

  //   const bookingReviewsSorted = this.model.bookingReviews
  //     .map(br => {
  //       const date = moment(br.createdAt).format('YYYY-MM-DD HH:mm:ss');
  //       br['createdAt'] = date;
  //       return br;
  //     })
  //     .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  //   return bookingReviewsSorted[0];
  // }

  @computed('model.bookingReviewListings', 'mostRecentBookingReview.id')
  get bookingReviewListingIds() {
    return (
      this.model?.bookingReviewListings
        ?.filter(brl => brl.bookingReviewId === this.mostRecentBookingReview.id)
        ?.map(brl => brl.listingId) || []
    );
  }

  @computed(
    'model.bookingReviewListings',
    'mostRecentBookingReview.id',
    'selectedListingsBookingReview'
  )
  get bookingReviewListOfChanges() {
    let brListings = this.model?.bookingReviewListings?.filter(
      brl =>
        brl.bookingReviewId === this.mostRecentBookingReview.id &&
        brl.status === STATUS_MAPPING.SUGGESTION_COMPLETED
    );

    if (
      this.selectedListingsBookingReview.length > 0 &&
      brListings.length > this.selectedListingsBookingReview.length
    ) {
      brListings = brListings.filter(brl => {
        return this.selectedListingsBookingReview.find(l =>
          l.bookingReviewListingIds.includes(brl.id)
        );
      });
    }

    let listBasePrice = [];
    let listMinPrice = [];
    let listOtherChanges = 0;
    for (const brl of brListings) {
      let brlIsChanged = false;
      if (
        brl.suggestedBasePricePercentage !== -100 &&
        brl.suggestedBasePricePercentage !== 0
      ) {
        brlIsChanged = true;
        const lbpItem = listBasePrice.findIndex(
          lbp => lbp.percentage === brl.suggestedBasePricePercentage
        );
        if (lbpItem > -1) {
          listBasePrice[lbpItem].count = listBasePrice[lbpItem].count++;
        } else {
          listBasePrice.push({
            percentage: brl.suggestedBasePricePercentage,
            count: 1,
          });
        }
      }
      if (
        brl.suggestedMinPricePercentage !== -100 &&
        brl.suggestedBasePricePercentage !== 0
      ) {
        brlIsChanged = true;
        const lbpMinItem = listMinPrice.findIndex(
          lbp => lbp.percentage === brl.suggestedMinPricePercentage
        );
        if (lbpMinItem > -1) {
          listMinPrice[lbpMinItem].count = lbpMinItem.count++;
        } else {
          listMinPrice.push({
            percentage: brl.suggestedMinPricePercentage,
            count: 1,
          });
        }
      }
      if (!brlIsChanged) listOtherChanges++;
    }
    return {
      minPriceChange: listMinPrice.sort((a, b) => b.percentage - a.percentage),
      basePriceChange: listBasePrice.sort((a, b) => b.percentage - a.percentage),
      otherChanges: listOtherChanges,
    };
  }

  @computed('bookingReviewListingIds', 'model.listings.[]')
  get canEditBookingReviewListingsCount() {
    return this.model.listings
      .filter(l => l.canEdit)
      .filter(l => this.bookingReviewListingIds.includes(l.id)).length;
  }

  @computed('currentUser.globalPermission')
  get hasEditAllPermission() {
    return ['admin', 'edit'].includes(this.currentUser.globalPermission);
  }

  @computed(
    'bookingReviewListingIds',
    'canEditBookingReviewListingsCount',
    'hasEditAllPermission'
  )
  get canApproveAllInBookingReview() {
    const canEditAllBookingReviewListings =
      this.canEditBookingReviewListingsCount === this.bookingReviewListingIds.length;

    return this.hasEditAllPermission || canEditAllBookingReviewListings;
  }

  @computed('canEditBookingReviewListingsCount', 'hasEditAllPermission')
  get canEditListingInBookingReview() {
    const canEditSomeBookingReviewListings = this.canEditBookingReviewListingsCount > 0;

    return this.hasEditAllPermission || canEditSomeBookingReviewListings;
  }

  @computed('mostRecentBookingReview.completedAt')
  get hasUncompletedBookingReview() {
    return this.mostRecentBookingReview?.completedAt === null;
  }

  @computed('mostRecentBookingReview.id', 'model.bookingReviewListings.@each.status')
  get mostRecentBookingReviewListings() {
    if (
      !this.model.bookingReviewListings.length ||
      !get(this, 'mostRecentBookingReview')
    ) {
      return [];
    }

    return this.model.bookingReviewListings.filter(
      brl => brl.bookingReviewId === get(this, 'mostRecentBookingReview.id')
    );
  }

  @computed(
    'mostRecentBookingReviewListings.length',
    'mostRecentBookingReview.@each.completedAt',
    'listing.@each.{basePriceSuggestionAdded,minPriceSuggestionAdded}'
  )
  get listingsNeedReviewCount() {
    const listingsNeverReviewd = this.model.listings.filter(
      l => !l.bookingReviewListings.filter(b => b).length
    );
    const listingsReviewedInPastBatch = this.model.listings.filter(l =>
      get(l, 'mostRecentBookingReview.bookingReview.completedAt')
    );

    const listingsReviewedWithChanges = this.model.listings.filter(
      l => l.basePriceSuggestionAdded || l.minPriceSuggestionAdded
    );

    let listingsNeedReviewIDs = [
      ...new Set(
        [
          ...listingsNeverReviewd,
          ...listingsReviewedInPastBatch,
          ...listingsReviewedWithChanges,
        ].map(l => l.id)
      ),
    ];

    let listingsNeedReviewCount = this.model.listings
      .filter(l => listingsNeedReviewIDs.includes(l.id))
      // only include avairable listings
      .filter(l => get(l, 'primaryChannelListing.listed'))
      .filter(l => get(l, 'inActiveMarket'));

    return listingsNeedReviewCount.length;
  }

  @computed('model.bookingReviewListings.@each.status')
  get listingsNeedReviewCountForApprover() {
    return this.model.bookingReviewListings.filter(brl => brl.isSuggestionCompleted)
      .length;
  }

  @computed('model.bookingReviewListings.@each.status')
  get listingsAlreadyReviewedCount() {
    return this.model.bookingReviewListings
      .filter(brl => get(brl, 'isAlreadyReviewed'))
      .filter(brl => !get(brl, 'bookingReview.completedAt')).length;
  }

  @computed('mostRecentBookingReviewListings.@each.status')
  get hasPendingReviews() {
    const listingsHavePendingReview = get(
      this,
      'mostRecentBookingReviewListings'
    ).filter(br => br.hasPendingSuggestion);
    return !!listingsHavePendingReview.length;
  }

  @computed(
    'mostRecentBookingReviewListings.@each.status',
    'hasUncompletedBookingReview'
  )
  get displayDiscardBookingReviewButton() {
    const allReviewNotCompleted =
      this.mostRecentBookingReviewListings.filter(
        brl => brl.status === STATUS_MAPPING.SUGGESTED
      ).length > 0;

    if (this.hasUncompletedBookingReview && allReviewNotCompleted) {
      return true;
    }

    return false;
  }

  @computed('mostRecentBookingReviewListings.@each.status')
  get hasSubmittedReviews() {
    const listingsSubmittedReview = get(this, 'mostRecentBookingReviewListings').filter(
      br => br.isSuggestionCompleted
    );
    return !!listingsSubmittedReview.length;
  }

  @computed('mostRecentBookingReviewListings.@each.status')
  get listingsSubmittedReviewCount() {
    const listingsSubmittedReview = get(this, 'mostRecentBookingReviewListings').filter(
      br => br.isSuggestionCompleted
    );
    return listingsSubmittedReview.length;
  }

  @computed('model.bookingReviewListings.@each.status')
  get nextNeedReviewListingId() {
    const nextNeedReviewListings = this.flatListings.filter(l => {
      if (!l.mostRecentBookingReview) {
        return true;
      }
      return (
        l.mostRecentBookingReview.status !== STATUS_MAPPING.SUGGESTED &&
        l.mostRecentBookingReview.status !== STATUS_MAPPING.SUGGESTION_COMPLETED
      );
    });

    if (!nextNeedReviewListings.length) {
      return null;
    }

    return nextNeedReviewListings[0].id;
  }

  @computed('model.bookingReviewListings.@each.status')
  get nextNeedReviewListingIdForApprover() {
    const nextNeedReviewListings = this.flatListings
      .filter(l => l.mostRecentBookingReview)
      .filter(
        l =>
          l.mostRecentBookingReview.bookingReviewId ===
          get(this, 'mostRecentBookingReview.id')
      )
      .filter(l => {
        return (
          l.mostRecentBookingReview.status === STATUS_MAPPING.SUGGESTED ||
          l.mostRecentBookingReview.status === STATUS_MAPPING.SUGGESTION_COMPLETED
        );
      });
    if (!nextNeedReviewListings.length) return null;
    return nextNeedReviewListings[0].id;
  }

  @computed('mostRecentBookingReview.completedAt')
  get mostRecentBookingReviewIsPending() {
    return !!get(this, 'mostRecentBookingReview.completedAt') === false;
  }

  @computed('hasSubmittedReviews', 'mostRecentBookingReviewIsPending')
  get displayApproveAllSubHeader() {
    return this.hasSubmittedReviews && get(this, 'mostRecentBookingReviewIsPending');
  }

  @computed(
    'displayApproveAllSubHeader',
    'mostRecentBookingReviewIsPending',
    'listingsAlreadyReviewedCount'
  )
  get displayCompleteSubHeader() {
    return (
      !this.displayApproveAllSubHeader &&
      this.mostRecentBookingReviewIsPending &&
      this.listingsAlreadyReviewedCount
    );
  }

  @computed(
    'bookingReview.isRunningReview',
    'currentUser.isBookingReviewSubmitterEnabled',
    'displayApproveAllSubHeader',
    'displayCompleteSubHeader'
  )
  get displayBookingReviewSubHeader() {
    if (this.currentUser.isBookingReviewSubmitterEnabled) {
      // submitter case
      return this.bookingReview.isRunningReview;
    } else {
      // approver case
      return this.displayApproveAllSubHeader || this.displayCompleteSubHeader;
    }
  }

  @computed('showListingSyncFeedbackLoop', 'currentUser.isStaff')
  get displayListingSyncFeedbackLoopUI() {
    return this.showListingSyncFeedbackLoop;
  }

  @observes(
    'displayBookingReviewSubHeader',
    'bookingReview.isRunningReview',
    'currentUser.isBookingReviewSubmitterEnabled',
    'displayApproveAllSubHeader',
    'displayCompleteSubHeader'
  )
  _updateIsBookingReviewMode() {
    if (!this.router.currentRoute) return;
    if (this.router.currentRoute.name !== 'dashboard.grid.filter') {
      this.bookingReview.set(
        'displayBookingReviewSubHeader',
        this.displayBookingReviewSubHeader
      );
    }
  }

  @computed('model.accounts.@each.valid')
  get invalidAccounts() {
    return this.model.accounts.filter(item => !item.valid);
  }

  @computed('model.accounts.@each.channel')
  get isVrboTransitionVisible() {
    return this.model.accounts.map(a => a.channel).includes('homeaway_web');
  }

  @computed('model.user.{status,defaultPaymentMethod}')
  get isBillingBannerVisible() {
    const { status, defaultPaymentMethod, isBlocked } = this.model.user;
    return UserUtil.isUserBlocked(status, isBlocked, defaultPaymentMethod);
  }

  @computed('model.user.{isBlocked,status,locale}')
  get billingBannerMessage() {
    const { status, isBlocked } = this.model.user;
    return UserUtil.blockedUserMessage(isBlocked, status);
  }

  /** vertical collection */

  @computed('appearance')
  get listHeight() {
    const appearance = this.appearance;

    if (appearance === 'card-view') {
      return 238;
    } else if (appearance === 'table-view') {
      return 65;
    } else {
      // fallback
      return 100;
    }
  }

  /** view */
  get viewOptions() {
    let out = [
      {
        name: this.intl.t('pricing.dashboard'),
        svgUrl: 'card-view',
        beta: false,
      },
      { name: this.intl.t('pricing.table'), svgUrl: 'table-view', beta: false },
      { name: this.intl.t('pricing.grid'), svgUrl: 'grid-view', beta: false },
    ];

    out = this.isAutoBookingReviewAvailable
      ? [
          ...out,
          {
            name: this.intl.t('pricing.autoBookingReview'),
            svgUrl: 'auto-booking-review-view',
            beta: false,
          },
        ]
      : out;

    return out;
  }

  get tableViewVariants() {
    return {
      tv:
        this.displayCheckbox &&
        (this.hasNewListings || this.hasSuggestionAddedListings),
      tv_nocb:
        !this.displayCheckbox &&
        (this.hasNewListings || this.hasSuggestionAddedListings),
      tv_noactn:
        this.displayCheckbox &&
        !(this.hasNewListings || this.hasSuggestionAddedListings),
      tv_nocb_noactn:
        !this.displayCheckbox &&
        !(this.hasNewListings || this.hasSuggestionAddedListings),
    };
  }

  // @type {Object}
  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed('appearance', 'viewOptions.[]', 'mode')
  get selectedView() {
    if (this._selectedView) {
      return this._selectedView;
    }
    if (this.appearance === 'card-view' && this.mode === 'new') {
      this.embed.czCaptureEvent(
        'Dashboard',
        'User viewed pricing dashboard in Dashboard Card View'
      );

      return this.viewOptions[0];
    } else if (this.appearance === 'table-view' && this.mode === 'legacy') {
      this.embed.czCaptureEvent(
        'Table View',
        'User viewed pricing dashboard in Table View'
      );
      return this.viewOptions[1];
    } else {
      // fallback to card-view just in case
      return this.viewOptions[0];
    }
  }

  set selectedView(value) {
    if (value?.name) {
      this.embed.czCaptureEvent(
        `${value.name} View', 'User viewed pricing dashboard in ${value.name} View`
      );
    }
    return (this._selectedView = value);
  }

  @computed()
  get appearance() {
    let out = localStorage.getItem('appearance') || 'card-view';
    if (out === 'grid-view') {
      out = 'card-view';
      localStorage.setItem('appearance', 'card-view');
    }
    // Old appearances were list/table, now we're using card/table. Update
    // old clients.
    if (['list-view', 'merge-view'].includes(out)) {
      localStorage.setItem('appearance', 'card-view');
      out = 'card-view';
    }
    return out;
  }

  set appearance(value) {
    if (value) {
      localStorage.setItem('appearance', value);
    } else {
      localStorage.removeItem('appearance');
    }
    return localStorage.getItem('appearance');
  }

  @computed()
  get mode() {
    let out = localStorage.getItem('mode') || 'legacy';

    if (this.appeareance === 'grid-view') {
      out = 'legacy';
      localStorage.setItem('mode', 'legacy');
    }
    return out;
  }

  set mode(value) {
    if (value) {
      localStorage.setItem('mode', value);
    } else {
      localStorage.removeItem('mode');
    }
    return localStorage.getItem('mode');
  }

  @computed('appearance', 'isBulkUpdatesEnabled')
  get displayCheckbox() {
    return this.isBulkUpdatesEnabled && this.appearance === 'table-view';
  }

  @computed('model.listings.@each.isNewListing')
  get hasNewListings() {
    const newListings = this.model.listings.filter(l => l.isNewListing);
    return newListings.length > 0;
  }

  @computed('model.listings.@each.suggestionAdded')
  get hasSuggestionAddedListings() {
    const suggestionAddedListings = this.model.listings.filter(l => l.suggestionAdded);
    return suggestionAddedListings.length > 0;
  }

  /** saved filter */
  @computed('model.savedFilters.length')
  get savedFilters() {
    return this.model.savedFilters;
  }

  /**  bulk edit */
  @computed('selectedListings.length')
  get bulkEditActionIsVisible() {
    // if one or more listings are selected, display bulk edit options
    return this.selectedListings.length > 0;
  }

  get noPaymentMethodAdded() {
    return (
      !this.model.user.primaryCardId &&
      !this.model.user.paypalAccountEmail &&
      !this.model.user.bankAccountDetails &&
      this.isAddFirstPaymentMethodBannerAvailable
    );
  }

  get referrerLink() {
    let referrerCode = this.model.user.referrerCode;
    return `https://beyondpricing.com/#r=${referrerCode}`;
  }

  @computed('approverNotes', 'approverNotesOther')
  get isDeleteButtonDisabled() {
    if (
      (this.approverNotes === this.intl.t('pricing.otherReason') &&
        this.approverNotesOther !== null) ||
      (this.approverNotes !== this.intl.t('pricing.otherReason') &&
        this.approverNotes !== null)
    ) {
      return false;
    }
    return true;
  }

  @action
  closeRejectModal() {
    set(this, 'showRejectBookingReviewModal', null);
    set(this, 'approverNotes', null);
    set(this, 'approverNotesOther', null);
  }

  /**  sort */
  @action
  changeSortField(field) {
    this.listControl.changeSortField(field);
  }

  /** listings */
  @action
  editBasePrice(listing) {
    this.editingListing = listing;
  }

  @action
  editMinPrice(listing) {
    this.editingMinListing = listing;
  }

  @action
  editRevenueGoal(listing) {
    this.editingListingRev = listing;
  }

  @action
  async toggleEnabled(listing, enabled) {
    if (![true, false].includes(enabled)) {
      throw Error('Invalid enabled state');
    }
    set(listing, 'enabled', enabled);
    let result = await listing.saveWithValidation();

    if (!result) {
      // eslint-disable-next-line require-atomic-updates
      set(listing, 'enabled', listing._dirty.enabled);
      return;
    }

    if (enabled) {
      this.alert.success(
        `OK! Updating prices on your ${listing.allChannelLabels} calendar.`
      );
    } else {
      this.alert.success('Price syncing disabled.');
    }
  }

  @action
  async enablePricing(listing) {
    listing.enabled = true;
    let result = await listing.saveWithValidation();

    if (!result) {
      // eslint-disable-next-line require-atomic-updates
      listing.enabled = listing._dirty.enabled;
      return;
    }

    this.alert.success(
      `OK! Updating prices on your ${listing.allChannelLabels} calendar.`
    );
  }

  @action
  async saveBasePrice(listing, event) {
    this.editingListing = null;
    // We actually use a bound property so this isn't needed, but eventually
    // I'd like to switch the app-currency-helper to be DDAU.

    let currency = listing.currency;

    let basePrice = event.target.value;
    if (basePrice < 10) {
      let validationError = this.intl.t('validation.pricingErrors.minBasePrice', {
        value: CurrencyUtil.format(10, {
          currency,
        }),
      });
      this.alert.error(validationError, { timeout: 10000 });
      return;
    }

    listing.basePrice = basePrice;

    if (!listing.isDirty) {
      return;
    }

    try {
      await listing.save();
    } catch (err) {
      this.alert.error('validation.pricingErrors.basePriceSave', { timeout: 10000 });
    }
  }

  @action
  async saveMinPrice(listing, event) {
    this.editingMinListing = null;

    let currency = listing.currency;

    let minPrice = event.target.value;
    if (minPrice < 5) {
      let validationError = this.intl.t('validation.pricingErrors.minMinPrice', {
        value: CurrencyUtil.format(5, {
          currency,
        }),
      });
      this.alert.error(validationError, { timeout: 10000 });
      return;
    }

    listing.minPrice = minPrice;

    if (!listing.isDirty) {
      return;
    }

    try {
      await listing.save();
    } catch (err) {
      this.alert.error('validation.pricingErrors.minPriceSave', { timeout: 10000 });
    }
  }

  @action
  async saveRevenueGoal(listing, event) {
    this.editingListingRev = null;

    let revenueGoal = event.target.value;

    try {
      await this.ajax._post(`/api/pricing/listings/${listing.id}/revenue-goals`, [
        {
          year: moment(new Date()).year(),
          target: revenueGoal,
        },
      ]);
      this.revenueGoals = this.revenueGoals.map(rg => ({
        ...rg,
        target: rg.listingId == listing.id ? revenueGoal : rg.target,
      }));
    } catch (err) {
      this.alert.error(err);
    }
  }

  @action
  async notify(listing) {
    listing.notify = true;
    await listing.save();
    this.alert.success("OK! We'll let you know.");
  }

  @action
  updateApproverNotes(e) {
    set(this, 'approverNotesOther', e.target.value === '' ? null : e.target.value);
  }

  /** view */

  @action
  changeAppearance(selectedView) {
    if (selectedView.name === this.intl.t('pricing.dashboard')) {
      set(this, 'mode', 'new');
      this.transitionToRoute({
        queryParams: { appearance: 'card-view', mode: 'new' },
      });

      this.selectedView = selectedView;
    } else if (selectedView.name === this.intl.t('pricing.table')) {
      set(this, 'appearance', 'table-view');
      set(this, 'mode', 'legacy');
      this.selectedView = selectedView;
    } else if (selectedView.name === this.intl.t('pricing.grid')) {
      this.transitionToRoute('dashboard.grid');
    } else if (selectedView.name === 'Map') {
      this.transitionToRoute('dashboard.map');
    } else if (selectedView.name === this.intl.t('pricing.autoBookingReview')) {
      this.transitionToRoute('dashboard.pricing.booking-review');
    }
  }

  @action
  transitionToDashboard() {
    set(this, 'mode', 'new');
    this.selectedView = this.viewOptions.find(
      v => v.name === this.intl.t('pricing.dashboard')
    );
    this.transitionToRoute({
      queryParams: { appearance: 'card-view', mode: 'new' },
    });
  }
  @action
  transitionToNewTableView() {
    set(this, 'mode', 'new');
    this.selectedView = this.viewOptions.find(
      v => v.name === this.intl.t('pricing.dashboard')
    );
    this.transitionToRoute({
      queryParams: { appearance: 'table-view', mode: 'new' },
    });
  }

  @action dismissCardViewDeprecationBanner() {
    sessionStorage.setItem('dismissCardViewDeprecationBanner', 'true');
    this.isCardViewDeprecationBannerDismissed = true;
  }

  /**  filter */
  @action
  toggleFilterSidePanel(type) {
    if (type === 'close') {
      this.isFilterVisible = false;
    }

    later(
      this,
      () => {
        this.transitionToRoute(this.toggleFilterRoutePath);
      },
      100
    );
  }

  /** saved filter */
  @action
  closeShowFilterModal() {
    this.listControl.closeShowFilterModal();
  }

  @action
  closeEditFilterModal() {
    this.listControl.closeEditFilterModal();
  }

  @action
  closeConfirmationBulkUpdate() {
    this.confirmBulkActions = false;
  }

  @action
  async saveNewFilter() {
    try {
      // api call
      await this.savedFiltersApi.saveNewFilter({
        name: this.listControl.newFilterName,
        content: this.listControl.updatedQueryParams,
      });

      // reset
      this.listControl.resetAllFilters();
      this.send('updateSearchParams', this.listControl.defaultQueryParams);
      this.listControl.resetInitialAndUpdatedQueryParams();

      // reload
      getOwner(this).lookup('route:dashboard').refresh();
    } finally {
      this._resetSavedFilterAttr();
    }
  }

  @action
  async editFilter(newFilterName) {
    let savedFilter;

    // set saved filter depends on how user is updating filter
    if (this.listControl.currentlySelectedSavedFilter) {
      savedFilter = this.listControl.currentlySelectedSavedFilter;
      // case for updating via modal
    } else {
      savedFilter = this.listControl.selectedSavedFilter;
      // case for updating via update button
    }

    try {
      // api call
      if (newFilterName) {
        await this.savedFiltersApi.renameFilter(savedFilter, {
          name: this.listControl.newFilterName,
        });
      } else {
        await this.savedFiltersApi.editFilter(savedFilter, {
          content: this.listControl.updatedQueryParams,
        });
      }
      // reset
      this.listControl.initialQueryParams.setProperties(
        this.listControl.updatedQueryParams
      );

      // reload
      getOwner(this).lookup('route:dashboard').refresh();
    } finally {
      this._resetSavedFilterAttr();
    }
  }

  @action
  async deleteFilter() {
    try {
      // api call
      await this.savedFiltersApi.deleteFilter(
        this.listControl.currentlySelectedSavedFilter
      );

      // reset
      this.listControl.resetAllFilters();
      this.send('updateSearchParams', this.listControl.defaultQueryParams);
      this.listControl.resetInitialAndUpdatedQueryParams();

      // reload
      getOwner(this).lookup('route:dashboard').refresh();
    } finally {
      this._resetSavedFilterAttr();
    }
  }

  // booking review

  @action
  toggleRunReviewState() {
    this.bookingReview.set(
      'displayBookingReviewSubHeader',
      !this.bookingReview.isRunningReview
    );
    this.bookingReview.toggleRunReviewState();
  }

  @action
  async cancelBookingReview() {
    this.showCancelRevewModal = false;
    this.toggleRunReviewState();

    if (!this.hasUncompletedBookingReview) {
      return;
    }

    const cancellingBookingReviewId = this.mostRecentBookingReview.id;
    let url = `/api/pricing/booking-reviews/discard`;
    const data = { booking_review_id: cancellingBookingReviewId };
    try {
      await this.ajax._post(url, data);
    } catch (errors) {
      displayErrors({ errors: errors, alert: this.alert });
      return;
    }

    const mostRecentBookingReview = this._getMostRecentBookingReview(
      this.model.bookingReviews
    );

    set(mostRecentBookingReview, 'completedAt', new Date());
    this.model.bookingReviewListings
      ?.filter(brl => brl.bookingReviewId === mostRecentBookingReview.id)
      ?.forEach(brl => set(brl, 'status', STATUS_MAPPING.CANCELED));

    this.router.transitionTo('dashboard.pricing.index');
  }

  @action
  async clearSuggestion(targetBookingReviewListingId) {
    let bookingReviewListing = this.model.bookingReviewListings.find(
      brl => brl.id == targetBookingReviewListingId
    );

    if (bookingReviewListing) {
      this.model.bookingReviewListings.removeObject(bookingReviewListing);
    }
  }

  @action
  async completeSuggestion() {
    let url = '/api/booking_reviews/complete_suggestions';
    set(this, 'completingSuggestion', true);

    const data = {};
    data['booking_review_listing_ids'] = this.model.bookingReviewListings
      .filter(brl => brl.status === STATUS_MAPPING.SUGGESTED)
      .map(brl => brl.id);

    if (this.customBookingReviewExpirationDate) {
      data['expires_at'] = moment(this.customBookingReviewExpirationDate).format(
        'YYYY-MM-DD'
      );
    }

    let response;
    try {
      response = await this.ajax._put(url, data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      return;
    } finally {
      set(this, 'completingSuggestion', false);
      if (response) {
        set(this, 'completedSuggestion', true);
      }
    }

    // update booking review models
    const completedReviews = response.completedReviews.map(bookingReviewListing => {
      return this.bpStore.createRecord('bookingReviewListing', bookingReviewListing);
    });

    const mergedBookingReviews = this._mergeById(
      this.model.bookingReviewListings,
      completedReviews
    );

    set(this, 'model.bookingReviewListings', mergedBookingReviews);

    // update listings models
    this.model.listings
      .filter(l => l.mostRecentBookingReview)
      .filter(l => l.mostRecentBookingReview.hasPendingSuggestion)
      .forEach(l =>
        l.mostRecentBookingReview.set('status', STATUS_MAPPING.SUGGESTION_COMPLETED)
      );

    // Update model and store record to reflect submitted status
    let mostRecentBookingReview = this.bpStore.peekRecord(
      'bookingReview',
      this.mostRecentBookingReview.id
    );
    let expiresAt = data['expires_at'] || moment().add(14, 'days').format('YYYY-MM-DD');
    let submittedAt = moment().format('YYYY-MM-DD');
    set(mostRecentBookingReview, 'submittedAt', submittedAt);
    set(mostRecentBookingReview, 'expiresAt', expiresAt);
    set(this.mostRecentBookingReview, 'submittedAt', submittedAt);
    set(this.mostRecentBookingReview, 'expiresAt', expiresAt);
    this.bookingReview.updateBookingReviewStatus();

    if (
      !(
        this.bookingReview.showCustomerFacingBartFeature &&
        !this.bookingReview.currentUser.isStaff
      )
    ) {
      this.alert.success(
        'The suggestions have been successfully sent to the customer for review'
      );
    }
  }

  @action
  finishRunningReview() {
    set(this, 'completedSuggestion', false);
    this.send('toggleRunReviewState');
  }

  @action
  async approveAllSuggestions() {
    let url = '/api/pricing/booking_reviews_legacy/approve';
    set(this, 'completingApproveAll', true);

    let bookingReviewListingIds = this.model.bookingReviewListings
      .filter(brl => brl.status === STATUS_MAPPING.SUGGESTION_COMPLETED)
      .map(brl => brl.id);

    let message = this.intl.t('pricing.allSuggestionsApprovedSuccesfully');
    this.embed.czCaptureEvent(
      'accept-all-recommendations',
      'User accepted all booking review recommendations'
    );

    if (
      this.selectedListingsBookingReview.length > 0 &&
      bookingReviewListingIds.length > this.selectedListingsBookingReview.length
    ) {
      message = this.intl.t('pricing.suggestionsApprovedSuccesfully', {
        count: this.selectedListingsBookingReview.length,
      });
      bookingReviewListingIds = bookingReviewListingIds.filter(brId => {
        return this.selectedListingsBookingReview.find(l =>
          l.bookingReviewListingIds.includes(brId)
        );
      });
    }

    const data = {};
    data['booking_review_listing_ids'] = bookingReviewListingIds;

    let response;
    let updatedListings;
    try {
      response = await this.ajax._post(url, data);
      updatedListings = await this.ajax._get('/api/accounts/listings');
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      return;
    } finally {
      set(this, 'completingApproveAll', false);
      if (response) {
        set(this, 'completedApproveAll', true);
      }
    }

    response.bookingReviewListings.map(bookingReviewListing => {
      return this.bpStore.createRecord('bookingReviewListing', bookingReviewListing);
    });

    // update cache data with relationships
    const completedReviewListings = response.bookingReviewListings.map(
      bookingReviewListing => {
        return this.bpStore.createRecord('bookingReviewListing', bookingReviewListing);
      }
    );

    const mergedBookingReviewListings = this._mergeById(
      this.model.bookingReviewListings,
      completedReviewListings
    );

    set(this, 'model.bookingReviewListings', mergedBookingReviewListings);

    // update listings models
    this.model.listings
      .filter(l => l.mostRecentBookingReview)
      .filter(l => bookingReviewListingIds.includes(l.mostRecentBookingReview.id))
      .forEach(l => {
        const updatedListing = updatedListings.listings.find(nl => nl.id === l.id);

        // update listing model
        l.set('basePrice', updatedListing.basePrice);
        l.set('basePriceHistoryV2', updatedListing.basePriceHistoryV2);
        l.set('basePriceUpdatedAt', updatedListing.basePriceUpdatedAt);
        l.set('minPrice', updatedListing.minPrice);
        l.set('minPriceHistoryV2', updatedListing.minPriceHistoryV2);
        l.set('minPriceUpdatedAt', updatedListing.minPriceUpdatedAt);

        l.markClean();
      });

    set(this, 'showAcceptBookingReviewModal', false);
    set(this, 'selectedListingsIds', []);
    this.listings.map(listing => {
      set(listing, 'selected', false);
    });
    this.alert.success(message);
  }

  @action
  async rejectAllSuggestions() {
    let url = '/api/pricing/booking_reviews_legacy/reject';
    set(this, 'completingRejectAll', true);

    let bookingReviewListingIds = this.model.bookingReviewListings
      .filter(brl => brl.status === STATUS_MAPPING.SUGGESTION_COMPLETED)
      .map(brl => brl.id);

    let message = this.intl.t('pricing.allSuggestionsRejectedSuccesfully');

    if (
      this.selectedListingsBookingReview.length > 0 &&
      bookingReviewListingIds.length > this.selectedListingsBookingReview.length
    ) {
      message = this.intl.t('pricing.suggestionsRejectedSuccesfully', {
        count: this.selectedListingsBookingReview.length,
      });
      bookingReviewListingIds = bookingReviewListingIds.filter(brId => {
        return this.selectedListingsBookingReview.find(l =>
          l.bookingReviewListingIds.includes(brId)
        );
      });
    }

    let approverNotes = `${this.approverNotes}; ${this.approverNotesOther}`;

    const data = {};
    data['booking_review_listing_ids'] = bookingReviewListingIds;
    data['approver_notes'] = approverNotes;

    let response;
    let updatedListings;
    try {
      response = await this.ajax._post(url, data);
      updatedListings = await this.ajax._get('/api/accounts/listings');
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      return;
    } finally {
      set(this, 'completingRejectAll', false);
      if (response) {
        set(this, 'completedRejectAll', true);
      }
    }

    response.bookingReviewListings.map(bookingReviewListing => {
      return this.bpStore.createRecord('bookingReviewListing', bookingReviewListing);
    });

    // update cache data with relationships
    const completedReviewListings = response.bookingReviewListings.map(
      bookingReviewListing => {
        return this.bpStore.createRecord('bookingReviewListing', bookingReviewListing);
      }
    );

    const mergedBookingReviewListings = this._mergeById(
      this.model.bookingReviewListings,
      completedReviewListings
    );

    set(this, 'model.bookingReviewListings', mergedBookingReviewListings);

    // update listings models
    this.model.listings
      .filter(l => l.mostRecentBookingReview)
      .filter(l => bookingReviewListingIds.includes(l.mostRecentBookingReview.id))
      .forEach(l => {
        const updatedListing = updatedListings.listings.find(nl => nl.id === l.id);

        // update listing model
        l.set('basePrice', updatedListing.basePrice);
        l.set('basePriceHistoryV2', updatedListing.basePriceHistoryV2);
        l.set('basePriceUpdatedAt', updatedListing.basePriceUpdatedAt);
        l.set('minPrice', updatedListing.minPrice);
        l.set('minPriceHistoryV2', updatedListing.minPriceHistoryV2);
        l.set('minPriceUpdatedAt', updatedListing.minPriceUpdatedAt);

        l.markClean();
      });

    set(this, 'showRejectBookingReviewModal', false);
    set(this, 'selectedListingsIds', []);
    set(this, 'approverNotes', null);
    set(this, 'approverNotesOther', null);
    this.listings.map(listing => {
      set(listing, 'selected', false);
    });
    this.alert.success(message);
  }

  @action
  async completeBookingReview() {
    let url = `/api/booking_reviews/${get(
      this,
      'mostRecentBookingReview.id'
    )}/complete`;

    let response;
    const data = {};
    data['date_and_time'] = moment().format();

    try {
      response = await this.ajax._put(url, data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      return;
    }

    this.bpStore.createRecord('bookingReview', response.bookingReview);
    this.model.bookingReviews.pushObject(response.bookingReview);

    this.alert.success('Completed booking review successfully');
  }

  // bulk edit

  @action
  clickedSelectAllCheckbox() {
    if (this.allListingsSelected) {
      this.deselectAllListings();
      this.cancelBulkEdit();
    } else {
      this.selectAllListings();
    }
  }

  @action
  selectAllListings() {
    // clear existing selectedListingsIds
    set(this, 'selectedListingsIds', []);
    this.allListingsSelected = true;
    const listingsToSelect = this.flatListings.filter(
      l =>
        l.permissions !== 'view' &&
        (l.channelListings.length === 0 ||
          (l.channelListings.length > 0 && !l.channelListings[0].multiUnitParentId))
    );

    for (let l of listingsToSelect) {
      set(l, 'selected', true);
      this.selectedListingsIds.pushObject(l.id);
    }
  }

  @action
  deselectAllListings() {
    // clear existing selectedListingsIds
    set(this, 'selectedListingsIds', []);
    this.allListingsSelected = false;

    for (let l of this.model.listings) {
      set(l, 'selected', false);
    }
  }

  @action
  addSelectedListing(listing, option) {
    if (option.checked) {
      // checked
      this.selectedListingsIds.pushObject(listing.id);
      set(listing, 'selected', true);
    } else {
      // unchecked
      this.selectedListingsIds.removeObject(listing.id);
      set(listing, 'selected', false);
      if (this.selectedListingsIds.length === 0) {
        this.send('cancelBulkEdit');
      }
    }
  }

  @action
  cancelBulkEdit() {
    for (let sl of this.selectedListings) {
      set(sl, 'selected', false);
    }
    this.send('deselectAllListings');
    this.send('hideBulkEditDropdownOptions');
    this.transitionToRoute('dashboard.pricing.index');
  }

  @action
  toggleBulkEditDropdownOptions() {
    this.bulkEditDropdownOptionsIsVisible = !this.bulkEditDropdownOptionsIsVisible;
  }

  @action
  displayBulkEditDropdownOptions() {
    this.bulkEditDropdownOptionsIsVisible = true;
  }

  @action
  hideBulkEditDropdownOptions() {
    this.bulkEditDropdownOptionsIsVisible = false;
  }

  @action
  clickedBulkEditOption() {
    // hide dropdown
    this.send('hideBulkEditDropdownOptions');

    // move to bulk edit route
    this.transitionToRoute('dashboard.pricing.index.bulk-edit');
  }

  @action
  clickedBulkSuggestionOption() {
    this.transitionToRoute('dashboard.pricing.index.bulk-suggestion');
  }

  @action
  saveBulkSettings() {
    this.hog.capture('$bulk-actions');
    this.confirmBulkActions.success();
    set(this, 'confirmBulkActions', false);
  }

  @action
  translatedWeekdays(weekdays) {
    return weekdays
      .map(d => this.intl.t(`common.weekdays.${d.toLowerCase()}`))
      .join(', ');
  }

  @action
  copyReferralLinkProgram() {
    navigator.clipboard.writeText(this.referrerLink);
    this.alert.info(this.intl.t('pricing.referralLinkCopied'), { timeout: 5000 });
  }

  @action
  async confirmTableViewPreferences() {
    const preferences = this.tableViewColumnsOrder;

    const payload = { tableViewColumns: preferences };

    try {
      await this.ajax._post('/api/pricing/credential/preferences', payload);
      this.alert.success(
        this.intl.t('validation.dashboardErrors.tableViewPreferencesSuccess')
      );
      this.tableViewLoadedPreferences = this.tableViewColumnsOrder;
    } catch {
      this.alert.error(
        this.intl.t('validation.dashboardErrors.tableViewPreferencesError')
      );
      return;
    } finally {
      this.resetTableViewPreferences();
    }
  }

  @action
  resetTableViewPreferences() {
    this.tableViewColumnsSetupIsVisible = false;
    this.tableViewPreferences = this.tableViewPreferences.map(column => {
      if (['booking-pace'].includes(column.identifier)) return { ...column };

      return {
        ...column,
        visibility: this.tableViewLoadedPreferences.includes(column.key),
        selected: false,
      };
    });

    this.tableViewColumnsOrder = this.tableViewLoadedPreferences;
    this.listControl.sortFieldsPreferences = this.tableViewLoadedPreferences;
    this.updateSortingOrder();
  }

  @action
  moveTableLeft() {
    let header = document.getElementById('table-view-header');

    let headerChild = header.firstElementChild;
    let columnWidth = headerChild.firstElementChild.getBoundingClientRect().width;
    let columnsGap = Number(getComputedStyle(headerChild).gap.split('p')[0]);

    if (this.tableViewOffsetValue >= this.tableViewOffsetLimit) return;

    this.tableViewOffsetValue =
      this.tableViewOffsetValue + columnWidth + columnsGap > this.tableViewOffsetLimit
        ? this.tableViewOffsetLimit
        : this.tableViewOffsetValue + columnWidth + columnsGap;

    this.tableViewOffset = htmlSafe(
      `transform: translateX(-${this.tableViewOffsetValue}px)`
    );
  }

  @action
  moveTableRight() {
    let header = document.getElementById('table-view-header');

    let headerChild = header.firstElementChild;

    let columnWidth = headerChild.firstElementChild.getBoundingClientRect().width;
    let columnsGap = Number(getComputedStyle(headerChild).gap.split('p')[0]);

    if (this.tableViewOffsetValue <= 0) return;

    this.tableViewOffsetValue =
      this.tableViewOffsetValue - (columnWidth + columnsGap) < 0
        ? 0
        : this.tableViewOffsetValue - (columnWidth + columnsGap);

    this.tableViewOffset = htmlSafe(
      `transform: translateX(-${this.tableViewOffsetValue}px)`
    );
  }

  @action
  updateColumnVisibility(column) {
    this.tableViewPreferences = this.tableViewPreferences.map(c => {
      if (['booking-pace'].includes(c.identifier)) return { ...c };

      return {
        ...c,
        visibility: c.key == column.key ? !column.visibility : c.visibility,
        selected: c.key == column.key ? false : c.selected,
      };
    });

    this.tableViewColumnsOrder = column.visibility
      ? this.tableViewColumnsOrder.filter(p => p != column.key)
      : [column.key, ...this.tableViewColumnsOrder];

    this.updateSortingOrder();

    if (!column.visibility) return;

    this.updateTableViewOffsetPosition();
  }

  @action
  updateTableViewOffsetPosition() {
    let header = document.getElementById('table-view-header');

    let headerChild = header.firstElementChild;

    let columnWidth = headerChild.firstElementChild.getBoundingClientRect().width;
    let columnsGap = Number(getComputedStyle(headerChild).gap.split('p')[0]);

    this.tableViewOffsetValue =
      this.tableViewOffsetValue + columnWidth + columnsGap > this.tableViewOffsetLimit
        ? this.tableViewOffsetLimit
        : this.tableViewOffsetValue;

    this.tableViewOffset = htmlSafe(
      `transform: translateX(-${this.tableViewOffsetValue}px)`
    );
  }

  @action
  updateSortingOrder() {
    this.tableViewPreferences = this.tableViewPreferences.map(column => {
      if (['booking-pace'].includes(column.identifier)) {
        if (column.identifier == 'booking-pace') {
          let controlIdx = [5, 6, 7].filter(i =>
            this.tableViewColumnsOrder.includes(i)
          );

          return {
            ...column,
            sortOrder:
              controlIdx.length > 0
                ? this.tableViewColumnsOrder.indexOf(
                    controlIdx[controlIdx.length - 1]
                  ) + 1.5
                : this.tableViewColumnsOrder.length + 1.5,
          };
        }
      }

      return {
        ...column,
        sortOrder: this.tableViewColumnsOrder.includes(column.key)
          ? this.tableViewColumnsOrder.indexOf(column.key) + 1
          : this.tableViewColumnsOrder.length + 1,
      };
    });
  }

  @action
  sortColumnOrder(direction) {
    if (!this.tableViewPreferences.some(c => c.selected)) return;

    let itemToOffset = this.tableViewPreferences.filter(c => c.selected)[0]?.key;
    let itemToOffsetPosition = this.tableViewColumnsOrder.indexOf(itemToOffset);
    let offsetIdx =
      direction == 'asc' ? itemToOffsetPosition - 1 : itemToOffsetPosition + 2;

    if (
      (itemToOffsetPosition == 0 && direction == 'asc') ||
      (itemToOffsetPosition == this.tableViewColumnsOrder.length - 1 &&
        direction == 'desc')
    )
      return;

    this.tableViewColumnsOrder = [
      ...this.tableViewColumnsOrder.slice(0, offsetIdx).filter(i => i != itemToOffset),
      itemToOffset,
      ...this.tableViewColumnsOrder.slice(offsetIdx).filter(i => i != itemToOffset),
    ];

    this.updateSortingOrder();
  }

  @action
  selectColumn(key) {
    this.tableViewPreferences = this.tableViewPreferences.map(c => {
      return { ...c, selected: c.key == key ? !c.selected : false };
    });
  }

  @action
  closeCustomTableViewNewFeaturePopup() {
    localStorage.setItem(
      'customTableViewNewFeaturePopup',
      JSON.stringify({
        visited: true,
      })
    );

    this.customTableViewNewFeaturePopup = false;
  }

  @action
  highlightCustomTableViewAccess() {
    this.moreTableViewOptsIsVisible = true;
    this.closeCustomTableViewNewFeaturePopup();
  }

  constructor() {
    super(...arguments);

    this.featureFlag.evaluate('listing-special-requirements', false).then(value => {
      this.hasSpecialRequirementsEnabled = value;
    });

    this.featureFlag.evaluate('bulk-suggestion', false).then(value => {
      this.showBulkSuggestion = value;
    });

    this.featureFlag.evaluate('map-view', false).then(value => {
      this.showMapView = value;
    });

    this.featureFlag.evaluate('listing-sync-feedback-loop', false).then(value => {
      this.showListingSyncFeedbackLoop = value || this.currentUser.isStaff;
    });

    this.featureFlag.evaluate('monthly-pricing-v-1', false).then(value => {
      this.monthlyPricing = value;
    });

    this.featureFlag.evaluate('add-first-payment-method-banner', false).then(value => {
      this.isAddFirstPaymentMethodBannerAvailable = value;
    });

    this.featureFlag
      .evaluate('referral-link-program-in-dashboard-banner', false)
      .then(value => {
        this.isReferralLinkProgramBannerAvailable = value;
      });

    this.featureFlag.evaluate('react-booking-review', false).then(enabled => {
      this.isAutoBookingReviewAvailable = enabled;
    });

    this.featureFlag.evaluate('custom-table-view', false).then(enabled => {
      if (enabled) {
        this.ajax
          ._get('/api/pricing/credential/preferences')
          .then(result => {
            if (result.preferences === null) {
              this.tableViewPreferences = this.tableView.preferences.map(
                (column, idx) => {
                  if (['booking-pace'].includes(column.identifier))
                    return { ...column, sortOrder: idx + 1 };
                  return { ...column, visibility: column.standard, sortOrder: idx + 1 };
                }
              );
              return;
            }
            this.tableViewPreferences = this.tableViewPreferences.map(column => {
              if (['booking-pace'].includes(column.identifier)) {
                if (column.identifier == 'booking-pace') {
                  let controlIdx = [5, 6, 7].filter(i =>
                    result.preferences.tableViewColumns.includes(i)
                  );

                  return {
                    ...column,
                    sortOrder:
                      controlIdx.length > 0
                        ? result.preferences.tableViewColumns.indexOf(
                            controlIdx[controlIdx.length - 1]
                          ) + 1.5
                        : result.preferences.tableViewColumns.length + 1.5,
                  };
                }
              }

              return {
                ...column,
                visibility: result.preferences.tableViewColumns.includes(column.key),
                sortOrder: result.preferences.tableViewColumns.includes(column.key)
                  ? result.preferences.tableViewColumns.indexOf(column.key) + 1
                  : result.preferences.tableViewColumns.length + 1,
              };
            });
          })
          .catch(() => {
            this.tableViewPreferences = this.tableView.preferences.map(
              (column, idx) => {
                if (['booking-pace'].includes(column.identifier))
                  return { ...column, sortOrder: idx + 1 };
                return { ...column, visibility: column.standard, sortOrder: idx + 1 };
              }
            );
          })
          .finally(() => {
            this.tableViewLoadedPreferences = [...this.tableViewPreferences]
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map(c => (c.visibility ? c.key : null))
              .filter(c => c);

            this.listControl.sortFieldsPreferences = this.tableViewLoadedPreferences;
            this.tableViewColumnsOrder = this.tableViewLoadedPreferences;
          });

        if (localStorage.getItem('customTableViewNewFeaturePopup')) {
          let customTableViewNewFeaturePopup = JSON.parse(
            localStorage.getItem('customTableViewNewFeaturePopup')
          );

          this.customTableViewNewFeaturePopup = !customTableViewNewFeaturePopup.visited;
        } else {
          localStorage.setItem(
            'customTableViewNewFeaturePopup',
            JSON.stringify({
              visited: false,
            })
          );

          this.customTableViewNewFeaturePopup = true;
        }
      }

      this.customTableViewEnabled = enabled;
    });

    this._clickedPricingPageHandler = this._clickedPricingPage.bind(this);

    // register clicking page event
    $('body').on('click', this._clickedPricingPageHandler);
  }

  willDestroy() {
    $('body').unbind('click', this._clickedPricingPageHandler);
  }

  _resetSavedFilterAttr() {
    set(this, 'listControl.saveFilterModalIsVisible', false);
    set(this, 'listControl.editFilterModalIsVisible', false);
    set(this, 'listControl.deleteFilterModalIsVisible', false);
    set(this, 'listControl.currentlySelectedSavedFilter', null);
    set(this, 'listControl.newFilterName', '');
    /*this.listControl.saveFilterModalIsVisible = false;
    this.listControl.editFilterModalIsVisible = false;
    this.listControl.deleteFilterModalIsVisible = false;
    this.listControl.currentlySelectedSavedFilter = null;
    this.listControl.newFilterName = '';*/
  }

  _clickedPricingPage(e) {
    if (!this.bulkEditDropdownOptionsIsVisible) {
      return;
    }
    if ($(e.target).parents().hasClass('bulk-edit-wrap')) {
      return;
    }

    set(this, 'bulkEditDropdownOptionsIsVisible', false);
  }

  _mergeById(old, updated) {
    const o = {};

    old.forEach(function (v) {
      o[v.id] = v;
    });

    updated.forEach(function (v) {
      o[v.id] = v;
    });

    const r = [];

    for (let p in o) {
      if (Object.prototype.hasOwnProperty.call(o, p)) r.push(o[p]);
    }

    return r;
  }
}
