import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { action, set } from '@ember/object';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';

@classic
@tagName('div')
@classNames('app-radio-bulk-input')
export default class AppRadioBulkInput extends Component {
  furtherOptions = null;
  areFurtherOptionsAvailable = false;
  @tracked mainItemSelected = '';
  @tracked detailItemSelected = '';

  @tracked
  selectedReasons = [];

  get shouldDisabledSubmitBtn() {
    if (this.mainItemSelected === '') {
      return true;
    }

    const dataReasonSelected = this.reasonsForAction.reasons.find(
      r => r.id === this.mainItemSelected
    );

    if (
      dataReasonSelected &&
      dataReasonSelected.hasDetails &&
      !this.selectedReasons[0].includes('::')
    ) {
      return true;
    }

    const dataExtraReasonSelected =
      dataReasonSelected &&
      this.reasonsExtraDetails[dataReasonSelected.id]?.options?.find(r => r.hasDetails);

    if (
      dataExtraReasonSelected &&
      dataExtraReasonSelected?.id === this.detailItemSelected &&
      this.selectedReasons[0].split('::').length < 3
    ) {
      return true;
    }

    return false;
  }

  @action
  internalToggle(param, event) {
    event.preventDefault();
    if (this.reasonsForAction && (param.value == 'on' || this.selectedOption != null)) {
      set(this, 'selectedReasons', []);
      this.updateReasons(this.selectedReasons, this.shouldDisabledSubmitBtn);
    }

    this.updateSelectedOption(
      param,
      param.value === this.selectedOption ? false : true
    );
    this.toggle();
  }

  @action
  toggleFurtherOption(option) {
    set(option, 'value', null);
    this.updateValues(option);
  }

  @action
  cancelField(option) {
    set(option, 'value', undefined);
    this.updateValues(option);
  }

  @action
  updateSelectedReasons(reason) {
    let editedReason = `${reason.id}${
      reason.id == 'NEED_AIDED_WALK_THROUGH' && this.userPhoneNumber
        ? '::' + this.userPhoneNumber
        : ''
    }`;

    set(this, 'selectedReasons', [editedReason]);
    set(this, 'detailItemSelected', '');
    set(this, 'mainItemSelected', reason.id);
    this.updateReasons(this.selectedReasons, this.shouldDisabledSubmitBtn);
  }

  @action
  updateSelectedDetailReason(reason) {
    set(this, 'selectedReasons', [this.mainItemSelected + '::' + reason.id]);
    set(this, 'detailItemSelected', reason.id);
    this.updateReasons(this.selectedReasons, this.shouldDisabledSubmitBtn);
  }

  @action
  updateOtherReason(evt) {
    const reasons = [this.mainItemSelected, this.detailItemSelected, evt.target.value]
      .filter(i => i)
      .join('::');

    set(this, 'selectedReasons', [reasons]);
    this.updateReasons(this.selectedReasons, this.shouldDisabledSubmitBtn);
  }
}
