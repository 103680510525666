import Model, { attr, belongsTo } from '@ember-data/model';
import { collectionAction } from 'ember-api-actions';
import { serializeAndPush } from 'appkit/lib/models';

export default class PermissionModel extends Model {
  @attr('string', { defaultValue: 'view' }) grant;
  @belongsTo('listing', { async: false }) listing;
  @belongsTo('credential', { async: false }) credential;

  updateMany = collectionAction({
    path: 'update',
    type: 'POST',
    after: serializeAndPush,
  });

  deleteMany = collectionAction({
    path: 'delete',
    type: 'DELETE',
    after: function (response) {
      for (const id of response.permissionIds) {
        const record = this.store.peekRecord('permission', id);
        if (record) {
          this.store.unloadRecord(record);
        }
      }
    },
  });

  createMany = collectionAction({
    path: 'bulk_new',
    type: 'POST',
    after: serializeAndPush,
  });
}
