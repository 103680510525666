import buttonGroupTheme from './button.theme';
import { VARIANTS, COLORS } from '../theme';

const variantClasses = ({ isToggled }) => ({
  [VARIANTS.ALL]: {
    [COLORS.ALL]: ['border'],
    [COLORS.BRAND]: isToggled
      ? ['text-brand-500', 'bg-brand-200', 'border-brand-400', 'z-10']
      : ['text-gray-600'],
  },
});

export default {
  root: {
    ...buttonGroupTheme.root,
    variantClasses,
  },
};
