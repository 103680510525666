import moment from 'moment';

export const SEARCH_SELECTS = [
  {
    id: 'availability-select',
    label: 'is',
    operation: 'filterByAvailablility',
    selected: 0,
    options: [
      { label: 'all', value: null },
      { label: 'available', value: 'available' },
      { label: 'blocked', value: 'blocked' },
      { label: 'issue', value: 'issue' },
    ],
  },
  {
    id: 'date-select',
    label: 'dates',
    operation: 'filterByCalendarDate',
    selected: 0,
    options: [
      { label: 'all', value: null },
      //Replace this with a function call and send just unit param, otherwise the stamps will only be calculed once
      { label: 'next week', value: moment().add(1, 'week') },
      { label: 'next month', value: moment().add(1, 'month') },
      { label: 'next quarter', value: moment().add(1, 'quarter') },
      { label: 'next 6 months', value: moment().add(6, 'month') },
      { label: 'next year', value: moment().add(1, 'year') },
    ],
  },
  {
    id: 'operationDate-select',
    label: 'updated',
    operation: 'filterByOperationDate',
    selected: 0,
    options: [
      { label: 'all', value: null },
      //Replace this with a function call and send just unit param, otherwise the stamps will only be calculed once
      { label: 'last hour', value: moment().subtract(1, 'hour') },
      { label: 'last 24 hours', value: moment().subtract(24, 'hour') },
      { label: 'last week', value: moment().subtract(1, 'week') },
      { label: 'last month', value: moment().subtract(1, 'month') },
      { label: 'last quarter', value: moment().subtract(1, 'quarter') },
    ],
  },
];

export const SORT_OPTIONS = [
  {
    id: 'sort.date',
    operation: 'sortByCalendarDate',
    label: 'calendar date',
  },
  {
    id: 'sort.availability',
    operation: 'sortByAvailability',
    label: 'availability',
  },
  // {
  //   id: 'sort.lastScrap',
  //   label: 'last scrap',
  // },
  {
    id: 'sort.lastUpdate',
    operation: 'sortByMostRecentUpdate',
    label: 'last update',
  },
];

export const AVAILABILITY_FILTER_TABS = [
  {
    id: 'all',
    type: 'is',
    label: 'all',
    highlightColor: 'border-blue-600',
    isActive: false,
  },
  {
    id: 'available',
    label: 'available',
    type: 'is',
    count: 150,
    highlightColor: 'border-blue-600',
    isActive: false,
  },
  {
    id: 'blocked',
    label: 'blocked',
    type: 'is',
    count: 125,
    highlightColor: 'border-blue-600',
    isActive: false,
  },
  {
    id: 'issue',
    label: 'issues',
    type: 'is',
    count: 25,
    highlightColor: 'border-blue-600',
    counterClass: 'bg-warning-400 bg-opacity-75 text-white',
    isActive: false,
  },
];
