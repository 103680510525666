import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import logger from 'appkit/lib/logger';

export default class RefreshService extends Service {
  @service ajax;
  @tracked refreshingAccounts = [];
  @tracked accountName = '';
  @tracked accountComplete = '';
  @tracked accountListings = 0;
  @tracked listingsSynced = 0;
  @tracked status = '';
  @tracked listingId = 0;
  @tracked listingChannel = '';
  @tracked listingStatus = '';
  @tracked lastScraped = '';

  refreshAccount(accountId, accountName, totalListings) {
    let url = `/api/accounts/${accountId}/sync/force`;
    this.accountListings += totalListings;
    this.accountName = accountName;
    this.refreshingAccounts.pushObject(accountName);

    const loadPromise = this.ajax
      .stream(url, {}, data => {
        if (data.message.match(/^Syncing\slisting\s/)) {
          this.listingsSynced++;
        }
      })
      .then(
        () => {
          logger.info('Promise stream done', arguments);
          this.status = 'success';
          this.accountComplete = accountName;
          this.listingsSynced = 0;
          this.accountListings -= totalListings;
          this.refreshingAccounts = this.refreshingAccounts.filter(
            item => item !== accountName
          );
          this.hideBanner();
        },
        err => {
          logger.error('Promise stream error', err);
          this.status = 'failed';
          this.listingsSynced = 0;
          this.accountListings -= totalListings;
          this.refreshingAccounts = this.refreshingAccounts.filter(
            item => item !== accountName
          );
          this.hideBanner();
        }
      );
    return loadPromise;
  }

  refreshListing(listing) {
    this.listingId = listing.id;
    this.listingChannel = listing.primaryChannelListing__channel;
    let url = `/api/channellistings/${listing.primaryChannelListingId}/refresh`;

    const loadPromise = this.ajax
      .stream(url, {}, data => {
        console.log(data);
      })
      .then(
        async () => {
          logger.info('Promise stream done', arguments);
          await this.recentData(listing.id);
        },
        err => {
          logger.error('Promise stream error', err);
          this.listingStatus = 'failed';
          this.hideBanner();
        }
      );
    return loadPromise;
  }

  get numAccounts() {
    return this.refreshingAccounts.length;
  }

  get multipleAccounts() {
    return this.refreshingAccounts.length > 1;
  }

  get refreshing() {
    return this.refreshingAccounts.length > 0;
  }

  hideBanner() {
    setTimeout(() => {
      this.status = '';
      this.listingStatus = '';
      this.listingId = 0;
    }, 8000);
  }

  recentData(id) {
    const dataPromise = this.ajax._get(`/api/listings/${id}`).then(data => {
      this.listingStatus = 'success';
      this.lastScraped = data.channelListings[0].reservationsSyncedAt;
      this.hideBanner();
    });
    return dataPromise;
  }
}
