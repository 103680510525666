export default [
  {
    id: 'one',
    options: {
      classes: 'shepherd-tour shepherd-step-one',
      tippyOptions: {
        offset: '',
      },
      title: 'Welcome to the Beyond Pricing Calendar',
      text: [
        `This demo gives you access to your pricing calendar. Sign up to see prices for your own listing.`,
      ],
    },
  },
  {
    id: 'two',
    options: {
      attachTo: {
        element: '.mini-channel-logos',
        on: 'bottom',
      },
      classes: 'shepherd-tour shepherd-step-two',
      title: 'Pricing Across All Channels',
      text: [
        `Our pricing tools connect to your property management system and can price individual listings across all channels.`,
      ],
    },
  },
  {
    id: 'three',
    options: {
      attachTo: {
        element: '.price-inputs',
        on: 'bottom',
      },
      classes: 'shepherd-tour shepherd-step-two',
      title: 'Pricing Control For Every Listing',
      text: [
        `Our base and minimum pricing controls can be applied to each listing individually. Ensuring maximum control and profitability.`,
      ],
    },
  },
  {
    id: 'four',
    options: {
      attachTo: {
        element: '.app-calendar',
        on: '',
      },
      classes: 'shepherd-tour shepherd-step-three',
      title: 'Your whole year at a glance.',
      text: [
        'Our calendar view will allow you to easily see when and why pricing has been adjusted. Just hover over any date to see detailed pricing data.',
      ],
    },
  },
];
