import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class='flex items-center hover:shadow-md {{\n    unless @hasNoOffset 'p-2'\n  }} text-gray-800 {{if this.strippedRows 'even:bg-gray-100' ''}} {{\n    if this.selected 'bg-brand-200' ''\n  }} {{unless this.alreadyLoaded @animForNewlyAdded}}'\n  ...attributes\n>\n  {{#if @selectable}}\n    <div class='p-2 flex items-center text-base'>\n      <input\n        type='checkbox'\n        checked={{this.selected}}\n        onclick={{this.onSelect}}\n        class='form-checkbox'\n      />\n    </div>\n  {{/if}}\n  {{yield\n    (hash\n      Cell=(component 'bp/table/cell' widthClass=@widthClass)\n      actions=(hash onSelect=this.onSelect)\n    )\n    @entry\n    this.selected\n  }}\n</div>", {"contents":"<div\n  class='flex items-center hover:shadow-md {{\n    unless @hasNoOffset 'p-2'\n  }} text-gray-800 {{if this.strippedRows 'even:bg-gray-100' ''}} {{\n    if this.selected 'bg-brand-200' ''\n  }} {{unless this.alreadyLoaded @animForNewlyAdded}}'\n  ...attributes\n>\n  {{#if @selectable}}\n    <div class='p-2 flex items-center text-base'>\n      <input\n        type='checkbox'\n        checked={{this.selected}}\n        onclick={{this.onSelect}}\n        class='form-checkbox'\n      />\n    </div>\n  {{/if}}\n  {{yield\n    (hash\n      Cell=(component 'bp/table/cell' widthClass=@widthClass)\n      actions=(hash onSelect=this.onSelect)\n    )\n    @entry\n    this.selected\n  }}\n</div>","moduleName":"appkit/components/bp/table/row.hbs","parseOptions":{"srcName":"appkit/components/bp/table/row.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class BpTableRowComponent extends Component {
  get selected() {
    return this.args.selected;
  }

  get strippedRows() {
    return this.args.strippedRows;
  }

  get alreadyLoaded() {
    return this.args.alreadyLoaded;
  }

  @action
  onSelect() {
    this.args.onSelect([this.args.entry], !this.args.selected);
  }
}
