import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! Important to keep forced relative, to allow the SVG to stick in place}}\n<div style='position: relative' class={{this.classes}} ...attributes>\n  {{#if @extra.allowDelete}}\n    <div role='button' {{on 'mouseup' this.onDelete}}>\n      <AppSvg @src='trash' @class={{this.deleteIconClasses}} />\n    </div>\n  {{/if}}\n  <div class={{this.itemClasses}}>\n    <p class='whitespace-nowrap truncate'>\n      {{yield @option @select}}\n    </p>\n  </div>\n  {{#if @isSelected}}\n    <AppSvg @src='check-mark-alt' @class={{this.iconClasses}} />\n  {{/if}}\n</div>", {"contents":"{{! Important to keep forced relative, to allow the SVG to stick in place}}\n<div style='position: relative' class={{this.classes}} ...attributes>\n  {{#if @extra.allowDelete}}\n    <div role='button' {{on 'mouseup' this.onDelete}}>\n      <AppSvg @src='trash' @class={{this.deleteIconClasses}} />\n    </div>\n  {{/if}}\n  <div class={{this.itemClasses}}>\n    <p class='whitespace-nowrap truncate'>\n      {{yield @option @select}}\n    </p>\n  </div>\n  {{#if @isSelected}}\n    <AppSvg @src='check-mark-alt' @class={{this.iconClasses}} />\n  {{/if}}\n</div>","moduleName":"appkit/components/bp/select/parts/option/index.hbs","parseOptions":{"srcName":"appkit/components/bp/select/parts/option/index.hbs"}});
import Component from '@glimmer/component';
import { Themeable, VARIANTS } from '../../../theme';
import OptionTheme from './index.theme';
import { action } from '@ember/object';

export default class BpSelectPartsOptionComponent extends Themeable(Component, {
  theme: OptionTheme,
  variant: VARIANTS.TEXT,
}) {
  get themeOptions() {
    return {
      ...super.themeOptions,
      isSelected: this.args.isSelected,
      isHighlighted: this.args.isHighlighted,
      highlightOnHover: this.args.highlightOnHover,
    };
  }

  get itemClasses() {
    return this.getClassesForTheme(this.theme.item);
  }

  get deleteIconClasses() {
    return this.getClassesForTheme(this.theme.deleteIcon);
  }

  get iconClasses() {
    return this.getClassesForTheme(this.theme.checkedIcon);
  }

  @action
  onDelete(evt) {
    evt.stopPropagation();
    this.args.extra.actions?.onDelete(this.args.option, this.args.select);
  }
}
