import RESTSerializer from '@ember-data/serializer/rest';

export default class ApplicationSerializer extends RESTSerializer {
  keyForRelationship(key, _relationship, _method) {
    return `${key}Id`;
  }
  // Avoid warnings as status is not part of Ember's REST spec
  // All normalization methods use _normalizeResponse
  _normalizeResponse(_store, _primaryModelClass, payload) {
    delete payload.status;
    return super._normalizeResponse(...arguments);
  }
}
