import AuthRoute from 'appkit/routes/auth';

export default class ChannelRoute extends AuthRoute {
  controllerName = 'dashboard.connect.channel';

  // Turn off sticky params
  resetController(controller, isExiting, _transition) {
    if (isExiting) {
      controller.set('id', null);
      controller.set('idSecondary', null);
      controller.set('code', null);
      controller.set('product', null);
    }
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('connectModel', this.modelFor('dashboard.insights.connect'));
    controller.set('product', 'insights');
  }
}
