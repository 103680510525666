import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { equal, match, notEmpty } from '@ember/object/computed';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment';

@classic
@tagName('div')
@classNames('app-date-range', 'customizable')
export default class AppDateRange extends Component {
  @computed()
  get currentDate() {
    return new Date();
  }

  fields = '';
  @computed('fields')
  get fieldsArray() {
    return this.fields.split(' ');
  }

  listingValue = undefined;
  listingError = undefined;
  forceFieldDescription = false;
  startedSettingListingValue = false;
  dependentRange = [];

  @computed('ranges')
  get hasSecondRange() {
    return this.fields.split(' ').length > 1;
  }

  @notEmpty('ranges') hasRanges;

  @equal('valueType', 'int') isIntValueType;
  @equal('valueType', 'currency') isCurrencyValueType;
  @equal('valueType', 'multi') isMultiValueType;
  @match('valueType', /^select-*.*/) isSelectValueType;
  @equal('valueType', 'select-hash') isSelectHashValueType;

  @action
  setRangeStart(range, value) {
    if (!value) return;
    let startDate = range.set('startDate', value && moment(value));
    let endDate = range.get('endDate');
    if (startDate && (!endDate || startDate.isAfter(endDate))) {
      range.set('endDate', value && moment(value));
    }
    this.onRangeChange(this.ranges);
  }

  @action
  setRangeEnd(range, value) {
    if (!value) return;
    range.set('endDate', value && moment(value));
    this.onRangeChange(this.ranges);
  }

  @action
  addRanges() {
    this.addRange();
  }

  @action
  removeRanges(range) {
    this.removeRange(range);
  }

  @action
  cbRangeValueChanged(range, field, option, ev) {
    const checked = ev.target.checked;
    const newRangeValue = range.get(field) || [];
    const copyRange = newRangeValue.slice();

    if (checked) {
      copyRange.push(option);
    } else {
      copyRange.splice(copyRange.indexOf(option), 1);
    }
    range.set(field, copyRange);
    this.onRangeChange(this.ranges);
  }

  @action
  rangeValueChanged(range, field, component_value) {
    const value = component_value.target
      ? component_value.target.value
      : component_value;

    range.set(field, value);
    this.onRangeChange(this.ranges);
  }

  @action
  rangeChanged() {
    this.onRangeChange(this.ranges);
  }
}
