import ApplicationSerializer from './application';
import DS from 'ember-data';

export default class UserSerializer extends ApplicationSerializer.extend(
  DS.EmbeddedRecordsMixin,
  {
    attrs: {
      accounts: { embedded: 'always' },
      listings: { embedded: 'always' },
      permissions: { embedded: 'always' },
      credential: { embedded: 'always' },
      credentials: { embedded: 'always' },
    },
  }
) {
  normalizeResponse(_store, _primaryModelClass, payload, _id, _requestType) {
    // Permissions need to be sideloaded for Credentials to be able to load them correctly
    payload.permissions = payload.user.permissions;
    return super.normalizeResponse(...arguments);
  }
}
