import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import { action } from '@ember/object';

@classic
export default class BeyondAvailabilityRoute extends AuthRoute {
  templateName = 'data.highcharts-stock';
  controllerName = 'data.beyond-availability';

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('site', 'beyond');
    let cluster = this.modelFor('data.cluster');
    controller.set('pageTitle', `Beyond Availability -- ${cluster.get('name')}`);
    let url = `${location.origin}/admin/api/clusters/${cluster.get(
      'id'
    )}/beyond/availability.csv?token=${this.currentUser.get('token')}`;
    this.controllerFor('data.cluster').set('csvUrl', url);
    let csvFilename = `beyond-availability_${cluster.get('market.name')}_${cluster.get(
      'name'
    )}.csv`;
    this.controllerFor('data.cluster').set('csvFilename', csvFilename);
  }

  deactivate() {
    this.controllerFor('data.cluster').set('csvUrl', false);
  }

  model() {
    let cluster = this.modelFor('data.cluster');
    let url = `/admin/api/clusters/${cluster.id}/beyond/availability`;

    let filterCols = (arr, col) =>
      arr.filter(row => row[col]).map(row => [row[0], row[col]]);

    return this.ajax._get(url).then(data =>
      EmberObject.create({
        events: filterCols(data.availability, 1),
        availabilityNum: filterCols(data.availability, 2),
        availabilityPct: filterCols(data.availability, 3),
        availableEnabledPct: filterCols(data.availability, 4),
        available0BedPct: filterCols(data.availability, 5),
        available1BedPct: filterCols(data.availability, 6),
        available2BedPct: filterCols(data.availability, 7),
        available3BedPct: filterCols(data.availability, 8),
        available4BedPct: filterCols(data.availability, 9),
        availableRoomsPct: filterCols(data.availability, 10),
      })
    );
  }

  @action
  compareCluster(id) {
    this.controller.send('compareCluster', id);
  }

  @action
  compareMarket(id) {
    this.controller.send('compareMarket', id);
  }
}
