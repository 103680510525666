import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import logger from 'appkit/lib/logger';
import { getOwner } from '@ember/application';
import { displayErrors } from 'appkit/lib/display_errors';
import { inject as service } from '@ember/service';

@classic
export default class RefreshRoute extends AuthRoute {
  @service alert;
  model(params) {
    return EmberObject.create(params);
  }

  afterModel(model) {
    model.set('loadingStatus', 'loading listing');
    let url = `/api/accounts/${model.id}/sync/force`;

    let p = this.ajax.stream(url, {}, data => {
      if (data.message) {
        model.set('loadingStatus', data.message);
      }
    });

    p.then(() => {
      logger.info('Promise stream done', arguments);

      let applicationInstance = getOwner(this);
      let dashboardRoute = applicationInstance.lookup('route:dashboard');
      dashboardRoute.refresh();

      this.replaceWith('dashboard.user.accounts');
      this.alert.success('Account was successfully refreshed.');
    });

    p.catch(() => {
      logger.error('Promise stream error', arguments);
      this.replaceWith('dashboard.user.accounts');
      const errors = [{ message: 'Account refresh failed, please try again later.' }];
      displayErrors({ errors, modelOrKefywordThis: model, alert: this.alert });
    });
  }
}
