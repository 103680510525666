import moment from 'moment';

function findMedian(arr) {
  if (arr.length === 0) {
    return 0;
  }
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
}

export function generateTimeSeries(start, end, timeframe, format) {
  let startDate, endDate;
  if (timeframe === 'week') {
    startDate = moment.utc(start, format);
    endDate = moment.utc(end, format);
  } else {
    startDate = moment.utc(start, format).startOf(timeframe);
    endDate = moment.utc(end, format).startOf(timeframe);
  }

  let bounds = [];
  while (startDate.isSameOrBefore(endDate)) {
    bounds.push(startDate.clone().format(format));
    startDate.add(1, timeframe);
  }

  return bounds;
}

export function aggregateOccupancy(
  availableNights,
  occupancyStats,
  { filterByBedrooms, timeframeBounds }
) {
  let medianLeadTime = [];
  let medianStayLength = [];
  let userAvailableNights = [];
  let lastRow = null;
  let timeframeIndex = 0;
  let initialListingCount = 0;
  let boundInitial = timeframeBounds[timeframeIndex];
  let boundStart = timeframeBounds[timeframeIndex];
  let boundEnd = timeframeBounds[timeframeIndex + 1];
  let aggregator;
  let output = [];
  const fields = [
    'addedListingCount',
    'occupiedDaysCount',
    'yearAgoOccupiedDaysCount',
    'twoYearsAgoOccupiedDaysCount',
    'threeYearsAgoOccupiedDaysCount',
    'volume',
    'yearAgoVolume',
    'twoYearsAgoVolume',
    'threeYearsAgoVolume',
    'bookedAtCount',
    'checkinDateCount',
    'volumeByBookedAt',
    'volumeByCheckinDate',
    'totalCountByStayDate',
    'totalCountByBookedDate',
    'canceledCountByStayDate',
    'canceledCountByBookedDate',
    'canceledCountByCanceledDate',
  ];

  // note - this has to be sorted now. raise an error if it's not? or just sort it
  // first?
  for (let row of occupancyStats) {
    const rowDate = moment(row.date);

    // Skip stats before initial timeframe bound
    if (rowDate.isBefore(boundInitial)) {
      initialListingCount = initialListingCount + row.addedListingCount;
      continue;
    }

    // Treat null bedrooms as all bedrooms summed
    if (filterByBedrooms?.length && !filterByBedrooms.includes(row.bedrooms)) {
      continue;
    }

    if (boundEnd && rowDate.isSameOrAfter(boundEnd)) {
      if (aggregator) {
        aggregator['medianLeadTime'] = findMedian(medianLeadTime);
        aggregator['medianStayLength'] = findMedian(medianStayLength);
        aggregator['userAvailableNights'] = userAvailableNights.reduce(
          (a, b) => a + b,
          0
        );
      }
      timeframeIndex++;
      boundStart = timeframeBounds[timeframeIndex];
      boundEnd = timeframeBounds[timeframeIndex + 1];
      aggregator = null;
      medianLeadTime = [];
      medianStayLength = [];
      userAvailableNights = [];
    }

    if (!aggregator) {
      aggregator = {
        timeframe: boundStart,
        daysInTimeframe: 0,
      };
      fields.forEach(field => (aggregator[field] = 0));
      aggregator['userAvailableNights'] = 0;

      if (initialListingCount > 0) {
        aggregator.addedListingCount = initialListingCount;
        initialListingCount = 0;
      }
      output.push(aggregator);
    }
    if (row.medianLeadTime) medianLeadTime.push(row.medianLeadTime);
    if (row.medianStayLength) medianStayLength.push(row.medianStayLength);
    if (!(availableNights && Object.keys(availableNights).length === 0)) {
      userAvailableNights.push(availableNights[row.date]?.availability);
    }

    fields.forEach(field => (aggregator[field] += row[field]));

    if (!lastRow || row.date !== lastRow.date) {
      aggregator.daysInTimeframe += 1;
    }
    lastRow = row;
  }

  lastRow = null;
  for (let row of output) {
    if (lastRow) {
      row.listingCount = lastRow.listingCount + row.addedListingCount;
    } else {
      row.listingCount = row.addedListingCount;
    }
    lastRow = row;
  }

  return output;
}
