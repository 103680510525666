import { equal } from '@ember/object/computed';
import EmberObject from '@ember/object';
import { on } from '@ember/object/evented';
import { computed } from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';
import moment from 'moment';
import FactorsModel from 'appkit/pricing/factors_model';

const ListingDate = BaseModel.extend({
  init() {
    this._super(arguments);
    this._EDITABLE = [
      'date',
      'availability',
      'priceUser',
      'monthlyPriceUser',
      'minStayUser',
      'minPriceUser',
      'maxPriceUser',
      'percentageUserOverride',
    ];
    this.set('factors', this.factors ?? []);
  },

  _priceUser: null,
  priceUser: computed('_priceUser', {
    get() {
      return this._priceUser;
    },
    set(key, value) {
      this.set('_priceUser', value && Number(value));
      return this._priceUser;
    },
  }),

  _percentageUserOverride: null,
  percentageUserOverride: computed('_percentageUserOverride', {
    get() {
      return this._percentageUserOverride;
    },
    set(key, value) {
      this.set('_percentageUserOverride', value && Number(value));
      return this._percentageUserOverride;
    },
  }),

  _monthlyPriceUser: null,
  monthlyPriceUser: computed('_monthlyPriceUser', {
    get() {
      return this._monthlyPriceUser;
    },
    set(key, value) {
      this.set('_monthlyPriceUser', value && Number(value));
      return this._monthlyPriceUser;
    },
  }),

  _priceScraped: 0,
  priceScraped: computed('_priceScraped', {
    get() {
      return this._priceScraped;
    },
    set(_key, value) {
      this.set('_priceScraped', value != null ? Number(value) : null);
      return this._priceScraped;
    },
  }),

  priceReservationUserOrModeled: computed(
    'reservations',
    'priceUser',
    'priceModeled',
    function () {
      return this.get('reservations')[0]?.adr || this.priceUser || this.priceModeled;
    }
  ),

  multiUnitPriceReservationsModeledCombined: computed(
    'reservations',
    'priceUser',
    'priceModeled',
    'listing.nbSubUnits',
    function () {
      const totalAdrFromReservations = this.reservations.reduce((a, b) => a + b.adr, 0);

      const availableSubUnits = this.listing.nbSubUnits - this.reservations.length;
      const totalProjectedAdr = availableSubUnits * this.priceUserOrModeled;

      return totalAdrFromReservations + totalProjectedAdr;
    }
  ),

  priceAvgReservationsOrModeled: computed(
    'reservations',
    'priceUser',
    'priceModeled',
    function () {
      const totalAdrFromReservations = this.reservations.reduce((a, b) => a + b.adr, 0);
      const avgAdr = totalAdrFromReservations / this.reservations.length;

      return avgAdr || this.priceUser || this.priceModeled;
    }
  ),

  priceUserOrModeled: computed('priceUser', 'priceModeled', {
    get() {
      return this.priceUser || this.priceModeled;
    },
    set(key, value) {
      this.set('priceUser', Number(value));
      return this.priceUser || this.priceModeled;
    },
  }),

  _date: null,
  date: computed('_date', {
    get() {
      return this._date;
    },
    set(key, value) {
      if (!value._isAMomentObject) {
        value = moment(value);
      }
      this.set('_date', value);
      return this._date;
    },
  }),

  minStayUser: null,
  minPriceUser: null,
  dowMinPrice: null,
  maxPriceUser: null,
  availability: '',
  listing: null,

  isOverridden: computed('priceUser', 'percentageUserOverride', function () {
    return this.priceUser != null || this.percentageUserOverride != null;
  }),
  isBooked: equal('availabilityDescription', 'booked'),
  isBlocked: computed('availability', function () {
    const availability = this.availability;
    return availability.includes('blocked') || availability === 'unavailable';
  }),
  isOwnerReservation: computed('isBooked', function () {
    return this.isBooked && this.reservations[0]?.isOwner;
  }),

  isAvailable: computed(function () {
    return this.availability.includes('available');
  }),

  isAvailabilityEditable: computed(function () {
    return this.availability.includes('editable');
  }),

  isAvailabilityUneditable: computed(function () {
    return !this.isAvailabilityEditable;
  }),

  /**
     Returns normalized availability value.

     Availability can either come from the channel or from a user setting.
     In both cases the normalized name is part of the original name, hence
     this normalization can be looked at as stripping part of the string.
     e.g.: `available_editable` becomes `available`
   */
  availabilityDescription: computed('availability', function () {
    if (this.availability.includes('booked')) return 'booked';
    if (this.availability.includes('unavailable')) return 'unavailable';
    if (this.availability.includes('available')) return 'available';
    if (this.availability.includes('blocked')) return 'blocked';
    return 'unavailable';
  }),

  disabledFactors: null,
  _disabledFactorsInit_: on('init', function () {
    this.set(
      'disabledFactors',
      EmberObject.create({
        dow: false,
        seasonality: false,
        event: false,
        seasonalMins: false,
        seasonalMaxes: false,
      })
    );
  }),
  factorArray: computed(
    'factors',
    'listing.{basePrice,minPrice,maxPrice,monthlyMinPrice}',
    'disabledFactors.{dow,seasonality,event,seasonalMins,seasonalMaxes}',
    function () {
      const isMonthly = this.factors.any(f => f.key === 'monthly');
      const listingMinPrice = isMonthly
        ? this.get('listing.monthlyMinPrice')
        : this.get('listing.minPrice');
      const listingMaxPrice = isMonthly ? null : this.get('listing.maxPrice');

      const params = {
        basePrice: this.get('listing.basePrice'),
        listingMinPrice,
        listingMaxPrice,
        minPriceUser: this.minPriceUser,
        dowMinPrice: this.dowMinPrice,
        maxPriceUser: this.maxPriceUser,
        factors: this.factors,
        roundToNearestNumber: this.get('listing.roundToNearestNumber'),
      };

      return FactorsModel(params, this.disabledFactors || {});
    }
  ),

  priceModeled: computed('factorArray', {
    set() {
      // Ignore setters, e.g. when the server responds with price_modeled
      return this.factorArray[1];
    },
    get() {
      return this.factorArray[1];
    },
  }),

  priceFactors: computed('factorArray', function () {
    return this.factorArray[0];
  }),

  save() {
    let listingId = this.get('listing.id');
    this.set('saving', true);

    let url = `/api/listings/${listingId}/calendar`;
    let params = this.serialize;

    this.ajax._post(url, [params]).then(() => {
      this.set('saving', false);
      this.markClean();
    });
  },
});

function filterByKeys(obj, allowed) {
  return Object.keys(obj)
    .filter(key => allowed.includes(key))
    .reduce((res, key) => {
      res[key] = obj[key];
      return res;
    }, {});
}

ListingDate.reopenClass({
  async saveMany(listingDates, properties) {
    let listingId = listingDates[0].get('listing.id');
    let url = `/api/listings/${listingId}/calendar`;
    let params = listingDates.map(date =>
      properties
        ? filterByKeys(date.get('serialize'), properties)
        : date.get('serialize')
    );

    for (let date of listingDates) {
      date.set('saving', true);
    }

    // TODO: work out how to inject ajax into the base class
    let ajax = listingDates[0].ajax;

    return ajax._post(url, params).then(() => {
      for (let date of listingDates) {
        if (date.percentageUserOverride !== date._dirty.percentageUserOverride) {
          let factors = JSON.parse(JSON.stringify(date.factors));

          if (!date.percentageUserOverride) {
            factors = factors.filter(f => f.key !== 'percentage_user_override');
          } else {
            let factor = factors.find(f => f.key === 'percentage_user_override');
            if (factor) {
              factor.ratio = date.percentageUserOverride;
            } else {
              factors.push({
                applyAfterMinprice: false,
                key: 'percentage_user_override',
                order: factors.length + 1,
                ratio: date.percentageUserOverride,
              });
            }
          }
          date.set('factors', factors);
        }

        date.set('saving', false);

        date.markClean(properties);
      }
    });
  },
});

export default ListingDate;
