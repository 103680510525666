import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-insert this.onLoad}}>\n  {{yield (hash data=this.data actions=(hash update=this.update))}}\n</div>", {"contents":"<div {{did-insert this.onLoad}}>\n  {{yield (hash data=this.data actions=(hash update=this.update))}}\n</div>","moduleName":"appkit/components/bp/storybook-container.hbs","parseOptions":{"srcName":"appkit/components/bp/storybook-container.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';

// A Stateful container to use in storybook stories
export default class BpStorybookContainerComponent extends Component {
  @tracked data;

  @action
  onLoad() {
    this.data = this.args.data;
  }

  @action
  update(field, value) {
    set(this.data, field, value);
  }
}
