import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    filteredSupportedChannels=this.filteredSupportedChannels\n    filteredAssistedChannels=this.filteredAssistedChannels\n    hasAccounts=this.hasAccounts\n    channels=this.channels\n    actions=(hash updateSearchTerm=this.updateSearchTerm)\n  )\n}}", {"contents":"{{yield\n  (hash\n    filteredSupportedChannels=this.filteredSupportedChannels\n    filteredAssistedChannels=this.filteredAssistedChannels\n    hasAccounts=this.hasAccounts\n    channels=this.channels\n    actions=(hash updateSearchTerm=this.updateSearchTerm)\n  )\n}}","moduleName":"appkit/components/connect/container.hbs","parseOptions":{"srcName":"appkit/components/connect/container.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';
export default class InsightsConnectContainer extends Component {
  @tracked searchTerm = '';

  get channels() {
    return this.args.supportedChannels
      .map(c => ({ value: c, isAssisted: false }))
      .concat(this.args.assistedChannels.map(c => ({ value: c, isAssisted: true })))
      .filter(c => c.value.includes(this.searchTerm))
      .sort((a, b) => {
        return a.value.localeCompare(b.value);
      });
  }

  get hasAccounts() {
    return this.args.accounts?.length > 0;
  }

  get filteredSupportedChannels() {
    return this.args.supportedChannels.filter(c => c.includes(this.searchTerm)).sort();
  }

  get filteredAssistedChannels() {
    return this.args.assistedChannels.filter(c => c.includes(this.searchTerm)).sort();
  }

  _updateSearchTerm(term) {
    this.searchTerm = term;
  }

  @action
  updateSearchTerm(evt) {
    debounce(this, this._updateSearchTerm, evt.target.value, 150);
  }
}
