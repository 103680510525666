import Controller from '@ember/controller';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

class Errors {
  @tracked _cache = {};

  set(key, value) {
    this._cache[key] = value;
    this._cache = this._cache;
  }

  get(key) {
    return this._cache[key];
  }
}

export default class BulkUpdateController extends Controller {
  @tracked userId = null;
  @tracked startDate = moment().subtract(1, 'year');
  @tracked endDate = moment();
  @tracked statsStartDate = moment();
  @tracked statsEndDate = moment().add(1, 'year');
  @tracked limitEnabled = true;
  @tracked limitListed = true;

  @tracked saving = false;
  @tracked errors = new Errors();
  @tracked responseData = A([]);

  get hasErrors() {
    return Object.keys(this.errors).length > 0;
  }

  validate() {
    this.errors = new Errors();

    const required = [
      'userId',
      'startDate',
      'endDate',
      'statsStartDate',
      'statsEndDate',
    ];
    for (let field of required) {
      if (!this[field]) {
        this.errors[field] = `${field} is required`;
      }
    }
    if (this.startDate && this.endDate && this.startDate.isAfter(this.endDate)) {
      this.errors.base = 'Start Date must be before End Date';
    }
    if (
      this.statsStartDate &&
      this.statsEndDate &&
      this.statsStartDate.isAfter(this.statsEndDate)
    ) {
      this.errors.base = 'Stats Start Date must be before Stats End Date';
    }

    return Object.keys(this.errors).length == 0;
  }

  generateDownload(fileData) {
    let csv = '';
    for (let row of fileData) {
      // Use JSON to escape quotes
      csv += row
        .map(value => {
          if (value === null) {
            return '';
          }
          return JSON.stringify(value);
        })
        .join(',');
      csv += '\n';
    }
    console.log('Generating CSV with', csv);

    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const now = moment().toISOString(true).slice(0, 10);
    link.download = `${now}-booking-report-${this.userId}.csv`;

    document.body.appendChild(link);
    link.click();
    // document.body.removeChild(link);
  }

  @action
  async submit() {
    if (!this.validate()) {
      return;
    }
    this.saving = true;
    this.responseData = A([]);
    const url = `/api/queries/booking-report`;
    const params = {
      userId: this.userId,
      startDate: this.startDate.toISOString().slice(0, 10),
      endDate: this.endDate.toISOString().slice(0, 10),
      statsStartDate: this.statsStartDate.toISOString().slice(0, 10),
      statsEndDate: this.statsEndDate.toISOString().slice(0, 10),
      limitListed: this.limitListed,
      limitEnabled: this.limitEnabled,
    };

    let response;
    try {
      response = await this.ajax._post(url, params);
    } catch (errors) {
      console.log(errors);
      let text = `Error: ${errors[0].title} - ${errors[0].source.message}`;
      this.responseData.pushObject(text);
      return;
    } finally {
      this.saving = false;
    }

    this.responseData.pushObject(`Received ${response.result.length} rows`);
    this.generateDownload(response.result);
  }
}
