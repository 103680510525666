import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
@classic
export default class SudoController extends Controller {
  // If the user hit the sudo page as a result of being redirected from an
  // auth route, store the previous transition so we can put them back to where
  // they were.
  attemptedTransition = null;

  errors = null;
  posting = false;
  newTokenName = '';
}
