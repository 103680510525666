import Transform from '@ember-data/serializer/transform';

export default class FilterBedroomsTransform extends Transform {
  deserialize(serialized) {
    return serialized ? serialized.split('-').map(x => parseInt(x)) : [];
  }

  serialize(deserialized = []) {
    return deserialized.join('-');
  }
}
