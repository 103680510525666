import Controller, { inject as controller } from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { ChannelUtil } from 'appkit/lib/channel';
import RSVP from 'rsvp';
import { setRelayUserRole } from 'appkit/lib/relay/roles/rel-user-roles';
import { getRelayUserPermissions } from 'appkit/lib/relay/permissions/rel-user-permissions';

export default class DashboardRelayController extends Controller {
  @service bpStore;
  @service alert;
  @service staffView;
  @service featureFlag;
  @service relayFilters;
  @service('listings/listings-service') listingsService;
  @service intl;
  @service currentUser;
  @tracked selectedListings = [];
  @tracked sortOptions = ['a', 'b'];
  @tracked sortBy = 'a';

  @tracked selectedTab;
  @tracked previousTab;
  @tracked showSidebar = null;
  @tracked modal = { name: null, loading: false };

  @tracked relayWithoutPricingEnabled = false;
  @tracked dynamicMarkupsFlag = false;
  @tracked focusDynamicMarkups = false;
  @tracked isUpdatingPreferences = false;
  @tracked relayReservationsIsAvailable = false;
  @tracked relayContentSyncEnabled;

  @tracked areReservationsLoading = true;
  @tracked defaultRelayDashboardOptions = [
    { label: 'merged listings', id: 0, icon: 'relay/merged-icon', visible: true },
    { label: 'unmerged listings', id: 1, icon: 'relay/unmerged-icon', visible: true },
    { label: 'reservations', id: 2, icon: 'relay/reservations-icon', visible: false },
    { label: 'settings', id: 'settings', icon: 'relay/settings-icon', visible: false },
  ];

  @tracked selectedDashboardOption = this.relayDashboardOptions.find(
    m => m.id === this.selectedTab
  );
  // Path pre-v2
  flag_USER_LISTING_VIEW_ENABLED;

  @controller('dashboard.relay.index.reservations') reservationsController;

  @computed('relayReservationsIsAvailable', 'currentUser.isStaff')
  get relayDashboardOptions() {
    return this.defaultRelayDashboardOptions.map(dopts => ({
      ...dopts,
      visible:
        dopts.id === 2
          ? this.currentUser.isStaff || this.relayReservationsIsAvailable
          : dopts.visible,
    }));
  }

  constructor() {
    super(...arguments);

    const relayWithoutPricingEnabled = this.featureFlag
      .evaluate('relay-without-pricing', false)
      .then(flag => {
        this.relayWithoutPricingEnabled = flag;
      });

    this.featureFlag.evaluate('dynamic-markups', false).then(flag => {
      this.dynamicMarkupsFlag = flag;
    });

    this.featureFlag.evaluate('relay-reservations-list-view', false).then(flag => {
      this.relayReservationsIsAvailable = flag;
    });

    this.featureFlag.evaluate('relay-content-sync-enabled', false).then(flag => {
      this.relayContentSyncEnabled = flag;
    });

    set(this, 'relayWithoutPricingEnabled', relayWithoutPricingEnabled);
  }

  @action
  onSortByChanged(option) {
    this.sortBy = option;
  }

  @action
  onSelectedTabChanged(option) {
    this.selectedTab = option;
  }

  @action
  onSortParamsChanged(order, field) {
    if (this.selectedTab == 2) {
      this.reservationsController.getReservations(order, field);
    }
  }

  @action
  onToggleSidebar(sidebar) {
    if (sidebar === 'edit') this.setUserPermissions(); // WIP while v2 is not implemented

    if (this.focusDynamicMarkups) {
      this.focusDynamicMarkups = false;
    }
    if (this.showSidebar === sidebar) {
      this.showSidebar = null;
      this.isUpdatingPreferences = false; // Temp
    } else {
      this.showSidebar = sidebar;
    }
  }

  get isStaff() {
    return this.staffView.isStaffView;
  }

  get accounts() {
    return this.model.accounts;
  }

  @computed('model.accounts.@each.channel')
  get accountChannels() {
    return Array.from(new Set([...this.accounts.map(a => a.channel)]));
  }

  @computed('accountChannels.length')
  get hasPms() {
    return this.accountChannels.some(ac => ChannelUtil.isPms(ac));
  }

  @computed('accountChannels.length')
  get hasDirect() {
    return this.accountChannels.some(ac => !ChannelUtil.isPms(ac));
  }

  get listings() {
    return this.bpStore.all('listing');
  }

  get channelListings() {
    return this.bpStore.all('channelListing');
  }

  get savedFilters() {
    return this.model.savedFilters;
  }

  @action
  onChangeOption(opt) {
    if (opt.id == 'settings') {
      const settingsRoute = 'dashboard.relay.relay-settings';
      return this.transitionToRoute(settingsRoute);
    }

    if (opt.id == 2) {
      this.transitionToRoute('dashboard.relay.index.reservations');
    } else if (opt.id == 1) {
      this.transitionToRoute('dashboard.relay.index.unmerged-listings');
    } else {
      this.transitionToRoute('dashboard.relay.index.merged-listings');
    }
    this.selectedTab = opt.id;
    this.selectedDashboardOption = opt;
    this.selectedListings = [];
  }

  // Called from the router file, once the feature flags are resolved
  setNavMenuOptionVisibility(id, shouldDisplay) {
    const i = this.relayDashboardOptions.findIndex(opt => opt.id === id);

    this.relayDashboardOptions[i] = {
      ...this.relayDashboardOptions[i],
      visible: shouldDisplay,
    };
  }

  @action showUnmergedTab() {
    this.transitionToRoute('dashboard.relay.index.unmerged-listings');
  }

  @action
  async refreshListings() {
    try {
      const [{ listings }, { channelListings }] = await RSVP.Promise.all([
        this.ajax._get('/api/accounts/listings'),
        this.ajax._get('/api/accounts/channel_listings'),
      ]);

      // remove existing listings model & store
      this.model.set('listings', []);
      this.bpStore.unloadAll('listing');

      // remove existing channel listings model & store
      this.model.set('channelListings', []);
      this.bpStore.unloadAll('channelListing');

      // TODO: duplication from dashboard route. need cleaner way.

      // add new listings model & store
      this.model
        .get('listings')
        .pushObjects(
          listings.map(listing => this.bpStore.createRecord('listing', listing))
        );

      // add new channel listings model & store
      this.model
        .get('channelListings')
        .pushObjects(
          channelListings.map(listing =>
            this.bpStore.createRecord('channelListing', listing)
          )
        );

      // Set the relationships
      this.model.get('accounts').forEach(account => {
        account.set(
          'channelListings',
          channelListings
            .filter(cl => cl.managedAccountId === account.id)
            .map(cl => cl.id)
            .map(channelListingId => {
              let channelListing = this.bpStore.peekRecord(
                'channelListing',
                channelListingId
              );
              // Handle accounts where the current credential doesn't have access
              // to all the channel listings. Ideally we should strip these out
              // server side, but our serializers don't really support it.
              if (!channelListing) {
                return null;
              }
              channelListing.set('account', account);
              return channelListing;
            })
            .filter(el => el)
        );
      });

      this.model.get('listings').forEach(listing => {
        listing.set(
          'channelListings',
          listing.get('channelListingsIds').map(channelListingId => {
            let channelListing = this.bpStore.peekRecord(
              'channelListing',
              channelListingId
            );
            channelListing.set('masterListing', listing);
            return channelListing;
          })
        );

        let primaryChannelListingId = listing.get('primaryChannelListingId');
        let primaryChannelListing = this.bpStore.peekRecord(
          'channelListing',
          primaryChannelListingId
        );
        listing.set('primaryChannelListing', primaryChannelListing);
      });
    } catch (err) {
      const errors = Array.isArray(err)
        ? err
        : [{ message: 'Failed to update listing day configuration.' }];
      this.alert.error(errors[0].message, { timeout: 10000 });
    }
  }

  /** FILTERS ------------------------------------------------------------------------- */
  @action
  onClearFilters(isUnmergedTab) {
    this.relayFilters.shouldApplyDefaultFilters() &&
      this.relayFilters.refreshDefaultFilters(isUnmergedTab);
  }

  @action
  onDeleteFilter(isUnmergedTab) {
    this.relayFilters.refreshDefaultFilters(isUnmergedTab);
  }

  /** LISTING SELECTION --------------------------------------------------------------
   * Previously: part of the Search-container. As this is UI related and global, we keep it here
   * to keep components interaction simpler.
   */
  @action
  onSingleSelect(listing) {
    this.selectedListings = [listing].filter(id => id);
  }

  @action
  onSelect(listings, selected) {
    if (selected) {
      this.selectedListings = [...new Set(this.selectedListings.concat(listings))];
    } else {
      const listingsIds = listings.map(l => l.id);
      this.selectedListings = this.selectedListings.filter(
        l => !listingsIds.includes(l.id)
      );
    }
  }

  @action
  onClearSelection(isUnmergedTab = this.selectedTab > 0) {
    this.selectedListings = [];
    this.relayFilters.refreshDefaultFilters(isUnmergedTab);
  }

  /** MARKUPS -------------------------------------------------------------------------- */
  @action
  onEnableDynamicMarkups() {
    this.showSidebar = 'edit';
    this.focusDynamicMarkups = true;
  }

  @action
  onAddMarkup(listing) {
    this.onSingleSelect(listing);
    this.showSidebar = 'edit';
  }

  /** MODAL HANDLING ------------------------------------------------------------------- */
  @action
  onToggleModal(name) {
    const emptyState = { name: null, loading: false };
    const loadedState = { name, loading: false };

    const state = name === this.modal.name ? emptyState : loadedState;
    if (name === 'merge') this.setUserPermissions(); // WIP while v2 is not implemented
    this.updateModalState(state);
  }

  updateModalState(newState) {
    this.modal = { ...this.modal, ...newState };
  }

  /** MERGE / UNMERGE /SAVE PREFERENCES --------------------------------------------------
   * Previously: Merge container, composed template functions & containers callbacks.
   * Now the controller handles all the UI changes at once, and delegates business logic
   * to the listings service (the async tasks, mapping, validation & domain operations)
   */
  @action
  async onMergeListings(preferences) {
    if (!this.selectedListings.length) return;

    this.updateModalState({ loading: true });

    await this.alert.asyncOperationNotifier(
      this.listingsService.mergeListings(this.selectedListings, preferences),
      'relay.mergingWait'
    );

    this.refreshListings();
    this.onClearSelection();
    this.onToggleModal('merge');
    this.showSidebar = null;
  }

  @action
  async onUnmergeListings() {
    if (!this.selectedListings.length) return;

    this.updateModalState({ loading: true });

    await this.alert.asyncOperationNotifier(
      this.listingsService.unmergeListings(this.selectedListings),
      'relay.unmergingWait'
    );

    this.refreshListings();
    this.onClearSelection();
    this.onToggleModal('unmerge');
  }

  @action
  async onSavePreferences(preferences) {
    const shouldSave = this.selectedListings.length || this.hasPreferences(preferences);
    if (!shouldSave) return;

    // Notice: The listings-service doesn´t know the user directly.
    // The controller connects diferent entities (models) and generates the interaction.
    const user = this.model.user;
    this.isUpdatingPreferences = true;

    const wasSuccessPOST = await this.alert.asyncOperationNotifier(
      this.listingsService.savePreferences(this.selectedListings, preferences, user),
      'relay.updatingCalendar' // This translation is problably not the one. But this is how it was in the code.
    );
    if (wasSuccessPOST) {
      // Optimistic update of the models
      this.listingsService.applySettingsOnMergedListings(
        this.selectedListings,
        preferences
      );
    }
    this.isUpdatingPreferences = false;
    this.onToggleSidebar('edit');
    this.onClearSelection();
  }

  //TEMP: (Transition in between step 1 and step 2. Will be removed next PR, with the implementation of editor)
  @action
  setSelection(listingSelection) {
    // The mergedListing + the selected unmerged one
    this.selectedListings = [...listingSelection];
  }

  /* UTILS */
  hasPreferences(preferences) {
    const { syncPricing, syncAvailability, syncReservations, markups } = preferences;

    if (
      syncPricing === null &&
      syncAvailability === null &&
      syncReservations === null &&
      Object.keys(markups).length
    ) {
      return false;
    }
    return true;
  }

  @action
  onMergeSuggestions(listingSuggestions) {
    this.selectedListings = [...listingSuggestions];
    this.onToggleModal('merge');
  }

  @tracked userPermissions;

  setUserPermissions() {
    const hasMergedListings = this.listings.some(
      l => l.channelListingsWithoutPrimary.length > 0
    );
    const featureFlags = {
      flag_USER_DYNAMIC_MARKUPS_ENABLED: this.dynamicMarkupsFlag,
      flag_USER_LISTING_VIEW_ENABLED: this.flag_USER_LISTING_VIEW_ENABLED,
      flag_USER_CONTENT_SYNC_ENABLED: this.relayContentSyncEnabled,
    };
    const userWithRole = setRelayUserRole(this.model.user, hasMergedListings);
    this.userPermissions = getRelayUserPermissions(userWithRole, featureFlags);
  }
}
