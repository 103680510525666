import ApplicationSerializer from './application';
import DS from 'ember-data';

export default class CredentialSerializer extends ApplicationSerializer.extend(
  DS.EmbeddedRecordsMixin,
  {
    attrs: {
      permissions: { embedded: 'always' },
    },
  }
) {
  // We have to map the payload permission IDs to our "unsaved" permissions manually.
  // Use the listing ID to identify the permission since it's unique.
  normalizeCreateRecordResponse(store, _primaryModelClass, payload, _id, _requestType) {
    const credential = store
      .peekAll('credential')
      .filter(c => c.isSaving && c.email === payload.credential.email).firstObject;

    credential.permissions.forEach(record => {
      const json = payload.permissions.find(
        p => p.listingId === Number(record.listing.id)
      );

      if (json) {
        // record.unloadRecord();
        record.set('id', json.id);
        store.markSaved(record);
        // store.push({ data: json });
      }
    });

    return super.normalizeSaveResponse(
      store,
      _primaryModelClass,
      payload,
      _id,
      _requestType
    );
  }
}
