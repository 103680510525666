import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class AppDateRangePicker extends Component {
  @tracked _center;

  constructor() {
    super(...arguments);
    this._center = new Date();
  }

  get center() {
    return this.args.center ?? this._center;
  }

  set center(value) {
    if (this.args.center) {
      this.args.onCenterChanged?.(value);
    } else {
      this._center = value;
    }
  }

  get renderInPlace() {
    return this.args.renderInPlace ?? true;
  }

  get hideYear() {
    return this.args.hideYear ?? false;
  }

  get calendarHeaderFormat() {
    return this.hideYear ? 'MMMM' : 'MMMM YYYY';
  }

  get minRange() {
    return this.args.minRange ?? 0;
  }

  get centerDate() {
    return moment.utc(this.center);
  }

  get minDate() {
    return this.args.minDate && moment.utc(this.args.minDate);
  }

  get maxDate() {
    return this.args.maxDate && moment.utc(this.args.maxDate);
  }

  get selected() {
    return {
      start: moment.isMoment(this.args.start)
        ? this.args.start.toDate()
        : this.args.start,
      end: moment.isMoment(this.args.end) ? this.args.end.toDate() : this.args.end,
    };
  }

  get startDateParts() {
    return this.selected.start
      ? moment(this.selected.start)
          .format('L')
          .split('/')
          .map(d => d.slice(-2))
      : [];
  }

  get endDateParts() {
    return this.selected.end
      ? moment(this.selected.end)
          .format('L')
          .split('/')
          .map(d => d.slice(-2))
      : [];
  }

  get disablePrev() {
    return (
      this.minDate &&
      this.centerDate.subtract(1, 'month').isBefore(this.minDate, 'month')
    );
  }

  get disableNext() {
    return (
      this.maxDate && this.centerDate.add(1, 'month').isAfter(this.maxDate, 'month')
    );
  }

  @action
  moveToPrevious() {
    if (this.args.circle && this.disablePrev) {
      this.center = this.maxDate;
    } else {
      this.center = this.centerDate.subtract(1, 'month').toDate();
    }
  }

  @action
  moveToNext() {
    if (this.args.circle && this.disableNext) {
      this.center = this.minDate;
    } else {
      this.center = this.centerDate.add(1, 'month').toDate();
    }
  }

  @action
  classForDay(day) {
    const date = moment(day.date);
    if (
      (this.minDate && date.isBefore(this.minDate, 'day')) ||
      (this.maxDate && date.isAfter(this.maxDate, 'day'))
    ) {
      return 'ember-power-calendar-day--blocked';
    }
  }

  @action
  selectRange(toggle, value) {
    const { start, end } = value.moment;
    if (
      (start &&
        !start.isSame(this.args.start) &&
        ((this.minDate != null && !start.isSameOrAfter(this.minDate, 'day')) ||
          (this.maxDate != null && !start.isSameOrBefore(this.maxDate, 'day')))) ||
      (end &&
        !end.isSame(this.args.end) &&
        ((this.minDate != null && !end.isSameOrAfter(this.minDate, 'day')) ||
          (this.maxDate != null && !end.isSameOrBefore(this.maxDate, 'day'))))
    ) {
      return;
    }

    this.args.onDateRangeSelected(value.moment);

    if (start && end) {
      toggle();
    }
  }

  @action
  selectToday(toggle) {
    // This is a bit of a UX challenge since we have a single date picker for two
    // separate dates. Current good-enough behavior is to set the start if there's
    // nothing set yet, otherwise set the end.
    const value = {
      date: { start: this.selected.start, end: this.selected.end },
      moment: {
        start: moment(this.selected.start),
        end: moment(this.selected.end),
      },
    };

    if (this.selected.start) {
      value.moment.end = moment();
    } else {
      value.moment.start = moment();
    }
    this.selectRange(toggle, value);
    return false;
  }
}
