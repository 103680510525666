import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import { A } from '@ember/array';

export default class MinPriceRecommendationsController extends Controller {
  @tracked user = null;
  @tracked userIds = null;
  @tracked userDetails = [];
  @tracked uploadResponse = A([]);
  @tracked results = [];
  @tracked posted = false;
  @tracked errorMessage = '';
  @tracked results = [];
  @tracked isCompleteReviewChecked = false;
  @tracked userDetailsNotFound = [];

  @action
  checkUserList() {
    let controller = this;
    let userIds = controller.userIds;
    userIds = userIds.split(',').map(id => id.trim());
    let usersDetailsFound = [];
    let notFound = [];

    userIds.map(userId => {
      let url = `/api/bulk_updates/check_user/${userId}`;
      this.ajax._get(url).then(
        data => {
          usersDetailsFound.push(`ID: ${data.id} email: ${data.email}`);
          controller.set('userDetails', usersDetailsFound);
        },
        () => {
          notFound.push(`User ID: ${userId} not found`);
          controller.set('userDetailsNotFound', notFound);
        }
      );
    });
  }
  @action
  onCompleteReviewChecked() {
    this.isCompleteReviewChecked
      ? (this.isCompleteReviewChecked = false)
      : (this.isCompleteReviewChecked = true);
  }

  @action
  async submitForm() {
    this.errorMessage = null;
    this.results = [];
    let controller = this;
    let userIds = controller.userIds;
    userIds = userIds.split(',').map(id => id.trim());
    controller.uploadResponse = A(['Processing...']);
    controller.results = A([]);

    if (!this.staffUserId) {
      alert('Submitter User ID is required. Aborting.');
      return;
    }
    let data = {
      staff_user_id: this.staffUserId,
      user_id_list: userIds,
      complete_review: this.isCompleteReviewChecked,
    };

    this.posted = false;
    let updates = [];
    let resultCache = [];

    try {
      await this.ajax.stream(
        '/api/internal-tools/min-price-recommendations',
        data,
        responseData => {
          if (responseData.message != 'done') {
            if (responseData.message?.startsWith('results:')) {
              let results = JSON.parse(responseData.message.substring(8));
              for (const key in results) {
                resultCache.push({
                  userId: key,
                  booking_reviews: results[key].bookingReviewListingIds
                    ? results[key].bookingReviewListingIds.length
                    : '',
                  error: results[key].error,
                });
              }
              controller.results = resultCache;
            } else {
              updates.push(responseData.message);
              controller.uploadResponse = updates;
            }
          }
        }
      );
    } catch (e) {
      this.errorMessage = 'Uh oh! Something went wrong!';
      if (e.type == 'error') {
        controller.uploadResponse.push(this.errorMessage);
      } else {
        controller.uploadResponse.push(this.errorMessage + 'Error description: ' + e);
      }
    } finally {
      this.posted = true;
    }
  }
}
