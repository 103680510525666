import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class RelayConnectChannelRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service router;
  @service intl;

  channelName = '';

  title() {
    let title = null;
    title = `${this.intl.t('relay.relay')} / Connect account`;
    this.globalNav.setTitle(title);
    return title;
  }
}
