import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import EmberObject from '@ember/object';

@classic
export default class IndexRoute extends Route {
  model() {
    return this.ajax._get('/api/reservations').then(responseData => {
      responseData.channelListings.map(channelListingData =>
        this.bpStore.createRecord('channelListing', channelListingData)
      );

      let reservations = responseData.reservations.map(reservationData => {
        let resObj = this.bpStore.createRecord('reservation', reservationData);
        let channelListing = this.bpStore.peekRecord(
          'channelListing',
          reservationData.channelListingId
        );
        resObj.set('channelListing', channelListing);
        return resObj;
      });

      return EmberObject.create({
        reservations: reservations,
      });
    });
  }
}
