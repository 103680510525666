import Service from '@ember/service';
import { computed, set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class OwnerResourceService extends Service {
  @tracked loading;
  @tracked selectedCluster;
  @tracked selectedAddress;
  @tracked selectedBedroomSize;
  @tracked searchedAddressGeocode = {};
  @tracked selectedCurrency;
  @tracked searchedReferenceListings;

  @tracked nearbyListings;
  @tracked selectedListing;
  @tracked listingBasePrice;
  @tracked manualBasePrice;

  resetService() {
    this.loading = false;
    this.selectedCluster = null;
    this.selectedAddress = null;
    this.selectedBedroomSize = null;
    this.searchedAddressGeocode = {
      address: null,
      city: null,
      state: null,
      zipcode: null,
      country: null,
    };
    // this.selectedCurrency = null;
    this.searchedReferenceListings = false;

    this.nearbyListings = [];
    this.selectedListing = null;
    this.listingBasePrice = null;
    this.manualBasePrice = null;
  }

  @computed('listingBasePrice', 'manualBasePrice')
  get basePrice() {
    return this.manualBasePrice ? this.manualBasePrice : this.listingBasePrice;
  }

  get displayAddress() {
    const { address, city, state, zipcode, country } = this.searchedAddressGeocode;
    return [address, city, [state, zipcode].filter(s => s != null).join(' '), country]
      .filter(s => s != null)
      .join(', ');
  }

  @action
  changedManualBasePrice(e) {
    set(this, 'manualBasePrice', Number(e.target.value));
  }

  @action
  selectListing(listing) {
    set(this, 'selectedListing', listing);
    set(this, 'listingBasePrice', listing?.basePrice);
  }

  @action
  unselectListing() {
    set(this, 'selectedListing', null);
    set(this, 'listingBasePrice', null);
    set(this, 'manualBasePrice', null);
  }
}
