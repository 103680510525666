import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
export default class AppChannelIcon extends Component {
  @computed('channel')
  get icon() {
    const channel = this.channel;

    if (channel === 'airbnb') {
      return 'belo';
    } else if (channel === 'homeaway') {
      return 'vrbo-icon';
    } else if (channel === 'booking') {
      return 'booking-mini';
    } else {
      console.error(`${channel} is not a know channel`);
      return '';
    }
  }
}
