import classic from 'ember-classic-decorator';
import { attributeBindings, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import Component from '@ember/component';

@classic
@tagName('button')
@attributeBindings('disabled', 'type', 'holdSize:style')
export default class AppButton extends Component {
  // PUBLIC
  param = null;

  action = () => {};
  type = 'button';
  _hasRendered = false;
  color = '#888';

  didInsertElement() {
    super.didInsertElement(...arguments);

    scheduleOnce('afterRender', this, this.afterRenderHook);
  }

  afterRenderHook() {
    this.set('_hasRendered', true);
    const border = window.getComputedStyle(this.element)['color'];
    this.set('color', border);
  }

  @computed('saving')
  get disabled() {
    if (this.saving) {
      return 'disabled';
    }
    return '';
  }

  // Dropping the text out for the spinner results in a zero-width button (as
  // spin.js uses absolute positioning). Before I was setting a min width on the
  // buton but there was a jump if the text and min-width didn't match exactly.
  lockWidth = 0;

  lockHeight = 0;

  @computed('saving', 'lockWidth', 'lockHeight')
  get holdSize() {
    if (!this.saving) {
      return ''.htmlSafe();
    }

    let width = Number(this.lockWidth);
    let height = Number(this.lockHeight);
    return `width: ${width}px; height: ${height}px;`.htmlSafe();
  }

  // Before Observers are deprecated. Need to save the width and height of the
  // button so we can hold it when we replace it with the spinner. We can't do
  // this in the "ember approved" way of just saving the old value, because the
  // size will have already changed by then. Could do on init, but then if the
  // size changes due to resizing / text changing / whatever, we won't catch it.
  //  * http://emberjs.com/deprecations/v1.x/#toc_beforeobserver
  _saving = false;

  @computed('_saving')
  set saving(value) {
    // There's probably an api for this...
    if (this._hasRendered) {
      this.set('lockWidth', this.element.offsetWidth);
      this.set('lockHeight', this.element.offsetHeight);
    }

    this.set('_saving', value);
    return value;
  }

  get saving() {
    return this._saving;
  }

  click() {
    this.action(this.param);
  }
}
