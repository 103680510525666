import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield (hash args=this.args listingsInDetail=this.listingsInDetail)}}", {"contents":"{{yield (hash args=this.args listingsInDetail=this.listingsInDetail)}}","moduleName":"appkit/components/dashboard/map-view/listing-details-container.hbs","parseOptions":{"srcName":"appkit/components/dashboard/map-view/listing-details-container.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class DashboardMapViewListingDetailsContainerComponent extends Component {
  @tracked
  listingsInDetail = this.args.listings.map(lg => ({
    ...lg,
    listings: lg.listings.map(l => ({
      ...l,
      properties: {
        ...l.properties,
        imageDimensions: l.properties.image.includes('.svg') ? null : '32',
      },
    })),
  }));
}
