import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div role='button' class={{this.classes}} onmousedown={{this.onMouseDown}}>\n  <Input\n    @value={{this.text}}\n    type='text'\n    role='combobox'\n    class={{this.typeaheadClasses}}\n    autocomplete='off'\n    autocorrect='off'\n    autocapitalize='off'\n    spellcheck='false'\n    placeholder={{@placeholder}}\n    oninput={{@onInput}}\n    onfocus={{@onFocus}}\n    onblur={{@onBlur}}\n    disabled={{@select.disabled}}\n    onkeydown={{this.onKeyDown}}\n    onmousedown={{this.onMouseDown}}\n    {{did-insert this.update}}\n    {{did-update this.update @select}}\n  />\n  {{#if @select.loading}}\n    <AppSvg @src='loading' @class={{concat this.chevronClasses ' animate-spin'}} />\n  {{else}}\n    <AppSvg @src='search' @class={{this.chevronClasses}} />\n  {{/if}}\n</div>", {"contents":"<div role='button' class={{this.classes}} onmousedown={{this.onMouseDown}}>\n  <Input\n    @value={{this.text}}\n    type='text'\n    role='combobox'\n    class={{this.typeaheadClasses}}\n    autocomplete='off'\n    autocorrect='off'\n    autocapitalize='off'\n    spellcheck='false'\n    placeholder={{@placeholder}}\n    oninput={{@onInput}}\n    onfocus={{@onFocus}}\n    onblur={{@onBlur}}\n    disabled={{@select.disabled}}\n    onkeydown={{this.onKeyDown}}\n    onmousedown={{this.onMouseDown}}\n    {{did-insert this.update}}\n    {{did-update this.update @select}}\n  />\n  {{#if @select.loading}}\n    <AppSvg @src='loading' @class={{concat this.chevronClasses ' animate-spin'}} />\n  {{else}}\n    <AppSvg @src='search' @class={{this.chevronClasses}} />\n  {{/if}}\n</div>","moduleName":"appkit/components/bp/select/parts/trigger/typeahead.hbs","parseOptions":{"srcName":"appkit/components/bp/select/parts/trigger/typeahead.hbs"}});
import { isBlank } from '@ember/utils';
import { run } from '@ember/runloop';
import TriggerComponent from './index';
import { action } from '@ember/object';
import { Themeable } from '../../../theme';
import typeaheadTheme from './typeahead.theme';
import { tracked } from '@glimmer/tracking';

// Based on https://github.com/cibernox/ember-power-select-typeahead/blob/master/addon/components/power-select-typeahead/trigger.js
export default class BpSelectPartsTriggerTypeaheadComponent extends Themeable(
  TriggerComponent,
  {
    theme: typeaheadTheme,
  }
) {
  @tracked oldSelect;
  @tracked text;

  get typeaheadClasses() {
    return this.getClassesForTheme(this.theme.typeahead);
  }

  @action
  update() {
    let oldSelect = this.oldSelect;
    let newSelect = this.args.select;
    this.oldSelect = newSelect;

    // Update text to selected option on mount
    if (!oldSelect) {
      this.text = this.getSelectedAsText();
      return;
    }

    /*
     * We need to update the input field with value of the selected option whenever we're closing
     * the select box.
     */
    if (oldSelect.isOpen && !newSelect.isOpen && newSelect.searchText) {
      this.text = this.getSelectedAsText();
    }

    if (newSelect.lastSearchedText !== oldSelect.lastSearchedText) {
      if (isBlank(newSelect.lastSearchedText)) {
        run.schedule('actions', null, newSelect.actions.close, null, true);
      } else {
        run.schedule('actions', null, newSelect.actions.open);
      }
    }

    if (oldSelect.selected !== newSelect.selected) {
      this.text = this.getSelectedAsText();
    }
  }

  @action
  onMouseDown(e) {
    e.stopPropagation();
  }

  @action
  onKeyDown(e) {
    let select = this.args.select;
    // up or down arrow and if not open, no-op and prevent parent handlers from being notified
    if ([38, 40].indexOf(e.keyCode) > -1 && !select.isOpen) {
      e.stopPropagation();
      return;
    }
    let isLetter = (e.keyCode >= 48 && e.keyCode <= 90) || e.keyCode === 32; // Keys 0-9, a-z or SPACE
    // if isLetter, escape or enter, prevent parent handlers from being notified
    if (isLetter || [13, 27].indexOf(e.keyCode) > -1) {
      // open if loading msg configured
      if (!select.isOpen && this.args.loadingMessage) {
        run.schedule('actions', null, select.actions.open);
      }
      e.stopPropagation();
    }

    // optional, passed from power-select
    let onkeydown = this.args.onKeydown;
    if (onkeydown && onkeydown(e) === false) {
      return false;
    }
  }

  @action
  getSelectedAsText() {
    let select = this.args.select;
    let labelPath = this.extra.labelPath;
    let value;
    if (labelPath) {
      // complex object
      value = select.selected?.[labelPath];
    } else {
      // primitive value
      value = select.selected;
    }
    if (value === undefined) {
      value = '';
    }
    return value;
  }
}
