import classic from 'ember-classic-decorator';
import HeaderTransitionRoute from 'appkit/routes/header-transition';

@classic
export default class IndexRoute extends HeaderTransitionRoute {
  beforeModel() {
    if (this.currentUser.get('token')) {
      this.replaceWith('dashboard');
    }
  }
}
