import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
// TODO: Implementation notes: We will need to receive the country for the markets

// Check all-countries file under this folder, when adding new supported countries
// The map key should have the same format
const WORLD_COUNTRIES_MAP = new Map();
WORLD_COUNTRIES_MAP.set('Spain', { translationPath: 'spain' });

/**
 * Recovers the calendar festivities by country name and festivity Id
 *
 * If the translation doesn't exist, it will use the API untranslated version
 * Worldwide events are separated into a single file, not binded to a specific country or market
 *
 * @Ussage
 * {{translations/calendar-events countryName eventId sourceEventName}}
 */
export default class CalendarEventsTranslationHelper extends Helper {
  @service intl;

  compute(payload) {
    try {
      const [countryName, eventId, sourceEventName] = payload;
      const fallbackEventName = sourceEventName || '';

      if (!countryName && !eventId) {
        // v.0.1 where API or FE didn´t include countryId or eventId.
        // In present time, we are just using the event name directly.
        return fallbackEventName;
      }

      const isWorldwideEvent = !countryName && eventId;
      const countryi18nResource = WORLD_COUNTRIES_MAP.get(countryName).translationPath;

      const parentFolder = isWorldwideEvent ? 'global' : countryi18nResource;

      const translationPath = `country-markets.${parentFolder}.events.${eventId}`;
      const isMarketTranslated = this.intl.exists(translationPath);

      if (isMarketTranslated) return this.intl.t(translationPath);

      return fallbackEventName;
    } catch (e) {
      const fallbackEventName = payload?.sourceEventName || '';
      return fallbackEventName;
    }
  }
}
