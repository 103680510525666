/* global require */
import { typeOf } from '@ember/utils';
import { on } from '@ember/object/evented';
import EmberObject, { defineProperty, computed, get } from '@ember/object';
import moment from 'moment';
import isEqual from 'lodash.isequal';
import { inject as service } from '@ember/service';
import { copy, Copyable } from 'ember-copy';

// eslint-disable-next-line ember/no-classic-classes
const BaseModel = EmberObject.extend(Copyable, {
  ajax: service(),
  featureFlag: service(),
  bpStore: service(),

  id: null,
  isNew: true,
  saving: false,

  errors: EmberObject.create({}),

  init() {
    this._super(arguments);
    this._EDITABLE = this._EDITABLE ?? [];
  },

  simpleName() {
    return this.constructor.toString();
  },

  _serializeInit_: on('init', function () {
    let editable = this._EDITABLE;
    defineProperty(
      this,
      'serialize',
      computed(...editable, function () {
        let out = {};
        for (let key of editable) {
          // TODO: more introspection - detect type?
          let value = this.get(key);
          if (key === 'date') {
            value = moment(value).format('YYYY-MM-DD');
          }
          out[key] = value;
        }
        return out;
      })
    );
  }),

  _dirtyInit_: on('init', function () {
    // Store the initial value so that we can reset
    this.set('_dirty', EmberObject.create({}));
    this.markClean();

    let editable = this._EDITABLE;

    // isDirty is going to be computed from both the real values and the dirty values.
    let dependent = editable.concat(editable.map(key => `_dirty.${key}`));

    for (let key of editable) {
      let initialValue = this.get(key);
      if (Array.isArray(initialValue)) {
        dependent.push(`${key}.[]`);
        dependent.push(`_dirty.${key}.[]`);
      }
    }

    defineProperty(
      this,
      'isDirty',
      computed('_dirty', ...dependent, function () {
        // Since we did a deep-copy above, we now have to traverse into any
        // arrays / objects and do value comparisons.
        for (let key of editable) {
          // Technically backwards. ¯\_(ツ)_/¯
          let cleanValue = this.get(key);
          let dirtyValue = this.get(`_dirty.${key}`);

          // Handle the objects that we know how to compare directly
          if (dirtyValue && dirtyValue._isAMomentObject) {
            if (dirtyValue.toISOString() !== cleanValue.toISOString()) {
              return true;
            }
            continue;
          }

          // JSON comparison works on primitives and arrays, not complex js
          // classes / objects.
          if (
            dirtyValue &&
            typeof dirtyValue === 'object' &&
            !Array.isArray(dirtyValue)
          ) {
            console.warn(
              'Performing deep comparison on an object without a `copy` method',
              dirtyValue.toString(),
              dirtyValue
            );
          }

          // Check equality JSON.stringify instead of "shallow checking" - i.e.
          // using `===`. Shallow checks just look for equal addresses, but we
          // want to check for equal content.
          if (!isEqual(dirtyValue, cleanValue)) {
            return true;
          }
        }
        return false;
      })
    );
  }),

  // Required for ember-inspector
  eachAttribute(callback) {
    for (let key of this.constructor.attributes()) {
      callback(key);
    }
  },

  // Not sure if there's a preferred nomenclature for dirty checking routines.
  // There is probably something that reads better than this.
  resetChanges() {
    // Do a shallow assignment back to the listing, and then deep copy via markClean
    for (let key of this._EDITABLE) {
      this.set(key, this.get(`_dirty.${key}`));
    }
    this.markClean();
  },

  markClean(properties) {
    const editable = properties
      ? this._EDITABLE.filter(e => properties.includes(e))
      : this._EDITABLE;

    for (let key of editable) {
      // An object might store multiple objects / arrays / reference types. If
      // these are reference types (e.g. an array of IDs), we have to take a
      // copy of the dependent objects when setting the dirtyfields, otherwise
      // they both the dirty and clean versions will change together.
      //
      // We can use the ember `copy` function to take a deep copy. In most
      // cases, these are either primitives, or baseModel derived objects, so
      // we just need to implement the `copy` method. A notable exception is
      // moment - we can't override the base class to implement copy.
      //
      // TODO: possibly cleaner to override the moment base class and alias
      // clone <=> copy.
      let newValue;
      let initialValue = this.get(key);

      if (initialValue && initialValue._isAMomentObject) {
        newValue = initialValue.clone();
      } else {
        newValue = copy(initialValue, true);
      }
      // Keep the original values in the _dirty object so we can easily reset to it.
      this.set(key, newValue);
      this.set(`_dirty.${key}`, initialValue);
    }
  },

  // Moving the save method to the model has been a massive pain in the ass.
  // Injections don't work on models without a lot of work. And I use an
  // injector for ajax calls.
  save() {
    this.set('saving', true);
    // Currently this posts as a top level object. If we ever require atomic
    // commits of multiple objects, this will have to be nested inside the json.
    // e.g. data[@shortName()] = @getProperties @get('_EDITABLE')
    let data = this.getProperties(this._EDITABLE);

    return this.ajax._post(this.url, data).then(responseData => {
      this.set('saving', false);
      // Assuming the response is an object with a singular key with the object
      // properties. e.g. a listing should have this response:
      // {"listing": {"id": 123, "base_price": 123}}
      this.setProperties(responseData[this.simpleName()]);
      this.markClean();
      return true;
    });
  },

  // Reload the data in an already-existing model.
  //
  // e.g. When we enter a route with stale data we can still show the old data
  // but should update in the background. This also allows us to (eventually)
  // trigger a client-side reload
  //
  // By default, we keep any changes made locally and just update the cache.
  // Pass override=true to force the model to refresh with whatever was
  // received, regardless of local changes.
  reload({ override = false } = {}) {
    let url = this.url;

    return this.ajax._get(url).then(responseData => {
      let modelData = responseData[this.simpleName()];

      for (let key of Object.keys(modelData)) {
        let value = modelData[key];
        if (override || this.get(`_dirty.${key}`) === this.get(key)) {
          this.set(key, value);
        }

        this.set(`_dirty.${key}`, value);
      }
    });
  },
});

BaseModel.reopenClass({
  saveMany(objs) {
    let fake = this.create({});
    let url = fake.get('url');
    let data = objs.map(obj => obj.getProperties(obj.get('_EDITABLE')));

    for (let obj of objs) {
      obj.set('saving', true);
    }

    // TODO: work out how to inject ajax into the base class
    let ajax = fake.ajax;
    return ajax._post(url, data).then(() => {
      for (let obj of objs) {
        obj.set('saving', false);
        obj.markClean();
      }
    });
  },

  attributes() {
    let props = [];
    let obj = this.create();

    for (let prop of Object.keys(obj)) {
      if (prop.indexOf('__ember') >= 0) {
        continue;
      }
      if (prop.indexOf('_super') >= 0) {
        continue;
      }
      if (typeof obj[prop] === 'function') {
        continue;
      }
      if (typeOf(get(obj, prop)) === 'function') {
        continue;
      }

      props.push(prop);
    }
    return props;
  },
});

BaseModel.addTypeKeys = function () {
  let types = Object.keys(require._eak_seen).filter(
    key => !!key.match(/^appkit\/bp-models\//) && this.detect(require(key).default)
  );

  types.forEach(key => {
    let type = require(key).default;
    let typeKey = key.match(/^appkit\/bp-models\/(.*)/)[1];
    type.toString = () => typeKey;
  });

  return types;
};

export default BaseModel;
