import classic from 'ember-classic-decorator';
import { classNames, classNameBindings } from '@ember-decorators/component';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@classNames('app-logo')
@classNameBindings('isLogo:logo-sign:section')
export default class AppLogo extends Component {
  hoverClass = 'checkin-checkout-hover';

  @equal('logoText', 'Airbnb')
  isLogo;

  logoText = 'Airbnb';

  // for svg-icon
  isSvgIcon = true;

  svgSrc = 'null';
}
