import AuthRoute from 'appkit/routes/auth';

export default class DashboardGridBulkRouter extends AuthRoute {
  afterModel() {
    let gridController = this.controllerFor('dashboard.grid');
    console.log(
      'Selected',
      gridController.selectedDates,
      gridController.selectedListingIds
    );
    if (
      !(
        gridController.selectedDates?.length &&
        gridController.selectedListingIds?.length
      )
    ) {
      return this.transitionTo('dashboard.grid');
    }
  }
}
