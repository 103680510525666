import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Controller from '@ember/controller';
@classic
export default class HotelPricesController extends Controller {
  chartOptions = {
    rangeSelector: {
      selected: 5,
    },

    yAxis: [
      {
        title: {
          text: 'Number',
        },
      },
      {
        title: {
          text: 'Percent',
        },
        opposite: false,
      },
    ],
  };

  // 'Date', 'Price Change', 'Rolling Average', 'Percent Occupancy', 'Total Number'
  @computed('model.{prices,rollingAverage,availability}')
  get chartData() {
    return [
      {
        name: 'Price (pct)',
        data: this.get('model.prices'),
        tooltip: {
          valueDecimals: 2,
        },
      },
      {
        name: 'Rolling Avg',
        data: this.get('model.rollingAverage'),
        yAxis: 0,
        tooltip: {
          valueDecimals: 2,
        },
      },
      {
        name: 'Availability (pct)',
        data: this.get('model.availability'),
        yAxis: 1,
        tooltip: {
          valueDecimals: 2,
        },
      },
    ];
  }
}
