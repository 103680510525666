import { fontSizeClasses } from '../../../../../bp/select/parts/trigger/index.theme';
import { SIZES } from '../../../../../bp/theme';

export const spacingClasses = () => ({
  [SIZES.ALL]: ['pr-1'],
});

export default {
  root: {
    fontSizeClasses: fontSizeClasses,
    spacingClasses,
  },
};
