import { computed } from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';

export default BaseModel.extend({
  name: '',

  init() {
    this._super(arguments);
    this.set('clusters', this.clusters ?? []);
  },

  imageUrl: computed('name', function () {
    let filename = this.name
      .replace(/\./g, '')
      .replace(', CA', '')
      .toLowerCase()
      .underscore();
    return window.fingerprints[`assets/markets/${filename}.jpg`];
  }),
});
