import Model, { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';

export default class SavedFilterModel extends Model {
  @attr name;
  @attr currentId;
  @attr currentUser;
  @attr('boolean', { defaultValue: false }) nameOnly;
  @fragment('saved-filter-content', { defaultValue: {} }) content;

  isEqual(other) {
    return this.id === other.id;
  }
}
