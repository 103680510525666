import classic from 'ember-classic-decorator';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { oneWay } from '@ember/object/computed';
import $ from 'jquery';
import { ChannelUtil } from 'appkit/lib/channel';
import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import showRelayRegisterInterest from '../lib/show-relay-register-interest';
import { getOwner } from '@ember/application';

@classic
export default class DashboardController extends Controller {
  @controller application;
  // -- Parameters ---------------------------------------------------------------------
  @service('global-nav')
  globalNav;

  @service('-routing')
  router;

  @service
  featureFlag;

  @service
  staffView;

  @service
  beyondI18n;

  @service
  refresh;

  @service
  intl;

  @service
  embed;

  @oneWay('currentUser.isStaff')
  isStaff;

  @oneWay('model.user.signalCustomerId')
  signalCustomerId;

  @oneWay('currentUser.isRelayEnabled')
  isRelayEnabled;

  @oneWay('currentUser.languagePreference')
  languagePreference;

  showOwnerResource = false;
  selectLocaleControl;
  date = new Date();

  @tracked displayNotification = false;

  @tracked showSignal = false;

  // -- Lifecycle Hooks ----------------------------------------------------------------

  async init() {
    super.init(...arguments);

    const showOwnerResource = await this.featureFlag.evaluate('owner-resource', false);
    const showSignal = await this.featureFlag.evaluate('signal-dashboard', false);
    const surfaceRelayInApp = await this.featureFlag.evaluate(
      'surface-relay-in-app',
      false
    );
    const supercontrolFFEnabled = await this.featureFlag.evaluate(
      'surface-relay-in-app-supercontrol',
      false
    );

    const trackFFEnabled = await this.featureFlag.evaluate(
      'surface-relay-in-app-track',
      false
    );

    const uncontractedFlowEnabled = await this.featureFlag.evaluate(
      'surface-relay-in-app-uncontracted',
      false
    );

    set(this, 'showOwnerResource', showOwnerResource);
    set(this, 'showSignal', showSignal);
    set(this, 'surfaceRelayInApp', surfaceRelayInApp);
    set(this, 'supercontrolFFEnabled', supercontrolFFEnabled);
    set(this, 'trackFFEnabled', trackFFEnabled);
    set(this, 'uncontractedFlowEnabled', uncontractedFlowEnabled);
  }

  get signalDashboardRoute() {
    if (this.signalCustomerId) return 'dashboard.signal';

    return 'dashboard.signal.welcome';
  }

  // -- Computed Properties ------------------------------------------------------------
  get locales() {
    return this.beyondI18n.locales;
  }

  get selectedLocale() {
    return this.beyondI18n.selectedLocale;
  }

  get systemLanguage() {
    return this.beyondI18n.getSystemLanguage(this.currentUser.locale).label;
  }

  @computed('model.listings.[]')
  get listings() {
    return this.model.listings.filter(
      l =>
        l.channelListings.length === 0 ||
        !l.channelListings[0].multiUnitParentId ||
        (l.channelListings[0].multiUnitParentId &&
          l.channelListings[0].multiUnitGroupTitle)
    );
  }

  @computed('model.accounts.@each.valid')
  get invalidAccounts() {
    return this.get('model.accounts').filter(item => !item.get('valid'));
  }

  @computed('model.credential.isAdministrator')
  get isAdmin() {
    return this.get('model.credential.isAdministrator');
  }

  @computed('model.accounts.length')
  get accountsCount() {
    return this.get('model.accounts.length');
  }

  @computed('accountsCount')
  get hasAccounts() {
    return this.accountsCount > 0;
  }

  @computed('model.accounts.@each.channel')
  get accountChannels() {
    return Array.from(new Set([...this.model.accounts.map(a => a.channel)]));
  }

  @computed('accountChannels.length')
  get hasPms() {
    return this.accountChannels.some(ac => ChannelUtil.isPms(ac));
  }

  @computed('accountChannels.length')
  get hasDirect() {
    return this.accountChannels.some(ac => !ChannelUtil.isPms(ac));
  }

  @computed('accountChannels.length')
  get hasPmsWithRelaySupport() {
    return this.accountChannels.some(ac => ChannelUtil.supportsRelay(ac));
  }

  @computed('hasPmsWithRelaySupport', 'hasPms', 'hasDirect')
  get displayRelay() {
    return (
      this.hasPmsWithRelaySupport ||
      (this.hasPms && this.hasDirect) ||
      (showRelayRegisterInterest(
        this.get('model.accounts'),
        this.get('model.user'),
        this.supercontrolFFEnabled,
        this.trackFFEnabled,
        this.uncontractedFlowEnabled
      ) &&
        this.surfaceRelayInApp)
    );
  }

  @computed('model.{accounts,user}')
  get relayLink() {
    if (
      showRelayRegisterInterest(
        this.get('model.accounts'),
        this.get('model.user'),
        this.supercontrolFFEnabled,
        this.trackFFEnabled
      ) &&
      this.surfaceRelayInApp
    ) {
      return 'dashboard.relay.welcome';
    }

    return 'dashboard.relay';
  }

  @computed('router.currentPath')
  get wideContent() {
    const wideRoutes = [
      'dashboard.pricing.index',
      'dashboard.grid.index',
      'dashboard.grid.filter',
      'dashboard.account-stats.index',
      'dashboard.pricing.map',
      'dashboard.relay',
      'dashboard.connect',
      'dashboard.connect-prompt',
      'dashboard.signal',
      'dashboard.pricing.listing',
    ].filter(id => id);

    if (wideRoutes.find(r => this.router.currentPath.includes(r))) {
      return 'wide';
    }
    return '';
  }

  @computed('router.currentPath')
  get noShadow() {
    if (
      this.router.currentPath.includes('dashboard.pricing.index') ||
      this.router.currentPath.includes('dashboard.pricing.map') ||
      this.router.currentPath.includes('dashboard.relay') ||
      this.router.currentPath.includes('dashboard.connect-prompt') ||
      this.router.currentPath.includes('dashboard.pricing.listing')
    ) {
      return 'no-shadow';
    }
    return '';
  }

  @computed('router.currentPath')
  get displaySearch() {
    if (this.router.currentPath.includes('dashboard.pricing.index')) {
      return true;
    } else if (this.router.currentPath.includes('dashboard.relay.merge')) {
      return true;
    } else if (this.router.currentPath.includes('dashboard.signal.index')) {
      return true;
    } else {
      return false;
    }
  }

  @computed('router.currentPath')
  get displayListingSearch() {
    return this.router.currentPath.includes('dashboard.pricing.listing');
  }

  @action
  clickedMobileMenu() {
    if (this.embed.isEmbedded) {
      this.embed.openDrawer();
    } else {
      // show left nav
      $('.side-nav').addClass('show');
    }
  }

  @action
  clickedMobileMenuClose() {
    if ($('.side-nav.show').length) {
      $('.side-nav').removeClass('show');
    }
  }

  @action
  changeLocale(option) {
    this.beyondI18n.updateLocale(option.field);
    getOwner(this).lookup('route:dashboard').refresh();
  }

  @action
  updateLanguage(language) {
    // for modal that allows user to update language preference
    this.beyondI18n.updateLocale(language);
    this.currentUser.set('updateLanguage', false);
  }

  @action
  showNotification() {
    this.displayNotification = true;
    this.selectLocaleControl.actions.open();

    setTimeout(() => {
      this.displayNotification = false;
      this.selectLocaleControl.actions.close();
    }, 6000);
  }

  @action
  async onIgnoreLocaleSuggestion() {
    this.currentUser.set('updateLanguage', false);
    localStorage.setItem('hideLocaleUpdate', 'true');
    this.beyondI18n.updateLocale(this.currentUser.initialLocale);
    await getOwner(this).lookup('route:dashboard').refresh(); //revert to user selected locale
    // loading page is displaying so adding a timeout for the dashboard page to render before showing the notification
    setTimeout(() => {
      this.showNotification();
    }, 1000);
  }

  @action
  clearRefreshStatus() {
    this.refresh.status = '';
  }

  @action
  redirectToListing(listing) {
    this.router.router.transitionTo('dashboard.pricing.listing', listing.id);
  }
}
