import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class HomeawayRoute extends AuthRoute {
  controllerName = 'listing.nearby.inner';
  templateName = 'listing/nearby/inner';

  setupController(controller, model) {
    controller.set('model', model);
    controller.nearbyController.set('channel', 'homeaway');

    controller.nearbyController.set('nearbyListings', model.get('listings'));
    controller.nearbyController.set('_nearbyListingsCacheHomeaway', model);
  }

  model() {
    let listing = this.modelFor('preview').get('listing');
    let id = listing.get('id');
    let channel = listing.get('channel');
    let url = `/api/channellistings/preview/${channel}/${id}/nearby/homeaway`;

    let cache = this.get('controller.nearbyController._nearbyListingsCacheHomeaway');
    if (cache) {
      return cache;
    }

    return this.ajax._get(url).then(data =>
      EmberObject.create({
        listings: data.listings.map(row => EmberObject.create(row)),
      })
    );
  }
}
