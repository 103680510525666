import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';

export default class DashboardUserBillingCardSessionRoute extends AuthRoute {
  @service cards;
  @service forcePaymentMethod;

  async afterModel(model, transition) {
    const url = '/api/cards';
    const data = {
      session_id: transition.to.queryParams.id,
    };

    this.cards.add(url, data, model);

    this.forcePaymentMethod.resume();
  }
}
