import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@classNames('app-sort-arrow')
export default class AppSortArrow extends Component {
  // -- Parameters ---------------------------------------------------------------------
  @service('list-control')
  listControl;

  sortFieldTarget = null; // @type {String}

  // -- Computed Properties ------------------------------------------------------------
  @computed('sortFieldTarget', 'listControl.sortField')
  get isActive() {
    return this.sortFieldTarget === this.get('listControl.sortField');
  }

  // -- Lifecycle Hooks ----------------------------------------------------------------
  // -- Actions ------------------------------------------------------------------------
  // -- Private Functions --------------------------------------------------------------
  // -- Overwrite ----------------------------------------------------------------------
}
