// Sizes (height)
// xxs - 16px
// xs - 24px
// sm - 32px
// md - 36px
// lg - 40px
// xl - 48px
export const SIZES = {
  ALL: 'all',
  XXS: 'xxs',
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export const COLORS = {
  ALL: 'all',
  BRAND: 'brand',
  GRAY: 'gray',
  SUCCESS: 'success',
  ERROR: 'error',
  BLACK: 'black',
  WHITE: 'white',
};

export const VARIANTS = {
  ALL: 'all',
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
};
