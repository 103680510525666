import moment from 'moment';

/**
 * Recovers the state of a listingDate for a concrete moment if time,
 * and for each of the channels
 *
 * //IDEA: this could be in the model itself!
 **/
export function getAvailabilitySnapshot(snapshotTimestamp, PMS, directChannels) {
  const getClosestStamp = (snapshotTimestamp, updateList) => {
    const closestDates = updateList.filter(update =>
      moment(update.timestamp).isSameOrBefore(snapshotTimestamp)
    );
    // Natural orden: Ascending (older to newest)

    if (!closestDates.length) return null;

    return closestDates.reverse()[0];
  };
  const snapshot = {
    snapshotTimestamp,
    pms: {
      label: PMS.label,
      snapshot: getClosestStamp(snapshotTimestamp, PMS.updateStamps),
    },
    directChannels: [],
  };

  for (const dc of directChannels) {
    snapshot.directChannels.push({
      label: dc.label,
      snapshot: getClosestStamp(snapshotTimestamp, dc.updateStamps),
    });
  }

  console.log({ calendarDate: PMS.date, snapshotStamp: snapshotTimestamp, snapshot });

  return snapshot;
}
