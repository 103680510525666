import { helper } from '@ember/component/helper';

export default helper(function (params) {
  const day = params[0];

  const priceWithoutOverrides = day.priceModeledWithoutOverrides;
  const dynamicManualOverride = day.percentageUserOverride;
  const annualMinPrice = day.listing.minPrice;
  const annualMaxPrice = day.listing.maxPrice;
  const seasonalMinPrice = day.minPriceUser;
  const seasonalMaxPrice = day.maxPriceUser;

  const minPrice = seasonalMinPrice || day.dowMinPrice || annualMinPrice;
  const maxPrice = seasonalMaxPrice ? seasonalMaxPrice : annualMaxPrice;

  let price = Math.round(priceWithoutOverrides * (1 + dynamicManualOverride));

  price = minPrice ? Math.max(price, minPrice) : price;
  price = maxPrice ? Math.min(price, maxPrice) : price;

  return price;
});
