import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import Util from 'appkit/lib/util';

@classic
export default class UnavailableController extends Controller {
  loading = false;
  email = '';

  @action
  submitForm() {
    let email = this.email;
    Util.isEmailInvalid(this.email).then(reason => {
      if (reason) {
        this.set('error', reason);
        return false;
      }
      this.set('loading', true);

      let data = {
        reason: 'Notify when available',
        market: this.get('model.market'),
        email: email,
        airbnb_id: this.get('model.id'),
      };

      this.ajax._post('/api/contact', data).then(() => {
        this.set('posted', true);
      });

      return false;
    });
  }
}
