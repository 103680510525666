import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import { action } from '@ember/object';

@classic
export default class AirbnbPricingRoute extends AuthRoute {
  templateName = 'data.highcharts-stock';
  controllerName = 'data.pricing';

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('site', 'airbnb_v2');
    let cluster = this.modelFor('data.cluster');
    controller.set('pageTitle', `Airbnb Pricing -- ${cluster.get('name')}`);
    let url = `${location.origin}/admin/api/clusters/${cluster.get(
      'id'
    )}/airbnb/pricing.csv?token=${this.currentUser.get('token')}`;
    this.controllerFor('data.cluster').set('csvUrl', url);
    let csvFilename = `airbnb-pricing_${cluster.get('market.name')}_${cluster.get(
      'name'
    )}.csv`;
    this.controllerFor('data.cluster').set('csvFilename', csvFilename);
  }

  deactivate() {
    this.controllerFor('data.cluster').set('csvUrl', false);
  }

  model() {
    let cluster = this.modelFor('data.cluster');
    let url = `/admin/api/clusters/${cluster.id}/airbnb_v2/pricing`;

    let filterCols = (arr, col) =>
      arr.filter(row => row[col]).map(row => [row[0], row[col]]);

    return this.ajax._get(url).then(data =>
      EmberObject.create({
        prices: {
          price0Bed: filterCols(data.availability, 1),
          price1Bed: filterCols(data.availability, 2),
          price2Bed: filterCols(data.availability, 3),
          price3Bed: filterCols(data.availability, 4),
          price4Bed: filterCols(data.availability, 5),
          price5Bed: filterCols(data.availability, 6),
          price6Bed: filterCols(data.availability, 7),
          price7Bed: filterCols(data.availability, 8),
          price8Bed: filterCols(data.availability, 9),
          price9Bed: filterCols(data.availability, 10),
          price10Bed: filterCols(data.availability, 11),
          price11Bed: filterCols(data.availability, 12),
          priceRooms: filterCols(data.availability, 13),
        },
        counts: {
          count0Bed: filterCols(data.availability, 14),
          count1Bed: filterCols(data.availability, 15),
          count2Bed: filterCols(data.availability, 16),
          count3Bed: filterCols(data.availability, 17),
          count4Bed: filterCols(data.availability, 18),
          count5Bed: filterCols(data.availability, 19),
          count6Bed: filterCols(data.availability, 20),
          count7Bed: filterCols(data.availability, 21),
          count8Bed: filterCols(data.availability, 22),
          count9Bed: filterCols(data.availability, 23),
          count10Bed: filterCols(data.availability, 24),
          count11Bed: filterCols(data.availability, 25),
          countRooms: filterCols(data.availability, 26),
        },
      })
    );
  }

  @action
  compareCluster(id) {
    this.controller.send('compareCluster', id);
  }

  @action
  compareMarket(id) {
    this.controller.send('compareMarket', id);
  }
}
