import classic from 'ember-classic-decorator';
import { on } from '@ember-decorators/object';
import Service from '@ember/service';
// import ENV from 'appkit/config/environment';

@classic
export default class SupportVisibleService extends Service {
  default = false; // ENV.environment === 'production',
  value = false;

  @on('init')
  _loadDefault() {
    this.set('value', this['default']);
  }
}
