import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import logger from 'appkit/lib/logger';

@classic
@tagName('form')
@classNames('demo-form horizontal')
export default class AppPreviewForm extends Component {
  placeholder = 'Enter your Airbnb/Booking Listing URL.';
  errors = false;
  loading = false;
  url = '';

  submit() {
    return false;
  }

  @action
  submitForm() {
    this.set('loading', true);
    let error = () => {
      logger.error('URL parsing error');
      // Kind of dumb, but gives visual feedback the click worked.
      setTimeout(() => {
        this.set('errors', true);
        this.set('loading', false);
      }, 400);
    };

    this.set('errors', false);

    let payload = {
      url: this.url.trim(),
    };

    this.ajax
      ._post('/api/channellistings/preview/url/parse', payload)
      .then(
        data => {
          window.t = this;
          this.router.transitionTo('preview', `${data.channel}-${data.id}`);
        },
        errors => {
          let data = errors[0];
          if (data.meta && data.meta.code === 'unavailable') {
            this.router.transitionTo('error.unavailable', `${data.channel}-${data.id}`);
          } else {
            logger.error('Server error', data);
            error();
          }
        }
      )
      .finally(() => {
        this.set('loading', false);
      });

    return false;
  }
}
