import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Controller from '@ember/controller';

function availabilitySeries(name, data, visible) {
  return {
    name: name,
    data: data,
    visible: !!visible,
    type: 'area',
    yAxis: 1,
    tooltip: { valueDecimals: 2, valueSuffix: '%' },
  };
}

function factorSeries(name, data, visible) {
  return {
    name: name,
    data: data,
    visible: !!visible,
    yAxis: 1,
    tooltip: { valueDecimals: 2, valueSuffix: '%' },
  };
}

function priceSeries(name, data, visible) {
  return {
    id: name + '_price',
    name: name,
    data: data,
    visible: !!visible,
    yAxis: 0,
    tooltip: { valueDecimals: 0, valuePrefix: '$' },
  };
}

function eventSeries(name, data, visible) {
  return {
    name: name + ' Events',
    visible: !!visible,
    yAxis: 0,
    tooltip: { valueDecimals: 0, valuePrefix: '$' },
    type: 'flags',
    onSeries: name + '_price',
    data: data.map(event => ({
      x: event[0],
      title: '*',
      text: event[1],
    })),
  };
}

@classic
export default class ExperimentalPricingController extends Controller {
  model = null;

  compareId = null;
  loadingCompareData = false;
  compareData = [];

  chartOptions = {
    rangeSelector: {
      selected: 5,
    },
    yAxis: [
      {
        labels: {
          format: '${value}',
        },
        title: {
          text: 'Price (USD)',
        },
      },
      {
        title: {
          text: 'Percent',
        },
        labels: {
          text: '{value}%',
        },
      },
    ],
    legend: {
      padding: 8,
      enabled: true,
      itemDistance: 10,
    },
    navigator: { baseSeries: 0 },
  };

  @computed('model{pricingModels,availabilityCurve}', 'compareData')
  get chartData() {
    let charts = [];

    let availabilityCurve = this.get('model.availabilityCurve');
    charts.push(availabilitySeries('Availability Curve', availabilityCurve, false));
    charts[0].color = '#c1c1c1';
    charts[0].lineWidth = 3;

    let palettes = {
      reds: ['#7f0000', '#d7301f', '#fc8d59'],
      blues: ['#084081', '#2b8cbe', '#7bccc4'],
      purples: ['#3f007d', '#6a51a3', '#9e9ac8'],
    };

    let colorOrder = ['reds', 'blues', 'purples'];

    let pricingModels = this.get('model.pricingModels');

    pricingModels.forEach(function (pricingModel, index) {
      let priceChart = priceSeries(
        pricingModel.humanName,
        pricingModel.dailyPrices,
        true
      );
      let color = colorOrder[index];
      priceChart.color = palettes[color][0];
      priceChart.lineWidth = 1;
      charts.push(priceChart);

      let dowChart = factorSeries(
        pricingModel.humanName + ' DoW',
        pricingModel.dowFactors,
        false
      );
      dowChart.color = palettes[color][1];
      dowChart.lineWidth = 1;
      charts.push(dowChart);

      let seasonalityChart = factorSeries(
        pricingModel.humanName + ' Seasonality',
        pricingModel.seasonalityFactors,
        false
      );
      seasonalityChart.color = palettes[color][2];
      seasonalityChart.lineWidth = 1;
      charts.push(seasonalityChart);

      let eventChart = eventSeries(
        pricingModel.humanName,
        pricingModel.eventFactors,
        false
      );
      charts.push(eventChart);
    });

    return charts;
  }
}
