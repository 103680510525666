import { action, set } from '@ember/object';
import AuthRoute from 'appkit/routes/auth';

export default class DashboardGridBulkEditRoute extends AuthRoute {
  @action
  willTransition() {
    this.controller.customize.resetChanges();
  }

  async beforeModel() {
    super.beforeModel(arguments);
    let gridController = this.controllerFor('dashboard.grid');

    if (!gridController.model?.listings.filter(listing => listing.selected))
      return this.transitionTo('dashboard.grid');
  }

  setupController(controller, model) {
    set(controller, 'model', model);
    set(controller, 'listingReferralToImport', null);
    set(controller, 'listingReferralSettings', null);
    controller.resetCustomize();
  }

  templateName = 'dashboard.pricing.index.bulk-edit';
}
