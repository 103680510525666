import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { alias } from '@ember/object/computed';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class PaymentRoute extends AuthRoute {
  @service ajax;

  @computed()
  get invoiceFieldToKey() {
    return {
      pricing: 'glossary.pricing',
      growth_package: 'glossary.growthPackage',
      relay: 'glossary.productRelay',
      guidance: 'glossary.productGuidance',
      signal: 'glossary.productSignal',
      one_off: 'common.oneOff',
      balance_adjustment: 'common.balanceAdjustment',
      tax: 'relay.taxes',
    };
  }

  @computed('model.payment')
  get title() {
    if (this.paymentDate) {
      return `Invoice ${this.paymentDate} | Beyond Pricing`;
    } else {
      return 'Dynamic Pricing / Dashboard';
    }
  }

  @alias('model.payment.date')
  paymentDate;

  async model(params) {
    // Get the statement
    let user = this.modelFor('dashboard.user');
    await this.getBillingPeriods(user);

    let payment = user.payments.filter(payment => payment.id == params.id)[0];
    let { startDate, endDate } = this.getPaymentPeriod(user, payment);
    await this.getPaymentAmountSplit(user, payment);

    user.totalAmount = user.split.reduce((acc, v) => acc + Number(v.sum), 0);
    user.periodFrom = startDate;
    user.periodTo = endDate;
    user.payment = payment;

    return user;
  }

  getPaymentPeriod(user, payment) {
    let paymentDate = moment(payment.date, 'YYYY-MM-DD');
    let period = user.periods.find(
      p => moment(p.startDate) < paymentDate && paymentDate <= moment(p.endDate)
    );

    if (period) {
      return { startDate: period.startDate, endDate: period.endDate };
    } else {
      return { startDate: user.createdAt, endDate: moment() };
    }
  }

  getBillingPeriods(user) {
    return this.ajax._get('/api/billing/periods').then(r => {
      let periods = r.map(period =>
        this.bpStore.createRecord('billing-period', period)
      );

      user.set('periods', periods);
    });
  }

  getPaymentAmountSplit(user, payment) {
    const defaultSplit = [{ sum: payment.amount, product: 'admin.monthlyServiceFee' }];

    return this.ajax
      ._get(`/api/billing/payment/${payment.id}/invoice`)
      .then(r => {
        const split = r.map(s => ({
          ...s,
          product: this.invoiceFieldToKey[s.product],
        }));

        user.set('split', split.length > 0 ? split : defaultSplit);
      })
      .catch(() => {
        user.set('split', defaultSplit);
      });
  }

  renderTemplate() {
    this.send('changeLayout', 'empty');
    this.render('dashboard.user.payment', { into: 'empty' });
  }
}
