import BpSelectBaseComponent from './base';

export default class BpSelectComponent extends BpSelectBaseComponent {
  get powerSelectComponent() {
    return 'power-select';
  }

  get beforeOptionsComponent() {
    return this.args.beforeOptionsComponent ?? 'bp/select/parts/before-options';
  }
}
