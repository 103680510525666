import classic from 'ember-classic-decorator';
import { classNames, classNameBindings } from '@ember-decorators/component';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@classNames('app-question leading-5')
@classNameBindings('isQuestion:question:custom')
export default class AppQuestion extends Component {
  hoverClass = 'popper';

  @equal('questionText', '?')
  isQuestion;

  questionText = '?';
  visible = false;

  // for svg-icon
  isSvgIcon = false;

  svgSrc = null;

  didInsertElement() {
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.element.addEventListener('mouseenter', this.handleMouseEnter);
    this.element.addEventListener('mouseleave', this.handleMouseLeave);
  }

  willDestroyElement() {
    this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    this.element.removeEventListener('mouseleave', this.handleMouseLeave);
  }

  handleMouseEnter() {
    this.set('visible', true);
    this.onHandleMouseEnter?.();
  }

  handleMouseLeave() {
    this.set('visible', false);
  }
}
