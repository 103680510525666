import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    listingPoints=this.listingPoints\n    navigationControl=this.navigationControl\n    clusterDetails=this.clusterDetails\n    onPopupCall=this.onPopupCall\n    actions=(hash\n      onTriggeringClick=this.onTriggeringClick\n      onTriggeringSourceData=this.onTriggeringSourceData\n      updateInfoPoints=this.updateInfoPoints\n      showPopupDetails=this.showPopupDetails\n    )\n  )\n}}", {"contents":"{{yield\n  (hash\n    listingPoints=this.listingPoints\n    navigationControl=this.navigationControl\n    clusterDetails=this.clusterDetails\n    onPopupCall=this.onPopupCall\n    actions=(hash\n      onTriggeringClick=this.onTriggeringClick\n      onTriggeringSourceData=this.onTriggeringSourceData\n      updateInfoPoints=this.updateInfoPoints\n      showPopupDetails=this.showPopupDetails\n    )\n  )\n}}","moduleName":"appkit/components/dashboard/map-view/map-container.hbs","parseOptions":{"srcName":"appkit/components/dashboard/map-view/map-container.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import MapboxGl from 'mapbox-gl';

export default class DashboardMapViewMapContainerComponent extends Component {
  @tracked listingPoints;
  @tracked clusterDetails;
  @tracked onPopupCallFromCluster;
  @tracked selectedClusterDetails;
  @tracked onPopupCallFromSinglePoint;
  @tracked selectedSinglePointDetails;
  @tracked onPopupCallFromManyOnPoint;
  @tracked selectedManyOnPointDetails;

  navigationControl = new MapboxGl.NavigationControl({ showCompass: false });

  @action
  updateInfoPoints() {
    this.listingPoints = this.args.listings
      .map(l => {
        const out = {};

        let sameLocationListings = this.args.listings.filter(
          a => a.latitude == l.latitude && a.longitude == l.longitude
        );

        out['type'] = 'Feature';
        out['properties'] = {};

        out['properties']['sameLocationCount'] = sameLocationListings.length;
        out['properties']['groupOnSameLocation'] = sameLocationListings.map(l => l.id);
        out['properties']['isInTargetCluster'] = false;
        out['geometry'] = {};
        out['geometry']['type'] = 'Point';
        out['geometry']['coordinates'] = [Number(l.longitude), Number(l.latitude)];

        out['properties']['id'] = l.id;

        out['properties']['address'] =
          l.address ?? [Number(l.latitude), Number(l.longitude)].join(', ');
        out['properties']['image'] = l.imageOrDefault;

        out['properties']['title'] = l.title;
        out['properties']['bedrooms'] = l.bedrooms ?? '--';
        out['properties']['bathrooms'] =
          l.bathrooms != null ? l.bathroomsNoDecimal : '--';
        out['properties']['canPrice'] = l.primaryChannelListing?.canPrice;

        out['properties']['healthScore'] = l.healthScore;
        out['properties']['bookedThirty'] = l.bookedThirty;
        out['properties']['bookedNinety'] = l.bookedNinety;

        out['properties']['basePrice'] = l.basePrice;
        out['properties']['minPrice'] = l.minPrice;
        out['properties']['currency'] = l.currency;

        return out;
      })
      .filter(f => f.geometry.coordinates[0] !== 0 && f.geometry.coordinates[1] !== 0);
  }

  @action
  onTriggeringSourceData({ target: map }) {
    if (!map.hasImage('more-details')) return;
    if (map.getLayoutProperty('clusters-expand-ui', 'icon-image')) return;

    map.setLayoutProperty('clusters-expand-ui', 'icon-image', 'more-details');

    let p0 = [
      this.listingPoints
        .map(lp => lp.geometry.coordinates[0])
        .sort((a, b) => {
          return a - b;
        })[0],
      this.listingPoints
        .map(lp => lp.geometry.coordinates[1])
        .sort((a, b) => {
          return a - b;
        })[0],
    ];

    let p1 = [
      this.listingPoints
        .map(lp => lp.geometry.coordinates[0])
        .sort((a, b) => {
          return a - b;
        })
        .slice(-1)[0],
      this.listingPoints
        .map(lp => lp.geometry.coordinates[1])
        .sort((a, b) => {
          return a - b;
        })
        .slice(-1)[0],
    ];

    map.fitBounds([p0, p1], {
      padding: { top: 100, bottom: 250, left: 50, right: 50 },
    });
  }

  @action
  onTriggeringClick({ target: map, point }) {
    this.selectedClusterDetails = null;
    this.selectedSinglePointDetails = null;
    this.selectedManyOnPointDetails = null;
    this.clusterDetails = null;
    this.onPopupCallFromCluster = false;
    this.onPopupCallFromSinglePoint = false;
    this.onPopupCallFromManyOnPoint = false;
    this.clusterClick(map, point);
    this.offsetClusterClick(map, point);
    this.singlePointClick(map, point);
    this.manyOnPointClick(map, point);
  }

  @action
  showPopupDetails({ target: map }) {
    this.showClusterDetails(map);
    this.showSinglePointDetails(map);
    this.showManyOnPointDetails(map);
  }

  @action
  showManyOnPointDetails() {
    if (!this.onPopupCallFromManyOnPoint && !this.selectedManyOnPointDetails) return;

    let listings = this.listingPoints.filter(l =>
      this.selectedManyOnPointDetails.includes(l.properties.id)
    );

    this.clusterDetails = {};
    this.clusterDetails.popupOffset = [7, 10];
    this.clusterDetails.coordinates = listings[0].geometry.coordinates;
    this.clusterDetails.listingsGroups = [
      {
        address: listings[0].properties.address,
        coordinates: listings[0].geometry.coordinates,
        listingsCount: listings.length,
        listings: listings,
      },
    ];

    this.clusterDetails.isReady = true;
  }

  @action
  showSinglePointDetails() {
    if (!this.onPopupCallFromSinglePoint && !this.selectedSinglePointDetails) return;

    let listing = this.selectedSinglePointDetails;

    this.clusterDetails = {};
    this.clusterDetails.popupOffset = [5, 8];
    this.clusterDetails.coordinates = listing.geometry.coordinates;
    this.clusterDetails.listingsGroups = [
      {
        address: listing.properties.address,
        coordinates: listing.geometry.coordinates,
        listingsCount: 1,
        listings: this.listingPoints.filter(
          l => l.properties.id === listing.properties.id
        ),
      },
    ];

    this.clusterDetails.isReady = true;
  }

  @action
  showClusterDetails(map) {
    if (!this.onPopupCallFromCluster && !this.selectedClusterDetails) return;
    map
      .getSource('bp-map')
      .getClusterLeaves(
        this.selectedClusterDetails.properties.cluster_id,
        200,
        0,
        (err, aFeatures) => {
          if (err) return;

          this.clusterDetails = {};
          this.clusterDetails.popupOffset = [15, 18];
          this.clusterDetails.coordinates = this.selectedClusterDetails.geometry.coordinates;
          let listingsIdList = aFeatures.map(f => f.properties.id);

          this.listingPoints = this.listingPoints.map(lp => ({
            ...lp,
            properties: {
              ...lp.properties,
              isInTargetCluster: listingsIdList.includes(lp.properties.id),
            },
          }));

          let clusterDetailsListings = this.listingPoints.filter(
            lp => lp.properties.isInTargetCluster
          );

          let sortListingsBylocation = [
            ...new Set(
              clusterDetailsListings.map(l => l.geometry.coordinates.join('_'))
            ),
          ];

          this.clusterDetails.listingsGroups = sortListingsBylocation
            .map(l => {
              let listings = this.listingPoints.filter(
                lp => lp.geometry.coordinates.join('_') == l
              );

              return {
                coordinates: l.split('_'),
                address: listings[0].properties.address,
                listingsCount: listings.length,
                listings: listings,
              };
            })
            .sort((a, b) => {
              if (a.listingsCount < b.listingsCount) {
                return 1;
              } else {
                return -1;
              }
            });
          this.clusterDetails.isReady = true;
        }
      );
  }

  @action
  clusterClick(map, point) {
    const features = map.queryRenderedFeatures(point, {
      layers: ['clusters'],
    });
    if (!features.length) {
      return;
    }

    const clusterId = features[0].properties.cluster_id;
    map.getSource('bp-map').getClusterExpansionZoom(clusterId, function (err, zoom) {
      if (err) return;

      map.easeTo({
        center: features[0].geometry.coordinates,
        zoom: zoom - map.style.z > 10 ? 14 : zoom,
        animate: true,
      });
    });
  }

  @action
  offsetClusterClick(map, point) {
    const features = map.queryRenderedFeatures([point.x - 10, point.y - 10], {
      layers: ['clusters-expand-ui'],
    });
    if (!features.length) {
      return;
    }
    this.onPopupCallFromCluster = true;

    this.selectedClusterDetails = {};
    this.selectedClusterDetails = features[0];
    map.panTo(map.unproject([point.x + 325, point.y + 250]));
  }

  @action
  singlePointClick(map, point) {
    const singlePoint = map.queryRenderedFeatures(point, {
      layers: ['unclustered-count'],
    });

    if (!singlePoint.length) {
      return;
    }

    this.onPopupCallFromSinglePoint = true;

    this.selectedSinglePointDetails = {};
    this.selectedSinglePointDetails = singlePoint[0];
    map.panTo(map.unproject([point.x + 325, point.y + 250]));
  }

  @action
  manyOnPointClick(map, point) {
    const manyOnPoint = map.queryRenderedFeatures(point, {
      layers: ['same-location-points'],
    });

    if (!manyOnPoint.length) {
      return;
    }

    this.onPopupCallFromManyOnPoint = true;

    this.selectedManyOnPointDetails = {};
    this.selectedManyOnPointDetails = manyOnPoint.map(
      l => l.properties.groupOnSameLocation
    )[0];

    map.panTo(map.unproject([point.x + 325, point.y + 250]));
  }
}
