import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<input\n  class='pr-3 pl-3 py-2 px-3 rounded border\n  {{if this.isDirty 'bg-brand-200' 'bg-gray-100'}}\n   focus:bg-brand-200 focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 w-full\n  {{if @hasError 'border-error-400' 'focus:border-brand-400 border-brand-200'}}'\n  type='text'\n  ...attributes\n  {{on 'focus' this.toggleIsFocused}}\n  {{on 'blur' this.toggleIsFocused}}\n  {{on 'change' (queue this.onChange this.setHasValue)}}\n  {{on 'input' this.onInput}}\n  {{on 'focusout' this.onFocusout}}\n/>", {"contents":"<input\n  class='pr-3 pl-3 py-2 px-3 rounded border\n  {{if this.isDirty 'bg-brand-200' 'bg-gray-100'}}\n   focus:bg-brand-200 focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 w-full\n  {{if @hasError 'border-error-400' 'focus:border-brand-400 border-brand-200'}}'\n  type='text'\n  ...attributes\n  {{on 'focus' this.toggleIsFocused}}\n  {{on 'blur' this.toggleIsFocused}}\n  {{on 'change' (queue this.onChange this.setHasValue)}}\n  {{on 'input' this.onInput}}\n  {{on 'focusout' this.onFocusout}}\n/>","moduleName":"appkit/components/bp/input/text.hbs","parseOptions":{"srcName":"appkit/components/bp/input/text.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BpInputTextComponent extends Component {
  @tracked isFocused = false;
  @tracked _isDirty = false;

  get isDirty() {
    return this.args.isDirty ?? this._isDirty;
  }

  @action
  toggleIsFocused() {
    this.isFocused = !this.isFocused;
    this.args.toggleIsFocused?.(this.isFocused);
  }

  @action
  setHasValue(evt) {
    this._isDirty = !!evt.target.value;
  }

  @action
  onInput(evt) {
    this.args.onInput?.(evt);
  }

  @action
  onFocusout(evt) {
    this.args.onFocusout?.(evt);
  }

  @action
  onChange(evt) {
    this.args.onChange?.(evt);
  }
}
