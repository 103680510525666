/* global Plaid:false */
import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import classic from 'ember-classic-decorator';
import ENV from 'appkit/config/environment';

const fetchLinkToken = async ajax => {
  const result = await ajax._get('/api/plaid/link_token');

  return result['linkToken'];
};

const showSuccessAlert = alertService => message => {
  alertService.success(message, {
    timeout: 6000,
  });
};

const showErrorAlert = (alertService, intlService) => {
  alertService.error(intlService.t('admin.addBankAccountFailure'), {
    timeout: 6000,
  });
};

@classic
export default class PlaidService extends Service {
  @service ajax;
  @service alert;
  @service intl;

  linkHandler = null;
  onFinish = null;

  async initPlaid() {
    const alertService = this.alert;
    const ajaxService = this.ajax;
    const intlService = this.intl;
    const plaidLinkToken = await fetchLinkToken(ajaxService);

    const configs = {
      token: plaidLinkToken,
      env: ENV.APP.PLAID_ENVIRONMENT,
      onSuccess: (publicToken, metadata) => {
        const account = metadata['account'];
        const institution = metadata['institution']['name'];
        const mask = account['mask'];

        ajaxService
          ._post('/api/ach/add', {
            public_token: publicToken,
            account: {
              ...account,
              institution: institution,
            },
          })
          .then(() => {
            this.onFinish({ institution, lastFour: mask });
            showSuccessAlert(alertService)(
              intlService.t('admin.addBankAccountSuccess')
            );
          })
          .catch(() => {
            this.onFinish();
            showErrorAlert(alertService, intlService);
          });
      },
      onExit: async (err, _) => {
        if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
          this.linkHandler.destroy();
          this.linkHandler = Plaid.create({
            ...configs,
            token: await fetchLinkToken(),
          });
        }
        if (err != null) {
          showErrorAlert(alertService, intlService);
        }
        this.onFinish();
      },
    };

    return Plaid.create(configs);
  }

  async getInstance() {
    if (!this.linkHandler) {
      this.linkHandler = await this.initPlaid();
    }

    return this.linkHandler;
  }

  @action
  async openLink(onFinish) {
    this.onFinish = onFinish;
    (await this.getInstance()).open();
  }

  @action
  async delete(onFinish) {
    this.ajax
      ._delete('/api/ach')
      .then(() => {
        showSuccessAlert(this.alert)(this.intl.t('admin.deleteBankAccountSuccess'));
        onFinish(null);
      })
      .catch(() => {
        showErrorAlert(this.alert, this.intl);
        onFinish(null);
      });
  }
}
