import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Bp::Button::Base\n  class={{this.classes}}\n  @onClick={{this.onClick}}\n  @href={{@href}}\n  ...attributes\n>\n  <AppSvg @src={{@icon}} @class={{this.iconClasses}} />\n</Bp::Button::Base>", {"contents":"<Bp::Button::Base\n  class={{this.classes}}\n  @onClick={{this.onClick}}\n  @href={{@href}}\n  ...attributes\n>\n  <AppSvg @src={{@icon}} @class={{this.iconClasses}} />\n</Bp::Button::Base>","moduleName":"appkit/components/bp/button/icon.hbs","parseOptions":{"srcName":"appkit/components/bp/button/icon.hbs"}});
import BaseButton from './base';
import { Themeable, VARIANTS } from '../theme';
import iconTheme from './icon.theme';
import classNames from 'classnames';

export default class BpButtonIconComponent extends Themeable(BaseButton, {
  theme: iconTheme,
  variant: VARIANTS.TEXT,
}) {
  get themeOptions() {
    return {
      ...super.themeOptions,
      rounded: this.args.rounded,
    };
  }
  get iconClasses() {
    return classNames(this.getClassesForTheme(this.theme.icon), this.args.iconClass);
  }
}
