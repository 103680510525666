import Util from 'appkit/lib/util';
/* eslint-disable no-unused-vars */

export class RelayFakeSettingsRepository {
  state = Util.camelizer({
    should_auto_enable_reservations: false,
    should_create_holds_from_unconfirmed_reservations: false,
    should_create_reservations_with_guest_email: false,
    should_create_reservations_with_cleaning_fee: false,
    should_skip_all_reservations_updates: false,
    should_only_update_reservations_status: false,
    should_allow_update_guest_data: false,
  });

  async get(managedAccountId) {
    console.log('getting API settings', this.state);
    return Promise.resolve({ ...this.state });
  }

  async patch(managedAccountId, settings) {
    this.state = {
      ...this.state,
      ...settings,
    };
    return Promise.resolve(this.state);
  }
}
