import { helper as buildHelper } from '@ember/component/helper';

export default buildHelper(function (params) {
  let score = params[0];
  if (score < 30) {
    return 'neg-large';
  }
  if (score < 50) {
    return 'neg-small';
  }
  if (score < 70) {
    return 'pos-small';
  }
  return 'pos-large';
});
