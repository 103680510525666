import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class RegionsRoute extends AuthRoute {
  controllerName = 'data.regions';
  templateName = 'data.regions';

  model() {
    let url = `/admin/api/markets/0/regions`;
    return this.ajax._get(url);
  }

  @action
  savePolygon() {
    let points = this.controller.get('pathPoints');
    let polygon = this.controller.get('polygon');
    if (points) {
      let pointsString = points.map(point => `${point[0]} ${point[1]}`).toString();
      this.ajax
        ._post(`/admin/api/markets/${polygon.id}/regions`, {
          data: pointsString,
        })
        .then(
          () => {
            alert(`Market: "${polygon.name}" ID: ${polygon.id} Update Saved!`);
          },
          () => {
            alert('Region Update Failed.');
          }
        );
    }
  }

  @action
  loadHeatmap() {
    // Only supported for market regions
  }
}
