import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='flex flex-col items-center text-xs text-gray-600 text-center'>\n  {{#if this.hasSuggestion}}\n    <p>\n      {{t 'relay.similarListingsFound'}}\n    </p>\n    <Bp::Button @variant='text' @size='xs' {{on 'click' this.onShowSuggestion}}>\n      {{t 'relay.viewMerge'}}\n    </Bp::Button>\n  {{else}}\n    <p class='italic'>\n      {{t 'relay.noSimilarListingsFound'}}\n    </p>\n  {{/if}}\n</div>", {"contents":"<div class='flex flex-col items-center text-xs text-gray-600 text-center'>\n  {{#if this.hasSuggestion}}\n    <p>\n      {{t 'relay.similarListingsFound'}}\n    </p>\n    <Bp::Button @variant='text' @size='xs' {{on 'click' this.onShowSuggestion}}>\n      {{t 'relay.viewMerge'}}\n    </Bp::Button>\n  {{else}}\n    <p class='italic'>\n      {{t 'relay.noSimilarListingsFound'}}\n    </p>\n  {{/if}}\n</div>","moduleName":"appkit/components/relay/table/body/suggestion-cell.hbs","parseOptions":{"srcName":"appkit/components/relay/table/body/suggestion-cell.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class RelayTableMarkupCellComponent extends Component {
  get hasSuggestion() {
    return this.args.listing.id in this.args.suggestedListingsById;
  }

  @action
  onShowSuggestion() {
    this.args.onShowSuggestion(this.args.suggestedListingsById[this.args.listing.id]);
  }
}
