import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import EmberObject, { computed } from '@ember/object';
import { FAKE_QUOTE_ENGINE_RESULT } from '../lib/fake_quote_engine_result';

@classic
export default class SearchRoute extends AuthRoute {
  templateName = 'search';
  controllerName = 'search';

  @computed
  get lastSearchParamsCache() {
    return JSON.parse(localStorage.getItem('lastSearchParamsCache'));
  }

  set lastSearchParamsCache(value) {
    if (value) {
      localStorage.setItem('lastSearchParamsCache', JSON.stringify(value));
    } else {
      localStorage.removeItem('lastSearchParamsCache');
    }
    return JSON.parse(localStorage.getItem('lastSearchParamsCache'));
  }

  activate() {
    super.activate();
    // only isStaff users can access this product for now
    if (!this.currentUser.get('isStaff')) {
      this.transitionTo('dashboard');
    }
    // FIXME: Hack to hide header and footer for v1
    setTimeout(function () {
      this.document.querySelector('article').style.minHeight = '100vh';
    }, 500);
  }

  async model(_params) {
    // prepopulate result if last search cache exists
    let model = EmberObject.create({});

    // set all clusters as dropdown option
    const clustersData = await this.ajax._get(`/api/quote/clusters`);
    model.set('clustersOptions', clustersData);

    if (!this.lastSearchParamsCache) {
      return model;
    }

    // prepopulate result if last search cache exists

    // FIXME: only for dev purpose
    let data = FAKE_QUOTE_ENGINE_RESULT;

    // FIXME: only for dev purpose
    // model.set('lastSearchParamsCache', this.get('lastSearchParamsCache'));

    // let data = await this.ajax._get(`/api/quote/search?startDate=2019-03-01&endDate=2019-03-07&lat=37.778718&lng=-122.412460`);
    // // FIXME: need to be removed
    // data.listings = [...Array(100).fill(data.listings[0])];

    model.set(
      'searchResult',
      data.listings.map(l => {
        return this.bpStore.createRecord('listing', l);
      })
    );

    return model;
  }
}
