// Helpers to fake templates with ES6 strings
//  https://www.keithcirkel.co.uk/es6-template-literals/
//  https://www.strauss.io/blog/2015-speed-up-ember-js-list-rendering-by-example.html
//
// Ideally we'd use ember-vertical-collection in grid format, but there is no horizontal
// scroll support. There are some forks, but none that seem to work consistently
// https://github.com/html-next/vertical-collection/issues/268
import { htmlSafe } from '@ember/template';
import { helper } from '@ember/component/helper';
import { CurrencyUtil } from 'appkit/lib/currency';
import moment from 'moment';

const availabilityMap = function (index) {
  if (!index) {
    return null;
  }
  return [null, 'available', 'unavailable', 'booked', 'blocked'][index];
};

const fields = [
  'availability',
  'minStayUser',
  'maxStayUser',
  'minPriceUser',
  'maxPriceUser',
  'priceUser',
  'pricePosted',
  'reservationIds',
  'reservation',
  'percentageUserOverride',
];

const _fieldValue = function (currentDay, field, currency) {
  // Determine the value to be displayed in the grid for a listing/date when the user
  // selects a field.

  let fieldValue = '';
  let isCurrency = false;
  if (field === 'seasonalMinPrices') {
    fieldValue = currentDay.minPriceUser;
    isCurrency = true;
  } else if (field === 'seasonalMaxPrices') {
    fieldValue = currentDay.maxPriceUser;
    isCurrency = true;
  } else if (field === 'minStays') {
    fieldValue = currentDay.minStayUser;
  } else if (field === 'maxStays') {
    fieldValue = currentDay.maxStayUser;
  } else if (field === 'overrides') {
    fieldValue = currentDay.priceUser;
    isCurrency = true;
  } else if (field === 'postedPrices') {
    fieldValue = currentDay.pricePosted;
    isCurrency = true;
  }
  if (isCurrency && fieldValue) {
    const currencySymbol = CurrencyUtil.getSymbol(currency);

    const formattedValue = CurrencyUtil.format(fieldValue, {
      currency: currency,
    });

    fieldValue =
      currencySymbol.symbol.length > 1 || fieldValue.length > 3
        ? formattedValue.replace(currencySymbol.symbol, '')
        : formattedValue;
  }
  return fieldValue || '';
};

export function unboundGrid(values) {
  const listing = values[0];
  const field = values[1];
  const calendar = values[2];
  const today = values[3];
  const startDate = values[4];
  const endDate = values[5];
  const textIncrease = values[6];
  const textDecrease = values[7];
  const parentId = values[8];

  const startingOffset = startDate.diff(today, 'days');
  const endingOffset = endDate?.diff(today, 'days');
  const numDays = endDate?.diff(startDate, 'days');
  if (!calendar) {
    return htmlSafe(`
      <div
        class="cell body loading"
        style="grid-column: auto / span ${numDays}"
      >
        <div class="diagonal"></div>
      </div>`);
  }

  // The current state of the calendar as we traverse from first to last day.
  const currentDay = {};

  const response = [];

  // We jump forward days on a reservation and always want a boundary day after. We
  // could go back and parse the last offset, but double bookings make that hard. Track
  // whether we jumped instead to keep life easy.
  let wasReservation = false;
  let originalNextOffset = 0;

  for (let offset = startingOffset; offset <= endingOffset; offset++) {
    let date = values[3].clone().add(offset, 'days').format('YYYY-MM-DD');

    // If the value changes on this day, update the current representation.
    for (let field of fields) {
      if (calendar[field].has(offset.toString())) {
        currentDay[field] = calendar[field].get(offset.toString());
      } else if (originalNextOffset !== 0) {
        for (let i = originalNextOffset; i < offset; i++) {
          if (calendar[field].has(i.toString())) {
            currentDay[field] = calendar[field].get(i.toString());
          }
        }
      }
    }

    originalNextOffset = 0;
    // We might have a reservation ID but no reservation object due to a double booking.
    if (calendar.reservation.has(offset.toString()) && currentDay.reservation) {
      // Reference is set by user / PMS so it's untrusted - make sure to escape it.
      let reference = '';
      if (currentDay.reservation.reference) {
        const safeReference = currentDay.reservation.reference.replace(/"/g, '&quot;');
        reference += `${safeReference}:&nbsp;`;
      }

      const currencySymbol = CurrencyUtil.getSymbol(listing.currency);

      const formattedValue = CurrencyUtil.format(currentDay.reservation.rentalAmount, {
        currency: listing.currency,
      });

      const amount =
        currencySymbol.symbol.length > 1 ||
        currentDay.reservation.rentalAmount.length > 3
          ? formattedValue.replace(currencySymbol.symbol, '')
          : formattedValue;

      const classes = [
        'cell',
        'body',
        'reservation',
        moment(date).format('dddd').toLowerCase(),
      ];
      if (currentDay.reservation.isOwner) {
        classes.push('owner');
      }

      // Reservations are always boundaries, unless they are at the start / end of a
      // calendar
      const boundaryLeft = offset != startingOffset;
      const boundaryRight = offset + currentDay.reservation.nbNights < endingOffset;

      if (boundaryLeft) {
        classes.push('boundary-left', 'pl-6');
      }
      if (boundaryRight) {
        classes.push('boundary-right');
      }

      // Compensate for difference betwen "last night" and "checkout date" meanings.
      const endDateAdjusted = endDate.clone().add(1, 'day');
      const checkinDate = moment(currentDay.reservation.checkinDate);
      const checkoutDate = moment(currentDay.reservation.checkoutDate);

      // Limit the reservation to only take up what's left in the calendar
      let nbNights = currentDay.reservation.nbNights;
      if (checkinDate.isBefore(startDate) || checkoutDate.isAfter(endDateAdjusted)) {
        const firstDate = [startDate, checkinDate].sort(
          (a, b) => a.valueOf() - b.valueOf()
        )[1];
        const lastDate = [endDateAdjusted, checkoutDate].sort(
          (a, b) => a.valueOf() - b.valueOf()
        )[0];

        nbNights = lastDate.diff(firstDate, 'days');
      }

      response.push(`
        <div
          class="${classes.join(' ')} ${parentId ? 'border-b border-gray-200' : ''}"
          data-reservation-id="${currentDay.reservation.id}"
          style="grid-column: auto / span ${nbNights}"
        >
          <div class="diagonal">
            ${reference}
            <div class="amount">
              ${amount}
            </div>
          </div>
        </div>
      `);

      originalNextOffset = offset;

      // Jump to the last day of the reservation, let the `for` loop move us to the
      // checkout date.
      offset += nbNights - 1;
      wasReservation = true;
      continue;
    }

    if (field === 'overrides') {
      const classes = [
        'cell',
        'body',
        'overriden',
        'boundary-left',
        'boundary-right',
        moment(date).format('dddd').toLowerCase(),
      ];

      let nextOffset;
      if (currentDay.priceUser && calendar.priceUser.size > 0) {
        calendar.priceUser.forEach((val, key) => {
          if (parseInt(key) > offset) {
            nextOffset = parseInt(key);
            return false;
          }
          return true;
        });

        let cells = nextOffset - offset;

        for (let i = offset + 1; i <= nextOffset; i++) {
          if (calendar.reservation.has(i.toString())) {
            cells = i - offset;
            break;
          }
        }

        offset += cells - 1;

        const currencySymbol = CurrencyUtil.getSymbol(listing.currency);

        const formattedValue = CurrencyUtil.format(currentDay.priceUser, {
          currency: listing.currency,
        });

        const amount =
          currencySymbol.symbol.length > 1 || currentDay.priceUser.length > 3
            ? formattedValue.replace(currencySymbol.symbol, '')
            : formattedValue;

        response.push(`
          <div
            class="${classes.join(' ')} ${parentId ? 'border-b border-gray-200' : ''}"
            style="grid-column: auto / span ${cells}"
          >
            <div class="diagonal justify-start">
            <div class="w-3 h-3 ml-2 mr-1 flex justify-center items-center">
              <svg fill="#FA8829" width="12" height="11" viewBox="0 0 72 88"><path d="M65 31h-9v-7C56 9.514 49.326 1 36 1 22.673 1 16 9.514 16 24v7H6c-2.762 0-5 3.239-5 6v39c0 2.75 2.142 5.691 4.759 6.535 0 0 12.991 4.465 15.741 4.465h29c2.75 0 15.741-4.465 15.741-4.465C68.857 81.691 71 78.75 71 76V37c0-2.761-3.238-6-6-6zM42.188 70.818h-12l2.031-11.308c-1.751-1.165-2.906-3.626-2.906-5.886a6.5 6.5 0 1113 0c0 1.963-.875 4.191-2.252 5.383l2.127 11.811zM46 31H26v-9c0-7.225 3.986-11 10-11 6.014 0 10 3.775 10 11v9z"/></svg>
            </div>
              <span class="font-bold">
                ${amount}
              </span>
            </div>
          </div>
        `);

        continue;
      }

      if (
        currentDay.percentageUserOverride &&
        calendar.percentageUserOverride.size > 0
      ) {
        let offsetIsMet = false;
        calendar.percentageUserOverride.forEach((val, key) => {
          if (parseInt(key) > offset && !offsetIsMet) {
            nextOffset = parseInt(key);
            offsetIsMet = true;
            return true;
          }
          return true;
        });

        let cells = nextOffset - offset;

        for (let i = offset + 1; i <= nextOffset; i++) {
          if (calendar.reservation.has(i.toString())) {
            cells = i - offset;
            break;
          }
        }

        offset += cells - 1;

        response.push(`
          <div
            class="${classes.join(' ')} ${parentId ? 'border-b border-gray-200' : ''}"
            style="grid-column: auto / span ${cells}"
          >
            <div class="diagonal justify-start items-center lowercase">
              <div class="w-3 h-3 ml-2 mr-1 flex justify-center items-center">
                <svg width="12" height="11" viewBox="0 0 12 11" fill="#FA8829"><path d="M.333 6.051a.333.333 0 01.667 0v4.334H.333V6.05zM3 4.051a.333.333 0 11.667 0v6.334H3V4.05zM5.667 1.385a.333.333 0 11.667 0v9h-.667v-9zM8.333 6.72a.333.333 0 01.667 0v3.666h-.667V6.72zM11 4.72a.333.333 0 01.666 0v5.666H11V4.72z" stroke-width=".667"/></svg>
              </div>
              <span class="font-bold">
                ${Math.abs(Math.round(currentDay.percentageUserOverride * 100))}% &nbsp;
              </span>
               ${currentDay.percentageUserOverride > 0 ? textIncrease : textDecrease}
            </div>
          </div>
        `);

        continue;
      }
    }

    const classes = [
      'cell',
      'body',
      moment(date).format('dddd').toLowerCase(),
      availabilityMap(currentDay.availability),
    ];

    // Double bookings can mess with availability. Ideally we remove this once we have
    // better multi-reservation display, and once the reservation and availability
    // syncers are unified.
    if (currentDay.reservationIds?.length > 1) {
      classes.push('double-booked');
    }

    // Boundary days are the change in state between availability and a reservation and
    // should have a diagonal border. I.e. there are three cases where we have a
    // boundary:
    // 1. A day is a reservation (unless it's at the start / end of the window).
    // 2. Any change in availability
    // 3. The prior day was a reservation
    let boundaryLeft = wasReservation;
    if (offset === startingOffset) {
      boundaryLeft = false;
    } else if (calendar.availability.has(offset.toString())) {
      boundaryLeft = true;
    }

    let boundaryRight = false;
    if (offset + 1 === endingOffset) {
      boundaryRight = false;
    } else if (calendar.availability.has((offset + 1).toString())) {
      if (availabilityMap(currentDay.availability) !== 'available') {
        boundaryRight = true;
      }
    } else if (calendar.reservation.has((offset + 1).toString())) {
      boundaryRight = true;
    }

    if (boundaryLeft) {
      classes.push('boundary-left', 'pl-6');
    }
    if (boundaryRight) {
      classes.push('boundary-right');
    }

    let fieldValue = _fieldValue(currentDay, field, listing?.currency);

    response.push(`
      <div
        id="cell-${parentId ? parentId : listing?.id}-${date}-${listing?.id}"
        class="${classes.join(' ')} ${parentId ? 'border-b border-gray-200' : ''}"
      >
        <div class="diagonal">
          ${fieldValue}
        </div>
      </div>`);
    wasReservation = false;
  }
  return htmlSafe(response.join(''));
}

export default helper(unboundGrid);
