import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
/* global braintree, paypal */
import Component from '@ember/component';
import ENV from 'appkit/config/environment';
import Util from 'appkit/lib/util';
import logger from 'appkit/lib/logger';
import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import PAYMENT_METHOD from 'appkit/lib/payment-method';

// Make sure we only load the braintree scripts once per page load.
let braintreePromise = null;

let loadBraintree = () => {
  if (braintreePromise) {
    return braintreePromise;
  }

  // Libs required for Braintree
  let scripts = [
    'https://www.paypalobjects.com/api/checkout.js',
    'https://js.braintreegateway.com/web/3.21.1/js/client.min.js',
    'https://js.braintreegateway.com/web/3.21.1/js/paypal-checkout.min.js',
  ];

  let p = Util.getMultiScripts(scripts).promise();

  braintreePromise = p;
  return braintreePromise;
};

@classic
@classNames('app-braintree-paypal')
export default class AppBraintreePaypal extends Component {
  get isPrimary() {
    return this.user.defaultPaymentMethod === PAYMENT_METHOD.PAYPAL;
  }

  get paymentMethod() {
    return PAYMENT_METHOD.PAYPAL;
  }

  @service ajax;
  @service alert;
  @service intl;
  @service forcePaymentMethod;

  user = null;

  get shouldLoadBraintree() {
    return this.isToAddPaypalAsPaymentMethod && !this.user.paypalAccountEmail;
  }

  // loading status = true when the component is created because it will take
  // time for the iframe to be rendered, even if the libs are already loaded
  loading = true;

  init() {
    super.init(...arguments);
    if (this.shouldLoadBraintree) {
      this.initBraintree();
    } else {
      this.set('loading', false);
    }
  }

  @action
  setPaymentMethod() {
    this.onChangePaymentMethod(PAYMENT_METHOD.PAYPAL);
  }

  @action
  async deleteAccount() {
    this.loading = true;

    try {
      await this.ajax._delete('/api/braintree/paypal');
      this.user.set('paypalAccountEmail', null);
      this.alert.success(this.intl.t('admin.paypalAccountDeletedSuccessfully'), {
        timeout: 6000,
      });
    } catch (e) {
      this.alert.error(this.intl.t('validation.genericWithContact'), {
        timeout: 10000,
      });
    } finally {
      this.loading = false;
    }
  }

  @action
  initBraintree() {
    loadBraintree().then(() => {
      // Get client token to initialize the client
      let pBraintreeClientToken = this.ajax._get('/api/braintree/client_token');

      pBraintreeClientToken.then(data => {
        let client_token = data.clientToken;

        // Braintree client
        braintree.client.create(
          {
            authorization: client_token,
          },
          (clientErr, clientInstance) => {
            // Stop if there was a problem creating the client.
            // This could happen if there is a network error or if the authorization
            // is invalid.
            if (clientErr) {
              logger.error('Error creating client:', clientErr);
              return;
            }

            // Create a PayPal Checkout component.
            braintree.paypalCheckout.create(
              {
                client: clientInstance,
              },
              (paypalErr, paypalCheckoutInstance) => {
                if (paypalErr) {
                  logger.error('Error creating PayPal:', paypalErr);
                  return;
                }

                // Define the paypal env
                let paypalEnv = ENV.APP.BRAINTREE_ENVIRONMENT;

                // Set up PayPal with the checkout.js library
                paypal.Button.render(
                  {
                    env: paypalEnv, // 'production' or 'sandbox'

                    style: {
                      size: 'responsive',
                      color: 'white',
                      shape: 'rect',
                      label: 'pay',
                      height: 42,
                      tagline: 'false',
                      border: 'none',
                    },

                    payment: () => {
                      return paypalCheckoutInstance.createPayment({
                        // http://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
                        flow: 'vault',
                      });
                    },

                    onAuthorize: data => {
                      const alert = this.alert;

                      this.checkForUnsavedProgress();
                      if (!this.okToProceed) {
                        alert.error(this.intl.t('validation.genericWithContact'), {
                          timeout: 10000,
                        });
                        return;
                      }

                      return paypalCheckoutInstance
                        .tokenizePayment(data)
                        .then(payload => {
                          // Submit `payload.nonce` to the server.
                          this.ajax
                            ._post('/api/braintree/add_payment_method', {
                              nonce: payload.nonce,
                            })
                            .then(
                              data => {
                                let user = this.user;
                                user.set('paypalAccountEmail', data.paypalAccountEmail);
                                alert.success(
                                  'PayPal account added successfully. Thank you.',
                                  { timeout: 6000 }
                                );

                                getOwner(this).lookup('route:dashboard.user').refresh();

                                this.forcePaymentMethod.resume();
                              },
                              errors => {
                                let err = errors[0];
                                alert.error(err.message, { timeout: 10000 });
                              }
                            );
                        });
                    },

                    onCancel: function (data) {
                      logger.error(
                        'checkout.js payment cancelled',
                        JSON.stringify(data, 0, 2)
                      );
                    },

                    onError: function (err) {
                      logger.error('checkout.js error', err);
                    },
                  },
                  '#paypal-button'
                ).then(() => {
                  // The PayPal button will be rendered in an html element with the id
                  // `paypal-button`. This function will be called when the PayPal button
                  // is set up and ready to be used.
                  this.set('loading', false);
                });
              }
            );
          }
        );
      });
    });
  }
}
