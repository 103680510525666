import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';

@classic
export default class ChartRoute extends AuthRoute {
  templateName = 'listing.chart';
  controllerName = 'listing.chart';
  @service embed;

  model() {
    // We want to be able to switch back and forward from listing to calendar
    // quickly, so we want to share a model and not require a reload, but we
    // need stats on the chart to get prior year max-adr. Lazy load them and
    // update the chart once they're available.
    const dashboard = this.modelFor('dashboard.pricing.listing');
    this.embed.czCaptureEvent(
      'Chart Tab View',
      'User viewed the Chart Tab for a listing'
    );

    const listingId = dashboard.get('listing.id');
    let url = `/api/listings/${listingId}/stats`;
    if (!dashboard.get('stats')) {
      this.ajax._get(url).then(responseData => {
        dashboard.set('stats', EmberObject.create(responseData.stats));
      });
    }
    return dashboard;
  }
}
