export default {
  root: {
    defaultClasses: ({ disabled }) => [
      'outline-none',
      disabled && ['cursor-not-allowed'],
    ],
  },

  dropdown: {
    defaultClasses: ({ size }) => [
      'bp-select-dropdown',
      'shadow-md',
      'border',
      'rounded',
      size,
    ],
  },
};
