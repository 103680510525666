import { set } from '@ember/object';
import { action, computed } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import ListingDate from 'appkit/bp-models/listing_date';
import { inject as service } from '@ember/service';

class FormState {
  @tracked priceOverrideSelected;
  @tracked priceOverrideValue;
  @tracked userMinStays;
  @tracked availability;
  @tracked dynamicManualOverrideChecked = true;
  _defaultOverrideSelected;

  constructor({ priceOverrideSelected } = {}) {
    this._defaultOverrideSelected = priceOverrideSelected;
    this.priceOverrideSelected = priceOverrideSelected;
  }

  get isClear() {
    return (
      this.priceOverrideSelected === this._defaultOverrideSelected &&
      !this.priceOverrideValue &&
      !this.userMinStays &&
      !this.availability
    );
  }
}

export default class AppCalendarDay extends Component {
  @service intl;
  @service featureFlag;
  @service listingSetup;

  minDate = this.args.listing.calendar[0].date;
  maxDate = this.args.listing.calendar[this.args.listing.calendar.length - 1].date;

  @computed('args.listing.isNewListing')
  get isNewListing() {
    return this.args.listing.isNewListing;
  }

  get canEdit() {
    return !this.isNewListing && this.args.listing.canEdit;
  }

  // Store a copy of the original selected days properties
  // We need to do this because we only want to display the warnings after the changes are saved
  @computed('args.selectedDays.@each.saving')
  get originalDays() {
    return this.args.selectedDays.map(d => ListingDate.create(d._dirty));
  }

  @computed('originalDays.[]')
  get originalDaysAvailable() {
    const editableDays = this.originalDays.filter(d => d.isAvailabilityEditable);
    return editableDays.every(d => d.isAvailable);
  }

  @computed('originalDays.[]')
  get originalDaysUnavailable() {
    const editableDays = this.originalDays.filter(d => d.isAvailabilityEditable);
    return editableDays.every(d => !d.isAvailable);
  }

  get daysCount() {
    return this.args.selectedDays.length;
  }

  @computed(
    'args.selectedDays.@each.{priceReservationUserOrModeled,priceUserOrModeled}'
  )
  get daysTotal() {
    const isMultiUnit = this.args.selectedDays[0].listing.nbSubUnits > 0;

    return this.args.selectedDays
      .map(day =>
        isMultiUnit
          ? day.multiUnitPriceReservationsModeledCombined
          : day.priceReservationUserOrModeled
      )
      .reduce((a, b) => a + b, 0);
  }

  @computed(
    'args.selectedDays.@each.{priceReservationUserOrModeled,priceUserOrModeled}'
  )
  get multiUnitAdrTotal() {
    return this.args.selectedDays
      .map(day => day.priceAvgReservationsOrModeled)
      .reduce((a, b) => a + b, 0);
  }

  @computed(
    'args.selectedDays.@each.{priceReservationUserOrModeled,priceUserOrModeled}'
  )
  get daysPredictedADR() {
    if (
      this.args.selectedDays.filter(day => day.isBooked || day.reservations.length > 0)
        .length === 0
    ) {
      return false;
    }
    const total = this.args.selectedDays
      .map(day => day.priceUserOrModeled)
      .reduce((a, b) => a + b, 0);
    const totalWithoutOverrides = this.args.selectedDays
      .map(day => day.priceModeled)
      .reduce((a, b) => a + b, 0);

    const hasOverrides = this.args.selectedDays.find(it => it.priceUser !== null);

    return {
      hasOverrides: !!hasOverrides,
      adr: Math.floor(total / this.daysCount),
      adrWithoutOverrides: Math.floor(totalWithoutOverrides / this.daysCount),
    };
  }

  get daysADR() {
    const isMultiUnit = this.args.selectedDays[0].listing.nbSubUnits > 0;
    const total = isMultiUnit ? this.multiUnitAdrTotal : this.daysTotal;

    return Math.floor(total / this.daysCount);
  }

  get isRangeEditable() {
    if (
      this.args.listing?.channelListings?.length > 0 &&
      this.args.listing?.channelListings[0].subUnits?.length > 0
    ) {
      return this.args.selectedDays.any(
        d =>
          (d.reservationIds?.length ?? 0) <
          this.args.listing?.channelListings[0].subUnits.length
      );
    }
    return this.args.selectedDays.any(d => !d.isBooked);
  }

  // Clear the internal state when the selected days change
  @computed('args.{squareFirst,squareLast}')
  get state() {
    return new FormState({
      priceOverrideSelected: this.priceOverrideOptions[0],
    });
  }

  @computed('state.priceOverrideSelected')
  get showDynamicOverridesRadio() {
    return (
      this.state.priceOverrideSelected.action === 'increase' ||
      this.state.priceOverrideSelected.action === 'decrease'
    );
  }

  // Define a setter so we can cache bust the computed property
  // This is used to implement the "Clear fields" functionality
  set state(value) {}

  get priceOverrideOptions() {
    return [
      {
        action: 'increase',
        term: this.intl.t('pricing.listing.sidebar.increasePriceBy'),
      },
      {
        action: 'decrease',
        term: this.intl.t('pricing.listing.sidebar.decreasePriceBy'),
      },
      { action: 'amount', term: this.intl.t('pricing.listing.sidebar.setPriceTo') },
      {
        action: 'clear',
        term: this.intl.t('pricing.listing.sidebar.removeOverrides'),
      },
    ];
  }

  getWarningFor = (type, all) => {
    const warningsByType = {
      BLOCKED: {
        type: 'BLOCKED',
        text: this.intl.t('pricing.listing.sidebar.blockedDatesWarning', {
          all: all
            ? this.intl.t('pricing.listing.sidebar.dates')
            : this.intl.t('pricing.listing.sidebar.someDates'),
        }),
      },
      OVERRIDE: {
        type: 'OVERRIDE',
        text: this.intl.t('pricing.listing.sidebar.manuallySetWarning', {
          all: all
            ? this.intl.t('pricing.listing.sidebar.rates')
            : this.intl.t('pricing.listing.sidebar.someRates'),
        }),
      },
    };

    return warningsByType[type];
  };

  get weekdayFilters() {
    function rotateArray(array, n) {
      n = n % array.length;
      return array.slice(n, array.length).concat(array.slice(0, n));
    }

    const firstDow = moment.localeData(this.intl.locale[0])._week.dow;
    const weekdays = rotateArray(moment.localeData('en').weekdays(0), firstDow);

    return weekdays.map(day => {
      const disabled = !this.isWeekdayInRange(
        day,
        this.firstDay?.date,
        this.lastDay?.date
      );

      return {
        day,
        label: this.intl.t('common.weekdays.' + day.toLowerCase()),
        selected:
          !disabled &&
          !!this.args.selectedWeekdayFilters.find(selected => selected === day),
        disabled,
      };
    });
  }

  get firstDay() {
    return this.args.squareFirst?.day;
  }

  get lastDay() {
    return this.args.squareLast?.day;
  }

  get isMultiDay() {
    if (!this.args.squareFirst) {
      return false;
    }
    if (!this.args.squareLast) {
      return false;
    }
    return this.args.squareFirst !== this.args.squareLast;
  }

  get uniqueMinStay() {
    return this.args.selectedDays.map(d => d.minStayUser).uniq().length === 1;
  }

  get minStay() {
    return this.args.selectedDays[0].minStay || 1;
  }

  get dayWarnings() {
    const warnings = [];

    // Blocked days
    if (this.originalDays.any(d => d.isBlocked)) {
      const all = this.originalDays.every(d => d.isBlocked);
      warnings.push(this.getWarningFor('BLOCKED', all));
    }

    // Overriden days
    if (this.originalDays.any(d => d.isOverridden)) {
      const all = this.originalDays.every(d => d.isOverridden);

      warnings.push(this.getWarningFor('OVERRIDE', all));
    }
    return warnings;
  }

  get userMinStaysOverride() {
    return this.originalDays.some(d => d.minStayUser);
  }

  isWeekdayInRange(weekday, start, end) {
    if (!start || !end) {
      return false;
    }
    let startDate = moment(start);
    const endDate = moment(end);
    while (startDate.isSameOrBefore(endDate)) {
      if (startDate.locale('en').format('dddd') === weekday) {
        return true;
      }
      startDate = startDate.add(1, 'day');
    }
    return false;
  }

  @computed('args.selectedDays.@each.isAvailabilityEditable')
  get selectedDaysAvailabilityEditable() {
    return (
      this.args.canSetAvailability &&
      this.args.selectedDays.any(day => day.isAvailabilityEditable)
    );
  }

  get isSelectedDaysAvailable() {
    return this.state.availability === 'available_editable';
  }

  get isSelectedDaysUnavailable() {
    return this.state.availability === 'blocked_editable';
  }

  get isDirty() {
    return this.args.selectedDays.some(d => d.isDirty);
  }

  get hasChanges() {
    return this.isDirty && !this.state.isClear;
  }

  clearChanges() {
    set(
      this,
      'state',
      new FormState({
        priceOverrideSelected: this.priceOverrideOptions[0],
      })
    );
    this.notifyChanges();
  }

  notifyChanges() {
    this.args.onSettingsChanged(this.args.selectedDays, this.state);
  }

  @action
  async onSaveChanges() {
    const success = await this.args.onSaveChanges(this.args.selectedDays);
    if (success) {
      // De-select current days
      this.args.onDateRangeSelected(null);
    }
  }

  @action
  onClearChanges() {
    this.clearChanges();
  }

  @action
  onPriceOverrideChanged(evt) {
    this.state.priceOverrideValue = evt.target.value;
    this.state.isDynamicOverrideEnabled = this.dynamicManualOverrideChecked;
    this.notifyChanges();
  }

  @action
  onPriceOverrideSelected(value) {
    set(this, 'state.priceOverrideSelected', value);

    this.notifyChanges();
  }

  @action
  onMinStayChanged(evt) {
    this.state.userMinStays = evt.target.value;
    this.notifyChanges();
  }

  @action
  onAvailabilityChanged(value, evt) {
    this.state.availability = evt.target.checked ? value : null;
    this.notifyChanges();
  }

  @action
  onWeekdayFiltersChanged(day, evt) {
    this.args.onWeekdayFiltersChanged(day, evt.target.checked, this.state);
  }

  @action
  dynamicOverridesChange(value) {
    this.dynamicManualOverrideChecked = value;
    this.state.isDynamicOverrideEnabled = this.dynamicManualOverrideChecked;

    if (this.state.priceOverrideValue === '0') {
      for (let day of this.args.selectedDays) {
        day.set('percentageUserOverride', 0);

        day.set('priceUser', day._dirty.priceUser);
      }
    }

    if (
      this.state.priceOverrideValue !== undefined &&
      this.dynamicManualOverrideChecked &&
      this.state.priceOverrideValue !== '0'
    ) {
      for (let day of this.args.selectedDays) {
        if (this.state.priceOverrideSelected.action === 'increase') {
          day.set('priceUser', null);
          day.set(
            'percentageUserOverride',
            Number(this.state.priceOverrideValue / 100)
          );
        }

        if (this.state.priceOverrideSelected.action === 'decrease') {
          day.set('priceUser', null);
          day.set(
            'percentageUserOverride',
            Number((this.state.priceOverrideValue / 100) * -1)
          );
        }
      }
    } else if (
      this.state.priceOverrideValue !== undefined &&
      !this.dynamicManualOverrideChecked &&
      this.state.priceOverrideValue !== '0'
    ) {
      for (let day of this.args.selectedDays) {
        day.set('percentageUserOverride', undefined);

        if (
          this.state.priceOverrideValue !== 0 ||
          this.state.priceOverrideValue !== undefined
        ) {
          const originalPriceUser = day._dirty.priceUser || day.priceModeled;
          if (this.state.priceOverrideSelected.action === 'increase') {
            const price = Math.floor(
              originalPriceUser +
                originalPriceUser * (this.state.priceOverrideValue / 100)
            );
            day.set('priceUser', price);
          }
          if (this.state.priceOverrideSelected.action === 'decrease') {
            const price = Math.floor(
              originalPriceUser -
                originalPriceUser * (this.state.priceOverrideValue / 100)
            );
            day.set('priceUser', price);
          }
        }
      }
    }
  }
}
