import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { get, set, computed, action, getProperties } from '@ember/object';
import { inject as controller } from '@ember/controller';

export default class DashboardOwnerResourceProjectionController extends Controller {
  // -- Parameters ---------------------------------------------------------------------
  @service('owner-resource') ownerResource;
  @service router;
  @service('alert') alert;

  @controller('dashboard.owner-resource.projection.build-projection')
  buildProjectionController;

  displayTemplateModal = false;

  includeSeasons = true;
  includeExpenses = true;
  includePropertyManagementFee = true;
  includeCompanyInfo = true;

  // -- Controller Properties -----------------------------------------------------------

  // -- Computed Properties ------------------------------------------------------------
  get isOnReferenceListing() {
    return (
      this.router.currentRouteName ===
      'dashboard.owner-resource.projection.reference-listing'
    );
  }

  @computed(
    'buildProjectionController.projectionTemplateName',
    'buildProjectionController.selectedProjectionTemplateOption.{templateName,isDirty}'
  )
  get canUpdateTemplate() {
    const projectionTemplateName = get(
      this,
      'buildProjectionController.projectionTemplateName'
    );
    const templateName = get(
      this,
      'buildProjectionController.selectedProjectionTemplateOption.templateName'
    );

    if (templateName && projectionTemplateName !== templateName) {
      return true;
    }

    const templateIsDirty = get(
      this,
      'buildProjectionController.selectedProjectionTemplateOption.isDirty'
    );
    if (templateIsDirty) {
      return true;
    }

    return false;
  }

  // -- Lifecycle Hooks ----------------------------------------------------------------

  // -- Actions ------------------------------------------------------------------------
  @action
  async saveTemplate() {
    set(this, 'displayTemplateModal', false);

    const templateName = get(this, 'buildProjectionController.projectionTemplateName');

    const seasons = this.includeSeasons
      ? get(this, 'buildProjectionController.seasons').map(season =>
          getProperties(
            season,
            'seasonName',
            'startDate',
            'endDate',
            'marketOccupancy',
            'customOccupancy'
          )
        )
      : null;

    const expenses = this.includeExpenses
      ? get(this, 'buildProjectionController.expenses').map(expense =>
          getProperties(expense, 'expenseName', 'value')
        )
      : null;

    const pmFee = this.includePropertyManagementFee
      ? get(this, 'buildProjectionController.propertyManagementFee')
      : null;

    const companyNotes = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.notes')
      : null;

    const website = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.website')
      : null;

    const phone = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.phone')
      : null;

    const email = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.email')
      : null;

    const address = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.address')
      : null;

    const city = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.city')
      : null;

    const state = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.state')
      : null;

    const zipcode = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.zipcode')
      : null;

    const country = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.country')
      : null;

    const currency = this.ownerResource.selectedCurrency || 'USD';

    const data = {
      templateName: templateName,
      seasons: seasons,
      expenses: expenses,
      pmFee: pmFee,
      companyNotes: companyNotes,
      companyWebsite: website,
      companyPhone: phone,
      companyEmail: email,
      companyAddress: address,
      companyCity: city,
      companyState: state,
      companyZipcode: zipcode,
      companyCountry: country,
      currency: currency,
    };

    let res;
    try {
      res = await this.ajax._post('/api/projections_templates', data);
    } catch (errors) {
      for (let e of errors) {
        this.alert.error(e.source?.message);
      }
      return;
    }

    const template = this.bpStore.createRecord(
      'projectionTemplate',
      res.projectionsTemplate
    );
    get(this, 'buildProjectionController.model.projectionTemplates').pushObject(
      template
    );

    this.alert.success('Projection template saved successfully.');
  }

  @action
  async updateTemplate() {
    const template = get(
      this,
      'buildProjectionController.selectedProjectionTemplateOption'
    );
    const id = template.get('id');

    const templateName = get(this, 'buildProjectionController.projectionTemplateName');

    const seasons = this.includeSeasons
      ? get(this, 'buildProjectionController.seasons').map(season =>
          getProperties(
            season,
            'seasonName',
            'startDate',
            'endDate',
            'marketOccupancy',
            'customOccupancy'
          )
        )
      : null;

    const expenses = this.includeExpenses
      ? get(this, 'buildProjectionController.expenses').map(expense =>
          getProperties(expense, 'expenseName', 'value')
        )
      : null;

    const pmFee = this.includePropertyManagementFee
      ? get(this, 'buildProjectionController.propertyManagementFee')
      : null;

    const companyNotes = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.notes')
      : null;

    const website = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.website')
      : null;

    const phone = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.phone')
      : null;

    const email = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.email')
      : null;

    const address = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.address')
      : null;

    const city = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.city')
      : null;

    const state = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.state')
      : null;

    const zipcode = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.zipcode')
      : null;

    const country = this.includeCompanyInfo
      ? get(this, 'buildProjectionController.country')
      : null;

    const currency = this.ownerResource.selectedCurrency || 'USD';

    const data = {
      id: id,
      templateName: templateName,
      seasons: seasons,
      expenses: expenses,
      pmFee: pmFee,
      companyNotes: companyNotes,
      companyWebsite: website,
      companyPhone: phone,
      companyEmail: email,
      companyAddress: address,
      companyCity: city,
      companyState: state,
      companyZipcode: zipcode,
      companyCountry: country,
      currency: currency,
    };

    let res;
    try {
      res = await this.ajax._put('/api/projections_templates', data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain', { timeout: 10000 });
      return;
    }

    this.bpStore.createRecord('projectionTemplate', res.projectionsTemplate);

    const projectionTemplates = get(
      this,
      'buildProjectionController.model.projectionTemplates'
    );

    projectionTemplates
      .findBy('id', res.projectionsTemplate.id)
      .setProperties(res.projectionsTemplate);

    this.alert.success('Projection template saved successfully.');
  }

  @action
  editAddress() {
    // Make sure we go back to reference listing search from projections screen
    this.ownerResource.searchedReferenceListings = true;
    this.router.transitionTo('dashboard.owner-resource.projection.reference-listing');
  }

  // -- Private Functions --------------------------------------------------------------

  // -- Overwrite ----------------------------------------------------------------------
}
