import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import LoginRoute from 'appkit/routes/login';
import { displayErrors } from 'appkit/lib/display_errors';

@classic
export default class SudoRoute extends LoginRoute {
  url = '/api/login';

  // Remove login-route behavior of sending us to the dashboard
  beforeModel() {}

  @action
  onSubmit(model) {
    this.controller.set('posting', true);
    let props = model.getProperties('password', 'referralCode');
    props.email = this.currentUser.get('email');

    this.ajax
      ._post(this.url, props)
      .then(data => {
        this.currentUser.setProperties({
          username: props.email,
          token: data.token,
          isStaff: data.isStaff,
        });

        let redirect = this.controller.get('attemptedTransition');
        if (redirect) {
          redirect.retry();
        } else {
          this.transitionTo('dashboard.populate');
        }
      })
      .catch(errors => {
        this.controller.set('posting', false);
        displayErrors({
          errors: errors,
          modelOrKeywordThis: model,
          isProperty: true,
        });
      });
    return false;
  }
}
