import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='flex flex-wrap'>\n  <Bp::ButtonGroup::Toggleable\n    @toggled={{this.selectedBedrooms}}\n    @wrap={{true}} as |Button|\n  >\n    {{#each this.bedroomList as |bedroom|}}\n      <Button\n        class='w-10'\n        disabled={{@disabled}}\n        @toggleKey={{bedroom}}\n        @onClick={{fn this.onChangedSelectedBedroom bedroom}}\n      >\n        {{bedroom}}\n      </Button>\n    {{/each}}\n  </Bp::ButtonGroup::Toggleable>\n</div>", {"contents":"<div class='flex flex-wrap'>\n  <Bp::ButtonGroup::Toggleable\n    @toggled={{this.selectedBedrooms}}\n    @wrap={{true}} as |Button|\n  >\n    {{#each this.bedroomList as |bedroom|}}\n      <Button\n        class='w-10'\n        disabled={{@disabled}}\n        @toggleKey={{bedroom}}\n        @onClick={{fn this.onChangedSelectedBedroom bedroom}}\n      >\n        {{bedroom}}\n      </Button>\n    {{/each}}\n  </Bp::ButtonGroup::Toggleable>\n</div>","moduleName":"appkit/components/filters/bedrooms/index.hbs","parseOptions":{"srcName":"appkit/components/filters/bedrooms/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class FiltersBedroomsIndexComponent extends Component {
  get bedroomList() {
    return this.args.bedroomList;
  }

  get selectedBedrooms() {
    return this.args.selectedBedrooms;
  }

  @action
  onChangedSelectedBedroom(bedroom) {
    const selectedBedrooms = this.args.selectedBedrooms ?? [];
    this.args.onSelect?.(bedroom);

    if (selectedBedrooms.includes(bedroom)) {
      this.args.onChange?.(selectedBedrooms.filter(b => b !== bedroom));
    } else {
      this.args.onChange?.(selectedBedrooms.concat(bedroom));
    }
  }
}
