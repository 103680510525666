import classic from 'ember-classic-decorator';
import { attributeBindings, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
// Sourced from: https://github.com/yapplabs/ember-radio-button
import { once } from '@ember/runloop';

import Component from '@ember/component';
@classic
@tagName('input')
@attributeBindings('checked', 'disabled', 'name', 'required', 'type', 'value')
export default class RadioButtonInput extends Component {
  type = 'radio';

  @(computed('groupValue', 'value').readOnly())
  get checked() {
    return this.groupValue === this.value;
  }

  sendChangedAction() {
    this.changed(this.value);
    return false;
  }

  change() {
    if (this.groupValue !== this.value) {
      this.set('groupValue', this.value);
      once(this, 'sendChangedAction');
    }
    return false;
  }
}
