import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';

export default class DashboardInsightsIndexRoute extends AuthRoute {
  @service insightsFilters;
  @service router;

  async beforeModel() {
    const model = this.modelFor('dashboard');
    this.insightsFilters.clearFilters();

    if (
      model.user.insightsActivatedAt === null &&
      !this.currentUser.staffUser?.impersonating &&
      model.get('accounts').length === 0
    ) {
      return this.transitionTo('dashboard.insights.join');
    }

    // No need to check listing length for non-admin credentials - we already exclude
    // them above.
    else if (model.get('accounts').length === 0) {
      return this.transitionTo('/accounts/default?product=insights');
    }
  }
}
