import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class ClusterRoute extends AuthRoute {
  model(params) {
    let markets = this.modelFor('data');

    for (let market of markets) {
      for (let cluster of market.get('clusters')) {
        if (cluster.get('id') === Number(params.cluster_id)) {
          return cluster;
        }
      }
    }

    throw new Error('no cluster found');
  }

  serialize(model) {
    return {
      cluster_id: model.get('id'),
    };
  }
}
