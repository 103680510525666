import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class={{this.classes}}>\n  {{#let this.triggerComponent as |Trigger|}}\n    <Trigger\n      @allowClear={{@allowClear}}\n      @buildSelection={{@buildSelection}}\n      @loadingMessage={{@loadingMessage}}\n      @selectedItemComponent={{@selectedItemComponent}}\n      @select={{@select}}\n      @search={{@search}}\n      @searchEnabled={{@searchEnabled}}\n      @searchField={{@searchField}}\n      @onFocus={{@onFocus}}\n      @onBlur={{@onBlur}}\n      @extra={{this.extra}}\n      @listboxId={{@listboxId}}\n      @onInput={{@onInput}}\n      @onKeydown={{@onKeydown}}\n      @placeholder={{@placeholder}}\n      @placeholderComponent={{@placeholderComponent}} as |opt term|\n    >\n      {{yield opt term}}\n    </Trigger>\n    <AppSvg @src='arrow-drop-down' @class={{this.chevronClasses}} />\n  {{/let}}\n</div>", {"contents":"<div class={{this.classes}}>\n  {{#let this.triggerComponent as |Trigger|}}\n    <Trigger\n      @allowClear={{@allowClear}}\n      @buildSelection={{@buildSelection}}\n      @loadingMessage={{@loadingMessage}}\n      @selectedItemComponent={{@selectedItemComponent}}\n      @select={{@select}}\n      @search={{@search}}\n      @searchEnabled={{@searchEnabled}}\n      @searchField={{@searchField}}\n      @onFocus={{@onFocus}}\n      @onBlur={{@onBlur}}\n      @extra={{this.extra}}\n      @listboxId={{@listboxId}}\n      @onInput={{@onInput}}\n      @onKeydown={{@onKeydown}}\n      @placeholder={{@placeholder}}\n      @placeholderComponent={{@placeholderComponent}} as |opt term|\n    >\n      {{yield opt term}}\n    </Trigger>\n    <AppSvg @src='arrow-drop-down' @class={{this.chevronClasses}} />\n  {{/let}}\n</div>","moduleName":"appkit/components/bp/select/parts/trigger/index.hbs","parseOptions":{"srcName":"appkit/components/bp/select/parts/trigger/index.hbs"}});
import Component from '@glimmer/component';
import { Themeable, VARIANTS } from '../../../theme';
import { tracked } from '@glimmer/tracking';
import triggerTheme from './index.theme';

export default class BpSelectPartsTriggerIndexComponent extends Themeable(Component, {
  theme: triggerTheme,
  variant: VARIANTS.OUTLINED,
}) {
  @tracked activeClasses;

  get themeOptions() {
    return {
      ...super.themeOptions,
      active: this.args.select?.isActive || this.args.select?.isOpen,
      highlight: this.args.extra.highlightSelected && this.args.select?.selected,
      disabled: this.args.extra.disabled,
    };
  }

  get extra() {
    return this.args.extra;
  }

  get triggerComponent() {
    return 'bp/select/parts/trigger/single';
  }

  get chevronClasses() {
    return this.getClassesForTheme(this.theme.chevron);
  }
}
