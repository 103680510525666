import baseButtonTheme from './base.theme';
import { SIZES, mergeTheme } from '../theme';

const defaultClasses = mergeTheme(
  baseButtonTheme.root.defaultClasses,
  ({ rounded }) => [rounded ? 'rounded-full' : 'rounded-sm']
);

const iconSizeClasses = () => ({
  [SIZES.ALL]: ['fill-current'],
  [SIZES.XXS]: ['h-3', 'w-3'],
  [SIZES.XS]: ['h-4', 'w-4'],
  [SIZES.SM]: ['h-4', 'w-4'],
  [SIZES.MD]: ['h-5', 'w-5'],
  [SIZES.LG]: ['h-6', 'w-6'],
  [SIZES.XL]: ['h-6', 'w-6'],
});

const spacingClasses = () => ({
  [SIZES.ALL]: ['p-2'],
});

export default {
  root: {
    ...baseButtonTheme.root,
    defaultClasses,
    spacingClasses,
  },
  icon: {
    iconSizeClasses: iconSizeClasses,
  },
};
