import classic from 'ember-classic-decorator';
import { classNames, classNameBindings } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
@classic
@classNames('icon')
@classNameBindings('clickable')
export default class AppIcon extends Component {
  params = null;
  src = null;

  @computed('action')
  get clickable() {
    return !!this.action;
  }

  click() {
    this.triggerAction({ actionContext: this.params });
    return true;
  }
}
