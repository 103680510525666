import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DashboardOwnerResourceProjectionBuildProjectionRoute extends Route {
  @service('alert') alert;
  @service('global-nav') globalNav;
  @service('owner-resource') ownerResource;
  @service router;

  title() {
    let title = 'Projections / Build projection';
    this.globalNav.setTitle(title);
    return title;
  }

  beforeModel(transition) {
    const clusterId = this.ownerResource.selectedCluster;
    if (
      !clusterId &&
      transition.to.name !== 'dashboard.owner-resource.projection.build-projection.view'
    ) {
      this.router.transitionTo('dashboard.owner-resource.projection.reference-listing');
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setProperties({
      dailyOccupancyAvg: null,
      dailyAdr: null,
      radioBtnGroupValue: this.currentUser.isStaff
        ? 'makertOccupancy'
        : 'customOccupancy',
      seasons: [],
      expenses: [],
      projectionTemplateName: '',
      propertyManagementFee: null,
      notes: null,
      website: null,
      phone: null,
      email: null,
      address: null,
      city: null,
      state: null,
      zipcode: null,
      country: null,
    });

    const ownerResource = this.controllerFor('dashboard.owner-resource');
    ownerResource.set('disableSaveProjection', false);
    return controller;
  }

  @action
  willTransition(transition) {
    const transitionToBuildProjection = transition.to.name.includes(
      'dashboard.owner-resource.projection.reference-listing'
    );
    if (transitionToBuildProjection) return;

    this.ownerResource.searchedReferenceListings = false;
    this.ownerResource.selectedAddress = null;
    this.ownerResource.selectedBedroomSize = null;
  }

  renderTemplate() {
    this.send('changeLayout', 'application');
    this.render('dashboard/owner-resource/projection/build-projection', {
      into: 'dashboard/owner-resource/projection',
      outlet: 'build-projection',
    });
  }
}
