import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class IndexRoute extends AuthRoute {
  beforeModel(transition) {
    if (transition.to.name === 'dashboard.index') {
      const { user } = this.modelFor('dashboard');

      if (user.pricingActivatedAt) {
        return this.transitionTo('dashboard.pricing');
      } else if (user.insightsActivatedAt) {
        return this.transitionTo('dashboard.insights');
      } else {
        return this.transitionTo('dashboard.pricing.join');
      }
    }
  }
}
