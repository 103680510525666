import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
@classic
export default class PrivateModeRoute extends Route {
  beforeModel() {
    try {
      localStorage.setItem('beyond-localstorage', 'success');
    } catch (err) {
      return super.beforeModel(arguments);
    }

    this.transitionTo('index');
  }
}
