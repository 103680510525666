import Service from '@ember/service';
import ListingDate from 'appkit/bp-models/listing_date';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

const defaultWeekdayFilters = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export default class ListingActionsService extends Service {
  @tracked selectionWeekdayFilters = defaultWeekdayFilters.concat();
  @tracked calendar = null;
  @tracked monthlyCalendar = null;
  @tracked startDate = null;
  @tracked endDate = null;
  @tracked canOverride = true;
  @tracked listing = null;
  @tracked isMonthly = null;

  get selectedDays() {
    let startDate = this.startDate;
    if (!startDate) {
      return [];
    }
    let endDate = this.endDate;
    if (!endDate) {
      return [];
    }

    let calendarAll = (this.isMonthly ? this.monthlyCalendar : this.calendar) || [];
    return calendarAll.filter(item => {
      if (!item) {
        return false;
      }
      if (item.date < startDate) {
        return false;
      }
      if (item.date > endDate) {
        return false;
      }
      const dayName = moment.localeData('en')._weekdays[item.date.weekday()];
      if (!this.selectionWeekdayFilters.includes(dayName)) {
        return false;
      }
      return true;
    });
  }

  resetChanges(selectedDays) {
    const days = selectedDays || this.selectedDays;
    days.filter(day => day.isDirty).forEach(dirtyDay => dirtyDay.resetChanges());
  }

  resetWeekdayFilters() {
    this.selectionWeekdayFilters = defaultWeekdayFilters.concat();
  }

  resetChangesForWeekday(weekday) {
    this.selectedDays
      .filter(day => day.date.locale('en').format('dddd') === weekday)
      .filter(day => day.isDirty)
      .forEach(dirtyDay => dirtyDay.resetChanges());
  }

  applyChangesForWeekday(weekday, formState) {
    const days = this.selectedDays.filter(
      day => day.date.locale('en').format('dddd') === weekday
    );

    this.applyChangesForDays(days, formState);
  }

  applyChangesForDays(selectedDays, formState) {
    const {
      userMinStays,
      priceOverrideSelected,
      priceOverrideValue,
      availability,
      isDynamicOverrideEnabled,
    } = formState;

    if (formState.isClear) {
      return this.resetChanges(selectedDays);
    }

    if (userMinStays) {
      selectedDays.forEach(day => {
        day.set('minStayUser', userMinStays);
      });
    }

    if (priceOverrideSelected?.action != null) {
      this.priceOverrideChanged(
        selectedDays,
        priceOverrideValue,
        priceOverrideSelected?.action,
        isDynamicOverrideEnabled
      );
    }

    if (availability !== undefined) {
      selectedDays
        .filter(day => day.isAvailabilityEditable)
        .forEach(day => {
          if (availability) {
            day.set('availability', availability);
          } else {
            day.set('availability', day._dirty.availability);
          }
        });
    }
  }

  priceOverrideChanged(
    selectedDays,
    value = null,
    method,
    dynamicOverridesEnabled = false
  ) {
    for (let day of selectedDays) {
      if (method === 'clear') {
        day.set('priceUser', null);
        day.set('percentageUserOverride', null);
        continue;
      }

      if (!(Number(value) !== 0)) {
        // They've erased the whole price. Reset it back to the original price.
        if (day._dirty.priceUser) {
          day.set('priceUser', day._dirty.priceUser);
        } else {
          day.set('priceUser', null);
        }

        day.set('percentageUserOverride', null);

        continue;
      }

      if (method === 'amount') {
        day.set('priceUser', Number(value));
        day.set('percentageUserOverride', null);
      } else if (method === 'increase') {
        // Always use original value to avoid compounding effect
        const originalPriceUser = day._dirty.priceUser || day.priceModeled;
        const price = Math.floor(
          originalPriceUser + originalPriceUser * (Number(value) / 100)
        );
        // Do not allow prices above maximum price

        if (dynamicOverridesEnabled) {
          day.set('priceUser', null);
          day.set('percentageUserOverride', Number(value) / 100);
        } else {
          day.set('priceUser', price);
          day.set('percentageUserOverride', null);
        }
      } else if (method === 'decrease') {
        // Always use original value to avoid compounding effect
        const originalPriceUser = day._dirty.priceUser || day.priceModeled;
        const price = Math.floor(
          originalPriceUser - originalPriceUser * (Number(value) / 100)
        );
        // Do not allow prices below minimum price
        if (dynamicOverridesEnabled) {
          day.set('priceUser', null);
          value;
          day.set('percentageUserOverride', (Number(value) / 100) * -1);
        } else {
          day.set('priceUser', price < 10 ? 10 : price);
          day.set('percentageUserOverride', null);
        }
      } else {
        throw Error(`Invalid method: ${method}`);
      }
    }
  }

  async saveDays(selectedDays, properties) {
    const days = selectedDays || this.selectedDays;

    if (!this.canOverride) {
      return false;
    }

    let dirtyDays = days.filter(day => day.isDirty);

    let canEdit = this.listing.canEdit;
    if (!dirtyDays.length) {
      return false;
    }
    if (!canEdit) {
      return false;
    }

    if (this.isMonthly) {
      for (let day of days) {
        day.set('monthlyPriceUser', day.priceUser);
      }
    }

    let propertiesToSave = this.isMonthly
      ? ['id', 'date', 'monthlyPriceUser']
      : properties;

    try {
      await ListingDate.saveMany(days, propertiesToSave);
    } catch (err) {
      // Handle the case where the client fails
      const errors = Array.isArray(err)
        ? err
        : [{ message: 'Failed to update listing day configuration.' }];
      throw errors;
    } finally {
      if (this.isMonthly) {
        for (let day of days) {
          day.markClean();
        }
      }
    }

    // TODO: Temporarily set the date of overrides
    // We should probably get this updated listing dates on the response
    // and refresh the listing date models.
    for (let day of days) {
      if (day.priceUser && !day.priceUserUpdatedAt) {
        day.set('priceUserUpdatedAt', Date());
      }
    }

    return true;
  }

  setup({ calendar, monthlyCalendar, listing, isMonthly } = {}) {
    this.startDate = null;
    this.endDate = null;
    this.listing = listing ?? this.listing;
    this.calendar = calendar ?? this.calendar;
    this.monthlyCalendar = monthlyCalendar ?? this.monthlyCalendar;
    this.isMonthly = isMonthly ?? this.isMonthly;
    this.resetWeekdayFilters();
  }

  addWeekday(key, formState) {
    this.selectionWeekdayFilters.addObject(key);
    this.applyChangesForWeekday(key, formState);
  }

  removeWeekday(key) {
    this.resetChangesForWeekday(key);
    this.selectionWeekdayFilters.removeObject(key);
  }

  cancelOverride(selectedDays) {
    const days = selectedDays.filter(
      day => day.priceUser !== null || day.percentageUserOverride !== null
    );
    days.forEach(d => {
      d.set('priceUser', null);
      d.set('percentageUserOverride', null);
    });

    return this.saveDays(days, ['date', 'priceUser', 'percentageUserOverride']);
  }

  cancelMinStayOverride(selectedDays) {
    const days = selectedDays.filter(day => day.minStayUser !== null);
    days.forEach(d => d.set('minStayUser', null));

    return this.saveDays(days, ['date', 'minStayUser']);
  }

  dateRangeSelected(range) {
    this.startDate = range?.start;
    this.endDate = range?.end;
  }
}
