import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import logger from 'appkit/lib/logger';

@classic
export default class ErrorRoute extends Route {
  renderTemplate(controller, model) {
    logger.error('Error route rendered', arguments);

    if (model && model.template) {
      this.render(model.template);
    } else {
      if (Array.isArray(model)) {
        model = model[0];
      }
      this.render('error.server', { model: model });
    }
  }
}
