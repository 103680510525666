define("appkit/components/g-map/marker", ["exports", "ember-google-maps/components/g-map/marker"], function (_exports, _marker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _marker.default;
    }
  });
});
