import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Component from '@ember/component';

@classic
export default class AppDiscount extends Component {
  @computed('discount.percentage')
  get discountPercentage() {
    const val = Math.round(this.get('discount.percentage') * 100);
    return Math.abs(val);
  }

  @computed('discount.id')
  get isDayLocked() {
    const id = this.get('discount.id');
    return id != null && id != -1;
  }

  @action
  onRemoveDiscount() {
    this.removeDiscount(this.discount);
  }

  @action
  discountPercentageChange(val) {
    let percentage = parseInt(val) / 100;
    if (this.get('discount.isIncrease')) {
      percentage = percentage * -1;
    }
    this.discountUpdate('percentage', percentage, this.discount);
  }

  @action
  discountTypeChange(ev) {
    const isIncrease = ev.target.value === 'increase';
    const multiplier = isIncrease ? -1 : 1;
    const percentage = Math.abs(this.get('discount.percentage')) * multiplier;
    this.set('discount.isIncrease', isIncrease);

    this.discountUpdate('percentage', percentage, this.discount);
  }

  @action
  discountDaysChange(attr, val) {
    if (!Number.isNaN(parseInt(val))) {
      val = parseInt(val);
    }
    this.discountUpdate(attr, val, this.discount);
  }

  @action
  discountChange(attr, val) {
    val = val.target.value;
    this.discountUpdate(attr, val, this.discount);
  }
}
