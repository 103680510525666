import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{this.classes}}\n  {{did-insert this.updateWrappedState}}\n  {{did-update this.updateWrappedState @select.selected}}\n>\n  <span class='{{this.textClasses}}'>\n    {{#if this.optionText}}\n      {{this.optionText}}\n    {{else}}\n      {{yield @option}}\n    {{/if}}\n  </span>\n  {{#if this.allowTagRemove}}\n    <div\n      role='button'\n      class={{this.iconWrapperClasses}}\n      {{on 'mousedown' this.onClear}}\n    >\n      <AppSvg @src='close' @class={{this.iconSizeClasses}} />\n    </div>\n  {{/if}}\n</div>", {"contents":"<div\n  class={{this.classes}}\n  {{did-insert this.updateWrappedState}}\n  {{did-update this.updateWrappedState @select.selected}}\n>\n  <span class='{{this.textClasses}}'>\n    {{#if this.optionText}}\n      {{this.optionText}}\n    {{else}}\n      {{yield @option}}\n    {{/if}}\n  </span>\n  {{#if this.allowTagRemove}}\n    <div\n      role='button'\n      class={{this.iconWrapperClasses}}\n      {{on 'mousedown' this.onClear}}\n    >\n      <AppSvg @src='close' @class={{this.iconSizeClasses}} />\n    </div>\n  {{/if}}\n</div>","moduleName":"appkit/components/bp/select/parts/selected-item/multiple.hbs","parseOptions":{"srcName":"appkit/components/bp/select/parts/selected-item/multiple.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Themeable } from '../../../theme';
import multipleSelectedItemTheme from './multiple.theme';

export default class BpSelectPartsSelectedItemMultipleComponent extends Themeable(
  Component,
  {
    theme: multipleSelectedItemTheme,
  }
) {
  get optionText() {
    return undefined;
  }

  get themeOptions() {
    return {
      ...super.themeOptions,
      allowTagRemove: this.allowTagRemove,
      noWrap: this.args.extra.selectedItemNoWrap,
    };
  }

  get allowTagRemove() {
    return this.args.extra?.allowTagRemove ?? true;
  }

  get textClasses() {
    return this.getClassesForTheme(this.theme.tagText);
  }

  get iconSizeClasses() {
    return this.getClassesForTheme(this.theme.tagIcon);
  }

  get iconWrapperClasses() {
    return this.getClassesForTheme(this.theme.tagIconWrapper);
  }

  // Very hacky but we need to check the parent offset to know if we're wrapped
  getParentOffset(el) {
    return el.parentElement.parentElement.children[0].offsetTop;
  }

  @action
  updateWrappedState(el) {
    if (!this.args.extra.selectedItemNoWrap) {
      return;
    }

    // The element is wrapped
    if (el.offsetTop > this.getParentOffset(el)) {
      this.args.extra.addWrappedItem(this.args.option);
    } else {
      this.args.extra.removeWrappedItem(this.args.option);
    }
  }

  willDestroy() {
    if (!this.args.extra.selectedItemNoWrap) {
      return;
    }

    this.args.extra.removeWrappedItem(this.args.option);
  }

  @action
  onClear(e) {
    e.stopPropagation();
    this.args.select.actions.choose(this.args.option);
  }
}
