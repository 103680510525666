import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { CurrencyUtil } from 'appkit/lib/currency';

@classic
@tagName('div')
@classNames('top-bookings')
export default class AppTopBookings extends Component {
  layoutName = 'components/app-top-bookings';
  listing = null;
  bookings = [];
  averageNightlyPayout = null;

  @computed('listing.currency')
  get currency() {
    return this.get('listing.currency');
  }

  @computed('averageDailyRate')
  get formattedAverageDailyRate() {
    let amount = this.averageDailyRate;

    return CurrencyUtil.format(amount, {
      currency: this.currency,
    });
  }
}
