import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set, get } from '@ember/object';

export default class DashboardOwnerResourceProjectionBuildProjectionViewRoute extends Route {
  @service ownerResource;

  model(params) {
    return this.bpStore.peekRecord('projection', params.id);
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    this.buildProjection = this.controllerFor(
      'dashboard.owner-resource.projection.build-projection'
    );
    const projection = model;

    if (projection.projectionTemplate) {
      set(
        this,
        'buildProjection.selectedProjectionTemplateOption',
        projection.projectionTemplate
      );
    } else {
      set(
        this,
        'buildProjection.selectedProjectionTemplateOption',
        this.buildProjection.defaultOptions[0]
      );
    }
    set(this, 'buildProjection.projectionTemplateName', projection.projectionName);
    set(this, 'buildProjection.seasons', projection.seasons);
    set(this, 'buildProjection.propertyManagementFee', projection.pmFee);
    set(this, 'buildProjection.expenses', projection.expenses);

    // company info
    const {
      companyNotes,
      companyWebsite,
      companyPhone,
      companyEmail,
      companyAddress,
      companyCity,
      companyState,
      companyZipcode,
      companyCountry,
    } = projection;

    const hasCompanyInfo =
      !!companyWebsite ||
      !!companyPhone ||
      !!companyEmail ||
      !!companyAddress ||
      !!companyCity ||
      !!companyState ||
      !!companyZipcode ||
      !!companyCountry;

    if (hasCompanyInfo) {
      set(this, 'buildProjection.displayCompanyInfo', true);
    }
    set(this, 'buildProjection.notes', companyNotes);
    set(this, 'buildProjection.website', companyWebsite);
    set(this, 'buildProjection.phone', companyPhone);
    set(this, 'buildProjection.email', companyEmail);
    set(this, 'buildProjection.address', companyAddress);
    set(this, 'buildProjection.city', companyCity);
    set(this, 'buildProjection.state', companyState);
    set(this, 'buildProjection.zipcode', companyZipcode);
    set(this, 'buildProjection.country', companyCountry);

    this.ownerResource.selectListing(projection.referenceListing);
    set(this, 'ownerResource.selectedCluster', projection.clusterId);
    if (!projection.referenceListing) {
      set(this, 'ownerResource.manualBasePrice', projection.referenceBasePrice);
    }

    set(this, 'ownerResource.selectedBedroomSize', projection.beds);
    set(this, 'ownerResource.searchedAddressGeocode.address', projection.address);
    set(this, 'ownerResource.searchedAddressGeocode.city', projection.city);
    set(this, 'ownerResource.searchedAddressGeocode.state', projection.state);
    set(this, 'ownerResource.searchedAddressGeocode.zipcode', projection.zipcode);
    set(this, 'ownerResource.searchedAddressGeocode.country', projection.country);
    set(this, 'ownerResource.selectedCurrency', projection.currency);

    if (get(projection, 'projectionTemplate')) {
      const projectionTemplate = this.bpStore.peekRecord(
        'projectionTemplate',
        projection.projectionTemplate
      );

      set(
        this,
        'buildProjection.selectedProjectionTemplateOption.id',
        projectionTemplate
      );
    }

    const ownerResource = this.controllerFor('dashboard.owner-resource');
    set(ownerResource, 'disableSaveProjection', true);
  }
}
