import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class DashboardSignalController extends Controller {
  @service router;

  get isWelcome() {
    return (
      this.router.currentRouteName === 'dashboard.signal.welcome' ||
      this.router.currentRouteName === 'dashboard.signal.form'
    );
  }
}
