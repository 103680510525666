import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { CurrencyUtil } from 'appkit/lib/currency';
import '@ember/object';

@classic
export default class AdjustPrice extends Helper {
  @service
  intl;

  _formatNumber(number, maximumSignificantDigits) {
    let options = {};
    if (maximumSignificantDigits) {
      options = { maximumSignificantDigits: maximumSignificantDigits };
    }
    return Intl.NumberFormat(this.intl.locale, options).format(number);
  }

  _getCurrencySymbol(currency) {
    return CurrencyUtil.getSymbol(currency);
  }

  _generateHtml(priceClass, currency, price, metricUnit) {
    const currencySymbol = this._getCurrencySymbol(currency);
    const currencyEl = `<span class="currency ${priceClass}">${currencySymbol.symbol}</span>`;
    const priceEl = `<span class="${priceClass}">${price}${metricUnit}</span>`;

    return (currencySymbol.isPrefixed
      ? `${currencyEl}${priceEl}`
      : `${priceEl}${currencyEl}`
    ).htmlSafe();
  }

  compute(priceInfo) {
    let price = priceInfo[0];
    let currency = priceInfo[1];
    let metricUnit = '';
    let priceClass = '';

    // Examples
    // 1,369,253 => 1.37M
    // 13,692,526 => 13.7M
    if (price >= 1000000) {
      let roundedPrice = price / 1000000;
      metricUnit = 'M';
      if (price >= 1000000) {
        priceClass = 'very-big-amount';
      } else {
        priceClass = 'big-amount';
      }
      let formattedPrice = this._formatNumber(roundedPrice, 3);
      return this._generateHtml(priceClass, currency, formattedPrice, metricUnit);
    }

    // Examples
    // 13,693 => 13.7K
    // 136,925 => 137K
    if (price >= 10000) {
      let roundedPrice = price / 1000;
      metricUnit = 'K';
      if (price >= 100000) {
        priceClass = 'very-big-amount';
      } else {
        priceClass = 'big-amount';
      }
      let formattedPrice = this._formatNumber(roundedPrice, 3);
      return this._generateHtml(priceClass, currency, formattedPrice, metricUnit);
    }

    // < 10000
    // Do not round the price, use it directly
    // Format without truncating any numbers
    let formattedPrice = this._formatNumber(price, null);
    return this._generateHtml(priceClass, currency, formattedPrice, metricUnit);
  }

  // eslint-disable-next-line ember/no-observers
  @observes('intl.locale')
  _recomputeOnLocaleChange() {
    this.recompute();
  }
}
