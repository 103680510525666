import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import $ from 'jquery';

@classic
export default class HeaderTransitionRoute extends Route {
  activate() {
    $(window).on('scroll.header', function (event) {
      let height = document.querySelector('header').offsetHeight;

      if (event.currentTarget.pageYOffset > height) {
        document.body.classList.add('scrolled');
      } else {
        document.body.classList.remove('scrolled');
      }
    });
  }

  deactivate() {
    document.body.classList.remove('scrolled');
    $(window).off('scroll.header');
  }
}
