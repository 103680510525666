import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class HotelMapRoute extends AuthRoute {
  templateName = 'data.map';

  model() {
    let market = this.modelFor('data.market');
    let url = `/admin/api/markets/${market.id}/hotels/map`;

    return this.ajax._get(url).then(data =>
      EmberObject.create({
        points: data.hotels,
      })
    );
  }
}
