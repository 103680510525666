import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import { getOwner } from '@ember/application';
import { ChannelUtil } from 'appkit/lib/channel';
import { default as mainChannel } from 'appkit/lib/main_channel';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

@classic
export default class ConnectRoute extends AuthRoute {
  routeName = 'dashboard.connect';
  @service router;
  @tracked chainedConnectContext;

  model() {
    let model = this.modelFor('dashboard');

    return this.ajax._get('/api/user/questions').then(response => {
      if (response.listingAvailableToBookOn && response.mostTimeManagingSpentOn) {
        model.onboardingSurveyAlreadyAnswered = true;

        model.mainChannel = mainChannel(
          response.mostTimeManagingSpentOn,
          response.listingAvailableToBookOn
        );
      }
      model.listingAvailableToBookOn = response.listingAvailableToBookOn;

      model.chainedConnectContext = false;

      model.firstAccountAlreadyConnected = false;

      return model;
    });
  }

  afterModel(model, transition) {
    let fastTrack =
      !transition.from?.name &&
      model?.mainChannel &&
      transition.to.name === 'dashboard.connect-prompt.index';

    if (!fastTrack) return;

    let assistedChannels = ChannelUtil.getAssisted();

    let routeToTake = assistedChannels.includes(model.mainChannel)
      ? 'assisted'
      : 'channel';

    getOwner(this).lookup(
      `controller:dashboard.connect-prompt.${routeToTake}`
    ).hasFastTrack = fastTrack;

    if (transition.to.name !== 'dashboard.connect-prompt.index') return;

    this.transitionTo(`dashboard.connect-prompt.${routeToTake}`, model.mainChannel);
  }

  setupController(controller, model) {
    controller.set('model', model);

    return controller;
  }

  @action
  willTransition(transition) {
    this.chainedConnectContext =
      transition.from?.name == 'dashboard.connect-prompt.channel';
    set(this, 'controller.model.chainedConnectContext', this.chainedConnectContext);

    let model = this.controller.get('model');
    let fastTrack = !transition.from?.name && model?.mainChannel;

    let assistedChannels = ChannelUtil.getAssisted();

    let routeToTake = assistedChannels.includes(model.mainChannel)
      ? 'assisted'
      : 'channel';

    getOwner(this).lookup(
      `controller:dashboard.connect-prompt.${routeToTake}`
    ).hasFastTrack = fastTrack;

    if (fastTrack) {
      this.transitionTo(`dashboard.connect-prompt.${routeToTake}`, model.mainChannel);
    }
  }
}
