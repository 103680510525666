import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='beyond-legacy {{this.className}}'>\n  <PowerSelect\n    @destination='ember-legacy-dropdown-wormhole'\n    @triggerClass={{this.triggerClass}}\n    @dropdownClass='no-bg-for-current'\n    @placeholder={{@placeholder}}\n    @searchField={{@searchField}}\n    @searchEnabled={{@searchEnabled}}\n    @options={{@options}}\n    @selected={{@selected}}\n    @onChange={{@onChange}}\n    @renderInPlace={{@renderInPlace}}\n    ...attributes as |l|\n  >\n    {{yield l}}\n  </PowerSelect>\n</div>", {"contents":"<div class='beyond-legacy {{this.className}}'>\n  <PowerSelect\n    @destination='ember-legacy-dropdown-wormhole'\n    @triggerClass={{this.triggerClass}}\n    @dropdownClass='no-bg-for-current'\n    @placeholder={{@placeholder}}\n    @searchField={{@searchField}}\n    @searchEnabled={{@searchEnabled}}\n    @options={{@options}}\n    @selected={{@selected}}\n    @onChange={{@onChange}}\n    @renderInPlace={{@renderInPlace}}\n    ...attributes as |l|\n  >\n    {{yield l}}\n  </PowerSelect>\n</div>","moduleName":"appkit/components/bp/select-old.hbs","parseOptions":{"srcName":"appkit/components/bp/select-old.hbs"}});
import Component from '@glimmer/component';

export default class SelectComponent extends Component {
  get className() {
    return this.args.className || 'text-gray-800 font-normal py-3';
  }

  get triggerClass() {
    let css =
      this.args.triggerClass ||
      'border border-solid align-middle leading-9 outline-none';

    if (this.args.hasChanges) {
      css += ' border-brand-400';
    } else {
      css += ' border-gray-300';
    }

    return css;
  }
}
