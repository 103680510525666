import { helper } from '@ember/component/helper';

export const eq_array = ([arr1, arr2]) => {
  if (!Array.isArray(arr1)) {
    arr1 = [arr1];
  }
  if (!Array.isArray(arr2)) {
    arr2 = [arr2];
  }

  return JSON.stringify(arr1) === JSON.stringify(arr2);
};

export default helper(eq_array);
