import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='relative inline-block {{@containerClass}}'>\n  <div\n    class='absolute\n    {{if @append 'right-0'}}\n     flex flex-col h-full justify-center p-2 text-gray-400 pointer-events-none\n    {{if this.isFocused 'text-brand-600'}}'\n  >\n    {{@symbol}}\n  </div>\n  <Bp::Input::Text\n    class='{{if @append (if this.large 'pr-10' 'pr-6') (if this.large 'pl-10' 'pl-6')}}'\n    @onInput={{@onInput}}\n    @onChange={{@onChange}}\n    @hasError={{@hasError}}\n    @isDirty={{@isDirty}}\n    @toggleIsFocused={{this.toggleIsFocused}}\n    ...attributes\n  />\n</div>", {"contents":"<div class='relative inline-block {{@containerClass}}'>\n  <div\n    class='absolute\n    {{if @append 'right-0'}}\n     flex flex-col h-full justify-center p-2 text-gray-400 pointer-events-none\n    {{if this.isFocused 'text-brand-600'}}'\n  >\n    {{@symbol}}\n  </div>\n  <Bp::Input::Text\n    class='{{if @append (if this.large 'pr-10' 'pr-6') (if this.large 'pl-10' 'pl-6')}}'\n    @onInput={{@onInput}}\n    @onChange={{@onChange}}\n    @hasError={{@hasError}}\n    @isDirty={{@isDirty}}\n    @toggleIsFocused={{this.toggleIsFocused}}\n    ...attributes\n  />\n</div>","moduleName":"appkit/components/bp/input/symbol.hbs","parseOptions":{"srcName":"appkit/components/bp/input/symbol.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BpInputSymbolComponent extends Component {
  @tracked isFocused = false;

  get large() {
    return this.args.symbol?.length > 1;
  }

  @action
  toggleIsFocused() {
    this.isFocused = !this.isFocused;
    this.args.toggleIsFocused?.(this.isFocused);
  }
}
