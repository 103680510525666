import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import { ValueRange } from 'appkit/lib/value_range';
import Customize from 'appkit/bp-models/customize';
import { inject as service } from '@ember/service';

@classic
export default class CustomizeLegacyRoute extends AuthRoute {
  templateName = 'listing.customize';

  @service featureFlag;
  @service intl;

  monthlyFeatureFlagEnabled = false;

  setupController(controller, model) {
    controller.set('model', model);
    controller.set(
      'listing',
      this.modelFor('dashboard.pricing.listing').get('listing')
    );
    controller.set('monthlyFeatureFlagEnabled', this.monthlyFeatureFlagEnabled);
  }

  async model() {
    let model = this.modelFor('dashboard.pricing.listing');
    let listingId = model.get('listing.id');
    let url = `/api/listings/${listingId}/customize`;

    this.monthlyFeatureFlagEnabled = await this.featureFlag.evaluate(
      'monthly-pricing-v-1',
      false
    );

    let customizeData = await this.ajax._get(url);

    for (let field of Object.keys(customizeData)) {
      if (!field.endsWith('Ranges')) {
        continue;
      }

      customizeData[field] = customizeData[field].map(row => ValueRange.create(row));
    }

    let intl = this.intl;

    return Customize.create({
      ...customizeData,
      intl,
      currency: model.listing.currency,
    });
  }

  @action
  willTransition(transition) {
    let saving = this.controller.get('saving');
    let isDirty = this.controller.get('model').get('isDirty');

    if (!saving && isDirty && !confirm('Are you sure you want to abandon progress?')) {
      transition.abort();
    } else {
      this.controller.set('saving', false);
      return true;
    }
  }
}
