import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='{{this.classes}}' ...attributes>\n  {{yield\n    (component\n      'bp/button-group/button' variant=this.variant color=this.color size=this.size\n    )\n  }}\n</div>", {"contents":"<div class='{{this.classes}}' ...attributes>\n  {{yield\n    (component\n      'bp/button-group/button' variant=this.variant color=this.color size=this.size\n    )\n  }}\n</div>","moduleName":"appkit/components/bp/button-group/index.hbs","parseOptions":{"srcName":"appkit/components/bp/button-group/index.hbs"}});
import Component from '@glimmer/component';
import { Themeable } from 'appkit/components/bp/theme';
import buttonGroupTheme from './index.theme';

export default class BpButtonGroupComponent extends Themeable(Component, {
  theme: buttonGroupTheme,
}) {
  get themeOptions() {
    return {
      ...super.themeOptions,
      wrap: this.args.wrap,
    };
  }
}
