import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { ChannelUtil } from 'appkit/lib/channel';

export default class DashboardRelayIndexReservationsController extends Controller {
  @tracked reservations;
  @tracked nextReservationsPage;
  @tracked getMoreReservationsIsDown = false;
  @tracked lastReservation;
  @tracked reachedLastReservationPage = false;
  @tracked hasReservationsUnavailable;
  @tracked selectedSortingField = 'status_updated_at';
  @tracked sortingOrder = '-';
  @tracked errorResolvePathSidebarIsVisible = false;
  @tracked selectedReservation = [];

  @tracked isCheckingForReservationBlock = true;
  @tracked hasReservationBlockFound = true;
  @tracked selectedPMSListing;
  @tracked reservationToBeResolved;

  @tracked isIntroForResolveReservationError = true;
  @tracked isReservationToBeMerged;
  @tracked isIntroForCancelPMSReservation = true;
  @tracked isPMSReservationToBeCanceled;
  @tracked enqueuePushReservationSuccessful = false;
  @tracked isProcessingARequest = false;

  @service alert;
  @service ajax;
  @service('reservations') reservationsService;

  @controller('dashboard.relay.index') indexController;

  @action
  onCancelSidebar() {
    this.errorResolvePathSidebarIsVisible = false;
    this.selectedReservation = [];

    if (this.enqueuePushReservationSuccessful) {
      this.getReservations(this.sortingOrder, { field: this.selectedSortingField });
    } else {
      this.reservations = this.reservations.map(r => ({
        ...r,
        selected: false,
      }));
    }
  }

  @action
  async cancelAndEnqueueReservation(shouldCancelFirst) {
    this.isProcessingARequest = true;

    try {
      if (shouldCancelFirst) {
        await this.reservationsService.editReservations(
          this.reservationToBeResolved.id,
          'cancel_in_pms'
        );
      }
      await this.reservationsService.editReservations(
        this.selectedReservation[0].id,
        'enqueue_push_reservation'
      );
    } catch {
      this.alert.error('An error occurred. Please try again.');
      return false;
    } finally {
      this.isProcessingARequest = false;
    }

    if (shouldCancelFirst) {
      this.onCancelSidebar();
      this.isIntroForCancelPMSReservation = true;
      this.isIntroForResolveReservationError = true;
      this.getReservations(this.sortingOrder, { field: this.selectedSortingField });
    } else {
      this.enqueuePushReservationSuccessful = true;
    }
  }

  @action
  async mergeReservations() {
    this.isProcessingARequest = true;

    try {
      await this.reservationsService.editReservations(
        this.reservationToBeResolved.id,
        `set_direct_reservation/${this.selectedReservation[0].id}`
      );

      await this.reservationsService.editReservations(
        this.selectedReservation[0].id,
        'resolve_last_push_outcome'
      );
    } catch {
      this.alert.error('An error occurred. Please try again.');
      return false;
    } finally {
      this.isProcessingARequest = false;
    }

    this.enqueuePushReservationSuccessful = true;
    this.onCancelSidebar();
  }

  @action
  async onSelect(reservation, sel) {
    if (
      !(
        reservation[0].lastReservationPushStatus?.status == 'error' &&
        reservation[0].listing.reservationIsSupported &&
        reservation[0].defaultStatus != 'canceled'
      )
    )
      return;

    const sidebarIsBlocked = reservation[0].id != this.selectedReservation[0]?.id;

    this.reservations = this.reservations.map(r => ({
      ...r,
      selected: sel && reservation[0].id == r.id ? true : false,
    }));

    this.selectedReservation = sel
      ? [this.reservations.find(r => r.selected == true)]
      : [];

    this.errorResolvePathSidebarIsVisible = this.errorResolvePathSidebarIsVisible
      ? sidebarIsBlocked
      : true;

    if (!this.errorResolvePathSidebarIsVisible) return;

    this.selectedPMSListing = this.selectedReservation[0].listing.channelListings.find(
      cl => ChannelUtil.isPms(cl.channel)
    );
    this.selectedChannelListing = this.selectedReservation[0].listing.channelListings.find(
      cl => cl.channel == this.selectedReservation[0].channel
    );

    let result;

    try {
      this.isCheckingForReservationBlock = true;
      result = await this.ajax._get(
        `/api/relay/reservations/?type=PMS&channel_listing_id=${this.selectedPMSListing.id}&checkin_date=${this.selectedReservation[0].checkinDate}&checkout_date=${this.selectedReservation[0].checkoutDate}`
      );
    } catch (error) {
      this.alert.error('An error occurred. Please try again.');
      return false;
    }

    this.isIntroForCancelPMSReservation = true;
    this.isIntroForResolveReservationError = true;
    this.enqueuePushReservationSuccessful = false;
    this.hasReservationBlockFound = result.data.some(res => res);
    this.reservationToBeResolved = result.data
      .map(res => ({
        ...res.attributes,
        guest: {
          ...res.attributes.guest,
          name:
            res.attributes.guest?.name ??
            `${res.attributes.guest?.firstName ?? '--'} ${
              res.attributes.guest?.firstName
                ? res.attributes.guest?.lastName ?? ''
                : ''
            }`,
        },
        id: res.id,
      }))
      .find(res => res);
    this.isCheckingForReservationBlock = false;
  }

  @action
  async getReservations(order, field) {
    this.errorResolvePathSidebarIsVisible = false;
    this.selectedReservation = [];
    this.reservations = this.reservations.map(r => ({
      ...r,
      selected: false,
    }));

    this.sortingOrder = order;
    this.selectedSortingField = field.field;

    this.indexController.areReservationsLoading = true;
    this.reservations = [];

    let rawReservations;

    try {
      rawReservations = await this.reservationsService.getReservations(
        this.selectedSortingField,
        this.sortingOrder,
        this.model,
        1
      );
    } catch (errors) {
      this.alert.error('An error occurred. Please try again.');
      return false;
    }

    if (!rawReservations.listed) {
      this.hasReservationsUnavailable = true;
      return;
    }

    this.reservations = rawReservations.listed;

    this.reachedLastReservationPage =
      rawReservations.totalPages == rawReservations.currentPage;

    this.nextReservationsPage = this.reachedLastReservationPage
      ? rawReservations.totalPages
      : rawReservations.currentPage + 1;

    this.indexController.areReservationsLoading = false;
    this.getMoreReservationsIsDown = false;
  }

  @action
  async getMoreReservations(lastItem) {
    if (this.getMoreReservationsIsDown || lastItem.id == this.lastReservation?.id)
      return;

    this.getMoreReservationsIsDown = true;
    this.reservations = this.reservations.map(r => ({
      ...r,
      alreadyLoaded: true,
    }));

    if (this.reachedLastReservationPage) return;

    let rawReservations;

    try {
      rawReservations = await this.reservationsService.getReservations(
        this.selectedSortingField,
        this.sortingOrder,
        this.model,
        this.nextReservationsPage
      );
    } catch (errors) {
      this.alert.error('An error occurred. Please try again.');
      return false;
    }

    if (!rawReservations) {
      this.hasReservationsUnavailable = true;
      return;
    }

    this.lastReservation = lastItem;

    this.reservations = [
      ...this.reservations,
      ...rawReservations.listed.filter(
        r => !this.reservations.map(re => re.id).includes(r.id)
      ),
    ];

    this.reachedLastReservationPage =
      rawReservations.totalPages == rawReservations.currentPage;

    this.nextReservationsPage = this.reachedLastReservationPage
      ? rawReservations.totalPages
      : rawReservations.currentPage + 1;

    this.getMoreReservationsIsDown = false;
  }
}
