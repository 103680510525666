import { CHANNEL } from './channel_configuration';

export default function mainChannel(secondAnswer, firstAnswer) {
  let out = null;

  if (secondAnswer.includes('OTHER')) {
    if (secondAnswer.includes('PMS')) {
      return out;
    } else {
      out =
        CHANNEL[
          ['AIRBNB', 'VRBO', 'BOOKING'].filter(i => firstAnswer.includes(i))[0]
        ] ?? null;
    }
  } else {
    out = CHANNEL[secondAnswer];
  }

  if (out == 'booking') out = 'booking_connect';

  return out;
}
