import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { displayErrors } from 'appkit/lib/display_errors';

@classic
export default class TokensController extends Controller {
  @service
  alert;

  @action
  async redirectToReadme() {
    let response;
    try {
      response = await this.ajax._post('/api/generate_readme_token');
    } catch (errors) {
      displayErrors({ errors: errors, modelOrKeywordThis: this, alert: alert });
      return;
    }
    const url = `https://api-docs.beyondpricing.com?h=${response.token}`;
    window.location = url;
  }
}
