import BaseModel from 'appkit/bp-models/base_model';
import { computed } from '@ember/object';
import moment from 'moment';

const ListingQuote = BaseModel.extend({
  init() {
    this._super(arguments);
    this._EDITABLE = ['date', 'price', 'lastScrapeStamp'];
  },
  _date: null,
  date: computed('_date', {
    get() {
      return this._date;
    },
    set(_key, value) {
      if (!value._isAMomentObject) {
        value = moment(value);
      }

      this.set('_date', value);

      return this._date;
    },
  }),
});

export default ListingQuote;
