import { SETTINGS } from 'appkit/lib/relay/settings/managed-account/rel-managed-account-settings';

/**
 * Calls a settings repository to get a managed account settings
 *
 * Return a settings object { id, channel, settings } for that account
 *
 * @param {*} settingsRepository  - The data provider
 * @param {*} managedAccount
 *
 * @rule
 * The managed account must be a PMS
 */
export async function getSettingsUC(
  settingsRepository,
  managedAccount,
  intl,
  featureFlags
) {
  const { id, channel, channelConfig } = managedAccount;
  if (!channelConfig.pms) return;

  try {
    const APISettings = await settingsRepository.get(managedAccount.id);

    let syncSettings = SETTINGS.sync;

    if (!featureFlags.flag_RELAY_CONTENT_SYNC_ENABLED) {
      syncSettings.settings = SETTINGS.sync.settings.filter(
        setting => setting.key !== 'shouldAutoEnableContentSyncing'
      );
    }

    const settings = {
      sync: getSettingsMap(APISettings, syncSettings, intl),
      new_reservations: getSettingsMap(APISettings, SETTINGS.new_reservations, intl),
      updated_reservations: getSettingsMap(
        APISettings,
        SETTINGS.updated_reservations,
        intl
      ),
    };

    const hasSettings =
      settings.sync.settings.length ||
      settings.updated_reservations.settings.length ||
      settings.updated_reservations.settings.length;

    return {
      id,
      channel,
      label: channelConfig.label,
      settings: hasSettings ? settings : null,
    };
  } catch (e) {
    console.error(e);
  }
}

/**
 * Maps each one of the API settings into enriched map used in the DOM
 */
function getSettingsMap(apiConfig, sourceSettingsMap, intl) {
  const apiSettingsKeys = Object.keys(apiConfig);
  const newSettingArray = [];

  //add syncAll key to api keys so that it shows on the UI
  apiSettingsKeys.push('syncAll');

  sourceSettingsMap.settings.forEach(settingMapItem => {
    // By iterating on the API keys, we directly discard the ones that are not supported
    for (const setting of apiSettingsKeys) {
      if (settingMapItem.key === setting) {
        const sourceSetting = settingMapItem;

        const updatedSetting = {
          ...sourceSetting,
          description: sourceSetting.description
            ? intl.t(sourceSetting.description)
            : undefined,
          isActive:
            setting === 'syncAll'
              ? apiConfig['shouldAutoEnablePricing'] &&
                apiConfig['shouldAutoEnableAvailability'] &&
                apiConfig['shouldAutoEnableReservations'] &&
                apiConfig['shouldAutoEnableContentSyncing']
              : apiConfig[setting],
        };
        newSettingArray.push(updatedSetting);
      }
    }
  });

  return {
    label: sourceSettingsMap.label ? intl.t(sourceSettingsMap.label) : undefined,
    settings: newSettingArray,
  };
}
