import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames, classNameBindings } from '@ember-decorators/component';
import { scheduleOnce } from '@ember/runloop';
import Component from '@ember/component';
import Util from 'appkit/lib/util';
import { displayErrors } from 'appkit/lib/display_errors';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

@classic
@classNameBindings('posting')
@classNames('form', 'vertical', 'beyond-legacy')
export default class AppCredentials extends Component {
  @service intl;
  // ID can be username, email, actual ID
  idPlaceholder = 'ID';

  idType = 'text';
  showID = true;

  // No need to verify on login
  verifyEmail = true;

  // Secondary ID
  idSecondaryPlaceholder = 'Secondary ID';
  idSecondaryType = 'text';
  showSecondaryID = false;
  requireSecondaryID = true; // applied only if it is shown

  // Password
  passwordPlaceholder = 'Password';
  showPassword = true;
  requirePassword = true; // applied only if it is shown

  // Secondary Username
  showSecondaryUsername = false;
  secondaryUsernameType = 'text';
  requireSecondaryUsername = true; // applied only if it is shown
  secondaryUsernamePlaceolder = 'Your read username';

  // Secondary Password
  showSecondaryPassword = false;
  secondaryPasswordPlaceolder = 'Your read password';
  requireSecondaryPassword = true; // applied only if it is shown

  accountExists = false;
  showReferral = false;
  showFacebookGoogleConnect = false;
  showAuthorizationRequest = false;
  posting = false;
  model = null;
  channel = null;
  @tracked syncStarted = false;

  init() {
    super.init(...arguments);

    scheduleOnce('afterRender', this, this.focusInput);
  }

  focusInput() {
    this.element.querySelector('input').focus();
  }

  @action
  nextInputOrSubmit() {
    return Util.nextInputOrSubmit.call(this, window.event);
  }

  @action
  showReferral() {
    this.toggleProperty('referralVisible');
    return false;
  }

  getProps() {
    return this.model.getProperties(
      'id',
      'idSecondary',
      'password',
      'secondaryUsername',
      'secondaryPassword'
    );
  }

  getErrors() {
    let errors = this.get('model.errors');
    errors.clear();

    return errors;
  }

  validate(props, errors) {
    if (this.showID) {
      if (!props.id || props.id.length === 0) {
        errors.set(
          'id',
          this.intl.t('validation.required', { entity: this.intl.t('glossary.field') })
        );
        return false;
      }
    }

    if (this.showSecondaryID && this.requireSecondaryID) {
      if (!props.idSecondary || props.idSecondary.length === 0) {
        errors.set(
          'idSecondary',
          this.intl.t('validation.required', { entity: this.intl.t('glossary.field') })
        );
        return false;
      }
    }

    if (this.showPassword && this.requirePassword) {
      if (!props.password || props.password.length === 0) {
        errors.set(
          'password',
          this.intl.t('validation.required', {
            entity: this.intl.t('glossary.password'),
          })
        );
        return false;
      }
    }

    if (this.showSecondaryUsername && this.requireSecondaryUsername) {
      if (!props.secondaryUsername || props.secondaryUsername.length === 0) {
        errors.set(
          'secondaryUsername',
          this.intl.t('validation.required', { entity: this.intl.t('glossary.field') })
        );
        return false;
      }
    }

    if (this.showSecondaryPassword && this.requireSecondaryPassword) {
      if (!props.secondaryPassword || props.secondaryPassword.length === 0) {
        errors.set(
          'secondaryPassword',
          this.intl.t('validation.required', {
            entity: this.intl.t('glossary.password'),
          })
        );
        return false;
      }
    }
  }

  @action
  async onSubmit() {
    this.syncStarted = true;
    let errors = this.getErrors();
    let props = this.getProps();

    this.validate(props, errors);

    if (this.idType === 'email' && this.showID) {
      this.set('posting', true);

      Util.isEmailInvalid(props.id, this.verifyEmail, this.intl).then(async reason => {
        if (reason) {
          errors.set('id', reason);
          this.set('posting', false);
          this.syncStarted = false;
          return false;
        }

        try {
          await this.action(this.model);
        } finally {
          this.set('posting', false);
          this.syncStarted = false;
        }
      });
    } else {
      try {
        await this.action(this.model);
      } finally {
        this.set('posting', false);
        this.syncStarted = false;
      }
    }

    return false;
  }

  @action
  async submitAuthorizationRequest() {
    let props = this.getProps();
    let errors = this.getErrors();
    this.validate(props, errors);

    this.set('posting', true);

    let url = '/api/accounts/authorization_request';
    let body = {
      id: props.id,
      password: props.password,
      channel: this.get('channel'),
    };

    let response;
    try {
      response = await this.ajax._post(url, body);
    } catch (errors) {
      displayErrors({
        errors: errors,
        modelOrKeywordThis: this.model,
        isProperty: true,
      });
      return;
    } finally {
      this.set('posting', false);
    }

    if (response.status === 'success') {
      this.model.set('errors.success', response.message);
      this.model.set('pulse_token', response.pulseToken);
      this.model.set('device_id', response.deviceId);
    }

    if (
      response.status === 'invalid_credentials' ||
      response.status === 'not_successful'
    ) {
      this.model.setProperties({ password: '' });
      this.model.set('errors.base', response.message);
    }
  }
}
