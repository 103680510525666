import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import DiscountModel from 'appkit/bp-models/discount';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

@classic
@classNames('app-discounts')
export default class AppDiscounts extends Component {
  discountsLoading = true;

  @service intl;

  @alias('customizeDiscounts')
  rawDiscounts;

  @computed('rawDiscounts.[]', 'rawDiscounts.@each.{error,percentage,days}')
  get discounts() {
    return this.rawDiscounts
      .filter(d => d.get('days') !== 0)
      .sort((da, db) => da.get('days') - db.get('days'));
  }

  @computed('rawDiscounts.[]')
  get discountsEmpty() {
    return !this.discountsLoading && this.rawDiscounts.length === 0;
  }

  @computed('discounts.[]')
  get hasVisibleDiscounts() {
    return this.discounts.length > 0;
  }

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    let discounts = this.customizeDiscounts;
    this.set(
      'customizeDiscounts',
      discounts.map(d => {
        let discount = DiscountModel.create(d);
        discount.set('isIncrease', discount.get('percentage') < 0);
        return discount;
      })
    );
    this.set('discountsLoading', false);
  }

  validate() {
    let isValid = true;
    const discounts = this.customizeDiscounts
      .filter(d => d.get('days') !== 0)
      .sort((da, db) => da.get('days') - db.get('days'));
    const getById = discountId => discounts.filter(d => d.id === discountId)[0];
    const errorMessage = this.intl.t(
      'validation.discountRangeError.discountsMustBeSetAtdifferentDays'
    );
    let discountAtDays = {};
    let isIn = false;

    if (discounts.length === 0) return false;

    discounts.forEach(discount => {
      discount.set('error', '');
      if (discountAtDays[discount.days]) {
        getById(discountAtDays[discount.days]).set('error', errorMessage);
        discount.set('error', errorMessage);
        isValid = false;
      } else {
        discountAtDays[discount.days] = discount.id;
      }
      isIn = isIn || discount.direction === 'in';
      if (isIn && discount.direction !== 'in') {
        // This validates if an 'out' discount appears after an 'in' discount - which wouldn't make sense:
        // D1: in discount (after), starts on the 10th day, doesn't end
        // D2: out discount (for the next), starts on 0, ends on 14th
        //
        // day  day  day  day  day  ...
        //  10   11   12   13   14
        // ------------D1--------/ forever /--...
        // ------------D2--------x

        discount.set(
          'error',
          this.intl.t(
            'validation.discountRangeError.forTheNextDiscountsCantsucceedAfterDiscounts'
          )
        );
        isValid = false;
      }
      isValid = isValid && discount.validate();
    });

    return isValid;
  }

  sendDiscounts() {
    if (this.validate()) {
      const discounts = this.customizeDiscounts
        .map(discount => {
          let d = discount.getProperties('id', 'days', 'percentage', 'direction');
          //        d['dirty'] = discount.get('isDirty');
          return d;
        })
        .filter(discount => (discount.id && discount.days !== 0) || !discount.id);
      this.dataIsValid(discounts);
    } else {
      this.dataIsInvalid();
    }
  }

  @action
  addDiscount() {
    this.customizeDiscounts.pushObject(DiscountModel.create({}));
    this.sendDiscounts();
  }

  @action
  removeDiscount(discount) {
    this.customizeDiscounts.removeObject(discount);
    this.sendDiscounts();
  }

  @action
  discountUpdate(attr, val, discount) {
    // When increase option is chosen in dropdown menu this sets percentage from 0 to -1%n
    if (val === 0 && discount.isIncrease && attr === 'percentage') {
      discount.set(attr, -0.01);
    } else {
      discount.set(attr, val);
    }
    this.sendDiscounts();
  }

  @computed('discounts.[]', 'discounts.@each.{days,percentage,direction}')
  get chartDiscountsData() {
    const discounts = this.discounts;
    const definedDays = discounts[discounts.length - 1].days;
    const discountsX = [...Array(definedDays + Math.floor(definedDays / 3)).keys()];

    let discountsY = [];
    let discountIdx = 0;

    discountsX.forEach(day => {
      let x = selectDiscount(day, discounts, discountIdx);
      discountIdx = x.idx;
      if (x.err === -1) {
        discountsY.push(0);
      } else {
        discountsY.push(-1 * Math.floor(x.percentage * 100));
      }
    });

    return [{ data: discountsY }];

    function selectDiscount(currentDay, discounts, idx) {
      let discount = discounts[idx];
      if (currentDay <= discount.get('days') && discount.get('direction') === 'out') {
        return { idx: idx, percentage: discount.get('percentage') };
      }
      if (
        idx != discounts.length - 1 &&
        discounts[idx + 1].get('direction') === 'in' &&
        discounts[idx + 1].get('days') <= currentDay
      ) {
        idx = idx + 1;
        discount = discounts[idx];
      }

      if (currentDay >= discount.get('days') && discount.get('direction') === 'in') {
        return { idx: idx, percentage: discount.get('percentage') };
      }

      if (
        idx == discounts.length - 1 ||
        (discounts[idx + 1].get('direction') === 'in' &&
          discounts[idx + 1].get('days') > currentDay)
      ) {
        return { idx: idx, err: -1 };
      }

      return selectDiscount(currentDay, discounts, idx + 1);
    }
  }

  @computed('discounts.[]', 'discounts.@each.{days,percentage,direction}')
  get chartDiscountsOptions() {
    const discounts = this.discounts;
    const definedDays = discounts[discounts.length - 1].days;
    const discountsX = [...Array(definedDays + Math.floor(definedDays / 3)).keys()];

    return chartOptions(discountsX, this.intl);
  }
}

function chartOptions(discountsX, intl) {
  const out = {
    chart: {
      type: 'areaspline',
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          font: '14px Europa',
        },
        format: '{value}%',
      },
      gridLineColor: 'transparent',
      tickColor: 'transparent',
    },
    xAxis: {
      categories: discountsX,
      title: {
        text: intl.t('common.days'),
      },
      tickColor: 'transparent',
      labels: {
        style: {
          font: '14px Europa',
        },
        format: `{value} ${intl.t('common.days')}`,
        step: Math.floor(discountsX.length / 10),
      },
      gridLineColor: 'rgba(185, 202, 210, 0.2)',
      gridLineWidth: 1,
      tickmarkPlacement: 'on',
      gridLineDashStyle: 'dot',
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        lineColor: 'rgba(37, 129, 137, 0.4)',
        fillColor: 'rgba(67, 182, 192, 0.4)',
        lineWidth: 2,
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      formatter: function () {
        let type = '';
        if (this.y === 0) {
          return 'No change in price';
        } else if (this.y < 0) {
          type = 'Discount ';
        } else {
          type = 'Surcharge';
        }

        return type + ' of ' + Math.abs(this.y) + '% on day ' + this.x;
      },
    },
  };

  return out;
}
