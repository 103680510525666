import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('app-dropdown', 'beyond-legacy')
export default class AppDropdown extends Component {
  // -- Parameters ---------------------------------------------------------------------
  viewOptions = null; // @type {Array}

  selectedView = null; // @type {Object}
  searchEnabled = null; // @type {Boolean}
}
