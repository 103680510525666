import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import $ from 'jquery';
import Component from '@ember/component';
import { Spinner } from 'appkit/lib/spin';

@classic
@classNames('app-spin')
export default class AppSpin extends Component {
  size = 30;
  color = '#888';

  didInsertElement() {
    super.didInsertElement(...arguments);

    this.spinner = new Spinner({
      radius: Math.floor(this.size / 4),
      length: Math.floor(this.size / 3),
      width: 2 + Math.floor(this.size / 10),
      lines: 6 + Math.floor(this.size / 10),
      color: this.color,
      hwaccel: true,
    });

    this.spinner.spin(this.element);

    this.set('top', $(this.element).parents().height() / 2);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.spinner.stop();
  }
}
