/* global Highcharts */
// TODO: merge this with core availability controller
import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';

function chartSeries(name, data, visible, tooltip, axis) {
  let series = {
    name: name,
    data: data,
  };
  if (tooltip === '$') {
    series.tooltip = { valueDecimals: 2, valuePrefix: '$' };
  }
  if (tooltip === '%') {
    series.tooltip = { valueDecimals: 2, valueSuffix: '%' };
  }
  if (!visible) {
    series.visible = false;
  }
  if (axis) {
    series.yAxis = axis;
  }
  return series;
}

@classic
export default class BeyondAvailabilityController extends Controller {
  compareId = null;
  loadingCompareData = false;
  comparePct = [];

  chartOptions = {
    rangeSelector: {
      selected: 5,
    },

    yAxis: [
      {
        title: {
          text: 'Number',
        },
      },
      {
        labels: {
          format: '{value}%',
        },
        max: 100,
        title: {
          text: 'Percent',
        },
        opposite: false,
      },
    ],
    legend: {
      padding: 8,
      enabled: true,
      itemDistance: 10,
    },
    navigator: { baseSeries: 0 },
    plotOptions: {
      series: {
        showInNavigator: true,
      },
    },
  };

  @computed(
    // eslint-disable-next-line ember/use-brace-expansion
    'model.events',
    'model.availabilityNum',
    'model.availabilityPct',
    'model.availabilityEnabledPct',
    'model.available0BedPct',
    'model.available1BedPct',
    'model.available2BedPct',
    'model.available3BedPct',
    'model.available4BedPct',
    'model.availableRoomsPct'
  )
  get chartData() {
    let out = [
      chartSeries('Availability Raw (num)', this.get('model.availabilityNum'), true),
      {
        name: 'Availability Raw (pct)',
        id: 'availability_pct',
        data: this.get('model.availabilityPct'),
        yAxis: 1,
        tooltip: { valueDecimals: 2, valueSuffix: '%' },
      },
      {
        name: 'Events',
        type: 'flags',
        onSeries: 'availability_pct',
        color: Highcharts.getOptions().colors[0], // same as onSeries
        fillColor: Highcharts.getOptions().colors[0],
        data: (this.get('model.events') || []).map(event => ({
          x: event[0],
          title: '*',
          text: event[1],
        })),
      },
      chartSeries(
        'Avail. Enabled (pct)',
        this.get('model.availableEnabledPct'),
        true,
        '%',
        1
      ),
      chartSeries(
        'Avail. 0 Bedroom (pct)',
        this.get('model.available0BedPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Avail. 1 Bedroom (pct)',
        this.get('model.available1BedPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Avail. 2 Bedroom (pct)',
        this.get('model.available2BedPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Avail. 3 Bedroom (pct)',
        this.get('model.available3BedPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Avail. 4+ Bedroom (pct)',
        this.get('model.available4BedPct'),
        false,
        '%',
        1
      ),
    ];

    return out;
  }

  @action
  compareMarket(_id) {
    throw Error('Not implemented');
  }

  @action
  compareCluster(_id) {
    throw Error('Not implemented');
  }
}
