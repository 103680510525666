import { computed } from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';

export default BaseModel.extend({
  amount: 0,
  currency: 0,
  card: null,
  startDate: 0,
  endDate: 0,
  paidAt: 0,
  payments: null,
  chargeable: 0,
  statementItems: null,
  isCurrencySynced: false,

  init() {
    this._super(arguments);
    this.set('payments', this.payments ?? []);
    this.set('statementItems', this.statementItems ?? []);
  },

  hasNegativeAmount: computed('amount', function () {
    return this.amount < 0;
  }),

  classSelected: computed('itemsVisible', function () {
    if (this.itemsVisible) {
      return 'row-selected';
    }
    return '';
  }),
});
