const CURRENCY_DEFINITIONS = {
  AED: {
    label: 'Arab Emirates Dirham',
    symbol: 'ﺩ.ﺇ',
  },
  AFN: {
    label: 'Afghan Afghani',
    symbol: '؋',
  },
  ALL: {
    label: 'Albania Lek',
    symbol: 'Lekë',
  },
  AMD: {
    label: 'Armenian Dram',
    symbol: '֏',
  },
  ANG: {
    label: 'Antillean Guilder',
    symbol: 'NAf.',
  },
  AOA: {
    label: 'Angolan Kwanza',
    symbol: 'Kz',
  },
  ARS: {
    label: 'Argentine Peso',
    symbol: 'ARS$',
  },
  AUD: {
    label: 'Australian Dollar',
    symbol: 'A$',
  },
  AWG: {
    label: 'Aruban Florin',
    symbol: 'Afl.',
  },
  AZN: {
    label: 'Azerbaijani Manat',
    symbol: '₼',
  },
  BAM: {
    label: 'Bosnia-Herzegovina Convertible Mark',
    symbol: 'КМ',
  },
  BBD: {
    label: 'Barbadian Dollar',
    symbol: 'BDS$',
  },
  BDT: {
    label: 'Bangladeshi Taka',
    symbol: '৳',
  },
  BGN: {
    label: 'Bulgarian lev',
    symbol: 'Лв',
  },
  BHD: {
    label: 'Bahraini Dinar',
    symbol: 'د.ب.‏',
  },
  BIF: {
    label: 'Burundian Franc',
    symbol: 'FBu',
  },
  BMD: {
    label: 'Bermudan Dollar',
    symbol: '$',
  },
  BND: {
    label: 'Brunei Dollar',
    symbol: '$',
  },
  BOB: {
    label: 'Bolivian Boliviano',
    symbol: 'Bs',
  },
  BOV: {
    label: 'Bolivian Mvdol',
    symbol: 'BOV',
  },
  BRL: {
    label: 'Brazilian Real',
    symbol: 'R$',
  },
  BSD: {
    label: 'Bahamian Dollar',
    symbol: '$',
  },
  BTN: {
    label: 'Bhutan currency',
    symbol: 'Nu.',
  },
  BWP: {
    label: 'Botswanan Pula',
    symbol: 'P',
  },
  BYN: {
    label: 'Belarusian Ruble',
    symbol: 'Br',
  },
  BZD: {
    label: 'Belize Dollar',
    symbol: '$',
  },
  CAD: {
    label: 'Canadian Dollar',
    symbol: 'C$',
  },
  CDF: {
    label: 'Congolese franc',
    symbol: 'FC',
  },
  CHE: {
    label: 'WIR Euro ',
    symbol: 'CHE',
  },
  CHF: {
    label: 'Swiss Franc',
    symbol: 'CHF',
  },
  CHW: {
    label: 'WIR Franc',
    symbol: 'CHW',
  },
  CLF: {
    label: 'Chilean Unit of Account',
    symbol: 'CLF',
  },
  CLP: {
    label: 'Chilean Peso',
    symbol: 'CLP$',
  },
  CNH: {
    label: 'Chinese Renminbi',
    symbol: 'CNH',
  },
  CNY: {
    label: 'Chinese Renminbi Yuan',
    symbol: '¥',
  },
  COP: {
    label: 'Colombian Peso',
    symbol: 'COL$',
  },
  COU: {
    label: 'Colombian unidad de valor real',
    symbol: 'COU',
  },
  CRC: {
    label: 'Costa Rican Colón',
    symbol: '₡',
  },
  CUC: {
    label: 'convertible peso',
    symbol: 'CUC',
  },
  CUP: {
    label: 'Cuban Peso',
    symbol: '$',
  },
  CVE: {
    label: 'Cape Verdean Escudo',
    symbol: 'CVE',
  },
  CZK: {
    label: 'Czech Koruna',
    symbol: 'Kč',
  },
  DJF: {
    label: 'Djiboutian Franc',
    symbol: 'Fdj',
  },
  DKK: {
    label: 'Danish Krone',
    symbol: 'kr',
  },
  DOP: {
    label: 'Dominican Peso',
    symbol: 'RD$',
  },
  DZD: {
    label: 'Algerian Dinar',
    symbol: 'د.ج.‏',
  },
  EGP: {
    label: 'Egyptian Pound',
    symbol: 'ج.م.',
  },
  ERN: {
    label: 'Eritrean Nakfa',
    symbol: 'Nfk',
  },
  ETB: {
    label: 'Ethiopian Birr',
    symbol: 'ብር',
  },
  EUR: {
    label: 'Euro',
    symbol: '€',
  },
  FJD: {
    label: 'Fijian Dollar',
    symbol: '$',
  },
  FKP: {
    label: 'Falkland Islands Pound',
    symbol: '£',
  },
  GBP: {
    label: 'British Pound',
    symbol: '£',
  },
  GEL: {
    label: 'Georgian Lari',
    symbol: 'ლ',
  },
  GHS: {
    label: 'Georgian Lari',
    symbol: 'GH₵',
  },
  GIP: {
    label: 'Gibraltar Pound',
    symbol: '£',
  },
  GMD: {
    label: 'Gambian dalasi',
    symbol: 'D',
  },
  GNF: {
    label: 'Guinean Franc',
    symbol: 'FG',
  },
  GTQ: {
    label: 'Guatemalan Quetzal',
    symbol: 'Q',
  },
  GYD: {
    label: 'Guyanaese Dollar',
    symbol: '$',
  },
  HKD: {
    label: 'Hong Kong Dollar',
    symbol: 'HK$',
  },
  HNL: {
    label: 'Honduran Lempira',
    symbol: 'L',
  },
  HRK: {
    label: 'Croatian Kuna',
    symbol: 'kn',
  },
  HTG: {
    label: 'Haitian Gourde',
    symbol: 'G',
  },
  HUF: {
    label: 'Hungarian Forint',
    symbol: 'Ft',
  },
  IDR: {
    label: 'Indonesian Rupiah',
    symbol: 'Rp',
  },
  ILS: {
    label: 'Israeli New Sheqel',
    symbol: '₪',
  },
  INR: {
    label: 'Indian Rupee',
    symbol: '₹',
  },
  IQD: {
    label: 'Iraqi Dinar',
    symbol: 'د.ع.‏',
  },
  IRR: {
    label: 'Iranian Rial',
    symbol: 'IRR',
  },
  ISK: {
    label: 'Icelandic Króna',
    symbol: 'Íkr',
  },
  JMD: {
    label: 'Jamaican Dollar',
    symbol: '$',
  },
  JOD: {
    label: 'Jordanian Dinar',
    symbol: 'د.أ.‏',
  },
  JPY: {
    label: 'Japanese Yen',
    symbol: '¥',
  },
  KES: {
    label: 'Kenyan Shilling',
    symbol: 'Ksh',
  },
  KGS: {
    label: 'Kyrgystani Som',
    symbol: 'сом',
  },
  KHR: {
    label: 'Cambodian riel',
    symbol: '៛',
  },
  KMF: {
    label: 'Comorian franc',
    symbol: 'CF',
  },
  KPW: {
    label: 'North Korean Won',
    symbol: 'KPW',
  },
  KRW: {
    label: 'South Korean Won',
    symbol: '₩',
  },
  KWD: {
    label: 'Kuwaiti Dinar',
    symbol: 'د.ك.‏',
  },
  KYD: {
    label: 'Cayman Islands Dollar',
    symbol: 'KYD',
  },
  KZT: {
    label: 'Kazakhstani Tenge',
    symbol: '₸',
  },
  LAK: {
    label: 'Lao kip',
    symbol: '₭',
  },
  LBP: {
    label: 'Lebanese pound',
    symbol: 'ل.ل.‏',
  },
  LKR: {
    label: 'Sri Lankan Rupee',
    symbol: '₨',
  },
  LRD: {
    label: 'Liberian Dollar',
    symbol: '$',
  },
  LSL: {
    label: 'Lesotho loti',
    symbol: 'LSL',
  },
  LYD: {
    label: 'Libyan Dinar',
    symbol: 'د.ل.‏',
  },
  MAD: {
    label: 'Moroccan Dirham',
    symbol: 'د.م.',
  },
  MDL: {
    label: 'Moldovan Leu',
    symbol: 'L',
  },
  MGA: {
    label: 'Malagasy Ariary',
    symbol: 'AR',
  },
  MKD: {
    label: 'Macedonian denar',
    symbol: 'ден',
  },
  MMK: {
    label: 'Myanmar Kyat',
    symbol: 'K',
  },
  MNT: {
    label: 'Mongolian Tögrög',
    symbol: '₮',
  },
  MOP: {
    label: 'Macanese Pataca',
    symbol: 'MOP$',
  },
  MRO: {
    label: 'Mauritanian Ouguiya',
    symbol: 'أ.م.‏',
  },
  MUR: {
    label: 'Mauritian Rupee',
    symbol: 'Rs',
  },
  MWK: {
    label: 'Malawian Kwacha',
    symbol: 'MK',
  },
  MXN: {
    label: 'Mexican Peso',
    symbol: 'Mex$',
    currencyDisplay: 'symbol',
  },
  MXV: {
    label: 'Mexican unidad de inversión',
    symbol: 'MXV',
  },
  MYR: {
    label: 'Malaysian Ringgit',
    symbol: 'RM',
  },
  MZN: {
    label: 'Mozambican metical',
    symbol: 'MTn',
  },
  NAD: {
    label: 'Namibian dollar',
    symbol: '$',
  },
  NGN: {
    label: 'Nigerian Naira',
    symbol: 'Nkr',
  },
  NIO: {
    label: 'Nicaraguan Córdoba',
    symbol: 'C$',
  },
  NOK: {
    label: 'Norwegian Krone',
    symbol: 'Nkr',
  },
  NPR: {
    label: 'Nepalese Rupee',
    symbol: 'नेरू',
  },
  NZD: {
    label: 'New Zealand Dollar',
    symbol: 'NZ$',
  },
  OMR: {
    label: 'Omani Rial',
    symbol: 'ر.ع.‏',
  },
  PAB: {
    label: 'Panamanian Balboa',
    symbol: 'B/.',
  },
  PEN: {
    label: 'Peruvian Sol',
    symbol: 'S/',
  },
  PGK: {
    label: 'Papua New Guinean Kina',
    symbol: 'K',
  },
  PHP: {
    label: 'Philippine Peso',
    symbol: '₱',
  },
  PKR: {
    label: 'Pakistani Rupee',
    symbol: 'PKR',
  },
  PLN: {
    label: 'Polish Złoty',
    symbol: 'zł',
  },
  PYG: {
    label: 'Paraguayan Guarani',
    symbol: 'Gs.',
  },
  QAR: {
    label: 'Qatari Rial',
    symbol: 'ر.ق.',
  },
  RON: {
    label: 'Romanian Leu',
    symbol: 'RON',
  },
  RSD: {
    label: 'Serbian Dinar',
    symbol: 'RSD',
  },
  RUB: {
    label: 'Russian Ruble',
    symbol: '₽',
  },
  RWF: {
    label: 'Rwandan franc',
    symbol: 'RWF',
  },
  SAR: {
    label: 'Saudi riyal',
    symbol: 'SAR',
  },
  SBD: {
    label: 'Solomon Islands Dollar',
    symbol: '$',
  },
  SCR: {
    label: 'Seychellois Rupee',
    symbol: 'SR',
  },
  SDG: {
    label: 'Sudanese pound',
    symbol: 'ج.س.',
  },
  SEK: {
    label: 'Swedish Krona',
    symbol: 'Skr',
  },
  SGD: {
    label: 'Singapore Dollar',
    symbol: 'S$',
  },
  SHP: {
    label: 'Saint Helena Pound',
    symbol: '£',
  },
  SLL: {
    label: ' Sierra Leonean Leone ',
    symbol: 'Le',
  },
  SOS: {
    label: 'Somali Shilling',
    symbol: 'S',
  },
  SRD: {
    label: 'Surinamese Dollar',
    symbol: '$',
  },
  SSP: {
    label: 'South Sudanese pound',
    symbol: '£',
  },
  STN: {
    label: 'São Tomé & Príncipe Dobra',
    symbol: 'STN',
  },
  SYP: {
    label: 'Syrian pound',
    symbol: 'ل.س.‏',
  },
  SZL: {
    label: 'Swazi Lilangeni',
    symbol: 'E',
  },
  THB: {
    label: 'Thai Baht',
    symbol: '฿',
  },
  TJS: {
    label: 'Tajikistani Somoni',
    symbol: 'сом.',
  },
  TND: {
    label: 'Tunisian dinar',
    symbol: 'د.ت.',
  },
  TOP: {
    label: 'Tongan paʻanga',
    symbol: 'T$',
  },
  TRY: {
    label: 'Turkish Lira',
    symbol: '₺',
  },
  TTD: {
    label: 'Trinidad & Tobago Dollar',
    symbol: '$',
  },
  TWD: {
    label: 'New Taiwan Dollar',
    symbol: 'NT$',
  },
  TZS: {
    label: 'Tanzanian Shilling',
    symbol: 'TSh',
  },
  UAH: {
    label: 'Ukrainian Hryvnia',
    symbol: '₴',
  },
  UGX: {
    label: 'Ugandan Shilling',
    symbol: 'USh',
  },
  USD: {
    label: 'United States Dollar',
    symbol: '$',
  },
  USN: {
    label: 'NEW US dollar',
    symbol: 'USN',
  },
  UYI: {
    label: 'Uruguay Peso en Unidades Indexadas',
    symbol: 'UYI',
  },
  UYU: {
    label: 'Uruguayan peso',
    symbol: '$U',
  },
  UZS: {
    label: 'Uruguayan peso',
    symbol: 'сўм',
  },
  VEF: {
    label: 'Uruguayan peso',
    symbol: 'Bs.',
  },
  VND: {
    label: 'Vietnamese dong',
    symbol: '₫',
  },
  VUV: {
    label: 'Vanuatu Vatu',
    symbol: 'VT',
  },
  WST: {
    label: 'Samoan Tala',
    symbol: 'WS$',
  },
  XAF: {
    label: 'Central African CFA franc',
    symbol: 'FCFA',
  },
  XCD: {
    label: 'East Caribbean Dollar',
    symbol: '$',
  },
  XOF: {
    label: 'West African CFA franc',
    symbol: 'CFA',
  },
  XPF: {
    label: 'CFP franc',
    symbol: '₣',
  },
  YER: {
    label: 'Yemeni Rial',
    symbol: 'ر.ي.',
  },
  ZAR: {
    label: 'South African Rand',
    symbol: 'R',
  },
  ZMW: {
    label: 'Zambian Kwacha',
    symbol: 'K',
  },
};

const BILLING_CURRENCIES = [
  'AED',
  'ARS',
  'AUD',
  'BAM',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'GEL',
  'GTQ',
  'HKD',
  'HNL',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JMD',
  'JPY',
  'KRW',
  'KYD',
  'LKR',
  'MAD',
  'MWK',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PEN',
  'PHP',
  'PLN',
  'RON',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'UAH',
  'USD',
  'UYU',
  'VND',
  'XOF',
  'XPF',
  'ZAR',
].sort();

const PAYPAL_BILLING_CURRENCIES = [
  'AED',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PLN',
  'RUB',
  'USD',
].sort();

const DEFAULT_CURRENCY = 'USD';

export class CurrencyUtil {
  static locale = 'en';

  static setLocale(locale) {
    CurrencyUtil.locale = locale;
  }

  static getBillingCurrencies() {
    return BILLING_CURRENCIES;
  }

  static getPaypalBillingCurrencies() {
    return PAYPAL_BILLING_CURRENCIES;
  }

  static getSymbol(currency) {
    currency = currency || DEFAULT_CURRENCY;
    const parts = new Intl.NumberFormat(CurrencyUtil.locale, {
      style: 'currency',
      currency,
      currencyDisplay:
        CURRENCY_DEFINITIONS[currency]?.currencyDisplay ?? 'narrowSymbol',
    }).formatToParts(1);

    return {
      symbol: parts.find(p => p.type === 'currency').value,
      isPrefixed:
        parts.findIndex(p => p.type === 'currency') <
        parts.findIndex(p => p.type === 'integer'),
    };
  }

  static getLabel(currency) {
    currency = currency || DEFAULT_CURRENCY;
    return CURRENCY_DEFINITIONS[currency].label;
  }

  static getFlag(currency) {
    currency = currency || DEFAULT_CURRENCY;
    let currency2Letters = currency.toUpperCase().split('').splice(0, 2);
    if (currency === 'XPF') {
      currency2Letters = 'PF'.split('');
    }

    // https://dev.to/jorik/country-code-to-flag-emoji-a21 for reference
    const codePoints = currency2Letters.map(char => 127397 + char.charCodeAt());

    return String.fromCodePoint(...codePoints);
  }

  // Options:
  //  - decimalPlaces: number of digits after the decimal point. Default: 0
  //  - currency: the currency code: Default: DEFAULT_CURRENCY
  //  - signed: boolean. Default: false.
  //  - abs: boolean. Default: false. Ignored if signed is true.
  static format(amount, options = {}) {
    const currency = options.currency || DEFAULT_CURRENCY;
    const formatOptions = {
      style: 'currency',
      currency: currency,
      currencyDisplay:
        CURRENCY_DEFINITIONS[currency]?.currencyDisplay ?? 'narrowSymbol',
      signDisplay: options.signed ? 'exceptZero' : 'auto',
      minimumFractionDigits: options.decimalPlaces ?? 0,
      maximumFractionDigits: options.decimalPlaces ?? 0,
      notation: options.compact ? 'compact' : 'standard',
    };

    try {
      return new Intl.NumberFormat(CurrencyUtil.locale, formatOptions).format(amount);
    } catch {
      /**
       * Per https://github.com/google/site-kit-wp/issues/3255 there have been issues with some versions of Safari
       * on some operating systems throwing issues with some parameters in the formatOptions.
       *
       * If an error is thrown, we remove some troublesome params from the formatOptions object and fallback to no formatting.
       *
       * This allows us to degrade somewhat gracefully without breaking the dashboard for users of unaffected browsers.
       */
    }

    // Taken from https://github.com/google/site-kit-wp/pull/3620/files
    // Remove these key/values from formatOptions.
    const unstableFormatOptionValues = {
      currencyDisplay: 'narrow',
      currencySign: 'accounting',
      style: 'unit',
    };

    // Remove these keys from formatOptions irrespective of value.
    const unstableFormatOptions = ['signDisplay', 'compactDisplay'];

    const reducedFormatOptions = {};

    for (const [key, value] of Object.entries(formatOptions)) {
      if (
        unstableFormatOptionValues[key] &&
        value === unstableFormatOptionValues[key]
      ) {
        continue;
      }
      if (unstableFormatOptions.includes(key)) {
        continue;
      }
      reducedFormatOptions[key] = value;
    }

    try {
      return new Intl.NumberFormat(CurrencyUtil.locale, reducedFormatOptions).format(
        amount
      );
    } catch {
      return new Intl.NumberFormat(CurrencyUtil.locale).format(amount);
    }
  }
}
