import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames, classNameBindings } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import Component from '@ember/component';
import Util from 'appkit/lib/util';
import { run } from '@ember/runloop';
import { displayErrors } from 'appkit/lib/display_errors';

@classic
@classNameBindings('supportVisible.value::hidden', 'minimized')
@classNames('app-support')
export default class AppSupport extends Component {
  @service
  supportVisible;

  minimized = true;
  saving = false;
  errors = '';

  // search -> email -> posted
  state = 'search';

  name = '';
  email = '';
  message = '';

  // Hide the email form until they click at least one question.
  hasClicked = false;

  loadingArticles = false;
  articles = [];

  @action
  submitForm() {
    let params = this.getProperties('name', 'email', 'message');
    params.referer = document.referrer;
    this.set('saving', true);

    Util.isEmailInvalid(params.email).then(reason => {
      if (reason) {
        this.set('errors', reason);
        this.set('saving', false);
        return false;
      }

      this.ajax
        ._post('/api/support', params)
        .then(() => {
          this.set('saving', false);
          this.set('posted', true);
          setTimeout(() => {
            this.set('minimized', true);
          }, 2000);
        })
        .catch(errors => {
          this.set('saving', false);
          displayErrors({
            errors: errors,
            modelOrKeywordThis: this,
            isProperty: true,
            propertyName: 'errors',
          });
        });
    });
  }

  @action
  searchChanged(searchTerm) {
    let timer = this.timer;
    timer && clearTimeout(timer);

    this.set('loadingArticles', true);

    this.set(
      'timer',
      setTimeout(() => {
        let key = '7pSOIqANY6cASsM08J14TFhfB1DNyzn1HjadgKUJ';
        // let searchTerm =
        let url = `https://api.helpdocs.io/v1/search?query=${encodeURIComponent(
          searchTerm
        )}&key=${key}`;
        $.ajax({ url: url }).then(data => {
          run(() => {
            this.set('loadingArticles', false);
            this.set('articles', data.articles);
          });
        });
      }, 200)
    );
  }

  @action
  showEmailForm() {
    this.set('state', 'email');
  }

  @action
  toggleMinimized() {
    this.set('minimized', !this.minimized);
    return false;
  }

  @action
  questionClick() {
    this.set('hasClicked', true);
  }

  @action
  close() {
    this.set('supportVisible.value', false);
    return false;
  }
}
