import moment from 'moment';
import * as ReservationUtils from 'appkit/lib/relay/utils/rel-reservation-utils';
import * as ReservationMetaAdapters from 'appkit/lib/relay/adapters/reservations/rel-reservations-metadata-adapter';
import { getReservationHistoryDates } from 'appkit/lib/relay/adapters/reservations/rel-reservations-timestamps-adapter';
import { errorAdapter } from 'appkit/lib/relay/adapters/reservations/rel-reservations-error-adapter';

/**
 * Adapts the relay/reservations endpoint response and adds some metadata
 * about listing and relative dates, to be consumed in the view
 */
export const ReservationsIntegrationsAdapters = {
  genericDC: baseDCReservationAdapter,
  genericPMS: basePMSReservationAdapter,
};

export const ReservationStatus = {
  accepted: 'Booking',
  unconfirmed: 'Inquiry',
  canceled: 'Cancelled',
};

/**
 * Contains all shared properties by PMS and DC reservations
 **/
function commonReservationDataAdapter(reservation, listing) {
  try {
    const r = reservation;
    return {
      ...r.attributes,
      id: r.id,
      status: ReservationStatus[r.attributes.status],
      channelListingId: r.relationships.channelListing.data.id,
      stayLength: getStayLength(r.attributes),
      relativeConfirmationDate: moment(r.attributes.bookedAt).fromNow(),
      relativeCreationDate: moment(r.attributes.createdAt).fromNow(),
      metadata: [
        ...ReservationMetaAdapters.getReservationSourceMetadata(reservation),
        ...ReservationMetaAdapters.getBasePriceMetadata(reservation, listing),
        ...ReservationMetaAdapters.getStayLengthMetadata(reservation, listing),
      ],
      relationships: r.relationships,
    };
  } catch (e) {
    throw new Error('Error adapting common data from /reservation to the view models');
  }
}

/**
 * Generic adapter for DC reservations response
 **/
export function baseDCReservationAdapter(dcReservationsResponse, listing, dcPushLog) {
  try {
    return dcReservationsResponse.data.map(dcReservation => {
      const reservation = commonReservationDataAdapter(dcReservation, listing);
      const syncStatus = reservationSyncStatusAdapter(dcReservation, dcPushLog);

      const doubleBookingMeta = ReservationMetaAdapters.getDCDoubleBookingMetadata(
        dcReservation,
        listing,
        dcPushLog
      );
      const errorLog = ReservationUtils.getReservationErrorLog(
        dcReservation.id,
        dcPushLog
      ).map(err => errorAdapter(dcReservation.attributes.channel, err));

      const reservationStory = getReservationHistoryDates(
        dcReservation.attributes,
        errorLog,
        syncStatus
      );
      return {
        ...reservation,
        syncStatus,
        reservationStory,
        metadata: [...doubleBookingMeta, ...reservation.metadata],
      };
    });
  } catch (e) {
    console.error(e);
    throw new Error('Error adapting /reservation response to the view models');
  }
}

/**
 * Generic adapter for PMS reservations response
 **/
export function basePMSReservationAdapter(pmsReservationsResponse, listing) {
  try {
    return pmsReservationsResponse.data.map(pmsReservation => {
      return commonReservationDataAdapter(pmsReservation, listing);
    });
  } catch (e) {
    throw new Error('Error adapting /reservation response to the view models');
  }
}

function getStayLength(reservation) {
  if (!reservation) return null;
  return moment(reservation.checkoutDate).diff(reservation.checkinDate, 'days');
}

/**
 * Checks the reservation push log to see if a DC reservation has been synced with PMS
 * Returns a color class to represent the outcome in the reservation accordion
 */
function reservationSyncStatusAdapter(reservation, reservationsPushLog) {
  try {
    const lastUpdateId = reservation.relationships.lastPushReservationOutcome.data.id;
    const lastUpdateLog = reservationsPushLog.find(log => log.id === lastUpdateId);
    const { status } = lastUpdateLog.attributes;

    if (status === 'error') {
      return {
        id: 'error',
        type: 'error',
        color: 'border-l-[#e26565]',
        text: '!',
        outcomeId: lastUpdateLog.id,
      };
    }
    // status = 'ok'
    return {
      id: 'synced',
      type: 'synced',
      color: 'border-l-brand-400',
      text: moment(status.timeStamp).format('MM/DD/YYYY [at] LT'),
    };
  } catch (e) {
    return {
      id: 'pending',
      type: 'pending',
      color: 'border-l-gray-400',
      text: 'Not synced yet',
    };
  }
}
