import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import moment from 'moment';
import { displayErrors } from 'appkit/lib/display_errors';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';

export default class DashboardGridBulkController extends Controller {
  @controller('dashboard.grid') gridController;
  @service alert;
  @service featureFlag;
  @service intl;

  @tracked saving = false;

  @tracked minStayValue = null;
  @tracked minStayType = null;
  minStaySettings = [
    { action: 'set', term: 'Set Minimum Stay to', field: 'value' },
    { action: 'clear', term: 'Clear Seasonal Min. Stay', field: null },
  ];

  @tracked isDynamicOverride = false;
  @tracked overrideValue = null;
  @tracked overrideType = null;
  overrideSettings = [
    { action: 'increase', term: 'Increase price by', field: 'percent' },
    { action: 'decrease', term: 'Decrease price by', field: 'percent' },
    { action: 'set', term: 'Set price to', field: 'currency' },
    { action: 'clear', term: 'Clear overrides', field: null },
  ];

  @tracked minPriceValue = null;
  @tracked minPriceType = null;
  minPriceSettings = [
    { action: 'increase', term: 'Inc. min price by', field: 'percent' },
    { action: 'decrease', term: 'Dec. min price by', field: 'percent' },
    { action: 'set', term: 'Set min price to', field: 'currency' },
    { action: 'clear', term: 'Clear min prices', field: null },
  ];

  @computed('overrideType')
  get showDynamicOverridesSelector() {
    if (
      this.overrideType &&
      (this.overrideType.action === 'increase' ||
        this.overrideType.action === 'decrease')
    ) {
      return true;
    }

    return false;
  }

  // -- Actions ------------------------------------------------------------------------
  @action dynamicOverridesChange(value) {
    this.isDynamicOverride = value;
  }

  @action cancelBulk() {
    this.transitionToRoute('dashboard.grid');
  }

  @action
  async bulkSave() {
    const postData = {
      listings: this.gridController.gridSelection,
      dateAndTime: moment().format(),
      actions: {},
    };

    if (this.minStayType) {
      let value;
      if (this.minStayType.action === 'cancel') {
        value = 0;
      } else {
        value = Number(this.minStayValue);
      }

      postData.actions = { minStay: value, ...postData.actions };
    }

    if (this.minPriceType) {
      let fieldName;
      let value;
      if (this.minPriceType.action === 'set') {
        fieldName = 'minPrice';
        value = Number(this.minPriceValue);
      } else if (this.minPriceType.action === 'clear') {
        fieldName = 'minPrice';
        value = 0;
      } else if (this.minPriceType.action === 'increase') {
        fieldName = 'minPricePercentage';
        value = 100 + Number(this.minPriceValue);
      } else if (this.minPriceType.action === 'decrease') {
        fieldName = 'minPricePercentage';
        value = 100 - Number(this.minPriceValue);
      }

      postData.actions = { [fieldName]: value, ...postData.actions };
    }

    if (this.overrideType) {
      let fieldName;
      let value;

      if (this.overrideType.action === 'set') {
        fieldName = 'priceOverride';
        value = Number(this.overrideValue);
      } else if (this.overrideType.action === 'clear') {
        fieldName = 'priceOverridePercentage';
        value = 100;
        postData.actions = { dynamicPercentageUserOverride: 0, ...postData.actions };
      } else if (this.overrideType.action === 'increase') {
        fieldName = this.isDynamicOverride
          ? 'dynamicPercentageUserOverride'
          : 'priceOverridePercentage';
        value = this.isDynamicOverride
          ? Number(this.overrideValue) / 100
          : 100 + Number(this.overrideValue);
      } else if (this.overrideType.action === 'decrease') {
        fieldName = this.isDynamicOverride
          ? 'dynamicPercentageUserOverride'
          : 'priceOverridePercentage';
        value = this.isDynamicOverride
          ? Number((this.overrideValue / 100) * -1)
          : 100 - Number(this.overrideValue);
      }
      postData.actions = { [fieldName]: value, ...postData.actions };
    }

    try {
      this.saving = true;
      const url = '/api/grid/customize';
      await this.ajax._post(url, postData);
    } catch (errors) {
      displayErrors({ errors: errors, modelOrKeywordThis: this, alert: this.alert });
      return;
    } finally {
      this.saving = false;
    }
    this.alert.success(this.intl.t('pricing.gridView.updatingCalendar'));

    let startDate = this.gridController.gridStartDate;
    let endDate = this.gridController.gridEndDate;

    this.gridController.updateDataInGrid(
      this.gridController.selectedListingIds,
      startDate,
      endDate
    );

    // TODO: update this inline and avoid a refresh! We need to get modeled prices into
    // the front end first though.
    getOwner(this).lookup('route:dashboard.grid').refresh();
    this.transitionToRoute('dashboard.grid');
  }
}
