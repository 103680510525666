import Service from '@ember/service';

export default class CurrentUserService extends Service {
  get userId() {
    return this.currentUser.userId;
  }

  get token() {
    return this.currentUser.token;
  }

  get username() {
    return this.currentUser.username;
  }
  get email() {
    return this.currentUser.username;
  }

  get ldEmail() {
    return this.currentUser.ldEmail;
  }

  get isStaff() {
    return this.currentUser.isStaff;
  }

  get isImpersonating() {
    return this.currentUser.isImpersonating;
  }

  get isSuper() {
    return this.currentUser.isSuper;
  }

  get isRelayEnabled() {
    return this.currentUser.isRelayEnabled;
  }

  get firstListingMergedAt() {
    return this.currentUser.firstListingMergedAt;
  }

  get globalPermission() {
    return this.currentUser.globalPermission;
  }

  get hasUnseenBookingReview() {
    return this.currentUser.hasUnseenBookingReview;
  }

  get hasUnseenAutoAcceptedBookingReview() {
    return this.currentUser.hasUnseenAutoAcceptedBookingReview;
  }

  get ldKey() {
    return this.currentUser.ldKey;
  }

  get ldSecureHash() {
    return this.currentUser.ldSecureHash;
  }

  get isBookingReviewSubmitterEnabled() {
    return this.currentUser.isBookingReviewSubmitterEnabled;
  }

  clear() {
    this.currentUser.clear();
  }
}
