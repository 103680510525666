import { CHANNEL } from '../channel_configuration';
const HIDE_UPSELL_BANNER = 'hideSignalUpsell2';
const HIDE_UPSELL_FORM = 'hideSignalForm';

export function getPMSList() {
  return [
    CHANNEL.BAREFOOT,
    CHANNEL.CIIRUS,
    CHANNEL.ESCAPIA,
    CHANNEL.STREAMLINE,
    CHANNEL.TRACK,
    CHANNEL.HOMEAWAY_V12,
  ];
}

function getHiddenUsers(key) {
  const localStorageValue = window.localStorage.getItem(key) ?? '';
  return localStorageValue.split(',').filter(id => id);
}

export function hideBanner(userId) {
  const currentUsers = [
    ...new Set(getHiddenUsers(HIDE_UPSELL_BANNER).concat(String(userId))),
  ];
  window.localStorage.setItem(HIDE_UPSELL_BANNER, currentUsers.join(','));
}

export function hideForm(userId) {
  const currentUsers = [
    ...new Set(getHiddenUsers(HIDE_UPSELL_FORM).concat(String(userId))),
  ];
  window.localStorage.setItem(HIDE_UPSELL_FORM, currentUsers.join(','));
}

export function showBanner(_accounts, user) {
  //TODO: Add specific account based rules here
  return !isHiddenForUser(user.id, HIDE_UPSELL_BANNER);
}

export function isHiddenForUser(userId, key) {
  return getHiddenUsers(key).find(u => u === String(userId));
}

export function showForm(userId) {
  return !isHiddenForUser(userId, HIDE_UPSELL_FORM);
}
