/**
 * Joins Listings in a group that can have shared configuration.
 *  (taxes, markups, syncing of pricing/availability...)
 */
export async function MergeListingsUC(listings, settings, ajax) {
  const listingIds = listings.map(listing => listing.id);

  const payload = {
    listingIds: [listingIds],
    ...settings,
  };

  try {
    const { message } = await ajax._post('/api/listings/merge', payload);
    return message;
  } catch (errors) {
    let err = errors[0];
    throw new Error(err.message);
  }
}
