import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';

import Component from '@ember/component';
@classic
@tagName('')
export default class RadioButton extends Component {
  // value - passed in, required, the value for this radio button
  // groupValue - passed in, required, the currently selected value
  //
  // optionally passed in:
  // disabled - boolean
  // required - boolean
  // name - string
  // radioClass - string
  // radioId - string

  @(computed('groupValue', 'value').readOnly())
  get checked() {
    return this.groupValue === this.value;
  }

  @action
  onChanged(newValue) {
    this.changed?.(newValue);
    return false;
  }
}
