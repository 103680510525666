export function mapSavedFilterToState(savedFilter, listings, state) {
  const {
    bedroomsMin,
    bedroomsMax,
    selectedBedrooms,
    selectedAccounts = [],
    selectedMarketNames = [],
    selectedClusterNames = [],
    listingsToInclude = [],
    listingsToExclude = [],
    listingsToCompare = [],
    bookedAtStartDate,
    bookedAtEndDate,
    selectedOriginForRadius = null,
    distanceMin = null,
    distanceMax = null,
    basePriceMin = 0,
    basePriceMax = null,
    healthScoreMin = null,
    healthScoreMax = null,
    hideUnavailable = false,
    showEnabled = true,
    showDisabled = true,
    monthlyPricePostingEnabled = true,
    monthlyPricePostingDisabled = true,
    selectedCategory1Names = [],
    selectedCategory2Names = [],
    filterByNeedReview = null,
  } = savedFilter.content;

  state.distanceMin = distanceMin;
  state.distanceMax = distanceMax;
  state.basePriceMin = basePriceMin;
  state.basePriceMax = basePriceMax;
  state.healthScoreMin = healthScoreMin;
  state.healthScoreMax = healthScoreMax;
  state.hideUnavailable = hideUnavailable;
  state.showEnabled = showEnabled;
  state.showDisabled = showDisabled;
  state.monthlyPricePostingEnabled = monthlyPricePostingEnabled;
  state.monthlyPricePostingDisabled = monthlyPricePostingDisabled;
  state.selectedCategory1Names = selectedCategory1Names;
  state.selectedCategory2Names = selectedCategory2Names;
  state.selectedAccountsByName = selectedAccounts;
  state.selectedMarketNames = selectedMarketNames;
  state.selectedClusterNames = selectedClusterNames;
  state.bookedAtStartDate = bookedAtStartDate;
  state.bookedAtEndDate = bookedAtEndDate;
  state.filterByNeedReview = filterByNeedReview;

  state.selectedOriginForRadius = listings.find(l => l.id === selectedOriginForRadius);
  state.selectedListingsToInclude = listingsToInclude
    .map(id => listings.find(l => l.id === id))
    .filter(id => id);
  state.selectedListingsToExclude = listingsToExclude
    .map(id => listings.find(l => l.id === id))
    .filter(id => id);
  state.selectedListingsToCompare = listingsToCompare
    .map(id => listings.find(l => l.id === id))
    .filter(id => id);

  let bedrooms;

  if (selectedBedrooms == null && bedroomsMin != null && bedroomsMax != null) {
    // Current bedroom filters operate with ranges, we need to convert it to individual options
    bedrooms = Array.from({ length: bedroomsMax - bedroomsMin + 1 }).map(
      (_, index) => index + bedroomsMin
    );
  } else {
    bedrooms = selectedBedrooms ?? [];
  }
  state.selectedBedrooms = bedrooms;
}

export function mapStateToSavedFilter(state, savedFilter) {
  savedFilter.content = {
    selectedAccounts: state.selectedAccountsByName,
    selectedMarketNames: state.selectedMarketNames,
    selectedClusterNames: state.selectedClusterNames,
    selectedBedrooms: state.selectedBedrooms,
    selectedOriginForRadius: state.selectedOriginForRadius,
    distanceMin: state.distanceMin,
    distanceMax: state.distanceMax,
    basePriceMin: state.basePriceMin,
    basePriceMax: state.basePriceMax,
    healthScoreMin: state.healthScoreMin,
    healthScoreMax: state.healthScoreMax,
    hideUnavailable: state.hideUnavailable,
    showEnabled: state.showEnabled,
    showDisabled: state.showDisabled,
    monthlyPricePostingEnabled: state.monthlyPricePostingEnabled,
    monthlyPricePostingDisabled: state.monthlyPricePostingDisabled,
    selectedCategory1Names: state.selectedCategory1Names,
    selectedCategory2Names: state.selectedCategory2Names,
    listingsToInclude: state.selectedListingsToInclude?.map(l => l.id),
    listingsToExclude: state.selectedListingsToExclude?.map(l => l.id),
    listingsToCompare: state.selectedListingsToCompare?.map(l => l.id),
    bookedAtStartDate: state.bookedAtStartDate,
    bookedAtEndDate: state.bookedAtEndDate,
  };
}
