import ApplicationAdapter from './application';
import { pluralize } from 'ember-inflector';

export default class CredentialAdapter extends ApplicationAdapter {
  pathForType(modelName) {
    return `user/${pluralize(modelName)}`;
  }

  urlForCreateRecord(_modelName, _snapshot) {
    return super.urlForCreateRecord(...arguments) + '/new';
  }
}
