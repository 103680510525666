import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield (hash chartOptions=this.chartOptions chartData=this.chartData)}}", {"contents":"{{yield (hash chartOptions=this.chartOptions chartData=this.chartData)}}","moduleName":"appkit/components/insights/charts/available-occupancy/container.hbs","parseOptions":{"srcName":"appkit/components/insights/charts/available-occupancy/container.hbs"}});
import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { chunk, chartOptions, round } from 'appkit/lib/insights/charts';
import { inject as service } from '@ember/service';

export default class InsightsChartsOccupancyContainer extends Component {
  @service intl;

  get chartOptions() {
    return chartOptions({
      locale: this.intl.locale,
      yTitle: this.intl.t('pricing.listing.occupancy'),
      tooltipOptionsFn: () => ({
        suffix: '%',
        timeframe: this.args.timeframe,
        displayBy: this.args.displayBy,
      }),
    });
  }

  @computed(
    'args.{timeframe,timeranges,useTimeRanges,displayBy,statsByTimeframe,compareStatsByTimeframe}'
  )
  get chartData() {
    const {
      timeframe,
      timeranges,
      displayBy,
      timeframeCache,
      isChartVisible,
      onChartVisibilityChange,
      statsByTimeframe,
      compareStatsByTimeframe,
    } = this.args;

    return chunk(
      {
        chartName: this.intl.t('pricing.listing.occupancy'),
        timeframe: timeframe,
        timeranges: timeranges,
        displayBy: displayBy,
        series: statsByTimeframe,
        future: true,
        mapFunction: row =>
          row.userAvailableNights
            ? round(row.occupiedDaysCount / row.userAvailableNights) * 100
            : 0,
        yearAgoMapFunction: row =>
          row.userAvailableNights
            ? round(row.yearAgoOccupiedDaysCount / row.userAvailableNights) * 100
            : 0,
        twoYearsAgoMapFunction: row =>
          row.userAvailableNights
            ? round(row.twoYearsAgoOccupiedDaysCount / row.userAvailableNights) * 100
            : 0,
        threeYearsAgoMapFunction: row =>
          row.userAvailableNights
            ? round(row.threeYearsAgoOccupiedDaysCount / row.userAvailableNights) * 100
            : 0,
        timeframeCache: timeframeCache,
        isChartVisible: isChartVisible,
        onChartVisibilityChange: onChartVisibilityChange,
        marketGroupName: this.intl.t('insights.marketGroupName'),
      },
      {
        compareToSeries: compareStatsByTimeframe,
        compareToMapFunction: row =>
          row.userAvailableNights
            ? round(row.occupiedDaysCount / row.userAvailableNights) * 100
            : 0,
        compareToYearAgoMapFunction: row =>
          row.userAvailableNights
            ? round(row.yearAgoOccupiedDaysCount / row.userAvailableNights) * 100
            : 0,
        compareToTwoYearsAgoMapFunction: row =>
          row.userAvailableNights
            ? round(row.twoYearsAgoOccupiedDaysCount / row.userAvailableNights) * 100
            : 0,
        compareToThreeYearsAgoMapFunction: row =>
          row.userAvailableNights
            ? round(row.threeYearsAgoOccupiedDaysCount / row.userAvailableNights) * 100
            : 0,
      },
      this.args.useTimeRanges
    );
  }
}
