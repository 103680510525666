import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import pmSteps from 'appkit/tours/demo/pm-demo';
import vroSteps from 'appkit/tours/demo/vro-demo';
import { scheduleOnce, next } from '@ember/runloop';
import { copy } from 'ember-copy';

@classic
export default class CalendarRoute extends Route {
  @service featureFlag;

  @service tour;

  async init() {
    super.init(...arguments);

    this.templateName = 'dashboard.pricing.listing.calendar';
    this.controllerName = 'listing.calendar';
  }

  setupController(controller, model) {
    controller.set('model', model);

    // Only show the tour once per entry into the demo routes. Show on the
    // entry to the calendar, not into chart / stats.
    const demoController = this.controllerFor('listing');
    demoController.set('tourCount', demoController.get('tourCount') + 1);
    if (demoController.get('tourCount') > 1) {
      return controller;
    }

    const params = this.paramsFor('demo');

    if (!['property-manager', 'vacation-rental-owner'].includes(params.id)) {
      throw Error('Invalid ID');
    }
    let steps;
    if (params.id === 'property-manager') {
      steps = pmSteps;
    } else {
      steps = vroSteps;
    }

    // The inbuilt requiredElements just prevents the tour from starting - and
    // it doesn't seem to even do that reliably. Have to roll our own checks.
    //
    // The shepherd representation for attachTo is "{selector} {position}",
    // which seems to cause all sorts of confusion:
    // https://github.com/shipshapecode/shepherd/issues/131
    //
    // Because of this, I tried to use the object format. E.g.
    // attachTo: {
    //   element: '.foo',
    //   position: 'top',
    // }
    // However if you return to page again and check the steps, they've been
    // rewritten to be the string format. Shepherd are doing some kind of
    // transform in-place instead of making a clone. Simple fix is to clone for
    // them.
    //
    // Should probably file a bug report, but I'm over it.
    steps = copy(steps, { deep: true });
    controller.set('steps', steps);

    const tour = this.tour;

    const buttons = [
      {
        classes: 'nav-button',
        text: 'Exit',
        type: 'cancel',
      },
      {
        classes: 'nav-button',
        text: 'Next',
        type: 'next',
      },
    ];
    steps.forEach(step => {
      step.options.buttons = buttons;
      // step.options.modal = true;
    });

    tour.set('defaultStepOptions', {
      classes: 'shepherd-tour',
      buttons: buttons,
    });
    tour.set('disableScroll', true);
    tour.set('modal', true);

    const requiredElements = steps
      .filter(step => step.options.attachTo)
      .map(step => step.options.attachTo.element);

    let attempts = 0;
    // Loop waiting for the attachTo selectors to become visible
    const initSteps = () => {
      if (attempts++ > 10) {
        return;
      }

      // Technically not visible - just whether they're present. Should
      // probably add in an offsetParent check here too.
      const elementsVisible = requiredElements.map(el => !!document.querySelector(el));
      if (!elementsVisible.reduce((a, b) => a && b)) {
        setTimeout(initSteps, 300);
        return;
      }
      tour.addSteps(steps);
      next(() => {
        tour.start();
      });
    };
    scheduleOnce('afterRender', initSteps);
    return controller;
  }

  deactivate() {
    let tour = this.tour;
    tour && tour.tourObject && tour.cancel();
  }
}
