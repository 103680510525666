import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { STATUS_MAPPING } from 'appkit/bp-models/booking_review_listing';

@classic
export default class IndexController extends Controller {
  @service('-routing') router;
  @service('booking-review') bookingReview;
  @controller('dashboard.pricing.index') pricingController;
  preserveScrollPosition = true;

  showPriceHistoryTable = false; // @type {Boolean}
  lastUpdatedHover = null; // @type {Object}
  STATUS_MAPPING = STATUS_MAPPING;

  @computed('router.currentPath')
  get ratesTabIsActive() {
    return (
      this.get('router.currentPath') ===
      'dashboard.pricing.index.booking-review.index.rates'
    );
  }

  @computed('router.currentPath')
  get listingStatsTabIsActive() {
    return (
      this.get('router.currentPath') ===
      'dashboard.pricing.index.booking-review.index.listing-stats'
    );
  }

  @action
  async togglePriceHistoryTable() {
    const listing = this.model.get('listing');
    if (!listing.bookingReviewListingsLoaded) {
      const data = await this.ajax._get(
        `/api/pricing/listings/${listing.id}/booking-review-recommendations`
      );
      listing.set(
        'bookingReviewListings',
        data.bookingReviewListings.map(bookingReviewListing => {
          return this.bpStore.createRecord(
            'bookingReviewListing',
            bookingReviewListing
          );
        })
      );
      listing.set('bookingReviewListingsLoaded', true);
    }
    this.set('showPriceHistoryTable', !this.showPriceHistoryTable);
    this.set('lastUpdatedHover', null);
  }

  @action
  closeSidePanel() {
    this.set('isVisible', false);

    later(
      this,
      function () {
        this.transitionToRoute('dashboard.pricing');
      },
      100
    );
  }
}
