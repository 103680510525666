import Transform from '@ember-data/serializer/transform';

export default class FilterMarketsTransform extends Transform {
  deserialize(serialized) {
    return serialized ? serialized.split('__') : [];
  }

  serialize(deserialized = []) {
    return deserialized.join('__');
  }
}
