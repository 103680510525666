import classic from 'ember-classic-decorator';
import { classNames, classNameBindings } from '@ember-decorators/component';
import Component from '@ember/component';
@classic
@classNames('app-toggle')
@classNameBindings('value:on:off')
export default class AppToggle extends Component {
  value = false;
  param = null;

  click() {
    this.action(!this.value);
    return false;
  }
}
