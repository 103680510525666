import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { CurrencyUtil } from 'appkit/lib/currency';
import moment from 'moment';

const colors = {
  TEAL: 'rgba(30, 202, 211, 0.6)',
  TEAL_A2: 'rgba(30, 202, 211, 0.1)',
  YELLOW: 'rgb(255, 191, 0)',
  YELLOW_A3: 'rgba(255, 191, 0, 0.3)',
  BLUE: 'rgb(24, 163, 208)',
  BLUE_A3: 'rgb(24, 163, 208, 0.3)',
  GRAY_A6: 'rgba(127, 143, 164, 0.6)',
  GRAY_A3: 'rgba(127, 143, 164, 0.3)',
  GRAY_A2: 'rgba(127, 143, 164, 0.2)',
  PURPLE_A6: 'rgba(176, 49, 199, 0.6)',
  PURPLE_A2: 'rgba(176, 49, 199, 0.2)',
  GRID_A2: 'rgba(185, 202, 210, 0.2)',
};

// Parse month into a human format
// E.g.: '2015-06' -> Jun 2015
const parseMonth = str => moment(str, 'YYYY-MM').format('YYYY-MM');
const parseMonthLong = str => moment(str, 'YYYY-MM').format('MMMM');

function YoYSeries(
  arr,
  col,
  startTwoAgo = 0,
  startPast = 12,
  startPresent = 24,
  duration = 15
) {
  return [
    arr.slice(startTwoAgo, startTwoAgo + duration).map(row => row[col]),
    arr.slice(startPast, startPast + duration).map(row => row[col]),
    arr.slice(startPresent, startPresent + duration).map(row => row[col]),
  ];
}

function toHistoricalSeries(yDataTwoAgo, yDataPast, yDataPresent, intl, opts = {}) {
  const twoAgoColorPrimary = opts.twoAgoColorPrimary || colors.PURPLE_A6;
  const twoAgoColorSecundary = opts.twoAgoColorSecundary || colors.PURPLE_A2;
  const pastColorPrimary = opts.pastColorPrimary || colors.GRAY_A6;
  const pastColorSecundary = opts.pastColorSecundary || colors.GRAY_A3;
  const presentColorPrimary = opts.presentColorPrimary || colors.TEAL;
  const presentColorSecundary = opts.presentColorSecundary || colors.TEAL_A2;

  return [
    {
      name: opts.twoAgoName || intl.t('common.2YearsAgo'),
      data: yDataTwoAgo,
      yearDistance: 2,
      color: twoAgoColorPrimary,
      lineColor: twoAgoColorSecundary,
      fillOpacity: 0,
      lineWidth: 3,
      marker: {
        enabled: false,
      },
      zones: opts.minZone && [
        {
          value: 0.05,
          color: twoAgoColorSecundary,
        },
      ],
    },
    {
      name: opts.pastName || intl.t('common.lastYear'),
      data: yDataPast,
      yearDistance: 1,
      color: pastColorPrimary,
      lineColor: pastColorPrimary,
      fillOpacity: 0,
      lineWidth: 3,
      marker: {
        enabled: false,
      },
      zones: opts.minZone && [
        {
          value: 0.05,
          color: pastColorSecundary,
        },
      ],
    },
    {
      name: opts.presentName || intl.t('common.thisYear'),
      yearDistance: 0,
      data: yDataPresent,
      color: presentColorPrimary,
      lineColor: presentColorPrimary,
      fillColor: presentColorSecundary,
      lineWidth: 3,
      marker: {
        enabled: false,
      },
      zones: opts.minZone && [
        {
          value: 0.05,
          color: presentColorSecundary,
        },
      ],
    },
  ];
}

function commonOptions(opts = {}) {
  const out = {
    chart: {
      height: 250,
      animation: false,
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          font: '14px Europa',
        },
      },
      gridLineColor: 'transparent',
      tickColor: 'transparent',
      tickInterval: opts.tickInterval,
    },
    xAxis: {
      categories: opts.categories,
      tickColor: 'transparent',
      labels: {
        formatter: function () {
          return `<span class="capitalize">${moment(this.value, 'YYYY-MM').format(
            'MMM'
          )}</span>`;
        },
        style: {
          font: '14px Europa',
        },
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    tooltip: {},
    series: opts.series,
  };

  if (opts.labelFormatter) {
    out.yAxis.labels.formatter = opts.labelFormatter;
  }
  if (opts.tooltipFormatter) {
    out.tooltip.formatter = opts.tooltipFormatter;
  }
  if (opts.legendFormatter) {
    out.legend.labelFormatter = opts.legendFormatter;
  }
  return out;
}

function areasplineOptions(opts = {}) {
  const out = commonOptions(opts);
  out.chart.type = 'areaspline';
  out.tooltip.xDateFormat = '%L';
  out.tooltip.shared = true;

  // Background Grid Dots
  out.xAxis.gridLineColor = colors.GRID_A2;
  out.xAxis.gridLineWidth = 4;
  out.xAxis.tickmarkPlacement = 'on';
  out.xAxis.gridLineDashStyle = 'dot';

  return out;
}

function columnOptions(opts = {}) {
  const out = commonOptions(opts);
  out.chart.type = 'column';
  out.xAxis.crosshair = true;
  out.tooltip.shared = true;
  out.plotOptions = {
    series: {
      minPointLength: 5,
      events: {
        legendItemClick: opts.legendItemClick,
      },
    },
  };

  return out;
}

function bookingsOptions(categories, yDataSeries, intl, opts = {}) {
  const series = toHistoricalSeries(...yDataSeries, intl, {
    presentColorPrimary: opts.presentColorPrimary || colors.TEAL,
    presentColorSecundary: opts.presentColorSecundary || colors.TEAL_A2,
    minZone: true,
  });

  return columnOptions({
    categories,
    series,
    tickInterval: opts.tickInterval || 5,
    tooltipFormatter: function () {
      const yValues = this.points.map(point => point.y.toLocaleString());
      return getTooltipContent(this, yValues);
    },
  });
}

function currencyOptions(categories, seriesData, currency, intl) {
  const series = toHistoricalSeries(...seriesData, intl);

  return areasplineOptions({
    categories,
    series,
    labelFormatter: function () {
      return currencyFormatter(currency, this.value);
    },
    tooltipFormatter: function () {
      const currencies = this.points.map(point => currencyFormatter(currency, point.y));
      return getTooltipContent(this, currencies);
    },
  });
}

/**
 * Creates a Tooltip for a chart, with the series dates and adds the extra content.
 * Targets the column the user is hovering and the selected series from the chart menu
 */
function getTooltipContent(columnData, extraRowContent) {
  const { x, points } = columnData;

  const hoverDateInCurrentYear = x;
  const tooltipHeader =
    '<span class="capitalize font-bold">' +
    parseMonthLong(hoverDateInCurrentYear) +
    '</span>';

  const tooltipRows = points.map((point, i) => {
    const { series } = point;
    const { color } = series;
    const yearDistance = series.userOptions.yearDistance;
    const bullet = `<span style='color:${color}'>●</span>`;
    const rowDate = tooltipDateFormatter(hoverDateInCurrentYear, yearDistance);

    return `<br/>${bullet} <span class="capitalize"> ${rowDate}: ${extraRowContent[i]}</span>`;
  });

  return tooltipHeader + tooltipRows.join('');
}

function currencyFormatter(currency, yPosition) {
  if (currency.isPrefixed) {
    return currency.symbol + yPosition.toLocaleString();
  }
  return yPosition.toLocaleString() + currency.symbol;
}

function tooltipDateFormatter(XAxisDate, yearDistance) {
  return moment(XAxisDate, 'YYYY-MM').subtract(yearDistance, 'years').format('MMM YY');
}

@classic
export default class ByListingController extends Controller {
  @service featureFlag;
  @service intl;

  @tracked selectedListing;
  @tracked listingBookings;
  @tracked topBookings;
  @tracked averageDailyRate;
  @tracked monthlyStats;
  @tracked statsCurrency;
  @tracked listingStatsLoading;

  async init() {
    super.init(...arguments);
  }

  get currency() {
    return CurrencyUtil.getSymbol(this.statsCurrency);
  }

  @computed
  get getListings() {
    return this.model.listings.filter(listing => listing.primaryChannelListing?.listed);
  }

  @computed
  get presentMonth() {
    return moment().format('MMMM YYYY');
  }

  get presentMonths() {
    return YoYSeries(this.monthlyStats, 'month', 0, 12, 24, 15)[2].map(parseMonth);
  }

  get pacingChartMonths() {
    return YoYSeries(this.monthlyStats, 'month', 3, 15, 27, 12)[2].map(parseMonth);
  }

  get chartOccupancy() {
    const categories = this.presentMonths;
    const occupancyDataSeries = YoYSeries(this.monthlyStats, 'occupancy');

    const twoYearsAgoLabel = this.intl.t('common.2YearsAgo');
    const lastYearLabel = this.intl.t('common.lastYear');
    const thisYearLabel = this.intl.t('common.thisYear');
    const occupancyLabel = this.intl.t('pricing.listing.occupancy');
    const blockedLabel = this.intl.t('glossary.blocked');
    const totalLabel = this.intl.t('common.total');

    const occupancySeries = toHistoricalSeries(...occupancyDataSeries, this.intl, {
      twoAgoName: 'occupancy',
      pastName: 'occupancy',
      presentName: 'occupancy',
      presentColorPrimary: colors.TEAL,
      presentColorSecundary: colors.TEAL_A2,
      minZone: true,
    });
    occupancySeries[0].stack = 'TwoYearsAgo';
    occupancySeries[1].stack = 'PastYear';
    occupancySeries[2].stack = 'PresentYear';

    const blockedColor = colors.GRAY_A2;
    const blockedDataSeries = YoYSeries(this.monthlyStats, 'blockedOccupancy');
    const blockedSeries = toHistoricalSeries(...blockedDataSeries, this.intl, {
      twoAgoName: 'blocked',
      pastName: 'blocked',
      presentName: 'blocked',
      twoAgoColorPrimary: blockedColor,
      twoAgoColorSecundary: blockedColor,
      presentColorPrimary: blockedColor,
      presentColorSecundary: blockedColor,
      pastColorPrimary: blockedColor,
      pastColorSecundary: blockedColor,
    });
    blockedSeries.forEach(s => {
      s.minPointLength = 0;
      s.pointPadding = 0.2;
      s.showInLegend = false;
    });
    blockedSeries[0].stack = 'TwoYearsAgo';
    blockedSeries[1].stack = 'PastYear';
    blockedSeries[2].stack = 'PresentYear';

    const out = columnOptions({
      categories,
      series: [...blockedSeries, ...occupancySeries],
      tooltipFormatter: function () {
        const stackFilter = stack => p =>
          p.series.userOptions.stack === stack &&
          (p.series.name === 'occupancy' || p.y !== 0);

        const i18nName = series => {
          const name = series.name === 'blocked' ? blockedLabel : occupancyLabel;
          return `<span class='lowercase'>${name}</span>`;
        };

        const seriesLabel = points =>
          `${points.map(p => `${p.y}% ${i18nName(p.series)}`).join(' / ')}`;

        const seriesTooltip = (title, points) => {
          let msg = '';

          if (points.length > 0) {
            const bullet = `<span style='color:${points[0].color}'>●</span>`;
            msg += `<br/>${bullet}<span class="capitalize"> ${title}: </span>`; //Row Date

            if (points.length > 1) {
              msg += `${points
                .map(p => p.y)
                .reduce((acc, cur) => acc + cur, 0)}% ${totalLabel} (${seriesLabel(
                points
              )})`;
            } else {
              msg += seriesLabel(points);
            }
          }

          return msg;
        };
        var s =
          '<span class="capitalize font-bold">' + parseMonthLong(this.x) + '</span>';
        const twoYearsAgoPoints = this.points
          .filter(stackFilter('TwoYearsAgo'))
          .reverse();
        const pastPoints = this.points.filter(stackFilter('PastYear')).reverse();
        const presentPoints = this.points.filter(stackFilter('PresentYear')).reverse();
        s += seriesTooltip(
          moment(this.x, 'YYYY-MM').subtract(2, 'years').format('MMM YY'),
          twoYearsAgoPoints
        );
        s += seriesTooltip(
          moment(this.x, 'YYYY-MM').subtract(1, 'years').format('MMM YY'),
          pastPoints
        );
        s += seriesTooltip(moment(this.x, 'YYYY-MM').format('MMM YY'), presentPoints);

        return s;
      },
      legendFormatter: function () {
        const stack = this.userOptions.stack;
        return stack === 'PresentYear'
          ? thisYearLabel
          : stack === 'PastYear'
          ? lastYearLabel
          : twoYearsAgoLabel;
      },
      legendItemClick: function (evt) {
        evt.preventDefault();
        this.chart.series.forEach(s => {
          if (s.stackKey === this.stackKey) {
            if (s.visible) {
              s.hide();
            } else {
              s.show();
            }
          }
        });
      },
    });

    out.yAxis.max = 100;
    out.yAxis.tickInterval = 50;
    out.plotOptions.series.stacking = 'normal';

    return out;
  }

  get chartAdr() {
    const yDataSeries = YoYSeries(this.monthlyStats, 'adr');
    return currencyOptions(this.presentMonths, yDataSeries, this.currency, this.intl);
  }

  get chartBookingVolumeStay() {
    const yDataSeries = YoYSeries(this.monthlyStats, 'volumeByCheckinDate');
    return currencyOptions(this.presentMonths, yDataSeries, this.currency, this.intl);
  }

  get chartAdrAvgMax() {
    const yDataSeries = YoYSeries(this.monthlyStats, 'adrMax');
    return currencyOptions(this.presentMonths, yDataSeries, this.currency, this.intl);
  }

  get chartRevPan() {
    const yDataSeries = YoYSeries(this.monthlyStats, 'revpan');
    return currencyOptions(this.presentMonths, yDataSeries, this.currency, this.intl);
  }

  get chartBookingVolumeBooked() {
    const yDataSeries = YoYSeries(this.monthlyStats, 'volumeByBookingDate');
    return currencyOptions(this.presentMonths, yDataSeries, this.currency, this.intl);
  }

  get chartStayLength() {
    const yDataSeries = YoYSeries(this.monthlyStats, 'stayLengthAvg');
    return bookingsOptions(this.presentMonths, yDataSeries, this.intl);
  }

  get chartBookingLeadTime() {
    const yDataSeries = YoYSeries(this.monthlyStats, 'avgLeadTime');

    return bookingsOptions(this.presentMonths, yDataSeries, this.intl, {
      presentColorPrimary: colors.TEAL,
      presentColorSecundary: colors.TEAL_A2,
      tickInterval: 30,
    });
  }

  get chartBookingsBooked() {
    const yDataSeries = YoYSeries(this.monthlyStats, 'bookedCount');
    return bookingsOptions(this.presentMonths, yDataSeries, this.intl);
  }

  get chartBookingsCheckIn() {
    const yDataSeries = YoYSeries(this.monthlyStats, 'checkinCount');
    return bookingsOptions(this.presentMonths, yDataSeries, this.intl);
  }

  get chartPacing() {
    const yDataSeries = YoYSeries(this.monthlyStats, 'revenuePacing', 3, 15, 27, 12);

    return currencyOptions(
      this.pacingChartMonths,
      yDataSeries,
      this.currency,
      this.intl
    );
  }

  @action
  changeListing(option) {
    this.listingStatsLoading = true;
    this.selectedListing = option;
    this.getListingData(option.id).then(() => (this.listingStatsLoading = false));
  }

  @action
  async getListingData(listingId) {
    const today = moment.utc().format('YYYY-MM-DD');
    const url = `/api/listings/${listingId}/stats?date=${today}`;
    const data = await this.ajax._get(url);

    this.listingBookings = data.stats.recentBookings;
    this.topBookings = data.stats.topBookings;
    this.averageDailyRate = data.stats.averageDailyRate;
    this.monthlyStats = data.stats.monthlyStats;
    this.statsCurrency = data.stats.currency;
  }

  @action
  print() {
    window.print();
  }
}
