import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import {
  getReservationsInDateRange,
  sortReservationsByCheckinDate,
} from 'appkit/lib/relay/utils';

const loadedReservationState =
  'relay/core/widgets/accordion/reservation/reservation-header';

const loadedReservationDetails =
  'relay/core/widgets/accordion/reservation/reservation-detail';

export default class RelayListingReservationsController extends Controller {
  @service('relay/reservations-service') reservationsService;

  @tracked expandedReservationId = null;
  @tracked referenceCode = null;

  @tracked filteredChannelReservations = this.model.reservations.sort(
    this.sortReservationsByCheckinDate
  );
  @tracked PMSReservationsDetails = [];

  @tracked reservationLogTitle = 'Recent channel reservations';
  @tracked PMSHeaderComponent = loadedReservationState;
  @tracked PMSDetailsComponent;
  @tracked dcHeaderComponent = loadedReservationState;
  @tracked dcDetailsComponent = loadedReservationDetails;

  @action
  onExpandReservationToggle(id) {
    this.expandedReservationId = this.expandedReservationId === id ? null : id;
    // this.highlighReservationDays(referenceCode);
  }

  // FILTER FLOW-----------------------------------------------------------------------

  /**
   * Filter reservations by date criteria
   **/
  @action
  onCalendarDayClick(calendarDay) {
    const { date, reservation } = calendarDay;
    // Gives all DC reservations in range of the clicked PMS reservation if existing,
    // or for that concrete calendar day otherwise
    const dateRangeStart = reservation?.checkinDate || date;
    const dateRangeEnd = reservation?.checkoutDate || date;

    this.highlighReservationDays(calendarDay.reservation?.reference);
    this.updateReservationLogTitle(calendarDay);

    this.filteredChannelReservations = [
      ...getReservationsInDateRange(
        this.model.reservations,
        dateRangeStart,
        dateRangeEnd
      ),
    ].sort(sortReservationsByCheckinDate);

    // Update view with accordion states (loaded, loading, error, no results)
    this.updateDCAccordionState();
    this.updatePMSAccordionState(calendarDay.reservation?.checkinDate);
  }

  /**
   * Filter reservations by date criteria
   **/
  @action
  onSearchByReferenceCodeKeyUp(inputEvent) {
    const { key } = inputEvent;
    const { value } = inputEvent.srcElement;

    if (key === 'Enter' && value !== this.referenceCode) {
      const reference = value.trim().toUpperCase();
      // PMS
      const { _listingDetails } = this.model;
      const PMSReservation = _listingDetails.reservations.find(
        res => res.reference === reference
      );
      this.updatePMSAccordionState(PMSReservation?.checkinDate);
      // DC
      this.filteredChannelReservations = this.model.reservations.filter(
        res => res.reference === reference
      );
      this.updateDCAccordionState();
      this.highlighReservationDays(reference);
      this.updateReservationLogTitle(null, reference);
      this.referenceCode = reference;
    }
  }

  // CONTROLLER FUNCTIONS ---------------------------------------------------------------------
  updateDCAccordionState() {
    const { directChannels } = this.model.channelListings;

    if (this.filteredChannelReservations.length) {
      this.updateReservationComponent('', 'dcHeaderComponent');
      this.dcDetailsComponent = loadedReservationDetails;
      return;
    }

    this.filteredChannelReservations = this.getReservationPlaceholderData(
      ...directChannels
    );
    this.dcDetailsComponent = null;
    this.updateReservationComponent('no-results', 'dcHeaderComponent');
  }

  updatePMSAccordionState(checkinDate) {
    const { PMS } = this.model.channelListings;
    this.PMSReservationsDetails = this.getReservationPlaceholderData(PMS);

    if (checkinDate) {
      this.updateReservationComponent('loading', 'PMSHeaderComponent');
      this.fetchReservationDetails(PMS.id, checkinDate);
      return;
    }

    this.PMSDetailsComponent = null;
    this.updateReservationComponent('no-results', 'PMSHeaderComponent');
  }

  async fetchReservationDetails(channelListingId, checkinDate) {
    const reservationsRes = await this.reservationsService.fetchPMSReservationDetails({
      channelListingId,
      checkinDate,
    });
    const reservationAdapter = this.reservationsService.getIntegrationReservationsAdapter(
      'genericPMS'
    );

    this.PMSReservationsDetails = reservationAdapter(
      reservationsRes,
      this.model._listingDetails
    );

    const hasReservations = this.PMSReservationsDetails.length;
    const state = hasReservations ? '' : 'no-results';
    this.PMSDetailsComponent = hasReservations ? loadedReservationDetails : null;
    this.updateReservationComponent(state, 'PMSHeaderComponent');
  }

  // Using the PMS data as placeholder while data is been fetched or if has no results
  getReservationPlaceholderData(...channelListings) {
    return channelListings.map(ch => ({
      ...ch,
      relativeLastScrape: moment(ch.lastScrapeStamp).fromNow(),
    }));
  }

  updateReservationComponent(status, target) {
    const baseComponent = 'relay/core/widgets/accordion/reservation/reservation';
    this[target] = status ? `${baseComponent}-${status}` : `${baseComponent}-header`;
  }

  updateReservationLogTitle(calendarDay, reference) {
    const DATE_FORMAT = 'MMM D, YYYY';
    if (reference) {
      this.reservationLogTitle = `Channel reservations with reservation code ${reference}`;
    } else if (!calendarDay) {
      this.reservationLogTitle = 'Recent channel reservations';
    } else if (!calendarDay.reservation) {
      const calendarDate = moment(calendarDay.date).format(DATE_FORMAT);
      this.reservationLogTitle = `Channel reservations in ${calendarDate}`;
    } else {
      const { checkinDate, checkoutDate } = calendarDay.reservation;
      const checkin = moment(checkinDate).format(DATE_FORMAT);
      const checkout = moment(checkoutDate).format(DATE_FORMAT);
      this.reservationLogTitle = `Channel reservations for ${checkin} - ${checkout}`;
    }
  }

  highlighReservationDays(reference) {
    const highlightStateClasses = ['border-2', 'border-gray-600'];
    const normalStateClasses = ['border', 'border-gray-400'];
    if (reference) {
      // Highlight new selection
      document.querySelectorAll(`.reservation-${reference}`).forEach(x => {
        x.classList.remove(...normalStateClasses);
        x.classList.add(...highlightStateClasses);
      });
    }
    if (this.referenceCode && reference !== this.referenceCode) {
      //Clear previous selection
      document.querySelectorAll(`.reservation-${this.referenceCode}`).forEach(x => {
        x.classList.add(...normalStateClasses);
        x.classList.remove(...highlightStateClasses);
      });
    }
    this.referenceCode = reference;
  }
}
