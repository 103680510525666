import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';

@classic
export default class SignalWelcomeRoute extends AuthRoute {
  isSurfaceSignalInAppEnabled;

  @service featureFlag;

  async init() {
    super.init(...arguments);
  }

  async beforeModel() {
    this.isSurfaceSignalInAppEnabled = await this.featureFlag.evaluate(
      'surface-signal-in-app',
      false
    );
  }

  async model() {
    const dashboardModel = this.modelFor('dashboard');

    return EmberObject.create({
      user: dashboardModel.user,
      isSurfaceSignalInAppEnabled: this.isSurfaceSignalInAppEnabled,
    });
  }

  async afterModel(model) {
    if (model.user.signalCustomerId) this.transitionTo('/dashboard/signal');
  }
}
