import Controller from '@ember/controller';
import { computed, action, set, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { map } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import { getOwner } from '@ember/application';
import moment from 'moment';

export class ProjectionHeader {
  statusMapping = {
    asc: 'asc',
    desc: 'desc',
  };

  @tracked sortOrder = this.statusMapping.asc;

  @tracked
  columns = [
    { name: 'Last Updated', _name: 'lastUpdated', selected: true, colSpan: 2 },
    { name: 'Name', _name: 'name', selected: false, colSpan: 2 },
    { name: 'Address', _name: 'address', selected: false, colSpan: 3 },
    { name: 'Bedrooms', _name: 'bedrooms', selected: false, colSpan: 1 },
    { name: 'Rev Estimate', _name: 'revenueEstimate', selected: false, colSpan: 1 },
    { name: 'Status', _name: 'status', selected: false, colSpan: 2 },
    { name: 'Actions', _name: 'actions', selected: false, colSpan: 1 },
  ];

  @computed('columns.@each.selected')
  get selectedColumn() {
    return this.columns.filter(c => c.selected)[0];
  }

  @action
  clickedColumn(column, e) {
    e.stopPropagation();
    if (e.target.name === 'isSelected') {
      return;
    }

    if (column._name === this.selectedColumn._name) {
      this.switchSortOrder();
      return;
    }
    this.resetSortOrder();
    this.deselect();
    this.select(column);
  }

  switchSortOrder() {
    if (this.sortOrder === this.statusMapping.desc) {
      set(this, 'sortOrder', this.statusMapping.asc);
    } else {
      set(this, 'sortOrder', this.statusMapping.desc);
    }
  }

  resetSortOrder() {
    set(this, 'sortOrder', this.statusMapping.asc);
  }

  select(column) {
    set(column, 'selected', true);
  }

  deselect() {
    set(this, 'selectedColumn.selected', false);
  }
}

export default class DashboardOwnerResourceIndexController extends Controller {
  @service('alert') alert;
  @service('owner-resource') ownerResource;
  @service pdfMake;

  @controller('dashboard.owner-resource.projection.build-projection') buildProjection;

  @tracked projectionHeader = new ProjectionHeader();

  @tracked selectedProjections = [];

  bulkCheckSelected = false;

  statusOptions = [
    {
      name: 'NOT AVAILABLE',
      option: 'n/a',
      style:
        'py-1 px-3 text-sm cursor-pointer w-32 text-center min-w-12 inline-block rounded-full border-2 border-white text-gray-500 border-dashed border-gray-300',
    },
    {
      name: 'WON',
      option: 'won',
      style:
        'py-1 px-3 text-sm cursor-pointer w-32 text-center min-w-12 inline-block rounded-full border-2 border-white bg-success-500 text-gray-100',
    },
    {
      name: 'LOST',
      option: 'lost',
      style:
        'py-1 px-3 text-sm cursor-pointer w-32 text-center min-w-12 inline-block rounded-full border-2 border-white bg-gray-200 text-gray-600',
    },
    {
      name: 'SENT',
      option: 'sent',
      style:
        'py-1 px-3 text-sm cursor-pointer w-32 text-center min-w-12 inline-block rounded-full border-2 border-white bg-warning-400 text-gray-100',
    },
  ];

  @computed('selectedProjections.[]')
  get selectedProjectionIds() {
    return this.selectedProjections.map(sp => sp.id);
  }

  @computed
  get searchText() {
    return localStorage.getItem('searchText');
  }
  set searchText(value) {
    if (value) {
      localStorage.setItem('searchText', value);
    } else {
      localStorage.removeItem('searchText');
    }
    return localStorage.getItem('searchText');
  }

  @map('model.projections', function (p) {
    return p.id;
  })
  allProjectionIds;

  @computed('projectionHeader.{selectedColumn,sortOrder}', 'model.projections.[]')
  get projectionsSorted() {
    const sortedBy = this.projectionHeader.selectedColumn._name;

    const compare = (a, b) => {
      let comparison = 0;

      switch (sortedBy) {
        case 'lastUpdated':
          if (a.updatedAt) {
            comparison = moment(b._updatedAt).diff(a._updatedAt);
          } else {
            comparison = moment(b._createdAt).diff(a._createdAt);
          }
          break;
        case 'name':
          comparison = a.projectionName.localeCompare(b.projectionName);
          break;
        case 'address':
          comparison = a.referenceListing.address.localeCompare(
            b.referenceListing.address
          );
          break;
        case 'bedrooms':
          comparison = a.referenceListing.bedrooms - b.referenceListing.bedrooms;
          break;
        case 'revenueEstimate':
          comparison = a.revenueEstimate - b.revenueEstimate;
          break;
        default:
          comparison = a.date - b.date;
      }

      const sortOrder = this.projectionHeader.sortOrder;
      return sortOrder === 'desc' ? comparison * -1 : comparison;
    };

    return this.model.projections.sort(compare).filter(p => !get(p, 'deletedAt'));
  }

  @action
  viewProjection(projection) {
    set(this, 'selectedProjections', []);

    this.transitionToRoute(
      'dashboard.owner-resource.projection.build-projection.view',
      projection.id
    );
  }

  @action
  addSelectedProjection(projection, option) {
    if (option.target.checked) {
      this.selectedProjections.pushObject(projection);
      set(projection, 'selected', true);
    } else {
      this.selectedProjections.removeObject(projection);
      set(projection, 'selected', false);
    }
  }

  @action
  selectAllProjections(e) {
    e.stopPropagation();

    get(this.model, 'projections').forEach(p => {
      set(this, 'bulkCheckSelected', e.target.checked);
      set(p, 'selected', e.target.checked);
    });

    if (e.target.checked) {
      set(this, 'selectedProjections', get(this, 'projectionsSorted'));
    } else {
      set(this, 'selectedProjections', []);
    }
  }

  @action
  async updateStatus(projection, status) {
    set(projection, 'status', status);

    if (projection._dirty.dealStatus === projection.dealStatus) {
      return;
    }

    const data = { id: projection.id };
    data['dealStatus'] = projection.dealStatus;

    try {
      await this.ajax._put('/api/properties_projections', data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain', { timeout: 10000 });
      return;
    }
    this.alert.success('Projection updated successfully.');
  }

  @action
  async focusedOutNameField(projection) {
    set(projection, 'displayNameFieldOutline', false);

    if (projection._dirty.projectionName === projection.projectionName) {
      return;
    }

    const data = { id: projection.id };
    data['projectionName'] = projection.projectionName;

    try {
      await this.ajax._put('/api/properties_projections', data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain', { timeout: 10000 });
      return;
    }
    this.alert.success('Projection updated successfully.');
  }

  @action
  async downloadProjection(projection) {
    const pdfFormat = getOwner(this)
      .lookup('controller:dashboard.owner-resource')
      .generatePdfFormat(projection);

    const pdfMake = await this.pdfMake.getInstance();

    pdfMake
      .createPdf(pdfFormat)
      .download(`Projection — ${moment().format('DD-MM-YYYY')}`);
  }
}
