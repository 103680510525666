import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';

export default class DashboardPricingIndexSetupRoute extends AuthRoute {
  @service listControl;

  basePriceHelperPrice = null;
  beforeModel(transition) {
    const bphValue = transition.to.queryParams?.basePrice;
    if (bphValue) {
      this.basePriceHelperPrice = Number(bphValue);
    }
  }

  async model(params) {
    const listingId = parseInt(params.id);
    this.listControl.set('viewingListingId', listingId);
    let listing = this.bpStore.peekRecord('listing', listingId);

    const dashboardModel = this.modelFor('dashboard.pricing.index');

    if (listing.channels.includes('booking_connect')) {
      const customize = await this.ajax._get(`/api/listings/${listing.id}/customize`);
      listing.exportCalendarToken = customize.exportCalendarToken;
    }
    if (this.basePriceHelperPrice) {
      listing.bphPrice = this.basePriceHelperPrice;
    }
    return {
      listing,
      credential: dashboardModel.credential,
      listings: dashboardModel.listings,
    };
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.listingSetup.reset();
  }

  afterModel(model) {
    // If the model is not a new listing redirect to dashboard
    if (!model.listing.isNewListing) {
      this.listControl.set('viewingListingId', null);
      return this.transitionTo('dashboard.pricing.index');
    }
  }

  deactivate() {
    // Only reset the list control listing id if it's the same that we previously selected
    if (this.listControl.viewingListingId === this.controller.model.listing.id) {
      this.listControl.set('viewingListingId', null);
    }
  }

  renderTemplate() {
    this.render('dashboard/pricing/index/setup/index', {
      into: 'dashboard/pricing/index',
      outlet: 'side-panel',
    });
  }
}
