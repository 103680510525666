import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { computed } from '@ember/object';
import Controller from '@ember/controller';
@classic
export default class DataController extends Controller {
  model = [];
  searchTerm = '';
  expandedMarket = null;

  @computed('target.currentPath')
  get clusterSubroute() {
    let path = this.get('target.currentPath');
    if (path.includes('.cluster.')) {
      return path;
    }
    return 'data.cluster.airbnb-availability';
  }

  @computed('target.currentPath')
  get marketSubroute() {
    let path = this.get('target.currentPath');
    if (path.includes('.market.')) {
      return path;
    }
    return 'data.market.cluster-prices';
  }

  debouncedSearchTerm = '';
  _debounceSearchTimeout = null;

  // eslint-disable-next-line ember/no-observers
  @observes('searchTerm')
  _debounceSearch() {
    if (this._debounceSearchTimeout) {
      clearTimeout(this._debounceSearchTimeout);
    }
    this._debounceSearchTimeout = setTimeout(() => {
      this.set('debouncedSearchTerm', this.searchTerm);
    }, 500);
  }

  @computed('model.[]', 'debouncedSearchTerm')
  get filteredModel() {
    let term = this.debouncedSearchTerm.toLowerCase();
    return this.model.filter(market => market.get('name').toLowerCase().includes(term));
  }
}
