import classic from 'ember-classic-decorator';
import { action, computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import { CHANNEL, DATA_TYPE, CHANNEL_CONFIG } from 'appkit/lib/channel_configuration';
import ListingDate from 'appkit/bp-models/listing_date';
import { UserUtil } from 'appkit/lib/user-util';

@classic
export default class ListingController extends Controller {
  @service alert;
  @service router;
  @service listingSetup;
  @service listingActions;
  @service intl;
  @service refresh;
  @service forcePaymentMethod;
  @service hog;
  @service embed;

  @alias('model.listing') listing;
  @tracked showListingSetupModal;
  @tracked fromDashboard;
  @tracked calendarEditExpanded = true;
  @tracked calendarEditExpandedAnimEnd = true;
  @tracked basePriceModalOpen;
  @tracked showMinPriceInput = false;
  @tracked showBasePriceInput = false;
  @controller('dashboard') dashboardController;

  reasonsToDisable = [
    {
      id: 'STOP_RENTING',
      label: this.intl.t('pricing.listing.reasonsToDisable.stopRenting'),
    },
    {
      id: 'PAUSED_LISTING',
      label: this.intl.t('pricing.listing.reasonsToDisable.pausedListing', {
        count: 1,
      }),
    },
    {
      id: 'COMPETITION',
      label: this.intl.t('pricing.listing.reasonsToDisable.competition'),
    },
    {
      id: 'COST_ROI',
      label: this.intl.t('pricing.listing.reasonsToDisable.costRoi'),
    },
    {
      id: 'OTHER',
      label: this.intl.t('pricing.listing.reasonsToDisable.other'),
    },
  ];

  tooltipOptions = {
    modifiers: {
      preventOverflow: {
        escapeWithReference: false,
      },
    },
  };

  loading = false;
  showChannelInfo = false;
  showNotes = false;
  showEditNotes = false;
  notes = '';
  date = moment().startOf('day');

  @computed('dashboardController.model.user.{status,defaultPaymentMethod}')
  get isUserBlocked() {
    const {
      status,
      defaultPaymentMethod,
      isBlocked,
    } = this.dashboardController.model.user;

    return UserUtil.isUserBlocked(status, isBlocked, defaultPaymentMethod);
  }

  @computed('dashboardController.model.user.{isBlocked,status,locale}')
  get blockedUserMessage() {
    const { status, isBlocked } = this.dashboardController.model.user;
    let message = UserUtil.blockedUserMessage(isBlocked, status);

    return [
      this.intl.t(message[0]),
      this.intl.t('pricing.updatePaymentDetailsSimple'),
      this.intl.t(message[1]),
    ].join(' ');
  }

  @computed(
    'currentUser.isStaff',
    'router.currentRouteName',
    'model.listing.{canEdit,isDemo,isPreview,primaryChannelListing.canPushRates}'
  )
  get showFeature() {
    const staff = this.get('currentUser.isStaff');
    const demo = this.get('model.listing.isDemo');
    const preview = this.get('model.listing.isPreview');
    const canEdit = this.get('model.listing.canEdit');
    const canPush = this.get('model.listing.primaryChannelListing.canPushRates');
    const dashboard = !demo && !preview;
    const downloadStats =
      dashboard && this.router.currentRouteName === 'dashboard.pricing.listing.stats';
    const isBookingListing = this.model.listing.channels?.some(
      c => c === 'booking_connect'
    );
    const isMergedListing = this.model.listing.channels.length > 1;
    const account = this.model.listing.primaryChannelListing?.account;
    const canSetMinStay =
      dashboard &&
      account?.isMinStayEnabled &&
      (account.channelConfig.canPush.includes(DATA_TYPE.MIN_STAY_FOR_LISTING) ||
        account.channelConfig.canPush.includes(DATA_TYPE.MIN_STAY_PER_DAY));

    return {
      backButton: dashboard,
      calendar: true,
      chart: true,
      stats: dashboard,
      nearby: !demo && (staff || dashboard),
      marketData: staff || dashboard || demo,
      customize: canEdit && dashboard,
      enabled: !demo && !preview,
      // TODO: add credentials to this
      enabledToggle: canPush && !demo && !preview,
      signUpCTA: preview || demo,
      previewListing: preview || demo,
      canOverride: !(preview || demo),
      setAvailability: !isMergedListing && isBookingListing,
      isMinStayEnabled: account?.isMinStayEnabled,
      downloadStats,
      canSetMinStay,
      isMergedListing,
    };
  }

  @computed('listing')
  get channelListings() {
    return [
      this.listing.primaryChannelListing,
      ...this.listing.channelListingsWithoutPrimary,
    ].filter(c => c != null);
  }

  @tracked showMonthlyCalendar = false;

  get channelCanPushMonthly() {
    const channel = this.model.listing.primaryChannelListing.channel;
    const channelConfig = CHANNEL_CONFIG[channel];
    return channelConfig.canPush.includes(DATA_TYPE.MONTHLY_PRICE);
  }

  get isMonthlyCalendarVisible() {
    const channel = this.model.listing?.primaryChannelListing?.channel;

    // TODO: Remove this after we make sure that all PMSs that support monthly pricing are working
    const ALLOWED_CHANNELS = [CHANNEL.ESCAPIA, CHANNEL.FAKE_PMS, CHANNEL.TRACK];
    if (!ALLOWED_CHANNELS.includes(channel)) return false;

    return this.channelCanPushMonthly && this.monthlyFeatureFlagEnabled;
  }

  get backLink() {
    const view = localStorage.getItem('appearance');
    return view === 'grid-view' ? 'dashboard.grid' : 'dashboard.pricing.index';
  }
  @computed('calendarEditExpanded', 'listing.isPreview')
  get showMinBasepriceDesktop() {
    if (this.listing.isPreview) return true;
    return this.listing.canEdit ? !this.calendarEditExpanded : true;
  }

  @computed(
    'model.listing.{basePrice,minPrice,monthlyMinPrice}',
    'calendarEditExpanded',
    'model.listing._dirty.{basePrice,minPrice,monthlyMinPrice}'
  )
  get showSaveMinBasePriceBar() {
    if (
      this.calendarEditExpanded ||
      (this.listing.basePrice === this.listing._dirty.basePrice &&
        this.listing.minPrice === this.listing._dirty.minPrice &&
        this.listing.monthlyMinPrice === this.listing._dirty.monthlyMinPrice)
    ) {
      return false;
    } else {
      return true;
    }
  }

  @action
  change(field, evt) {
    set(this.listing, field, Number(evt.target.value));
    this.listing.validateInput();
  }

  @action
  changeCalendarView(mode) {
    this.set('showMonthlyCalendar', mode === 'monthly');
    this.listingActions.setup({
      isMonthly: this.showMonthlyCalendar,
    });
  }

  @action
  setBasePrice(value) {
    this.set('model.listing.basePrice', value);
    this.get('model.listing').validateInput();
  }

  @action
  setMinPrice(value) {
    this.set('model.listing.minPrice', value);
    this.get('model.listing').validateInput();
  }

  @action
  copyCurrentToUser() {
    for (let row of this.get('model.calendar')) {
      let price = row.get('priceScraped');
      if (!price) {
        continue;
      }
      row.set('priceUser', Math.floor(price));
    }
  }

  @action
  editNotes(enable) {
    this.set('notes', this.listing.pmNotes);
    this.set('showEditNotes', enable);
  }

  @action
  updateNotes(evt) {
    this.set('notes', evt.target.value);
  }

  @action
  async saveNotes(notes) {
    this.set('listing.pmNotes', notes);

    if (this.listing.isPreview || this.listing.isDemo) return;

    const result = await this.listing.saveWithValidation();

    if (result) {
      this.set('showEditNotes', false);
    } else {
      this.set('listing.pmNotes', this.listing.get('_dirty.pmNotes'));
    }

    return result;
  }

  @action
  async toggleEnabled(listing, reasons, enabled) {
    if (this.forcePaymentMethod.userRequirements() && enabled) {
      this.forcePaymentMethod.redirect();
      return;
    }

    if (enabled && this.isUserBlocked) {
      this.alert.error(this.blockedUserMessage, 10000);
      return;
    }

    const alert = this.alert;

    if (listing.get('isDirty')) {
      alert.error('validation.pricingErrors.savePriceEditsBeforeToggle');
      return;
    }
    listing.set('reasonsSyncingTurnedOff', reasons);
    listing.set('enabled', enabled);
    const result = await listing.saveWithValidation();
    if (!result) {
      listing.set('enabled', listing.get('_dirty.enabled'));
      return;
    }

    if (enabled) {
      alert.success(
        `OK! Updating prices on your ${listing.get('allChannelLabels')} calendar.`
      );
    } else {
      listing.set('monthlyEnabled', false);
      alert.success('Price syncing disabled.');
    }
  }

  @action
  fitBaseToCurrent() {
    /*
    This assumes error has a single global minima. This seems true when
    prices are flat, or mostly match our prices, but we should check some
    odd pricing setups (e.g. incorrect / inverse sesaonality) and make sure
    the assumption holds

    Done:
     * set min to lowest price
     * starting at the lowest price, calculate the RMS error
     * Loop through 100 prices between min and max seen, use the one with lowest RMS error.

    Still todo:
     * Do binary search to cut number of attempted prices
     * Keep looping until price difference is $1
     * Look to largest event - make sure our rates are 20% highero
     * Move to a web worker so we don't lock the UI thread
    */
    let prices = this.get('model.calendar').map(row => row.get('priceScraped'));
    let minSeen = Math.floor(Math.min(...prices));
    let maxSeen = Math.floor(Math.max(...prices)) + 1;

    // If they're flat pricing, make sure we try some reasonable values
    let avgSeen = Math.floor(prices.reduce((accum, el) => accum + el) / prices.length);
    this.get('model.listing').set('basePrice', avgSeen);
    let modeledPrices = this.get('model.calendar').map(row => row.get('priceModeled'));
    let minModeled = Math.floor(Math.min(...modeledPrices));
    let maxModeled = Math.floor(Math.max(...modeledPrices));

    let startPrice = Math.min(minSeen, minModeled);
    let endPrice = Math.max(maxSeen, maxModeled);

    // TODO: For people with lots of rates, we want to match their min, but
    // for people with flat pricing, we want to allow seasonality to go on
    // either side of their flat rate.
    this.get('model.listing').set('minPrice', startPrice);

    let basePrice = startPrice;
    let errorByPrice = {};
    while (basePrice < endPrice) {
      this.get('model.listing').set('basePrice', basePrice);

      let error = 0;
      for (let row of this.get('model.calendar')) {
        // I don't know if there's some magic behind squared error, but it
        // seems to overvalue events. Try just using the absolute value.
        // error += Math.pow(row.get('priceModeled') - row.get('priceScraped'), 2);
        error += Math.abs(row.get('priceModeled') - row.get('priceScraped'));
      }
      errorByPrice[basePrice] = error;

      basePrice += (maxSeen - minSeen) / 100;
    }

    let targetBase = null;
    let bestError = Infinity;
    for (let key of Object.keys(errorByPrice)) {
      if (errorByPrice[key] < bestError) {
        targetBase = key;
        bestError = errorByPrice[key];
      }
    }
    targetBase = Math.round(targetBase);
    this.get('model.listing').set('basePrice', targetBase);
  }

  @action
  async onSaveListingSetup(data) {
    if (data.syncEnabled && this.isUserBlocked) {
      this.alert.error(this.blockedUserMessage, 10000);

      return;
    }

    if (this.forcePaymentMethod.userRequirements() && data.syncEnabled) {
      this.forcePaymentMethod.redirect();
      return;
    }

    await this.listingSetup.onSave(data);
    await this.model.listing.reload({ override: true });
    this.showListingSetupModal = false;
  }

  @action
  onChangeListingSetup(data, field) {
    set(this.listing, field, Number(data[field]));
    this.listing.validateInput();
  }

  @action
  goToDashboard() {
    // Go back to dashboard using browser history to preserve scroll position
    if (this.fromDashboard) {
      history.back();
    } else {
      this.router.transitionTo('dashboard');
    }
  }

  @action
  toggleCalendarEditExpanded() {
    this.calendarEditExpanded = !this.calendarEditExpanded;
  }

  @action
  monthyPricingChanged(monthlyEnabled) {
    this.model.listing.set('monthlyEnabled', monthlyEnabled);
  }

  @action
  clearRefreshStatus() {
    this.refresh.listingStatus = '';
  }

  @action
  async refreshSingleListing(listing) {
    this.embed.czCaptureEvent(
      'Listing Refresh',
      'User clicked refresh button on single listing'
    );
    const listingId = listing.id;
    this.get('model.listings')
      .find(listing => listing.id === listingId)
      .set('loading', true);

    const listingPromise = this.refresh.refreshListing(listing);
    this.hog.capture('$refresh-single-listing');

    listingPromise.then(() => {
      const item = this.get('model.listings').find(listing => listing.id === listingId);
      item.set('loading', false);
      item.set('lastScraped', this.refresh.lastScraped);
    });
  }

  @action onCloseBasePriceModel() {
    this.basePriceModalOpen = false;
    this.resetChanges();
  }

  @action onOpenBasePriceModal() {
    this.basePriceModalOpen = true;
  }

  @action
  increment(field) {
    const amount = Math.round(get(this.listing._dirty, field) * 0.05);
    const current = get(this.listing, field);
    const newAmount = current + amount;
    set(this.listing, field, newAmount > 0 ? newAmount : 0);
    this.listing.validateInput();
  }

  @action
  decrement(field) {
    const amount = Math.round(get(this.listing._dirty, field) * 0.05);
    const current = get(this.listing, field);
    const newAmount = current - amount;
    set(this.listing, field, newAmount > 0 ? newAmount : 0);
    this.listing.validateInput();
  }

  @action
  hideBaseMinPriceInput(e) {
    if (
      !e.target.classList.contains('base-min-price-input') &&
      !e.target.closest('.base-min-price-input')
    ) {
      this.showMinPriceInput = false;
      this.showBasePriceInput = false;
      document.removeEventListener('click', this.hideBaseMinPriceInput);
    }
  }

  @action
  onShowBasePriceInput() {
    if (this.listing.canEdit) {
      !this.showBasePriceInput &&
        document.addEventListener('click', this.hideBaseMinPriceInput);
      this.showBasePriceInput = true;
    }
  }

  @action
  onShowMinPriceInput() {
    if (this.listing.canEdit) {
      !this.showMinPriceInput &&
        document.addEventListener('click', this.hideBaseMinPriceInput);
      this.showMinPriceInput = true;
    }
  }

  @action
  onTogglePricingSidebar(event) {
    // we're looking for the specific property here because 'transitionend'
    // is triggered by each property that was transitioned.
    // This prevents other properties to trigger this multiple times on a single transition
    if (event.propertyName != 'width') return;
    this.calendarEditExpandedAnimEnd = !this.calendarEditExpandedAnimEnd;
  }

  @action
  resetChanges() {
    this.listing.resetChanges();
    this.listing.validateInput();
  }

  @action
  async saveBaseMinPrice() {
    const dirtyDays = this.listing.get('calendar').filter(day => day.get('isDirty'));
    if (dirtyDays.length) {
      await ListingDate.saveMany(dirtyDays);
    }

    const result = await this.listing.saveWithValidation();

    if (!result) {
      return;
    }

    this.alert.success(
      this.intl.t('pricing.listing.sidebar.basePriceMinimumOverridesSaved')
    );
  }
}
