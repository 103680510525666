import Route from '@ember/routing/route';
import EmberObject, { set, get, action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DashboardOwnerResourceRoute extends Route {
  @service('global-nav') globalNav;
  @service('owner-resource') ownerResource;

  title() {
    let title = 'Projections / Dashboard';
    this.globalNav.setTitle(title);
    return title;
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    this.ownerResource.resetService();

    let uniqueCount = {};
    for (let l of model.listings) {
      if ({}.hasOwnProperty.call(uniqueCount, get(l, 'currency'))) {
        uniqueCount[get(l, 'currency')].count++;
      } else {
        uniqueCount[get(l, 'currency')] = { count: 1 };
      }
    }

    let out = [];
    for (let c in uniqueCount) {
      let item = {
        currency: c,
        count: uniqueCount[c].count,
      };
      out.push(item);
    }

    out = out
      .sort((a, b) => {
        return b.count - a.count;
      })
      .map(i => i.currency);

    this.ownerResource.selectedCurrency = out[0];

    return controller;
  }

  async model() {
    const model = EmberObject.create({});
    let dashboardModel = this.modelFor('dashboard');

    // projection templates
    const url_p = `/api/projections_templates/get_all_templates`;
    const res_p = await this.ajax._get(url_p);
    model.set(
      'projectionTemplates',
      res_p.projectionsTemplates.map(pt => {
        return this.bpStore.createRecord('projectionTemplate', pt);
      })
    );

    // projections
    const url = '/api/properties_projections/get_all_projections';
    const res = await this.ajax._get(url);
    const projections = res.propertiesProjections.map(projection =>
      this.bpStore.createRecord('projection', projection)
    );

    projections.map(projection => {
      // reference listing
      const referenceListing = this.bpStore.peekRecord(
        'listing',
        projection.get('referenceListingId')
      );
      set(projection, 'referenceListing', referenceListing);

      // projection template
      const projectionTemplate = this.bpStore.peekRecord(
        'projectionTemplate',
        projection.get('projectionTemplateId')
      );
      set(projection, 'projectionTemplate', projectionTemplate);
    });

    set(model, 'projections', projections);
    set(model, 'listings', dashboardModel.listings);
    set(model, 'credential', dashboardModel.credential);

    return model;
  }

  @action
  willTransition(transition) {
    const transitionToBuildProjection = transition.to.name.includes(
      'dashboard.owner-resource.projection.build-projection'
    );
    if (!transitionToBuildProjection) {
      this.ownerResource.selectedAddress = null;
      this.ownerResource.selectedBedroomSize = null;
    }
  }
}
