import moment from 'moment';

export const RELAY_USER_ROLE = {
  Staff: 1,
  Premium: 2,
  Trial: 3, // Not implemented
  Pioneer: 4,
  NonPayUser: 5,
  InactiveUser: 6,
};

/**
 * Defines a role for a Relay User.
 * This will be the base to define group permissions to features at user-level.
 */
export function setRelayUserRole(user, hasMergedListings) {
  let role = isStaffUser(user)
    ? RELAY_USER_ROLE.Staff
    : isPremiumRelayUser(user, hasMergedListings)
    ? RELAY_USER_ROLE.Premium
    : isPioneerUser(user)
    ? RELAY_USER_ROLE.Pioneer
    : isNonPayRelayUser(user)
    ? RELAY_USER_ROLE.NonPayUser
    : isInactiveRelayUser(user, hasMergedListings)
    ? RELAY_USER_ROLE.InactiveUser
    : null;

  if (!role) {
    throw new Error('Error defining Relay User Role. No conditions matched.');
  }

  return { ...user, relayRole: role };
}

export const isStaffUser = user => user.isStaff;

export const isPremiumRelayUser = (user, hasMergedListings) =>
  user.isRelayEnabled && hasMergedListings;

export const isPioneerUser = user =>
  !user.isRelayEnabled && user.isGrandfatheredRelayUser;

// Not implemented
export const isTrialUser = user => {
  if (user.isRelayEnabled) return false;
  if (isPioneerUser(user)) return false;
  if (!user.trialStartDate) return false;

  return moment().isBefore(user.trialEndDate);
};

// As we charge only on 'merged' operations, makes sense to check if the user has merged listings.
// As they may have relay enabled but simply not using it. Good to know from sales standpoint.
export const isInactiveRelayUser = (user, hasMergedListings) => {
  return user.isRelayEnabled && !hasMergedListings;
};

export const isNonPayRelayUser = user => {
  if (user.isRelayEnabled) return false;
  if (isPioneerUser(user)) return false;
  // if (isTrialUser(user)) return false;
  return true;
};
