import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import { checkPassword } from 'appkit/lib/password';
import { displayErrors } from 'appkit/lib/display_errors';
import { inject as service } from '@ember/service';

@classic
export default class SummaryRoute extends AuthRoute {
  titleToken = 'General';
  @service featureFlag;

  @action
  willTransition(_transition) {
    // Reset password and errors after successfully changing the password
    this.controller.set('errors', {});
    this.controller.set('model.password', '');
    this.controller.set('model.confirmation', '');
  }

  @action
  async onSubmit() {
    let model = this.controller.get('model');
    let userData = { password: model.get('password') };

    const { isValid, error } = checkPassword(userData.password, {
      confirmation: model.get('confirmation'),
      checkConfirmation: true,
    });

    if (!isValid) {
      this.controller.set('errors.base', error);
      return false;
    }

    this.controller.set('posting', true);
    let result = null;

    try {
      result = await this.ajax._post('/api/user', userData);
    } catch (errors) {
      displayErrors({
        errors: errors,
        modelOrKeywordThis: this.controller,
        isProperty: true,
      });
      return false;
    } finally {
      this.controller.set('posting', false);
    }

    // If the LaunchDarkly client is not initialized after changing the password
    // force them to login again in order to retrieve the LD keys.
    // This should only happen the first time a user is invited to join an account.
    if (!this.featureFlag.initialized) {
      this.transitionTo('logout');
    } else {
      this.currentUser.set('token', result.token);
      this.transitionTo('dashboard');
    }

    return false;
  }
}
