import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { displayErrors } from 'appkit/lib/display_errors';

export default class DashboardPricingJoinController extends Controller {
  @service ajax;
  @service alert;

  @action
  async activatePricing() {
    try {
      await this.ajax._post('/api/user', {
        pricingActivatedAt: new Date(),
      });

      this.send('refreshCurrentRoute');
    } catch (errors) {
      displayErrors({ errors: errors, modelOrKeywordThis: this, alert });
    }
  }
}
