import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';
import EmberObject, { action } from '@ember/object';

const permissionsErrorCodes = ['NO-GRANTS-ON-LISTINGS', 'NO-PERMISSIONS-TO-CONNECT'];

@classic
export default class ConnectRoute extends AuthRoute {
  @service('global-nav')
  globalNav;

  @service featureFlag;

  @service intl;

  @service
  alert;

  title(tokens) {
    const title = [this.intl.t('connect.linkAnotherAccount'), ...tokens].join(' / ');
    this.globalNav.setTitle(title);
    return title;
  }

  async model() {
    const responseData = await this.ajax._get('/api/accounts/short');
    const user = this.bpStore.createRecord('user', responseData.user);
    const credential = this.bpStore.createRecord('credential', responseData.credential);
    const accounts = responseData.accounts.map(account =>
      this.bpStore.createRecord('account', account)
    );
    user.set('accounts', accounts);
    accounts.forEach(account => {
      account.set('user', user);
      account.set('channelListings', () => {
        throw Error('Channel Listings not loaded');
      });
    });

    return EmberObject.create({
      user: user,
      accounts: accounts,
      credential: credential,
    });
  }

  afterModel({ user, accounts }, transition) {
    if (
      user.pricingActivatedAt === null &&
      transition.to.name === 'dashboard.connect.index'
    ) {
      return this.replaceWith('dashboard.insights.connect');
    }

    if (accounts.length === 0) {
      // some integrations use oauth and when the integration redirects
      // we want to let the redirect happen.
      // I'm not checking by any specific query params because they might
      // be different depending on the integration
      if (!transition.to.params?.channel_name) {
        return this.replaceWith('/accounts/default');
      }
    }
  }

  @action
  error(reason) {
    // Assume we only have one error when dealing with server errors on navigation.
    if (Array.isArray(reason)) {
      reason = reason[0];
    }

    // NOTE: This error event handler for the connect route exists to fix a tricky issue with grants.
    // Users with permissions and grants on listings would log in successfully and see the dashboard.
    // But if a user without grants and global permissions tried to login the generic error handler
    // would store in the `attemptedTransition` the route `dashboard.connect.index`.
    // After this, any user that only had grants, after a successful login, would be redirected to the connect route
    // and be logged out because of lack of permissions to connect accounts.
    if (
      typeof reason === 'object' &&
      reason.status === '401' &&
      permissionsErrorCodes.includes(reason.code)
    ) {
      this.alert.error(`Unauthorized: ${reason.message}`);

      this.currentUser.clear();
      this.transitionTo('login');

      return false;
    }

    // For any other kind of error, let it bubble to the parent route.
    return true;
  }
}
