import Model, { attr } from '@ember-data/model';
import { computed, set } from '@ember/object';
import { ChannelUtil } from 'appkit/lib/channel';
import fromNow from 'ember-moment/computeds/from-now';
import Util from 'appkit/lib/util';
import moment from 'moment';
import EmberObject from '@ember/object';
import { displayErrors } from 'appkit/lib/display_errors';

/**
 * Note:  This model class is not fully in use, and is part of a migration to use ember-data
 * The listing model in use by the application can be found at `client/app/bp-models/listing.js`
 */

export default class ListingModel extends Model {
  @attr basePrice;
  @attr minStay;
  @attr orphanMinStay;
  @attr orphanMinStayBuffer;
  @attr minPrice;
  @attr maxPrice;
  @attr extraGuestFee;
  @attr extraGuestThreshold;
  @attr enabled;
  @attr availabilityEnabled;
  @attr notify;
  @attr notes;
  @attr pmNotes;
  @attr reservationsEnabled;
  @attr directEnabled;
  @attr pricingType;
  @attr isOrphanChangeoverDaysEnabled;

  @attr basePriceHistory;
  @attr('array', { defaultValue: () => [] }) basePriceHistoryV2;

  @attr minPriceHistory;
  @attr('array', { defaultValue: () => [] }) minPriceHistoryV2;

  @attr('array', { defaultValue: () => [] }) nearbyListings;
  @attr('array', { defaultValue: () => [] }) calendar;

  @attr('array', { defaultValue: () => [] }) bookingReviewListings;
  @attr('array', { defaultValue: () => [] }) bookingReviewListingIds;

  @attr('array', { defaultValue: () => [] }) channelListings;
  @attr primaryChannelListing;

  @attr('boolean', { defaultValue: false }) syncing;
  @attr('boolean', { defaultValue: false }) enabled;

  @attr('boolean', { defaultValue: false }) directEnabled;

  @attr('number', { defaultValue: 2 }) pricingType;

  get primaryChannelListingIsPms() {
    return ChannelUtil.isPms(this.primaryChannelListing?.channel);
  }

  // Using double-underscore django style notation to show these are looked up via a
  // relationship. This is important for doing computed properties on primary channel
  // listing settings.
  // If this reads well, change all the above computed props to this format.
  get primaryChannelListing__channel() {
    return this.primaryChannelListing?.channel;
  }
  get primaryChannelListing__account() {
    return this.primaryChannelListing?.account;
  }
  get primaryChannelListing__listed() {
    return this.primaryChannelListing?.listed;
  }
  get primaryChannelListing__account__id() {
    return this.primaryChannelListing?.account?.id;
  }
  get primaryChannelListing__account__valid() {
    return this.primaryChannelListing?.account?.valid;
  }
  get primaryChannelListing__account__channelDisplayId() {
    return this.primaryChannelListing?.account?.channelDisplayId;
  }

  get channelListingsWithoutPrimary() {
    const primaryChannelListing = this.primaryChannelListing;
    let out = this.channelListings || [];
    out = out.filter(l => l !== primaryChannelListing);
    return out;
  }

  get channels() {
    let channelListings = this.channelListings ?? [];
    let channels = new Set(
      channelListings.map(function (cl) {
        return cl.channel;
      })
    );
    // Back to list
    return [...channels];
  }

  get isMerged() {
    return this.channelListings?.length > 1;
  }

  @attr('boolean', { defaultValue: false }) availabilityEnabled;
  get availabilityIsSupported() {
    const relayAvailabilitySupportedChannels = ChannelUtil.getRelayAvailabilitySupported();
    return relayAvailabilitySupportedChannels.includes(
      this.primaryChannelListing?.channel
    );
  }

  @attr('boolean', { defaultValue: false }) reservationsEnabled;
  get reservationsIsSupported() {
    const relayAvailabilitySupportedChannels = ChannelUtil.getRelayReservationSupported();
    return relayAvailabilitySupportedChannels.includes(
      this.primaryChannelListing?.channel
    );
  }

  @attr('moment') basePriceUpdatedAt;
  get basePriceUpdatedAtFormatted() {
    return fromNow(this.basePriceUpdatedAt, false);
  }

  get basePriceHistorySorted() {
    return this.basePriceHistoryV2
      .sort((a, b) => (a.stamp < b.stamp ? 1 : -1)) // sort by most recent
      .slice(0, 5)
      .map(h => {
        if (h.credentialId) {
          h.credential = this.store.peekRecord('credential', h.credentialId);
        }
        return h;
      });
  }

  @attr('moment') minPriceUpdatedAt;
  get minPriceUpdatedAtFormatted() {
    return fromNow(this.minPriceUpdatedAt, false);
  }

  get minPriceHistorySorted() {
    return this.minPriceHistoryV2
      .sort((a, b) => (a.stamp < b.stamp ? 1 : -1)) // sort by most recent
      .slice(0, 5)
      .map(h => {
        if (h.credentialId) {
          h.credential = this.store.peekRecord('credential', h.credentialId);
        }
        return h;
      });
  }

  @attr extraGuestFee;
  @attr extraGuestThreshold;
  @attr roundToNearestNumber;
  @attr image;

  get imageOrDefault() {
    return this.image || 'assets/home-blue.svg';
  }

  @attr('boolean', { defaultValue: true }) inActiveMarket;
  @attr('moment') lastUpdated;

  @attr('boolean', { defaultValue: false }) isPreview;
  @attr('boolean', { defaultValue: false }) isDemo;
  @attr('boolean', { defaultValue: false }) notify;

  @attr title;
  @attr address;
  @attr city;
  @attr market;
  @attr country;
  @attr('string', { defaultValue: 'USD' }) currency;

  @attr('number', { defaultValue: 0 }) bookedThirty;
  @attr('number', { defaultValue: 0 }) bookedNinety;
  @attr('number', { defaultValue: 0 }) totalEarnings;
  @attr('number', { defaultValue: 0 }) healthScore;

  @attr('number', { defaultValue: 0 }) bedrooms;
  @attr('number', { defaultValue: 0 }) bathrooms;

  get bathroomsNoDecimal() {
    if (!this.bathrooms) {
      return '-';
    }
    if (this.bathrooms % 1 != 0) {
      return this.bathrooms;
    } else {
      return parseInt(this.bathrooms);
    }
  }

  get searchTerm() {
    return `${this.id}:${this.title}`;
  }

  // Stats
  @attr statistics;
  @attr statisticsUpdatedAt;
  @attr notes;
  @attr pmNotes;

  @computed(
    'statistics.{oneWeekReservationsCount,twoWeekReservationsCount,twoWeekReservationsDays,daysHittingAnyMin,daysAvailable,daysBooked,daysBlocked,daysUnavailable,daysLive,enabledReservationsCount}'
  )
  get hasStatistics() {
    return this.statistics && !Util.isObjectEmpty(this.statistics);
  }

  get statisticsIsOutdated() {
    const now = moment();
    const statisticsUpdatedAt = moment(this.statisticsUpdatedAt);
    const diff = now.diff(statisticsUpdatedAt, 'hours', true);
    // if user loaded statistics more than 24h/1d ago, it is outdated
    return diff > 24;
  }

  get lastLoadedDate() {
    return moment(this.statisticsUpdatedAt).calendar();
  }

  @computed(
    'statistics.{daysHittingAnyMin,daysHittingAnyMax,daysAvailable,daysBooked,daysBlocked,daysUnavailable}'
  )
  get ninetyDaysPercentages() {
    let daysAvailable = '-';
    let daysBooked = '-';
    let daysBlocked = '-';
    let daysUnavailable = '-';
    let daysHittingMin = '-';
    let daysHittingMax = '-';

    if (this.hasStatistics) {
      daysHittingMin = Math.round((100 * this.statistics.daysHittingAnyMin) / 90);
      daysHittingMax = Math.round((100 * this.statistics.daysHittingAnyMax) / 90);
      daysAvailable = Math.round((100 * this.statistics.daysAvailable) / 90);
      daysBooked = Math.round((100 * this.statistics.daysBooked) / 90);
      daysBlocked = Math.round((100 * this.statistics.daysBlocked) / 90);
      daysUnavailable = 100 - daysAvailable;
    }

    return {
      daysHittingMin: daysHittingMin,
      daysHittingMax: daysHittingMax,
      daysAvailable: daysAvailable,
      daysBooked: daysBooked,
      daysBlocked: daysBlocked,
      daysUnavailable: daysUnavailable,
    };
  }

  // bookingReview
  @computed('bookingReviewListings.@each.status')
  get alreadyReviewedBookingReviews() {
    if (!this.bookingReviewListings.length) {
      return this.bookingReviewListings;
    }

    return this.bookingReviewListings.filter(brArr => brArr.isAlreadyReviewed);
  }

  @computed(
    'alreadyReviewedBookingReviews.@each.status',
    'basePriceHistoryV2.[]',
    'minPriceHistoryV2.[]'
  )
  get previousChanges() {
    const basePriceWithLabel = this.basePriceHistoryV2.map(bp => {
      bp['type'] = 'basePrice';
      return bp;
    });

    const minPriceWithLabel = this.minPriceHistoryV2.map(mp => {
      mp['type'] = 'minPrice';
      return mp;
    });

    const combinedPrices = [
      ...this.alreadyReviewedBookingReviews,
      ...basePriceWithLabel,
      ...minPriceWithLabel,
    ];

    // booking reviews – group by date
    const bookingReviewsGroupedByDate = combinedPrices.reduce((accum, br) => {
      let date;
      if (Object.prototype.hasOwnProperty.call(br, 'createdAt')) {
        date = moment(br.createdAt).format('MMM DD, YYYY');
      } else {
        date = moment(br.stamp).format('MMM DD, YYYY');
      }
      if (!Object.prototype.hasOwnProperty.call(accum, date)) {
        accum[date] = [br];
      } else {
        accum[date].push(br);
      }
      return accum;
    }, {});

    // convert back to array & sort by date in desc order
    return Object.keys(bookingReviewsGroupedByDate)
      .map(key => {
        return { date: key, bookingReviewListings: bookingReviewsGroupedByDate[key] };
      })
      .sort((a, b) => moment(b.date).diff(a.date));
  }

  @computed('bookingReviewListings.[]')
  get mostRecentBookingReview() {
    if (this.bookingReviewListings.length === 0) return null;

    return this.bookingReviewListings.sort((a, b) =>
      moment(b.createdAt).diff(a.createdAt)
    )[0];
  }

  @attr('number') basePrice;
  @attr('number') maxPrice;
  @attr('number') minPrice;
  @attr({ defaultValue: () => ({}) }) validationErrors;

  get isValid() {
    return Object.keys(this.validationErrors).length === 0;
  }

  @computed('channelListing.@each.channelLabel')
  get allChannelLabels() {
    const channels = this.channelListings.map(cl => cl.get('channelLabel'));
    if (channels.length === 1) {
      return channels[0];
    }
    const last = channels.pop();
    return channels.join(', ') + ' & ' + last;
  }

  validateInput() {
    this.validationErrors = EmberObject.create();

    let minPrice = this.minPrice;
    let maxPrice = this.maxPrice;
    let basePrice = this.basePrice;

    if (minPrice && maxPrice) {
      if (minPrice > maxPrice) {
        set(
          this.validationErrors,
          'minPrice',
          `Minimum Price must be lower than Default Maximum Price ($${maxPrice} in Customize Page)`
        );
      }
    }

    if (basePrice < 10) {
      set(this.validationErrors, 'basePrice', 'Base Price must be at least $10');
    }

    // Figure out if some channel listings cannot be synced
    const changed = this.changedAttributes();
    const prevEnabled = changed.enabled?.[0];
    if (this.enabled && !prevEnabled) {
      const unlistedChannelListings = this.channelListings.filter(
        cl => !cl.get('listed')
      );

      if (unlistedChannelListings.length > 0) {
        let channels = unlistedChannelListings
          .map(cl => cl.get('channelLabel'))
          .join(', ');
        let errorMessage = `You must activate your listing on ${channels} to be able to sync prices.`;
        set(this.validationErrors, 'channelListings', errorMessage);
      }
    }

    return this.validationErrors;
  }

  // Only offer to save prices when things have changed.
  get pricesDirty() {
    const changed = this.changedAttributes();

    if (changed.basePrice) {
      return true;
    }

    if (changed.minPrice) {
      return true;
    }

    if (changed.maxPrice) {
      return true;
    }

    return false;
  }

  // Permissions
  get canEdit() {
    return this.permissions === 'edit' || this.permissions === 'admin';
  }

  get canAdministrate() {
    return this.permissions === 'admin';
  }

  @computed(
    'createdAt',
    'isListed',
    'inActiveMarket',
    'basePriceHistoryV2',
    'minPriceHistoryV2'
  )
  get isNewListing() {
    // This is the moment we launched this feature
    // Some users have a ton of "new listings" that they've just never set up.
    // We'll want to let users dismiss these for a few reasons eventually
    // but for now we can keep the dashboard clean this way
    if (moment.utc(this.createdAt) < moment.utc('2020-03-01')) {
      return false;
    }
    if (!this.isListed || !this.inActiveMarket) {
      return false;
    }
    return !(this.basePriceHistoryV2.length || this.minPriceHistoryV2.length);
  }

  _cleanStr(str = null) {
    if (!str) {
      return str;
    }
    return str
      .toLowerCase() // convert to lowercase
      .replace(/\s/g, '') // remove all spaces
      .replace(/[^\w\s]/gi, ''); // remove all special characters
  }

  async saveWithValidation() {
    let result = this.validateInput();

    if (Object.keys(result).length !== 0) {
      for (let key of Object.keys(result)) {
        this.alert.error(result[key], { timeout: 10000 });
      }
      return false;
    }

    try {
      await this.save();
    } catch (errors) {
      displayErrors({ errors: errors, modelOrKeywordThis: this, alert: alert });
      return false;
    }

    return true;
  }

  // Merge
  @attr('boolean', { defaultValue: false }) selected;

  get cleanAddress() {
    return this._cleanStr(this.address);
  }

  get cleanTitle() {
    return this._cleanStr(this.title);
  }

  @computed('channelListings.@each.listed')
  get isListed() {
    return this.channelListings.some(cl => cl.listed);
  }
}
