import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import { tracked } from '@glimmer/tracking';

@classic
export default class UserRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service featureFlag;

  @tracked emberDataEnabled = false;

  title(tokens) {
    let title = null;

    if (!tokens || !tokens.length) {
      title = 'User Settings';
    } else {
      title = `User Settings / ${tokens}`;
    }

    this.globalNav.setTitle(title);
    return title;
  }

  async beforeModel() {
    this.emberDataEnabled = await this.featureFlag.evaluate('ember-data', false);
  }

  async model() {
    if (this.emberDataEnabled) {
      return this.store.queryRecord('user', {});
    } else {
      return this.ajax._get('/api/user').then(data => {
        let user = this.bpStore.createRecord('user', data.user);

        user.set(
          'accounts',
          user.get('accounts').map(account => {
            let obj = this.bpStore.createRecord('account', account);
            obj.set('user', user);
            return obj;
          })
        );

        user.set(
          'listings',
          user.get('listings')?.map(listing => {
            let obj = this.bpStore.createRecord('listing', listing);
            obj.set('user', user);
            return obj;
          }) ?? []
        );

        user.set(
          'permissions',
          user
            .get('permissions')
            .map(permission => {
              let obj = this.bpStore.createRecord('permission', permission);
              obj.set(
                'credential',
                this.bpStore.peekRecord('credential', obj.credentialId)
              );
              obj.set('listing', this.bpStore.peekRecord('listing', obj.listingId));

              return obj;
            })
            .filter(permission => permission.listing)
        );

        user.set(
          'credentials',
          user.get('credentials').map(credential => {
            let obj = this.bpStore.createRecord('credential', credential);
            obj.set('user', user);

            obj.set(
              'permissions',
              obj
                .get('permissionIds')
                .map(permissionId => {
                  let obj = this.bpStore.peekRecord('permission', permissionId);
                  return obj;
                })
                .filter(permission => permission.listing)
            );

            return obj;
          })
        );

        user.set(
          'cards',
          data.user.cards.map(cardData => EmberObject.create(cardData))
        );
        user.get('cards').sort((a, b) => b.get('id') - a.get('id'));

        let credential = user.get('credential');
        user.set('credential', this.bpStore.createRecord('credential', credential));
        return user;
      });
    }
  }
}
