import { VARIANTS, COLORS } from '../theme';

const variantClasses = ({ wrap }) => ({
  [VARIANTS.ALL]: {
    [COLORS.ALL]: ['flex', wrap && 'flex-wrap', 'ml-px', '-mt-1'],
  },
});

export default {
  root: {
    variantClasses,
  },
};
