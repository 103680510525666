import { CHANNEL } from './channel_configuration';

export function getPMSList(supercontrolFFEnabled, trackFFEnabled) {
  let list = [
    CHANNEL.ESCAPIA,
    CHANNEL.LIVEREZ_DIRECT,
    CHANNEL.GUESTY,
    CHANNEL.HOSTIFY,
    CHANNEL.FAKE_PMS,
    CHANNEL.STREAMLINE,
    CHANNEL.VRM,
    CHANNEL.SMOOBU,
    CHANNEL.HOSTFULLY,
    CHANNEL.LODGIX,
    CHANNEL.OWNERREZ,
    CHANNEL.LIGHTMAKER,
  ];

  if (supercontrolFFEnabled) list.push(CHANNEL.SUPERCONTROL);
  if (trackFFEnabled) list.push(CHANNEL.TRACK);

  return list;
}

const ACCOUNTS_LIST = [
  CHANNEL.AIRBNB,
  CHANNEL.VRBO,
  CHANNEL.BOOKING,
  CHANNEL.BOOKING_CONNECT,
  CHANNEL.FAKE,
];

export default function showRelayRegisterInterest(
  accounts,
  user,
  supercontrolFFEnabled,
  trackFFEnabled,
  isUncontractedEnabled
) {
  let isValidForRelay = false;
  let hasAccountsConnected = false;
  const PMS = getPMSList(supercontrolFFEnabled, trackFFEnabled);
  for (const acc of accounts) {
    if (PMS.indexOf(acc.channel) > -1) isValidForRelay = true;
    if (ACCOUNTS_LIST.includes(acc.channel)) hasAccountsConnected = true;
  }

  if (user.sfAccountId === null && isUncontractedEnabled) isValidForRelay = true;

  return (
    user.isRelayEnabled &&
    !user.firstListingMergedAt &&
    isValidForRelay &&
    !hasAccountsConnected
  );
}
