import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import Util from 'appkit/lib/util';

@classic
export default class IndexController extends Controller {
  videoVisible = false;
  errorMessage = null;
  submittingNotifyForm = false;

  @action
  nextInputOrSubmit() {
    return Util.nextInputOrSubmit.call(this, window.event);
  }

  @action
  submitNotifyForm() {
    this.set('errorMessage', null);

    if (!this.get('city.length')) {
      this.set('errorMessage', 'City must be present');
      return false;
    }

    this.set('submittingNotifyForm', true);
    Util.isEmailInvalid(this.email).then(reason => {
      if (reason) {
        this.set('errorMessage', reason);
        this.set('submittingNotifyForm', false);
        return false;
      }

      this.ajax
        ._post('/api/contact', {
          email: this.email,
          reason: 'Home page notify signup',
          city: this.city,
        })
        .then(() => {
          this.set('submittingNotifyForm', false);
          this.set('submitted', true);
        });
    });

    return false;
  }

  @action
  loadVideo() {
    this.set('videoVisible', true);
  }
}
