import classic from 'ember-classic-decorator';
import { on } from '@ember-decorators/object';
// eslint-disable-next-line ember/no-observers
import { action, computed, observer, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { empty } from '@ember/object/computed';
import { getOwner } from '@ember/application';
import Controller from '@ember/controller';
import { ValueRange, RangeValidator } from 'appkit/lib/value_range';
import { copy } from 'ember-copy';
import moment from 'moment';
import logger from 'appkit/lib/logger';
import { CHANNEL, CHANNEL_CONFIG, DATA_TYPE } from 'appkit/lib/channel_configuration';
import {
  CHANGEOVER_DAYS,
  WEEK_DAYS,
  RANGE_DEFAULT,
  FIELD_PANE_MAP,
  DEFAULT_DISCOUNTS,
  DEFAULT_ORPHAN_DAYS,
} from 'appkit/lib/customize';
import { ChannelUtil } from 'appkit/lib/channel';
import { displayErrors } from 'appkit/lib/display_errors';
import { tracked } from '@glimmer/tracking';

@classic
export default class CustomizeLegacyController extends Controller {
  @service
  alert;

  @service
  intl;

  @service
  featureFlag;

  showMinMaxPricesContent = false;
  showSeasonalMinMaxPricesRanges = false;
  showSeasonalMinPricesRanges = false;
  showLastMinuteDiscountsContent = false;
  showOrphanDaysContent = false;
  showGuestFeesContent = false;
  showGuestFeesInput = false;
  showMinStayContent = false;
  showMinStayRanges = false;
  showMaxStayContent = false;
  showMaxStayRanges = false;
  showChangeoverContent = false;
  showChangeoverRanges = false;
  showSyncCalendarsContent = false;
  showChangeover = true;

  changeoverDays = CHANGEOVER_DAYS;
  weekDays = WEEK_DAYS;
  defaultChangeoverValue = WEEK_DAYS;

  @computed('model.lastMinuteMinStays.[]')
  get lastMinuteMinStays() {
    let forTheNextRanges = [
      ...this.get('model.lastMinuteMinStays')
        .filter(c => c.direction == 'in')
        .sort((a, b) => a.daysAway < b.daysAway),
    ];
    let afterRanges = [
      ...this.get('model.lastMinuteMinStays')
        .filter(c => c.direction == 'out')
        .sort((a, b) => a.daysAway > b.daysAway),
    ];
    const minStays = [...forTheNextRanges, ...afterRanges];
    return minStays;
  }

  discounts = [];
  orphanDays = [];
  saving = false;
  showValidationErrors = false;
  @tracked formHasErrors = false;
  discountsHasErrors = false;
  orphanDaysHasErrors = false;
  enableMinCheckBoxValue = null;
  disableMinCheckBoxValue = null;
  enableMaxCheckBoxValue = null;
  disableMaxCheckBoxValue = null;
  importCalendarUrlsHasErrors = false;

  get isStaff() {
    return this.currentUser.staffUser && this.currentUser.staffUser.email;
  }

  @computed('model.{minPrice,maxPrice}')
  get minMaxPriceDefaultArePristine() {
    return !(this.get('model.minPrice') > 0 || this.get('model.maxPrice') > 0);
  }

  @computed(
    'model.{minPriceRanges,minMaxPriceRanges,minPriceRanges.[],minMaxPriceRanges.[]}'
  )
  get minMaxPriceRangesArePristine() {
    return !(
      this.get('model.minPriceRanges').length > 0 ||
      this.get('model.minMaxPriceRanges').length > 0
    );
  }

  @computed('model.{extraGuestFee,extraGuestThreshold}')
  get extraGuestDefaultArePristine() {
    return !(
      this.get('model.extraGuestFee') > 0 && this.get('model.extraGuestThreshold') > 0
    );
  }

  @computed('model.{discounts,discounts.[]}')
  get discountsDefaultArePristine() {
    let currentDiscounts = this.get('model.discounts');
    if (currentDiscounts.length !== DEFAULT_DISCOUNTS.length) return false;

    return DEFAULT_DISCOUNTS.every(defaultDiscount => {
      return currentDiscounts.some(currentDiscount => {
        return (
          defaultDiscount.days === currentDiscount.days &&
          defaultDiscount.percentage === currentDiscount.percentage &&
          defaultDiscount.direction === currentDiscount.direction
        );
      });
    });
  }

  @computed('model.{orphanDays,orphanDays.[]}')
  get orphanDaysDefaultArePristine() {
    let currentOrphanDays = this.get('model.orphanDays');

    if (currentOrphanDays.length === 0) return true; // means there are no settings at all so the checkmark on Annual should be grey color

    if (currentOrphanDays.length !== DEFAULT_ORPHAN_DAYS.length) return false;

    return DEFAULT_ORPHAN_DAYS.every(defaultOrphanDay => {
      return currentOrphanDays.some(currentOrphanDay => {
        return (
          defaultOrphanDay.days === currentOrphanDay.days &&
          defaultOrphanDay.percentage === currentOrphanDay.percentage &&
          defaultOrphanDay.ignoreOnWeekdays === currentOrphanDay.ignoreOnWeekdays
        );
      });
    });
  }

  @computed('model.minStay')
  get minStayDefaultArePristine() {
    return !(this.get('model.minStay') > 0);
  }

  @computed('model.maxStay')
  get maxStayDefaultArePristine() {
    return !(this.get('model.maxStay') > 0);
  }

  @computed('model.{minStayRanges,minStayRanges.[]}')
  get minStayRangesRangesArePristine() {
    return !(this.get('model.minStayRanges').length > 0);
  }

  @computed('model.{maxStayRanges,maxStayRanges.[]}')
  get maxStayRangesRangesArePristine() {
    return !(this.get('model.maxStayRanges').length > 0);
  }

  @computed('model.{checkinDays,checkoutDays}')
  get checkinCheckoutDaysDefaultArePristine() {
    return false;
  }

  @computed('model.{checkinCheckoutDaysRanges,checkinCheckoutDaysRanges.[]}')
  get checkinCheckoutDaysRangesArePristine() {
    return !(this.get('model.checkinCheckoutDaysRanges').length > 0);
  }

  @computed('model.{checkinDaysRanges,checkinDaysRanges.[]}')
  get checkinDaysRangesArePristine() {
    return !(this.get('model.checkinDaysRanges').length > 0);
  }

  @computed('model.importCalendarUrls.[]')
  get importCalendarUrlsArePristine() {
    return this.get('model.importCalendarUrls').length === 0;
  }

  @computed('saving', 'model.isDirty', 'formHasErrors')
  get saveButtonColor() {
    if (this.get('model.isDirty') && !(this.saving || this.formHasErrors)) {
      return 'btn-green';
    } else {
      return 'btn-disabled';
    }
  }

  _showDefaultMaxPrice = false;

  @computed('_showDefaultMaxPrice', 'model.maxPrice')
  get showDefaultMaxPriceInput() {
    return this._showDefaultMaxPrice || !!this.get('model.maxPrice');
  }

  set showDefaultMaxPriceInput(value) {
    this.set('_showDefaultMaxPrice', value);
    return this._showDefaultMaxPrice || !!this.get('model.maxPrice');
  }

  _showDefaultMinPrice = false;

  @computed('_showDefaultMinPrice', 'model.minPrice')
  get showDefaultMinPriceInput() {
    return this._showDefaultMinPrice || !!this.get('model.minPrice');
  }

  set showDefaultMinPriceInput(value) {
    this.set('_showDefaultMinPrice', value);
    return this._showDefaultMinPrice || !!this.get('model.minPrice');
  }

  @computed('model.isCheckoutEnabled', 'listing.primaryChannelListing.channel')
  get canCustomizeCheckoutDays() {
    // This temporary hack: We support Airbnb checkout_days_for_listing feature but we must not set 'is_checkout_days_enabled'
    // flag to True because the same user can have Escapia MA that must have 'is_checkout_days_enabled' = False
    // TODO: In client/app/lib/channel.js define explicit configurations of checkin/checkout days for different channels
    return (
      this.get('model.isCheckoutEnabled') ||
      this.listing.primaryChannelListing.channel === CHANNEL.AIRBNB
    );
  }

  get enableCustomizeChangeoverForNonePMS() {
    return this.showChangeover;
  }

  @computed('listing.primaryChannelListing.channel')
  get isPMS() {
    const primaryChannel = this.listing.primaryChannelListing.channel;
    return ChannelUtil.isPms(primaryChannel);
  }
  @computed('listing.primaryChannelListing.channel')
  get isPMSorDC() {
    const primaryChannel = this.listing.primaryChannelListing.channel;
    return ChannelUtil.isPms(primaryChannel) || [CHANNEL.VRBO].includes(primaryChannel);
  }

  @computed('listing.channelNames')
  get isAirbnbWithPMS() {
    const channels = this.listing.channelNames;
    return channels.includes(CHANNEL.AIRBNB) && this.isPMS;
  }

  @computed('listing.channelNames')
  get isAirbnbNoPMS() {
    const channels = this.listing.channelNames;
    return channels.includes(CHANNEL.AIRBNB) && !this.isPMS;
  }

  @computed('listing.primaryChannelListing.channel')
  get seasonalSingleCheckin() {
    return this.listing.primaryChannelListing.channel === CHANNEL.AIRBNB;
  }

  @empty('model.minStayRanges')
  canRemoveListingMinStay;

  @empty('model.maxStayRanges')
  canRemoveListingMaxStay;

  @computed('model.{checkinCheckoutChannelTerminology,isCheckoutEnabled}')
  get changeoverName() {
    const checkoutEnabled = this.get('model.isCheckoutEnabled');
    const checkinChannelTerminology = this.get(
      'model.checkinCheckoutChannelTerminology'
    );
    if (!checkinChannelTerminology) {
      return '';
    }

    if (checkinChannelTerminology === 'changeover' || !checkoutEnabled) {
      return 'changeoverDay';
    }

    return 'checkinDays';
  }

  get checkoutName() {
    return 'checkoutDays';
  }

  changeoverNameForAirbnb = 'checkinDays';

  @computed('model.{minStayRanges.[],minStay}')
  get showListingMinStay() {
    return (
      this.get('model.minStay') !== null || this.get('model.minStayRanges').length > 0
    );
  }

  @computed('model.{maxStayRanges.[],maxStay}')
  get showListingMaxStay() {
    return (
      this.get('model.maxStay') !== null || this.get('model.maxStayRanges').length > 0
    );
  }

  @computed('model.{minPrice,maxPrice}', 'showValidationErrors')
  get invalidMinMaxListingPrices() {
    const isDefined = val => val !== null && val !== undefined && val !== '';
    const minPrice = this.get('model.minPrice');
    const maxPrice = this.get('model.maxPrice');
    let ret = false;

    if (isDefined(minPrice) && isDefined(maxPrice)) {
      ret = Number(maxPrice) < Number(minPrice);
    }
    return ret && this.showValidationErrors;
  }

  @computed(
    'model.checkinCheckoutChannelTerminology',
    'listing.primaryChannelListing.channel'
  )
  get canCustomizeChangeover() {
    let hideChangeoverStatus = this.hideMinStay;
    let channel = this.get('listing.primaryChannelListing.channel');
    let channelsSupportedStaffOnly = [CHANNEL.AIRBNB];
    let isMinStaysEnabledInDb = this.get('model.isMinStayEnabled');

    const checkinChannelTerminology = this.get(
      'model.checkinCheckoutChannelTerminology'
    );

    if (hideChangeoverStatus) {
      return false;
    }
    if (isMinStaysEnabledInDb && channelsSupportedStaffOnly.includes(channel)) {
      return true;
    }
    if (
      channelsSupportedStaffOnly.includes(channel) &&
      this.isStaff &&
      !isMinStaysEnabledInDb
    ) {
      return true;
    }
    if (
      ['checkin', 'changeover'].includes(checkinChannelTerminology) &&
      !channelsSupportedStaffOnly.includes(channel)
    ) {
      return true;
    }

    return false;
  }

  @computed('model.checkinCheckoutSeasonal')
  get showSeasonalCheckinCheckout() {
    return this.get('model.checkinCheckoutSeasonal');
  }

  @computed('listing.primaryChannelListing.channel')
  get canCustomizeMinStay() {
    let channel = this.get('listing.primaryChannelListing.channel');
    let minStaysAreNotHidden = !this.hideMinStay;
    let isMinStaysEnabledInDb = this.get('model.isMinStayEnabled');
    let channelsSupported = [CHANNEL.GUESTY, CHANNEL.AIRBNB];
    let channelsSupportedStaffOnly = [CHANNEL.STREAMLINE];

    return (
      isMinStaysEnabledInDb ||
      (channelsSupported.includes(channel) && minStaysAreNotHidden) ||
      (channelsSupportedStaffOnly.includes(channel) &&
        minStaysAreNotHidden &&
        this.isStaff)
    );
  }

  @computed('listing.primaryChannelListing.channel')
  get canCustomizeSeasonalMinStay() {
    let channel = this.get('listing.primaryChannelListing.channel');
    let minStaysAreNotHidden = !this.hideMinStay;
    let isMinStaysEnabledInDb = this.get('model.isMinStayEnabled');
    let channelsNotSupported = [CHANNEL.KIGO];
    let channelsSupported = [CHANNEL.GUESTY, CHANNEL.AIRBNB];
    let channelsSupportedStaffOnly = [CHANNEL.STREAMLINE];

    return (
      // Can customize min stays if  minStaysAreNotHidden = True and (if is_min_stay_enabled = True and channel not Kigo)
      // or (if Guesty) or (if Airbnb and staff = True)
      ((isMinStaysEnabledInDb && !channelsNotSupported.includes(channel)) ||
        channelsSupported.includes(channel) ||
        (channelsSupportedStaffOnly.includes(channel) && this.isStaff)) &&
      minStaysAreNotHidden
    );
  }

  @computed(
    'listing.primaryChannelListing.channel',
    'hideMaxStay',
    'model.isMaxStayEnabled'
  )
  get canCustomizeMaxStay() {
    let channel = this.get('listing.primaryChannelListing.channel');
    let maxStaysAreNotHidden = this.hideMaxStay === 'false';
    let isMaxStaysEnabledInDb = this.get('model.isMaxStayEnabled');
    let channelsSupported = [];
    let channelsSupportedStaffOnly = [CHANNEL.VREASY];

    return (
      isMaxStaysEnabledInDb ||
      (channelsSupported.includes(channel) && maxStaysAreNotHidden) ||
      (channelsSupportedStaffOnly.includes(channel) &&
        maxStaysAreNotHidden &&
        this.isStaff)
    );
  }

  @computed('listing.primaryChannelListing.channel')
  get canCustomizeGuestFees() {
    let channel = this.get('listing.primaryChannelListing.channel');
    let channelsSupported = [
      CHANNEL.ESCAPIA,
      CHANNEL.FAKE,
      CHANNEL.FAKE_PMS,
      CHANNEL.TRACK,
      CHANNEL.HOSTFULLY,
      CHANNEL.SECRA,
      CHANNEL.RENTALS_UNITED,
      CHANNEL.LODGIFY,
      CHANNEL.AVANTIO,
      CHANNEL.TOWNEBANK,
      CHANNEL.VILLAS365,
    ];
    return channelsSupported.includes(channel);
  }

  @computed('listing.channelListings.@each.channel')
  get canSyncCalendars() {
    let channelsSupported = [CHANNEL.BOOKING_CONNECT, CHANNEL.FAKE, CHANNEL.FAKE_PMS];
    let channelListings = this.get('listing.channelListings');

    if (channelListings.length > 1) {
      return false;
    }

    return channelsSupported.includes(channelListings[0].channel);
  }

  userEnabledMinStaysAnswer = null;
  userEnabledMaxStaysAnswer = null;

  @computed('listing.primaryChannelListing.channel', 'model.isMinStayEnabled')
  get showMinStayMessage() {
    let channel = this.get('listing.primaryChannelListing.channel');
    let channelsSupported = [CHANNEL.GUESTY, CHANNEL.AIRBNB];
    let channelsSupportedStaffOnly = [CHANNEL.STREAMLINE];

    return (
      (channelsSupported.includes(channel) ||
        (channelsSupportedStaffOnly.includes(channel) && this.isStaff)) &&
      !this.get('model.isMinStayEnabled')
    );
  }

  @computed('listing.primaryChannelListing.channel', 'model.isMaxStayEnabled')
  get showMaxStayMessage() {
    let channel = this.get('listing.primaryChannelListing.channel');
    let channelsSupported = [];
    let channelsSupportedStaffOnly = [CHANNEL.VREASY];

    return (
      (channelsSupported.includes(channel) ||
        (channelsSupportedStaffOnly.includes(channel) && this.isStaff)) &&
      !this.get('model.isMaxStayEnabled')
    );
  }

  @computed('listing.primaryChannelListing.channel', 'model.isMinStayEnabled')
  get showChangeoverMinStayMessage() {
    let channel = this.get('listing.primaryChannelListing.channel');
    return channel === CHANNEL.AIRBNB && !this.get('model.isMinStayEnabled');
  }

  @computed
  get hideMinStay() {
    const ma = Number(this.get('listing.primaryChannelListing.managedAccountId'));
    const hideMinStay = localStorage.getItem('hideMinStay');

    if (hideMinStay === 'true') {
      localStorage.setItem('hideMinStay', ma);
    }

    const hideIds = localStorage.getItem('hideMinStay') || '';
    const maIds = hideIds
      .split(',')
      .map(id => Number(id))
      .filter(id => id);
    return maIds.includes(ma);
  }

  set hideMinStay(value) {
    const ma = Number(this.get('listing.primaryChannelListing.managedAccountId'));
    if (value) {
      const hideIds = localStorage.getItem('hideMinStay') || '';
      const maIds = [
        ...new Set(
          hideIds
            .split(',')
            .map(id => Number(id))
            .filter(id => id)
            .concat([ma])
        ),
      ];

      localStorage.setItem('hideMinStay', maIds.join(','));
    } else {
      localStorage.removeItem('hideMinStay');
    }
  }

  @computed
  get hideMaxStay() {
    if (localStorage.getItem('hideMaxStay') === null) {
      localStorage.setItem('hideMaxStay', false);
    }
    return localStorage.getItem('hideMaxStay');
  }

  set hideMaxStay(value) {
    if (value) {
      localStorage.setItem('hideMaxStay', value);
    } else {
      localStorage.removeItem('hideMaxStay');
    }
    return localStorage.getItem('hideMaxStay');
  }

  showLoadingListing = true;
  listingStatus = '';

  customizingExtraGuestSettings = false;

  _initExtraGuestSettings = on(
    'init',
    // eslint-disable-next-line ember/no-observers
    observer('model', 'model.extraGuestFee', 'model.extraGuestThreshold', function () {
      let customizing = !!(
        this.get('model.extraGuestFee') || this.get('model.extraGuestThreshold')
      );
      this.set('customizingExtraGuestSettings', customizing);
    })
  );

  @computed('saving')
  get saveButtonText() {
    if (this.saving) {
      return this.intl.t('common.saving');
    } else {
      return this.intl.t('common.saveChanges');
    }
  }

  customizingLastMinute = false;

  // TODO: this will read a lot nicer if we used didReceiveAttrs
  transformCheckinValue(value) {
    if (!value) {
      return null;
    }
    let val = value;
    if (this.get('model.checkinCheckoutOptions') == 'single' && !Array.isArray(val)) {
      // The value we receive is a comma separated string
      // Which is a limitation over the values of a HTML select element
      // Hence, we need to convert back to array value
      val = value.split(',');
    } else {
      if (val && val.length === 0) {
        val = null;
      }
    }
    return val;
  }

  buildCleanRanges() {
    const cleanRanges = {
      minStayRanges: null,
      maxStayRanges: null,
      minPriceRanges: null,
      maxPriceRanges: null,
      minMaxPriceRanges: null,
      checkinDaysRanges: null,
      checkinCheckoutDaysRanges: null,
    };

    Object.keys(cleanRanges).forEach(rangeName => {
      const ranges = this.get(`model.${rangeName}`);
      cleanRanges[rangeName] = RangeValidator.removeEmpty(ranges);
    });

    return cleanRanges;
  }

  checkFormForErrors(valid, fields, ranges) {
    if (valid) {
      this.set(`model.${fields}`, copy(ranges, true));
      this.set('formHasErrors', !valid);
    } else {
      this.set('formHasErrors', !valid);
    }
  }

  @computed('model.{orphanMinStay,orphanMinStayBuffer}')
  get gapFillChecked() {
    return this.model.orphanMinStayBuffer !== null || this.model.orphanMinStay !== null;
  }

  // When header div is clicked, show that pane
  @action
  toggleContent(value, fields, rangeName) {
    let visible = this.set(value, !this.get(value));

    let ranges = [];
    if (rangeName) {
      ranges = this.get(rangeName);
    }
    // When collapsing, remove any extra empty ranges
    if (
      !visible &&
      RangeValidator.removeEmpty(ranges).length === 0 &&
      rangeName !== undefined
    ) {
      this.set(rangeName, []);
    }
  }

  @computed('monthlyFeatureFlagEnabled', 'listing.primaryChannelListing.channel')
  get canPushMonthlyPricing() {
    const channel = this.get('listing.primaryChannelListing.channel');

    // TODO: Remove this after we make sure that all PMSs that support monthly pricing are working
    const ALLOWED_CHANNELS = [CHANNEL.ESCAPIA, CHANNEL.FAKE_PMS, CHANNEL.TRACK];
    if (!ALLOWED_CHANNELS.includes(channel)) return false;

    const channelConfig = CHANNEL_CONFIG[channel];
    const channelCanPushMonthly = channelConfig.canPush.includes(
      DATA_TYPE.MONTHLY_PRICE
    );

    return channelCanPushMonthly && this.monthlyFeatureFlagEnabled;
  }

  @action
  addRange(ranges, fields) {
    const defaultData = {
      startDate: moment(),
      endDate: moment(),
      fields: fields,
    };
    fields.split(' ').forEach(field => {
      // eslint-disable-next-line no-prototype-builtins
      if (!RANGE_DEFAULT.hasOwnProperty(field)) {
        throw Error('Field not found');
      }

      defaultData[field] = ['minPrice', 'monthlyMinPrice'].includes(field)
        ? this.model[field]
        : RANGE_DEFAULT[field];
    });
    ranges.pushObject(ValueRange.create(defaultData));
    this.rangeUpdated(ranges, fields);
  }

  @action
  removeRange(ranges, rangeVisible, fields, range) {
    ranges.removeObject(range);
    this.model.validateForm(this.intl);
  }

  @action
  setMinPrice(value) {
    this.set('model.minPrice', value);
    let valid = this.model.validateInput();
    this.set('formHasErrors', !valid);
  }

  @action
  setMaxPrice(value) {
    this.set('model.maxPrice', value);
    let valid = this.model.validateInput();
    this.set('formHasErrors', !valid);
  }

  @action
  setExtraGuestFee(value) {
    this.set('model.extraGuestFee', value);
    let valid = this.model.validateInput();
    this.set('formHasErrors', !valid);
  }

  @action
  setExtraGuestThreshold(value) {
    this.set('model.extraGuestThreshold', value);
    let valid = this.model.validateInput();
    this.set('formHasErrors', !valid);
  }

  @action
  setMinStay(value) {
    this.set('model.minStay', value);
    let valid = this.model.validateInput();
    this.set('formHasErrors', !valid);
  }

  @action
  setMaxStay(value) {
    this.set('model.maxStay', value);
    let valid = this.model.validateInput();
    this.set('formHasErrors', !valid);
  }

  @action
  setOrphanMinStay(field, evt) {
    this.set(`model.${field}`, Number(evt.target.value));
    let valid = this.model.validateInput();
    this.set('formHasErrors', !valid);
  }

  @action
  toggleRemoveAddBtn(btnToShow, model, state) {
    this.set(btnToShow, state);
    if (!state) {
      this.set(model, null);
    }
  }

  @action
  deleteExtraGuestSettings() {
    this.set('model.extraGuestFee', null);
    this.set('model.extraGuestThreshold', null);
    this.set('customizingExtraGuestSettings', false);
  }

  @action
  customizeExtraGuestSettings() {
    this.set('customizingExtraGuestSettings', true);
  }

  @action
  rangeUpdated(fields, ranges) {
    let valid = this.model.validateForm(this.intl);
    this.checkFormForErrors(valid, fields, ranges);
  }

  @action
  changeoverRangeUpdated(fields, ranges) {
    ranges.forEach(range => {
      set(range, 'checkoutDays', range.checkinDays);
    });
    let valid = this.model.validateForm(this.intl);
    this.checkFormForErrors(valid, fields, ranges);
  }

  @action
  checkinDaysRangesUpdated(fields, ranges) {
    ranges = ranges.map(range => {
      const valForIn = this.transformCheckinValue(range.get('checkinDays'));
      const valForOut = this.transformCheckinValue(range.get('checkoutDays'));
      let valid = this.model.validateForm(this.intl);
      let startDate = range.get('startDate');
      let endDate = range.get('endDate');

      if (startDate && endDate) {
        range.set('checkinDays', valForIn);
        range.set('checkoutDays', valForOut);
      }

      this.checkFormForErrors(valid, fields, ranges);
      return range;
    });
  }

  @action
  checkinDaysUpdated(value) {
    const val = this.transformCheckinValue(value);
    this.set('model.checkinDays', val);
  }

  @action
  checkoutDaysUpdated(value) {
    const val = this.transformCheckinValue(value);
    this.set('model.checkoutDays', val);
  }

  @action
  toggleGapFill(checked) {
    if (checked) {
      this.set('model.gapFillingEnabled', true);
    } else {
      this.set('model.gapFillingEnabled', false);
    }
  }

  @action
  toggleIsOrphanChangeoverDaysEnabled(checked) {
    this.set('model.isOrphanChangeoverDaysEnabled', checked);
  }

  @action
  cbCheckinDaysUpdated(option, ev) {
    const checked = ev.target.checked;
    const newCheckinDaysValue = this.get('model.checkinDays') || [];
    if (checked) {
      newCheckinDaysValue.push(option);
    } else {
      newCheckinDaysValue.splice(newCheckinDaysValue.indexOf(option), 1);
    }
    this.set('model.checkinDays', newCheckinDaysValue);
  }

  @action
  cbCheckoutDaysUpdated(option, ev) {
    const checked = ev.target.checked;
    const newCheckoutDaysValue = this.get('model.checkoutDays') || [];
    if (checked) {
      newCheckoutDaysValue.push(option);
    } else {
      newCheckoutDaysValue.splice(newCheckoutDaysValue.indexOf(option), 1);
    }
    this.set('model.checkoutDays', newCheckoutDaysValue);
  }

  @action
  startCustomizingLastMinute() {
    this.set('customizingLastMinute', true);
  }

  @action
  discountsValid(discounts) {
    this.model.set('discounts', copy(discounts, true));
    this.set('formHasErrors', false);
    this.set('discountsHasErrors', false);
  }

  @action
  discountsInvalid() {
    this.set('formHasErrors', true);
    this.set('discountsHasErrors', true);
  }

  @action
  lastMinuteMinStaysValid(lastMinuteMinStays) {
    this.model.set('lastMinuteMinStays', copy(lastMinuteMinStays, true));
    this.set('formHasErrors', false);
    this.set('lastMinuteMinStaysHasErrors', false);
  }

  @action
  lastMinuteMinStaysInvalid() {
    this.set('formHasErrors', true);
    this.set('lastMinuteMinStaysHasErrors', true);
  }

  @action
  orphanDaysValid(orphanDays) {
    this.set('model.orphanDays', copy(orphanDays, true));
    this.set('formHasErrors', false);
    this.set('orphanDaysHasErrors', false);
  }

  @action
  orphanDaysInvalid() {
    this.set('formHasErrors', true);
    this.set('orphanDaysHasErrors', true);
  }

  @action
  setEnableMinStay(value) {
    if (value === 'enable') {
      this.set('enableMinCheckBoxValue', 'MinStay');
      this.set('disableMinCheckBoxValue', null);
      this.set('userEnabledMinStaysAnswer', true);
    } else if (value === 'disable') {
      this.set('enableMinCheckBoxValue', null);
      this.set('disableMinCheckBoxValue', 'MinStay');
      this.set('userEnabledMinStaysAnswer', false);
    }
  }

  @action
  enableMinStays() {
    if (this.userEnabledMinStaysAnswer) {
      let ma = this.get('listing.primaryChannelListing.managedAccountId');
      let url = `/api/accounts/${ma}/reset_and_import_settings/min_stays`;

      let p = this.ajax.stream(url, {}, data => {
        this.set('showMinStayMessage', true);
        this.set('showLoadingListings', true);
        if (data.message) {
          this.set('listingStatus', data.message);
        }
      });

      p.then(() => {
        getOwner(this).lookup('route:dashboard.pricing.listing').refresh();
        // Open Min Stays when page refreshes
        this.set('showMinStayMessage', false);
        this.set('minStayDefaultArePristine', false);
        this.set('showChangeoverMinStayMessage', false);
        this.set('showLoadingListings', false);
      });

      p.catch(() => {
        logger.error('Promise stream error', arguments);
        this.render('error.server', { into: 'application' });
      });
    } else if (this.userEnabledMinStaysAnswer === false) {
      this.set('hideMinStay', true);
      this.set('canCustomizeMinStay', false);
    }
  }

  @action
  setEnableMaxStay(value) {
    if (value === 'enable') {
      this.set('enableMaxCheckBoxValue', 'MaxStay');
      this.set('disableMaxCheckBoxValue', null);
      this.set('userEnabledMaxStaysAnswer', true);
    } else if (value === 'disable') {
      this.set('enableMaxCheckBoxValue', null);
      this.set('disableMaxCheckBoxValue', 'MaxStay');
      this.set('userEnabledMaxStaysAnswer', false);
    }
  }

  @action
  enableMaxStays() {
    if (this.userEnabledMaxStaysAnswer) {
      let ma = this.get('listing.primaryChannelListing.managedAccountId');
      let url = `/api/accounts/${ma}/reset_and_import_settings/max_stays`;

      let p = this.ajax.stream(url, {}, data => {
        this.set('showMaxStayMessage', true);
        this.set('showLoadingListings', true);
        if (data.message) {
          this.set('listingStatus', data.message);
        }
      });

      p.then(() => {
        getOwner(this).lookup('route:dashboard.pricing.listing').refresh();
        this.set('maxStayDefaultArePristine', false);
        this.set('showLoadingListings', false);
        this.set('showMaxStayMessage', false);
      });

      p.catch(() => {
        logger.error('Promise stream error', arguments);
        this.render('error.server', { into: 'application' });
      });
    } else if (this.userEnabledMaxStaysAnswer === false) {
      this.set('hideMaxStay', true);
      this.set('canCustomizeMaxStay', false);
    }
  }

  @action
  monthyPricingChanged(monthlyEnabled) {
    this.listing.set('monthlyEnabled', monthlyEnabled);
  }

  @action
  specialRequirementsChanged(srEnabled) {
    this.listing.set('specialRequirements', srEnabled);
  }

  @action
  setMonthlyMinPrice(value) {
    this.set('model.monthlyMinPrice', value);
    let valid = this.model.validateInput();
    this.set('formHasErrors', !valid);
  }

  @action
  importCalendarUrlsUpdated(urls) {
    this.model.set('importCalendarUrls', urls);
    this.model.validateForm(this.intl);
  }

  @computed('listing', 'model.exportCalendarToken')
  get generateCalendarLink() {
    let listing = this.get('listing');

    return `https://${window.location.hostname}/api/listings/${
      listing.id
    }/export_ical/${this.get('model.exportCalendarToken')}`;
  }

  @action
  copyToClipboardExportCalendarLink() {
    let exportCalendarLinkInput = document.getElementById('exportCalendarLinkInput');
    exportCalendarLinkInput.select();
    exportCalendarLinkInput.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
  }

  @action
  async saveListing() {
    let validation = this.model.validateForm(this.intl);

    // There was a validation error. Code bellow expands the panes with the errors
    if (!validation) {
      for (let prop in FIELD_PANE_MAP) {
        let field = this.get(`model.${prop}`);
        if (Array.isArray(field)) {
          field.map(r => {
            if (r.error) {
              this.set(FIELD_PANE_MAP[prop], true);
            }
          });
        }
      }

      let validationErrors = this.get('model.validationErrors');
      if (validationErrors.get('extraGuestFees')) {
        this.set(FIELD_PANE_MAP.extraGuestFees, true);
      }
      if (validationErrors.get('maxPrice')) {
        this.set(FIELD_PANE_MAP.maxPrice, true);
      }
      if (validationErrors.get('maxStay')) {
        this.set(FIELD_PANE_MAP.maxStay, true);
      }
      return;
    }

    if (this.discountsHasErrors) {
      this.set(FIELD_PANE_MAP.discounts, true);
      return;
    }

    if (this.lastMinuteMinStaysHasErrors) {
      this.set(FIELD_PANE_MAP.minStay, true);
      return;
    }

    if (this.orphanDaysHasErrors) {
      this.set(FIELD_PANE_MAP.orphanDays, true);
      return;
    }

    if (this.importCalendarUrlsHasErrors) {
      this.set(FIELD_PANE_MAP.importCalendarUrls, true);
      return;
    }

    if (this.saving) {
      return;
    }

    let listing = this.listing;
    let url = `/v2/listings/${listing.id}/customize`;

    let serializedData = this.model.serializeData();
    this.set('saving', true);

    try {
      await this.ajax._post(url, serializedData);
    } catch (errors) {
      this.set('saving', false);
      displayErrors({ errors: errors, modelOrKeywordThis: this, alert: alert });
      return;
    }

    let clNames = [];
    for (const cl of this.get(`listing`)?.channelNames) {
      if (!clNames.includes(CHANNEL_CONFIG[cl].label)) {
        clNames.push(CHANNEL_CONFIG[cl].label);
      }
    }

    let clNamesString = clNames.join(', ');
    if (this.listing.isNewListing) {
      this.alert.success(this.intl.t('common.savedSuccessfully'));
    } else {
      this.alert.success(
        this.intl.t('pricing.bulkEdit.bulkEditSuccesfullySaved', {
          clNamesString: clNamesString,
        })
      );
    }

    this.bpStore.unloadRecord('listing', listing);
    getOwner(this).lookup('route:dashboard.pricing.listing').refresh();
    this.set('saving', false);
  }
}
