import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class ProcessingController extends Controller {
  @service populateAccount;

  get listingsProgress() {
    let out = this.populateAccount.collectedLogs;
    if (out.length > 15) return out.slice(0, 15);
    return out;
  }
}
