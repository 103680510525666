import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { CHANNEL } from '../../../../lib/channel_configuration';

export default class DashboardRelayConnectChannelController extends Controller {
  @service populateAccount;

  @action
  async accountAdded(accountId) {
    this.populateAccount.start(accountId, this.model.channel_name);

    let channelsToConnect = this.model.selectedChannels;

    if (channelsToConnect && channelsToConnect.length > 0) {
      const nextChannelToConnect = channelsToConnect.shift();

      if (nextChannelToConnect.name === 'booking')
        nextChannelToConnect.name === CHANNEL['BOOKING_CONNECT'];
      this.transitionToRoute('dashboard.relay.connect.channel', {
        channel_name: nextChannelToConnect.name,
        selectedChannels: channelsToConnect,
      });
    } else {
      this.populateAccount.onFinish(this.onFinish);
    }
  }

  get accountsInProgress() {
    let inProgress = this.populateAccount.accountsInProcess;
    return inProgress;
  }

  get syncingStarted() {
    let inProgress = this.populateAccount.accountsInProcess;
    return inProgress.length > 0;
  }

  @action
  onFinish() {
    setTimeout(() => {
      window.location.href = '/dashboard/relay';
    }, 2000);
  }

  @computed('model.channel_name')
  get channelName() {
    if (this.model.channel_name === 'booking_connect') return 'Booking';
    return this.model.channel_name;
  }
}
