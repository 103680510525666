import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { copy } from 'ember-copy';
import Controller from '@ember/controller';
@classic
export default class SeasonalityController extends Controller {
  compareId = null;
  compareData = [];

  init() {
    super.init.apply(this, arguments);

    // Highcharts takes over `this` - rebind to the controller so we can update
    // the data after a drag.
    this.chartOptions.plotOptions.series.point.events.drag = this.chartOptions.plotOptions.series.point.events.drag.bind(
      this
    );
    this.chartOptions.plotOptions.series.point.events.drop = this.chartOptions.plotOptions.series.point.events.drop.bind(
      this
    );
  }

  windowLength = 91;

  chartOptions = {
    plotOptions: {
      series: {
        cursor: 'ns-resize',

        point: {
          events: {
            drop(event) {
              let point = event.target;

              // Delete all starting drag points.
              point.series.data.forEach(p => delete p.adjacentDragStart);

              // Update the model data. The binding is only one-way from model
              // to the chart data, so updating the points directly in the drag
              // event doesn't get copied back to the model.
              this.set(
                'model.custom',
                point.series.data.map(p => [p.x, p.y])
              );
            },

            drag(event) {
              let point = event.target;

              // First click doesn't have a dragStart set - wait until the user
              // has moved the mouse.
              if (!point.dragStart) {
                return;
              }

              let delta = point.y - point.dragStart.y;
              let centerIndex = point.series.data.indexOf(point);
              let windowLength = this.windowLength;
              let windowSequence = Array(windowLength)
                .fill()
                .map((el, i) => i - Math.floor(windowLength / 2));

              windowSequence.forEach(i => {
                // Center point is already modified by the drag. Only need to
                // update adjacent points.
                if (i === 0) {
                  return;
                }

                // Gaussian moving average. Modifiers pulled out of my ass.
                let modifier = Math.exp(-(1 / (2 * windowLength)) * Math.pow(i, 2));
                // Ensure wrapping around the edges works.
                let length = point.series.data.length;
                let index = (centerIndex + i + length) % length;
                let adjacentPoint = point.series.data[index];

                if (adjacentPoint.adjacentDragStart) {
                  adjacentPoint.y = adjacentPoint.adjacentDragStart + delta * modifier;
                } else {
                  adjacentPoint.adjacentDragStart = adjacentPoint.y;
                }
              });
            },
          },
        },
      },
    },

    title: {
      text: '',
    },
    tooltip: {
      shared: true,
    },

    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        // don't display the dummy year
        month: '%e. %b',
        year: '%b',
      },
      title: {
        text: 'Date',
      },
    },
    yAxis: {
      title: {
        text: 'Percent',
      },
      plotLines: [
        {
          color: 'red',
          dashStyle: 'longdashdot', // Style of the plot line. Default to solid
          value: 0,
          width: 2,
        },
      ],
    },
  };

  @computed('model.{seasonality,airbnb,homeaway,custom}', 'compareData')
  get chartData() {
    let out = [
      {
        name: 'Airbnb Seasonality (pct)',
        data: this.get('model.airbnb'),
        tooltip: { valueDecimals: 2 },
      },
      {
        name: 'Homeaway Seasonality (pct)',
        data: this.get('model.homeaway'),
        tooltip: { valueDecimals: 2 },
      },
    ];

    let custom = this.get('model.custom');
    if (custom && custom.length) {
      out.push({
        draggableY: true,
        name: 'Custom Seasonality (pct)',
        data: custom,
        tooltip: { valueDecimals: 2 },
      });
    }

    let compareData = this.compareData || [];
    if (compareData.length) {
      out.push({
        name: `Compare ${this.compareId}`,
        data: compareData,
        tooltip: { valueDecimals: 2 },
      });
    }

    return out;
  }

  _loadComparison(url) {
    this.ajax._get(url).then(data => {
      let out = Object.keys(data)
        .sort()
        .map(key => {
          let month = Math.floor(key / 100);
          let day = key % 100;
          return [Date.UTC(2016, month - 1, day), 100 * data[key]];
        });
      this.set('compareData', out);
    });
  }

  @action
  setWindowLength(event) {
    this.set('windowLength', Number(event.target.value) * 2 + 1);
  }

  @action
  copyFromAirbnb() {
    this.set('model.custom', copy(this.get('model.airbnb')));
  }

  @action
  copyFromHomeaway() {
    this.set('model.custom', copy(this.get('model.homeaway')));
  }

  @action
  compareMarket(id) {
    this.set('compareId', id);
    let url = `/admin/api/markets/${id}/${this.site}/seasonality`;
    this._loadComparison(url);
  }

  @action
  compareCluster(id) {
    this.set('compareId', id);
    let url = `/admin/api/clusters/${id}/${this.site}/seasonality`;
    this._loadComparison(url);
  }
}
