import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class BillingRoute extends AuthRoute {
  titleToken = 'Payment Methods';

  setupController(controller, model) {
    controller.set('model', model);
    let hasPaypal = model.paypalAccountEmail !== null;
    let hasCreditCard = model.cards?.length !== 0;
    let hasBankAccount = model.bankAccountDetails !== null;
    let hasPaymentMethod = hasPaypal || hasCreditCard || hasBankAccount;

    controller.set('hasAddPaymentMethodDialogVisible', hasPaymentMethod ? false : true);
    controller.set('hasPaymentMethod', hasPaymentMethod ? true : false);
  }
}
