import BaseModel from 'appkit/bp-models/base_model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import EmberObject from '@ember/object';

export default BaseModel.extend({
  init() {
    this._super(arguments);
    this._EDITABLE = [
      'templateName',
      'seasons',
      'pmFee',
      'expenses',
      'logo',
      'companyNotes',
    ];
  },

  id: null,
  createdAt: null,
  userId: null,
  templateName: null,
  name: alias('templateName'),
  _seasons: computed({
    get() {
      return [];
    },
  }),
  seasons: computed('_seasons', {
    get() {
      return this._seasons.map(s => EmberObject.create(s));
    },
    set(key, value) {
      this.set('_seasons', value);
      return value;
    },
  }),

  pmFee: null,
  _expenses: computed({
    get() {
      return [];
    },
  }),
  expenses: computed('_expenses', {
    get() {
      return this._expenses.map(e => EmberObject.create(e));
    },
    set(key, value) {
      this.set('_expenses', value);
      return value;
    },
  }),

  logo: null,
  companyNotes: null,
  companyWebsite: null,
  companyPhone: null,
  companyEmail: null,
  companyAddress: null,
  companyCity: null,
  companyState: null,
  companyZipcode: null,
  companyCountry: null,

  url: computed('id', function () {
    if (this.id) {
      return `/api/projections_templates/${this.id}`;
    } else {
      return '/api/projections_templates/';
    }
  }),
});
