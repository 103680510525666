import Controller from '@ember/controller';
import { computed, action, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import EmberObject from '@ember/object';
import { CurrencyUtil } from 'appkit/lib/currency';
import moment from 'moment';
import { displayErrors } from 'appkit/lib/display_errors';
import { tracked } from '@glimmer/tracking';

export default class DashboardOwnerResourceController extends Controller {
  @service('owner-resource') ownerResource;
  @service alert;
  @service router;
  @service('intl') intl;
  @service pdfMake;

  @controller('dashboard.owner-resource.index') ownerResourceIndex;
  @controller('dashboard.owner-resource.projection.build-projection')
  buildProjectionController;
  @controller('dashboard.owner-resource.projection') projectionController;

  @tracked disableSaveProjection;

  @computed('router.currentRouteName')
  get displaySaveProjectionButton() {
    return this.router.currentRouteName.includes('build-projection');
  }

  @computed('router.currentRouteName', 'ownerResource.searchedReferenceListings')
  get displaySubheaderCurrencySelection() {
    const isOnBuildProjection = this.router.currentRouteName.includes(
      'build-projection'
    );
    const searchedReferenceListings = this.ownerResource.searchedReferenceListings;

    return searchedReferenceListings || isOnBuildProjection;
  }

  @computed('router.currentRouteName')
  get isOnDashboard() {
    const isOnBuildProjection = this.router.currentRouteName.includes(
      'owner-resource.index'
    );

    return isOnBuildProjection;
  }

  @action
  clickedNewProjection() {
    this.ownerResource.unselectListing();
  }

  @action
  async saveProjection() {
    const includeCompanyInfo = this.projectionController.includeCompanyInfo;
    const bpc = this.buildProjectionController;

    const data = {
      creatorCredentialId: this.model.credential.id || null,
      clusterId: this.ownerResource.selectedCluster || null,
      referenceListingId: this.ownerResource.selectedListing?.id || null,
      beds: this.ownerResource.selectedBedroomSize || null,
      referenceBasePrice:
        this.ownerResource.selectedListing?.basePrice ||
        this.ownerResource.manualBasePrice ||
        null,
      address: this.ownerResource.searchedAddressGeocode.address || null,
      city: this.ownerResource.searchedAddressGeocode.city || null,
      state: this.ownerResource.searchedAddressGeocode.state || null,
      country: this.ownerResource.searchedAddressGeocode.country || null,
      zipcode: this.ownerResource.searchedAddressGeocode.zipCode || null,
      currency: this.ownerResource.selectedCurrency || null,

      // company info
      companyWebsite: includeCompanyInfo ? bpc.website : null,
      companyPhone: includeCompanyInfo ? bpc.phone : null,
      companyEmail: includeCompanyInfo ? bpc.email : null,
      companyAddress: includeCompanyInfo ? bpc.address : null,
      companyCity: includeCompanyInfo ? bpc.city : null,
      companyState: includeCompanyInfo ? bpc.state : null,
      companyZipcode: includeCompanyInfo ? bpc.zipcode : null,
      companyCountry: includeCompanyInfo ? bpc.country : null,
      companyNotes: bpc.notes || null,

      projectionName: bpc.projectionTemplateName || null,
      projectionTemplateId: bpc.selectedProjectionTemplateOption?.id || null,
      seasons: bpc.seasons || null,
      pmFee: bpc.propertyManagementFee || null,
      expenses: bpc.expenses || null,
      revenueEstimate: bpc.revenueProjectionsTotal.revenueEstimateTotal || null,
      dealStatus: 'n/a',
    };

    let res;
    try {
      res = await this.ajax._post('/api/properties_projections', data);
    } catch (errors) {
      displayErrors({ errors: errors, modelOrKeywordThis: this, alert: this.alert });
      return;
    }

    this.transitionToRoute(
      'dashboard.owner-resource.projection.build-projection.view',
      res.propertyProjection.id
    );

    const projection = this.bpStore.createRecord('projection', res.propertyProjection);
    const referenceListing = this.bpStore.peekRecord(
      'listing',
      get(projection, 'referenceListingId')
    );
    set(projection, 'referenceListing', referenceListing);

    // prevent dupe
    if (this.model.projections.map(p => p.id).indexOf(projection.id) === -1) {
      get(this, 'model.projections').pushObject(projection);
    }

    this.alert.success('Projection saved successfully.');
  }

  @computed('router.currentRouteName')
  get disableDownloadPdfButton() {
    return (
      this.router.currentRouteName !==
      'dashboard.owner-resource.projection.build-projection.view'
    );
  }

  @computed('ownerResourceIndex.selectedProjections.[]')
  get selectedProjectionsPdf() {
    return this.ownerResourceIndex.selectedProjections.map(sp => {
      return this.generatePdfFormat(sp);
    });
  }

  @computed('model.listings.@each.currency}')
  get currencyOptions() {
    let uniqueCount = {};
    for (let l of get(this, 'model.listings')) {
      if ({}.hasOwnProperty.call(uniqueCount, get(l, 'currency'))) {
        uniqueCount[get(l, 'currency')].count++;
      } else {
        uniqueCount[get(l, 'currency')] = { count: 1 };
      }
    }

    let out = [];
    for (let c in uniqueCount) {
      let item = {
        currency: c,
        count: uniqueCount[c].count,
      };
      out.push(item);
    }

    return out
      .sort((a, b) => {
        return b.count - a.count;
      })
      .map(i => i.currency);
  }

  @action
  onChangeCurrency(currency) {
    this.ownerResource.selectedCurrency = currency;
  }

  generatePdfFormat(sp) {
    /** SEASONS */

    const seasonBody = [];

    const seasonColumn = text => {
      return {
        text: text,
        fontSize: 9,
        color: 'gray',
        alignment: 'left',
        margin: [8, 8, 8, 8],
        border: [false, false, false, false],
        fillColor: '#F6F7F9',
      };
    };

    const seasonColumns = [
      seasonColumn('Period:'),
      seasonColumn('Days in period:'),
      seasonColumn('Occupancy:'),
      seasonColumn('Gross estimate:'),
    ];

    const getOccupancy = s => {
      let occupancy;
      if (s.customOccupancy) {
        occupancy = s.customOccupancy;
      } else if (s.marketOccupancy) {
        occupancy = s.marketOccupancy;
      } else {
        occupancy = 0;
      }
      return occupancy;
    };

    const revenueProjections = sp.seasons.slice().map((s, index) => {
      const indexIsEven = index % 2 === 0;

      return [
        // Period
        {
          fillColor: `${indexIsEven ? '#fff' : '#F6F7F9'}`,
          stack: [
            {
              text: s.seasonName,
              fontSize: 10,
              alignment: 'left',
              margin: [8, 4, 8, 2],
              color: '#69C5CD',
            },
            {
              text: `${s.formatStartDate} — ${s.formatEndDate}`,
              fontSize: 9,
              alignment: 'left',
              margin: [8, 2, 8, 4],
              color: 'gray',
            },
          ],
          border: [false, false, false, false],
        },
        // Days in period
        {
          text: s.daysInPeriod,
          fontSize: 10,
          alignment: 'center',
          margin: [8, 8, 8, 8],
          border: [false, false, false, false],
          fillColor: `${indexIsEven ? '#fff' : '#F6F7F9'}`,
        },
        // Occupancy
        {
          text: `${getOccupancy(s)}%`,
          fontSize: 10,
          alignment: 'center',
          margin: [8, 8, 8, 8],
          border: [false, false, false, false],
          fillColor: `${indexIsEven ? '#fff' : '#F6F7F9'}`,
        },
        // Gross estimate
        {
          text: CurrencyUtil.format(s.revenueEstimate, {
            currency: sp.currency,
          }),
          fontSize: 10,
          alignment: 'right',
          margin: [8, 8, 8, 8],
          border: [false, false, false, false],
          fillColor: `${indexIsEven ? '#fff' : '#F6F7F9'}`,
        },
      ];
    });

    const revTotals = sp.seasons.reduce(
      (accum, s) => {
        accum.set(
          'daysInPeriodTotal',
          get(accum, 'daysInPeriodTotal') + get(s, 'daysInPeriod')
        );
        accum.set('occupancyTotal', get(accum, 'occupancyTotal') + getOccupancy(s));
        accum.set(
          'revenueEstimateTotal',
          get(accum, 'revenueEstimateTotal') + get(s, 'revenueEstimate')
        );
        accum.set(
          'netToOwnerTotal',
          get(accum, 'netToOwnerTotal') + get(s, 'netToOwner')
        );
        return accum;
      },
      EmberObject.create({
        daysInPeriodTotal: 0,
        occupancyTotal: 0,
        revenueEstimateTotal: 0,
        netToOwnerTotal: 0,
      })
    );

    const expenseTotal = sp.expenses.reduce((accum, e) => {
      return get(e, 'value') + accum;
    }, 0);

    if (expenseTotal) {
      revTotals.netToOwnerTotal = revTotals.netToOwnerTotal - expenseTotal;
    }

    const margin = [
      {
        margin: [2, 2, 2, 2],
        border: [false, false, false, false],
        text: '',
      },
      {
        margin: [2, 2, 2, 2],
        border: [false, false, false, false],
        text: '',
      },
      {
        margin: [2, 2, 2, 2],
        border: [false, false, false, false],
        text: '',
      },
      {
        margin: [2, 2, 2, 2],
        border: [false, false, false, false],
        text: '',
      },
    ];

    const revenueProjectionsTotals = [
      {
        text: 'Sub-total:',
        fontSize: 10,
        alignment: 'left',
        margin: [8, 8, 8, 8],
        border: [true, true, false, true],
      },
      {
        // Total days in period
        text: revTotals.daysInPeriodTotal,
        fontSize: 10,
        alignment: 'center',
        margin: [8, 8, 8, 8],
        border: [false, true, false, true],
      },
      {
        // Total occupancy
        text: `${revTotals.occupancyTotal / sp.seasons.length}%`,
        fontSize: 10,
        alignment: 'center',
        margin: [8, 8, 8, 8],
        border: [false, true, false, true],
      },
      {
        // Total rev estimate
        text: CurrencyUtil.format(revTotals.revenueEstimateTotal, {
          currency: sp.currency,
        }),
        fontSize: 10,
        alignment: 'right',
        margin: [8, 8, 8, 8],
        border: [false, true, false, true],
      },
    ];

    seasonBody.push(seasonColumns);
    seasonBody.push(...revenueProjections);
    seasonBody.push(margin);
    seasonBody.push(revenueProjectionsTotals);

    /** expense */

    const expenseBody = [];

    const expenses = sp.expenses.slice().map(e => {
      return [
        {
          text: e.expenseName,
          fontSize: 10,
          alignment: 'left',
          margin: [8, 8, 8, 8],
          fillColor: '#fff',
        },
        {
          text: `(${CurrencyUtil.format(e.value, {
            currency: sp.currency,
          })})`,
          fontSize: 10,
          alignment: 'right',
          margin: [8, 8, 8, 8],
          fillColor: '#fff',
        },
      ];
    });

    const expensesTotal = [
      {
        text: 'Totals:',
        fontSize: 10,
        alignment: 'left',
        margin: [8, 8, 8, 8],
        fillOpacity: 0.1,
        fillColor: '#69C5CD',
      },
      {
        text: CurrencyUtil.format(revTotals.netToOwnerTotal, {
          currency: sp.currency,
        }),
        fontSize: 10,
        alignment: 'right',
        margin: [8, 8, 8, 8],
        fillOpacity: 0.1,
        fillColor: '#69C5CD',
        bold: true,
        color: '#69C5CD',
      },
    ];

    expenseBody.push(...expenses);
    expenseBody.push(expensesTotal);

    /** Reference Listing Info  */

    let bedroomSize;
    if (sp.beds === 0) {
      bedroomSize = this.intl.t('bedroom.zero').string;
    } else if (sp.beds === 1) {
      bedroomSize = this.intl.t('bedroom.one').string;
    } else {
      bedroomSize = this.intl.t('bedroom.other', {
        count: sp.beds,
      }).string;
    }

    /** Summary Table Info  */

    // left

    const TotalGrossRevenueLeft = {
      text: 'Total gross revenue',
      margin: [12, 12, 12, 4],
      fontSize: 9,
    };

    const PropertyManagementFeeLeft = {
      text: 'Property management fee',
      margin: [12, 4, 12, 4],
      fontSize: 9,
    };

    const TotalExpectedExpensesLeft = {
      text: 'Total expected expenses',
      margin: [12, 4, 12, 4],
      fontSize: 9,
    };

    const expectedNetRevenueLeft = {
      text: 'Expected net revenue',
      margin: [12, 12, 12, 12],
      fontSize: 10,
    };

    // right

    const TotalGrossRevenueRight = {
      text: CurrencyUtil.format(revTotals.revenueEstimateTotal, {
        currency: sp.currency,
      }),
      margin: [12, 12, 12, 4],
      fontSize: 9,
      bold: true,
      alignment: 'right',
    };

    const PropertyManagementFeeRight = {
      columns: [
        {
          width: '*',
          text: `${sp.pmFee ? sp.pmFee : 0}% /`,
          color: '#868686',
          alignment: 'right',
        },
        {
          width: '*',
          text: ` (${CurrencyUtil.format(
            (revTotals.revenueEstimateTotal * (sp.pmFee / 100)).toFixed(2),
            {
              currency: sp.currency,
            }
          )})`,
          color: '#333333',
          alignment: 'right',
        },
      ],
      columnGap: 0,
      width: '*',
      margin: [12, 4, 12, 4],
      fontSize: 9,
      color: '#333333',
      alignment: 'right',
    };

    const TotalExpectedExpensesRight = {
      text: `(${CurrencyUtil.format(expenseTotal, {
        currency: sp.currency,
      })})`,
      margin: [12, 4, 12, 4],
      fontSize: 9,
      color: '#333333',
      alignment: 'right',
    };

    const expectedNetRevenueRight = {
      text: CurrencyUtil.format(revTotals.netToOwnerTotal, {
        currency: sp.currency,
      }),
      margin: [12, 12, 12, 12],
      fontSize: 10,
      bold: true,
      color: '#69C5CD',
      alignment: 'right',
    };

    let expectedNetRevenueLeftStack;
    let expectedNetRevenueRightStack;

    if (expenseTotal) {
      expectedNetRevenueLeftStack = [
        TotalGrossRevenueLeft,
        PropertyManagementFeeLeft,
        TotalExpectedExpensesLeft,
        expectedNetRevenueLeft,
      ];
      expectedNetRevenueRightStack = [
        TotalGrossRevenueRight,
        PropertyManagementFeeRight,
        TotalExpectedExpensesRight,
        expectedNetRevenueRight,
      ];
    } else {
      expectedNetRevenueLeftStack = [
        TotalGrossRevenueLeft,
        PropertyManagementFeeLeft,
        expectedNetRevenueLeft,
      ];
      expectedNetRevenueRightStack = [
        TotalGrossRevenueRight,
        PropertyManagementFeeRight,
        expectedNetRevenueRight,
      ];
    }

    const summaryTableColumns = [
      {
        width: '60%',
        stack: expectedNetRevenueLeftStack,
      },
      {
        width: '40%',
        stack: expectedNetRevenueRightStack,
      },
    ];

    const summaryTableRight = {
      border: [true, true, true, true],
      columns: summaryTableColumns,
    };

    const summaryTableLeft = {
      stack: [
        {
          text: 'Rental projection',
          margin: [4, 4, 4, 4],
          fontSize: 13,
          bold: true,
        },
        {
          text: `${sp.address ? sp.address + ',' : ''} ${
            sp.city ? sp.city + ',' : ''
          } ${sp.state ? sp.state : ''} ${sp.zipcode ? sp.zipcode : ''}`,
          margin: [4, 4, 4, 4],
          fontSize: 10,
        },
        { text: bedroomSize, margin: [4, 4, 4, 4], fontSize: 10 },
      ],
      border: [false, false, false, false],
    };

    /** PDF */

    const spacer = {
      layout: 'noBorders',
      // eslint-disable-next-line no-dupe-keys
      table: {
        heights: [20],
        body: [{}],
      },
    };

    const title = title => {
      return {
        layout: 'noBorders',
        // eslint-disable-next-line no-dupe-keys
        table: {
          heights: [20],
          widths: ['100%'],
          body: [
            [
              {
                text: title,
                alignment: 'left',
                fontSize: 14,
                color: '#333333',
              },
            ],
          ],
        },
      };
    };

    const createdDate = {
      layout: 'noBorders',
      // eslint-disable-next-line no-dupe-keys
      table: {
        heights: [20],
        widths: ['100%'],
        body: [
          [
            {
              text: `Date created: ${moment().format('MMMM DD, YYYY')}`,
              alignment: 'right',
              fontSize: 9,
              color: 'gray',
            },
          ],
        ],
      },
    };

    const basicInfo = {
      // eslint-disable-next-line no-dupe-keys
      table: {
        widths: ['100%'],
        body: [
          [
            {
              text: 'Date Created: ',
              margin: [4, 4, 4, 4],
              fontSize: 10,
              color: 'gray',
            },
          ],
        ],
      },
      // eslint-disable-next-line no-dupe-keys
      table: {
        widths: ['50%', '50%'],
        body: [[summaryTableLeft, summaryTableRight]],
      },
      layout: {
        hLineColor: function () {
          return '#BFE8EB';
        },
        vLineColor: function () {
          return '#BFE8EB';
        },
        paddingLeft: function () {
          return 10;
        },
        paddingRight: function () {
          return 10;
        },
        paddingTop: function () {
          return 10;
        },
        paddingBottom: function () {
          return 10;
        },
      },
    };

    // Revenue Projections

    const revProjectionTable = {
      // eslint-disable-next-line no-dupe-keys
      table: {
        widths: ['40%', '20%', '20%', '20%'],
        body: seasonBody,
      },
      layout: {
        hLineColor: function () {
          return '#E9E9E9';
        },
        vLineColor: function () {
          return '#E9E9E9';
        },
        marginTop: function () {
          return 10;
        },
      },
    };

    /** Property Management Fee */

    const propertyManagementFeeTable = {
      layout: 'noBorders',
      // eslint-disable-next-line no-dupe-keys
      table: {
        widths: ['60%', '40%'],
        body: [
          [
            {
              text: 'Property management fee:',
              fontSize: 10,
              alignment: 'left',
              margin: [8, 8, 8, 8],
              fillColor: '#fff',
            },
            {
              fontSize: 10,
              alignment: 'right',
              margin: [8, 8, 8, 8],
              columnGap: 2,
              columns: [
                {
                  width: '*',
                  text: `${sp.pmFee ? sp.pmFee : 0}% / `,
                  color: '#868686',
                  alignment: 'right',
                  fontSize: 10,
                },
                {
                  width: 'auto',
                  text: `(${CurrencyUtil.format(
                    (revTotals.revenueEstimateTotal * (sp.pmFee / 100)).toFixed(2),
                    {
                      currency: sp.currency,
                    }
                  )})`,
                  color: '#333333',
                  alignment: 'right',
                  fontSize: 10,
                },
              ],
            },
          ],
        ],
      },
    };

    // Expenses

    const expensesTable = {
      layout: 'noBorders',
      // eslint-disable-next-line no-dupe-keys
      table: {
        widths: ['60%', '40%'],
        body: expenseBody,
      },
    };

    // Notes

    const Notes = {
      layout: 'noBorders',
      // eslint-disable-next-line no-dupe-keys
      table: {
        // heights: [20],
        widths: ['100%'],
        body: [
          [
            {
              text: sp.companyNotes,
              alignment: 'left',
              fontSize: 10,
              color: 'gray',
            },
          ],
        ],
      },
    };

    // Company Info

    const companyInfoTextLeft = text => {
      return {
        text: text,
        margin: [0, 8, 8, 4],
        fontSize: 8,
        color: '#A8A8A8',
      };
    };

    const companyInfoTextRight = text => {
      return {
        text: text,
        margin: [0, 8, 8, 4],
        fontSize: 9,
      };
    };

    const companyInfoLeft = [];
    const companyInfoRight = [];

    const WebsiteLeft = companyInfoTextLeft('Website');
    const PhoneLeft = companyInfoTextLeft('Phone');
    const EmailLeft = companyInfoTextLeft('Email');
    const AddressLeft = companyInfoTextLeft('Address');

    const WebsiteRight = companyInfoTextRight(sp.companyWebsite);
    const PhoneRight = companyInfoTextRight(sp.companyPhone);
    const EmailRight = companyInfoTextRight(sp.companyEmail);

    const AddressRight = companyInfoTextRight(`${sp.companyAddress}, ${sp.companyCity}, ${sp.companyState}, ${sp.companyZipcode}, ${sp.companyCountry},
    `);

    if (WebsiteRight.text) {
      companyInfoLeft.push(WebsiteLeft);
      companyInfoRight.push(WebsiteRight);
    }
    if (PhoneRight.text) {
      companyInfoLeft.push(PhoneLeft);
      companyInfoRight.push(PhoneRight);
    }
    if (EmailRight.text) {
      companyInfoLeft.push(EmailLeft);
      companyInfoRight.push(EmailRight);
    }
    if (AddressRight.text) {
      companyInfoLeft.push(AddressLeft);
      companyInfoRight.push(AddressRight);
    }

    const companyInfo = {
      // eslint-disable-next-line no-dupe-keys
      table: {
        widths: ['100%'],
        body: [
          [
            {
              border: [false, false, false, false],
              columns: [
                {
                  width: '10%',
                  stack: [WebsiteLeft, PhoneLeft, EmailLeft, AddressLeft],
                },
                {
                  width: '90%',
                  stack: [WebsiteRight, PhoneRight, EmailRight, AddressRight],
                },
              ],
            },
          ],
        ],
      },
    };

    const content = [];

    // summary
    content.push(createdDate);
    content.push(basicInfo);
    content.push(spacer);

    // revenue projections
    content.push(spacer);
    content.push(title('Revenue Projections'));
    content.push(revProjectionTable);

    // Property management fee
    content.push(propertyManagementFeeTable);

    // expenses
    content.push(expensesTable);

    // notes
    if (sp.companyNotes) {
      content.push(spacer);
      content.push(Notes);
    }

    // company info
    if (companyInfoLeft.length) {
      content.push(spacer);
      content.push(title('Company information:'));
      content.push(companyInfo);
    }

    return {
      content: content,
    };
  }

  @action
  async downloadProjection() {
    const id = this.router.currentURL.split('/').pop();
    const projection = this.model.projections.find(p => p.id === Number(id));
    const pdfFormat = this.generatePdfFormat(projection);

    const pdfMake = await this.pdfMake.getInstance();

    pdfMake
      .createPdf(pdfFormat)
      .download(`Projection — ${moment().format('DD-MM-YYYY')}`);
  }

  @action
  async bulkDownloadPdf() {
    const pdfMake = await this.pdfMake.getInstance();
    this.selectedProjectionsPdf.forEach(spp =>
      pdfMake.createPdf(spp).download(`Projection — ${moment().format('DD-MM-YYYY')}`)
    );
    set(this, 'ownerResourceIndex.selectedProjections', []);
    this.model.projections.map(p => set(p, 'selected', false));
  }

  @action
  async bulkDuplicate() {
    const ids = this.ownerResourceIndex.selectedProjectionIds;

    let res;
    const data = { projectionsIds: ids };
    try {
      res = await this.ajax._post('/api/properties_projections/bulk', data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain', { timeout: 10000 });
      return;
    }

    const projections = res.duplicatedProjections.map(projection =>
      this.bpStore.createRecord('projection', projection)
    );

    projections.map(projection => {
      const referenceListing = this.bpStore.peekRecord(
        'listing',
        projection.get('referenceListingId')
      );
      set(projection, 'referenceListing', referenceListing);
    });

    set(this, 'model.projections', this.model.projections.pushObjects(projections));

    this.alert.success('Projection saved successfully.');
    set(this, 'ownerResourceIndex.selectedProjections', []);
    this.model.projections.map(p => set(p, 'selected', false));
  }

  @action
  async bulkDelete() {
    let sure = confirm('This will delete your data');
    if (!sure) {
      return false;
    }

    const ids = this.ownerResourceIndex.selectedProjectionIds;

    const data = { projectionsIds: ids };
    try {
      await this.ajax._delete('/api/properties_projections/bulk', data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain', { timeout: 10000 });
      return;
    }

    set(
      this,
      'model.projections',
      this.model.projections.filter(p => ids.indexOf(p.id) === -1)
    );

    this.alert.success('Projection saved successfully.');
    set(this, 'ownerResourceIndex.selectedProjections', []);
    this.model.projections.map(p => set(p, 'selected', false));
  }
}
