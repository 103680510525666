// vanilla javascript based mouse handling since we don't have access to ember
// niceities here.
//
// We use X/Y offsets to do the listing and date lookup as this leads to fast
// selecting / clearing (which is nice for drawing the selection as we drag) and for
// selecting when hovering over non-singular cells (e.g. reservations or titles)
import Controller, { inject as controller } from '@ember/controller';
import moment from 'moment';
import { htmlSafe } from '@ember/template';
import { action, computed } from '@ember/object';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-mixins
import dashboardSearchParamsMixin from 'appkit/mixins/dashboard-search-params';
// eslint-disable-next-line ember/no-mixins
import flatListingsMixin from 'appkit/mixins/flat-listings';
// eslint-disable-next-line ember/no-mixins
import filtersMixin from 'appkit/mixins/dashboard-filter';
import $ from 'jquery';
import EmberObject, { set } from '@ember/object';
import { getOwner } from '@ember/application';

export default class DashboardGridController extends Controller.extend(
  flatListingsMixin,
  dashboardSearchParamsMixin,
  filtersMixin
) {
  @service alert;
  @service intl;
  @service('list-control') listControl;
  @service savedFiltersApi;
  @service featureFlag;
  @service embed;
  @service router;

  @controller('dashboard.grid.bulk-edit') bulkEditController;

  @tracked saving = false;
  @tracked isFilterVisible = false;
  @tracked hoverDate = null;
  @tracked hoverListingId = null;
  @tracked hoverReservationTarget = null;
  @tracked dragInfo = null;
  @tracked selectedDates = [];
  @tracked selectedListingIds = [];
  @tracked today = moment().startOf('day');
  @tracked startDate = moment().startOf('day').subtract(1, 'week');
  @tracked endDate = null;
  @tracked fromDate = null;
  @tracked listingCalendars = {};
  // Lock for loading more dates from the grid endpoint
  @tracked loadingMoreDates = false;
  @tracked selectedListings = [];
  @tracked gridSelection = [];
  @tracked gridStartDate;
  @tracked gridEndDate;

  @tracked isBulkUpdatesOngoing = null;
  @tracked confirmBulkActions = false;

  @tracked firstVisiblePos = 0;
  @tracked lastVisiblePos = 0;

  @tracked firstFetchedPos = 0;
  @tracked lastFetchedPos = 0;

  @tracked cached = {};

  @tracked verticalDirection;

  @tracked allListingsSelected = false;
  @tracked openMultiUnitListings = [];

  @tracked hasSpecialRequirementsEnabled = false;
  @tracked isAutoBookingReviewAvailable = false;
  @tracked isGridBetaAvailable = false;
  @tracked isTableViewAvailable = false;

  constructor() {
    super(...arguments);
    this.featureFlag.evaluate('listing-special-requirements', false).then(value => {
      this.hasSpecialRequirementsEnabled = value;
    });

    this.featureFlag.evaluate('react-booking-review', false).then(enabled => {
      this.isAutoBookingReviewAvailable = enabled;
    });

    this.featureFlag.evaluate('react-grid-view', false).then(enabled => {
      this.isGridBetaAvailable = enabled;
    });

    this.featureFlag.evaluate('table-view', false).then(enabled => {
      this.isTableViewAvailable = enabled;
    });

    set(this, 'displayType', 'postedPrices');
  }

  //-- Computed Properties ------------------------------------------------------------
  @computed('flatListings.[]')
  get mainListings() {
    return this.flatListings.filter(
      l =>
        !l.channelListings[0].multiUnitParentId ||
        (l.channelListings[0].multiUnitParentId &&
          l.channelListings[0].multiUnitGroupTitle)
    );
  }

  @computed('selectedListings.[]')
  get selectedListingsIncludeMultiUnit() {
    return this.selectedListings.some(l => l.channelListings[0].subUnits.length > 0);
  }

  @computed('listingCalendars')
  get listingsInView() {
    let ids = Object.values(this.listingCalendars).map(row => row[0].listingId);
    ids = [...new Set(ids)];
    let out = ids.map(id => this.bpStore.peekRecord('listing', id));
    return out;
  }

  @computed(
    'selectedListings.[]',
    'bulkEditController.excludeSpecialRequirements',
    'hasSpecialRequirementsEnabled'
  )
  get showSpecialRequirementsMsg() {
    return (
      this.hasSpecialRequirementsEnabled &&
      this.selectedListings.find(l => l.specialRequirements) &&
      !this.bulkEditController.excludeSpecialRequirements
    );
  }

  @computed('selectedListings.[]')
  get selectedListingsWithoutSR() {
    return this.selectedListings.filter(l => !l.specialRequirements);
  }
  @computed('selectedListings.[]', 'selectedListingsWithoutSR.[]')
  get srExplanationStar() {
    return this.selectedListings.length > this.selectedListingsWithoutSR.length
      ? '*'
      : '';
  }

  get hoverReservation() {
    if (!this.hoverReservationTarget) {
      return null;
    }
    const reservation = this.bpStore.peekRecord(
      'reservation',
      this.hoverReservationTarget.dataset.reservationId
    );
    const adr = Math.floor(reservation.rentalAmount / reservation.nbNights);
    reservation.adr = adr;
    return reservation;
  }

  get viewOptions() {
    let out = [
      {
        name: this.intl.t('pricing.dashboard'),
        svgUrl: 'card-view',
        beta: false,
      },
    ];

    out = this.isTableViewAvailable
      ? [...out, { name: this.intl.t('pricing.table'), svgUrl: 'table-view' }]
      : out;

    out = this.isGridBetaAvailable
      ? [
          ...out,
          {
            name: this.intl.t('pricing.grid'),
            svgUrl: 'grid-view',
            beta: true,
          },
        ]
      : out;

    out = [...out, { name: this.intl.t('pricing.grid'), svgUrl: 'grid-view' }];
    out = this.isAutoBookingReviewAvailable
      ? [
          ...out,
          {
            name: this.intl.t('pricing.autoBookingReview'),
            svgUrl: 'auto-booking-review-view',
            beta: false,
          },
        ]
      : out;
    return out;
  }

  get selectedView() {
    this.embed.czCaptureEvent(
      `Grid View`,
      `User viewed pricing dashboard in Grid View`
    );
    return this.viewOptions[2];
  }

  set selectedView(value) {
    return (this._selectedView = value);
  }

  @computed()
  get mode() {
    let out = localStorage.getItem('mode') || 'legacy';
    if (out === 'grid-view') {
      out = 'card-view';
      localStorage.setItem('mode', 'legacy');
    }
    return out;
  }

  set mode(value) {
    if (value) {
      localStorage.setItem('mode', value);
    } else {
      localStorage.removeItem('mode');
    }
    return localStorage.getItem('mode');
  }

  get visibleListingIds() {
    let calendarChannelListingIds = Object.keys(this.listingCalendars);
    let visibleWindow = this.lastVisiblePos - this.firstVisiblePos;

    if (
      this.verticalDirection == 'up' &&
      this.firstVisiblePos < this.firstFetchedPos + visibleWindow
    ) {
      this.firstFetchedPos = this.firstVisiblePos - visibleWindow * 2;
    } else if (
      this.verticalDirection == 'down' &&
      this.lastVisiblePos > this.lastFetchedPos - visibleWindow
    ) {
      this.lastFetchedPos = this.lastVisiblePos + visibleWindow * 2;
    }

    return this.flatListings
      .filter(
        (l, index) =>
          index >= this.firstFetchedPos &&
          index <= this.lastFetchedPos &&
          !any(calendarChannelListingIds, l.channelListingIds)
      )
      .map(l => l.id);
  }

  @computed('displayType')
  get showPriceOverridesItem() {
    return this.displayType === 'overrides';
  }

  @action
  selectAllListings() {
    if (this.flatListings.filter(l => l.selected).length === this.flatListings.length) {
      this.allListingsSelected = false;
      this.selectedListings = [];
      this.flatListings.forEach(listing => {
        listing.set('selected', false);
      });
    } else {
      this.allListingsSelected = true;
      this.flatListings.forEach(listing => {
        this.selectedListings.pushObject(listing);
        listing.set('selected', true);
      });
    }
  }

  @action
  changeAppearance(selectedView) {
    if (selectedView.name === this.intl.t('pricing.dashboard')) {
      set(this, 'mode', 'new');
      this.transitionToRoute('dashboard.index', {
        queryParams: { appearance: 'card-view', mode: 'new' },
      });
      set(this, 'selectedView', selectedView);
    } else if (selectedView.name === this.intl.t('pricing.table')) {
      this.transitionToRoute('dashboard.index', {
        queryParams: { appearance: 'table-view', mode: 'legacy' },
      });
      set(this, 'mode', 'legacy');
      set(this, 'selectedView', selectedView);
    } else if (
      selectedView.name === this.intl.t('pricing.grid') &&
      !selectedView.beta
    ) {
      this.transitionToRoute('dashboard.grid');
      set(this, 'mode', 'legacy');
    } else if (selectedView.beta && selectedView.name === this.intl.t('pricing.grid')) {
      this.transitionToRoute('dashboard.pricing.grid');
    } else if (selectedView.name === this.intl.t('pricing.autoBookingReview')) {
      this.transitionToRoute('dashboard.pricing.booking-review');
    }
  }

  /** saved filter */
  @computed('model.savedFilters.length')
  get savedFilters() {
    return this.model.savedFilters;
  }

  get gridStyle() {
    return htmlSafe(
      `grid-template-columns: 300px repeat(${this.allDates.length}, 50px);`
    );
  }
  get todayStyle() {
    return htmlSafe(`left: ${this.todayOffset}px; height: ${this.todayHeight}px`);
  }
  get loadingStyle() {
    return htmlSafe(`left: ${this.fromDateOffset}px; width: ${this.endDateOffset}px;`);
  }

  get allDates() {
    let date = this.startDate.clone();
    const allDates = [];
    while (date.isSameOrBefore(this.endDate)) {
      allDates.push(date.clone());
      date.add(1, 'day');
    }
    return allDates;
  }

  get allMonths() {
    let date = this.startDate.clone();
    const allMonths = [];
    while (date.isSameOrBefore(this.endDate)) {
      const next = moment.min(date.clone().endOf('month'), this.endDate);
      allMonths.push({
        first: date.clone(),
        remainingDays: next.diff(date, 'days') + 1,
        title: date.locale('en').format('MMMM'),
      });
      date.add(1, 'month').startOf('month');
    }
    return allMonths;
  }

  @computed('flatListings', 'listingCalendars')
  get todayHeight() {
    if (document.querySelector('.today')) {
      document.querySelector('.today').style.height = '0';
    }
    return document.querySelector('.wrapper').scrollHeight;
  }

  get todayOffset() {
    if (!this.endDate) {
      return 0;
    }
    const $cell = document.querySelector('.cell.date');
    const $title = document.querySelector('.wrapper .title');
    if (!($cell && $title)) {
      return 0;
    }
    const days = this.today.diff(this.startDate, 'days');
    return $title.offsetWidth + days * $cell.offsetWidth;
  }
  get fromDateOffset() {
    if (!this.endDate) {
      return 0;
    }
    const $cell = document.querySelector('.cell.date');
    const $title = document.querySelector('.wrapper .title');
    if (!($cell && $title)) {
      return 0;
    }
    const days = this.fromDate.diff(this.startDate, 'days');
    return $title.offsetWidth + days * $cell.offsetWidth;
  }
  get endDateOffset() {
    if (!this.endDate) {
      return 0;
    }
    const $cell = document.querySelector('.cell.date');
    if (!$cell) {
      return 0;
    }
    const days = this.endDate.diff(this.fromDate, 'days') + 1;
    return days * $cell.offsetWidth;
  }

  // -- Actions ------------------------------------------------------------------------
  @action
  toggleFilterSidePanel(type) {
    if (type === 'close') {
      this.isFilterVisible = false;
    }

    later(
      this,
      () => {
        this.transitionToRoute(this.toggleFilterRoutePath);
      },
      100
    );
  }

  // -- Grid Utilities -----------------------------------------------------------------
  findListingDateFromPosition(x, y, path) {
    // Take a grid X/Y coordinate and give the corresponding listing / date combination.
    // This is faster / better than looking up the data from the element as it allows
    // looking up a listing / date even when the point is over a reservation or a gap
    // between dates.

    const $wrapper = document.querySelector('.wrapper');
    // Use the first cell position to calculate all offsets.
    const $cell = document.querySelector('.cell.body');
    if (!$cell) {
      return {};
    }
    // First cell could be a reservation - use the date cell for width
    const $dateCell = document.querySelector('.cell.date');
    // Can't use $dateCell.clientWidth/clientHeight because we're using borders /
    // margins.
    if (!$dateCell) {
      return {};
    }

    let dateOffset = Math.floor(
      (x + $wrapper.scrollLeft - $cell.offsetLeft - $cell.offsetParent.offsetLeft) /
        $($dateCell).outerWidth(true)
    );

    dateOffset = Math.clamp(dateOffset, 0, this.allDates.length - 1);
    const clickDate = moment(this.startDate).add(dateOffset, 'days');

    let el = path.find(p => p.id?.includes('cell'));

    const id = el ? el.id.split('-')[1] : '';
    const hoverId = el ? el.id.split('-')[5] : '';

    return {
      date: clickDate,
      listingId: parseInt(id),
      hoverId: parseInt(hoverId),
    };
  }

  clearSelected() {
    document
      .querySelectorAll('.cell.selected')
      .forEach(el => el.classList.remove('selected'));
    this.selectedDates = [];
    this.selectedListingIds = [];
    this.transitionToRoute('dashboard.grid');
  }

  setSelected(
    { startListingId, endListingId, startDate, endDate },
    isMultipleSelection
  ) {
    // For a range of listings / dates, set the .selected class on all listings / dates
    // in between. This is used for drag selections.
    if (isMultipleSelection) {
      document
        .querySelectorAll('.cell.select-multiple')
        .forEach(el => el.classList.remove('selected', 'select-multiple'));
    } else {
      document
        .querySelectorAll('.cell.selected')
        .forEach(el => el.classList.remove('selected'));
    }

    // If startDate and endDate are equal we can end up with both min and max
    // returning the same moment object, and then the loop never exits as both date
    // and end are being incremented.
    let date = moment.min(startDate, endDate).clone();
    const end = moment.max(startDate, endDate);

    const selectedDates = [];
    while (date <= end) {
      selectedDates.push(date.format('YYYY-MM-DD'));
      date.add(1, 'day');
    }
    this.selectedDates = selectedDates;

    let listingIds = this.flatListings.map(l => l.id);
    const listingIndices = [
      listingIds.indexOf(startListingId),
      listingIds.indexOf(endListingId),
    ].sort((a, b) => a - b);
    listingIds = listingIds.slice(listingIndices[0], listingIndices[1] + 1);
    this.selectedListingIds = listingIds;

    for (let id of listingIds) {
      for (let date of selectedDates) {
        const el = document.querySelector(`#cell-${id}-${date}-${id}`);
        if (el) {
          if (isMultipleSelection) {
            el.classList.add('select-multiple');
          }
          el.classList.add('selected');
        }
      }
    }

    const selectedCells = document.querySelectorAll('.selected');
    let selectedItems = [];
    for (const cell of selectedCells) {
      const cellData = cell.id.split('-');
      const cellDate = `${cellData[2]}-${cellData[3]}-${cellData[4]}`;
      if (selectedItems.find(it => it.listingId === cellData[1])) {
        const ind = selectedItems.findIndex(it => it.listingId === cellData[1]);
        selectedItems[ind].dates.push(cellDate);
      } else {
        selectedItems.push({
          listingId: cellData[1],
          dates: [cellDate],
        });
      }
    }

    let newSelection = {};
    let gridStartDate;
    let gridEndDate;
    for (const cell of selectedItems) {
      newSelection = {
        [cell.listingId]: [],
        ...newSelection,
      };
      for (let i = 0; i < cell.dates.length; i++) {
        if (newSelection[cell.listingId].length === 0) {
          newSelection[cell.listingId].push({
            startDate: cell.dates[0],
            endDate: cell.dates[0],
          });
        } else {
          const endDate =
            newSelection[cell.listingId][newSelection[cell.listingId].length - 1]
              .endDate;
          if (
            moment(cell.dates[i], 'YYYY-MM-DD').diff(
              moment(endDate, 'YYYY-MM-DD'),
              'days'
            ) === 1
          ) {
            newSelection[cell.listingId][
              newSelection[cell.listingId].length - 1
            ].endDate = cell.dates[i];
          } else {
            newSelection[cell.listingId].push({
              startDate: cell.dates[i],
              endDate: cell.dates[i],
            });
          }
        }

        // get overall start and end date
        if (
          !gridStartDate ||
          moment(gridStartDate, 'YYYY-MM-DD').diff(
            moment(cell.dates[i], 'YYYY-MM-DD'),
            'days'
          ) > 0
        ) {
          gridStartDate = cell.dates[i];
        }

        if (
          !gridEndDate ||
          moment(gridEndDate, 'YYYY-MM-DD').diff(
            moment(cell.dates[i], 'YYYY-MM-DD'),
            'days'
          ) < 0
        ) {
          gridEndDate = cell.dates[i];
        }
      }
    }

    this.gridStartDate = gridStartDate;
    this.gridEndDate = gridEndDate;
    this.gridSelection = newSelection;
  }

  // -- Event Handler Helpers ----------------------------------------------------------
  _attachedHandlers = [];
  attachHandler(element, eventName, handler, options = {}) {
    const boundHandler = handler.bind(this);
    this._attachedHandlers.push([element, eventName, boundHandler, options]);

    element.addEventListener(eventName, boundHandler, options);
  }
  detachHandler(detachElement, detachEventName) {
    const remainingHandlers = [];
    for (let [element, eventName, handler, options] of this._attachedHandlers) {
      if (detachElement === element && detachEventName === eventName) {
        element.removeEventListener(eventName, handler, options);
      } else {
        remainingHandlers.push([element, eventName, handler, options]);
      }
    }
    this._attachedHandlers = remainingHandlers;
  }
  detachAllHandlers() {
    for (let [element, eventName, handler, options] of this._attachedHandlers) {
      element.removeEventListener(eventName, handler, options);
    }
    this._attachedHandlers = [];
  }

  // -- Event Handlers -----------------------------------------------------------------
  mouseMoveHandler(event) {
    // Track the mouse move when dragging so we can paint the cells a different color to
    // show the current selection.
    const { listingId, date, hoverId } = this.findListingDateFromPosition(
      event.pageX,
      event.pageY,
      event.composedPath()
    );
    this.hoverListingId = hoverId;
    this.hoverDate = date;

    if (!this.dragInfo) {
      return;
    }

    // Select boxes if we're still inside the grid
    if (listingId && date) {
      this.setSelected(
        {
          startListingId: this.dragInfo.listingId,
          endListingId: listingId,
          startDate: this.dragInfo.date,
          endDate: date,
        },
        event.metaKey || event.ctrlKey
      );
    }
    const $wrapper = document.querySelector('.wrapper');

    // Draw the select box regardless of where they are
    const width = Math.abs(event.pageX - this.dragInfo.x);
    const height = Math.abs(event.pageY - this.dragInfo.y);
    let newX =
      event.pageX < this.dragInfo.x ? this.dragInfo.x - width : this.dragInfo.x;
    let newY =
      event.pageY < this.dragInfo.y ? this.dragInfo.y - height : this.dragInfo.y;

    newX = newX + $wrapper.scrollLeft - $wrapper.offsetLeft;
    newY = newY + $wrapper.scrollTop - $wrapper.offsetTop;

    this.dragInfo.box.style.width = `${width}px`;
    this.dragInfo.box.style.height = `${height}px`;
    this.dragInfo.box.style.top = `${newY}px`;
    this.dragInfo.box.style.left = `${newX}px`;
  }

  mouseDownHandler(event) {
    // Start the drag if they click inside a cell and check if it's left click
    if (!event.target.closest('.cell') || event.which !== 1) {
      return;
    }

    Array.from(document.querySelectorAll('.focus')).forEach(el =>
      el.classList.remove('focus')
    );
    this.hoverReservationTarget = null;

    const { listingId, date } = this.findListingDateFromPosition(
      event.pageX,
      event.pageY,
      event.composedPath()
    );
    const box = document.createElement('div');
    this.dragInfo = {
      date: date,
      listingId: listingId,
      box: box,
      x: event.pageX,
      y: event.pageY,
    };

    box.style.position = 'absolute';
    box.style.background = 'transparent';
    box.style.border = '1px dotted black';
    box.style.zIndex = 1000;
    // Append to the wrapper instead of the body so if we quickly move the mouse onto
    // the selected area then the events still bubble.
    document.querySelector('.wrapper').appendChild(box);

    // End the drag on mouseup, but also when the user exits the body, so we can stop
    // dragging when they drag off the window.
    this.attachHandler(document.body, 'mouseup', this.mouseUpHandler, {
      passive: true,
    });
    this.attachHandler(document.body, 'mouseLeave', this.mouseUpHandler, {
      passive: true,
    });
  }

  mouseUpHandler(event) {
    // End the drag if they mouse up and were dragging, show the bulk action pane.
    this.dragInfo.box.remove();
    if (event.ctrlKey || event.metaKey) {
      document
        .querySelectorAll('.cell.select-multiple')
        .forEach(el => el.classList.remove('select-multiple'));
    }

    // Make a click without a drag deselect everything
    if (this.dragInfo.x === event.pageX && this.dragInfo.y === event.pageY) {
      if (!event.metaKey) {
        this.clearSelected();
      }

      if (event.target.closest('.reservation')) {
        const $res = event.target.closest('.reservation');
        $res.classList.add('focus');
        document
          .querySelectorAll('.multi-grid.body')
          .forEach(el => el.classList.add('focus'));
        this.hoverReservationTarget = $res;
      }
    } else {
      this.transitionToRoute('dashboard.grid.bulk');
    }
    this.dragInfo = null;
    this.detachHandler(document.body, 'mouseup', this.mouseUpHandler);
    this.detachHandler(document.body, 'mouseleave', this.mouseUpHandler);
  }
  scrollToToday() {
    // Called on first load from the router in case we have past days loaded.
    document.querySelector('.wrapper').scrollLeft = this.todayOffset;
  }

  updateDates() {
    if (this.endDate) {
      this.fromDate = this.endDate.clone().add(1, 'day');
      // Shouldn't need to reassign here, but ember needs an assignment to trigger a
      // change in the tracked property.
      this.endDate = this.endDate.add(1, 'month');
    } else {
      // No dates set - this is our first run.
      this.fromDate = this.startDate;
      this.endDate = this.startDate.clone().add(3, 'months');
    }
  }

  async fetchData() {
    const fromDateIso = moment()
      .startOf('day')
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    if (!this.endDate) this.updateDates();
    const untilDateIso = this.endDate.format('YYYY-MM-DD');
    const todayIso = this.today.format('YYYY-MM-DD');

    // TODO: requesting individual fields works on backend - need to support on the
    // frontend as well.
    const fields = [
      'availability',
      'price_user',
      'price_posted',
      'min_stay_user',
      'max_stay_user',
      'min_price_user',
      'max_price_user',
      'reservation_ids',
      'percentage_user_override',
    ];

    let listingIds = [];

    for (let listingId of this.visibleListingIds) {
      if (this.cached[listingId] === untilDateIso) {
        continue;
      }

      this.cached[listingId] = untilDateIso;
      listingIds.push(listingId);
    }

    if (listingIds.length == 0) {
      return;
    }

    this.loadingMoreDates = true;

    const url =
      `/api/grid/${fromDateIso}/${untilDateIso}` +
      `?today=${todayIso}&fields=${fields.join(',')}` +
      `&listing_ids=${listingIds.join(',')}`;

    let data;
    try {
      data = await this.ajax._get(url);
    } catch {
      this.alert.error('validation.dashboardErrors.calendarLoading');
      return;
    }

    data.reservations.forEach(row =>
      this.bpStore.createRecord('reservation', {
        id: row[0],
        checkinDate: row[1],
        checkoutDate: row[2],
        channelListingId: row[3],
        nbNights: row[4],
        reference: row[5],
        isOwner: row[6],
        bookedAt: row[7],
        currency: row[8],
        rentalAmount: row[9],
        sourceChannel: row[10],
      })
    );

    // I can't make ember refresh this without resetting it to a new object.
    let newCalendars = { ...this.listingCalendars };

    data.listingCalendars.forEach(calendar => {
      let existingCalendar = this.listingCalendars[calendar.channelListingId];
      if (!existingCalendar) {
        // JS objects iterate in a bizarre order and not based on insertion order, so we
        // can't use them if we want to iterate in order easily. Rather than splitting
        // everything into key/value arrays, use maps, which preserve insertion order.
        existingCalendar = EmberObject.create({
          availability: new Map(),
          minStayUser: new Map(),
          maxStayUser: new Map(),
          minPriceUser: new Map(),
          maxPriceUser: new Map(),
          reservationIds: new Map(),
          priceUser: new Map(),
          pricePosted: new Map(),
          reservation: new Map(),
          percentageUserOverride: new Map(),
        });
      }

      // We shouldn't have duplicate values within a single response, but we may have
      // them when stitching two responses together. Remove them.
      Object.keys(existingCalendar).forEach(field => {
        if (!calendar[field]) {
          return;
        }

        let tmpField = {};

        for (let [key, value] of existingCalendar[field]) {
          tmpField[key] = value;
        }

        for (let key of Object.keys(calendar[field])) {
          tmpField[key] = calendar[field][key];
        }

        let sortedKeys = Object.keys(tmpField).sort((a, b) => Number(a) - Number(b));
        let newField = new Map();

        for (let key of sortedKeys) {
          newField.set(key, tmpField[key]);
        }

        existingCalendar[field] = newField;
      });

      // Pre-populate the reservations for quick lookup
      const reservationChangeOffsets = Array.from(
        existingCalendar.reservationIds.keys()
      );
      reservationChangeOffsets.forEach(offset => {
        const currentIds = existingCalendar.reservationIds.get(offset);

        // Ignore double bookings - all should have been removed on the backend already.
        if (currentIds.length !== 1) {
          return;
        }

        existingCalendar.reservation.set(
          offset,
          this.bpStore.peekRecord('reservation', currentIds[0])
        );
      });

      newCalendars[calendar.channelListingId] = existingCalendar;
    });

    this.listingCalendars = newCalendars;
    this.loadingMoreDates = false;
  }

  @action async scrollHandler(event) {
    const cellWidth = 50;

    // Start loading when they are 14 days from the end. Could probably do scroll
    // momentum to make this better.
    // Hard coded width to save lookup time - this function is called a lot.
    if (event.target.scrollLeft + 14 * cellWidth < scrollLeftMax(event.target)) {
      return;
    }

    this.updateDates();

    // We want to flag the `loadingMoreDates` as quickly as possible so future scroll
    // events return as quickly as possible. Before this was set in `fetchData` but
    // scroll was lagging when loading the next page.
    // This pattern is also used in the route and should probably be moved into a
    // function.
    await this.fetchData();
  }

  @action
  attachHandlers() {
    // Called from did-insert modifier in template
    const $wrapper = document.querySelector('.wrapper');

    // Mouse move handlers to set the row / column highlights when hovering, and draw
    // the selection box / styles when dragging.
    this.attachHandler($wrapper, 'mousemove', this.mouseMoveHandler, { passive: true });
    // Mouse down handler to start the selection drag
    this.attachHandler($wrapper, 'mousedown', this.mouseDownHandler, { passive: true });
  }

  detachHandlers() {
    // Called from the route deactivate hook
    this.detachAllHandlers();
  }

  get showBulkEditActions() {
    return this.selectedListings.length > 0;
  }

  @action addSelectedListing(listing, option) {
    const hasListing = this.selectedListings.find(l => l.id === listing.id);

    if (hasListing || option === false) {
      this.selectedListings.removeObject(listing);
      listing.set('selected', false);
    } else {
      this.selectedListings.pushObject(listing);
      listing.set('selected', true);
    }

    this.allListingsSelected =
      this.flatListings.filter(l => l.selected).length === this.flatListings.length;
  }

  async updateDataInGrid(selectedDayIds, startDate, endDate) {
    const fields = [
      'price_user',
      'min_stay_user',
      'min_price_user',
      'percentage_user_override',
    ];

    const todayIso = this.today.format('YYYY-MM-DD');

    const endDateNextDay = moment(endDate, 'YYYY-MM-DD')
      .add(1, 'days')
      .format('YYYY-MM-DD');

    const url =
      `/api/grid/${startDate}/${endDateNextDay}` +
      `?today=${todayIso}&fields=${fields.join(',')}` +
      `&listing_ids=${selectedDayIds.join(',')}`;

    let data;

    data = await this.ajax._get(url);

    let newCalendars = { ...this.listingCalendars };

    data.listingCalendars.forEach(calendar => {
      let existingCalendar = this.listingCalendars[calendar.channelListingId];

      Object.keys(existingCalendar).forEach(field => {
        if (!calendar[field]) {
          return;
        }

        let tmpField = {};

        for (let [key, value] of existingCalendar[field]) {
          tmpField[key] = value;
        }

        for (let key of Object.keys(calendar[field])) {
          tmpField[key] = calendar[field][key];
        }

        let sortedKeys = Object.keys(tmpField).sort((a, b) => Number(a) - Number(b));
        let newField = new Map();

        for (let key of sortedKeys) {
          newField.set(key, tmpField[key]);
        }

        existingCalendar[field] = newField;
      });

      newCalendars[calendar.channelListingId] = existingCalendar;
    });

    this.listingCalendars = newCalendars;
  }

  @action
  toggleListing(listingId) {
    this.openMultiUnitListings = this.openMultiUnitListings.includes(listingId)
      ? this.openMultiUnitListings.filter(id => id != listingId)
      : [...this.openMultiUnitListings, listingId];
  }

  @action
  clickedBulkEdit() {
    this.transitionToRoute('dashboard.grid.bulk-edit');
  }

  @action
  cancelBulkEdit() {
    this.selectedListings = [];
    for (let l of this.flatListings) {
      l.set('selected', false);
    }
    this.transitionToRoute('dashboard.grid');
  }

  @action
  closeConfirmationBulkUpdate() {
    this.confirmBulkActions = false;
  }

  @action
  saveBulkSettings() {
    this.confirmBulkActions.success();
    set(this, 'confirmBulkActions', false);
  }

  @action
  getWeekday(date) {
    return moment(date).format('dddd');
  }

  @action
  translatedWeekdays(weekdays) {
    return weekdays
      .map(d => this.intl.t(`common.weekdays.${d.toLowerCase()}`))
      .join(', ');
  }

  /** saved filter */

  _resetSavedFilterAttr() {
    set(this, 'listControl.saveFilterModalIsVisible', false);
    set(this, 'listControl.editFilterModalIsVisible', false);
    set(this, 'listControl.deleteFilterModalIsVisible', false);
    set(this, 'listControl.currentlySelectedSavedFilter', null);
    set(this, 'listControl.newFilterName', '');
  }

  @action
  closeShowFilterModal() {
    this.listControl.closeShowFilterModal();
  }

  @action
  closeEditFilterModal() {
    this.listControl.closeEditFilterModal();
  }

  @action
  async saveNewFilter() {
    try {
      // api call
      await this.savedFiltersApi.saveNewFilter({
        name: this.listControl.newFilterName,
        content: this.listControl.updatedQueryParams,
      });

      // reset
      this.listControl.resetAllFilters();
      this.send('updateSearchParams', this.listControl.defaultQueryParams);
      this.listControl.resetInitialAndUpdatedQueryParams();

      // reload
      getOwner(this).lookup('route:dashboard').refresh();
    } finally {
      this._resetSavedFilterAttr();
    }
  }

  @action
  async editFilter(newFilterName) {
    let savedFilter;

    // set saved filter depends on how user is updating filter
    if (this.listControl.currentlySelectedSavedFilter) {
      savedFilter = this.listControl.currentlySelectedSavedFilter;
      // case for updating via modal
    } else {
      savedFilter = this.listControl.selectedSavedFilter;
      // case for updating via update button
    }

    try {
      // api call
      if (newFilterName) {
        await this.savedFiltersApi.renameFilter(savedFilter, {
          name: this.listControl.newFilterName,
        });
      } else {
        await this.savedFiltersApi.editFilter(savedFilter, {
          content: this.listControl.updatedQueryParams,
        });
      }
      // reset
      this.listControl.initialQueryParams.setProperties(
        this.listControl.updatedQueryParams
      );

      // reload
      getOwner(this).lookup('route:dashboard').refresh();
    } finally {
      this._resetSavedFilterAttr();
    }
  }

  @action
  async deleteFilter() {
    try {
      // api call
      await this.savedFiltersApi.deleteFilter(
        this.listControl.currentlySelectedSavedFilter
      );

      // reset
      this.listControl.resetAllFilters();
      this.send('updateSearchParams', this.listControl.defaultQueryParams);
      this.listControl.resetInitialAndUpdatedQueryParams();

      // reload
      getOwner(this).lookup('route:dashboard').refresh();
    } finally {
      this._resetSavedFilterAttr();
    }
  }

  @action
  firstVisibleChanged(_item, firstPos) {
    this.firstVisiblePos = firstPos;
  }

  @action
  lastVisibleChanged(_item, lastPos) {
    if (lastPos != this.lastVisiblePos || lastPos === 0) {
      this.verticalDirection = lastPos > this.lastVisiblePos ? 'down' : 'up';
      this.lastVisiblePos = lastPos;
      this.fetchData();
    }
  }

  @action
  alertToUnavailableListing(onProgram, title) {
    if (onProgram) return;

    this.alert.error(
      this.intl.t('pricing.listing.alertToOffProgramListing', {
        listingTitle: title,
      }),
      { timeout: 10000 }
    );
  }

  @action
  transitionToNewGridView() {
    set(this, 'mode', 'new');

    this.transitionToRoute('dashboard.pricing.grid');
  }
}

function scrollLeftMax(element) {
  return element.scrollWidth - element.clientWidth;
}

function any(listToCheck, list) {
  for (let item in list) {
    if (listToCheck.includes(item)) {
      return true;
    }
  }

  return false;
}
