import moment from 'moment';

// DATA MATCHERS -------------------------------------------------------------------------

export function getReservationsInDateRange(reservations, dateStart, dateEnd) {
  return reservations.filter(res => {
    const start = moment(dateStart);
    //In the calendar checkout date doesn´t count as reservation, just the previous night
    // When we click on a reservation, dateStart and dateEnd are a range.
    // When we click on a empty day, dateStart and dateEnd are the same.
    const end =
      dateEnd !== dateStart ? moment(dateEnd).subtract(1, 'day') : moment(dateEnd);
    const checkin = moment(res.checkinDate || res.attributes.checkinDate);
    const checkout = moment(res.checkoutDate || res.attributes.checkoutDate);

    const isSameRange = start.isSameOrAfter(checkin) && end.isBefore(checkout);

    const isStartColliding = start.isBefore(checkin) && end.isSameOrAfter(checkin);

    const isEndColliding = start.isBefore(checkout) && end.isSameOrAfter(checkout);

    return isSameRange || isStartColliding || isEndColliding;
  });
}

export function getReservationErrorLog(reservationId, dcOutcomeLog) {
  return dcOutcomeLog.filter(
    outcome =>
      outcome.attributes.status === 'error' &&
      outcome.relationships.reservation.data.id === reservationId
  );
}

export function isDoubleBooking(PMSReservation, directChannelReservationsInRange) {
  if (!PMSReservation || !directChannelReservationsInRange.length) return false;

  const dclReferences = directChannelReservationsInRange
    .filter(res => res.attributes.status === 'accepted')
    .map(res => res.attributes.reference);

  return dclReferences.some(ref => ref !== PMSReservation.reference);
}

export function isDirectChannelDoubleBooking(dcReservation, PMSReservations) {
  if (!PMSReservations.length) return false;

  const dcReference = dcReservation.attributes.reference;
  return !PMSReservations.includes(dcReference);
}

export function sortReservationsByCheckinDate(a, b) {
  const checkinA = moment(a.checkinDate || a.attributes.checkinDate);
  const checkingB = moment(b.checkinDate || b.attributes.checkinDate);

  return checkinA.isSameOrAfter(checkingB) ? 1 : -1;
}

//
function didRelayPushReservation(reservation) {
  if (!reservation) return false;
  const { status, pushedReservationStamps } = reservation.attributes;
  return status === 'accepted' && pushedReservationStamps.length;
}

function isPMSReferenceIncludedInReservationPush(
  channelReservationId,
  reference,
  reservationPushOutcome
) {
  const { attributes, relationships } = reservationPushOutcome;
  const includesPMSReference = attributes.message.includes(reference);
  const includesReservationId =
    relationships.reservation.data.id === channelReservationId;

  return includesPMSReference && includesReservationId;
}

export function isDCDoubleBooking(dcReservation, listing, pushedReservationsChangelog) {
  // if (!pmsReference || !dcReservationsInDaterange.length) return false;
  const { checkinDate, checkoutDate } = dcReservation.attributes;

  const PMSReservationsInRange = getReservationsInDateRange(
    listing.reservations,
    checkinDate,
    checkoutDate
  );
  // UC 0: DC Reservation, but no PMS syncing yet.
  if (!PMSReservationsInRange.length) return false;

  //UC 1: There should only be 1 reservation in PMS in the range of dates of the DC reservation
  if (PMSReservationsInRange > 1) return true;

  //UC 2: Synced reservation.
  // The PMS reservation reference an the dc Reservation Id should be in the reservation push log.
  if (!didRelayPushReservation(dcReservation)) return false;

  const [pmsReservation] = PMSReservationsInRange;
  const { pushedReservationAt, pushedReservationStamps } = dcReservation.attributes;

  const i = pushedReservationStamps.findIndex(stamp => stamp === pushedReservationAt);
  const { id: pushId } = dcReservation.relationships.reservationpushoutcomeSet.data[i];
  const firstPushData = pushedReservationsChangelog.find(log => log.id === pushId);

  if (
    isPMSReferenceIncludedInReservationPush(
      dcReservation.id,
      pmsReservation.reference,
      firstPushData
    )
  ) {
    // PMS Reservation was created by Relay. DC and PMS reservations are bound.
    // console.log('PMS REFERENCE INCLUDED', pmsReservation.reference, firstPushData);
    return false;
  } else {
    return true;
  }
}
