import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Controller from '@ember/controller';
function chartSeries(name, data, visible) {
  let series = {
    name: name,
    data: data,
    tooltip: { valueDecimals: 0, valuePrefix: '$' },
  };
  // test
  if (!visible) {
    series.visible = false;
  }
  return series;
}

@classic
export default class ClusterPricesController extends Controller {
  compareId = null;
  loadingCompareData = false;
  compareData = [];

  chartOptions = {
    rangeSelector: {
      selected: 5,
    },

    yAxis: [
      {
        labels: {
          format: '${value}',
        },
        title: {
          text: 'Price (USD)',
        },
      },
      {
        title: {
          text: 'Number',
        },
      },
    ],
    legend: {
      padding: 8,
      enabled: true,
      itemDistance: 10,
    },
    navigator: { baseSeries: 0 },
  };

  // Have to stick with highcharts 4.2.7 to add a series. Right now it breaks
  // with setting the data on the navigator. I have lost the errors, but it
  // will be complaining about pointStart. I think it's this issue:
  // https://github.com/highcharts/highcharts/issues/5936
  @computed('model.{prices,clusterList}', 'compareData')
  get chartData() {
    let out = [];
    let clusterList = this.get('model.clusterList');
    let prices = this.get('model.prices');
    clusterList.forEach(function (e) {
      let price = prices[e[0]];
      out.push(chartSeries(`${e[0]}: ${e[1]}`, price, false));
    });
    // Make the first line visible
    out[0].visible = true;
    return out;
  }
}
