import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import logger from 'appkit/lib/logger';
import { getOwner } from '@ember/application';
import { ChannelUtil } from 'appkit/lib/channel';
import { inject as service } from '@ember/service';

@classic
export default class AccountRoute extends AuthRoute {
  @service() featureFlag;
  @service impact;
  isSelfServedOnboardingEnabled = false;

  beforeModel() {
    if (this.modelFor('dashboard.populate').accounts.length < 1) {
      this.featureFlag.evaluate('self-served-onboarding', false).then(ff => {
        if (ff) this.isSelfServedOnboardingEnabled = true;
      });
    }

    return;
  }

  async model(params) {
    let out = EmberObject.create({ status: 'loading listings' });
    let accountId = params.id;

    const data = await this.ajax._get(`/api/accounts/${accountId}`);
    let channel = null;
    if (data && data.account) {
      channel = data.account.channel;
    }

    // Sync the account with force=True to make sure it is scraped
    let url = `/api/accounts/${accountId}/sync/force`;

    this.ajax
      .stream(url, {}, async data => {
        if (data.message) {
          out.set('status', data.message);
        }
        await this.impact.trackConversion('AddListing', this.currentUser);
      })
      .then(
        async () => {
          logger.info('Promise stream done', arguments);

          let applicationInstance = getOwner(this);
          let dashboardRoute = applicationInstance.lookup('route:dashboard');
          let triggerToOnboarding = false;

          if (
            dashboardRoute.currentModel.accounts.length < 1 &&
            this.isSelfServedOnboardingEnabled
          ) {
            triggerToOnboarding = true;
          }

          dashboardRoute.refresh();

          if (ChannelUtil.isPms(channel)) {
            this.replaceWith('dashboard');
          } else {
            this.replaceWith('dashboard.relay');
          }

          if (triggerToOnboarding) {
            this.replaceWith('dashboard.onboarding.intro');
          }

          /**
           * Submits user attributes for ConnectAccount and Trial setup tracking to Impact affiliate partner
           */
          try {
            await this.impact.trackConversion('ConnectAccount', this.currentUser);
            await this.impact.trackConversion('Trial', this.currentUser);
            logger.info(
              'ConnectAccount and Trial events dispatched to Impact Affiliate Partner.'
            );
          } catch (e) {
            logger.error(
              'Failed to dispatch ConnectAccount and Trial events to Impact partner.',
              e
            );
          }
        },
        err => {
          logger.error('Promise stream error', err);

          if (err.constructor === ProgressEvent) {
            this.render('error.timeout', { into: 'application' });
          } else {
            this.render('error.server', { into: 'application' });
          }
        }
      );

    return out;
  }
}
