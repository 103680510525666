import Controller from '@ember/controller';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import { run } from '@ember/runloop';

class Errors {
  @tracked _cache = {};

  set(key, value) {
    this._cache[key] = value;
    this._cache = this._cache;
  }

  get(key) {
    return this._cache[key];
  }
}

export default class BulkUpdateController extends Controller {
  @tracked saving = false;
  @tracked objectId = '';
  @tracked objectType = '';
  @tracked startDate = null;
  @tracked endDate = null;
  @tracked errors = new Errors();
  @tracked responseData = A([]);
  @tracked extraYears = 0;

  constructor() {
    super(...arguments);
    this.startDate = moment.utc().startOf('year').subtract(1, 'year');
    this.endDate = moment.utc();
  }

  get range() {
    return {
      start: this.startDate,
      end: this.endDate,
    };
  }

  get hasErrors() {
    return Object.keys(this.errors).length > 0;
  }

  validate() {
    this.errors = new Errors();

    if (!this.objectType) {
      this.errors.objectType = 'Object Type is required';
    } else if (!['managed_account', 'user', 'market'].includes(this.objectType)) {
      this.errors.objectType = 'Object Type must be managed_account, user, or market';
    }

    if (!this.objectId) {
      this.errors.objectId = 'Object ID is required';
    }

    if (!this.startDate) {
      this.errors.startDate = 'Start Date is required';
    }
    if (!this.endDate) {
      this.errors.endDate = 'End Date is required';
    }
    if (this.startDate.isAfter(this.endDate)) {
      this.errors.base = 'Start Date must be before End Date';
    }

    return Object.keys(this.errors).length == 0;
  }

  generateDownload(fileData) {
    const bytes = atob(fileData);
    const arrayBuffer = new ArrayBuffer(bytes.length);
    let view = new Uint8Array(arrayBuffer);
    for (var i = 0; i != bytes.length; ++i) {
      view[i] = bytes.charCodeAt(i) & 0xff;
    }
    const blob = new Blob([arrayBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const now = moment().toISOString(true).slice(0, 19);
    link.download = `${now}-revlift-${this.objectType}-${this.objectId}.xlsx`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  @action
  async setRange(value) {
    this.startDate = value.start;
    this.endDate = value.end;
  }

  @action
  async submit() {
    if (!this.validate()) {
      return;
    }
    this.saving = true;
    this.responseData = A([]);
    const url = `/api/data/rev-lift`;
    const params = {
      objectType: this.objectType,
      objectId: this.objectId,
      startDate: this.range.start.toISOString().slice(0, 10),
      endDate: this.range.end.toISOString().slice(0, 10),
      extraYears: this.extraYears,
    };

    try {
      await this.ajax.stream(url, params, responseData => {
        // No nice way to handle different message types back from JobSubscibeHandler,
        // so I'm hacking it with a prefix.
        if (responseData.message.startsWith('excel: ')) {
          this.responseData.pushObject('Generating excel file');
          run(() => {
            this.generateDownload(responseData.message.slice(7));
          });
        } else {
          this.responseData.pushObject(responseData.message);
        }
      });
    } catch (errors) {
      console.log(errors);
      let text = `Error: ${errors[0].title} - ${errors[0].source.message}`;
      this.responseData.pushObject(text);
      return;
    } finally {
      this.saving = false;
    }
  }
}
