import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';

@classic
export default class MarketDataRoute extends AuthRoute {
  templateName = 'listing.market-data';
  controllerName = 'listing.market-data';
  @service embed;

  setupController(controller, model) {
    controller.set('model', model);
    let listing = this.modelFor('dashboard.pricing.listing').get('listing');
    controller.set('listing', listing);
  }

  model() {
    this.embed.czCaptureEvent(
      'Market Data Tab View',
      'User viewed the Market Data Tab for a listing'
    );
    let id = this.modelFor('dashboard.pricing.listing').get('listing.id');
    let url = `/api/listings/${id}/market_data`;
    return this.ajax._get(url);
  }
}
