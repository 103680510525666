import LoginRoute from 'appkit/routes/login';
import { inject as service } from '@ember/service';

export default class CreateAccountRoute extends LoginRoute {
  @service router;

  beforeModel(transition) {
    super.beforeModel(...arguments);

    // If the user tries to access the old /create-account route redirect to pricing
    if (transition.to.name === 'create-account.index') {
      this.transitionTo('create-account.pricing');
    }
  }
}
