import Controller from '@ember/controller';
import { action } from '@ember/object';
import { hideForm, hideBanner, showForm } from 'appkit/lib/upsell/signal';
import { tracked } from '@glimmer/tracking';

export default class DashboardSignalFormController extends Controller {
  @tracked _signalBannerSubmitted;

  constructor() {
    super(...arguments);
  }

  get signalBannerSubmitted() {
    return this._signalBannerSubmitted ?? !showForm(this.model.user.id);
  }

  @action
  onSubmitInterestForm() {
    hideForm(this.model.user.id);
    hideBanner(this.model.user.id);
    this._signalBannerSubmitted = true;
  }
}
