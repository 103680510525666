import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
@classic
export default class UnavailableRoute extends Route {
  model(params) {
    return this.ajax
      ._get(`/api/listings/${params.id}`)
      .then(data => {
        // If this succeeds then we have a valid listing - point them to the
        // right place.
        let listing = this.bpStore.createRecord('listing', data);
        this.transitionTo('preview', listing);
      })
      .catch(errors => {
        let data = errors[0];
        return data.meta;
      });
  }
}
