import moment from 'moment';
import { isDCDoubleBooking } from 'appkit/lib/relay/utils';

export function getBasePriceMetadata(reservation, listing) {
  const { basePrice, minPrice } = listing;
  const { billableAmount, checkinDate, checkoutDate } = reservation.attributes;
  const totalDays = moment(checkoutDate).diff(checkinDate, 'days');
  const pricingMetadata = [];

  if (!basePrice) return pricingMetadata;

  const tripleBasePrice = totalDays * basePrice * 3;
  const doubleBasePRice = totalDays * basePrice * 2;
  const aboveAvgBasePrice = totalDays * basePrice * 1.5;
  const belowAvgBasePrice = totalDays * basePrice * 0.5;

  if (billableAmount >= tripleBasePrice) {
    pricingMetadata.push({
      id: 'basePricex3',
      text: '3x base price',
      class: 'bg-brand-600',
    });
  } else if (billableAmount >= doubleBasePRice) {
    pricingMetadata.push({
      id: 'basePricex2',
      text: '2x base price',
      class: 'bg-brand-500',
    });
  } else if (billableAmount >= aboveAvgBasePrice) {
    pricingMetadata.push({
      id: 'basePricex1.5',
      text: '1.5x base price',
      class: 'bg-brand-400',
    });
  } else if (billableAmount >= belowAvgBasePrice) {
    pricingMetadata.push({
      id: 'basePricex0.5',
      text: '0.5x base price',
      class: 'bg-gray-400',
    });
  } else if (billableAmount >= minPrice) {
    pricingMetadata.push({
      id: 'minPrice',
      text: 'Minimun price',
      class: 'bg-gray-400',
    });
  }

  return pricingMetadata;
}

export function getStayLengthMetadata(reservation, listing) {
  const { minStay } = listing;
  const { checkinDate, checkoutDate } = reservation.attributes;
  const totalDays = moment(checkoutDate).diff(checkinDate, 'days');
  const stayLengthMetadata = [];

  if (!minStay) return stayLengthMetadata;

  if (totalDays >= minStay * 2) {
    stayLengthMetadata.push({
      id: 'longStay',
      text: 'Long Stay',
      class: 'bg-success-600',
    });
  }
  if (totalDays < minStay) {
    stayLengthMetadata.push({
      id: 'belowMinStay',
      text: 'Below min Stay',
      class: 'bg-success-600',
    });
  }
  if (totalDays === minStay) {
    stayLengthMetadata.push({
      id: 'minStay',
      text: 'Min Stay',
      class: 'bg-success-600',
    });
  }
  return stayLengthMetadata;
}

export function getReservationSourceMetadata(reservation) {
  const { createdByRelay, createdBySignal, isOwner } = reservation.attributes;
  const sourceMetadata = [];

  if (isOwner) {
    sourceMetadata.push({
      id: 'ownerReservation',
      text: 'owner reservation',
      class: 'bg-gray-400',
    });
  }

  if (createdByRelay) {
    sourceMetadata.push({
      id: 'createdByRelay',
      text: 'created by Relay',
      class: 'bg-brand-400',
    });
  }

  if (createdBySignal) {
    sourceMetadata.push({
      id: 'createdBySignal',
      text: 'created By Signal',
      class: 'bg-signal-500',
    });
  }
  return sourceMetadata;
}

/** Compares a DC Reservation response with the PMS Reservations in the listing
 *
 * If is not a Double Booking:
 * - There should only be 1 PMS reservation in range of the DC reservation dates
 * - The DC Reservation should contain the Reference or reservationId of the PMS reservation
 */
export function getDCDoubleBookingMetadata(dcReservation, listing, dcPushLog) {
  const doubleBookingMetadata = [];

  if (isDCDoubleBooking(dcReservation, listing, dcPushLog)) {
    doubleBookingMetadata.push({
      id: 'doubleBooking',
      text: 'Double booking',
      class: 'bg-warning-400',
    });
  }

  return doubleBookingMetadata;
}
