import classic from 'ember-classic-decorator';
// Data down, actions up. I think this would be better suited to being defined
// in the app-credentials component, but because of data down, and no controller
// proxying, it has to be defined here.

import Controller from '@ember/controller';

@classic
export default class LoginController extends Controller {
  // If the user hit the login page as a result of being redirected from an
  // auth route, store the previous transition so we can put them back to where
  // they were.
  attemptedTransition = null;

  errors = {};
  posting = false;
}
