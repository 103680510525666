import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class ByListingRoute extends AuthRoute {
  templateName = 'dashboard.insights.by-listing';
  controllerName = 'dashboard.insights.by-listing';

  setupController(controller, model) {
    controller.set('model', model);
  }
}
