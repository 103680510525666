export class RelayAPISettingsRepository {
  constructor(ajax) {
    this.httpClient = ajax;
  }
  async get(managedAccountId) {
    const endpoint = `/api/relay/managed_account/${managedAccountId}/settings/`;
    const response = await this.httpClient._get(endpoint);

    return response.data.attributes;
  }

  async patch(managedAccountId, settings) {
    const endpoint = `/api/relay/managed_account/${managedAccountId}/settings/`;

    return this.httpClient._patch(endpoint, settings);
  }
}
