import classic from 'ember-classic-decorator';
import { observes, on } from '@ember-decorators/object';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-observers
import '@ember/object';
import Helper from '@ember/component/helper';
import moment from 'moment';

// https://github.com/moment/moment/issues/2433
@classic
export default class MomentLocale extends Helper {
  @service
  intl;

  // https://github.com/jamesarosen/ember-intl/issues/299
  @on('init')
  _initintl() {
    this.intl;
  }

  compute(params) {
    let [method, key] = params;
    let englishData = moment.localeData('en');

    if (!(method in englishData)) {
      throw Error(`Unknown moment property - ${method}`);
    }
    // https://github.com/moment/moment/issues/2433
    const index = englishData[method]().indexOf(key);
    return moment[method]()[index];
  }

  // eslint-disable-next-line ember/no-observers
  @observes('intl.locale')
  _recomputeOnLocaleChange() {
    this.recompute();
  }
}
