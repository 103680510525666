import Service from '@ember/service';

function addScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export default class PdfMakeService extends Service {
  instance = null;

  async getInstance() {
    if (this.instance) {
      return this.instance;
    }

    this.instance = (async function () {
      await addScript(
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.65/pdfmake.min.js'
      );
      await addScript(
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.65/vfs_fonts.min.js'
      );

      return window.pdfMake;
    })();

    return this.instance;
  }
}
