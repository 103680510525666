import Service from '@ember/service';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { oneWay } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import { CurrencyUtil } from 'appkit/lib/currency';

export default class BeyondI18NService extends Service {
  @tracked locale = null;

  @service
  ajax;

  @service
  intl;

  @service
  moment;

  @computed
  get locales() {
    return [
      { field: 'en', label: 'English - US' },
      {
        field: 'es',
        label: 'Español - España',
        new: this.intl.lookup('common.new', 'es'),
      },
      { field: 'fr', label: 'Français', new: this.intl.lookup('common.new', 'fr') },
      { field: 'it', label: 'Italiano', new: this.intl.lookup('common.new', 'it') },
      { field: 'pt', label: 'Português', new: this.intl.lookup('common.new', 'pt') },
      { field: 'en-GB', label: 'English - UK' },
      { field: 'en-AU', label: 'English - Australia' },
      { field: 'en-CA', label: 'English - Canada' },
      { field: 'es-MX', label: 'Español - LATAM' },
      { field: 'ja', label: '日本語' },
      { field: 'de', label: 'Deutsch' },
    ];
  }

  @oneWay('currentUser.userId')
  userId;

  /**
   * For new users without a locale, we want to use their browser language setting
   * (as long as we have translations for it)
   */
  @action
  initLocale() {
    let browserLocale = this.getBrowserLocale();
    let locale = localStorage.getItem('locale') || 'en';

    if (!localStorage.getItem('locale') && this.isBaseLocaleSupported(browserLocale)) {
      this.updateLocale(browserLocale);
    } else {
      this.updateLocale(locale);
    }
  }

  // @deprecated
  // this is now handled by React
  updateLocaleInServer(_locale) {
    // Avoid updating the locale if the user id is not set
    // This can happen when impersonating a user
    if (!this.userId) return;
    // return this.ajax._post(`/api/user/${this.userId}/update_locale`, {
    //   locale,
    // });
  }

  @action
  updateLocale(newLocale) {
    let oldLocale = this.locale;
    this.locale = newLocale;
    localStorage.setItem('locale', this.locale);
    this.patchLocale(this.locale);

    if (oldLocale && newLocale != oldLocale) {
      return this.updateLocaleInServer(this.locale);
    }
  }

  @action
  previousChosenLocale(newLocale) {
    if (this.locale && this.locale != newLocale) {
      return this.updateLocaleInServer(this.locale);
    }
  }

  getSystemLanguage(field) {
    return (
      this.locales.find(l => l.field === field) ??
      this.locales.find(l => l.field === field.split('-')[0])
    );
  }

  get selectedLocale() {
    return this.locales.find(l => l.field === this.locale);
  }

  getStoredLocale() {
    return localStorage.getItem('locale') || 'en';
  }

  getBrowserLocale() {
    return navigator.language || navigator.userLanguage;
  }

  /**
   * Provides a suggestion to the user to swap app language, to match their browser locale,
   * if we have a high level of translation coverage for it.
   *
   * Note: We have more languages in the sidebar to be selected, but most of them are in early stages of translation.
   */
  getMainLocaleSuggestion() {
    const userBrowserLocale = this.getBrowserLocale();
    const appLocale = this.getStoredLocale();
    const hideLocale = localStorage.getItem('hideLocaleUpdate') === 'true';

    if (!appLocale) return; // UC 1: First visit

    if (hideLocale) return; // UC 2: User have already rejected the suggestion

    if (appLocale === userBrowserLocale) return; // UC 3: Already matched

    if (this.isLocaleVariationSupported(userBrowserLocale)) {
      return userBrowserLocale;
    }

    if (this.isBaseLocaleSupported(userBrowserLocale)) {
      const baseLanguage = userBrowserLocale.split('-')[0]; //en-GB => en
      if (appLocale !== baseLanguage) return baseLanguage;
    }
  }
  /**
   * Updates locale without triggering a server update
   **/
  patchLocale(locale) {
    // Add primary locale to the list of locales
    const isSubLocale = locale.split('-').length > 1;
    const fallbackLocale = isSubLocale ? locale.split('-')[0] : 'en';
    // Add "en" as fallback locale to avoid missing translation errors
    this.intl.setLocale([locale, fallbackLocale, 'en']);
    this.setupMoment(locale);
    CurrencyUtil.setLocale(this.locale);
  }

  /**
   * Sets initial locale value and fallback for the App.
   *  No need of triggering server update
   */
  initializeAppLocale() {
    let lastAppLocale = this.getStoredLocale();
    if (!this.isValidLocale(lastAppLocale)) {
      console.info('Invalid locale - resetting to en', lastAppLocale);
      lastAppLocale = 'en';
    }
    this.patchLocale(lastAppLocale);
  }

  isValidLocale(locale) {
    return this.locales.map(l => l.field).includes(locale);
  }

  /** Locale without variations. Should be offered as a initial option */
  isBaseLocaleSupported(userLocale) {
    if (!userLocale) return false;
    // const supportedLanguages = ['en', 'ja', 'fr', 'ja', 'pt', 'de', 'es', 'it'];
    const localesWithHighTranslationRatio = ['en', 'fr', 'es', 'it'];
    const baseLanguage = userLocale.split('-')[0];

    return localesWithHighTranslationRatio.includes(baseLanguage);
  }

  /** en-AU, PT-bs... all follows same ISO convention */
  isLocaleVariation(userLocale) {
    if (!userLocale) return false;

    return userLocale.split('-').length === 2;
  }

  isLocaleVariationSupported(userLocale) {
    // const supportedVariations = ['en-AU', 'en-GB', 'en-CA'];
    const localeVariationsWithHighTranslationRatio = [];

    return localeVariationsWithHighTranslationRatio.includes(userLocale);
  }

  setupMoment(locale) {
    moment.locale(locale.toLocaleLowerCase());
    moment.updateLocale('es', {
      monthsShort: [
        'en.',
        'febr.',
        'mzo.',
        'abr.',
        'my.',
        'jun.',
        'jul.',
        'agt.',
        'sept.',
        'oct.',
        'nov.',
        'dic.',
      ],
    });
    this.moment.setLocale(locale.toLocaleLowerCase());
  }
}
