import Transform from '@ember-data/serializer/transform';

// Serialized format: "categoryName1__accountId1,categoryName2__accountId2"
export default class FilterCategoriesTransform extends Transform {
  deserialize(serialized) {
    if (!serialized) {
      return [];
    }

    return serialized.split(/(?<=__\d+)[-,]/);
  }

  serialize(deserialized = []) {
    return deserialized.join(',');
  }
}
