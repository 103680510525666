import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';

export default class InsightsRoute extends AuthRoute {
  @service('global-nav') globalNav;

  title(tokens) {
    let title = null;

    if (!tokens || !tokens.length) {
      title = 'Portfolio Insights';
    } else {
      title = `Portfolio Insights / ${tokens}`;
    }

    this.globalNav.setTitle(title);
    return title;
  }
}
