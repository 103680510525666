import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class ClusterPricesRoute extends AuthRoute {
  templateName = 'data.highcharts-stock';
  controllerName = 'data.cluster-prices';

  setupController(controller, model) {
    controller.set('model', model);
    let market = this.modelFor('data.market');
    controller.set('pageTitle', `Cluster Pricing -- ${market.get('name')}`);

    this.controllerFor('data').set('comparable', true);
  }

  deactivate() {
    this.controllerFor('data.market').set('csvUrl', false);
    this.controllerFor('data').set('comparable', false);
  }

  model() {
    let market = this.modelFor('data.market');
    let url = `/admin/api/markets/${market.get('id')}/cluster_prices`;

    let filterCols = (arr, col) =>
      arr.filter(row => row[col]).map(row => [row[0], row[col]]);

    return this.ajax._get(url).then(data => {
      let cluster_list = data.clusterList;
      let prices = {};
      cluster_list.forEach(function (e, i) {
        prices[e[0]] = filterCols(data.pricing, i + 1);
      });
      return EmberObject.create({
        clusterList: cluster_list,
        prices: prices,
      });
    });
  }
}
