import JSONAPIAdapter from '@ember-data/adapter/json-api';
export default class SearchHistoryByAmenityAdapter extends JSONAPIAdapter {
  namespace = 'api';

  get headers() {
    return {
      token: localStorage.getItem('token'),
    };
  }
}
