import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { get } from '@ember/object';
import { CHANNEL_CONFIG } from 'appkit/lib/channel_configuration';

export default class AccountModel extends Model {
  @belongsTo('user', { async: false }) user;
  @hasMany('channel-listing', { async: false }) channelListings;

  @attr('boolean', { defaultValue: false }) isDeleting;
  @attr('string') channelDisplayId;
  @attr('string') channelConnectId;
  @attr('string') channelConnectIdSecondary;
  @attr('string') email;
  @attr('string') channel;
  @attr('boolean', { defaultValue: false }) valid;
  @attr('boolean', { defaultValue: false }) isMinStayEnabled;
  @attr('array', { defaultValue: () => [] }) listings;
  @attr('string') markup;

  get canEditMarkup() {
    const canEditMarkupAccountIds = this.user.canEditMarkupAccountIds;
    const accountId = this.id;
    let isRelayGrandfathered = get(this, 'user.isGrandfatheredRelayUser');
    return canEditMarkupAccountIds.includes(accountId) && isRelayGrandfathered;
  }

  get channelConfig() {
    return CHANNEL_CONFIG[this.channel];
  }
}
