import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { displayErrors } from 'appkit/lib/display_errors';

export default class ToggleComponent extends Component {
  @service ajax;
  @service intl;
  @service alert;

  @action
  async toggleMonthlyEnabled(monthlyEnabled) {
    const { listingId, monthyPricingChanged } = this.args;

    monthyPricingChanged(monthlyEnabled);

    let url = `/v2/listings/${listingId}/customize`;
    let data = { monthlyEnabled };

    try {
      await this.ajax._post(url, data);
    } catch (errors) {
      displayErrors({ errors: errors, modelOrKeywordThis: this, alert: alert });
      monthyPricingChanged(!monthlyEnabled);
      return;
    }

    const msg = monthlyEnabled
      ? this.intl.t('pricing.monthlyPricingEnabled')
      : this.intl.t('pricing.monthlyPricingDisabled');

    this.alert.success(msg);
  }
}
