import { airbnbErrorAdapterv0 } from 'appkit/lib/relay/adapters/reservations/dc/rel-airbnb-error-adapter';

function defaultErrorAdapter(reservationPushOutcome) {
  return {
    outcomeId: reservationPushOutcome.id,
    ...reservationPushOutcome.attributes,
  };
}

/** Maps a reservation error into a user-friendly message */
export function errorAdapter(channel, reservationPushOutcome) {
  try {
    const _channel = channel.toLowerCase().trim();
    switch (_channel) {
      case 'airbnb':
        return airbnbErrorAdapterv0(reservationPushOutcome);
      default:
        return defaultErrorAdapter(reservationPushOutcome);
    }
  } catch (e) {
    console.error(e);
    return defaultErrorAdapter(reservationPushOutcome);
  }
}
