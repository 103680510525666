import { ChannelUtil } from 'appkit/lib/channel';

export const SETTINGS = {
  sync: {
    label: 'relay.settings.syncLabel',
    settings: [
      {
        supportedBy: [ChannelUtil.getChannelLabel('escapia'), 'fake_pms'],
        id: 'syncAll',
        key: 'syncAll',
        description: 'relay.syncall',
        isActive: null,
      },
      {
        supportedBy: [ChannelUtil.getChannelLabel('escapia'), 'fake_pms'],
        id: 'syncPricing',
        key: 'shouldAutoEnablePricing',
        description: 'relay.syncPricing',
        isActive: null,
      },
      {
        supportedBy: [ChannelUtil.getChannelLabel('escapia'), 'fake_pms'],
        id: 'syncAv',
        key: 'shouldAutoEnableAvailability',
        description: 'relay.syncAv',
        isActive: null,
      },
      {
        supportedBy: [ChannelUtil.getChannelLabel('escapia'), 'fake_pms'],
        id: 'syncRes',
        key: 'shouldAutoEnableReservations',
        description: 'relay.syncRes',
        isActive: null,
      },
      {
        supportedBy: [ChannelUtil.getChannelLabel('escapia'), 'fake_pms'],
        id: 'syncContent',
        key: 'shouldAutoEnableContentSyncing',
        description: 'relay.syncContent',
        isActive: null,
      },
    ],
  },
  new_reservations: {
    label: 'relay.settings.shouldCreateReservationsWithGuestEmail.label',
    settings: [
      {
        supportedBy: [ChannelUtil.getChannelLabel('escapia'), 'fake_pms'],
        id: 'escapia.reservation.syncEmail',
        key: 'shouldCreateReservationsWithGuestEmail',
        description:
          'relay.settings.shouldCreateReservationsWithGuestEmail.description',
        isActive: null,
      },
    ],
  },
  updated_reservations: {
    label: 'relay.settings.shouldSkipAllReservationsUpdates.label',
    settings: [
      {
        id: 'reservation.details',
        description: 'relay.settings.shouldSkipAllReservationsUpdates.description',
        key: 'shouldSkipAllReservationsUpdates', //changed message to align with API negative (skip)
        supportedBy: [ChannelUtil.getChannelLabel('escapia'), 'fake_pms'],
        isActive: null,
      },
      {
        id: 'escapia.reservation.syncOnlyStatus',
        key: 'shouldOnlyUpdateReservationsStatus',
        supportedBy: [ChannelUtil.getChannelLabel('escapia'), 'fake_pms'],
        description: 'relay.settings.shouldOnlyUpdateReservationsStatus.description',
        isActive: null,
      },
    ],
  },
};

// In the OKR, but still to be developed / tested
export const RELAY_V2_SETTINGS = [
  {
    id: 'escapia.reservation.cancelations',
    label: 'Cancelations',
    description: 'Remove canceled reservations from my PMS',
    isActive: null,
  },
  {
    id: 'user.notifications',
    label: 'Email notification settings',
    labelDetail: 'Email me when...',
    description: 'Relay creates a block for a reservation request on Airbnb',
    isActive: null,
    children: [
      {
        id: 'user.notifications.block.airbnb',
        description: 'Relay creates a block for a reservation request on Airbnb',
        isActive: null,
      },
      {
        id: 'user.notifications.reservation.creation.',
        description: 'Relay creates a reservation',
        isActive: null,
      },
      {
        id: 'user.notifications.reservation.update.',
        description: 'Relay updates a reservation',
        isActive: null,
      },
      {
        id: 'user.notifications.reservation.error.',
        description: 'Relay cannot create a reservation',
        isActive: null,
      },
      {
        id: 'user.notifications.reservation.removal.',
        description: 'Relay removes a canceled reservation',
        isActive: null,
      },
    ],
  },
];
