import USER_STATUS from '../models/user-status';
import PAYMENT_METHOD from './payment-method';

export class UserUtil {
  static isUserBlocked(status, isBlocked, defaultPaymentMethod) {
    return (
      (status == USER_STATUS.DELINQUENT ||
        (isBlocked && status == USER_STATUS.EXPIRED)) &&
      [
        PAYMENT_METHOD.CREDIT_CARD,
        PAYMENT_METHOD.PAYPAL,
        PAYMENT_METHOD.ACH,
        PAYMENT_METHOD.BEYOND_CREDIT_CARD,
      ].includes(defaultPaymentMethod)
    );
  }

  static blockedUserMessage(isBlocked, status) {
    return !isBlocked
      ? ['admin.oopsMissedPayment1', 'admin.oopsMissedPayment2']
      : status == USER_STATUS.EXPIRED
      ? ['admin.oopsTrialExpired1', 'admin.oopsTrialExpired2']
      : ['admin.oopsLockedAccount1', 'admin.oopsLockedAccount2'];
  }
}
