import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ENV from 'appkit/config/environment';

const fetchBPGDetails = async (ajax, type) => {
  return await ajax._post('/api/cards/session/bpg', { type: type });
};

const showErrorAlert = alertService => {
  const message = 'An error occurred. Please contact support@beyondpricing.com.';
  alertService.error(message, {
    timeout: 6000,
  });
};

export default class BPGService extends Service {
  @service ajax;
  @service alert;

  @action
  async openCheckout(type) {
    try {
      const { cardRequestToken } = await fetchBPGDetails(this.ajax, type);
      top.window.location.href = `${ENV.APP.BPG_CHECKOUT_URL}/load-unified?card_request_token=${cardRequestToken}`;
    } catch (e) {
      showErrorAlert(this.alert);
    }
  }
}
