import classic from 'ember-classic-decorator';
// TODO: this doesn't work...
import $ from 'jquery';

import Component from '@ember/component';
@classic
export default class AppPlotly extends Component {
  domain = 'beyondpricing';
  title = '';
  id = null;

  // _renderPlotly: Ember.on('didInsertElement', function() {
  _renderPlotly() {
    // Ember won't allow us to use a script tag in a template
    let data = this.domain + ':' + this.id;
    let script = $(
      `<script data-plotly="${data}" src="https://plot.ly/embed.js" async></script>`
    );
    this.element.appendChild(script);
  }
}
