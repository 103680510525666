define("appkit/components/power-calendar/days", ["exports", "ember-power-calendar/components/power-calendar/days"], function (_exports, _days) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _days.default;
    }
  });
});
