import classic from 'ember-classic-decorator';
import { on } from '@ember-decorators/object';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { oneWay } from '@ember/object/computed';
import Controller from '@ember/controller';
import $ from 'jquery';
import { run } from '@ember/runloop';
import { getOwner } from '@ember/application';

@classic
export default class ApplicationController extends Controller {
  @service
  intl;

  @service
  moment;

  @service
  alert;

  @service
  beyondI18n;

  @service
  embed;

  @service
  featureFlag;

  navClass = ''; // For setting the mobiles nav display when clicking the hamburger

  @on('init')
  _init() {
    this.beyondI18n.initLocale();
  }

  get locales() {
    return this.beyondI18n.locales;
  }

  get selectedLocale() {
    return this.beyondI18n.selectedLocale;
  }

  @action
  changeLocale(option) {
    this.beyondI18n.updateLocale(option.field);
    getOwner(this).lookup('route:application').refresh();
  }

  @oneWay('currentUser.isStaff')
  isStaff;

  @action
  dismissAlert(model) {
    this.alert.dismiss(model);
    return false;
  }

  @action
  toggleNav() {
    let visible = !!this.navClass;

    if (visible) {
      $('body').off('click.mobilenav');
      this.set('navClass', '');
    } else {
      this.set('navClass', 'show-mobile');
      // TODO: maybe use an overlay div instead?
      $('body').on('click.mobilenav', () => {
        run(() => {
          $('body').off('click.mobilenav');
          this.set('navClass', '');
        });
      });
    }
  }
}
