import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { reject } from 'rsvp';
import { run } from '@ember/runloop';
import Route from '@ember/routing/route';
import logger from 'appkit/lib/logger';

@classic
export default class AuthRoute extends Route {
  beforeModel() {
    let token = this.currentUser.get('token');
    if (!token) {
      return run(this, reject, 'user');
    }

    return super.beforeModel(arguments);
  }

  @action
  error(reason, transition) {
    // Assume we only have one error when dealing with server errors on
    // navigation.
    if (Array.isArray(reason)) {
      reason = reason[0];
    }

    if (reason === 'user') {
      // No token - happens when the user hits an authed url manually without
      // being logged in
    } else if (typeof reason === 'object' && reason.status === 503) {
      window.location.href = '/maintenance';
      return false;
    } else if (typeof reason === 'object' && reason.status === 500) {
      // Most 500s have no data. Show a generic server error.
      delete reason.status;
      return true;
    } else if (typeof reason === 'object' && reason.status === '401') {
      // Model promise rejects with 401 - unauthorized. Happens when we
      // invalidate tokens / delete users / user messes with localStorage
    } else {
      // Any other kind of model error - let it bubble to the route.
      return true;
    }

    logger.error('No user. Redirecting.');
    this.currentUser.clear();

    let loginController = this.controllerFor('login');
    loginController.set('attemptedTransition', transition);
    this.transitionTo('login');

    return false;
  }
}
