/* 
 The search map is a Js Map that stores the function name as a key,
 and the search value as the value. 
 This way, an operation is secured to be unique when the user changes options.

 When the function is called, a progressive filter is applied;

 Example:
  1. searchMap.set('filterByAvailability', 'blocked')
  2. runSearch()
  3. query = operations['filterByAvailability'](blocked)
 */

export class RelaySearcher {
  filtersQueryList = new Map();
  sortFnName = null;
  sortAscending = true;
  searchStory = [];

  setConfig({ filters, sorts }) {
    this.filters = filters;
    this.sorts = sorts;
    return this;
  }

  updateFiltersQueryList(filterFnName, searchValue) {
    //Add error UC here (no filters defined, or typo in filterName)
    searchValue
      ? this.filtersQueryList.set(filterFnName, searchValue)
      : this.filtersQueryList.delete(filterFnName);

    return this;
  }

  clearFiltersQueryList() {
    this.filtersQueryList = new Map();
    return this;
  }

  setSortAscending(sortAscending) {
    this.sortAscending = sortAscending;
    return this;
  }

  setActiveSort(sortFnName) {
    //Add error UC here (no sorters defined, or typo in sorterFnName)
    this.sortFnName = sortFnName;
    return this;
  }

  filter(data) {
    if (!this.filtersQueryList.size) {
      return data;
    }
    // For each item check every filter criteria. Stop if one fails.
    // Once filter loop ends, if a item is valid add it to results
    let results = [];

    for (const item of data) {
      let isValidItem = false;

      // console.log('Filter (options)', this.filters, { data });
      for (const [filterName, searchValue] of this.filtersQueryList) {
        // console.log('running query fragment', { filterName, searchValue });
        const query = this.filters[filterName](searchValue);
        isValidItem = query(item);
        if (!isValidItem) break;
      }

      if (isValidItem) {
        results.push(item);
      }
    }

    this.searchStory.push(new Map(this.activeFilters));

    return this.sort(results);
  }

  sort(data) {
    if (!this.sortFnName) return data;

    const sorter = this.sorts[this.sortFnName];
    const sortedData = [...data.sort(sorter)];

    return this.sortAscending ? sortedData.reverse() : sortedData;
  }
}
