import classic from 'ember-classic-decorator';
import Service, { inject as service } from '@ember/service';
import * as LDClient from 'launchdarkly-js-client-sdk';
import ENV from 'appkit/config/environment';
import { sha512 } from 'js-sha512';

@classic
export default class FeatureFlagService extends Service {
  @service ajax;

  client = null;
  ldKey = null;
  ldSecureHash = null;
  email = null;
  initialized = false;

  // Experiments
  experimentCache = {};
  experimentQueue = [];
  experimentDebouncer = null;

  init() {
    super.init(...arguments);

    // By default we are building the client using localStorage variables.
    // When these are not available the developer should use the setters below first and update the client.
    this.ldKey = localStorage.getItem('ldKey');
    this.ldSecureHash = localStorage.getItem('ldSecureHash');
    this.email = localStorage.getItem('ldEmail')?.toLowerCase();
    this.userId = localStorage.getItem('userId');
    this.initClient();
  }

  flushExperimentQueueOnUnload() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState == 'hidden') {
        if (this.experimentDebouncer) {
          clearTimeout(this.experimentDebouncer);

          const token = localStorage.getItem('token');
          if (!token) return;

          const payload = new Blob([JSON.stringify(this.getExperimentsPayload())], {
            type: 'application/json; charset=UTF-8',
          });
          navigator.sendBeacon(`api/experiments?token=${token}`, payload);
        }
      }
    });
  }

  setLdKey(value) {
    this.ldKey = value;
  }

  setLdSecureHash(value) {
    this.ldSecureHash = value;
  }

  setEmail(value) {
    this.email = value;
  }

  createContext() {
    let ldHash = {
      kind: 'user',
      key: this.ldKey,
      email: this.email,
      ...(this.userId && { userId: parseInt(this.userId, 10) }),
    };

    return ldHash;
  }

  initClient(opts) {
    if (opts) {
      this.email = opts.email;
      this.ldSecureHash = opts.ldSecureHash;
      this.userId = opts.userId;
    }

    if (this.email) {
      this.ldKey = sha512(this.email.toLowerCase());
    }

    // Only initialize the client if all the required information is available
    if (!(this.email && this.ldKey && this.ldSecureHash)) {
      return;
    }

    this.initialized = true;
    const context = this.createContext();
    const options = { hash: this.ldSecureHash };

    const client = LDClient.initialize(
      ENV.APP.LAUNCHDARKLY_CLIENT_SIDE_ID,
      context,
      options
    );

    this.client = new Promise((resolve, reject) => {
      client.on('ready', () => {
        this.flushExperimentQueueOnUnload();
        resolve(client);
      });

      client.on('failed', err => {
        reject(err);
      });
    });

    return this.client;
  }

  async evaluate(featureFlag, defaultValue = false) {
    if (!this.initialized) return defaultValue;

    try {
      const client = await this.client;
      const variant = client.variation(featureFlag, defaultValue);

      this.queueExperiment(featureFlag, variant);
      return client.variation(featureFlag, defaultValue);
    } catch (e) {
      console.error('LaunchDarkly:', e?.message);
      return defaultValue;
    }
  }

  async queueExperiment(featureFlag, variant) {
    // Avoid sending experiments if the user id is not set
    // This happens when impersonating a user
    if (!this.userId) return;

    if (featureFlag in this.experimentCache) return;

    this.experimentCache[featureFlag] = true;
    this.experimentQueue.push({
      userId: this.userId,
      featureFlag,
      variant,
    });

    if (this.experimentDebouncer) {
      clearTimeout(this.experimentDebouncer);
    }

    this.experimentDebouncer = setTimeout(this.sendExperiments.bind(this), 10000);
  }

  getExperimentsPayload() {
    return this.experimentQueue.map(({ userId, featureFlag, variant }) => ({
      user_id: userId,
      variant_name: variant,
      experiment_name: featureFlag,
    }));
  }

  async sendExperiments() {
    // Avoid sending experiments if the user id is not set
    // This happens when impersonating a user
    if (!this.userId) return;
    const payload = this.getExperimentsPayload();
    this.experimentDebouncer = null;
    this.experimentQueue = [];

    try {
      await this.ajax._post('/api/experiments', payload);
    } catch (errors) {
      // Clear feature flag from the sent experiments cache since request failed on the server
      payload.forEach(({ experiment_name }) => {
        delete this.experimentCache[experiment_name];
      });
      console.error('Failed to send experiment data:', errors);
    }
  }
}
