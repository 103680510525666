import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='ml-1 flex flex-col' ...attributes>\n  <AppSvg\n    @src='arrow-up'\n    @class='fill-current h-2 w-2 bp-arrow-up\n      {{\n      if (and this.isSorting (eq @sortOrder 'asc')) 'text-brand-400' 'text-gray-400'\n    }}'\n  />\n  <AppSvg\n    @src='arrow-down'\n    @class='fill-current h-2 w-2 bp-arrow-down\n      {{\n      if (and this.isSorting (eq @sortOrder 'desc')) 'text-brand-400' 'text-gray-400'\n    }}'\n  />\n</div>", {"contents":"<div class='ml-1 flex flex-col' ...attributes>\n  <AppSvg\n    @src='arrow-up'\n    @class='fill-current h-2 w-2 bp-arrow-up\n      {{\n      if (and this.isSorting (eq @sortOrder 'asc')) 'text-brand-400' 'text-gray-400'\n    }}'\n  />\n  <AppSvg\n    @src='arrow-down'\n    @class='fill-current h-2 w-2 bp-arrow-down\n      {{\n      if (and this.isSorting (eq @sortOrder 'desc')) 'text-brand-400' 'text-gray-400'\n    }}'\n  />\n</div>","moduleName":"appkit/components/user-credentials/list/sort-arrows.hbs","parseOptions":{"srcName":"appkit/components/user-credentials/list/sort-arrows.hbs"}});
import Component from '@glimmer/component';

export default class SortArrowsComponent extends Component {
  get isSorting() {
    const { sortedBy, sortField } = this.args;

    return sortedBy === sortField;
  }
}
