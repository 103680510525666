import Service from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { displayErrors } from 'appkit/lib/display_errors';
import Customize from 'appkit/bp-models/customize';
import { ValueRange } from 'appkit/lib/value_range';

export default class ListingSetupService extends Service {
  @service ajax;
  @service alert;
  @service intl;
  @tracked importedListing;
  @tracked displayModal;

  onSaveCallback = null;

  @action
  reset() {
    this.importedListing = null;
  }

  @action
  async onImportListing(listing) {
    const url = `/api/listings/${listing.id}/customize`;
    const data = await this.ajax._get(url);
    // This is very hacky... hopefully we can solve this with Ember Data
    for (const field of Object.keys(data)) {
      if (!field.endsWith('Ranges')) {
        continue;
      }

      data[field] = data[field].map(row => ValueRange.create(row));
    }

    let intl = this.intl;

    const customize = Customize.create({ ...data, intl });

    this.importedListing = {
      listing: listing,
      settings: customize.serializeData(),
    };
  }

  @action
  async onSave(data) {
    const url = `/api/listings/${data.id}/customize`;

    try {
      let importCalendarUrls;
      if (data.importCalendarUrls) {
        importCalendarUrls = data.importCalendarUrls.filter(c => c);
      }
      const result = await this.ajax._post(url, {
        ...data.settings,
        minStay: parseInt(data.minStay),
        basePrice: parseInt(data.basePrice),
        minPrice: parseInt(data.minPrice),
        enabled: data.syncEnabled,
        pmNotes: data.pmNotes,
        importCalendarUrls: importCalendarUrls,
        ...(data.syncEnabled && { onProgram: data.syncEnabled }),
      });

      this.onSaveCallback?.(null, result);
      return result;
    } catch (err) {
      const errors = Array.isArray(err)
        ? err
        : [{ message: this.intl.t('glossary.failedToSaveSetup') }];
      displayErrors({ errors, modelOrKeywordThis: this, alert });
      this.onSaveCallback?.(err, null);
      throw err;
    }
  }

  @action
  setBaseListing(listing) {
    this.importedListing = { listing };
  }

  @action
  setOnSaveCallback(cb) {
    this.onSaveCallback = cb;
  }

  @action
  onOpenModal() {
    this.displayModal = true;
  }

  @action
  onCloseModal() {
    this.displayModal = false;
    this.reset();
  }
}
