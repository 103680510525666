import classic from 'ember-classic-decorator';
import $ from 'jquery';
import EmberObject, { action } from '@ember/object';
import Route from '@ember/routing/route';
import logger from 'appkit/lib/logger';
import { inject as service } from '@ember/service';
import ENV from 'appkit/config/environment';
import moment from 'moment';

function convertQuotesToObject(data) {
  return data.reduce((acc, obj) => {
    acc[obj.date] = Number(obj.price);
    return acc;
  }, {});
}

@classic
export default class PreviewRoute extends Route {
  @service featureFlag;
  @service listingActions;

  async afterModel() {
    this.templateName = 'dashboard.pricing.listing';
    this.controllerName = 'listing';
  }

  activate() {
    if (this.templateName === 'dashboard.pricing.listing') {
      document.body.classList.add('v2');
    }
  }

  deactivate() {
    if (this.templateName === 'dashboard.pricing.listing') {
      document.body.classList.remove('v2');
    }
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set(
      'links',
      EmberObject.create({
        calendar: 'preview.calendar',
        chart: 'preview.chart',
        marketData: 'preview.market-data',
        nearby: 'preview.nearby',
      })
    );

    this.listingActions.setup({
      listing: model.listing,
      calendar: model.calendar,
    });

    return controller;
  }

  async model(params) {
    // Want to keep existing behavior. Basic ID is just airbnb. Separating with
    // a hyphen saves me having to mess with routes.
    let parts = params.id.split('-');
    let id = parts.pop();
    let channel = parts.pop() || 'airbnb';
    let result = EmberObject.create({
      listing: {},
      calendar: [],
      lastUpdatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
    });

    let url = `/api/channellistings/preview/${channel}/${id}`;
    let data = await this.ajax._get(url);

    if (channel === 'airbnb') {
      await new Promise((resolve, reject) => {
        let quotesNotifierUrl = `/api/airbnb/quotes/${id}/notifier`;
        let quotesCheckerUrl = `/api/airbnb/quotes/${id}/started`;
        let defaultQuoteStatus = `Searching quotes for listing ${id}`;

        if (ENV.environment === 'test') {
          resolve();
        }

        this.ajax
          .stream(quotesNotifierUrl, {}, async data => {
            if (data.message) {
              if (data.message == 'starting') {
                await this.ajax._get(quotesCheckerUrl).then(started => {
                  if (started) {
                    result.set('quoteStatus', defaultQuoteStatus);
                  }
                });
                resolve();
              } else if (data.message == 'done') {
                result.set('quoteStatus', null);
              } else {
                result.set('quoteStatus', data.message);
              }
            }
          })
          .then(async () => {
            this.refreshCurrentPrice(id);
          })
          .catch(reject);
      });
    }

    let listing = this.bpStore.createRecord('listing', data);

    let quotes = [];
    let quotesPerDate = {};

    if (channel === 'airbnb') {
      quotes = await this.getQuotes(id);
      quotesPerDate = convertQuotesToObject(quotes);
    }

    let calendar = listing.set(
      'calendar',
      data.calendar.map(day => {
        if (channel === 'airbnb') {
          if (quotesPerDate[day.date]) {
            day.priceScraped = quotesPerDate[day.date];
          } else {
            day.priceScraped = 0;
          }
        }

        let ld = this.bpStore.createRecord('listingDate', day);
        ld.set('listing', listing);
        return ld;
      })
    );
    result.set('calendar', calendar);

    listing.set('permissions', 'edit');

    let windowParams = window.hashParams || {};
    if (windowParams.basePrice) {
      listing.set('basePrice', Number(windowParams.basePrice));
      delete windowParams.basePrice;
    }
    if (windowParams.minPrice) {
      listing.set('minPrice', Number(windowParams.minPrice));
      delete windowParams.minPrice;
    }

    result.set('listing', listing);
    result.set('lastUpdatedAt', moment().format('YYYY-MM-DD HH:mm:ss'));

    return result;
  }

  async getQuotes(id) {
    let quotesUrl = `/api/airbnb/quotes/${id}/preview`;
    let quotesData = await this.ajax._get(quotesUrl);

    return quotesData;
  }

  @action
  toggleForm() {
    $('.demo-form-wrap').slideToggle();
    $('.demo-form-wrap input').focus();
    return false;
  }

  @action
  error(reason) {
    logger.error('Calendar actions error', reason, arguments);
    if (Array.isArray(reason)) {
      reason = reason[0];
    }

    if (!reason) {
      return true;
    }

    if (reason.status === '404') {
      // This isn't a real ember API - it's just me passing a model with a
      // template field. Seems a little cleaner than custom error objects.
      this.intermediateTransitionTo('error', {
        template: 'error/listing-not-found',
      });
      return false;
    }

    if (reason.status === '422') {
      let model = reason.meta;
      model.template = 'error/unavailable';
      this.intermediateTransitionTo('error.unavailable', model);
      return false;
    }

    return true;
  }

  async refreshCurrentPrice(id) {
    let quotes = await this.getQuotes(id);
    let quotesPerDate = convertQuotesToObject(quotes);

    this.currentModel.get('calendar').forEach(day => {
      let date = day.date.format('YYYY-MM-DD');

      if (quotesPerDate[date]) {
        day.set('priceScraped', quotesPerDate[date]);
      }
    });

    this.currentModel.set('lastUpdatedAt', moment().format('YYYY-MM-DD HH:mm:ss'));
  }
}
