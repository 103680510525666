import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { action, computed } from '@ember/object';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import moment from 'moment';
import { htmlSafe } from '@ember/template';

const BULK_UPDATE_CONFIGURATION = {
  from_existing: {
    helpText: htmlSafe(
      'Create fake reservations from existing reservations on Channel Listings<br>' +
        'To be used when the customer already has reservations created on our system, but on a differ Channel Listing (source_channel_listing_id)<br>' +
        'Format of the CSV:' +
        '<pre>source_channel_listing_id, destination_channel_listing_id</pre>'
    ),
  },
  from_csv: {
    helpText: htmlSafe(
      'Create fake reservations from existing reservations on Channel Listings<br>' +
        'To be used when the customer wants to import reservations from other systems into his Channel Listings (channel_listing_id)<br>' +
        'Format of the CSV:' +
        '<pre>channel_listing_id,channel,channel_id,reference,booked_at,checkin_date,checkout_date,nb_guests,currency,rental_amount,rental_amount_all_fees_excl_taxes,rental_amount_all_fees_incl_taxes,total_host_payout,total_guest_amount,is_owner,status</pre>'
    ),
  },
};

@classic
export default class ImportReservationsController extends Controller {
  @tracked asyncResponseData = A([]);
  @tracked progressData = '';
  @tracked importedData = '';
  @tracked ignoredData = '';
  @tracked jobId = '';
  updateType = 'none';
  userId = null;
  userDetails = 'No User Checked';
  uploadResponse = null;
  cutOffDate = moment();

  // Action when the update type changes
  // eslint-disable-next-line ember/no-observers
  @observes('updateType')
  watchUpdateType() {
    // Reset the results to avoid ambiguity
    this.set('uploadResponse', null);
  }

  @computed('updateType')
  get helpText() {
    let updateType = this.updateType;

    if (updateType === 'none') {
      return 'None';
    }

    if (updateType in BULK_UPDATE_CONFIGURATION) {
      return BULK_UPDATE_CONFIGURATION[updateType]['helpText'];
    }

    return 'Error';
  }

  @action
  checkUser() {
    let userId = this.userId;
    let url = `/api/bulk_updates/check_user/${userId}`;
    this.ajax._get(url).then(
      data => {
        let text = `ID: ${data.id} Email: ${data.email}`;
        this.set('userDetails', text);
      },
      () => {
        alert('User lookup failed.');
        this.set('userDetails', 'User lookup failed.');
      }
    );
  }

  @action
  async submitAsyncForm() {
    const url = '/api/import_reservations/async';
    this.asyncResponseData = A([]);
    this.progressData = '';
    this.ignoredData = '';
    this.importedData = '';
    this.jobId = '';
    let formData = new FormData();
    let file = document.getElementsByName('fileUpload')[0].files[0];
    let userId = this.userId;
    let cutOffDate = this.cutOffDate;
    let result;
    let updateType = this.updateType;
    formData.append('file', file);
    formData.append('user_id', userId);
    formData.append('cutoff_date', moment(cutOffDate).format('YYYY-MM-DD'));
    formData.append('update_type', updateType);

    function getMessage(message) {
      return message.substring(message.indexOf(':') + 1);
    }

    if (!userId) {
      alert('User ID is required. Aborting.');
      return;
    }
    if (updateType === 'none') {
      alert('No update selected. Aborting.');
      return;
    }
    if (!file) {
      alert('No file. Aborting.');
      return;
    }
    if (!cutOffDate) {
      alert('No cutoff-date. Aborting.');
      return;
    }

    try {
      result = await this.ajax._postWithFiles(url, formData);
      this.jobId = result.jobId;
      let jobUrl = `/v2/job/${result.jobId}/status`;
      try {
        await this.ajax.stream(jobUrl, {}, responseData => {
          if (responseData.message.startsWith('progress:')) {
            this.progressData = getMessage(responseData.message);
          } else if (responseData.message.startsWith('imported:')) {
            this.importedData = getMessage(responseData.message);
          } else if (responseData.message.startsWith('ignored:')) {
            this.ignoredData = getMessage(responseData.message);
          } else if (responseData.message.startsWith('errors:')) {
            for (const message of responseData.message
              .substring(responseData.message.indexOf(':') + 1)
              .split('~')) {
              this.asyncResponseData.pushObject(message);
            }
          }
        });
      } catch (errors) {
        if (Array.isArray(errors)) {
          errors.forEach(e => {
            this.asyncResponseData.pushObject(e.message);
          });
        } else {
          this.asyncResponseData.pushObject(`Something went wrong: ${errors}`);
        }
      }
    } catch (errors) {
      if (Array.isArray(errors)) {
        errors.forEach(e => {
          this.asyncResponseData.pushObject(e.message);
        });
      } else {
        this.asyncResponseData.pushObject(`Something went wrong: ${errors}`);
      }
    }
  }
}
