import { fontSizeClasses } from '../trigger/index.theme';
import { VARIANTS, COLORS, baseTheme } from '../../../theme';
const { spacingClasses, iconSizeClasses } = baseTheme;

const defaultClasses = ({ isSelected }) => [
  'flex',
  'items-center',
  'justify-between',
  'cursor-pointer',
  isSelected && 'pr-12',
];

export const variantClasses = ({ isSelected, isHighlighted, highlightOnHover }) => ({
  [VARIANTS.ALL]: {
    [COLORS.BRAND]: [
      isSelected ? ['text-brand-500'] : ['bg-white', 'text-gray-800'],
      isSelected && !isHighlighted && ['bg-brand-200'],
      isHighlighted && ['bg-gray-100'],
      highlightOnHover && ['hover:bg-brand-200'],
    ],
    [COLORS.SUCCESS]: [
      isSelected ? ['text-success-500'] : ['bg-white', 'text-gray-800'],
      isSelected && !isHighlighted && ['bg-success-100'],
      isHighlighted && ['bg-gray-100'],
      highlightOnHover && ['hover:bg-success-100'],
    ],
  },
});

const itemDefaultClasses = () => ['flex-1', 'overflow-hidden'];

const deleteIconDefaultClasses = () => [
  'mr-2',
  'fill-current',
  'text-gray-600',
  'hover:text-error-400',
];

const checkedIconDefaultClasses = () => ['fill-current', 'absolute', 'right-0', 'mr-4'];

const borders = [];

export default {
  root: {
    defaultClasses,
    fontSizeClasses,
    spacingClasses,
    variantClasses,
    borders,
  },
  item: {
    defaultClasses: itemDefaultClasses,
  },
  deleteIcon: {
    defaultClasses: deleteIconDefaultClasses,
    iconSizeClasses,
  },
  checkedIcon: {
    defaultClasses: checkedIconDefaultClasses,
    iconSizeClasses,
  },
};
