import classic from 'ember-classic-decorator';
import { on } from '@ember-decorators/object';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';

// Better to do this with query params??
// http://jsbin.com/ucanam/6100/edit
@classic
export default class DataRoute extends AuthRoute {
  @service
  supportVisible;

  @on('init')
  _hideSupport() {
    this.set('supportVisible.value', false);
  }

  @on('deactivate')
  _resetSupport() {
    this.set('supportVisible.value', this.get('supportVisible.default'));
  }

  model() {
    return this.ajax._get('/admin/api/markets_and_clusters').then(data => {
      let markets = data.markets.map(marketData => {
        let market = this.bpStore.createRecord('market', marketData);
        let clusters = market
          .get('clusters')
          .map(clusterData => this.bpStore.createRecord('cluster', clusterData));

        // Alphabetical sort of clusters in sidebar
        market.set(
          'clusters',
          clusters.sort((a, b) => a.get('name').localeCompare(b.get('name')))
        );

        // Reverse relation
        for (let c of market.get('clusters')) {
          c.set('market', market);
        }

        return market;
      });

      // Alphabetical sort of markets in sidebar
      markets.sort((a, b) => a.get('name').localeCompare(b.get('name')));
      return markets;
    });
  }
}
