import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n  {{yield\n    (hash\n      builtInOptions=@builtInOptions\n      userGeneratedOptions=@userGeneratedOptions\n      userGeneratedLabel=@userGeneratedLabel\n      selectedOption=@selectedOption\n      actions=(hash onChange=this.onChange deleteOption=this.deleteOption)\n    )\n  }}\n</div>", {"contents":"<div ...attributes>\n  {{yield\n    (hash\n      builtInOptions=@builtInOptions\n      userGeneratedOptions=@userGeneratedOptions\n      userGeneratedLabel=@userGeneratedLabel\n      selectedOption=@selectedOption\n      actions=(hash onChange=this.onChange deleteOption=this.deleteOption)\n    )\n  }}\n</div>","moduleName":"appkit/components/bp/dropdown/container.hbs","parseOptions":{"srcName":"appkit/components/bp/dropdown/container.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class BpDropdownBuiltInContainerComponent extends Component {
  @action
  onChange(option, dropdown) {
    dropdown.actions.close();
    this.args.onChange?.(option);
  }

  @action
  deleteOption(option, dropdown, e) {
    e.preventDefault();
    dropdown.actions.close();
    this.args.deleteOption?.(option);
  }
}
