import flatten from 'lodash.flatten';
import { ChannelUtil } from 'appkit/lib/channel';
import * as MarkupsUtils from 'appkit/lib/relay/adapters/rel-markups-adapter';

export function RelayMergedListingViewModelAdapter(masterListing) {
  return {
    ...listingCardData(masterListing),
    ...editordata(masterListing),
  };
}

/** Add only the information we currently need in Relay */
function listingCardData(listingModel) {
  return {
    id: listingModel.id,
    image: listingModel.imageOrDefault,
    healthScore: listingModel.healthScore,
    name: listingModel.title, // name = marketing name, title = content sync title
    bedrooms: listingModel.bedrooms,
    bathrooms: listingModel.bathroomsNoDecimal,
    channelListings: listingModel.channelListings, //review this DRY
    address: listingModel.address,
    city: listingModel.city,
    currency: listingModel.currency,
  };
}

function editordata(masterListing) {
  const { directChannels } = getAllPMSAndDirectChannels([masterListing]);
  const markups = MarkupsUtils.getMarkups(directChannels);
  const markupsType = MarkupsUtils.getMarkupsType(markups);

  return {
    isPricePostingEnabled: masterListing.enabled,
    isAvailabilitySyncEnabled: masterListing.availabilityEnabled,
    isReservationSyncEnabled: masterListing.reservationsEnabled,
    isContentSyncEnabled: masterListing.contentSyncEnabled || false,
    taxType: masterListing.reservationTaxStatus,
    markups,
    markupsType,
  };
}

/** Get all the PMS and directChannels from a listing group */
export function getAllPMSAndDirectChannels(listings) {
  const allChannelListings = flatten(listings.map(l => l.channelListings));

  const PMSList = [];
  const directChannels = [];

  for (const channelListing of allChannelListings) {
    ChannelUtil.isPms(channelListing.channel)
      ? PMSList.push(channelListing)
      : directChannels.push(channelListing);
  }

  return { PMSList, directChannels };
}
