import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { equal, match, notEmpty } from '@ember/object/computed';
import { action, set } from '@ember/object';
import { computed } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

function isWeekdayInRange(weekday, start, end) {
  if (!start || !end) {
    return false;
  }
  let startDate = moment(start);
  const endDate = moment(end);
  while (startDate.isSameOrBefore(endDate)) {
    if (startDate.locale('en').format('dddd') === weekday) {
      return true;
    }
    startDate = startDate.add(1, 'day');
  }
  return false;
}

function weekdayFilters(intl, firstDay, lastDay) {
  function rotateArray(array, n) {
    n = n % array.length;
    return array.slice(n, array.length).concat(array.slice(0, n));
  }

  const firstDow = moment.localeData(intl.locale[0])._week.dow;
  const weekdays = rotateArray(moment.localeData('en').weekdays(0), firstDow);

  return weekdays.map(day => {
    const disabled = !isWeekdayInRange(day, firstDay, lastDay);

    return {
      day,
      label: intl.t('common.weekdays.' + day.toLowerCase()),
      selected: !disabled,
      disabled,
    };
  });
}

@classic
@tagName('div')
@classNames('app-bulk-date-range', 'customizable')
export default class AppBulkDateRange extends Component {
  @service intl;
  minDate = new Date();

  maxDate = moment().add(2, 'years').toDate();

  @computed('settingsListForBulk.[]')
  get initialDDSettings() {
    return {
      minStay: this.settingsListForBulk[0],
    };
  }

  fields = '';
  @computed('fields')
  get fieldsArray() {
    return this.fields.split(' ');
  }

  @computed('ranges.@each.{isDynamicOverride,endDate}')
  get hasDoWWidget() {
    const fields = this.fields.split(' ');

    return (
      fields.some(f => ['priceOverridePercentage'].includes(f)) &&
      this.dayOfWeekIsAvailable
    );
  }

  listingValue = undefined;
  listingError = undefined;
  forceFieldDescription = false;
  startedSettingListingValue = false;
  dependentRange = [];
  valueModel = [];

  @notEmpty('ranges') hasRanges;

  @equal('valueType', 'int') isIntValueType;
  @equal('valueType', 'currency') isCurrencyValueType;
  @equal('valueType', 'multi') isMultiValueType;
  @match('valueType', /^select-*.*/) isSelectValueType;
  @equal('valueType', 'select-hash') isSelectHashValueType;

  isForBulkUpdates = false;
  settingsListForBulk = [];
  selectedSettingForBulk = [];
  currentRangeIds = [];
  showDropDown = false;
  @tracked selectedDynamicOverride = [];

  @computed('currentRangeIds.[]')
  get currentStartRangeOptions() {
    return this.currentRangeIds.map(index => ({
      bound: false,
      field: document.getElementById(`start-date-field-for-${this.fields}-${index}`),
      container: document.getElementById(
        `range-calendar-container-for-${this.fields}-${index}`
      ),
    }));
  }

  @computed('currentRangeIds.[]')
  get currentEndRangeOptions() {
    return this.currentRangeIds.map(index => ({
      bound: false,
      field: document.getElementById(`end-date-field-for-${this.fields}-${index}`),
      container: document.getElementById(
        `range-calendar-container-for-${this.fields}-${index}`
      ),
    }));
  }

  @computed('ranges.[]')
  get valuesForBulkUpdate() {
    return this.ranges.map((range, index) => {
      let relativeValueModel =
        this.valueModel[index].model === 'relative' ? true : false;

      return this.fieldsArray.map(field =>
        relativeValueModel ? Math.abs(100 - range.get(field)) : range.get(field)
      );
    });
  }

  @computed('ranges.@each.{error}')
  get rangeHaveErrors() {
    return this.ranges.some(range => range.error) ? true : false;
  }

  @action
  toggleCalendarContainer(index, focus) {
    let container = document.getElementsByClassName(
      `range-calendar-container ${this.fields}`
    )[index];

    if (focus) {
      container.classList.add('showing');
      return;
    }
    container.classList.remove('showing');
  }

  @action
  setRangeStart(range, value) {
    if (!value) return;
    let startDate = range.set('startDate', value && moment.utc(value));
    let endDate = range.get('endDate');
    if (startDate && (!endDate || startDate.isAfter(endDate))) {
      range.set('endDate', value && moment.utc(value));
    }
    this.rangeChanged();
  }

  @action
  setRangeEnd(range, value) {
    if (!value) return;
    range.set('endDate', value && moment.utc(value));
    this.rangeChanged();

    let rangeIndex = this.ranges.indexOf(range);
    if (rangeIndex < 0) return;
    this.send('toggleCalendarContainer', rangeIndex, false);
  }

  @action
  addRanges() {
    if (this.currentRangeIds.length > 0 && this.ranges.length < 1) {
      set(this, 'currentRangeIds', []);
    }
    if (this.selectedSettingForBulk.length > 0 && this.ranges.length < 1) {
      set(this, 'selectedSettingForBulk', []);
    }
    if (this.selectedDynamicOverride.length > 0 && this.ranges.length < 1) {
      set(this, 'selectedDynamicOverride', []);
    }
    if (this.valueModel.length > 0 && this.ranges.length < 1) {
      set(this, 'selectedSettingForBulk', []);
    }

    let arrLength = this.currentRangeIds.length;

    this.selectedSettingForBulk.pushObject(
      this.fieldsArray.map(f => this.initialDDSettings[f])
    );
    this.selectedDynamicOverride.pushObject([false]);

    if (arrLength < 1) {
      this.currentRangeIds.pushObject(1);
      this.valueModel.pushObject({
        model: this.absoluteValues ? 'absolute' : 'relative',
        id: 1,
      });
      this.addRange();
      if (this.hasDoWWidget) {
        this.addDayOfWeek(this.ranges.lastObject);
      }
      return;
    }

    this.currentRangeIds.pushObject(
      parseInt(this.currentRangeIds.objectAt(arrLength - 1)) + 1
    );
    this.valueModel.pushObject({
      model: this.absoluteValues ? 'absolute' : 'relative',
      id: parseInt(this.currentRangeIds.objectAt(arrLength - 1)) + 1,
    });

    this.addRange();
    if (this.hasDoWWidget) {
      this.addDayOfWeek(this.ranges.lastObject);
    }
  }

  @action addDayOfWeek(range) {
    range.set(
      'dayOfWeekWidget',
      weekdayFilters(this.intl, range.startDate, range.endDate)
    );
    range.set(
      'dayOfWeek',
      range.dayOfWeekWidget
        .map(dow => (dow.selected ? dow.day.toLowerCase() : ''))
        .filter(dow => dow)
    );

    range.set('fields', this.fields + ' dayOfWeek');
  }

  @action
  removeRanges(range, rangeId) {
    this.removeRange(this.ranges, this.rangesField, range);

    let arr = [];
    let settingsArr = [];
    let valueModelArr = [];
    let dynamicArr = [];
    this.currentRangeIds.forEach((range, index) => {
      if (range !== rangeId) {
        arr.push(range);
        settingsArr.push(this.selectedSettingForBulk.objectAt(index));
        valueModelArr.push(this.valueModel.objectAt(index));
        dynamicArr.push(this.selectedDynamicOverride.objectAt(index));
      }
    });
    set(this, 'currentRangeIds', arr);
    set(this, 'selectedSettingForBulk', settingsArr);
    set(this, 'valueModel', valueModelArr);
  }

  @action
  rangeValueForBulkChanged(range, rangeName, index, fields, component_value) {
    const value = component_value?.target
      ? component_value.target.value
      : component_value;

    let selectedSetting = this.selectedSettingForBulk[index][fields];
    let selectedModel = this.valueModel[index];
    let priceUpdated;
    let dynamicOverride = this.selectedDynamicOverride[index][fields];

    if (selectedModel.model === 'relative') {
      priceUpdated =
        selectedSetting.action === 'increase' ? 100 + +value / 1 : 100 - +value;
    } else {
      priceUpdated = value;
    }

    range.set('isDynamicOverride', dynamicOverride);
    range.set(rangeName, selectedSetting.action === 'set' ? value : priceUpdated);
    this.rangeChanged();
  }

  @action
  updateDynamicOverride(ind, en, value, range) {
    this.selectedDynamicOverride[ind][en] = value;
    range.set('isDynamicOverride', value);
    range.set(
      'fields',
      range.isDynamicOverride
        ? range.fields.replace(
            'priceOverridePercentage',
            'dynamicPercentageUserOverride'
          )
        : range.fields.replace(
            'dynamicPercentageUserOverride',
            'priceOverridePercentage'
          )
    );

    this.rangeChanged();
  }

  @action
  updateRangeValue(index, range, idxFields) {
    if (!this.selectedSettingForBulk[index][idxFields]) return;

    let selectedSetting = this.selectedSettingForBulk[index][idxFields];

    if (selectedSetting.action === 'keep') {
      range.set(`${this.fieldsArray[idxFields]}`, null);
      range.set('fields', range.fields.replace(this.fieldsArray[idxFields], ''));
      this.rangeChanged();
      return;
    }

    if (!range.fields.includes(this.fieldsArray[idxFields])) {
      set(range, 'fields', [range.fields, this.fieldsArray[idxFields]].join(' '));
    }

    const valueToSend =
      selectedSetting.action !== 'clear' ? this.valuesForBulkUpdate[index][0] : 0;

    if (
      this.rangesField == 'minStayRanges' &&
      !this.fieldsArray.includes('minStayLocked')
    )
      range.set('fields', 'minStay minStayLocked');

    selectedSetting.action === 'lock' && this.rangesField == 'minStayRanges'
      ? range.set('minStayLocked', true)
      : range.set('minStayLocked', false);

    this.rangeChanged();
    this.rangeValueForBulkChanged(
      range,
      this.fieldsArray[idxFields],
      index,
      idxFields,
      valueToSend
    );
  }

  @action
  rangeValueChanged(range, field, component_value) {
    const value = component_value.target
      ? component_value.target.value
      : component_value;

    range.set(field, value);
    this.rangeChanged();
  }

  @action
  rangeChanged() {
    this.ranges.forEach((r, i) => {
      r.set(
        'isFixedValue',
        this.selectedSettingForBulk[i]
          .filter(i => i)
          .map(s => s.action)
          .some(s => s == 'set')
      );

      if (!(r.isFixedValue && r.isDynamicOverride)) return;

      r.set('isDynamicOverride', false);
      r.set(
        'fields',
        r.fields.replace('dynamicPercentageUserOverride', 'priceOverridePercentage')
      );
    });

    this.onRangeChange(this.rangesField, this.ranges);
  }

  @action
  dateRangeSelected(range, dates) {
    range.set('startDate', dates.start.toDate());
    range.set('endDate', dates.end?.toDate());

    if (dates.end) {
      if (this.hasDoWWidget) {
        range.set(
          'dayOfWeekWidget',
          weekdayFilters(this.intl, range.startDate, range.endDate)
        );
        range.set(
          'dayOfWeek',
          range.dayOfWeekWidget
            .map(dow => (dow.selected ? dow.day.toLowerCase() : ''))
            .filter(dow => dow)
        );

        range.set('fields', range.fields + ' dayOfWeek');
      }

      this.rangeChanged();
    }
  }

  @action
  toggleDayOfWeek(range, weekday) {
    range.set(
      'dayOfWeekWidget',
      range.dayOfWeekWidget.map(dow =>
        dow == weekday ? { ...dow, selected: !weekday.selected } : dow
      )
    );
    range.set(
      'dayOfWeek',
      range.dayOfWeekWidget
        .map(dow => (dow.selected ? dow.day.toLowerCase() : ''))
        .filter(dow => dow)
    );
    this.rangeChanged();
  }
}
