import { spacingClasses } from '../../../theme/base.theme';
import { fontSizeClasses } from '../option/index.theme';

const defaultClasses = () => ['w-full', 'border-b', 'outline-none', 'italic'];

export default {
  root: {
    defaultClasses,
    fontSizeClasses,
    spacingClasses,
  },
};
