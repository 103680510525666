export default [
  {
    id: 'one',
    options: {
      classes: 'shepherd-tour shepherd-step-one',
      tippyOptions: {
        offset: '',
      },
      title: 'Welcome to the Beyond Pricing Calendar',
      text: [
        `This demo gives you access to your pricing calendar. Sign up to see prices for your own listing.`,
      ],
    },
  },
  {
    id: 'two',
    options: {
      attachTo: {
        element: '.price-inputs',
        on: 'bottom',
      },
      classes: 'shepherd-tour shepherd-step-two',
      title: 'Pricing Controls and Insights',
      text: [
        `Control your pricing, set minimums, view specific dates, and get a bird's eye view of your entire year. Click on the question mark icon for more details on setting a base price correctly.`,
      ],
    },
  },
  {
    id: 'three',
    options: {
      attachTo: {
        element: '.app-calendar',
        scrollTo: true,
      },
      classes: 'shepherd-tour shepherd-step-three',
      title: 'Your whole year at a glance.',
      text: [
        'Our calendar view will allow you to easily see when and why pricing has been adjusted for every day of the year.',
      ],
    },
  },
];
