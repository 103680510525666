import AuthRoute from 'appkit/routes/auth';

export default class DashboardPricingJoinRoute extends AuthRoute {
  async beforeModel() {
    const { user } = this.modelFor('dashboard');
    if (user.pricingActivatedAt) {
      this.transitionTo('dashboard.pricing');
    }
  }
}
