import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';

const showErrorAlert = (alertService, intlService) => {
  alertService.error(intlService.t('validation.genericWithContact'), {
    timeout: 6000,
  });
};

const showSuccessAlert = (alertService, intlService) => {
  alertService.success(intlService.t('admin.paymentConfirmation'), {
    timeout: 6000,
  });
};

export default class DashboardUserBillingPaymentConfirmRoute extends AuthRoute {
  @service ajax;
  @service alert;
  @service intl;

  async beforeModel(transition) {
    const url = '/api/payments/confirm';

    try {
      await this.ajax._post(url, transition.to.queryParams);
      showSuccessAlert(this.alert, this.intl);
    } catch (errors) {
      showErrorAlert(this.alert, this.intl);
      return;
    }

    this.transitionTo('/dashboard/user/billing');
  }
}
