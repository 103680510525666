import moment from 'moment';

export const chartColors = [
  ['rgba(50, 107, 255, 0.6)', 'rgba(50, 107, 255, 0.1)'],
  ['rgba(255, 94, 178, 0.6)', 'rgba(255, 94, 178, 0.1)'],
  ['rgba(30, 202, 211, 0.6)', 'rgba(30, 202, 211, 0.1)'],
  ['rgba(149, 133, 255, 0.6)', 'rgba(149, 133, 255, 0.1)'],
  ['rgba(176, 49, 199, 0.6)', 'rgba(176, 49, 199, 0.1)'],
  ['rgba(227, 17, 167, 0.6)', 'rgba(227, 17, 167, 0.1)'],
  ['rgba(0, 0, 199, 0.6)', 'rgba(0, 0, 199, 0.1)'],
  ['rgba(38, 203, 124, 0.6)', 'rgba(38, 203, 124, 0.1)'],
];

export function toGradient(colors) {
  return {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [
      [0, colors[1]],
      [1, 'rgba(255, 255, 255, 0.0)'],
    ],
  };
}

export function numberWithCommas(x, { showDecimals = false } = {}) {
  const value = showDecimals ? x : Math.round(x);

  const formatter = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
}

export function chunk(
  {
    chartName,
    timeframe,
    timeranges,
    displayBy,
    series,
    future,
    mapFunction,
    yearAgoMapFunction,
    twoYearsAgoMapFunction,
    threeYearsAgoMapFunction,
    options,
    timeframeCache,
    isChartVisible,
    onChartVisibilityChange,
    marketGroupName = 'Comparison group',
  },
  {
    compareToSeries,
    compareToMapFunction,
    compareToYearAgoMapFunction,
    compareToTwoYearsAgoMapFunction,
    compareToThreeYearsAgoMapFunction,
  } = {}
) {
  const date = moment.utc();
  const isDoW = timeframe === 'day' && displayBy === 'dow';

  const isVisible = (name, visibleFn) => {
    const supportsPacing =
      !!yearAgoMapFunction || !!twoYearsAgoMapFunction || !!threeYearsAgoMapFunction;
    const supportsFuture = future;
    const visible =
      typeof visibleFn === 'function'
        ? visibleFn(supportsPacing, supportsFuture)
        : visibleFn;

    const existing = isChartVisible(chartName, name);

    if (existing !== undefined) {
      return existing;
    }

    return visible ?? false;
  };

  const adjustForDoW = (bounds, date, isDoW) =>
    isDoW
      ? {
          start: bounds.start.day(date.day()),
          end: bounds.end.day(date.day()),
        }
      : bounds;

  const ranges = timeranges.ranges
    .filter(r => future || r.future !== true) // Filter out future ranges if it's not supported
    .map(r => ({
      ...r,
      bounds: adjustForDoW(r.bounds(date, timeframe), date, isDoW),
    }));

  const withSeries = (
    series = [],
    ranges,
    mapFunction,
    yearAgoMapFunction,
    twoYearsAgoMapFunction,
    threeYearsAgoMapFunction,
    isMarketData
  ) =>
    ranges
      .map((r, idx) => ({ ...r, colorIndex: idx % 8 }))
      .filter(
        r =>
          !r.pacing ||
          yearAgoMapFunction ||
          twoYearsAgoMapFunction ||
          threeYearsAgoMapFunction
      ) // Filter out pacing ranges if it's not supported
      .map(r => ({
        ...r,
        isMarketData,
        series: series
          .filter(s => {
            const res = moment
              .utc(s.timeframe)
              .isBetween(r.bounds.start, r.bounds.end, timeframe, '[)');
            return res;
          })
          .map((row, i) => {
            // Cache the iso -> moment -> int conversion since it happens a lot
            // const key = `${chartName}-${row.timeframe}-${timeframe}`;
            // Stamp should based on UTC time and should be exactly the same across different timezones
            let time = row.timeframe;
            const key = `${time}-${timeframe}`;
            let stamp = timeframeCache[key];

            if (!stamp) {
              let timeWithUTCTimeZone = time.concat('T00:00:00.000Z');
              let momentUTCtime = moment.utc(timeWithUTCTimeZone);
              stamp = momentUTCtime.clone().startOf(timeframe).valueOf();
              timeframeCache[key] = stamp;
            }

            // We need to check whether it is pacing from this year or pacing from two years ago
            let y;
            if (!r.pacing) {
              y = mapFunction(row, i);
            } else if (r.key === 'insights.charts.asOf3YearsAgo') {
              y = threeYearsAgoMapFunction(row, i);
            } else if (r.key === 'insights.charts.asOf2YearsAgo') {
              y = twoYearsAgoMapFunction(row, i);
            } else if (r.key === 'insights.charts.asOf1Year') {
              y = yearAgoMapFunction(row, i);
            }
            return {
              y: y,
              name: stamp,
              options: options ? { tooltip_value: row[options.tooltip_key] } : null,
            };
          }),
      }));

  const rangesWithSeries = withSeries(
    series,
    ranges,
    mapFunction,
    yearAgoMapFunction,
    twoYearsAgoMapFunction,
    threeYearsAgoMapFunction
  ).concat(
    compareToSeries?.length > 0
      ? withSeries(
          compareToSeries,
          ranges,
          compareToMapFunction,
          compareToYearAgoMapFunction,
          compareToTwoYearsAgoMapFunction,
          compareToThreeYearsAgoMapFunction,
          true
        )
      : []
  );

  // Values for xAxis labels is created from pointStart, pointInterval, pointIntervalUnit
  let pointStart = ranges.find(r => r.visible === true).bounds.start.valueOf();
  let pointInterval = 1;
  let pointIntervalUnit = timeframe;

  if (timeframe === 'week') {
    pointInterval = 7;
    pointIntervalUnit = 'day';
  }

  const rangesWithChart = rangesWithSeries.map((r, i) => ({
    ...r,
    chartData: {
      legendIndex: i,
      name: `${r.isMarketData ? marketGroupName : ''}${r.label}`, // Names have to be unique so we prepend the series group name
      _groupName: r.isMarketData ? marketGroupName : null,
      data: r.series,
      _offset: 0, //index - 2,
      marker: {
        symbol: 'circle',
        lineColor: null,
        lineWidth: 1,
      },
      pointStart,
      pointInterval,
      pointIntervalUnit,
      events: {
        show() {
          onChartVisibilityChange(chartName, r.label, true);
        },
        hide() {
          onChartVisibilityChange(chartName, r.label, false);
        },
      },
      lineColor: chartColors[r.colorIndex][0],
      dashStyle: r.isMarketData ? 'Dash' : 'Solid',
      color: chartColors[r.colorIndex][0],
      fillColor: toGradient(chartColors[r.colorIndex]),
      visible: isVisible(r.label, r.visible),
    },
  }));
  return rangesWithChart.map(d => d.chartData);
}

export function momentFormatMonth(_moment) {
  if (!_moment._isAMomentObject) {
    _moment = moment.utc(_moment);
  }
  return moment.months()[_moment.month()];
}

export function momentFormatDayMonth(_moment, locale) {
  if (!_moment._isAMomentObject) {
    _moment = moment.utc(_moment);
  }
  let options = {
    month: 'short',
    day: '2-digit',
  };
  return new Intl.DateTimeFormat(locale, options).format(
    new Date(_moment.locale('en').format('MMM DD YYYY'))
  );
}

// Format moment objects as month + year. e.g. March 2020. Useful for monthly comparisons.
export function momentFormatMonthYear(_moment, locale) {
  if (!_moment._isAMomentObject) {
    _moment = moment.utc(_moment);
  }
  let options = {
    month: 'short',
    year: 'numeric',
  };
  return new Intl.DateTimeFormat(locale, options).format(
    new Date(_moment.locale('en').format('MMM DD YYYY'))
  );
}

export function round(num) {
  return Math.round(100 * num) / 100;
}

export function chartOptions({
  title = '',
  yTitle = '',
  locale,
  tooltipOptionsFn = () => ({
    prefix: '',
    suffix: '',
    details: '',
    timeframe: null,
    displayBy: null,
    showDecimals: false,
  }),
}) {
  return {
    chart: {
      height: '300px',
      type: 'areaspline',
      events: {
        beforePrint: function () {
          this.oldhasUserSize = this.hasUserSize;
          this.resetParams = [this.chartWidth, this.chartHeight, false];
          this.setSize(600, 400, false);
        },
        afterPrint: function () {
          this.setSize.apply(this, this.resetParams);
          this.hasUserSize = this.oldhasUserSize;
        },
      },
    },
    title: {
      text: title,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          font: '14px Europa',
        },
        formatter() {
          // Display xAxix labels as months for all charts
          const _moment = moment.utc(moment(this.value));
          return `<span class="capitalize">${momentFormatMonth(
            _moment,
            locale
          )}</span>`;
        },
      },
      tickColor: 'transparent',
      tickInterval: 30 * 24 * 3600 * 1000, // 1 month
      gridLineColor: 'rgba(185, 202, 210, 0.4)',
      minorGridLineColor: 'rgba(185, 202, 210, 0.4)',
      crosshair: true,
    },
    yAxis: {
      labels: {
        style: {
          font: '14px Europa',
        },
      },
      title: {
        text: yTitle,
      },
      gridLineColor: 'transparent',
      tickColor: 'transparent',
    },
    plotOptions: {
      series: {
        showInNavigator: true,
      },
    },
    tooltip: {
      valueDecimals: 2,
      split: true,
      formatter: function () {
        //          | Tooltip                      | Axis Label
        // ---------|------------------------------|-------------------
        // Daily    | Jan 28, 2019: value          | Jan 28
        // Weekly   | Week 4, 2019 (Jan 28): value | Week 4
        // Monthly  | Jan 2019: value              | Jan

        // Format the X-axis header - strip all year info
        let formatter;

        // Fetch dynamic options
        const {
          timeframe,
          displayBy,
          prefix = '',
          suffix = '',
          details = '',
          showDecimals,
        } = tooltipOptionsFn();

        if (timeframe === 'month') {
          formatter = momentFormatMonth;
        } else if (timeframe === 'week') {
          formatter = _moment => `Week ${_moment.week()}`;
        } else if (timeframe === 'day' && displayBy === 'dow') {
          formatter = _moment => `${_moment.format('ddd')}`;
        } else {
          formatter = momentFormatDayMonth;
        }

        let pointName = moment.utc(this.points[0].x);
        const headerLabel = formatter(pointName, locale);

        // Format the tooltip
        const pointLabels = (this.points || []).map(point => {
          let formatter;
          if (timeframe === 'month') {
            formatter = momentFormatMonthYear;
          } else if (timeframe === 'week') {
            formatter = _moment =>
              `Week ${_moment.week()} starts on ${_moment
                .day(displayBy)
                .format('MMM DD')}, ${_moment.year()}`;
          } else if (timeframe === 'day') {
            formatter = _moment => `${_moment.format('ddd, MMM DD, YYYY')}`;
          } else {
            formatter = _moment => _moment.format('ll');
          }
          const offset = point.series.userOptions._offset || 0;
          // We have to plot them on the same year to get highcharts to behave.
          // Reset the year in the tooltip.
          let pointLabel = formatter(
            moment.utc(point.key).add(offset, 'years'),
            locale
          );

          const yLabel = numberWithCommas(point.y, { showDecimals });
          if (point.point.options.tooltip_value) {
            return `<span class="capitalize">${pointLabel}</span>: <b>${prefix}${yLabel}${suffix} out of ${point.point.options.tooltip_value} ${details}</b>`;
          }
          return `<span class="capitalize">${pointLabel}</span>: <b>${prefix}${yLabel}${suffix}</b>`;
        });

        // The first returned item is the header, subsequent items are the
        // points
        return [
          `<span class="capitalize font-bold">${headerLabel}</span>`,
          ...pointLabels,
        ];
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
}
