import * as Sentry from '@sentry/browser';
import { Ember } from '@sentry/integrations/esm/ember';
import posthog from 'posthog-js';
import config from 'appkit/config/environment';

export function startSentry() {
  Sentry.init({
    ...config.sentry,
    ignoreErrors: ['user'],
    integrations: [new Ember(), new posthog.SentryIntegration(posthog, 'Beyond', 4230)],
    beforeSend(event, hint) {
      const error = hint.originalException;

      // ignore aborted route transitions from the Ember.js router
      if (error && error.name === 'TransitionAborted') {
        return null;
      }

      return event;
    },
  });
}
