import BaseModel from 'appkit/bp-models/base_model';
import { computed } from '@ember/object';
import { CHANNEL_CONFIG } from 'appkit/lib/channel_configuration';
import { ChannelUtil } from 'appkit/lib/channel';

export default BaseModel.extend({
  isDeleting: false,
  channelDisplayId: '',
  channelConnectId: '',
  channelConnectIdSecondary: '',
  email: '',
  valid: false,
  channel: '',
  isMinStayEnabled: false,
  markup: null,

  init() {
    this._super(arguments);
    this.set('listings', this.listings ?? []);
  },

  canEditMarkup: computed('user.canEditMarkupAccountIds', function () {
    const canEditMarkupAccountIds = this.get('user.canEditMarkupAccountIds');
    const accountId = this.id;
    let isRelayGrandfathered = this.get('user.isGrandfatheredRelayUser');
    return canEditMarkupAccountIds.includes(accountId) && isRelayGrandfathered;
  }),
  channelConfig: computed('channel', function () {
    return CHANNEL_CONFIG[this.channel];
  }),
  hasChannelMigration: computed('channel', function () {
    return ChannelUtil.hasChannelMigration(this.channel);
  }),
  channelMigration: computed('channel', function () {
    return ChannelUtil.getChannelMigration(this.channel);
  }),
});
