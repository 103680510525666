import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class StatsRoute extends Route {
  templateName = 'dashboard.stats';
  controllerName = 'dashboard.stats';

  model() {
    let params = this.paramsFor('demo');
    let url = `/api/listings/demo/${params.id}/stats`;
    return this.ajax._get(url);
  }
}
