import AuthRoute from 'appkit/routes/auth';
import showRelayRegisterInterest from '../../../lib/show-relay-register-interest';
import { inject as service } from '@ember/service';

export default class FormRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service intl;
  @service featureFlag;

  isSurfaceRelayInAppEnabled = false;
  supercontrolFFEnabled = false;
  trackFFEnabled = false;

  title() {
    let title = null;
    title = `${this.intl.t('relay.relay')} / ${this.intl.t('relay.registerInterest')}`;
    this.globalNav.setTitle(title);
    return title;
  }

  async beforeModel() {
    this.isSurfaceRelayInAppEnabled = await this.featureFlag.evaluate(
      'surface-relay-in-app',
      false
    );
    this.supercontrolFFEnabled = await this.featureFlag.evaluate(
      'surface-relay-in-app-supercontrol',
      false
    );

    this.trackFFEnabled = await this.featureFlag.evaluate(
      'surface-relay-in-app-track',
      false
    );
  }

  afterModel(model) {
    model.supercontrolFFEnabled = this.supercontrolFFEnabled;
    model.trackFFEnabled = this.trackFFEnabled;
    if (
      !showRelayRegisterInterest(
        model.accounts,
        model.user,
        this.supercontrolFFEnabled,
        this.trackFFEnabled
      ) ||
      !this.isSurfaceRelayInAppEnabled
    ) {
      return this.transitionTo('dashboard');
    }
  }
}
