import FiltersStateService, { BaseFiltersState } from './base-filters';

const DEFAULT_RELAY_FILTERS_MERGED = {
  ...BaseFiltersState.defaultPreset,
  hideUnavailable: true,
};
const DEFAULT_RELAY_FILTERS_UNMERGED = {
  ...BaseFiltersState.defaultPreset,
  hideUnavailable: false,
};

class RelayFiltersState extends BaseFiltersState {
  constructor(defaultRelayFilters = RelayFiltersState.getRelayFiltersPreset()) {
    super(defaultRelayFilters);
  }

  static getRelayFiltersPreset(isUnmerged) {
    const defaultPreset = isUnmerged
      ? DEFAULT_RELAY_FILTERS_UNMERGED
      : DEFAULT_RELAY_FILTERS_MERGED;

    return { ...defaultPreset };
  }
}

export default class RelayFiltersStateService extends FiltersStateService(
  RelayFiltersState
) {
  shouldApplyDefaultFilters = () =>
    !this.hasFiltersApplied && !this.selectedSavedFilter;

  refreshDefaultFilters(isUnmergedTab) {
    const defaultFilters = RelayFiltersState.getRelayFiltersPreset(isUnmergedTab);
    this.clearFilters(defaultFilters);
  }
}
