import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import EmberObject, { action } from '@ember/object';
import Route from '@ember/routing/route';

@classic
export default class DemoRoute extends Route {
  titleToken = 'Demo';

  @service tour;
  @service featureFlag;
  @service listingActions;

  async afterModel() {
    this.templateName = 'dashboard.pricing.listing';
    this.controllerName = 'listing';
  }

  activate() {
    if (this.templateName === 'dashboard.pricing.listing') {
      document.body.classList.add('v2');
    }
  }

  deactivate() {
    if (this.templateName === 'dashboard.pricing.listing') {
      document.body.classList.remove('v2');
    }
  }

  setupController(controller, model) {
    // How many times we've displayed the tour. This is a hack - we should likely
    // set the tour on a higher route. That way we can navigate through multiple
    // pages as part of the tour.
    controller.set('tourCount', 0);

    super.setupController(...arguments);

    controller.set('model', model);
    controller.set(
      'links',
      EmberObject.create({
        calendar: 'demo.calendar',
        chart: 'demo.chart',
        stats: 'demo.stats',
        marketData: 'demo.market-data',
        nearby: 'demo.nearby',
        customize: 'demo.customize',
      })
    );

    this.listingActions.setup({
      listing: model.listing,
      calendar: model.calendar,
    });

    return controller;
  }

  model(params) {
    let url = `/api/listings/demo/${params.id}`;

    return this.ajax._get(url).then(data => {
      let listing = this.bpStore.createRecord('listing', data.listing);

      let channelListings = data.channelListings.map(cl => {
        let obj = this.bpStore.createRecord('channelListing', cl);
        obj.set('masterListing', listing);
        return obj;
      });
      listing.set('channelListings', channelListings);

      let primary = this.bpStore.peekRecord(
        'channelListing',
        listing.get('primaryChannelListingId')
      );
      listing.set('primaryChannelListing', primary);

      let reservations = data.reservations.map(reservation =>
        this.bpStore.createRecord('reservation', reservation)
      );

      let calendar = listing.set(
        'calendar',
        data.calendar.map(day => {
          let ld = this.bpStore.createRecord('listingDate', day);
          ld.set('listing', listing);

          if (ld.get('reservationId')) {
            let reservation = this.bpStore.peekRecord(
              'reservation',
              ld.get('reservationId')
            );
            ld.set('reservations', reservation);
          }
          return ld;
        })
      );

      listing.set('permissions', 'edit');

      return EmberObject.create({
        listing: listing,
        calendar: calendar,
        reservations: reservations,
      });
    });
  }

  @action
  toggleForm() {
    // TODO: get rid of this. Use mut helper.
    $('.demo-form-wrap').slideToggle();
    $('.demo-form-wrap input').focus();
    return false;
  }
}
