import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';

export default class DashboardUserBillingCardCallbackRoute extends AuthRoute {
  @service cards;
  @service featureFlag;
  @service forcePaymentMethod;

  async afterModel(model, transition) {
    const url = '/api/cards/bpg';
    const data = {
      card_id: transition.to.queryParams.card_id,
    };

    this.cards.add(url, data, model);

    this.forcePaymentMethod.resume();
  }
}
