export const colorBlindOptions = intl => [
  {
    title: intl.t('admin.standardBeyond'),
    description: '',
    value: 'default',
  },
  {
    title: 'Deuteranopia',
    description: intl.t('admin.deuteranopiaDescription'),
    value: 'deuteranopia',
  },
  {
    title: 'Protanopia',
    description: intl.t('admin.protanopiaDescription'),
    value: 'protanopia',
  },
  {
    title: 'Tritanopia',
    description: intl.t('admin.tritanopiaDescription'),
    value: 'tritanopia',
  },
];
