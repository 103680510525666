import AuthRoute from 'appkit/routes/auth';

export default class DashboardInsightsJoinRoute extends AuthRoute {
  templateName = 'dashboard.insights.join';

  async beforeModel() {
    const { user } = this.modelFor('dashboard');
    if (user.insightsActivatedAt) {
      this.transitionTo('dashboard.insights');
    }
  }
}
