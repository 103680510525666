import EmberObject, { computed } from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';
import moment from 'moment';

export default BaseModel.extend({
  password: '',
  confirmation: '',
  status: '',
  cards: null,
  isStaff: false,
  isBlocked: false,
  referrerCode: '',
  isRelayEnabled: false,
  isGrandfatheredRelayUser: false,
  hasUsedMarkups: false,
  errors: EmberObject.create({}),
  ldKey: '',
  ldSecureHash: '',

  init() {
    this._super(arguments);
    this.set('channelsBetaEnabled', this.channelsBetaEnabled ?? []);
    this.set('accounts', this.accounts ?? []);
    this.set('credentials', this.credentials ?? []);
    this.set('statements', this.statements ?? []);
    this.set('accountErrors', this.accountErrors ?? []);
    this.set('canEditMarkupAccountIds', this.canEditMarkupAccountIds ?? []);
    this.set('cards', this.cards ?? []);
  },

  _trialStartDate: '',
  trialStartDate: computed('_trialStartDate', {
    get() {
      return this._trialStartDate;
    },
    set(key, value) {
      if (value && !value._isAMomentObject) {
        value = moment(value);
      }
      this.set('_trialStartDate', value);
      return this._trialStartDate;
    },
  }),

  _trialEndDate: '',
  trialEndDate: computed('_trialEndDate', {
    get() {
      return this._trialEndDate;
    },
    set(key, value) {
      if (value && !value._isAMomentObject) {
        value = moment(value);
      }
      this.set('_trialEndDate', value);
      return this._trialEndDate;
    },
  }),

  _firstEnabledAt: null,
  firstEnabledAt: computed('_firstEnabledAt', {
    get() {
      return this._firstEnabledAt;
    },
    set(key, value) {
      if (value && !value._isAMomentObject) {
        value = moment(value);
      }
      this.set('_firstEnabledAt', value);
      return this._firstEnabledAt;
    },
  }),
});
