export const MarkupsType = {
  Static: 'static',
  Dynamic: 'dynamic',
  Mixed: 'mixed',
};

export const markupsFormater = {
  // Atm, gpt API value  to reset static=0.000 , dynamicMarkups=null
  // It would be really nice for back to accept null values for static markups
  // so we can skip this evaluations
  toApi: (markup, nullValue = '0.0000') =>
    !isNaN(parseFloat(markup)) ? (markup / 100).toFixed(4) : nullValue,
  toApp: markup => {
    if (markup === '0.0000' || !markup) return null;
    if (!isNaN(parseFloat(markup))) return (markup * 100).toFixed(2);
  },
};

export function getMarkups(channelListings) {
  const staticMarkups = getStaticMarkups(...channelListings).filter(
    m => Number(m.value) !== 0
  );
  const dynamicMarkups = getDynamicMarkups(...channelListings);

  return [...staticMarkups, ...dynamicMarkups];
}

/**
 * Types:
 * - null: no markups defined.
 * - Mixed: dynamic + static, or several dynamic/static with diferent values
 * - Static: single value. { value: x }
 * - Dynamic: Min and Max values or both  { minMarkup, maxMarkup }
 */
export function getMarkupsType(markups) {
  if (!markups.length) return null;

  // Iterate over all channelListings markups, to detect if any channel has
  // different markupTypes or different values of the same markupType
  const someDynamicMarkups = markups.some(m => m.type === MarkupsType.Dynamic);
  const someStaticMarkups = markups.some(m => m.type === MarkupsType.Static);

  if (someDynamicMarkups && someStaticMarkups) return MarkupsType.Mixed;

  // Iterate over values to find diffs
  const markupsMap = new Map();
  for (const markup of markups) {
    const existingMarkup = markupsMap.get(markup.channel);

    if (!existingMarkup) {
      markupsMap.set(markup.channel, markup);
      continue;
    }
    // We compare with stored value
    if (markup.type === MarkupsType.Static) {
      const { value } = markup;
      const isSameValue = value === existingMarkup.value;

      if (!isSameValue) return MarkupsType.Mixed;
    } else if (markup.type === MarkupsType.Dynamic) {
      const { minMarkup, maxMarkup } = markup;
      const isSameMin = minMarkup === existingMarkup.minMarkup;
      const isSameMax = maxMarkup === existingMarkup.maxMarkup;

      if (!isSameMin || !isSameMax) return MarkupsType.Mixed;
    }
  }
  const allDynamicMarkups = markups.every(m => m.type === MarkupsType.Dynamic);
  const allStaticMarkups = markups.every(m => m.type === MarkupsType.Static);

  return allDynamicMarkups
    ? MarkupsType.Dynamic
    : allStaticMarkups
    ? MarkupsType.Static
    : null;
}

export function getChannelMetadata(channel) {
  return {
    //This is curious: We are currently posting the channel when updating prefs, but not the id :o
    channel: channel.channel,
    channelId: channel.account.channelId,
    channelLabel: channel.channelLabel,
  };
}

export function getDynamicMarkups(...channelListings) {
  return channelListings
    .filter(cl => cl.dynamicMarkupsEnabled === true)
    .map(cl => {
      return {
        ...getChannelMetadata(cl),
        type: MarkupsType.Dynamic,
        maxMarkup: markupsFormater.toApp(cl.maxMarkup),
        minMarkup: markupsFormater.toApp(cl.minMarkup),
      };
    });
}

export function getStaticMarkups(...channelListings) {
  return channelListings
    .filter(cl => cl.appliedMarkup)
    .map(cl => {
      return {
        ...getChannelMetadata(cl),
        type: MarkupsType.Static,
        value: markupsFormater.toApp(cl.appliedMarkup),
      };
    });
}

export function adaptMarkupsToAPI(markups, markupsType) {
  return markupsType === null
    ? formatClearedMarkups(markups)
    : markupsType === MarkupsType.Dynamic
    ? formatDynamicMarkups(markups)
    : markupsType == MarkupsType.Static
    ? formatStaticMarkups(markups)
    : {}; // mixed markups don´t get bulk updated
}

export function formatStaticMarkups(markupsMap) {
  const markupsItem = {};

  for (const [channelId, markup] of markupsMap.entries()) {
    markupsItem[channelId] = markupsFormater.toApi(markup.value);
  }
  return markupsItem;
}

export function formatDynamicMarkups(markupsMap) {
  const markupsItem = {};

  for (const [channelId, markup] of markupsMap.entries()) {
    markupsItem[channelId] = {
      minMarkup: markupsFormater.toApi(markup.minMarkup, null),
      maxMarkup: markupsFormater.toApi(markup.maxMarkup, null),
    };
  }
  return markupsItem;
}

export function formatClearedMarkups(markupsMap) {
  const markupsItem = {};

  for (const [channelId] of markupsMap.entries()) {
    markupsItem[channelId] = {
      minMarkup: null,
      maxMarkup: null,
      value: '0.0000',
    };
  }
  return markupsItem;
}
