/* eslint-disable ember/no-observers */
import Mixin from '@ember/object/mixin';
import { observer } from '@ember/object';
import { isArray } from '@ember/array';
import { isEmpty, typeOf } from '@ember/utils';
import logger from 'appkit/lib/logger';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  // -- Parameters ---------------------------------------------------------------------
  queryParams: {
    searchParam: 'search',
    searchAppearance: 'appearance',
    searchMode: 'mode',
    searchSortField: 'sort_field',
    searchSortOrder: 'sort_order',
    searchHealthScoreForInputMin: 'health_score_min',
    searchHealthScoreForInputMax: 'health_score_max',
    searchBasePriceForInputMin: 'base_price_min',
    searchBasePriceForInputMax: 'base_price_max',
    searchSelectedBedrooms: 'selected_bedrooms',
    searchSelectedMarketNames: 'selected_market_names',
    searchSelectedClusterNames: 'selected_cluster_names',
    searchSelectedCategory1Names: 'selected_category_1_names',
    searchSelectedCategory2Names: 'selected_category_2_names',
    searchListingsToInclude: 'listings_to_include',
    searchListingsToExclude: 'listings_to_exclude',
    searchSelectedOriginForRadius: 'selected_origin_for_radius',
    searchDistancesForInputMin: 'distance_min',
    searchDistancesForInputMax: 'distance_max',
    searchSelectedAccountsByName: 'selected_accounts',
    searchShowEnabled: 'show_enabled',
    searchShowDisabled: 'show_disabled',
    searchShowOnProgram: 'show_on_program',
    searchShowOffProgram: 'show_off_program',
    searchMonthlyPricePostingEnabled: 'monthly_price_posting_enabled',
    searchMonthlyPricePostingDisabled: 'monthly_price_posting_disabled',
    searchHideUnavailable: 'hide_unavailable',
    searchSelectedListingIds: 'listing_ids',
    filterByNeedReview: 'filter_by_need_review',
  },

  searchParam: null,
  searchAppearance: null,
  searchMode: null,
  searchSortField: null,
  searchSortOrder: null,
  searchHealthScoreForInputMin: null,
  searchHealthScoreForInputMax: null,
  searchBasePriceForInputMin: null,
  searchBasePriceForInputMax: null,
  searchSelectedBedrooms: null,
  searchBedroomsForInputMin: null,
  searchBedroomsForInputMax: null,
  searchSelectedMarketNames: null,
  searchSelectedClusterNames: null,
  searchSelectedCategory1Names: null,
  searchSelectedCategory2Names: null,
  searchListingsToInclude: null,
  searchListingsToExclude: null,
  searchSelectedOriginForRadius: null,
  searchDistancesForInputMin: null,
  searchDistancesForInputMax: null,
  searchSelectedAccountsByName: null,
  searchShowEnabled: null,
  searchShowDisabled: null,
  searchShowOnProgram: null,
  searchShowOffProgram: null,
  searchMonthlyPricePostingEnabled: null,
  searchMonthlyPricePostingDisabled: null,
  searchHideUnavailable: null,
  selectedListingsIds: [],
  filterByNeedReview: null,

  // Required because some computed properties change other properties on change (ex: sortField, sortOrder)
  searchParamsAlreadyUsed: false,

  // -- Computed Properties ------------------------------------------------------------

  // In docs explicitly says we cannot map query params to computed properties. So we store them in values and have
  // observers to react to changes in the service.
  _updateSearchParam: observer('listControl.debouncedSearch', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchParam', this.get('listControl.debouncedSearch'));
  }),

  _updateSearchAppearance: observer('appearance', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchAppearance', this.appearance);
  }),

  _updateMode: observer('mode', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchMode', this.mode);
  }),

  _updateSearchMode: observer('mode', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchMode', 'legacy');
  }),

  _updateSearchSortField: observer('listControl.sortField', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchSortField', this.get('listControl.sortField'));
  }),

  _updateSearchSortOrder: observer('listControl.sortOrder', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchSortOrder', this.get('listControl.sortOrder'));
  }),

  _updateSearchHealthScoreForInput: observer(
    'listControl.healthScoreForInput',
    function () {
      if (!this.searchParamsAlreadyUsed) return;

      const healthScoreForInput = this.get('listControl.healthScoreForInput');

      if (!isArray(healthScoreForInput)) return;

      const [healthScoreForInputMin, healthScoreForInputMax] = healthScoreForInput;

      this.set('searchHealthScoreForInputMin', healthScoreForInputMin);
      this.set('searchHealthScoreForInputMax', healthScoreForInputMax);
    }
  ),

  _updateSearchSelectedBedrooms: observer('listControl.selectedBedrooms', function () {
    if (!this.searchParamsAlreadyUsed) return;

    const selectedBedrooms = this.get('listControl.selectedBedrooms');
    if (!isArray(selectedBedrooms)) return;

    this.set('searchSelectedBedrooms', selectedBedrooms.join('-'));
  }),

  _updateSearchBasePriceForInput: observer(
    'listControl.basePriceForInput',
    function () {
      if (!this.searchParamsAlreadyUsed) return;

      const basePriceForInput = this.get('listControl.basePriceForInput');
      if (!isArray(basePriceForInput)) return;

      const [basePriceForInputMin, basePriceForInputMax] = basePriceForInput;

      this.set('searchBasePriceForInputMin', basePriceForInputMin);
      this.set('searchBasePriceForInputMax', basePriceForInputMax);
    }
  ),

  _updateSearchBedroomsForInput: observer('listControl.bedroomsForInput', function () {
    if (!this.searchParamsAlreadyUsed) return;

    const bedroomsForInput = this.get('listControl.bedroomsForInput');
    if (!isArray(bedroomsForInput)) return;

    const [bedroomsForInputMin, bedroomsForInputMax] = bedroomsForInput;

    this.set('searchBedroomsForInputMin', bedroomsForInputMin);
    this.set('searchBedroomsForInputMax', bedroomsForInputMax);
  }),

  _updateSearchSelectedMarketNames: observer(
    'listControl.selectedMarketNames',
    function () {
      if (!this.searchParamsAlreadyUsed) return;
      this.set(
        'searchSelectedMarketNames',
        this.get('listControl.selectedMarketNames')
      );
    }
  ),

  _updateSearchSelectedClusterNames: observer(
    'listControl.selectedClusterNames',
    function () {
      if (!this.searchParamsAlreadyUsed) return;
      this.set(
        'searchSelectedClusterNames',
        this.get('listControl.selectedClusterNames')
      );
    }
  ),

  _updateSearchSelectedCategory1Names: observer(
    'listControl.selectedCategory1Names',
    function () {
      if (!this.searchParamsAlreadyUsed) return;
      this.set(
        'searchSelectedCategory1Names',
        this.get('listControl.selectedCategory1Names')
      );
    }
  ),

  _updateSearchSelectedCategory2Names: observer(
    'listControl.selectedCategory2Names',
    function () {
      if (!this.searchParamsAlreadyUsed) return;
      this.set(
        'searchSelectedCategory2Names',
        this.get('listControl.selectedCategory2Names')
      );
    }
  ),

  _updateSearchSelectedOriginForRadius: observer(
    'listControl.selectedOriginForRadius',
    function () {
      if (!this.searchParamsAlreadyUsed) return;
      this.set(
        'searchSelectedOriginForRadius',
        this.get('listControl.selectedOriginForRadius.id')
      );
    }
  ),

  _updateSearchDistancesForInput: observer(
    'listControl.{selectedOriginForRadius,distancesForInput}',
    function () {
      if (!this.searchParamsAlreadyUsed) return;

      const distancesForInput = this.get('listControl.distancesForInput');
      if (!isArray(distancesForInput)) return;

      const [distancesForInputMin, distancesForInputMax] = distancesForInput;

      this.set('searchDistancesForInputMin', distancesForInputMin);
      this.set('searchDistancesForInputMax', distancesForInputMax);
    }
  ),

  _updateSearchListingsToInclude: observer(
    'listControl.listingsToInclude',
    function () {
      if (!this.searchParamsAlreadyUsed) return;
      let includeListingsIds = this.listControl.listingsToInclude
        .map(listing => listing.id)
        .join('-');
      this.set('searchListingsToInclude', includeListingsIds);
    }
  ),

  _updateSearchListingsToExclude: observer(
    'listControl.listingsToExclude',
    function () {
      if (!this.searchParamsAlreadyUsed) return;
      let excludeListingsIds = this.listControl.listingsToExclude
        .map(listing => listing.id)
        .join('-');
      this.set('searchListingsToExclude', excludeListingsIds);
    }
  ),

  _updateSearchSelectedAccountsByName: observer(
    'listControl.selectedAccountsByName',
    function () {
      if (!this.searchParamsAlreadyUsed) return;

      this.set(
        'searchSelectedAccountsByName',
        this.get('listControl.selectedAccountsByName')
      );
    }
  ),

  _updateSearchShowEnabled: observer('listControl.showEnabled', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchShowEnabled', this.get('listControl.showEnabled'));
  }),

  _updateSearchShowDisabled: observer('listControl.showDisabled', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchShowDisabled', this.get('listControl.showDisabled'));
  }),

  _updateSearchShowOnProgram: observer('listControl.showOnProgram', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchShowOnProgram', this.get('listControl.showOnProgram'));
  }),

  _updateSearchShowOffProgram: observer('listControl.showOffProgram', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchShowOffProgram', this.get('listControl.showOffProgram'));
  }),

  _updateSearchMonthlyPricePostingEnabled: observer(
    'listControl.monthlyPricePostingEnabled',
    function () {
      if (!this.searchParamsAlreadyUsed) return;

      this.set(
        'searchMonthlyPricePostingEnabled',
        this.get('listControl.monthlyPricePostingEnabled')
      );
    }
  ),

  _updateSearchMonthlyPricePostingDisabled: observer(
    'listControl.monthlyPricePostingDisabled',
    function () {
      if (!this.searchParamsAlreadyUsed) return;

      this.set(
        'searchMonthlyPricePostingDisabled',
        this.get('listControl.monthlyPricePostingDisabled')
      );
    }
  ),

  _updateSearchHideUnavailable: observer('listControl.hideUnavailable', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('searchHideUnavailable', this.get('listControl.hideUnavailable'));
  }),

  _updatefilterByNeedReview: observer('listControl.filterByNeedReview', function () {
    if (!this.searchParamsAlreadyUsed) return;

    this.set('filterByNeedReview', this.get('listControl.filterByNeedReview'));
  }),
  // -- Lifecycle Hooks ----------------------------------------------------------------

  // -- Actions ------------------------------------------------------------------------
  actions: {
    updateSearchParams(queryParams) {
      // set query params
      this.set('searchParam', queryParams.search);
      this.set('searchAppearance', queryParams.appearance);
      this.set('searchMode', queryParams.mode);
      this.set('searchSortField', queryParams.sortField);
      this.set('searchSortOrder', queryParams.sortOrder);
      this.set('searchHealthScoreForInputMin', queryParams.healthScoreMin);
      this.set('searchHealthScoreForInputMax', queryParams.healthScoreMax);
      this.set('searchBasePriceForInputMin', queryParams.basePriceMin);
      this.set('searchBasePriceForInputMax', queryParams.basePriceMax);
      this.set('searchSelectedBedrooms', queryParams.selectedBedrooms);
      this.set('searchBedroomsForInputMin', queryParams.bedroomsMin);
      this.set('searchBedroomsForInputMax', queryParams.bedroomsMax);
      this.set('searchSelectedMarketNames', queryParams.selectedMarketNames);
      this.set('searchSelectedClusterNames', queryParams.selectedClusterNames);
      this.set('searchSelectedCategory1Names', queryParams.selectedCategory1Names);
      this.set('searchSelectedCategory2Names', queryParams.selectedCategory2Names);
      this.set('searchListingsToInclude', queryParams.listingsToInclude);
      this.set('searchListingsToExclude', queryParams.listingsToExclude);
      this.set('searchSelectedOriginForRadius', queryParams.selectedOriginForRadius);
      this.set('searchDistancesForInputMin', queryParams.distanceMin);
      this.set('searchDistancesForInputMax', queryParams.distanceMax);
      this.set('searchSelectedAccountsByName', queryParams.selectedAccounts);
      this.set('searchShowEnabled', queryParams.showEnabled);
      this.set('searchShowDisabled', queryParams.showDisabled);
      this.set('searchShowOnProgram', queryParams.showOnProgram);
      this.set('searchShowOffProgram', queryParams.showOffProgram);
      this.set(
        'searchMonthlyPricePostingEnabled',
        queryParams.monthlyPricePostingEnabled
      );
      this.set(
        'searchMonthlyPricePostingDisabled',
        queryParams.monthlyPricePostingDisabled
      );
      this.set('searchHideUnavailable', queryParams.hideUnavailable);
      this.set('filterByNeedReview', queryParams.filterByNeedReview);
      // apply query params to filter
      this.changeFiltersWithSearchParams();
    },
  },

  // -- Private Functions --------------------------------------------------------------
  changeFiltersWithSearchParams() {
    if (this.searchParam !== null) {
      this.set('listControl.search', this.searchParam);
      this.set('listControl.debouncedSearch', this.searchParam);
    }

    if (this.searchAppearance !== null) {
      this.set('appearance', this.searchAppearance);
      this.set(
        'selectedView',
        this.viewOptions.find(
          opt =>
            opt.svgUrl.includes(this.searchAppearance) &&
            opt.name !== this.intl.t('pricing.dashboard')
        )
      );
    } else {
      this.set('searchAppearance', this.appearance);
    }

    if (this.searchMode !== null) {
      this.set('mode', this.searchMode);
    } else {
      this.set('searchMode', 'legacy');
    }

    if (this.searchSortField !== null && this.searchSortField !== undefined) {
      this.listControl.set('sortField', this.searchSortField);
    } else {
      this.set('searchSortField', this.get('listControl.sortField'));
    }

    if (this.searchSortOrder !== null && this.searchSortOrder !== undefined) {
      this.listControl.set('sortOrder', this.searchSortOrder);
    } else {
      this.set('searchSortOrder', this.get('listControl.sortOrder'));
    }

    if (
      this.searchHealthScoreForInputMin !== null &&
      this.searchHealthScoreForInputMax !== null
    ) {
      const healthScoreForInputMin = parseInt(this.searchHealthScoreForInputMin, 10);
      const healthScoreForInputMax = parseInt(this.searchHealthScoreForInputMax, 10);
      const healthScoreForInput = [healthScoreForInputMin, healthScoreForInputMax];

      this.set('listControl.healthScoreForInput', healthScoreForInput);
      this.set('listControl.healthScoreForSlider', healthScoreForInput);
    }

    if (
      this.searchBasePriceForInputMin !== null &&
      this.searchBasePriceForInputMax !== null
    ) {
      const basePriceForInputMin = parseInt(this.searchBasePriceForInputMin, 10);
      const basePriceForInputMax = parseInt(this.searchBasePriceForInputMax, 10);
      const basePriceForInput = [basePriceForInputMin, basePriceForInputMax];

      this.set('listControl.basePriceForInput', basePriceForInput);
      this.set('listControl.basePriceForSlider', basePriceForInput);
    }

    if (this.searchSelectedBedrooms != null) {
      const selectedBedrooms = this.searchSelectedBedrooms
        .split('-')
        .map(b => Number(b))
        .filter(b => !isNaN(b));

      this.set('listControl.selectedBedrooms', selectedBedrooms);
    }

    if (
      this.searchBedroomsForInputMin !== null &&
      this.searchBedroomsForInputMax !== null
    ) {
      const bedroomsForInputMin = parseInt(this.searchBedroomsForInputMin, 10);
      const bedroomsForInputMax = parseInt(this.searchBedroomsForInputMax, 10);
      const bedroomsForInput = [bedroomsForInputMin, bedroomsForInputMax];

      this.set('listControl.bedroomsForInput', bedroomsForInput);
      this.set('listControl.bedroomsForSlider', bedroomsForInput);
    }

    if (
      this.searchDistancesForInputMin !== null &&
      this.searchDistancesForInputMax !== null
    ) {
      const distancesForInputMin = parseFloat(this.searchDistancesForInputMin);
      const distancesForInputMax = parseFloat(this.searchDistancesForInputMax);
      const distancesForInput = [distancesForInputMin, distancesForInputMax];

      this.set('listControl.distancesForInput', distancesForInput);
      this.set('listControl.distancesForSlider', distancesForInput);
    }

    if (this.searchSelectedMarketNames !== null) {
      let selectedMarketNames = [];
      if (!isEmpty(this.searchSelectedMarketNames)) {
        try {
          selectedMarketNames =
            typeOf(this.searchSelectedMarketNames) === 'array'
              ? this.searchSelectedMarketNames
              : this.searchSelectedMarketNames.split('__');
        } catch (e) {
          logger.error("Market names must have commas and can't be split");
          logger.error(e);
        }
      }

      this.set('listControl.selectedMarketNames', selectedMarketNames);
    }

    if (this.searchSelectedClusterNames !== null) {
      let selectedClusterNames = [];
      if (!isEmpty(this.searchSelectedClusterNames)) {
        try {
          selectedClusterNames =
            typeOf(this.searchSelectedClusterNames) === 'array'
              ? this.searchSelectedClusterNames
              : this.searchSelectedClusterNames.split('__');
        } catch (e) {
          logger.error("Cluster names must have commas and can't be split");
          logger.error(e);
        }
      }

      this.set('listControl.selectedClusterNames', selectedClusterNames);
    }

    if (this.searchSelectedCategory1Names !== null) {
      let selectedCategory1Names = [];

      if (!isEmpty(this.searchSelectedCategory1Names)) {
        try {
          selectedCategory1Names = decodeURI(this.searchSelectedCategory1Names).split(
            /(?<=__\d+)[-,]/
          );
        } catch (e) {
          logger.error("Category names must have commas and can't be split");
          logger.error(e);
        }
      }

      selectedCategory1Names.forEach(cat => {
        let catRef = cat.split('__')[1];

        if (this.listControl.categoriesOptionsLists.length) {
          const category1Selected = this.listControl.categoriesOptionsLists.find(
            opt => opt.id === parseInt(catRef)
          ).category1Selected;

          if (!category1Selected.includes(cat)) category1Selected.push(cat);
        }
      });
    }

    if (this.searchSelectedCategory2Names !== null) {
      let selectedCategory2Names = [];

      if (!isEmpty(this.searchSelectedCategory2Names)) {
        try {
          selectedCategory2Names = decodeURI(this.searchSelectedCategory2Names).split(
            /(?<=__\d+)[-,]/
          );
        } catch (e) {
          console.error("Category names must have commas and can't be split");
          console.error(e);
        }
      }

      selectedCategory2Names.forEach(cat => {
        let catRef = cat.split('__')[1];

        if (this.listControl.categoriesOptionsLists.length) {
          const category2Selected = this.listControl.categoriesOptionsLists.find(
            opt => opt.id === parseInt(catRef)
          ).category2Selected;
          if (!category2Selected.includes(cat)) category2Selected.push(cat);
        }
      });
    }

    if (this.searchListingsToInclude !== null) {
      let includeListingsIds;

      if (!isEmpty(this.searchListingsToInclude)) {
        try {
          includeListingsIds = decodeURI(this.searchListingsToInclude).split('-');
        } catch (e) {
          console.error("Listings IDs must have commas and can't be split");
          console.error(e);
        }
      }

      this.set('listControl.listingsToIncludeIds', includeListingsIds);
    }

    if (this.searchListingsToExclude !== null) {
      let excludeListingsIds;

      if (!isEmpty(this.searchListingsToExclude)) {
        try {
          excludeListingsIds = decodeURI(this.searchListingsToExclude).split('-');
        } catch (e) {
          console.error("Listings IDs must have commas and can't be split");
          console.error(e);
        }
      }

      this.set('listControl.listingsToExcludeIds', excludeListingsIds);
    }

    if (this.searchSelectedOriginForRadius !== null) {
      this.set(
        'listControl.selectedOriginForRadius',
        this.searchSelectedOriginForRadius
      );
    }

    if (this.searchSelectedAccountsByName !== null) {
      let selectedAccountsByName = [];

      if (!isEmpty(this.searchSelectedAccountsByName)) {
        try {
          selectedAccountsByName = decodeURI(this.searchSelectedAccountsByName).split(
            new RegExp('(?:,|__)', 'g')
          );
        } catch (e) {
          logger.error("Account names must have commas and can't be split");
          logger.error(e);
        }
      }

      this.set('listControl.selectedAccountsByName', selectedAccountsByName);
    }

    if (this.searchShowEnabled !== null && this.searchShowEnabled !== undefined) {
      this.set('listControl.showEnabled', String(this.searchShowEnabled) === 'true');
    }

    if (this.searchShowDisabled !== null && this.searchShowDisabled !== undefined) {
      this.set('listControl.showDisabled', String(this.searchShowDisabled) === 'true');
    }

    if (this.searchShowOnProgram !== null && this.searchShowOnProgram !== undefined) {
      this.set(
        'listControl.showOnProgram',
        String(this.searchShowOnProgram) === 'true'
      );
    }

    if (this.searchShowOffProgram !== null && this.searchShowOffProgram !== undefined) {
      this.set(
        'listControl.showOffProgram',
        String(this.searchShowOffProgram) === 'true'
      );
    }

    if (
      this.searchMonthlyPricePostingEnabled !== null &&
      this.searchMonthlyPricePostingEnabled !== undefined
    ) {
      this.set(
        'listControl.monthlyPricePostingEnabled',
        String(this.searchMonthlyPricePostingEnabled) === 'true'
      );
    }

    if (
      this.searchMonthlyPricePostingDisabled !== null &&
      this.searchMonthlyPricePostingDisabled !== undefined
    ) {
      this.set(
        'listControl.monthlyPricePostingDisabled',
        String(this.searchMonthlyPricePostingDisabled) === 'true'
      );
    }

    if (
      this.searchHideUnavailable !== null &&
      this.searchHideUnavailable !== undefined
    ) {
      this.set(
        'listControl.hideUnavailable',
        String(this.searchHideUnavailable) === 'true'
      );
    }

    if (this.filterByNeedReview !== null && this.filterByNeedReview !== undefined) {
      this.set(
        'listControl.filterByNeedReview',
        String(this.filterByNeedReview) === 'true'
      );
    }

    this.set('searchParamsAlreadyUsed', true);
  },

  resetSearchParams() {
    this.set('searchParam', null);
    this.set('searchAppearance', null);
    this.set('searchMode', null);
    this.set('searchSortField', null);
    this.set('searchSortOrder', null);
    this.set('searchHealthScoreForInputMin', null);
    this.set('searchHealthScoreForInputMax', null);
    this.set('searchBasePriceForInputMin', null);
    this.set('searchBasePriceForInputMax', null);
    this.set('searchBedroomsForInputMin', null);
    this.set('searchBedroomsForInputMax', null);
    this.set('searchSelectedMarketNames', null);
    this.set('searchSelectedClusterNames', null);
    this.set('searchSelectedCategory1Names', null);
    this.set('searchSelectedCategory2Names', null);
    this.set('searchListingsToInclude', null);
    this.set('searchListingsToExclude', null);
    this.set('searchSelectedOriginForRadius', null);
    this.set('searchDistancesForInputMin', null);
    this.set('searchDistancesForInputMax', null);
    this.set('searchSelectedAccountsByName', null);
    this.set('searchShowEnabled', null);
    this.set('searchShowDisabled', null);
    this.set('searchShowOnProgram', null);
    this.set('searchShowOffProgram', null);
    this.set('searchMonthlyPricePostingEnabled', null);
    this.set('searchMonthlyPricePostingDisabled', null);
    this.set('searchHideUnavailable', null);
    this.set('filterByNeedReview', null);
  },
});
