import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield (hash chartOptions=this.chartOptions chartData=this.chartData)}}", {"contents":"{{yield (hash chartOptions=this.chartOptions chartData=this.chartData)}}","moduleName":"appkit/components/insights/charts/volume/container.hbs","parseOptions":{"srcName":"appkit/components/insights/charts/volume/container.hbs"}});
import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { CurrencyUtil } from 'appkit/lib/currency';
import { chunk, chartOptions, round } from 'appkit/lib/insights/charts';
import { inject as service } from '@ember/service';

export default class InsightsChartsVolumeContainer extends Component {
  @service intl;

  get type() {
    return this.args.type ?? 'total';
  }

  get chartOptions() {
    switch (this.type) {
      case 'stay_date':
        return this.volumeByStayDateOptions;
      case 'booked_date':
        return this.volumeByBookingDateOptions;
      default:
        return this.volumeChartOptions;
    }
  }

  get volumeByStayDateOptions() {
    const { currency } = this.args;
    const s = CurrencyUtil.getSymbol(currency);
    const label = CurrencyUtil.getLabel(currency);

    return chartOptions({
      locale: this.intl.locale,
      yTitle: label,
      tooltipOptionsFn: () => ({
        prefix: s.isPrefixed ? s.symbol : '',
        suffix: !s.isPrefixed ? s.symbol : '',
        timeframe: this.args.timeframe,
        displayBy: this.args.displayBy,
      }),
    });
  }

  get volumeByBookingDateOptions() {
    const { currency } = this.args;
    const s = CurrencyUtil.getSymbol(currency);
    const label = CurrencyUtil.getLabel(currency);

    return chartOptions({
      locale: this.intl.locale,
      yTitle: label,
      tooltipOptionsFn: () => ({
        prefix: s.isPrefixed ? s.symbol : '',
        suffix: !s.isPrefixed ? s.symbol : '',
        timeframe: this.args.timeframe,
        displayBy: this.args.displayBy,
      }),
    });
  }

  get volumeChartOptions() {
    const { currency } = this.args;
    const s = CurrencyUtil.getSymbol(currency);

    return chartOptions({
      locale: this.intl.locale,
      yTitle: this.intl.t('insights.stats.volume'),
      tooltipOptionsFn: () => ({
        prefix: s.isPrefixed ? s.symbol : '',
        suffix: !s.isPrefixed ? s.symbol : '',
        timeframe: this.args.timeframe,
        displayBy: this.args.displayBy,
      }),
    });
  }

  get chartData() {
    switch (this.type) {
      case 'stay_date':
        return this.volumeByStayDateData;
      case 'booked_date':
        return this.volumeByBookingDateData;
      default:
        return this.volumeChartData;
    }
  }

  @computed(
    'args.{timeframe,timeranges,useTimeRanges,displayBy,statsByTimeframe,compareStatsByTimeframe}'
  )
  get volumeChartData() {
    const {
      timeframe,
      timeranges,
      displayBy,
      timeframeCache,
      isChartVisible,
      onChartVisibilityChange,
      statsByTimeframe,
      compareStatsByTimeframe,
    } = this.args;
    return chunk(
      {
        chartName: this.intl.t('common.revenue'),
        timeframe: timeframe,
        timeranges: timeranges,
        displayBy: displayBy,
        series: statsByTimeframe,
        future: true,
        mapFunction: row => round(row.volume),
        yearAgoMapFunction: row => round(row.yearAgoVolume),
        twoYearsAgoMapFunction: row => round(row.twoYearsAgoVolume),
        threeYearsAgoMapFunction: row => round(row.threeYearsAgoVolume),
        timeframeCache: timeframeCache,
        isChartVisible: isChartVisible,
        onChartVisibilityChange: onChartVisibilityChange,
        marketGroupName: this.intl.t('insights.marketGroupName'),
      },
      {
        compareToSeries: compareStatsByTimeframe,
        compareToMapFunction: row => round(row.volume),
        compareToYearAgoMapFunction: row => round(row.yearAgoVolume),
        compareToTwoYearsAgoMapFunction: row => round(row.twoYearsAgoVolume),
        compareToThreeYearsAgoMapFunction: row => round(row.threeYearsAgoVolume),
      },
      this.args.useTimeRanges
    );
  }

  @computed('args.{timeframe,timeranges,useTimeRanges,displayBy,statsByTimeframe}')
  get volumeByStayDateData() {
    const {
      timeframe,
      timeranges,
      displayBy,
      timeframeCache,
      isChartVisible,
      onChartVisibilityChange,
      statsByTimeframe,
    } = this.args;

    return chunk(
      {
        chartName: this.intl.t('insights.stats.volStayDate'),
        timeframe: timeframe,
        timeranges: timeranges,
        displayBy: displayBy,
        series: statsByTimeframe,
        future: true,
        mapFunction: row => round(row.volumeByCheckinDate),
        timeframeCache: timeframeCache,
        isChartVisible: isChartVisible,
        onChartVisibilityChange: onChartVisibilityChange,
        marketGroupName: this.intl.t('insights.marketGroupName'),
      },
      {},
      this.args.useTimeRanges
    );
  }

  @computed('args.{timeframe,timeranges,useTimeRanges,displayBy,statsByTimeframe}')
  get volumeByBookingDateData() {
    const {
      timeframe,
      timeranges,
      displayBy,
      timeframeCache,
      isChartVisible,
      onChartVisibilityChange,
      statsByTimeframe,
    } = this.args;

    return chunk(
      {
        chartName: this.intl.t('insights.stats.volBook'),
        timeframe: timeframe,
        timeranges: timeranges,
        displayBy: displayBy,
        series: statsByTimeframe,
        future: false,
        mapFunction: row => round(row.volumeByBookedAt),
        timeframeCache: timeframeCache,
        isChartVisible: isChartVisible,
        onChartVisibilityChange: onChartVisibilityChange,
        marketGroupName: this.intl.t('insights.marketGroupName'),
      },
      {},
      this.args.useTimeRanges
    );
  }
}
