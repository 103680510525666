import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import EmberObject, { action } from '@ember/object';
import { schedule } from '@ember/runloop';

@classic
export default class SearchListingRoute extends AuthRoute {
  templateName = 'search-listing';
  controllerName = 'search-listing';

  setupController(controller, model) {
    controller.set('model', model);
    return controller;
  }

  model(params) {
    let model = EmberObject.create({});
    model.set('listing', this.bpStore.peekRecord('listing', parseInt(params.id)));
    model.set('hideRightPanel', false);
    return model;
  }

  activate() {
    super.activate();
    schedule('afterRender', this, function () {
      this.send('moveDetailPageToLeft');
    });
  }

  @action
  moveDetailPageToLeft() {
    if (!localStorage.getItem('lastSearchParamsCache')) {
      this.transitionTo('search');
    }
  }
}
