import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Controller from '@ember/controller';
import moment from 'moment';
import { CurrencyUtil } from 'appkit/lib/currency';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';

function momentFormatMonthYear(_moment, locale) {
  if (!_moment._isAMomentObject) {
    _moment = moment.utc(_moment);
  }
  let options = {
    month: 'short',
    year: 'numeric',
  };
  return new Intl.DateTimeFormat(locale, options).format(
    new Date(_moment.locale('en').format('MMM DD YYYY'))
  );
}
function momentFormatDayMonthYear(_moment, locale) {
  let options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  return new Intl.DateTimeFormat(locale, options).format(
    new Date(_moment.locale('en').format('MMM DD YYYY'))
  );
}
function momentFormatMonth(_moment, locale) {
  let options = {
    month: 'long',
  };
  return new Intl.DateTimeFormat(locale, options).format(
    new Date(_moment.locale('en').format('MMM DD YYYY'))
  );
}

function bedroomsString(bedrooms, roomType) {
  if (roomType === 'Shared room' || roomType === 'Private room') {
    return 'Rooms';
  } else if (bedrooms > 10) {
    return '11+BD';
  } else {
    return bedrooms + 'BD';
  }
}

function averageValue(data, precision) {
  let values = data.map(row => row[1]);
  let average = values.reduce((a, b) => a + b, 0) / values.length;
  let factor = Math.pow(10, precision);
  return Math.round(average * factor) / factor;
}

function chartOptions(title, yTitle) {
  return {
    title: {
      text: title,
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Date',
      },
      labels: {
        style: {
          font: '14px Europa',
        },
      },
    },
    yAxis: {
      labels: {},
      title: {
        text: yTitle,
      },
    },
    plotOptions: {
      series: {
        showInNavigator: true,
      },
    },
    tooltip: {
      xDateFormat: '%L',
      valueDecimals: 2,
      shared: true,
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
  };
}

function getOccupancyByMonthPerYear(yearOccupancy) {
  // Group by month and calculate average occupancy
  let currentMonth;
  let monthlyGroups = [];

  yearOccupancy.forEach(row => {
    let parsed = moment.utc(row[0]);
    if (currentMonth != parsed.month()) {
      monthlyGroups.push([]);
    }
    monthlyGroups[monthlyGroups.length - 1].push(row);
    currentMonth = parsed.month();
  });

  let occupancyByMonth = [];
  monthlyGroups.forEach(month => {
    let average = averageValue(month, 2);
    occupancyByMonth = occupancyByMonth.concat(month.map(row => [row[0], average]));
  });

  return occupancyByMonth;
}

@classic
export default class MarketDataController extends Controller {
  @service intl;
  @controller('listing') listingController;

  @computed
  get occupancyChartOptions() {
    const locale = this.intl.locale;
    const options = chartOptions(
      this.intl.t('pricing.listing.neighborhoodOccupancy'),
      this.intl.t('pricing.listing.occupancy')
    );
    options.yAxis.max = 100;
    options.xAxis.labels.formatter = function () {
      // Display xAxis labels as months for all charts
      const _moment = moment.utc(this.value);
      return momentFormatMonth(_moment, locale);
    };
    options.tooltip.formatter = function () {
      // Format the X-axis header - strip all year info
      const pointName = moment.utc(this.points[0].x);
      const headerLabel = momentFormatMonth(pointName, locale);

      // Format the tooltip
      const pointLabels = (this.points || []).map(point => {
        let pointLabel;

        if (point.series.userOptions._pointLabel) {
          pointLabel = point.series.userOptions._pointLabel(point);
        } else {
          const offset = point.series.userOptions._offset || 0;
          pointLabel = momentFormatDayMonthYear(
            moment.utc(point.x).add(offset, 'years')
          );
        }

        return `
            <br/>
            <span style="color:${point.series.color}">●</span>
            <span class="capitalize">${pointLabel}:</span> <b>${point.y}%</b>`;
      });

      // The first returned item is the header, subsequent items are the
      // points
      return [
        `<span class="capitalize font-bold">${headerLabel}</span>`,
        ...pointLabels,
      ];
    };
    return options;
  }

  hotelChartOptions = chartOptions(
    this.intl.t('pricing.listing.hotelPricingUpcomingYear'),
    this.intl.t('pricing.listing.price')
  );

  pricingChartOptions = chartOptions(
    this.intl.t('pricing.listing.neighborhoodPricing'),
    this.intl.t('pricing.listing.price')
  );

  @computed('model.pastOccupancy')
  get occupancyChartData() {
    let futureOccupancy = this.model.futureOccupancy.map(row => [
      moment.utc(row[0]).subtract(1, 'year').valueOf(),
      row[1],
    ]);
    let twoYearsAgoOccupancy = this.model.pastOccupancy['twoYearsAgo'].map(row => [
      moment.utc(row[0]).add(1, 'year').valueOf(),
      row[1],
    ]);
    let pastYearOccupancy = this.model.pastOccupancy['pastYear'];

    let twoYearsAgoOccupancyByMonth = getOccupancyByMonthPerYear(twoYearsAgoOccupancy);
    let pastYearOccupancyByMonth = getOccupancyByMonthPerYear(pastYearOccupancy);

    let averageLabel = this.intl.t('common.average');

    return [
      {
        name: this.intl.t('pricing.listing.2YearsAgoOccupancy'),
        color: 'rgba(176, 49, 199, 0.6)',
        data: twoYearsAgoOccupancy,
        _offset: -1,
      },
      {
        name: this.intl.t('pricing.listing.pastYearOccupancy'),
        color: 'rgba(30, 202, 211, 0.7)',
        data: pastYearOccupancy,
      },
      {
        name: this.intl.t('pricing.listing.futureOccupancy'),
        color: 'rgba(50, 107, 255, 0.6)',
        data: futureOccupancy,
        _offset: 1,
      },
      {
        name: this.intl.t('pricing.listing.average2YearsAgoOccupancy'),
        color: 'rgba(149, 133, 255, 0.6)',
        data: twoYearsAgoOccupancyByMonth,
        visible: false,
        _pointLabel(indicator) {
          let month = momentFormatMonthYear(moment.utc(indicator.x).add(-1, 'years'));
          return `${month} ${averageLabel}`;
        },
      },
      {
        name: this.intl.t('pricing.listing.averagePastYearOccupancy'),
        color: 'rgba(255, 94, 178, 0.6)',
        data: pastYearOccupancyByMonth,
        visible: false,
        _pointLabel(indicator) {
          let month = momentFormatMonthYear(indicator.x);
          return `${month} ${averageLabel}`;
        },
      },
    ];
  }

  @computed(
    'model.{pricingAirbnb,pricingHomeaway}',
    'listing.{bedrooms,enabled,currency,roomType,calendar.[]}'
  )
  get pricingChartData() {
    let bedrooms = bedroomsString(
      this.get('listing.bedrooms'),
      this.get('listing.roomType')
    );
    let enabled = this.get('listing.enabled');
    let currency = this.get('listing.currency');
    let airbnbData = this.get('model.pricingAirbnb').map(row => [
      moment(row[0]).valueOf(),
      row[1],
    ]);
    let averageAirbnb = averageValue(airbnbData, 0);
    let averageAirbnbData = airbnbData.map(row => [row[0], averageAirbnb]);

    let airbnbPricingLabel = this.intl.t('pricing.listing.channelPricing', {
      channel: 'Airbnb',
    });
    let airbnbAveragePricingLabel = this.intl.t(
      'pricing.listing.channelAveragePricing',
      {
        channel: 'Airbnb',
      }
    );

    let vrboPricingLabel = this.intl.t('pricing.listing.channelPricing', {
      channel: 'VRBO',
    });
    let vrboAveragePricingLabel = this.intl.t('pricing.listing.channelAveragePricing', {
      channel: 'VRBO',
    });

    const s = CurrencyUtil.getSymbol(currency);

    let out = [
      {
        name: `${airbnbPricingLabel} - ${bedrooms}`,
        color: '#ff385c',
        data: airbnbData,
        tooltip: {
          valuePrefix: s.isPrefixed ? s.symbol : '',
          valueSuffix: !s.isPrefixed ? s.symbol : '',
        },
      },
      {
        name: `${airbnbAveragePricingLabel} - ${bedrooms}`,
        color: '#ff385c7d',
        data: averageAirbnbData,
        visible: false,
        tooltip: {
          valuePrefix: s.isPrefixed ? s.symbol : '',
          valueSuffix: !s.isPrefixed ? s.symbol : '',
        },
      },
    ];

    if (bedrooms !== 'Rooms') {
      let homeawayData = this.get('model.pricingHomeaway').map(row => [
        moment(row[0]).valueOf(),
        row[1],
      ]);
      let averageHomeaway = averageValue(homeawayData, 0);
      let averageHomeawayData = homeawayData.map(row => [row[0], averageHomeaway]);
      out.push({
        name: `${vrboPricingLabel} - ${bedrooms}`,
        color: '#285dbd',
        data: homeawayData,
        tooltip: {
          valuePrefix: s.isPrefixed ? s.symbol : '',
          valueSuffix: !s.isPrefixed ? s.symbol : '',
        },
      });
      out.push({
        name: `${vrboAveragePricingLabel} - ${bedrooms}`,
        color: '#285dbd96',
        data: averageHomeawayData,
        visible: false,
        tooltip: {
          valuePrefix: s.isPrefixed ? s.symbol : '',
          valueSuffix: !s.isPrefixed ? s.symbol : '',
        },
      });
    }

    out.push({
      name: this.intl.t('pricing.listing.yourPrice'),
      color: 'rgba(30, 202, 211, 0.6)',
      data: this.get('listing.calendar')
        .filter(row => {
          return (
            moment() <= moment(row.get('date')) &&
            moment(row.get('date')) < moment().add(365, 'd')
          );
        })
        .map(row => [
          moment(row.get('date')).valueOf(),
          enabled ? row.get('priceUserOrModeled') : row.get('priceScraped'),
        ]),
      tooltip: {
        valueDecimals: 0,
        valuePrefix: s.isPrefixed ? s.symbol : '',
        valueSuffix: !s.isPrefixed ? s.symbol : '',
      },
    });

    return out;
  }

  @computed('model.hotelPrices')
  get hotelChartData() {
    let currency = this.get('listing.currency');
    const s = CurrencyUtil.getSymbol(currency);
    return [
      {
        name: this.intl.t('pricing.listing.hotelPrices'),
        color: '#666666',
        data: this.get('model.hotelPrices'),
        tooltip: {
          valuePrefix: s.isPrefixed ? s.symbol : '',
          valueSuffix: !s.isPrefixed ? s.symbol : '',
        },
      },
    ];
  }
}
