import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @href}}\n  <a ...attributes href={{@href}} {{on 'click' this.onClick}}>\n    {{yield}}\n  </a>\n{{else}}\n  <button type='button' ...attributes {{on 'click' this.onClick}}>\n    {{yield}}\n  </button>\n{{/if}}", {"contents":"{{#if @href}}\n  <a ...attributes href={{@href}} {{on 'click' this.onClick}}>\n    {{yield}}\n  </a>\n{{else}}\n  <button type='button' ...attributes {{on 'click' this.onClick}}>\n    {{yield}}\n  </button>\n{{/if}}","moduleName":"appkit/components/bp/button/base.hbs","parseOptions":{"srcName":"appkit/components/bp/button/base.hbs"}});
import Component from '@glimmer/component';

const noOp = () => {};

export default class BpButtonBaseComponent extends Component {
  get onClick() {
    return this.args.onClick ?? noOp;
  }
}
