import BaseModel from 'appkit/bp-models/base_model';
import { computed } from '@ember/object';
import moment from 'moment';

export const STATUS_MAPPING = {
  SUGGESTED: 'suggested',
  SUGGESTION_COMPLETED: 'suggestion_completed',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  CANCELED: 'canceled',
};

export default BaseModel.extend({
  // default
  id: null,
  bookingReview: null,
  bookingReviewId: null,
  createdAt: null,
  listing: null,
  submitterNotes: null,
  bookingAnalysis: null,
  recommendations: null,
  _suggestedBasePrice: null,
  _suggestedMinPrice: null,
  approvedAt: null,
  approverNotes: null,
  status: null,
  initialHealthScore: null,
  initialStatistics: null,
  _initialBasePrice: null,
  _initialMinPrice: null,
  _finalBasePrice: null,
  _finalMinPrice: null,

  init() {
    this._super(arguments);
    this._EDITABLE = [
      'suggestedBasePrice',
      'suggestedMinPrice',
      'bookingAnalysis',
      'recommendations',
    ];
  },

  suggestedBasePrice: computed('_suggestedBasePrice', {
    get() {
      return Number(this._suggestedBasePrice) || null;
    },
    set(key, value) {
      this.set('_suggestedBasePrice', Number(value) || null);
      return this._suggestedBasePrice;
    },
  }),

  suggestedMinPrice: computed('_suggestedMinPrice', {
    get() {
      return Number(this._suggestedMinPrice) || null;
    },
    set(key, value) {
      this.set('_suggestedMinPrice', Number(value) || null);
      return this._suggestedMinPrice;
    },
  }),

  initialBasePrice: computed('_initialBasePrice', {
    get() {
      return Number(this._initialBasePrice) || null;
    },
    set(key, value) {
      this.set('_initialBasePrice', Number(value) || null);
      return this._initialBasePrice;
    },
  }),

  initialMinPrice: computed('_initialMinPrice', {
    get() {
      return Number(this._initialMinPrice) || null;
    },
    set(key, value) {
      this.set('_initialMinPrice', Number(value) || null);
      return this._initialMinPrice;
    },
  }),

  finalBasePrice: computed('_finalBasePrice', {
    get() {
      return Number(this._finalBasePrice) || null;
    },
    set(key, value) {
      this.set('_finalBasePrice', Number(value) || null);
      return this._finalBasePrice;
    },
  }),

  finalMinPrice: computed('_finalMinPrice', {
    get() {
      return Number(this._finalMinPrice) || null;
    },
    set(key, value) {
      this.set('_finalMinPrice', Number(value) || null);
      return this._finalMinPrice;
    },
  }),

  // custom
  createdAtMoment: computed('createdAt', function () {
    if (!this.createdAt) return;
    return moment(this.createdAt).calendar();
  }),

  hasPendingSuggestion: computed('status', function () {
    return this.status === STATUS_MAPPING.SUGGESTED;
  }),

  isSuggestionCompleted: computed('status', function () {
    return this.status === STATUS_MAPPING.SUGGESTION_COMPLETED;
  }),

  isApproved: computed('status', function () {
    return this.status === STATUS_MAPPING.APPROVED;
  }),

  isAlreadyReviewed: computed('status', function () {
    return (
      this.status === STATUS_MAPPING.APPROVED || this.status === STATUS_MAPPING.REJECTED
    );
  }),

  suggestedBasePricePercentage: computed(
    'suggestedBasePrice',
    'initialBasePrice',
    function () {
      return (
        Math.ceil(
          ((this.suggestedBasePrice / this.initialBasePrice) * 100 - 100) * 100
        ) / 100
      );
    }
  ),

  suggestedMinPricePercentage: computed(
    'suggestedMinPrice',
    'initialMinPrice',
    function () {
      return (
        Math.ceil(((this.suggestedMinPrice / this.initialMinPrice) * 100 - 100) * 100) /
        100
      );
    }
  ),

  finalBasePricePercentage: computed('finalBasePrice', 'initialBasePrice', function () {
    return Math.round((this.finalBasePrice / this.initialBasePrice) * 100 - 100);
  }),

  finalMinPricePercentage: computed('finalMinPrice', 'initialMinPrice', function () {
    return Math.round((this.finalMinPrice / this.initialMinPrice) * 100 - 100);
  }),

  percentageForBasePriceDirection: computed(
    'suggestedBasePricePercentage',
    function () {
      if (this.suggestedBasePricePercentage === 1) {
        return '';
      } else if (this.suggestedBasePricePercentage > 1) {
        return 'increased';
      } else if (this.suggestedBasePricePercentage < 1) {
        return 'decreased';
      } else {
        return '';
      }
    }
  ),

  percentageForMinPriceDirection: computed('suggestedMinPricePercentage', function () {
    if (this.suggestedMinPricePercentage === 1) {
      return '';
    } else if (this.suggestedMinPricePercentage > 1) {
      return 'increased';
    } else if (this.suggestedMinPricePercentage < 1) {
      return 'decreased';
    } else {
      return '';
    }
  }),
});
