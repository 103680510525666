import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
// eslint-disable-next-line ember/no-mixins
import LoadableModel from 'ember-data-storefront/mixins/loadable-model';
export default class UserModel extends Model.extend(LoadableModel) {
  @hasMany('account', { async: false }) accounts;
  @hasMany('listing', { async: false }) listings;
  @belongsTo('credential', { async: false }) credential;
  @hasMany('credentials', { async: false }) credentials;
  @hasMany('statement', { async: false }) statements;

  @attr billing;
  @attr('string', { defaultValue: 'USD ' }) billingCurrency;
  @attr('number') billingPercentage;
  @attr('number') defaultPaymentMethod;

  @attr('boolean') isBillingEnabled;
  @attr('boolean') isCheckoutDaysEnabled;
  @attr('boolean') isRelayEnabled;
  @attr('boolean') isRunningBalanceEnabled;
  @attr('boolean') isSkipTrialInformationEnabled;
  @attr('boolean') isStaff;
  @attr('boolean') isBlocked;
  @attr('boolean') hasEnabledListings;

  @attr('string') email;
  @attr('moment') firstEnabledAt;
  @attr('string') firstName;
  @attr('string') password;
  @attr('string') confirmation;
  @attr('string') status;
  @attr('string') referrerCode;
  @attr('string') runningBalance;
  @attr('string') ldKey;
  @attr('string') ldSecureHash;
  @attr('array') channelsBetaEnabled;
  @attr('array') accountErrors;
  @attr('array') canEditMarkupAccountIds;

  @attr('array') statements;
  @attr('array') periods;

  @attr('number') primaryCardId;
  @attr('array', { defaultValue: () => [] }) cards;
  @attr('array', { defaultValue: () => [] }) errors;
  @attr('array', { defaultValue: () => [] }) referrals;

  @attr('moment') trialStartDate;
  @attr('moment') trialEndDate;
  @attr('moment') firstEnabledAt;
  @attr('moment') pricingActivatedAt;
  @attr('moment') insightsActivatedAt;
  @attr('moment') projectionsActivatedAt;
  @attr('moment') firstListingMergedAt;

  @attr('string') sfAccountId;
  @attr('string') sfAccountManager;
  @attr('string') sfAccountCustomerSuccessManager;
  @attr('string') signalCustomerId;
}
