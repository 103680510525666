//Utility functions for Ember-Data models
import { isArray } from '@ember/array';
import { pluralize } from 'ember-inflector';

// A serializeAndPush function based on ember-api-actions serializeAndPush
// https://github.com/mike-north/ember-api-actions/blob/master/addon/utils/serialize-and-push.ts
// Useful in the after hook of ember-api-actions
export function serializeAndPush(response) {
  const store = this.store;
  const recordClass = this._internalModel;
  const modelName = pluralize(recordClass.modelName);
  const serializer = this.store.serializerFor(modelName);

  let normalized;

  if (isArray(response[modelName])) {
    const doc = response;
    normalized = serializer.normalizeArrayResponse(
      this.store,
      recordClass,
      doc,
      null,
      'findAll'
    );
  } else {
    const doc = response;
    normalized = serializer.normalizeSingleResponse(
      store,
      recordClass,
      doc,
      `${doc?.[modelName]?.id || '(unknown)'}`,
      'findRecord'
    );
  }

  return store.push(normalized);
}
