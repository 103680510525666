import Model, { attr, belongsTo } from '@ember-data/model';
import { ChannelUtil } from 'appkit/lib/channel';
import { CHANNEL } from 'appkit/lib/channel_configuration';

export default class ChannelListingModel extends Model {
  @belongsTo('account') account;
  @attr masterListing;

  @attr channel;
  @attr channelId;
  @attr channelIdSecondary;
  @attr channelUrl;

  @attr('number', { defaultValue: 0 }) basePrice;
  @attr image;
  @attr('moment') lastUpdated;
  @attr('moment') pricedAt;
  @attr('number', { defaultValue: 0 }) minPrice;
  @attr('boolean', { defaultValue: true }) listed;
  @attr('boolean', { defaultValue: false }) notify;
  @attr('boolean', { defaultValue: false }) syncing;

  @attr('array') nearbyListings;
  @attr('array') calendar;

  @attr title;
  @attr('string', { defaultValue: 'USD' }) currency;
  @attr('string') markup;
  @attr stats;

  get channelLabel() {
    return ChannelUtil.getLabel(this.channel);
  }

  get activateUrl() {
    let channel = this.channel;
    let url = null;
    if (channel === CHANNEL.AIRBNB) {
      let id = this.channelId;
      url = `https://www.airbnb.com/manage-listing/${id}/calendar`;
    }
    return url;
  }

  get pricesDirty() {
    const changed = this.changedAttributes();

    if (changed.basePrice) {
      return true;
    }

    if (changed.minPrice) {
      return true;
    }

    return false;
  }

  get imageOrDefault() {
    return this.image || 'assets/home-blue.svg';
  }

  get appliedMarkup() {
    return this.markup ?? this.account.markup;
  }
}
