import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';

import logger from 'appkit/lib/logger';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

@classic
export default class PopulateAccountRoute extends AuthRoute {
  @tracked syncingStatus = 0;
  @tracked syncingIsDone = false;
  @tracked collectedLogs = [];

  @service() featureFlag;
  isSelfServedOnboardingEnabled = false;

  async model(params) {
    let out = EmberObject.create({
      status: {
        progress: this.syncingStatus,
        message: 'Starting account listings sync.',
        syncingIsDone: false,
        logs: this.collectedLogs,
      },
    });
    let skipSteps = 0;
    let accountId = params.id;
    // Sync the account with force=True to make sure it is scraped
    let url = `/api/accounts/${accountId}/sync/force`;

    this.ajax
      .stream(url, {}, data => {
        if (data.message) {
          out.set('status', {
            progress: this.syncingStatus,
            message: `${data.message}...`,
            syncingIsDone: false,
            logs: this.collectedLogs,
          });
        }
        if (skipSteps >= 2) {
          this.syncingStatus++;
          this.collectedLogs.push(data.message);
        } else {
          skipSteps++;
        }
      })
      .then(
        () => {
          logger.info('Promise stream done', arguments);
          out.set('status', {
            ...out.status,
            message: 'Account listings sync is finished.',
            syncingIsDone: true,
          });

          let applicationInstance = getOwner(this);

          let dashboardRoute = applicationInstance.lookup('route:dashboard');
          let triggerToOnboarding = false;

          applicationInstance.lookup(
            `controller:dashboard.connect-prompt`
          ).firstAccountAlreadyConnected = true;

          if (
            dashboardRoute.currentModel.accounts.length < 1 &&
            this.isSelfServedOnboardingEnabled
          ) {
            triggerToOnboarding = true;
          }

          setTimeout(() => {
            dashboardRoute.refresh();

            this.replaceWith('dashboard');

            if (triggerToOnboarding) {
              this.replaceWith('dashboard.onboarding.intro');
            }
          }, 2000);
        },
        err => {
          this.syncingIsDone = true;
          logger.error('Promise stream error', err);
          if (err.constructor === ProgressEvent) {
            this.render('error.timeout', { into: 'application' });
          } else {
            this.render('error.server', { into: 'application' });
          }
        }
      );
    return out;
  }
}
