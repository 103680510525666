import { on } from '@ember/object/evented';
import { alias } from '@ember/object/computed';
import EmberObject from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';

// eslint-disable-next-line ember/no-classic-classes
const Errors = EmberObject.extend({
  base: '',
  id: '',
  idSecondary: '',
  password: '',
  secondaryUsername: '',
  secondaryPassword: '',
  success: '',

  clear() {
    this.setProperties({
      base: '',
      id: '',
      idSecondary: '',
      password: '',
      secondaryUsername: '',
      secondaryPassword: '',
      success: '',
    });
  },
});

export default BaseModel.extend({
  id: '',
  email: alias('id'),
  idSecondary: '',
  password: '',
  referralCode: '',
  secondaryUsername: '',
  secondaryPassword: '',
  pulseToken: '',
  deviceId: '',

  _initErrors: on('init', function () {
    this.set('errors', Errors.create({}));
  }),

  init() {
    this._super(arguments);
    this._EDITABLE = ['id', 'idSecondary', 'password', 'referralCode'];
  },
});
