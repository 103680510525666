import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield (hash chartOptions=this.chartOptions chartData=this.chartData)}}", {"contents":"{{yield (hash chartOptions=this.chartOptions chartData=this.chartData)}}","moduleName":"appkit/components/insights/charts/cancellations/container.hbs","parseOptions":{"srcName":"appkit/components/insights/charts/cancellations/container.hbs"}});
import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { chunk, chartOptions, round } from 'appkit/lib/insights/charts';
import { inject as service } from '@ember/service';

export default class InsightsChartsCancellationsContainer extends Component {
  @service intl;

  get type() {
    return this.args.type ?? 'canceled_date';
  }

  get chartOptions() {
    switch (this.type) {
      case 'stay_date':
        return this.cancellationsByStayDateChartOptions;
      case 'booked_date':
        return this.cancellationsByBookedDateChartOptions;
      default:
        return this.cancellationsByCanceledDateChartOptions;
    }
  }

  get cancellationsByStayDateChartOptions() {
    return chartOptions({
      locale: this.intl.locale,
      yTitle: this.intl.t('insights.stats.percCanceled'),
      tooltipOptionsFn: () => ({
        suffix: '%',
        details: 'stay days',
        timeframe: this.args.timeframe,
        displayBy: this.args.displayBy,
      }),
    });
  }

  get cancellationsByBookedDateChartOptions() {
    return chartOptions({
      locale: this.intl.locale,
      yTitle: this.intl.t('insights.stats.percCanceled'),
      tooltipOptionsFn: () => ({
        suffix: '%',
        details: 'reservations',
        timeframe: this.args.timeframe,
        displayBy: this.args.displayBy,
      }),
    });
  }

  get cancellationsByCanceledDateChartOptions() {
    return chartOptions({
      locale: this.intl.locale,
      yTitle: this.intl.t('insights.stats.numbCanceled'),
      tooltipOptionsFn: () => ({
        timeframe: this.args.timeframe,
        displayBy: this.args.displayBy,
      }),
    });
  }

  get chartData() {
    switch (this.type) {
      case 'stay_date':
        return this.cancellationsByStayDateChartData;
      case 'booked_date':
        return this.cancellationsByBookedDateChartData;
      default:
        return this.cancellationsByCanceledDateChartData;
    }
  }

  @computed(
    'args.{timeframe,timeranges,useTimeRanges,displayBy,statsByTimeframe,compareStatsByTimeframe}'
  )
  get cancellationsByStayDateChartData() {
    const {
      timeframe,
      timeranges,
      displayBy,
      timeframeCache,
      isChartVisible,
      onChartVisibilityChange,
      statsByTimeframe,
      compareStatsByTimeframe,
    } = this.args;

    return chunk(
      {
        chartName: this.intl.t('insights.stats.cancByStay'),
        timeframe: timeframe,
        timeranges: timeranges,
        displayBy: displayBy,
        series: statsByTimeframe,
        future: true,
        mapFunction: row => {
          if (!row.totalCountByStayDate) return 0;
          return round((100 * row.canceledCountByStayDate) / row.totalCountByStayDate);
        },
        timeframeCache: timeframeCache,
        isChartVisible: isChartVisible,
        onChartVisibilityChange: onChartVisibilityChange,
        // options: { tooltip_key: 'totalCountByStayDate' },
        marketGroupName: this.intl.t('insights.marketGroupName'),
      },
      {
        compareToSeries: compareStatsByTimeframe,
        compareToMapFunction: row => {
          if (!row.totalCountByStayDate) return 0;
          return round((100 * row.canceledCountByStayDate) / row.totalCountByStayDate);
        },
      },
      this.args.useTimeRanges
    );
  }

  @computed('args.{timeframe,timeranges,useTimeRanges,displayBy,statsByTimeframe}')
  get cancellationsByBookedDateChartData() {
    const {
      timeframe,
      timeranges,
      displayBy,
      timeframeCache,
      isChartVisible,
      onChartVisibilityChange,
      statsByTimeframe,
      compareStatsByTimeframe,
    } = this.args;

    return chunk(
      {
        chartName: this.intl.t('insights.stats.cancByBook'),
        timeframe: timeframe,
        timeranges: timeranges,
        displayBy: displayBy,
        series: statsByTimeframe,
        mapFunction: row => {
          if (!row.totalCountByBookedDate) return 0;
          return round(
            (100 * row.canceledCountByBookedDate) / row.totalCountByBookedDate
          );
        },
        timeframeCache: timeframeCache,
        isChartVisible: isChartVisible,
        onChartVisibilityChange: onChartVisibilityChange,
        // options: { tooltip_key: 'totalCountByBookedDate' },
        marketGroupName: this.intl.t('insights.marketGroupName'),
      },
      {
        compareToSeries: compareStatsByTimeframe,
        compareToMapFunction: row => {
          if (!row.totalCountByBookedDate) return 0;
          return round(
            (100 * row.canceledCountByBookedDate) / row.totalCountByBookedDate
          );
        },
      },
      this.args.useTimeRanges
    );
  }

  @computed('args.{timeframe,timeranges,useTimeRanges,displayBy,statsByTimeframe}')
  get cancellationsByCanceledDateChartData() {
    const {
      timeframe,
      timeranges,
      displayBy,
      timeframeCache,
      isChartVisible,
      onChartVisibilityChange,
      statsByTimeframe,
      compareStatsByTimeframe,
    } = this.args;

    return chunk(
      {
        chartName: this.intl.t('insights.stats.cancByCanc'),
        timeframe: timeframe,
        timeranges: timeranges,
        displayBy: displayBy,
        series: statsByTimeframe,
        mapFunction: row => row.canceledCountByCanceledDate,
        timeframeCache: timeframeCache,
        isChartVisible: isChartVisible,
        onChartVisibilityChange: onChartVisibilityChange,
        marketGroupName: this.intl.t('insights.marketGroupName'),
      },
      {
        compareToSeries: compareStatsByTimeframe,
        compareToMapFunction: row => row.canceledCountByCanceledDate,
      },
      this.args.useTimeRanges
    );
  }
}
