import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class MarketDataRoute extends AuthRoute {
  templateName = 'listing.market-data';
  controllerName = 'listing.market-data';

  setupController(controller, model) {
    controller.set('model', model);
    let listing = this.modelFor('preview').get('listing');
    controller.set('listing', listing);
  }

  model() {
    let listing = this.modelFor('preview').get('listing');
    let channel = listing.get('channel');
    let id = listing.get('channelId');
    let url = `/api/channellistings/preview/${channel}/${id}/market_data`;

    return this.ajax._get(url);
  }
}
