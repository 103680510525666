import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class SubpageRoute extends AuthRoute {
  model(params) {
    const listing = this.modelFor('dashboard.listing-redirect');
    const subpage = params.id;
    this.transitionTo(`dashboard.pricing.listing.${subpage}`, listing.id);
  }
}
