import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    currentQuestion=this.currentQuestion\n    currentQuestionIdx=this.currentQuestionIdx\n    currentAnswer=this.currentAnswer\n    surveyLength=this.surveyLength\n    answers=this.answers\n    currentAnswerOptionsAvailable=this.currentAnswerOptionsAvailable\n    actions=(hash\n      selectAnswer=this.selectAnswer\n      nextAnswer=this.nextAnswer\n      previousAnswer=this.previousAnswer\n    )\n  )\n}}", {"contents":"{{yield\n  (hash\n    currentQuestion=this.currentQuestion\n    currentQuestionIdx=this.currentQuestionIdx\n    currentAnswer=this.currentAnswer\n    surveyLength=this.surveyLength\n    answers=this.answers\n    currentAnswerOptionsAvailable=this.currentAnswerOptionsAvailable\n    actions=(hash\n      selectAnswer=this.selectAnswer\n      nextAnswer=this.nextAnswer\n      previousAnswer=this.previousAnswer\n    )\n  )\n}}","moduleName":"appkit/components/connect/survey-prompt.hbs","parseOptions":{"srcName":"appkit/components/connect/survey-prompt.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
export default class ConnectSurveyPromptComponent extends Component {
  @tracked currentQuestionIdx = 0;
  @tracked answers = {};
  @tracked surveyIsDispatched = false;

  get currentQuestion() {
    return this.args.survey[this.currentQuestionIdx];
  }

  get currentAnswerOptionsAvailable() {
    let dependantCondition = this.currentQuestion?.dependant ?? false;

    if (!dependantCondition) return this.currentQuestion.answers.map(a => a.value);

    let out = this.currentQuestion.answers.filter(
      answer =>
        !(
          this.currentQuestion.dependant.value.includes(
            this.answers[dependantCondition.question]?.value
          ) &&
          (answer.value === this.answers[dependantCondition.question].value ||
            dependantCondition.hide?.includes(answer.value))
        )
    );

    return out.map(a => a.value);
  }

  get surveyLength() {
    return this.args.survey.length - 1;
  }

  get currentAnswer() {
    return this.answers[this.currentQuestion.id];
  }

  @tracked checkForMultipleAnswers = false;

  @action nextAnswer() {
    if (this.currentQuestionIdx >= this.surveyLength) {
      if (!this.surveyIsDispatched) {
        this.args.onSurveyDispatch(this.answers);
        this.surveyIsDispached = true;
      }
      return;
    }
    this.currentQuestionIdx += 1;

    if (!(this.currentQuestion?.dependant && this.answers[this.currentQuestion.id]))
      return;

    let currentQuestion = this.currentQuestion;
    let dependant = this.currentQuestion.dependant;

    if (currentQuestion.type == 'multiple') {
      this.answers[currentQuestion.id] = this.answers[currentQuestion.id].filter(
        a =>
          !(
            dependant.value.includes(this.answers[dependant.question].value) &&
            a.value === this.answers[dependant.question].value
          )
      );
    } else {
      this.answers[currentQuestion.id] =
        dependant.value.includes(this.answers[dependant.question].value) &&
        (this.answers[currentQuestion.id].value ===
          this.answers[dependant.question].value ||
          dependant.hide.includes(this.answers[currentQuestion.id].value))
          ? undefined
          : this.answers[currentQuestion.id];
    }
  }

  @action previousAnswer() {
    this.currentQuestionIdx -= 1;
  }

  @action selectAnswer(answer) {
    if (this.currentQuestion.type == 'multiple') {
      if (!this.answers[this.currentQuestion.id]) {
        this.answers[this.currentQuestion.id] = [];
      } else {
        this.answers[this.currentQuestion.id] = [
          ...this.answers[this.currentQuestion.id],
        ];
      }

      if (this.currentAnswer?.includes(answer)) {
        this.answers = {
          ...this.answers,
          [this.currentQuestion.id]: this.currentAnswer.filter(
            a => a.value != answer.value
          ),
        };
      } else {
        this.answers = {
          ...this.answers,
          [this.currentQuestion.id]: [...this.currentAnswer, answer],
        };
      }
    } else {
      if (this.currentAnswer?.value == answer.value) {
        this.answers = { ...this.answers, [this.currentQuestion.id]: undefined };
      } else {
        this.answers = { ...this.answers, [this.currentQuestion.id]: answer };
      }
    }
  }
}
