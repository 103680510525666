import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { ReservationsIntegrationsAdapters } from 'appkit/lib/relay/adapters';

export default class RelayListingReservationsAPI extends Service {
  @service ajax;

  /**
   * Recovers data from api/listings, that includes the PMS reservation calendar
   **/
  @action
  async fetchListingDetails(masterListingId) {
    return await this.ajax._get(`/api/listings/${masterListingId}`);
  }

  /**
   * Recovers data from api/relay/reservations endpoint
   **/
  @action
  async fetchChannelListingReservations({ channelListingId, checkinDate, pageSize }) {
    const query = `&channel_listing_id=${channelListingId}&checkin_date__gt=${checkinDate}&page_size=${pageSize}&ordering=-pushed_reservation_at`;
    return this.ajax._get(`/api/relay/reservations?${query}`);
  }

  /**
   * Recovers PMS reservation data from api/relay/reservations endpoint by exact checkinDate
   **/
  @action
  async fetchPMSReservationDetails({ channelListingId, checkinDate, pageSize }) {
    const query = `&channel_listing_id=${channelListingId}&checkin_date=${checkinDate}&page_size=${pageSize}&ordering=-pushed_reservation_at`;
    return this.ajax._get(`/api/relay/reservations?${query}`);
  }

  /**
   * Recovers a concrete adapter for the api/relay/reservations endpoint that will transform scrapped integration
   * modeling to data used by the listing reservations view.
   *
   * @param {string} channelName
   * @param {string} fallbackAdapter = 'default'
   * @returns adapter
   */
  getIntegrationReservationsAdapter(channelName, fallbackAdapter = 'genericDC') {
    const viewAdapter = ReservationsIntegrationsAdapters[channelName];
    if (viewAdapter) return viewAdapter;

    const fallbackMsg = `No adapter found for channel ${channelName}, using ${fallbackAdapter} channel as fallback`;
    console.warn(fallbackMsg);
    return ReservationsIntegrationsAdapters[fallbackAdapter];
  }
}
