import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  {{did-insert this.updateRanges}}\n  {{did-update this.updateRanges @ranges}}\n>\n  {{yield\n    (hash\n      discountRanges=this.discountRanges\n      actions=(hash\n        onAddDiscount=this.onAddDiscount\n        onUpdateDiscountPercentage=this.onUpdateDiscountPercentage\n        onUpdateDiscountAction=this.onUpdateDiscountAction\n        onUpdateDiscountPeriodSetting=this.onUpdateDiscountPeriodSetting\n        onUpdateDiscountPeriod=this.onUpdateDiscountPeriod\n        onUpdateDiscountIgnoreInstructions=this.onUpdateDiscountIgnoreInstructions\n        onUpdateDiscountInput=this.onUpdateDiscountInput\n        onDeleteDiscount=this.onDeleteDiscount\n        onUpdateLastMinuteMinStayDirection=this.onUpdateLastMinuteMinStayDirection\n      )\n    )\n  }}\n</div>", {"contents":"<div\n  ...attributes\n  {{did-insert this.updateRanges}}\n  {{did-update this.updateRanges @ranges}}\n>\n  {{yield\n    (hash\n      discountRanges=this.discountRanges\n      actions=(hash\n        onAddDiscount=this.onAddDiscount\n        onUpdateDiscountPercentage=this.onUpdateDiscountPercentage\n        onUpdateDiscountAction=this.onUpdateDiscountAction\n        onUpdateDiscountPeriodSetting=this.onUpdateDiscountPeriodSetting\n        onUpdateDiscountPeriod=this.onUpdateDiscountPeriod\n        onUpdateDiscountIgnoreInstructions=this.onUpdateDiscountIgnoreInstructions\n        onUpdateDiscountInput=this.onUpdateDiscountInput\n        onDeleteDiscount=this.onDeleteDiscount\n        onUpdateLastMinuteMinStayDirection=this.onUpdateLastMinuteMinStayDirection\n      )\n    )\n  }}\n</div>","moduleName":"appkit/components/app-bulk-discounts.hbs","parseOptions":{"srcName":"appkit/components/app-bulk-discounts.hbs"}});
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import DiscountModel from 'appkit/bp-models/discount';
import OrphanDayModel from 'appkit/bp-models/orphan_day';
import { inject as service } from '@ember/service';

export default class AppBulkDiscountsContainer extends Component {
  @tracked discountRanges;
  @service intl;

  @action
  updateRanges() {
    this.discountRanges = [...this.args.ranges];
  }

  validate() {
    let isValid = true;
    const discounts = this.discountRanges
      .filter(d => d.days !== 0 && d.selectedDiscountAction !== null)
      .sort((da, db) => da.get('days') - db.get('days'));
    const errorMessage = this.intl.t(
      'validation.discountRangeError.discountsMustBeSetAtdifferentDays'
    );
    let isIn = false;

    if (discounts.length < this.discountRanges.length) {
      this.discountRanges.forEach(discount => {
        if (discount.selectedDiscountPeriodSetting && discount.days == 0) {
          set(
            discount,
            'error',
            this.intl.t(
              'validation.discountRangeError.discountLengthShouldBeGreaterthanZeroDays'
            )
          );
        } else if (
          discount.selectedDiscountPeriodSetting &&
          !discount.selectedDiscountAction
        ) {
          set(
            discount,
            'error',
            this.intl.t(
              'validation.discountRangeError.discountShouldhaveAnInstructionDefined'
            )
          );
        }
        return false;
      });
    }

    let countingDays = discounts.map(discount => discount.days);

    discounts.forEach(discount => {
      set(discount, 'error', '');

      if (countingDays.filter(d => d === discount.days).length > 1) {
        set(discount, 'error', errorMessage);
        isValid = false;
      }

      isIn = isIn || discount.direction === 'in';
      if (isIn && discount.direction !== 'in') {
        set(
          discount,
          'error',
          this.intl.t(
            'validation.discountRangeError.forTheNextDiscountsCantsucceedAfterDiscounts'
          )
        );
        isValid = false;
      }
      isValid = isValid && discount.validate();
    });

    return isValid;
  }

  @action
  onAddDiscount(direction) {
    let discountActions = [
      {
        action: 'increase',
        term: this.intl.t('pricing.bulkEdit.increasePriceBy'),
      },
      {
        action: 'decrease',
        term: this.intl.t('pricing.bulkEdit.decreasePriceBy'),
      },
    ];

    if (this.args.customizeRanges == 'orphanDays') {
      this.discountRanges.pushObject(
        OrphanDayModel.create({
          discountActions: discountActions,
          selectedDiscountAction: null,
        })
      );
    } else {
      this.discountRanges.pushObject(
        DiscountModel.create({
          discountActions: discountActions,
          discountPeriodSettings: [
            { action: 'out', term: this.intl.t('common.forTheNext') },
            { action: 'in', term: this.intl.t('common.after') },
          ],
          selectedDiscountAction: null,
          selectedDiscountPeriodSetting: null,
          direction: typeof direction == 'string' ? direction : 'out',
        })
      );
    }

    this.args.updateDiscounts(
      [...this.discountRanges],
      this.args.rangeRef,
      this.args.customizeRanges,
      this.validate()
    );
  }

  @action
  onUpdateDiscountAction(discount, selected) {
    set(discount, 'selectedDiscountAction', selected);
    set(discount, 'percentage', 0);

    this.onUpdateDiscountPercentage(discount, false);
    this.args.updateDiscounts(
      [...this.discountRanges],
      this.args.rangeRef,
      this.args.customizeRanges,
      this.validate()
    );
  }

  @action
  onUpdateDiscountPercentage(discount, evt) {
    let refValue = evt ? evt.target.valueAsNumber : Math.abs(discount.percentage * 100);

    if (['orphanDays'].includes(this.args.customizeRanges)) refValue *= -1;

    set(
      discount,
      'percentage',
      discount.selectedDiscountAction.action === 'decrease' || refValue === 0
        ? parseFloat((refValue * 0.01).toFixed(2))
        : parseFloat((refValue * -0.01).toFixed(2))
    );

    this.args.updateDiscounts(
      [...this.discountRanges],
      this.args.rangeRef,
      this.args.customizeRanges,
      this.validate()
    );
  }

  @action
  onUpdateDiscountPeriodSetting(discount, selected) {
    set(discount, 'selectedDiscountPeriodSetting', selected);
    set(discount, 'direction', selected.action);
    set(discount, 'days', 0);

    this.args.updateDiscounts(
      [...this.discountRanges],
      this.args.rangeRef,
      this.args.customizeRanges,
      this.validate()
    );
  }

  @action
  onUpdateDiscountPeriod(discount, evt) {
    set(discount, 'days', evt.target.valueAsNumber);

    this.args.updateDiscounts(
      [...this.discountRanges],
      this.args.rangeRef,
      this.args.customizeRanges,
      this.validate()
    );
  }

  @action
  onUpdateDiscountIgnoreInstructions(discount, field, value) {
    set(discount, field, value);

    this.args.updateDiscounts(
      [...this.discountRanges],
      this.args.rangeRef,
      this.args.customizeRanges,
      this.validate()
    );
  }

  @action
  onUpdateDiscountInput(discount, field, evt) {
    set(discount, field, Number(evt.target.value));

    this.args.updateDiscounts(
      [...this.discountRanges],
      this.args.rangeRef,
      this.args.customizeRanges,
      this.validate()
    );
  }

  @action
  onUpdateLastMinuteMinStayDirection(discount, field, evt) {
    set(discount, field, evt.target.value);
    this.args.updateDiscounts(
      [...this.discountRanges],
      this.args.rangeRef,
      this.args.customizeRanges,
      this.validate()
    );
  }

  @action
  onDeleteDiscount(index) {
    this.discountRanges.splice(index, 1);
    this.args.updateDiscounts(
      [...this.discountRanges],
      this.args.rangeRef,
      this.args.customizeRanges,
      this.validate()
    );
  }
}
