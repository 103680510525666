import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';
import { setRelayUserRole } from 'appkit/lib/relay/roles/rel-user-roles';
import { getRelayUserPermissions } from 'appkit/lib/relay/permissions/rel-user-permissions';
import { getRelayListingPermissions } from 'appkit/lib/relay/permissions/rel-listing-channel-permissions';
import { RelayMergedListingViewModelAdapter } from 'appkit/lib/relay/adapters';

export default class RelayListingDetailRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service featureFlag;
  featureFlags;

  title() {
    const title = `Relay / ${this.context.listing.name}`;
    this.globalNav.setTitle(title);
    return title;
  }

  async beforeModel() {
    this.featureFlags = await this.resolveFeatureFlags();
    this.guardRoute();
  }

  guardRoute() {
    if (!this.currentUser.isStaff) {
      console.warn('Route only accessible to Staff members. Redirecting to dashboard');
      this.transitionTo('dashboard.relay');
    }
    if (!this.featureFlags.flag_USER_LISTING_VIEW_ENABLED) {
      console.warn(
        'User is not allowed to enter this section. Add the target to the feature-flag.'
      );
      this.transitionTo('dashboard.relay');
    }
  }

  async resolveFeatureFlags() {
    const flag_USER_LISTING_VIEW_ENABLED = await this.featureFlag.evaluate(
      'relay-listing-view',
      false
    );
    const flag_USER_DYNAMIC_MARKUPS_ENABLED = await this.featureFlag.evaluate(
      'dynamic-markups',
      false
    );
    const flag_USER_CONTENT_SYNC_ENABLED = await this.featureFlag.evaluate(
      'relay-content-sync-enabled',
      false
    );
    const flag_USER_RESERVATION_LOG_ENABLED = await this.featureFlag.evaluate(
      'relay-listing-reservations',
      false
    );
    const flag_FEATURE_MOCKS_ENABLED = await this.featureFlag.evaluate(
      'relay-feature-mocks',
      false
    );

    return {
      flag_USER_LISTING_VIEW_ENABLED,
      flag_USER_DYNAMIC_MARKUPS_ENABLED,
      flag_USER_CONTENT_SYNC_ENABLED,
      flag_USER_RESERVATION_LOG_ENABLED,
      flag_FEATURE_MOCKS_ENABLED,
    };
  }

  /** Define a simpler model for the view, taking only the listing properties we use in Relay.
   * This is combined with the new permission layer (~user role and listing level) to have
   * a cleaner picture of what can be done.
   *
   * The model is directly accesible in the template using @model , and reused in child routes
   */
  model(routeParams) {
    const { master_listing_id } = routeParams;
    // Stored data: Already fetched ----------------------------------------------
    const masterListing = this.bpStore.peekRecord('listing', master_listing_id);
    const featureFlags = this.featureFlags;

    // need to do this more semantic, boolean arguments are confusing.
    const { user } = this.modelFor('dashboard'); // The user we are impersonating
    const userWithRelayRole = setRelayUserRole(user, true);
    const userPermissions = getRelayUserPermissions(userWithRelayRole, featureFlags);

    const listingPermissions = getRelayListingPermissions({
      listings: [masterListing],
      featureFlags,
    });

    const model = {
      userPermissions,
      listing: {
        ...RelayMergedListingViewModelAdapter(masterListing),
        ...listingPermissions,
      },
    };

    return model;
  }
}
