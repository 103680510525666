import { RELAY_USER_ROLE } from '../roles/rel-user-roles';

/**
 * Defines access to Relay features at atomic level, based on the Relay user role and feature flags.
 * This permissions rule over listing permissions.
 */
export function getRelayUserPermissions(user, featureFlags) {
  if (!user.relayRole) {
    throw new Error('Error defining User Permissions. No Relay user role found.');
  }
  try {
    switch (user.relayRole) {
      // The premium users should be the only ones having access to the newest targeted/public feature flags features
      case RELAY_USER_ROLE.Staff:
        return getRelayPremiumUserPermissions(featureFlags);
      case RELAY_USER_ROLE.Premium:
        return getRelayPremiumUserPermissions(featureFlags);
      case RELAY_USER_ROLE.InactiveUser:
        return getRelayPremiumUserPermissions(featureFlags);
      // case RELAY_USER_ROLE.Trial: (To be implemented) return getRelayTrialUserPermissions(user);
      case RELAY_USER_ROLE.Pioneer:
        return getRelayPioneerUserPermissions(user);
      case RELAY_USER_ROLE.NonPayUser:
        return getRelayNonPayUserPermissions();
    }
  } catch (e) {
    throw new Error(`Error mapping user permissions for role ${user.relayRole}`);
  }
}

/**
 * Pioneer users  (a.k.a. Relay grandfathered users, users with Relay enabled but no relay_enabled_date)
 *
 * Their permissions for old features ussage are based on historical usage.
 * If they were using certain features during the beta period, they should be able to keep using them.
 * Otherwise, the same rules as non-relay user apply. The Newest features shouldn't be available.
 */
function getRelayPioneerUserPermissions(user) {
  return {
    canMerge: true,
    canSetAvailabilitySync: false,
    canSetStaticMarkups: Boolean(user.hasUsedMarkups),
    canSetDynamicMarkups: false,
    canSetContentSync: false,
    canSetReservationSync: false,
  };
}

/**
 * Non-pay users have access to nothing.
 * A notification should appear to activate Relay
 */
function getRelayNonPayUserPermissions() {
  return {
    canMerge: false,
    canSetAvailabilitySync: false,
    canSetStaticMarkups: false,
    canSetDynamicMarkups: false,
    canSetReservationSync: false,
    canSetContentSync: false,
  };
}

/**
 * Premium users access have all settled features.
 * Aditionally, they may be the target for feature flag features.
 * Staff users replicate the same permissions that the user they are emulating
 */
function getRelayPremiumUserPermissions(featureFlags) {
  return {
    canMerge: true,
    canSetAvailabilitySync: true,
    canSetStaticMarkups: true,
    canSetReservationSync: true,
    //This is a good example: Atm, this flag is Public for everyone. But that doesn't mean all roles should have access to it.
    canSetDynamicMarkups: featureFlags.flag_USER_DYNAMIC_MARKUPS_ENABLED,
    canSetContentSync: featureFlags.flag_USER_CONTENT_SYNC_ENABLED,
    canViewReservationLog: featureFlags.flag_USER_RESERVATION_LOG_ENABLED,
    canViewListingDetailView: featureFlags.flag_USER_LISTING_VIEW_ENABLED,
    canViewAvailabilityCalendar: featureFlags.flag_FEATURE_MOCKS_ENABLED,
  };
}

/** PROPOSAL: NOT IMPLEMENTED
 *
 *  The model of user has trial_start_at and trial_end_date already
 *  In the future, we could mimic that approach to define which features would be enabled for this group.
 *
 *  A user that has trial expired, is considered in role/permissions as a non-paid user.
 */
export function getRelayTrialUserPermissions() {
  const trialActivePermissions = {
    canMerge: true,
    canSetStaticMarkups: true,
    canSetAvailabilitySync: true,
    canSetDynamicMarkups: false,
    canSetReservationSync: false,
    canSetContentSync: false,
  };

  return trialActivePermissions;
}
