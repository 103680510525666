// Constants used in the customize page. Customize constants are used in both the
// listing customize page and in the bulk-edit page

export const CHANGEOVER_DAYS = {
  Any: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
  Monday: ['monday'],
  Tuesday: ['tuesday'],
  Wednesday: ['wednesday'],
  Thursday: ['thursday'],
  Friday: ['friday'],
  Saturday: ['saturday'],
  Sunday: ['sunday'],
};
export const WEEK_DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
export const RANGE_DEFAULT = {
  checkinDays: WEEK_DAYS,
  checkoutDays: WEEK_DAYS,
  minStay: 1,
  maxStay: 1,
  minPrice: '',
  maxPrice: '',
  monthlyMinPrice: '',
  monthlyMaxPrice: '',
  priceOverridePercentage: 100,
  monthlyPriceOverridePercentage: 100,
  monthlyEnabled: true,
};

//Map the customize fields and ranges to the pane they appear on.
//That way we can know which panes to expand when there are errors.
export const FIELD_PANE_MAP = {
  minPrice: 'showMinMaxPricesContent',
  maxPrice: 'showMinMaxPricesContent',
  monthlyMinPrice: 'showMinMaxPricesContent',
  minMaxPriceRanges: 'showMinMaxPricesContent',
  minPriceRanges: 'showMinMaxPricesContent',
  extraGuestFees: 'showGuestFeesContent',
  extraGuestThreshold: 'showGuestFeesContent',
  discounts: 'showLastMinuteDiscountsContent',
  orphanDays: 'showOrphanDaysContent',
  minStay: 'showMinStayContent',
  maxStay: 'showMaxStayContent',
  minStayRanges: 'showMinStayContent',
  maxStayRanges: 'showMaxStayContent',
  checkinDays: 'showChangeoverContent',
  checkoutDays: 'showChangeoverContent',
  checkinCheckoutDaysRanges: 'showChangeoverContent',
  checkinDaysRanges: 'showChangeoverContent',
  changeoverDay: 'showChangeoverContent',
  changeoverDayRanges: 'showChangeoverContent',
  priceOverride: 'showPriceOverrideContent',
  priceOverrideRanges: 'showPriceOverrideContent',
  importCalendarUrls: 'showSyncCalendarsContent',
};

export const DEFAULT_DISCOUNTS = [
  { percentage: 0.25, days: 3, direction: 'out' },
  { percentage: 0.1, days: 7, direction: 'out' },
  { percentage: 0, days: 14, direction: 'out' },
  { percentage: 0, days: 21, direction: 'out' },
  { percentage: 0, days: 28, direction: 'out' },
  { percentage: -0.1, days: 270, direction: 'in' },
  { percentage: -0.3, days: 390, direction: 'in' },
];

export const DEFAULT_ORPHAN_DAYS = [
  { percentage: -0.25, days: 1, ignoreOnWeekdays: null },
  { percentage: -0.1, days: 2, ignoreOnWeekdays: ['friday'] },
];
