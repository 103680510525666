import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { CHANNEL } from '../../../../lib/channel_configuration';

export default class DashboardRelayConnectController extends Controller {
  @tracked selectedChannels = [
    { name: CHANNEL.AIRBNB, selected: false },
    { name: CHANNEL.VRBO, selected: false },
    { name: CHANNEL.BOOKING, selected: false },
  ];

  @action
  selectChannel(channel) {
    this.selectedChannels.find(ch => {
      if (ch.name === channel) {
        ch.selected = !ch.selected;
      }
    });
    this.selectedChannels = JSON.parse(JSON.stringify(this.selectedChannels));
  }

  get isActive() {
    return this.selectedChannels.find(ch => ch.selected);
  }

  @action
  goToFirstChannel() {
    const channel = this.selectedChannels.find(ch => ch.selected);
    const channels = this.selectedChannels.filter(
      ch => ch.selected && ch.name !== channel.name
    );

    if (channel.name === 'booking') channel.name = 'booking_connect';

    this.transitionToRoute('dashboard.relay.connect.channel', {
      channel_name: channel.name,
      selectedChannels: channels,
    });
  }
}
