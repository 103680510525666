import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ModalDialog\n  @containerClass='bg-white text-gray-800 border border-solid border-gray-200 rounded max-h-1/2 max-w-md w-full flex flex-col'\n  @onClose={{this.onCloseModal}}\n  @overlayClass='bg-gray-200 bg-opacity-50 z-50'\n>\n  <div class=''>\n    <h1 class='text-xl text-center px-8 py-6'>\n      {{t 'pricing.confirmChanges'}}\n    </h1>\n  </div>\n\n  <div class='p-6 text-center'>\n    <p class='text-gray-600 mb-2'>\n      {{this.bodyMessage}}\n    </p>\n  </div>\n\n  <div class='p-6 flex items-center justify-around'>\n    <Bp::Button\n      @color='gray'\n      @variant='outlined'\n      class='flex-1 mr-2 py-2'\n      @onClick={{this.onCloseModal}}\n    >\n      {{t 'common.no'}}\n    </Bp::Button>\n\n    <Bp::Button class='btn-blue flex-1 ml-2 py-2' @onClick={{this.onCofirmModal}}>\n      {{t 'common.yes'}}\n    </Bp::Button>\n  </div>\n</ModalDialog>", {"contents":"<ModalDialog\n  @containerClass='bg-white text-gray-800 border border-solid border-gray-200 rounded max-h-1/2 max-w-md w-full flex flex-col'\n  @onClose={{this.onCloseModal}}\n  @overlayClass='bg-gray-200 bg-opacity-50 z-50'\n>\n  <div class=''>\n    <h1 class='text-xl text-center px-8 py-6'>\n      {{t 'pricing.confirmChanges'}}\n    </h1>\n  </div>\n\n  <div class='p-6 text-center'>\n    <p class='text-gray-600 mb-2'>\n      {{this.bodyMessage}}\n    </p>\n  </div>\n\n  <div class='p-6 flex items-center justify-around'>\n    <Bp::Button\n      @color='gray'\n      @variant='outlined'\n      class='flex-1 mr-2 py-2'\n      @onClick={{this.onCloseModal}}\n    >\n      {{t 'common.no'}}\n    </Bp::Button>\n\n    <Bp::Button class='btn-blue flex-1 ml-2 py-2' @onClick={{this.onCofirmModal}}>\n      {{t 'common.yes'}}\n    </Bp::Button>\n  </div>\n</ModalDialog>","moduleName":"appkit/components/app-confirm-modal.hbs","parseOptions":{"srcName":"appkit/components/app-confirm-modal.hbs"}});
import Component from '@ember/component';

export default class AppConfirmModal extends Component {
  bodyMessage = '';

  constructor() {
    super(...arguments);
  }
}
