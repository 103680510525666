import { ChannelUtil } from 'appkit/lib/channel';

/**
 * Provides Channel Listings suggestions to Add to an existing Merged Listing
 */
export function getAddToExistingMergeSuggestions(masterListing, listings) {
  const mergeSuggestions = [];
  const unmergedChannelListings = listings.filter(isUnMergedListing).filter(isNotPMS);
  const mergeSuggestionsFilterCriterias = [hasSameTitle, hasSameAddress];

  for (const filterCriteria of mergeSuggestionsFilterCriterias) {
    const listings = unmergedChannelListings.filter(listing =>
      filterCriteria(masterListing, listing)
    );
    if (listings.length) {
      mergeSuggestions.push(...listings);
      break;
    }
  }
  return mergeSuggestions;
}
function isUnMergedListing(listing) {
  return listing.channelListingsIds.length < 2;
}
function isNotPMS(listing) {
  return !ChannelUtil.isPms(listing.channelListings[0].channel);
}
function hasSameTitle(listingA, listingB) {
  return listingA.cleanTitle === listingB.cleanTitle;
}
function hasSameAddress(listingA, listingB) {
  return listingA.cleanAddress === listingB.cleanAddress;
}
