import { VARIANTS, COLORS, baseTheme } from '../../../theme';
import { fontSizeClasses } from '../option/index.theme';
const { spacingClasses } = baseTheme;

const defaultClasses = () => [
  'w-full',
  'flex',
  'justify-between',
  '-mr-2',
  'overflow-hidden',
];

const variantClasses = () => ({
  [VARIANTS.ALL]: {
    [COLORS.ALL]: ['bg-gray-100'],
  },
});

const borders = [];

export default {
  root: {
    defaultClasses,
    fontSizeClasses,
    spacingClasses,
    variantClasses,
    borders,
  },
  button: {
    defaultClasses: () => ['w-full', ' mr-2'],
  },
};
