import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { copy } from '@ember/object/internals';
import moment from 'moment';
import EmberObject, { get, action, computed } from '@ember/object';
import { debounce } from '@ember/runloop';
import { FAKE_QUOTE_ENGINE_RESULT } from '../lib/fake_quote_engine_result';

@classic
export default class SearchController extends Controller {
  // -- Parameters ---------------------------------------------------------------------
  @service
  alert;
  @service
  intl;

  showDatePicker = false;
  bedroomOptions = [1, 2, 3, 4, 5];
  guestOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

  // -- Computed Properties ------------------------------------------------------------
  // TODO: could utilize list-control service instead

  /**
   * search
   */

  // @Array
  @alias('model.clustersOptions')
  clustersOptions;

  @computed('clustersOptions')
  get clustersIds() {
    return this.clustersOptions.map(option => `${option.name} — ${option.id}`);
  }

  // @Number
  @computed('lastSearchParamsCache')
  get selectedCluster() {
    return this.get('lastSearchParamsCache.selectedCluster');
  }

  // @Number
  @computed('lastSearchParamsCache')
  get selectedBedroom() {
    return this.get('lastSearchParamsCache.selectedBedroom');
  }

  // @Number
  @computed('lastSearchParamsCache')
  get selectedGuest() {
    return this.get('lastSearchParamsCache.selectedGuest');
  }

  // @Object {start: foo, end: foo}
  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed('lastSearchParamsCache')
  get range() {
    if (this._range) {
      return this._range;
    }
    // exit case
    if (!this.get('lastSearchParamsCache.range')) {
      return null;
    }

    const range = this.get('lastSearchParamsCache.range');
    range.start = moment(range.start);
    range.end = moment(range.end);
    return range;
  }

  set range(value) {
    return (this._range = value);
  }

  @computed('model.searchResult')
  get searchResult() {
    return this.get('model.searchResult') || [];
  }

  @computed('searchResult', 'bedroomsForInput', 'bathroomsForInput', 'priceForInput')
  get searchResultFiltered() {
    let out = this.searchResult;

    if (out.length) {
      // filter for bedrooms
      out = out.filter(
        l =>
          l.get('bedrooms') >= get(this, 'bedroomsForInput')[0] &&
          l.get('bedrooms') <= get(this, 'bedroomsForInput')[1]
      );

      // filter for bathrooms
      out = out.filter(
        l =>
          l.get('bathrooms') >= get(this, 'bathroomsForInput')[0] &&
          l.get('bathrooms') <= get(this, 'bathroomsForInput')[1]
      );

      // filter for base price
      out = out.filter(
        l =>
          l.get('price') >= get(this, 'priceForInput')[0] &&
          l.get('price') <= get(this, 'priceForInput')[1]
      );
    }

    return out;
  }

  // @type {Object}
  @computed('range')
  get cleanedRange() {
    if (!this.range) {
      return null;
    }

    const range = copy(this.range);
    // TODO: should do a locale check and display in their native date format for v2.
    if (range && range.start) {
      range.start = moment(range.start).format('MM/DD/YYYY');
    }
    if (range && range.end) {
      range.end = moment(range.end).format('MM/DD/YYYY');
    }
    return range;
  }

  // catch

  // @type {Object}

  @computed
  get lastSearchParamsCache() {
    return JSON.parse(localStorage.getItem('lastSearchParamsCache'));
  }

  set lastSearchParamsCache(value) {
    if (value) {
      localStorage.setItem('lastSearchParamsCache', JSON.stringify(value));
    } else {
      localStorage.removeItem('lastSearchParamsCache');
    }
    return JSON.parse(localStorage.getItem('lastSearchParamsCache'));
  }

  // @type {Boolean}
  @computed('lastSearchParamsCache')
  get hasLastSearchParamsCache() {
    return !!this.lastSearchParamsCache;
  }

  /**
   * filter
   */

  // bedrooms

  // @type {Number}
  @computed('searchResult')
  get maxListingsBedrooms() {
    const listings = this.searchResult;
    const listingsBedroomsArr = listings.map(listing => listing.bedrooms);
    return Math.max(...listingsBedroomsArr, 1);
  }

  // @type {Array}
  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed('maxListingsBedrooms')
  get bedroomsForInput() {
    if (this._bedroomsForInput) {
      return this._bedroomsForInput;
    }
    return [0, this.maxListingsBedrooms];
  }

  set bedroomsForInput(value) {
    return (this._bedroomsForInput = value);
  }

  // @type {Array}
  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed('maxListingsBedrooms')
  get bedroomsForSlider() {
    if (this._bedroomsForSlider) {
      return this._bedroomsForSlider;
    }
    return [0, this.maxListingsBedrooms];
  }

  set bedroomsForSlider(value) {
    return (this._bedroomsForSlider = value);
  }

  // @type {Object}
  @computed('bedroomsForSlider')
  get rangeBedrooms() {
    const maxBedrooms = this.bedroomsForSlider[1];
    return EmberObject.create({ min: [0], max: [maxBedrooms] });
  }

  // bathrooms

  // @type {Number}
  @computed('searchResult')
  get maxListingsBathrooms() {
    const listings = this.searchResult;
    const listingsBathroomsArr = listings.map(listing => listing.bathrooms);
    return Math.max(...listingsBathroomsArr, 1);
  }

  // @type {Array}
  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed('maxListingsBathrooms')
  get bathroomsForInput() {
    if (this._bedroomsForInput) {
      return this._bathroomsForInput;
    }
    return [0, this.maxListingsBathrooms];
  }

  set bathroomsForInput(value) {
    return (this._bathroomsForInput = value);
  }

  // @type {Array}
  @computed('maxListingsBathrooms')
  get bathroomsForSlider() {
    return [0, this.maxListingsBathrooms];
  }

  // @type {Object}
  @computed('bathroomsForSlider')
  get rangeBathrooms() {
    const maxBathrooms = this.bathroomsForSlider[1];
    return EmberObject.create({ min: [0], max: [maxBathrooms] });
  }

  // price

  // @type {Number}
  @computed('searchResult')
  get maxListingsPrice() {
    const listings = this.searchResult;
    const listingsPriceArr = listings.map(listing => listing.price);
    return Math.max(...listingsPriceArr, 1);
  }

  // @type {Array}
  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed('maxListingsPrice')
  get priceForInput() {
    if (this._priceForInput) {
      return this._priceForInput;
    }
    return [0, this.maxListingsPrice];
  }

  set priceForInput(value) {
    return (this._priceForInput = value);
  }

  // @type {Array}
  @computed('maxListingsPrice')
  get priceForSlider() {
    return [0, this.maxListingsPrice];
  }

  // @type {Object}
  @computed('priceForSlider')
  get rangePrice() {
    const maxPrice = this.priceForSlider[1];
    return EmberObject.create({ min: [0], max: [maxPrice] });
  }

  // -- Debounced methods --------------------------------------------------------------

  debouncedChangedBedrooms(value) {
    this.set('bedroomsForInput', value);
  }

  debouncedChangedBathrooms(value) {
    this.set('bathroomsForInput', value);
  }

  debouncedChangedPrice(value) {
    this.set('priceForInput', value);
  }

  /**
   * search
   */
  showRequiredError(i18nGlossaryKey) {
    const field = this.intl.t(`glossary.${i18nGlossaryKey}`);
    const msg = this.intl.t('validation.selectRequired', { entity: field });

    this.alert.error(msg, { timeout: 1000 });
  }

  @action
  async clickedSearch() {
    const range = this.range;
    const selectedCluster = this.selectedCluster;
    const selectedGuest = this.selectedGuest;

    if (!range) {
      this.showRequiredError('date');
      return;
    }
    if (!range.start) {
      this.showRequiredError('checkinDays');
      return;
    }
    if (!range.end) {
      this.showRequiredError('checkoutDays');
      return;
    }
    if (!selectedCluster) {
      this.showRequiredError('cluster');
      return;
    }
    // TODO: add guest alert once it's added to endpoint args

    // const checkin = moment(range.start).format('YYYY-MM-DD');
    // const checkout = moment(range.end).format('YYYY-MM-DD');
    // const longitude = this.get('clustersOptions').filter(co => co.id === parseInt(selectedCluster.split(' — ')[1]))[0].longitude;
    // const latitude = this.get('clustersOptions').filter(co => co.id === parseInt(selectedCluster.split(' — ')[1]))[0].latitude;
    // const ajaxParams = { checkin, checkout, longitude, latitude, selectedGuest };
    // let data;
    // try {
    //   data = await this.ajax._get(`/api/quote/search?startDate=${ajaxParams.checkin}&endDate=${ajaxParams.checkout}&lat=${ajaxParams.latitude}&lng=${ajaxParams.longitude}`);
    // } catch (error) {
    //   // TODO: switch to error.message once endpoint returns meaningful message
    //   alert.error(`status ${error[0].status}: ${error[0].title}`, {timeout: 10000});
    //   return;
    // }

    // FIXME: only for dev purpose
    let data = FAKE_QUOTE_ENGINE_RESULT;

    // FIXME: only for dev purpose
    // const checkin = '2019-03-01';
    // const checkout = '2019-03-07';
    // const latitude = '37.778718';
    // const longitude = '-122.412460';
    // const ajaxParams = { checkin, checkout, longitude, latitude, selectedGuest };
    // let data;
    // try {
    //   data = await this.ajax._get('/api/quote/search', ajaxParams);
    // } catch (error) {
    //   // TODO: switch to error.message once endpoint returns meaningful message
    //   alert.error(`status ${error[0].status}: ${error[0].title}`, {timeout: 10000});
    //   return;
    // }

    // // FIXME: need to be removed
    // data.listings = [...Array(100).fill(data.listings[0])];

    this.set(
      'searchResult',
      data.listings.map(l => {
        return this.bpStore.createRecord('listing', l);
      })
    );

    // TODO: once endpoint returns channelListing
    /**
     * It might be worth creating the `channelListing` objects here too, and mapping
     * them together. Then you get nice object traversal inside the templates. e.g.
     *
     * const channelListings = data.channelListings.map(cl =>
        this.bpStore.createRecord('channelListing', cl));
        const listings = data.listings.map(l => this.bpStore.createRecord('listing', l));
        listings.forEach(l => {
          l.set('primaryChannelListing', this.bpStore.peekRecord('channelListing', l.primaryChannelListingId));
          l.set('channelListings', l.channelListingIds.map(id =>
          this.bpStore.peekRecord('channelListing', id));
        });
        channelListings.forEach(cl => {
          cl.set('masterListing', this.bpStore.peekRecord('listing', l.masterListingId));
        });
        this.set('searchResult', listings);
     */

    // save on client
    const cacheParams = { range, selectedCluster, selectedGuest };
    this.set('lastSearchParamsCache', cacheParams);
  }

  /**
   * filter
   */

  // bedrooms

  @action
  changedBedrooms(value) {
    const min = Math.round(value[0]);
    const max = Math.round(value[1]);
    debounce(this, this.debouncedChangedBedrooms, [min, max], 200);
  }

  @action
  changedMinBedrooms(value) {
    const max = this.bedroomsForInput[1];
    this.set('bedroomsForInput', [parseInt(value), parseInt(max)]);
    this.set('bedroomsForSlider', [parseInt(value), parseInt(max)]);
  }

  @action
  changedMaxBedrooms(value) {
    const min = this.bedroomsForInput[0];
    this.set('bedroomsForInput', [parseInt(min), parseInt(value)]);
    this.set('bedroomsForSlider', [parseInt(min), parseInt(value)]);
  }

  @action
  resetBedrooms() {
    const max = this.maxListingsBedrooms;
    this.set('bedroomsForInput', [0, max]);
    this.set('bedroomsForSlider', [0, max]);
  }

  // bathrooms

  @action
  changedBathrooms(value) {
    const min = Math.round(value[0]);
    const max = Math.round(value[1]);
    debounce(this, this.debouncedChangedBathrooms, [min, max], 200);
  }

  @action
  changedMinBathrooms(value) {
    const max = this.bathroomsForInput[1];
    this.set('bathroomsForInput', [parseInt(value), parseInt(max)]);
    this.set('bathroomsForSlider', [parseInt(value), parseInt(max)]);
  }

  @action
  changedMaxBathrooms(value) {
    const min = this.bathroomsForInput[0];
    this.set('bathroomsForInput', [parseInt(min), parseInt(value)]);
    this.set('bathroomsForSlider', [parseInt(min), parseInt(value)]);
  }

  @action
  resetBathrooms() {
    const max = this.maxListingsBathrooms;
    this.set('bathroomsForInput', [0, max]);
    this.set('bathroomsForSlider', [0, max]);
  }

  // price

  @action
  changedPrice(value) {
    const min = Math.round(value[0]);
    const max = Math.round(value[1]);
    debounce(this, this.debouncedChangedPrice, [min, max], 200);
  }

  @action
  changedMinPrice(value) {
    const max = this.priceForInput[1];
    this.set('priceForInput', [parseInt(value), parseInt(max)]);
    this.set('priceForSlider', [parseInt(value), parseInt(max)]);
  }

  @action
  changedMaxPrice(value) {
    const min = this.priceForInput[0];
    this.set('priceForInput', [parseInt(min), parseInt(value)]);
    this.set('priceForSlider', [parseInt(min), parseInt(value)]);
  }

  @action
  resetPrice() {
    const max = this.maxListingsPrice;
    this.set('priceForInput', [0, max]);
    this.set('priceForSlider', [0, max]);
  }

  // all
  @action
  resetAllFilters() {
    this.send('resetBedrooms');
    this.send('resetBathrooms');
    this.send('resetPrice');
  }
}
