import { attr } from '@ember-data/model';
import MF from 'ember-data-model-fragments';

export default class extends MF.Fragment {
  @attr('string') search;
  @attr('string') appearance;
  @attr('number') basePriceMax;
  @attr('number') basePriceMin;
  @attr('number') bedroomsMax;
  @attr('number') bedroomsMin;
  @attr('number') healthScoreMax;
  @attr('number') healthScoreMin;
  @attr('filter-accounts') selectedAccounts;
  @attr('filter-markets') selectedMarketNames;
  @attr('filter-clusters') selectedClusterNames;
  @attr('filter-categories') selectedCategory1Names;
  @attr('filter-categories') selectedCategory2Names;
  @attr('filter-listings') listingsToInclude;
  @attr('filter-listings') listingsToExclude;
  @attr('filter-listings') listingsToCompare;
  @attr('filter-bedrooms') selectedBedrooms;
  @attr('number') selectedOriginForRadius;
  @attr('string') distanceMax;
  @attr('string') distanceMin;
  @attr('boolean', { allowNull: true }) showDisabled;
  @attr('boolean', { allowNull: true }) showEnabled;
  @attr('boolean', { allowNull: true }) monthlyPricePostingDisabled;
  @attr('boolean', { allowNull: true }) monthlyPricePostingEnabled;
  @attr('boolean', { allowNull: true }) showOnProgram;
  @attr('boolean', { allowNull: true }) showOffProgram;
  @attr('string') sortField;
  @attr('string') sortOrder;
  @attr('boolean', { allowNull: true }) hideUnavailable;
  @attr('boolean', { allowNull: true }) filterByNeedReview;
  @attr('moment') bookedAtStartDate;
  @attr('moment') bookedAtEndDate;
}
