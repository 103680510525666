export function displayErrors({
  errors = null,
  modelOrKeywordThis = null,
  alert = null,
  timeout = 10000,
  isProperty = false,
  propertyName = 'errors.base',
  intlService = null,
} = {}) {
  // If status code is 500 show defaultError otherwise show specificError
  // We display multiple errors if isAlert true otherwise display first error

  let defaultError = 'validation.genericWithContact';

  if (!Array.isArray(errors)) {
    errors = [errors];
  }
  for (let error of errors) {
    let specificError = error.detail || error.message || error.title;
    let errorToDisplay = parseInt(error.status) === 500 ? defaultError : specificError;
    errorToDisplay = intlService ? intlService.t(errorToDisplay) : errorToDisplay;

    if (alert) {
      modelOrKeywordThis
        ? modelOrKeywordThis.alert.error(errorToDisplay, { timeout: timeout })
        : alert.error(errorToDisplay, { timeout: timeout });
    }
    if (isProperty) {
      modelOrKeywordThis.set(propertyName, errorToDisplay);
      break;
    }
  }
}
