import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { displayErrors } from 'appkit/lib/display_errors';
import ENV from 'appkit/config/environment';

export default class ContactApiService extends Service {
  @service ajax;
  @service alert;

  async submit(reason, email, body) {
    // Break cookies into an object. No, there's no built-in function to
    // handle this. Yes, that's insane.
    const cookies = document.cookie.split(';').reduce((cookies, row) => {
      const [key, value] = row.trim().split('=');
      cookies[key] = value;
      return cookies;
    }, {});

    // Add hubspot token so we can treat signup as a hubspot form.
    // Have to check for _hsq in case user has blocked via adblock.
    if (ENV.environment === 'production' && window._hsq) {
      // Hubspot
      window._hsq.push([
        'identify',
        {
          email: email,
        },
      ]);
      body.hubspotToken = cookies.hubspotutk;
    }

    try {
      await this.ajax._post('/api/contact', {
        reason,
        email,
        ...body,
      });
    } catch (err) {
      // Handle the case where the client fails
      const errors = Array.isArray(err)
        ? err
        : [{ message: 'validation.genericWithTryAgain' }];
      displayErrors({
        errors: errors,
        modelOrKeywordThis: this,
        isProperty: true,
        propertyName: 'errorMessage',
        alert: this.alert,
      });
      throw errors;
    }
  }
}
