import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { displayErrors } from 'appkit/lib/display_errors';

export default class InsightsApiService extends Service {
  @service ajax;
  @service intl;
  @service alert;

  async getStats({
    filterByMarkets,
    filterByClusters,
    filterByCities,
    filterByServices,
    filterByListings,
    filterByListingsToExclude,
    filterByListingsToCompareWith,
    isGetMarketData,
    isGetClusterData,
    isGetCityData,
    selectedCurrency,
    bedrooms,
    bookedStartDate,
    bookedEndDate,
    checkinStartDate,
    checkinEndDate,
    selectedCategory1Names,
    selectedCategory2Names,
    healthScoreMin,
    healthScoreMax,
    basePriceMin,
    basePriceMax,
    showEnabled,
    showDisabled,
    monthlyPricePostingEnabled,
    monthlyPricePostingDisabled,
    hideUnavailable,
    selectedOriginForRadius,
    distanceMin,
    distanceMax,
    selectedClusterIds,
  }) {
    const payload = {
      filterByMarkets,
      filterByClusters,
      filterByCities,
      filterByServices,
      filterByListings,
      filterByListingsToExclude,
      filterByListingsToCompareWith,
      isGetMarketData,
      isGetClusterData,
      isGetCityData,
      selectedCurrency,
      bedrooms,
      bookedStartDate,
      bookedEndDate,
      checkinStartDate,
      checkinEndDate,
      selectedCategory1Names,
      selectedCategory2Names,
      healthScoreMin,
      healthScoreMax,
      basePriceMin,
      basePriceMax,
      showEnabled,
      showDisabled,
      monthlyPricePostingEnabled,
      monthlyPricePostingDisabled,
      hideUnavailable,
      selectedOriginForRadius,
      distanceMin,
      distanceMax,
      selectedClusterIds,
    };
    payload.kmFactor = this.intl.locale === 'en' ? 0.621371 : 1;
    if (selectedOriginForRadius) {
      payload.selectedOriginForRadius = selectedOriginForRadius.id;
    }

    try {
      let stats = await this.ajax._post('/api/insights/account-stats', payload);

      return {
        stats: stats,
        bedrooms: stats.bedrooms,
      };
    } catch (err) {
      const errors = Array.isArray(err) ? err : [{ message: 'Failed to fetch stats.' }];
      displayErrors({ errors, modelOrKeywordThis: this, alert: this.alert });
      throw err;
    }
  }

  async getMarketStats({
    filterByMarkets,
    filterByClusters,
    filterByCities,
    filterByServices,
    filterByListings,
    filterByListingsToExclude,
    filterByListingsToCompareWith,
    isGetMarketData,
    isGetClusterData,
    isGetCityData,
    selectedCurrency,
    bedrooms,
    bookedStartDate,
    bookedEndDate,
    checkinStartDate,
    checkinEndDate,
    selectedClusterIds,
  }) {
    const payload = {
      filterByMarkets,
      filterByClusters,
      filterByCities,
      filterByServices,
      filterByListings,
      filterByListingsToExclude,
      filterByListingsToCompareWith,
      isGetMarketData,
      isGetClusterData,
      isGetCityData,
      selectedCurrency,
      bedrooms,
      bookedStartDate,
      bookedEndDate,
      checkinStartDate,
      checkinEndDate,
      selectedClusterIds,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      let stats = await this.ajax._post('/api/insights/market-stats', payload);

      return {
        stats: stats,
        bedrooms: stats.bedrooms,
      };
    } catch (err) {
      throw err;
    }
  }

  async getListingsPerformanceByRevenueUser({
    filterByMarkets,
    filterByClusters,
    filterByCities,
    filterByServices,
    filterByListings,
    filterByListingsToExclude,
    filterByListingsToCompareWith,
    isGetMarketData,
    isGetClusterData,
    isGetCityData,
    selectedCurrency,
    bedrooms,
    bookedStartDate,
    bookedEndDate,
    checkinStartDate,
    checkinEndDate,
    selectedCategory1Names,
    selectedCategory2Names,
    healthScoreMin,
    healthScoreMax,
    basePriceMin,
    basePriceMax,
    showEnabled,
    showDisabled,
    monthlyPricePostingEnabled,
    monthlyPricePostingDisabled,
    hideUnavailable,
    selectedOriginForRadius,
    distanceMin,
    distanceMax,
    selectedClusterIds,
  }) {
    const payload = {
      filterByMarkets,
      filterByClusters,
      filterByCities,
      filterByServices,
      filterByListings,
      filterByListingsToExclude,
      filterByListingsToCompareWith,
      isGetMarketData,
      isGetClusterData,
      isGetCityData,
      selectedCurrency,
      bedrooms,
      bookedStartDate,
      bookedEndDate,
      checkinStartDate,
      checkinEndDate,
      selectedCategory1Names,
      selectedCategory2Names,
      healthScoreMin,
      healthScoreMax,
      basePriceMin,
      basePriceMax,
      showEnabled,
      showDisabled,
      monthlyPricePostingEnabled,
      monthlyPricePostingDisabled,
      hideUnavailable,
      selectedOriginForRadius,
      distanceMin,
      distanceMax,
      selectedClusterIds,
    };
    payload.kmFactor = this.intl.locale === 'en' ? 0.621371 : 1;
    if (selectedOriginForRadius) {
      payload.selectedOriginForRadius = selectedOriginForRadius.id;
    }

    // eslint-disable-next-line no-useless-catch
    try {
      return {
        stats: await this.ajax._post(
          '/api/insights/listings_performance_user',
          payload
        ),
      };
    } catch (err) {
      throw err;
    }
  }

  async getListingsPerformanceByRevpanUser({
    filterByMarkets,
    filterByClusters,
    filterByCities,
    filterByServices,
    filterByListings,
    filterByListingsToExclude,
    filterByListingsToCompareWith,
    isGetMarketData,
    isGetClusterData,
    isGetCityData,
    selectedCurrency,
    bedrooms,
    bookedStartDate,
    bookedEndDate,
    checkinStartDate,
    checkinEndDate,
    selectedCategory1Names,
    selectedCategory2Names,
    healthScoreMin,
    healthScoreMax,
    basePriceMin,
    basePriceMax,
    showEnabled,
    showDisabled,
    monthlyPricePostingEnabled,
    monthlyPricePostingDisabled,
    hideUnavailable,
    selectedOriginForRadius,
    distanceMin,
    distanceMax,
    selectedClusterIds,
  }) {
    const payload = {
      filterByMarkets,
      filterByClusters,
      filterByCities,
      filterByServices,
      filterByListings,
      filterByListingsToExclude,
      filterByListingsToCompareWith,
      isGetMarketData,
      isGetClusterData,
      isGetCityData,
      selectedCurrency,
      bedrooms,
      bookedStartDate,
      bookedEndDate,
      checkinStartDate,
      checkinEndDate,
      selectedCategory1Names,
      selectedCategory2Names,
      healthScoreMin,
      healthScoreMax,
      basePriceMin,
      basePriceMax,
      showEnabled,
      showDisabled,
      monthlyPricePostingEnabled,
      monthlyPricePostingDisabled,
      hideUnavailable,
      selectedOriginForRadius,
      distanceMin,
      distanceMax,
      selectedClusterIds,
    };
    payload.kmFactor = this.intl.locale === 'en' ? 0.621371 : 1;
    if (selectedOriginForRadius) {
      payload.selectedOriginForRadius = selectedOriginForRadius.id;
    }

    // eslint-disable-next-line no-useless-catch
    try {
      return {
        stats: await this.ajax._post(
          '/api/insights/listings_performance_revpan_user',
          payload
        ),
      };
    } catch (err) {
      throw err;
    }
  }

  getListingsPerformanceByRevenueMarket({
    filterByMarkets,
    filterByClusters,
    filterByCities,
    filterByServices,
    filterByListings,
    filterByListingsToExclude,
    isGetMarketData,
    isGetClusterData,
    isGetCityData,
    selectedCurrency,
    bedrooms,
    bookedStartDate,
    bookedEndDate,
    checkinStartDate,
    checkinEndDate,
    selectedClusterIds,
  }) {
    const payload = {
      filterByMarkets,
      filterByClusters,
      filterByCities,
      filterByServices,
      filterByListings,
      filterByListingsToExclude,
      isGetMarketData,
      isGetClusterData,
      isGetCityData,
      selectedCurrency,
      bedrooms,
      bookedStartDate,
      bookedEndDate,
      checkinStartDate,
      checkinEndDate,
      selectedClusterIds,
    };

    const { promise, jqXHR } = this.ajax.post(
      '/api/insights/listings_performance_market',
      payload
    );

    return [
      promise.then(data => ({
        stats: data,
      })),
      jqXHR,
    ];
  }

  async getCachedMarketAvailableNights({
    filterByMarkets,
    filterByClusters,
    filterByCities,
    filterByServices,
    filterByListings,
    filterByListingsToExclude,
    filterByListingsToCompareWith,
    isGetMarketData,
    isGetClusterData,
    isGetCityData,
    selectedCurrency,
    bedrooms,
    bookedStartDate,
    bookedEndDate,
    checkinStartDate,
    checkinEndDate,
    selectedCategory1Names,
    selectedCategory2Names,
    healthScoreMin,
    healthScoreMax,
    basePriceMin,
    basePriceMax,
    showEnabled,
    showDisabled,
    monthlyPricePostingEnabled,
    monthlyPricePostingDisabled,
    hideUnavailable,
    selectedOriginForRadius,
    distanceMin,
    distanceMax,
    selectedClusterIds,
  }) {
    const payload = {
      filterByMarkets,
      filterByClusters,
      filterByCities,
      filterByServices,
      filterByListings,
      filterByListingsToExclude,
      filterByListingsToCompareWith,
      isGetMarketData,
      isGetClusterData,
      isGetCityData,
      selectedCurrency,
      bedrooms,
      bookedStartDate,
      bookedEndDate,
      checkinStartDate,
      checkinEndDate,
      selectedCategory1Names,
      selectedCategory2Names,
      healthScoreMin,
      healthScoreMax,
      basePriceMin,
      basePriceMax,
      showEnabled,
      showDisabled,
      monthlyPricePostingEnabled,
      monthlyPricePostingDisabled,
      hideUnavailable,
      selectedOriginForRadius,
      distanceMin,
      distanceMax,
      selectedClusterIds,
    };
    payload.kmFactor = this.intl.locale === 'en' ? 0.621371 : 1;
    if (selectedOriginForRadius) {
      payload.selectedOriginForRadius = selectedOriginForRadius.id;
    }

    try {
      let availabilityStats = await this.ajax._post(
        '/api/insights/cached_market_available_nights',
        payload
      );
      return {
        stats: availabilityStats,
      };
    } catch (err) {
      const errors = Array.isArray(err) ? err : [{ message: 'Failed to fetch stats.' }];
      displayErrors({ errors, modelOrKeywordThis: this, alert: this.alert });
      throw err;
    }
  }

  async getCachedMarketInsights({
    filterByMarkets,
    filterByClusters,
    filterByCities,
    filterByServices,
    filterByListings,
    filterByListingsToExclude,
    filterByListingsToCompareWith,
    isGetMarketData,
    isGetClusterData,
    isGetCityData,
    selectedCurrency,
    bedrooms,
    bookedStartDate,
    bookedEndDate,
    checkinStartDate,
    checkinEndDate,
    selectedCategory1Names,
    selectedCategory2Names,
    healthScoreMin,
    healthScoreMax,
    basePriceMin,
    basePriceMax,
    showEnabled,
    showDisabled,
    monthlyPricePostingEnabled,
    monthlyPricePostingDisabled,
    hideUnavailable,
    selectedOriginForRadius,
    distanceMin,
    distanceMax,
    selectedClusterIds,
  }) {
    const payload = {
      filterByMarkets,
      filterByClusters,
      filterByCities,
      filterByServices,
      filterByListings,
      filterByListingsToExclude,
      filterByListingsToCompareWith,
      isGetMarketData,
      isGetClusterData,
      isGetCityData,
      selectedCurrency,
      bedrooms,
      bookedStartDate,
      bookedEndDate,
      checkinStartDate,
      checkinEndDate,
      selectedCategory1Names,
      selectedCategory2Names,
      healthScoreMin,
      healthScoreMax,
      basePriceMin,
      basePriceMax,
      showEnabled,
      showDisabled,
      monthlyPricePostingEnabled,
      monthlyPricePostingDisabled,
      hideUnavailable,
      selectedOriginForRadius,
      distanceMin,
      distanceMax,
      selectedClusterIds,
    };
    payload.kmFactor = this.intl.locale === 'en' ? 0.621371 : 1;
    if (selectedOriginForRadius) {
      payload.selectedOriginForRadius = selectedOriginForRadius.id;
    }

    try {
      let stats = await this.ajax._post('/api/insights/cached_market_stats', payload);
      return {
        stats: stats,
        bedrooms: stats.bedrooms,
      };
    } catch (err) {
      const errors = Array.isArray(err) ? err : [{ message: 'Failed to fetch stats.' }];
      displayErrors({ errors, modelOrKeywordThis: this, alert: this.alert });
      throw err;
    }
  }
}
