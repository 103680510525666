import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    suggestedListings=this.suggestedListings\n    suggestedListingsById=this.suggestedListingsById\n  )\n}}", {"contents":"{{yield\n  (hash\n    suggestedListings=this.suggestedListings\n    suggestedListingsById=this.suggestedListingsById\n  )\n}}","moduleName":"appkit/components/relay/suggestions-container.hbs","parseOptions":{"srcName":"appkit/components/relay/suggestions-container.hbs"}});
import Component from '@glimmer/component';
import { ChannelUtil } from 'appkit/lib/channel';

export default class RelaySuggestionsContainerContainer extends Component {
  get listings() {
    return this.args.listings ?? [];
  }

  get groupedListingsByAddress() {
    const skippedAddresses = JSON.parse(this.skippedAddresses) || [];
    let listings = this.listings;

    let groupedListings = listings
      .filter(listing => listing.cleanAddress)
      .filter(listing => !skippedAddresses.includes(listing.cleanAddress))
      .sort((a, b) => a.cleanAddress.localeCompare(b.cleanAddress))
      .reduce((accum, listing) => {
        accum[listing.cleanAddress] = accum[listing.cleanAddress] || [];
        accum[listing.cleanAddress].push(listing);
        return accum;
      }, {});

    return this._filterUnmetRequirement(Object.values(groupedListings));
  }

  get suggestedListingsOnAddress() {
    return this._filterUnmetRequirement(this.groupedListingsByAddress);
  }

  get groupedListingsByTitle() {
    const skippedTitles = JSON.parse(this.skippedTitles) || [];
    let listings = this.listings;

    let groupedListings = listings
      .filter(listing => listing.cleanTitle)
      .filter(listing => !skippedTitles.includes(listing.cleanTitle))
      .sort((a, b) => a.cleanTitle.localeCompare(b.cleanTitle))
      .reduce((accum, listing) => {
        accum[listing.cleanTitle] = accum[listing.cleanTitle] || [];
        accum[listing.cleanTitle].push(listing);
        return accum;
      }, {});
    return this._filterUnmetRequirement(Object.values(groupedListings));
  }

  get suggestedListingsOnTitle() {
    return this._filterUnmetRequirement(this.groupedListingsByTitle);
  }

  get suggestedListings() {
    const suggestedListingsOnAddress = this.suggestedListingsOnAddress;
    const suggestedListingsOnTitle = this.suggestedListingsOnTitle;

    if (suggestedListingsOnAddress.length) {
      // default to suggestions based on address
      return suggestedListingsOnAddress;
    } else if (suggestedListingsOnTitle.length) {
      // fallback to suggestion based on titles
      return suggestedListingsOnTitle;
    } else {
      // always fallback to empty array
      return [];
    }
  }

  get suggestedListingsById() {
    const suggestedListingsById = this.suggestedListings.reduce((acc, cur) => {
      cur.forEach(c => {
        acc[c.id] = cur;
      });
      return acc;
    }, {});
    return suggestedListingsById;
  }

  get skippedAddresses() {
    return localStorage.getItem('skippedAddresses');
  }

  set skippedAddresses(value) {
    if (value) {
      localStorage.setItem('skippedAddresses', value);
    } else {
      localStorage.removeItem('skippedAddresses');
    }
    return localStorage.getItem('skippedAddresses');
  }

  get skippedTitles() {
    return localStorage.getItem('skippedTitles');
  }

  set skippedTitles(value) {
    if (value) {
      localStorage.setItem('skippedTitles', value);
    } else {
      localStorage.removeItem('skippedTitles');
    }
    return localStorage.getItem('skippedTitles');
  }

  _filterUnmetRequirement(suggestedListings) {
    return (
      suggestedListings
        // filter for 1 PMS and at least 1 Direct channel count
        .filter(listingArr => {
          const isPmsArr = listingArr.map(listing => {
            if (listing && listing.primaryChannelListing) {
              return ChannelUtil.isPms(listing.primaryChannelListing.channel);
            } else {
              return false;
            }
          });
          const pmsCount = isPmsArr.filter(i => i).length;
          const directCount = isPmsArr.filter(i => !i).length;
          return pmsCount === 1 && directCount > 0;
        })
        // filter for same bedroom count
        .filter(listingArr => {
          const uniqueBedroomCounts = listingArr.map(listing => {
            return listing.bedrooms;
          });
          return new Set(uniqueBedroomCounts).size === 1;
        })
    );
  }
}
