import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import moment from 'moment';
import { saveAs } from 'file-saver';

export default class SignalContainer extends Component {
  @service ajax;
  @service store;

  @tracked total = 0;

  @tracked loading = false;

  constructor() {
    super(...arguments);
  }

  @tracked sortOrder = 'asc';

  @tracked start;
  @tracked end;

  @tracked page = 1;
  @tracked pageSize = 25;

  @tracked selectedRangeStart;
  @tracked selectedRangeEnd;

  @tracked entries = [];

  @tracked selectedRange;

  get isFiltering() {
    return this.selectedRange || this.start || this.end || this.page > 1;
  }

  ranges = [
    { field: 'last_day', label: 'Last 24 hours' },
    { field: 'last_week', label: 'Last 7 days' },
    { field: 'last_14_days', label: 'Last 14 days' },
    { field: 'last_30_days', label: 'Last 30 days' },
    { field: 'last_60_days', label: 'Last 60 days' },
    { field: 'last_90_days', label: 'Last 90 days' },
    { field: 'last_year', label: 'Last year' },
  ];

  @action
  clearFilters() {
    this.selectedRange = null;
    this.selectedRangeStart = null;
    this.selectedRangeEnd = null;
    this.start = null;
    this.end = null;
    this.page = 1;

    this.loadHistory();
  }

  @action
  onDateRangeSelected(range) {
    this.selectedRange = null;
    this.selectedRangeStart = null;
    this.selectedRangeEnd = null;
    this.start = range.start;
    this.end = range.end;
    this.page = 1;

    this.loadHistory();
  }

  @action
  onSelectedRangeChanged(field) {
    this.selectedRange = field;

    if (field.field !== '-') {
      this.start = null;
      this.end = null;
    }

    switch (field.field) {
      case 'last_day':
        this.selectedRangeEnd = moment();
        this.selectedRangeStart = moment().subtract(1, 'days');
        break;

      case 'last_week':
        this.selectedRangeEnd = moment();
        this.selectedRangeStart = moment().subtract(1, 'weeks');
        break;

      case 'last_14_days':
        this.selectedRangeEnd = moment();
        this.selectedRangeStart = moment().subtract(14, 'days');
        break;

      case 'last_30_days':
        this.selectedRangeEnd = moment();
        this.selectedRangeStart = moment().subtract(30, 'days');
        break;

      case 'last_60_days':
        this.selectedRangeEnd = moment();
        this.selectedRangeStart = moment().subtract(60, 'days');
        break;

      case 'last_90_days':
        this.selectedRangeEnd = moment();
        this.selectedRangeStart = moment().subtract(90, 'days');
        break;

      case 'last_year':
        this.selectedRangeEnd = moment();
        this.selectedRangeStart = moment().subtract(1, 'year');
        break;

      default:
        this.selectedRangeStart = null;
        this.selectedEndStart = null;
        break;
    }

    this.page = 1;
    this.loadHistory();
  }

  @action
  onSortFieldChanged(field) {
    this.sortField = field;

    this.loadHistory();
  }

  @action
  onSortOrderChanged() {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';

    this.loadHistory();
  }

  @action
  gotoPage(page) {
    this.page = page;
    this.loadHistory();
  }

  @action
  async loadHistory() {
    this.loading = true;
    let history;

    let start = this.start || this.selectedRangeStart;
    let end = this.end || this.selectedRangeEnd;

    try {
      history = await this.store.query(this.modelName, {
        ordering: (this.sortOrder === 'desc' ? '-' : '') + this.sortField.field,
        page: {
          size: this.pageSize,
          number: this.page,
        },
        filter: {
          from_date: start?.format('YYYY-MM-DD'),
          to_date: end?.format('YYYY-MM-DD'),
        },
      });
    } catch (e) {
      console.error(e);
      return;
    } finally {
      this.loading = false;
    }

    this.total = history.meta.pagination.count;
    this.entries = history;
  }

  @action
  downloadExport() {
    let start = this.start || this.selectedRangeStart;
    let end = this.end || this.selectedRangeEnd;
    let filename = 'results.csv';

    let sorting = this.sortOrder === 'desc' ? '-' : '';

    let queryParams = `ordering=${sorting}${this.sortField.field}`;

    if (start) {
      queryParams += `&filter[from_date]=${start.format('YYYY-MM-DD')}`;
    }

    if (end) {
      queryParams += `&filter[to_date]=${end.format('YYYY-MM-DD')}`;
    }

    this.downloadFileAsCSV(`${this.exportCsvUrl}?${queryParams}`, filename);
  }

  downloadFileAsCSV(url, filename) {
    this.ajax._get(url).then(async response => {
      const blob = new Blob([response]);

      saveAs(blob, filename);
    });
  }
}
