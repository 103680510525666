import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Controller from '@ember/controller';

@classic
export default class NotificationsController extends Controller {
  posting = false;
  errors = {};

  @action
  toggleNotificationItems(notification_rule) {
    notification_rule.toggleProperty('itemsVisible');
    return false;
  }
}
