import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import { later } from '@ember/runloop';
import { getOwner } from '@ember/application';

@classic
export default class FilterRoute extends AuthRoute {
  renderTemplate() {
    this.render('dashboard/filter', {
      into: 'dashboard/pricing/index',
      outlet: 'side-panel',
      controller: 'dashboard/pricing/index',
    });
  }

  activate() {
    const pricingFilterController = getOwner(this).lookup(
      'controller:dashboard.pricing.index'
    );
    later(
      this,
      function () {
        pricingFilterController.isFilterVisible = true;
      },
      100
    );
  }

  deactivate() {
    const pricingFilterController = getOwner(this).lookup(
      'controller:dashboard.pricing.index'
    );
    pricingFilterController.isFilterVisible = false;
  }
}
