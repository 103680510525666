import { assign } from '@ember/polyfills';
import $ from 'jquery';
import Service from '@ember/service';
import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import ENV from 'appkit/config/environment';
import Util from 'appkit/lib/util';
import logger from 'appkit/lib/logger';

const generateToken = (length = 15) => {
  // Generate random token with `length` amount of chars.
  // `36` is the max radix value `toString` accepts
  // `2` is the 3th char at the generated string(that looks like "0.u2c3erbg668")
  //  We could get any char after the dot.
  return [...Array(length)].map(() => Math.random().toString(36)[2]).join('');
};

// This is useful as a per session token to identify the user actions that happened
// in the close past, like 2 factor auth, channel connect etc
const sessionToken = generateToken();

@classic
class AjaxService extends Service {
  get host() {
    return ENV.APP.host;
  }

  request(
    method,
    url,
    data = null,
    headers = {},
    timeout = 300000,
    requestOptions = {}
  ) {
    if (typeof data === 'function') {
      throw Error('ajax uses promises, not callbacks');
    }

    let reqHeaders = {
      'Content-Type': 'application/json; charset=UTF-8',
    };

    if (requestOptions['enctype'] == 'multipart/form-data') {
      reqHeaders = {};
    }

    headers = assign({}, reqHeaders, headers);

    if (window.STAMP && data) {
      data.STAMP = window.STAMP;
    }

    let token = localStorage.getItem('token');
    if (token && !headers.token) {
      headers.token = token;
    }

    let sudo = localStorage.getItem('sudo');
    if (sudo) {
      headers.sudo = true;
    }

    headers.prefixRequestId = this._getRequestPrefix();

    if (this.host) {
      url = this.host + url;
    }

    let authFields = {};
    let username = ENV.APP.username;
    let password = ENV.APP.password;
    if (username || password) {
      authFields = {
        username: username,
        password: password,
      };
    }

    if (requestOptions['enctype'] != 'multipart/form-data') {
      data = data && JSON.stringify(Util.decamelizer(data));
    }

    let params = Object.assign(
      {},
      authFields,
      requestOptions,
      {
        method: method,
        data: data,
        headers: headers,
        timeout: timeout,
        xhrFields: {
          withCredentials: true,
        },
        converters: {
          'text json': function (data) {
            const json = JSON.parse(data);
            return Util.camelizer(json);
          },
        },
      },
      this._getExtraAjaxParams()
    );

    let jqXHR = null;

    let promise = new RSVP.Promise((resolve, reject) => {
      jqXHR = $.ajax(url, params);
      jqXHR.done(resolve).fail((jqXHR, statusText) => {
        if (statusText === 'abort') {
          return;
        }
        // We allow multiple errors in a response, per JSON-API spec.
        const responseData = Util.camelizer(jqXHR.responseJSON);
        if (jqXHR.status >= 500 && jqXHR.status <= 600) {
          // We have a default error message to handle django / nginx server errors
          // that aren't json formatted.
          let serverError = responseData ? responseData.errors : responseData;
          let error = serverError || [
            {
              status: jqXHR.status,
              message: `Server error: ${jqXHR.status}`,
            },
          ];

          return reject(error);
        }

        try {
          reject(responseData.errors);
        } catch (err) {
          logger.error('Invalid JSON', jqXHR);
          reject('Invalid JSON response');
        }
      });
    });

    return { promise, jqXHR };
  }

  _getExtraAjaxParams() {
    return {};
  }

  _getRequestPrefix() {
    return this._getDeviceToken() + '-' + sessionToken;
  }

  _getDeviceToken() {
    // Returns a prefix that being kept in localStorage to identify the user, not as
    // the sessionToken that gets re-created on every page refresh.
    let prefix = localStorage.getItem('prefixRequestId');
    if (!prefix) {
      prefix = generateToken(10);
      localStorage.setItem('prefixRequestId', prefix);
    }
    return prefix;
  }

  _request(type, url, data, headers) {
    if (type === 'POST') {
      return this._post(url, data, headers);
    } else {
      return this._get(url, data, headers);
    }
  }

  get(url, data, headers) {
    return this.request('GET', url, data, headers);
  }

  post(url, data, headers, timeout) {
    return this.request('POST', url, data, headers, timeout);
  }

  put(url, data, headers) {
    return this.request('PUT', url, data, headers);
  }

  patch(url, data, headers) {
    return this.request('PATCH', url, data, headers);
  }

  delete(url, data, headers) {
    return this.request('DELETE', url, data, headers);
  }

  _get(_url, _data, _headers) {
    return this.get.apply(this, arguments).promise;
  }

  _post(_url, _data, _headers, _timeout) {
    return this.post.apply(this, arguments).promise;
  }

  _delete(_url, _data, _headers) {
    return this.delete.apply(this, arguments).promise;
  }

  _put(_url, _data, _headers) {
    return this.put.apply(this, arguments).promise;
  }

  _patch(_url, _data, _headers) {
    return this.patch.apply(this, arguments).promise;
  }

  _postWithFiles(url, data, headers, timeout) {
    const requestOptions = {
      enctype: 'multipart/form-data',
      processData: false,
      contentType: false,
      cache: false,
    };

    return this.request('POST', url, data, headers, timeout, requestOptions).promise;
  }

  stream(path, data, callback = null) {
    const baseHost = this.host ? this.host : document.location.href;
    let host = new URL(baseHost).host;
    // TODO: move token out of here and into websocket payload. We need to
    // process the token to determine if they have permission though, so for
    // that to work we would need some sort of DB access on the streaming server.
    let token = localStorage.getItem('token');
    data.token = token;
    const getParams = $.param(Util.decamelizer(data));
    let socket = new WebSocket(`wss://${host}${path}?${getParams}`);

    // A collection of all successfull messages parsed. Returned when the
    // promise resolves.
    let payloads = [];
    let onMessage = responseData => {
      let fullText = responseData.data;

      for (let line of fullText.split('\n')) {
        if (line.trim().length === 0) {
          continue;
        }

        if (line.length === 0) {
          logger.error('Warning - empty json payload');
          continue;
        }

        let out = line;
        try {
          out = JSON.parse(line);
          out = Util.camelizer(out);
        } catch (error) {
          if (!(error instanceof SyntaxError)) {
            throw error;
          }
          logger.error('Invalid json payload', line, error);
        }
        payloads.push(out);
        if (out.message === 'failed') {
          deferred.reject(out?.error ?? 'Error in stream response');
        }
        callback(out);
      }
    };

    let deferred = RSVP.defer();
    socket.addEventListener('message', onMessage);
    socket.addEventListener('error', event => {
      logger.error('Websocket error', event);
      deferred.reject(event);
    });
    socket.addEventListener('open', () => {
      socket.send(
        JSON.stringify({
          method: 'GET',
          token: localStorage.getItem('token'),
          path: path,
          data: data,
        })
      );
    });
    socket.addEventListener('close', () => {
      deferred.resolve(payloads);
    });

    // Though a stream doesn't really fit to a promise (as a promise can only
    // be resolved once), I try and stick to normal promise semantics as much as
    // possible. This means we get async routing and error substates for free if
    // we want them.
    return deferred.promise;
  }
}

export default AjaxService;
