import { on } from '@ember/object/evented';
import { oneWay, equal } from '@ember/object/computed';
import EmberObject, { computed, get } from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';
import moment from 'moment';
import momentComputed from 'ember-moment/computeds/moment';
import fromNow from 'ember-moment/computeds/from-now';
import { inject as service } from '@ember/service';
import { ChannelUtil } from 'appkit/lib/channel';
import { CurrencyUtil } from 'appkit/lib/currency';
import Util from 'appkit/lib/util';
import { displayErrors } from 'appkit/lib/display_errors';
import { CHANNEL, DATA_TYPE, CHANNEL_CONFIG } from 'appkit/lib/channel_configuration';

export default BaseModel.extend({
  alert: service(),
  featureFlag: service('feature-flag'),
  intl: service(),

  init() {
    this._super(arguments);
    this._EDITABLE = [
      'basePrice',
      'minStay',
      'orphanMinStay',
      'orphanMinStayBuffer',
      'isOrphanChangeoverDaysEnabled',
      'minPrice',
      'maxPrice',
      'monthlyMinPrice',
      'extraGuestFee',
      'extraGuestThreshold',
      'enabled',
      'availabilityEnabled',
      'notify',
      'notes',
      'onProgram',
      'pmNotes',
      'liverezReservationsEnabled',
      'streamlineReservationsEnabled',
      'reasonsSyncingTurnedOff',
      'geocodeCollisionsUpdatedAt',
      'geocodeCollisions',
      'directEnabled',
      'pricingType',
    ];

    this.set('basePriceHistory', this.basePriceHistory ?? {});
    this.set('basePriceHistoryV2', this.basePriceHistoryV2 ?? []);

    this.set('minPriceHistory', this.minPriceHistory ?? {});
    this.set('minPriceHistoryV2', this.minPriceHistoryV2 ?? []);
    this.set('monthlyMinPriceHistoryV2', this.monthlyMinPriceHistoryV2 ?? []);

    this.set('nearbyListings', this.nearbyListings ?? []);

    this.set('calendar', this.calendar ?? []);

    // bookingReview
    this.set('bookingReviewListings', this.bookingReviewListings ?? []);
    this.set('bookingReviewListingIds', this.bookingReviewListingIds ?? []);
    setTimeout(() => {
      this.featureFlag
        .evaluate('relay-liverezdirect-push-reservations', false)
        .then(ff => this.set('liverezReservationsEnabled', ff));
      this.featureFlag
        .evaluate('relay-streamline-push-reservations', false)
        .then(ff => this.set('streamlineReservationsEnabled', ff));
    });
  },

  bookingReviewListingsLoaded: false,
  id: null,
  url: computed('id', function () {
    return `/api/listings/${this.id}`;
  }),
  primaryChannelListingIsPms: computed('primaryChannelListing', function () {
    return ChannelUtil.isPms(this.get('primaryChannelListing.channel'));
  }),

  // Using double-underscore django style notation to show these are looked up via a
  // relationship. This is important for doing computed properties on primary channel
  // listing settings.
  // If this reads well, change all the above computed props to this format.
  primaryChannelListing__channel: oneWay('primaryChannelListing.channel'),
  primaryChannelListing__account: oneWay('primaryChannelListing.account'),
  primaryChannelListing__listed: oneWay('primaryChannelListing.listed'),
  primaryChannelListing__account__id: oneWay('primaryChannelListing.account.id'),
  primaryChannelListing__account__valid: oneWay('primaryChannelListing.account.valid'),
  primaryChannelListing__account__channelDisplayId: oneWay(
    'primaryChannelListing.account.channelDisplayId'
  ),

  channelListings: null,
  primaryChannelListing: null,
  channelListingsWithoutPrimary: computed(
    'channelListings',
    'primaryChannelListing',
    function () {
      const primaryChannelListing = this.primaryChannelListing;
      let out = this.channelListings || [];
      out = out.filter(l => l !== primaryChannelListing);
      return out;
    }
  ),

  // On what channels this listing is on: getting that info
  // from the channel listings
  channels: computed('channelListings', function () {
    let channelListings = this.channelListings ?? [];
    let channels = new Set(
      channelListings.map(function (cl) {
        return cl.channel;
      })
    );
    // Back to list
    return [...channels];
  }),

  canPushMonthly: computed('primaryChannelListing', function () {
    const channel = this.primaryChannelListing.channel;
    const channelConfig = CHANNEL_CONFIG[channel];
    // Checks whether or not it is an allowed channel. Can be removed once all are allowed.
    const ALLOWED_CHANNELS = [CHANNEL.ESCAPIA, CHANNEL.FAKE_PMS, CHANNEL.TRACK];
    if (!ALLOWED_CHANNELS.includes(channel)) return false;
    return channelConfig.canPush.includes(DATA_TYPE.MONTHLY_PRICE);
  }),

  isMerged: computed('channelListings.[]', function () {
    return this.channelListings && this.channelListings.length > 1;
  }),

  isSubUnit: computed('channelListings.[]', function () {
    const firstChannelListing = this.channelListings[0];

    if (firstChannelListing.multiUnitParentId) {
      return firstChannelListing.multiUnitParentId !== parseInt(firstChannelListing.id);
    }
    return false;
  }),

  syncing: false,
  enabled: false,
  availabilityEnabled: false,
  availabilityIsSupported: computed('primaryChannelListing.channel', function () {
    const relayAvailabilitySupportedChannels = ChannelUtil.getRelayAvailabilitySupported();
    return relayAvailabilitySupportedChannels.includes(
      this.primaryChannelListing.channel
    );
  }),
  reservationsEnabled: false,
  reservationIsSupported: computed(
    'primaryChannelListing.channel',
    'liverezReservationsEnabled',
    'streamlineReservationsEnabled',
    function () {
      const relayAvailabilitySupportedChannels = ChannelUtil.getRelayReservationSupported();
      let reservationSupported = relayAvailabilitySupportedChannels.includes(
        this.primaryChannelListing.channel
      );
      if (this.primaryChannelListing.channel === CHANNEL.LIVEREZ_DIRECT) {
        return reservationSupported && !!this.liverezReservationsEnabled;
      }
      if (this.primaryChannelListing.channel === CHANNEL.STREAMLINE) {
        return reservationSupported && !!this.streamlineReservationsEnabled;
      }
      return reservationSupported;
    }
  ),
  contentSyncingEnabled: false,
  contentSyncingIsSupported: computed('primaryChannelListing.channel', function () {
    const relayContentSyncingSupportedChannels = ChannelUtil.getRelayContentSyncingSupported();
    return relayContentSyncingSupportedChannels.includes(
      this.primaryChannelListing.channel
    );
  }),

  /**
   * Accepts an array of priceHistory elements which may have decimels as values, and rounds the values to
   * full numbers.
   * @param {Array<{value: number}>} priceHistory
   * @return {Array<value: number}>} priceHistory same array, with `value` property rounded up to nearest whole number
   */
  roundUpPriceHistoryValues: function (priceHistory) {
    if (!priceHistory) {
      return [];
    }
    return priceHistory.map(priceHistoryDate => {
      priceHistoryDate.value = Math.round(priceHistoryDate.value);
      return priceHistoryDate;
    });
  },

  basePriceUpdatedAt: null,
  basePriceUpdatedAtFormatted: fromNow(momentComputed('basePriceUpdatedAt'), false),

  basePriceHistorySorted: computed('basePriceHistoryV2', function () {
    const result = this.roundUpPriceHistoryValues(
      this.basePriceHistoryV2
        .sort((a, b) => (a.stamp < b.stamp ? 1 : -1)) // sort by most recent
        .slice(0, 5)
        .map(h => {
          if (h.credentialId) {
            h.credential = this.bpStore.peekRecord('credential', h.credentialId);
          }
          return h;
        })
    );
    return result;
  }),

  minPriceUpdatedAt: null,
  minPriceUpdatedAtFormatted: fromNow(momentComputed('minPriceUpdatedAt'), false),

  minPriceHistorySorted: computed('minPriceHistoryV2', function () {
    return this.roundUpPriceHistoryValues(
      this.minPriceHistoryV2
        .sort((a, b) => (a.stamp < b.stamp ? 1 : -1)) // sort by most recent
        .slice(0, 5)
        .map(h => {
          if (h.credentialId) {
            h.credential = this.bpStore.peekRecord('credential', h.credentialId);
          }
          return h;
        })
    );
  }),

  monthlyMinPriceHistorySorted: computed('monthlyMinPriceHistoryV2', function () {
    return this.monthlyMinPriceHistoryV2
      .sort((a, b) => (a.stamp < b.stamp ? 1 : -1)) // sort by most recent
      .slice(0, 5)
      .map(h => {
        if (h.credentialId) {
          h.credential = this.bpStore.peekRecord('credential', h.credentialId);
        }
        return h;
      });
  }),

  extraGuestFee: null,
  extraGuestThreshold: null,
  roundToNearestNumber: null,

  image: '',
  imageOrDefault: computed('image', function () {
    return this.image || 'assets/home-blue.svg';
  }),

  inActiveMarket: true,
  lastUpdated: null,

  isPreview: false,
  isDemo: false,
  notify: false,

  title: '',
  address: '',
  city: '',
  market: '',
  country: '',
  currency: 'USD',

  bookedThirty: 0,
  bookedNinety: 0,
  totalEarnings: 0,
  healthScore: 0,

  bedrooms: 0,
  bathrooms: 0,

  displayTitle: computed('title', function () {
    return (
      (this.primaryChannelListing && this.primaryChannelListing.multiUnitGroupTitle) ||
      this.title
    );
  }),

  lastScraped: computed('primaryChannelListing.lastScrapeStamp', function () {
    return this.primaryChannelListing && this.primaryChannelListing.lastScrapeStamp;
  }),

  bathroomsNoDecimal: computed('bathrooms', function () {
    if (!this.bathrooms) {
      return '-';
    }
    if (this.bathrooms % 1 != 0) {
      return this.bathrooms;
    } else {
      return parseInt(this.bathrooms);
    }
  }),

  searchTerm: computed('displayTitle', 'channelListingsIds', function () {
    return `${this.id}:${this.displayTitle}`;
  }),

  // Stats
  statistics: null, //type {Object}
  statisticsUpdatedAt: null,
  notes: '',
  pmNotes: '',

  hasStatistics: computed(
    'statistics.@each.{oneWeekReservationsCount,twoWeekReservationsCount,twoWeekReservationsDays,daysHittingAnyMax,daysHittingAnyMin,daysAvailable,daysBooked,daysBlocked,daysUnavailable,daysLive,enabledReservationsCount}',
    function () {
      return this.statistics && !Util.isObjectEmpty(this.statistics);
    }
  ),

  statisticsIsOutdated: computed('statisticsUpdatedAt', function () {
    const now = moment();
    const statisticsUpdatedAt = moment(this.statisticsUpdatedAt);
    const diff = now.diff(statisticsUpdatedAt, 'hours', true);
    // if user loaded statistics more than 24h/1d ago, it is outdated
    return diff > 24;
  }),

  lastLoadedDate: computed('statisticsUpdatedAt', function () {
    return moment(this.statisticsUpdatedAt).calendar();
  }),

  ninetyDaysPercentages: computed(
    'statistics.@each.{daysHittingAnyMin,daysHittingAnyMax,daysAvailable,daysBooked,daysBlocked,daysUnavailable,daysWithOverrides}',
    function () {
      let daysAvailable = null;
      let daysBooked = null;
      let daysBlocked = null;
      let daysUnavailable = null;
      let daysHittingMin = null;
      let daysHittingMax = null;
      let daysWithOverrides = null;

      if (this.hasStatistics) {
        daysWithOverrides =
          Math.round((100 * this.statistics.daysWithOverrides) / 90) || 0;
        daysHittingMin =
          Math.round((100 * this.statistics.daysHittingAnyMin) / 90) || 0;
        daysHittingMax =
          Math.round((100 * this.statistics.daysHittingAnyMax) / 90) || 0;
        daysAvailable = Math.round((100 * this.statistics.daysAvailable) / 90) || 0;
        daysBooked = Math.round((100 * this.statistics.daysBooked) / 90) || 0;
        daysBlocked =
          Math.round(
            (100 * (this.statistics.daysBlocked + this.statistics.daysUnavailable)) / 90
          ) || 0;
        daysUnavailable = 100 - daysAvailable || 100;
      }

      return {
        daysHittingMin: daysHittingMin,
        daysHittingMax: daysHittingMax,
        daysAvailable: daysAvailable,
        daysBooked: daysBooked,
        daysBlocked: daysBlocked,
        daysUnavailable: daysUnavailable,
        daysWithOverrides: daysWithOverrides,
      };
    }
  ),

  lastBookingDateFormatted: computed('statistics.lastBookingDate', function () {
    let date = moment(this.statistics.lastBookingDate);
    let today = moment().startOf('day');
    if (Math.abs(date.diff(today, 'days')) >= 2) {
      return date.from(today);
    }
    return date.calendar().split(' ')[0].toLowerCase();
  }),

  furthestCheckinDateFormatted: computed('statistics.furthestCheckinDate', function () {
    let date = moment(this.statistics.furthestCheckinDate);
    let today = moment().startOf('day');
    if (Math.abs(date.diff(today, 'days')) >= 2) {
      return date.from(today);
    }
    return date.calendar().split(' ')[0].toLowerCase();
  }),

  // bookingReview
  alreadyReviewedBookingReviews: computed(
    'bookingReviewListings.@each.status',
    function () {
      if (!this.bookingReviewListings.filter(b => b).length) {
        return this.bookingReviewListings.filter(b => b);
      }

      return this.bookingReviewListings
        .filter(b => b)
        .filter(brArr => brArr.isAlreadyReviewed);
    }
  ),

  previousChanges: computed(
    'alreadyReviewedBookingReviews.@each.status',
    'basePriceHistoryV2.[]',
    'minPriceHistoryV2.[]',
    function () {
      const basePriceWithLabel = this.basePriceHistoryV2.map(bp =>
        Object.assign({}, bp, { type: 'basePrice' })
      );

      const minPriceWithLabel = this.minPriceHistoryV2.map(mp =>
        Object.assign({}, mp, { type: 'minPrice' })
      );

      const combinedPrices = [
        ...this.alreadyReviewedBookingReviews,
        ...basePriceWithLabel,
        ...minPriceWithLabel,
      ];

      // booking reviews – group by date
      const bookingReviewsGroupedByDate = combinedPrices.reduce((accum, br) => {
        let date;
        if (Object.prototype.hasOwnProperty.call(br, 'createdAt')) {
          date = moment(br.createdAt).format('MMM DD, YYYY');
        } else {
          date = moment(br.stamp).format('MMM DD, YYYY');
        }
        if (!Object.prototype.hasOwnProperty.call(accum, date)) {
          accum[date] = [br];
        } else {
          accum[date].push(br);
        }
        return accum;
      }, {});

      // convert back to array & sort by date in desc order
      return Object.keys(bookingReviewsGroupedByDate)
        .map(key => {
          return { date: key, bookingReviewListings: bookingReviewsGroupedByDate[key] };
        })
        .sort((a, b) => moment(b.date).diff(a.date));
    }
  ),

  bookingReviewListingsSorted: computed('bookingReviewListings.[]', function () {
    if (this.get('bookingReviewListings').filter(b => b).length === 0) return;

    return this.get('bookingReviewListings').sort((a, b) =>
      moment(b.createdAt).diff(a.createdAt)
    );
  }),

  mostRecentBookingReview: computed('bookingReviewListingsSorted.[]', function () {
    if (!this.get('bookingReviewListingsSorted.length')) return;
    return this.get('bookingReviewListingsSorted')[0];
  }),

  removeMostRecentBookingReview() {
    this.get('bookingReviewListingsSorted').removeAt(0);
  },

  lastBookingReviewForTableView: computed(
    'bookingReviewListingsSorted.[]',
    function () {
      if (!this.get('bookingReviewListingsSorted.length')) {
        return;
      }
      const result = this.get('bookingReviewListingsSorted').filter(
        br => br && (br.hasPendingSuggestion || br.isSuggestionCompleted)
      );
      return result?.[0];
    }
  ),

  suggestionAdded: computed(
    'mostRecentBookingReview.{isSuggestionCompleted,hasPendingSuggestion}',
    function () {
      const isSuggestionCompleted = this.mostRecentBookingReview?.isSuggestionCompleted;
      const hasPendingSuggestion = this.mostRecentBookingReview?.hasPendingSuggestion;

      return isSuggestionCompleted || hasPendingSuggestion;
    }
  ),

  basePriceSuggestionAdded: computed(
    'suggestionAdded',
    'mostRecentBookingReview.{suggestedBasePrice,initialBasePrice}',
    function () {
      const suggestedBasePrice = this.mostRecentBookingReview?.suggestedBasePrice;
      const initialBasePrice = this.mostRecentBookingReview?.initialBasePrice;

      if (this.suggestionAdded && !suggestedBasePrice) return false;
      if (suggestedBasePrice === initialBasePrice) return false;
      return true;
    }
  ),

  minPriceSuggestionAdded: computed(
    'suggestionAdded',
    'mostRecentBookingReview.{suggestedMinPrice,initialMinPrice}',
    function () {
      const mostRecentBookingReview = get(this, 'mostRecentBookingReview');

      if (!mostRecentBookingReview) {
        return false;
      }

      const { suggestedMinPrice, initialMinPrice } = mostRecentBookingReview;

      if (this.suggestionAdded && !suggestedMinPrice) return false;
      if (suggestedMinPrice === initialMinPrice) return false;
      return true;
    }
  ),

  // projections
  geocodeCollisionsHistory: null,
  geocodeCollisionsUpdatedAt: null,
  geocodeCollisions: 0,

  // Meta
  _basePrice: null,
  basePrice: computed('_basePrice', {
    get() {
      return Number(this._basePrice) || null;
    },
    set(key, value) {
      this.set('_basePrice', Number(value) || null);
      return this._basePrice;
    },
  }),

  _maxPrice: null,
  maxPrice: computed('_maxPrice', {
    get() {
      return Number(this._maxPrice) || null;
    },
    set(key, value) {
      this.set('_maxPrice', Number(value) || null);
      return this._maxPrice;
    },
  }),

  _minPrice: null,
  minPrice: computed('_minPrice', {
    get() {
      return Number(this._minPrice) || null;
    },
    set(key, value) {
      this.set('_minPrice', Number(value) || null);
      return this._minPrice;
    },
  }),

  _monthlyMinPrice: null,
  monthlyMinPrice: computed('_monthlyMinPrice', {
    get() {
      return Number(this._monthlyMinPrice) || null;
    },
    set(key, value) {
      this.set('_monthlyMinPrice', Number(value) || null);
      return this._monthlyMinPrice;
    },
  }),

  validationErrors: EmberObject.create(),
  isValid: computed('validationErrors', function () {
    return Object.keys(this.validationErrors).length === 0;
  }),

  allChannelLabels: computed('channelListing.@each.channelLabel', function () {
    const channels = this.channelListings.map(cl => cl.get('channelLabel'));
    if (channels.length === 1) {
      return channels[0];
    }
    const last = channels.pop();
    return channels.join(', ') + ' & ' + last;
  }),

  validateInput() {
    this.set('validationErrors', EmberObject.create());

    let minPrice = this.minPrice;
    let maxPrice = this.maxPrice;
    let basePrice = this.basePrice;
    let currency = this.currency;

    if (minPrice && maxPrice) {
      if (minPrice > maxPrice) {
        this.set(
          'validationErrors.minPrice',
          this.intl.t('validation.pricingErrors.minPriceHighterThanMaxPrice', {
            value: CurrencyUtil.format(maxPrice, {
              currency,
            }),
          })
        );
      }
    }

    if (basePrice < 10) {
      this.set(
        'validationErrors.basePrice',
        this.intl.t('validation.pricingErrors.minBasePrice', {
          value: CurrencyUtil.format(10, {
            currency,
          }),
        })
      );
    }

    if (minPrice < 5) {
      this.set(
        'validationErrors.minPrice',
        this.intl.t('validation.pricingErrors.minMinPrice', {
          value: CurrencyUtil.format(5, {
            currency,
          }),
        })
      );
    }

    // Figure out if some channel listings cannot be synced
    if (this.enabled && !this.get('_dirty.enabled')) {
      const unlistedChannelListings = this.channelListings.filter(
        cl => !cl.get('listed')
      );

      if (unlistedChannelListings.length > 0) {
        let channels = unlistedChannelListings
          .map(cl => cl.get('channelLabel'))
          .join(', ');
        let errorMessage = `You must activate your listing on ${channels} to be able to sync prices.`;
        this.set('validationErrors.channelListings', errorMessage);
      }
    }

    return this.validationErrors;
  },

  // Only offer to save prices when things have changed.
  pricesDirty: computed(
    'basePrice',
    '_dirty.{basePrice,minPrice,maxPrice,monthlyMinPrice}',
    'minPrice',
    'maxPrice',
    'monthlyMinPrice',
    function () {
      if (this.basePrice !== this.get('_dirty.basePrice')) {
        return true;
      }
      if (this.minPrice !== this.get('_dirty.minPrice')) {
        return true;
      }
      if (this.maxPrice !== this.get('_dirty.maxPrice')) {
        return true;
      }
      if (this.monthlyMinPrice !== this.get('_dirty.monthlyMinPrice')) {
        return true;
      }
      return false;
    }
  ),

  // Permissions
  canEdit: computed('permissions', function () {
    return this.permissions === 'edit' || this.permissions === 'admin';
  }),

  canAdministrate: equal('permissions', 'admin'),

  _momentCasts: on('init', function () {
    this.set('calendar', []);
    let lastUpdated = this.lastUpdated;
    if (lastUpdated && !lastUpdated._isAMomentObject) {
      this.set('lastUpdated', moment(lastUpdated));
    }
  }),

  async saveWithValidation() {
    let result = this.validateInput();

    if (Object.keys(result).length !== 0) {
      for (let key of Object.keys(result)) {
        this.alert.error(result[key], { timeout: 10000 });
      }
      return false;
    }

    try {
      await this.save();
    } catch (errors) {
      displayErrors({ errors: errors, modelOrKeywordThis: this, alert: alert });
      return false;
    }

    return true;
  },

  // Merge
  selected: false,

  cleanAddress: computed('address', function () {
    return this._cleanStr(this.address);
  }),

  cleanTitle: computed('title', function () {
    return this._cleanStr(this.title);
  }),

  isListed: computed('channelListings.@each.listed', function () {
    return this.channelListings?.some(cl => cl.listed);
  }),
  isNewListing: computed(
    'createdAt',
    'isListed',
    'inActiveMarket',
    'basePriceHistoryV2',
    'minPriceHistoryV2',
    function () {
      // This is the moment we launched this feature
      // Some users have a ton of "new listings" that they've just never set up.
      // We'll want to let users dismiss these for a few reasons eventually
      // but for now we can keep the dashboard clean this way
      if (moment.utc(this.createdAt) < moment.utc('2020-03-01')) {
        return false;
      }
      if (!this.isListed || !this.inActiveMarket) {
        return false;
      }
      return !(this.basePriceHistoryV2.length || this.minPriceHistoryV2.length);
    }
  ),
  directEnabled: false,
  pricingType: 2,

  _cleanStr(str = null) {
    if (!str) {
      return str;
    }
    return str
      .toLowerCase() // convert to lowercase
      .replace(/\s/g, '') // remove all spaces
      .replace(/[^\w\s]/gi, ''); // remove all special characters
  },
});
