import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Changeset } from 'ember-changeset';

export const DEFAULT_FILTERS_PRESET = {
  selectedMarketNames: [],
  selectedClusterNames: [],
  selectedAccountsByName: [],
  selectedListingsToInclude: [],
  selectedListingsToExclude: [],
  selectedBedrooms: [],
  selectedSavedFilter: null,
  healthScoreMin: null,
  healthScoreMax: null,
  basePriceMin: null,
  basePriceMax: null,
  selectedOriginForRadius: null,
  distanceMin: null,
  distanceMax: null,
  showEnabled: true,
  showDisabled: true,
  monthlyPricePostingEnabled: true,
  monthlyPricePostingDisabled: true,
  hideUnavailable: false,
  selectedCategory1Names: [],
  selectedCategory2Names: [],
};
export class BaseFiltersState {
  initialState;

  constructor(defaultFilters = BaseFiltersState.defaultPreset) {
    this.initialState = { ...defaultFilters };
  }

  // Selected options
  @tracked selectedMarketNames = this.initialState.selectedMarketNames;
  @tracked selectedClusterNames = this.initialState.selectedClusterNames;
  @tracked selectedAccountsByName = this.initialState.selectedAccountsByName;
  @tracked selectedListingsToInclude = this.initialState.selectedListingsToInclude;
  @tracked selectedListingsToExclude = this.initialState.selectedListingsToExclude;
  @tracked selectedBedrooms = this.initialState.selectedBedrooms;
  @tracked selectedSavedFilter = this.initialState.selectedSavedFilter;
  @tracked healthScoreMin = this.initialState.healthScoreMin;
  @tracked healthScoreMax = this.initialState.healthScoreMax;
  @tracked basePriceMin = this.initialState.basePriceMin;
  @tracked basePriceMax = this.initialState.basePriceMax;
  @tracked selectedOriginForRadius = this.initialState.selectedOriginForRadius;
  @tracked distanceMin = this.initialState.distanceMin;
  @tracked distanceMax = this.initialState.distanceMax;
  @tracked showEnabled = this.initialState.showEnabled;
  @tracked showDisabled = this.initialState.showDisabled;
  @tracked monthlyPricePostingEnabled = this.initialState.monthlyPricePostingEnabled;
  @tracked monthlyPricePostingDisabled = this.initialState.monthlyPricePostingDisabled;
  @tracked hideUnavailable = this.initialState.hideUnavailable;
  @tracked selectedCategory1Names = this.initialState.selectedCategory1Names;
  @tracked selectedCategory2Names = this.initialState.selectedCategory2Names;

  static get defaultPreset() {
    return { ...DEFAULT_FILTERS_PRESET };
  }

  toJSON() {
    return {
      selectedMarketNames: this.selectedMarketNames,
      selectedClusterNames: this.selectedClusterNames,
      selectedAccountsByName: this.selectedAccountsByName,
      selectedListingsToInclude: this.selectedListingsToInclude,
      selectedListingsToExclude: this.selectedListingsToExclude,
      selectedBedrooms: this.selectedBedrooms,
      selectedSavedFilter: this.selectedSavedFilter,
      healthScoreMin: this.healthScoreMin,
      healthScoreMax: this.healthScoreMax,
      basePriceMin: this.basePriceMin,
      basePriceMax: this.basePriceMax,
      selectedOriginForRadius: this.selectedOriginForRadius,
      distanceMin: this.distanceMin,
      distanceMax: this.distanceMax,
      showEnabled: this.showEnabled,
      showDisabled: this.showDisabled,
      monthlyPricePostingEnabled: this.monthlyPricePostingEnabled,
      monthlyPricePostingDisabled: this.monthlyPricePostingDisabled,
      hideUnavailable: this.hideUnavailable,
      selectedCategory1Names: this.selectedCategory1Names,
      selectedCategory2Names: this.selectedCategory2Names,
    };
  }

  getSelectedServices(accounts) {
    let services = this.selectedAccountsByName ?? [];
    return _mapNamesToId(services, accounts);
  }
}

// Base mixin for a service that holds the filter's state
// Receives an object of tipo FiltersState
const FiltersStateService = FiltersState =>
  class extends Service {
    @tracked state = new FiltersState();
    @tracked appliedState = Changeset(this.state);
    @tracked allChanges = this.appliedState;

    clearFilters(defaultFilters) {
      this.state = defaultFilters
        ? new FiltersState(defaultFilters)
        : new FiltersState();

      this.appliedState = Changeset(this.state);
      this.allChanges = this.appliedState;
    }

    getInitialState() {
      return new FiltersState();
    }

    get hasFiltersApplied() {
      return (
        this.allChanges?.isDirty &&
        // Filter out empty arrays and null values from changes
        this.allChanges.changes.filter(c =>
          Array.isArray(c.value) ? c.value.length > 0 : c.value != null
        ).length > 0
      );
    }

    get changesCount() {
      return this.allChanges.changes.filter(c =>
        Array.isArray(c.value)
          ? c.value.length > 0
          : c.value != null && c.key !== 'selectedSavedFilter'
      ).length;
    }

    @action
    async save(changeSet) {
      if (changeSet.reset) {
        this.clearFilters();
      } else {
        this.allChanges = this.allChanges.merge(changeSet);
        await changeSet.execute();
        this.appliedState = changeSet;
      }
      this.state = this.state;
      return this.state;
    }
  };

function _mapNamesToId(names, accounts) {
  let ids = [];
  let modelName, modelChannel, target;

  for (let i = 0; i < names.length; i++) {
    for (let j = 0; j < accounts.length; j++) {
      modelName = accounts[j].channelDisplayId;
      modelChannel = accounts[j].channel;
      target = _trimName(names[i]);
      if (target.name === modelName && target.channel === modelChannel) {
        ids.push(accounts[j].id);
        break;
      }
    }
  }
  return ids;
}

function _trimName(nameToTrim) {
  let accountName = nameToTrim.split(' — ');
  return { name: accountName[0], channel: accountName[1] };
}

export default FiltersStateService;
