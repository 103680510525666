import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @searchEnabled}}\n  <div class='w-full'>\n    <input\n      type='text'\n      autocomplete='off'\n      autocorrect='off'\n      autocapitalize='off'\n      spellcheck={{false}}\n      role='combobox'\n      class={{this.classes}}\n      value={{@select.searchText}}\n      aria-controls={{@listboxId}}\n      placeholder={{@searchPlaceholder}}\n      {{on 'input' @onInput}}\n      {{on 'focus' @onFocus}}\n      {{on 'blur' @onBlur}}\n      {{on 'keydown' this.handleKeydown}}\n      {{did-insert this.focusInput}}\n      {{will-destroy this.clearSearch}}\n    />\n  </div>\n{{/if}}", {"contents":"{{#if @searchEnabled}}\n  <div class='w-full'>\n    <input\n      type='text'\n      autocomplete='off'\n      autocorrect='off'\n      autocapitalize='off'\n      spellcheck={{false}}\n      role='combobox'\n      class={{this.classes}}\n      value={{@select.searchText}}\n      aria-controls={{@listboxId}}\n      placeholder={{@searchPlaceholder}}\n      {{on 'input' @onInput}}\n      {{on 'focus' @onFocus}}\n      {{on 'blur' @onBlur}}\n      {{on 'keydown' this.handleKeydown}}\n      {{did-insert this.focusInput}}\n      {{will-destroy this.clearSearch}}\n    />\n  </div>\n{{/if}}","moduleName":"appkit/components/bp/select/parts/before-options/index.hbs","parseOptions":{"srcName":"appkit/components/bp/select/parts/before-options/index.hbs"}});
import Component from '@glimmer/component';
import { scheduleOnce, next } from '@ember/runloop';
import { action } from '@ember/object';
import { Themeable, VARIANTS } from '../../../theme';
import BeforeOptionsTheme from './index.theme';

// Use the same font and spacing classes as options for consistency
export default class BeforeOptions extends Themeable(Component, {
  theme: BeforeOptionsTheme,
  variant: VARIANTS.TEXT,
}) {
  @action
  clearSearch() {
    scheduleOnce('actions', this.args.select.actions, 'search', '');
  }

  @action
  handleKeydown(e) {
    if (this.args.onKeydown(e) === false) {
      return false;
    }
    if (e.keyCode === 13) {
      this.args.select.actions.close(e);
    }
  }

  @action
  focusInput(el) {
    next(() => {
      if (this.args.autofocus !== false) {
        el.focus();
      }
    });
  }
}
