import AuthRoute from 'appkit/routes/auth';
import { showBanner } from 'appkit/lib/upsell/signal';
import { inject as service } from '@ember/service';

export default class FormRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service intl;
  @service featureFlag;

  isSurfaceSignalInAppEnabled = false;

  title() {
    let title = null;
    title = `${this.intl.t('glossary.productSignal')} / ${this.intl.t(
      'signal.registerInterest'
    )}`;
    this.globalNav.setTitle(title);
    return title;
  }

  async beforeModel() {
    this.isSurfaceSignalInAppEnabled = await this.featureFlag.evaluate(
      'surface-signal-in-app',
      false
    );
  }

  afterModel(model) {
    if (!showBanner(model.accounts, model.user) || !this.isSurfaceSignalInAppEnabled) {
      return this.transitionTo('dashboard');
    }
  }
}
