import { helper } from '@ember/component/helper';

export function percentage(
  [value],
  { defaultValue = 0, decimalPlaces = 2 } = { defaultValue: 0, decimalPlaces: 2 }
) {
  value = value ?? defaultValue;

  if (isNaN(parseFloat(value))) {
    return '0';
  }

  return (
    Math.round(parseFloat(value) * 100 * 10 ** decimalPlaces) / 10 ** decimalPlaces
  );
}

export default helper(percentage);
