import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('app-dashboard-merge-suggestion')
export default class AppDashboardMergeSuggestion extends Component {
  // -- Parameters ---------------------------------------------------------------------------------
  listings = null; // @type {Array}

  index = null; // @type {Number}
  appearance = null; // @type {String}
}
