import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { decamelize } from '@ember/string';

export default class SavedFiltersApiService extends Service {
  @service alert;
  @service ajax;
  @service embed;

  async saveNewFilter({ name, content }) {
    let url = '/api/filters';

    try {
      await this.ajax._post(url, {
        name,
        content,
      });
      this.embed.czCaptureEvent(
        'New Customer Filter Pricing',
        'User created a new custom filter at the pricing dashboard'
      );
      this.alert.success('New filter successfully saved');
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      throw errors;
    }
  }

  async renameFilter(savedFilter, { name }) {
    const id = savedFilter.id;
    let url = `/api/filters/${id}`;

    try {
      await this.ajax._put(url, {
        id,
        name,
        name_only: true,
      });
      this.alert.success('Filter successfully updated');
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      throw errors;
    }
  }

  async editFilter(savedFilter, { content }) {
    const { id, name } = savedFilter;
    let url = `/api/filters/${id}`;

    const oldContent = Object.entries(savedFilter.content).reduce((acc, cur) => {
      acc[decamelize(cur[0])] = cur[1];
      return acc;
    }, {});

    try {
      await this.ajax._put(url, {
        id,
        name,
        content: Object.assign({}, oldContent, content),
        name_only: false,
      });
      this.alert.success('Filter successfully updated');
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      throw errors;
    }
  }

  async deleteFilter(savedFilter) {
    const id = savedFilter.id;
    let url = `/api/filters/${id}`;

    try {
      await this.ajax._delete(url, { id });
      this.alert.success('Filter successfully deleted');
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      throw errors;
    }
  }
}
