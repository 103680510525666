import { getOwner } from '@ember/application';
import Controller from '@ember/controller';
import EmberObject, { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CurrencyUtil } from 'appkit/lib/currency';
import PAYMENT_METHOD from 'appkit/lib/payment-method';
import PAYMENT_PLAN from 'appkit/lib/payment-plan';
import classic from 'ember-classic-decorator';
import CREDIT_CARD_PROCESSOR from 'appkit/lib/credit-card-processor';

@classic
export default class BillingController extends Controller {
  @service
  intl;

  @service featureFlag;

  @service
  alert;

  posting = false;

  get sortedBillingCurrencies() {
    if (this.selectedPaymentMethod === PAYMENT_METHOD.ACH) {
      return [
        {
          value: 'USD',
          label: 'USD' + ' - ' + CurrencyUtil.getLabel('USD'),
          flag: CurrencyUtil.getFlag('USD'),
        },
      ];
    } else if (this.selectedPaymentMethod === PAYMENT_METHOD.PAYPAL) {
      return CurrencyUtil.getPaypalBillingCurrencies().map(c => ({
        value: c,
        label: c + ' - ' + CurrencyUtil.getLabel(c),
        flag: CurrencyUtil.getFlag(c),
      }));
    } else {
      return CurrencyUtil.getBillingCurrencies().map(c => ({
        value: c,
        label: c + ' - ' + CurrencyUtil.getLabel(c),
        flag: CurrencyUtil.getFlag(c),
      }));
    }
  }

  @tracked payOnStayExtraFee = 0.0049;

  @tracked
  isCurrencySupportedForPaypal = CurrencyUtil.getPaypalBillingCurrencies().includes(
    this.selectedBillingCurrency.value
  );

  @tracked
  isCurrencySupportedForACH = this.selectedBillingCurrency.value === 'USD';

  @tracked selectedBillingCurrency = this.sortedBillingCurrencies.find(
    currency => currency.value === this.model.billingCurrency
  );

  @tracked isClearToProceed = true;
  @tracked isAddingPaymentMethod = false;

  @tracked isSaving = false;
  @tracked selectedCard = this.model.cards.find(card => card.isPrimary);

  @tracked paymentPlans = [
    {
      label: this.intl.t('admin.payOnBook'),
      plan: PAYMENT_PLAN.PAY_ON_BOOK,
      isPopular: !this.model.sfAccountId,
      value:
        Math.round(
          (Number((this.model.pricingPercentage + this.guidancePercentage) * 100) +
            Number.EPSILON) *
            100
        ) / 100,
      description: this.intl.t('admin.payOnBookDescription'),
      isSelected: this.model.paymentPlan === PAYMENT_PLAN.PAY_ON_BOOK,
    },
    {
      label: this.intl.t('admin.payOnStay'),
      plan: PAYMENT_PLAN.PAY_ON_STAY,
      isPopular: false,
      value: this.payOnStayPercentage,
      description: this.intl.t('admin.payOnStayDescription'),
      isSelected: this.model.paymentPlan === PAYMENT_PLAN.PAY_ON_STAY,
    },
  ];

  @tracked hasAvailablePaymentPlansVisible = false;
  @tracked selectedPaymentPlan = this.paymentPlans.find(plan => plan.isSelected);

  @tracked isChangePlanControlAvailable =
    this.selectedPaymentPlan?.plan === this.model.paymentPlan;

  @tracked hasPaymentMethod;

  @tracked hasAddPaymentMethodDialogVisible;

  @tracked selectedPaymentMethod = this.model.defaultPaymentMethod;

  @tracked showModalWarningCreditCard = false;

  init() {
    super.init(...arguments);

    this.featureFlag.evaluate('pay-on-stay-rate', 0.0049).then(value => {
      this.payOnStayExtraFee = value;
    });
  }

  get isUserCurrencySupportedForTally() {
    return this.model.billingCurrency === 'USD';
  }

  get guidancePercentage() {
    let guidancePercentage = this.model.guidancePercentage ?? 0.005;
    return this.model.sfActiveGuidance ? guidancePercentage : 0;
  }

  @computed('model.defaultPaymentMethod')
  get showPayPal() {
    return this.get('model.defaultPaymentMethod') === PAYMENT_METHOD.PAYPAL;
  }

  @computed('model.defaultPaymentMethod')
  get showACH() {
    return this.get('model.defaultPaymentMethod') === PAYMENT_METHOD.ACH;
  }

  get payOnStayPercentage() {
    const percentage =
      (this.model.pricingPercentage +
        this.payOnStayExtraFee +
        this.guidancePercentage) *
      100;
    return Math.round((percentage + Number.EPSILON) * 100) / 100;
  }

  get paymentMethodChangesSaveDisabled() {
    if (this.selectedBillingCurrency.value !== this.model.billingCurrency) return false;
    if (this.model.defaultPaymentMethod !== this.selectedPaymentMethod) return false;
    if (this.model.cards.find(card => card.isPrimary)?.id !== this.selectedCard?.id)
      return false;
    if (
      this.model.paymentPlan !== this.paymentPlans.find(plan => plan.isSelected)?.plan
    )
      return false;
    return true;
  }

  @computed('model')
  get endOfTrial() {
    let isBillingEnabled = this.get('model.isBillingEnabled');

    // Try to guess it from the statements only if billing is enabled
    if (isBillingEnabled) {
      let lastStatement = this.get('model.statements.0');
      if (lastStatement && !lastStatement.chargeable) {
        return window.DateUtil.format(lastStatement.endDate, {
          fmt: 'MMM DD, YYYY',
          utc: true,
        });
      }
    }

    // Default
    return this.intl.t('admin.endOfTrial');
  }

  @computed('model.referrerCode')
  get referrerLink() {
    let referrerCode = this.get('model.referrerCode');
    return `https://beyondpricing.com/#r=${referrerCode}`;
  }

  @computed('model.billing')
  get showAddCreditCardNotice() {
    return (
      parseFloat(this.get('model.billing.runningBalance')) > 0 &&
      this.get('model.isRunningBalanceEnabled')
    );
  }

  @action updateSupportedCurrencies() {
    this.isCurrencySupportedForPaypal = CurrencyUtil.getPaypalBillingCurrencies().includes(
      this.selectedBillingCurrency.value
    );
    this.isCurrencySupportedForACH = this.selectedBillingCurrency.value === 'USD';
  }

  @action
  onAddingPaymentMethod(checking) {
    this.isAddingPaymentMethod = checking;
  }

  @action
  dismissCurrencyChanges() {
    this.selectedBillingCurrency = this.sortedBillingCurrencies.find(
      c => c.value === this.model.billingCurrency
    );
    this.updateSupportedCurrencies();
  }

  @action
  onCardDeleted(cards) {
    set(
      this,
      'model.cards',
      cards?.map(card => EmberObject.create(card))
    );
  }

  @action
  onCardSelected(card, paymentMethod) {
    this.selectedCard = card;
    this.selectedPaymentMethod = paymentMethod;
  }

  @action
  isCreditCardSelected(card) {
    if (!this.selectedCard) return false;

    return (
      card.id === this.selectedCard.id &&
      this.isPaymentMethodCreditCard(this.selectedPaymentMethod)
    );
  }

  @action
  resetPaymentMethodSelection() {
    this.selectedCard = this.model.cards.find(card => card.isPrimary);
    this.selectedPaymentMethod = this.model.defaultPaymentMethod;
  }

  @action
  resetPaymentPlanSelection() {
    this.paymentPlans = this.paymentPlans.map(p => ({
      ...p,
      isSelected: p.plan === this.model.paymentPlan,
    }));

    this.changePaymentPlan(this.paymentPlans.find(plan => plan.isSelected).plan);
    this.isChangePlanControlAvailable = true;
  }

  @action
  changePaymentPlan(plan) {
    if (this.model.sfAccountId != null) return;

    this.paymentPlans = this.paymentPlans.map(p => ({
      ...p,
      isSelected: p.plan === plan,
    }));

    this.selectedPaymentPlan = this.paymentPlans.find(plan => plan.isSelected);

    if (this.model.paymentPlan === this.selectedPaymentPlan.plan) return;

    this.hasAvailablePaymentPlansVisible = false;
    this.isChangePlanControlAvailable = false;
  }

  // TODO: this is psuedocode until we get the referral links back in.
  @action
  selectText(el) {
    el = this.element.querySelector('span.selectable');
    let range = document.createRange();
    range.selectNode(el);
    window.getSelection().addRange(range);
    return true;
  }

  @action
  changePaymentMethod(paymentMethod) {
    this.selectedPaymentMethod = paymentMethod;
  }

  @action
  async updateBillingCurrency() {
    try {
      await this.ajax._post('/api/user/update_billing_currency', {
        currency: this.selectedBillingCurrency.value,
      });
    } catch (errors) {
      this.selectedBillingCurrency = this.sortedBillingCurrencies.find(
        currency => currency.value === this.model.billingCurrency
      );
      let errorMessage =
        errors.message || this.intl.t('validation.adminErrors.changeCurrencyError');
      this.alert.error(errorMessage, { timeout: 10000 });
      return false;
    }
    return true;
  }

  isPaymentMethodCreditCard(paymentMethod) {
    return [PAYMENT_METHOD.CREDIT_CARD, PAYMENT_METHOD.BEYOND_CREDIT_CARD].includes(
      paymentMethod
    );
  }

  @action
  async updatePayment() {
    let result;
    let selectedCard = this.selectedCard;
    let selectedPaymentMethod = this.selectedPaymentMethod;
    const isSelectedCreditCard = this.isPaymentMethodCreditCard(selectedPaymentMethod);
    let payload = {
      paymentMethod: selectedPaymentMethod,
      ...(isSelectedCreditCard && {
        primaryCardId: selectedCard.id,
      }),
    };
    try {
      result = await this.ajax._put('/api/billing/payment_method', payload);
    } catch (errors) {
      this.resetPaymentMethodSelection();
      let errorMessage =
        errors.message || this.intl.t('validation.adminErrors.changeCardError');
      this.alert.error(errorMessage, { timeout: 10000 });
      return [false, result];
    }
    return [true, result];
  }

  @action
  async updatePaymentPlan() {
    try {
      await this.ajax._post('/api/billing/payment_plan', {
        paymentPlan: this.selectedPaymentPlan.plan,
      });
    } catch (errors) {
      const alert = this.alert;
      this.resetPaymentPlanSelection();
      let errorMessage =
        errors.message || this.intl.t('validation.adminErrors.changePaymentPlanError');
      alert.error(errorMessage, { timeout: 10000 });
      return false;
    }
    return true;
  }

  @action
  async savePaymentMethodChanges() {
    if (this.isSaving) return;

    if (
      this.isPaymentMethodCreditCard(this.selectedPaymentMethod) &&
      this.selectedBillingCurrency.value !== 'USD' &&
      this.selectedCard &&
      this.selectedCard.processor === CREDIT_CARD_PROCESSOR.BPG
    ) {
      this.showModalWarningCreditCard = true;
      return;
    }

    this.isSaving = true;

    let hasChangePaymentPlan = true;

    if (this.model.paymentPlan !== this.selectedPaymentPlan.plan) {
      hasChangePaymentPlan = await this.updatePaymentPlan();

      set(this, 'model.paymentPlan', this.selectedPaymentPlan.plan);
      this.isChangePlanControlAvailable = true;
      this.selectedPaymentPlan = this.selectedPaymentPlan;
    }

    let hasChangeBillingCurrency = true;

    if (this.selectedBillingCurrency.value !== this.model.billingCurrency) {
      hasChangeBillingCurrency = await this.updateBillingCurrency();

      set(this, 'model.billingCurrency', this.selectedBillingCurrency.value);
      this.selectedBillingCurrency = this.selectedBillingCurrency;
    }

    let hasChangePaymentMethod = true;
    let result;

    if (
      this.selectedPaymentMethod !== this.model.defaultPaymentMethod ||
      this.model.cards.find(card => card.isPrimary)?.id !== this.selectedCard?.id
    ) {
      [hasChangePaymentMethod, result] = await this.updatePayment();

      if (hasChangePaymentMethod) {
        set(this, 'model.defaultPaymentMethod', this.selectedPaymentMethod);

        if (result.cards) {
          set(
            this,
            'model.cards',
            result.cards.map(card => EmberObject.create(card))
          );
        } else {
          set(
            this,
            'model.cards',
            this.model.cards.map(c => ({
              ...c,
              isPrimary: c.id === this.selectedCard.id,
            }))
          );
        }
      }

      this.selectedPaymentMethod = this.model.defaultPaymentMethod;
      this.selectedCard = this.model.cards.find(card => card.isPrimary);
    }

    this.isSaving = false;

    let successMessage = this.intl.t('admin.somePaymentMethodsChangesSaved');

    if (hasChangeBillingCurrency && hasChangePaymentMethod && hasChangePaymentPlan) {
      successMessage = this.intl.t('admin.paymentMethodsChangesSaved');
    }

    this.alert.success(successMessage, { timeout: 10000 });
    getOwner(this).lookup('route:dashboard.user').refresh();
  }

  @action
  copyToClipboardReferralProgramLink() {
    let referralProgramLink = document.getElementById('referralProgramLink');
    referralProgramLink.select();
    referralProgramLink.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
  }

  get isMissingStripeCard() {
    if (
      this.isPaymentMethodCreditCard(this.selectedPaymentMethod) &&
      this.selectedBillingCurrency.value !== 'USD'
    ) {
      if (
        this.selectedCard &&
        this.selectedCard.processor !== CREDIT_CARD_PROCESSOR.STRIPE
      ) {
        return true;
      }
    }
    return false;
  }

  @action
  onCancelModalWarningCreditCard() {
    this.showModalWarningCreditCard = false;
  }

  @action
  onConfirmModalWarningCreditCard() {
    this.selectedBillingCurrency = this.sortedBillingCurrencies.find(
      currency => currency.value === 'USD'
    );
    this.showModalWarningCreditCard = false;
    (async () => await this.savePaymentMethodChanges())();
  }

  @action
  isAlertLastStripeCard(cardChecked) {
    if (
      this.selectedBillingCurrency.value === 'USD' ||
      cardChecked.processor === CREDIT_CARD_PROCESSOR.BPG
    ) {
      return false;
    }
    const existsStripe = this.model.cards.find(
      card =>
        card.processor === CREDIT_CARD_PROCESSOR.STRIPE && card.id !== cardChecked.id
    );
    const existsTally = this.model.cards.find(
      card => card.processor === CREDIT_CARD_PROCESSOR.BPG && card.id !== cardChecked.id
    );
    return existsTally && !existsStripe;
  }
}
