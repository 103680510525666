import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class CurrentUserController extends Controller {
  staffUser = null;
  isStaffView = null;

  @computed
  get token() {
    return localStorage.getItem('token');
  }

  set token(value) {
    if (value) {
      localStorage.setItem('token', value);
    } else {
      localStorage.removeItem('token');
    }
    return localStorage.getItem('token');
  }

  @computed
  get username() {
    return localStorage.getItem('username')?.toLowerCase();
  }

  set username(value) {
    if (value) {
      localStorage.setItem('username', value.toLowerCase());
    } else {
      localStorage.removeItem('username');
    }
    return localStorage.getItem('username');
  }

  @computed
  get ldEmail() {
    return localStorage.getItem('ldEmail')?.toLowerCase();
  }

  set ldEmail(value) {
    if (value) {
      localStorage.setItem('ldEmail', value.toLowerCase());
    } else {
      localStorage.removeItem('ldEmail');
    }
    return localStorage.getItem('ldEmail');
  }

  set userId(value) {
    if (value) {
      localStorage.setItem('userId', value);
    } else {
      localStorage.removeItem('userId');
    }
    return localStorage.getItem('userId');
  }

  @computed
  get userId() {
    let userId = localStorage.getItem('userId');

    return userId ? parseInt(userId, 10) : userId;
  }

  @computed('isStaffView', 'staffUser')
  get isStaff() {
    // If the user used the staff banner return the override
    if (this.isStaffView != null) {
      return this.isStaffView;
    }

    // else if we're impersonating we're staff
    if (this.isImpersonating) {
      return true;
    }

    // otherwise fallback to check localstorage
    return localStorage.getItem('isStaff') === 'true';
  }

  set isStaff(value) {
    localStorage.setItem('isStaff', value);
  }

  @computed('staffUser.impersonating')
  get isImpersonating() {
    return !!this.staffUser?.impersonating;
  }

  @computed
  get isSuper() {
    return localStorage.getItem('isSuper') === 'true';
  }

  set isSuper(value) {
    if (typeof value === 'boolean') {
      localStorage.setItem('isSuper', value);
    } else {
      localStorage.removeItem('isSuper');
    }
    return localStorage.getItem('isSuper') === 'true';
  }

  @computed
  get isRelayEnabled() {
    return localStorage.getItem('isRelayEnabled') === 'true';
  }

  set isRelayEnabled(value) {
    if (typeof value === 'boolean') {
      localStorage.setItem('isRelayEnabled', value);
    } else {
      localStorage.removeItem('isRelayEnabled');
    }
    return localStorage.getItem('isRelayEnabled') === 'true';
  }

  @computed
  get globalPermission() {
    return localStorage.getItem('globalPermission');
  }

  set globalPermission(value) {
    if (value) {
      localStorage.setItem('globalPermission', value);
    } else {
      localStorage.removeItem('globalPermission');
    }
    return localStorage.getItem('globalPermission');
  }

  @computed
  get ldKey() {
    return localStorage.getItem('ldKey');
  }

  set ldKey(value) {
    if (value) {
      localStorage.setItem('ldKey', value);
    } else {
      localStorage.removeItem('ldKey');
    }
    return localStorage.getItem('ldKey');
  }

  @computed
  get ldSecureHash() {
    return localStorage.getItem('ldSecureHash');
  }

  set ldSecureHash(value) {
    if (value) {
      localStorage.setItem('ldSecureHash', value);
    } else {
      localStorage.removeItem('ldSecureHash');
    }
    return localStorage.getItem('ldSecureHash');
  }

  @alias('username')
  email;

  @computed('isStaff', 'isImpersonating')
  get isBookingReviewSubmitterEnabled() {
    return this.isStaff && this.isImpersonating;
  }

  clear() {
    this.set('userId', null);
    this.set('username', null);
    this.set('token', null);
    this.set('isStaff', null);
    this.set('isSuper', null);
    this.set('ldKey', null);
    this.set('ldEmail', null);
    this.set('ldSecureHash', null);
  }
}
