import { computed } from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';

export default BaseModel.extend({
  amount: 0,
  delta: 0,
  key: '',
  ratio: 1,
  reason: '',
  positive: computed('amount', function () {
    return this.amount > 0;
  }),
});
