export default {
  CREDIT_CARD: 1,
  CHECK: 2,
  BP_CREDIT: 3,
  NOT_APPLICABLE: 4,
  PAYPAL: 5,
  MONTHLY_CREDIT: 6,
  GHOST_CREDIT: 7,
  ACH: 8,
  BEYOND_CREDIT_CARD: 13,
  INITIAL_CREDIT: 14,
  DIRECT_DEBIT: 15,
};
