import Service from '@ember/service';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class StaffViewService extends Service {
  @tracked viewAsClient = false;

  get isImpersonating() {
    return this.currentUser.isImpersonating;
  }

  get isStaffView() {
    return !this.viewAsClient && this.currentUser.isStaff;
  }

  @action
  toggleStaffView(value) {
    if (value === undefined) {
      value = null;
    }
    this.viewAsClient = value;
    // Update current user so all legacy code relying on currentUser.isStaff keeps working
    set(this.currentUser, 'isStaffView', !value);
  }
}
