import { computed } from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';

export default BaseModel.extend({
  id: null,
  checkinDate: null,
  checkoutDate: null,
  bookedAt: null,
  reference: null,
  rentalAmount: 0,
  sourceChannel: '',
  nbNights: 0,
  isOwner: null,
  adr: 0,

  parsedSourceChannel: computed('sourceChannel', function () {
    const source = this.sourceChannel?.trim()?.toLowerCase();
    if (!source) {
      return '';
    }
    if (source.includes('airbnb')) {
      return 'airbnb';
    }
    if (['homeaway', 'vrbo'].some(channel => source.includes(channel))) {
      return 'homeaway';
    }
    return '';
  }),
});
