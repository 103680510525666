import classic from 'ember-classic-decorator';
import { classNames, classNameBindings } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('alert')
@classNameBindings('model._type', 'dismissing')
export default class AppAlert extends Component {
  layoutName = 'components/simple-yield';

  @computed('model.timeout')
  get displayTime() {
    return this.get('model.timeout') || 5000;
  }

  _dismissTimer = null;
  _transitionCount = 0;
  dismissing = false;
  model = null;

  init() {
    super.init(...arguments);

    this._dismissTimer = setTimeout(() => {
      // Tests think this is destroyed after a transition. It shouldn't be, because it's
      // in the application route, but more investigation is required.
      if (!this.isDestroyed) {
        this.send('dismiss');
      }
    }, this.displayTime);
  }

  didInsertElement() {
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.element.addEventListener('mouseenter', this.handleMouseEnter);
    this.element.addEventListener('mouseleave', this.handleMouseLeave);
  }

  willDestroyElement() {
    this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    this.element.removeEventListener('mouseleave', this.handleMouseLeave);
  }

  transitionEnd() {
    // Browser transition events fire once for each type of transition. In
    // this case, both margin-top and opacity will fire it. Would be much
    // nicer if there was a way to listen for the end of all transitions.
    let t = this.incrementProperty('_transitionCount');
    if (t !== 2) {
      return false;
    }

    this.action(this.model);
    return false;
  }

  handleMouseEnter() {
    // Want to reset back to visible if they hover over.
    this.set('dismissing', false);
    this.set('_transitionCount', 0);
    clearTimeout(this._dismissTimer);
    return false;
  }

  handleMouseLeave() {
    if (this.dismissing) {
      return;
    }

    this._dismissTimer = setTimeout(() => {
      if (!this.isDestroyed) {
        this.send('dismiss');
      }
    }, this.displayTime);
    return false;
  }

  @action
  dismiss() {
    clearTimeout(this._dismissTimer);
    // Make sure not to double dismiss if they clicked and the timeout fired.
    if (this.dismissing) {
      return false;
    }

    this.set('dismissing', true);
    return false;
  }
}
