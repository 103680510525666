import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';
import { startSentry } from 'appkit/lib/sentry';

startSentry();

import 'appkit/lib/util';
import 'appkit/lib/currency';
import 'appkit/lib/channel';
import 'appkit/lib/dateutil';
import 'appkit/lib/current_user';

const args = ['%c APP: ', 'background: #222; color: #bada55'];
console.color = console.log.bind(console, args[0], args[1]);

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
  customEvents = {
    webkitTransitionEnd: 'transitionEnd',
    transitionend: 'transitionEnd',
    msTransitionEnd: 'transitionEnd',
    oTransitionEnd: 'transitionEnd',
    mousewheel: 'mousewheel',
    input: 'input',
  };
}

loadInitializers(App, config.modulePrefix);
