import TriggerMultiple from '../../../../../bp/select/parts/trigger/multiple';

export default class SelectBedroomsTriggerComponent extends TriggerMultiple {
  get beforeSelectedItemComponent() {
    return 'insights/select/bedrooms/parts/before-selected-item';
  }

  // Sort selected items
  get selectedItems() {
    const selected = this.args.select?.selected ?? [];
    return selected.sort((a, b) => a - b);
  }
}
