import moment from 'moment';

/**
 * ChannelListingDateViewObjectMother
 *
 * Used to generate mocks on demand for the view, and for Unit Testing of use cases in FE
 *
 * - Examples in availablity-mock-cases.js
 */
export class ChannelListingDateViewObjectMother {
  label = null;
  availability = null;
  lastStatusUpdatedAt = null;
  lastScrapeAt = null;
  updateStamps = [];
  scrapStamps = [];

  referenceOperationDate = null;

  /**
   * Used as a base operation date to fake updates with a specified delay
   **/
  setReferenceOperationdate(date) {
    this.referenceOperationDate = moment(date);
    return this;
  }

  getReferenceOperationDate() {
    return this.referenceOperationDate || this.updateStamps[0] || moment();
  }

  setLabel(label) {
    this.label = label;
    return this;
  }

  setDate(date) {
    this.date = date;
    return this;
  }

  setAvailability(availability, timestamp, operationType) {
    if (availability !== this.availability) {
      this.availability = availability;
      this.lastStatusUpdatedAt = timestamp;

      this.updateStamps.push({
        channel: this.label,
        availability,
        timestamp,
        type: operationType,
      });
    }
    return this;
  }

  pushSyncUpdate(availability, delaySecs) {
    const timestamp = moment(this.getReferenceOperationDate()).add(delaySecs, 's');

    this.setAvailability(availability, timestamp, 'synced');
    return this;
  }

  pushScrapedUpdate(availability, delaySecs) {
    const timestamp = moment(this.getReferenceOperationDate()).add(delaySecs, 's');
    this.lastScrapeAt = timestamp;

    this.scrapStamps.push({
      channel: this.label,
      availability,
      timestamp,
    });

    this.setAvailability(availability, timestamp, 'pulled');
    return this;
  }
}
