import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class AppHover extends Component {
  targetComponent = 'app-hover/target';
  popupComponent = 'app-hover/popup';

  @tracked
  visible = false;

  @action
  handleMouseEnter() {
    this.visible = true;
  }

  @action
  handleMouseLeave() {
    this.visible = false;
  }
}
