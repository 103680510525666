import Controller from '@ember/controller';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { CurrencyUtil } from 'appkit/lib/currency';

class Errors {
  @tracked _cache = {};

  set(key, value) {
    this._cache[key] = value;
    this._cache = this._cache;
  }

  get(key) {
    return this._cache[key];
  }
}

export default class BulkUpdateController extends Controller {
  @tracked userId = '';
  @tracked targetCurrency = '';
  @tracked currencyOptions = CurrencyUtil.getBillingCurrencies();
  @tracked errors = new Errors();
  @tracked responseData = A([]);

  get hasErrors() {
    return Object.keys(this.errors).length > 0;
  }

  validate() {
    this.errors = new Errors();

    if (!this.userId) {
      this.errors.userId = 'User ID is required';
    }
    if (!this.targetCurrency) {
      this.errors.targetCurrency = 'Currency is required.';
    }

    return Object.keys(this.errors).length == 0;
  }

  @action
  async submit() {
    if (!this.validate()) {
      return;
    }
    this.saving = true;
    this.responseData = A([]);
    const url = `/api/internal-tools/change-currency`;
    let params = {
      userId: this.userId,
      targetCurrency: this.targetCurrency,
    };

    try {
      let responseData = await this.ajax._post(url, params);
      this.responseData.pushObject(responseData['message']);
    } catch (errors) {
      console.log(errors);
      let text = `Error: ${errors[0].title} - ${errors[0].source.message}`;
      this.responseData.pushObject(text);
      return;
    } finally {
      this.saving = false;
    }
  }
}
