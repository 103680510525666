import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import EmberObject from '@ember/object';

@classic
export default class ShowRoute extends Route {
  async model(params) {
    let url = `/api/reservations/${params.id}`;
    let responseData = await this.ajax._get(url);

    let reservation = this.bpStore.createRecord(
      'reservation',
      responseData.reservation
    );
    console.color('reservation is', reservation);
    return EmberObject.create({
      reservation: reservation,
    });
  }
}
