import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@classNames('app-sort-input')
export default class AppSortInput extends Component {
  // -- Parameters ---------------------------------------------------------------------
  @service('list-control')
  listControl;

  @service('booking-review')
  bookingReview;

  @action
  reverseSortOrder() {
    this.listControl.reverseSortOrder();
  }

  @action
  updateSortField(target) {
    this.listControl.updateSortField(target);
  }
}
