/* global UserLeap */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class UserleapService extends Service {
  @service currentUser;
  @tracked userId;
  @tracked email;

  initClient(opts) {
    if (window.UserLeap === undefined) {
      console.warn('UserLeap not loaded');
      return;
    }

    if (!(opts.userId && opts.email)) return;

    this.userId = opts.userId;
    this.email = opts.email;

    UserLeap('setUserId', opts.userId);
    UserLeap('setEmail', opts.email);
  }

  trackEvent(eventId) {
    if (this.currentUser.isImpersonating) return;
    if (!(this.userId && this.email)) return;

    UserLeap('track', eventId);
  }

  logout() {
    if (window.UserLeap === undefined) return;

    UserLeap('logoutUser');
  }
}
