import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    series=this.series\n    locale=this.locale\n    disabledListings=this.disabledListings\n    enabledListings=this.enabledListings\n    enabledClusters=this.enabledClusters\n    hasAnyDynamicMarkups=this.hasAnyDynamicMarkups\n    isLoading=this.isLoading\n  )\n}}", {"contents":"{{yield\n  (hash\n    series=this.series\n    locale=this.locale\n    disabledListings=this.disabledListings\n    enabledListings=this.enabledListings\n    enabledClusters=this.enabledClusters\n    hasAnyDynamicMarkups=this.hasAnyDynamicMarkups\n    isLoading=this.isLoading\n  )\n}}","moduleName":"appkit/components/relay/dcm-card/container.hbs","parseOptions":{"srcName":"appkit/components/relay/dcm-card/container.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { all } from 'ember-concurrency';
import { restartableTask, enqueueTask } from 'ember-concurrency-decorators';
import { displayErrors } from 'appkit/lib/display_errors';
import { tracked } from '@glimmer/tracking';
import flatten from 'lodash.flatten';
import { computed } from '@ember/object';

export default class DcmCardContainer extends Component {
  @service store;
  @service ajax;
  @tracked series = [];
  @service intl;

  constructor() {
    super(...arguments);
    this.getChannelMarkupsData.perform(this.clusters);
  }

  get locale() {
    return this.intl.locale;
  }

  get isLoading() {
    return this.getChannelMarkupsData.isRunning;
  }

  get clusterListingsCount() {
    return this.listings.reduce((acc, l) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!acc.hasOwnProperty(l.cluster)) {
        acc[l.cluster] = 0;
      }

      acc[l.cluster] += 1;
      return acc;
    }, {});
  }

  get listings() {
    return this.args.listings ?? [];
  }

  get channelListings() {
    return flatten(this.listings.map(l => l.channelListings));
  }

  @computed('channelListings.@each.dynamicMarkupsEnabled')
  get enabledListings() {
    return this.listings.filter(l =>
      l.channelListings.some(cl => cl.dynamicMarkupsEnabled)
    );
  }

  // Listings without dynamic markups
  @computed('channelListings.@each.dynamicMarkupsEnabled')
  get disabledListings() {
    return this.listings.filter(l =>
      l.channelListings.every(cl => !cl.dynamicMarkupsEnabled)
    );
  }

  get listingsClusterNames() {
    return this.listings.map(l => l.cluster);
  }

  get clusters() {
    return this.store
      .peekAll('cluster')
      .filter(c => this.listingsClusterNames.includes(c.name));
  }

  get enabledClusters() {
    return this.enabledListings.map(l => this.clusters.find(c => c.name === l.cluster));
  }

  get hasAnyDynamicMarkups() {
    return flatten(this.listings.map(l => l.channelListings)).find(
      cl => cl.dynamicMarkupsEnabled
    );
  }

  @restartableTask
  *getChannelMarkupsData(clusters) {
    const allTasks = clusters.map(c => {
      this.getChannelMarkupsByClusterId.perform(c);
    });
    yield all(allTasks);
  }

  @enqueueTask({ maxConcurrency: 4 })
  *getChannelMarkupsByClusterId(c) {
    const req = this.ajax.get(`/api/channel_markups/${c.id}`);

    try {
      const data = yield req.promise;
      this.series = [
        ...this.series,
        {
          id: c.id,
          name: `${c.name} (${this.clusterListingsCount[c.name]})`,
          data: data.markups,
        },
      ];
    } catch (errors) {
      displayErrors({ errors, modelOrKeywordThis: this, alert: this.alert });
    } finally {
      if (req.jqXHR.readyState !== 4) {
        req.jqXHR.abort();
      }
    }
  }
}
