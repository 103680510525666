import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';
import EmberObject, { action } from '@ember/object';
import { typeOf } from '@ember/utils';

@classic
export default class IndexRoute extends AuthRoute {
  @service('-routing') router;
  @service('list-control') listControl;
  @service featureFlag;
  @service userleap;
  @service('booking-review') bookingReview;

  titleToken = 'Dashboard';

  beforeModel() {
    const dashboardModel = this.modelFor('dashboard');

    if (dashboardModel.user.pricingActivatedAt == null) {
      return this.transitionTo('dashboard.pricing.join');
    }
  }

  afterModel(model) {
    if (model.get('accountErrors')) {
      return model;
    }

    // Need to check both account length and listing length - credentials that
    // only have listing-level permissions won't be able to see the accounts.
    if (model.get('accounts').length === 0 && model.get('listings').length === 0) {
      return this.replaceWith('dashboard.connect');
    }

    this.featureFlag
      .evaluate('userleap-usability-renewal-survey', false)
      .then(value => {
        this.isInUsabilityRenewalSurveyGroup = value;

        if (this.isInUsabilityRenewalSurveyGroup || model.user.sfCustomerContracted) {
          this.userleap.trackEvent('usability renewal survey');
        }
      });

    return model;
  }

  setupController(controller, model) {
    // filter is enabled on table-view by default
    if (
      controller.listControl.initialQueryParams.appearance === 'table-view' &&
      this.router.router.currentURL.split('/').length < 4
    ) {
      this.transitionTo('dashboard.pricing.index.filter');
    }

    controller.changeFiltersWithSearchParams();

    if (controller.selectedListingsIds.length > 0) {
      for (let listing of model.listings) {
        const selected = controller.selectedListingsIds.includes(listing.id.toString());
        listing.set('selected', selected);
      }
    }

    // set selected saved filter if avairable
    for (let sf of model.savedFilters) {
      this.setSelectedSavedFilter(this.listControl.initialQueryParams, sf);
    }

    super.setupController(controller, model);

    if (
      this.bookingReview.showCustomerFacingBartFeature &&
      !this.bookingReview.currentUser.isStaff &&
      this.bookingReview.forceCompleteSuggestion
    ) {
      this.controller.completeSuggestion();
      this.bookingReview.forceCompleteSuggestion = false;
    }
  }

  resetController(controller, isExiting, _transition) {
    if (isExiting) {
      controller.resetSearchParams();
    }
  }

  @action
  queryParamsDidChange(changedQueryParam, currentQueryParams) {
    if (currentQueryParams.run_booking_review == 'true') {
      this.bookingReview.submitBartReport();
    }
    // TODO: This call to super is within an action, and has to refer to the parent
    // class's actions to be safe. This should be refactored to call a normal method
    // on the parent class. If the parent class has not been converted to native
    // classes, it may need to be refactored as well. See
    // https: //github.com/scalvert/ember-native-class-codemod/blob/master/README.md
    // for more details.
    super.actions.queryParamsDidChange.call(this, ...arguments);

    const queryParams = EmberObject.create({
      search: currentQueryParams.search,
      appearance: currentQueryParams.appearance,
      base_price_max: currentQueryParams.base_price_max,
      base_price_min: currentQueryParams.base_price_min,
      selected_bedrooms: currentQueryParams.selected_bedrooms,
      health_score_max: currentQueryParams.health_score_max,
      health_score_min: currentQueryParams.health_score_min,
      listing_ids: currentQueryParams.listing_ids,
      selected_accounts: currentQueryParams.selected_accounts,
      selected_market_names: currentQueryParams.selected_market_names,
      selected_cluster_names: currentQueryParams.selected_cluster_names,
      selected_category_1_names: currentQueryParams.selected_category_1_names,
      selected_category_2_names: currentQueryParams.selected_category_2_names,
      listings_to_include: currentQueryParams.listings_to_include,
      listings_to_exclude: currentQueryParams.listings_to_exclude,
      selected_origin_for_radius: currentQueryParams.selected_origin_for_radius,
      distance_max: currentQueryParams.distance_max,
      distance_min: currentQueryParams.distance_min,
      show_disabled: currentQueryParams.show_disabled,
      show_enabled: currentQueryParams.show_enabled,
      show_on_program: currentQueryParams.show_on_program,
      show_off_program: currentQueryParams.show_off_program,
      monthly_price_posting_disabled: currentQueryParams.monthly_price_posting_disabled,
      monthly_price_posting_enabled: currentQueryParams.monthly_price_posting_enabled,
      sort_field: currentQueryParams.sort_field,
      sort_order: currentQueryParams.sort_order,
      hide_unavailable: currentQueryParams.hide_unavailable,
      filter_by_need_review: currentQueryParams.filter_by_need_review,
      mode: currentQueryParams.mode,
    });

    // check initial query params exists
    const initialQueryParamsIsNotSet =
      Object.values(this.listControl.initialQueryParams).filter(iqp => iqp === null)
        .length > 0;

    if (initialQueryParamsIsNotSet) {
      // if initial query params doesn't eixst, set both initial and updated params
      this.set('listControl.initialQueryParams', queryParams);
      this.set('listControl.updatedQueryParams', queryParams);
    } else {
      // otherwise set only updated params to track diff
      this.set('listControl.updatedQueryParams', queryParams);
    }
  }

  // -- Private Functions --------------------------------------------------------------

  setSelectedSavedFilter(initialQueryParams, savedFilter) {
    const iqp = initialQueryParams;
    const sfc = savedFilter.content;

    /* eslint-disable no-prototype-builtins */
    const appearanceExist = sfc.hasOwnProperty('appearance');
    const basePriceMaxExist = sfc.hasOwnProperty('basePriceMax');
    const basePriceMinExist = sfc.hasOwnProperty('basePriceMin');
    const selectedBedroomsExist = sfc.hasOwnProperty('selectedBedrooms');
    const healthScoreMaxExist = sfc.hasOwnProperty('healthScoreMax');
    const healthScoreMinExist = sfc.hasOwnProperty('healthScoreMin');
    const hideUnavailableExist = sfc.hasOwnProperty('hideUnavailable');
    const listingIdsExist = sfc.hasOwnProperty('listingIds');
    const searchExist = sfc.hasOwnProperty('search');
    const selectedAccountsExist = sfc.hasOwnProperty('selectedAccounts');
    const selectedMarketNamesExist = sfc.hasOwnProperty('selectedMarketNames');
    const selectedClusterNamesExist = sfc.hasOwnProperty('selectedClusterNames');
    const selectedCategory1NamesExist = sfc.hasOwnProperty('selectedCategory1Names');
    const selectedCategory2NamesExist = sfc.hasOwnProperty('selectedCategory2Names');
    const listingsToIncludeExist = sfc.hasOwnProperty('listingsToInclude');
    const listingsToExcludeExist = sfc.hasOwnProperty('listingsToExclude');
    const selectedOriginForRadiusExist = sfc.hasOwnProperty('selectedOriginForRadius');
    const distanceMaxExist = sfc.hasOwnProperty('distanceMax');
    const distanceMinExist = sfc.hasOwnProperty('distanceMin');
    const showDisabledExist = sfc.hasOwnProperty('showDisabled');
    const showEnabledExist = sfc.hasOwnProperty('showEnabled');
    const showOnProgramExist = sfc.hasOwnProperty('showOnProgram');
    const showOffProgramExist = sfc.hasOwnProperty('showOffProgram');
    const monthlyPricePostingDisabledExist = sfc.hasOwnProperty(
      'monthlyPricePostingDisabled'
    );
    const monthlyPricePostingEnabledExist = sfc.hasOwnProperty(
      'monthlyPricePostingEnabled'
    );
    const sortFieldExist = sfc.hasOwnProperty('sortField');
    const sortOrderExist = sfc.hasOwnProperty('sortOrder');
    const filterByNeedReview = sfc.hasOwnProperty('filterByNeedReview');
    /* eslint-enable no-prototype-builtins */

    // exit if any of query param doesn't match
    if (appearanceExist && iqp.appearance !== sfc.appearance) return;
    if (basePriceMaxExist && iqp.base_price_max !== sfc.basePriceMax) return;
    if (basePriceMinExist && iqp.base_price_min !== sfc.basePriceMin) return;
    if (selectedBedroomsExist && iqp.selected_bedrooms !== sfc.selectedBedrooms) return;
    if (healthScoreMaxExist && iqp.health_score_max !== sfc.healthScoreMax) return;
    if (healthScoreMinExist && iqp.health_score_min !== sfc.healthScoreMin) return;
    if (hideUnavailableExist && iqp.hide_unavailable !== sfc.hideUnavailable) return;
    if (listingIdsExist && iqp.listing_ids !== sfc.listingIds) return;
    if (searchExist && iqp.search !== sfc.search) return;
    if (selectedAccountsExist && iqp.selected_accounts !== sfc.selectedAccounts) return;
    if (
      selectedMarketNamesExist &&
      iqp.selected_market_names !== sfc.selectedMarketNames
    )
      return;
    if (
      selectedClusterNamesExist &&
      iqp.selected_cluster_names !== sfc.selectedClusterNames
    )
      return;
    if (
      selectedCategory1NamesExist &&
      iqp.selected_Category_1_names !== sfc.selectedCategory1Names
    )
      return;
    if (
      selectedCategory2NamesExist &&
      iqp.selected_Category_2_names !== sfc.selectedCategory2Names
    )
      return;
    if (listingsToIncludeExist && iqp.listings_to_include !== sfc.listingsToInclude)
      return;
    if (listingsToExcludeExist && iqp.listings_to_exclude !== sfc.listingsToExclude)
      return;
    if (
      selectedOriginForRadiusExist &&
      iqp.selected_origin_for_radius !== sfc.selectedOriginForRadius
    )
      return;
    if (distanceMaxExist && iqp.distance_max !== sfc.distanceMax) return;
    if (distanceMinExist && iqp.distance_min !== sfc.distanceMin) return;
    if (showDisabledExist && iqp.show_disabled !== sfc.showDisabled) return;
    if (showEnabledExist && iqp.show_enabled !== sfc.showEnabled) return;
    if (showOnProgramExist && iqp.show_on_program !== sfc.showOnProgram) return;
    if (showOffProgramExist && iqp.show_off_program !== sfc.showOffProgram) return;
    if (
      monthlyPricePostingDisabledExist &&
      iqp.monthly_price_posting_disabled !== sfc.monthlyPricePostingDisabled
    )
      return;
    if (
      monthlyPricePostingEnabledExist &&
      iqp.monthly_price_posting_enabled !== sfc.monthlyPricePostingEnabled
    )
      return;
    if (sortFieldExist && iqp.sort_field !== sfc.sortField) return;
    if (sortOrderExist && iqp.sort_order !== sfc.sortOrder) return;
    if (filterByNeedReview && iqp.filter_by_need_review !== sfc.filterByNeedReview)
      return;

    // if everything matched, set as selectedSavedFilter
    this.set('listControl.selectedSavedFilter', savedFilter);
  }

  // Overwrite -------------------------------------------------------------------------

  serializeQueryParam(value, urlKey, defaultValueType) {
    if (urlKey === 'selected_listing_ids' && typeOf(value) === 'array') {
      value?.forEach((item, index) => {
        value[index] = item.toString().replace('"', '');
      });
      return value.join('-');
    } else if (urlKey === 'selected_market_names' && typeOf(value) === 'array') {
      return value.join('__');
    } else if (urlKey === 'selected_cluster_names' && typeOf(value) === 'array') {
      return value.join('__');
    } else {
      return super.serializeQueryParam(value, urlKey, defaultValueType);
    }
  }

  deserializeQueryParam(value, urlKey, defaultValueType) {
    if (urlKey === 'selected_listing_ids') {
      return value.split('-');
    } else if (
      urlKey === 'selected_market_names' ||
      urlKey === 'selected_cluster_names'
    ) {
      if (!value) return;
      return value.split('__');
    } else {
      return super.deserializeQueryParam(value, urlKey, defaultValueType);
    }
  }
}
