import { VARIANTS, COLORS, SIZES, baseTheme, mergeTheme } from '../../../theme';
const { spacingClasses, iconSizeClasses, heightClasses } = baseTheme;

const defaultClasses = ({ noWrap, disabled }) => [
  'w-full',
  'rounded',
  'inline-flex',
  !noWrap && 'items-center',
  'justify-between',
  disabled && 'bg-gray-100',
];

const rootSpacingClasses = opts => {
  if (opts.noWrap) {
    return mergeTheme(spacingClasses, heightClasses)(opts);
  }

  return spacingClasses(opts);
};

export const variantClasses = ({ active, highlight, disabled }) => ({
  [VARIANTS.CONTAINED]: {
    [COLORS.ALL]: ['text-white'],
    [COLORS.BRAND]: [active ? 'bg-brand-600' : 'bg-brand-400'],
  },
  [VARIANTS.OUTLINED]: {
    [COLORS.ALL]: ['border', 'bg-white', 'text-gray-600'],
    [COLORS.BRAND]: [
      active && !highlight ? 'border-brand-500' : 'border-gray-300',
      highlight && [
        'border',
        !disabled && 'bg-brand-200',
        'border-brand-500',
        'text-gray-800',
      ],
    ],
  },
  [VARIANTS.TEXT]: {
    [COLORS.ALL]: !highlight && ['border', 'bg-white', 'text-gray-600'],
    [COLORS.BRAND]: [
      active && !highlight ? 'border-brand-500' : 'border-transparent',
      highlight && [
        'border',
        !disabled && 'bg-brand-200',
        'border-brand-500',
        'text-gray-800',
      ],
    ],
  },
});

export const fontSizeClasses = () => ({
  [SIZES.XS]: ['leading-4', 'text-xs', 'font-base'],
  [SIZES.SM]: ['leading-4', 'text-sm', 'font-base'],
  [SIZES.MD]: ['leading-5', 'text-base', 'font-base'],
  [SIZES.LG]: ['leading-6', 'text-base', 'font-base'],
  [SIZES.XL]: ['leading-6', 'text-base', 'font-base'],
});

// Chevron
const chevronDefaultClasses = () => ['fill-current', 'flex-initial'];
const chevronVariationClasses = ({ active, highlight }) => ({
  [VARIANTS.CONTAINED]: {
    [COLORS.ALL]: ['text-white'],
  },
  [VARIANTS.OUTLINED]: {
    [COLORS.ALL]: [!active && 'text-gray-300'],
    [COLORS.BRAND]: [(active || highlight) && 'text-brand-500'],
  },
  [VARIANTS.TEXT]: {
    [COLORS.ALL]: [!active && 'text-gray-300'],
    [COLORS.BRAND]: [(active || highlight) && 'text-brand-500'],
  },
});

const chevronSpacingClasses = () => ({
  [SIZES.ALL]: ['-mr-1', 'ml-2'],
});

const borders = [VARIANTS.OUTLINED, VARIANTS.TEXT];

export default {
  root: {
    defaultClasses,
    fontSizeClasses,
    spacingClasses: rootSpacingClasses,
    variantClasses,
    borders,
  },
  chevron: {
    defaultClasses: chevronDefaultClasses,
    variantClasses: chevronVariationClasses,
    spacingClasses: chevronSpacingClasses,
    iconSizeClasses,
  },
};
