import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class MarketDataRoute extends Route {
  templateName = 'listing.market-data';
  controllerName = 'listing.market-data';

  setupController(controller, model) {
    controller.set('model', model);
    let listing = this.modelFor('demo').get('listing');
    controller.set('listing', listing);
  }

  model() {
    let params = this.paramsFor('demo');
    let url = `/api/listings/demo/${params.id}/market_data`;
    return this.ajax._get(url);
  }
}
