import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class HotelPricesRoute extends AuthRoute {
  templateName = 'data.hotel-prices';
  controllerName = 'data.hotel-prices';

  setupController(controller, model) {
    controller.set('model', model);

    let market = this.modelFor('data.market');
    controller.set('title', market.get('name'));

    let url = `${location.origin}/admin/api/markets/${market.get(
      'id'
    )}/hotels/prices.csv?token=${this.currentUser.get('token')}`;
    this.controllerFor('data.market').set('csvUrl', url);
  }

  deactivate() {
    this.controllerFor('data.market').set('csvUrl', false);
  }

  model() {
    let id = this.modelFor('data.market').get('id');
    let url = `/admin/api/markets/${id}/hotels/prices`;
    return this.ajax._get(url);
  }
}
