import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ProcessingRoute extends AuthRoute {
  @service populateAccount;

  beforeModel() {
    if (this.populateAccount.syncingIsDone) {
      this.replaceWith('dashboard');
    } else {
      this.populateAccount.onFinish(this.onFinish);
    }
  }

  @action
  willTransition() {
    this.populateAccount.preventFinishDetails(this.onFinish);
  }

  @action
  onFinish() {
    setTimeout(() => {
      this.replaceWith('dashboard');
    }, 2000);
  }
}
