import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { showForm } from '../../../lib/upsell/relay';

export default class DashboardRelayFormController extends Controller {
  constructor() {
    super(...arguments);
  }

  @computed('model.user.id')
  get relayBannerSubmitted() {
    return !showForm(this.model.user.id);
  }
}
