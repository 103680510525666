import Service, { inject as service } from '@ember/service';
import EmberObject, { action, set } from '@ember/object';

const showErrorAlert = alertService => {
  const message = 'An error occurred. Please contact support@beyondpricing.com.';
  alertService.error(message, {
    timeout: 6000,
  });
};

const showSuccessAlert = alertService => {
  alertService.success('Credit Card added successfully. Thank you.', {
    timeout: 6000,
  });
};

export default class CardsService extends Service {
  @service ajax;
  @service alert;
  @service impact;

  @action
  async add(url, data, model) {
    try {
      const result = await this.ajax._post(url, data);
      set(
        model,
        'cards',
        result.cards.map(cardData => EmberObject.create(cardData))
      );

      try {
        this.impact.trackConversion('AddCreditCard', this.currentUser);
      } catch (e) {
        console.log(
          `Failed to track AddCreditCard event in Impact Affiliate Partner, error: ${e}`
        );
      }

      showSuccessAlert(this.alert);
    } catch (errors) {
      showErrorAlert(this.alert);
      return;
    }
  }
}
