import { modifier } from 'ember-modifier';

const events = [
  'input',
  'keydown',
  'keyup',
  'mousedown',
  'mouseup',
  'select',
  'contextmenu',
  'drop',
];

// IMPORTANT: Make sure this modifier is used before any other event handler that depends on the input value
export default modifier((element, [regex]) => {
  const re = new RegExp(regex);

  function handler() {
    if (re.test(this.value)) {
      this.oldValue = this.value;
      this.oldSelectionStart = this.selectionStart;
      this.oldSelectionEnd = this.selectionEnd;
    } else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
      this.value = this.oldValue;
      this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
    } else {
      this.value = '';
    }
  }

  events.forEach(function (event) {
    element.addEventListener(event, handler);
  });

  return () => {
    events.forEach(function (event) {
      element.removeEventListener(event, handler);
    });
  };
});
