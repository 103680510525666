import { helper } from '@ember/component/helper';

export default helper(function (params) {
  const day = params[0];
  const newPrice = Math.round(
    day.priceModeled / (1 + day._dirty.percentageUserOverride)
  );

  return newPrice > params[1] ? newPrice : params[1];
});
