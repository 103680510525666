import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import BaseModel from 'appkit/bp-models/base_model';

export default BaseModel.extend({
  // -- Component Properties -----------------------------------------------------------
  type: '', // @type {String}
  email: '', // @type {String}
  name: '', // @type {String}
  first_name: '', // @type {String}
  last_name: '', // @type {String}
  global_permissions: '', // @type {String}
  permissions: null, // @type {Array}

  isAdministrator: equal('globalPermissions', 'admin'),

  classSelected: computed('itemsVisible', function () {
    if (this.itemsVisible) {
      return 'row-selected';
    }
    return '';
  }),

  readableName: computed('type', function () {
    if (this.type === 'EmailCredential') {
      return this.email;
    }

    if (this.type === 'TokenCredential') {
      return `API: ${this.name}`;
    }

    return '-';
  }),

  // -- Lifecycle Hooks ----------------------------------------------------------------
  init() {
    this._super(...arguments);

    this._EDITABLE = ['globalPermissions'];

    this.set('permissions', this.permissions ?? []);
  },
});
