/* eslint-disable prettier/prettier */
import moment from 'moment';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { RelaySearcher } from '../../../../lib/relay/utils/rel-searcher';
import * as AvailabilityFilters from '../../../../lib/relay/availability/filters';
import {
  RelayFavoriteSearch,
  FavoriteSearchRepository,
  getAvailabilitySnapshot,
} from '../../../../lib/relay/availability/filters';

export default class RelayListingReservationsController extends Controller {
  // CONFIG ------------------------------------------------------------------->>
  searcher = new RelaySearcher()
  .setConfig({
    filters: AvailabilityFilters.listingDatesFilters,
    sorts: AvailabilityFilters.listingDatesSorts,
  }).setActiveSort(AvailabilityFilters.listingDatesSorts.sortByCalendarDate)
    .setSortAscending(true);

  filterPanels = [...AvailabilityFilters.AVAILABILITY_FILTER_TABS];
  searchSelects = [...AvailabilityFilters.SEARCH_SELECTS];
  sortOptions = [...AvailabilityFilters.SORT_OPTIONS];

  // STATE ------------------------------------------------------------------->>
  @tracked searchTags = new Map();
  @tracked filteredResults = [...this.model._listingDates];
  @tracked recentSearches = [];
  @tracked selectedSortOption = this.sortOptions[0];
  @tracked isAscendingSortOrder = true;
  @tracked isSearchPanelOpen = false;
  @tracked selectedAvailabilityTab = 0;
  @tracked expandedDate = null;

  /**
   * Controller functions
   * Every time a selected filter option changes:
   * - The filter name and search value are pushed to the searcher to compose a query
   * - The select selectedOption is updated in the DOM
   * - The search tags displayed in the search bar are updated
   */
  onSearchCriteriaChanged(selectId, optionIndex) {
    const { select, option, operation, value } = this.getSelectOption(
      selectId,
      optionIndex
    );

    this.searcher.updateFiltersQueryList(operation, value);

    this.updateSelectedOption(selectId, optionIndex);

    this.updateSearchTags({
      selectId,
      optionIndex,
      selectLabel: select.label,
      optionLabel: option.label,
    });
  }

  getSelectOption(selectId, optionIndex) {
    const select = this.searchSelects.find(x => x.id === selectId);
    const option = select.options[optionIndex];

    return {
      select,
      option,
      operation: select.operation,
      value: option.value,
    };
  }

  updateSelectedOption(id, selectedIndex) {
    const i = this.searchSelects.findIndex(sel => sel.id === id);
    const updatedSelect = { ...this.searchSelects[i], selected: selectedIndex };

    this.searchSelects = this.searchSelects.map((storedSelect, j) =>
      i === j ? updatedSelect : storedSelect
    );
  }

  updateSearchTags({ selectId, optionIndex, selectLabel, optionLabel }) {
    if (optionIndex) {
      this.searchTags.set(selectId, { selectLabel, optionIndex, optionLabel });
    } else {
      this.searchTags.delete(selectId);
    }
    this.searchTags = new Map(this.searchTags);
  }

  updateRecentSearches(searchTags) {
    const MAX_CACHE_SIZE = 3;
    this.recentSearches = [searchTags, ...this.recentSearches];
   
    if (this.recentSearches.length > MAX_CACHE_SIZE) {
      this.recentSearches.pop();
    }
  }


  // TAB NAVIGATION ------------------------------------------------------------------->>
  @action
  onFilterTabClick(tabIndex) {
    this.isDashboardTabOpen = false;
    this.onSearchCriteriaChanged('availability-select', tabIndex);
    this.search(this.searchTags, false);
    this.selectedAvailabilityTab = tabIndex;
    this.isSearchPanelOpen = false;
  }

  // FILTER / SORT OPTIONS -------------------------------------------------------------->>
  @action
  onFilterOptionChanged(event) {
    const { id, options } = event.target;
    this.onSearchCriteriaChanged(id, options.selectedIndex);
  }

  @action
  onSortOptionChanged(event) {
    const { value } = event.target;
    const selectedSortOption = this.sortOptions[value];

    this.filteredResults = this.searcher
      .setActiveSort(selectedSortOption.operation)
      .sort(this.filteredResults);
  }

  @action
  onSortOrderBtnClicked() {
    this.isAscendingSortOrder = !this.isAscendingSortOrder;
    this.searcher.setSortAscending(this.isAscendingSortOrder);
    this.filteredResults = this.searcher.sort(this.filteredResults);
  }

  // SEARCH TAGS ------------------------------------------------------------------------->>
  /** Several search tags can be loaded at once by clicking on a Recent or a Fav Search */
  onLoadSearchTags(searchTags) {
    for (const [selectId, tagData] of searchTags) {
      this.onSearchCriteriaChanged(selectId, tagData.optionIndex);
    }
    this.searchTags = new Map(searchTags);
  }

  // SEARCH  ------------------------------------------------------------------------------->>
  @action
  runSearch() {
    this.search(this.searchTags);
  }

  @action
  runRecentSearch(searchTags) {
    this.resetFilters();
    this.onLoadSearchTags(searchTags);
    this.search(searchTags, false);
  }
  
  @action
  runFavoriteSearch(searchTags) {
    this.resetFilters();
    this.onLoadSearchTags(searchTags);
    this.search(searchTags);
    this.isDashboardTabOpen = false;
    this.selectedAvailabilityTab = 0;
  }

  @action
  clearSearch() {
    this.resetFilters();
    if (this.isSearchPanelOpen) this.toggleSearchPanel();
    if (!this.isDashboardTabOpen) {
      this.selectedAvailabilityTab = 0;
    }
  }

  search(searchTags, shouldAddToRecentSearches = true) {
    this.filteredResults = this.searcher.filter(this.model._listingDates);

    if (shouldAddToRecentSearches) this.updateRecentSearches(searchTags);

    if (this.isSearchPanelOpen) this.toggleSearchPanel();
  }

  resetFilters() {
    this.filteredResults = this.model._listingDates;
    this.searchSelects = [...AvailabilityFilters.SEARCH_SELECTS];
    this.searcher.clearFiltersQueryList();
    this.searchTags = new Map();
  }

  // UI CONTROL --------------------------------------------------------------->>
  @action
  toggleSearchPanel() {
    this.isSearchPanelOpen = !this.isSearchPanelOpen;
  }

  @action
  onAvailabilityAccordionToggle(dayData) {
    this.expandedDate = this.expandedDate === dayData.date ? null : dayData.date;
    if (this.expandedDate) {
      this.updateStorySnapshot(dayData, this.snapshotDate || this.fetchDate);
    }
  }
  // STORY SNAPSHOT --------------------------------------------------------------->>
  // Time travel to get the value of PMS an DC for a listing date, in a concrete time
  @tracked snapshotDate = null;
  @tracked availabilitySnapshot;
  @tracked fetchDate = moment();

  @action
  onStorySnapshotDateChanged(dayData, snapshotDate) {
    this.updateStorySnapshot(dayData, snapshotDate);
  }

  @action
  onStorySnapshotTimeChanged(dayData, event) {
    const time = event.target.value;
    // TODO: Validation
    const [hour, minute] = time.split(':');
    const updatedSnapshotDate = moment(this.snapshotDate || this.fetchDate).set({
      hour,
      minute,
    });
    this.updateStorySnapshot(dayData, updatedSnapshotDate);
  }

  updateStorySnapshot(dayData, snapshotDate) {
    const timestamp = moment(snapshotDate);
    const { PMS, directChannels } = dayData;
    const storySnapshot = getAvailabilitySnapshot(timestamp, PMS, directChannels);
    this.snapshotDate = timestamp;
    this.availabilitySnapshot = storySnapshot;
  }

  // FAVORITES ----------------------------------------------------------------- */
  // Keep fav searches between sessions.
  // Basically is stored search tags, that gets loaded into the searcher on click
  favRepository = new FavoriteSearchRepository();
  @tracked fav;
  @tracked showAddToFavoriteModal = false;
  @tracked favSearches = this.favRepository.favSearches;

  @action
  onOpenFavModal() {
    if (!this.searchTags.size) return;
    this.fav = new RelayFavoriteSearch();
    this.fav.setSearchTags(this.searchTags);
    this.showAddToFavoriteModal = true;
  }
  @action
  onFavEdited(fav) {
    this.fav = RelayFavoriteSearch.from(fav);
  }
  @action
  onSaveFav(fav) {
    //TODO: Add name unique validation err
    this.favRepository.create(fav);
    this.onCloseFavModal();
    this.toggleFavTooltip();
  }
  @action
  onCloseFavModal() {
    this.showAddToFavoriteModal = false;
  }

  @action
  onFavOptionSelected(event) {
    const { value } = event.target;
    const selectedFavSearch = this.favSearches.get(value);
    this.resetFilters();
    this.onLoadSearchTags(selectedFavSearch.searchTags);
  }

  @tracked shouldShowFavTooltip = false;
  @action
  toggleFavTooltip() {
    this.shouldShowFavTooltip = !this.shouldShowFavTooltip;
  }

  // DASHBOARD ----------------------------------------------------------------- */
  // v0.2 -> Shows a list of the fav searches, with the counter of search results
  @tracked isDashboardTabOpen = false;

  @action
  onDashboardTabSelected() {
    this.isDashboardTabOpen = true;
    this.isSearchPanelOpen = false;
    this.selectedAvailabilityTab = -1;
    this.shouldShowFavTooltip = false;
    this.runFavSearches(this.model._listingDates);
  }

  // RESULT COUNTERS -------------------------------------------------------------->
  setAvailabilityCounters() {
    const counters = this.model.meta.availabilityCounters;
    for (const key in counters) {
      const i = this.filterPanels.findIndex(x => x.id === key);
      this.filterPanels[i].count = counters[key];
    }
  }
  // Runs every fav search to display the summary counters in the dashboard
  runFavSearches(listingDates) {
    const favSearches = this.favSearches;

    favSearches.forEach((fav, favName) => {
      // Clear stored search
      this.searcher.clearFiltersQueryList();
      // Set the query
      fav.searchTags.forEach((tag, tagName) => {
        // Recover operation name and value from selects
        const selectModel = this.searchSelects.find(x => x.id === tagName);
        const selectedOption = selectModel.options[tag.optionIndex];
        // Update query list
        this.searcher.updateFiltersQueryList(
          selectModel.operation,
          selectedOption.value
        );
      });
      // Run search
      const results = this.searcher.filter(listingDates);
      // Add profile metadata (count & search date)
      this.favSearches.set(favName, {
        ...fav,
        count: results.length,
        lastTimeChecked: moment(),
      });
    });
    this.searcher.clearFiltersQueryList();
  }
}
