import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { getOwner } from '@ember/application';

@classic
export default class CalendarLegacyRoute extends AuthRoute {
  @service featureFlag;
  @service intl;

  async afterModel() {
    this.templateName = 'dashboard.pricing.listing.calendar';
    this.controllerName = 'listing.calendar';
  }

  activate() {
    const calendarControler = getOwner(this).lookup('controller:listing.calendar');
    calendarControler.set('calendarStartDate', moment().locale(this.intl.locale));

    calendarControler.set('showLoadPastMonthsButton', true);
  }
}
