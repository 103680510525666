import { helper as buildHelper } from '@ember/component/helper';

export function truncateText(params, detail) {
  var string = params[0];
  var maxLen = detail.limit;
  var forceUpperCase = detail.uppercase;
  var truncated_string = '';

  truncated_string = string.substr(0, maxLen);

  if (forceUpperCase) {
    truncated_string = truncated_string.toUpperCase();
  }

  return truncated_string;
}

export default buildHelper(truncateText);
