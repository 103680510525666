import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    isIntroAnimationStopped=this.isIntroAnimationStopped\n    actions=(hash\n      onFinishingSequence=this.onFinishingSequence\n      onAnimationStopped=this.onAnimationStopped\n    )\n  )\n}}", {"contents":"{{yield\n  (hash\n    isIntroAnimationStopped=this.isIntroAnimationStopped\n    actions=(hash\n      onFinishingSequence=this.onFinishingSequence\n      onAnimationStopped=this.onAnimationStopped\n    )\n  )\n}}","moduleName":"appkit/components/onboarding/intro-sequence-container.hbs","parseOptions":{"srcName":"appkit/components/onboarding/intro-sequence-container.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class OnboardingIntroSequenceContainerComponent extends Component {
  @tracked isIntroAnimationStopped = false;

  @action
  onFinishingSequence() {
    this.args.finishedSequence();
  }

  @action
  onAnimationStopped() {
    this.isIntroAnimationStopped = true;
  }
}
