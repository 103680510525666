import moment from 'moment';

export const fakeCalendar = {
  days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
};

/**
 * Builds a calendar beetween two specified lengths
 * If no dates are specified, it will build a  calendar of 365 days
 *
 * format:  [{ date: YYYY-MM-DD }]
 **/
export function buildDailyCalendar(dateStart, dateEnd) {
  try {
    const TODAY = moment();
    const ONE_YEAR_FROM_NOW = moment(TODAY).add(365, 'days');

    const _dateStart = (dateStart && moment(dateStart)) || TODAY;
    const _dateEnd = (dateEnd && moment(dateEnd)) || ONE_YEAR_FROM_NOW;

    const totalCalendarDays = _dateEnd.diff(_dateStart, 'days');

    return Array(totalCalendarDays)
      .fill()
      .map((_, i) => ({
        date: moment(_dateStart).add(i, 'days').format('YYYY-MM-DD'),
      }));
  } catch (e) {
    throw 'Error building calendar of the specified length';
  }
}

/** ---------------------------------------------------------------------------------
  Receives a flat array of days data in a year
  - Creates a weekly calendar similar to Commit´s calendar from GitHub
  - Starting the begining of current week, to N weeks(default= 53, 1 year)
 -----------------------------------------------------------------------------------*/
export function buildWeeklyCalendar(dailyCalendar, maxWeeks) {
  try {
    const WEEKS_IN_YEAR = 53;
    const weeks = Array(maxWeeks || WEEKS_IN_YEAR).fill();

    const populatedWeeks = weeks.map((_, i) =>
      Array(7)
        .fill()
        .map((_, j) => dailyCalendar[i * 7 + j])
    );

    return {
      weeks: populatedWeeks,
      days: fakeCalendar.days,
      months: getMonthLabels(populatedWeeks),
    };
  } catch (e) {
    throw 'Not enought data to build a weekly calendar of specified length';
  }
}

/**
 * Helper to get the view month labels aligned with the columns of the calendar
 **/
function getMonthLabels(weekCalendar, weekColumnWidth = 4, monthFormat = 'MMM') {
  // Map the week calendar, to get total weeks per week calendar month
  const naturalWeeksInCalendar = weekCalendar.map((week, i) => {
    const weekStartMonth = moment(week[0].date).format(monthFormat);
    const naturalWeeksCount = countConsecutiveMonthNameRepetitions(weekCalendar, i);
    return {
      name: weekStartMonth,
      naturalWeeksCount,
      width: `w-${naturalWeeksCount * weekColumnWidth}`,
    };
  });

  // Skip consecutive weeks in same month to get months labels in place
  const monthCalendar = naturalWeeksInCalendar.reduce((acc, item, i) => {
    const previousMonthWeek = naturalWeeksInCalendar[i - 1];
    if (previousMonthWeek) {
      const isSameMonth = previousMonthWeek.name === item.name;
      return isSameMonth ? acc : acc.push(item) && acc;
    }
    return acc.push(item) && acc;
  }, []);

  return monthCalendar;
}

// Start and end month in a weekly calendar can be partial, giving N columns for same month at the start and end.
// (Example: calendar starting 2 weeks of January, and ending 2 weeks of January)
function countConsecutiveMonthNameRepetitions(arr, startIndex) {
  let repetitionCounter = 0;
  const targetMonth = moment(arr[startIndex][0].date).month();

  for (let i = startIndex; i < arr.length; i++) {
    if (moment(arr[i][0].date).month() === targetMonth) {
      repetitionCounter++;
      continue;
    }
    return repetitionCounter;
  }
  return 1;
}

export function getExtraCalendarDays(
  date,
  days,
  fromWeekStart = true,
  dateFormat = 'YYYY-MM-DD'
) {
  if (!days) return [];
  const momentDate = moment(date);
  const method = fromWeekStart ? 'startOf' : 'endOf';
  const arrMethod = fromWeekStart ? 'unshift' : 'push';

  const extraCalendarDays = [];

  for (let i = days; i >= 0; i--) {
    const extraDay = moment(momentDate[method]('week'))
      .add(i, 'days')
      .format(dateFormat);
    extraCalendarDays[arrMethod](extraDay);
  }

  return extraCalendarDays;
}
