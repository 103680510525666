import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

@classic
export default class CalendarRoute extends Route {
  @service featureFlag;

  async afterModel() {
    this.templateName = 'dashboard.pricing.listing.calendar';
    this.controllerName = 'listing.calendar';
  }
}
