import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { setProperties } from '@ember/object';
import Component from '@ember/component';
import InViewportMixin from 'ember-in-viewport';

@classic
@tagName('i')
export default class AppInViewport extends Component.extend(InViewportMixin) {
  enterViewport() {}
  exitViewport() {}

  didEnterViewport() {
    undefined;
    this.enterViewport();
  }

  didExitViewport() {
    undefined;
    this.exitViewport();
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    setProperties(this, {
      viewportSpy: true,
    });
  }
}
