import { action } from '@ember/object';
import $ from 'jquery';
import Component from '@ember/component';

export default class AppModal extends Component {
  layoutName = 'components/app-modal';

  constructor() {
    super(...arguments);
    $(window).on('keydown.app-modal', this._keyDown.bind(this));
  }

  willDestroys() {
    $(window).off('keydown.app-modal');
  }

  _keyDown(e) {
    // ESC
    if (e.keyCode === 27) {
      this.close();
      return false;
    }
    return true;
  }

  @action
  onClose() {
    this.close();
    return false;
  }

  @action
  noop(event) {
    event.stopPropagation();
    return false;
  }
}
