import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
@classic
@classNames('app-carousel')
export default class AppCarousel extends Component {
  // Steps of the carousel
  // Step 0 is called "intro"
  step = 0;

  @equal('step', 0)
  isIntro;

  @equal('step', 1)
  isStepOne;

  @equal('step', 2)
  isStepTwo;

  @equal('step', 3)
  isStepThree;

  @equal('step', 4)
  isStepFour;

  @action
  next() {
    if (this.step === 4) {
      this.set('step', 0);
    } else {
      this.incrementProperty('step');
    }
    return false;
  }

  @action
  goToStep(value) {
    this.set('step', value);
    return false;
  }

  @action
  close() {
    let elToFadeOut = document.querySelector('.app-carousel.base-price-info');

    elToFadeOut.classList.add('fade-out');
    setTimeout(() => {
      this.action();
    }, 500);
  }
}
