import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Bp::Select::Parts::Option\n  @highlightOnHover={{true}}\n  @variant={{@variant}}\n  @color={{@color}}\n  @size='sm'\n  {{on 'click' this.onClick}}\n>\n  {{yield}}\n</Bp::Select::Parts::Option>", {"contents":"<Bp::Select::Parts::Option\n  @highlightOnHover={{true}}\n  @variant={{@variant}}\n  @color={{@color}}\n  @size='sm'\n  {{on 'click' this.onClick}}\n>\n  {{yield}}\n</Bp::Select::Parts::Option>","moduleName":"appkit/components/bp/button/split/option.hbs","parseOptions":{"srcName":"appkit/components/bp/button/split/option.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class BpButtonIndexComponent extends Component {
  @action
  onClick() {
    if (this.args.close) {
      this.args.dd.actions.close();
    }

    return this.args.onClick?.();
  }
}
