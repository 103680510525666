import { helper } from '@ember/component/helper';

export default helper(function (params) {
  const value = params[0];
  if (!value) {
    return '';
  }
  if (!value.length) {
    return '';
  }
  return value[0];
});
