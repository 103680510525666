import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

/** Check if the errors object has a JSON API compliant error */
function isJSONAPIError(errors) {
  return (
    Array.isArray(errors) && errors.length > 0 && errors.find(error => error.message)
  );
}

/** Returns the error message if it's JSON API compliant compliant or a default.  */
function errorMessage(errors, intl) {
  if (isJSONAPIError(errors)) {
    return errors.find(error => error.message).message;
  } else {
    return intl.t('user.accounts.errors.generic');
  }
}

/** Component used to update the markup for a managed account. */
@classic
@classNames('app-channel-markup')
export default class AppChannelMarkup extends Component {
  @service
  alert;

  @service
  intl;

  @service
  ajax;

  isSaving = false;
  isEditing = false;
  hasErrors = false;

  @computed('markup')
  get markupPercentage() {
    const markup = this.markup;

    return this.convertToPercentage(markup);
  }

  set markupPercentage(value) {
    const errors = this.validate(value);

    if (errors.length) {
      return value;
    }

    const markup = this.convertFromPercentage(value);
    this.set('markup', markup);
    return value;
  }

  /** Edit markup */
  @action
  editMarkup() {
    this.set('isEditing', true);
  }

  /** Validate markup, but only set border in input */
  @action
  validateMarkup() {
    const errors = this.validate();

    if (errors.length === 0) {
      return;
    }

    this.set('hasErrors', true);
  }

  /** Update markup and show success alert or show errors otherwise  */
  @action
  async onUpdateMarkup(accountId) {
    if (this.isSaving) {
      return;
    }

    const alert = this.alert;
    const intl = this.intl;
    const ajax = this.ajax;

    const errors = this.validate();

    if (errors.length > 0) {
      this.showErrors(errors);
      return;
    }

    const markup = this.markup;
    this.set('isSaving', true);

    try {
      await ajax._post(`/api/accounts/${accountId}/set_markup`, { markup });
    } catch (errors) {
      alert.error(errorMessage(errors, intl), { timeout: 10000 });
      return;
    } finally {
      this.set('isSaving', false);
      this.set('hasErrors', false);
    }

    this.updateMarkup(accountId, markup);
    this.set('isEditing', false);
    alert.success(intl.t('user.accounts.markupUpdated'), {
      timeout: 10000,
    });
  }

  /** Returns an array of errors for markup field */
  validate(value) {
    const intl = this.intl;
    const markupPercentage = value || this.markupPercentage;
    const errors = [];

    if (this.isBlank(markupPercentage)) {
      return [];
    }

    if (!this.isPercentage(markupPercentage)) {
      errors.push(intl.t('user.accounts.errors.markupNaN'));
    }

    if (!this.isBetweenBounds(markupPercentage)) {
      errors.push(intl.t('user.accounts.errors.markupOutOfBounds'));
    }

    if (!this.hasTwoDecimals(markupPercentage)) {
      errors.push(intl.t('user.accounts.errors.markupDecimalPlaces'));
    }

    return errors;
  }

  /** Display an alert for each error */
  showErrors(errors) {
    const alert = this.alert;
    errors.forEach(error => alert.error(error));
    this.set('hasErrors', true);
  }

  /** Check if value is blank */
  isBlank(value) {
    return value === null || (typeof value === 'string' && value.trim() === '');
  }

  /** Check if value is a percentage */
  isPercentage(value) {
    const percentage = parseFloat(value);
    return !Number.isNaN(percentage);
  }

  /** Check if value is between upper and lower bounds */
  isBetweenBounds(value) {
    const percentage = parseFloat(value);
    return percentage >= -100 && percentage <= 100;
  }

  /** Check number of decimal places */
  hasTwoDecimals(value) {
    const number = parseFloat(value);

    if (Math.floor(number) === number) {
      return true;
    }

    // Force locale to en-US so that number has period.
    const valueWithDot = new Intl.NumberFormat('en-US').format(number);
    const decimalPlaces = valueWithDot.split('.')[1];

    return decimalPlaces.length <= 2;
  }

  /** Convert float to a percentage value, ex: 0.23 -> 23 */
  convertToPercentage(value) {
    if (this.isBlank(value)) return '';
    return Math.floor(value * 10000) / 100;
  }

  /** Convert from percentage to float */
  convertFromPercentage(value) {
    if (this.isBlank(value)) {
      return null;
    } else {
      return parseFloat(value) / 100;
    }
  }
}
