import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';
import { getOwner } from '@ember/application';

@classic
export default class AccountsRoute extends AuthRoute {
  @service
  alert;

  titleToken = 'Accounts';

  @action
  deleteAccount(account, reasons) {
    let sure = confirm('This will delete all your data');
    if (!sure) {
      return false;
    }

    let id = account.get('id');
    const alert = this.alert;
    account.set('isDeleting', true);

    this.ajax
      ._post(`/api/accounts/${id}/delete`, {
        reasonsForDeletion: reasons,
      })
      .then(
        () => {
          let applicationInstance = getOwner(this);
          let dashboardRoute = applicationInstance.lookup('route:dashboard');
          dashboardRoute.refresh();

          alert.success('Account deleted');
          account.set('isDeleting', false);
        },
        errors => {
          let data = errors[0];
          account.set('isDeleting', false);
          alert.error(`Error - ${data.message}`, { timeout: 10000 });
        }
      );

    return false;
  }
}
