import moment from 'moment';
import { ChannelListingDateViewObjectMother } from './availability-object-mother';

export const AVAILABILITY_SYNC_MOCK_USE_CASES = {
  random: getRandomAvailabilitySyncUseCase,
  dcReservationSynced: getFakeDCAvailabilitySynced,
  dcReservationUnsynced: getFakeUnsyncedDCAvailability,
  pmsOwnerReservationSynced: getFakeOwnerReservationUseCase,
  pmsOwnerReservationUnsynced: getUnsyncedPMSAvailability,
  dcReservationUnsyncedMultiDC: getFakeUnsyncedMultiDCAvailability,
  dcCancelationSynced: getFakeSyncedDCCancelation,
  pmsCancelationSynced: getFakeSyncedPMSCancelation,
};

export function getRandomAvailabilitySyncUseCase(date) {
  const mockKeys = Object.keys(AVAILABILITY_SYNC_MOCK_USE_CASES);
  const randomIndex = (mockKeys.length * Math.random()) | 0;
  const randomKey = mockKeys[randomIndex];

  return AVAILABILITY_SYNC_MOCK_USE_CASES[randomKey](date);
}

/** UC 1: DC Reservation (synced)
 * - Type: Happy path 🎉
 * - A DC reservation arrives => PMS updates => Availability synced to a 2nd DC
 */
export function getFakeDCAvailabilitySynced(date) {
  const SECS_IN_DAY = 24 * 60 * 60;
  const SOURCE_RESERVATION_DETECTED = 25;

  const PMS = new ChannelListingDateViewObjectMother()
    .setLabel('escapia')
    .setDate(date)
    .setReferenceOperationdate('2022-01-02')
    .pushScrapedUpdate('available', 0)
    .pushScrapedUpdate('blocked', SECS_IN_DAY + 2 * SOURCE_RESERVATION_DETECTED);

  const directChannels = [
    new ChannelListingDateViewObjectMother()
      .setLabel('airbnb')
      .setDate(date)
      .setReferenceOperationdate('2022-01-02')
      .pushSyncUpdate('blocked', SECS_IN_DAY + 2.5 * SOURCE_RESERVATION_DETECTED),

    new ChannelListingDateViewObjectMother()
      .setLabel('booking_connect')
      .setDate(date)
      .setReferenceOperationdate('2022-01-02')
      .pushSyncUpdate('blocked', SECS_IN_DAY + 3 * SOURCE_RESERVATION_DETECTED),
  ];
  return [PMS, directChannels];
}

/** UC 2: DC Reservation (unsynced)
 * - Type: Unhappy path 😞
 * - A DC reservation  => PMS didn´t update availability
 */
export function getFakeUnsyncedDCAvailability(date) {
  const SOURCE_RESERVATION_DETECTED = 25;

  const PMS = new ChannelListingDateViewObjectMother()
    .setLabel('escapia')
    .setDate(date)
    .setReferenceOperationdate('2022-01-02')
    .pushScrapedUpdate('available', 0);

  const directChannels = [
    new ChannelListingDateViewObjectMother()
      .setLabel('airbnb')
      .setDate(date)
      .setReferenceOperationdate('2022-01-02')
      .pushScrapedUpdate('blocked', SOURCE_RESERVATION_DETECTED),

    new ChannelListingDateViewObjectMother().setLabel('booking_connect').setDate(date),
  ];
  return [PMS, directChannels];
}

/** UC 3: PMS Owner Reservation (synced)
 * - Type: Happy path  🎉
 * - A PMS reservation scraped => Availability synced to both DCs
 */
export function getFakeOwnerReservationUseCase(date) {
  const SECS_IN_DAY = 24 * 60 * 60;
  const SOURCE_RESERVATION_DETECTED = 25;

  const PMS = new ChannelListingDateViewObjectMother()
    .setLabel('escapia')
    .setDate(date)
    .setReferenceOperationdate('2022-01-02')
    // .pushScrapedUpdate('available', 0)
    .pushScrapedUpdate('blocked', SECS_IN_DAY + SOURCE_RESERVATION_DETECTED);

  const directChannels = [
    new ChannelListingDateViewObjectMother()
      .setLabel('airbnb')
      .setDate(date)
      .setReferenceOperationdate('2022-01-02')
      .pushSyncUpdate('blocked', SECS_IN_DAY + 2 * SOURCE_RESERVATION_DETECTED),

    new ChannelListingDateViewObjectMother()
      .setLabel('booking_connect')
      .setDate(date)
      .setReferenceOperationdate('2022-01-02')
      .pushSyncUpdate('blocked', SECS_IN_DAY + 2.5 * SOURCE_RESERVATION_DETECTED),
  ];
  return [PMS, directChannels];
}

/** UC 4: PMS Owner Reservation (unsynced)
 * - Type: UnHappy path 😞
 * - A owner reservation in PMS , but no availability changes on DC yet
 */
export function getUnsyncedPMSAvailability(date) {
  const SECS_IN_DAY = 24 * 60 * 60;
  const SOURCE_RESERVATION_DETECTED = 25;

  const PMS = new ChannelListingDateViewObjectMother()
    .setLabel('escapia')
    .setDate(date)
    .setReferenceOperationdate('2022-01-02')
    .pushScrapedUpdate('available', 0)
    .pushScrapedUpdate('blocked', SECS_IN_DAY + SOURCE_RESERVATION_DETECTED);

  const directChannels = [
    new ChannelListingDateViewObjectMother().setLabel('airbnb').setDate(date),

    new ChannelListingDateViewObjectMother().setLabel('booking_connect').setDate(date),
  ];
  return [PMS, directChannels];
}

/** UC 5: Multi-channel (unsynced)
 * - Type: Unhappy path 😞
 * - A DC reservation => fetched in PMS  => no post on a 2nd channel
 */
export function getFakeUnsyncedMultiDCAvailability(date) {
  const SOURCE_RESERVATION_DETECTED = 25;

  const PMS = new ChannelListingDateViewObjectMother()
    .setLabel('escapia')
    .setDate(date)
    .setReferenceOperationdate('2022-01-02')
    .pushScrapedUpdate('available', 0)
    .pushScrapedUpdate('blocked', 2 * SOURCE_RESERVATION_DETECTED);

  const directChannels = [
    new ChannelListingDateViewObjectMother()
      .setLabel('airbnb')
      .setDate(date)
      .setReferenceOperationdate('2022-01-02')
      .pushScrapedUpdate('blocked', SOURCE_RESERVATION_DETECTED),

    new ChannelListingDateViewObjectMother().setLabel('booking_connect').setDate(date),
  ];
  return [PMS, directChannels];
}

/** COMBOS ------------------------------------------------- */
/** UC 6: Cancelation of DC reservation (synced)
 *  * - Type: Unhappy path  🎉
 *  - success DC reservation => canceled in DC => fetched in PMS => synced to 2nd DC
 */
export function getFakeSyncedDCCancelation(date) {
  // We take a mock of a synced block (source of reservation: DC)
  const [PMS, directChannels] = getFakeDCAvailabilitySynced(date);
  const refOperationDate = PMS.referenceOperationDate;
  // We move forward 1 week
  const statusToSync = 'available';
  const newRefOperationDate = moment(refOperationDate).add(1, 'week');

  directChannels[0]
    .setReferenceOperationdate(newRefOperationDate)
    .pushScrapedUpdate(statusToSync, 0);

  // eslint-disable-next-line prettier/prettier
  PMS.setReferenceOperationdate(newRefOperationDate).pushScrapedUpdate(
    statusToSync,
    45
  );

  directChannels[1]
    .setReferenceOperationdate(newRefOperationDate)
    .pushSyncUpdate(statusToSync, 105);

  return [PMS, directChannels];
}

/** UC 7: Cancelation of Owner reservation in PMS (synced)
 *  - Type: Happy path 🎉
 *  - success PMS reservation sync => canceled PMS reservation => Synced to DCs
 */
export function getFakeSyncedPMSCancelation(date) {
  // We take a mock of a synced block (source of reservation: DC)
  const [PMS, directChannels] = getFakeOwnerReservationUseCase(date);
  const refOperationDate = PMS.referenceOperationDate;
  // We move forward 1 week
  const statusToSync = 'available';
  const newRefOperationDate = moment(refOperationDate).add(1, 'week');

  // eslint-disable-next-line prettier/prettier
  PMS.setReferenceOperationdate(newRefOperationDate).pushScrapedUpdate(statusToSync, 0);

  directChannels[0]
    .setReferenceOperationdate(newRefOperationDate)
    .pushScrapedUpdate(statusToSync, 50);

  directChannels[1]
    .setReferenceOperationdate(newRefOperationDate)
    .pushSyncUpdate(statusToSync, 100);

  return [PMS, directChannels];
}
