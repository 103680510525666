import Store from '@ember-data/store';

export default class StoreService extends Store {
  // Ember-data doesn't provide an API to "mark" the records as being updated
  // This manual implementation was based on:
  // https://github.com/psteininger/ember-data-save-relationships/blob/16d1dbcad36f3504525de3bdf689b0414e618e4a/addon/index.js#L110
  markSaved(record) {
    const changedAttributes = record.changedAttributes();
    for (const key of Object.keys(changedAttributes)) {
      record._internalModel[key] = changedAttributes[key][1];
    }
    record._internalModel.adapterWillCommit();
    this.didSaveRecord(record._internalModel);
  }

  // Utility function to unload multiple records
  //
  // Usage:
  //   this.store.unloadRecords(recordInstances)
  //   this.store.unloadRecords('modelName', ids)
  unloadRecords(modelNameOrRecords, ids = []) {
    let recordsToUnload = modelNameOrRecords;
    if (typeof modelNameOrRecords === 'string') {
      recordsToUnload = ids.map(id => this.peekRecord(modelNameOrRecords, id));
    }

    for (const record of recordsToUnload) {
      this.unloadRecord(record);
    }
  }
}
