import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Bp::Button::Base\n  class={{this.classes}}\n  @onClick={{this.onClick}}\n  @href={{@href}}\n  ...attributes\n>\n  {{#if @startIcon}}\n    <AppSvg @src={{@startIcon}} @class={{this.startIconClasses}} />\n  {{/if}}\n  {{yield (hash Icon=(component 'app-svg' class=this.iconClasses))}}\n  {{#if @endIcon}}\n    <AppSvg @src={{@endIcon}} @class={{this.endIconClasses}} />\n  {{/if}}\n</Bp::Button::Base>", {"contents":"<Bp::Button::Base\n  class={{this.classes}}\n  @onClick={{this.onClick}}\n  @href={{@href}}\n  ...attributes\n>\n  {{#if @startIcon}}\n    <AppSvg @src={{@startIcon}} @class={{this.startIconClasses}} />\n  {{/if}}\n  {{yield (hash Icon=(component 'app-svg' class=this.iconClasses))}}\n  {{#if @endIcon}}\n    <AppSvg @src={{@endIcon}} @class={{this.endIconClasses}} />\n  {{/if}}\n</Bp::Button::Base>","moduleName":"appkit/components/bp/button/index.hbs","parseOptions":{"srcName":"appkit/components/bp/button/index.hbs"}});
import BaseButton from './base';
import { Themeable, VARIANTS } from '../theme';
import buttonTheme from './index.theme';
import classNames from 'classnames';

export default class BpButtonIndexComponent extends Themeable(BaseButton, {
  theme: buttonTheme,
  variant: VARIANTS.CONTAINED,
}) {
  get startIconClasses() {
    return classNames(
      this.getClassesForTheme(this.theme.startIcon),
      this.args.startIconClass
    );
  }

  get endIconClasses() {
    return classNames(
      this.getClassesForTheme(this.theme.endIcon),
      this.args.endIconClass
    );
  }

  get iconClasses() {
    return classNames(this.getClassesForTheme(this.theme.icon), this.args.iconClass);
  }
}
