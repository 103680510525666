import { action } from '@ember/object';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
export default class ResetPmsConnection extends Controller {
  @tracked managedAccountValid = false;
  @tracked asyncResponseData = A([]);
  @tracked outdatedSyncWarning = '';
  managedAccountId = null;
  staffUserId = null;
  managedAccountDetails = ['No Managed Account Checked'];

  @action
  checkManagedAccount() {
    let managedAccountId = this.managedAccountId;
    let url = `/api/bulk_updates/check_managed_account/${managedAccountId}`;
    let controller = this;

    this.ajax._get(url).then(
      data => {
        let details = [
          `MA id: ${data.id}`,
          `# LAM: ${data.lam}`,
          `Channel: ${data.channel}`,
          `User: ${data.user}`,
          `Last scrape: ${new Date(data.lastScrapeStamp)}`,
        ];
        const oneDay = 1000 * 24 * 60 * 60;
        const dateDiff = new Date() - new Date(data.lastScrapeStamp);
        if (dateDiff > oneDay) {
          this.outdatedSyncWarning = `It looks like this managed account hasn’t been refreshed in ${Math.round(
            dateDiff / oneDay
          )} days. You can still reset the settings here, but you also might want to refresh the account in Beyond to pull in the latest reservations, etc.`;
        }
        controller.set('managedAccountDetails', details);
        this.managedAccountValid = true;
      },
      () => {
        alert('Managed Account lookup failed.');
        controller.set('managedAccountDetails', ['Managed Account lookup failed']);
        controller.set('managedAccountId', null);
        this.managedAccountValid = false;
      }
    );
  }

  @action
  async submitForm() {
    let managedAccountId = this.managedAccountId;
    let staffUserId = this.staffUserId;
    let data = {
      staff_user_id: staffUserId,
      managed_account_id: managedAccountId,
    };
    let url = '/api/internal-tools/hard-reset-and-import-settings';
    let result;
    this.asyncResponseData = A([]);
    if (!staffUserId) {
      alert('Submitter User ID is required. Aborting.');
      return;
    }

    if (!managedAccountId) {
      alert('Managed Account ID is required. Aborting.');
      return;
    }

    try {
      result = await this.ajax._post(url, data);
      console.log(result);
      let jobUrl = `/v2/job/${result.jobId}/status`;
      try {
        await this.ajax.stream(jobUrl, {}, responseData => {
          this.asyncResponseData.pushObject(responseData.message);
        });
      } catch (errors) {
        if (Array.isArray(errors)) {
          errors.forEach(e => {
            this.asyncResponseData.pushObject(e.message);
          });
        } else {
          this.asyncResponseData.pushObject(`Something went wrong: ${errors}`);
        }
      }
    } catch (errors) {
      if (Array.isArray(errors)) {
        errors.forEach(e => {
          this.asyncResponseData.pushObject(e.message);
        });
      } else {
        this.asyncResponseData.pushObject(`Something went wrong: ${errors}`);
      }
    }
  }
}
