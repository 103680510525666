import EmberObject, { computed } from '@ember/object';
import { Copyable } from 'ember-copy';
import moment from 'moment';
import { CurrencyUtil } from 'appkit/lib/currency';

export const RangeValidator = {
  validateSingleRange(
    ranges,
    minStay,
    maxStay,
    checkinCheckoutOptions,
    intl,
    currency
  ) {
    let valid = true;

    let singleCurrency = typeof currency === 'string';

    for (let rangeName in ranges) {
      ranges[rangeName].forEach(range => {
        range.set('error', null);

        if (!range.fields) {
          range.set('error', intl.t('validation.rangeErrors.rangefieldsMustBeSet'));
          valid = false;
          return;
        }

        if (!range.get('startDate') || !range.get('endDate')) {
          range.set(
            'error',
            intl.t('validation.rangeErrors.pleaseAddStartDateAndEndDate')
          );
          valid = false;
          return;
        }

        if (range.get('startDate') || range.get('endDate')) {
          if (
            rangeName === 'minStayRanges' &&
            range.get('minStay') !== null &&
            range.get('minStay') < 0
          ) {
            range.set('error', intl.t('validation.rangeErrors.pleaseSetMinimumStay'));
            valid = false;
          }
          if (rangeName === 'maxStayRanges' && !range.get('maxStay')) {
            range.set('error', intl.t('validation.rangeErrors.pleaseSetMaximumStay'));
            valid = false;
          }
          if (
            rangeName === 'minPriceRanges' &&
            range.minPrice !== null &&
            range.minPrice < 0 &&
            !range.get('monthlyMinPrice')
          ) {
            range.set('error', intl.t('validation.rangeErrors.pleaseSetMinimumPrice'));
            valid = false;
          }
          if (
            rangeName === 'minPriceRanges' &&
            range.minPrice !== null &&
            range.minPrice < 5 &&
            !range.get('monthlyMinPrice')
          ) {
            if (!(!singleCurrency && range.minPrice == 0)) {
              range.set(
                'error',
                intl.t('validation.pricingErrors.minMinPrice', {
                  value: singleCurrency
                    ? CurrencyUtil.format(5, {
                        currency,
                      })
                    : currency
                        .map(currency =>
                          CurrencyUtil.format(5, {
                            currency,
                          })
                        )
                        .join(', '),
                })
              );
              valid = false;
            }
          }
          if (
            rangeName === 'minMaxPriceRanges' &&
            range.minPrice !== null &&
            range.minPrice < 0 &&
            range.maxPrice < 0 &&
            !range.monthlyMinPrice &&
            !range.monthlyMaxPrice
          ) {
            range.set(
              'error',
              intl.t('validation.rangeErrors.pleaseSetMinimumPriceOrMaximumPriceOrBoth')
            );
            valid = false;
          }
          if (
            rangeName === 'minMaxPriceRanges' &&
            range.minPrice !== null &&
            range.minPrice < 5
          ) {
            if (!(!singleCurrency && range.minPrice == 0)) {
              range.set(
                'error',
                intl.t('validation.pricingErrors.minMinPrice', {
                  value: singleCurrency
                    ? CurrencyUtil.format(5, {
                        currency,
                      })
                    : currency
                        .map(currency =>
                          CurrencyUtil.format(5, {
                            currency,
                          })
                        )
                        .join(', '),
                })
              );
              valid = false;
            }
          }
          if (
            rangeName === 'minMaxPriceRanges' &&
            range.maxPrice !== null &&
            range.maxPrice < 10
          ) {
            if (!(!singleCurrency && range.maxPrice == 0)) {
              range.set(
                'error',
                intl.t('validation.pricingErrors.minMaxPrice', {
                  value: singleCurrency
                    ? CurrencyUtil.format(10, {
                        currency,
                      })
                    : currency
                        .map(currency =>
                          CurrencyUtil.format(10, {
                            currency,
                          })
                        )
                        .join(', '),
                })
              );
              valid = false;
            }
          }
          if (rangeName === 'monthlyMinPriceRanges' && range.monthlyMinPrice === null) {
            range.set(
              'error',
              intl.t('validation.rangeErrors.pleaseSetMonthlyMinimumPrice')
            );
            valid = false;
          }
          if (
            rangeName === 'monthlyMinPriceRanges' &&
            range.monthlyMinPrice !== null &&
            range.monthlyMinPrice < 5
          ) {
            if (!(!singleCurrency && range.monthlyMinPrice == 0)) {
              range.set(
                'error',
                intl.t('validation.pricingErrors.minMonthlyMinPrice', {
                  value: singleCurrency
                    ? CurrencyUtil.format(5, {
                        currency,
                      })
                    : currency
                        .map(currency =>
                          CurrencyUtil.format(5, {
                            currency,
                          })
                        )
                        .join(', '),
                })
              );
              valid = false;
            }
          }
          if (
            rangeName === 'monthlyMinMaxPriceRanges' &&
            range.monthlyMinPrice === null &&
            range.monthlyMaxPrice === null
          ) {
            range.set(
              'error',
              intl.t(
                'validation.rangeErrors.pleaseSeMonthlytMinimumPriceOrMonthlyMaximumPriceOrBoth'
              )
            );
            valid = false;
          }
          if (
            rangeName === 'monthlyMinMaxPriceRanges' &&
            range.monthlyMinPrice !== null &&
            range.monthlyMinPrice < 5
          ) {
            if (!(!singleCurrency && range.monthlyMinPrice == 0)) {
              range.set(
                'error',
                intl.t('validation.pricingErrors.minMonthlyMinPrice', {
                  value: singleCurrency
                    ? CurrencyUtil.format(5, {
                        currency,
                      })
                    : currency
                        .map(currency =>
                          CurrencyUtil.format(5, {
                            currency,
                          })
                        )
                        .join(', '),
                })
              );
              valid = false;
            }
          }
          if (
            rangeName === 'monthlyMinMaxPriceRanges' &&
            range.monthlyMaxPrice !== null &&
            range.monthlyMaxPrice < 10
          ) {
            if (!(!singleCurrency && range.monthlyMaxPrice == 0)) {
              range.set(
                'error',
                intl.t('validation.pricingErrors.minMonthlyMaxPrice', {
                  value: singleCurrency
                    ? CurrencyUtil.format(10, {
                        currency,
                      })
                    : currency
                        .map(currency =>
                          CurrencyUtil.format(10, {
                            currency,
                          })
                        )
                        .join(', '),
                })
              );
              valid = false;
            }
          }
          if (
            range.get('maxStay') !== null &&
            rangeName === 'maxStayRanges' &&
            minStay > range.get('maxStay')
          ) {
            range.set(
              'error',
              intl.t('validation.rangeErrors.maximumStaysMustBeLargerThanMinimumStays')
            );
            valid = false;
          }
          if (
            range.get('maxStay') !== null &&
            rangeName === 'minStayRanges' &&
            range.get('minStay') > maxStay
          ) {
            range.set(
              'error',
              intl.t('validation.rangeErrors.maximumStaysMustBeLargerThanMinimumStays')
            );
            valid = false;
          }

          if (
            rangeName === 'checkinDaysRanges' &&
            !((range.checkinDays || []).length > 0)
          ) {
            range.set('error', intl.t('validation.rangeErrors.pleaseSetCheckinDays'));
            valid = false;
          }
        }
      });
    }
    return valid;
  },

  validateRanges(rangeObjects, intl) {
    let priorRange = null;
    let valid = true;

    if (rangeObjects === undefined || rangeObjects === null) {
      return valid;
    }
    if (rangeObjects.length === 0) {
      return valid;
    }

    rangeObjects.forEach(range => {
      range.set('error', null);
    });

    // Sort ranges by startDate
    rangeObjects.sort((a, b) => {
      return a.get('startDate').valueOf() - b.get('startDate').valueOf();
    });

    // Check if ranges overlap with each other
    for (let range of rangeObjects) {
      if (!priorRange) {
        priorRange = range;
        continue;
      }
      let msg = intl.t('validation.rangeErrors.dateRangeShouldNotOverlap');

      let priorRangeDoWSettings = priorRange.dayOfWeek;
      let currentRangeDoWSettings = range.dayOfWeek;

      let isDayOfWeekOverlapping =
        priorRangeDoWSettings?.length > currentRangeDoWSettings?.length
          ? currentRangeDoWSettings?.filter(dow => priorRangeDoWSettings?.includes(dow))
              .length != 0
          : priorRangeDoWSettings?.filter(dow => currentRangeDoWSettings?.includes(dow))
              .length != 0;

      if (
        range.get('startDate').isSameOrBefore(priorRange.get('endDate')) &&
        range.get('endDate').isSameOrAfter(priorRange.get('startDate')) &&
        isDayOfWeekOverlapping
      ) {
        range.set('error', msg);
        priorRange.set('error', msg);
        valid = false;
      }

      priorRange = range;
    }

    return valid;
  },

  removeEmpty(ranges) {
    if (ranges === undefined || ranges === null) return [];

    return ranges.reduce((acc, range) => {
      if (!range.get('startDate') && !range.get('endDate') && range.hasNoValue()) {
        return acc;
      }
      acc.push(range);
      return acc;
    }, []);
  },
};

// eslint-disable-next-line ember/no-classic-classes
export const ValueRange = EmberObject.extend(Copyable, {
  savedMessage: null,
  error: null,

  // Space separated list of fields that we care about for this range. This
  // used to just be a value, but then we started having ranges that had
  // multiple values (e.g. min/max price)
  fields: '',

  _startDate: null,
  startDate: computed('_startDate', {
    get() {
      return this._startDate;
    },
    set(key, value) {
      if (value && value._isMomentObject) {
        this.set('_startDate', value);
        return value;
      }
      let startDate = value && moment(value);
      this.set('_startDate', startDate);
      return startDate;
    },
  }),
  startDateIso: computed('startDate', function () {
    if (this.startDate) {
      return this.startDate.format('YYYY-MM-DD');
    } else {
      return '';
    }
  }),
  startDateNative: computed('startDate', function () {
    let iso = this.startDateIso;
    return iso ? moment(iso).toDate() : new Date();
  }),

  _endDate: null,
  endDate: computed('_endDate', {
    get() {
      return this._endDate;
    },
    set(key, value) {
      if (value && value._isMomentObject) {
        this.set('_endDate', value);
        return value;
      }
      let endDate = value && moment(value);
      this.set('_endDate', endDate);
      return endDate;
    },
  }),
  endDateIso: computed('endDate', function () {
    if (this.endDate) {
      return this.endDate.format('YYYY-MM-DD');
    } else {
      return '';
    }
  }),
  endDateNative: computed('endDate', function () {
    let iso = this.endDateIso;
    return iso ? moment(iso).toDate() : '';
  }),

  _changeoverDay: null,
  changeoverDay: computed('_changeoverDay', {
    get() {
      return this._changeoverDay;
    },
    set(key, value) {
      this.set('_changeoverDay', value);
      return this._changeoverDay;
    },
  }),

  _checkinDays: null,
  checkinDays: computed('_checkinDays', {
    get() {
      return this._checkinDays;
    },
    set(key, value) {
      this.set('_checkinDays', value);
      return this._checkinDays;
    },
  }),

  _checkoutDays: null,
  checkoutDays: computed('_checkoutDays', {
    get() {
      return this._checkoutDays;
    },
    set(key, value) {
      this.set('_checkoutDays', value);
      return this._checkoutDays;
    },
  }),

  _minStay: null,
  minStay: computed('_minStay', {
    get() {
      return this._minStay;
    },
    set(key, value) {
      this.set('_minStay', value !== null ? Number(value) : null);
      return this._minStay;
    },
  }),

  _maxStay: null,
  maxStay: computed('_maxStay', {
    get() {
      return Number(this._maxStay) || null;
    },
    set(key, value) {
      this.set('_maxStay', Number(value));
      return this._maxStay;
    },
  }),

  _maxPrice: null,
  maxPrice: computed('_maxPrice', {
    get() {
      return Number(this._maxPrice) || null;
    },
    set(key, value) {
      this.set('_maxPrice', Number(value) || null);
      return this._maxPrice;
    },
  }),

  _minPrice: null,
  minPrice: computed('_minPrice', {
    get() {
      return this._minPrice;
    },
    set(key, value) {
      this.set('_minPrice', value !== null ? Number(value) : null);
      return this._minPrice;
    },
  }),

  _monthlyMinPrice: null,
  monthlyMinPrice: computed('_monthlyMinPrice', {
    get() {
      return Number(this._monthlyMinPrice) || null;
    },
    set(key, value) {
      this.set('_monthlyMinPrice', value !== null ? Number(value) : null);
      return this._monthlyMinPrice;
    },
  }),

  _monthlyMaxPrice: null,
  monthlyMaxPrice: computed('_monthlyMaxPrice', {
    get() {
      return Number(this._monthlyMaxPrice) || null;
    },
    set(key, value) {
      this.set('_monthlyMaxPrice', value !== null ? Number(value) : null);
      return this._monthlyMaxPrice;
    },
  }),

  _priceOverridePercentage: null,
  priceOverridePercentage: computed('_priceOverride', {
    get() {
      return Number(this._priceOverridePercentage) || null;
    },
    set(key, value) {
      this.set('_priceOverridePercentage', Number(value) || null);
      return this._priceOverridePercentage;
    },
  }),

  _monthlyPriceOverridePercentage: null,
  monthlyPriceOverridePercentage: computed('_monthlyPriceOverride', {
    get() {
      return Number(this._monthlyPriceOverridePercentage) || null;
    },
    set(key, value) {
      this.set('_monthlyPriceOverridePercentage', Number(value) || null);
      return this._monthlyPriceOverridePercentage;
    },
  }),

  _monthlyEnabled: null,
  monthlyEnabled: computed('_monthlyEnabled', {
    get() {
      return Boolean(this._monthlyEnabled) || null;
    },
    set(key, value) {
      this.set('_monthlyEnabled', Boolean(value) || null);
      return this._monthlyEnabled;
    },
  }),

  validate({ listingMinPrice, listingMaxPrice }, intl) {
    const isDefined = x => x !== null && x !== undefined && x !== '';

    const minPrice = this.minPrice;
    const maxPrice = this.maxPrice;
    const monthlyMinPrice = this.monthlyMinPrice;
    const monthlyMaxPrice = this.monthlyMaxPrice;

    if (isDefined(maxPrice) && maxPrice < 10) {
      this.set(
        'error',
        intl.t('validation.rangeErrors.seasonalMaximumPriceMustBeAtLeastTen')
      );
      return false;
    }

    if (isDefined(minPrice) && isDefined(maxPrice) && minPrice > maxPrice) {
      this.set(
        'error',
        intl.t(
          'validation.rangeErrors.seasonalMaximumPriceMustBeLargerThanSeasonalMinimumPrice'
        )
      );
      return false;
    }

    if (
      isDefined(monthlyMinPrice) &&
      isDefined(monthlyMaxPrice) &&
      monthlyMinPrice > monthlyMaxPrice
    ) {
      this.set(
        'error',
        intl.t(
          'validation.rangeErrors.seasonalMonthlyMaximumPriceMustBeLargerThanSeasonalMonthlyMinimumPrice'
        )
      );
      return false;
    }

    if (
      isDefined(minPrice) &&
      !isDefined(maxPrice) &&
      isDefined(listingMaxPrice) &&
      minPrice > listingMaxPrice
    ) {
      this.set(
        'error',
        intl.t(
          'validation.rangeErrors.seasonalMinimumPriceMustBeLessThanDefaultMinimumPrice'
        )
      );
      return false;
    }

    if (
      isDefined(monthlyMinPrice) &&
      !isDefined(monthlyMaxPrice) &&
      isDefined(listingMaxPrice) &&
      monthlyMinPrice > listingMaxPrice
    ) {
      this.set(
        'error',
        intl.t(
          'validation.rangeErrors.seasonalMonthlyMinimumPriceMustBeLessThanDefaultMinimumPrice'
        )
      );
      return false;
    }

    if (
      isDefined(maxPrice) &&
      !isDefined(minPrice) &&
      isDefined(listingMinPrice) &&
      maxPrice < listingMinPrice
    ) {
      this.set(
        'error',
        intl.t(
          'validation.rangeErrors.seasonalMaximumPriceMustBeLargerThanDefaultMinimumPrice'
        )
      );
      return false;
    }

    if (
      isDefined(monthlyMaxPrice) &&
      !isDefined(monthlyMinPrice) &&
      isDefined(listingMinPrice) &&
      monthlyMaxPrice < listingMinPrice
    ) {
      this.set(
        'error',
        intl.t(
          'validation.rangeErrors.seasonalMonthlyMaximumPriceMustBeLargerThanDefaultMinimumPrice'
        )
      );
      return false;
    }

    this.set('error', null);
    return true;
  },

  allFields() {
    return [
      'minStay',
      'maxStay',
      'maxPrice',
      'minPrice',
      'checkinDays',
      'checkoutDays',
      'changeoverDay',
      'priceOverridePercentage',
      'monthlyMinPrice',
      'monthlyMaxPrice',
      'monthlyPriceOverridePercentage',
      'monthlyEnabled',
    ];
  },

  serialize() {
    if (!this.startDate || !this.endDate) {
      return {};
    }
    const ret = {
      startDate: this.startDate.format('YYYY-MM-DD'),
      endDate: this.endDate.format('YYYY-MM-DD'),
    };

    this.fields
      .split(' ')
      .filter(i => i)
      .forEach(field => {
        ret[field.camelize()] = this.get(field.camelize());
      });

    return ret;
  },

  copy() {
    // Creates a copy of a range
    const ret = {
      fields: this.fields,
      startDate: this.startDate.clone(),
      endDate: this.endDate.clone(),
    };
    this.fields.split(' ').forEach(field => {
      ret[field.camelize()] = this.get(field.camelize());
    });

    return this.constructor.create(ret);
  },

  hasNoValue() {
    const values = [
      this._minPrice,
      this._maxPrice,
      this._monthlyMinPrice,
      this._monthlyMaxPrice,
      this._minStay,
      this._maxStay,
      this._changeoverDay,
      this._checkinDays,
      this._checkoutDays,
      this._priceOverride,
      this._monthlyPriceOverride,
      this._monthlyEnabled,
    ];
    let ret = true;
    values.forEach(val => {
      ret = val === null && ret;
    });

    return ret;
  },
});
