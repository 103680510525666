import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class DashboardConnectPromptController extends Controller {
  @service populateAccount;
  @tracked onboardingSurveyAlreadyAnswered = this.model.onboardingSurveyAlreadyAnswered;
  @tracked firstAccountAlreadyConnected = this.model.firstAccountAlreadyConnected;

  get areAccountsInProcess() {
    return this.populateAccount.accountsInProcess.length > 0;
  }

  get accountsInSyncingProcess() {
    return this.populateAccount.accountsInProcess;
  }
}
