import Service from '@ember/service';
import { inject as service } from '@ember/service';
import {
  MergeListingsUC,
  UnmergeListingsUC,
  SaveMergedListingsPreferences,
  ApplyMergedListingsConfigUC,
} from '../../lib/listings/UC';

/**
 * Act as a facade to the diferent parts of the Listings domain
 * (data, persistence, use cases).
 *
 * Consumers (controllers) can access this without having knowledge
 * of the concrete implementation.
 */

export default class ListingsFacadeService extends Service {
  @service ajax;

  async mergeListings(listings, settings) {
    return MergeListingsUC(listings, settings, this.ajax);
  }
  async unmergeListings(listings) {
    return UnmergeListingsUC(listings, this.ajax);
  }
  async savePreferences(listings, preferences, user) {
    return SaveMergedListingsPreferences(listings, preferences, user, this.ajax);
  }
  applySettingsOnMergedListings(listings, settings) {
    ApplyMergedListingsConfigUC(listings, settings);
  }
}
