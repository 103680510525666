import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// import {
//   validationErrorsEnum,
//   dynamicMarkupValidator,
//   staticMarkupValidator,
// } from '../../merged/settings/settings_validators';
import { MarkupsType } from 'appkit/lib/relay/adapters/rel-markups-adapter';

const DEFAULT_EDITOR_STATE = {
  markupsType: null,
  markups: new Map(),
  taxType: null,
  syncPricing: null,
  syncAvailability: null,
  syncReservations: null,
};

/**
 * A single class to handle changes at merged listings.
 * - Receives an initial state, with the parts of the model that the user is allowed to edit
 * - Does live-validation on input changes at single level
 * - Does a group-validation on command
 */
export class MergedListingsSettingsEditor {
  @tracked state = DEFAULT_EDITOR_STATE;
  @tracked errors = {};
  //   validationErrorsEnum = validationErrorsEnum;
  //   permissions;
  //   groupValidator;

  constructor({ initialSettings }) {
    // this.permissions = permissions;
    // this.groupValidator = groupValidator;

    const { markups, ...rest } = initialSettings;
    this.state = { ...this.state, ...rest };
    this.setupInitialMarkups(markups, initialSettings.markupsType);
  }

  @action
  onMarkupChanged(channel, markupKey, event) {
    const value = event.target.value;
    const markup = this.state.markups.get(channel) || {};

    this.state.markups.set(channel, { ...markup, [markupKey]: value });
    // this.validateMarkup(channel);
  }

  @action
  onMarkupsTypeChanged(markupsType) {
    this.state = { ...this.state, markupsType: markupsType };
  }

  @action
  onClearMarkups() {
    this.state.markups.forEach(x => {
      x.value = null;
      x.minMarkup = null;
      x.maxMarkup = null;
    });
    this.state = { ...this.state, markupsType: null };
    this.errors = { ...this.errors, staticMarkups: null, dynamicMarkups: null };
  }

  @action
  onTaxStatusChanged(taxType) {
    this.state = { ...this.state, taxType: taxType };
    this.errors = { ...this.errors, taxType: null };
  }

  @action
  onSyncToggled(syncKey, event) {
    this.state = { ...this.state, [syncKey]: event.target.checked };
  }

  setupInitialMarkups(markups, markupsType) {
    if (!markupsType || markupsType === MarkupsType.Mixed) {
      // Empty state. Just use the channel data
      markups.forEach(markup => {
        const { channel, channelId, channelLabel } = markup;
        this.state.markups.set(channel, { channel, channelId, channelLabel });
      });
    } else {
      // Saved state. Keep the channel data AND the existing markups data
      markups.forEach(markup => this.state.markups.set(markup.channel, markup));
    }
  }

  updateErrorState(key, err) {
    if (this.errors[key] !== err) {
      this.errors = { ...this.errors, [key]: err };
    }
  }

  //   validateMarkup(channel) {
  //     const updatedMarkup = this.state.markups.get(channel);

  //     if (this.state.markupsType === MarkupsType.Dynamic) {
  //       const validation = dynamicMarkupValidator(updatedMarkup);
  //       this.updateErrorState('dynamicMarkups', validation);
  //     }
  //     if (this.state.markupsType === MarkupsType.Static) {
  //       const validation = staticMarkupValidator(updatedMarkup);
  //       this.updateErrorState('staticMarkups', validation);
  //     }
  //   }

  // validateGroup() {
  //   const errors = this.groupValidator(this.state, this.permissions);

  //   if (Object.keys(errors).length) {
  //     this.errors = { ...this.errors, ...errors };
  //   }
  //   const isValid = !Object.values(this.errors).some(err => err);

  //   return isValid;
  // }
}
