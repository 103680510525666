import calculatePosition from 'ember-basic-dropdown/utils/calculate-position';

// Dropdown config
const verticalSpacing = 16;

export const getCalculatePosition = callback =>
  function (_trigger, _content, _destination, { renderInPlace }) {
    const newPosition = calculatePosition(...arguments);
    let classes = renderInPlace ? ['w-full', 'my-4'] : [];

    if (newPosition.verticalPosition === 'below') {
      newPosition.style.top = newPosition.style.top + verticalSpacing;
      classes.push('dropdown-arrow-top');
    } else {
      newPosition.style.top = newPosition.style.top - verticalSpacing;
      classes.push('dropdown-arrow-bottom');
    }

    callback({ classes });

    return newPosition;
  };
