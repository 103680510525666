import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import { later } from '@ember/runloop';
import { getOwner } from '@ember/application';

@classic
export default class FilterRoute extends AuthRoute {
  renderTemplate() {
    this.render('dashboard/filter', {
      into: 'dashboard/grid',
      controller: 'dashboard/grid',
    });
  }

  activate() {
    const gridController = getOwner(this).lookup('controller:dashboard.grid');
    later(
      this,
      function () {
        gridController.isFilterVisible = true;
      },
      100
    );
  }

  deactivate() {
    const gridController = getOwner(this).lookup('controller:dashboard.grid');
    gridController.isFilterVisible = false;
  }
}
