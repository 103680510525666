import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class InvalidListingController extends Controller {
  @action
  async onClickValidate(channelListingId) {
    let url = `/api/listings/${channelListingId}/validate`;

    try {
      await this.ajax._post(url).then(() => {
        window.location.reload();
      });
    } catch (errors) {
      alert(JSON.stringify(errors));
    }
  }
}
