import classic from 'ember-classic-decorator';
import { classNames, classNameBindings } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { run, next } from '@ember/runloop';
import $ from 'jquery';
import Component from '@ember/component';
import EmberObject, { action, computed } from '@ember/object';
import ListingDate from 'appkit/bp-models/listing_date';
import moment from 'moment';
import { displayErrors } from 'appkit/lib/display_errors';
import { tracked } from '@glimmer/tracking';

@classic
class Square extends EmberObject {
  calendar = null;
  id = null;
  day = null;
  hoverTarget = false;
}

@classic
@classNames('app-calendar')
@classNameBindings('loading', 'calendarEditExpanded:expand-actions')
export default class AppCalendar extends Component {
  @service alert;
  @service intl;
  @service featureFlag;

  scrollVectorStart = 0;
  canOverride = true;

  @alias('listing.loading')
  loading;

  hoverDay = null;
  calendarEditExpanded = false;

  @tracked
  seasons = [];

  @computed('hoverDay')
  get popperClassNames() {
    if (this.hoverDay) {
      return 'popper hover-window calendar-hover show';
    }
    return 'popper hover-window calendar-hover';
  }

  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed
  get _popperTarget() {
    if (this.__popperTarget) {
      return this.__popperTarget;
    }
    return document.querySelector('.app-calendar') || document.body;
  }

  set _popperTarget(value) {
    return (this.__popperTarget = value);
  }

  @computed('intl.locale')
  get localeWeekdays() {
    // Consume computed property so we get called when it changes.
    this.get('intl.locale');

    // week.dow is the start of the week in the current locale.
    let start = moment.localeData()._week.dow;
    let out = [];

    for (let index = start; index < start + 7; index++) {
      let weekday = index % 7;
      out.push({
        short: moment.localeData()._weekdaysShort[weekday],
        long: moment.localeData()._weekdays[weekday],
      });
    }
    return out;
  }

  @computed('intl.locale', 'listing', 'calendar.@each', 'listing.monthlySeasons.[]')
  get months() {
    let locale = this.get('intl.locale');

    // Careful not to modify the original array
    let days = [].concat(this.get('calendar') || []);

    if (days.length < 1) {
      return [];
    }

    if (this.isMonthly) {
      let dailyPricingDays = [].concat(this.get('dailyCalendar') || []);

      for (let day of days) {
        day.set(
          'dailyPriceUserOrModeled',
          getDailyCalendarPrice(dailyPricingDays, day.date)
        );

        day.set(
          'monthlyEnabled',
          isInsideMonthlySeason(day.date, this.listing.monthlySeasons || [])
        );
      }
    }

    let months = [];
    for (let day of days) {
      // I think we've missed a dependent key somewhere. The locale on the
      // listing.calendar moment objects doesn't update. Cast it here.
      let date = day.get('date').locale(locale);
      let startOfMonth = date.clone().startOf('month');
      let endOfMonth = date.clone().endOf('month');

      // Weeks start on sunday. Fill first week with blanks.
      if (months.length < 1 || date.isSame(startOfMonth, 'day')) {
        let fillLength = date.weekday();
        months.push(
          EmberObject.create({
            title: date.locale('en').format('MMMM'),
            year: date.format('YYYY'),
            visible: false,
            days: Array(fillLength).fill(null),
          })
        );
      }

      months[months.length - 1].days.push(
        Square.create({
          calendar: this,
          day: day,
          hoverTarget: false,
        })
      );

      // Fill the last day of the cal and the last week of each month.
      if (day === days[days.length - 1] || date.isSame(endOfMonth, 'day')) {
        let fillLength = months[months.length - 1].days.length % 7;
        if (fillLength !== 0) {
          months[months.length - 1].days.push(...Array(7 - fillLength).fill(null));
        }
      }
    }
    return months;
  }

  selectSquareStart = null;
  selectSquareEnd = null;

  @computed('selectSquareStart', 'selectSquareEnd')
  get editSquareFirst() {
    if (this.selectSquareStart && this.selectSquareEnd) {
      // If both are not null
      if (
        this.get('selectSquareStart.day.date') > this.get('selectSquareEnd.day.date')
      ) {
        // If both Squares have values and Start is AFTER End, then return End as 'first'
        return this.selectSquareEnd;
      }
    }
    // If neither condition happens (either selectSquareStart is less or equal or one is null, just return the value
    return this.selectSquareStart;
  }

  @computed('selectSquareEnd', 'selectSquareStart')
  get editSquareLast() {
    if (this.selectSquareEnd && this.selectSquareStart) {
      // If both are not null
      if (
        this.get('selectSquareEnd.day.date') < this.get('selectSquareStart.day.date')
      ) {
        // If both Squares have values and End is BEFORE Start, then return Start as 'last'
        return this.selectSquareStart;
      }
    }
    // If neither condition happens (either selectSquareStart is less or equal or one is null, just return the value
    return this.selectSquareEnd;
  }

  selectionWeekdayFilters = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  @computed(
    'editSquareFirst.day',
    'editSquareLast.day',
    'calendar.@each',
    'selectionWeekdayFilters.@each'
  )
  get selectedDays() {
    let startDate = this.get('editSquareFirst.day.date');
    if (!startDate) {
      return [];
    }
    let endDate = this.get('editSquareLast.day.date');
    if (!endDate) {
      return [];
    }

    let calendarAll = this.get('calendar') || [];
    return calendarAll.filter(item => {
      if (!item) {
        return false;
      }
      if (item.date < startDate) {
        return false;
      }
      if (item.date > endDate) {
        return false;
      }
      const dayName = moment.localeData('en')._weekdays[item.date.weekday()];
      if (!this.selectionWeekdayFilters.includes(dayName)) {
        return false;
      }
      return true;
    });
  }

  @computed('currentUser.isStaff')
  get calendarColorsCss() {
    return '-calendar-colors';
  }

  resetChanges(selectedDays) {
    const days = selectedDays || this.selectedDays;
    days.filter(day => day.isDirty).forEach(dirtyDay => dirtyDay.resetChanges());
  }

  resetChangesForWeekday(weekday) {
    this.selectedDays
      .filter(day => day.date.locale('en').format('dddd') === weekday)
      .filter(day => day.isDirty)
      .forEach(dirtyDay => dirtyDay.resetChanges());
  }

  applyChangesForWeekday(weekday, formState) {
    const days = this.selectedDays.filter(
      day => day.date.locale('en').format('dddd') === weekday
    );

    this.applyChangesForDays(days, formState);
  }

  applyChangesForDays(selectedDays, formState) {
    const {
      userMinStays,
      priceOverrideSelected,
      priceOverrideValue,
      availability,
    } = formState;

    if (formState.isClear) {
      return this.resetChanges(selectedDays);
    }

    if (userMinStays) {
      selectedDays.forEach(day => {
        day.set('minStayUser', userMinStays);
      });
    }

    if (priceOverrideSelected?.action != null) {
      this.priceOverrideChanged(
        selectedDays,
        priceOverrideValue,
        priceOverrideSelected?.action
      );
    }

    if (availability !== undefined) {
      selectedDays
        .filter(day => day.isAvailabilityEditable)
        .forEach(day => {
          if (availability) {
            day.set('availability', availability);
          } else {
            day.set('availability', day._dirty.availability);
          }
        });
    }
  }

  priceOverrideChanged(selectedDays, value = null, method) {
    for (let day of selectedDays) {
      if (!(Number(value) !== 0)) {
        // They've erased the whole price. Reset it back to the original price.
        day.set('priceUser', day._dirty.priceUser);
        continue;
      }

      if (method === 'amount') {
        day.set('priceUser', Number(value));
      } else if (method === 'increase') {
        // Always use original value to avoid compounding effect
        const originalPriceUser = day._dirty.priceUser || day.priceModeled;
        const price = Math.floor(
          originalPriceUser + originalPriceUser * (Number(value) / 100)
        );
        // Do not allow prices above maximum price
        day.set('priceUser', price);
      } else if (method === 'decrease') {
        // Always use original value to avoid compounding effect
        const originalPriceUser = day._dirty.priceUser || day.priceModeled;
        const price = Math.floor(
          originalPriceUser - originalPriceUser * (Number(value) / 100)
        );
        // Do not allow prices below minimum price
        day.set('priceUser', price < 10 ? 10 : price);
      } else {
        throw Error(`Invalid method: ${method}`);
      }
    }
  }

  async saveDays(selectedDays, properties) {
    const days = selectedDays || this.selectedDays;

    if (!this.canOverride) {
      return false;
    }

    let dirtyDays = days.filter(day => day.isDirty);

    let canEdit = this.listing.canEdit;
    if (!dirtyDays.length) {
      return false;
    }
    if (!canEdit) {
      return false;
    }

    if (this.isMonthly) {
      for (let day of days) {
        day.set('monthlyPriceUser', day.priceUser);
      }
    }

    let propertiesToSave = this.isMonthly
      ? ['id', 'date', 'monthlyPriceUser']
      : properties;

    try {
      await ListingDate.saveMany(days, propertiesToSave);
    } catch (err) {
      // Handle the case where the client fails
      const errors = Array.isArray(err)
        ? err
        : [{ message: 'Failed to update listing day configuration.' }];
      displayErrors({ errors, modelOrKeywordThis: this, alert: alert });
      return false;
    }

    if (this.isMonthly) {
      for (let day of days) {
        day.markClean();
      }
    }

    return true;
  }

  // All selection logic for the calendar is tied to presentation data.
  // If we want to select a date programatically we need to search for
  // the squares taking this into account.
  findSquareByDate(date) {
    if (!date) return null;
    const monthTitle = date.locale('en').format('MMMM');
    const monthYear = date.format('YYYY');
    const month = this.months.find(m => m.title === monthTitle && m.year === monthYear);

    if (!month) return null;
    window.TEMP1 = month.days;

    return month.days.find(d => d && d.day.date.isSame(date, 'day'));
  }

  // We allow deselecting of individual weekdays, but we still want to show the
  // bounds of the selection. E.g. if you select sat-sat and then deselect sat.
  @computed('editSquareFirst', 'editSquareLast')
  get startToEndDays() {
    let startDate = this.get('editSquareFirst.day.date');
    if (!startDate) {
      return [];
    }
    let endDate = this.get('editSquareLast.day.date');
    if (!endDate) {
      return [];
    }

    let calendarAll = this.get('listing.calendar') || [];
    return calendarAll.filter(item => {
      if (!item) {
        return false;
      }
      if (item.date < startDate) {
        return false;
      }
      if (item.date > endDate) {
        return false;
      }
      return true;
    });
  }

  async clearEditSquare() {
    this.resetChanges();

    // Reset per-day checkboxes
    this.set('selectionWeekdayFilters', [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]);

    $(window).off('mousedown.selectSquareStart');

    this.set('selectSquareStart', null);
    this.set('selectSquareEnd', null);
    return false;
  }

  _popperModifiers = {
    offset: {
      offset: '0,20px',
    },
  };

  handlerInstalled = false;

  @action
  toggleCalendarEditExpanded() {
    this.set('calendarEditExpanded', !this.calendarEditExpanded);
  }

  @action
  onDateRangeSelected(range) {
    this.resetChanges();
    const squareStart = this.findSquareByDate(range?.start);
    const squareEnd = this.findSquareByDate(range?.end);
    this.set('selectSquareStart', squareStart);
    this.set('selectSquareEnd', squareEnd);
  }

  @action
  onSettingsChanged(selectedDays, formState) {
    this.applyChangesForDays(selectedDays, formState);
  }

  @action
  onWeekdayFiltersChanged(key, value, formState) {
    if (value) {
      this.selectionWeekdayFilters.addObject(key);
      this.applyChangesForWeekday(key, formState);
    } else {
      this.resetChangesForWeekday(key);
      this.selectionWeekdayFilters.removeObject(key);
    }
  }

  @action
  async onCancelOverride(selectedDays) {
    const days = selectedDays.filter(day => day.priceUser !== null);
    days.forEach(d => d.set('priceUser', null));

    return this.saveDays(days, ['date', 'priceUser']);
  }

  @action
  async onCancelMinStaysOverride(selectedDays) {
    const days = selectedDays.filter(day => day.minStayUser !== null);
    days.forEach(d => d.set('minStayUser', null));

    return this.saveDays(days, ['date', 'minStayUser']);
  }

  @action
  async onSaveSelectedDays(selectedDays) {
    return this.saveDays(selectedDays);
  }

  @action
  touchHandlerStart() {
    this.set('scrollVectorStart', window.scrollY);
  }

  @action
  touchHandlerEnd(square) {
    let scrollVectorStart = this.scrollVectorStart;
    let scrollVectorEnd = window.scrollY;
    let scrollVectorSize = Math.abs(scrollVectorStart - scrollVectorEnd);

    let scrollVectorTrigger = this.element.querySelector('.day').clientHeight;

    if (scrollVectorSize < scrollVectorTrigger) {
      this.set('selectSquareStart', square);
      this.set('selectSquareEnd', square);
    }

    let checkForTouches = e => {
      let a = e.target;

      if (
        !(
          a.closest('.day') ||
          a.closest('.calendar-edit') ||
          a.closest('#ember-basic-dropdown-wormhole') ||
          a.closest('#ember-legacy-dropdown-wormhole')
        )
      ) {
        this.clearEditSquare();
        document.removeEventListener('touchstart', checkForTouches);
        this.set('handlerInstalled', false);
      }
    };
    if (!this.handlerInstalled) {
      this.set('handlerInstalled', true);
      document.addEventListener('touchstart', checkForTouches);
    }
  }

  // THIS IS FIRED ON MOUSE ENTER
  // Mouse Enter Fires as we drag across the calendar, so what we need to happen here is:
  // 1. Set as the end day so we can compute the style changes
  // 2. Compute/Apply the style changes across the calendar to give the effect of dragging
  // 2a. For step one David suggested simply highlighting the FIRST and LAST day then apply fancy polish later.
  // 3. If the day we're hovering on is a blocked day - we need to select this day but not highlight it
  // 3a. This may just be possible to do in the styling (and update logic) completely and not need to do anything here
  @action
  setHoverDay(square) {
    if (this.dragging) {
      this.set('selectSquareEnd', square);
    }
    square.set('hoverTarget', true);
    this.set('hoverDay', square);
    next(() => {
      this.set(
        '_popperTarget',
        document.querySelector('.hover-target') || document.body
      );
    });
    return false;
  }

  @action
  clearHoverDay(square) {
    square.set('hoverTarget', false);
    // Only allow a day to clear itself
    if (this.hoverDay === square) {
      this.set('hoverDay', null);
    }
    return false;
  }

  @action
  async setEditSquare(square) {
    await this.clearEditSquare();

    $(window).off('mousedown.selectSquareStart');
    $(window).on('mousedown.selectSquareStart', event => {
      // When the user select a start square, and then click on the browser's scroll bar, `target`
      // is `document` and it doesn't have `closest` function. This make sure we won't error
      // out on this edge case.
      if (event.toElement === document) {
        return false;
      }
      if (event.target.closest('.calendar-edit')) {
        return true;
      }
      if (event.target.closest('.day')) {
        return true;
      }
      // Do not clear the selected days if we're inside a dropdown
      if (
        event.target.closest('#ember-basic-dropdown-wormhole') ||
        event.target.closest('#ember-legacy-dropdown-wormhole')
      ) {
        return true;
      }

      run(() => {
        this.clearEditSquare();
      });
    });

    this.set('selectSquareStart', square);
    this.set('selectSquareEnd', square);
    return false;
  }

  @action
  scrollToMonth(index) {
    const offset =
      $(this.element.querySelector(`[data-scroll-id="${index}"]`)).offset().top - 150;
    const scrollTop = $('.dashboard-content').scrollTop();
    $('.dashboard-content').animate({ scrollTop: offset + scrollTop }, 300);
  }

  @action
  monthEnter(month) {
    month.set('visible', true);
  }

  @action
  monthExit(month) {
    month.set('visible', false);
  }

  dragging = false;

  mouseDown() {
    // NOTE: dragging disabled for v1.
    // @set 'dragging', true
    // TODO: This assumes that the ordering stays constant - that actions are
    // fired before the click handlers. Check that assumption is correct.
    this.set('dragging', true);
    return true;
  }

  mouseUp() {
    this.set('dragging', false);
    return true;
  }
}

function getDailyCalendarPrice(days, date) {
  let price = null;

  let dailyPricingDay = days.find(d => d.date.isSame(date));

  if (dailyPricingDay) {
    price = dailyPricingDay.priceUserOrModeled;
  }

  return price;
}

function isInsideMonthlySeason(date, seasons) {
  if (seasons.length === 0) {
    return true;
  }

  for (let season of seasons) {
    if (date >= season.startDate && date <= season.endDate) {
      return true;
    }
  }

  return false;
}
