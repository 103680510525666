import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';
import { MergedListingsSettingsEditor } from 'appkit/lib/relay/editors/rel-merge-listings-editor';
import { getAddToExistingMergeSuggestions } from 'appkit/lib/relay/interactors/state/rel-merge-suggestions-interactor';
import { RelayMergedListingViewModelAdapter } from 'appkit/lib/relay';

export default class RelayListingSettingsRoute extends AuthRoute {
  @service('global-nav') globalNav;

  title() {
    const title = `Relay / ${this.context.listing.name} / Settings`;
    this.globalNav.setTitle(title);
    return title;
  }

  model() {
    const { listing, userPermissions } = this.modelFor(
      'dashboard.relay.merged-listing-detail'
    );
    const model = {
      listing,
      userPermissions,
    };

    return model;
  }

  /** After the model, we apply some DIP to set dependecies (editor) */
  setupController(controller, model) {
    super.setupController(...arguments);
    // Set Editor
    const editor = new MergedListingsSettingsEditor({
      initialSettings: model.listing, //clean this up to have only needed properties
    });
    controller.set('settingsEditor', editor);

    // Set Add to Existing Merge Suggestions
    const _listing = this.bpStore.peekRecord('listing', model.listing.id);
    const _listings = this.bpStore.all('listing');
    const mergeSuggestions = getAddToExistingMergeSuggestions(_listing, _listings).map(
      RelayMergedListingViewModelAdapter
    );

    // Mock if has no suggestions to show it in the POC // REMOVE THIS AFTER!
    if (!mergeSuggestions.length) {
      mergeSuggestions.push(mockSuggestion(_listing));
    }

    controller.set('mergeSuggestions', mergeSuggestions);
  }
}

function mockSuggestion(listing) {
  return {
    ...RelayMergedListingViewModelAdapter(listing),
    name: 'MOCK Suggestion',
  };
}
