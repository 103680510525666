import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield (hash chartOptions=this.chartOptions chartData=this.chartData)}}", {"contents":"{{yield (hash chartOptions=this.chartOptions chartData=this.chartData)}}","moduleName":"appkit/components/insights/charts/bookings/container.hbs","parseOptions":{"srcName":"appkit/components/insights/charts/bookings/container.hbs"}});
import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { chunk, chartOptions, round } from 'appkit/lib/insights/charts';
import { inject as service } from '@ember/service';

export default class InsightsChartsVolumeContainer extends Component {
  @service intl;

  get type() {
    return this.args.type ?? 'total';
  }

  get chartOptions() {
    switch (this.type) {
      case 'stay_date':
        return this.bookingsByStayDateOptions;
      case 'booked_date':
        return this.bookingsByBookedDateOptions;
      default:
        return this.bookingsByBookingDatePerListingOptions;
    }
  }

  get bookingsByBookingDatePerListingOptions() {
    return chartOptions({
      yTitle: this.intl.t('insights.stats.numberOfRes'),
      locale: this.intl.locale,
      tooltipOptionsFn: () => ({
        timeframe: this.args.timeframe,
        displayBy: this.args.displayBy,
        showDecimals: true,
      }),
    });
  }

  get bookingsByBookedDateOptions() {
    return chartOptions({
      locale: this.intl.locale,
      yTitle: this.intl.t('insights.stats.bookingCount'),
      tooltipOptionsFn: () => ({
        timeframe: this.args.timeframe,
        displayBy: this.args.displayBy,
      }),
    });
  }

  get bookingsByStayDateOptions() {
    return chartOptions({
      locale: this.intl.locale,
      yTitle: this.intl.t('insights.stats.bookingCount'),
      tooltipOptionsFn: () => ({
        timeframe: this.args.timeframe,
        displayBy: this.args.displayBy,
      }),
    });
  }

  get chartData() {
    switch (this.type) {
      case 'stay_date':
        return this.bookingsByCheckinDateData;
      case 'booked_date':
        return this.bookingsByBookingDateData;
      default:
        return this.bookingsByBookingDatePerListingData;
    }
  }

  @computed(
    'args.{timeframe,timeranges,useTimeRanges,displayBy,statsByTimeframe,compareStatsByTimeframe}'
  )
  get bookingsByBookingDatePerListingData() {
    const {
      timeframe,
      timeranges,
      displayBy,
      timeframeCache,
      isChartVisible,
      onChartVisibilityChange,
      statsByTimeframe,
    } = this.args;

    return chunk(
      {
        chartName: this.intl.t('insights.charts.bookingsByBookingDatePerListing'),
        timeframe: timeframe,
        timeranges: timeranges,
        displayBy: displayBy,
        series: statsByTimeframe,
        future: false,
        mapFunction: row => {
          if (!row.listingCount) return 0;
          return round(row.bookedAtCount / row.listingCount);
        },
        timeframeCache: timeframeCache,
        isChartVisible: isChartVisible,
        onChartVisibilityChange: onChartVisibilityChange,
        marketGroupName: this.intl.t('insights.marketGroupName'),
      },
      {},
      this.args.useTimeRanges
    );
  }

  @computed(
    'args.{timeframe,timeranges,useTimeRanges,displayBy,statsByTimeframe,compareStatsByTimeframe}'
  )
  get bookingsByBookingDateData() {
    const {
      timeframe,
      timeranges,
      displayBy,
      timeframeCache,
      isChartVisible,
      onChartVisibilityChange,
      statsByTimeframe,
    } = this.args;

    return chunk(
      {
        chartName: this.intl.t('pricing.listing.bookingsByBookingDate'),
        timeframe: timeframe,
        timeranges: timeranges,
        displayBy: displayBy,
        series: statsByTimeframe,
        future: false,
        mapFunction: row => row.bookedAtCount,
        timeframeCache: timeframeCache,
        isChartVisible: isChartVisible,
        onChartVisibilityChange: onChartVisibilityChange,
        marketGroupName: this.intl.t('insights.marketGroupName'),
      },
      {},
      this.args.useTimeRanges
    );
  }

  @computed(
    'args.{timeframe,timeranges,useTimeRanges,displayBy,statsByTimeframe,compareStatsByTimeframe}'
  )
  get bookingsByCheckinDateData() {
    const {
      timeframe,
      timeranges,
      displayBy,
      timeframeCache,
      isChartVisible,
      onChartVisibilityChange,
      statsByTimeframe,
    } = this.args;

    return chunk(
      {
        chartName: this.intl.t('pricing.listing.bookingsByCheckInDate'),
        timeframe: timeframe,
        timeranges: timeranges,
        displayBy: displayBy,
        series: statsByTimeframe,
        future: true,
        mapFunction: row => row.checkinDateCount,
        timeframeCache: timeframeCache,
        isChartVisible: isChartVisible,
        onChartVisibilityChange: onChartVisibilityChange,
        marketGroupName: this.intl.t('insights.marketGroupName'),
      },
      {},
      this.args.useTimeRanges
    );
  }
}
