import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
/*
 * Keycode based validation is a bad idea because it will break on foreign
 * keyboards, specifically AZERTY keyboards need to hold shift to get to the
 * numbers.
 *
 * Number inputs restrict the input to numbers, but it's impossible to deal
 * with the scroll events in a sane manner - it catches the scroll and then
 * starts adjusting the number. Also the step buttons are shadow elements and
 * so hard to style across browsers. Tel inputs give a nice display on mobile,
 * but do no validation.
 *
 * Before observers are being phased out of ember, so before I would have just
 * prevented any change to the value directly. Now when I try and do that in a
 * regular observer, there has to be hacks to stop the value observer getting
 * called infinitely. Also we're setting a value, so we have to deal with caret
 * problems anyway.
 *
 * So we're left with dealing with events on the textbox directly. keyDown
 * doesn't allow a consistent method of getting the actual character, and also
 * allows paste. Keyup means things like paste will go through. Input is a nice
 * event as it allows for value validation and is called before the actual
 * insert, but it's not cancelable. So we're back to storing the caret
 * location.
 */
import { scheduleOnce } from '@ember/runloop';
import Component from '@ember/component';
@classic
@tagName('div')
@classNames('app-input-regex', 'element')
export default class AppInputRegex extends Component {
  // PUBLIC
  autofocus = undefined;

  // Directly tied to the value inside the input box. Always a string.
  _rawValue = '';

  focusOut = () => {};

  // The number cast version for reading out / doing math on. Public.
  @computed('_rawValue')
  get value() {
    return Number(this._rawValue);
  }

  set value(value) {
    // Because the value is bound, the setter will get called once on the change
    // from the input box, and again when the external bound value changes. This
    // causes a cursor jump even when the value stays the same.
    this.set('_rawValue', value);
    return this.value;
  }

  didInsertElement() {
    super.didInsertElement(...arguments);

    // HTML5's native autofocus doesn't seem to select all the text. Not sure
    // if this is due to ember bindings changing the value or whether that's
    // intended behavior.
    if (!this.autofocus) {
      return;
    }

    scheduleOnce('afterRender', this, this.focusInput);
  }

  focusInput() {
    const selectEvent = document.createEvent('HTMLEvents');
    selectEvent.initEvent('select', true, false);
    this.element.querySelector('input').dispatchEvent(selectEvent);
    this.element.querySelector('input').focus();
  }

  carat = 0;

  @action
  onFocusOut(event) {
    this.focusOut(event);
    return true;
  }

  @action
  onInput(event) {
    let target = event.target;
    let willChange = !/^[1-9+]\d*$/.test(target.value);
    // Don't mess with the value unless we have to.
    if (willChange) {
      target.value = target.value.replace(/[^1-9+]/g, '');
      target.selectionStart = this.carat;
      target.selectionEnd = this.carat;
    }
    this.set('_rawValue', target.value);
    return false;
  }

  @action
  onKeyPress(event) {
    this.set('carat', event.currentTarget.selectionStart);
    return true;
  }

  @action
  onKeyDown(event) {
    let key = event.which || 0;

    // Blur on enter
    if (key === 13) {
      this.element.querySelector('input').blur();
    }
    return true;
  }
}
