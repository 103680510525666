import config from 'appkit/config/environment';

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export default {
  name: 'react-redirect',
  before: [
    'override-session',
    'ajax',
    'initialize-models',
    'tracking-params',
    'component-router',
    'moment',
  ],

  initialize() {
    // Always redirect to the beyond react app if Ember is not running in embedded mode
    if (!inIframe() && config.environment !== 'test') {
      const pathname = top.document.location.pathname;
      const query = top.document.location.search;
      const hash = top.document.location.hash;

      if (
        pathname.startsWith('/internal-tools') ||
        pathname.startsWith('/data') ||
        pathname.startsWith('/market-map-locator') ||
        pathname.startsWith('/preview') ||
        pathname.startsWith('/demo')
      ) {
        return;
      }

      const url = `${config.APP.BEYOND_REACT_APP_URL}${pathname}${query}${hash}`;
      top.window.location.replace(url);
    }
  },
};
