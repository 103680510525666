import { helper } from '@ember/component/helper';

export default helper(function (params) {
  const originalValue = params[0];
  const targetValue = params[1];

  if (!originalValue || !targetValue) {
    return '';
  }

  const rounded = Math.round((targetValue / originalValue) * 100);
  if (rounded > 100) {
    return 'increased';
  } else if (rounded < 100) {
    return 'decreased';
  }

  return Math.abs(rounded);
});
