import { VARIANTS, COLORS } from '../../../theme';

const variantClasses = () => ({
  [VARIANTS.OUTLINED]: {
    [COLORS.ALL]: 'text-gray-800',
  },
  [VARIANTS.CONTAINED]: {
    [COLORS.ALL]: 'text-white',
  },
});

export default {
  root: {
    defaultClasses: () => ['w-full'],
    variantClasses,
  },
};
