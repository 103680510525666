import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';

export default class OnboardingRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service() featureFlag;

  title(tokens) {
    let title = null;

    if (!tokens || !tokens.length) {
      title = "Let's get started!";
    } else {
      title = `Let's get started! / ${tokens}`;
    }

    this.globalNav.setTitle(title);
    return title;
  }

  async beforeModel() {
    const isSelfServedOnboardingEnabled = await this.featureFlag.evaluate(
      'self-served-onboarding',
      false
    );

    if (!isSelfServedOnboardingEnabled) {
      return this.transitionTo('dashboard.pricing.index');
    }
  }
}
