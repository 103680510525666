import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import Credentials from 'appkit/bp-models/credentials';
import { displayErrors } from 'appkit/lib/display_errors';
import ENV from 'appkit/config/environment';

@classic
export default class LoginRoute extends Route {
  titleToken = 'Login';
  url = '/api/login';

  @service featureFlag;
  @service userleap;
  @service googleTagManager;
  @service hog;

  beforeModel() {
    // Private mode on mobile safari causes localStorage to break, and it's hard
    // to tell that you're in private mode.
    try {
      localStorage.setItem('beyond-localstorage', 'success');
    } catch (err) {
      return this.transitionTo('private-mode');
    }
    localStorage.removeItem('beyond-localstorage');

    if (this.currentUser.get('token')) {
      this.transitionTo('dashboard');
    }
    return super.beforeModel.apply(this, arguments);
  }

  model() {
    return Credentials.create({
      referralCode: window.hashParams && window.hashParams.r,
    });
  }

  @action
  async onSubmit(model) {
    this.controller.set('posting', true);
    let props = model.getProperties(
      'email',
      'password',
      'referralCode',
      'skipTrial',
      'isRelayEnabled',
      'activateProducts'
    );

    const cookies = document.cookie
      .split(';')
      .filter(cookie => cookie)
      .reduce((cookies, row) => {
        const [key, value] = row.trim().split('=');
        cookies[key] = value;
        return cookies;
      }, {});

    if (cookies.utm) {
      try {
        props.trackingParams = JSON.parse(decodeURIComponent(cookies.utm));
      } catch {
        console.log('Error parsing cookie');
      }
    }

    let responseData;
    try {
      responseData = await this.ajax._post(this.url, props);
    } catch (errors) {
      displayErrors({ errors: errors, modelOrKeywordThis: model, isProperty: true });
      return;
    } finally {
      // Ensure this is reset on future visits to login (e.g. if they're
      // kicked out of their session due to a 401)
      this.controller.set('posting', false);
    }

    localStorage.setItem('isStaff', responseData.isStaff);

    // Add hubspot token so we can treat signup as a hubspot form.
    // Have to check for _hsq in case user has blocked via adblock.
    if (ENV.environment === 'production' && window._hsq) {
      // Hubspot
      window._hsq.push([
        'identify',
        {
          email: props.email,
        },
      ]);
      props.hubspotToken = cookies.hubspotutk;
    }
    this.hog.aliasUser(props, responseData);

    if (ENV.environment === 'production') {
      // Clear UTM tracking (set in tracking-params.js)
      // https://github.com/eslint/eslint/issues/11899
      // eslint-disable-next-line require-atomic-updates
      document.cookie =
        'utm=; domain=.beyondpricing.com; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=lax';
    }
    this.currentUser.setProperties({
      username: responseData.email,
      token: responseData.token,
      isStaff: responseData.isStaff,
      isSuper: responseData.isSuper,
      isRelayEnabled: responseData.isRelayEnabled,
      userId: responseData.id,
      hasDisconnectedAccounts: responseData.hasDisconnectedAccounts,
    });

    // Set up launch darkly after login
    this.featureFlag.initClient({
      email: responseData.ldEmail,
      ldSecureHash: responseData.ldSecureHash,
      userId: this.currentUser.userId,
    });

    // Store LaunchDarkly configs in local storage
    this.currentUser.setProperties({
      ldEmail: this.featureFlag.email,
      ldKey: this.featureFlag.ldKey,
      ldSecureHash: this.featureFlag.ldSecureHash,
    });

    this.userleap.initClient({
      email: this.currentUser.ldEmail,
      userId: this.currentUser.userId,
    });

    // track login event via Google Tag Manager service
    this.googleTagManager.login();

    const redirect = this.controller.get('attemptedTransition');
    if (redirect) {
      redirect.retry();
      this.set('attemptedTransition', null);
    } else {
      this.send('refreshCurrentRoute');

      if (!this.currentUser.hasDisconnectedAccounts) {
        this.transitionTo('dashboard');
      } else {
        this.transitionTo('dashboard.user.accounts');
      }
    }
  }
}
