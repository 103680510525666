/* global pendo */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class PendoService extends Service {
  @service currentUser;
  @tracked userId;
  @tracked email;

  initClient(opts) {
    if (window.pendo === undefined) {
      console.warn(
        'Cannot complete Pendo.io initialization: window.pendo is undefined'
      );
      return;
    }

    if (!(opts.userId && opts.email) || this.isPendoInitialized) return;

    pendo.initialize({
      visitor: {
        id: opts.userId,
        email: opts.email,
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
      account: {
        // id:           'ACCOUNT-UNIQUE-ID' // Highly recommended
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });

    this.isPendoInitialized = true;
  }
}
