import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@classNames('app-empty-state')
export default class AppEmptyState extends Component {
  // -- Parameters ---------------------------------------------------------------------
  @service('list-control')
  listControl;

  title = null; // @type {String}
  emptyStateType = null; // @type {String}
  model = null; // @type {Object}
  hideUnavailable = null; // @type {Boolean}
  invalidAccounts = null; // @type {Array}
  search = null; // @type {String}

  // -- Computed Properties ------------------------------------------------------------
  skippedAddresses = null; // @type {Object}

  skippedTitles = null; // @type {Object}

  // reset
  @action
  resetAllFilters() {
    this.listControl.resetAllFilters();
  }

  @action
  onRestoreSkippedSuggestedListings() {
    this.restoreSkippedSuggestedListings();
  }
}
