import Service from '@ember/service';
import posthog from 'posthog-js';
import environment from 'appkit/config/environment';

// const isAnonymousId = (uuid = '') => {
//   return uuid && uuid.length === 62 && uuid.split('-').length === 5;
// };

export default class HogService extends Service {
  initialized = false;
  identified = false;

  initialize() {
    // FIXME: disable posthog for now until we figure out the account merging issue
    // eslint-disable-next-line no-constant-condition
    if (false && environment.APP.POSTHOG_API_KEY) {
      posthog.init(environment.APP.POSTHOG_API_KEY, {
        api_host: 'https://app.posthog.com',
        disable_session_recording: true,
      });

      posthog.onFeatureFlags(function () {
        if (posthog.isFeatureEnabled('session-recordings')) {
          posthog.startSessionRecording();
        }
      });

      this.initialized = true;
    }
  }

  canSendEvent() {
    if (!this.initialized) console.warn('posthog not configured');
    return this.initialized;
  }
  capture(event, options = null) {
    if (!this.canSendEvent()) return;
    options = options ?? {};
    posthog.capture(event, options);
  }
  pageView(options = null) {
    this.capture('$pageview', options);
  }
  logout() {
    if (!this.canSendEvent()) return;
    posthog.reset();
    this.identified = false;
  }
  // investigate this logic for setting the alias - app/routes/dashboard.js
  activate(email) {
    if (!this.canSendEvent()) return;

    // const originalId = posthog.get_distinct_id?.();
    if (this.identified) {
      this.logout();
    }

    posthog.identify(email);
    posthog.people.set({ email: email });
    this.identified = true;
    // This should be done for us by the identify call above, but it's not working.
    // if (!this.currentUser.isImpersonating && isAnonymousId(originalId)) {
    //   posthog.alias(originalId, email);
    // }
  }
  aliasUser(props, responseData) {
    if (!this.canSendEvent()) return;
    // const originalId = posthog.get_distinct_id?.();

    if (this.identified) {
      this.logout();
    }

    posthog.identify(props.email, {
      email: props.email,
      staff: responseData.isStaff,
      userId: responseData.id,
    });
    this.identified = true;
    // This should be done for us by the posthog.identify call above, but it's not working.
    // if (!this.currentUser.isImpersonating && isAnonymousId(originalId)) {
    //   posthog.alias(originalId, props.email);
    // }
  }
}
