import ApplicationAdapter from './application';
import { decamelize } from '@ember/string';

export default class UserAdapter extends ApplicationAdapter {
  pathForType(modelName) {
    return decamelize(modelName);
  }

  // Implement queryRecord since the User model is a singleton
  // Reference: https://guides.emberjs.com/release/models/finding-records/#toc_querying-for-a-single-record
  queryRecord(store, type, _query) {
    return this.findAll(store, type);
  }
}
