import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield (hash dom=(component 'bp/high-charts/dom') chart=this.chart)}}", {"contents":"{{yield (hash dom=(component 'bp/high-charts/dom') chart=this.chart)}}","moduleName":"appkit/components/bp/high-charts/index.hbs","parseOptions":{"srcName":"appkit/components/bp/high-charts/index.hbs"}});
import EmberHighChartsComponent from 'ember-highcharts/components/high-charts';
import { observes } from '@ember-decorators/object';
import { next } from '@ember/runloop';

export default class BpHighChartsComponent extends EmberHighChartsComponent {
  // eslint-disable-next-line ember/no-observers
  @observes('chartOptions')
  contentDidChange() {
    next(() => {
      this.chart?.update(this.chartOptions);
    });
  }
}
