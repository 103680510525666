import { roundToNearest } from 'appkit/pricing/utils';

const FactorsModel = (
  {
    basePrice,
    listingMinPrice,
    listingMaxPrice,
    minPriceUser,
    dowMinPrice,
    maxPriceUser,
    factors,
    roundToNearestNumber,
  },
  disabledFactors = {}
) => {
  let sortedFactors = factors.sort((a, b) => a.order - b.order);
  let out = [];
  let order = 0;

  let price = basePrice;
  let runningBase = price;

  for (let factor of sortedFactors) {
    if (disabledFactors[factor.key]) {
      continue;
    }
    if (factor.applyAfterMinprice === true) {
      continue;
    }
    if (factor.order !== order) {
      order = factor.order;
      runningBase = price;
    }

    let delta = Math.round(factor.ratio * runningBase);

    // We don't show cents. Only show factors that change price by $1 or
    // more.
    if (Math.abs(delta) < 1) {
      continue;
    }

    price += delta;

    out.push({
      key: factor.key,
      amount: delta,
      ratio: factor.ratio,
      reason: factor.reason,
    });
  }

  // Day min price always win over listing's min price if it is defined
  // Note: make sure to not fall back to listing's value if the seasonal
  // value does not meet the criteria when compared to the price
  let dayMinPrice = minPriceUser;
  if (!disabledFactors.seasonalMins && dayMinPrice) {
    if (price < dayMinPrice) {
      out.push({
        key: 'seasonal_min_price',
        amount: dayMinPrice - price,
      });
      price = dayMinPrice;
    }
  } else if (dowMinPrice && price < dowMinPrice) {
    out.push({
      key: 'dow_min_price',
      amount: dowMinPrice - price,
    });
    price = dowMinPrice;
  } else if (listingMinPrice) {
    if (price < listingMinPrice) {
      out.push({
        key: 'min_price',
        amount: listingMinPrice - price,
      });
      price = listingMinPrice;
    }
  }

  // Apply the "applyAfterMinprice" factors
  order = 0;
  for (let factor of sortedFactors) {
    if (disabledFactors[factor.key]) {
      continue;
    }
    if (factor.applyAfterMinprice === true) {
      if (factor.order !== order) {
        order = factor.order;
        runningBase = price;
      }

      let delta = Math.round(factor.ratio * runningBase);

      // We don't show cents. Only show factors that change price by $1 or
      // more.
      if (Math.abs(delta) < 1) {
        continue;
      }

      price += delta;

      out.push({
        key: factor.key,
        amount: delta,
        ratio: factor.ratio,
        reason: factor.reason,
      });
    }
  }

  // Day max price always win over listing's max price if it is defined
  // Note: make sure to not fall back to listing's value if the seasonal
  // value does not meet the criteria when compared to the price
  let dayMaxPrice = maxPriceUser;
  if (!disabledFactors.seasonalMaxes && dayMaxPrice) {
    if (price > dayMaxPrice) {
      out.push({
        key: 'seasonal_max_price',
        amount: dayMaxPrice - price,
      });
      price = dayMaxPrice;
    }
  } else if (listingMaxPrice) {
    if (price > listingMaxPrice) {
      out.push({
        key: 'max_price',
        amount: listingMaxPrice - price,
      });
      price = listingMaxPrice;
    }
  }

  // Round to nearest number if defined (again)
  if (roundToNearestNumber) {
    let roundedPrice = roundToNearest(price, roundToNearestNumber);
    if (roundedPrice !== price) {
      out.push({
        key: 'rounding',
        amount: roundedPrice - price,
      });
      price = roundedPrice;
    }
  }

  return [out, price];
};

export default FactorsModel;
