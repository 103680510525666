import { htmlSafe } from '@ember/string';
import { helper } from '@ember/component/helper';
import Util from 'appkit/lib/util';

export default helper(function (params) {
  let value = params[0];

  // Still can't do a block helper without a view.
  let url = params.href;
  return new htmlSafe(`<a href="${Util.noreferrerHref(url)}">${value}</a>`);
});
