import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { or } from '@ember/object/computed';
import Component from '@ember/component';
import ListingDate from 'appkit/bp-models/listing_date';

@classic
@classNames('app-listing-pricing-input')
export default class AppListingPricingInput extends Component {
  @service alert;
  @service listingSetup;

  isBasePriceCarouselVisible = false;

  @or('model.listing.{isPreview,isDemo}')
  isPreviewOrDemo;

  get isNewListing() {
    return this.model.listing.isNewListing;
  }

  get minPriceHistory() {
    if (this.get('monthlyPricing')) {
      return this.model.listing.monthlyMinPriceHistorySorted;
    } else {
      return this.model.listing.minPriceHistorySorted;
    }
  }

  @action
  setBasePrice(value) {
    this.set('model.listing.basePrice', value);
    this.get('model.listing').validateInput();
  }

  @action
  setMinPrice(value) {
    this.set('model.listing.minPrice', value);
    this.get('model.listing').validateInput();
  }

  @action
  setMonthlyMinPrice(value) {
    this.set('model.listing.monthlyMinPrice', value);
    this.get('model.listing').validateInput();
  }

  @action
  resetChanges(model) {
    model.listing.resetChanges();
  }

  @action
  toggleBasePriceCarousel() {
    if (this.isBasePriceCarouselVisible) {
      let elToFadeOut = document.querySelector('.app-carousel.base-price-info');

      elToFadeOut.classList.add('fade-out');
      setTimeout(() => {
        this.toggleProperty('isBasePriceCarouselVisible');
      }, 500);
    } else {
      this.toggleProperty('isBasePriceCarouselVisible');
      return false;
    }
  }

  @action
  async saveListing(model) {
    let listing = model.get('listing');

    // When copying overrides, we don't have a good way to kick off a save,
    // because the days were never selected. Instead, just save any dirty days
    // right before saving the listing.
    let dirtyDays = listing.get('calendar').filter(day => day.get('isDirty'));
    if (dirtyDays.length) {
      await ListingDate.saveMany(dirtyDays);
    }

    listing.set('enabled', true);
    let result = await listing.saveWithValidation();
    if (!result) {
      listing.set('enabled', false);
      return;
    }

    this.alert.success(
      `Saved. Updating prices on your ${listing.get('allChannelLabels')} calendar now.`
    );
  }

  @action
  async reviewListing(model) {
    const listing = model.get('listing');
    this.listingSetup.setBaseListing(listing);
    this.listingSetup.onOpenModal();
  }

  @action
  async saveBaseMinPrice(model) {
    let listing = model.get('listing');
    let dirtyDays = listing.get('calendar').filter(day => day.get('isDirty'));
    if (dirtyDays.length) {
      await ListingDate.saveMany(dirtyDays);
    }

    let result = await listing.saveWithValidation();

    if (!result) {
      return;
    }

    this.alert.success('Base price, minimum price and overrides are saved.');
  }
}
