// YUCK: This file is stupid. Should be able to do it as part of the resolver.
import BaseModel from 'appkit/bp-models/base_model';
// import DebugAdapter from 'appkit/lib/debug_adapter';
import Account from 'appkit/bp-models/account';
import User from 'appkit/bp-models/user';
import Cluster from 'appkit/bp-models/cluster';
import Market from 'appkit/bp-models/market';
import Statement from 'appkit/bp-models/statement';
import BillingPeriod from 'appkit/bp-models/billing_period';
import Listing from 'appkit/bp-models/listing';
import ChannelListing from 'appkit/bp-models/channel_listing';
import ListingDate from 'appkit/bp-models/listing_date';
import ListingQuote from 'appkit/bp-models/listing_quote';
import Factor from 'appkit/bp-models/factor';
import Customize from 'appkit/bp-models/customize';
import Credential from 'appkit/bp-models/credential';
import Permission from 'appkit/bp-models/permission';
import Reservation from 'appkit/bp-models/reservation';
import Notification from 'appkit/bp-models/notification';
import NotificationRule from 'appkit/bp-models/notification_rule';
import AccountMarket from 'appkit/bp-models/account_market';
import savedFilter from 'appkit/bp-models/saved_filter';
import bookingReview from 'appkit/bp-models/booking_review';
import bookingReviewListing from 'appkit/bp-models/booking_review_listing';
import Alert from 'appkit/bp-models/alert';
import Projection from 'appkit/bp-models/projection';
import ProjectionTemplate from 'appkit/bp-models/projection_template';

export default {
  name: 'initialize-models',

  initialize(application) {
    application.register('bp-model:user', User, { instantiate: false });
    application.register('bp-model:listing', Listing, { instantiate: false });
    application.register('bp-model:account', Account, { instantiate: false });
    application.register('bp-model:account_market', AccountMarket, {
      instantiate: false,
    });
    application.register('bp-model:channelListing', ChannelListing, {
      instantiate: false,
    });
    //TODO: rename to channelListingDate
    application.register('bp-model:listingDate', ListingDate, { instantiate: false });
    application.register('bp-model:listingQuote', ListingQuote, { instantiate: false });
    application.register('bp-model:statement', Statement, { instantiate: false });
    application.register('bp-model:billing_period', BillingPeriod, {
      instantiate: false,
    });
    application.register('bp-model:cluster', Cluster, { instantiate: false });
    application.register('bp-model:market', Market, { instantiate: false });
    application.register('bp-model:factor', Factor, { instantiate: false });
    application.register('bp-model:customize', Customize, { instantiate: false });
    application.register('bp-model:credential', Credential, { instantiate: false });
    application.register('bp-model:permission', Permission, { instantiate: false });
    application.register('bp-model:reservation', Reservation, { instantiate: false });
    application.register('bp-model:notification', Notification, { instantiate: false });
    application.register('bp-model:notification_rule', NotificationRule, {
      instantiate: false,
    });
    application.register('bp-model:alert', Alert, { instantiate: false });
    application.register('bp-model:saved_filter', savedFilter, { instantiate: false });
    application.register('bp-model:booking_review', bookingReview, {
      instantiate: false,
    });
    application.register('bp-model:booking_review_listing', bookingReviewListing, {
      instantiate: false,
    });
    application.register('bp-model:projection', Projection, {
      instantiate: false,
    });
    application.register('bp-model:projection_template', ProjectionTemplate, {
      instantiate: false,
    });
    // Make sure we have loaded type keys before initializing the data adapter,
    // otherwise data inspector can't see real names.
    BaseModel.addTypeKeys();
    // application.register('data-adapter:main', DebugAdapter);
  },
};
