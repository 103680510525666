import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @select.selected}}\n  <div class={{this.classes}}>\n    {{t 'insights.lens.BDR'}}:\n  </div>\n{{/if}}", {"contents":"{{#if @select.selected}}\n  <div class={{this.classes}}>\n    {{t 'insights.lens.BDR'}}:\n  </div>\n{{/if}}","moduleName":"appkit/components/insights/select/bedrooms/parts/before-selected-item/index.hbs","parseOptions":{"srcName":"appkit/components/insights/select/bedrooms/parts/before-selected-item/index.hbs"}});
import Component from '@glimmer/component';
import { Themeable, VARIANTS } from '../../../../../bp/theme';
import BeforeSelectedItemTheme from './index.theme';

export default class SelectBedroomsTriggerComponent extends Themeable(Component, {
  theme: BeforeSelectedItemTheme,
  variant: VARIANTS.TEXT,
}) {}
