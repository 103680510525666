import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class IndexRoute extends AuthRoute {
  setupController(controller, model) {
    controller.set('model', model);
    let channel = controller.channel;
    let channelList = controller.supportedChannels;

    if (channelList.includes(channel)) {
      this.transitionTo('dashboard.connect-prompt.channel', channel);
    } else {
      this.transitionTo('dashboard.connect-prompt.index');
    }
  }
}
