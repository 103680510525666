import Service, { inject as service } from '@ember/service';

export default class TableViewService extends Service {
  @service intl;
  @service currentUser;

  preferences = [
    {
      key: 1,
      identifier: 'enabled',
      label: this.intl.t('pricing.pricePosting'),
      sortField: null,
      visibility: false,
      noSort: true,
      classNames: 'block w-24',
      default: true,
      standard: true,
    },
    {
      key: 2,
      identifier: 'market',
      label: this.intl.t('glossary.market'),
      sortField: 'market',
      visibility: false,
      standard: true,
    },
    {
      key: 21,
      identifier: 'cluster',
      label: this.intl.t('glossary.neighbourhood'),
      sortField: 'cluster',
      visibility: false,
    },
    {
      key: 3,
      identifier: 'bedrooms',
      label: this.intl.t('glossary.bedrooms'),
      sortField: 'bedrooms',
      visibility: false,
      standard: true,
    },
    {
      key: 4,
      identifier: 'bathrooms',
      label: this.intl.t('glossary.baths'),
      sortField: 'bathrooms',
      visibility: false,
      standard: true,
    },
    {
      key: 5,
      identifier: 'health-score',
      label: this.intl.t('glossary.health'),
      sortField: 'healthScore',
      visibility: false,
      standard: true,
    },
    {
      key: 6,
      identifier: 'one-week-reservations-count',
      label: this.intl.t('glossary.pastBookings', { days: 7 }),
      sortField: 'statistics.oneWeekReservationsCount',
      visibility: false,
    },
    {
      key: 7,
      identifier: 'two-week-reservations-count',
      label: this.intl.t('glossary.pastBookings', { days: 14 }),
      sortField: 'statistics.twoWeekReservationsCount',
      visibility: false,
      standard: true,
    },
    {
      key: 20,
      identifier: 'future-bookings-count',
      label: this.intl.t('pricing.listing.futureBookingsCount'),
      sortField: 'statistics.futureBookingsCount',
      visibility: false,
    },
    {
      identifier: 'booking-pace',
      label: this.intl.t('glossary.bookingPace'),
      noSort: true,
      visibility: this.currentUser.isStaff,
      standard: true,
    },
    {
      key: 9,
      identifier: 'last-booking-date',
      label: this.intl.t('glossary.lastBookingDate'),
      sortField: 'statistics.lastBookingDate',
      visibility: false,
    },
    {
      key: 10,
      identifier: 'furthest-checkin-date',
      label: this.intl.t('glossary.furthestCheckinDate'),
      sortField: 'statistics.furthestCheckinDate',
      visibility: false,
    },
    {
      key: 11,
      identifier: 'days-available',
      label: this.intl.t('pricing.listing.availableNextDays', { nbdays: 90 }),
      sortField: 'statistics.daysAvailable',
      visibility: false,
    },
    {
      key: 22,
      identifier: 'days-booked-7',
      label: this.intl.t('pricing.listing.daysBookedNextDays', { nbdays: 7 }),
      sortField: 'bookedSeven',
      visibility: false,
    },
    {
      key: 23,
      identifier: 'days-booked-14',
      label: this.intl.t('pricing.listing.daysBookedNextDays', { nbdays: 14 }),
      sortField: 'bookedFourteen',
      visibility: false,
    },
    {
      key: 24,
      identifier: 'days-booked-30',
      label: this.intl.t('pricing.listing.daysBookedNextDays', { nbdays: 30 }),
      sortField: 'bookedThirty',
      visibility: false,
    },
    {
      key: 25,
      identifier: 'days-booked-60',
      label: this.intl.t('pricing.listing.daysBookedNextDays', { nbdays: 60 }),
      sortField: 'bookedSixty',
      visibility: false,
    },
    {
      key: 12,
      identifier: 'days-booked-90',
      label: this.intl.t('pricing.listing.daysBookedNextDays', { nbdays: 90 }),
      sortField: 'bookedNinety',
      visibility: false,
    },
    {
      key: 19,
      identifier: 'days-blocked',
      label: this.intl.t('pricing.listing.daysBlockedNextDays', { nbdays: 90 }),
      sortField: 'ninetyDaysPercentages.daysBlocked',
      visibility: false,
    },
    {
      key: 13,
      identifier: 'days-hitting-min',
      label: this.intl.t('pricing.listing.hittingMinNextDays', { nbdays: 90 }),
      sortField: 'statistics.daysHittingAnyMin',
      visibility: false,
    },
    {
      key: 14,
      identifier: 'last-base-price-change-date',
      label: this.intl.t('pricing.listing.lastBasePriceChangeDate'),
      sortField: 'basePriceUpdatedAt',
      visibility: false,
    },
    {
      key: 15,
      identifier: 'base-price',
      label: this.intl.t('glossary.basePrice'),
      sortField: 'basePrice',
      visibility: false,
      default: true,
      standard: true,
    },
    {
      key: 16,
      identifier: 'min-price',
      label: this.intl.t('glossary.minPrice'),
      sortField: 'minPrice',
      visibility: false,
      default: true,
      standard: true,
    },
    {
      key: 17,
      identifier: 'booking-analysis',
      label: this.intl.t('pricing.bookingReviews.bookingAnalysis'),
      sortField: 'bookingAnalysisText',
      visibility: false,
    },
    {
      key: 18,
      identifier: 'recommendations',
      label: this.intl.t('pricing.bookingReviews.recommendations'),
      sortField: 'recommendationText',
      visibility: false,
    },
    {
      key: 45,
      identifier: 'revenue-progress',
      label: this.intl.t('glossary.revenueProgress'),
      sortField: 'revenueProgress',
      visibility: false,
    },
    {
      key: 46,
      identifier: 'revenue-goal',
      label: this.intl.t('glossary.revenueGoal'),
      sortField: 'revenueGoal',
      visibility: false,
    },
  ];
}
