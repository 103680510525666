import Controller from '@ember/controller';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

class Errors {
  @tracked _cache = {};

  set(key, value) {
    this._cache[key] = value;
    this._cache = this._cache;
  }

  get(key) {
    return this._cache[key];
  }
}

export default class BulkUpdateController extends Controller {
  @tracked saving = false;
  @tracked userId = '';
  @tracked listingIds = '';
  @tracked allListings = false;
  @tracked startDate = new Date('2014-01-01');
  @tracked endDate = new Date('2099-01-01');
  @tracked byListing = true;
  @tracked runningBalance = false;
  @tracked syncStamps = false;
  @tracked seasonalOptions = false;
  @tracked reservations = false;
  @tracked errors = new Errors();
  @tracked responseData = A([]);

  get hasErrors() {
    return Object.keys(this.errors).length > 0;
  }

  get range() {
    return {
      start: this.startDate,
      end: this.endDate,
    };
  }

  validate() {
    this.errors = new Errors();

    if (!this.userId) {
      this.errors.userId = 'User ID is required';
    }
    if (!this.startDate) {
      this.errors.userId = 'Start date is required';
    }
    if (!this.endDate) {
      this.errors.userId = 'End date is required';
    }
    if (this.listingIds) {
      this.listingIds.split(',').forEach(id => {
        if (isNaN(id)) {
          this.errors.listingIds = `Listing ID '${id}' is not an integer`;
        }
      });
    }

    return Object.keys(this.errors).length == 0;
  }

  @action
  async setRange(value) {
    this.startDate = value.start;
    this.endDate = value.end;
  }

  @action
  async submit() {
    if (!this.validate()) {
      return;
    }
    this.saving = true;
    this.responseData = A([]);
    const url = `/api/internal-tools/activity-log`;
    let params = {
      userId: this.userId,
      allListings: this.allListings,
      listingIds: this.listingIds.split(','),
      startDate: this.range.start.toISOString().slice(0, 10),
      endDate: this.range.end.toISOString().slice(0, 10),
      byListing: this.byListing,
      runningBalance: this.runningBalance,
      syncStamps: this.syncStamps,
      seasonalOptions: this.seasonalOptions,
      reservations: this.reservations,
    };

    try {
      let responseData = await this.ajax._post(url, params);
      this.responseData.pushObject(responseData['log']);
    } catch (errors) {
      console.log(errors);
      let text = `Error: ${errors[0].title} - ${errors[0].source.message}`;
      this.responseData.pushObject(text);
      return;
    } finally {
      this.saving = false;
    }
  }
}
