import Model, { attr, belongsTo } from '@ember-data/model';

export default class StatementModel extends Model {
  @belongsTo('user') user;
  @attr('number', { defaultValue: 0 }) amount;
  @attr('string', { defaultValue: 'USD' }) currency;
  @attr card;
  @attr('moment') startDate;
  @attr('moment') endDate;
  @attr('moment') paidAt;
  @attr('boolean', { defaultValue: false }) chargeable;
  @attr('boolean', { defaultValue: false }) isCurrencySynced;
  @attr({ defaultValue: () => [] }) payments;
  @attr({ defaultValue: () => [] }) statementItems;

  get hasNegativeAmount() {
    return this.amount < 0;
  }

  get classSelected() {
    if (this.itemsVisible) {
      return 'row-selected';
    }
    return '';
  }
}
