import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';
import { action } from '@ember/object';

@classic
export default class ReservationsRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service router;
  @service alert;
  @service reservations;

  hasReservationsUnavailable = false;

  nextPage;
  totalPages;

  title(tokens) {
    let title = null;

    if (!tokens || !tokens.length) {
      title = 'Relay Reservations';
    } else {
      title = `Relay Reservations / ${tokens}`;
    }

    this.globalNav.setTitle(title);
    return title;
  }

  renderTemplate() {
    this.render('dashboard/relay/reservations', {
      into: 'dashboard/relay/index',
      outlet: 'reservations',
      controller: 'dashboard/relay/index/reservations',
    });
  }

  async setupController(controller, model) {
    if (controller.get('reservations')?.length > 0) return;

    super.setupController(...arguments);

    let indexController = this.controllerFor('dashboard.relay.index');
    let reservationsController = this.controllerFor(
      'dashboard.relay.index.reservations'
    );

    let rawReservations;

    try {
      rawReservations = await this.reservations.getReservations(
        reservationsController.selectedSortingField,
        reservationsController.sortingOrder,
        model,
        1
      );
    } catch (errors) {
      this.alert.error('An error occurred. Please try again.');
      this.hasReservationsUnavailable = true;
      this.replaceWith('dashboard.relay.index');

      this.indexController.set('selectedTab', indexController.previousTab);
      this.indexController.set(
        'selectedDashboardOption',
        this.indexController.relayDashboardOptions.find(
          m => m.id === this.indexController.previousTab
        )
      );
      return;
    }

    controller.set('reservations', rawReservations.listed);
    controller.set(
      'nextReservationsPage',
      rawReservations.currentPage + 1 > rawReservations.totalPages
        ? rawReservations.totalPages
        : rawReservations.currentPage + 1
    );
    controller.set('totalReservationsPages', rawReservations.totalPages);
    controller.set('reachedLastReservationPage', rawReservations.totalPages == 1);
    controller.set('hasReservationsUnavailable', this.hasReservationsUnavailable);
    indexController.set('areReservationsLoading', false);
  }

  @action
  willTransition(transition) {
    let indexController = this.controllerFor('dashboard.relay.index');

    if (transition.to.localName === 'merged-listings') {
      indexController.set('selectedTab', 0);
      indexController.set(
        'selectedDashboardOption',
        indexController.relayDashboardOptions.find(m => m.id === 0)
      );
    }
    if (transition.to.localName === 'unmerged-listings') {
      indexController.set('selectedTab', 1);
      indexController.set(
        'selectedDashboardOption',
        indexController.relayDashboardOptions.find(m => m.id === 1)
      );
    }

    if (transition.to.name === 'dashboard.relay.index.reservations') {
      indexController.set('selectedTab', 2);
      indexController.set(
        'selectedDashboardOption',
        indexController.relayDashboardOptions.find(m => m.id === 2)
      );
    }
  }
}
