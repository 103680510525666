import { helper as buildHelper } from '@ember/component/helper';

export default buildHelper(function (params) {
  let score = params[0];
  if (score < 50) {
    return 'common.poor';
  }
  if (score < 70) {
    return 'common.fair';
  }
  return 'common.good';
});
