import { isBlank } from '@ember/utils';
import moment from 'moment';

const DateUtil = {
  DEFAULT_FORMAT: 'MMM DD, YYYY',

  _parse(value, utc = false) {
    if (typeof value.format === 'undefined') {
      value = moment(value);
    }
    if (utc) {
      value = value.utc();
    }
    return value;
  },

  format(value, options = {}) {
    if (isBlank(value)) {
      return '';
    }

    // Parse the options
    let fmt = options.fmt || DateUtil.DEFAULT_FORMAT;
    // undefined is falsy and default is false
    let utc = options.utc;

    // Formatting
    return DateUtil._parse(value, utc).format(fmt);
  },

  humanTime(value, options = {}) {
    if (isBlank(value)) {
      return '';
    }

    // undefined is falsy and default is false
    let utc = options.utc;

    // Formatting
    return DateUtil._parse(value, utc).calendar();
  },
};

// TODO: change this to an export
window.DateUtil = DateUtil;
export default DateUtil;
