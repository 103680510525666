import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class TokensRoute extends AuthRoute {
  @service
  intl;

  @service
  alert;

  titleToken = 'Tokens';

  model() {
    const alert = this.alert;
    return this.ajax._get('/api/user/tokens/index').catch(errors => {
      let defaultError = 'validation.genericWithContact';
      errors.forEach(error => {
        if (error.title === 'Password Needed') {
          let sudoController = this.controllerFor('sudo');
          sudoController.set('attemptedTransition', this.transitionTo(this.routeName));
          this.transitionTo('sudo');
          return false;
        }
        let specificError = error.detail || error.message || error.title;
        // If server error show defaultError otherwise show specificError
        if (parseInt(error.status) === 500) {
          alert.error(defaultError, { timeout: 10000 });
        } else {
          alert.error(specificError, { timeout: 10000 });
        }
      });
    });
  }

  setupController(controller, model) {
    controller.setProperties({
      model: model,
      user: this.modelFor('dashboard/user'),
    });
  }

  @action
  deleteToken(token) {
    const alert = this.alert;
    let sure = confirm(this.intl.t('admin.tokenDeletionWarning'));
    if (!sure) {
      return false;
    }

    let value = token.value;
    this.ajax._post(`/api/user/tokens/${value}/delete`).then(
      () => {
        alert.success(this.intl.t('admin.tokenDeleted'));
        this.get('controller.model.tokens').removeObject(token);
      },
      errors => {
        let data = errors[0];
        if (data.title === 'Password Needed') {
          let sudoController = this.controllerFor('sudo');
          sudoController.set('attemptedTransition', this.transitionTo(this.routeName));
          this.transitionTo('sudo');
          return false;
        }
        alert.error(`Error - ${data.message}`, { timeout: 10000 });
      }
    );

    return false;
  }

  @action
  createToken() {
    const alert = this.alert;
    let requestData = { newTokenName: this.controller.get('newTokenName') };
    this.controller.set('posting', true);

    this.ajax._post(`/api/user/tokens/new`, requestData).then(
      data => {
        alert.success(this.intl.t('admin.tokenCreated'));
        this.get('controller.model.tokens').addObject(data.token);
        this.controller.set('posting', false);
      },
      errors => {
        let data = errors[0];
        if (data.title === 'Password Needed') {
          let sudoController = this.controllerFor('sudo');
          sudoController.set('attemptedTransition', this.transitionTo(this.routeName));
          this.transitionTo('sudo');
          return false;
        }
        alert.error(`Error - ${data.message}`, { timeout: 10000 });
        this.controller.set('posting', false);
      }
    );
    return false;
  }
}
