import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { CHANNEL_CONFIG, CHANNEL } from 'appkit/lib/channel_configuration';
import { inject as service } from '@ember/service';

@classic
export default class ChannelController extends Controller {
  @service populateAccount;
  @service featureFlag;
  @tracked hasFastTrack = false;
  @tracked chainedConnectContext;

  chainedConnectionsEnabled = false;
  queryParams = ['id', 'idSecondary', 'code', 'context'];
  id = null;
  idSecondary = null;
  sidebarImageExpander = false;

  connectSupport = {
    airbnb: 'airbnb-and-beyond-pricing-HyaMDSoH_',
    vrbo: 'vrbo-and-beyond-pricing-S10MPBiHd',
    booking_connect: 'booking.com-and-beyond-pricing-r1lXPrjru',
    guesty: 'guesty-and-beyond-pricing-r1rdDSiHd',
    supercontrol: 'supercontrol-and-beyond-pricing-rJAYPHoBd',
    smoobu: 'smoobu-and-beyond-pricing-SJoKvSsS_',
    hostaway: 'hostaway-and-beyond-pricing-SyuOPHoSd',
    bookingsync: 'bookingsync-and-beyond-pricing-r1RwvBjr_',
    hostfully: 'hostfully-and-beyond-pricing-rka_PBjHO',
    ownerrez: 'ownerrez-and-beyond-pricing-HyPFPBiBd',
    kigo: 'kigo-and-beyond-pricing-ByJFvSoHO',
    myvr: 'myvr-and-beyond-pricing-H1SKvSiS_',
    zeevou: 'zeevou-and-beyond-pricing-B1c5DBiHu',
    lodgix: 'lodgix-and-beyond-pricing-BkNtDSsrO',
    vreasy: 'vreasy-and-beyond-pricing-SJVqDHsr_',
    barefoot_direct: 'barefoot-and-beyond-pricing-B1mwBsHd',
    direct: 'direct-and-beyond-pricing-SyQOPrjr_',
    hostify: 'hostify-and-beyond-pricing-rk5OPBsSO',
    beds24: 'beds24-and-beyond-rynOlgVbc',
  };

  connectInstructions = [
    'booking_connect',
    'guesty',
    'supercontrol',
    'smoobu',
    'hostaway',
    'bookingsync',
    'hostfully',
    'ownerrez',
    'kigo',
    'myvr',
    'zeevou',
    'lodgix',
    'vreasy',
    'barefoot_direct',
    'direct',
    'hostify',
    'lightmaker',
  ];

  // Query param sent by Homeaway for the oauth code
  // TODO: review to have maybe a subroute because we can't add all the
  // query params required for every channels here, it would be a mess.
  // For now, it's ok because we have only for Homeaway.
  code = null;

  @tracked product = null;

  // Gather the query params
  @computed('code')
  get extraParams() {
    let params = {};
    let keys = ['code'];
    for (let k of keys) {
      params[k] = this.get(k);
    }
    return params;
  }

  get channelLabel() {
    return this.model.channel_name ? CHANNEL_CONFIG[this.model.channel_name].label : '';
  }

  get isSyncingDone() {
    return this.populateAccount.syncingIsDone;
  }

  init() {
    super.init(...arguments);

    this.featureFlag.evaluate('chained-connections-enabled', false).then(value => {
      this.chainedConnectionsEnabled = value;
    });
  }

  @action
  async accountAdded(accountId) {
    if (!this.chainedConnectionsEnabled) {
      this.transitionToRoute('dashboard.connect-prompt.populate-account', accountId);
      return false;
    }
    this.populateAccount.start(accountId, this.model.channel_name);

    let channelsToConnect = this.connectModel.listingAvailableToBookOn
      .map(channel => {
        if (channel == 'BOOKING') return CHANNEL['BOOKING_CONNECT'];

        return CHANNEL[channel];
      })
      .filter(channel =>
        [CHANNEL.AIRBNB, CHANNEL.VRBO, CHANNEL.BOOKING_CONNECT, CHANNEL.FAKE].includes(
          channel
        )
      );

    let channelsInProgress = this.populateAccount.accountsInProcess.map(
      account => account.channel
    );

    let remainingChannelsToConnect = channelsToConnect.filter(
      c => !channelsInProgress.includes(c)
    );

    if (remainingChannelsToConnect.length === 0) {
      this.transitionToRoute('dashboard.connect-prompt.processing');
    } else {
      this.transitionToRoute(
        'dashboard.connect-prompt.channel',
        remainingChannelsToConnect[0]
      );
    }
  }
}
