import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LinkTo\n  ...attributes\n  @route={{@route}}\n  class='mr-6 pt-4 flex flex-row items-stretch'\n  {{on 'mouseenter' this.onMouseEnter}}\n  {{on 'mouseleave' this.onMouseLeave}}\n>\n  <div class='align-text-bottom mr-1'>\n    <AppSvg\n      @src={{@icon}}\n      @class='{{if this.isActive 'text-brand-400'}}\n       {{\n        if (and this.hover (not this.isActive)) 'text-gray-800' 'text-gray-600'\n      }}\n       fill-current h-4 w-4 transition duration-500 ease-in-out'\n    />\n  </div>\n  <div\n    class='{{\n      if\n      this.isActive\n      'text-gray-800 border-brand-400'\n      'text-gray-600 border-transparent hover:text-gray-800 hover:border-gray-600'\n    }}\n     border-b-4 text-sm pb-3 capitalize whitespace-nowrap -mb-px'\n  >\n    {{@title}}\n  </div>\n</LinkTo>", {"contents":"<LinkTo\n  ...attributes\n  @route={{@route}}\n  class='mr-6 pt-4 flex flex-row items-stretch'\n  {{on 'mouseenter' this.onMouseEnter}}\n  {{on 'mouseleave' this.onMouseLeave}}\n>\n  <div class='align-text-bottom mr-1'>\n    <AppSvg\n      @src={{@icon}}\n      @class='{{if this.isActive 'text-brand-400'}}\n       {{\n        if (and this.hover (not this.isActive)) 'text-gray-800' 'text-gray-600'\n      }}\n       fill-current h-4 w-4 transition duration-500 ease-in-out'\n    />\n  </div>\n  <div\n    class='{{\n      if\n      this.isActive\n      'text-gray-800 border-brand-400'\n      'text-gray-600 border-transparent hover:text-gray-800 hover:border-gray-600'\n    }}\n     border-b-4 text-sm pb-3 capitalize whitespace-nowrap -mb-px'\n  >\n    {{@title}}\n  </div>\n</LinkTo>","moduleName":"appkit/components/bp/menu.hbs","parseOptions":{"srcName":"appkit/components/bp/menu.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MenuComponent extends Component {
  @service router;

  @tracked hover = false;

  @action
  onMouseEnter() {
    this.hover = true;
  }

  @action
  onMouseLeave() {
    this.hover = false;
  }

  get isActive() {
    // https://devjournal.balinterdi.com/setting-link-activeness/
    // Access router.currentURL to mark it as a "dependent key"
    this.router.currentURL;

    return this.router.isActive(this.args.route);
  }
}
