import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class MarketOverallRoute extends AuthRoute {
  templateName = 'data.market-overall';
  controllerName = 'data.availability';

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('site', 'market-overall');
    let market = this.modelFor('data.market');
    controller.set('pageTitle', `Market overall -- ${market.get('name')}`);
    controller.set('id', market.get('id'));
  }
}
