import { helper } from '@ember/component/helper';

const ICON_CONFIG = {
  // Direct channels
  fake: {
    icon: '/assets/company-logo-icons/fake.svg',
  },

  airbnb: {
    icon: 'assets/company-logo-icons/airbnb-logo-4x.png',
  },

  airbnb_partner: {
    icon: 'assets/company-logo-icons/airbnb-logo-4x.png',
  },

  vrbo: {
    icon: 'assets/company-logo-icons/vrbo-owners-icon.png',
  },

  travelmob: {
    icon: null,
  },

  flipkey: {
    icon: 'assets/company-logo-icons/flipkey-logo-4x.png',
  },

  tripadvisor: {
    icon: 'assets/company-logo-icons/tripadvisor-logo-4x.png',
  },

  booking_connect: {
    icon: 'assets/company-logo-icons/bookingcom-logo-4x.png',
  },

  // PMSs
  barefoot_direct: {
    icon: 'assets/company-logo-icons/barefoottech-logo-4x.png',
  },

  bookingsync: {
    icon: 'assets/company-logo-icons/bookingsync-logo-4x.png',
  },

  ciirus: {
    icon: 'assets/company-logo-icons/CiiRUS-logo-4x.png',
  },

  direct: {
    icon: 'assets/company-logo-icons/direct-logo-4x.png',
  },

  escapia: {
    icon: 'assets/company-logo-icons/escapia-logo-4x.png',
  },

  fake_pms: {
    icon: '/assets/company-logo-icons/fake_pms.svg',
  },

  guesty: {
    icon: 'assets/company-logo-icons/guesty-logo-4x.png',
  },

  gfh: {
    icon: 'assets/company-logo-icons/gfh-logo-4x.png',
  },

  hostaway: {
    icon: 'assets/company-logo-icons/hostaway-logo-4x.png',
  },

  homeaway_v12: {
    icon: null,
  },

  hostfully: {
    icon: 'assets/company-logo-icons/hostfully-logo-4x.png',
  },
  icnea: {
    icon: 'assets/company-logo-icons/icnea-logo-4x.png',
  },
  kigo: {
    icon: 'assets/company-logo-icons/kigo-logo-4x.png',
  },

  liverez: {
    icon: 'assets/company-logo-icons/liverez-logo-4x.png',
  },

  liverez_direct: {
    icon: 'assets/company-logo-icons/liverez-logo-4x.png',
  },

  myvr: {
    icon: 'assets/company-logo-icons/MyVR-logo-4x.png',
  },

  stayduvet: {
    icon: 'assets/company-logo-icons/stayduvet-logo-4x.png',
  },

  streamline: {
    icon: 'assets/company-logo-icons/streamline-logo-4x.png',
  },

  smoobu: {
    icon: 'assets/company-logo-icons/smoobu-logo-4x.png',
  },

  supercontrol: {
    icon: 'assets/company-logo-icons/Supercontrol-logo-4x.png',
  },

  track: {
    icon: 'assets/company-logo-icons/TRACK-logo-4x.png',
  },

  vreasy: {
    icon: 'assets/company-logo-icons/vreasy-logo-4x.png',
  },

  vrm: {
    icon: 'assets/company-logo-icons/VRM-logo-4x.png',
  },

  zeevou: {
    icon: 'assets/company-logo-icons/Zeevou-logo-4x.png',
  },

  fantasticstay: {
    icon: 'assets/company-logo-icons/fantasticstay-logo-4x.png',
  },

  uplisting: {
    icon: 'assets/company-logo-icons/uplisting-logo-4x.png',
  },

  lodgify: {
    icon: 'assets/company-logo-icons/lodgify-logo-4x.png',
  },

  kross_booking: {
    icon: 'assets/company-logo-icons/krossbooking-logo-4x.png',
  },

  janiis: {
    icon: null,
  },

  igms: {
    icon: 'assets/company-logo-icons/igms-logo-4x.png',
  },
  rentals_united: {
    icon: 'assets/company-logo-icons/rentals_united.svg',
  },
  hostify: {
    icon: 'assets/company-logo-icons/hostify.svg',
  },
  avantio: {
    icon: 'assets/company-logo-icons/avantio.png',
  },
  beds24: {
    icon: 'assets/company-logo-icons/beds24.png',
  },
  homhero: {
    icon: 'assets/company-logo-icons/homhero.png',
  },
  tokeet: {
    icon: 'assets/company-logo-icons/tokeet-logo-4x.png',
  },
  rms: {
    icon: 'assets/company-logo-icons/rms.svg',
  },
  octorate: {
    icon: 'assets/company-logo-icons/octorate.svg',
  },
  rentalready: {
    icon: 'assets/company-logo-icons/rentalready.svg',
  },
  cloudbeds: {
    icon: 'assets/company-logo-icons/cloudbeds.svg',
  },
  real_time_rental: {
    icon: 'assets/company-logo-icons/real_time_rental.svg',
  },
  hospitable: {
    icon: 'assets/company-logo-icons/hospitable.svg',
  },
  lodgable: {
    icon: 'assets/company-logo-icons/lodgable-logo.png',
  },
  lightmaker: {
    icon: 'assets/company-logo-icons/lightmaker.svg',
  },
  irent: {
    icon: 'assets/company-logo-icons/irent-logo.svg',
  },
};

const getIcon = code => {
  let conf = ICON_CONFIG[code];
  if (conf) {
    return conf.icon;
  }
  return null;
};

export default helper(function (params) {
  return getIcon(params[0]);
});
