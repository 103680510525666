import { computed } from '@ember/object';
import Component from '@glimmer/component';
import moment from 'moment';

export default class AppCalendarHoverDay extends Component {
  @computed('hoverDay')
  get isOverridden() {
    return (
      this.args.hoverDay.isOverriden || !!this.args.hoverDay.percentageUserOverride
    );
  }

  @computed('hoverDay')
  get originalPriceModeled() {
    return this.args.hoverDay.priceUser
      ? this.args.hoverDay.priceModeled
      : Math.round(
          this.args.hoverDay.priceModeled /
            (1 + this.args.hoverDay.percentageUserOverride)
        );
  }

  @computed('args.hoverDay.date')
  get isPastDate() {
    return moment(this.args.hoverDay.date).isBefore(moment(), 'day');
  }
}
