import BaseModel from 'appkit/bp-models/base_model';

export default BaseModel.extend({
  grant: 'view',
  listingId: 0,
  credentialId: 0,
  init() {
    this._super(...arguments);

    this._EDITABLE = ['grant'];
  },
});
