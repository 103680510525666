import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class MinPriceRecommendationsRoute extends AuthRoute {
  model() {
    return this.ajax._get(`/api/user/is_staff`);
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const userId = model.find(m => m.default)?.id;

    controller.set('staffUserId', userId);
  }
}
