import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    extra=this.extra\n    selected=this.internalSelected\n    afterOptionsComponent=this.afterOptionsComponent\n    actions=(hash\n      registerAPI=this.registerAPI onChange=this.onChange onClose=this.onClose\n    )\n  )\n}}", {"contents":"{{yield\n  (hash\n    extra=this.extra\n    selected=this.internalSelected\n    afterOptionsComponent=this.afterOptionsComponent\n    actions=(hash\n      registerAPI=this.registerAPI onChange=this.onChange onClose=this.onClose\n    )\n  )\n}}","moduleName":"appkit/components/bp/select/confirm-container.hbs","parseOptions":{"srcName":"appkit/components/bp/select/confirm-container.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-observers
import { addObserver, removeObserver } from '@ember/object/observers';
import { next } from '@ember/runloop';

export default class BpSelectConfirmContainer extends Component {
  @tracked internalSelected = this.args.selected;

  constructor() {
    super(...arguments);
    // eslint-disable-next-line ember/no-observers
    addObserver(this.args, 'selected', this.updateSelected);
  }

  willDestroy() {
    removeObserver(this.args, 'selected', this.updateSelected);
  }

  get afterOptionsComponent() {
    return 'bp/select/parts/after-options';
  }

  get extra() {
    return {
      ...this.args.extra,
      canConfirm: this.internalSelected !== this.args.selected,
      canClear: Array.isArray(this.internalSelected)
        ? this.internalSelected.length > 0
        : this.internalSelected,
      allowTagRemove: false,
      actions: {
        confirmSelected: this.confirmSelected,
        clearSelected: this.clearSelected,
      },
    };
  }

  @action
  registerAPI(select) {
    this.select = select;
  }

  @action
  onChange(selected) {
    if (!this.select.isOpen) {
      this.select.actions.open();
    }
    this.internalSelected = selected;
  }

  @action
  updateSelected() {
    this.internalSelected = this.args.selected;
  }

  @action
  onClose() {
    this.internalSelected = this.args.selected;
  }

  @action
  confirmSelected() {
    next(() => {
      this.args.onChange(this.internalSelected);
      this.select.actions.close();
    });
  }

  @action
  clearSelected() {
    next(() => {
      const isSelected = Array.isArray(this.args.selected)
        ? this.args.selected.length > 0
        : this.args.selected != null;

      if (isSelected) {
        this.args.onChange(null);
      }
      this.select.actions.close();
    });
  }
}
