import { attributeBindings, tagName } from '@ember-decorators/component';
import { computed, setProperties } from '@ember/object';

import Component from '@ember/component';
import ENV from 'appkit/config/environment';
import InViewportMixin from 'ember-in-viewport';
import classic from 'ember-classic-decorator';

const nothing = ''.htmlSafe();

// TODO: Implement lazy loading
// https://medium.com/the-ember-way/lazy-loading-images-in-ember-b130533583ff
@classic
@tagName('img')
@attributeBindings('fingerprintedSrc:src', 'style')
export default class AppImg extends Component.extend(InViewportMixin) {
  dimensions = '';
  src = 'PUBLIC';
  _src = '';

  didInsertElement() {
    super.didInsertElement(...arguments);

    let enabled = this.lazy && localStorage.getItem('hideImages') !== 'true';

    setProperties(this, {
      viewportEnabled: enabled,
    });
  }

  // Whether to wait until in the viewport to load
  lazy = false;

  didEnterViewport() {
    undefined;

    this.set('_src', encodeURI(decodeURI(this.src)));
  }

  // Setting this to true makes the image transparent and instead places the
  // source image as a background image. This allows us to use cover / contain
  // styles.
  cover = false;

  // TODO: check all init's - setting from inside will cause issues
  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    if (this.lazy) {
      return;
    }
    let src = this.src;
    this.set('_src', encodeURI(decodeURI(src)));
  }

  @computed('fingerprintedUrl', 'cover')
  get style() {
    // Ember complains about style bindings being unsafe even when they're
    // undefined. Probably should bug report that...
    if (!this.cover) {
      return nothing;
    }

    let url = this.fingerprintedUrl;
    if (!url) {
      return nothing;
    }

    // Only way this can be a XSS vuln is if fingerprints is overridden, and
    // you'd have to have JS access for that anyway, so this is safe.
    return `background-image: url("${url}");
    background-size: cover;
    background-position: center;`.htmlSafe();
  }

  @computed('_src')
  get fingerprintedUrl() {
    let url = this._src;
    if (!url) {
      return nothing;
    }

    if (this.dimensions) {
      return `${ENV.APP.IMG_PROXY_URL}/${this.dimensions}/${url.htmlSafe()}`;
    }

    if (url.indexOf('https://assets.beyondpricing.com') === 0) {
      return url.htmlSafe();
    }

    if (url.indexOf('http') === 0) {
      // DirectIO images are being encoded incorrectly. Statement bellow reverts encoding.
      // Barefoot_direct too.
      if (
        url.match(/X-Amz-Credential=AKIAXTKU7IFB2NA7M2HR/) ||
        url.match(/barefoot.com/) ||
        url.match(/orbirental-images.s3.amazonaws.com/)
      ) {
        let clean_url = url.replace(/%25/gi, '%');
        return `${ENV.APP.IMG_PROXY_URL}/300/${clean_url.htmlSafe()}`;
      }
      return `${ENV.APP.IMG_PROXY_URL}/300/${url.htmlSafe()}`;
    }
    if (url.indexOf('/') === 0) {
      url = url.slice(1);
    }

    // Ember-cli preprocessor replaces asset urls inside javascript too. If
    // there's already a fingerprint in the URL, let it through.
    if (url.match(/(.*)-[0-9a-f]*\./)) {
      return '/' + url.htmlSafe();
    }

    if (window.fingerprints[url]) {
      url = '/' + window.fingerprints[url];
    } else {
      console.warn('No fingerprint found for image', url);
    }

    return url.htmlSafe();
  }

  @computed('fingerprintedUrl', 'cover')
  get fingerprintedSrc() {
    if (this.cover) {
      return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'.htmlSafe();
    }

    return this.fingerprintedUrl;
  }
}
