import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';

@classic
export default class NearbyRoute extends AuthRoute {
  templateName = 'listing.nearby';
  controllerName = 'listing.nearby';
  @service featureFlag;

  setupController(controller, model) {
    // Controllers are singletons. Clear all caches so that we can load new
    // nearby data as we move between listings. We should probably move this up
    // a level to the listing controller - nearby shouldn't need to reload as we
    // switch between calendar and nearby.
    controller._nearbyCache = {};
    controller._nearbyLoading = {};
    controller.set('channel', 'airbnb');
    controller.set('model', model);
  }

  async model() {
    return this.modelFor('dashboard.pricing.listing');
  }
}
