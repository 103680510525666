import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { colorBlindOptions } from '../../../lib/constants/accessibility';

@classic
export default class AccessibilityController extends Controller {
  @service featureFlag;
  @service intl;

  @tracked userSetting = 'default';
  @tracked selectedScheme = false;
  @tracked cvdOptions = colorBlindOptions(this.intl);

  async init() {
    super.init(...arguments);
  }

  @action
  setUserSetting(event) {
    this.userSetting = event.target.value;
  }
}
