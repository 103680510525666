import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DashboardOwnerResourceReferenceListingRoute extends Route {
  @service('global-nav') globalNav;
  @service('owner-resource') ownerResource;

  title() {
    let title = 'Projections / Reference listing';
    this.globalNav.setTitle(title);
    return title;
  }

  @action
  willTransition(transition) {
    const transitionToBuildProjection = transition.to.name.includes(
      'dashboard.owner-resource.projection.build-projection'
    );
    if (transitionToBuildProjection) {
      return;
    }

    this.ownerResource.searchedReferenceListings = false;
  }

  renderTemplate() {
    this.render('dashboard/owner-resource/projection/reference-listing', {
      into: 'dashboard/owner-resource/projection',
      outlet: 'reference-listing',
    });
  }
}
