import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class NearbyRoute extends AuthRoute {
  templateName = 'listing.nearby';
  controllerName = 'listing.nearby';

  setupController(controller, model) {
    // Clear caches
    controller._nearbyCache = {};
    controller._nearbyLoading = {};
    controller.set('channel', 'airbnb');
    controller.set('model', model);
  }

  model() {
    return this.modelFor('preview');
  }
}
