import moment from 'moment';

export default function sortListings(listings, sortField, sortOrder) {
  const out = listings.concat();
  // We always want to keep unlisting / inactive places at the bottom,
  // regardless of sort order. We always want to keep enabled at the top and
  // apply the custom sort within it.
  let primarySortFields = {
    'primaryChannelListing.listed': -1,
    inActiveMarket: -1,
    enabled: -1,
  };
  let secondarySortFields = {
    title: 1,
    'statistics.twoWeekReservationsCount': 1,
    bookedThirty: 1,
    bookedNinety: 1,
    healthScore: 1,
    'statistics.daysHittingAnyMin': 1,
    'ninetyDaysPercentages.daysBlocked': 1,
    basePrice: 1,
    minPrice: 1,
    bedrooms: 1,
    bathrooms: 1,
    basePriceUpdatedAt: 1,
    createdAt: 1,
    market: 1,
    cluster: 1,
  };

  // If we sort by one of these fields we want to skip primary sorting
  let ignorePrimarySortFields = ['createdAt'];

  let userSort = sortField;
  let direction = 1;
  if (sortOrder !== 'asc') {
    direction = -1;
  }
  out.sort((a, b) => {
    // Always put unlisted listings at the bottom, regardless of direction
    // unless specifically disabled in "ignorePrimarySortFields".
    if (!ignorePrimarySortFields.includes(userSort)) {
      for (let field of Object.keys(primarySortFields)) {
        let modifier = primarySortFields[field];
        if (a.get(field) < b.get(field)) {
          return -1 * modifier;
        }
        if (a.get(field) > b.get(field)) {
          return 1 * modifier;
        }
      }
    }
    // Next sort by the user preferred sort

    // user preferred sort for date field
    if (['basePriceUpdatedAt', 'createdAt'].includes(userSort)) {
      // put null at the bottom
      if (a.get(userSort) === null) {
        return 1;
      }
      if (b.get(userSort) === null) {
        return -1;
      }
      // handle date sort
      let modifier = secondarySortFields[userSort];
      if (moment(a.get(userSort)).isBefore(moment(b.get(userSort)))) {
        return -1 * modifier * direction;
      }
      if (moment(a.get(userSort)).isAfter(moment(b.get(userSort)))) {
        return 1 * modifier * direction;
      }
      // user preferred sort for non-date field
    } else {
      let modifier = secondarySortFields[userSort];
      if (a.get(userSort) < b.get(userSort)) {
        return -1 * modifier * direction;
      }
      if (a.get(userSort) > b.get(userSort)) {
        return 1 * modifier * direction;
      }
    }

    // Then loop through the rest of the columns
    for (let field of Object.keys(secondarySortFields)) {
      let modifier = secondarySortFields[field];
      if (a.get(field) < b.get(field)) {
        return -1 * modifier * direction;
      }
      if (a.get(field) > b.get(field)) {
        return 1 * modifier * direction;
      }
    }

    return 0;
  });

  return out;
}
