import Service from '@ember/service';

export default class CookiesService extends Service {
  cookies = {};

  constructor() {
    super(...arguments);
    this.cookies = document.cookie.split(';').reduce((cookies, row) => {
      const [key, value] = row.trim().split('=');
      cookies[key] = value;
      return cookies;
    }, {});
  }

  get(key) {
    return this.cookies[key];
  }

  set(key, value, expirationStamp, sameSite = 'lax', domain = '.beyondpricing.com') {
    let cookie = `${key}=${value}; path=/; expires=${expirationStamp}`;

    if (sameSite) cookie += ` SameSite=${sameSite};`;
    if (domain) cookie += ` domain=${domain};`;

    document.cookie = cookie;
    this.cookies[key] = value;
  }
}
