import Controller from '@ember/controller';
import { CHANNEL, CHANNEL_CONFIG } from 'appkit/lib/channel_configuration';
import { ChannelUtil } from 'appkit/lib/channel';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { default as mainChannel } from 'appkit/lib/main_channel';
export default class IndexController extends Controller {
  @service ajax;
  @service alert;
  @service intl;
  @service cookies;
  @tracked onOnbordingSurvey = false;
  @tracked surveyAnswersToDispatch = {};
  @tracked onboardingSurveyAlreadyAnswered = this.model.onboardingSurveyAlreadyAnswered;

  @service featureFlag;
  @tracked isOnAssistedConnectProgram = false;
  @tracked mainChannel;

  get pmsForInquiry() {
    let excludeList = [
      'barefoot_direct',
      'liverez_direct',
      'best_beach',
      'niido',
      'stayduvet',
      'fake_pms',
    ];

    return Object.values(CHANNEL).filter(
      channel => CHANNEL_CONFIG[channel]?.pms && !excludeList.includes(channel)
    );
  }

  get firstAccountConnected() {
    return this.model.accounts?.length > 0;
  }

  get channelsBetaEnabled() {
    return this.model.user.channelsBetaEnabled ?? [];
  }

  get supportedChannels() {
    let supported = ChannelUtil.getSupported();
    let beta = this.channelsBetaEnabled.filter(item => !supported.includes(item));

    beta = beta.sort();
    return supported.concat(beta);
  }

  get assistedChannels() {
    return ChannelUtil.getAssisted();
  }

  onboardingSurveyQuestions = [
    {
      id: 'question_1',
      value: this.intl.t('onboarding.question-1'),
      answers: [
        {
          type: 'logo',
          label: 'airbnb',
          value: 'AIRBNB',
        },
        {
          type: 'logo',
          label: 'vrbo',
          value: 'VRBO',
        },
        {
          type: 'logo',
          label: 'booking',
          value: 'BOOKING',
        },
        {
          type: 'text',
          label: this.intl.t('onboarding.answerDirectWebsite'),
          value: 'DIRECT_WEBSITE',
        },
        {
          type: 'text',
          label: this.intl.t('onboarding.answerOther'),
          value: 'OTHER',
        },
        {
          type: 'text',
          label: this.intl.t('onboarding.answerNowhere'),
          value: 'NOWHERE',
        },
      ],
      type: 'multiple',
    },
    {
      id: 'question_2',
      value: this.intl.t('onboarding.question-2'),
      answers: [
        {
          type: 'text',
          label: this.intl.t('onboarding.answerOtherChannels'),
          value: 'OTHER:CHANNELS',
        },
        {
          type: 'nested',
          label: this.intl.t('onboarding.ddPMSTriggerPlaceholder'),
          value: {
            options: this.pmsForInquiry
              .map(pms => ({
                type: 'logo',
                label: pms,
                value: pms.toUpperCase(),
              }))
              .concat({
                type: 'text',
                label: this.intl.t('onboarding.answerOtherPMS'),
                value: 'OTHER:PMS',
              }),
            triggerPlaceholder: this.intl.t('onboarding.ddPMSTriggerPlaceholder'),
            contentPlaceholder: this.intl.t('onboarding.ddPMSContentPlaceholder'),
          },
        },
        {
          type: 'text',
          label: this.intl.t('onboarding.answerOther'),
          value: 'OTHER:GENERAL',
        },
      ],
      type: 'single',
    },
  ];

  @action
  async postSurveyAnswers(answers) {
    let url = '/api/user/questions';
    const headers = {
      'X-CSRFToken': this.cookies.get('csrftoken'),
    };

    this.surveyAnswersToDispatch.mostTimeManagingSpentOn = answers.question_2.value;
    this.surveyAnswersToDispatch.listingAvailableToBookOn = answers.question_1.map(
      a => a.value
    );
    //this.surveyAnswersToDispatch.managesAvailabilityOn;

    try {
      await this.ajax._patch(url, this.surveyAnswersToDispatch, headers);
    } catch (errors) {
      console.log(errors);
      return;
    }

    this.mainChannel = mainChannel(
      answers.question_2.value,
      this.surveyAnswersToDispatch.listingAvailableToBookOn
    );

    getOwner(this).lookup(
      `controller:dashboard.connect-prompt`
    ).onboardingSurveyAlreadyAnswered = true;
    this.onboardingSurveyAlreadyAnswered = true;
    this.onOnbordingSurvey = false;

    if (!this.mainChannel) return;

    let routeToTake = this.assistedChannels.includes(this.mainChannel)
      ? 'assisted'
      : 'channel';

    this.transitionToRoute(`dashboard.connect-prompt.${routeToTake}`, this.mainChannel);

    getOwner(this).lookup(
      `controller:dashboard.connect-prompt.${routeToTake}`
    ).hasFastTrack = true;
  }

  @action
  skipOnboardingSurvey() {
    getOwner(this).lookup(
      `controller:dashboard.connect-prompt`
    ).onboardingSurveyAlreadyAnswered = true;

    this.onboardingSurveyAlreadyAnswered = true;
  }

  constructor() {
    super(...arguments);

    this.featureFlag
      .evaluate('assisted-connect-to-self-served-users', false)
      .then(value => {
        this.isOnAssistedConnectProgram = value;
      });
  }
}
