/* global Highcharts */
import moment from 'moment';

// Setting the timezoneOffset to zero in charts because chart data is currently
// already localized and does not need offsetting, and this option not yet
// available per-chart https://github.com/highcharts/highcharts/issues/5168
export default function (defaultOptions) {
  defaultOptions.global.timezoneOffset = 0;
  defaultOptions.credits.enabled = false;

  // Format a date into a locale specific date string. highcharts uses strftime
  // format, and moment uses it's own `DDDD mm YY` style format.
  // No idea why this doesn't work when defined on defaultOptions...
  Highcharts.dateFormats = {
    L(timestamp) {
      return moment(timestamp).format('LL');
    },
  };
  Highcharts.credits = { enabled: false };

  return defaultOptions;
}
