import classic from 'ember-classic-decorator';
import { action, set } from '@ember/object';
//import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class BulkEditRoute extends AuthRoute {
  //@service() featureFlag;

  @action
  willTransition() {
    this.controller.customize.resetChanges();
  }

  async beforeModel() {
    super.beforeModel(arguments);
    let pricingController = this.controllerFor('dashboard.pricing.index');

    if (!pricingController.model?.listings.filter(listing => listing.selected))
      return this.transitionTo('dashboard.pricing.index');

    // Disabling bulk updates flag for now, it should be GA
    // const isBulkUpdatesEnabled = await this.featureFlag.evaluate(
    //   'bulk-updates',
    //   false
    // );
    //
    // if (!isBulkUpdatesEnabled) {
    //   return this.transitionTo('dashboard.pricing.index');
    // }
  }

  setupController(controller, model) {
    set(controller, 'model', model);
    set(controller, 'listingReferralToImport', null);
    set(controller, 'listingReferralSettings', null);
    controller.resetCustomize();
  }

  renderTemplate() {
    this.render('dashboard/pricing/index/bulk-edit', {
      into: 'dashboard/pricing/index',
      outlet: 'side-panel',
    });
  }
}
