import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
// import logger from 'appkit/lib/logger';

const timeToSync = diff => {
  //0 seconds
  if (diff <= 0) {
    return {
      duration: '0 secs',
      parsed: true,
    };
  }

  //n seconds
  if (diff < 60) {
    return {
      duration: `${diff} sec${diff == 1 ? '' : 's'}`,
      parsed: true,
    };
  }

  //n minutes
  if (diff < 60 * 60) {
    let minutes = Math.round(diff / 60);
    let minute = 60;

    return {
      duration: `${minutes} min${minutes == minute ? '' : 's'}`,
      parsed: true,
    };
  }

  //n hours
  if (diff < 60 * 60 * 24) {
    let hours = Math.round(diff / (60 * 60));
    let hour = 60 * 60;

    return {
      duration: `${hours} hr${hours == hour ? '' : 's'}`,
      parsed: true,
    };
  }

  return {
    duration: diff,
    parsed: false,
  };
};

const statusSetAt = (status, bookingAt, canceledAt, standardAt) => {
  if (status == 'accepted') {
    return bookingAt;
  } else if (status == 'canceled') {
    return canceledAt;
  } else {
    return standardAt;
  }
};

const reservationStatus = {
  accepted: 'Booking',
  unconfirmed: 'Inquiry',
  canceled: 'Cancelled',
};

const getLastReservationPushStatus = (payload, relationships) => {
  let outcome = payload.included?.find(pr => pr.id === relationships?.data?.id);

  return outcome?.attributes;
};

export default class ReservationsService extends Service {
  @service ajax;
  @tracked selectedSortingField;
  @tracked sortingOrder;
  @tracked pageToRequest;

  async callToGetReservations(model) {
    let page = `&page_num=${this.pageToRequest}`;
    let result;

    try {
      result = await this.ajax._get(
        `/api/relay/reservations?ordering=${
          this.sortingOrder + this.selectedSortingField + page
        }&type=DIRECT&checkin_date__gte=${moment().format('YYYY-MM-DD')}`
      );
    } catch (errors) {
      return;
    }

    let filteredReservations = result.data.filter(
      r =>
        model.channelListings
          .map(cl => parseInt(cl.id))
          .includes(parseInt(r.relationships.channelListing.data.id)) &&
        (!r.attributes.reference?.includes('thread') ||
          !r.attributes.status?.includes('unconfirmed'))
    );

    return {
      listed: filteredReservations.map(r => ({
        ...r.attributes,
        guest: {
          ...r.attributes.guest,
          name:
            r.attributes.guest?.name ??
            `${r.attributes.guest?.firstName ?? '--'} ${
              r.attributes.guest?.firstName ? r.attributes.guest?.lastName ?? '' : ''
            }`,
        },
        id: r.id,
        status: reservationStatus[r.attributes.status],
        defaultStatus: r.attributes.status,
        statusSetAt:
          statusSetAt(
            r.attributes.status,
            r.attributes.bookedAt,
            r.attributes.canceledAt,
            r.attributes.statusHistory[r.attributes.statusHistory.length - 1].stamp
          ) || r.attributes.statusHistory[r.attributes.statusHistory.length - 1].stamp,
        channelListingId: r.relationships.channelListing.data.id,
        listing: model.listings.find(l =>
          l.channelListingsIds.includes(
            parseInt(r.relationships.channelListing.data.id)
          )
        ),
        ...(r.relationships.lastPushReservationOutcome.data != null && {
          lastReservationPushStatus: getLastReservationPushStatus(
            result,
            r.relationships.lastPushReservationOutcome
          ),
        }),
        listingReservationChannel: model.listings
          .find(l =>
            l.channelListingsIds.includes(
              parseInt(r.relationships.channelListing.data.id)
            )
          )
          .channelListings.filter(cl => cl.channel == r.attributes.channel),
        timeToSync: timeToSync(
          moment(r.attributes.firstPushedReservationAt).diff(
            moment(r.attributes.bookedAt || r.attributes.statusHistory[0].stamp),
            'seconds'
          )
        ),
      })),
      currentPage: result.meta.pagination.page,
      totalPages: result.meta.pagination.pages,
    };
  }

  getReservations(sortingField, sortingOrder, model, pageToRequest) {
    this.selectedSortingField = sortingField || 'status_updated_at';
    this.sortingOrder = sortingOrder;
    this.pageToRequest = pageToRequest;

    return this.callToGetReservations(model);
  }

  editReservations(reservationId, editTerm) {
    return this.ajax._post(`/api/relay/reservations/${reservationId}/${editTerm}/`);
  }
}
