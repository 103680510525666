import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import logger from 'appkit/lib/logger';
import ENV from 'appkit/config/environment';
import LoginRoute from 'appkit/routes/login';
import { checkPassword } from 'appkit/lib/password';

export default class CreateAccountBaseRoute extends LoginRoute {
  url = '/api/signup';
  activateProducts = [];

  @service
  googleTagManager;

  @service
  router;

  @service
  impact;

  @action
  async onSubmit(credentials) {
    const { isValid, error } = checkPassword(credentials.password);

    if (!isValid) {
      credentials.set('errors.base', error);
      this.controller.set('posting', false);
      return false;
    }

    // Setting a new controller just for getting a parameter from the route is a bit
    // overkill. Rather have the context on trial skip on the same place where we
    // create the account for readability purposes.
    const skipTrial = this.router.currentRouteName.includes('skip-trial');
    credentials.set('skipTrial', skipTrial);
    credentials.set('activateProducts', this.activateProducts);

    await super.onSubmit.apply(this, [credentials]);
    if (ENV.environment !== 'production') {
      return true;
    }
    // Can't send PII to GA
    // let email = this.controller.get('model.email');
    let code = this.controller.get('model.referralCode') || 'none';
    let label = `referral:${code}`;
    if (typeof window.ga !== 'undefined') {
      window.ga('send', 'event', 'submit', 'sign_up_submit', label);
    }
    /**
     * Submits user attributes for Create-Account tracking to Impact affiliate partner
     */
    try {
      this.currentUser.email = credentials.email;
      await this.impact.trackConversion('Signup', this.currentUser);
      logger.info(
        `Signup event dispatched to Impact Affiliate Partner. UserID: ${this.currentUser?.userId}`
      );
    } catch (e) {
      logger.error(
        `Failed to track Signup Impact Event, error: ${e}. UserID: ${this.currentUser?.userId}`,
        e
      );
    }

    this.googleTagManager.createAccount();

    return true;
  }
}
