import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class BookingRoute extends AuthRoute {
  controllerName = 'listing.nearby.inner';
  templateName = 'listing/nearby/inner';

  setupController(controller, model) {
    controller.set('model', model);
    const nearbyController = controller.nearbyController;
    nearbyController.set('channel', 'booking');
    nearbyController.set('nearbyListings', model.get('listings'));
    nearbyController.set('_nearbyListingsCacheBooking', model);
  }

  resetController(controller, isExiting, transition) {
    if (isExiting && !transition.to.name.includes('.listing.nearby.')) {
      const nearbyController = controller.nearbyController;
      nearbyController.set('_nearbyListingsCacheAirbnb', null);
      nearbyController.set('_nearbyListingsCacheHomeaway', null);
      nearbyController.set('_nearbyListingsCacheBooking', null);
    }
  }

  async model() {
    const id = this.modelFor('dashboard.pricing.listing').get('listing.id');
    const url = `/api/listings/${id}/nearby/booking`;
    const cache = this.get('controller.nearbyController._nearbyListingsCacheBooking');

    if (cache) {
      return cache;
    }

    const data = await this.ajax._get(url);

    const nearbyListings = data.listings.map(row =>
      this.bpStore.createRecord('listing', row)
    );

    return EmberObject.create({
      listings: nearbyListings,
    });
  }
}
