import { SIZES, VARIANTS, COLORS, mergeTheme, baseTheme } from '../../../theme';

const defaultClasses = () => ['flex', 'pr-1', 'max-w-full'];

export const spacingClasses = ({ allowTagRemove }) => ({
  [SIZES.XS]: allowTagRemove ? ['pl-2', 'pr-1', 'py-0.5'] : ['px-2', 'py-0.5'],
  [SIZES.SM]: allowTagRemove ? ['pl-2', 'pr-1', 'py-0.5'] : ['px-2', 'py-0.5'],
  [SIZES.MD]: allowTagRemove ? ['pl-2', 'pr-1', 'py-0.5'] : ['px-2', 'py-0.5'],
  [SIZES.LG]: allowTagRemove ? ['pl-2', 'pr-1', 'py-0.5'] : ['px-2', 'py-0.5'],
  [SIZES.XL]: allowTagRemove ? ['pl-2', 'pr-1', 'py-0.5'] : ['px-2', 'py-0.5'],
});

const fontSizeClasses = () => {
  const baseFontSizes = baseTheme.fontSizeClasses();
  return {
    [SIZES.MD]: baseFontSizes.sm,
    [SIZES.LG]: baseFontSizes.md,
    [SIZES.XL]: baseFontSizes.lg,
  };
};

const variantClasses = () => ({
  [VARIANTS.CONTAINED]: {
    [COLORS.ALL]: 'bg-white',
    [COLORS.BRAND]: ['text-brand-500'],
  },
  [VARIANTS.OUTLINED]: {
    [COLORS.ALL]: 'text-white',
    [COLORS.BRAND]: ['bg-brand-500'],
  },
  [VARIANTS.TEXT]: {
    [COLORS.ALL]: 'text-white',
    [COLORS.BRAND]: ['bg-brand-500'],
  },
});

const tagIconWrapperClasses = mergeTheme(variantClasses, () => ({
  [VARIANTS.CONTAINED]: {
    [COLORS.ALL]: ['bg-white', 'hover:bg-gray-100'],
  },
  [VARIANTS.OUTLINED]: {
    [COLORS.BRAND]: ['hover:bg-brand-600'],
  },
}));

const tagIconSizesClasses = () => ({
  [SIZES.ALL]: ['h-3', 'w-3'],
});

const tagIconSpacingClasses = () => ({
  [SIZES.ALL]: ['mx-1'],
});

export default {
  root: {
    defaultClasses,
  },

  tagText: {
    defaultClasses: ({ allowTagRemove, noWrap }) => [
      allowTagRemove ? 'rounded-l' : 'rounded',
      noWrap && 'truncate',
    ],
    fontSizeClasses,
    variantClasses,
    spacingClasses,
  },

  tagIconWrapper: {
    defaultClasses: () => ['rounded-r', 'flex', 'items-center'],
    variantClasses: tagIconWrapperClasses,
  },

  tagIcon: {
    defaultClasses: () => ['fill-current'],
    iconSizeClasses: tagIconSizesClasses,
    spacingClasses: tagIconSpacingClasses,
  },
};
