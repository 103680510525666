import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and @sticky @unstuckClasses @stuckClasses)}}\n  <div {{on-visible (concat '#' @id) @unstuckClasses @stuckClasses}}></div>\n{{/if}}\n<div\n  id={{@id}}\n  class='bp-table-header flex bg-gray-100 border border-gray-200 text-sm text-gray-500 leading-4 {{\n    this.stickyClasses\n  }}'\n  ...attributes\n>\n  {{#if @selectable}}\n    <div class='p-2 flex items-center text-base'>\n      <input\n        type='checkbox'\n        checked={{this.allSelected}}\n        onclick={{this.onSelect}}\n        class='form-checkbox'\n      />\n    </div>\n  {{/if}}\n  {{yield (hash Cell=(component 'bp/table/cell' widthClass=@widthClass))}}\n</div>", {"contents":"{{#if (and @sticky @unstuckClasses @stuckClasses)}}\n  <div {{on-visible (concat '#' @id) @unstuckClasses @stuckClasses}}></div>\n{{/if}}\n<div\n  id={{@id}}\n  class='bp-table-header flex bg-gray-100 border border-gray-200 text-sm text-gray-500 leading-4 {{\n    this.stickyClasses\n  }}'\n  ...attributes\n>\n  {{#if @selectable}}\n    <div class='p-2 flex items-center text-base'>\n      <input\n        type='checkbox'\n        checked={{this.allSelected}}\n        onclick={{this.onSelect}}\n        class='form-checkbox'\n      />\n    </div>\n  {{/if}}\n  {{yield (hash Cell=(component 'bp/table/cell' widthClass=@widthClass))}}\n</div>","moduleName":"appkit/components/bp/table/header.hbs","parseOptions":{"srcName":"appkit/components/bp/table/header.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import classNames from 'classnames';

export default class BpTableHeaderComponent extends Component {
  get stickyClasses() {
    return this.args.sticky
      ? classNames('sticky top-0', {
          'px-2': !this.args.unstuckClasses,
        })
      : 'px-2';
  }

  get allSelected() {
    const { entries = [], selected = [] } = this.args;
    return !!selected.length && selected.length >= entries.length;
  }

  @action
  onSelect() {
    const { entries = [] } = this.args;
    this.args.onSelect(entries, !this.allSelected);
  }
}
