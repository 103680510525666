import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import logger from 'appkit/lib/logger';
import AuthRoute from 'appkit/routes/auth';
import EmberObject from '@ember/object';
import RSVP from 'rsvp';
import config from 'appkit/config/environment';

@classic
export default class DashboardRoute extends AuthRoute {
  @service('global-nav') globalNav;

  @service kustomer;
  @service beyondI18n;
  @service store;
  @service impact;
  @service accessibility;
  @service embed;
  @service featureFlag;
  @service currentUser;
  @service hog;
  @service forcePaymentMethod;

  title = 'Dynamic Pricing / Dashboard';

  activate() {
    this.globalNav.setTitle(this.title);
    try {
      this.kustomer.initClient();
    } catch (e) {
      logger.error('Error initializing Kustomer client. Error: ', e);
    }
    let email = this.currentUser.email;
    if (this.currentUser.isImpersonating) {
      email = this.currentUser.staffUser.email;
    }
    this.hog.activate(email);
  }

  @action
  willTransition(transition) {
    if (this.embed.tryDelegate(transition)) {
      transition.abort();
    }
  }
  async beforeModel(transition) {
    const beyondReactAppEnabled = await this.featureFlag.evaluate(
      'beyond-react-app',
      false
    );

    if (!this.embed.isEmbedded && beyondReactAppEnabled) {
      // Bypass redirect for invoices
      if (transition.to.name === 'dashboard.user.payment') {
        return;
      }

      let session;

      // Safari
      if (/apple/i.test(navigator.vendor)) {
        const getCookieValue = name =>
          document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
        const cookie = getCookieValue('appsession');
        try {
          session = JSON.parse(cookie);
        } catch {
          // Do nothing if it can't parse session
        }
      }

      session = session ?? this.currentUser;
      const { userId, email, token, ldEmail, ldSecureHash } = session;
      const pathname = top.document.location.pathname;
      const query = top.document.location.search;
      const hash = `#id=${userId}&email=${email}&token=${token}&ld_email=${ldEmail}&ld_secure_hash=${ldSecureHash}`;
      const url = `${config.APP.BEYOND_REACT_APP_URL}${pathname}${query}${hash}`;

      top.window.location.replace(url);
    }
  }

  async model() {
    let data = await RSVP.Promise.all([
      this.ajax._get('/api/accounts/user'),
      this.ajax._get('/api/pricing/booking-reviews/most-recent'),
      this.ajax._get('/api/accounts/saved_filters'),
      this.ajax._get('/api/accounts/credentials'),
      // Recover from error if metrics endpoint is not available
      this.ajax._get('/api/accounts/metrics').catch(() => ({ metrics: {} })),
      this.ajax._get('/api/accounts/all'),
      this.ajax._get('/api/accounts/listings'),
      this.ajax._get('/api/accounts/channel_listings'),
      this.ajax._get('/api/pricing/listings/revenue-goals'),
    ]).then(values => {
      const [
        user,
        bookingReviews,
        savedFilters,
        credentials,
        metrics,
        allAccounts,
        listings,
        channelListings,
        revenueGoals,
      ] = values;

      return Object.assign(
        {},
        user,
        bookingReviews,
        savedFilters,
        credentials,
        metrics,
        allAccounts,
        listings,
        channelListings,
        { revenueGoals: revenueGoals ?? [] }
      );
    });

    const model = EmberObject.create({});
    //////
    // Add in the store
    //////

    // User
    const user = this.bpStore.createRecord('user', data.user);
    model.set('user', user);

    this.currentUser.setProperties({
      sfAccountId: data.user.sfAccountId,
      staffUser: data.staffUser,
      isStaff: data.user.isStaff,
      isRelayEnabled: data.user.isRelayEnabled,
      pricingActivatedAt: data.user.pricingActivatedAt,
      insightsActivatedAt: data.user.insightsActivatedAt,
      sfAccountManager: data.user.sfAccountManager,
      sfAccountCustomerSuccessManager: data.user.sfAccountCustomerSuccessManager,
      firstListingMergedAt: data.user.firstListingMergedAt,
      hasEnabledListings: data.user.hasEnabledListings,
      hasUnseenBookingReview: data.hasUnseenBookingReview,
      hasUnseenAutoAcceptedBookingReview: data.hasUnseenAutoAcceptedBookingReview,
    });

    this.accessibility.applyAccessibilitySettings(data.user);

    /**
     * Sends customer identity information to Impact affiliate partner on each page load
     */
    try {
      this.impact.identifyUser(this.currentUser);
    } catch (e) {
      logger.error('Exception from impact.identifyUser', e);
    }

    this.beyondI18n.previousChosenLocale(user.locale);

    // Credential
    const credential = this.bpStore.createRecord('credential', data.credential);
    model.set('credential', credential);

    // Add global permission credential to current user controller
    this.currentUser.set('globalPermission', credential.globalPermissions);

    // Load all credentials for base / min price history
    model.set(
      'credentials',
      data.credentials.map(credential =>
        this.bpStore.createRecord('credential', credential)
      )
    );

    // Beyond Revenue Goals
    model.set('revenueGoals', data.revenueGoals);

    model.set(
      'listings',

      data.listings.map(listing => {
        listing['bookingReviews'] = [];
        return this.bpStore.createRecord('listing', listing);
      })
    );

    // Accounts
    model.set(
      'accounts',
      data.accounts.map(account => this.bpStore.createRecord('account', account))
    );

    // Channel Listings
    model.set(
      'channelListings',
      data.channelListings.map(channelListing =>
        this.bpStore.createRecord('channelListing', channelListing)
      )
    );

    // Saved Filter
    model.set(
      'savedFilters',
      data.savedFilters.map(savedFilter => {
        return this.bpStore.createRecord('savedFilter', savedFilter);
      })
    );

    // Push to ember-data store
    // Currently only used for Insights
    this.store.pushPayload({ savedFilters: data.savedFilters });

    // Booking Review
    model.set(
      'bookingReviews',
      data.bookingReviews.map(bookingReview => {
        return this.bpStore.createRecord('bookingReview', bookingReview);
      })
    );

    // Booking Review Listing
    model.set(
      'bookingReviewListings',
      data.bookingReviewListings.map(bookingReviewListing => {
        return this.bpStore.createRecord('bookingReviewListing', bookingReviewListing);
      })
    );

    //////
    // Set the relationships
    //////

    // Accounts
    model.get('accounts').forEach(account => {
      account.set(
        'channelListings',
        model
          .get('channelListings')
          .filter(cl => cl.managedAccountId === account.id)
          .map(cl => cl.id)
          .map(channelListingId => {
            const channelListing = this.bpStore.peekRecord(
              'channelListing',
              channelListingId
            );
            // Handle accounts where the current credential doesn't have access
            // to all the channel listings. Ideally we should strip these out
            // server side, but our serializers don't really support it.
            if (!channelListing) {
              return null;
            }
            channelListing.set('account', account);
            return channelListing;
          })
          .filter(el => el)
      );
    });

    // Listings
    model.get('listings').forEach(listing => {
      // Channel Listings
      let channelListings = listing
        .get('channelListingsIds')
        .map(channelListingId => {
          const channelListing = this.bpStore.peekRecord(
            'channelListing',
            channelListingId
          );
          channelListing.set('masterListing', listing);
          return channelListing;
        })
        .filter(cl => cl)
        // Always put primary channel listing first so we can do a quick lookup / nicer
        // display of icons on the dash.
        .sort((a, b) => {
          if (a.id === listing.primaryChannelListingId) {
            return -1;
          }
          return a.id - b.id;
        });
      listing.set('channelListings', channelListings);

      const primaryChannelListingId = listing.get('primaryChannelListingId');
      const primaryChannelListing = this.bpStore.peekRecord(
        'channelListing',
        primaryChannelListingId
      );
      listing.set('primaryChannelListing', primaryChannelListing);

      // Booking Review Listings
      let bookingReviewListings = this.bpStore
        .all('bookingReviewListing')
        .filter(brl => brl.listingId === listing.id);

      listing.set('bookingReviewListings', bookingReviewListings);
    });

    // Booking Reviews
    model.get('bookingReviews').forEach(bookingReview => {
      // Booking Review
      bookingReview.set(
        'bookingReviewListings',
        this.bpStore.all('bookingReviewListing')
      );
    });

    // Set health score defaults
    model.get('listings').forEach(listing => {
      // Set default values for edge cases
      listing.set('idealSeven', 85);
      listing.set('idealFourteen', 71);
      listing.set('idealThirty', 52);
      listing.set('idealSixty', 33);
      listing.set('idealNinety', 23);

      // Set ideal health score values per listing
      let metrics = data.metrics[listing.id];
      if (metrics) {
        listing.set('idealSeven', metrics.idealSeven);
        listing.set('idealFourteen', metrics.idealFourteen);
        listing.set('idealThirty', metrics.idealThirty);
        listing.set('idealSixty', metrics.idealSixty);
        listing.set('idealNinety', metrics.idealNinety);
      }

      const revenueGoal =
        data.revenueGoals.length > 0
          ? data?.revenueGoals?.find(rg => rg.listingId === listing.id)
          : null;

      listing.set('revenueGoal', revenueGoal?.target ?? '-');
      listing.set(
        'revenueProgress',
        revenueGoal?.target
          ? Math.round((revenueGoal?.revenue * 100) / revenueGoal?.target)
          : '-'
      );
    });

    return model;
  }

  afterModel({ user }) {
    this.forcePaymentMethod.setup(user);
  }
}
