import BaseModel from 'appkit/bp-models/base_model';

export default BaseModel.extend({
  name: '',
  market: null,
  latitude: null,
  longitude: null,

  init() {
    this._super(arguments);
    this.set('neighborhoods', this.neighborhoods ?? []);
  },
});
