import classic from 'ember-classic-decorator';
import { tracked } from '@glimmer/tracking';
import { assign } from '@ember/polyfills';
import Service from '@ember/service';
import { inject as service } from '@ember/service';

@classic
export default class AlertService extends Service {
  model = [];
  /** Modifier class for the container of the messages **/
  @tracked cssPanelClass = '';
  @service intl;

  // {_type: 'success', message: 'a success message' },
  // {_type: 'error', message: 'an error message' }

  success(message, options = {}) {
    let params = assign({}, options, {
      _type: 'success',
      message: message,
    });
    this.model.pushObject(params);
  }

  error(message, options = {}) {
    const translatedMessage = this.intl.exists(message)
      ? this.intl.t(message)
      : message;

    let params = assign({}, options, {
      _type: 'error',
      message: translatedMessage,
    });
    this.model.pushObject(params);
  }

  info(message, options = {}) {
    let params = assign({}, options, {
      _type: 'info',
      message: message,
    });
    this.model.pushObject(params);

    this.setCssPanelClass(options);
    return { dismiss: () => this.dismiss(params) };
  }

  dismiss(entry) {
    this.model.removeObject(entry);
  }

  setCssPanelClass(options) {
    this.cssPanelClass = options.cssPanelClass ? options.cssPanelClass : '';
  }

  clearPanelModifier(delay = 30000) {
    setTimeout(() => (this.cssPanelClass = ''), delay);
  }

  /**
   * A notifier for async API operations, that displays loading/loaded/error states to the user
   * Automatically dismisses loading notifications after loaded or error.
   *
   */
  async asyncOperationNotifier(
    operation,
    loadingi18nKey, // this is always in the client side
    successI18nKey = null,
    errorI18nKey = null
  ) {
    const loadingSnackbar = this.displayLoadingNotification(loadingi18nKey);
    try {
      const successMessage = await operation;
      loadingSnackbar?.dismiss();
      this.displaySuccessNotification(successI18nKey || successMessage);
      this.clearPanelModifier();
      return true;
    } catch (e) {
      const errorMessage = e.message;
      loadingSnackbar?.dismiss();
      this.displayErrorNotification(errorI18nKey || errorMessage);
      this.clearPanelModifier();
      return false;
    }
  }

  displayLoadingNotification(loadingMessagei18Key) {
    const loadingNotification = this.getTranslatedMessage(loadingMessagei18Key);

    if (loadingNotification) {
      return this.info(loadingNotification, {
        timeout: 50000, // high times are ok, now that we can dismiss them when the operation is complete
        cssPanelClass: '-mt-16',
      });
    }
  }
  displayErrorNotification(message) {
    const errorNotification = this.getTranslatedMessage(message);
    if (errorNotification) {
      this.error(errorNotification, { timeout: 6000 });
    }
  }
  displaySuccessNotification(message) {
    const successNotification = this.getTranslatedMessage(message);

    if (successNotification) {
      this.success(successNotification, { timeout: 10000 });
    }
  }

  getTranslatedMessage(message) {
    if (message) {
      const translatedMessage = this.intl.t(message);
      const wasAlreadyATranslation = translatedMessage.includes('Missing translation');
      return wasAlreadyATranslation ? message : translatedMessage;
    }
    return null;
  }
}
