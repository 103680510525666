import classic from 'ember-classic-decorator';
import Service from '@ember/service';
import EmberObject from '@ember/object';
import { getOwner } from '@ember/application';

@classic
export default class BpStoreService extends Service {
  _records = null;

  init() {
    super.init();
    this.set('_records', EmberObject.create({}));
    return this;
  }

  types() {
    let records = this._records;
    let out = [];
    for (let key of Object.keys(records)) {
      out.push(records[key].type);
    }
    return out;
  }

  _all(typeKey) {
    return this.get(`_records.${typeKey}.records`) || {};
  }
  all(typeKey) {
    return Object.values(this._all(typeKey));
  }

  createRecord(typeKey, attr = {}) {
    let type = getOwner(this).lookup('bp-model:' + typeKey);

    if (!type) {
      throw new Error("Can't find model: " + typeKey);
    }

    // Sorry this is ugly. JS is dumb and casts all object keys to strings, so
    // we can't put the type as the key.
    let recordCache = this.get(`_records.${typeKey}`);
    if (!recordCache) {
      recordCache = EmberObject.create({ type: type, records: {} });
      this.set(`_records.${typeKey}`, recordCache);
    }

    // Check for an existing record and update that if we have it. This makes
    // sure we update potentially outdated info when we return to the dashboard
    // and reload listings / account details that likely already exist in the
    // store.
    //
    // This happens especially after reconnecting an account and then returning
    // to the dashboard.
    let record = this.peekRecord(typeKey, attr.id);
    if (record) {
      record.setProperties(attr);
      record.markClean();
    } else {
      record = type.create(attr);
      record.set('container', this.container);
      recordCache.get('records')[record.id] = record;
    }

    return record;
  }

  unloadRecord(typeKey, model) {
    let recordCache = this.get(`_records.${typeKey}`);
    if (recordCache) {
      delete recordCache.get('records')[model.id];
    }
  }

  unloadAll(typeKey) {
    this.set(`_records.${typeKey}.records`, {});
  }

  // Get a record by a given type and ID without triggering a fetch.
  // Works like the original `peekRecord` method from ember data:
  // http://emberjs.com/api/data/classes/DS.Store.html#method_peekRecord)
  peekRecord(typeKey, id) {
    return this._all(typeKey)[id];
  }
}
