import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Bp::HighCharts @chartOptions={{this.chartOptions}} @content={{this.content}} as |dc|>\n  <Insights::Charts::Base::Extend\n    class='chart-base-container flex w-full overflow-hidden'\n    @chart={{dc.chart}}\n    @loading={{@loading}}\n    @redraw={{@redraw}}\n  >\n    {{#unless this.compact}}\n      <div>\n        <Insights::Charts::Base::Legends @chart={{dc.chart}} />\n      </div>\n    {{/unless}}\n    <div class='flex-1 overflow-hidden'>\n      <dc.dom />\n    </div>\n  </Insights::Charts::Base::Extend>\n</Bp::HighCharts>", {"contents":"<Bp::HighCharts @chartOptions={{this.chartOptions}} @content={{this.content}} as |dc|>\n  <Insights::Charts::Base::Extend\n    class='chart-base-container flex w-full overflow-hidden'\n    @chart={{dc.chart}}\n    @loading={{@loading}}\n    @redraw={{@redraw}}\n  >\n    {{#unless this.compact}}\n      <div>\n        <Insights::Charts::Base::Legends @chart={{dc.chart}} />\n      </div>\n    {{/unless}}\n    <div class='flex-1 overflow-hidden'>\n      <dc.dom />\n    </div>\n  </Insights::Charts::Base::Extend>\n</Bp::HighCharts>","moduleName":"appkit/components/relay/dcm-card/chart.hbs","parseOptions":{"srcName":"appkit/components/relay/dcm-card/chart.hbs"}});
import Component from '@glimmer/component';
import moment from 'moment';
import { momentFormatMonth, chartColors, toGradient } from 'appkit/lib/insights/charts';
import { inject as service } from '@ember/service';

export default class DcmChartComponent extends Component {
  @service intl;

  get compact() {
    return this.args.compact ?? false;
  }

  get series() {
    return this.args.series ?? [];
  }

  get locale() {
    return this.args.locale;
  }

  get chartOptions() {
    const compact = this.compact;
    return {
      chart: {
        height: compact ? '46px' : '340px',
        type: 'areaspline',
        margin: compact ? [0, 0, 0, 0] : undefined,
      },
      title: {
        text: null,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          enabled: !compact,
          style: {
            font: '14px Europa',
          },
          formatter() {
            // Display xAxix labels as months for all charts
            const _moment = moment.utc(this.value);
            return momentFormatMonth(_moment, this.locale);
          },
        },
        tickColor: 'transparent',
        tickInterval: 30 * 24 * 3600 * 1000, // 1 month
        gridLineColor: 'rgba(185, 202, 210, 0.4)',
        minorGridLineColor: 'rgba(185, 202, 210, 0.4)',
        crosshair: true,
      },
      yAxis: {
        labels: {
          enabled: !compact,
          style: {
            font: '14px Europa',
          },
          formatter() {
            return this.value * 100 + '%';
          },
        },
        title: {
          text: null,
        },
        gridLineColor: 'transparent',
        tickColor: 'transparent',
      },
      plotOptions: {
        series: {
          showInNavigator: true,
        },
      },
      tooltip: {
        outside: true,
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0,
        },
        valueDecimals: 2,
        shared: true,
        formatter: function () {
          const point = moment.utc(this.points[0].x);
          const headerLabel = `${point.format('ll')} / ${point.diff(
            moment.utc(),
            'days'
          )} days out:`;

          // Format the tooltip
          const pointLabels = (this.points || []).map(point => {
            const yLabel = Number((point.y * 100).toFixed(2)) + '%';
            const s = point.series;
            return `<div class='flex items-center'>
              <div class="w-2 h-2 rounded-full mr-2" style="background-color: ${
                s.color
              }"></div>
              <span class='text-gray-600'>${
                s.name.split('(')[0]
              }: </span><b>${yLabel}</b>
             </div>`;
          });

          return `<div class='text-gray-800'><p class='header pb-1'>${headerLabel}</p><div class='text-xs flex flex-col'>${pointLabels.join(
            ''
          )}</div></div>`;
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    };
  }

  get content() {
    const content = this.series.map((s, idx) => ({
      legendIndex: idx,
      name: s.name,
      _groupName: this.intl.t('glossary.neighbourhoods') + ':',
      data: s.data.map(d => ({
        y: d.markup,
        name: moment.utc(d.date).valueOf(),
      })),
      marker: {
        symbol: 'circle',
        lineColor: null,
        lineWidth: 1,
      },
      pointStart: moment.utc(s.data[0]?.date).valueOf(),
      pointInterval: 1,
      pointIntervalUnit: 'day',
      events: {},
      lineColor: chartColors[idx % 5][0],
      dashStyle: 'Solid',
      color: chartColors[idx % 5][0],
      fillColor: toGradient(chartColors[idx % 5]),
      visible: true,
    }));
    return content;
  }
}
