import Controller from '@ember/controller';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { saveAs } from 'file-saver';

class Errors {
  @tracked _cache = {};

  set(key, value) {
    this._cache[key] = value;
    this._cache = this._cache;
  }

  get(key) {
    return this._cache[key];
  }
}

export default class BulkUpdateController extends Controller {
  @tracked saving = false;
  @tracked websiteUrl = '';
  @tracked errors = new Errors();
  @tracked responseData = A([]);
  @tracked asFile = false;

  get hasErrors() {
    return Object.keys(this.errors).length > 0;
  }

  validate() {
    this.errors = new Errors();

    if (!this.websiteUrl) {
      this.errors.websiteUrl = 'Website is required';
    } else {
      let url = this.websiteUrl;
      if (!url.startsWith('http')) {
        url = `http://${url}`;
      }
      try {
        new URL(url);
      } catch (err) {
        this.errors.websiteUrl = `Can't parse URL: ${err}`;
      }
    }

    return Object.keys(this.errors).length == 0;
  }

  @action
  async submit() {
    if (!this.validate()) {
      return;
    }
    this.saving = true;
    this.responseData = A([]);
    const url = `/api/internal-tools/website-provider`;

    let websiteUrl = this.websiteUrl.trim();
    if (!websiteUrl.startsWith('http')) {
      websiteUrl = `http://${websiteUrl}`;
    }
    const params = {
      websiteUrl: websiteUrl,
      asFile: this.asFile,
    };

    let response;
    try {
      response = await this.ajax._post(url, params);
      if (this.asFile) {
        const blob = new Blob([JSON.stringify(response)]);
        saveAs(blob, 'providers.json');
      }
    } catch (errors) {
      let message = errors[0].source?.message || errors[0].message;
      let errorMessage = `Error: ${errors[0].title} - ${message}`;
      this.responseData = A([errorMessage]);
      return;
    } finally {
      this.saving = false;
    }
    // this.responseData = JSON.stringify(response);
    this.responseData = response;
  }

  @action
  onAsFileChanged() {
    this.asFile = !this.asFile;
  }
}
