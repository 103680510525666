import { helper } from '@ember/component/helper';

export function is_included([value, array, word]) {
  if (array === undefined || array === null) {
    return '';
  }
  if (!Array.isArray(value)) {
    value = [value];
  }
  if (array.some(v => value.includes(v))) {
    return word;
  }

  return '';
}

export default helper(is_included);
