import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import logger from 'appkit/lib/logger';
import Ember from 'ember';

@classic
export default class ApplicationRoute extends Route {
  @service
  intl;

  @service
  beyondI18n;

  @action
  willTransition(transition) {
    if (transition.to.name === 'index') {
      this.transitionTo('login');
    }
  }

  beforeModel(transition) {
    if (transition.to.name === 'index') {
      this.transitionTo('login');
    }
  }

  afterModel() {
    const locale = this.beyondI18n.getStoredLocale();
    const localeSuggestion = this.beyondI18n.getMainLocaleSuggestion();

    if (localeSuggestion) {
      // Modal in dashboard will open with a suggestion to change the app locale to match the browser locale,
      // if we got enought coverage. App will be shown in the suggested language during that time.
      // - Reversible if user declines the offer.
      // - Triggers a server update if the user accepts
      this.currentUser.set('updateLanguage', true);
      this.currentUser.set('locale', localeSuggestion);
      this.currentUser.set('initialLocale', locale);
    } else {
      this.beyondI18n.initializeAppLocale();
    }
  }

  @action
  changeLayout(templateName) {
    this.render(templateName);
  }

  @action
  refreshCurrentRoute() {
    this.refresh();
  }

  @action
  error(error, transition, route) {
    logger.error('application route error', error, transition, route);

    // Assume we only have one error when dealing with server errors on
    // navigation.
    if (Array.isArray(error)) {
      error = error[0];
    }

    if (typeof error === 'object' && error.status === '401') {
      // Model promise rejects with 401 - unauthorized. Happens when we
      // invalidate tokens / delete users / user messes with localStorage
      logger.error('No user. Redirecting.');
      this.currentUser.clear();

      let loginController = this.controllerFor('login');
      loginController.set('attemptedTransition', transition);
      this.transitionTo('login');

      return false;
    }

    // This will trigger Sentry
    // https://github.com/emberjs/ember.js/issues/18533
    Ember.onerror?.(error);

    // Make sure the error bubbles out of the router
    return true;
  }
}
