import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class InvalidListingsRoute extends AuthRoute {
  model() {
    let url = `/api/listings/invalid`;
    return this.ajax._get(url);
  }
}
