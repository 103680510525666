import Service, { inject as service } from '@ember/service';
import ENV from 'appkit/config/environment';

export default class KustomerService extends Service {
  @service ajax;
  @service currentUser;

  async initClient() {
    if (ENV.environment !== 'production') {
      return;
    }

    if (window.Kustomer === undefined) {
      console.warn('Kustomer not loaded');
      return;
    }

    if (!this.currentUser.token) return;

    const data = await this.ajax._get('/api/kustomer/get_jwt_token');

    window.Kustomer.start(
      {
        brandId: '603e4c1d539f9952be927d43',
      },
      function () {
        window.Kustomer.login(
          {
            jwtToken: data.token,
          },
          function (_loginCallbackResponse, error) {
            if (error) {
              console.log('Kustomer session not started', error);
            }
          }
        );
      }
    );
  }

  logout() {
    if (ENV.environment !== 'production') {
      return;
    }

    if (window.Kustomer === undefined) {
      console.warn('Kustomer not loaded');
      return;
    }

    window.Kustomer.logout((_logoutCallbackResponse, error) => {
      if (error) {
        console.log('Something went wrong logging out Kustomer session', error);
      }
    });
  }
}
