import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';

function chartSeries(name, data, visible, tooltip, axis) {
  let series = {
    name: name,
    data: data,
  };
  if (tooltip === '$') {
    series.tooltip = { valueDecimals: 2, valuePrefix: '$' };
  }
  if (tooltip === '%') {
    series.tooltip = { valueDecimals: 2, valueSuffix: '%' };
  }
  if (!visible) {
    series.visible = false;
  }
  if (axis) {
    series.yAxis = axis;
  }
  return series;
}

@classic
export default class BeyondAirbnbAvailabilityController extends Controller {
  compareId = null;
  loadingCompareData = false;
  comparePct = [];

  chartOptions = {
    rangeSelector: {
      selected: 5,
    },

    yAxis: [
      {
        title: {
          text: 'Number',
        },
      },
      {
        labels: {
          format: '{value}%',
        },
        max: 100,
        title: {
          text: 'Percent',
        },
        opposite: false,
      },
    ],
    legend: {
      padding: 8,
      enabled: true,
      itemDistance: 10,
    },
    navigator: { baseSeries: 0 },
    plotOptions: {
      series: {
        showInNavigator: true,
      },
    },
  };

  @computed(
    // eslint-disable-next-line ember/use-brace-expansion
    'model.beyondAvailabilityNum',
    'model.beyondAvailabilityPct',
    'model.beyondAvailabilityEnabledPct',
    'model.beyondTotalEnabledNum',
    'model.beyondTotalListedNum',
    'model.airbnbAvailabilityPct',
    'model.airbnbAvailabilityPlusCalendarEndAndDetectBlocksPct'
  )
  get chartData() {
    let out = [
      chartSeries(
        'Beyond Available&Listed (num)',
        this.get('model.beyondAvailabilityNum'),
        true
      ),
      {
        name: 'Beyond Available&Listed (pct)',
        id: 'availability_pct',
        data: this.get('model.beyondAvailabilityPct'),
        yAxis: 1,
        tooltip: { valueDecimals: 2, valueSuffix: '%' },
      },
      chartSeries(
        'Beyond Available&Enabled (pct)',
        this.get('model.beyondAvailableEnabledPct'),
        true,
        '%',
        1
      ),
      chartSeries(
        'Beyond Total Enabled (num)',
        this.get('model.beyondTotalEnabledNum'),
        true
      ),
      chartSeries(
        'Beyond Total Listed (num)',
        this.get('model.beyondTotalListedNum'),
        true
      ),
      chartSeries(
        'Airbnb Listed (pct)',
        this.get('model.airbnbAvailabilityPct'),
        true,
        '%',
        1
      ),
      chartSeries(
        'Airbnb Calendar End and Detect Block (pct)',
        this.get('model.airbnbAvailabilityPlusCalendarEndAndDetectBlocksPct'),
        true,
        '%',
        1
      ),
    ];

    return out;
  }

  @action
  compareMarket(_id) {
    throw Error('Not implemented');
  }

  @action
  compareCluster(_id) {
    throw Error('Not implemented');
  }
}
