import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

class Errors {
  @tracked _cache = {};

  set(key, value) {
    this._cache[key] = value;
    this._cache = this._cache;
  }

  get(key) {
    return this._cache[key];
  }
}

export default class ManualOverridesController extends Controller {
  @tracked saving = false;
  @tracked listingId = '';
  @tracked selectedDate = moment();
  @tracked errors = new Errors();
  @tracked responseData = '';

  validate() {
    this.errors = new Errors();

    if (!this.listingId) {
      this.errors.listingId = 'Listing ID is required';
    }
    if (!this.selectedDate) {
      this.errors.selectedDate = 'A date is required';
    }

    return Object.keys(this.errors).length == 0;
  }

  get hasErrors() {
    return Object.keys(this.errors).length > 0;
  }

  @action
  async submit() {
    if (!this.validate()) {
      return;
    }

    this.saving = true;
    const date = this.selectedDate.format('YYYY-MM-DD');
    const baseUrl = `/api/internal-tools/manual-overrides`;
    const url = baseUrl + `?listingId=${this.listingId}&date=${date}`;

    try {
      const responseData = await this.ajax._get(url);
      this.responseData = JSON.stringify(responseData['overrides'], null, 2);
    } catch (errors) {
      let text = `Error: ${errors[0].title} - ${errors[0].message}`;
      this.responseData = text;
      return;
    } finally {
      this.saving = false;
    }
  }
}
