import classic from 'ember-classic-decorator';
import { tracked } from '@glimmer/tracking';
import { observes } from '@ember-decorators/object';
import { action, computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { readOnly, oneWay } from '@ember/object/computed';
import Controller from '@ember/controller';
import { CurrencyUtil } from 'appkit/lib/currency';
import { getOwner } from '@ember/application';
import { STATUS_MAPPING } from 'appkit/bp-models/booking_review_listing';
import moment from 'moment';
import { later } from '@ember/runloop';
import { inject as controller } from '@ember/controller';
import { CHANNEL_CONFIG } from 'appkit/lib/channel_configuration';

function percentageDirection(percentage) {
  if (percentage === 1) {
    return '';
  } else if (percentage > 1) {
    return 'increased';
  } else if (percentage < 1) {
    return 'decreased';
  } else {
    return '';
  }
}

@classic
export default class RatesController extends Controller {
  queryParams = [{ enablePricingOnArrival: { type: 'boolean' } }];
  @service alert;
  @service userleap;
  @service('booking-review') bookingReview;
  @service intl;
  @service forcePaymentMethod;
  @controller('dashboard.pricing.index') pricingController;

  @tracked enablePricingOnArrival = null;
  @tracked basePrice = null;
  @tracked minPrice = null;
  @tracked suggestedBasePrice = null;
  @tracked suggestedMinPrice = null;
  finalBasePrice = null;
  finalMinPrice = null;
  enablePricesUponSave = false;
  notes = null;
  bookingAnalysis = null;
  recommendations = null;
  basePriceInputFocus = false;
  minPriceInputFocus = false;
  percentageForBasePrice = 1;
  percentageForMinPrice = 1;
  @tracked usePercentageForBasePrice = false;
  @tracked usePercentageForMinPrice = false;
  @tracked useSuggestedPercentageForBasePrice = false;
  @tracked useSuggestedPercentageForMinPrice = false;
  @tracked useFinalPercentageForBasePrice = false;
  @tracked useFinalPercentageForMinPrice = false;
  rejectingSuggestion = false;
  editingApprovedListing = false;
  editingRejectedListing = false;
  @tracked canceledEditingRejectedListing = false;
  @tracked valueIsBelowMinBasePrice = false;
  @tracked valueIsBelowMinMinPrice = false;

  @readOnly('model.listing._dirty.basePrice') dirtyBasePrice;
  @readOnly('model.listing._dirty.minPrice') dirtyMinPrice;

  @readOnly('model.listing.mostRecentBookingReview.initialBasePrice')
  initialBasePrice;
  @readOnly('model.listing.mostRecentBookingReview.initialMinPrice')
  initialMinPrice;
  @readOnly('model.listing.mostRecentBookingReview._dirty.suggestedBasePrice')
  dirtySuggestedBasePrice;
  @readOnly('model.listing.mostRecentBookingReview._dirty.suggestedMinPrice')
  dirtySuggestedMinPrice;
  @readOnly('model.listing.mostRecentBookingReview._dirty.finalBasePrice')
  dirtyFinalBasePrice;
  @readOnly('model.listing.mostRecentBookingReview._dirty.finalMinPrice')
  dirtyFinalMinPrice;

  @oneWay('currentUser.isBookingReviewSubmitterEnabled')
  isBookingReviewSubmitterEnabled;

  @computed('model.listing.currency')
  get currencySymbol() {
    return CurrencyUtil.getSymbol(this.model.listing.currency);
  }

  @computed(
    'basePrice',
    'minPrice',
    'notes',
    'dirtyBasePrice',
    'dirtyMinPrice',
    'suggestedBasePrice',
    'suggestedMinPrice',
    'dirtySuggestedBasePrice',
    'dirtySuggestedMinPrice',
    'enablePricesUponSave',
    'bookingReview.displayBookingReviewSubHeader'
  )
  get canSave() {
    let listing = this.get('model.listing');
    const basePriceIsDirty = this.basePrice !== listing.get('_dirty.basePrice');
    const minPriceIsDirty = this.minPrice !== listing.get('_dirty.minPrice');
    let notesIsDirty;
    if (this.bookingReview.displayBookingReviewSubHeader) {
      notesIsDirty = this.notes;
    } else {
      notesIsDirty = this.notes !== listing.get('_dirty.notes');
    }

    if (this.basePrice < 10 || this.minPrice < 5) return false;

    return (
      basePriceIsDirty || minPriceIsDirty || notesIsDirty || this.enablePricesUponSave
    );
  }

  @computed(
    'notes',
    'model.listing._dirty.{basePrice,minPrice,bookingAnalysis,recommendations}'
  )
  get canSaveRejection() {
    return !!this.notes;
  }

  @computed('percentageForBasePrice')
  get percentageForBasePriceDisplay() {
    const rounded = Math.round(this.percentageForBasePrice * 100 - 100);
    return Math.abs(rounded);
  }

  @computed('percentageForMinPrice')
  get percentageForMinPriceDisplay() {
    const rounded = Math.round(this.percentageForMinPrice * 100 - 100);
    return Math.abs(rounded);
  }

  @computed('percentageForBasePrice')
  get percentageForBasePriceDirection() {
    return percentageDirection(this.percentageForBasePrice);
  }

  @computed('percentageForMinPrice')
  get percentageForMinPriceDirection() {
    return percentageDirection(this.percentageForMinPrice);
  }

  @computed('basePrice', 'model.listing._dirty.{basePrice}')
  get basePriceChanged() {
    return this.basePrice !== this.get('model.listing._dirty.basePrice');
  }

  @computed('minPrice', 'model.listing._dirty.{minPrice}')
  get minPriceChanged() {
    return this.minPrice !== this.get('model.listing._dirty.minPrice');
  }

  @computed(
    // eslint-disable-next-line ember/use-brace-expansion
    'bookingReview.isRunningReview',
    'model.listing.mostRecentBookingReview.status',
    'model.listing.canEdit',
    'rejectingSuggestion',
    'editingRejectedListing',
    'currentUser.isBookingReviewSubmitterEnabled'
  )
  get canDisplayNoteField() {
    if (!this.model.listing.canEdit) {
      return false;
    }

    const isSuggestionCompleted = get(
      this,
      'model.listing.mostRecentBookingReview.isSuggestionCompleted'
    );
    const submitterCase = this.bookingReview.displayBookingReviewSubHeader;
    const isAlreadyReviewed = get(
      this,
      'model.listing.mostRecentBookingReview.isAlreadyReviewed'
    );
    const approverCase =
      (isSuggestionCompleted || isAlreadyReviewed) &&
      this.mostRecentBookingReviewListingIsInCurrentBatch &&
      this.hasUncompletedBookingReview;

    if (!submitterCase && !approverCase) {
      return true;
    }

    // submitter flow - always display note field
    if (this.bookingReview.isRunningReview) {
      return true;
    }

    // reject flow - note is required for rejecting
    if (this.model.listing.mostRecentBookingReview && this.rejectingSuggestion) {
      return true;
    }

    // editing flow - note is required for editing rejected review
    if (this.model.listing.mostRecentBookingReview && this.editingRejectedListing) {
      return true;
    }

    return false;
  }

  @computed(
    'bookingReview.isRunningReview',
    'currentUser.isBookingReviewSubmitterEnabled'
  )
  get isSubmitterCase() {
    return this.bookingReview.isRunningReview;
  }

  @computed(
    'model.listing.permissions',
    'model.listing.mostRecentBookingReview.{isAlreadyReviewed,isSuggestionCompleted}',
    'rejectingSuggestion',
    'hasSubmittedReviews',
    'hasUncompletedBookingReview',
    'mostRecentBookingReviewListingIsInCurrentBatch',
    'editingApprovedListing',
    'editingRejectedListing'
  )
  get isPriceInputDisabled() {
    if (this.get('model.listing.permissions') === 'view') {
      return true;
    }

    if (
      this.get('model.listing.mostRecentBookingReview.isSuggestionCompleted') &&
      !this.rejectingSuggestion
    ) {
      return true;
    }

    if (
      (this.hasSubmittedReviews || this.hasUncompletedBookingReview) &&
      this.model.listing.mostRecentBookingReview?.isAlreadyReviewed &&
      !this.editingApprovedListing &&
      !this.editingRejectedListing &&
      this.mostRecentBookingReviewListingIsInCurrentBatch
    ) {
      return true;
    }

    return false;
  }

  @computed(
    'model.listing.{permissions,mostRecentBookingReview.isSuggestionCompleted}',
    'currentUser.isBookingReviewSubmitterEnabled'
  )
  get hideActionFooter() {
    if (this.get('model.listing.permissions') === 'view') {
      return true;
    }
    if (
      this.get('model.listing.mostRecentBookingReview.isSuggestionCompleted') &&
      this.get('currentUser.isBookingReviewSubmitterEnabled')
    ) {
      return true;
    }
    return false;
  }

  @computed('model.listing.bookingReviews.[]')
  get bookingReviews() {
    this.model.listing.bookingReviews;
    return [];
  }

  @computed('model.bookingReviewListings.@each.status')
  get hasSubmittedReviews() {
    const listingsSubmittedReview = this.get('model.bookingReviewListings').filter(
      brl => brl.isSuggestionCompleted
    );
    return !!listingsSubmittedReview.length;
  }

  @computed('model.bookingReviews.[]')
  get mostRecentBookingReview() {
    if (!this.model.bookingReviews.length) {
      return null;
    }

    const bookingReviewsSorted = this.model.bookingReviews
      .map(br => {
        const date = moment(br.createdAt).format('YYYY-MM-DD HH:mm:ss');
        br['createdAt'] = date;
        return br;
      })
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return bookingReviewsSorted[0];
  }

  @computed(
    'hasUncompletedBookingReview',
    'mostRecentBookingReview.id',
    'model.listing.mostRecentBookingReview.bookingReviewId'
  )
  get mostRecentBookingReviewListingIsInCurrentBatch() {
    if (!this.hasUncompletedBookingReview) {
      return null;
    }
    if (!this.model.listing.mostRecentBookingReview) {
      return null;
    }

    const mostRecentBookingReviewId = get(this, 'mostRecentBookingReview.id');
    const mostRecentBookingReviewListing = get(
      this,
      'model.listing.mostRecentBookingReview'
    );

    return (
      mostRecentBookingReviewId ===
      get(mostRecentBookingReviewListing, 'bookingReviewId')
    );
  }

  @computed('mostRecentBookingReview.completedAt')
  get hasUncompletedBookingReview() {
    return !this.mostRecentBookingReview?.completedAt;
  }

  // base price

  @computed(
    'showsFinalBasePrice',
    'showsSuggestedBasePrice',
    'finalBasePrice',
    'suggestedBasePrice',
    'basePrice',
    'mostRecentBookingReviewListingIsInCurrentBatch',
    'mostRecentBookingReview.completedAt'
  )
  get basePriceBasedOnReviewStatus() {
    if (this.showsFinalBasePrice) {
      return this.finalBasePrice;
    } else if (this.showsSuggestedBasePrice) {
      return this.suggestedBasePrice;
    } else {
      return this.basePrice;
    }
  }

  // min price

  @computed(
    'showsFinalMinPrice',
    'showsSuggestedMinPrice',
    'finalMinPrice',
    'suggestedMinPrice',
    'minPrice',
    'mostRecentBookingReviewListingIsInCurrentBatch',
    'mostRecentBookingReview.completedAt'
  )
  get minPriceBasedOnReviewStatus() {
    const listing = this.get('model.listing');
    if (this.showsFinalMinPrice) {
      return listing.minPrice;
    } else if (this.showsSuggestedMinPrice) {
      return this.suggestedMinPrice;
    } else {
      return this.minPrice;
    }
  }

  // suggested base price

  @readOnly('model.listing.mostRecentBookingReview.suggestedBasePricePercentage')
  suggestedBasePricePercentage;

  @computed(
    'hasUncompletedBookingReview',
    'bookingReview.{isRunningReview,isBookingReviewActive}',
    'mostRecentBookingReviewListingIsInCurrentBatch',
    'model.listing.mostRecentBookingReview.suggestedBasePrice',
    'currentUser.isBookingReviewSubmitterEnabled'
  )
  get showsSuggestedBasePrice() {
    return (
      this.hasUncompletedBookingReview &&
      (this.bookingReview.isRunningReview ||
        this.bookingReview.isBookingReviewActive) &&
      this.mostRecentBookingReviewListingIsInCurrentBatch &&
      this.model.listing.mostRecentBookingReview?.suggestedBasePrice
    );
  }

  @computed(
    'model.listing.mostRecentBookingReview.{suggestedBasePricePercentage,suggestedBasePrice}',
    'dirtyBasePrice',
    'hasUncompletedBookingReview'
  )
  get showsSuggestedBasePricePercentage() {
    const mostRecentBookingReview = this.model.listing.mostRecentBookingReview;
    return (
      this.hasUncompletedBookingReview &&
      mostRecentBookingReview?.suggestedBasePrice != null &&
      this.dirtyBasePrice != mostRecentBookingReview.suggestedBasePrice &&
      mostRecentBookingReview.suggestedBasePricePercentage
    );
  }

  @computed('suggestedBasePricePercentage')
  get suggestedBasePriceDirection() {
    return percentageDirection(this.suggestedBasePricePercentage);
  }

  // final base price

  @readOnly('model.listing.mostRecentBookingReview.finalBasePricePercentage')
  finalBasePricePercentage;

  @computed(
    'hasUncompletedBookingReview',
    'mostRecentBookingReviewListingIsInCurrentBatch',
    'model.listing.mostRecentBookingReview.finalBasePrice'
  )
  get showsFinalBasePrice() {
    return (
      this.hasUncompletedBookingReview &&
      this.mostRecentBookingReviewListingIsInCurrentBatch &&
      this.model.listing.mostRecentBookingReview?.finalBasePrice
    );
  }

  @computed(
    'model.listing.mostRecentBookingReview.{finalBasePricePercentage,finalBasePrice}',
    'dirtyBasePrice',
    'hasUncompletedBookingReview'
  )
  get showsFinalBasePricePercentage() {
    const mostRecentBookingReview = this.model.listing.mostRecentBookingReview;
    return (
      this.hasUncompletedBookingReview &&
      mostRecentBookingReview?.finalBasePrice != null &&
      this.dirtyBasePrice != mostRecentBookingReview.finalBasePrice &&
      mostRecentBookingReview.finalBasePricePercentage
    );
  }

  @computed('finalBasePricePercentage')
  get finalBasePriceDirection() {
    return percentageDirection(this.finalBasePricePercentage);
  }

  // suggested min price

  @readOnly('model.listing.mostRecentBookingReview.suggestedMinPricePercentage')
  suggestedMinPricePercentage;

  @computed(
    'model.listing.mostRecentBookingReview.{suggestedMinPrice,isSuggestionCompleted}',
    'bookingReview.{isRunningReview,isBookingReviewActive}',
    'hasUncompletedBookingReview',
    'currentUser.isBookingReviewSubmitterEnabled'
  )
  get showsSuggestedMinPrice() {
    return (
      this.hasUncompletedBookingReview &&
      (this.bookingReview.isRunningReview ||
        this.bookingReview.isBookingReviewActive) &&
      this.mostRecentBookingReviewListingIsInCurrentBatch &&
      this.model.listing.mostRecentBookingReview?.suggestedMinPrice
    );
  }

  @computed(
    'model.listing.mostRecentBookingReview.{suggestedMinPricePercentage,suggestedMinPrice}',
    'dirtyMinPrice',
    'hasUncompletedBookingReview'
  )
  get showsSuggestedMinPricePercentage() {
    return (
      this.hasUncompletedBookingReview &&
      this.model.listing.mostRecentBookingReview?.suggestedMinPrice != null &&
      this.dirtyMinPrice !=
        this.model.listing.mostRecentBookingReview.suggestedMinPrice &&
      this.model.listing.mostRecentBookingReview.suggestedMinPricePercentage
    );
  }

  @computed('suggestedMinPricePercentage')
  get suggestedMinPriceDirection() {
    return percentageDirection(this.suggestedMinPricePercentage);
  }

  // suggested min price

  @readOnly('model.listing.mostRecentBookingReview.finalMinPricePercentage')
  finalMinPricePercentage;

  @computed(
    'hasUncompletedBookingReview',
    'model.listing.mostRecentBookingReview.finalMinPrice'
  )
  get showsFinalMinPrice() {
    return (
      this.hasUncompletedBookingReview &&
      this.mostRecentBookingReviewListingIsInCurrentBatch &&
      this.model.listing.mostRecentBookingReview?.finalMinPrice
    );
  }

  @computed(
    'model.listing.mostRecentBookingReview.{finalMinPricePercentage,finalMinPrice}',
    'dirtyMinPrice',
    'hasUncompletedBookingReview'
  )
  get showsFinalMinPricePercentage() {
    const mostRecentBookingReview = this.model.listing.mostRecentBookingReview;
    return (
      this.hasUncompletedBookingReview &&
      mostRecentBookingReview?.finalMinPrice != null &&
      this.dirtyMinPrice != mostRecentBookingReview.finalMinPrice &&
      mostRecentBookingReview.finalMinPricePercentage
    );
  }

  @computed('finalMinPricePercentage')
  get finalMinPriceDirection() {
    return percentageDirection(this.finalMinPricePercentage);
  }

  @computed(
    // eslint-disable-next-line ember/use-brace-expansion
    'model.listing.{enabled,canEdit}',
    'model.listing.mostRecentBookingReview.isSuggestionCompleted'
  )
  get displayEnablePricePostingUponSave() {
    return (
      !this.model.listing.enabled &&
      this.model.listing.canEdit &&
      !this.model.listing.mostRecentBookingReview?.isSuggestionCompleted
    );
  }

  // using observer for oneWay binding with value update on selecting new listing
  // eslint-disable-next-line ember/no-observers
  @observes(
    'model.listing.{basePrice,minPrice,id}',
    'model.listing.mostRecentBookingReview.{suggestedBasePrice,suggestedMinPrice}',
    'bookingReview.displayBookingReviewSubHeader',
    'enablePricingOnArrival'
  )
  setPriceValues() {
    this._resetValues();
    if (this.enablePricingOnArrival) {
      set(this, 'enablePricesUponSave', true);
    }
  }

  /** base price */

  @action
  basePriceFocusIn() {
    this.set('basePriceInputFocus', true);
  }

  @action
  basePriceFocusOut(e) {
    const newValue = parseInt(e.target.value);

    if (this.showsFinalBasePrice) {
      this.set('finalBasePrice', newValue);
    } else if (this.showsSuggestedBasePrice) {
      this.set('suggestedBasePrice', newValue);
    } else {
      this.set('basePrice', newValue);
    }

    this.set('percentageForBasePrice', newValue / this.dirtyBasePrice);
    this.set('basePriceInputFocus', false);
  }

  @action
  basePriceKeyUp(e) {
    // key up - incremental case
    if (e.which === 38) {
      this.send('increasedBasePrice');
    }
    // key down - decremental case
    if (e.which === 40) {
      this.send('decreasedBasePrice');
    }
  }

  @action
  checkMinBasePriceValue(evt) {
    this.valueIsBelowMinBasePrice = evt.target.value < 10;
  }

  @action
  checkMinMinPriceValue(evt) {
    this.valueIsBelowMinMinPrice = evt.target.value < 5;
  }

  @action
  setPercentageForBasePriceBasedOnStatus() {
    if (
      this.useFinalPercentageForBasePrice ||
      this.useSuggestedPercentageForBasePrice
    ) {
      return false;
    }

    const setPercentageForBasePrice = targetPercentrage => {
      set(this, 'percentageForBasePrice', targetPercentrage / 100 + 1);
      this.usePercentageForBasePrice = true;
    };

    const finalBaseCase =
      this.showsFinalBasePrice && !this.useFinalPercentageForBasePrice;
    const suggestedBaseCase =
      this.showsSuggestedBasePrice && !this.useSuggestedPercentageForBasePrice;
    const mostRecentBookingReview = this.model.listing.mostRecentBookingReview;

    if (finalBaseCase) {
      setPercentageForBasePrice(mostRecentBookingReview.finalBasePricePercentage);
      this.useFinalPercentageForBasePrice = true;
    } else if (suggestedBaseCase) {
      setPercentageForBasePrice(mostRecentBookingReview.suggestedBasePricePercentage);
      this.useSuggestedPercentageForBasePrice = true;
    }
  }

  @computed('showsFinalBasePrice', 'showsSuggestedBasePrice')
  get settingBasePrice() {
    let settingPrice;

    if (this.showsFinalBasePrice) {
      settingPrice = 'finalBasePrice';
    } else if (this.showsSuggestedBasePrice) {
      settingPrice = 'suggestedBasePrice';
    } else {
      settingPrice = 'basePrice';
    }

    return settingPrice;
  }

  @action
  increasedBasePrice() {
    this.setPercentageForBasePriceBasedOnStatus();
    this.set('percentageForBasePrice', this.percentageForBasePrice + 0.05);

    set(
      this,
      this.settingBasePrice,
      Math.round(this.dirtyBasePrice * this.percentageForBasePrice)
    );
  }

  @action
  decreasedBasePrice() {
    this.setPercentageForBasePriceBasedOnStatus();
    this.set('percentageForBasePrice', this.percentageForBasePrice - 0.05);

    set(
      this,
      this.settingBasePrice,
      Math.round(this.dirtyBasePrice * this.percentageForBasePrice)
    );
  }

  /** min price */

  @action
  minPriceFocusIn() {
    this.set('minPriceInputFocus', true);
  }

  @action
  minPriceFocusOut(e) {
    const newValue = parseInt(e.target.value);

    if (this.showsFinalMinPrice) {
      this.set('finalMinPrice', newValue);
    } else if (this.showsSuggestedMinPrice) {
      this.set('suggestedMinPrice', newValue);
    } else {
      this.set('minPrice', newValue);
    }

    this.set('percentageForMinPrice', newValue / this.dirtyMinPrice);
    this.set('minPriceInputFocus', false);
  }

  @action
  minPriceKeyUp(e) {
    // key up - incremental case
    if (e.which === 38) {
      this.send('increasedMinPrice');
    }
    // key down - decremental case
    if (e.which === 40) {
      this.send('decreasedMinPrice');
    }
  }

  @action
  setPercentageForMinPriceBasedOnStatus() {
    if (this.useFinalPercentageForMinPrice || this.useSuggestedPercentageForMinPrice) {
      return;
    }

    const setPercentageForMinPrice = targetPercentrage => {
      set(this, 'percentageForMinPrice', targetPercentrage / 100 + 1);
      this.usePercentageForMinPrice = true;
    };

    const finalMinCase = this.showsFinalMinPrice && !this.useFinalPercentageForMinPrice;
    const suggestedMinCase =
      this.showsSuggestedMinPrice && !this.useSuggestedPercentageForMinPrice;
    const mostRecentBookingReview = this.model.listing.mostRecentBookingReview;

    if (finalMinCase) {
      setPercentageForMinPrice(mostRecentBookingReview.finalMinPricePercentage);
      this.useFinalPercentageForMinPrice = true;
    } else if (suggestedMinCase) {
      setPercentageForMinPrice(mostRecentBookingReview.suggestedMinPricePercentage);
      this.useSuggestedPercentageForMinPrice = true;
    }
  }

  @computed('showsFinalMinPrice', 'showsSuggestedMinPrice')
  get settingMinPrice() {
    let settingPrice;

    if (this.showsFinalMinPrice) {
      settingPrice = 'finalMinPrice';
    } else if (this.showsSuggestedMinPrice) {
      settingPrice = 'suggestedMinPrice';
    } else {
      settingPrice = 'minPrice';
    }

    return settingPrice;
  }

  @computed(
    'showsSuggestedBasePrice',
    'showsSuggestedMinPrice',
    'suggestedBasePrice',
    'suggestedMinPrice',
    'dirtySuggestedBasePrice',
    'dirtySuggestedMinPrice',
    'initialBasePrice',
    'initialMinPrice'
  )
  get updatedSuggestedPricesAreSameWithInitialPrices() {
    // case for no suggested base/min prices
    if (!this.showsSuggestedBasePrice && !this.showsSuggestedMinPrice) {
      return false;
    }

    const canDeletePrice = (
      suggestedPrice,
      dirtySuggestedPrice,
      initialPrice,
      showsSuggestedPrice
    ) => {
      const updatedPriceIsSameWithInitialPrice =
        suggestedPrice !== dirtySuggestedPrice && initialPrice === suggestedPrice;

      if (!showsSuggestedPrice) return true;
      if (!updatedPriceIsSameWithInitialPrice) return false;
      return true;
    };

    const canDeleteSuggestedBasePrice = canDeletePrice(
      this.suggestedBasePrice,
      this.dirtySuggestedBasePrice,
      this.initialBasePrice,
      this.showsSuggestedBasePrice
    );

    const canDeleteSuggestedMinPrice = canDeletePrice(
      this.suggestedMinPrice,
      this.dirtySuggestedMinPrice,
      this.initialMinPrice,
      this.showsSuggestedMinPrice
    );

    return canDeleteSuggestedBasePrice && canDeleteSuggestedMinPrice;
  }

  @action
  increasedMinPrice() {
    this.setPercentageForMinPriceBasedOnStatus();
    this.set('percentageForMinPrice', this.percentageForMinPrice + 0.05);

    set(
      this,
      this.settingMinPrice,
      Math.round(this.dirtyMinPrice * this.percentageForMinPrice)
    );
  }

  @action
  decreasedMinPrice() {
    this.setPercentageForMinPriceBasedOnStatus();
    this.set('percentageForMinPrice', this.percentageForMinPrice - 0.05);

    set(
      this,
      this.settingMinPrice,
      Math.round(this.dirtyMinPrice * this.percentageForMinPrice)
    );
  }

  /** save */

  @action
  async saveListing() {
    if (!this.canSave) return;

    if (this.enablePricesUponSave && this.pricingController.isBillingBannerVisible) {
      let blockingErrorMessage = [
        this.intl.t(this.pricingController.billingBannerMessage[0]),
        this.intl.t('pricing.updatePaymentDetailsSimple'),
        this.intl.t(this.pricingController.billingBannerMessage[1]),
      ].join(' ');

      this.alert.error(blockingErrorMessage, 10000);

      return;
    }

    if (
      this.forcePaymentMethod.userRequirements() &&
      !this.model.listing.enabled &&
      this.enablePricesUponSave
    ) {
      this.forcePaymentMethod.redirect();
      return;
    }

    let listing = this.get('model.listing');
    const wasOnProgramBefore = this.get('model.listing.onProgram');

    if (this.basePrice !== listing.get('_dirty.basePrice')) {
      listing.set('basePrice', this.basePrice);
    }

    if (this.minPrice !== listing.get('_dirty.minPrice')) {
      listing.set('minPrice', this.minPrice);
    }

    if (!listing.enabled && this.enablePricesUponSave) {
      listing.set('enabled', true);
      listing.set('onProgram', true);
    }

    if (this.notes !== listing.get('_dirty.notes')) {
      listing.set('notes', this.notes);
    }

    let result = await listing.saveWithValidation();

    if (!result) {
      listing.set('enabled', false);
      listing.set('onProgram', wasOnProgramBefore);
      return;
    }

    let clNames = [];

    for (const cl of listing.channelListingsNames) {
      if (!clNames.includes(CHANNEL_CONFIG[cl].label)) {
        clNames.push(CHANNEL_CONFIG[cl].label);
      }
    }

    let clNamesString = clNames.join(', ');

    this.alert.success(
      this.intl.t('pricing.bulkEdit.bulkEditSuccesfullySaved', {
        clNamesString: clNamesString,
      })
    );
    this._resetValues();
  }

  // for submitter

  @action
  async saveSuggestion() {
    let url = '/api/booking_reviews';
    let listing = this.get('model.listing');
    const data = {
      listing_id: listing.id,
      submitter_email: this.currentUser.email,
      status: STATUS_MAPPING.SUGGESTED,
      suggested_base_price: null,
      suggested_min_price: null,
      submitter_notes: null,
      booking_analysis: null,
      recommendations: null,
    };

    // base price
    if (this.showsSuggestedBasePrice) {
      if (this.suggestedBasePrice !== this.dirtySuggestedBasePrice) {
        data['suggested_base_price'] = this.suggestedBasePrice;
      }
    } else {
      if (this.basePrice !== listing.get('_dirty.basePrice')) {
        data['suggested_base_price'] = this.basePrice;
      }
    }

    // min price
    if (this.showsSuggestedMinPrice) {
      if (this.suggestedMinPrice !== this.dirtySuggestedMinPrice) {
        data['suggested_min_price'] = this.suggestedMinPrice;
      }
    } else {
      if (this.minPrice !== listing.get('_dirty.minPrice')) {
        data['suggested_min_price'] = this.minPrice;
      }
    }

    data['submitter_notes'] = [this.bookingAnalysis, this.recommendations]
      .join(' ')
      .trim();

    data['booking_analysis'] = this.bookingAnalysis;
    data['recommendations'] = this.recommendations;

    let response;
    try {
      response = await this.ajax._post(url, data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      return;
    }

    // manage cache
    const dashboardPricingController = getOwner(this).lookup(
      'controller:dashboard.pricing.index'
    );

    // bookingReview
    const bookingReviewListing = response.bookingReviewListing;
    const newBookingReview = response.bookingReview;
    bookingReviewListing.bookingReviewListing = bookingReviewListing;
    if (!this.bpStore.peekRecord('bookingReview', newBookingReview.id)) {
      this.bpStore.createRecord('bookingReview', newBookingReview);
      dashboardPricingController.model.bookingReviews.pushObject(newBookingReview);
    }

    // bookingReviewListing
    const newBookingReviewListing = this.bpStore.createRecord(
      'bookingReviewListing',
      response.bookingReviewListing
    );

    dashboardPricingController.model.bookingReviewListings.pushObject(
      newBookingReviewListing
    );

    let bookingReviewListings = this.get('model.listing.bookingReviewListings');
    bookingReviewListings.pushObject(newBookingReviewListing);

    // alert

    this.alert.success('Suggestion saved successfully');
    this._resetValues();

    // select next need review listing
    const listingId = dashboardPricingController.nextNeedReviewListingId;
    if (!listingId) {
      return;
    }
    this.transitionToRoute(
      `dashboard.pricing.index.booking-review.index.rates`,
      listingId
    );
  }

  @action
  async updateSuggestion(targetBookingReviewListingId) {
    let url = '/api/booking_reviews';
    const data = {
      booking_review_listing_id: targetBookingReviewListingId,
      suggested_base_price: null,
      suggested_min_price: null,
      submitter_notes: null,
      booking_analysis: null,
      recommendations: null,
    };

    let listing = this.get('model.listing');
    // base price
    if (this.showsSuggestedBasePrice) {
      if (this.suggestedBasePrice !== this.dirtySuggestedBasePrice) {
        data['suggested_base_price'] = this.suggestedBasePrice;
      }
    } else {
      if (this.basePrice !== listing.get('_dirty.basePrice')) {
        data['suggested_base_price'] = this.basePrice;
      }
    }

    // min price
    if (this.showsSuggestedMinPrice) {
      if (this.suggestedMinPrice !== this.dirtySuggestedMinPrice) {
        data['suggested_min_price'] = this.suggestedMinPrice;
      }
    } else {
      if (this.minPrice !== listing.get('_dirty.minPrice')) {
        data['suggested_min_price'] = this.minPrice;
      }
    }

    data['submitter_notes'] = [this.bookingAnalysis, this.recommendations]
      .join(' ')
      .trim();

    data['booking_analysis'] = this.bookingAnalysis;
    data['recommendations'] = this.recommendations;

    let response;
    try {
      response = await this.ajax._put(url, data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      return;
    }

    const bookingReviewListing = response.bookingReview;
    this.bpStore.createRecord('bookingReviewListing', bookingReviewListing);

    this.alert.success('Updated suggestion successfully');
    this._resetValues();
  }

  @action
  async deleteSuggestion(targetBookingReviewListingId) {
    let url = '/api/booking_review_listings';
    const data = {
      booking_review_listing_ids: [targetBookingReviewListingId],
    };

    try {
      await this.ajax._delete(url, data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      return;
    }

    this.model.listing.removeMostRecentBookingReview();
    this.pricingController.clearSuggestion(targetBookingReviewListingId);

    this.alert.success('Cleared suggestion successfully');
    this._resetValues();
  }

  // for approver

  @action
  async approveSuggestion(targetListing) {
    let listing = targetListing ? targetListing : this.get('model.listing');
    let url = `/api/pricing/booking_reviews_legacy/approve`;

    const data = {
      booking_review_listing_ids: [listing.get('mostRecentBookingReview.id')],
    };

    let response;
    try {
      response = await this.ajax._post(url, data);
      const updatedListing = await this.ajax._get(`/api/listings/${listing.id}`);

      // update listing model
      set(listing, 'basePrice', updatedListing.basePrice);
      set(listing, 'basePriceHistoryV2', updatedListing.basePriceHistoryV2);
      set(listing, 'basePriceUpdatedAt', updatedListing.basePriceUpdatedAt);
      set(listing, 'minPrice', updatedListing.minPrice);
      set(listing, 'minPriceHistoryV2', updatedListing.minPriceHistoryV2);
      set(listing, 'minPriceUpdatedAt', updatedListing.minPriceUpdatedAt);
      listing.markClean();
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      return;
    }

    if (response.bookingReviewListings && response.bookingReviewListings.length > 0) {
      this.bpStore.createRecord(
        'bookingReviewListing',
        response.bookingReviewListings[0]
      );
    }

    this.alert.success('Approved suggestion successfully');

    if (!targetListing) {
      this._resetValues();
      // select next need review listing
      const dashboardPricingController = getOwner(this).lookup(
        'controller:dashboard.pricing.index'
      );
      const listingId = dashboardPricingController.nextNeedReviewListingIdForApprover;
      if (!listingId) {
        return;
      }
      this.transitionToRoute(
        `dashboard.pricing.index.booking-review.index.rates`,
        listingId
      );
    }
  }

  @action
  async rejectSuggestion() {
    if (!this.notes) {
      return;
    }

    let listing = this.get('model.listing');
    let url = `/api/pricing/booking_reviews_legacy/reject`;
    const data = {
      booking_review_listing_ids: [listing.get('mostRecentBookingReview.id')],
      final_base_price: null,
      final_min_price: null,
      approver_notes: null,
    };

    if (this.showsFinalBasePrice) {
      if (this.finalBasePrice !== this.dirtyFinalBasePrice) {
        data['final_base_price'] = this.finalBasePrice;
      }
    } else if (this.showsSuggestedBasePrice) {
      if (this.suggestedBasePrice !== this.dirtySuggestedBasePrice) {
        data['final_base_price'] = this.suggestedBasePrice;
      }
    } else {
      if (this.basePrice !== this.dirtyBasePrice) {
        data['final_base_price'] = this.basePrice;
      }
    }

    if (this.showsFinalMinPrice) {
      if (this.finalMinPrice !== this.dirtyFinalMinPrice) {
        data['final_min_price'] = this.finalMinPrice;
      }
    } else if (this.showsSuggestedMinPrice) {
      if (this.suggestedMinPrice !== this.dirtySuggestedMinPrice) {
        data['final_min_price'] = this.suggestedMinPrice;
      }
    } else {
      if (this.minPrice !== this.dirtyMinPrice) {
        data['final_min_price'] = this.minPrice;
      }
    }

    data['approver_notes'] = this.notes;

    let response;
    try {
      response = await this.ajax._post(url, data);
      const updatedListing = await this.ajax._get(`/api/listings/${listing.id}`);

      // update listing model
      set(listing, 'basePrice', updatedListing.basePrice);
      set(listing, 'basePriceHistoryV2', updatedListing.basePriceHistoryV2);
      set(listing, 'basePriceUpdatedAt', updatedListing.basePriceUpdatedAt);
      set(listing, 'minPrice', updatedListing.minPrice);
      set(listing, 'minPriceHistoryV2', updatedListing.minPriceHistoryV2);
      set(listing, 'minPriceUpdatedAt', updatedListing.minPriceUpdatedAt);
      listing.markClean();
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain');
      return;
    }
    if (response.bookingReviewListings && response.bookingReviewListings.length > 0) {
      this.bpStore.createRecord(
        'bookingReviewListing',
        response.bookingReviewListings[0]
      );
    }

    this.alert.success('Rejected suggestion successfully');
    this._resetValues();

    // select next need review listing
    const dashboardPricingController = getOwner(this).lookup(
      'controller:dashboard.pricing.index'
    );
    const listingId = dashboardPricingController.nextNeedReviewListingIdForApprover;
    if (!listingId) {
      return;
    }
    this.transitionToRoute(
      `dashboard.pricing.index.booking-review.index.rates`,
      listingId
    );
  }

  @action
  editAlreadyReviewdListing(status) {
    if (status === STATUS_MAPPING.APPROVED) {
      this.set('editingApprovedListing', true);
    } else if (status === STATUS_MAPPING.REJECTED) {
      this.set('editingRejectedListing', true);
    } else {
      return;
    }
  }

  @action
  changeCanceledEditingRejectedListing(status) {
    this.canceledEditingRejectedListing = status;
  }

  @action
  startRejectingSuggestion(value) {
    set(this, 'rejectingSuggestion', value);

    if (!value) {
      return;
    }

    later(
      this,
      function () {
        // scroll div to bottom to display comment section
        const bookingReviewWrapArr = document.getElementsByClassName(
          'booking-review-wrap'
        );
        if (!bookingReviewWrapArr.length) {
          return;
        }
        const bookingReviewWrap = bookingReviewWrapArr[0];
        bookingReviewWrap.scroll({
          top: bookingReviewWrap.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });

        // add pulse effects
        const pulseArr = document.getElementsByClassName('br-pulse');
        Array.from(pulseArr).forEach(element => {
          element.classList.add('add-pulse');
        });

        // remove pulse effects
        later(
          this,
          function () {
            const pulseArr = document.getElementsByClassName('br-pulse');
            Array.from(pulseArr).forEach(element => {
              element.classList.remove('add-pulse');
            });
          },
          6000
        );
      }.bind(this),
      200
    );
  }

  @action
  resetValues() {
    this._resetValues();
  }

  _resetValues() {
    const mostRecentBookingReview = this.model.listing.mostRecentBookingReview;

    this.set('basePrice', this.model.listing.basePrice);
    this.set('minPrice', this.model.listing.minPrice);
    if (mostRecentBookingReview) {
      this.set('suggestedBasePrice', mostRecentBookingReview.suggestedBasePrice);
      this.set('suggestedMinPrice', mostRecentBookingReview.suggestedMinPrice);
      this.set('finalBasePrice', mostRecentBookingReview.finalBasePrice);
      this.set('finalMinPrice', mostRecentBookingReview.finalMinPrice);
    } else {
      this.set('suggestedBasePrice', undefined);
      this.set('suggestedMinPrice', undefined);
      this.set('finalBasePrice', undefined);
      this.set('finalMinPrice', undefined);
    }
    const submitterCase = this.bookingReview.isRunningReview;
    const isSuggestionCompleted = get(
      this,
      'model.listing.mostRecentBookingReview.isSuggestionCompleted'
    );
    const isAlreadyReviewed = get(
      this,
      'model.listing.mostRecentBookingReview.isAlreadyReviewed'
    );
    const approverCase =
      (isSuggestionCompleted || isAlreadyReviewed) &&
      this.mostRecentBookingReviewListingIsInCurrentBatch &&
      this.hasUncompletedBookingReview;

    const isListingInMostRecentUncompletedBookingReview =
      this.mostRecentBookingReviewListingIsInCurrentBatch &&
      this.hasUncompletedBookingReview;

    if (approverCase) {
      this.set('notes', mostRecentBookingReview.approverNotes);
    } else if (submitterCase) {
      // case for listing in most recent uncompleted booking review
      if (isListingInMostRecentUncompletedBookingReview && mostRecentBookingReview) {
        this.set('bookingAnalysis', mostRecentBookingReview.bookingAnalysis);
        this.set('recommendations', mostRecentBookingReview.recommendations);
      } else {
        this.set('notes', null);
        this.set('bookingAnalysis', null);
        this.set('recommendations', null);
      }
    } else {
      this.set('notes', this.model.listing.notes);
    }
    this.set('enablePricesUponSave', false);
    this.set('rejectingSuggestion', false);
    this.set('percentageForBasePrice', 1);
    this.set('percentageForMinPrice', 1);
    this.set('editingApprovedListing', false);
    this.set('editingRejectedListing', false);
    this.set('canceledEditingRejectedListing', false);
    this.set('useSuggestedPercentageForBasePrice', false);
    this.set('useFinalPercentageForBasePrice', false);
    this.set('useSuggestedPercentageForMinPrice', false);
    this.set('usePercentageForBasePrice', false);
    this.set('usePercentageForMinPrice', false);
  }

  // Overwrite -------------------------------------------------------------------------
}
