import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { collectionAction } from 'ember-api-actions';

export default class CredentialModel extends Model {
  @belongsTo('user', { async: false, inverse: 'credentials' }) user;
  @hasMany('permission', { async: false, defaultValue: () => [] }) permissions;
  @attr('string', { defaultValue: 'EmailCredential' }) type;
  @attr email;
  @attr name;
  @attr firstName;
  @attr lastName;
  @attr globalPermissions;
  @attr('moment') expiresAt;

  updatePermissions = collectionAction({
    path: 'update',
    type: 'POST',
  });

  deleteMany = collectionAction({
    path: 'delete',
    type: 'POST',
    after(response) {
      for (const credentialId of response.credentialIds) {
        const credential = this.store.peekRecord('credential', credentialId);
        this.store.unloadRecords(credential.permissions.toArray());
        this.store.unloadRecord(credential);
      }
    },
  });

  get isAdministrator() {
    return this.globalPermissions === 'admin';
  }

  get classSelected() {
    if (this.itemsVisible) {
      return 'row-selected';
    }
    return '';
  }

  get readableName() {
    if (this.type === 'EmailCredential') {
      return this.email;
    }

    if (this.type === 'TokenCredential') {
      return `API: ${this.name}`;
    }

    return '-';
  }
}
