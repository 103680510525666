import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  {{did-update this.onRedrawChanged @redraw}}\n  {{did-update this.onLoadingChanged @loading}}\n  {{did-update this.onChartInstanceChange @chart}}\n  {{did-update this.onUseLensChanged @useLens}}\n  {{did-update this.onLensRangeChanged @lensRange}}\n>\n  {{yield}}\n</div>", {"contents":"<div\n  ...attributes\n  {{did-update this.onRedrawChanged @redraw}}\n  {{did-update this.onLoadingChanged @loading}}\n  {{did-update this.onChartInstanceChange @chart}}\n  {{did-update this.onUseLensChanged @useLens}}\n  {{did-update this.onLensRangeChanged @lensRange}}\n>\n  {{yield}}\n</div>","moduleName":"appkit/components/insights/charts/base/extend.hbs","parseOptions":{"srcName":"appkit/components/insights/charts/base/extend.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { once } from '@ember/runloop';

export default class InsightsChartsExtendComponent extends Component {
  selectRect;
  leftHandler;
  rightHandler;
  closeBtn;
  dropShadowFilter;

  onMouseDown;
  onMouseMove;
  dragTimeFrame;
  leftDrag;
  rightDrag;

  lensMin;
  lensMax;

  get chart() {
    return this.args.chart;
  }

  @action
  onUseLensChanged() {
    if (this.args.useLens) {
      this.configLens();
    } else {
      this.removeLens();
    }
  }

  @action
  onLensRangeChanged() {
    if (!this.args.useLens) {
      return;
    }
    const chart = this.chart;
    const xAxis = this.chart.xAxis[0];
    const { lensRange } = this.args;

    if (lensRange && lensRange.start && lensRange.end) {
      this.renderLens(chart);

      //Convert date values to chart pixels
      const start = xAxis.toPixels(lensRange.start);
      const end = xAxis.toPixels(lensRange.end) - start;

      const plotMin = chart.plotLeft;
      const plotMax = chart.plotLeft + chart.plotWidth;
      if (
        start >= plotMin &&
        start <= plotMax &&
        start + end >= plotMin &&
        start + end <= plotMax
      ) {
        this.updateLens(chart, start, end);
      } else {
        this.clearLens(false);
      }
    } else {
      this.clearLens();
    }
  }

  @action
  onChartInstanceChange() {
    if (!this.args.chart) {
      return;
    }

    this.onLoadingChanged();
    this.onRedrawChanged();

    this.removeEventHandler?.();
    this.beforePrintHandler?.();
    this.afterPrintHandler?.();

    this.removeEventHandler = window.Highcharts.addEvent(
      this.chart,
      'render',
      this.onLensRangeChanged
    );

    window.addEventListener('beforeprint', this.beforePrintHandler);
    window.addEventListener('afterprint', this.afterPrintHandler);
  }

  willDestroy() {
    this.removeEventHandler?.();
    window.removeEventListener('beforeprint', this.beforePrintHandler);
    window.removeEventListener('afterprint', this.afterPrintHandler);
  }

  @action
  beforePrintHandler() {
    this.resetParams = [this.chart.chartWidth, this.chart.chartHeight, false];
    this.chart.setSize(650, 250, false);
  }

  @action
  afterPrintHandler() {
    this.chart.setSize(this.resetParams[0], this.resetParams[1], this.resetParams[2]);
  }

  @action
  updateLens(chart, start, end) {
    this.leftOverlay.attr({
      x: chart.plotLeft,
      width: start - chart.plotLeft,
      y: chart.plotTop,
      height: chart.plotHeight,
    });

    this.selectRect
      .attr({
        x: start,
        width: end,
        y: chart.plotTop,
        height: chart.plotHeight,
      })
      .css({
        cursor: 'grab',
      });

    this.rightOverlay.attr({
      x: start + end,
      width: chart.plotWidth - (start + end) + chart.plotLeft,
      y: chart.plotTop,
      height: chart.plotHeight,
    });

    this.leftHandler
      .attr({
        transform: `translate (${start - 7}, ${chart.plotHeight / 2 - 6})`,
      })
      .css({
        cursor: 'ew-resize',
      });

    this.rightHandler
      .attr({
        transform: `translate (${start + end - 5}, ${chart.plotHeight / 2 - 6})`,
      })
      .css({
        cursor: 'ew-resize',
      });

    this.closeBtn
      .attr({
        transform: `translate (${start + end}, ${chart.plotTop})`,
      })
      .css({
        cursor: 'pointer',
      });
  }

  @action
  onLoadingChanged() {
    if (this.args.loading) {
      if (this.args.useLens) {
        this.removeLens();
      }
      this.chart?.showLoading(
        `<div class="line-scale-party">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>`
      );
    } else {
      this.chart?.hideLoading();
      if (this.args.useLens) {
        this.configLens();
      }
    }
  }

  @action
  onRedrawChanged() {
    const container = this.chart.container.parentElement.getBoundingClientRect();
    // when navigating from React to Insights charts parent element width is 0, use window width percentage instead
    let width = container.width || window.innerWidth - window.innerWidth * 0.206;

    this.chart?.setSize(width, container.height);
    this.chart?.reflow(); //leaving this here just for future reference as an option
  }

  configLens() {
    const chart = this.chart;
    const chartContainer = this.chart.container;
    const xAxis = this.chart.xAxis[0];

    let isMouseDown = false;
    let firstDrag = false;
    let min;
    let max;
    let chartX;
    let endChartX;
    let dragX;

    this.addDropShadowFilter(chart);

    if (this.onMouseDown && this.onMouseMove) {
      this.removeLens();
    }

    this.onMouseDown = e => {
      if (this.args.loading || isMouseDown) {
        return;
      }

      window.addEventListener('mouseup', handleMouseUp);
      e = this.chart.pointer.normalize(e);

      // Only render lens inside plot area
      if (e.chartX < chart.plotLeft || e.chartX > chart.plotWidth + chart.plotLeft) {
        return;
      }

      isMouseDown = true;

      if (!this.selectRect) {
        firstDrag = true;
        chartX = e.chartX;
        endChartX = e.chartX;
      } else {
        firstDrag = false;

        if (e.chartX > chartX && e.chartX < endChartX) {
          this.dragTimeFrame = true;
          dragX = e.chartX;
        } else {
          this.dragTimeFrame = false;
        }
      }
    };

    this.onMouseMove = e => {
      let dragTimeFrame = this.dragTimeFrame;
      let leftDrag = this.leftDrag;
      let rightDrag = this.rightDrag;

      e = this.chart.pointer.normalize(e);
      if (isMouseDown) {
        if (!firstDrag && !leftDrag && !rightDrag && !dragTimeFrame) {
          return;
        }

        if (firstDrag && e.chartX >= chartX && e.chartX - 10 <= chartX) {
          e.chartX = chartX + 10;
        }

        if (firstDrag && e.chartX < chartX && e.chartX + 10 > chartX) {
          e.chartX = chartX - 10;
        }

        if (rightDrag && e.chartX - 10 < chartX) {
          return;
        }

        if (leftDrag && e.chartX + 10 > endChartX) {
          return;
        }

        this.renderLens(chart);

        let start, end;

        if (!firstDrag) {
          if (rightDrag) {
            start = chartX;
            end = e.chartX - chartX;
          } else if (leftDrag) {
            start = e.chartX;
            end = endChartX - e.chartX;
          } else if (dragTimeFrame) {
            start = e.chartX - dragX + chartX;
            end = e.chartX - dragX + (endChartX - start);
          }
        } else {
          if (e.chartX > chartX) {
            start = chartX;
            end = e.chartX - chartX;
          } else {
            start = e.chartX;
            end = chartX - e.chartX;
          }
        }

        // Constrain timeframe to chart bounderies
        if (start < chart.plotLeft) {
          start = chart.plotLeft;

          if (leftDrag || firstDrag) {
            end = end - (start - e.chartX);
          }
        }

        if (start + end > chart.plotWidth + chart.plotLeft) {
          if (dragTimeFrame) {
            start = start - (start + end - (chart.plotWidth + chart.plotLeft));
          }
          end = chart.plotWidth + chart.plotLeft - start;
        }

        this.updateLens(chart, start, end);

        min = Math.round(xAxis.toValue(start));
        max = Math.round(xAxis.toValue(start + end));
      }
    };

    const handleMouseUp = () => {
      let dragTimeFrame = this.dragTimeFrame;
      let leftDrag = this.leftDrag;
      let rightDrag = this.rightDrag;

      if (this.selectRect) {
        chartX = this.selectRect.attr('x');
        endChartX = this.selectRect.attr('x') + this.selectRect.attr('width');
      }

      if (chartX === endChartX) {
        return this.clearLens();
      }

      if (leftDrag || rightDrag || dragTimeFrame || firstDrag) {
        this.lensMin = min;
        this.lensMax = max;
        this.args.onLensRangeChanged?.(min, max);
      }
      isMouseDown = false;
      this.leftDrag = false;
      this.rightDrag = false;
      this.dragTimeFrame = false;

      window.removeEventListener('mouseup', handleMouseUp);
    };

    const addEventHandlers = () => {
      chartContainer.addEventListener('mousedown', this.onMouseDown);
      chartContainer.addEventListener('mousemove', this.onMouseMove);
    };

    once(this, addEventHandlers);
  }

  removeLens() {
    this.clearLens();
    this.chart.container.removeEventListener('mousedown', this.onMouseDown);
    this.chart.container.removeEventListener('mousemove', this.onMouseMove);
    this.onMouseDown = null;
    this.onMouseMove = null;
  }

  renderLens(chart) {
    if (this.selectRect) {
      return;
    }
    this.leftOverlay = chart.renderer
      .rect(0, 0, 0, 0, 0)
      .attr({
        fill: '#ffffff',
        zIndex: 3,
      })
      .css({
        fillOpacity: '.5',
      })
      .add();

    this.rightOverlay = chart.renderer
      .rect(0, 0, 0, 0, 0)
      .attr({
        fill: '#ffffff',
        zIndex: 3,
      })
      .css({
        fillOpacity: '.5',
      })
      .add();

    this.selectRect = chart.renderer
      .rect(0, 0, 0, 0, 0)
      .attr({
        id: 'highcharts-lens',
        zIndex: 4,
        rx: 4,
        ry: 4,
        fill: '#A8A8A8',
      })
      .css({
        filter: 'url(#lens-glow)',
      })
      .on('mousedown', () => {
        this.dragTimeFrame = true;
      })
      .add();

    this.leftHandler = chart.renderer
      .g('left-handler')
      .attr({
        id: 'left-handler',
        zIndex: 5,
      })
      .on('mousedown', () => {
        this.leftDrag = true;
      })
      .add();

    chart.renderer
      .rect(0, 0, 12, 32, 2)
      .attr({
        fill: 'white',
        stroke: '#D8D8D8',
        'stroke-width': 0.5,
      })
      .css({
        filter: 'url(#handler-glow)',
      })
      .add(this.leftHandler);

    chart.renderer
      .path(['M', '4', '8', 'L', '4', '24', 'M', '8', '8', 'L', '8', '24'])
      .attr({
        stroke: '#D8D8D8',
        'stroke-width': 0.8,
      })
      .add(this.leftHandler);

    this.rightHandler = chart.renderer
      .g('right-handler')
      .attr({
        id: 'right-handler',
        zIndex: 5,
      })
      .on('mousedown', () => {
        this.rightDrag = true;
      })
      .add();

    chart.renderer
      .rect(0, 0, 12, 32, 2)
      .attr({
        id: 'right-handler',
        fill: 'white',
        stroke: '#D8D8D8',
        'stroke-width': 0.5,
      })
      .css({
        filter: 'url(#handler-glow)',
      })
      .add(this.rightHandler);

    chart.renderer
      .path(['M', '4', '8', 'L', '4', '24', 'M', '8', '8', 'L', '8', '24'])
      .attr({
        stroke: '#D8D8D8',
        'stroke-width': 0.8,
      })
      .add(this.rightHandler);

    this.closeBtn = chart.renderer
      .g('close-btn')
      .attr({
        zIndex: 5,
      })
      .on('mousedown', e => {
        e.stopPropagation();
      })
      .on('mouseup', e => {
        e.stopPropagation();
      })
      .on('click', () => {
        this.clearLens();
      });

    chart.renderer
      .circle(0, 0, 8)
      .attr({
        fill: '#43B6C0',
        'stroke-width': '.5',
      })
      .add(this.closeBtn);

    chart.renderer
      .rect(-4, -1, 8, 2, 1, 2)
      .attr({
        transform: 'rotate(45)',
        fill: '#ffffff',
      })
      .add(this.closeBtn);

    chart.renderer
      .rect(-4, -1, 8, 2, 1, 2)
      .attr({
        transform: 'rotate(-45)',
        fill: '#ffffff',
      })
      .add(this.closeBtn);

    this.closeBtn.add();
  }

  addDropShadowFilter(chart) {
    if (this.dropShadowFilter) {
      return;
    }

    // Add drop shadow filter
    this.dropShadowFilter = chart.renderer
      .definition({
        tagName: 'filter',
        id: 'lens-glow',
        x: '-100%',
        y: '-100%',
        width: '400%',
        height: '400%',
        // opacity: 0.5,
        children: [
          {
            tagName: 'feGaussianBlur',
            stdDeviation: 6,
          },
          {
            tagName: 'feComposite',
            operator: 'out',
            in2: 'SourceGraphic',
          },
        ],
      })
      .add();

    this.chart.renderer
      .definition({
        tagName: 'filter',
        id: 'handler-glow',
        x: '-50%',
        y: '-50%',
        width: '200%',
        height: '200%',
        children: [
          {
            tagName: 'feDropShadow',
            stdDeviation: 2,
            'flood-color': '#BFBFBF',
            dx: 0,
            dy: 0,
          },
        ],
      })
      .add();
  }

  clearLens(notify = true) {
    if (this.selectRect == null) {
      return;
    }

    this.lensMin = null;
    this.lensMax = null;

    if (notify) {
      this.args.onLensRangeChanged?.(null, null);
    }

    this.selectRect?.destroy();
    this.closeBtn?.destroy();
    this.leftHandler?.destroy();
    this.rightHandler?.destroy();
    this.leftOverlay?.destroy();
    this.rightOverlay?.destroy();
    this.selectRect = null;
    this.closeBtn = null;
    this.leftHandler = null;
    this.rightHandler = null;
    this.leftOverlay = null;
    this.rightOverlay = null;
  }
}
