import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';
import { ChannelUtil } from 'appkit/lib/channel';

@classic
export default class RelayV2Route extends AuthRoute {
  @service('global-nav') globalNav;
  @service featureFlag;
  @service router;

  isSurfaceRelayInAppEnabled = false;
  isRelayv2Enabled = false;

  title(tokens) {
    let title = null;

    if (!tokens || !tokens.length) {
      title = 'Relay';
    } else {
      title = `Relay / ${tokens}`;
    }

    this.globalNav.setTitle(title);
    return title;
  }

  async beforeModel() {
    this.isSurfaceRelayInAppEnabled = await this.featureFlag.evaluate(
      'surface-relay-in-app',
      false
    );
    this.isRelayv2Enabled = await this.featureFlag.evaluate(
      'use-relay-v2-controller',
      false
    );
  }

  afterModel(model, transition) {
    if (!this.isRelayv2Enabled) {
      console.log(
        'You don`t have permission to view Relay v2. Redirecting to v1',
        this.isRelayv2Enabled
      );
      this.transitionTo('dashboard.relay.index');
    }

    model.isSurfaceRelayInAppEnabled = this.isSurfaceRelayInAppEnabled;
    // PMS users with relay enabled can see relay
    const hasPms = model.accounts.some(ac => ChannelUtil.isPms(ac.channel));
    const hasDirect = model.accounts.some(ac => !ChannelUtil.isPms(ac.channel));
    const hasSupportedPms = model.accounts.some(ac =>
      ChannelUtil.supportsRelay(ac.channel)
    );

    if ((hasPms && hasDirect) || hasSupportedPms) {
      return model;
    }

    const currURL = transition.to.name;

    if (this.isSurfaceRelayInAppEnabled) {
      if (currURL === 'dashboard.relay.index') {
        this.transitionTo('dashboard');
      }
    } else {
      this.transitionTo('dashboard');
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const hasMergedListings =
      model.listings.filter(l => l.channelListingsWithoutPrimary.length > 0).length > 0;
    controller.set('selectedTab', hasMergedListings ? 0 : 1);
  }
}
