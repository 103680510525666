import { tracked } from '@glimmer/tracking';
import Component from '@ember/component';

// TODO: These functions are shared from app-channel-markup

/** Check if value is blank */
function isBlank(value) {
  return value === null || (typeof value === 'string' && value.trim() === '');
}

/** Convert float to a percentage value, ex: 0.23 -> 23 */
function convertToPercentage(value) {
  if (isBlank(value)) return '';
  return Math.floor(value * 10000) / 100;
}

export default class AppShowChannelMarkup extends Component {
  @tracked isMerged = false;

  get managedAccount() {
    return this.channelListing?.managedAccount;
  }

  get markup() {
    if (
      !(this.managedAccount?.markup || this.channelListing?.markup) ||
      !this.isMerged
    ) {
      return '';
    }

    const markup = this.channelListing.markup || this.managedAccount.markup;
    return convertToPercentage(markup) + '%';
  }
}
