import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class RegionsRoute extends AuthRoute {
  controllerName = 'data.regions';
  templateName = 'data.regions';

  model() {
    let market = this.modelFor('data.market');
    let url = `/admin/api/markets/${market.get('id')}/regions`;

    return this.ajax._get(url).then(regionData => {
      return {
        regions: regionData.regions,
        listings: [],
      };
    });
  }

  @action
  savePolygon() {
    let points = this.controller.get('pathPoints');
    if (points) {
      let market = this.modelFor('data.market');
      let pointsString = points.map(point => `${point[0]} ${point[1]}`).toString();
      this.ajax
        ._post(`/admin/api/markets/${market.id}/regions`, {
          data: pointsString,
        })
        .then(
          () => {
            alert(`Market: "${market.name}" ID: ${market.id} Update Saved!`);
          },
          () => {
            alert('Region Update Failed.');
          }
        );
    }
  }

  @action
  loadHeatmap() {
    // The real one is in app/routes/data/market/map.js
  }
}
