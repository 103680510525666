import classic from 'ember-classic-decorator';
import Component from '@ember/component';

@classic
export default class AppPriceInput extends Component {
  // -- Parameters ---------------------------------------------------------------------
  currency = null;

  focus = null;
  editMethod = null;

  price = null;

  // -- Component Properties -----------------------------------------------------------

  // -- Computed Properties ------------------------------------------------------------

  // -- Lifecycle Hooks ----------------------------------------------------------------

  // -- Actions ------------------------------------------------------------------------

  // -- Private Functions --------------------------------------------------------------

  // -- Overwrite ----------------------------------------------------------------------
}
