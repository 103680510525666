import Controller from '@ember/controller';
import { ChannelUtil } from 'appkit/lib/channel';
export default class IndexController extends Controller {
  get firstAccountConnected() {
    return this.model.accounts?.length > 0;
  }

  get channelsBetaEnabled() {
    return this.model.user.channelsBetaEnabled ?? [];
  }

  // All channels to display
  get supportedChannels() {
    let supported = ChannelUtil.getSupported();
    let beta = this.channelsBetaEnabled.filter(item => !supported.includes(item));

    beta = beta.sort();
    return supported.concat(beta);
  }

  get assistedChannels() {
    let assisted = ChannelUtil.getAssisted();

    return assisted.filter(item => !this.supportedChannels.includes(item));
  }
}
