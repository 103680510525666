import { helper } from '@ember/component/helper';
import { CHANNEL } from 'appkit/lib/channel_configuration';

export default helper(function (params) {
  const code = params[0];
  if (!code) {
    return '';
  }
  if (code === CHANNEL.FAKE_PMS) {
    // return 'fp' to not confuse it with the Fake channel 'f'
    return 'fp';
  }
  return code.charAt(0);
});
