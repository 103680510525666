import Application from '@ember/application';
import CurrentUserController from 'appkit/controllers/current_user';

Application.initializer({
  name: 'register and inject currentUser',
  initialize(application) {
    application.register('user:current', CurrentUserController, { singleton: true });

    application.inject('controller', 'currentUser', 'user:current');
    application.inject('component', 'currentUser', 'user:current');
    application.inject('route', 'currentUser', 'user:current');
    application.inject('service', 'currentUser', 'user:current');
  },
});
