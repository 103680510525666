import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  role='button'\n  class='bp-toggle-{{this.size}} {{if @isChecked 'on' 'off'}} {{\n    if @disabled 'unavailable'\n  }}'\n  ...attributes\n  {{on 'click' this.onClick}}\n>\n  <div class='slider {{if @isChecked 'on' 'off'}}'></div>\n</div>", {"contents":"<div\n  role='button'\n  class='bp-toggle-{{this.size}} {{if @isChecked 'on' 'off'}} {{\n    if @disabled 'unavailable'\n  }}'\n  ...attributes\n  {{on 'click' this.onClick}}\n>\n  <div class='slider {{if @isChecked 'on' 'off'}}'></div>\n</div>","moduleName":"appkit/components/bp/toggle.hbs","parseOptions":{"srcName":"appkit/components/bp/toggle.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ToggleComponent extends Component {
  get size() {
    const { size } = this.args;

    return size ?? 'md';
  }

  @action
  onClick() {
    const { onChange, isChecked, disabled } = this.args;

    if (disabled) return false;

    onChange(!isChecked);
    return false;
  }
}
