import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Controller from '@ember/controller';

@classic
export default class CredentialsController extends Controller {
  @action
  addNewCredential(newCredential, permissions) {
    let credential = this.bpStore.createRecord('credential', newCredential);
    this.set('model.credentials', [credential, ...this.model.credentials]);

    if (permissions && permissions.length > 0) {
      for (let prm of permissions) {
        let permission = this.bpStore.createRecord('permission', prm);
        permission.set('credential', credential);
        let listing = this.bpStore.peekRecord('listing', prm.listingId);
        permission.set('listing', listing);
        credential.permissions.pushObject(permission);
      }
    }
  }

  @action
  deleteCredentials(credentialIds) {
    this.set(
      'model.credentials',
      this.model.credentials.filter(c => !credentialIds.includes(c.id))
    );

    for (let id of credentialIds) {
      let credential = this.bpStore.peekRecord('credential', id);
      credential.destroy();
    }
  }

  @action
  resetCredentials() {
    for (const credential of this.model.credentials) {
      credential.resetChanges();
    }
  }
}
