import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class ExperimentalPricingAuthRoute extends AuthRoute {
  templateName = 'data.highcharts-stock';
  controllerName = 'data.experimental-pricing';

  setupController(controller, model) {
    controller.set('model', model);
    let market = this.modelFor('data.cluster');
    controller.set('pageTitle', `Experimental Pricing -- ${market.get('name')}`);

    this.controllerFor('data').set('comparable', true);
  }

  deactivate() {
    this.controllerFor('data.market').set('csvUrl', false);
    this.controllerFor('data').set('comparable', false);
  }

  model() {
    let cluster = this.modelFor('data.cluster');
    let url = `/admin/api/clusters/${cluster.get('id')}/experimental_pricing`;

    return this.ajax._get(url).then(data => {
      return EmberObject.create({
        pricingModels: data.pricingModels,
        availabilityCurve: data.availabilityCurve,
      });
    });
  }
}
