// Define the product codes
// They must be the same than the ones defined in the backend.
export const PRODUCT = {
  PRICING: 'pricing',
  RELAY: 'relay',
  GUIDANCE: 'guidance',
  SIGNAL: 'signal',
  ONE_OFF: 'one_off',
  PRO_PACKAGE: 'pro_package',
  PRICING_PRO: 'pricing_pro',
  INSIGHTS_PRO: 'insights_pro',
  GROWTH_PACKAGE: 'growth_package',
};

const PRODUCT_CONFIG = {
  [PRODUCT.PRICING]: { label: 'Pricing' },
  [PRODUCT.RELAY]: { label: 'Relay' },
  [PRODUCT.GUIDANCE]: { label: 'Guidance' },
  [PRODUCT.SIGNAL]: { label: 'Signal' },
  [PRODUCT.ONE_OFF]: { label: 'One Off' },
  [PRODUCT.PRO_PACKAGE]: { label: 'Pro Package' },
  [PRODUCT.INSIGHTS_PRO]: { label: 'Insights Pro' },
  [PRODUCT.PRICING_PRO]: { label: 'Pricing Pro' },
  [PRODUCT.GROWTH_PACKAGE]: { label: 'Growth Package' },
};

export class ProductUtil {
  static getConf(code) {
    return PRODUCT_CONFIG[code];
  }

  static getLabel(code) {
    let conf = ProductUtil.getConf(code);
    if (conf) {
      return conf.label;
    }
    return null;
  }
}
