import BaseModel from 'appkit/bp-models/base_model';
import EmberObject from '@ember/object';

export default BaseModel.extend({
  name: '',
  content: EmberObject.create({
    search: null,
    appearance: null,
    base_price_max: null,
    base_price_min: null,
    selected_bedrooms: null,
    health_score_max: null,
    health_score_min: null,
    listing_ids: null,
    selected_accounts: null,
    selected_market_names: null,
    selected_category_1_names: null,
    selected_category_2_names: null,
    listings_to_include: [],
    listings_to_exclude: [],
    listings_to_compare: [],
    selected_origin_for_radius: null,
    distance_max: null,
    distance_min: null,
    show_disabled: null,
    show_enabled: null,
    show_on_program: null,
    show_off_program: null,
    sort_field: null,
    sort_order: null,
    hide_unavailable: null,
    filter_by_need_review: null,
  }),
  currentId: null,
  current_user: null,
});
