import buttonBaseTheme from '../button/base.theme';
import buttonTheme from '../button/index.theme';
import { VARIANTS, COLORS, extendTheme, baseTheme } from '../theme';
const { fontSizeClasses, spacingClasses } = baseTheme;

const defaultClasses = extendTheme(buttonBaseTheme.root.defaultClasses, () => [
  '-ml-px',
  'first:rounded-l',
  'last:rounded-r',
  'mt-1',
]);

const variantClasses = extendTheme(buttonBaseTheme.root.variantClasses, () => ({
  [VARIANTS.ALL]: {
    [COLORS.ALL]: ['border'],
    [COLORS.BRAND]: ['border-brand-600'],
    [COLORS.GRAY]: ['border-gray-600'],
    [COLORS.SUCCESS]: ['border-success-600'],
    [COLORS.ERROR]: ['border-error-600'],
  },
}));

const borders = [VARIANTS.ALL];

export default {
  root: {
    defaultClasses,
    variantClasses,
    spacingClasses,
    fontSizeClasses,
    borders,
  },
  icon: buttonTheme.icon,
  startIcon: buttonTheme.startIcon,
  endIcon: buttonTheme.endIcon,
};
