import moment from 'moment';
import Transform from '@ember-data/serializer/transform';

export default class MomentTransform extends Transform {
  deserialize(serialized) {
    return serialized ? moment(serialized) : serialized;
  }

  serialize(deserialized) {
    return moment.isMoment(deserialized) ? deserialized.format() : deserialized;
  }
}
