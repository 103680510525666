/*
***** Not meant to be called directly ****
=> use `round_to_nearest` below directly instead.

*/
const _roundToNearest = function (x, endNumber) {
  // Make sure we're working with integers
  x = Math.round(x);

  const supported = [0, 5, 9, 10];
  if (!supported.includes(endNumber)) {
    throw new Error(`end number ${endNumber} not supported`);
  }

  if (endNumber === 10) {
    endNumber = 0;
  }

  const xStr = x.toString();
  const endNumberStr = endNumber.toString();

  // Already ends with the wanted number
  if (xStr[xStr.length - 1] === endNumberStr) {
    return x;
  }

  // Generate first possibility
  // Replace end number with the wanted one
  const first = Number(xStr.substring(0, xStr.length - 1) + endNumberStr);
  let second = null;

  // Generate second possibility by adding or removing 10 from the first one
  if (first < x) {
    second = first + 10;
  } else {
    second = first - 10;
  }

  const firstDistance = Math.abs(x - first);
  const secondDistance = Math.abs(x - second);

  // Pick the first one if the distance is smaller
  if (firstDistance < secondDistance) {
    return first;
  } else if (firstDistance > secondDistance) {
    // The second one otherwise
    return second;
  }

  // The distances are equal, pick the higher number
  return Math.max(first, second);
};

/*
Round to the nearest number ending with endNumbers.
`endNumbers` can be a list or a single number.

Supported values: 0, 5, 9, 10(=0)

Examples:
    round_to_nearest(154, 0) => 150
    round_to_nearest(155, 0) => 160
    round_to_nearest(159, 5) => 155
    round_to_nearest(160, 5) => 165
    round_to_nearest(163, 9) => 159
    round_to_nearest(164, 9) => 169
    round_to_nearest(157, [5, 10]) => 155
    round_to_nearest(158, [5, 10]) => 160
    round_to_nearest(166, [5, 9]) => 165
    round_to_nearest(167, [5, 9]) => 169

 */
export const roundToNearest = function (x, endNumbers) {
  // Make sure we're working with integers
  x = Math.round(x);

  // Not several numbers to look for, return for single number directly
  if (!Array.isArray(endNumbers)) {
    return _roundToNearest(x, endNumbers);
  }
  if (endNumbers.length === 1) {
    return _roundToNearest(x, endNumbers[0]);
  }

  // If there are several possible end numbers, calculate each one first
  let possibilities = endNumbers.map(endNumber => {
    return _roundToNearest(x, endNumber);
  });

  // Calculate distances
  let distances = {};
  possibilities.forEach(p => {
    let distance = Math.abs(x - p);
    if (!(distance in distances)) {
      distances[distance] = [];
    }
    distances[distance].push(p);
  });

  // Get the minimum distance
  // Cast to numbers because Object.keys returns strings
  const min_distance = Math.min(...Object.keys(distances));

  // Sort the numbers from the same minimum distance and keep
  // the highest number
  return distances[min_distance].sort()[distances[min_distance].length - 1];
};
