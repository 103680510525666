import SelectedItemMultiple from '../../../../../bp/select/parts/selected-item/multiple';
import { Themeable, VARIANTS } from '../../../../../bp/theme';
import SelectedItemMultipleTheme from './index.theme';

export default class SelectBedroomsTriggerComponent extends Themeable(
  SelectedItemMultiple,
  {
    theme: SelectedItemMultipleTheme,
    variant: VARIANTS.TEXT,
  }
) {
  get optionText() {
    const { index, select } = this.args;
    let text = this.args.option;
    if (index < select.selected.length - 1) {
      text = text + ', ';
    }

    return text;
  }
}
