import flatten from 'lodash.flatten';
import { ChannelUtil } from 'appkit/lib/channel';
import { CHANNEL } from 'appkit/lib/channel_configuration';

/**
 * Defines permissions at Master Listing level, for an invidual or a group of merged listings.
 *
 * Some features are enabled at admin level, but not to general public.
 * This defines the general rules available in production.
 */
export function getRelayListingPermissions({ listings, featureFlags }) {
  const { PMSList, directChannels } = getAllPMSAndDirectChannels(listings);

  return {
    permissions: {
      // Markups & dynamic markups, atm are based on USER-level permissions
      canSetPricingSync: getPricingSyncPermission(listings),
      canSetAvailabilitySync: getAvailabilitySyncPermission(listings),
      canSetReservationSync: getReservationSyncPermission(listings, PMSList),
      canSetContentSync: getContentSyncPermission(
        PMSList,
        directChannels,
        featureFlags
      ),
      canShowMinStaysSyncMessage: showMinStaysSyncMessage(directChannels),
    },
  };
}

/**
 * Enables the user to enable/disable Price Syncing
 *
 * After last meeting, we decided to delegate that into Pricing, and remove existing code related to this,
 * as there were weird interactions with the 'relay-without-pricing' feature.
 *
 * Pricing will be set from Pricing app, not from Relay
 */
function getPricingSyncPermission() {
  return false;
}

/**
 * Enables the user to enable/disable Reservation Sync and define Tax type
 * This allows to create reservations at PMS
 *
 * - Atm, in FE we want to block this unless PMS is Escapia
 */
function getReservationSyncPermission(masterListings, PMSList) {
  const availablePMS = [CHANNEL.ESCAPIA, 'fake_pms'];

  const isChannelSupported = masterListings.some(l => l.reservationIsSupported);
  const isPMSSupported = isSupported(PMSList, availablePMS);

  return isPMSSupported && isChannelSupported;
}

/**
 * Enables the user to enable/disable Availability Sync
 * This allows to pull reservations from the PMS
 */
function getAvailabilitySyncPermission(masterListings) {
  const isSupported = masterListings.some(l => l.availabilityIsSupported);

  return isSupported;
}

/**
 * Enables automatic Content Sync from PMS to direct channels
 */
// eslint-disable-next-line no-unused-vars
function getContentSyncPermission(PMSList, directChannels, featureFlags) {
  const availableChannels = [CHANNEL.AIRBNB, CHANNEL.AIRBNB_PARTNER, 'fake'];
  const availablePMS = [CHANNEL.ESCAPIA, 'fake_pms'];

  const isPMSSupported = isSupported(PMSList, availablePMS);
  const isChannelSupported = isSupported(directChannels, availableChannels);

  return isPMSSupported && isChannelSupported;
}

/**
 * Provides extra info on Merge
 */
function showMinStaysSyncMessage(directChannels) {
  // Because for airBnB historically users can decide if they want Beyond to manage min stays.
  // They can get confused because we ignore this setting if the listing is merged.

  const shouldShow = directChannels.some(dc => dc.name === CHANNEL.AIRBNB);

  return shouldShow;
}

/** Get all the PMS and directChannels from a listing group */
export function getAllPMSAndDirectChannels(listings) {
  const allChannelListings = flatten(listings.map(l => l.channelListings));

  const PMSList = [];
  const directChannels = [];

  for (const channelListing of allChannelListings) {
    ChannelUtil.isPms(channelListing.channel)
      ? PMSList.push(channelListing)
      : directChannels.push(channelListing);
  }

  return { PMSList, directChannels };
}

function isSupported(channelListings, whiteList) {
  return channelListings.every(cl => whiteList.includes(cl.channel));
}
