import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class RelayConnectRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service router;
  @service intl;

  title() {
    let title = null;
    title = `${this.intl.t('relay.relay')} / Connect`;
    this.globalNav.setTitle(title);
    return title;
  }
}
