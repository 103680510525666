import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='w-16 flex justify-center'>\n  <div class='flex items-center w-12 truncate'>\n    {{#if (is-present this.percentage)}}\n      {{#if (lt this.percentage 0)}}\n        <AppSvg @src='triangle-down' @class='h-3 w-3 fill-current text-error-400' />\n      {{else}}\n        <AppSvg @src='triangle-up' @class='h-3 w-3 fill-current text-success-500' />\n      {{/if}}\n      <span class='w-full text-right'>\n        {{abs this.percentage}}%\n      </span>\n    {{else}}\n      <span class='w-full text-right'>\n        --\n      </span>\n    {{/if}}\n  </div>\n</div>", {"contents":"<div class='w-16 flex justify-center'>\n  <div class='flex items-center w-12 truncate'>\n    {{#if (is-present this.percentage)}}\n      {{#if (lt this.percentage 0)}}\n        <AppSvg @src='triangle-down' @class='h-3 w-3 fill-current text-error-400' />\n      {{else}}\n        <AppSvg @src='triangle-up' @class='h-3 w-3 fill-current text-success-500' />\n      {{/if}}\n      <span class='w-full text-right'>\n        {{abs this.percentage}}%\n      </span>\n    {{else}}\n      <span class='w-full text-right'>\n        --\n      </span>\n    {{/if}}\n  </div>\n</div>","moduleName":"appkit/components/insights/lens/card/comparison-cell.hbs","parseOptions":{"srcName":"appkit/components/insights/lens/card/comparison-cell.hbs"}});
import Component from '@glimmer/component';

export default class InsightsLensCardComparisonCellComponent extends Component {
  get value() {
    return this.args.value ? parseFloat(this.args.value) : 0;
  }

  get compareTo() {
    return this.args.compareTo ? parseFloat(this.args.compareTo) : 0;
  }

  get percentage() {
    if (isNaN(this.value) || isNaN(this.compareTo) || this.compareTo === 0) {
      return null;
    }

    return Math.round(100 - (this.value * 100) / this.compareTo) * -1;
  }
}
