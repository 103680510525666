import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class RatesRoute extends AuthRoute {
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('enablePricingOnArrival', null);
    }
  }
}
