import { tracked } from '@glimmer/tracking';
import moment from 'moment';

import FiltersStateService, { BaseFiltersState } from './base-filters';

class InsightsFiltersState extends BaseFiltersState {
  // Selected options
  @tracked bookedAtEndDate;
  @tracked bookedAtStartDate;
  @tracked isGetMarketData = false;
  @tracked isGetClusterData = false;
  @tracked isGetCityData = false;
  @tracked listingPerformanceEndDate = moment();
  @tracked listingPerformanceStartDate = moment();
  @tracked selectedClusterNames = [];
  @tracked selectedCities = [];
  @tracked selectedCurrency = null;
  @tracked selectedListingsToCompare = [];

  toJSON() {
    const json = super.toJSON();
    return {
      ...json,
      bookedAtStartDate: this.bookedAtStartDate,
      bookedAtEndDate: this.bookedAtEndDate,
      isGetMarketData: this.isGetMarketData,
      isGetClusterData: this.isGetClusterData,
      isGetCityData: this.isGetCityData,
      listingPerformanceEndDate: this.listingPerformanceEndDate,
      listingPerformanceStartDate: this.listingPerformanceStartDate,
      selectedClusterNames: this.selectedClusterNames,
      selectedCities: this.selectedCities,
      selectedCurrency: this.selectedCurrency,
      selectedListingsToCompare: this.selectedListingsToCompare,
    };
  }
}

export default class InsightsFiltersStateService extends FiltersStateService(
  InsightsFiltersState
) {}
