import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';

@classic
export default class AppLastMinuteMinStay extends Component {
  @tracked dirtyLastMinuteMinStay = this.get('lastMinuteMinStay');

  @action
  minDaysUpdated(value) {
    this.dirtyLastMinuteMinStay = {
      ...this.dirtyLastMinuteMinStay,
      minDays: Number(value),
    };
  }

  @action
  daysAwayUpdated(value) {
    this.dirtyLastMinuteMinStay = {
      ...this.dirtyLastMinuteMinStay,
      daysAway: Number(value),
    };
  }

  @action
  directionUpdated(value) {
    this.dirtyLastMinuteMinStay = {
      ...this.dirtyLastMinuteMinStay,
      direction: value,
    };
    this.lastMinuteMinStayValueChanged('direction');
  }

  @action
  onRemoveLastMinuteMinStay(lastMinuteMinStay) {
    this.removeLastMinuteMinStay(lastMinuteMinStay);
  }

  @action
  lastMinuteMinStayValueChanged(fieldName) {
    this.set(`lastMinuteMinStay.${fieldName}`, this.dirtyLastMinuteMinStay[fieldName]);

    let value = this.get(`lastMinuteMinStay.${fieldName}`);
    if (value <= 0) {
      this.set(`lastMinuteMinStay.${fieldName}Error`, 'Value should be above zero');
    } else {
      this.set(`lastMinuteMinStay.${fieldName}Error`, null);
    }

    this.onUpdate();
  }
}
