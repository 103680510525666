import classic from 'ember-classic-decorator';
import { on } from '@ember-decorators/object';
import Controller, { inject as controller } from '@ember/controller';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { CurrencyUtil } from 'appkit/lib/currency';
import { chartOptions, chartColors, toGradient } from 'appkit/lib/insights/charts';
import { CHANNEL } from 'appkit/lib/channel_configuration';
import EmberObject, { action, computed, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import cloneDeep from 'lodash.clonedeep';

@classic
export default class ChartController extends Controller {
  @service
  intl;

  @service featureFlag;
  @service router;

  @controller('listing') listingController;
  @controller('dashboard') dashboardController;

  @tracked disabledFactors = null;

  @on('init')
  _disabledFactorsInit_() {
    window.addEventListener('resize', () => {
      set(
        this,
        'listingController.calendarEditExpandedAnimEnd',
        !this.listingController.calendarEditExpandedAnimEnd
      );
    });
    this.set(
      'disabledFactors',
      EmberObject.create({
        dow: false,
        seasonality: false,
        event: false,
        seasonalMins: false,
        seasonalMaxes: false,
      })
    );
  }

  @computed('model.listing.{basePrice,minPrice,currency}')
  get chartOptions() {
    let currency = this.get('model.listing.currency');

    const that = this;

    let yAxisData = [
      {
        labels: {
          style: {
            font: '14px Europa',
          },
        },
        title: {
          text: this.intl.t('pricing.listing.price'),
        },
        min: 0,
        gridLineColor: 'transparent',
        tickColor: 'transparent',
      },
      {
        labels: {
          style: {
            font: '14px Europa',
          },
        },
        title: {
          text: '% ' + this.intl.t('pricing.listing.occupancy'),
        },
        opposite: true,
        format: '{value}°%',
        min: 0,
        max: 100,
        floor: 0,
        ceiling: 100,
        gridLineColor: 'transparent',
        tickColor: 'transparent',
      },
    ];

    let extChartOptions = chartOptions({
      locale: this.intl.locale,
    });

    extChartOptions.yAxis = yAxisData;
    extChartOptions.tooltip = {
      ...extChartOptions.tooltip,
      shared: true,
      pointFormatter: function () {
        const occupancyLabel = that.intl.t('pricing.listing.occupancy');
        const blockedLabel = that.intl.t('glossary.blocked');
        const totalLabel = that.intl.t('common.total');
        return (
          '<span style="color:' +
          this.color +
          '">●</span>\n' +
          '<span>' +
          this.series.name +
          ': </span>\n' +
          '<span style="font-weight:bold">' +
          (this.series.name === that.intl.t('pricing.listing.priorYearMonthlyOccupancy')
            ? `${totalLabel}: ${this.y}% (${this.occupancy}% ${occupancyLabel} / ${this.blocked}% ${blockedLabel})`
            : CurrencyUtil.format(this.y, { currency: currency })) +
          '</span><br/>'
        );
      },
      xDateFormat: '%L',
      formatter: null,
      split: false,
    };
    extChartOptions.chart = {
      ...extChartOptions.chart,
      animation: false,
    };
    extChartOptions.plotOptions = {
      ...extChartOptions.plotOptions,
      areaspline: {
        states: { hover: { lineWidthPlus: 0.5, halo: { size: 7 } } },
        marker: {
          symbol: 'circle',
          states: { hover: { radius: 3, radiusPlus: 2 } },
        },
        events: {
          afterAnimate: this.getVisibility(),
        },
      },
    };
    extChartOptions.chart = { ...extChartOptions.chart, height: '450px' };

    return extChartOptions;
  }

  getVisibility() {
    if (this.visibilityReady != 0) return;

    setTimeout(() => {
      this.visibilityReady = 1;
    }, 500);
  }

  @tracked visibilityReady = 0;

  // We can't use computed properties since we don't control where the chart
  // visibility is set. Instead we add an event handler and use the last
  // visibility setting when redrawing the series.
  @tracked visible = {
    price: true,
    basePrice: true,
    minPrice: true,
    bookingsADR: true,
    currentADR: true,
    priorYearMaxADR: false,
    priorYearMonthlyADR: false,
    priorTwoYearMaxADR: false,
    priorTwoYearMonthlyADR: false,
    occupancy: false,
  };

  @computed(
    'model.listing.{enabled,basePrice,minPrice,channelNames}',
    'disabledFactors.{dow,seasonality,event,seasonalMins,seasonalMaxes}'
  )
  get predictedPrice() {
    return this.setSeasonalsInPredictedPrice();
  }

  get minPrice() {
    return this.setSeasonalsInMinPrice();
  }

  @computed('router.@each')
  get isOutOfDashboard() {
    return !this.router.currentRouteName.split('.').includes('dashboard');
  }

  @computed('model.stats.@each')
  get statsLoaded() {
    if (this.isOutOfDashboard) return false;
    return this.model.stats == undefined;
  }

  @computed('model.calendar.[].@each')
  get oneYearCalendar() {
    return [
      ...this.get('model.calendar').filter(row => {
        return moment(row.get('date')).isAfter(moment().format('YYYY-MM-DD'));
      }),
    ];
  }

  @computed(
    'listingController.calendarEditExpandedAnimEnd',
    'visibilityReady',
    'statsLoaded',
    'predictedPrice.@each.[]',
    'model.{calendar.[],stats.monthlyStats,lastUpdatedAt}',
    'model.listing.{enabled,basePrice,minPrice,channelNames}',
    'disabledFactors.{dow,seasonality,event,seasonalMins,seasonalMaxes}',
    'visible.{price,basePrice,minPrice,bookingsADR,currentADR,priorYearMaxADR,priorYearMonthlyADR,priorTwoYearMaxADR,priorTwoYearMonthlyADR,occupancy}'
  )
  get chartData() {
    let enabled = this.get('model.listing.enabled');

    let out = [
      {
        name: enabled
          ? this.intl.t('pricing.listing.price')
          : this.intl.t('pricing.listing.predictedPrice'),
        color: chartColors[2][0],
        lineColor: chartColors[2][0],
        fillColor: toGradient(chartColors[2]),
        lineWidth: 1.5,
        tooltip: { valueDecimals: 0 },
        visible: this.visible.price,
        data: this.setChartsDataset(this.predictedPrice),
        events: this.toggleSerieVisibility('price'),
        opacity: this.visibilityReady,
      },
      {
        name: this.intl.t('glossary.basePrice'),
        color: chartColors[1][0],
        lineColor: chartColors[1][0],
        fillOpacity: 0,
        dashStyle: 'dashdot',
        lineWidth: 1.5,
        tooltip: { valueDecimals: 0 },
        visible: this.visible.basePrice,
        data: this.setChartsDataset([
          ...this.oneYearCalendar.map(row => {
            let stamp = moment.utc(row.get('date')).valueOf();
            return [stamp, this.model.listing.basePrice];
          }),
        ]),
        events: this.toggleSerieVisibility('basePrice'),
        opacity: this.visibilityReady,
      },
      {
        name: this.intl.t('glossary.minPrice'),
        color: 'rgba(128, 128, 0, 0.6)',
        lineColor: 'rgba(128, 128, 0, 0.6)',
        fillOpacity: 0,
        dashStyle: 'shortdash',
        lineWidth: 1.5,
        tooltip: { valueDecimals: 0 },
        visible: this.visible.minPrice,
        data: this.setChartsDataset(this.minPrice),
        events: this.toggleSerieVisibility('minPrice'),
        opacity: this.visibilityReady,
      },
    ];

    if (!enabled) {
      const channelNames = this.get('model.listing.channelNames') || [];
      const adrLabel = channelNames.includes(CHANNEL.SUPERCONTROL)
        ? this.intl.t('pricing.listing.weeklySevenAdr')
        : this.intl.t('pricing.listing.currentPriceAdr');

      out = [
        ...out,
        {
          name: adrLabel,
          color: chartColors[3][0],
          lineColor: chartColors[3][0],
          fillOpacity: 0,
          lineWidth: 1.5,
          tooltip: { valueDecimals: 0 },
          visible: this.visible.currentADR,
          data: this.setChartsDataset([
            ...this.oneYearCalendar.map(row => {
              let stamp = moment.utc(row.get('date')).valueOf();
              return [stamp, row.get('priceScraped')];
            }),
          ]),
          events: this.toggleSerieVisibility('currentADR'),
          opacity: this.visibilityReady,
        },
      ];
    }
    if (!this.isOutOfDashboard) {
      out = [
        ...out,
        {
          name: this.intl.t('pricing.listing.futureBookingsAdr'),
          color: 'rgba(255, 165, 0, 0.6)',
          lineColor: 'rgba(255, 165, 0, 0.6)',
          fillOpacity: 0,
          lineWidth: 1.5,
          tooltip: { valueDecimals: 0 },
          visible: this.visible.bookingsADR,
          data: this.setChartsDataset([
            ...this.oneYearCalendar.map(row => {
              let stamp = moment.utc(row.get('date')).valueOf();

              let value = 0;

              if (row.reservations.length > 0) {
                const totalAdr = row.reservations.reduce((a, b) => a + b.adr, 0);
                value = totalAdr / row.reservations.length;
              }

              return [stamp, value];
            }),
          ]),
          events: this.toggleSerieVisibility('bookingsADR'),
          opacity: this.visibilityReady,
        },
      ];
    }

    // If we have monthlyStats, then add ADR data lines
    if (this.get('model.stats.monthlyStats')) {
      let stats = {};
      this.get('model.stats.monthlyStats').forEach(
        value => (stats[value['month']] = value)
      );
      let calendar = this.oneYearCalendar;
      let dates = [...calendar.map(row => cloneDeep(row.get('date')))];
      let lastYearDates = [...dates.map(date => cloneDeep(date).subtract(1, 'years'))];
      let secondLastYearDates = [
        ...lastYearDates.map(date => cloneDeep(date).subtract(1, 'years')),
      ];

      // last year
      out = [
        ...out,
        {
          name: this.intl.t('pricing.listing.priorYearMaxAdr'),
          color: chartColors[4][0],
          lineColor: chartColors[4][0],
          fillOpacity: 0,
          lineWidth: 1.5,
          tooltip: { valueDecimals: 0 },
          visible: this.visible.priorYearMaxADR,
          data: this.oneYearCalendar.map((row, index) => {
            let lastYearMonth = lastYearDates[index].format('YYYY-MM');
            let stamp = moment.utc(row.get('date')).valueOf();
            let value = stats[lastYearMonth] ? stats[lastYearMonth]['adrMax'] : 0;
            return [stamp, value];
          }),
          events: this.toggleSerieVisibility('priorYearMaxADR'),
        },
        {
          name: this.intl.t('pricing.listing.priorYearMonthlyAdr'),
          color: chartColors[5][0],
          lineColor: chartColors[5][0],
          fillOpacity: 0,
          lineWidth: 1.5,
          tooltip: { valueDecimals: 0 },
          visible: this.visible.priorYearMonthlyADR,
          data: this.oneYearCalendar.map((row, index) => {
            let lastYearMonth = lastYearDates[index].format('YYYY-MM');
            let stamp = moment.utc(row.get('date')).valueOf();
            let value = stats[lastYearMonth] ? stats[lastYearMonth]['adr'] : 0;
            return [stamp, value];
          }),
          events: this.toggleSerieVisibility('priorYearMonthlyADR'),
        },
      ];

      // second last year
      out = [
        ...out,
        {
          name: this.intl.t('pricing.listing.2YearsAgoMaxAdr'),
          color: chartColors[6][0],
          lineColor: chartColors[6][0],
          fillOpacity: 0,
          lineWidth: 1.5,
          tooltip: { valueDecimals: 0 },
          visible: this.visible.priorTwoYearMaxADR,
          data: this.oneYearCalendar.map((row, index) => {
            let secondLastYearMonth = secondLastYearDates[index].format('YYYY-MM');
            let stamp = moment.utc(row.get('date')).valueOf();
            let value = stats[secondLastYearMonth]
              ? stats[secondLastYearMonth]['adrMax']
              : 0;
            return [stamp, value];
          }),
          events: this.toggleSerieVisibility('priorTwoYearMaxADR'),
        },
        {
          name: this.intl.t('pricing.listing.2YearsAgoMonthlyAdr'),
          color: chartColors[7][0],
          lineColor: chartColors[7][0],
          fillOpacity: 0,
          lineWidth: 1.5,
          tooltip: { valueDecimals: 0 },
          visible: this.visible.priorTwoYearMonthlyADR,
          data: this.oneYearCalendar.map((row, index) => {
            let secondLastYearMonth = secondLastYearDates[index].format('YYYY-MM');
            let stamp = moment.utc(row.get('date')).valueOf();
            let value = stats[secondLastYearMonth]
              ? stats[secondLastYearMonth]['adr']
              : 0;
            return [stamp, value];
          }),
          events: this.toggleSerieVisibility('priorTwoYearMonthlyADR'),
        },
      ];

      out = [
        ...out,
        {
          name: this.intl.t('pricing.listing.priorYearMonthlyOccupancy'),
          color: 'rgba(250, 128, 114, 0.3)',
          lineColor: 'rgba(250, 128, 114, 0.3)',
          fillColor: 'rgba(250, 128, 114, 0.1)',
          lineWidth: 1.5,
          tooltip: { valueDecimals: 0 },
          visible: this.visible.occupancy,
          yAxis: 1,
          zIndex: -1,
          data: this.oneYearCalendar.map((row, index) => {
            let lastYearMonth = lastYearDates[index].format('YYYY-MM');
            let stamp = moment.utc(row.get('date')).valueOf();
            return {
              x: stamp,
              y: stats[lastYearMonth]
                ? stats[lastYearMonth]['occupancy'] +
                  stats[lastYearMonth]['blockedOccupancy']
                : 0,
              blocked: stats[lastYearMonth]
                ? stats[lastYearMonth]['blockedOccupancy']
                : 0,
              occupancy: stats[lastYearMonth] ? stats[lastYearMonth]['occupancy'] : 0,
            };
          }),
          events: this.toggleSerieVisibility('occupancy'),
        },
      ];
    }

    return out;
  }

  @action
  toggleSerieVisibility(serie) {
    return {
      show: () => {
        this.set(`visible.${serie}`, true);
      },
      hide: () => {
        this.set(`visible.${serie}`, false);
      },
    };
  }

  @action
  toggleFactor(factor) {
    let value = !this.get(`disabledFactors.${factor}`);
    this.set(`disabledFactors.${factor}`, value);
    this.model.calendar.forEach(date => date.set(`disabledFactors.${factor}`, value));
  }

  @action
  toggleSeasonalMins() {
    this.disabledFactors = {
      ...this.disabledFactors,
      seasonalMins: !this.disabledFactors.seasonalMins,
    };
  }

  @action
  toggleSeasonalMaxes() {
    this.disabledFactors = {
      ...this.disabledFactors,
      seasonalMaxes: !this.disabledFactors.seasonalMaxes,
    };
  }

  setSeasonalsInPredictedPrice() {
    return [
      ...this.oneYearCalendar.map(row => {
        let stamp = moment.utc(row.get('date')).valueOf();
        let seasonalMinFactor = row
          .get('priceFactors')
          .find(f => f.key == 'seasonal_min_price');
        let seasonalMaxFactor = row
          .get('priceFactors')
          .find(f => f.key == 'seasonal_max_price');

        let price;
        if (this.disabledFactors.seasonalMins) {
          if (
            row.get('listing.minPrice') &&
            row.get('listing.minPrice') >
              row.get('priceUserOrModeled') - (seasonalMinFactor?.amount || 0)
          ) {
            price = row.get('listing.minPrice');
          } else {
            price = row.get('priceUserOrModeled') - (seasonalMinFactor?.amount || 0);
          }
        } else {
          price = row.get('priceUserOrModeled');
        }

        if (this.disabledFactors.seasonalMaxes) {
          if (
            row.get('listing.maxPrice') &&
            row.get('listing.maxPrice') < price - (seasonalMaxFactor?.amount || 0)
          ) {
            price = row.get('listing.maxPrice');
          } else {
            price = price - (seasonalMaxFactor?.amount || 0);
          }
        }

        return [stamp, price];
      }),
    ];
  }

  setSeasonalsInMinPrice() {
    return [
      ...this.oneYearCalendar.map(row => {
        let stamp = moment.utc(row.get('date')).valueOf();
        return [
          stamp,
          !this.disabledFactors.seasonalMins
            ? row.get('minPriceUser') || row.get('listing.minPrice')
            : row.get('listing.minPrice'),
        ];
      }),
    ];
  }

  setChartsDataset(dataset) {
    return !this.statsLoaded
      ? dataset
      : [
          ...this.oneYearCalendar.map(row => {
            let stamp = moment.utc(row.get('date')).valueOf();
            return [stamp, 0];
          }),
        ];
  }
}
