const ONLY_NUMBERS_REGEX = /^\d+$/;

/** Returns an array with all errors in a password */
function validatePassword(password, confirmation = null, checkConfirmation = false) {
  let errors = [];

  if (password.length < 1) {
    errors = errors.concat('Password must be present');
  }

  if (password.length < 9) {
    errors = errors.concat(
      'This password is too short. It must contain at least 9 characters.'
    );
  }

  if (ONLY_NUMBERS_REGEX.test(password)) {
    errors = errors.concat('This password is entirely numeric.');
  }

  if (checkConfirmation && password !== confirmation) {
    errors = errors.concat("Passwords don't match.");
  }

  return errors;
}

/** Checks if a password is valid and returns the first error if it is invalid. */
export function checkPassword(password, options = {}) {
  const { confirmation, checkConfirmation } = options;

  const errors = validatePassword(password, confirmation, checkConfirmation);
  const error = errors.length > 0 ? errors[0] : [];
  const isValid = errors.length === 0;
  return { isValid, error };
}
