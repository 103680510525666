import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import { action } from '@ember/object';

@classic
export default class SeasonalityRoute extends AuthRoute {
  templateName = 'data.highcharts-graph';
  controllerName = 'data.seasonality';

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('customizable', false);

    let market = this.modelFor('data.market');
    controller.set('pageTitle', `Seasonality -- ${market.get('name')}`);
    let url = `${location.origin}/admin/api/markets/${market.get(
      'id'
    )}/airbnb/seasonality.csv?token=${this.currentUser.get('token')}`;
    this.controllerFor('data.market').set('csvUrl', url);
    let csvFilename = `airbnb-seasonality_${market.get('name')}.csv`;
    this.controllerFor('data.market').set('csvFilename', csvFilename);
  }

  deactivate() {
    this.controllerFor('data.market').set('csvUrl', false);
  }

  model() {
    let id = this.modelFor('data.market').get('id');
    let url = `/admin/api/markets/${id}/seasonality`;

    return this.ajax._get(url).then(data => {
      let out = {
        airbnb: [],
        homeaway: [],
      };
      Object.keys(data)
        .sort()
        .forEach(key => {
          let month = Math.floor(key / 100);
          let day = key % 100;
          let date = Date.UTC(2016, month - 1, day);
          out.airbnb.push([date, 100 * data[key][0]]);
          out.homeaway.push([date, 100 * data[key][1]]);
        });
      return out;
    });
  }

  @action
  compareCluster(id) {
    this.controller.send('compareCluster', id);
  }

  @action
  compareMarket(id) {
    this.controller.send('compareMarket', id);
  }
}
