/* eslint-disable ember/no-observers */
import Mixin from '@ember/object/mixin';
import EmberObject, { get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Util from 'appkit/lib/util';
import moment from 'moment';
import { observer } from '@ember/object';
import { typeOf } from '@ember/utils';
import { once } from '@ember/runloop';
import { STATUS_MAPPING } from 'appkit/bp-models/booking_review_listing';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  listControl: service('list-control'),
  intl: service(),

  model: null,

  _distanceBetween(targetListing, originListing, kmFactor) {
    let earthRadius = 6371e3;
    let olLatToRadians = originListing.latitude * (Math.PI / 180);
    let tlLatToRadians = targetListing.latitude * (Math.PI / 180);

    let deltaLat = (targetListing.latitude - originListing.latitude) * (Math.PI / 180);
    let deltaLng =
      (targetListing.longitude - originListing.longitude) * (Math.PI / 180);

    let formula =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(olLatToRadians) *
        Math.cos(tlLatToRadians) *
        Math.sin(deltaLng / 2) *
        Math.sin(deltaLng / 2);

    let distanceFactor = 2 * Math.atan2(Math.sqrt(formula), Math.sqrt(1 - formula));

    let distance = Math.round(earthRadius * distanceFactor * kmFactor) / 1000;
    return distance;
  },

  // @type {Number}
  flatListingsCount: computed('flatListings.[]', function () {
    const filtered = this.flatListings.filter(
      l =>
        !l.channelListings ||
        l.channelListings.length === 0 ||
        (l.channelListings.length > 0 && l.channelListings[0]?.subUnits?.length === 0)
    );
    return filtered.length;
  }),

  // @type {Number}
  listingsTotalCount: computed(
    'model.listings.@each.primaryChannelListing__account__valid',
    function () {
      let listings = this.get('model.listings');
      listings = listings.filter(l => l.primaryChannelListing__account__valid);
      listings =
        listings.filter(
          l =>
            !l.channelListings ||
            l.channelListings.length === 0 ||
            (l.channelListings.length > 0 &&
              l.channelListings[0]?.subUnits?.length === 0)
        ) || [];
      return listings.length;
    }
  ),

  _preFilterFlatListings: computed(
    'model.listings.@each.{_basePrice,primaryChannelListing__listed,primaryChannelListing__account__valid,primaryChannelListing__account__channelDisplayId,id,city,address,imageOrDefault,inActiveMarket,enabled,monthlyEnabled,healthScore,title,bedrooms,basePriceUpdatedAt,longitude,latitude}',
    'model.channelListings.@each.{categories1,categories2}',
    'listControl.{selectedCategory1Names,selectedCategory2Names,listingsToInclude,listingsToExclude,selectedOriginForRadius,selectedMarketNames,selectedClusterNames,selectedAccountsByName,showEnabled,showDisabled,showOnProgram,showOffProgram,monthlyPricePostingEnabled,monthlyPricePostingDisabled,hideUnavailable,healthScoreForInput,basePriceForInput,selectedBedrooms,bedroomsForInput,distancesForInput,filterByNeedReview}',
    'currentUser.isBookingReviewSubmitterEnabled',
    function () {
      let out = this.get('model.listings');

      // Only if the account is valid
      //TODO: to review to handle several channel listings!
      out = out?.filter(l => l.primaryChannelListing__account__valid);

      // filter for health score
      out = out?.filter(
        l =>
          l.get('healthScore') >= get(this, 'listControl.healthScoreForInput')[0] &&
          l.get('healthScore') <= get(this, 'listControl.healthScoreForInput')[1]
      );

      // filter for base price
      out = out?.filter(
        l =>
          l.get('_basePrice') >= get(this, 'listControl.basePriceForInput')[0] &&
          l.get('_basePrice') <= get(this, 'listControl.basePriceForInput')[1]
      );

      // filter for bedrooms
      if (get(this, 'listControl.selectedBedrooms.length')) {
        out = out?.filter(l =>
          get(this, 'listControl.selectedBedrooms').includes(l.get('bedrooms') || 0)
        );
      }

      // filter for market
      if (this.listControl.selectedMarketNames.length > 0) {
        out = out?.filter(l =>
          this.listControl.selectedMarketNames.includes(l.get('market'))
        );
      }

      // filter for market
      if (this.listControl.selectedClusterNames.length > 0) {
        out = out?.filter(l =>
          this.listControl.selectedClusterNames.includes(l.get('cluster'))
        );
      }

      //filter for category
      let channelListings = this.model?.channelListings;

      if (this.listControl.selectedCategory1Names.length > 0) {
        let channelListingWithCat1 = channelListings?.filter(
          listing =>
            this.listControl.selectedCategory1Names?.filter(category =>
              listing.get('categories1').includes(category.split('__')[0])
            ).length
        );

        channelListingWithCat1 = [
          ...new Set(channelListingWithCat1.map(listing => listing.masterListing)),
        ];

        out = out?.filter(listing => channelListingWithCat1.includes(listing));
      }

      if (this.listControl.selectedCategory2Names.length > 0) {
        let channelListingWithCat2 = channelListings?.filter(
          listing =>
            this.listControl.selectedCategory2Names?.filter(category =>
              listing.get('categories2').includes(category.split('__')[0])
            ).length
        );

        channelListingWithCat2 = [
          ...new Set(channelListingWithCat2.map(listing => listing.masterListing)),
        ];

        out = out?.filter(listing => channelListingWithCat2.includes(listing));
      }

      // filter for distances
      if (this.listControl.selectedOriginForRadius) {
        let originListing = this.listControl.selectedOriginForRadius;
        let kmFactor = this.intl.locale === 'en' ? 0.621371 : 1;

        out = out?.filter(
          l =>
            this._distanceBetween(l, originListing, kmFactor) >=
              this.get('listControl.distancesForInput')[0] &&
            this._distanceBetween(l, originListing, kmFactor) <=
              this.get('listControl.distancesForInput')[1]
        );
      }

      // filter for IdentifierServices
      if (this.listControl.selectedAccountsByName.length > 0) {
        const identifierServicesPairs = this.listControl.selectedAccountsByName.map(
          sis => {
            return {
              displayId: sis.split(' — ')[0],
              channel: sis.split(' — ')[1],
            };
          }
        );

        out = out?.filter(l => {
          for (let cl of l.get('channelListings')) {
            const outerIdentifier = cl.get('account.channelDisplayId');
            const outerService = cl.get('account.channel');
            for (let is of identifierServicesPairs) {
              if (outerIdentifier === is.displayId && outerService === is.channel) {
                return true;
              }
            }
          }
          return false;
        });
      }

      // filter for sync
      if (!this.listControl.showEnabled) {
        out = out?.filter(l => !l.enabled);
      }
      if (!this.listControl.showDisabled) {
        out = out?.filter(l => l.enabled);
      }

      // filter for listing program status
      if (!this.listControl.showOnProgram) {
        out = out?.filter(l => !l.onProgram);
      }
      if (!this.listControl.showOffProgram) {
        out = out?.filter(l => l.onProgram);
      }

      // filter for monthly price posting
      if (!this.listControl.monthlyPricePostingEnabled) {
        out = out?.filter(l => !l.monthlyEnabled);
      }
      if (!this.listControl.monthlyPricePostingDisabled) {
        out = out?.filter(l => l.monthlyEnabled);
      }

      // filter for availability
      if (this.listControl.hideUnavailable) {
        out = out
          ?.filter(l => l.get('primaryChannelListing.listed'))
          ?.filter(l => l.get('inActiveMarket'));
      }

      // filter for booking review
      if (this.listControl.filterByNeedReview) {
        if (this.currentUser.isBookingReviewSubmitterEnabled) {
          // submitter case
          out = out?.filter(l => {
            if (l.mostRecentBookingReview) {
              return (
                l.mostRecentBookingReview.status === STATUS_MAPPING.SUGGESTED ||
                l.mostRecentBookingReview.status === STATUS_MAPPING.SUGGESTION_COMPLETED
              );
            }
          });
        } else {
          // approver case
          out = out?.filter(l => {
            if (l.mostRecentBookingReview) {
              return (
                l.mostRecentBookingReview.status === STATUS_MAPPING.SUGGESTION_COMPLETED
              );
            }
          });
        }
      }

      return out;
    }
  ),

  _filterFlatListings: computed(
    'model.listings.@each.{_basePrice,primaryChannelListing__listed,primaryChannelListing__account__valid,primaryChannelListing__account__channelDisplayId,id,city,address,imageOrDefault,inActiveMarket,enabled,monthlyEnabled,healthScore,title,bedrooms,basePriceUpdatedAt,longitude,latitude}',
    'model.channelListings.@each.{categories1,categories2}',
    'listControl.{selectedCategory1Names,selectedCategory2Names,listingsToInclude,listingsToExclude,selectedOriginForRadius,selectedMarketNames,selectedClusterNames,selectedAccountsByName,showEnabled,showDisabled,showOnProgram,showOffProgram,monthlyPricePostingEnabled,monthlyPricePostingDisabled,hideUnavailable,healthScoreForInput,basePriceForInput,selectedBedrooms,bedroomsForInput,distancesForInput,filterByNeedReview}',
    'currentUser.isBookingReviewSubmitterEnabled',
    function () {
      let out = this._preFilterFlatListings;
      // filter for include/exclude
      const listingsToInclude = this.listControl.listingsToInclude;
      if (listingsToInclude.length > 0) {
        let multiUnitChildListings = [];

        this.get('model.listings').forEach(listing => {
          const multiUnitParentId = listing.channelListings[0]?.multiUnitParentId?.toString();

          if (multiUnitParentId) {
            if (
              out.map(l => l.channelListings[0]?.id).includes(multiUnitParentId) &&
              listingsToInclude
                .map(l => l.channelListings[0]?.id)
                .includes(multiUnitParentId)
            ) {
              multiUnitChildListings.push(listing);
            }
          }
        });

        return [...new Set([...listingsToInclude, ...multiUnitChildListings])];
      }

      if (this.listControl.listingsToExclude.length > 0) {
        out = out?.filter(
          listing => !this.listControl.listingsToExclude.includes(listing)
        );
      }

      return out;
    }
  ),

  _filterSortFlatListings: computed(
    '_filterFlatListings.length',
    'listControl.{sortField,sortOrder}',
    function () {
      const out = this._filterFlatListings;

      // We always want to keep unlisting / inactive places at the bottom,
      // regardless of sort order. We always want to keep enabled at the top and
      // apply the custom sort within it.
      let primarySortFields = {
        'primaryChannelListing.listed': -1,
        inActiveMarket: -1,
        enabled: -1,
      };
      let secondarySortFields = {
        title: 1,
        'statistics.twoWeekReservationsCount': 1,
        'statistics.oneWeekReservationsCount': 1,
        bookedSeven: 1,
        bookedFourteen: 1,
        bookedThirty: 1,
        bookedSixty: 1,
        bookedNinety: 1,
        healthScore: 1,
        'ninetyDaysPercentages.daysHittingMin': 1,
        'ninetyDaysPercentages.daysBlocked': 1,
        'ninetyDaysPercentages.daysBooked': 1,
        basePrice: 1,
        minPrice: 1,
        bedrooms: 1,
        bathrooms: 1,
        basePriceUpdatedAt: 1,
        createdAt: 1,
        market: 1,
        cluster: 1,
        'statistics.lastBookingDate': 1,
        'statistics.furthestCheckinDate': 1,
        'statistics.daysAvailable': 1,
        'statistics.daysBooked': 1,
        'statistics.daysHittingAnyMin': 1,
        'statistics.futureBookingsCount': 1,
        revenueGoal: 1,
        revenueProgress: 1,
      };

      // If we sort by one of these fields we want to skip primary sorting
      let ignorePrimarySortFields = ['createdAt'];

      let userSort = this.listControl.sortField;
      let direction = 1;
      if (this.listControl.sortOrder !== 'asc') {
        direction = -1;
      }

      out?.sort((a, b) => {
        // Always put unlisted listings at the bottom, regardless of direction
        // unless specifically disabled in "ignorePrimarySortFields".
        if (!ignorePrimarySortFields.includes(userSort)) {
          for (let field of Object.keys(primarySortFields)) {
            let modifier = primarySortFields[field];
            if (a.get(field) < b.get(field)) {
              return -1 * modifier;
            }
            if (a.get(field) > b.get(field)) {
              return 1 * modifier;
            }
          }
        }
        // Next sort by the user preferred sort

        // user preferred sort for date field
        if (
          [
            'basePriceUpdatedAt',
            'createdAt',
            'statistics.lastBookingDate',
            'statistics.furthestCheckinDate',
          ].includes(userSort)
        ) {
          // put null at the bottom
          if (a.get(userSort) === null) {
            return 1;
          }
          if (b.get(userSort) === null) {
            return -1;
          }
          // handle date sort
          let modifier = secondarySortFields[userSort];
          if (moment(a.get(userSort)).isBefore(moment(b.get(userSort)))) {
            return -1 * modifier * direction;
          }
          if (moment(a.get(userSort)).isAfter(moment(b.get(userSort)))) {
            return 1 * modifier * direction;
          }
          // user preferred sort for non-date field
        } else {
          // put listings without stats computed or missing in the bottom
          if (a.get(userSort) === '-') {
            return 1;
          }
          if (b.get(userSort) === '-') {
            return -1;
          }

          let modifier = secondarySortFields[userSort];
          if (a.get(userSort) < b.get(userSort)) {
            return -1 * modifier * direction;
          }
          if (a.get(userSort) > b.get(userSort)) {
            return 1 * modifier * direction;
          }
        }

        // Then loop through the rest of the columns
        for (let field of Object.keys(secondarySortFields)) {
          let modifier = secondarySortFields[field];
          if (a.get(field) < b.get(field)) {
            return -1 * modifier * direction;
          }
          if (a.get(field) > b.get(field)) {
            return 1 * modifier * direction;
          }
        }

        return 0;
      });

      return out;
    }
  ),

  _filterSortSearchFlatListings: computed(
    '_filterSortFlatListings.[]',
    'listControl.{debouncedSearch,search}',
    function () {
      let out = this._filterSortFlatListings;

      if (!this.listControl.search) {
        return out;
      }

      let search = new RegExp(
        Util.escapeRegExpPattern(this.listControl.debouncedSearch),
        'i'
      );

      // TODO: loop through channel listings
      let fields = [
        'city',
        'title',
        'address',
        'primaryChannelListing__account__channelDisplayId',
        'id',
      ];

      const listings = out;
      out = listings.filter(item => {
        for (let field of fields) {
          let value = item.get(field);
          if (
            (value && value.toString().match(search)) ||
            (item.channelListings[0]?.multiUnitGroupTitle &&
              item.channelListings[0]?.multiUnitGroupTitle.toString().match(search)) ||
            (item.channelListings[0]?.multiUnitParentId &&
              !item.channelListings[0]?.multiUnitGroupTitle)
          ) {
            return true;
          }
        }
        return false;
      });

      return out;
    }
  ),

  flatListings: computed('_filterSortSearchFlatListings.[]', function () {
    let out = this._filterSortSearchFlatListings;

    if (out) {
      return [...out];
    } else {
      // ensure flatListings returns empty array if not available
      return [];
    }
  }),

  _updateMaxListingsBasePrice: observer('model.listings.@each._basePrice', function () {
    const listings = this.get('model.listings');
    const listingsBasePriceArr = listings.map(listing => listing._basePrice);
    this.set('listControl.maxListingsBasePrice', Math.max(...listingsBasePriceArr, 1));
    // return Math.max(...listingsBasePriceArr, 1);
  }),

  _updateUniqueMarketList: observer(
    'model.listings.@each.primaryChannelListing__account__valid',
    'model.listings.@each.market',
    function () {
      let listings = this.get('model.listings');
      this.set(
        'listControl.uniqueMarketList',
        [
          ...new Set(
            listings
              .filter(listing => listing.primaryChannelListing__account__valid)
              .map(listing => listing.market)
              .filter(i => i)
          ),
        ].sort()
      );
    }
  ),

  _updateUniqueClusterList: observer('model.listings.@each.cluster', function () {
    let listings = this.get('model.listings');
    this.set('listControl.uniqueClusterList', [
      ...new Set(listings.map(listing => listing.cluster).filter(i => i)),
    ]);
  }),

  _accountsWithCustomFields: computed(
    'model.accounts.@each.{categories,channelId}',
    function () {
      let accounts = this.model?.accounts;

      return accounts?.filter(
        account => account.categories && Object.entries(account.categories).length !== 0
      );
    }
  ),

  _listingsWithCat1: computed(
    'model.channelListings.@each.{account,categories1,categories2}',
    function () {
      let listings = this.model?.channelListings;

      return listings?.filter(listing => listing.categories1.length != 0);
    }
  ),

  _listingsWithCat2: computed(
    'model.channelListings.@each.{account,categories1,categories2}',
    function () {
      let listings = this.model?.channelListings;

      return listings?.filter(listing => listing.categories2.length != 0);
    }
  ),

  _selectedListingsWithCat1: computed(
    'listControl.initialQueryParams.selected_category_1_names',
    function () {
      let selectedListingsWithCat1 = this.get(
        'listControl.initialQueryParams.selected_category_1_names'
      )
        ? this.get('listControl.initialQueryParams.selected_category_1_names').split(
            /(?<=__\d+)[-,]/
          )
        : [];
      return selectedListingsWithCat1;
    }
  ),

  _selectedListingsWithCat2: computed(
    'listControl.initialQueryParams.selected_category_2_names',
    function () {
      let selectedListingsWithCat1 = this.get(
        'listControl.initialQueryParams.selected_category_2_names'
      )
        ? this.get('listControl.initialQueryParams.selected_category_2_names').split(
            /(?<=__\d+)[-,]/
          )
        : [];
      return selectedListingsWithCat1;
    }
  ),

  _accountCategoriesDetails: computed(
    'accountsWithCustomFields.@each.{categories1,categories2}',
    'listingsWithCat1.@each.{account,channelId,categories1,categories2}',
    'listingsWithCat2.@each.{account,channelId,categories1,categories2}',
    'selectedListingsWithCat1',
    'selectedListingsWithCat2',
    function () {
      let accountsWithCustomFields = this._accountsWithCustomFields;
      let listingsWithCat1 = this._listingsWithCat1;
      let listingsWithCat2 = this._listingsWithCat2;
      let selectedListingsWithCat1 = this._selectedListingsWithCat1;
      let selectedListingsWithCat2 = this._selectedListingsWithCat2;

      let accountCategoriesDetails = accountsWithCustomFields?.map(account => {
        let cat1name =
          account.categories.categories1 != undefined
            ? account.categories.categories1.name
            : null;

        let cat1options = listingsWithCat1?.filter(
          listing => listing.account === account && listing.categories1 != 0
        );

        let cat2name =
          account.categories.categories2 != undefined
            ? account.categories.categories2.name
            : null;

        let cat2options = listingsWithCat2?.filter(
          listing => listing.account === account && listing.categories2 != 0
        );

        let selectedCat1 = selectedListingsWithCat1?.filter(
          category => category.split('__')[1] === account.id.toString()
        );

        let selectedCat2 = selectedListingsWithCat2?.filter(
          category => category.split('__')[1] === account.id.toString()
        );

        const out = EmberObject.create({
          account: account.channelId,
          channel: account.channel,
          id: account.id,
          category1Selected: selectedCat1,
          category2Selected: selectedCat2,
        });
        if (cat1name) {
          out.category1Name = cat1name;
        }
        if (cat1options.length) {
          const allCategories1 = [
            ...new Set(
              cat1options.reduce(
                (accum, listing) => accum.concat(listing.categories1),
                []
              )
            ),
          ];
          out.category1Options = allCategories1
            ?.map(category => `${category}__${account.id}`)
            ?.sort();
        }
        if (cat2name) {
          out.category2Name = cat2name;
        }
        if (cat2options.length) {
          const allCategories2 = [
            ...new Set(
              cat2options.reduce(
                (accum, listing) => accum.concat(listing.categories2),
                []
              )
            ),
          ];

          out.category2Options = allCategories2
            ?.map(category => `${category}__${account.id}`)
            ?.sort();
        }
        return out;
      });

      return accountCategoriesDetails;
    }
  ),

  _updateCustomFieldsLists: observer(
    'model.channelListings.@each.{account,channelId,categories1,categories2}',
    'listControl.initialQueryParams.{selected_category_1_names,selected_category_2_names}',
    function () {
      let accountCategoriesDetails = this._accountCategoriesDetails;

      accountCategoriesDetails?.forEach(accountDetail => {
        if (accountDetail.category1Options || accountDetail.category2Options) {
          this.set('listControl.viewCategoriesSection', true);
        }
      });
      this.set('listControl.categoriesOptionsLists', [
        ...new Set(accountCategoriesDetails),
      ]);
    }
  ),

  _updateSelectedCategories1: observer(
    'listControl.categoriesOptionsLists.@each.{category1Selected,category2Selected}',
    function () {
      let masterCategories1 = [];
      let masterCategories2 = [];

      this.get('listControl.categoriesOptionsLists').forEach(account => {
        account.category1Selected.forEach(category => {
          masterCategories1.push(category);
        });
        account.category2Selected.forEach(category => {
          masterCategories2.push(category);
        });
      });

      if (
        masterCategories1.length ||
        this.get('listControl.selectedCategory1Names').length
      ) {
        this.set('listControl.selectedCategory1Names', masterCategories1);
      }

      if (
        masterCategories2.length ||
        this.get('listControl.selectedCategory2Names').length
      ) {
        this.set('listControl.selectedCategory2Names', masterCategories2);
      }
    }
  ),

  _updateListingsSetToInclude: observer(
    'listControl.listingsToIncludeIds',
    'model.listings.[]',
    function () {
      let listingsToIncludeIds = this.get('listControl.listingsToIncludeIds')
        ? this.get('listControl.listingsToIncludeIds')
        : [];
      let listings = this.get('model.listings');

      if (!listings || listingsToIncludeIds.length < 1) return;

      let preSetIncludedListings = listings.filter(listing =>
        listingsToIncludeIds.includes(listing.id.toString())
      );

      this.set('listControl.listingsToInclude', [...new Set(preSetIncludedListings)]);
    }
  ),

  _updateListingsToIncludeList: observer(
    'model.listings.[]',
    'flatListings.[]',
    'listControl.{listingsToExclude,listingsToInclude}',
    function () {
      once(this, this.__updateListingsToIncludeList);
    }
  ),

  __updateListingsToIncludeList: function () {
    let listings = this._preFilterFlatListings;
    // let listingsAlreadyExcluded = this.flatListings;
    let listingsInExcludeList = this.get('listControl.listingsToExclude');

    if (listings) {
      let listingsToIncludeList = listings
        // .filter(listing => listingsAlreadyExcluded.includes(listing))
        .filter(
          listing =>
            !listingsInExcludeList.includes(listing) &&
            ((listing.channelListings[0]?.multiUnitParentId &&
              listing.channelListings[0]?.multiUnitGroupTitle) ||
              !listing.channelListings[0]?.multiUnitParentId)
        )
        .concat(this.get('listControl.listingsToInclude'));

      this.set('listControl.listingsToIncludeList', [
        ...new Set(listingsToIncludeList.sort((a, b) => (a.title > b.title ? 1 : -1))),
      ]);
    }
  },

  _updateListingsSetToExclude: observer(
    'listControl.listingsToExcludeIds',
    'model.listings.[]',
    function () {
      once(this, this.__updateListingsSetToExclude);
    }
  ),

  __updateListingsSetToExclude: function () {
    let listingsToExcludeIds = this.get('listControl.listingsToExcludeIds')
      ? this.get('listControl.listingsToExcludeIds')
      : [];
    let listings = this.get('model.listings');

    if (!listings || listingsToExcludeIds.length < 1) return;

    let preSetExcludeListings = listings.filter(listing =>
      listingsToExcludeIds.includes(listing.id.toString())
    );

    this.set('listControl.listingsToExclude', [...new Set(preSetExcludeListings)]);
  },

  _updateListingsToExcludeList: observer(
    'flatListings.[]',
    'model.listings.[]',
    'listControl.{listingsToExclude,listingsToInclude}',
    function () {
      once(this, this.__updateListingsToExcludeList);
    }
  ),

  __updateListingsToExcludeList: function () {
    let listings = this.get('model.listings');
    let listingsToBeMatched = this.flatListings;
    let listingsInIncludeList = this.get('listControl.listingsToInclude');

    let listingsToExcludeList = listings
      .filter(listing => listingsToBeMatched.includes(listing))
      .filter(listing => !listingsInIncludeList.includes(listing))
      .filter(
        listing =>
          (listing.channelListings[0]?.multiUnitParentId &&
            listing.channelListings[0]?.multiUnitGroupTitle) ||
          !listing.channelListings[0]?.multiUnitParentId
      )
      .concat(this.get('listControl.listingsToExclude'));

    this.set('listControl.listingsToExcludeList', [
      ...new Set(listingsToExcludeList.sort((a, b) => (a.title > b.title ? 1 : -1))),
    ]);
  },

  _updateGeoReferenceListingList: observer(
    'model.listings',
    'flatListings.[]',
    'listControl.{selectedOriginForRadius,selectedMarketNames}',
    function () {
      once(this, this.__updateGeoReferenceListingList);
    }
  ),

  __updateGeoReferenceListingList: function () {
    if (!this.get('model.listings')) return;

    let listings = this.get('model.listings').filter(listing =>
      listing.get('primaryChannelListing.canPrice')
    );

    if (listings && this.listControl.selectedMarketNames.length) {
      listings = listings.filter(listing =>
        this.listControl.selectedMarketNames.includes(listing.get('market'))
      );
    }

    this.set('listControl.uniqueGeoReferenceListingList', listings);
  },

  _updateMostfartherListing: observer(
    'model.listings',
    'flatListings.[]',
    'listControl.{selectedOriginForRadius,selectedMarketNames}',
    function () {
      once(this, this.__updateMostfartherListing);
    }
  ),

  __updateMostfartherListing: function () {
    let listings = this.get('model.listings');

    if (!listings) return;

    if (typeOf(this.listControl.get('selectedOriginForRadius')) === 'string') {
      this.listControl.set(
        'selectedOriginForRadius',
        listings.filter(
          listing => listing.id == this.listControl.get('selectedOriginForRadius')
        )[0]
      );
    }

    let referenceListing = this.listControl.get('selectedOriginForRadius');

    if (listings && this.listControl.selectedMarketNames.length) {
      listings = listings.filter(listing =>
        this.listControl.selectedMarketNames.includes(listing.get('market'))
      );
    }

    if (!referenceListing) return;

    let referenceListingLat = referenceListing.latitude;
    let referenceListingLng = referenceListing.longitude;

    let earthRadius = 6371e3;
    let lat1 = referenceListingLat * (Math.PI / 180);
    let lat2,
      listingLat,
      listingLng,
      deltaLat,
      deltaLng,
      formula,
      distanceFactor,
      distance = 0;

    let distancesBetweenlistings = [];
    let distanceFromfartherListing;

    listings.forEach(listing => {
      listingLat = listing.latitude;
      listingLng = listing.longitude;

      lat2 = listingLat * (Math.PI / 180);

      deltaLat = (listingLat - referenceListingLat) * (Math.PI / 180);
      deltaLng = (listingLng - referenceListingLng) * (Math.PI / 180);

      formula =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1) *
          Math.cos(lat2) *
          Math.sin(deltaLng / 2) *
          Math.sin(deltaLng / 2);

      distanceFactor = 2 * Math.atan2(Math.sqrt(formula), Math.sqrt(1 - formula));

      distance = Math.round(earthRadius * distanceFactor) / 1000;

      distancesBetweenlistings.push(distance);
    });

    distanceFromfartherListing = distancesBetweenlistings.reduce((a, b) => {
      return Math.max(a, b);
    });

    let fartherListing =
      this.intl.locale === 'en'
        ? Math.round((distanceFromfartherListing + 1) * 0.621371)
        : Math.round(distanceFromfartherListing + 1);

    let radiusCap = this.intl.locale === 'en' ? Math.round(3 * 0.621371) : 3;

    this.set(
      'listControl.fartherListing',
      fartherListing >= radiusCap ? radiusCap : fartherListing
    );
  },

  _updateUniqueBedroomsList: observer('model.listings.@each.bedrooms', function () {
    const listings = this.get('model.listings');
    const uniqueBedrooms = Array.from(
      new Set(listings.map(listing => (listing.bedrooms ? listing.bedrooms : 0)))
    );
    uniqueBedrooms.sort((a, b) => a - b);
    this.set('listControl.uniqueBedroomsList', uniqueBedrooms);
  }),

  _updateUniqueIdentifierServiceList: observer(
    'model.accounts.@each.{channelDisplayId,channel}',
    function () {
      const identifiers = this.model.accounts.map(
        a => `${a.channelDisplayId} — ${a.channel}`
      );
      this.set(
        'listControl.uniqueIdentifierServiceList',
        [...new Set(identifiers)].sort()
      );
      // return [...new Set(identifiers)].sort();
    }
  ),
});
