import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class ListingStatsController extends Controller {
  // -- Parameters ---------------------------------------------------------------------
  @service('booking-review')
  bookingReview;

  @alias('model.listing.statistics')
  statistics;

  @alias('model.listing.statisticsUpdatedAt')
  statisticsUpdatedAt;

  // -- Controller Properties -----------------------------------------------------------

  // -- Computed Properties ------------------------------------------------------------
  @computed('listing.stats.currency')
  get currency() {
    return this.get('listing.stats.currency');
  }

  @computed('statistics.{enabledReservationsCount,daysLive}')
  get bookingPace() {
    const bookingPaceCalc =
      this.statistics.enabledReservationsCount / (this.statistics.daysLive / 7);
    return Math.round(bookingPaceCalc * 10) / 10;
  }

  @action
  refreshStats() {
    this.bookingReview.refreshStats();
  }
}
