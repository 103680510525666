import { STATUS_MAPPING } from 'appkit/bp-models/booking_review_listing';

function _distanceBetween(targetListing, originListing, kmFactor) {
  let earthRadius = 6371e3;
  let olLatToRadians = originListing.latitude * (Math.PI / 180);
  let tlLatToRadians = targetListing.latitude * (Math.PI / 180);

  let deltaLat = (targetListing.latitude - originListing.latitude) * (Math.PI / 180);
  let deltaLng = (targetListing.longitude - originListing.longitude) * (Math.PI / 180);

  let formula =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(olLatToRadians) *
      Math.cos(tlLatToRadians) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);

  let distanceFactor = 2 * Math.atan2(Math.sqrt(formula), Math.sqrt(1 - formula));

  let distance = Math.round(earthRadius * distanceFactor * kmFactor) / 1000;
  return distance;
}

export default function filterListings(
  listings,
  {
    searchTerm = '',
    selectedMarketNames = [],
    selectedClusterNames = [],
    selectedAccountsByName = [],
    selectedOriginForRadius = null,
    distanceMin = null,
    distanceMax = null,
    selectedBedrooms = [],
    selectedListingsToInclude = [],
    selectedListingsToExclude = [],
    basePriceMin = null,
    basePriceMax = null,
    healthScoreMin = null,
    healthScoreMax = null,
    hideUnavailable = false,
    showEnabled = true,
    showDisabled = true,
    monthlyPricePostingEnabled = true,
    monthlyPricePostingDisabled = true,
    selectedCategory1Names = [],
    selectedCategory2Names = [],
    filterByNeedReview = false,
    isBookingReviewSubmitterEnabled = false,
  } = {},
  { locale = 'en' } = {}
) {
  const result = listings.filter(l => {
    // Listings to include
    // Include listings is a strong filter. When we use this, we want to exclude all the other and only return the ones selected.
    if (selectedListingsToInclude?.length) {
      return selectedListingsToInclude.some(sli => sli.id === l.id);
    }

    // Search Term
    if (
      searchTerm &&
      !(
        l.title?.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        l.address?.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
    ) {
      return false;
    }

    // Hide unavailable
    if (hideUnavailable && (!l.primaryChannelListing?.listed || !l.inActiveMarket)) {
      return false;
    }

    // Sync enabled
    if (!showEnabled && l.enabled) {
      return false;
    }

    // Sync disabled
    if (!showDisabled && !l.enabled) {
      return false;
    }

    // Monthly Price posting enabled
    if (!monthlyPricePostingEnabled && l.monthyEnabled) {
      return false;
    }

    // Monthly Price posting disabled
    if (!monthlyPricePostingDisabled && !l.monthyEnabled) {
      return false;
    }

    // Listings to exclude
    if (selectedListingsToExclude?.length) {
      if (selectedListingsToExclude.find(sli => sli.id === l.id)) {
        return false;
      }
    }

    // Health Score
    if (healthScoreMin != null && l.healthScore < healthScoreMin) {
      return false;
    }
    if (healthScoreMax != null && l.healthScore > healthScoreMax) {
      return false;
    }

    // Base price
    if (basePriceMin != null && l._basePrice < basePriceMin) {
      return false;
    }
    if (basePriceMax != null && l._basePrice > basePriceMax) {
      return false;
    }

    // Bedrooms
    if (selectedBedrooms?.length && !selectedBedrooms.includes(l.bedrooms || 0)) {
      return false;
    }

    // Markets
    if (selectedMarketNames?.length && !selectedMarketNames.includes(l.market)) {
      return false;
    }

    // Clusters
    if (selectedClusterNames?.length && !selectedClusterNames.includes(l.cluster)) {
      return false;
    }

    // Radius
    if (selectedOriginForRadius) {
      const kmFactor = locale === 'en' ? 0.621371 : 1;
      const distance = _distanceBetween(l, selectedOriginForRadius, kmFactor);
      if (distance < distanceMin || distance > distanceMax) {
        return false;
      }
    }

    // Service identifiers
    if (selectedAccountsByName?.length) {
      const identifierServicesPairs = selectedAccountsByName.map(sis => {
        return {
          displayId: sis.split(' — ')[0],
          channel: sis.split(' — ')[1],
        };
      });

      const cl = l.channelListings.find(cl => {
        const outerIdentifier = cl.account.channelDisplayId;
        const outerService = cl.account.channel;
        return !!identifierServicesPairs.find(
          is => outerIdentifier === is.displayId && outerService === is.channel
        );
      });

      if (!cl) {
        return false;
      }
    }

    // Categories
    for (let selectedCategoryNames of [
      selectedCategory1Names,
      selectedCategory2Names,
    ]) {
      if (selectedCategoryNames.length > 0) {
        let channelListingWithCat = l.channelListings.filter(
          listing =>
            selectedCategoryNames.filter(category =>
              listing.categories1.includes(category.split('__')[0])
            ).length
        );

        channelListingWithCat = [
          ...new Set(channelListingWithCat.map(listing => listing.masterListing)),
        ];

        if (!channelListingWithCat.includes(l)) {
          return false;
        }
      }
    }

    // Booking review
    if (filterByNeedReview) {
      if (isBookingReviewSubmitterEnabled) {
        // submitter case
        if (
          l.mostRecentBookingReview &&
          l.mostRecentBookingReview.status !== STATUS_MAPPING.SUGGESTED &&
          l.mostRecentBookingReview.status !== STATUS_MAPPING.SUGGESTION_COMPLETED
        ) {
          return false;
        }
      } else {
        // approver case
        if (
          l.mostRecentBookingReview &&
          l.mostRecentBookingReview.status !== STATUS_MAPPING.SUGGESTION_COMPLETED
        ) {
          return false;
        }
      }
    }

    return true;
  });

  return result;
}
