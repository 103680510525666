import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class AirbnbRoute extends AuthRoute {
  controllerName = 'listing.nearby.inner';

  templateName = 'listing/nearby/inner';

  setupController(controller, model) {
    controller.set('model', model);
  }

  async model() {
    return EmberObject.create({
      listings: [],
    });
  }
}
