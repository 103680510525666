/* global Highcharts */
import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';

function chartSeries(name, data, visible, tooltip, axis) {
  let series = {
    name: name,
    data: data,
  };
  if (tooltip === '$') {
    series.tooltip = { valueDecimals: 2, valuePrefix: '$' };
  }
  if (tooltip === '%') {
    series.tooltip = { valueDecimals: 2, valueSuffix: '%' };
  }
  if (!visible) {
    series.visible = false;
  }
  if (axis) {
    series.yAxis = axis;
  }
  return series;
}

@classic
export default class AvailabilityController extends Controller {
  compareId = null;
  loadingCompareData = false;
  comparePct = [];

  chartOptions = {
    rangeSelector: {
      selected: 5,
    },

    yAxis: [
      {
        title: {
          text: 'Number',
        },
      },
      {
        labels: {
          format: '{value}%',
        },
        max: 100,
        title: {
          text: 'Percent',
        },
        opposite: false,
      },
    ],
    legend: {
      padding: 8,
      enabled: true,
      itemDistance: 10,
    },
    navigator: { baseSeries: 0 },
    // plotOptions: {
    //   series: {
    //     showInNavigator: true
    //   }
    // }
  };

  // Have to stick with highcharts 4.2.7 to add a series. Right now it breaks
  // with setting the data on the navigator. I have lost the errors, but it
  // will be complaining about pointStart. I think it's this issue:
  // https://github.com/highcharts/highcharts/issues/5936
  @computed(
    // eslint-disable-next-line ember/use-brace-expansion
    'model.availabilityNum',
    'model.rawAvailabilityPct',
    'model.availabilityPct',
    'model.events',
    'model.availableAuthorizedPct',
    'model.available0BedPct',
    'model.available1BedPct',
    'model.available2BedPct',
    'model.available3BedPct',
    'model.available4BedPct',
    'model.availableRoomsPct',
    'model.activeAvailabilityPct',
    'model.detectBlockNum',
    'comparePct'
  )
  get chartData() {
    let out = [
      chartSeries('Availability Raw (num)', this.get('model.availabilityNum'), true),
      {
        name: 'Availability Raw (pct)',
        id: 'availability_pct',
        data: this.get('model.availabilityPct'),
        yAxis: 1,
        tooltip: { valueDecimals: 2, valueSuffix: '%' },
      },
      {
        name: 'Events',
        type: 'flags',
        onSeries: 'availability_pct',
        color: Highcharts.getOptions().colors[0], // same as onSeries
        fillColor: Highcharts.getOptions().colors[0],
        data: (this.get('model.events') || []).map(event => ({
          x: event[0],
          title: '*',
          text: event[1],
        })),
      },
      chartSeries(
        'Avail. Authorized (pct)',
        this.get('model.availableAuthorizedPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Avail. 0 Bedroom (pct)',
        this.get('model.available0BedPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Avail. 1 Bedroom (pct)',
        this.get('model.available1BedPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Avail. 2 Bedroom (pct)',
        this.get('model.available2BedPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Avail. 3 Bedroom (pct)',
        this.get('model.available3BedPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Avail. 4+ Bedroom (pct)',
        this.get('model.available4BedPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Detect Block Count (num)',
        this.get('model.detectBlocksCount'),
        false
      ),
      chartSeries(
        'Fixed Length Block Count (num)',
        this.get('model.fixedLengthBlockCount'),
        false
      ),
      chartSeries(
        'Calendar End Block Count (num)',
        this.get('model.calendarEndBlockCount'),
        false
      ),
      chartSeries(
        'Availability + Detect Block Count (pct)',
        this.get('model.availabilityPlusDetectBlocksPct'),
        true,
        '%',
        1
      ),
      chartSeries(
        'Availability + Fixed Length Block (pct)',
        this.get('model.availabilityPlusFixedLengthPct'),
        true,
        '%',
        1
      ),
      chartSeries(
        'Availability + Calendar End Block (pct)',
        this.get('model.availabilityPlusCalendarEndPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Availability + Fixed and Detect Block (pct)',
        this.get('model.availabilityPlusFixedAndDetectBlocksPct'),
        false,
        '%',
        1
      ),
      chartSeries(
        'Availability + Calendar End and Detect Block (pct)',
        this.get('model.availabilityPlusCalendarEndAndDetectBlocksPct'),
        false,
        '%',
        1
      ),
    ];
    if (this.site === 'airbnb_v2') {
      out.splice(
        4,
        0,
        chartSeries(
          'Avail. Rooms (pct)',
          this.get('model.availableRoomsPct'),
          false,
          '%',
          1
        )
      );
      out.push(
        chartSeries(
          'Active Availability (pct)',
          this.get('model.activeAvailabilityPct'),
          false,
          '%',
          1
        )
      );
    }
    let comparePct = this.comparePct || [];
    if (comparePct.length) {
      out.push(chartSeries(`Compare ${this.compareId}`, comparePct, true, '%', 1));
    }
    return out;
  }

  _loadComparison(url) {
    let filterCols = (arr, col) =>
      arr.filter(row => row[col]).map(row => [row[0], row[col]]);

    this.set('loadingCompareData', true);
    this.ajax._get(url).then(data => {
      // Just compare pct for now...
      this.set('comparePct', filterCols(data.availability, 2));
      this.set('loadingCompareData', false);
    });
  }

  @action
  compareMarket(id) {
    this.set('compareId', id);
    let url = `/admin/api/markets/${id}/${this.site}/availability`;
    this._loadComparison(url);
  }

  @action
  compareCluster(id) {
    this.set('compareId', id);
    let url = `/admin/api/clusters/${id}/${this.site}/availability`;
    this._loadComparison(url);
  }
}
