import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { A } from '@ember/array';

export default class AppImportCalendars extends Component {
  @tracked urls = A([]);

  constructor() {
    super(...arguments);
    this.urls = A(this.args.inputUrls);
  }

  @action
  addImportCalendarUrl() {
    this.urls.pushObject('');
    this._triggerOnUpdate();
  }

  @action
  removeImportCalendarUrl(index) {
    this.urls.removeAt(index);
    this._triggerOnUpdate();
  }

  @action
  onUpdateSingle(index, importedUrl) {
    this.urls.set(index, importedUrl);
    this._triggerOnUpdate();
  }

  _triggerOnUpdate() {
    this.args.onUpdate(this.urls);
  }
}
