import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

// Apparently service injection doesn't work with helpers. Using a helper
// object works though. I need to upgrade to ember 2 before I go digging any
// further.
//  * https://github.com/emberjs/ember.js/issues/11021
@classic
export default class ReasonString extends Helper {
  @service('intl')
  intl;

  compute([value]) {
    let intl = this.intl;
    if (
      value.key === 'hurricane' ||
      value.key === 'listing_occupancy' ||
      value.key === 'god_mode' ||
      value.key === 'spp_factor'
    ) {
      return value.reason;
    }
    if (value.key === 'yieldfactor') {
      return intl.t(`reason.${value.reason}`);
    }
    if (value.reason) {
      return `${intl.t('pricing.event')}: ${value.reason}`;
    }

    if (isOrphanDay(value.key)) {
      return generateOrphanDaysText(value, intl);
    }

    if (!value.key) {
      return '';
    }

    if (is_dynamic_manual_override(value.key)) {
      return generate_dynamic_manual_override_text(value, intl);
    }

    if (is_discount(value.key)) {
      return generate_discount_text(value, intl);
    }
    return intl.t(`reason.${value.key}`);
  }
}

const is_discount = key =>
  key && key.indexOf('percentage') >= 0 && key.split('_').length >= 2;

const generate_discount_text = (value, intl) => {
  const days = value.key.split('_')[0];
  const prefix = 'reason.discounts.';
  const surcharge_or_discount = intl.t(
    prefix + (value.amount > 0 ? 'surcharge' : 'discount')
  );
  const raised_or_lowered = intl.t(prefix + (value.amount > 0 ? 'raise' : 'lower'));
  const days_in_or_days_out = intl.t(
    prefix + (value.key.indexOf('_out_') > 0 ? 'days_out' : 'days_in')
  );

  return intl.t(prefix + 'discount_text', {
    surcharge_or_discount,
    raised_or_lowered,
    days,
    days_in_or_days_out,
  });
};

const isOrphanDay = key => key && key.includes('orphan');

const generateOrphanDaysText = (value, intl) => {
  const days = value.key.split('_')[1];
  const prefix =
    value.amount > 0 ? 'reason.orphan_days.premium' : 'reason.orphan_days.discount';

  return intl.t(prefix, {
    count: days,
  });
};

const is_dynamic_manual_override = key =>
  key && key.includes('percentage_user_override');

const generate_dynamic_manual_override_text = (value, intl) => {
  const percentage = (Math.abs(value.ratio) * 100).toFixed(2);
  const prefix = 'reason.dynamic_manual_override.';
  const increased_or_decreased = intl.t(
    prefix + (value.amount > 0 ? 'increased' : 'decreased')
  );

  return intl.t(prefix + 'text', {
    increased_or_decreased,
    percentage,
  });
};
