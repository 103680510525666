import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { CurrencyUtil } from 'appkit/lib/currency';

@classic
@tagName('tr')
export default class AppTopBookingsRow extends Component {
  layoutName = 'components/app-top-bookings-row';
  listing = null;
  booking = null;

  @computed('listing.currency')
  get currency() {
    return this.get('listing.currency');
  }

  @computed('booking.adr')
  get formattedAverageDailyRate() {
    let amount = this.get('booking.adr');

    return CurrencyUtil.format(amount, {
      currency: this.currency,
    });
  }

  @computed('booking.additionalRevenue')
  get formattedAdditionalRevenue() {
    let amount = this.get('booking.additionalRevenue');

    if (!amount || amount <= 0) {
      return 'N/A';
    }

    return CurrencyUtil.format(amount, {
      currency: this.currency,
      signed: true,
    });
  }
}
