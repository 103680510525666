import mergeWith from 'lodash.mergewith';

// Extends a Theme with a new variation / color / size
// The new classes will override the original classes
export const extendTheme = (originalTheme, extendedTheme) => options => {
  const originalClasses = originalTheme(options);

  if (Array.isArray(originalClasses)) {
    return [...originalClasses, ...extendedTheme(options)];
  }

  return { ...originalClasses, ...extendedTheme(options) };
};

// Merges an existing theme declaration with new classes, this will apply
// The new classes will be applied after the original classes
export const mergeTheme = (originalTheme, mergeTheme) => options => {
  function arrayMerger(objValue, srcValue) {
    if (Array.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  }

  const originalClasses = originalTheme(options);
  const mergeClasses = mergeTheme(options);

  if (Array.isArray(originalClasses)) {
    return [...originalClasses, ...mergeClasses];
  }

  return mergeWith(originalClasses, mergeClasses, arrayMerger);
};
