import classic from 'ember-classic-decorator';
import EmberObject, { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';
import { run } from '@ember/runloop';
import { oneWay } from '@ember/object/computed';

import { tracked } from '@glimmer/tracking';

@classic
export default class ListControlService extends Service {
  // -- Parameters ---------------------------------------------------------------------
  @service
  alert;

  @service('booking-review')
  bookingReview;

  @service
  intl;

  @service tableView;

  search = '';
  debouncedSearch = '';
  appearance = null; // @type {String}
  viewingListingId = null;
  viewCategoriesSection = false;

  /**
    sort
  */

  sortField = 'healthScore';

  sortOrder = 'desc';

  @oneWay('currentUser.isStaff')
  isStaff;

  @tracked sortFieldsPreferences = [2, 3, 4, 5, 7, 14, 15, 16];

  @computed('isStaff', 'intl.locale', 'sortFieldsPreferences.[]')
  get sortFields() {
    let fields = [
      { field: 'title', label: this.intl.t('pricing.title') },
      { field: 'createdAt', label: this.intl.t('pricing.dateAddedToBeyond') },
      ...this.tableView.preferences
        .filter(f => this.sortFieldsPreferences.includes(f.key))
        .flatMap(f => {
          if (f.key == 5) {
            return [
              {
                field: f.sortField,
                label: this.intl.t('glossary.healthScore'),
                key: 5,
              },
              {
                field: 'bookedThirty',
                label: this.intl.t('glossary.bookedThirty'),
                key: 5,
              },
              {
                field: 'bookedNinety',
                label: this.intl.t('glossary.bookedNinety'),
                key: 5,
              },
            ];
          }
          return [{ field: f.sortField, label: f.label }];
        }),
    ];
    return fields;
  }

  /**
    filter
  */

  healthScoreForSlider = [0, 100];

  healthScoreForInput = [0, 100];

  rangeHealthScore = EmberObject.create({ min: [0], max: [100] });
  selectedMarketNames = [];

  selectedClusterNames = [];

  selectedCategory1Names = [];
  selectedCategory2Names = [];
  selectedOriginForRadius = null;
  selectedAccountsByName = [];
  listingsToInclude = [];
  listingsToExclude = [];
  listingsToIncludeIds = [];
  listingsToExcludeIds = [];
  selectedListingIds = [];
  selectedBedrooms = [];
  showEnabled = true;
  showDisabled = true;
  showOnProgram = true;
  showOffProgram = false;
  monthlyPricePostingEnabled = true;
  monthlyPricePostingDisabled = true;
  hideUnavailable = true;
  filterByNeedReview = false;

  // observing from flat-listing
  maxListingsBasePrice = 1; // @type {Number}

  uniqueMarketList = []; // @type {Array}
  uniqueClusterList = []; // @type {Array}
  uniqueBedroomsList = []; // @type {Array}
  uniqueCategoryList = []; // type {Array}

  categoriesOptionsLists = {}; //type {Object}
  uniqueIdentifierServiceList = []; // @type {Array}

  listingsToIncludeList = []; // @type {Array}
  listingsToExcludeList = []; // @type {Array}
  uniqueGeoReferenceListingList = []; // @type {Array}
  fartherListing = null; // @type {Number}

  /**
    saved filter
  */

  // query params
  initialQueryParams = EmberObject.create({
    search: null,
    appearance: null,
    base_price_max: null,
    base_price_min: null,
    selected_bedrooms: null,
    health_score_max: null,
    health_score_min: null,
    listing_ids: null,
    selected_accounts: null,
    selected_market_names: null,
    selected_cluster_names: null,
    selected_category_1_names: null,
    selected_category_2_names: null,
    listings_to_include: [],
    listings_to_exclude: [],
    selected_origin_for_radius: null,
    distance_min: null,
    distance_max: null,
    show_disabled: null,
    show_enabled: null,
    show_on_program: null,
    show_off_program: null,
    monthly_price_posting_enabled: null,
    monthly_price_posting_disabled: null,
    sort_field: null,
    sort_order: null,
    hide_unavailable: null,
    filter_by_need_review: null,
    mode: null,
  });

  updatedQueryParams = EmberObject.create({
    search: null,
    appearance: null,
    base_price_max: null,
    base_price_min: null,
    selected_bedrooms: null,
    health_score_max: null,
    health_score_min: null,
    listing_ids: null,
    selected_accounts: null,
    selected_market_names: null,
    selected_cluster_names: null,
    selected_category_1_names: null,
    selected_category_2_names: null,
    listings_to_include: [],
    listings_to_exclude: [],
    selected_origin_for_radius: null,
    distance_min: null,
    distance_max: null,
    show_disabled: null,
    show_enabled: null,
    show_on_program: null,
    show_off_program: null,
    monthly_price_posting_enabled: null,
    monthly_price_posting_disabled: null,
    sort_field: null,
    sort_order: null,
    hide_unavailable: null,
    filter_by_need_review: null,
    mode: null,
  });

  @computed('maxListingsBasePrice', 'fartherListing', 'uniqueBedroomsList')
  get defaultQueryParams() {
    return {
      search: null,
      appearance: null,
      sortField: 'healthScore',
      sortOrder: 'asc',
      healthScoreMin: 0,
      healthScoreMax: 100,
      basePriceMin: 0,
      basePriceMax: this.maxListingsBasePrice,
      distanceMin: 0,
      distanceMax: this.fartherListing,
      selectedMarketNames: [],
      selectedClusterNames: [],
      selectedCategory1Names: [],
      selectedCategory2Names: [],
      listingsToInclude: [],
      listingsToExclude: [],
      // FIXME: We're leaking the query params serialized format to list-control :\
      selectedBedrooms: this.uniqueBedroomsList.join('-'),
      selectedOriginForRadius: null,
      selectedAccounts: [],
      showEnabled: 'true',
      showDisabled: 'true',
      showOnProgram: 'true',
      showOffProgram: 'false',
      monthlyPricePostingEnabled: 'true',
      monthlyPricePostingDisabled: 'true',
      hideUnavailable: 'true',
      listingIds: [],
      filterByNeedReview: 'false',
      mode: 'legacy',
    };
  }

  saveFilterModalIsVisible = false;
  editFilterModalIsVisible = false;
  deleteFilterModalIsVisible = false;

  // filter that's currently selected from saved filter dropdown
  selectedSavedFilter = null;

  // filter that's currently saving / editing / deleting on modal
  currentlySelectedSavedFilter = null;

  newFilterName = '';

  // -- Computed Properties ------------------------------------------------------------

  /**
    filter
  */

  // Base Price

  // @type {Array}
  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed('maxListingsBasePrice')
  get basePriceForInput() {
    if (this._basePriceForInput) {
      return this._basePriceForInput;
    }
    return [0, this.maxListingsBasePrice];
  }

  set basePriceForInput(value) {
    return (this._basePriceForInput = value);
  }

  // @type {Array}
  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed('maxListingsBasePrice')
  get basePriceForSlider() {
    if (this._basePriceForSlider) {
      return this._basePriceForSlider;
    }
    return [0, this.maxListingsBasePrice];
  }

  set basePriceForSlider(value) {
    return (this._basePriceForSlider = value);
  }

  // @type {Object}
  @computed('maxListingsBasePrice')
  get rangeBasePrice() {
    const maxPrice = this.maxListingsBasePrice;
    return EmberObject.create({ min: 0, max: maxPrice });
  }

  // Bedrooms
  @computed('selectedBedrooms.length')
  get allBedroomsSelected() {
    return this.selectedBedrooms.length === this.uniqueBedroomsList.length;
  }

  // Listing distances

  // @type {Array}
  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed('fartherListing')
  get distancesForInput() {
    if (this._distancesForInput) {
      return this._distancesForInput;
    }
    return [0, this.fartherListing];
  }

  set distancesForInput(value) {
    return (this._distancesForInput = value);
  }

  // @type {Array}
  // FIXME: computed properties should be read-only
  // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
  @computed('fartherListing')
  get distancesForSlider() {
    if (this._distancesForInput) {
      return this._distancesForInput;
    }
    return [0, this.fartherListing];
  }

  set distancesForSlider(value) {
    return (this._distancesForInput = value);
  }

  // @type {Object}
  @computed('fartherListing')
  get rangeDistances() {
    const distanceMax = this.fartherListing;
    return EmberObject.create({ min: 0, max: distanceMax });
  }

  /**
    saved filter
  */

  @computed(
    'initialQueryParams.{search,appearance,base_price_max,base_price_min,selected_bedrooms,health_score_max,health_score_min,listing_ids,selected_accounts,selected_market_names,selected_cluster_names,selected_category_1_names,selected_category_2_names,listings_to_include,listings_to_exclude,selected_origin_for_radius,distance_min,distance_max,show_disabled,show_enabled,show_on_program,show_off_program,sort_field,sort_order,hide_unavailable,filter_by_need_review,monthly_price_posting_enabled,monthly_price_posting_disabled,mode}',
    'updatedQueryParams.{search,appearance,base_price_max,base_price_min,selected_bedrooms,health_score_max,health_score_min,listing_ids,selected_accounts,selected_market_names,selected_cluster_names,selected_category_1_names,selected_category_2_names,listings_to_include,listings_to_exclude,selected_origin_for_radius,distance_min,distance_max,show_disabled,show_enabled,show_on_program,show_off_program,sort_field,sort_order,hide_unavailable,filter_by_need_review,monthly_price_posting_enabled,monthly_price_posting_disabled,mode}'
  )
  get queryParamsHasChanged() {
    let iqp = this.initialQueryParams;
    let uqp = this.updatedQueryParams;

    if (iqp.search !== uqp.search) return true;
    if (iqp.appearance !== uqp.appearance) return true;
    if (iqp.base_price_max !== uqp.base_price_max) return true;
    if (iqp.base_price_min !== uqp.base_price_min) return true;
    if (iqp.selected_bedrooms !== uqp.selected_bedrooms) return true;
    if (iqp.health_score_max !== uqp.health_score_max) return true;
    if (iqp.health_score_min !== uqp.health_score_min) return true;
    if (iqp.listing_ids !== uqp.listing_ids) return true;
    if (iqp.selected_accounts !== uqp.selected_accounts) return true;
    if (iqp.selected_market_names !== uqp.selected_market_names) return true;
    if (iqp.selected_cluster_names !== uqp.selected_cluster_names) return true;
    if (iqp.selected_category_1_names !== uqp.selected_category_1_names) return true;
    if (iqp.selected_category_2_names !== uqp.selected_category_2_names) return true;
    if (iqp.listings_to_include !== uqp.listings_to_include) return true;
    if (iqp.listings_to_exclude !== uqp.listings_to_exclude) return true;
    if (iqp.selected_origin_for_radius !== uqp.selected_origin_for_radius) return true;
    if (iqp.distance_min !== uqp.distance_min) return true;
    if (iqp.distance_max !== uqp.distance_max) return true;
    if (iqp.show_disabled !== uqp.show_disabled) return true;
    if (iqp.show_enabled !== uqp.show_enabled) return true;
    if (iqp.show_on_program !== uqp.show_on_program) return true;
    if (iqp.show_off_program !== uqp.show_off_program) return true;
    if (iqp.monthly_price_posting_disabled !== uqp.monthly_price_posting_disabled)
      return true;
    if (iqp.monthly_price_posting_enabled !== uqp.monthly_price_posting_enabled)
      return true;
    if (iqp.sort_field !== uqp.sort_field) return true;
    if (iqp.sort_order !== uqp.sort_order) return true;
    if (iqp.hide_unavailable !== uqp.hide_unavailable) return true;
    if (iqp.filter_by_need_review !== uqp.filter_by_need_review) return true;

    return false;
  }

  // -- Actions ------------------------------------------------------------------------

  /**
    sort
  */

  updateSortField(target) {
    this.set('sortOrder', 'asc');
    this.set('sortField', target);
  }

  reverseSortOrder() {
    let order = this.sortOrder;
    if (order === 'asc') {
      order = 'desc';
    } else {
      order = 'asc';
    }
    this.set('sortOrder', order);
  }

  changeSortField(field) {
    if (this.sortField === field) {
      this.reverseSortOrder();
    } else {
      this.set('sortOrder', 'asc');
      this.set('sortField', field);
    }
  }

  /**
    filter
  */

  // health score

  changedToBadHealthScore() {
    this.set('healthScoreForInput', [0, 59]);
    this.set('healthScoreForSlider', [0, 59]);
  }

  changedToGoodHealthScore() {
    this.set('healthScoreForInput', [60, 100]);
    this.set('healthScoreForSlider', [60, 100]);
  }

  debouncedChangeHealthScore(value) {
    this.set('healthScoreForInput', value);
  }

  changedHealthScore(value) {
    debounce(this, this.debouncedChangeHealthScore, value, 200);
  }

  changedMinHealthScore(value) {
    const max = this.healthScoreForInput[1];
    this.set('healthScoreForInput', [value, max]);
    this.set('healthScoreForSlider', [value, max]);
  }

  changedMaxHealthScore(value) {
    const min = this.healthScoreForInput[0];
    this.set('healthScoreForInput', [min, value]);
    this.set('healthScoreForSlider', [min, value]);
  }

  resetHealthScore() {
    this.set('healthScoreForInput', [0, 100]);
    this.set('healthScoreForSlider', [0, 100]);
  }

  // base price

  debouncedChangeBasePrice(value) {
    this.set('basePriceForInput', value);
  }

  changedBasePrice(value) {
    const min = Math.round(value[0]);
    const max = Math.round(value[1]);
    debounce(this, this.debouncedChangeBasePrice, [min, max], 200);
  }

  changedMinBasePrice(value) {
    const max = this.basePriceForInput[1];
    this.set('basePriceForInput', [value, max]);
    this.set('basePriceForSlider', [value, max]);
  }

  changedMaxBasePrice(value) {
    const min = this.basePriceForInput[0];
    this.set('basePriceForInput', [min, value]);
    this.set('basePriceForSlider', [min, value]);
  }

  resetBasePrice() {
    const max = this.maxListingsBasePrice;
    this.set('basePriceForInput', [0, max]);
    this.set('basePriceForSlider', [0, max]);
  }

  // markets
  resetMarkets() {
    this.set('selectedMarketNames', []);
  }

  resetClusters() {
    this.set('selectedClusterNames', []);
  }

  // categories
  resetAllCategories() {
    this.set('selectedCategory1Names', []);
    this.set('selectedCategory2Names', []);
    this.categoriesOptionsLists.forEach(account => {
      account.set('category1Selected', []);
      account.set('category2Selected', []);
    });
  }

  resetCategory1(categories, id) {
    this.set(
      'selectedCategory1Names',
      this.selectedCategory1Names.filter(category => !categories.includes(category))
    );

    this.categoriesOptionsLists.forEach(account => {
      if (account.id !== id) return;
      account.set('category1Selected', []);
    });
  }

  resetCategory2(categories, id) {
    this.set(
      'selectedCategory2Names',
      this.selectedCategory2Names.filter(category => !categories.includes(category))
    );

    this.categoriesOptionsLists.forEach(account => {
      if (account.id !== id) return;
      account.set('category2Selected', []);
    });
  }

  // listings
  resetIncludeListings() {
    this.set('listingsToInclude', []);
    this.set('listingsToIncludeIds', []);
  }

  resetExcludeListings() {
    this.set('listingsToExclude', []);
    this.set('listingsToExcludeIds', []);
  }

  // radius search
  resetRadiusSearch() {
    this.set('selectedOriginForRadius', null);
  }

  resetFartherListing() {
    this.set('fartherListing', null);
  }

  // accounts
  resetIdentifierServices() {
    this.set('selectedAccountsByName', []);
  }

  // sync
  resetSync() {
    this.set('showEnabled', true);
    this.set('showDisabled', true);
  }

  // listing program status
  resetProgramStatus() {
    this.set('showOnProgram', true);
    this.set('showOffProgram', false);
  }

  // monthly pricing posting
  resetMonthlyPricePosting() {
    this.set('monthlyPricePostingEnabled', true);
    this.set('monthlyPricePostingDisabled', true);
  }

  // availability

  resetAvailability() {
    this.set('hideUnavailable', true);
  }

  // bedrooms

  changedSelectedBedrooms(selectedBedrooms) {
    this.set('selectedBedrooms', selectedBedrooms);
  }

  resetBedrooms() {
    this.set('selectedBedrooms', this.uniqueBedroomsList);
  }

  toggleBedrooms() {
    if (this.allBedroomsSelected) {
      this.set('selectedBedrooms', []);
    } else {
      this.set('selectedBedrooms', this.uniqueBedroomsList);
    }
  }

  // distances

  debouncedChangedDistances(value) {
    this.set('distancesForInput', value);
  }

  changedDistances(value) {
    const max = Math.round(value * 100) / 100;

    debounce(this, this.debouncedChangedDistances, [0, max], 200);
  }

  changedMinDistances(value) {
    const max = this.distancesForInput[1];
    this.set('distancesForInput', [value, max]);
    this.set('distancesForSlider', [value, max]);
  }

  changedMaxDistances(value) {
    const min = this.distancesForInput[0];
    this.set('distancesForInput', [min, value]);
    this.set('distancesForSlider', [min, value]);
  }

  // booking review
  resetNeedReview() {
    this.set('filterByNeedReview', false);
  }

  // clear all
  resetAllFilters() {
    this.set('search', null);
    this.set('selectedListingIds', []);
    this.resetHealthScore();
    this.resetBasePrice();
    this.resetBedrooms();
    this.resetIncludeListings();
    this.resetExcludeListings();
    this.resetMarkets();
    this.resetClusters();
    this.resetAllCategories();
    this.resetRadiusSearch();
    this.resetIdentifierServices();
    this.resetSync();
    this.resetProgramStatus();
    this.resetMonthlyPricePosting();
    this.resetAvailability();
    this.resetNeedReview();
    this.set('selectedSavedFilter', null);
  }

  /**
    search
  */

  debouncedUpdatedSearch() {
    this.set('debouncedSearch', this.search);
  }

  updatedSearch() {
    run.debounce(this, this.debouncedUpdatedSearch, 600);
  }

  /**
    saved filter
  */

  resetInitialAndUpdatedQueryParams() {
    const defaultState = {
      search: this.defaultQueryParams.search,
      appearance: this.defaultQueryParams.appearance,
      base_price_max: this.defaultQueryParams.basePriceMax,
      base_price_min: this.defaultQueryParams.basePriceMin,
      selected_bedrooms: this.defaultQueryParams.selectedBedrooms,
      health_score_max: this.defaultQueryParams.healthScoreMax,
      health_score_min: this.defaultQueryParams.healthScoreMin,
      listing_ids: this.defaultQueryParams.listingIds,
      selected_accounts: this.defaultQueryParams.selectedAccounts,
      selected_market_names: this.defaultQueryParams.selectedMarketNames,
      selected_cluster_names: this.defaultQueryParams.selectedClusterNames,
      selected_category_1_names: this.defaultQueryParams.selectedCategory1Names,
      selected_category_2_names: this.defaultQueryParams.selectedCategory2Names,
      listings_to_include: this.defaultQueryParams.listingsToInclude,
      listings_to_exclude: this.defaultQueryParams.listingsToExclude,
      selected_listings_for_geo_control: this.defaultQueryParams
        .selectedListingForGeoControl,
      selected_origin_for_radius: this.defaultQueryParams.selectedOriginForRadius,
      distance_min: this.defaultQueryParams.distanceMin,
      distance_max: this.defaultQueryParams.distanceMax,
      show_disabled: this.defaultQueryParams.showDisabled,
      show_enabled: this.defaultQueryParams.showEnabled,
      show_on_program: this.defaultQueryParams.showOnProgram,
      show_off_program: this.defaultQueryParams.showOffProgram,
      monthly_price_posting_disabled: this.defaultQueryParams
        .monthlyPricePostingDisabled,
      monthly_price_posting_enabled: this.defaultQueryParams.monthlyPricePostingEnabled,
      sort_field: this.defaultQueryParams.sortField,
      sort_order: this.defaultQueryParams.sortOrder,
      hide_unavailable: this.defaultQueryParams.hideUnavailable,
      filter_by_need_review: this.defaultQueryParams.filterByNeedReview,
    };
    this.initialQueryParams.setProperties(defaultState);
    this.updatedQueryParams.setProperties(defaultState);
  }

  showEditFilterModal(selectedSavedFilter) {
    this.set('newFilterName', selectedSavedFilter.name);
    this.set('editFilterModalIsVisible', true);
    this.set('currentlySelectedSavedFilter', selectedSavedFilter);
  }

  showDeleteFilterModal(selectedSavedFilter) {
    this.set('deleteFilterModalIsVisible', true);
    this.set('currentlySelectedSavedFilter', selectedSavedFilter);
  }

  closeShowFilterModal() {
    this.set('saveFilterModalIsVisible', false);
    this.set('newFilterName', '');
  }

  closeEditFilterModal() {
    this.set('editFilterModalIsVisible', false);
    this.set('newFilterName', '');
  }

  // -- Overrides  ---------------------------------------------------------------------
}
