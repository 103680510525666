import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class SubpageRoute extends AuthRoute {
  model(params) {
    const subpage = params.id;
    this.transitionTo(`dashboard.user.${subpage}`);
  }
}
