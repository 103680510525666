import Mixin from '@ember/object/mixin';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Util from 'appkit/lib/util';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  listControl: service('list-control'),
  router: service('-routing'),

  toggleFilterRoutePath: computed('router.currentRouteName', function () {
    const currentPath = get(this, 'router.currentRouteName');

    if (currentPath.includes('dashboard.pricing.index.filter')) {
      return 'dashboard.pricing.index.index';
    } else if (
      currentPath.includes('dashboard.pricing.index.index') ||
      currentPath.includes('dashboard.pricing.index.booking-review') ||
      currentPath.includes('dashboard.pricing.index.setup')
    ) {
      return 'dashboard.pricing.index.filter';
    } else if (currentPath.includes('dashboard.relay.merge.index.index')) {
      return 'dashboard.relay.merge.index.filter';
    } else if (currentPath.includes('dashboard.relay.merge.index.filter')) {
      return 'dashboard.relay.merge.index.index';
    } else if (currentPath.includes('dashboard.grid.filter')) {
      return 'dashboard.grid';
    } else if (currentPath.includes('dashboard.grid')) {
      return 'dashboard.grid.filter';
    } else {
      return;
    }
  }),

  actions: {
    toggleCategoryField(index, account) {
      let element = document.querySelector(`.cat-${index}-${account}`).classList;
      let trigger = document.querySelector(`.title-${index}-${account}`).classList;

      element.value.indexOf('is-collapsed') > -1
        ? element.replace('is-collapsed', 'is-expanded')
        : element.replace('is-expanded', 'is-collapsed');

      trigger.value.indexOf('is-collapsed') > -1
        ? trigger.replace('is-collapsed', 'is-expanded')
        : trigger.replace('is-expanded', 'is-collapsed');
    },
    /**
      filter
    */

    // reset
    async resetAllFilters() {
      await this.listControl.resetAllFilters();
      await this.send('updateSearchParams', this.listControl.defaultQueryParams);
      // await this.updateSearchParams(this.listControl.defaultQueryParams);
      await this.listControl.initialQueryParams.setProperties(
        this.listControl.updatedQueryParams
      );
    },
    resetHealthScore() {
      this.listControl.resetHealthScore();
    },
    resetBasePrice() {
      this.listControl.resetBasePrice();
    },
    toggleBedrooms() {
      this.listControl.toggleBedrooms();
    },
    resetIncludeListings() {
      this.listControl.resetIncludeListings();
    },
    resetExcludeListings() {
      this.listControl.resetExcludeListings();
    },
    resetMarkets() {
      this.listControl.resetMarkets();
    },
    resetClusters() {
      this.listControl.resetClusters();
    },
    resetRadiusSearch() {
      this.listControl.resetRadiusSearch();
    },
    resetFartherListing() {
      this.listControl.resetFartherListing();
    },
    resetAllCategories() {
      this.listControl.resetAllCategories();
    },
    resetCategory1(categories, id) {
      this.listControl.resetCategory1(categories, id);
    },
    resetCategory2(categories, id) {
      this.listControl.resetCategory2(categories, id);
    },
    resetIdentifierServices() {
      this.listControl.resetIdentifierServices();
    },
    resetSync() {
      this.listControl.resetSync();
    },
    resetProgramStatus() {
      this.listControl.resetProgramStatus();
    },
    resetMonthlyPricePosting() {
      this.listControl.resetMonthlyPricePosting();
    },
    resetAvailability() {
      this.listControl.resetAvailability();
    },

    // health score
    changedToBadHealthScore() {
      this.listControl.changedToBadHealthScore();
    },
    changedToGoodHealthScore() {
      this.listControl.changedToGoodHealthScore();
    },
    changedHealthScore(value) {
      this.listControl.changedHealthScore(value);
    },
    changedMinHealthScore(value) {
      this.listControl.changedMinHealthScore(value);
    },
    changedMaxHealthScore(value) {
      this.listControl.changedMaxHealthScore(value);
    },

    // base price
    changedBasePrice(value) {
      this.listControl.changedBasePrice(value);
    },

    changedMinBasePrice(value) {
      this.listControl.changedMinBasePrice(value);
    },

    changedMaxBasePrice(value) {
      this.listControl.changedMaxBasePrice(value);
    },

    // bedrooms
    changedSelectedBedrooms(value) {
      this.listControl.changedSelectedBedrooms(value);
    },

    // distances
    changedDistances(value) {
      this.listControl.changedDistances(value);
    },
    changedMaxDistances(value) {
      this.listControl.changedMaxDistances(value);
    },

    //search listing
    searchListings(listing, term) {
      let search = new RegExp(Util.escapeRegExpPattern(term), 'i');

      const value = listing.title.toString();
      if (
        (value && value.toString().match(search)) ||
        (listing.channelListings[0]?.multiUnitGroupTitle &&
          listing.channelListings[0]?.multiUnitGroupTitle.toString().match(search)) ||
        (listing.channelListings[0]?.multiUnitParentId &&
          !listing.channelListings[0]?.multiUnitGroupTitle)
      ) {
        return 1;
      } else {
        return -1;
      }
    },

    /**
      saved filter
    */

    showEditFilterModal(selectedFilter) {
      this.listControl.showEditFilterModal(selectedFilter);
    },

    showDeleteFilterModal(selectedFilter) {
      this.listControl.showDeleteFilterModal(selectedFilter);
    },

    async selectSavedFilter(selectedFilter) {
      // update dropdown selected value
      this.set('listControl.selectedSavedFilter', selectedFilter);

      if (!selectedFilter) {
        return;
      }

      this.listControl.resetHealthScore();
      this.listControl.resetBasePrice();
      this.listControl.resetBedrooms();
      this.listControl.resetIncludeListings();
      this.listControl.resetExcludeListings();
      this.listControl.resetMarkets();
      this.listControl.resetClusters();
      this.listControl.resetAllCategories();
      this.listControl.resetRadiusSearch();
      this.listControl.resetIdentifierServices();
      this.listControl.resetSync();
      this.listControl.resetMonthlyPricePosting();
      this.listControl.resetAvailability();

      // Migrate the bedrooms range format to be an array
      const content = this._migrateBedrooms(selectedFilter.content);
      const params = this.router.currentRoute.queryParams;
      const appearance = params.appearance;
      content.appearance = appearance || content.appearance || 'card-view';

      if (!content.mode) {
        content.mode = content.appearance === 'card-view' ? 'new' : 'legacy';
      }

      await this.send('updateSearchParams', content);

      await this.listControl.initialQueryParams.setProperties(
        this.listControl.updatedQueryParams
      );
    },

    _stopPropagation(e) {
      e.stopPropagation();
    },
  },

  _migrateBedrooms(filterContent) {
    const { bedroomsMin, bedroomsMax, ...content } = filterContent;
    const bedroomsMinNumber = parseInt(bedroomsMin);
    const bedroomsMaxNumber = parseInt(bedroomsMax);

    if (Number.isInteger(bedroomsMinNumber) && Number.isInteger(bedroomsMaxNumber)) {
      // Current bedroom filters operate with ranges, we need to convert it to individual options
      const selectedBedrooms = Array.from({
        length: bedroomsMaxNumber - bedroomsMinNumber + 1,
      }).map((_, index) => index + bedroomsMinNumber);

      content.selectedBedrooms = selectedBedrooms.join('-');
    }

    return content;
  },
});
