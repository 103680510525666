import { helper } from '@ember/component/helper';
import { CurrencyUtil } from 'appkit/lib/currency';

export default helper(function (params, namedArgs) {
  return CurrencyUtil.format(params[0], {
    currency: namedArgs.currency,
    decimalPlaces: namedArgs.decimalPlaces,
    abs: namedArgs.abs,
  });
});
