import { isEmpty } from '@ember/utils';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class GoogleTagManagerService extends Service {
  @service currentUser;
  @tracked userId;
  @tracked email;

  isGTMLibraryLoaded() {
    if (!window.dataLayer) {
      console.warn('The javascript library for GTM has not been initilized.');
      return false;
    } else {
      return true;
    }
  }

  /**
   * Sends user attributes to Google Tag Manager so they may be stored and later passed to
   * UserLeap Survey Platform.
   * TODO: (Not yet it in use) Replace current implmentation whcih directly calles window.dataLayer with thsi service method
   */
  login() {
    if (this.isGTMLibraryLoaded()) {
      window.dataLayer.push({
        event: 'login',
        userId: this.currentUser.userId,
        isRelayEnabled: this.currentUser.isRelayEnabled,
        pricingActivatedAt: this.currentUser.pricingActivatedAt,
        insightsActivatedAt: this.currentUser.insightsActivatedAt,
        sfAccountManager: this.currentUser.sfAccountManager,
        sfAccountCustomerSuccessManager: this.currentUser
          .sfAccountCustomerSuccessManager,
        firstListingMergedAt: this.currentUser.firstListingMergedAt,
        hasEnabledListings: this.currentUser.hasEnabledListings,
        isUserIdentity:
          !isEmpty(this.currentUser.email) && !isEmpty(this.currentUser.userId)
            ? true
            : false,
      });
    }
  }

  /**
   * Sends userId to GTM on each page load. (Not yet in use)
   */
  pageView() {
    if (this.isGTMLibraryLoaded()) {
      window.dataLayer.push({
        event: 'pageview',
        userId: this.currentUser.userId,
      });
    }
  }

  /**
   * Sends user attributes to Google Tag Manager whenever a user signs up for a new account
   */
  createAccount() {
    if (this.isGTMLibraryLoaded()) {
      window.dataLayer.push({
        event: 'create-account',
        userId: this.currentUser.userId,
        email: this.currentUser.email,
      });
    }
  }
}
