import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { action } from '@ember/object';

@classic
export default class DashboardGridBulkController extends Controller {
  // -- Actions ------------------------------------------------------------------------
  @action
  moveToDashboard() {
    this.transitionToRoute('dashboard.pricing.index');
  }
}
