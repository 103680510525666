import classic from 'ember-classic-decorator';
import { classNameBindings } from '@ember-decorators/component';
import { action, computed } from '@ember/object';

import Component from '@ember/component';
@classic
@classNameBindings('selectedClass')
export default class AppSelectable extends Component {
  layoutName = 'components/simple-yield';
  value = null;
  selected = null;

  @computed('value', 'selected')
  get isSelected() {
    return this.value === this.selected;
  }

  @computed('isSelected')
  get selectedClass() {
    if (this.isSelected) {
      return 'selected';
    }
    return '';
  }

  @action
  select(value) {
    if (this.selected === value) {
      value = null;
    }
    this.set('selected', value);
    return false;
  }
}
