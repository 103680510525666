import classic from 'ember-classic-decorator';

import { getOwner } from '@ember/application';
import { computed, action, get, set } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { ValueRange, RangeValidator } from 'appkit/lib/value_range';
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';
import moment from 'moment';
import Customize from 'appkit/bp-models/customize';
import { RANGE_DEFAULT, FIELD_PANE_MAP } from 'appkit/lib/customize';
import { displayErrors } from 'appkit/lib/display_errors';
import EmberObject from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { DATA_TYPE, CHANNEL, CHANNEL_CONFIG } from 'appkit/lib/channel_configuration';

@classic
export default class BulkEditController extends Controller {
  // -- Parameters ---------------------------------------------------------------------
  @service() alert;
  @service('-routing') router;
  @service() featureFlag;
  @service('intl') intl;
  @service forcePaymentMethod;
  @service intl;

  showMinStayContent = true;
  showMinStayRanges = false;
  saving = false;
  showValidationErrors = false;
  formHasErrors = false;
  @controller('dashboard.pricing.index') pricingController;
  isPriceSyncingAvailable = false;
  isMonthlyPricingAvailable = false;
  hasSpecialRequirementsEnabled = false;

  listingReferralToImport = null;
  listingReferralSettings = null;
  fieldsToReplaceOnImport = [];
  fieldsToUpdateOnImport = [];

  /**
    selected listings
  */

  @tracked customize = Customize.create({
    isMinStayEnabled: null,
    lastMinuteMinStays: [],
    minStay: null,
    minStayRanges: [],
    minPriceRanges: [],
    minMaxPriceRanges: [],
    gapFillingEnabled: undefined,
    orphanMinStay: undefined,
    orphanMinStayBuffer: undefined,
    isOrphanChangeoverDaysEnabled: undefined,
    priceOverridePercentageRanges: [],
    priceOverrideRanges: [],
    dynamicPercentageUserOverrideRanges: [],
    isPercentageUserOverride: false,
    minPrice: null,
    maxPrice: null,
    basePrice: null,
    minPricePercentage: null,
    basePricePercentage: null,
    maxPricePercentage: null,
    discounts: [],
    checkinDays: [],
    checkoutDays: [],
    checkinCheckoutDaysRanges: [],
    orphanDays: [],
    extraGuestFee: null,
    extraGuestThreshold: null,
    monthlyMinPrice: null,
    monthlyMinPricePercentage: null,
    monthlyMinPriceRanges: [],
    monthlyMinMaxPriceRanges: [],
    monthlyPriceOverridePercentageRanges: [],
    monthlySeasonsRanges: [],
    onProgram: null,
    intl: this.intl,
    currencies: null,
  });

  @tracked isChangeoverAvailable = true;
  @tracked excludeSpecialRequirements = true;
  @tracked dayOfWeekIsAvailable = false;
  @tracked isDoWSettingsAvailable = false;
  @tracked isSeasonalMinStaysLocksAvailable = true;

  @tracked disablingSurveyIsIncomplete = false;

  get seasonalMinStaySettings() {
    return [
      {
        action: 'set',
        term: this.intl.t('pricing.bulkEdit.setMinStayTo'),
        accessValue: true,
      },
      this.isSeasonalMinStaysLocksAvailable && {
        action: 'lock',
        term: 'Lock Min Stay to',
        accessValue: true,
      },
      {
        action: 'clear',
        term: this.intl.t('pricing.bulkEdit.clearSeasonalMinStay'),
        accessValue: false,
      },
    ].filter(i => i);
  }

  get seasonalMinPriceSettings() {
    return [
      {
        action: 'set',
        term: this.intl.t('pricing.bulkEdit.setMinPriceTo'),
        accessValue: true,
      },
      {
        action: 'clear',
        term: this.intl.t('pricing.bulkEdit.clearSeasonalMinPrice'),
        accessValue: false,
      },
    ];
  }

  get seasonalMinMaxPriceSettings() {
    return [
      {
        action: 'set',
        term: this.intl.t('pricing.bulkEdit.setSeasonalPriceTo'),
        accessValue: true,
      },
      {
        action: 'clear',
        term: this.intl.t('pricing.bulkEdit.clearSeasonalPrice'),
        accessValue: false,
      },
      {
        action: 'keep',
        term: this.intl.t('pricing.bulkEdit.keepSeasonalPrice'),
        accessValue: false,
      },
    ];
  }

  get priceOverrideSettings() {
    return [
      {
        action: 'increase',
        term: this.intl.t('pricing.bulkEdit.increasePriceBy'),
        accessValue: true,
      },
      {
        action: 'decrease',
        term: this.intl.t('pricing.bulkEdit.decreasePriceBy'),
        accessValue: true,
      },
      {
        action: 'clear',
        term: this.intl.t('pricing.bulkEdit.clearOverrides'),
        accessValue: false,
      },
      {
        action: 'set',
        term: this.intl.t('pricing.bulkEdit.setPriceTo'),
        accessValue: true,
      },
    ];
  }

  gapFillOptions = [
    { optionFor: 'gapFill', value: 'on' },
    { optionFor: 'gapFill', value: 'off' },
  ];

  changeoverGapFillOptions = [
    { optionFor: 'isOrphanChangeoverDaysEnabled', value: 'on' },
    { optionFor: 'isOrphanChangeoverDaysEnabled', value: 'off' },
  ];

  gapFillSelectedOption = null;

  get gapFillFurtherOptions() {
    return [
      {
        field: 'orphanMinStay',
        parsedField: this.intl.t('glossary.orphanMin'),
        value: undefined,
        beforeForm: this.intl.t('common.downTo'),
        afterForm: this.intl.t('glossary.nightOptionalPlural'),
        placeholder: this.intl.t('common.no'),
      },
      {
        field: 'orphanMinStayBuffer',
        parsedField: this.intl.t('glossary.buffer'),
        value: undefined,
        beforeForm: this.intl.t('glossary.withABufferOf'),
        afterForm: this.intl.t('glossary.nightOptionalPlural'),
        placeholder: this.intl.t('common.no'),
      },
    ];
  }

  isOrphanChangeoverDaysEnabledSelectedOption = null;

  priceSyncingOptions = [
    { optionFor: 'priceSyncing', value: 'on' },
    { optionFor: 'priceSyncing', value: 'off' },
  ];

  priceSyncingSelectedOption = null;

  get reasonsForDisablePricing() {
    return {
      inquiryHeader: this.intl.t('pricing.bulkEdit.reasonsForDisablePricingHeader'),
      reasons: [
        {
          id: 'NEED_AIDED_WALK_THROUGH',
          label: this.intl.t('pricing.listing.reasonsToDisable.needAidedWalkThrough'),
          hasDetails: true,
        },
        {
          id: 'COMPETITION',
          label: this.intl.t('pricing.listing.reasonsToDisable.competition'),
        },
        {
          id: 'FEATURES_MISSING',
          label: this.intl.t('pricing.listing.reasonsToDisable.featuresMissing'),
          hasDetails: true,
        },
        {
          id: 'ONBOARDING',
          label: this.intl.t('pricing.listing.reasonsToDisable.onboarding'),
        },
        {
          id: 'LEAVING_PROGRAM',
          label: this.intl.t('pricing.listing.reasonsToDisable.leavingProgram'),
          hasDetails: true,
        },
        {
          id: 'OTHER',
          label: this.intl.t('pricing.listing.reasonsToDisable.other'),
          hasDetails: true,
        },
      ],
    };
  }

  get reasonsExtraDetails() {
    return {
      NEED_AIDED_WALK_THROUGH: {
        type: 'NUMBER_INPUT',
        placeholder: this.intl.t('pricing.listing.reasonsToDisable.enterNumber'),
      },
      FEATURES_MISSING: {
        type: 'TEXTAREA',
        placeholder: this.intl.t('pricing.listing.reasonsToDisable.enterFeatures'),
      },
      LEAVING_PROGRAM: {
        type: 'RADIO',
        options: [
          {
            id: 'MARKET_REGULATIONS',
            label: this.intl.t('pricing.listing.reasonsToDisable.marketRegulations'),
          },
          {
            id: 'PROPERTY_SOLD',
            label: this.intl.t('pricing.listing.reasonsToDisable.propertySold'),
          },
          {
            id: 'STOP_MANAGING',
            label: this.intl.t('pricing.listing.reasonsToDisable.stopManaging'),
          },
          {
            id: 'STOP_RENTING',
            label: this.intl.t('pricing.listing.reasonsToDisable.stopRenting'),
          },
          {
            id: 'OTHER',
            label: this.intl.t('pricing.listing.reasonsToDisable.other'),
            hasDetails: true,
          },
        ],
      },
      OTHER: {
        type: 'TEXTAREA',
        placeholder: this.intl.t('pricing.listing.reasonsToDisable.enterReason'),
      },
    };
  }

  listingProgramOptions = [
    { optionFor: 'listingProgram', value: 'on' },
    { optionFor: 'listingProgram', value: 'off' },
  ];

  listingProgramSelectedOption = null;

  get priceChangeSettings() {
    return [
      {
        action: 'increase',
        term: this.intl.t('pricing.bulkEdit.increasePriceBy'),
        accessValue: true,
      },
      {
        action: 'decrease',
        term: this.intl.t('pricing.bulkEdit.decreasePriceBy'),
        accessValue: true,
      },
      {
        action: 'set',
        term: this.intl.t('pricing.bulkEdit.setPriceTo'),
        accessValue: true,
      },
    ];
  }
  changeoverDaysOptions = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  get monthlySeasonsSettings() {
    return [
      {
        action: 'set',
        term: this.intl.t('pricing.bulkEdit.addSeason'),
        accessValue: false,
      },
      {
        action: 'clear',
        term: this.intl.t('pricing.bulkEdit.clearSeason'),
        accessValue: false,
      },
    ];
  }

  basePriceForBulkSelectedSetting = null;
  basePriceForBulkValue = null;
  minPriceForBulkSelectedSetting = null;
  minPriceForBulkValue = null;
  maxPriceForBulkSelectedSetting = null;
  maxPriceForBulkValue = null;
  discountsForBulk = [];
  gapDiscountsForBulk = [];
  lastMinuteMinStayForBulk = [];

  monthlyPriceOptions = [
    { optionFor: 'monthlyPrice', value: 'on' },
    { optionFor: 'monthlyPrice', value: 'off' },
  ];

  monthlyPriceSelectedOption = null;
  monthlyMinPriceForBulkSelectedSetting = null;
  monthlyMinPriceForBulkValue = null;

  // -- Controller Properties -----------------------------------------------------------

  // -- Computed Properties ------------------------------------------------------------
  // @type {Array}
  @computed(
    'selectedListingsIds.[]',
    'model.listings.[]',
    'pricingController.selectedListings.[]'
  )
  get selectedListings() {
    return this.pricingController.selectedListings;
  }

  @computed('saving')
  get saveButtonText() {
    if (this.saving) {
      return this.intl.t('common.saving');
    } else {
      return this.intl.t('common.saveChanges');
    }
  }

  /**
    bulk edit
  */

  @computed('saving', 'customize.isDirty')
  get canSave() {
    return this.customize.isDirty && !this.saving;
  }

  @computed('selectedListings.[]')
  get canBulkEditPrice() {
    const listingsWithSuggestion = this.selectedListings.filter(l => l.suggestionAdded);
    return listingsWithSuggestion.length === 0;
  }

  /**
    min stays
  */
  @computed('pricingController.model.listings.@each.{selected}')
  get selectedListingsIds() {
    let selectedListings = this.model.listings
      .filter(listing => listing.selected)
      .map(listing => listing.id);

    return selectedListings ?? [];
  }

  @computed('selectedListings.@each.primaryChannelListing__account__id')
  get selectedListingsAccountIds() {
    return Array.from(
      new Set(this.selectedListings.map(l => l.primaryChannelListing__account__id))
    );
  }

  @computed(
    'selectedListings.@each.{primaryChannelListing__channel,primaryChannelListing__account}',
    'model.accounts.@each.isMinStayEnabled',
    'selectedListingsAccountIds.length'
  )
  get selectedListingsWithoutMinStaySupport() {
    let output = [];

    for (let listing of this.selectedListings) {
      const account = listing.primaryChannelListing.account;

      if (
        !account.channelConfig.canPush.includes(DATA_TYPE.MIN_STAY_FOR_LISTING) &&
        !account.channelConfig.canPush.includes(DATA_TYPE.MIN_STAY_PER_DAY)
      ) {
        output.push(listing);
      } else if (!account.isMinStayEnabled) {
        output.push(listing);
      }
    }

    return output;
  }

  @computed(
    'selectedListings.@each.{primaryChannelListing__channel,primaryChannelListing__account}',
    'selectedListingsAccountIds.length',
    'isChangeoverAvailable'
  )
  get selectedListingsWithoutChangeoverSupport() {
    let output = [];

    for (let listing of this.selectedListings) {
      const account = listing.primaryChannelListing.account;

      if (listing.channelListingsNames?.includes(CHANNEL.BOOKING_CONNECT)) {
        if (!this.isChangeoverAvailable) {
          output.push(listing);
          continue;
        }
      }

      if (
        !account.channelConfig.canPush.includes(DATA_TYPE.CHECKIN_DAYS_FOR_LISTING) &&
        !account.channelConfig.canPush.includes(DATA_TYPE.CHECKIN_DAYS_PER_DAY)
      ) {
        output.push(listing);
      }
    }

    return output;
  }

  @computed(
    'selectedListings.@each.{primaryChannelListing__channel,primaryChannelListing__account}',
    'selectedListingsAccountIds.length'
  )
  get selectedListingsWithoutExtraGuestFeeSupport() {
    let output = [];

    for (let listing of this.selectedListings) {
      const account = listing.primaryChannelListing.account;

      if (
        !account.channelConfig.canPush.includes(
          DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING
        ) &&
        !account.channelConfig.canPush.includes(DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY)
      ) {
        output.push(listing);
      }
    }

    return output;
  }

  @computed(
    'selectedListings.@each.{basePriceUpdatedAt}',
    'selectedListingsAccountIds.length'
  )
  get selectedListingsWithPriceSyncingBlock() {
    return this.selectedListings.filter(
      l => !l.basePriceUpdatedAt && !l.primaryChannelListing.multiUnitParentId
    );
  }

  @computed('model.user.isCheckoutDaysEnabled', 'model.accounts.@each.{channel}')
  get isCheckoutUpdateAvailable() {
    if (
      this.model.user.isCheckoutDaysEnabled ||
      this.model.accounts.map(account => account.channel).includes('airbnb')
    ) {
      return true;
    }

    return false;
  }

  @computed('selectedListings.[]', 'selectedListingsWithoutMinStaySupport.[]')
  get hasAllSelectedListingsWithoutMinStaySupport() {
    let listings = this.selectedListings.filter(listing =>
      this.selectedListingsWithoutMinStaySupport.includes(listing)
    );
    if (this.selectedListings.length === listings.length) {
      return true;
    } else {
      return false;
    }
  }

  @computed('selectedListings.[]', 'selectedListingsWithoutChangeoverSupport.[]')
  get hasAllSelectedListingsWithoutChangeoverSupport() {
    let listings = this.selectedListings.filter(listing =>
      this.selectedListingsWithoutChangeoverSupport.includes(listing)
    );
    if (this.selectedListings.length === listings.length) {
      return true;
    } else {
      return false;
    }
  }

  @computed('selectedListings.[]', 'selectedListingsWithoutExtraGuestFeeSupport.[]')
  get hasAllSelectedListingsWithoutExtraGuestFeeSupport() {
    let listings = this.selectedListings.filter(listing =>
      this.selectedListingsWithoutExtraGuestFeeSupport.includes(listing)
    );
    if (this.selectedListings.length === listings.length) {
      return true;
    } else {
      return false;
    }
  }

  @computed('selectedListings.[]')
  get hasAllselectedListingsWithPriceSyncingBlock() {
    return !this.selectedListings.some(l => l.basePriceUpdatedAt);
  }

  @computed('gapFillFurtherOptions.@each.{value}')
  get orphanMinStay() {
    return this.gapFillFurtherOptions.find(option => option.field === 'orphanMinStay')
      .value;
  }

  @computed('gapFillFurtherOptions.@each.{value}')
  get orphanMinStayBuffer() {
    return this.gapFillFurtherOptions.find(
      option => option.field === 'orphanMinStayBuffer'
    ).value;
  }

  @computed('gapFillSelectedOption', 'customize.{orphanMinStay,orphanMinStayBuffer}')
  get gapFillOptionsNonConsistentWithToggle() {
    if (
      this.gapFillSelectedOption === 'on' &&
      this.customize.orphanMinStay === null &&
      this.customize.orphanMinStayBuffer === null
    )
      return true;
    return false;
  }

  @computed('gapFillSelectedOption', 'customize.{orphanMinStay,orphanMinStayBuffer}')
  get gapFillErrorMessages() {
    let errorMessages = [];

    if (this.gapFillOptionsNonConsistentWithToggle) {
      errorMessages.push({
        message: this.intl.t('validation.pleaseEnterAValueInOneOfTheFields'),
      });
    }

    return errorMessages;
  }

  @computed(
    'priceSyncingSelectedOption',
    'customize.{enabled,reasonsSyncingTurnedOff}',
    'disablingSurveyIsIncomplete'
  )
  get priceSyncingNonConsistentReasons() {
    if (this.priceSyncingSelectedOption === 'on') return false;

    if (
      this.priceSyncingSelectedOption === 'off' &&
      this.customize.enabled === false &&
      this.customize.reasonsSyncingTurnedOff == null
    )
      return true;

    return this.disablingSurveyIsIncomplete;
  }

  @computed('priceSyncingSelectedOption', 'customize.{enabled,reasonsSyncingTurnedOff}')
  get priceSyncingErrorMessages() {
    let errorMessages = [];

    if (!this.customize.reasonsSyncingTurnedOff) {
      errorMessages.push({
        message: this.intl.t('validation.reasonForDisablingPriceSyncing'),
      });
    }

    this.customize.reasonsSyncingTurnedOff?.forEach(r => {
      if (!r.includes('Other')) return;
      if (!r.includes('::')) {
        errorMessages.push({
          message: this.intl.t('validation.detailReasonForDisablingPriceSyncing'),
        });
      }
    });

    return errorMessages;
  }

  @computed('listingProgramSelectedOption', 'selectedListings.[]')
  get hasConflictToRemoveFromProgram() {
    let hasEnabledListingInSelection = this.selectedListings.some(
      l => l.enabled == true
    );

    return this.listingProgramSelectedOption == 'off' && hasEnabledListingInSelection;
  }

  @computed('selectedListings.[]')
  get includesMonthlyPricingListings() {
    for (let listing of this.selectedListings) {
      const channel = listing?.primaryChannelListing?.channel;
      const channelConfig = CHANNEL_CONFIG[channel];
      let canPushMonthly = channelConfig.canPush.includes(DATA_TYPE.MONTHLY_PRICE);

      if (canPushMonthly) return true;
    }

    return false;
  }

  @computed('selectedListings.[]')
  get onlyListingsWithSRSelected() {
    const itemWithNoSR = this.selectedListings.find(l => !l.specialRequirements);
    return !itemWithNoSR;
  }

  @computed('selectedListings.[]')
  get selectedListingsWithoutSR() {
    return this.selectedListings.filter(l => !l.specialRequirements);
  }

  // -- Lifecycle Hooks ----------------------------------------------------------------

  // -- Actions ------------------------------------------------------------------------
  @action
  resetCustomize() {
    this.customize.setProperties({
      minStay: null,
      gapFillingEnabled: undefined,
      orphanMinStay: undefined,
      orphanMinStayBuffer: undefined,
      minPrice: null,
      maxPrice: null,
      basePrice: null,
      minPricePercentage: null,
      basePricePercentage: null,
      maxPricePercentage: null,
      minStayRanges: [],
      lastMinuteMinStays: [],
      minPriceRanges: [],
      minMaxPriceRanges: [],
      discounts: [],
      checkinDays: [],
      checkoutDays: [],
      checkinCheckoutDaysRanges: [],
      orphanDays: [],
      isOrphanChangeoverDaysEnabled: undefined,
      extraGuestFee: null,
      extraGuestThreshold: null,
      enabled: undefined,
      onProgram: null,
      reasonsSyncingTurnedOff: undefined,
      monthlyEnabled: undefined,
      monthlyMinPrice: null,
      monthlyMinPricePercentage: undefined,
      monthlyMinPriceRanges: [],
      monthlyMinMaxPriceRanges: [],
      monthlyPriceOverridePercentageRanges: [],
      monthlySeasonsRanges: [],
      excludeSpecialRequirements: true,
      currencies: null,
    });
    this.fieldsToUpdateOnImport = [];
    this.fieldsToReplaceOnImport = [];

    set(this, 'gapFillSelectedOption', null);
    set(this, 'isOrphanChangeoverDaysEnabledSelectedOption', null);
    set(this, 'priceSyncingSelectedOption', null);
    set(this, 'listingProgramSelectedOption', null);
    set(this, 'monthlyPriceSelectedOption', null);

    set(this, 'basePriceForBulkSelectedSetting', null);
    set(this, 'basePriceForBulkValue', null);

    set(this, 'minPriceForBulkSelectedSetting', null);
    set(this, 'minPriceForBulkValue', null);
    set(this, 'discountsForBulk', []);

    this.gapFillFurtherOptions.forEach(opt => {
      set(opt, 'value', undefined);
    });

    set(this, 'monthlyMinPriceForBulkSelectedSetting', null);
    set(this, 'monthlyMinPriceForBulkValue', null);
  }

  @action
  updateData(listing, settings) {
    //reset customize model once user chooses a different listing for reference
    this.resetCustomize();

    set(this, 'listingReferralToImport', listing);
    if (settings && this.hasAllSelectedListingsWithoutChangeoverSupport) {
      settings = Object.assign(settings, {
        checkinDays: [],
        checkoutDays: [],
        checkinDaysRanges: [],
        checkinCheckoutDaysRanges: [],
      });
    }
    set(this, 'listingReferralSettings', settings);
  }

  @action
  setCustomizeFromReference(settings) {
    this.resetCustomize();

    settings.forEach(setting => {
      if (setting.value.firstObject) {
        let ranges = [];

        setting.value.forEach(range => {
          if (!range.selected) {
            return;
          }

          let rangeFields =
            setting.rangeKey !== undefined && setting.rangeKey.firstObject
              ? setting.rangeKey.join(' ')
              : setting.rangeKey;

          set(range, 'range.fields', rangeFields);

          if (['dayOfWeekMinPrices', 'dayOfWeekMinStays'].includes(setting.key)) {
            ranges.pushObject(range.range);
          } else {
            ranges.pushObject(ValueRange.create(range.range));
          }

          if (['discounts', 'orphanDays', 'lastMinuteMinStays'].includes(setting.key)) {
            this.fieldsToReplaceOnImport.pushObject(setting.field);
          } else {
            this.fieldsToUpdateOnImport.pushObject(setting.field);
          }
        });
        this.customize.set(setting.key, ranges);
      } else {
        if (setting.selected === false) return;
        set(
          this,
          `customize.${setting.key}`,
          get(this, `listingReferralSettings.${setting.key}`)
        );

        if (!['extraGuestThreshold'].includes(setting.key)) {
          this.fieldsToReplaceOnImport.pushObject(setting.field);
        }

        setting.extrafields?.forEach(extra => {
          if (extra.selected === false) return;

          set(
            this,
            `customize.${extra.key}`,
            get(this, `listingReferralSettings.${extra.key}`)
          );
          this.fieldsToReplaceOnImport.pushObject(extra.field);
        });
      }
    });
  }

  @action
  removeAllSelectedListings(listings) {
    listings.forEach(listing => {
      this.pricingController.send('addSelectedListing', listing, false);
    });
  }

  // When header div is clicked, show that pane
  @action
  toggleContent(value, fields, rangeName) {
    let visible = this.set(value, !this.get(value));

    let ranges = [];
    if (rangeName) {
      ranges = this.get(rangeName);
    }
    // When collapsing, remove any extra empty ranges
    if (
      !visible &&
      RangeValidator.removeEmpty(ranges).length === 0 &&
      rangeName !== undefined
    ) {
      this.set(rangeName, []);
    }
  }

  @action
  toggleRemoveAddBtn(btnToShow, model, state) {
    this.set(btnToShow, state);
    if (!state) {
      this.set(model, null);
    }
  }

  @action
  async saveListings() {
    if (!this.canSave) return;

    const currencies = this.selectedListings
      .map(c => c.currency)
      .reduce(
        (base, current) => {
          if (base === []) return (base.output = [current]);
          base.output = [
            ...base.output,
            base.output.includes(current) ? null : current,
          ].filter(c => c);
          return base;
        },
        { output: [] }
      );

    set(this, 'customize.currencies', currencies.output);

    let validation = this.customize.validateForm(this.intl);

    // There was a validation error. Code bellow expands the panes with the errors
    if (!validation) {
      for (let prop in FIELD_PANE_MAP) {
        let field = get(this, `customize.${prop}`);
        if (Array.isArray(field)) {
          field.map(r => {
            if (r.error) {
              this.set(FIELD_PANE_MAP[prop], true);
            }
          });
        }
      }

      let validationErrors = this.customize.validationErrors;
      if (validationErrors.extraGuestFees) {
        this.set(FIELD_PANE_MAP.extraGuestFees, true);
      }
      if (validationErrors.maxPrice) {
        this.set(FIELD_PANE_MAP.maxPrice, true);
      }
      return;
    }

    if (this.lastMinuteMinStaysHasErrors) {
      this.set(FIELD_PANE_MAP.minStay, true);
      return;
    }

    if (this.saving) {
      return;
    }

    if (this.forcePaymentMethod.userRequirements() && this.customize.enabled) {
      this.forcePaymentMethod.redirect();
      return;
    }

    let url = '/v2/listings/bulk/customize';
    let listingIds = this.selectedListingsIds;
    if (this.excludeSpecialRequirements && this.hasSpecialRequirementsEnabled) {
      let lIds = [];
      for (const l of this.selectedListings) {
        if (!l.specialRequirements) {
          lIds.push(l.id);
        }
      }
      listingIds = lIds;
    }

    let serializedDirtyData = {
      listingIds: listingIds,
      dateAndTime: moment().format(),
      locale: moment.locale(),
      ...this.customize.serializeDirtyData(),
      ...(this.listingReferralSettings && {
        referenceListingId: this.listingReferralToImport.id,
      }),
      ...(this.customize.enabled && {
        onProgram: true,
      }),
    };

    if (this.hasSpecialRequirementsEnabled) {
      serializedDirtyData = {
        excludeSpecialRequirements: this.excludeSpecialRequirements,
        ...serializedDirtyData,
      };
    }

    set(this, 'saving', true);
    let onComplete = () => {
      set(this, 'saving', false);
      set(this, 'listingReferralToImport', null);
      set(this, 'listingReferralSettings', null);
      this.pricingController.isBulkUpdatesOngoing = null;
      this.resetCustomize();
    };

    let result;
    let currentUrl = document.location.href;
    window.history.replaceState({}, document.title, currentUrl.split('?')[0]);
    try {
      result = await this.ajax._post(url, serializedDirtyData);
    } catch (errors) {
      displayErrors({ errors: errors, modelOrKeywordThis: this, alert: alert });
      onComplete();
      return;
    } finally {
      window.history.replaceState({}, document.title, currentUrl);
    }

    if (result.jobId) {
      let listingCount = serializedDirtyData.listingIds.length;
      let jobUrl = `/v2/job/${result.jobId}/status`;
      this.pricingController.isBulkUpdatesOngoing = EmberObject.create({
        status: 'ongoing',
        message: `Saving changes on ${listingCount} listings...`,
      });

      try {
        await this.ajax.stream(jobUrl, {}, responseData => {
          this.pricingController.isBulkUpdatesOngoing.setProperties({
            message: responseData.message,
          });
        });
      } catch (error) {
        console.log('Error caught', error);
        this.alert.error('validation.genericWithContact');
        onComplete();
        return;
      }
    }
    let clNames = [];
    let isNewListing = true;
    for (const l of this.selectedListings) {
      if (!l.isNewListing) {
        isNewListing = l.isNewListing;
        for (const cl of l.channelListingsNames) {
          if (!clNames.includes(CHANNEL_CONFIG[cl].label)) {
            clNames.push(CHANNEL_CONFIG[cl].label);
          }
        }
      }
    }

    let clNamesString = clNames.join(', ');

    if (isNewListing) {
      this.alert.success(this.intl.t('common.savedSuccessfully'));
    } else {
      this.alert.success(
        this.intl.t('pricing.bulkEdit.bulkEditSuccesfullySaved', {
          clNamesString: clNamesString,
        })
      );
    }

    onComplete();

    if (this.target.currentRoute.name.includes('grid')) {
      getOwner(this).lookup('controller:dashboard.grid').send('cancelBulkEdit');
      getOwner(this).lookup('route:dashboard.grid').refresh();
    } else {
      getOwner(this)
        .lookup('controller:dashboard.pricing.index')
        .send('cancelBulkEdit');
      getOwner(this).lookup('route:dashboard').refresh();
    }
  }

  /**
    min stays
  */
  @action
  updateSelectedOption(param, check) {
    this.set(`${param.optionFor}SelectedOption`, check ? param.value : null);
  }

  @action
  toggleGapFill() {
    if (!this.gapFillSelectedOption) {
      set(this, 'customize.gapFillingEnabled', undefined);
    } else if (this.gapFillSelectedOption === 'on') {
      set(this, 'customize.gapFillingEnabled', true);
    } else {
      set(this, 'customize.gapFillingEnabled', false);
    }
  }

  @action
  updateOrphanMinStayOptions(option, evt) {
    if (option.value === undefined && evt === undefined) {
      set(this, `customize.${option.field}`, undefined);
      set(
        this.gapFillFurtherOptions.filter(opt => opt.field === option.field)[0],
        'value',
        undefined
      );
      return;
    }

    let incomingValue = evt ? evt.target.value : option.value;

    set(
      this,
      `customize.${option.field}`,
      incomingValue ? Math.abs(parseInt(incomingValue)) : null
    );
    set(
      this.gapFillFurtherOptions.filter(opt => opt.field === option.field)[0],
      'value',
      incomingValue ? Math.abs(parseInt(incomingValue)) : null
    );
  }

  @action
  toggleIsOrphanChangeoverDaysEnabled() {
    if (!this.isOrphanChangeoverDaysEnabledSelectedOption) {
      set(this, 'customize.isOrphanChangeoverDaysEnabled', undefined);
    } else if (this.isOrphanChangeoverDaysEnabledSelectedOption === 'on') {
      set(this, 'customize.isOrphanChangeoverDaysEnabled', true);
    } else {
      set(this, 'customize.isOrphanChangeoverDaysEnabled', false);
    }
  }

  @action
  togglePriceSyncing() {
    if (!this.priceSyncingSelectedOption) {
      set(this, 'customize.enabled', undefined);
      set(this, 'customize.reasonsSyncingTurnedOff', undefined);
    } else if (this.priceSyncingSelectedOption === 'on') {
      set(this, 'customize.enabled', true);
    } else {
      set(this, 'customize.enabled', false);
      set(this, 'customize.reasonsSyncingTurnedOff', null);
    }
  }

  @action
  toggleListingProgram() {
    if (!this.listingProgramSelectedOption) {
      set(this, 'customize.onProgram', undefined);
    } else if (this.listingProgramSelectedOption === 'on') {
      set(this, 'customize.onProgram', true);
    } else {
      set(this, 'customize.onProgram', false);
    }
  }

  @action
  toggleMonthyPrice() {
    if (!this.monthlyPriceSelectedOption) {
      set(this, 'customize.monthlyEnabled', undefined);
    } else if (this.monthlyPriceSelectedOption === 'on') {
      set(this, 'customize.monthlyEnabled', true);
    } else {
      set(this, 'customize.monthlyEnabled', false);
    }
  }

  @action
  updateReasonsForDisablePricing(reasons, disabledFlag) {
    this.disablingSurveyIsIncomplete = disabledFlag;
    if (reasons.length == 0) {
      set(this, 'customize.reasonsSyncingTurnedOff', undefined);
    } else {
      set(this, 'customize.reasonsSyncingTurnedOff', reasons);
    }
  }
  /*
  base and min price
  */

  @action
  updatePriceValueForBulk(field, isSetting, evt) {
    const currencies = this.selectedListings
      .map(c => c.currency)
      .reduce(
        (base, current) => {
          if (base === []) return (base.output = [current]);
          base.output = [
            ...base.output,
            base.output.includes(current) ? null : current,
          ].filter(c => c);
          return base;
        },
        { output: [] }
      );

    set(this, 'customize.currencies', currencies.output);

    let value = null;

    if (isSetting) {
      set(this, `${field}ForBulkSelectedSetting`, evt);
      set(this, `${field}ForBulkValue`, value);
    } else {
      value = evt.target.value ? evt.target.value : null;

      if (value !== null && value <= 0) {
        value = 1;
      }

      set(this, `${field}ForBulkValue`, value);
    }

    //reset for every update
    set(this, `customize.${field}`, null);
    set(this, `customize.${field}Percentage`, null);

    if (
      get(this, `${field}ForBulkSelectedSetting`) === null ||
      get(this, `${field}ForBulkValue`) === null
    ) {
      return;
    }

    if (get(this, `${field}ForBulkSelectedSetting.action`) === 'set') {
      set(this, `customize.${field}`, parseInt(get(this, `${field}ForBulkValue`)));
    } else {
      if (get(this, `${field}ForBulkSelectedSetting.action`) === 'increase') {
        set(
          this,
          `customize.${field}Percentage`,
          100 + +parseInt(get(this, `${field}ForBulkValue`)) / 1
        );
      } else {
        set(this, `${field}ForBulkValue`, value >= 100 ? 100 : value);

        set(
          this,
          `customize.${field}Percentage`,
          100 - +parseInt(get(this, `${field}ForBulkValue`))
        );
      }
    }
    set(this, 'formHasErrors', !this.customize.validateInput());
  }

  @action
  addRange(ranges, fields, rangeField) {
    const defaultData = {
      startDate: moment(),
      endDate: moment(),
      fields: fields,
    };
    fields.split(' ').forEach(field => {
      // eslint-disable-next-line no-prototype-builtins
      if (!RANGE_DEFAULT.hasOwnProperty(field)) {
        throw Error('Field not found');
      }
      defaultData[field] = ['minPrice', 'monthlyMinPrice'].includes(field)
        ? this.customize[field]
        : RANGE_DEFAULT[field];
    });
    ranges.pushObject(ValueRange.create(defaultData));
    this.rangeUpdated(rangeField, ranges);
  }

  @action
  removeRange(ranges, fields, range) {
    ranges.removeObject(range);
    let valid = this.customize.validateForm(this.intl);
    if (ranges.length == 0) {
      set(this, 'customize.currencies', null);
    }
    this.checkFormForErrors(valid, fields, ranges);
  }

  @action
  rangeUpdated(fields, ranges) {
    const currencies = this.selectedListings
      .map(c => c.currency)
      .reduce(
        (base, current) => {
          if (base === []) return (base.output = [current]);
          base.output = [
            ...base.output,
            base.output.includes(current) ? null : current,
          ].filter(c => c);
          return base;
        },
        { output: [] }
      );
    set(this, 'customize.currencies', currencies.output);

    let valid = this.customize.validateForm(this.intl);
    this.checkFormForErrors(valid, fields, ranges);
  }

  @action
  confirmBulkUpdates() {
    if (!this.canSave) return;
    if (this.customize.enabled && this.pricingController.isBillingBannerVisible) {
      let blockingErrorMessage = [
        this.intl.t(this.pricingController.billingBannerMessage[0]),
        this.intl.t('pricing.updatePaymentDetailsSimple'),
        this.intl.t(this.pricingController.billingBannerMessage[1]),
      ].join(' ');

      this.alert.error(blockingErrorMessage, 10000);
      return;
    }

    if (
      !this.isCheckoutUpdateAvailable &&
      !this.listingReferralSettings &&
      this.customize.checkinCheckoutDaysRanges
    ) {
      this.customize.checkinCheckoutDaysRanges.forEach(range => {
        set(range, 'checkoutDays', range.checkinDays);
        set(range, 'fields', 'checkinDays checkoutDays');
      });
    }

    set(this, 'customize.priceOverrideRanges', []);

    if (this.customize.priceOverridePercentageRanges?.some(r => r.isFixedValue)) {
      set(
        this,
        'customize.priceOverrideRanges',
        this.customize.priceOverridePercentageRanges.filter(r => r.isFixedValue)
      );

      this.customize.priceOverrideRanges.forEach(r => {
        r.set('fields', r.fields.replace('priceOverridePercentage', 'priceOverride'));
        r.set('priceOverride', r.priceOverridePercentage);
      });
    }

    this.pricingController.confirmBulkActions = EmberObject.create({
      visible: true,
      data: this.customize.serializeDirtyData(),
      success: this.saveListings,
    });

    if (!this.listingReferralSettings) {
      this.pricingController.confirmBulkActions.setProperties({
        contextInfo: {
          isCheckoutSupported:
            this.model.user.isCheckoutDaysEnabled ||
            this.model.accounts.map(account => account.channel).includes('airbnb')
              ? true
              : false,
        },
      });
      return;
    }
    let isCheckoutSupported =
      this.listingReferralSettings.isCheckoutEnabled &&
      this.listingReferralSettings.checkinCheckoutChannelTerminology !== 'changeover';
    let fieldsToReplace = [...this.fieldsToReplaceOnImport];
    if (!isCheckoutSupported) {
      fieldsToReplace = fieldsToReplace.filter(
        field => field !== 'Annual Checkout Days'
      );
    }

    this.pricingController.confirmBulkActions.setProperties({
      currency: this.listingReferralToImport.currency,
      referenceListingId: this.listingReferralToImport.id,
      fieldsToUpdate: [...new Set(this.fieldsToUpdateOnImport)],
      fieldsToReplace: [...new Set(fieldsToReplace)],
      contextInfo: {
        isCheckoutSupported: isCheckoutSupported,
      },
    });
  }

  @action
  updateCheckinDays(select) {
    set(this, 'customize.checkinDays', [...select]);
    if (
      !(
        this.model.user.isCheckoutDaysEnabled ||
        this.model.accounts.map(account => account.channel).includes('airbnb')
      )
    ) {
      set(this, 'customize.checkoutDays', this.customize.checkinDays);
    }
  }

  @action
  updateDiscounts(ranges, rangesName, targetRanges, isValid) {
    set(this, rangesName, ranges);
    if (!isValid) {
      set(this, 'formHasErrors', true);
      set(this.customize, targetRanges, '');
    } else {
      set(this, 'formHasErrors', false);
      set(
        this.customize,
        targetRanges,
        ranges.map(discount =>
          discount.getProperties('percentage', 'direction', 'days')
        )
      );
    }
  }

  @action
  updateGapDiscounts(ranges, rangesName, targetRanges, isValid) {
    set(this, rangesName, ranges);
    if (!isValid) {
      set(this, 'formHasErrors', true);
      set(this.customize, targetRanges, '');
    } else {
      set(this, 'formHasErrors', false);
      set(
        this.customize,
        targetRanges,
        ranges.map(discount =>
          discount.getProperties('percentage', 'days', 'ignoreOnWeekdays')
        )
      );
    }
  }

  @action
  updateLastMinuteMinStays(ranges, rangesName, targetRanges, isValid) {
    set(this, rangesName, ranges);
    if (!isValid) {
      set(this, 'formHasErrors', true);
      set(this.customize, targetRanges, '');
    } else {
      set(this, 'formHasErrors', false);
      set(
        this.customize,
        targetRanges,
        ranges.map(discount =>
          discount.getProperties('minDays', 'daysAway', 'direction')
        )
      );
    }
  }

  async init() {
    super.init(...arguments);

    await this.featureFlag
      .evaluate('listing-special-requirements', false)
      .then(enabled => {
        set(this, 'hasSpecialRequirementsEnabled', enabled);
      });

    await this.featureFlag
      .evaluate('enable-price-syncing-in-bulk', false)
      .then(enabled => {
        set(this, 'isPriceSyncingAvailable', enabled);
      });

    await this.featureFlag.evaluate('monthly-pricing-v-1', false).then(enabled => {
      set(this, 'isMonthlyPricingAvailable', enabled);
    });

    await this.featureFlag
      .evaluate('day-of-week-widget-for-bulk-edit', false)
      .then(enabled => {
        set(this, 'dayOfWeekIsAvailable', enabled);
      });

    await this.featureFlag
      .evaluate('day-of-week-settings-in-importing-with-bulk-actions', false)
      .then(enabled => {
        set(this, 'isDoWSettingsAvailable', enabled);
      });
  }

  @action updateExcludeSpecialRequirements() {
    set(this, 'excludeSpecialRequirements', !this.excludeSpecialRequirements);
  }

  // -- Private Functions --------------------------------------------------------------

  buildCleanRanges() {
    const cleanRanges = {
      minStayRanges: null,
      minPriceRanges: null,
      maxPriceRanges: null,
      minMaxPriceRanges: null,
      changeoverDayRanges: null,
      checkinDaysRanges: null,
      checkinCheckoutDaysRanges: null,
      priceOverridePercentageRanges: null,
      monthlyMinPriceRanges: null,
      monthlyMinMaxPriceRanges: null,
      monthlyPriceOverridePercentageRanges: null,
      monthlySeasonsRanges: null,
      onProgram: null,
    };

    Object.keys(cleanRanges).forEach(rangeName => {
      const ranges = this.get(`model.${rangeName}`);
      cleanRanges[rangeName] = RangeValidator.removeEmpty(ranges);
    });

    return cleanRanges;
  }

  checkFormForErrors(valid, fields, ranges) {
    if (valid) {
      if (fields !== 'priceOverridePercentageRanges') {
        this.set(`customize.${fields}`, copy(ranges, true));
      }

      this.set('formHasErrors', !valid);
    } else {
      this.set('formHasErrors', !valid);
    }
  }
}
