import { set } from '@ember/object';

/**
 * Applies the settings to the merged listings model (sync, markups)
 * Optimistic update after the POST operation
 */
export function ApplyMergedListingsConfigUC(listings, settings) {
  try {
    const {
      markups,
      syncPricing,
      syncAvailability,
      syncReservations,
      syncContent,
      reservationTaxStatus,
    } = settings;

    for (let listing of listings) {
      const {
        availabilityIsSupported,
        reservationIsSupported,
        contentSyncingIsSupported,
      } = listing;
      /** SYNCs ------------------------------------------------------------------------*/
      // I wonder in app models what would be the cost of grouping this into a single object (💡)
      // Note: Null value means not all the listings have same sync value, so we don´t update them
      // until that incoherence is solved.

      // price sync
      if (syncPricing != null) {
        set(listing, 'enabled', syncPricing);
      }
      // availability sync
      // set availabilityEnabled only if availability sync is supported
      if (availabilityIsSupported && syncAvailability != null) {
        set(listing, 'availabilityEnabled', syncAvailability);
      }
      // reservation sync
      if (reservationIsSupported && syncReservations != null) {
        set(listing, 'reservationsEnabled', syncReservations);
      }

      if (contentSyncingIsSupported && syncContent != null) {
        set(listing, 'contentSyncingEnabled', syncContent);
      }

      // New! This was missing from the model update <---
      set(listing, 'reservationTaxStatus', reservationTaxStatus);

      /** CHANNEL MARKUPS --------------------------------------------------------------*/
      if (markups) {
        const markupsChannelNames = Object.keys(markups);
        // Update listing channelListings with settings markups
        markupsChannelNames.forEach(channel => {
          const matchingChannelListings = listing.channelListings.filter(
            cl => cl.channel === channel
          );
          for (let channelListing of matchingChannelListings) {
            //Dynamic Markups
            if (typeof markups[channel] === 'object') {
              set(channelListing, 'minMarkup', markups[channel].minMarkup);
              set(channelListing, 'maxMarkup', markups[channel].maxMarkup);
              set(channelListing, 'dynamicMarkupsEnabled', true);
            } else {
              // Static markups
              set(channelListing, 'markup', markups[channel]);
              set(channelListing, 'dynamicMarkupsEnabled', false);
            }
          }
        });
      }
    }
  } catch (e) {
    throw new Error('relay.errorTryAgain');
  }
}
