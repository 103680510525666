import classic from 'ember-classic-decorator';
import { attributeBindings, tagName } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import { action } from '@ember/object';
// http://www.kaspertidemann.com/handling-contenteditable-in-ember-js-via-ember-contenteditableview/
import { next } from '@ember/runloop';

import Component from '@ember/component';
@classic
@tagName('div')
@attributeBindings('contenteditable', 'spellcheck')
export default class AppContentEditable extends Component {
  contenteditable = 'false';
  spellcheck = 'false';

  // Don't allow the value to be changed by the binding if the user is currently
  // editing the field.
  isUserTyping = false;

  // Remember that value in this case is kind of fake - it's just the text
  // content of the tag. The binding is still important for setting initial
  // value and reading the value out.
  value = '';

  // eslint-disable-next-line ember/no-observers
  @observes('value')
  _valueChanged() {
    if (this.isUserTyping) {
      return;
    }
    if (!this.value) {
      return;
    }
    this.setContent();
  }

  didInsertElement() {
    return this.setContent();
  }

  click() {
    this.set('contenteditable', 'true');
    next(() => this.element.focus());
    return true;
  }

  touchStart() {
    this.set('contenteditable', 'true');
    next(() => this.element.focus());
    return true;
  }

  focusIn() {
    this.focusChanged(true);
    this.set('dirtyValue', this.value);
    return false;
  }

  focusOut() {
    if (this.isUserTyping) {
      this.set('isUserTyping', false);
      // observers don't get called when the value hasn't changed. See:
      // https://github.com/emberjs/ember.js/blob/5fe2d63a7dab0484cad9e729886ac71b4c05f1fd/packages/ember-metal/lib/property_set.js#L80
      // On null values, we want to set value back to some default, but we don't
      // want to do it until the input box has blurred. Because the observer
      // doesn't get called again, we can't do this by setting the value to null
      // again, so data down doesn't work and we have a leaky abstraction here.
      this.setContent();
    }
    this.focusChanged(false);
    this.set('contenteditable', 'false');

    return true;
  }

  keyDown(e) {
    if (e.metaKey) {
      return true;
    }

    // Enter
    if (e.keyCode === 13) {
      this.element.blur();
      return false;
    }

    // ESC
    if (e.keyCode === 27) {
      this.set('value', this.dirtyValue);
      this.input(this.dirtyValue);
      this.set('isUserTyping', false);
      this.element.blur();
      return false;
    }

    this.set('isUserTyping', true);
    return true;
  }

  keyUp() {
    this.set('value', this.element.textContent);
    return true;
  }

  setContent() {
    this.element.innerHTML = this.value;
    return true;
  }

  @action
  edit() {
    // TODO: onblur
    this.set('contenteditable', 'true');
    next(() => this.element.focus());
    return false;
  }
}
