import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class ChannelRoute extends AuthRoute {
  // Turn off sticky params
  resetController(controller, isExiting, _transition) {
    if (isExiting) {
      controller.set('id', null);
      controller.set('idSecondary', null);
      controller.set('code', null);
      controller.set('product', null);
    }
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('connectModel', this.modelFor('dashboard.connect-prompt'));
    controller.set(
      'chainedConnectContext',
      this.modelFor('dashboard.connect-prompt').chainedConnectContext
    );
    controller.set('product', 'pricing');
  }
}
