import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
/* global google */

// I got super distracted making a color pallete generator, but it's a rabbit
// hole so I am hard coded and rotating through.
//  * https://github.com/taketwo/glasbey
//  * https://github.com/davidmerfield/randomColor/
import Component from '@ember/component';

import { defer, Promise as EmberPromise } from 'rsvp';
import $ from 'jquery';
const colors = [
  '#4281A4',
  '#48A9A6',
  '#AA5804',
  '#D4B483',
  '#C1666B',
  '#5FAD56',
  '#F2C14E',
  '#F78154',
  '#4D9078',
  '#B4436C',
];

// Make sure we only load the google maps script once per page load. After than,
// can just create new maps.
let mapsPromise = null;

let loadMaps = () => {
  if (mapsPromise) {
    return mapsPromise;
  }

  // For david@beyondstays.com
  let key = 'AIzaSyAmqESDpUOXSzYyAR9EeNujbXyh52ZhBCk';
  // We always load the viz library but we only really need it for heatmaps
  let src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=datamapinit&libraries=visualization`;

  $.getScript(src, () => {});

  let deferred = defer();
  window.datamapinit = () => {
    deferred.resolve();
  };

  mapsPromise = deferred.promise;
  return mapsPromise;
};

// This is a function to make it's not called before the google maps scripts is
// loaded.
let icons = () => {
  return {
    default: {
      url: '/assets/marker-default.png',
      size: new google.maps.Size(32, 32),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16, 16),
    },
    highlight: {
      url: '/assets/marker-highlight.png',
      size: new google.maps.Size(32, 32),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16, 16),
    },
    home: {
      url: '/assets/marker-home.svg',
      size: new google.maps.Size(64, 64),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(32, 32),
    },
  };
};

@classic
@classNames('app-google-map')
export default class AppGoogleMap extends Component {
  points = [];
  areas = [];
  polygons = [];
  setActivePolygon = () => {};
  updatePolygon = () => {};

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    let highlightedPoint = this.getAttr('highlightedPoint');
    this.set('highlightedPoint', highlightedPoint);

    let polygons = this.getAttr('polygons');
    let points = this.getAttr('points');
    let home = this.getAttr('home');
    let heatmapPoints = this.getAttr('heatmapPoints');

    if (heatmapPoints) {
      this._drawHeatmap(heatmapPoints);
    }

    if (points) {
      this._drawPoints(points, home);
    }
    if (polygons) {
      this._drawPolygons(polygons);
    }
  }

  _buildPromise = null;

  buildMap() {
    if (this._buildPromise) {
      return this._buildPromise;
    }

    this._buildPromise = new EmberPromise(resolve => {
      loadMaps().then(() => {
        // Apparently creating new maps is a memory leak, and there's no good
        // way to destoy them.
        //  * https://code.google.com/p/gmaps-api-issues/issues/detail?id=3803
        // Demo here:
        //  * http://jsfiddle.net/KWf4r/
        let el = this.element;
        let map = new google.maps.Map(el, {
          gestureHandling: 'cooperative',
        });
        resolve(map);
      });
    });

    return this._buildPromise;
  }

  // TODO: implement this and make it just alter the modified points
  // markers: ( -> ).property 'points.@each'
  pointsCache = {};

  _boundsFor(points) {
    // Can't just do a max(...lngs) here as that uses .apply under the hood,
    // which crashes with large arrays.
    let lat = points.reduce(
      (a, p) => ({
        min: Math.min(p.latitude, a.min || Infinity),
        max: Math.max(p.latitude, a.max || -Infinity),
      }),
      {}
    );

    let lng = points.reduce(
      (a, p) => ({
        min: Math.min(p.longitude, a.min || Infinity),
        max: Math.max(p.longitude, a.max || -Infinity),
      }),
      {}
    );

    if (Number.isNaN(lat) || Number.isNaN(lng)) {
      throw Error('NaN finding bounds');
    }

    let southWest = new google.maps.LatLng(lat.min, lng.min);
    let northEast = new google.maps.LatLng(lat.max, lng.max);
    return new google.maps.LatLngBounds(southWest, northEast);
  }

  _drawPolygons(polygons) {
    if (!polygons.length) {
      return;
    }

    this.buildMap().then(map => {
      let selectedVertex;

      // https://developers.google.com/maps/documentation/javascript/examples/delete-vertex-menu
      google.maps.event.addDomListener(document, 'keyup', function (e) {
        let code = e.keyCode ? e.keyCode : e.which;

        if (selectedVertex && (code === 8 || code === 46)) {
          let [path, vertex] = selectedVertex;
          path.removeAt(vertex);
        }
      });

      // We allow multipe polygons. Flatten them before finding bounds.
      let points = polygons.reduce((sum, p) => sum.concat(p.points), []);
      map.fitBounds(this._boundsFor(points));

      let self = this;
      let i = 0;
      let mapPolygons = [];

      polygons.forEach(polygon => {
        let color = colors[i++ % colors.length];

        let mapPolygon = new google.maps.Polygon({
          editable: false,
          paths: polygon.points.map(
            p => new google.maps.LatLng(p.latitude, p.longitude)
          ),
          strokeColor: color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.35,
          map: map,
        });
        mapPolygons.push(mapPolygon);

        // TODO: remove listeners on cleanup
        google.maps.event.addListener(mapPolygon, 'click', function (e) {
          if (e.vertex === undefined) {
            self.setActivePolygon(polygon);
            mapPolygons.forEach(mp => mp.setEditable(false));
            mapPolygon.setEditable(true);
            return;
          }
          let path = mapPolygon.getPaths().getAt(e.path);
          selectedVertex = [path, e.vertex];
        });
        mapPolygon.getPaths().forEach(path => {
          google.maps.event.addListener(path, 'set_at', function () {
            let pathPoints = path.getArray().map(point => {
              return [point.lng(), point.lat()];
            });
            self.updatePolygon(pathPoints);
          });
          google.maps.event.addListener(path, 'insert_at', function () {
            let pathPoints = path.getArray().map(point => {
              return [point.lng(), point.lat()];
            });
            self.updatePolygon(pathPoints);
          });
          google.maps.event.addListener(path, 'remove_at', function () {
            let pathPoints = path.getArray().map(point => {
              return [point.lng(), point.lat()];
            });
            self.updatePolygon(pathPoints);
          });
        });
      });
    });
  }

  _drawHeatmap(points) {
    if (!points.length) {
      return;
    }

    this.buildMap().then(map => {
      map.fitBounds(this._boundsFor(points));

      let heatmap = new google.maps.visualization.HeatmapLayer({
        map: map,
        data: points.map(
          point =>
            new google.maps.LatLng(Number(point.latitude), Number(point.longitude))
        ),
      });
      heatmap.set('radius', 20);
    });
  }

  _drawPoints(points, home) {
    // If the list is empty still draw the map with the home.
    // if (!points.length) {
    //   return;
    // }

    this.buildMap().then(map => {
      this.cleanupUnusedMarkers(points);

      const pointsWithHome = home ? [...points, home] : points;

      map.fitBounds(this._boundsFor(pointsWithHome));

      if (home) {
        new google.maps.Marker({
          position: new google.maps.LatLng(home.latitude, home.longitude),
          map: map,
          icon: icons()['home'],
        });
      }
      let pointsCache = this.pointsCache;

      for (let point of points) {
        let icon = icons().default;
        if (point === this.highlightedPoint) {
          icon = icons().highlight;
        }

        // Marker is the google maps wrapper for a point. Cache them so we can
        // quickly update the icon on a redraw.
        let key = `${point.latitude},${point.longitude}`;
        if (key in pointsCache) {
          let marker = pointsCache[key][0];
          marker.setIcon(icon);
          continue;
        }

        let marker = new google.maps.Marker({
          position: new google.maps.LatLng(point.latitude, point.longitude),
          map: map,
          icon: icon,
        });

        let cache = this.pointsCache;
        cache[key] = [marker, point];
        this.set('pointsCache', cache);

        google.maps.event.addListener(marker, 'mouseover', () => {
          if (this.highlightedPointChanged) {
            this.highlightedPointChanged(point);
          }
          this.set('highlightedPoint', point);

          // Redraw the map because why not.
          // Re-lookup the point cache so we correctly capture any changes
          // since this was initially defined.
          let pointsCache = this.pointsCache;
          let points = Object.keys(pointsCache).map(key => pointsCache[key][1]);
          this._drawPoints(points, home);
        });

        google.maps.event.addListener(marker, 'mouseout', () => {
          if (this.highlightedPointChanged) {
            this.highlightedPointChanged(null);
          }
          this.set('highlightedPoint', null);

          let pointsCache = this.pointsCache;
          let points = Object.keys(pointsCache).map(key => pointsCache[key][1]);
          this._drawPoints(points, home);
        });
      }
    });
  }

  /** Removes all markers that exist in points cache but do not exist in the new points array. */
  cleanupUnusedMarkers(points) {
    const pointsCache = this.pointsCache;

    const unusedPointCacheKeys = Object.keys(pointsCache).filter(key => {
      const point = pointsCache[key][1];
      return !points.includes(point);
    });

    for (let key of unusedPointCacheKeys) {
      const marker = pointsCache[key][0];

      google.maps.event.clearInstanceListeners(marker);
      marker.setMap(null);
      delete pointsCache[key];
    }

    this.set('pointsCache', pointsCache);
  }
}
