import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';
import moment from 'moment';
import { inject as service } from '@ember/service';

@classic
export default class StatsRoute extends AuthRoute {
  templateName = 'listing.stats';
  controllerName = 'listing.stats';
  @service embed;

  setupController(controller, model) {
    controller.set(
      'listing',
      this.modelFor('dashboard.pricing.listing').get('listing')
    );
    this.embed.czCaptureEvent('Stats Tab View', 'User viewed stats tab for a listing');
    controller.set('model', model);
  }

  model() {
    const dashboard = this.modelFor('dashboard.pricing.listing');
    const listingId = dashboard.get('listing.id');
    const today = moment.utc().format('YYYY-MM-DD');
    const url = `/api/listings/${listingId}/stats?date=${today}`;
    return this.ajax._get(url);
  }
}
