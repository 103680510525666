import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';

@classic
export default class DashboardParkedRoute extends Route {
  beforeModel() {
    window.document.body.classList.add('hidden');
  }

  @action
  willTransition() {
    window.document.body.classList.remove('hidden');
  }
}
