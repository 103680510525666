import { action } from '@ember/object';
import Controller from '@ember/controller';
export default class PmsFeesUploaderController extends Controller {
  uploadResponse = null;
  managedAccountId = null;
  staffUserId = null;
  managedAccountDetails = 'No Managed Account Checked';

  @action
  checkManagedAccount() {
    let managedAccountId = this.managedAccountId;
    let url = `/api/bulk_updates/check_managed_account/${managedAccountId}`;
    let controller = this;

    this.ajax._get(url).then(
      data => {
        let text = `ID: ${data.id} Channel: ${data.channel} Channel_ID: ${data.channelId} User: ${data.user}`;
        controller.set('managedAccountDetails', text);
      },
      () => {
        alert('Managed Account lookup failed.');
        controller.set('managedAccountId', 'Managed Account lookup failed');
      }
    );
  }

  @action
  async submitForm() {
    let controller = this;
    let managedAccountId = this.managedAccountId;
    let staffUserId = this.staffUserId;
    let file = document.getElementsByName('fileUpload')[0].files[0];
    let formData = new FormData();
    let url = `/api/relay/managed_account_channel_fees/${managedAccountId}/upload_fees_csv/`;
    let result;

    if (!staffUserId) {
      alert('Submitter User ID is required. Aborting.');
      return;
    }

    if (!managedAccountId) {
      alert('Managed Account ID is required. Aborting.');
      return;
    }

    if (!file) {
      alert('No file. Aborting.');
      return;
    }

    formData.append('file', file);
    formData.append('staff_user_id', staffUserId);
    formData.append('managed_account_id', managedAccountId);

    try {
      result = await this.ajax._postWithFiles(url, formData);
      if (result) {
        controller.set('uploadResponse', result.message);
      }
    } catch (error) {
      if (Array.isArray(error)) {
        controller.set('uploadResponse', error.map(e => e.message).join('\n'));
      } else {
        controller.set('uploadResponse', error.message);
      }
    }
  }
}
