import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { action, computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

@classic
@classNames('app-dashboard-listing')
export default class AppDashboardListing extends Component {
  // -- Parameters ---------------------------------------------------------------------------------
  @service('list-control') listControl;
  @service('booking-review') bookingReview;
  @service featureFlag;
  @service intl;
  @service alert;

  listing = null;
  appearance = null;
  editingListing = null;
  model = null;
  selectedListings = null;
  showPrimaryChannelInfo = false;
  showChannelInfo = false;
  listingSetupEnabled = false;
  lastUpdatedHover = null;
  lastUpdatedMinHover = null;
  editBasePrice = () => {};
  editMinPrice = () => {};
  toggleEnabled = () => {};
  saveBasePrice = () => {};
  saveMinPrice = () => {};
  addSelectedListing = () => {};
  notify = () => {};
  selectRow = () => {};
  popupVisible = false;
  popperTarget = null;
  timeout = null;
  subUnitPopupVisible = false;
  showSubUnits = false;
  lastBookingDateHover = null;
  furthestCheckinDateHover = null;
  bookingAnalysisHover = null;
  recommendationsHover = null;
  @tracked toggleTwoWeekReservationsCount = true;
  @tracked isBeingAddedToProgram = false;
  @tracked wasAddedToProgram = false;

  // -- Computed Properties ------------------------------------------------------------
  @computed('listing.frontBlockedDays')
  get hasFrontBlockedDays() {
    return this.get('listing.frontBlockedDays') >= 10;
  }

  @computed('appearance')
  get displayBasePrice() {
    const appearance = this.appearance;
    return appearance === 'card-view' || appearance === 'table-view';
  }

  @computed('listing.statistics.twoWeekReservationsCount')
  get twoWeekReservationsCount() {
    if (this.get('listing.hasStatistics')) {
      return this.get('listing.statistics.twoWeekReservationsCount');
    } else {
      return '—';
    }
  }
  @computed('listing.statistics.oneWeekReservationsCount')
  get oneWeekReservationsCount() {
    if (this.get('listing.hasStatistics')) {
      return this.get('listing.statistics.oneWeekReservationsCount');
    } else {
      return '—';
    }
  }

  @computed(
    'listing.mostRecentBookingReview.{isSuggestionCompleted,hasPendingSuggestion}',
    'bookingReview.{displayBookingReviewSubHeader,isBookingReviewActive}',
    'currentUser.isBookingReviewSubmitterEnabled'
  )
  get showBookingReviewWording() {
    // submitter case
    const displayBookingReviewSubHeader = this.bookingReview
      .displayBookingReviewSubHeader;

    if (displayBookingReviewSubHeader) {
      return true;
    }

    // approver case
    const isSuggestionCompleted = get(
      this,
      'listing.mostRecentBookingReview.isSuggestionCompleted'
    );
    const hasPendingSuggestion = get(
      this,
      'listing.mostRecentBookingReview.hasPendingSuggestion'
    );

    return (
      !this.currentUser.isBookingReviewSubmitterEnabled &&
      this.bookingReview.isBookingReviewActive &&
      (isSuggestionCompleted || hasPendingSuggestion)
    );
  }

  @computed('listing.channelListings.@each.subUnits')
  get subUnits() {
    // selecting the first one cause multi-unit can't have more than one channel listing
    let channelSubunits = this.listing.channelListings[0].subUnits;
    let units;

    if (channelSubunits) {
      let channelSubunitsIds = channelSubunits.map(su => su.id);

      units = this.listings.filter(l =>
        channelSubunitsIds.includes(Number(l.channelListings[0]?.id))
      );
    }

    return units ?? [];
  }

  @computed('appearance', 'subUnits')
  get isMultiUnitListingCardView() {
    return this.subUnits && this.subUnits.length > 0 && this.appearance === 'card-view';
  }

  @computed('appearance', 'subUnits')
  get isMultiUnitListingTableView() {
    return (
      this.subUnits && this.subUnits.length > 0 && this.appearance === 'table-view'
    );
  }

  @computed('subUnits')
  get moreSubUnits() {
    // This is displayed in card view when there's more than 5 sub units and is remaining number of listings not shown in tooltip
    return this.subUnits.length - 5;
  }

  @computed('listing.reasonsSyncingTurnedOff.[]')
  get labelsForReasonsSyncingTurnedOff() {
    if (!this.listing.reasonsSyncingTurnedOff.some(l => l)) return [];

    let labels = [
      {
        id: 'MARKET_REGULATIONS',
        label: this.intl.t('pricing.listing.reasonsToDisable.marketRegulations'),
      },
      {
        id: 'MONTHLY_RENTALS',
        label: this.intl.t('pricing.listing.reasonsToDisable.monthlyRentals'),
      },
      {
        id: 'LEAVING_PROGRAM',
        label: this.intl.t('pricing.listing.reasonsToDisable.leavingProgram'),
      },
      {
        id: 'NO_DYNAMIC_PRICING',
        label: this.intl.t('pricing.listing.reasonsToDisable.noDynamicPricing'),
      },
      {
        id: 'STOP_RENTING',
        label: this.intl.t('pricing.listing.reasonsToDisable.stopRenting'),
      },
      {
        id: 'PAUSED_LISTING',
        label: this.intl.t('pricing.listing.reasonsToDisable.pausedListing', {
          count: 1,
        }),
      },
      {
        id: 'COMPETITION',
        label: this.intl.t('pricing.listing.reasonsToDisable.competition'),
      },
      {
        id: 'COST_ROI',
        label: this.intl.t('pricing.listing.reasonsToDisable.costRoi'),
      },
      {
        id: 'OTHER',
        label: this.intl.t('pricing.listing.reasonsToDisable.other'),
      },
    ];

    return labels
      .filter(r => this.listing.reasonsSyncingTurnedOff.includes(r.id))
      .map(l => l?.label);
  }

  @computed('revenueGoals.[]', 'listing')
  get revenueGoal() {
    let revenueGoal = this.revenueGoals.find(rg => rg.listingId === this.listing.id);
    if (!revenueGoal || !revenueGoal.target) return null;
    return {
      ...revenueGoal,
      progress: Math.round((revenueGoal.revenue * 100) / revenueGoal.target),
    };
  }

  async init() {
    super.init(...arguments);

    this.featureFlag.evaluate('new-listings-setup', false).then(value => {
      set(this, 'listingSetupEnabled', value);
    });
  }

  @action
  onEditBasePrice(listing) {
    if (listing.permissions === 'view') return;
    this.editBasePrice(listing);
  }

  @action
  onEditMinPrice(listing) {
    if (listing.permissions === 'view') return;
    this.editMinPrice(listing);
  }

  @action
  onEditRevenueGoal(listing) {
    if (listing.permissions === 'view') return;
    this.editRevenueGoal(listing);
  }

  @action
  onToggleEnabled(listing, enabled) {
    if (listing.permissions === 'view') return;
    this.toggleEnabled(listing, enabled);
  }

  @action
  onSaveBasePrice(listing, event) {
    this.saveBasePrice(listing, event);
  }

  @action
  onSaveMinPrice(listing, event) {
    this.saveMinPrice(listing, event);
  }

  @action
  onSaveRevenueGoal(listing, event) {
    this.saveRevenueGoal(listing, event);
  }

  @action
  onNotify(listing) {
    this.notify(listing);
  }

  @action
  onSelectRow(listing) {
    this.selectRow(listing);
  }

  // booking review
  @action
  onShowDetailWithPriceEnable() {
    const ratesController = getOwner(this).lookup(
      'controller:dashboard.pricing.index.booking-review.index.rates'
    );
    ratesController.set('enablePricesUponSave', true);
  }

  @action
  approveSuggestion(listing) {
    const ratesController = getOwner(this).lookup(
      'controller:dashboard.pricing.index.booking-review.index.rates'
    );
    ratesController.approveSuggestion(listing);
  }

  @action
  rejectSuggestion() {
    const ratesController = getOwner(this).lookup(
      'controller:dashboard.pricing.index.booking-review.index.rates'
    );
    ratesController.startRejectingSuggestion(true);
  }

  @action
  showSyncPopupUpdateDetails(evt) {
    set(this, 'popupVisible', true);
    set(this, 'popperTarget', evt.target);
  }

  @action
  showSubUnitPopup(evt) {
    set(this, 'subUnitPopupVisible', !this.subUnitPopupVisible);
    set(this, 'popperTarget', this.popperTarget === null ? evt.target : null);
  }

  @action
  cancelTimeout() {
    clearTimeout(this.timeout);
  }

  @action
  timeOutForClear() {
    const th = this;
    this.timeout = setTimeout(function () {
      th.clearSyncPopupUpdateDetails();
    }, 500);
  }

  @action
  clearSyncPopupUpdateDetails() {
    set(this, 'popupVisible', false);
    set(this, 'popperTarget', null);
    this.timeout = null;
  }

  @action
  toggleSubUnits() {
    set(this, 'showSubUnits', !this.showSubUnits);
  }

  @action
  alertToUnavailableListing() {
    if (this.listing.onProgram) return;

    this.alert.error(
      this.intl.t('pricing.listing.alertToOffProgramListing', {
        listingTitle: this.listing.title,
      }),
      { timeout: 10000 }
    );
  }

  @action
  async getListingOnProgram() {
    const payload = {
      listingIds: [this.listing.id],
      dateAndTime: moment().format(),
      locale: moment.locale(),
      onProgram: true,
    };

    try {
      this.isBeingAddedToProgram = true;
      await this.ajax._post('/v2/listings/bulk/customize', payload);
    } catch (errors) {
      this.alert.error(
        this.intl.t('validation.genericWithContact', {
          listingTitle: this.listing.title,
        }),
        { timeout: 10000 }
      );
      return;
    } finally {
      this.isBeingAddedToProgram = false;
      this.wasAddedToProgram = true;
      this.alert.success(this.intl.t('pricing.listing.listingAddedToProgram'), {
        timeout: 10000,
      });
      getOwner(this).lookup('route:dashboard').refresh();
    }
  }
}
