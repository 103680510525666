import moment from 'moment';

export function getReservationHistoryDates(reservation, errorLog, lastPushStatus) {
  // BG colors for the bullets representing the reservation updates ---
  const channelOperation = {
    markerColor: 'bg-[#9be9a8]',
  };
  const synced = {
    markerColor: 'bg-brand-400',
  };
  const pendingSync = {
    markerColor: 'bg-gray-400',
  };
  const errorSync = {
    markerColor: 'bg-[#e26565]',
  };

  // Dates ------------------------------------------------------------
  const unconfirmedAt = {
    type: 'in-channel',
    name: 'unconfirmed',
    dotBg: 'bg-gray-400',
    date: reservation.inquiredAt,
    ...channelOperation,
  };
  const acceptedAt = {
    type: 'in-channel',
    name: 'accepted',
    date: reservation.statusHistory?.find(st => st === 'accepted'),
    ...channelOperation,
  };
  const bookedAt = {
    type: 'in-channel',
    name: 'booked',
    date: reservation.bookedAt,
    ...channelOperation,
  };
  const syncedNewBookingAt = {
    type: 'relay',
    name: 'synced',
    date: reservation.firstPushedReservationAt,
    ref: 'bookedAt',
    ...synced,
  };
  const canceledAt = {
    type: 'in-channel',
    name: 'canceled',
    date: reservation.canceledAt,
    ...channelOperation,
  };
  const lastUpdateAt = {
    type: 'in-channel',
    name: 'last update detected',
    date: reservation.lastReservationChangesDetectedAt,
    ...channelOperation,
  };
  const lastUpdateSyncedAt = {
    type: 'relay',
    name: 'synced',
    date: reservation.pushedReservationAt,
    ref: 'lastUpdateAt',
    ...synced,
  };

  const reservationStory = [
    unconfirmedAt,
    acceptedAt,
    bookedAt,
    syncedNewBookingAt,
    canceledAt,
    lastUpdateAt,
  ]
    .filter(benchmark => benchmark.date)
    .sort((a, b) => moment(a.date).isBefore(b.date));

  // Error & Pending States --------------------------------------------------
  // When there is no sync success data (pushed_reservation_stamps),
  // check the last item of push outcome, to get the potential error
  // or a pending update.

  if (lastPushStatus.type === 'pending') {
    reservationStory.push({
      type: 'relay-pending',
      name: 'sync pending',
      ...pendingSync,
    });
  } else if (lastPushStatus.type === 'error') {
    const { outcomeId } = lastPushStatus;
    reservationStory.push({
      id: outcomeId,
      type: 'relay-error-last-sync',
      name: 'sync error',
      errorDetails: errorLog?.find(err => err.outcomeId === outcomeId)?.message,
      ...errorSync,
    });
  } else {
    reservationStory.push(lastUpdateSyncedAt);
  }

  return reservationStory;
}
