import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Component from '@ember/component';

export const IGNORE_ON_WEEKDAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

@classic
export default class AppOrphanDay extends Component {
  ignoreOnWeekDaysList = IGNORE_ON_WEEKDAYS;

  @computed('orphanDay.percentage')
  get orphanDayPercentage() {
    const pct = Math.abs(this.get('orphanDay.percentage'));
    const val = Math.floor(pct * 100);
    return val;
  }

  @computed('orphanDay.ignoreOnWeekdays')
  get orphanDayIgnoreOnWeekdays() {
    const val = this.get('orphanDay.ignoreOnWeekdays');
    return val;
  }

  @action
  onRemoveOrphanDay() {
    this.removeOrphanDay(this.orphanDay);
  }

  @action
  orphanDayPercentageChange(val) {
    let percentage = parseInt(val) / 100;
    if (this.get('orphanDay.isDecrease')) {
      percentage = percentage * -1;
    }
    this.orphanDayUpdate('percentage', percentage, this.orphanDay);
  }

  @action
  orphanDayDiscountTypeChange(ev) {
    const isDecrease = ev.target.value === 'decrease';
    const multiplier = isDecrease ? -1 : 1;
    const percentage = Math.abs(this.get('orphanDay.percentage')) * multiplier;
    this.set('orphanDay.isDecrease', isDecrease);

    if (this.orphanDay.percentage != 0) {
      this.orphanDayUpdate('percentage', percentage, this.orphanDay);
    }
  }

  @action
  orphanDayIgnoreOnWeekdaysChange(option, ev) {
    const checked = ev.target.checked;
    const newIgnoreOnWeekdaysValue = this.get('orphanDay.ignoreOnWeekdays') || [];
    if (checked) {
      newIgnoreOnWeekdaysValue.push(option);
    } else {
      newIgnoreOnWeekdaysValue.splice(newIgnoreOnWeekdaysValue.indexOf(option), 1);
    }
    this.set('orphanDay.ignoreOnWeekdays', newIgnoreOnWeekdaysValue);
    this.orphanDayUpdate('ignoreOnWeekdays', newIgnoreOnWeekdaysValue, this.orphanDay);
  }

  @action
  orphanDayChange(attr, val) {
    if (!Number.isNaN(parseInt(val))) {
      val = parseInt(val);
    }
    this.orphanDayUpdate(attr, val, this.orphanDay);
  }
}
