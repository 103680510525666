// Add count & lastTimeChecked properties
export class RelayFavoriteSearch {
  static from(fav) {
    return new RelayFavoriteSearch()
      .setName(fav.name)
      .setDescription(fav.description)
      .setSearchTags(fav.searchTags)
      .setTagName(fav.tagName)
      .setTagBg(fav.bg)
      .setTagColor(fav.color);
  }
  setName(favName) {
    this.name = favName;
    return this;
  }
  setTagName(tagName) {
    this.tagName = tagName;
    return this;
  }
  setTagBg(bg) {
    if (bg) {
      const color = bg.replace('bg-', '');
      this.bg = bg;
      this.bg = `bg-${color}`;
      this.border = `border-${color}`;
    }
    return this;
  }
  setTagColor(color) {
    this.color = color;
    return this;
  }
  setSearchTags(value) {
    this.searchTags = value;
    return this;
  }
  setDescription(value) {
    this.description = value;
    return this;
  }
}

export class FavoriteSearchRepository {
  favSearches = new Map();

  constructor() {
    this._load();
  }

  getAll() {
    return this.favSearches;
  }
  get(favName) {
    return this.favSearches.get(favName);
  }
  create(favSearch) {
    if (this.favSearches.has(favSearch.name)) {
      throw 'Fav names must be unique';
    }
    this.favSearches.set(favSearch.name, favSearch);
    this._save();
  }
  update(favSearch) {
    const { name } = favSearch;
    if (this.favSearches.has(name)) {
      this.favSearches.set(name, new Map(favSearch));
      this._save();
    }
  }
  delete(favSearch) {
    this.favSearches.delete(favSearch.name);
    this._save();
  }

  _load() {
    this.favSearches = localStorageMapAdapter().load('rel-availability-favs');
    this.favSearches.forEach((fav, name) => {
      this.favSearches.set(name, { ...fav, searchTags: new Map(fav.searchTags) });
    });
  }
  _save() {
    // Maps must be converted to arrays to be valid JSON
    const favMapClone = new Map(this.favSearches);
    favMapClone.forEach((fav, name) => {
      favMapClone.set(name, { ...fav, searchTags: Array.from(fav.searchTags) });
    });
    localStorageMapAdapter().save('rel-availability-favs', favMapClone);
  }
}

function localStorageMapAdapter() {
  const save = (key, map) => {
    const mapAsString = JSON.stringify(Array.from(map));
    localStorage.setItem(key, mapAsString);
  };
  const load = key => {
    const strMap = localStorage.getItem(key);
    if (!strMap) return new Map();

    const map = new Map(JSON.parse(strMap));

    return map;
  };

  return {
    save,
    load,
  };
}
