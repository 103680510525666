import { computed } from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';

export default BaseModel.extend({
  amountDue: 0,
  beyondFees: 0,
  earnings: 0,
  currency: 0,
  startDate: 0,
  endDate: 0,
  payments: null,
  items: null,

  init() {
    this._super(arguments);
    this.set('payments', this.payments ?? []);
    this.set('items', this.items ?? []);
  },

  hasNegativeAmount: computed('amountDue', function () {
    return this.amountDue < 0;
  }),

  classSelected: computed('itemsVisible', function () {
    if (this.itemsVisible) {
      return 'row-selected';
    }
    return '';
  }),
});
