import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { displayErrors } from 'appkit/lib/display_errors';

/**
 * Controls the update of Relay global settings for shared settings across PMS,
 * and for concrete PMS rules / overrides
 *
 * Route: dashboard/relay/settings
 * Access: feature flag (mock view)
 * Version: v.0.2 - Nova (Feb 22)
 */

export default class RelayCustomizeSettingsController extends Controller {
  settingsRepository;
  @service alert;
  @tracked settings = this.model.settings;
  @tracked savingKey = null;

  @action
  goToRelayDashboard() {
    this.transitionToRoute('dashboard.relay.index');
  }

  @action
  async onSettingToggle(channelConfig, sectionKey, settingsKey, value) {
    if (this.savingKey === settingsKey) return;
    this.savingKey = settingsKey;

    try {
      // Optimistic update of the UI to prevent delay on the toggle animation
      // If for some reason, the operation fails, it can be reverted.
      this.updateUISetting(channelConfig, sectionKey, settingsKey, !value);

      //if sync all option is selected turn on or off all sync settings accordingly
      if (sectionKey === 'sync' && settingsKey === 'syncAll') {
        await this.settingsRepository.patch(channelConfig.id, {
          shouldAutoEnablePricing: !value,
          shouldAutoEnableAvailability: !value,
          shouldAutoEnableReservations: !value,
          shouldAutoEnableContentSyncing: !value,
        });
      } else {
        await this.settingsRepository.patch(channelConfig.id, {
          [settingsKey]: !value,
        });
      }
    } catch (errors) {
      displayErrors({ errors: errors, modelOrKeywordThis: this, alert: alert });
      return false;
    } finally {
      this.savingKey = null;
    }
  }

  getSettingActiveValue(settingBlock, key) {
    const setting = settingBlock.settings.find(setting => {
      return setting.key === key;
    });
    //if setting doesn't exist then return true so it's ignored from syncAllIsActiveValue condition
    return setting ? setting.isActive : true;
  }

  updateUISetting(channelConfig, sectionKey, settingsKey, value) {
    const sectionToUpdate = channelConfig.settings[sectionKey];

    if (sectionKey === 'sync' && settingsKey === 'syncAll') {
      sectionToUpdate.settings = sectionToUpdate.settings.map(setting => {
        return { ...setting, isActive: value };
      });
    } else {
      sectionToUpdate.settings = sectionToUpdate.settings.map(setting => {
        return setting.key === settingsKey ? { ...setting, isActive: value } : setting;
      });

      if (sectionKey === 'sync') {
        const syncAllIsActiveValue =
          this.getSettingActiveValue(sectionToUpdate, 'shouldAutoEnablePricing') &&
          this.getSettingActiveValue(sectionToUpdate, 'shouldAutoEnableAvailability') &&
          this.getSettingActiveValue(sectionToUpdate, 'shouldAutoEnableReservations') &&
          this.getSettingActiveValue(sectionToUpdate, 'shouldAutoEnableContentSyncing');

        sectionToUpdate.settings = sectionToUpdate.settings.map(setting => {
          return setting.key === 'syncAll'
            ? { ...setting, isActive: syncAllIsActiveValue }
            : setting;
        });
      }
    }

    this.updateChannelConfig({
      ...channelConfig,
      settings: {
        ...channelConfig.settings,
        [sectionKey]: sectionToUpdate,
      },
    });
  }

  updateChannelConfig(updatedChannelConfig) {
    this.settings = this.settings.map(s => {
      if (s.id !== updatedChannelConfig.id) return s;
      return updatedChannelConfig;
    });
  }
}
