import Controller from '@ember/controller';
import { action, set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import EmberObject from '@ember/object';
import moment from 'moment';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';
import { observes } from '@ember-decorators/object';
// eslint-disable-next-line ember/no-observers
import { addObserver, removeObserver } from '@ember/object/observers';

const getFixedFee = fee => {
  if (!fee) {
    return fee;
  }
  if (fee.toString().split('.')[1]?.length > 2) {
    return parseFloat(fee).toFixed(2);
  }
  return fee;
};

const fieldsToWatch = [
  'seasons',
  'expenses',
  'projectionTemplateName',
  'propertyManagementFee',
  'notes',
  'website',
  'phone',
  'email',
  'address',
  'city',
  'state',
  'zipcode',
  'country',
  'ownerResource.selectedBedroomSize',
];

export default class DashboardOwnerResourceProjectionBuildProjectionController extends Controller {
  @service('owner-resource') ownerResource;
  @service('alert') alert;
  @controller('dashboard.owner-resource') ownerResourceController;

  addSeasonTabIsActive = true;

  _propertyManagementFee = null;
  checkedSubtract = true;
  includePropertyManagementFee = true;
  _seasons = [];

  newSeason = EmberObject.create({
    seasonName: null,
    startDate: null,
    formatStartDate: null,
    endDate: null,
    formatEndDate: null,
    marketOccupancy: null,
    customOccupancy: null,
    marketAdr: null,
  });

  minDate = moment().startOf('year').toDate();

  maxDate = moment().add(1, 'year').endOf('year').toDate();

  centerDate = moment().startOf('year').toDate();

  radioBtnName = 'occupancSelection';
  makertOccupancyCheckBoxValue = 'makertOccupancy';
  customOccupancyCheckBoxValue = 'customOccupancy';
  radioBtnGroupValue;

  _expenses = [];

  newExpense = EmberObject.create({
    expenseName: null,
    value: null,
  });

  _notes = null;
  projectionTemplateName = null;
  displayDeleteTemplateModal = false;
  deletingTemplateProject = null;

  defaultOptions = [
    { name: 'Custom owner projection', label: 'customOwnerProjection' },
  ];
  userGeneratedLabel = 'MY TEMPLATES';

  selectedProjectionTemplateOption = this.defaultOptions[0];

  displayCompanyInfo = false;

  _website = null;
  _phone = null;
  _email = null;
  _address = null;
  _city = null;
  _state = null;
  _zipcode = null;
  _country = null;

  dailyOccupancyAvg = null;
  dailyAdr = null;

  @tracked _selectedAddress = null;
  @tracked _selectedBedroomSize = null;
  @tracked editingListingDetails = false;
  @tracked listingAddresses = [];
  @tracked selectedAddress = null;

  get isStaff() {
    return this.currentUser.isStaff;
  }

  @computed('model.projectionTemplates.[]')
  get projectionTemplates() {
    return get(this, 'model.projectionTemplates')?.filter(pt => !pt.get('deletedAt'));
  }

  @computed('_seasons.[]', 'selectedProjectionTemplateOption.seasons.[]')
  get seasons() {
    if (get(this, 'selectedProjectionTemplateOption.seasons')) {
      return get(this, 'selectedProjectionTemplateOption.seasons').map(s =>
        EmberObject.create(s)
      );
    } else {
      return get(this, '_seasons');
    }
  }
  set seasons(value) {
    if (get(this, 'selectedProjectionTemplateOption.seasons')) {
      return set(this, 'selectedProjectionTemplateOption.seasons', value);
    } else {
      return set(this, '_seasons', value);
    }
  }

  @computed('_expenses.[]', 'selectedProjectionTemplateOption.expenses.[]')
  get expenses() {
    if (get(this, 'selectedProjectionTemplateOption.expenses')) {
      return get(this, 'selectedProjectionTemplateOption.expenses').map(e =>
        EmberObject.create(e)
      );
    } else {
      return get(this, '_expenses');
    }
  }
  set expenses(value) {
    if (get(this, 'selectedProjectionTemplateOption.expenses')) {
      return set(this, 'selectedProjectionTemplateOption.expenses', value);
    } else {
      return set(this, '_expenses', value);
    }
  }

  @computed('_propertyManagementFee', 'selectedProjectionTemplateOption.pmFee')
  get propertyManagementFee() {
    let fee;
    if (get(this, 'selectedProjectionTemplateOption.pmFee')) {
      fee = get(this, 'selectedProjectionTemplateOption.pmFee')
        ? parseFloat(get(this, 'selectedProjectionTemplateOption.pmFee'))
        : null;
    } else {
      fee = get(this, '_propertyManagementFee')
        ? parseFloat(get(this, '_propertyManagementFee'))
        : null;
    }

    return getFixedFee(fee);
  }
  set propertyManagementFee(value) {
    if (get(this, 'selectedProjectionTemplateOption.pmFee')) {
      if (!value) {
        return set(this, 'selectedProjectionTemplateOption.pmFee', getFixedFee(value));
      } else {
        return parseFloat(
          set(this, 'selectedProjectionTemplateOption.pmFee', getFixedFee(value))
        );
      }
    } else {
      if (!value) {
        set(this, '_propertyManagementFee', getFixedFee(value));
      } else {
        return parseFloat(set(this, '_propertyManagementFee', getFixedFee(value)));
      }
    }
  }

  @computed('_notes', 'selectedProjectionTemplateOption.companyNotes')
  get notes() {
    if (get(this, 'selectedProjectionTemplateOption.companyNotes')) {
      return get(this, 'selectedProjectionTemplateOption.companyNotes');
    } else {
      return get(this, '_notes');
    }
  }
  set notes(value) {
    if (get(this, 'selectedProjectionTemplateOption.companyNotes')) {
      return set(this, 'selectedProjectionTemplateOption.companyNotes', value);
    } else {
      return set(this, '_notes', value);
    }
  }

  @computed('_website', 'selectedProjectionTemplateOption.companyWebsite')
  get website() {
    if (get(this, 'selectedProjectionTemplateOption.companyWebsite')) {
      return get(this, 'selectedProjectionTemplateOption.companyWebsite');
    } else {
      return get(this, '_website');
    }
  }
  set website(value) {
    if (get(this, 'selectedProjectionTemplateOption.companyWebsite')) {
      return set(this, 'selectedProjectionTemplateOption.companyWebsite', value);
    } else {
      return set(this, '_website', value);
    }
  }

  @computed('_phone', 'selectedProjectionTemplateOption.companyPhone')
  get phone() {
    if (get(this, 'selectedProjectionTemplateOption.companyPhone')) {
      return get(this, 'selectedProjectionTemplateOption.companyPhone');
    } else {
      return get(this, '_phone');
    }
  }
  set phone(value) {
    if (get(this, 'selectedProjectionTemplateOption.companyPhone')) {
      return set(this, 'selectedProjectionTemplateOption.companyPhone', value);
    } else {
      return set(this, '_phone', value);
    }
  }

  @computed('_email', 'selectedProjectionTemplateOption.companyEmail')
  get email() {
    if (get(this, 'selectedProjectionTemplateOption.companyEmail')) {
      return get(this, 'selectedProjectionTemplateOption.companyEmail');
    } else {
      return get(this, '_email');
    }
  }
  set email(value) {
    if (get(this, 'selectedProjectionTemplateOption.companyEmail')) {
      return set(this, 'selectedProjectionTemplateOption.companyEmail', value);
    } else {
      return set(this, '_email', value);
    }
  }

  @computed('_address', 'selectedProjectionTemplateOption.companyAddress')
  get address() {
    if (get(this, 'selectedProjectionTemplateOption.companyAddress')) {
      return get(this, 'selectedProjectionTemplateOption.companyAddress');
    } else {
      return get(this, '_address');
    }
  }
  set address(value) {
    if (get(this, 'selectedProjectionTemplateOption.companyAddress')) {
      return set(this, 'selectedProjectionTemplateOption.companyAddress', value);
    } else {
      return set(this, '_address', value);
    }
  }

  @computed('_city', 'selectedProjectionTemplateOption.companyCity')
  get city() {
    if (get(this, 'selectedProjectionTemplateOption.companyCity')) {
      return get(this, 'selectedProjectionTemplateOption.companyCity');
    } else {
      return get(this, '_city');
    }
  }
  set city(value) {
    if (get(this, 'selectedProjectionTemplateOption.companyCity')) {
      return set(this, 'selectedProjectionTemplateOption.companyCity', value);
    } else {
      return set(this, '_city', value);
    }
  }

  @computed('_state', 'selectedProjectionTemplateOption.companyState')
  get state() {
    if (get(this, 'selectedProjectionTemplateOption.companyState')) {
      return get(this, 'selectedProjectionTemplateOption.companyState');
    } else {
      return get(this, '_state');
    }
  }
  set state(value) {
    if (get(this, 'selectedProjectionTemplateOption.companyState')) {
      return set(this, 'selectedProjectionTemplateOption.companyState', value);
    } else {
      return set(this, '_state', value);
    }
  }

  @computed('_zipcode', 'selectedProjectionTemplateOption.companyZipcode')
  get zipcode() {
    if (get(this, 'selectedProjectionTemplateOption.companyZipcode')) {
      return get(this, 'selectedProjectionTemplateOption.companyZipcode');
    } else {
      return get(this, '_zipcode');
    }
  }
  set zipcode(value) {
    if (get(this, 'selectedProjectionTemplateOption.companyZipcode')) {
      return set(this, 'selectedProjectionTemplateOption.companyZipcode', value);
    } else {
      return set(this, '_zipcode', value);
    }
  }

  @computed('_country', 'selectedProjectionTemplateOption.companyCountry')
  get country() {
    if (get(this, 'selectedProjectionTemplateOption.companyCountry')) {
      return get(this, 'selectedProjectionTemplateOption.companyCountry');
    } else {
      return get(this, '_country');
    }
  }
  set country(value) {
    if (get(this, 'selectedProjectionTemplateOption.companyCountry')) {
      return set(this, 'selectedProjectionTemplateOption.companyCountry', value);
    } else {
      return set(this, '_country', value);
    }
  }

  @computed(
    'newSeason.{seasonName,startDate,endDate,customOccupancy}',
    'radioBtnGroupValue'
  )
  get addSeasonDisabled() {
    return !(
      this.newSeason.seasonName &&
      this.newSeason.startDate &&
      this.newSeason.endDate &&
      (this.radioBtnGroupValue !== 'customOccupancy' || this.newSeason.customOccupancy)
    );
  }

  @computed('newExpense.{expenseName,value}')
  get addExpenseDisabled() {
    return !(this.newExpense.expenseName && this.newExpense.value);
  }

  @computed(
    'seasons.@each.{customOccupancy,marketOccupancy,revenueEstimate}',
    'ownerResource.basePrice',
    'propertyManagementFee',
    'includePropertyManagementFee',
    'model.stats'
  )
  get revenueProjections() {
    return get(this, 'seasons')
      .slice()
      .map(rp => {
        // days in period
        const daysInPeriod = moment(rp.endDate).diff(moment(rp.startDate), 'days');
        set(rp, 'daysInPeriod', daysInPeriod);

        // revenue estimate
        const adr = get(rp, 'marketAdr');

        const occupancy = get(rp, 'customOccupancy')
          ? get(rp, 'customOccupancy')
          : get(rp, 'marketOccupancy');
        const revenueEstimate = adr * daysInPeriod * (occupancy / 100);
        set(rp, 'revenueEstimate', Math.round(revenueEstimate));

        // net to owner
        let netToOwner;
        if (!this.propertyManagementFee || !this.includePropertyManagementFee) {
          netToOwner = revenueEstimate;
        } else {
          netToOwner = revenueEstimate * (1 - this.propertyManagementFee / 100);
        }
        set(rp, 'netToOwner', Math.round(netToOwner));

        let formatStartDate = moment(rp.startDate).format('MMM D');
        set(rp, 'formatStartDate', formatStartDate);

        let formatEndDate = moment(rp.endDate).format('MMM D');
        set(rp, 'formatEndDate', formatEndDate);

        return rp;
      });
  }

  @computed('revenueProjections.length', 'checkedSubtract', 'expenseTotal')
  get revenueProjectionsTotal() {
    const revenueProjectionsTotals = get(this, 'revenueProjections').reduce(
      (accum, rp) => {
        accum.set(
          'daysInPeriodTotal',
          accum.get('daysInPeriodTotal') + get(rp, 'daysInPeriod')
        );

        const occupancy = get(rp, 'customOccupancy')
          ? get(rp, 'customOccupancy')
          : get(rp, 'marketOccupancy');
        accum.set('occupancyTotal', accum.get('occupancyTotal') + occupancy);

        accum.set(
          'revenueEstimateTotal',
          accum.get('revenueEstimateTotal') + get(rp, 'revenueEstimate')
        );

        accum.set(
          'netToOwnerTotal',
          accum.get('netToOwnerTotal') + get(rp, 'netToOwner')
        );

        return accum;
      },
      EmberObject.create({
        occupancyTotal: 0,
        daysInPeriodTotal: 0,
        revenueEstimateTotal: 0,
        netToOwnerTotal: 0,
      })
    );

    revenueProjectionsTotals['occupancyAvg'] = Math.round(
      revenueProjectionsTotals.occupancyTotal / get(this, 'revenueProjections').length
    );

    if (this.checkedSubtract) {
      revenueProjectionsTotals.netToOwnerTotal =
        revenueProjectionsTotals.netToOwnerTotal - this.expenseTotal;
    }

    return revenueProjectionsTotals;
  }

  @computed('revenueProjectionsTotal.revenueEstimateTotal', 'propertyManagementFee')
  get propertyManagementFeeResult() {
    let propertyManagementFeeResult;

    if (!this.propertyManagementFee || 0) {
      propertyManagementFeeResult = 0;
    } else {
      propertyManagementFeeResult =
        get(this, 'revenueProjectionsTotal.revenueEstimateTotal') *
        (this.propertyManagementFee / 100);
    }

    return Math.round(propertyManagementFeeResult);
  }

  @computed('expenses.length')
  get expenseTotal() {
    return get(this, 'expenses').reduce((accum, expense) => {
      return get(expense, 'value') + accum;
    }, 0);
  }

  @computed(
    'projectionTemplateName',
    'seasons.length',
    'expenses.length',
    'website',
    'phone',
    'email',
    'address',
    'city',
    'state',
    'zipcode',
    'country'
  )
  get canSaveTemplate() {
    const hasAnyTemplateData =
      this.projectionTemplateName ||
      this.seasons.length ||
      this.expenses.length ||
      this.website ||
      this.phone ||
      this.email ||
      this.address ||
      this.city ||
      this.state ||
      this.zipcode ||
      this.country;
    if (hasAnyTemplateData) {
      return true;
    }
    return false;
  }

  @action
  checkedSubtract(e) {
    set(this, 'checkedSubtract', e.target.checked);
  }

  @action
  checkedPropertyManagementFee(e) {
    set(this, 'includePropertyManagementFee', e.target.checked);
  }

  @action
  dateSeasonSelected(newSeason, dates) {
    let start = dates.start;
    let end = dates.end;

    // Since we're simulating an anual circular calendar, if the user selects two dates
    // that cross our min/max ranges we need to adjust the dates so that the selections
    // behave as expected.
    //
    // Example:
    // If the user selects a date in Dec '21, moves to Jan '20 by clicking the "next" arrow
    // and selects a date, he means to select the range Dec to Jan, not two whole years.
    const yearDiff = start.diff(end, 'year');
    if (!isNaN(yearDiff) && yearDiff !== 0) {
      const oldStart = start.clone();
      start = end.clone().set('year', moment().year());
      end = oldStart.set('year', start.clone().add(1, 'year').year());
      set(this, 'centerDate', end);
    }

    if (dates.start) newSeason.set('startDate', start.toDate());
    newSeason.set('endDate', end?.toDate());
  }

  @action
  clickedClearSeason() {
    this._resetNewSeason();
  }

  @action
  async getDailyOccupancyAvg() {
    this.ownerResource.loading = true;

    const today = moment.utc().format('YYYY-MM-DD');
    const currency = this.ownerResource.selectedCurrency || 'USD';
    const clusterId = this.ownerResource.selectedCluster;

    let occupancy_res;
    try {
      occupancy_res = await this.ajax._get(
        `/api/properties_projections/daily_occupancy?date=${today}&currency=${currency}&cluster_id=${clusterId}`
      );
    } catch (errors) {
      for (let e of errors) {
        this.alert.error(e.detail, { timeout: 10000 });
      }
      return;
    }

    set(this, 'dailyOccupancyAvg', occupancy_res.dailyOccupancyAvg);
  }

  @action
  async getDailyAdr() {
    this.ownerResource.loading = true;

    const today = moment.utc().format('YYYY-MM-DD');
    const currency = this.ownerResource.selectedCurrency || 'USD';
    const clusterId = this.ownerResource.selectedCluster;
    let referenceListingId = this.ownerResource.selectedListing?.id;
    const basePrice =
      this.ownerResource.listingBasePrice || this.ownerResource.manualBasePrice;

    // manual input should always overwrite referenceListing selection
    if (this.ownerResource.manualBasePrice) {
      referenceListingId = null;
    }

    let adr_res;
    try {
      if (referenceListingId) {
        adr_res = await this.ajax._get(
          `/api/properties_projections/daily_adr?date=${today}&currency=${currency}&cluster_id=${clusterId}&reference_listing_id=${referenceListingId}&base_price=${basePrice}`
        );
      } else {
        adr_res = await this.ajax._get(
          `/api/properties_projections/daily_adr?date=${today}&currency=${currency}&cluster_id=${clusterId}&base_price=${basePrice}`
        );
      }
    } catch (errors) {
      for (let e of errors) {
        this.alert.error(e.detail, { timeout: 10000 });
      }
      return;
    }

    set(this, 'dailyAdr', adr_res.dailyAdr);
  }

  getTimeValuesAdjusted(seasonStart, seasonEnd) {
    const dateStart = moment(seasonStart);
    const dateEnd = moment(seasonEnd);
    const interim = dateStart.clone();
    const timeValues = [];

    while (
      dateEnd > interim ||
      interim.format('YYYY-MM-DD') === dateEnd.format('YYYY-MM-DD')
    ) {
      timeValues.push(interim.clone());
      interim.add(1, 'day');
    }

    return timeValues.map(tv => {
      // if target is greater than today, decrease year by 1
      if (moment() < tv && moment().format('YYYY') !== tv.format('YYYY')) {
        tv.subtract(1, 'year');
      }

      // if target is this year but greater than today, decrease year by 1
      if (moment() < tv && moment().format('YYYY') === tv.format('YYYY')) {
        tv.subtract(1, 'year');
      }

      // change format to YYYY-MM-DD
      return tv.format('YYYY-MM-DD');
    });
  }

  @action
  watchChanges() {
    fieldsToWatch.forEach(field => {
      // eslint-disable-next-line ember/no-observers
      addObserver(this, field, this.enableSave);
    });
  }

  @action
  stopWatchChanges() {
    fieldsToWatch.forEach(field => {
      removeObserver(this, field, this.enableSave);
    });
  }

  async enableSave() {
    if (this.ownerResourceController.disableSaveProjection) {
      this.ownerResourceController.disableSaveProjection = false;
    }
  }

  // eslint-disable-next-line ember/no-observers
  @observes('ownerResource.selectedListing', 'selectedProjectionTemplateOption')
  async updateSeasons() {
    if (
      !this.seasons ||
      !(this.ownerResource.selectedListing || this.ownerResource.manualBasePrice)
    ) {
      return;
    }

    // get daily adr
    if (!this.dailyAdr) {
      await this.getDailyAdr();
    }
    this.ownerResource.loading = false;

    for (const season of this.seasons) {
      // get array of month range of selected start-end date
      const timeValuesAdjusted = this.getTimeValuesAdjusted(
        season.startDate,
        season.endDate
      );

      // adr
      const dailyAdrWithinSelectedRange = timeValuesAdjusted
        .filter(tv => {
          return {}.hasOwnProperty.call(this.dailyAdr, tv);
        })
        .map(tv => this.dailyAdr[tv].adr);

      const sumAdr = dailyAdrWithinSelectedRange.reduce((a, b) => a + b, 0);
      const marketAdr = sumAdr / dailyAdrWithinSelectedRange.length;

      set(season, 'marketAdr', Math.round(marketAdr));
    }
  }

  @action
  async clickedAddSeason() {
    // get daily occupany avg
    const getDailyOccupancyAvg =
      !this.dailyOccupancyAvg && get(this, 'radioBtnGroupValue') !== 'customOccupancy';
    if (getDailyOccupancyAvg) {
      await this.getDailyOccupancyAvg();
    }

    // get daily adr
    if (!this.dailyAdr) {
      await this.getDailyAdr();
    }

    this.ownerResource.loading = false;

    // get array of month range of selected start-end date
    const timeValuesAdjusted = this.getTimeValuesAdjusted(
      this.newSeason.startDate,
      this.newSeason.endDate
    );

    // exit if user selected range longer than 1 year
    if (timeValuesAdjusted.length > 365) {
      this.alert.error('validation.dashboardErrors.monthRange', {
        timeout: 10000,
      });
      return;
    }

    // occupancy
    if (get(this, 'radioBtnGroupValue') === 'customOccupancy') {
      // custom occupancy case
      this.newSeason.set('customOccupancy', Math.round(this.newSeason.customOccupancy));
    } else {
      const dailyOccupancyAvgWithinSelectedRange = timeValuesAdjusted
        .filter(tv => {
          return {}.hasOwnProperty.call(this.dailyOccupancyAvg, tv);
        })
        .map(tv => this.dailyOccupancyAvg[tv].occupancyAvg);

      const sumOccupancy = dailyOccupancyAvgWithinSelectedRange.reduce(
        (a, b) => a + b,
        0
      );

      const marketOccupancy =
        sumOccupancy / dailyOccupancyAvgWithinSelectedRange.length;

      this.newSeason.set('marketOccupancy', Math.round(marketOccupancy));
    }

    // adr
    const dailyAdrWithinSelectedRange = timeValuesAdjusted
      .filter(tv => {
        return {}.hasOwnProperty.call(this.dailyAdr, tv);
      })
      .map(tv => this.dailyAdr[tv].adr);

    const sumAdr = dailyAdrWithinSelectedRange.reduce((a, b) => a + b, 0);
    const marketAdr = sumAdr / dailyAdrWithinSelectedRange.length;

    this.newSeason.set('marketAdr', Math.round(marketAdr));

    this.seasons.pushObject(get(this, 'newSeason'));
    this._resetNewSeason();
  }

  @action
  clickedClearExpense() {
    this._resetNewExpense();
  }

  @action
  clickedAddExpense() {
    set(this, 'newExpense.value', Math.round(get(this, 'newExpense.value')));
    this.expenses.pushObject(get(this, 'newExpense'));
    this._resetNewExpense();
  }

  @action
  removeProjection(index) {
    get(this, 'seasons').removeAt(index);
  }

  @action
  removeExpense(index) {
    get(this, 'expenses').removeAt(index);
  }

  @action
  changedOccupancy(index, e) {
    index;
    e;
  }

  @action
  stopPropagation(e) {
    e.stopPropagation();
  }

  @action
  async deleteProjectionTemplate(projectionTemplate) {
    set(this, 'displayDeleteTemplateModal', false);

    const id = projectionTemplate.get('id');

    const data = {
      id: id,
    };

    try {
      await this.ajax._delete('/api/projections_templates', data);
    } catch (errors) {
      this.alert.error('validation.genericWithTryAgain', { timeout: 10000 });
      return;
    }

    const projectionTemplates = get(this, 'model.projectionTemplates');

    const templateToRemove = projectionTemplates.findBy('id', id);
    projectionTemplates.removeObject(templateToRemove);

    this.alert.success('Projection template deleted successfully.');
  }

  @action
  displayDeleteModal(deletingTemplateProject) {
    set(this, 'deletingTemplateProject', deletingTemplateProject);
    set(this, 'displayDeleteTemplateModal', true);
  }

  @action
  clearProjectionTemplate() {
    set(this, 'selectedProjectionTemplateOption', null);
    set(this, 'projectionTemplateName', null);
    set(this, 'seasons', []);
    set(this, 'expenses', []);
    set(this, 'propertyManagementFee', null);
    set(this, 'notes', null);
    // company info
    set(this, 'website', null);
    set(this, 'phone', null);
    set(this, 'email', null);
    set(this, 'address', null);
    set(this, 'city', null);
    set(this, 'state', null);
    set(this, 'zipcode', null);
    set(this, 'country', null);
  }

  @action
  changeSelectedOption(option) {
    this.send('clearProjectionTemplate');
    if (option.label === 'customOwnerProjection') return;
    set(this, 'selectedProjectionTemplateOption', option);
    this.ownerResource.selectedCurrency = option.currency || 'USD';
  }

  @action
  clickedEditListingDetails() {
    this.editingListingDetails = !this.editingListingDetails;
  }

  @action
  clickedCancelListingDetails() {
    this.editingListingDetails = false;
    this._selectedAddress = null;
    this._selectedBedroomSize = null;
  }

  @action
  clickedSaveListingDetails() {
    this.editingListingDetails = false;
    if (this._selectedAddress) {
      this.ownerResource.selectedAddress = this._selectedAddress;
    }
    if (this._selectedBedroomSize) {
      this.ownerResource.selectedBedroomSize = this._selectedBedroomSize;
    }
    this._selectedAddress = null;
    this._selectedBedroomSize = null;
  }

  @action
  onSelectedAddressFieldChange(e) {
    const typedAddress = e.target.value;
    this._selectedAddress = typedAddress;
  }

  @action
  onSelectedBedroomFieldChange(e) {
    const typedAddress = e.target.value;
    this._selectedBedroomSize = typedAddress;
  }

  @action
  onChangeSelectKeyboard(dropdown, e) {
    if (e.keyCode === 13) {
      this.selectedAddress = dropdown.searchText;
    }
  }

  @action
  onCloseListingDetailsAddressSelect(context) {
    const option = context.selected;
    const streetNumber = option['address'];
    const streetName = option['text'];

    let address = `${streetNumber} ${streetName}`;
    let city;
    let state;
    let zipcode;
    let country;

    for (let c of option.selected.context) {
      if (c.id.includes('place')) city = c.id.text;
      if (c.id.includes('region')) state = c.id.text;
      if (c.id.includes('postcode')) zipcode = c.id.text;
      if (c.id.includes('country')) country = c.id.text;
    }

    this.ownerResource.searchedAddressGeocode.address = address;
    this.ownerResource.searchedAddressGeocode.city = city;
    this.ownerResource.searchedAddressGeocode.state = state;
    this.ownerResource.searchedAddressGeocode.zipcode = zipcode;
    this.ownerResource.searchedAddressGeocode.country = country;
  }

  @action
  onChangeInput(address) {
    if (!address.length) {
      return;
    }
    debounce(this, this.getTypeaheadSearchResult, address, 500);
  }

  _resetNewSeason() {
    set(this, 'centerDate', moment().startOf('year').toDate());

    set(
      this,
      'newSeason',
      EmberObject.create({
        seasonName: null,
        startDate: null,
        endDate: null,
        marketOccupancy: null,
        customOccupancy: null,
      })
    );
  }

  _resetNewExpense() {
    set(
      this,
      'newExpense',
      EmberObject.create({
        expenseName: null,
        value: null,
      })
    );
  }
}
