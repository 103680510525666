import BaseModel from 'appkit/bp-models/base_model';
import EmberObject, { computed } from '@ember/object';
import { RangeValidator } from 'appkit/lib/value_range';
import { CurrencyUtil } from 'appkit/lib/currency';
import moment from 'moment';

export default BaseModel.extend({
  init() {
    this._super(arguments);
    this._EDITABLE = [
      'minStay',
      'maxStay',
      'orphanMinStay',
      'orphanMinStayBuffer',
      'minPrice',
      'dayOfWeekMinPrices',
      'dayOfWeekMinStays',
      'maxPrice',
      'checkinDays',
      'checkoutDays',
      'checkinCheckoutOptions',
      'checkinCheckoutChannelTerminology',
      'showSeasonalCheckinAndCheckout',
      'extraGuestFee',
      'extraGuestThreshold',
      'minStayRanges',
      'maxStayRanges',
      'minPriceRanges',
      'lastMinuteMinStays',
      'minMaxPriceRanges',
      'checkinCheckoutDaysRanges',
      'isCheckoutEnabled',
      'discounts',
      'orphanDays',
      'isMinStayEnabled',
      'priceOverridePercentageRanges',
      'priceOverrideRanges',
      'dynamicPercentageUserOverrideRanges',
      'isMaxStayEnabled',
      'gapFillingEnabled',
      'isOrphanChangeoverDaysEnabled',
      'basePrice',
      'basePricePercentage',
      'minPricePercentage',
      'maxPricePercentage',
      'importCalendarUrls',
      'exportCalendarLink',
      'exportCalendarToken',
      'enabled',
      'onProgram',
      'reasonsSyncingTurnedOff',
      'monthlyEnabled',
      'monthlyMinPrice',
      'monthlyMinPricePercentage',
      'monthlyMinPriceRanges',
      'monthlyMinMaxPriceRanges',
      'monthlyPriceOverridePercentageRanges',
      'monthlySeasonsRanges',
      'currencies',
    ];

    this.set('lastMinuteMinStays', this.lastMinuteMinStays ?? []);
    this.set('discounts', this.discounts ?? []);
    this.set('orphanDays', this.orphanDays ?? []);

    this.set('importCalendarUrls', this.importCalendarUrls ?? []);

    // This won't ever come back from the server - it's client side only
    this.set('priceOverridePercentageRanges', this.priceOverridePercentageRanges ?? []);
    this.set('priceOverrideRanges', this.priceOverrideRanges ?? []);

    this.set(
      'monthlyPriceOverridePercentageRanges',
      this.monthlyPriceOverridePercentageRanges ?? []
    );
  },

  _maxPrice: null,
  maxPrice: computed('_maxPrice', {
    get() {
      return Number(this._maxPrice) || null;
    },
    set(key, value) {
      this.set('validationErrors.maxPrice', null);
      // Because min price validation errors are only from min > max, we can
      // clear them when the user resets the max price.
      if (value === null) {
        this.set('validationErrors.minPrice', null);
      }
      this.set('_maxPrice', Number(value) || null);
      return this._maxPrice;
    },
  }),

  _minPrice: null,
  minPrice: computed('_minPrice', {
    get() {
      return Number(this._minPrice) || null;
    },
    set(key, value) {
      this.set('_minPrice', value !== null ? Number(value) : null);
      return this._minPrice;
    },
  }),

  _monthlyMinPrice: null,
  monthlyMinPrice: computed('_monthlyMinPrice', {
    get() {
      return Number(this._monthlyMinPrice) || null;
    },
    set(key, value) {
      this.set('_monthlyMinPrice', value !== null ? Number(value) : null);
      return this._monthlyMinPrice;
    },
  }),

  _basePrice: null,
  basePrice: computed('_basePrice', {
    get() {
      return Number(this._basePrice) || null;
    },
    set(key, value) {
      this.set('_basePrice', value !== null ? Number(value) : null);
      return this._basePrice;
    },
  }),

  _basePricePercentage: null,
  basePricePercentage: computed('_basePricePercentage', {
    get() {
      return Number(this._basePricePercentage) || null;
    },
    set(key, value) {
      this.set('_basePricePercentage', value !== null ? Number(value) : null);
      return this._basePricePercentage;
    },
  }),

  _minPricePercentage: null,
  minPricePercentage: computed('_minPricePercentage', {
    get() {
      return Number(this._minPricePercentage) || null;
    },
    set(key, value) {
      this.set('_minPricePercentage', Number(value) || null);
      return this._minPricePercentage;
    },
  }),

  _maxPricePercentage: null,
  maxPricePercentage: computed('_maxPricePercentage', {
    get() {
      return Number(this._maxPricePercentage) || null;
    },
    set(key, value) {
      this.set('_maxPricePercentage', Number(value) || null);
      return this._maxPricePercentage;
    },
  }),

  _minStay: null,
  minStay: computed('_minStay', {
    get() {
      return Number(this._minStay) || null;
    },
    set(key, value) {
      this.set('validationErrors.minStay', null);
      if (value === null) {
        this.set('validationErrors.maxStay', null);
      }
      this.set('_minStay', Number(value) || null);
      return this._minStay;
    },
  }),

  _maxStay: null,
  maxStay: computed('_maxStay', {
    get() {
      return Number(this._maxStay) || null;
    },
    set(key, value) {
      this.set('validationErrors.maxStay', null);
      if (value === null) {
        this.set('validationErrors.minStay', null);
      }
      this.set('_maxStay', Number(value) || null);
      return this._maxStay;
    },
  }),
  _orphanMinStay: undefined,
  orphanMinStay: computed('_orphanMinStay', {
    get() {
      return this._orphanMinStay === null
        ? null
        : Number(this._orphanMinStay) || undefined;
    },
    set(key, value) {
      let valueToPost = value !== undefined || '' ? Number(value) : undefined;

      this.set('_orphanMinStay', value === null ? null : valueToPost);
      return this._orphanMinStay;
    },
  }),

  _extraGuestFee: null,
  extraGuestFee: computed('_extraGuestFee', {
    get() {
      return Number(this._extraGuestFee) || null;
    },
    set(key, value) {
      this.set('_extraGuestFee', Number(value) || null);
      return this._extraGuestFee;
    },
  }),

  _extraGuestThreshold: null,
  extraGuestThreshold: computed('_extraGuestThreshold', {
    get() {
      return Number(this._extraGuestThreshold) || null;
    },
    set(key, value) {
      this.set('_extraGuestThreshold', Number(value) || null);
      return this._extraGuestThreshold;
    },
  }),

  _monthlyMinPricePercentage: null,
  monthlyMinPricePercentage: computed('_monthlyMinPricePercentage', {
    get() {
      return Number(this._monthlyMinPricePercentage) || null;
    },
    set(key, value) {
      this.set('_monthlyMinPricePercentage', Number(value) || null);
      return this._monthlyMinPricePercentage;
    },
  }),

  validateInput() {
    const minPriceInput = Number(this.minPrice) || null;
    const maxPriceInput = Number(this.maxPrice) || null;
    const basePriceInput = Number(this.basePrice) || null;
    const minMonthlyPriceInput = Number(this.monthlyMinPrice) || null;
    const minStayInput = Number(this.minStay) || null;
    const maxStayInput = Number(this.maxStay) || null;
    // const orphanMinStayInput = Number(this.orphanMinStay) || null;
    const currency = this.currency;
    const currencies = this.currencies;

    let valid = true;
    this.set('validationErrors', EmberObject.create());

    const extraGuestFee = Number(this.extraGuestFee) || null;
    const extraGuestThreshold = Number(this.extraGuestThreshold) || null;

    const orphanMinStay = Number(this.orphanMinStay) || null;

    if (this.gapFillingEnabled && orphanMinStay === 0) {
      this.set(
        'validationErrors.customizeErrors',
        this.intl.t('validation.pricingErrors.orphanMinStayPositiveNumber')
      );
      valid = false;
    }

    if (
      (extraGuestFee && !extraGuestThreshold) ||
      (extraGuestThreshold && !extraGuestFee)
    ) {
      this.set(
        'validationErrors.extraGuestFees',
        this.intl.t('validation.customizeErrors.incompleteExtraGuestFee')
      );
      valid = false;
    }

    if (minPriceInput && maxPriceInput && minPriceInput > maxPriceInput) {
      this.set(
        'validationErrors.maxPrice',
        this.intl.t('validation.customizeErrors.maxPriceLessThanMinPrice')
      );
      valid = false;
    }

    if (minPriceInput && maxPriceInput && maxPriceInput < 10 && maxPriceInput > 1) {
      this.set(
        'validationErrors.maxPrice',
        this.intl.t('validation.pricingErrors.minMaxPrice', {
          value: currency
            ? CurrencyUtil.format(10, {
                currency,
              })
            : currencies
                .map(currency =>
                  CurrencyUtil.format(10, {
                    currency,
                  })
                )
                .join(', '),
        })
      );
      valid = false;
    }

    if (basePriceInput && basePriceInput < 10) {
      this.set(
        'validationErrors.basePrice',
        this.intl.t('validation.pricingErrors.minBasePrice', {
          value: currency
            ? CurrencyUtil.format(10, {
                currency,
              })
            : currencies
                .map(currency =>
                  CurrencyUtil.format(10, {
                    currency,
                  })
                )
                .join(', '),
        })
      );
      valid = false;
    }

    if ((minPriceInput && minPriceInput < 5) || (!minPriceInput && !currencies)) {
      this.set(
        'validationErrors.minPrice',
        this.intl.t('validation.pricingErrors.minMinPrice', {
          value: currency
            ? CurrencyUtil.format(5, {
                currency,
              })
            : currencies
                .map(currency =>
                  CurrencyUtil.format(5, {
                    currency,
                  })
                )
                .join(', '),
        })
      );
      valid = false;
    }

    if (minMonthlyPriceInput && minMonthlyPriceInput < 5) {
      this.set(
        'validationErrors.monthlyMinPrice',
        this.intl.t('validation.pricingErrors.minMonthlyMinPrice', {
          value: CurrencyUtil.format(5, {
            currency,
          }),
        })
      );
      valid = false;
    }

    if (minStayInput && maxStayInput && minStayInput > maxStayInput) {
      this.set(
        'validationErrors.maxStay',
        this.intl.t('validation.rangeErrors.maximumStaysMustBeLargerThanMinimumStays')
      );
      valid = false;
    }

    // Disable this for now as it doesn't take into account other factors like season min stays
    // if (minStayInput && orphanMinStayInput && minStayInput <= orphanMinStayInput) {
    //   this.set(
    //     'validationErrors.minStay',
    //     'Minimum Stay Gap must be lower than Minimum Stay'
    //   );
    //   valid = false;
    // }

    return valid;
  },

  buildCleanRanges() {
    const cleanRanges = {
      minStayRanges: null,
      maxStayRanges: null,
      minPriceRanges: null,
      maxPriceRanges: null,
      minMaxPriceRanges: null,
      checkinCheckoutDaysRanges: null,
      priceOverridePercentageRanges: null,
      priceOverrideRanges: null,
      monthlyMinPriceRanges: null,
      monthlyMinMaxPriceRanges: null,
      monthlyPriceOverridePercentageRanges: null,
      monthlySeasonsRanges: null,
    };

    Object.keys(cleanRanges).forEach(rangeName => {
      const ranges = this.get(rangeName);
      cleanRanges[rangeName] = RangeValidator.removeEmpty(ranges);
    });

    return cleanRanges;
  },

  validationErrors: EmberObject.create(),

  validateForm(intl) {
    let minPrice = Number(this.minPrice) || null;
    let maxPrice = Number(this.maxPrice) || null;
    let minStay = Number(this.minStay) || null;
    let maxStay = Number(this.maxStay) || null;

    const cleanRanges = this.buildCleanRanges();
    let formIsValid = true;

    let payloadToSend = this.currency ?? this.currencies;

    // Validate each range; pass the propName of the range to allow creating of customized error for that range
    let singleRangeIsValid = RangeValidator.validateSingleRange(
      cleanRanges,
      minStay,
      maxStay,
      this.checkinCheckoutOptions,
      intl,
      payloadToSend
    );

    // Validate group of ranges belong to the same category
    formIsValid =
      singleRangeIsValid &&
      Object.values(cleanRanges)
        .map(ranges => RangeValidator.validateRanges(ranges, intl))
        .reduce((acc, val) => acc && val, formIsValid);

    // Validate default minPrice and maxPrice, extraGuestFee, extraGuestThreshold
    formIsValid = formIsValid && this.validateInput() && this._validateImportUrls();

    // Validate minPrice ranges or minMaxPrice ranges with minPrice and maxPrice
    formIsValid = Object.values(cleanRanges).reduce((acc, ranges) => {
      return (
        acc &&
        ranges.reduce((acc, range) => {
          return (
            acc &&
            range.validate(
              { listingMinPrice: minPrice, listingMaxPrice: maxPrice },
              intl
            )
          );
        }, true)
      );
    }, formIsValid);
    return formIsValid;
  },

  _validateImportUrls() {
    this.set('validationErrors.importCalendarUrls', []);

    let seen = [];
    let hasError = 0;

    this.importCalendarUrls.forEach(url => {
      this._validateUrl(url, hasError, seen);
    });

    return !hasError;
  },

  _validateUrl(url, hasError, seen) {
    let errors = [];
    if (!url?.trim()) {
      errors.push('Field is empty');
    }

    try {
      new URL(url);
    } catch (err) {
      errors.push('URL is invalid');
    }

    if (seen.indexOf(url) >= 0) {
      errors.push('URL is repeated');
    }
    seen.push(url);
    hasError += errors.length;

    this.get('validationErrors.importCalendarUrls').push(errors);
  },

  serializeData() {
    // No casting shuold be done in this function. Following properties should be converted to getter/setters:
    // orphan_min_stay, orphan_min_stay_buffer, checkin_days, checkout_days.
    const cleanRanges = this.buildCleanRanges();

    return {
      extraGuestFee: this.extraGuestFee,
      extraGuestThreshold: this.extraGuestThreshold,
      minPrice: this.minPrice,
      monthlyMinPrice: this.monthlyMinPrice,
      basePrice: this.basePrice,
      minPricePercentage: this.minPricePercentage,
      basePricePercentage: this.basePricePercentage,
      maxPricePercentage: this.maxPricePercentage,
      maxPrice: this.maxPrice,
      minStay: this.minStay,
      maxStay: this.maxStay,
      orphanMinStay: this.orphanMinStay,
      orphanMinStayBuffer:
        this.orphanMinStayBuffer && parseInt(this.orphanMinStayBuffer),
      isOrphanChangeoverDaysEnabled: this.isOrphanChangeoverDaysEnabled,
      checkinDays: this.checkinDays || null,
      checkoutDays: this.checkoutDays || null,
      minStayRanges: this.minStayRanges.map(r => ({
        ...r.serialize(),
        minStayLocked: r.minStayLocked,
      })),
      maxStayRanges: cleanRanges.maxStayRanges.map(range => range.serialize()),
      minPriceRanges: cleanRanges.minPriceRanges.map(range => range.serialize()),
      minMaxPriceRanges: cleanRanges.minMaxPriceRanges.map(range => range.serialize()),
      checkinCheckoutDaysRanges: cleanRanges.checkinCheckoutDaysRanges.map(range =>
        range.serialize()
      ),
      lastMinuteMinStays: this.lastMinuteMinStays
        .filter(lastMinuteMinStay => lastMinuteMinStay.daysAway !== 0)
        .map(lastMinuteMinStay => {
          return {
            id: lastMinuteMinStay.id,
            daysAway: lastMinuteMinStay.daysAway,
            minDays: lastMinuteMinStay.minDays,
            direction: lastMinuteMinStay.direction,
          };
        }),
      discounts: this.discounts.filter(discount => discount.days !== 0),
      orphanDays: this.orphanDays.filter(orphanDay => orphanDay.days !== 0),
      priceOverridePercentageRanges: cleanRanges.priceOverridePercentageRanges
        .filter(r => !r.isFixedValue)
        .reduce((arr, it) => {
          if (it.priceOverridePercentage === 100) {
            return [
              ...arr,
              {
                startDate: moment(it.startDate).format('YYYY-MM-DD'),
                endDate: moment(it.endDate).format('YYYY-MM-DD'),
                priceOverridePercentage: 100,
                ...(it.dayOfWeek && { dayOfWeek: it.dayOfWeek }),
              },
            ];
          }
          if (!it.isDynamicOverride) {
            return [...arr, it.serialize()];
          }
          return arr;
        }, []),
      dynamicPercentageUserOverrideRanges: cleanRanges.priceOverridePercentageRanges
        .filter(r => !r.isFixedValue)
        .reduce((arr, it) => {
          if (it.priceOverridePercentage === 100) {
            return [
              ...arr,
              {
                startDate: moment(it.startDate).format('YYYY-MM-DD'),
                endDate: moment(it.endDate).format('YYYY-MM-DD'),
                dynamicPercentageUserOverride: 0,
                ...(it.dayOfWeek && { dayOfWeek: it.dayOfWeek }),
              },
            ];
          }
          if (it.isDynamicOverride) {
            const pct =
              it.priceOverridePercentage > 100
                ? (it.priceOverridePercentage - 100) / 100
                : ((100 - it.priceOverridePercentage) / 100) * -1;
            it.dynamicPercentageUserOverride = pct;

            return [...arr, it.serialize()];
          }
          return arr;
        }, []),
      priceOverrideRanges: cleanRanges.priceOverrideRanges.map(range =>
        range.serialize()
      ),
      gapFillingEnabled: this.gapFillingEnabled,
      importCalendarUrls: this.importCalendarUrls.filter(el => el),
      enabled: this.enabled,
      onProgram: this.onProgram,
      reasonsSyncingTurnedOff: this.reasonsSyncingTurnedOff,
      dayOfWeekMinPrices: this.dayOfWeekMinPrices,
      dayOfWeekMinStays: this.dayOfWeekMinStays,
      monthlyEnabled: this.monthlyEnabled,
      monthlyMinPricePercentage: this.monthlyMinPricePercentage,
      monthlyMinPriceRanges: cleanRanges.monthlyMinPriceRanges.map(range =>
        range.serialize()
      ),
      monthlyMinMaxPriceRanges: cleanRanges.monthlyMinMaxPriceRanges.map(range =>
        range.serialize()
      ),
      monthlyPriceOverridePercentageRanges: cleanRanges.monthlyPriceOverridePercentageRanges.map(
        range => range.serialize()
      ),
    };
  },

  serializeDirtyData() {
    let serializedData = this.serializeData();

    let monthlySeasonsRanges = this.get('monthlySeasonsRanges');
    monthlySeasonsRanges = RangeValidator.removeEmpty(monthlySeasonsRanges);
    monthlySeasonsRanges = monthlySeasonsRanges.map(range => range.serialize());

    serializedData = { ...serializedData, monthlySeasonsRanges: monthlySeasonsRanges };

    let data = {};
    for (let key of this._EDITABLE) {
      // Assume that no data means they don't want to delete
      // TODO: have a tri-state opt-in for each attribute
      const currentValue = this.get(key);
      // let null values pass (specific case of wanting a tri-state for gap fills)
      if (currentValue === undefined && key !== 'dynamicPercentageUserOverrideRanges') {
        continue;
      }
      if (
        Array.isArray(currentValue) &&
        currentValue.length === 0 &&
        key !== 'dynamicPercentageUserOverrideRanges'
      ) {
        continue;
      }

      if (
        this.get(`_dirty.${key}`) === this.get(key) &&
        key !== 'dynamicPercentageUserOverrideRanges'
      ) {
        continue;
      }

      if (serializedData[key] && serializedData[key].length === 0) {
        continue;
      }

      data[key] = serializedData[key];
    }

    return data;
  },
});
