import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { oneWay } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@classNames('app-channel-picker')
export default class AppChannelPicker extends Component {
  @oneWay('currentUser.isStaff')
  isStaff;

  @computed('isStaff')
  get channels() {
    const out = [
      {
        code: 'airbnb',
        image: 'belo',
      },
      {
        code: 'homeaway',
        image: 'vrbo-icon',
      },
    ];

    return out;
  }

  changeChannel = () => {};
}
