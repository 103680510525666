import EmberObject, { action, computed } from '@ember/object';

import { CHANNEL } from 'appkit/lib/channel_configuration';
import { ChannelUtil } from 'appkit/lib/channel';
import Component from '@ember/component';
import Credentials from 'appkit/bp-models/credentials';
import ENV from 'appkit/config/environment';
import { classNames } from '@ember-decorators/component';
import classic from 'ember-classic-decorator';
import { displayErrors } from 'appkit/lib/display_errors';
import moment from 'moment';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

@classic
@classNames('app-import')
export default class AppImport extends Component {
  layoutName = 'components/app-import';
  page = '';

  // Manual Airlock step data
  showManualAirlock = false;
  showExtensionInstallInstructions = false;
  airlockVersion = 1;
  airlockData = {
    step: 1,
    airlockId: null,
  };

  @service intl;
  @service featureFlag;
  @service alert;
  @service cookies;

  @tracked shouldUseAirbnbManualAirlock = false;

  @computed('showManualAirlock', 'airlockData.step')
  get showOpenManualAirlock() {
    return this.showManualAirlock && this.airlockData.step === 1;
  }

  @computed
  get BookingSyncOAuthRedirectUrl() {
    return ChannelUtil.getBookingSyncOAuthRedirectUrl();
  }

  @computed
  get MyIGMSOAuthRedirectUrl() {
    return ChannelUtil.getIGMSOAuthRedirectUrl();
  }

  @computed
  get MyVROAuthRedirectUrl() {
    return ChannelUtil.getMyVROAuthRedirectUrl();
  }

  @computed
  get OctorateOAuthRedirectUrl() {
    return ChannelUtil.getOctorateOAuthRedirectUrl();
  }

  @computed
  get TokeetOAuthRedirectUrl() {
    return ChannelUtil.getTokeetOAuthRedirectUrl();
  }

  @computed
  get OwnerRezOAuthRedirectUrl() {
    return ChannelUtil.getOwnerRezOAuthRedirectUrl();
  }

  @computed
  get RentalReadyOAuthRedirectUrl() {
    return ChannelUtil.getRentalReadyOAuthRedirectUrl();
  }

  @computed
  get CloudbedsOAuthRedirectUrl() {
    return ChannelUtil.getCloudbedsOAuthRedirectUrl();
  }

  @computed
  get HostfullyV3OAuthRedirectUrl() {
    return ChannelUtil.getHostfullyV3OAuthRedirectUrl();
  }

  credentials = null;
  posting = false;
  channel = '';
  extra = null;
  twoFactorPhone = '';
  twoFactorMethod = '';
  twoFactorCode = '';
  encodedAirlockId = '';

  twoFactorPhones = {
    /*
    call: [['id1', '+1 XXX 1234'], ['id2', 'XXX 2345']],
    sms: [['id1', '+1 XXX 4567'], ['id2', 'XXX 2345']]
    */
  };
  twoFactorMethodsPretty = {
    call: 'Automated phone call',
    sms: 'Text message (SMS)',
    email: 'Email',
  };

  // Should be an alias to credentials.id, but since the credentials are
  // created on init, we have to add a hack. There is maybe a better way to
  // handle assigning a ember object on init that I'm missing.
  id = null;

  subchannel = null;

  linkAccountFor(channel) {
    this.set('channel', channel);
    this.set('page', channel);

    if (
      [
        CHANNEL.HOMEAWAY,
        CHANNEL.BOOKINGSYNC,
        CHANNEL.MYVR,
        CHANNEL.OCTORATE,
        CHANNEL.OWNERREZ,
        CHANNEL.IGMS,
        CHANNEL.TOKEET,
        CHANNEL.RENTALREADY,
      ].includes(channel)
    ) {
      let code = this.get('extra.code');
      if (code) {
        this.set('credentials.id', code);
        this.send('submitCredentials');
      }
    }
  }

  homeAwayOauthCode(channel) {
    this.set('channel', channel);
    this.set('page', channel);
  }

  init() {
    super.init(...arguments);
    this.shouldUseAirbnbManualAirlock = this.cookies.get('useAirbnbManualAirlock')
      ? true
      : false;
    // Create the credentials object with the ID if it exists
    this.initSetup();
  }

  didUpdateAttrs() {
    this.initSetup();
  }

  focusInput() {
    if (this.id) {
      this.element.querySelector('input.password')?.focus();
    } else {
      this.element.querySelector('input.id')?.focus();
    }
  }

  // Data to submit to the server on the next ajax call
  @computed(
    'page',
    'credentials.{id,idSecondary,password,secondaryUsername,secondaryPassword,device_id,pulse_token}',
    'channel',
    'twoFactorPhone',
    'twoFactorMethod',
    'twoFactorCode'
  )
  get props() {
    switch (this.page) {
      // Use id for all the channels for the main information to connect the
      // account. Using the same parameter makes it easier on the backend
      // view.
      // Use the fall-through feature of the switch.
      case CHANNEL.FAKE:
      case CHANNEL.FAKE_PMS:
      case CHANNEL.AIRBNB:
      case CHANNEL.HOMEAWAY:
      case CHANNEL.HOMEAWAY_V12:
      case CHANNEL.STREAMLINE:
      case CHANNEL.CIIRUS:
      case CHANNEL.BOOKINGSYNC:
      case CHANNEL.FLIPKEY:
      case CHANNEL.BOOKING:
      case CHANNEL.VREASY:
      case CHANNEL.LIVEREZ:
      case CHANNEL.MYVR:
      case CHANNEL.TRAVELMOB:
      case CHANNEL.TRIPADVISOR:
      case CHANNEL.HOSTFULLY:
      case CHANNEL.SUPERCONTROL:
      case CHANNEL.SMOOBU:
      case CHANNEL.BAREFOOT_DIRECT:
      case CHANNEL.VRM:
      case CHANNEL.HOSTAWAY:
      case CHANNEL.KIGO:
      case CHANNEL.ZEEVOU:
      case CHANNEL.BRIGHTSIDE:
      case CHANNEL.DIRECT:
      case CHANNEL.JANIIS:
      case CHANNEL.OWNERREZ:
      case CHANNEL.LODGIX:
      case CHANNEL.BEST_BEACH:
      case CHANNEL.VRBO:
      case CHANNEL.HOSTIFY:
      case CHANNEL.FANTASTICSTAY:
      case CHANNEL.LIGHTMAKER:
      case CHANNEL.SECRA:
      case CHANNEL.RENTALS_UNITED:
      case CHANNEL.UPLISTING:
      case CHANNEL.ICNEA:
      case CHANNEL.AVANTIO:
      case CHANNEL.RENTALREADY:
      case CHANNEL.RMS:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
          idSecondary: this.get('credentials.idSecondary'),
          password: this.get('credentials.password'),
        };
      case CHANNEL.ELINA:
        return {
          channel: this.channel,
          username: this.get('credentials.username'),
          password: this.get('credentials.password'),
        };
      case CHANNEL.ESCAPIA:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
          idSecondary: this.get('credentials.idSecondary'),
          password: this.get('credentials.password'),
        };
      case CHANNEL.IGMS:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
          idSecondary: this.get('credentials.idSecondary'),
          password: this.get('credentials.password'),
        };
      case CHANNEL.GUESTY:
      case CHANNEL.BEDS24:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
          booking_id: this.get('credentials.secondaryUsername'),
          booking_secret: this.get('credentials.secondaryPassword'),
        };
      case CHANNEL.IPRO:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
          idSecondary: this.get('credentials.idSecondary'),
          password: this.get('credentials.password'),
        };
      case CHANNEL.LODGIFY:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
          idSecondary: this.get('credentials.idSecondary'),
          password: this.get('credentials.password'),
        };
      case 'twoFactorSend':
        return {
          channel: this.channel,
          phone: this.twoFactorPhone,
          method: this.twoFactorMethod,
        };
      case 'twoFactorVerify':
        return {
          channel: this.channel,
          code: this.twoFactorCode,
        };

      case CHANNEL.TRACK:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
          idSecondary: this.get('credentials.idSecondary'),
          password: this.get('credentials.password'),
          secondaryUsername: this.get('credentials.secondaryUsername'),
          secondaryPassword: this.get('credentials.secondaryPassword'),
        };

      case CHANNEL.BOOKING_CONNECT:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
          password: this.get('credentials.password'),
          pulse_token: this.get('credentials.pulse_token'),
          device_id: this.get('credentials.device_id'),
        };

      case CHANNEL.KROSS_BOOKING:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
          idSecondary: this.get('credentials.idSecondary'),
          password: this.get('credentials.password'),
        };
      case CHANNEL.HOMHERO:
      case CHANNEL.HOMHERO_STAGING:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
        };
      case CHANNEL.OCTORATE:
        return {
          channel: this.channel,
          code: this.get('credentials.id'),
          redirect_uri: ENV.APP.OCTORATE_OAUTH_REDIRECT_URI,
        };
      case CHANNEL.TOKEET:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
          redirect_uri: ENV.APP.TOKEET_OAUTH_REDIRECT_URI,
        };
      case CHANNEL.REAL_TIME_RENTAL:
        return {
          channel: this.channel,
          id: this.get('credentials.id'),
        };

      default:
        throw Error('Channel not supported.');
    }
  }

  @computed('page')
  get url() {
    switch (this.page) {
      case 'twoFactorSend':
        return '/api/accounts/two_factor_request';
      case 'twoFactorVerify':
        return '/api/accounts/two_factor_verify';
      default:
        return '/api/accounts';
    }
  }

  @action
  initSetup() {
    this.set(
      'credentials',
      Credentials.create({
        id: this.id,
        idSecondary: this.idSecondary,
      })
    );

    let channel = this.channel;

    if (channel) {
      this.linkAccountFor(channel);
    }

    scheduleOnce('afterRender', this, this.focusInput);
  }

  @action
  pickSubchannel(value) {
    let model = this.credentials;
    let subchannel = value.target.value;
    this.set('subchannel', subchannel);
    if (!this.get(subchannel)) {
      model.set('errors.base', null);
    }
  }

  @action
  setTwoFactorPhone(value) {
    this.set('twoFactorPhone', value);
  }

  @action
  setTwoFactorMethod(value) {
    this.set('twoFactorMethod', value);
  }

  @action
  resendTwoFactor() {
    this.set('page', 'twoFactorSend');
    this.set('credentials.errors.base', '');
  }

  @action
  async openAirlock() {
    window.top.open(
      `https://www.airbnb.com/airlock?al_id=${this.airlockData.airlockId}`
    );
    await new Promise(resolve => setTimeout(resolve, 3000));
    this.set('airlockData.step', 2);
  }

  @action
  async manualVerify2FAMethod() {
    let model = this.credentials;
    const isExtensionAvailable = !!top.window.__BEYOND_EXT__;

    if (!isExtensionAvailable) {
      this.alert.error('Extension was not detected.');
    }

    let response;
    try {
      response = await top.window.__BEYOND_EXT__.send2FACode(
        this.get('encodedAirlockId'),
        this.twoFactorMethod,
        this.twoFactorPhone,
        this.twoFactorCode,
        this.airlockVersion
      );
    } catch (errors) {
      displayErrors({
        errors: errors,
        modelOrKeywordThis: model,
        isProperty: true,
        intlService: this.intl,
      });
      return;
    } finally {
      this.set('posting', false);
    }

    const body = response.body;

    if (
      response.status === 200 &&
      ((body.data && !body.data.airlockPhoneVerificationViaTextVerifyCode?.errors) ||
        (body.airlock && body.airlock?.status == 2))
    ) {
      this.submitAirbnbExtCredentials();
    } else {
      const errorMessage =
        body.data?.airlockPhoneVerificationViaTextVerifyCode?.errors?.[0]
          ?.errorMessage || body.airlock?.user_message;
      model.set(
        'errors.base',
        errorMessage || this.intl.t('validation.genericWithContact')
      );
    }
  }

  @action
  async manualSelect2FAMethod() {
    let response;
    let model = this.credentials;
    const isExtensionAvailable = !!top.window.__BEYOND_EXT__;

    if (!isExtensionAvailable) {
      this.alert.error('Extension was not detected.');
    }

    try {
      response = await top.window.__BEYOND_EXT__.select2FA(
        this.get('encodedAirlockId'),
        this.get('twoFactorMethod'),
        this.twoFactorPhone,
        this.get('airlockVersion')
      );
    } catch (errors) {
      displayErrors({
        errors: errors,
        modelOrKeywordThis: model,
        isProperty: true,
        intlService: this.intl,
      });
      return;
    } finally {
      this.set('posting', false);
    }

    if (response.status === 200 && !response.body.errors) {
      this.set('page', 'twoFactorVerify');
    } else {
      model.set('errors.base', "Didn't receive the code? Try again.");
    }
    return;
  }

  @action
  async submitAirbnbExtCredentials() {
    let model = this.credentials;
    const isExtensionAvailable = !!top.window.__BEYOND_EXT__;

    if (!isExtensionAvailable) {
      this.alert.error('Extension was not detected.');
    }

    let response;

    try {
      this.set('showExtensionInstallInstructions', false);

      response = await top.window.__BEYOND_EXT__.login(
        this.get('credentials.id'),
        this.get('credentials.password'),
        { user: this.currentUser.username }
      );
    } catch (errors) {
      displayErrors({
        errors: errors,
        modelOrKeywordThis: model,
        isProperty: true,
        intlService: this.intl,
      });
      return;
    } finally {
      this.set('posting', false);
      // model.setProperties({ id: '', password: '' });
    }

    if (response.status === 200) {
      this.handleLoggedInResponse(response);
      return;
    }

    if (response.status === 420) {
      const clientErrorInfo = response.body.client_error_info;
      const airlockVersion = clientErrorInfo?.airlock ? 1 : 2;
      this.set('airlockVersion', airlockVersion);

      if (airlockVersion == 1) {
        const flow = clientErrorInfo?.airlock.flow;
        const airlockData = response.body.client_error_info.airlock;

        if (flow == 'captcha_flow') {
          this.set('airlockData.step', 1);
          this.set('showManualAirlock', true);
          this.set('submitValue', 'Authenticate via Airbnb');
          this.set('airlockData.airlockId', airlockData.airlockId);
        } else if (flow == 'account_ownership_verification_for_login') {
          const frictionData = airlockData.friction_data;
          this.set('encodedAirlockId', airlockData.id);

          const availableOptions = frictionData.filter(
            o => o.name.includes('phone') || o.name.includes('email')
          );
          const parsedOptions = availableOptions
            .map(o => ({
              type: o.name,
              numbers: o.name.includes('phone')
                ? o.data.phone_numbers.map(p => [p.id, p.obfuscated])
                : [[1, o.data.obfuscated_email_address]],
            }))
            .reduce((p, o) => ({ [o.type]: o.numbers, ...p }), {});

          const options = {
            sms: parsedOptions['phone_verification_via_text'],
            call: parsedOptions['phone_verification_via_call'],
            email: parsedOptions['email_code_verification'],
          };

          this.set2FAOptions(options);
        }
      } else {
        const frictions = clientErrorInfo?.airlockV2?.frictionVersions
          .map(fv => fv.friction)
          .filter(e => e != 'CONTACT_US_FORM');

        if (frictions.includes('ARKOSE_BOT_DETECTION')) {
          this.set('airlockData.step', 1);
          this.set('showManualAirlock', true);
          this.set('submitValue', 'Authenticate via Airbnb');
          this.set(
            'airlockData.airlockId',
            clientErrorInfo?.airlockV2?.internalAirlockId
          );
        } else {
          this.set('encodedAirlockId', clientErrorInfo?.airlockV2.id);

          try {
            response = await top.window.__BEYOND_EXT__.request2FA(
              this.get('encodedAirlockId')
            );
          } catch (errors) {
            displayErrors({
              errors: errors,
              modelOrKeywordThis: model,
              isProperty: true,
              intlService: this.intl,
            });
            return;
          } finally {
            this.set('posting', false);
            // model.setProperties({ id: '', password: '' });
          }

          if (response.type == 'two_factor_request') {
            this.set2FAOptions(response.numbers);
          }
        }
      }
    }

    if (response.body?.error_type === 'invalid_credentials') {
      // Reset only password
      model.setProperties({ password: '' });
      model.set('errors.base', response.body.error_message);
      return;
    }
  }

  set2FAOptions(options) {
    this.set('page', 'twoFactorSend');
    let numbers = EmberObject.create(options);
    this.set('twoFactorPhones', numbers);

    // Try and default to SMS unless CALL has more phone numbers
    let method = '';
    let smsOption = Object.keys(numbers).includes('sms');
    let callOption = Object.keys(numbers).includes('call');
    let emailOption = Object.keys(numbers).includes('email');

    if (smsOption && callOption) {
      if (numbers.sms.length >= numbers.call.length) {
        method = 'sms';
      } else {
        method = 'call';
      }
    } else if (smsOption && !callOption) {
      method = 'sms';
    } else if (!smsOption && callOption) {
      method = 'call';
    } else if (emailOption && !smsOption && !callOption) {
      method = 'email';
    } else {
      this.set('twoFactorMethod', false);
    }

    // Default to the first number. Response is like this:
    //   call: [['id1', 'XXX 1234'], ['id2', 'XXX 2345']],
    //   sms: [['id1', 'XXX 1234'], ['id2', 'XXX 2345']],
    this.set('twoFactorMethod', method);
    this.set('twoFactorPhone', numbers[method][0][0]);
    return;
  }

  async handleLoggedInResponse(data) {
    const { token, filledAccountData } = data.body;
    let response;

    try {
      response = await this.ajax._post('/api/accounts/airbnb', {
        channel: 'airbnb',
        email: this.get('credentials.id'),
        password: this.get('credentials.password'),
        authToken: token,
        deviceId: data.deviceId,
        userId: filledAccountData.userId,
      });
    } catch (errors) {
      displayErrors({
        errors: errors,
        modelOrKeywordThis: this.credentials,
        isProperty: true,
        intlService: this.intl,
      });
      return;
    }

    console.log('account created', response);
    this.success(response.id);
    return;
  }

  @action
  async submitCredentials() {
    let model = this.credentials;

    if (['airbnb', 'booking_connect'].includes(this.props.channel)) {
      this.set('posting', true);
    }

    let response;
    let defaultError = this.intl.t('validation.genericWithContact');

    try {
      response = await this.ajax._post(this.url, this.props);
    } catch (errors) {
      const parsedErrors = Array.isArray(errors)
        ? errors.map(e =>
            e.message?.toLowerCase().includes('code')
              ? { ...e, detail: 'connect.twoFactorInvalidCode' }
              : e
          )
        : errors;
      displayErrors({
        errors: parsedErrors,
        modelOrKeywordThis: model,
        isProperty: true,
        intlService: this.intl,
      });
      return;
    } finally {
      this.set('posting', false);
      // model.setProperties({ id: '', password: '' });
    }

    if (response.status === 'success') {
      this.success(response.id);
      return;
    }

    if (response.status === 'airlock') {
      this.cookies.set(
        'useAirbnbManualAirlock',
        'true',
        moment().add(1, 'days').toDate().toUTCString()
      );
      this.shouldUseAirbnbManualAirlock = true;
      this.set('showExtensionInstallInstructions', true);
      this.set('posting', false);
      return;
    }

    if (response.status === 'invalid_credentials') {
      // Reset only password
      model.setProperties({ password: '' });
      model.set('errors.base', response.message || defaultError);
      return;
    }

    if (response.status === 'two_factor_request') {
      this.set('page', 'twoFactorSend');
      let numbers = EmberObject.create(response.numbers);
      this.set('twoFactorPhones', numbers);

      // Try and default to SMS unless CALL has more phone numbers
      let method = '';
      let smsOption = Object.keys(numbers).includes('sms');
      let callOption = Object.keys(numbers).includes('call');
      let emailOption = Object.keys(numbers).includes('email');

      if (smsOption && callOption) {
        if (numbers.sms.length >= numbers.call.length) {
          method = 'sms';
        } else {
          method = 'call';
        }
      } else if (smsOption && !callOption) {
        method = 'sms';
      } else if (!smsOption && callOption) {
        method = 'call';
      } else if (emailOption && !smsOption && !callOption) {
        method = 'email';
      } else {
        this.set('twoFactorMethod', false);
      }

      // Default to the first number. Response is like this:
      //   call: [['id1', 'XXX 1234'], ['id2', 'XXX 2345']],
      //   sms: [['id1', 'XXX 1234'], ['id2', 'XXX 2345']],
      this.set('twoFactorMethod', method);
      this.set('twoFactorPhone', numbers[method][0][0]);
      return;
    }

    if (response.status === 'two_factor_verify') {
      this.set('page', 'twoFactorVerify');
      return;
    }

    model.set('errors.base', response.message || defaultError);
  }

  @action
  openUrl(url) {
    top.window.location.href = url;
  }
}
