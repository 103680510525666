import { helper } from '@ember/component/helper';

export default helper(function (values) {
  let base = values[0];
  let modeled = values[1];
  if (!(base && modeled)) {
    return '';
  }

  let change = (modeled - base) / base;

  if (change < -0.2) {
    return 'neg-large';
  }
  if (change < 0) {
    return 'neg-small';
  }
  if (change > 0.2) {
    return 'pos-large';
  }
  return 'pos-small';
});
