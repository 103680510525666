import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import classic from 'ember-classic-decorator';
import ENV from 'appkit/config/environment';
import { loadStripe } from '@stripe/stripe-js';

const fetchStripeSessionId = async ajax => {
  const result = await ajax._get('/api/cards/session/stripe');
  return result['sessionId'];
};

const showErrorAlert = (alertService, stripeMessage) => {
  const message = stripeMessage || 'validation.genericWithContact';
  alertService.error(message, {
    timeout: 6000,
  });
};

@classic
export default class StripeService extends Service {
  @service ajax;
  @service alert;

  stripeHandler = null;

  async initStripe() {
    return await loadStripe(ENV.APP.STRIPE_PUBLIC_KEY);
  }

  async getInstance() {
    if (!this.stripeHandler) {
      this.stripeHandler = await this.initStripe();
    }

    return this.stripeHandler;
  }

  @action
  async openCheckout() {
    const stripeSessionId = await fetchStripeSessionId(this.ajax);

    (await this.getInstance())
      .redirectToCheckout({
        sessionId: stripeSessionId,
      })
      .then(result => {
        const error = result.error;
        if (error) {
          showErrorAlert(this.alert, error.message);
        }
      });
  }
}
