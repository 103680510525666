import Controller, { inject as controller } from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SetupController extends Controller {
  @service alert;
  @service listingSetup;
  preserveScrollPosition = true;
  @service intl;
  @service forcePaymentMethod;
  @tracked shouldPreventRedirect = false;

  @controller('dashboard.pricing.index') pricingController;

  @action
  onClose() {
    if (this.shouldPreventRedirect) {
      this.shouldPreventRedirect = false;
      return;
    }
    this.listingSetup.reset();
    this.transitionToRoute('dashboard.pricing');
  }

  @action
  onChangeListingSetup(data, field) {
    let listingToEdit = this.model.listings.find(l => l.id === data.id);

    set(listingToEdit, field, Number(data[field]));
    listingToEdit.validateInput();
  }

  @action
  async onSave(data) {
    if (data.syncEnabled && this.pricingController.isBillingBannerVisible) {
      let blockingErrorMessage = [
        this.intl.t(this.pricingController.billingBannerMessage[0]),
        this.intl.t('pricing.updatePaymentDetailsSimple'),
        this.intl.t(this.pricingController.billingBannerMessage[1]),
      ].join(' ');

      this.alert.error(blockingErrorMessage, 10000);

      return;
    }

    if (this.forcePaymentMethod.userRequirements() && data.syncEnabled) {
      this.forcePaymentMethod.redirect();
      this.shouldPreventRedirect = true;
      return;
    }
    this.shouldPreventRedirect = false;

    await this.listingSetup.onSave(data);
    return this.model.listing.reload({ override: true });
  }
}
