import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class MarketRoute extends AuthRoute {
  model(params) {
    let markets = this.modelFor('data');
    for (let market of markets) {
      if (market.get('id') === Number(params.market_id)) {
        return market;
      }
    }
    throw new Error('no market found');
  }

  serialize(model) {
    return {
      market_id: model.get('id'),
    };
  }
}
