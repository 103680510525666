import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class BulkUpdateController extends Controller {
  @tracked user = null;
  @tracked userId = null;
  @tracked userDetails = 'No User Checked';
  @tracked uploadResponse = null;
  @tracked posted = false;
  @tracked errorMessage = '';
  @tracked results = [];
  @tracked isCompleteReviewChecked = false;
  @tracked fileContent = [];

  @action
  onChangeFile() {
    function getUserIdIndex(header) {
      return header.trim().split(',').indexOf('user_id');
    }
    let file = document.getElementsByName('fileUpload')[0].files[0];
    const fileReader = new FileReader();
    fileReader.onload = fileLoadedEvent => {
      const fileContents = fileLoadedEvent.target.result;
      const fileRows = fileContents.split('\n');
      const userIdIndex = getUserIdIndex(fileRows[0]);
      const lines = fileRows.length - 1;
      const users = [];
      fileRows.slice(1).forEach(row => {
        const user = row.split(',')[userIdIndex];
        if (!users.includes(user)) {
          users.push(user);
        }
      });
      this.fileContent = [
        `Total rows: ${lines.toString()}`,
        `Users: ${users.toString()}`,
      ];
    };
    if (file) {
      const newLocal = 'UTF-8';
      fileReader.readAsText(file, newLocal);
    }
  }

  @action
  onCompleteReviewChecked() {
    this.isCompleteReviewChecked
      ? (this.isCompleteReviewChecked = false)
      : (this.isCompleteReviewChecked = true);
  }

  @action
  async submitForm() {
    this.errorMessage = null;
    this.results = [];

    let file = document.getElementsByName('fileUpload')[0].files[0];
    let formData = new FormData();

    if (!this.staffUserId) {
      alert('Submitter User ID is required. Aborting.');
      return;
    }

    if (!file) {
      alert('No file. Aborting.');
      return;
    }

    formData.append('staff_user_id', this.staffUserId);
    formData.append('file', file);
    formData.append('complete_review', this.isCompleteReviewChecked);

    let result;

    this.posted = false;

    try {
      result = await this.ajax._postWithFiles(
        '/api/internal-tools/booking-reviews',
        formData
      );
    } catch (e) {
      this.errorMessage = 'Uh oh! Something went wrong! Error description: ';
      if (e[0].message) {
        this.errorMessage += e[0].message;
      } else {
        this.errorMessage += e[0].source.message;
      }
      return;
    } finally {
      this.posted = true;
    }
    for (const key in result) {
      this.results.push({
        userId: key,
        booking_reviews: result[key].bookingReviewListingIds
          ? result[key].bookingReviewListingIds.length
          : '',
        error: result[key].error,
      });
    }
  }
}
