import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-update this.updateSettings @importedSettings}}>\n  {{yield\n    (hash\n      settingsList=this.settingsList\n      totalItems=this.totalItems\n      selectedItems=this.selectedItems\n      ifAllListItemsSelected=this.ifAllListItemsSelected\n      ifSomeListItemsSelected=this.ifSomeListItemsSelected\n      actions=(hash onSelectAll=this.onSelectAll onSelectingItem=this.onSelectingItem)\n    )\n  }}\n</div>", {"contents":"<div {{did-update this.updateSettings @importedSettings}}>\n  {{yield\n    (hash\n      settingsList=this.settingsList\n      totalItems=this.totalItems\n      selectedItems=this.selectedItems\n      ifAllListItemsSelected=this.ifAllListItemsSelected\n      ifSomeListItemsSelected=this.ifSomeListItemsSelected\n      actions=(hash onSelectAll=this.onSelectAll onSelectingItem=this.onSelectingItem)\n    )\n  }}\n</div>","moduleName":"appkit/components/app-imported-listing-settings-container.hbs","parseOptions":{"srcName":"appkit/components/app-imported-listing-settings-container.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { computed } from '@ember/object';
import { CurrencyUtil } from 'appkit/lib/currency';
import { inject as service } from '@ember/service';

function setValueArray(range, arrRef, currency) {
  let val = [];

  arrRef.forEach((ref, idx) => {
    if (range[ref] !== null) {
      val.push([CurrencyUtil.format(range[ref], { currency }), idx]);
    }
  });

  return val;
}

export default class AppImportedListingSettingsContainer extends Component {
  @tracked settingsList;
  @service intl;

  @action
  updateSettings() {
    const {
      basePrice,
      minPrice,
      dayOfWeekMinPrices,
      maxPrice,
      minPriceRanges,
      minMaxPriceRanges,
      extraGuestFee,
      extraGuestThreshold,
      minStay,
      dayOfWeekMinStays,
      gapFillingEnabled,
      orphanMinStay,
      orphanMinStayBuffer,
      minStayRanges,
      lastMinuteMinStays,
      orphanDays,
      discounts,
      checkinDays,
      checkoutDays,
      checkinCheckoutChannelTerminology,
      isCheckoutEnabled,
      checkinCheckoutDaysRanges,
      isMinStayEnabled,
      currency,
    } = this.args.importedSettings;

    const minPriceByDowParsed = dayOfWeekMinPrices?.reduce((arr, it) => {
      if (arr.length < 1) {
        arr = [{ minPrice: it.minPrice, weekdays: [it.weekday] }];
        return arr;
      }

      if (arr.map(val => val.minPrice).includes(it.minPrice)) {
        arr = arr.map(val => ({
          minPrice: val.minPrice,
          weekdays:
            it.minPrice == val.minPrice ? [...val.weekdays, it.weekday] : val.weekdays,
        }));
        return arr;
      }

      arr = [...arr, { minPrice: it.minPrice, weekdays: [it.weekday] }];
      return arr;
    }, []);

    const minStayByDowParsed = dayOfWeekMinStays?.reduce((arr, it) => {
      if (arr.length < 1) {
        arr = [{ minStay: it.minStay, weekdays: [it.weekday] }];
        return arr;
      }

      if (arr.map(val => val.minStay).includes(it.minStay)) {
        arr = arr.map(val => ({
          minStay: val.minStay,
          weekdays:
            it.minStay == val.minStay ? [...val.weekdays, it.weekday] : val.weekdays,
        }));
        return arr;
      }

      arr = [...arr, { minStay: it.minStay, weekdays: [it.weekday] }];
      return arr;
    }, []);

    let parsedSettings = [
      {
        ...(basePrice && {
          key: 'basePrice',
          field: this.intl.t('glossary.basePrice'),
          subject: this.intl.t('glossary.basePrice'),
          value: CurrencyUtil.format(basePrice, {
            currency,
          }),
          selected: true,
        }),
      },
      {
        ...(minPrice && {
          key: 'minPrice',
          field: this.intl.t('glossary.minPrice'),
          subject: this.intl.t('glossary.minPrice'),
          value: CurrencyUtil.format(minPrice, {
            currency,
          }),
          selected: true,
        }),
      },
      {
        ...(minPriceByDowParsed?.length &&
          this.args.isDoWSettingsAvailable && {
            key: 'dayOfWeekMinPricesParsed',
            field: this.intl.t('glossary.minPriceByDoW'),
            subject: this.intl.t('glossary.minPriceByDoW'),
            value: minPriceByDowParsed?.map(val => ({
              value: val.minPrice
                ? CurrencyUtil.format(val.minPrice, {
                    currency,
                  })
                : null,
              weekdays: val.weekdays
                .map(d =>
                  this.intl.t(`common.weekdaysPlural.${d.toLowerCase()}`).toLowerCase()
                )
                .join(', '),
              range: val,
              selected: true,
            })),
          }),
      },
      {
        ...(maxPrice && {
          key: 'maxPrice',
          field: this.intl.t('glossary.maxPrice'),
          subject: this.intl.t('glossary.maxPrice'),
          value: CurrencyUtil.format(maxPrice, {
            currency,
          }),
          selected: true,
        }),
      },
      {
        ...(minPriceRanges?.length !== 0 && {
          key: 'minPriceRanges',
          rangeKey: 'minPrice',
          field: this.intl.t('glossary.seasonalMinPrices'),
          subject: this.intl.t('glossary.minimumPrice'),
          value: minPriceRanges?.map(range => ({
            value: CurrencyUtil.format(range.minPrice, {
              currency,
            }),
            startDate: window.DateUtil.format(range?.startDate, {
              fmt: 'MM/DD/YYYY',
            }),
            endDate: window.DateUtil.format(range?.endDate, {
              fmt: 'MM/DD/YYYY',
            }),
            range: range,
            selected: true,
          })),
        }),
      },
      {
        ...(minMaxPriceRanges?.length !== 0 && {
          key: 'minMaxPriceRanges',
          rangeKey: ['minPrice', 'maxPrice'],
          field: this.intl.t('glossary.seasonalMinMaxPrices'),
          subject: [
            this.intl.t('glossary.minimumPrice'),
            this.intl.t('glossary.maximumPrice'),
          ],
          value: minMaxPriceRanges?.map(range => ({
            value: setValueArray(range, ['minPrice', 'maxPrice'], currency),
            startDate: window.DateUtil.format(range?.startDate, {
              fmt: 'MM/DD/YYYY',
            }),
            endDate: window.DateUtil.format(range?.endDate, {
              fmt: 'MM/DD/YYYY',
            }),
            range: range,
            selected: true,
          })),
        }),
      },
      {
        ...(extraGuestFee && {
          key: 'extraGuestFee',
          field: this.intl.t('glossary.extraGuestFee'),
          subject: this.intl.t('glossary.extraGuestFee'),
          value: CurrencyUtil.format(extraGuestFee, {
            currency,
          }),
          messageTerm: this.intl.t('glossary.perPersonPerNightAfter'),
          dependantField: {
            key: 'extraGuestThreshold',
            value: extraGuestThreshold,
            messageTerm: this.intl.t('glossary.guestOptionalPlural'),
          },
          selected: true,
        }),
      },
      {
        ...(extraGuestThreshold && {
          key: 'extraGuestThreshold',
          field: this.intl.t('glossary.extraGuestThreshold'),
          subject: this.intl.t('glossary.extraGuestThreshold'),
          value: extraGuestThreshold,
          selected: true,
        }),
      },
      {
        ...(minStay && {
          key: 'minStay',
          field: this.intl.t('glossary.annualMinStay'),
          subject: this.intl.t('glossary.annualMinStay'),
          value: minStay,
          messageTerm: this.intl.t('glossary.nightOptionalPlural'),
          selected: true,
        }),
      },
      {
        ...(minStayByDowParsed?.length &&
          this.args.isDoWSettingsAvailable && {
            key: 'dayOfWeekMinStaysParsed',
            field: this.intl.t('glossary.minStayByDoW'),
            subject: this.intl.t('glossary.minStayByDoW'),
            value: minStayByDowParsed?.map(val => ({
              value: val.minStay,
              weekdays: val.weekdays
                .map(d =>
                  this.intl.t(`common.weekdaysPlural.${d.toLowerCase()}`).toLowerCase()
                )
                .join(', '),
              range: val,
              selected: true,
            })),
          }),
      },
      {
        ...(gapFillingEnabled && {
          key: 'gapFillingEnabled',
          field: this.intl.t('glossary.gapFillSettings'),
          subject: this.intl.t('glossary.gapFilling'),
          value: this.intl.t('common.enabled'),
          selected: true,
          ...((orphanMinStay !== 0 || orphanMinStayBuffer !== 0) && {
            extrafields: [
              {
                ...(orphanMinStay !== 0 && {
                  key: 'orphanMinStay',
                  field: this.intl.t('glossary.orphanMinStay'),
                  value: orphanMinStay,
                  messageTerm: this.intl.t('glossary.nightOptionalPlural'),
                  selected: true,
                }),
              },
              {
                ...(orphanMinStayBuffer !== 0 && {
                  key: 'orphanMinStayBuffer',
                  field: this.intl.t('glossary.orphanMinStayBuffer'),
                  value: orphanMinStayBuffer,
                  messageTerm: this.intl.t('glossary.nightOptionalPlural'),
                  selected: true,
                }),
              },
            ].filter(item => item?.field),
          }),
        }),
      },
      {
        ...(minStayRanges?.length !== 0 && {
          key: 'minStayRanges',
          rangeKey: 'minStay',
          field: this.intl.t('glossary.seasonalMinStays'),
          subject: this.intl.t('glossary.minimumStay'),
          value: minStayRanges?.map(range => ({
            value: range.minStay,
            startDate: window.DateUtil.format(range?.startDate, {
              fmt: 'MM/DD/YYYY',
            }),
            endDate: window.DateUtil.format(range?.endDate, {
              fmt: 'MM/DD/YYYY',
            }),
            range: range,
            selected: true,
          })),
          messageTerm: this.intl.t('glossary.nightOptionalPlural'),
        }),
      },
      {
        ...(lastMinuteMinStays?.length !== 0 && {
          key: 'lastMinuteMinStays',
          field: this.intl.t('glossary.lastMinuteMinimumStay'),
          subject: this.intl.t('glossary.aLastMinuteMinimumStay'),
          value: lastMinuteMinStays?.map(range => ({
            value: range.minDays,
            duration: range.daysAway,
            direction: range.direction,
            hasExtraFields: true,
            range: range,
            selected: true,
          })),
          messageTerm: this.intl.t('glossary.dayOptionalPlural'),
        }),
      },
      {
        ...(orphanDays?.length !== 0 && {
          key: 'orphanDays',
          field: this.intl.t('glossary.orphanDays'),
          value: orphanDays?.map(range => ({
            action:
              range.percentage < 0
                ? this.intl.t('common.reduce')
                : this.intl.t('common.increase'),
            value: Math.abs(range.percentage * 100),
            duration: range.days,
            range: range,
            ignoreOnWeekdays: range.ignoreOnWeekdays
              .map(d =>
                this.intl.t(`common.weekdaysPlural.${d.toLowerCase()}`).toLowerCase()
              )
              .join(', '),
            selected: true,
          })),
        }),
      },
      {
        ...(discounts?.length !== 0 && {
          key: 'discounts',
          field: this.intl.t('glossary.lastMinuteDiscounts'),
          value: discounts?.map(range => ({
            action:
              range.percentage < 0
                ? this.intl.t('common.increase')
                : this.intl.t('common.reduce'),
            value: Math.abs(range.percentage * 100),
            timeFrameDef:
              range.direction === 'out'
                ? this.intl.t('common.forTheNext')
                : this.intl.t('common.after'),
            duration: range.days,
            range: range,
            selected: true,
          })),
        }),
      },
      {
        ...(checkinDays?.length !== 0 &&
          isMinStayEnabled && {
            key: 'checkinDays',
            field:
              checkinCheckoutChannelTerminology === 'changeover' || !isCheckoutEnabled
                ? this.intl.t('glossary.annualChangeoverDays')
                : this.intl.t('glossary.annualCheckinDays'),
            subject:
              checkinCheckoutChannelTerminology === 'changeover' || !isCheckoutEnabled
                ? this.intl.t('glossary.annualChangeoverDays')
                : this.intl.t('glossary.annualCheckinDays'),
            value: checkinDays
              ?.map(d =>
                this.intl.t(`common.weekdays.${d.toLowerCase()}`).toLowerCase()
              )
              .join(', ')
              .replace(/,([^,]*)$/, ` ${this.intl.t('common.and')}` + '$1'),
            selected: true,
          }),
      },
      {
        ...(checkoutDays?.length !== 0 &&
          isMinStayEnabled &&
          isCheckoutEnabled && {
            key: 'checkoutDays',
            field: this.intl.t('glossary.annualCheckoutDays'),
            subject: this.intl.t('glossary.annualCheckoutDays'),
            value: checkoutDays
              ?.map(d =>
                this.intl.t(`common.weekdays.${d.toLowerCase()}`).toLowerCase()
              )
              .join(', ')
              .replace(/,([^,]*)$/, ` ${this.intl.t('common.and')}` + '$1'),
            selected: true,
          }),
      },
      {
        ...(checkinCheckoutDaysRanges?.length !== 0 &&
          isMinStayEnabled && {
            key: 'checkinCheckoutDaysRanges',
            rangeKey: ['checkinDays', 'checkoutDays'],
            field:
              checkinCheckoutChannelTerminology === 'changeover' || !isCheckoutEnabled
                ? this.intl.t('glossary.seasonalChangeoverDays')
                : this.intl.t('glossary.seasonalCheckinAndCheckoutDays'),
            subject: [
              checkinCheckoutChannelTerminology === 'changeover' || !isCheckoutEnabled
                ? this.intl.t('glossary.changeoverDays')
                : this.intl.t('glossary.checkinDays'),
              this.intl.t('glossary.checkoutDays'),
            ],
            value: checkinCheckoutDaysRanges?.map(range => ({
              value: [
                [
                  range?.checkinDays
                    ?.map(d =>
                      this.intl.t(`common.weekdays.${d.toLowerCase()}`).toLowerCase()
                    )
                    .join(', ')
                    .replace(/,([^,]*)$/, ` ${this.intl.t('common.and')}` + '$1'),
                  0,
                ],
                [
                  checkinCheckoutChannelTerminology === 'changeover' ||
                  !isCheckoutEnabled
                    ? null
                    : range?.checkoutDays
                        ?.map(d =>
                          this.intl
                            .t(`common.weekdays.${d.toLowerCase()}`)
                            .toLowerCase()
                        )
                        .join(', ')
                        .replace(/,([^,]*)$/, ` ${this.intl.t('common.and')}` + '$1'),
                  1,
                ],
              ].filter(item => item.firstObject),
              startDate: window.DateUtil.format(range?.startDate, {
                fmt: 'MM/DD/YYYY',
              }),
              endDate: window.DateUtil.format(range?.endDate, {
                fmt: 'MM/DD/YYYY',
              }),
              range: range,
              selected: true,
            })),
          }),
      },
    ];

    this.settingsList = parsedSettings.filter(el => el?.field);
  }

  @computed('settingsList.[]', 'settingsList.@each.{value}')
  get nestedSettingsFromList() {
    let totalNestedSettings = this.settingsList.filter(item => item.value?.firstObject);

    return totalNestedSettings.map(item => item.value).flat();
  }

  @computed('settingsList.[]', 'settingsList.@each.{extrafields}')
  get extrafieldsSettingsFromList() {
    let totalExtrafieldsSettings = this.settingsList.filter(item => item?.extrafields);

    return totalExtrafieldsSettings.map(item => item.extrafields).flat();
  }

  @computed(
    'settingsList.[]',
    'settingsList.@each.{selected}',
    'nestedSettingsFromList.[]',
    'nestedSettingsFromList.@each.{selected}',
    'extrafieldsSettingsFromList.[]',
    'extrafieldsSettingsFromList.@each.{selected}'
  )
  get totalItems() {
    return this.nestedSettingsFromList.concat(
      this.settingsList.filter(item => !item.value?.firstObject),
      this.extrafieldsSettingsFromList
    );
  }

  @computed(
    'settingsList.[]',
    'settingsList.@each.{selected}',
    'nestedSettingsFromList.[]',
    'nestedSettingsFromList.@each.{selected}',
    'extrafieldsSettingsFromList.[]',
    'extrafieldsSettingsFromList.@each.{selected}'
  )
  get settingsListToSend() {
    if (
      !this.settingsList.some(s => s.key == 'dayOfWeekMinPricesParsed') &&
      !this.settingsList.some(s => s.key == 'dayOfWeekMinStaysParsed')
    )
      return [...this.settingsList];

    let dowMinPricesItem = this.settingsList.filter(
      s => s.key == 'dayOfWeekMinPricesParsed'
    )[0];

    let dowMinStaysItem = this.settingsList.filter(
      s => s.key == 'dayOfWeekMinStaysParsed'
    )[0];

    let serializedDowMinPrices = dowMinPricesItem.value
      .map(i =>
        i.range.weekdays.map(w => {
          return { weekday: w, minPrice: i.range.minPrice, selected: i.selected };
        })
      )
      .flat();

    let serializedDowMinStays = dowMinStaysItem.value
      .map(i =>
        i.range.weekdays.map(w => {
          return { weekday: w, minStay: i.range.minStay, selected: i.selected };
        })
      )
      .flat();

    return [
      ...this.settingsList,
      {
        key: 'dayOfWeekMinPrices',
        value: serializedDowMinPrices.map(val => ({
          value: val.minPrice,
          weekday: val.weekday,
          range: {
            minPrice: val.minPrice,
            weekday: val.weekday,
          },
          selected: val.selected,
        })),
      },
      {
        key: 'dayOfWeekMinStays',
        value: serializedDowMinStays.map(val => ({
          value: val.minStay,
          weekday: val.weekday,
          range: { minStay: val.minStay, weekday: val.weekday },
          selected: val.selected,
        })),
      },
    ];
  }

  @computed('totalItems.[]')
  get selectedItems() {
    return this.totalItems.filter(item => item.selected);
  }

  @computed('setttings.@each.selected', 'selectedItems.[]')
  get ifAllListItemsSelected() {
    return this.totalItems.length === this.selectedItems.length;
  }

  @computed('setttings.@each.selected', 'selectedItems.[]')
  get ifSomeListItemsSelected() {
    return (
      this.selectedItems.length !== 0 &&
      this.selectedItems.length < this.totalItems.length
    );
  }

  @action
  onSelectAll() {
    if (!this.ifAllListItemsSelected) {
      this.totalItems.forEach(item => {
        set(item, 'selected', true);
      });
    } else {
      this.totalItems.forEach(item => {
        set(item, 'selected', false);
      });
    }
    this.args.onSelection(this.settingsListToSend);
  }

  @action
  onSelectingItem(item) {
    if (item.dependantField) {
      set(
        this.settingsList.filter(field => field.key === item.dependantField.key)[0],
        'selected',
        item.selected === true ? false : true
      );
    }

    set(item, 'selected', item.selected === true ? false : true);

    this.args.onSelection(this.settingsListToSend);
  }

  constructor() {
    super(...arguments);
    this.updateSettings();

    const output = [...this.settingsListToSend];

    if (
      this.args.importedSettings.checkinDays &&
      !this.args.importedSettings.isCheckoutEnabled &&
      this.args.importedSettings.isMinStayEnabled
    ) {
      let checkoutDays = {
        key: 'checkoutDays',
        field: this.intl.t('glossary.annualCheckoutDays'),
        subject: this.intl.t('glossary.annualCheckoutDays'),
        value: this.args.importedSettings.checkinDays
          ?.map(d => this.intl.t(`common.weekdays.${d.toLowerCase()}`).toLowerCase())
          .join(', ')
          .replace(/,([^,]*)$/, ` ${this.intl.t('common.and')}` + '$1'),
        selected: true,
      };

      output.push(checkoutDays);
    }

    this.args.onSelection(output);
  }
}
