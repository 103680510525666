import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='{{this.classes}}'>\n  <Bp::Button\n    class={{this.buttonClasses}}\n    @size={{this.size}}\n    @variant='outlined'\n    @color='gray'\n    onClick={{this.onClearSelected}}\n    disabled={{this.clearDisabled}}\n  >\n    {{t 'common.clear'}}\n  </Bp::Button>\n  <Bp::Button\n    class={{this.buttonClasses}}\n    @size={{this.size}}\n    @color='brand'\n    onClick={{this.onConfirmSelected}}\n    disabled={{this.confirmDisabled}}\n  >\n    {{t 'common.apply'}}\n  </Bp::Button>\n</div>", {"contents":"<div class='{{this.classes}}'>\n  <Bp::Button\n    class={{this.buttonClasses}}\n    @size={{this.size}}\n    @variant='outlined'\n    @color='gray'\n    onClick={{this.onClearSelected}}\n    disabled={{this.clearDisabled}}\n  >\n    {{t 'common.clear'}}\n  </Bp::Button>\n  <Bp::Button\n    class={{this.buttonClasses}}\n    @size={{this.size}}\n    @color='brand'\n    onClick={{this.onConfirmSelected}}\n    disabled={{this.confirmDisabled}}\n  >\n    {{t 'common.apply'}}\n  </Bp::Button>\n</div>","moduleName":"appkit/components/bp/select/parts/after-options/index.hbs","parseOptions":{"srcName":"appkit/components/bp/select/parts/after-options/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Themeable, VARIANTS } from '../../../theme';
import afterOptionsTheme from './index.theme';

export default class AfterOptions extends Themeable(Component, {
  theme: afterOptionsTheme,
  variant: VARIANTS.CONTAINED,
}) {
  get confirmDisabled() {
    return !this.args.extra.canConfirm;
  }

  get clearDisabled() {
    return !this.args.extra.canClear;
  }

  get buttonClasses() {
    return this.getClassesForTheme(this.theme.button);
  }

  @action
  onConfirmSelected() {
    this.args.extra.actions.confirmSelected();
  }

  @action
  onClearSelected() {
    this.args.extra.actions.clearSelected();
  }
}
