import classic from 'ember-classic-decorator';
import { action, set, get } from '@ember/object';
import Component from '@ember/component';
import Util from 'appkit/lib/util';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';

function getCurrentPosition() {
  return new Promise(function (success, failure) {
    navigator.geolocation.getCurrentPosition(
      position => {
        success(position);
      },
      error => {
        failure(error);
      }
    );
  });
}

@classic
export default class AppMarketsMap extends Component {
  state = 'intro';
  errorMessage = null;
  submittingNotifyForm = false;
  marketName = 'San Francisco';
  mapCoordinates = {
    lat: '37.758818',
    lng: '-122.418150',
  };
  markerState = 'marker-set';

  @service googleMapsApi;
  @reads('googleMapsApi.google') google;
  mapSearchInput = '';
  searchInputState = 'hidden';
  processing = true;
  placeToNotify = '';

  @action
  checkAddress(event) {
    super.init(...arguments);

    this.get('google').then(google => {
      if (this.isDestroyed) {
        return;
      }

      var input = document.getElementById('googleSearchInput');
      var autocomplete = new google.maps.places.Autocomplete(input);

      google.maps.event.addListener(
        autocomplete,
        'place_changed',
        async function () {
          if (this.get('submitted')) {
            this.set('submitted', false);
            this.set('formIsCalled', false);
          }
          try {
            var place = autocomplete.getPlace();
            this.set('markerState', 'marker-load');

            event.map.panTo({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });

            const data = await this.ajax._post('/api/cluster_address', {
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            });

            this.set('hideOut', true);

            if (data.name) {
              set(this, 'state', 'marketFound');
              set(this, 'marketName', data.name);
              set(this, 'markerState', 'marker-set');
            } else {
              set(this, 'state', 'marketNotFound');
              set(this, 'markerState', 'marker-empty');
            }

            set(this, 'errorMessage', null);
            set(this, 'mapCoordinates.lat', place.geometry.location.lat());
            set(this, 'mapCoordinates.lng', place.geometry.location.lng());

            this.placeToNotify = place.formatted_address;
          } catch (error) {
            set(this, 'markerState', 'error-raised');
            set(
              this,
              'errorMessage',
              'Be sure to choose one of  the form suggestions...'
            );
            input.type = 'text';
            set(this, 'state', 'error');
          }
          set(this, 'searchInputState', 'text');
        }.bind(this)
      );
    });
  }

  @action
  async getInitialPosition() {
    set(this, 'markerState', 'marker-load');

    try {
      const position = await getCurrentPosition();

      set(this, 'hideOut', true);

      set(this, 'mapCoordinates.lat', position.coords.latitude);
      set(this, 'mapCoordinates.lng', position.coords.longitude);

      const data = await this.ajax._post('/api/cluster_address', {
        latitude: get(this, 'mapCoordinates.lat'),
        longitude: get(this, 'mapCoordinates.lng'),
      });

      if (data.name) {
        set(this, 'state', 'marketFound');
        set(this, 'marketName', data.name);
        set(this, 'markerState', 'marker-set');
      } else {
        set(this, 'state', 'marketNotFound');
        set(this, 'markerState', 'marker-empty');

        var maps = get(this.google, 'maps');

        let geoCoder = new maps.Geocoder();
        geoCoder.geocode(
          {
            location: {
              lat: get(this, 'mapCoordinates.lat'),
              lng: get(this, 'mapCoordinates.lng'),
            },
          },
          results => {
            try {
              let selectedAddress;
              selectedAddress = results.filter(
                result => result.types.includes('locality') == true
              )[0].formatted_address;
              set(this, 'placeToNotify', selectedAddress);
            } catch (error) {
              let selectedAddress;
              selectedAddress = results.filter(
                result => result.types.includes('administrative_area_level_2') == true
              )[0].formatted_address;
              set(this, 'placeToNotify', selectedAddress);
            }
          }
        );
      }
    } catch (error) {
      if (error.code === 1) {
        set(this, 'markerState', 'user-not-allowing');
        set(this, 'errorMessage', "It seems we can't track your position...");
      } else {
        set(this, 'markerState', 'error-raised');
        set(this, 'errorMessage', 'Try out our other options...');
      }
      set(this, 'state', 'error');
    }
    set(this, 'searchInputState', 'text');
    set(this, 'processing', false);
  }

  @action
  async checkPosition(event) {
    if (get(this, 'submitted')) {
      set(this, 'submitted', false);
      set(this, 'formIsCalled', false);
    }

    set(this, 'markerState', 'marker-load');

    event.map.panTo({
      lat: event.googleEvent.latLng.lat(),
      lng: event.googleEvent.latLng.lng(),
    });
    try {
      const data = await this.ajax._post('/api/cluster_address', {
        latitude: event.googleEvent.latLng.lat(),
        longitude: event.googleEvent.latLng.lng(),
      });

      if (data.name) {
        set(this, 'state', 'marketFound');
        set(this, 'marketName', data.name);
        set(this, 'markerState', 'marker-set');
      } else {
        set(this, 'state', 'marketNotFound');
        set(this, 'markerState', 'marker-empty');

        var maps = get(this.google, 'maps');
        let geoCoder = new maps.Geocoder();
        geoCoder.geocode(
          {
            location: {
              lat: get(this, 'mapCoordinates.lat'),
              lng: get(this, 'mapCoordinates.lng'),
            },
          },
          results => {
            try {
              let selectedAddress;
              selectedAddress = results.filter(
                result => result.types.includes('locality') == true
              )[0].formatted_address;
              this.set('placeToNotify', selectedAddress);
            } catch (error) {
              let selectedAddress;
              selectedAddress = results.filter(
                result => result.types.includes('administrative_area_level_2') == true
              )[0].formatted_address;
              set(this, 'placeToNotify', selectedAddress);
            }
          }
        );
      }
      set(this, 'errorMessage', null);
      set(this, 'mapCoordinates.lat', event.googleEvent.latLng.lat());
      set(this, 'mapCoordinates.lng', event.googleEvent.latLng.lng());
    } catch (error) {
      set(this, 'markerState', 'error-raised');
      set(this, 'errorMessage', 'Try again later...');

      set(this, 'state', 'error');
    }
    set(this, 'searchInputState', 'text');
  }

  @action
  nextInputOrSubmit() {
    return Util.nextInputOrSubmit.call(this, window.event);
  }

  @action
  submitNotifyForm() {
    set(this, 'errorMessage', null);

    set(this, 'submittingNotifyForm', true);
    Util.isEmailInvalid(this.email).then(reason => {
      if (reason) {
        set(this, 'errorMessage', reason);
        set(this, 'submittingNotifyForm', false);
        return false;
      }

      this.ajax
        ._post('/api/contact', {
          email: this.email,
          reason: 'Home page notify signup',
          city: this.placeToNotify,
          lat: this.mapCoordinates.lat,
          lng: this.mapCoordinates.lng,
        })
        .then(() => {
          set(this, 'submittingNotifyForm', false);
          set(this, 'submitted', true);
        });
    });

    return false;
  }
  primaryMapStyle = [
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#115960',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#8fd4da',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#69c5cd',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#7cccd3',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#7cccd3',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#69c5cd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#69c5cd',
        },
        {
          weight: 0.5,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#56bdc6',
        },
        {
          weight: 0.5,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#43b6c0',
        },
        {
          weight: 0.5,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#3ab0ba',
        },
        {
          weight: 0.5,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#69c5cd',
        },
        {
          weight: 0.5,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#56bdc6',
        },
        {
          weight: 0.5,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#69c5cd',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#aed8de',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#258189',
        },
      ],
    },
  ];
}
