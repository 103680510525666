import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Bp::Input::Symbol\n  @append={{true}}\n  @symbol='%'\n  @onInput={{@onInput}}\n  @hasError={{@hasError}}\n  @isDirty={{@isDirty}}\n  @toggleIsFocused={{@toggleIsFocused}}\n  @containerClass={{@containerClass}}\n  ...attributes\n  {{input-match this.percentageRegex}}\n/>", {"contents":"<Bp::Input::Symbol\n  @append={{true}}\n  @symbol='%'\n  @onInput={{@onInput}}\n  @hasError={{@hasError}}\n  @isDirty={{@isDirty}}\n  @toggleIsFocused={{@toggleIsFocused}}\n  @containerClass={{@containerClass}}\n  ...attributes\n  {{input-match this.percentageRegex}}\n/>","moduleName":"appkit/components/bp/input/percentage.hbs","parseOptions":{"srcName":"appkit/components/bp/input/percentage.hbs"}});
import Component from '@glimmer/component';

export default class BpInputPercentageComponent extends Component {
  get percentageRegex() {
    const negative = this.args.negative ? '-?' : '';
    const regexExp = this.args.decimals
      ? `^${negative}(100(\\.00?)?|\\d{0,2}(\\.|\\.\\d\\d?)?)$`
      : `^${negative}(\\d{0,2}|100)$`;
    return new RegExp(regexExp);
  }
}
