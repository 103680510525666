import { sha512 } from 'js-sha512';

export default {
  name: 'override-session',

  // This allows overriding of the current user by pulling the email / token from
  // the params
  initialize() {
    if (window.location.hash.length < 1) {
      return;
    }

    let query = window.location.hash.slice(1);
    let params = {};
    for (let pair of query.split('&')) {
      let key = pair.split('=')[0];
      let value = pair.split('=')[1];
      key = decodeURIComponent(key);
      value = decodeURIComponent(value);
      params[key] = value;
    }

    window.hashParams = params;
    // Ditch the hash params - ember used to do this for us.
    let url = window.location.pathname + window.location.search;
    history.replaceState('', document.title, url);

    if (params.email && params.token) {
      localStorage.setItem('username', params.email);
      localStorage.setItem('token', params.token);
      // Configure launch darkly
      if (params.ld_secure_hash) {
        localStorage.setItem('ldEmail', params.ld_email);
        localStorage.setItem('ldKey', sha512(params.ld_email));
        localStorage.setItem('ldSecureHash', params.ld_secure_hash);
      } else {
        localStorage.removeItem('ldEmail');
        localStorage.removeItem('ldKey');
        localStorage.removeItem('ldSecureHash');
      }
    }
    if (params.sudo !== undefined) {
      localStorage.setItem('sudo', params.sudo);
    }
    if (params.hideImages !== undefined) {
      localStorage.setItem('hideImages', params.hideImages);
    }
    if (params.isStaff !== undefined) {
      localStorage.setItem('isStaff', params.isStaff);
    }
  },
};
