/**
 *  Really basic and  temporal error mapper to make a POC for displaying error details in the view
 *
 *  Ideally, when we implement error control we would take the error id and do a proper mapping.
 *  For now, let's give it a try of abstracting the basic data from the error message in Airbnb format.
 */
export function airbnbErrorAdapterv0(reservationPushOutcome) {
  try {
    const { action, status, message, timestamp } = reservationPushOutcome.attributes;
    // Example format in Airbnb
    // "Error: <class 'lib.integrations.apis.escapia.exceptions.PushReservationError'>\nMessage: Reservation could not be created in Escapia because between 2022-02-12 and 2022-02-13 (first_night/last_night dates) the selected property is not available.Escapia account/domain=ACCOUNTNAME, PropertyID on escapia=PROPERTYID.",
    const errorBody = message.split('\nMessage:')[1];
    const errorMsg = errorBody.split('.')[0];
    return {
      outcomeId: reservationPushOutcome.id,
      action,
      status,
      timestamp,
      message: errorMsg,
    };
  } catch (e) {
    console.error('Uncategorized Airbnb error on push outcome', reservationPushOutcome);
    throw e;
  }
}
