import moment from 'moment';

/* FILTER FUNCTIONS
  - Are called when the user presses on the search button
  - Operate over the FE models!

  When the search is executed, the function first store the value
  we want to filter, and then the whole array is filtered by that criteria.
 */
const filterByAvailablility = availability => listingDate => {
  return availability === listingDate.availability.id;
};

const filterByOperationDate = dateFrom => listingDate => {
  const _refDate = moment(dateFrom);
  const lastUpdateAt = moment(listingDate.mostRecentUpdate.timestamp);

  return lastUpdateAt.isSameOrAfter(_refDate);
};

const filterByCalendarDate = dateEnd => listingDate => {
  const _dateEnd = moment(dateEnd);
  const calendarDate = moment(listingDate.date);

  return calendarDate.isSameOrBefore(_dateEnd);
};

/* SORT FUNCTIONS */
const sortByCalendarDate = (a, b) => {
  const _a = moment(a.date);
  const _b = moment(b.date);

  return _a.isSameOrAfter(_b) ? -1 : 1;
};

const sortByAvailability = (a, b) => {
  if (a.PMS.availability === 'blocked') return 1;
  if (b.PMS.availability === 'blocked') return -1;
  return 0;
};

const sortByMostRecentUpdate = (a, b) => {
  const _a = moment(a.mostRecentUpdate.timestamp);
  const _b = moment(b.mostRecentUpdate.timestamp);

  return _a.isSameOrAfter(_b) ? 1 : -1;
};

// COUNTERS
export function getAvailabilityCounters(listingDates) {
  const counterState = {
    available: 0,
    blocked: 0,
    issue: 0,
  };

  for (const listingDate of listingDates) {
    const availability = listingDate.availability.id;

    if (availability === 'blocked') counterState.blocked++;
    else if (availability === 'available') counterState.available++;
    else counterState.issue++;
  }

  return counterState;
}

export const listingDatesFilters = {
  filterByAvailablility,
  filterByOperationDate,
  filterByCalendarDate,
};

export const listingDatesSorts = {
  sortByCalendarDate,
  sortByAvailability,
  sortByMostRecentUpdate,
};
