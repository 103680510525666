import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SelectedItems {
  @tracked items = [];

  isSelected(item) {
    return this.items.filter(id => id === item.id).length > 0;
  }

  get length() {
    return this.items.length;
  }

  @action
  select(item) {
    if (this.isSelected(item)) {
      this.items = this.items.filter(id => id !== item.id);
    } else {
      this.items = [...this.items, item.id];
    }
  }

  @action
  selectAll(ids) {
    if (ids.length === this.items.length) {
      this.items = [];
    } else {
      this.items = ids;
    }
  }

  @action
  reset() {
    this.items = [];
  }
}
