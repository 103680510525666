import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import $ from 'jquery';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
@classic
export default class NearbyController extends Controller {
  @service intl;
  @service alert;
  @service featureFlag;

  hoverListing = null;
  channel = 'airbnb';
  loading = true;
  nearbyListings = [];

  sortFields = [
    { field: 'healthScore', label: this.intl.t('glossary.healthScore') },
    { field: 'metrics.basePrice', label: this.intl.t('pricing.listing.price') },
  ];

  sortField = 'healthScore';
  sortOrder = 'desc';

  async init() {
    super.init(...arguments);
  }

  @computed('sortField', 'sortOrder', 'nearbyListings.@each')
  get sortedListings() {
    let field = this.sortField;
    let out = this.nearbyListings;
    out.sort((a, b) => a.get(field) - b.get(field));

    if (this.sortOrder !== 'asc') {
      out.reverse();
    }

    return out;
  }

  @action
  changeChannel(channel) {
    // Disabled until BE Fixes homeaway nearby listing request (DSUP-1195)
    if (channel === 'homeaway') {
      const disableMsg = this.intl.t('validation.disabledFeature');
      return this.alert.info(disableMsg);
    }

    // This is pretty ugly...
    let path = this.get('target.currentPath');

    let target;
    if (path.includes('dashboard')) {
      target = 'dashboard.pricing.listing';
    } else {
      target = 'preview';
    }

    this.set('channel', channel);
    this.transitionToRoute(`${target}.nearby.${channel}`);
  }

  @action
  setHighlightedPoint(point) {
    this.set('highlightedPoint', point);
    if (!point) {
      return;
    }

    let id = point.get('listingId');
    // TODO: this is a hack. It should go via a component.
    let el = $(
      `.nearby > .detail-list > .listings > .listing[data-listing-id="${id}"]`
    );
    if (el.length !== 1) {
      return;
    }
    let $nearby = $('.nearby > .detail-list > .listings');
    let offset =
      $nearby.scrollTop() -
      $nearby.offset().top +
      el.offset().top -
      $nearby.height() / 2 +
      100;

    $nearby.stop(true, false).animate({ scrollTop: offset }, 300);
  }

  // Meh. Unhappy with this solution. I feel like there is an easier solution
  // that I'm just missing.
  @action
  setHighlightedPointInner(point) {
    this.set('highlightedPoint', point);
  }

  @action
  reverseSortOrder() {
    let order = this.sortOrder;
    if (order === 'asc') {
      order = 'desc';
    } else {
      order = 'asc';
    }
    this.set('sortOrder', order);
  }
}
