import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

/**
  Controller to set individual settings for a merged listing
  Receives the editor class and initial state from the route.
  @model
  - userPermissions
  -  listing

  TODO: Iterate/improve: Connect validation & save connecting with Bulk Edit.
**/
export default class RelayListingSettings extends Controller {
  @tracked mergeSuggestions;
  settingsEditor;
  @tracked isMergeSuggestionsExpanded = false;

  @action
  toggleMergeSuggestionsExpand() {
    this.isMergeSuggestionsExpanded = !this.isMergeSuggestionsExpanded;
  }
}
