export async function UnmergeListingsUC(listings, ajax) {
  const payload = {
    listingIds: listings.map(listing => listing.id),
  };
  try {
    const { message } = await ajax._post('/api/listings/unmerge', payload);
    return message;
  } catch (errors) {
    let err = errors[0];
    throw new Error(err.message);
  }
}
