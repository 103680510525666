import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import { displayErrors } from 'appkit/lib/display_errors';

@classic
export default class NotificationsRoute extends AuthRoute {
  titleToken = 'Notifications';

  @service
  alert;

  model() {
    const alert = this.alert;
    return this.ajax
      ._get('/api/user/notifications/index')
      .then(data => {
        let model = EmberObject.create({});

        model.set(
          'notifications',
          data.notifications.map(n => {
            let obj = this.bpStore.createRecord('notification', n);
            return obj;
          })
        );

        model.set(
          'notificationRules',
          data.notificationRules.map(nr => {
            let obj = this.bpStore.createRecord('notification_rule', nr);

            obj.set(
              'notifications',
              this.bpStore
                .all('notification')
                .filter(notification => notification.notificationRuleId === nr.id)
            );

            return obj;
          })
        );

        return model;
      })
      .catch(errors => {
        displayErrors({ errors: errors, alert: alert });
      });
  }

  setupController(controller, model) {
    controller.setProperties({
      model: model,
      user: this.modelFor('dashboard/user'),
    });
  }
}
