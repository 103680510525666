// WARNING! THIS FILE WAS GENERATED PLEASE DO NOT MODIFY IT!
// Generation Details:
// At: 2024-11-08 23:18:18.955424+00:00

export const DATA_TYPE = {
  AVAILABILITY: 'availability',
  CANCELED_AT: 'canceled_at',
  CHECKIN_DAYS_FOR_LISTING: 'checkin_days_for_listing',
  CHECKIN_DAYS_PER_DAY: 'checkin_days_per_day',
  CHECKOUT_DAYS_FOR_LISTING: 'checkout_days_for_listing',
  CHECKOUT_DAYS_PER_DAY: 'checkout_days_per_day',
  CONTENT: 'content',
  CUSTOM_QUOTE: 'custom_quote',
  DAILY_PRICE: 'daily_price',
  EXTRA_GUEST_FEE_FOR_LISTING: 'extra_guest_fee_for_listing',
  EXTRA_GUEST_FEE_PER_DAY: 'extra_guest_fee_per_day',
  FREQUENT_AVAILABILITY: 'frequent_availability',
  GUEST: 'guest',
  ICAL: 'ical',
  MAX_STAY_FOR_LISTING: 'max_stay_for_listing',
  MAX_STAY_PER_DAY: 'max_stay_per_day',
  MIN_STAY_FOR_LISTING: 'min_stay_for_listing',
  MIN_STAY_PER_DAY: 'min_stay_per_day',
  MONTHLY_PRICE: 'monthly_price',
  MULTI_UNIT: 'multi_unit',
  QUOTE: 'quote',
  RELAY: 'relay',
  RESERVATION: 'reservation',
  RESERVATION_TO_BOOK: 'book',
  REVIEW: 'review',
  SOURCE_CHANNEL: 'source_channel',
  WEEKLY_PRICE: 'weekly_price',
};

export const CHANNEL = {
  AIRBNB: 'airbnb',
  AIRBNB_PARTNER: 'airbnb_partner',
  AVANTIO: 'avantio',
  BAREFOOT: 'barefoot',
  BAREFOOT_DIRECT: 'barefoot_direct',
  BEDS24: 'beds24',
  BENTRAL: 'bentral',
  BEST_BEACH: 'best_beach',
  BOOKING: 'booking',
  BOOKINGSYNC: 'bookingsync',
  BOOKING_CONNECT: 'booking_connect',
  BOOKING_EXPERTS: 'booking_experts',
  BOOKIPRO: 'bookipro',
  BRIGHTSIDE: 'brightside',
  CIIRUS: 'ciirus',
  CLOUDBEDS: 'cloudbeds',
  CSV: 'csv',
  DIRECT: 'direct',
  ELINA: 'elina',
  ESCAPIA: 'escapia',
  FAKE: 'fake',
  FAKE_2: 'fake_2',
  FAKE_PMS: 'fake_pms',
  FANTASTICSTAY: 'fantasticstay',
  FLIPKEY: 'flipkey',
  GFH: 'gfh',
  GUESTY: 'guesty',
  HAPA: 'hapa',
  HOMEAWAY: 'homeaway',
  HOMEAWAY_V12: 'homeaway_v12',
  HOMEAWAY_WEB: 'homeaway_web',
  HOMERTI: 'homerti',
  HOMHERO: 'homhero',
  HOMHERO_STAGING: 'homhero_staging',
  HOSPITABLE: 'hospitable',
  HOSTAWAY: 'hostaway',
  HOSTFULLY: 'hostfully',
  HOSTIFY: 'hostify',
  ICNEA: 'icnea',
  IGMS: 'igms',
  ILOCA: 'iloca',
  IPRO: 'ipro',
  IRENT: 'irent',
  JANIIS: 'janiis',
  KIGO: 'kigo',
  KIGO_PRO: 'kigo_pro',
  KROSS_BOOKING: 'kross_booking',
  LIGHTMAKER: 'lightmaker',
  LIVEREZ: 'liverez',
  LIVEREZ_DIRECT: 'liverez_direct',
  LODGABLE: 'lodgable',
  LODGIFY: 'lodgify',
  LODGIX: 'lodgix',
  LOGGIA: 'loggia',
  MEWS: 'mews',
  MYVR: 'myvr',
  NIIDO: 'niido',
  OCTORATE: 'octorate',
  OWNERREZ: 'ownerrez',
  REAL_TIME_RENTAL: 'real_time_rental',
  RENTALREADY: 'rentalready',
  RENTALS_UNITED: 'rentals_united',
  RENTALWISE: 'rentalwise',
  RMS: 'rms',
  SECRA: 'secra',
  SEPTEO: 'septeo',
  SMOOBU: 'smoobu',
  STAYDUVET: 'stayduvet',
  STAYS: 'stays',
  STREAMLINE: 'streamline',
  SUPERCONTROL: 'supercontrol',
  TOKEET: 'tokeet',
  TOWNEBANK: 'townebank',
  TRACK: 'track',
  TRAVELMOB: 'travelmob',
  TRIPADVISOR: 'tripadvisor',
  ULTUS: 'ultus',
  UPLISTING: 'uplisting',
  VILLAS365: 'villas365',
  VRBO: 'vrbo',
  VREASY: 'vreasy',
  VRM: 'vrm',
  ZEEVOU: 'zeevou',
  ZEEVOU_DIRECT: 'zeevou_direct',
};

export const CHANNEL_CONFIG = {
  [CHANNEL.AIRBNB]: {
    label: 'Airbnb',
    pms: false,
    canPush: [
      DATA_TYPE.AVAILABILITY,
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.CONTENT,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.GUEST,
      DATA_TYPE.ICAL,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
    ],
    relay: false,
  },
  [CHANNEL.AIRBNB_PARTNER]: {
    label: 'Airbnb Partner',
    pms: false,
    canPush: [
      DATA_TYPE.AVAILABILITY,
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.CONTENT,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.GUEST,
      DATA_TYPE.ICAL,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.AVANTIO]: {
    label: 'Avantio',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.GUEST,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.BAREFOOT]: {
    label: 'Barefoot',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.BAREFOOT_DIRECT]: {
    label: 'Barefoot Direct',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.LISTINGS_IN_PARALLEL,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.BEDS24]: {
    label: 'Beds24',
    pms: true,
    canPush: [
      DATA_TYPE.AVAILABILITY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.BENTRAL]: {
    label: 'Bentral',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.BEST_BEACH]: {
    label: 'Best Beach',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.BOOKING]: {
    label: '_Booking',
    pms: false,
    canPush: [
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.BOOKINGSYNC]: {
    label: 'BookingSync',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.BOOKING_CONNECT]: {
    label: 'Booking',
    pms: false,
    canPush: [
      DATA_TYPE.AVAILABILITY,
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.GUEST,
    ],
    relay: false,
  },
  [CHANNEL.BOOKING_EXPERTS]: {
    label: 'Booking Experts',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
    ],
    canPull: [
      DATA_TYPE.CANCELED_AT,
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.GUEST,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.REVIEW,
    ],
    relay: false,
  },
  [CHANNEL.BOOKIPRO]: {
    label: 'BookIpro',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.BRIGHTSIDE]: {
    label: 'Brightside',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.CIIRUS]: {
    label: 'Ciirus',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.CLOUDBEDS]: {
    label: 'Cloudbeds',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.CSV]: {
    label: 'Csv',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.LISTINGS_IN_PARALLEL,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.DIRECT]: {
    label: 'Direct',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.ELINA]: {
    label: 'elina',
    pms: true,
    canPush: [
      DATA_TYPE.AVAILABILITY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.ESCAPIA]: {
    label: 'Escapia',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.RESERVATION,
      DATA_TYPE.RESERVATION_TO_BOOK,
    ],
    canPull: [
      DATA_TYPE.CONTENT,
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.GUEST,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.QUOTE,
      DATA_TYPE.REVIEW,
    ],
    relay: true,
  },
  [CHANNEL.FAKE]: {
    label: 'Fake Channel',
    pms: false,
    canPush: [
      DATA_TYPE.AVAILABILITY,
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.REVIEW,
    ],
    relay: false,
  },
  [CHANNEL.FAKE_2]: {
    label: 'Fake Channel 2',
    pms: false,
    canPush: [
      DATA_TYPE.AVAILABILITY,
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.REVIEW,
    ],
    relay: false,
  },
  [CHANNEL.FAKE_PMS]: {
    label: 'Fake PMS Channel',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.MONTHLY_PRICE,
      DATA_TYPE.RESERVATION,
      DATA_TYPE.RESERVATION_TO_BOOK,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.LISTINGS_IN_PARALLEL,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.QUOTE,
      DATA_TYPE.REVIEW,
    ],
    relay: true,
  },
  [CHANNEL.FANTASTICSTAY]: {
    label: 'FantasticStay',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.FLIPKEY]: {
    label: 'FlipKey',
    pms: false,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.GFH]: {
    label: 'Guesty for hosts',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.GUESTY]: {
    label: 'Guesty',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.RESERVATION,
      DATA_TYPE.RESERVATION_TO_BOOK,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.LISTINGS_IN_PARALLEL,
      DATA_TYPE.QUOTE,
      DATA_TYPE.REVIEW,
    ],
    relay: true,
  },
  [CHANNEL.HAPA]: {
    label: 'Hapa',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.CANCELED_AT,
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.SOURCE_CHANNEL,
    ],
    relay: false,
  },
  [CHANNEL.HOMEAWAY]: {
    label: '_HomeAway',
    pms: false,
    canPush: [
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.HOMEAWAY_V12]: {
    label: 'HomeAway V12',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.HOMEAWAY_WEB]: {
    label: 'HomeAway / Vrbo',
    pms: false,
    canPush: [
      DATA_TYPE.AVAILABILITY,
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.HOMERTI]: {
    label: 'Homerti',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.HOMHERO]: {
    label: 'HomHero',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.HOMHERO_STAGING]: {
    label: 'HomHero Staging',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.HOSPITABLE]: {
    label: 'Hospitable',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.GUEST,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.HOSTAWAY]: {
    label: 'Hostaway',
    pms: true,
    canPush: [DATA_TYPE.DAILY_PRICE, DATA_TYPE.MIN_STAY_PER_DAY],
    canPull: [],
    relay: false,
  },
  [CHANNEL.HOSTFULLY]: {
    label: 'Hostfully',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.CANCELED_AT,
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.SOURCE_CHANNEL,
    ],
    relay: false,
  },
  [CHANNEL.HOSTIFY]: {
    label: 'Hostify',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.ICNEA]: {
    label: 'Icnea',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.MONTHLY_PRICE,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.IGMS]: {
    label: 'igms',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.ILOCA]: {
    label: 'Iloca',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.CANCELED_AT,
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.SOURCE_CHANNEL,
    ],
    relay: false,
  },
  [CHANNEL.IPRO]: {
    label: 'iPro',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.IRENT]: {
    label: 'iRent',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.JANIIS]: {
    label: 'Janiis',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.KIGO]: {
    label: 'Kigo',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.KIGO_PRO]: {
    label: 'Kigo Pro',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.KROSS_BOOKING]: {
    label: 'Kross Booking',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.LIGHTMAKER]: {
    label: 'Lightmaker',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.LIVEREZ]: {
    label: 'Liverez',
    pms: true,
    canPush: [
    ],
    canPull: [
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.LIVEREZ_DIRECT]: {
    label: 'Liverez Direct',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.RESERVATION,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: true,
  },
  [CHANNEL.LODGABLE]: {
    label: 'Lodgable',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.GUEST,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
    ],
    relay: false,
  },
  [CHANNEL.LODGIFY]: {
    label: 'Lodgify',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.MONTHLY_PRICE,
      DATA_TYPE.RESERVATION,
      DATA_TYPE.WEEKLY_PRICE,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.GUEST,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: true,
  },
  [CHANNEL.LODGIX]: {
    label: 'Lodgix',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },  
  [CHANNEL.LOGGIA]: {
    label: 'Loggia',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.LISTINGS_IN_PARALLEL,
    ],
    relay: false,
  },
  [CHANNEL.MEWS]: {
    label: 'Mews',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.RESERVATION,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.MYVR]: {
    label: 'MyVR',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.MONTHLY_PRICE,
      DATA_TYPE.WEEKLY_PRICE,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.NIIDO]: {
    label: 'Niido',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.OCTORATE]: {
    label: 'Octorate',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.OWNERREZ]: {
    label: 'OwnerRez',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.REAL_TIME_RENTAL]: {
    label: 'RealTimeRental',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.RENTALREADY]: {
    label: 'RentalReady',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.RENTALS_UNITED]: {
    label: 'Rentals United',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.RENTALWISE]: {
    label: 'RentalWise',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.RMS]: {
    label: 'RMS',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.SECRA]: {
    label: 'Secra',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_FOR_LISTING,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.SMOOBU]: {
    label: 'Smoobu',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.STAYDUVET]: {
    label: 'Stay Duvet',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.STREAMLINE]: {
    label: 'Streamline',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.RESERVATION,
      DATA_TYPE.RESERVATION_TO_BOOK,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.GUEST,
      DATA_TYPE.QUOTE,
      DATA_TYPE.REVIEW,
    ],
    relay: true,
  },
  [CHANNEL.SUPERCONTROL]: {
    label: 'Supercontrol',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
    ],
    relay: false,
  },
  [CHANNEL.TOKEET]: {
    label: 'Tokeet',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.TOWNEBANK]: {
    label: 'Townebank',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.TRACK]: {
    label: 'Track',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.MONTHLY_PRICE,
      DATA_TYPE.RESERVATION_TO_BOOK,
      DATA_TYPE.WEEKLY_PRICE,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.LISTINGS_IN_PARALLEL,
      DATA_TYPE.QUOTE,
    ],
    relay: false,
  },
  [CHANNEL.TRAVELMOB]: {
    label: 'Travelmob',
    pms: false,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.TRIPADVISOR]: {
    label: 'Tripadvisor',
    pms: false,
    canPush: [
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.ULTUS]: {
    label: 'Ultus',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.UPLISTING]: {
    label: 'Uplisting',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.VILLAS365]: {
    label: '365Villas',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.EXTRA_GUEST_FEE_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.MONTHLY_PRICE,
      DATA_TYPE.WEEKLY_PRICE,
    ],
    canPull: [
      DATA_TYPE.CANCELED_AT,
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.VRBO]: {
    label: 'VRBO',
    pms: false,
    canPush: [
      DATA_TYPE.AVAILABILITY,
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.GUEST,
      DATA_TYPE.ICAL,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.VREASY]: {
    label: 'Vreasy',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.VRM]: {
    label: 'VRM',
    pms: true,
    canPush: [
      DATA_TYPE.CHECKIN_DAYS_PER_DAY,
      DATA_TYPE.CHECKOUT_DAYS_PER_DAY,
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.FREQUENT_AVAILABILITY,
      DATA_TYPE.LISTINGS_IN_PARALLEL,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    relay: false,
  },
  [CHANNEL.ZEEVOU]: {
    label: 'Zeevou',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
    ],
    relay: false,
  },
  [CHANNEL.ZEEVOU_DIRECT]: {
    label: 'Zeevou Direct',
    pms: true,
    canPush: [
      DATA_TYPE.DAILY_PRICE,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_PER_DAY,
    ],
    canPull: [
      DATA_TYPE.CHECKIN_DAYS_FOR_LISTING,
      DATA_TYPE.CHECKOUT_DAYS_FOR_LISTING,
      DATA_TYPE.MAX_STAY_FOR_LISTING,
      DATA_TYPE.MAX_STAY_PER_DAY,
      DATA_TYPE.MIN_STAY_FOR_LISTING,
      DATA_TYPE.MIN_STAY_PER_DAY,
      DATA_TYPE.SOURCE_CHANNEL,
    ],
    relay: false,
  },
};
