export default {
  initialize(applicationInstance) {
    applicationInstance.inject('route', 'bpStore', 'service:bp-store');
    applicationInstance.inject('model', 'bpStore', 'service:bp-store');
    applicationInstance.inject('controller', 'bpStore', 'service:bp-store');

    // Hack to ensure we can add the container to models initialized through
    // the store. This is required when using injected properties (e.g. alerts)
    // on models.
    const store = applicationInstance.lookup('service:bp-store');
    store.container = applicationInstance;
  },
};
