import { helper } from '@ember/component/helper';

// Strip subdomain
const websiteDomain = window.location.host.startsWith('app.')
  ? window.location.host.substr(4)
  : window.location.host;

export default helper(function ([path] = ['/']) {
  return `https://${websiteDomain}/${path.replace(/\//, '')}`;
});
