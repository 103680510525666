import { CHANNEL, CHANNEL_CONFIG, DATA_TYPE } from './channel_configuration';

import ENV from 'appkit/config/environment';

const SUPPORTED_CHANNELS = [
  CHANNEL.AIRBNB,
  CHANNEL.VRBO,
  CHANNEL.GUESTY,
  CHANNEL.HOSTAWAY,
  CHANNEL.MYVR,
  CHANNEL.BOOKINGSYNC,
  CHANNEL.KIGO,
  CHANNEL.SUPERCONTROL,
  CHANNEL.HOSTFULLY,
  CHANNEL.VREASY,
  CHANNEL.SMOOBU,
  CHANNEL.ZEEVOU,
  CHANNEL.LODGIX,
  CHANNEL.BOOKING_CONNECT,
  CHANNEL.OWNERREZ,
  CHANNEL.DIRECT,
  CHANNEL.HOSTIFY,
  CHANNEL.LIGHTMAKER,
  CHANNEL.SECRA,
  CHANNEL.FANTASTICSTAY,
  CHANNEL.IPRO,
  CHANNEL.UPLISTING,
  CHANNEL.LODGIFY,
  CHANNEL.ICNEA,
  CHANNEL.IGMS,
  CHANNEL.AVANTIO,
  CHANNEL.BEDS24,
  CHANNEL.HOMHERO,
  CHANNEL.GFH,
  CHANNEL.TOKEET,
  CHANNEL.RMS,
  CHANNEL.REAL_TIME_RENTAL,
];

const ASSISTED_CHANNELS = [
  CHANNEL.ESCAPIA,
  CHANNEL.STREAMLINE,
  CHANNEL.BAREFOOT_DIRECT,
  CHANNEL.CIIRUS,
  CHANNEL.VRM,
  CHANNEL.TRACK,
  CHANNEL.LIVEREZ,
  CHANNEL.JANIIS,
  CHANNEL.BRIGHTSIDE,
  CHANNEL.KROSS_BOOKING,
];

const CHANNEL_LABELS = {
  [CHANNEL.BAREFOOT_DIRECT]: 'Barefoot Direct',
  [CHANNEL.BOOKINGSYNC]: 'BookingSync',
  [CHANNEL.CIIRUS]: 'CiiRUS',
  [CHANNEL.ESCAPIA]: 'Escapia',
  [CHANNEL.GUESTY]: 'Guesty',
  [CHANNEL.HOSTAWAY]: 'Hostaway',
  [CHANNEL.HOSTFULLY]: 'Hostfully',
  [CHANNEL.KIGO]: 'Kigo',
  [CHANNEL.LIVEREZ]: 'Liverez',
  [CHANNEL.MYVR]: 'MyVR',
  [CHANNEL.SMOOBU]: 'Smoobu',
  [CHANNEL.SUPERCONTROL]: 'Supercontrol',
  [CHANNEL.STREAMLINE]: 'Streamline',
  [CHANNEL.TRACK]: 'Track',
  [CHANNEL.HOSTIFY]: 'Hostify',
  [CHANNEL.FANTASTICSTAY]: 'FantasticStay',
  [CHANNEL.KROSS_BOOKING]: 'KrossBooking',
  [CHANNEL.RENTALREADY]: 'RentalReady',
  [CHANNEL.CLOUDBEDS]: 'Cloudbeds',
  [CHANNEL.GFH]: 'Guesty For Hosts',
};

const MIGRATION_CHANNELS = {
  homeaway_web: 'vrbo',
};

export class ChannelUtil {
  static getSupported() {
    return SUPPORTED_CHANNELS;
  }
  static getAssisted() {
    return ASSISTED_CHANNELS;
  }

  static getChannelLabel(channel) {
    return CHANNEL_LABELS[channel] ?? channel;
  }

  static getChannelsThatSupport(property, configuration) {
    return Object.keys(CHANNEL_CONFIG).filter(code => {
      const conf = ChannelUtil.getConf(code);
      return conf[property]?.includes(configuration);
    });
  }

  static getRelayAvailabilitySupported() {
    return ChannelUtil.getChannelsThatSupport(
      'canPull',
      DATA_TYPE.FREQUENT_AVAILABILITY
    );
  }

  static getRelayReservationSupported() {
    return ChannelUtil.getChannelsThatSupport('canPush', DATA_TYPE.RESERVATION);
  }

  static getRelayContentSyncingSupported() {
    return [
      ...ChannelUtil.getChannelsThatSupport('canPush', DATA_TYPE.CONTENT),
      ...ChannelUtil.getChannelsThatSupport('canPull', DATA_TYPE.CONTENT),
    ];
  }

  static getConf(code) {
    return CHANNEL_CONFIG[code];
  }

  static isPms(code) {
    let conf = ChannelUtil.getConf(code);
    if (conf) {
      return conf.pms;
    }
    return null;
  }

  static supportsRelay(code) {
    let conf = ChannelUtil.getConf(code);
    if (conf) {
      return conf.relay;
    }
    return null;
  }

  static getLabel(code) {
    let conf = ChannelUtil.getConf(code);
    if (conf) {
      return conf.label;
    }
    return null;
  }

  static getBookingSyncOAuthRedirectUrl() {
    let base_url = 'https://www.bookingsync.com/oauth/authorize';
    let client_id = ENV.APP.BOOKINGSYNC_API_CLIENT_ID;
    let redirect_uri = ENV.APP.BOOKINGSYNC_OAUTH_REDIRECT_URI;
    let response_type = 'code';
    let scope = [
      'public',
      'rentals_read',
      'rentals_write',
      'rates_read',
      'rates_write',
      'bookings_read',
    ];
    let params = [
      'client_id=' + client_id,
      'redirect_uri=' + redirect_uri,
      'response_type=' + response_type,
      'scope=' + scope.join('%20'),
    ];

    return base_url + '?' + params.join('&');
  }
  static getIGMSOAuthRedirectUrl() {
    let base_url = 'https://www.igms.com/app/auth.html';
    let client_id = ENV.APP.IGMS_API_CLIENT_ID;
    let redirect_uri = ENV.APP.IGMS_OAUTH_REDIRECT_URI;
    let scope = ['pricing-management'];
    let params = [
      `client_id=${client_id}`,
      `redirect_uri=${redirect_uri}`,
      `scope=${scope.join('%20')}`,
    ];

    return `${base_url}?${params.join('&')}`;
  }

  static getMyVROAuthRedirectUrl() {
    let base_url = 'https://myvr.com/connect/oauth/auth';
    let client_id = ENV.APP.MYVR_API_CLIENT_ID;
    let redirect_uri = ENV.APP.MYVR_OAUTH_REDIRECT_URI;
    let response_type = 'code';
    let scope = [
      'property_read',
      'calendar_read',
      'rate_read_write',
      'reservation_read',
      'inquiry_read',
    ];
    let params = [
      'client_id=' + client_id,
      'redirect_uri=' + redirect_uri,
      'response_type=' + response_type,
      'scope=' + scope.join(','),
    ];

    return base_url + '?' + params.join('&');
  }

  static getOctorateOAuthRedirectUrl() {
    const base_url = 'https://admin.octorate.com/octobook/identity/oauth.xhtml';
    const params = {
      client_id: ENV.APP.OCTORATE_API_CLIENT_ID,
      redirect_uri: ENV.APP.OCTORATE_OAUTH_REDIRECT_URI,
      state: Math.random().toString(36),
    };
    // Return full URL with URL-encoded params values
    return `${base_url}?${Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key])}`)
      .join('&')}`;
  }

  static getTokeetOAuthRedirectUrl() {
    let base_url = 'https://papi.tokeet.com/dialog/';
    let client_id = ENV.APP.TOKEET_OAUTH_APP_CLIENT_ID;
    let redirect_uri = ENV.APP.TOKEET_OAUTH_REDIRECT_URI;
    let response_type = 'code';
    let scope = ['group-rates', 'inquiries', 'rates', 'rentals', 'images'];
    let params = [
      'client_id=' + client_id,
      'redirect_uri=' + redirect_uri,
      'response_type=' + response_type,
      'scope=' + scope.join(','),
    ];

    return base_url + '?' + params.join('&');
  }

  static getOwnerRezOAuthRedirectUrl() {
    let client_id = ENV.APP.OWNERREZ_OAUTH_APP_CLIENT_ID;
    return `https://secure.ownerreservations.com/oauth/authorize?response_type=code&client_id=${client_id}`;
  }

  static getRentalReadyOAuthRedirectUrl() {
    let base_url = 'https://guestready.rentalready.io/o/authorize/';
    let client_id = ENV.APP.RENTALREADY_API_CLIENT_ID;
    let redirect_uri = ENV.APP.RENTALREADY_OAUTH_REDIRECT_URI;
    let response_type = 'code';
    let scope = [
      'read',
      'write',
      'reservations:read',
      'reviews:read',
      'pricing:read',
      'pricing:write',
      'users:read',
      'calendar:read',
      'calendar:write',
      'rentals:read',
    ];
    let params = [
      'client_id=' + client_id,
      'redirect_uri=' + redirect_uri,
      'response_type=' + response_type,
      'scope=' + scope.join(' '),
    ];

    return base_url + '?' + params.join('&');
  }

  static getCloudbedsOAuthRedirectUrl() {
    let base_url = 'https://hotels.cloudbeds.com/api/v1.1/oauth';
    let client_id = ENV.APP.CLOUDBEDS_API_CLIENT_ID;
    let redirect_uri = ENV.APP.CLOUDBEDS_OAUTH_REDIRECT_URI;
    let response_type = 'code';
    let scope = [
      'read:adjustment',
      'read:item',
      'read:communication',
      'read:currency',
      'read:customFields',
      'read:guest',
      'read:hotel',
      'read:houseAccount',
      'read:housekeeping',
      'read:rate',
      'write:rate',
      'read:reservation',
      'read:room',
      'read:taxesAndFees',
      'read:user',
      'read:roomblock',
      'read:allotmentBlock',
    ];
    let params = [
      'client_id=' + client_id,
      'redirect_uri=' + redirect_uri,
      'response_type=' + response_type,
      'scope=' + scope.join('+'),
    ];

    return base_url + '?' + params.join('&');
  }

  static getHostfullyV3OAuthRedirectUrl() {
    let base_url = 'https://api.hostfully.com/api/auth/oauth/authorize';
    let client_id = ENV.APP.HOSTFULLY_V3_API_CLIENT_ID;
    let redirect_uri = ENV.APP.HOSTFULLY_V3_OAUTH_REDIRECT_URI;
    let state = 'BEYOND';
    let scope = 'FULL';
    let grantType = 'REFRESH_TOKEN';
    let params = [
      'client_id=' + client_id,
      'redirect_uri=' + redirect_uri,
      'state=' + state,
      'scope=' + scope,
      'grantType=' + grantType,
    ];

    return base_url + '?' + params.join('&');
  }

  static hasChannelMigration(channel) {
    return Object.prototype.hasOwnProperty.call(MIGRATION_CHANNELS, channel);
  }

  static getChannelMigration(channel) {
    return MIGRATION_CHANNELS[channel];
  }
}
