import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';
import { getSettingsUC, RelayAPISettingsRepository } from 'appkit/lib/relay/settings';

export default class RelayCustomizeSettingsRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service featureFlag;
  @service ajax;
  @service intl;
  featureFlags;
  APIProvider = new RelayAPISettingsRepository(this.ajax);

  title() {
    const title = 'Relay / Settings';
    this.globalNav.setTitle(title);
    return title;
  }

  async beforeModel() {
    this.featureFlags = await this.resolveFeatureFlags();
    this.guardRoute();
  }

  async model() {
    const { accounts } = this.modelFor('dashboard.relay');
    const settings = [];

    for (const account of accounts) {
      const accountSettings = await getSettingsUC(
        this.APIProvider,
        account,
        this.intl,
        this.featureFlags
      );
      if (accountSettings) settings.push(accountSettings);
    }

    // Add unhappy path -> Error page
    return {
      settings,
    };
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('settingsRepository', this.APIProvider);
  }

  guardRoute() {
    if (!this.currentUser.isStaff) {
      console.warn('Route only accessible to Staff members. Redirecting to dashboard');
      this.transitionTo('dashboard.relay');
    }
    if (!this.featureFlags.flag_RELAY_SETTINGS_PAGE_ENABLED) {
      console.warn(
        'User is not allowed to enter this section. Add the target to the feature-flag.'
      );
      this.transitionTo('dashboard.relay');
    }
  }

  async resolveFeatureFlags() {
    const flag_FEATURE_MOCKS_ENABLED = await this.featureFlag.evaluate(
      'relay-feature-mocks',
      false
    );

    const flag_RELAY_SETTINGS_PAGE_ENABLED = await this.featureFlag.evaluate(
      'relay-settings-page',
      false
    );

    const flag_RELAY_CONTENT_SYNC_ENABLED = await this.featureFlag.evaluate(
      'relay-content-sync-enabled',
      false
    );

    return {
      flag_FEATURE_MOCKS_ENABLED,
      flag_RELAY_SETTINGS_PAGE_ENABLED,
      flag_RELAY_CONTENT_SYNC_ENABLED,
    };
  }
}
