import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';
import moment from 'moment';
import {
  buildWeeklyCalendar,
  getExtraCalendarDays,
  getAllPMSAndDirectChannels,
} from 'appkit/lib/relay/adapters';
import { getReservationsInDateRange } from 'appkit/lib/relay/utils';
import { isDCDoubleBooking } from '../../../../lib/relay/utils/rel-reservation-utils';

export default class RelayListingDetailRoute extends AuthRoute {
  @service('global-nav') globalNav;
  @service('relay/reservations-service') reservationsService;
  @service intl;
  @service featureFlag;

  title() {
    const title = `Relay / ${this.context.listing.name} / Reservations`;
    this.globalNav.setTitle(title);
    return title;
  }

  /** Pre-fetch a recent log for Reservations and a particular listing
   */
  async model() {
    //1. GET master listing details ------------------------------------------------------
    // - Has the PMS Reservations and calendar without details, plus extra info that we use)
    const { listing } = this.modelFor('dashboard.relay.merged-listing-detail');

    const listingDetails = await this.reservationsService.fetchListingDetails(
      listing.id
    );
    const { reservations: PMSReservations, calendar } = listingDetails;

    //2. GET Direct Channels reservations ------------------------------------------------
    const { PMSList, directChannels } = getAllPMSAndDirectChannels([listingDetails]);

    const channelReservationsRes = await Promise.all(
      directChannels.map(chL =>
        this.reservationsService.fetchChannelListingReservations({
          channelListingId: chL.id,
          pageSize: 365,
          checkinDate: moment().format('YYYY-MM-DD'),
          ajax: this.ajax,
        })
      )
    );
    const channelReservationsData = channelReservationsRes.map(x => x.data).flat();
    const dcReservationPushLog = channelReservationsRes
      .map(x => x.included || [])
      .flat();

    //3. Adapt to view models & weekly calendar generation -----------------------------
    const dateStart = Date.now();
    const dcDoubleBookingsIds = channelReservationsData
      .filter(dcRes => isDCDoubleBooking(dcRes, listingDetails, dcReservationPushLog))
      .map(dcRes => dcRes.id);

    const dailyPMSCalendar = pricingDailyCalendarToPMSCalendar(
      dateStart,
      calendar,
      PMSReservations,
      channelReservationsData,
      dcDoubleBookingsIds
    );

    const adaptedDCReservations = channelReservationsRes
      .map(response => {
        const reservationAdapter = this.reservationsService.getIntegrationReservationsAdapter();
        return reservationAdapter(response, listingDetails, dcReservationPushLog);
      })
      .flat();

    const model = {
      listing,
      reservations: adaptedDCReservations,
      totalReservations: PMSReservations.length,
      calendar: buildWeeklyCalendar(dailyPMSCalendar),
      calendarLegend: getPMSCalendarLegend(),
      pmsChListingId: PMSList[0].id,
      _listingDetails: listingDetails,
      channelListings: {
        PMS: PMSList[0],
        directChannels,
      },
    };
    return model;
  }
}

/* ---------------------------------------------------------------------------------
  ADAPTERS FOR PMS Calendar Reservations
 -----------------------------------------------------------------------------------*/

/**
 * Returns the cells displayed below the calendar, explaining the meaning of the color
 * assignations for the calendar days
 */
function getPMSCalendarLegend() {
  return [
    { id: 'DC', name: 'DC', squareClass: 'bg-[#9be9a8]' },
    { id: 'PMS', name: 'PMS', squareClass: 'bg-[#30a14e]' },
    { id: 'Synced', name: 'Synced', squareClass: 'bg-brand-400' },
    { id: 'Double', name: 'Double', squareClass: 'bg-warning-400 bg-opacity-25' },
    { id: 'Owner', name: 'Owner', squareClass: 'bg-gray-400' },
  ];
}

function PMSCalendarAvailabilityAdapter(
  availability,
  reservation,
  hasDCReservations,
  isDoubleBooking
) {
  // There is an edge case in pricing calendar, where we have a date marked as booked in calendar,
  // but we don´t got reservation details yet
  if (reservation?.isOwner) {
    return { color: 'bg-gray-400', reference: reservation?.reference };
  }
  if (hasDCReservations && !reservation) {
    return { color: 'bg-[#9be9a8]' };
  }
  if (hasDCReservations && reservation && !isDoubleBooking) {
    return { color: 'bg-brand-400', reference: reservation?.reference };
  }
  if (isDoubleBooking) {
    return { color: 'bg-warning-400 bg-opacity-25', reference: reservation?.reference };
  }
  // PMS
  switch (availability) {
    case 'error':
      return { color: 'bg-[#e26565]' };
    case 'available': {
      if (hasDCReservations) return { color: 'bg-[#9be9a8]' };
      return { color: 'bg-gray-200 ' };
    }
    case 'booked':
      return { color: 'bg-[#30a14e]', reference: reservation?.reference };
    case 'blocked':
      return { color: 'bg-gray-600' };
  }
}

function buildReservationPMSCalendarDay(
  pricingCalendarDay,
  dcReservations,
  PMSReservations,
  dcDoubleBookingsIds
) {
  const { date, reservationId, availability } = pricingCalendarDay;

  const PMSReservation = PMSReservations.find(r => r.id === reservationId);

  const channelReservationsInRange = getReservationsInDateRange(
    dcReservations,
    PMSReservation?.checkinDate || date,
    PMSReservation?.checkoutDate || date
  );
  const channelReservationsInRangeCount = channelReservationsInRange.length;

  const isCheckinDate =
    PMSReservation && moment(date).isSame(PMSReservation.checkinDate);

  const _isDoubleBlooking = channelReservationsInRange.some(dcRes =>
    dcDoubleBookingsIds.includes(dcRes.id)
  );

  const stayLength = PMSReservation
    ? moment(PMSReservation.checkoutDate).diff(PMSReservation.checkinDate, 'days')
    : null;

  return {
    date: date,
    availability,
    reservation: PMSReservation ? { ...PMSReservation } : null,
    // View styling
    ...PMSCalendarAvailabilityAdapter(
      availability,
      PMSReservation,
      channelReservationsInRangeCount,
      _isDoubleBlooking
    ),
    isCheckinDate,
    _isDoubleBlooking,
    stayLength,
    channelReservationsInRangeCount,
  };
}

function getBlockeDay(date) {
  return {
    date,
    availability: 'blocked',
    reservation: null,
    ...PMSCalendarAvailabilityAdapter('blocked'),
  };
}

function pricingDailyCalendarToPMSCalendar(
  dateStart,
  listingCalendar,
  PMSReservations,
  dcReservations,
  dcDoubleBookingsIds
) {
  const momentDate = moment(dateStart);
  const listingCalendarStartDate = moment(listingCalendar[0].date);
  const listingCalendarEndDate = moment(
    listingCalendar[listingCalendar.length - 1].date
  );

  const calendarData = listingCalendar
    .filter(day => moment(day.date).isAfter(momentDate))
    .map(day =>
      buildReservationPMSCalendarDay(
        day,
        dcReservations,
        PMSReservations,
        dcDoubleBookingsIds
      )
    );

  // Need to fill 'blocked' days from start of the week to Tomorrow (as the Pricing calendar goes from Tomorrow forward)
  const pastDays = listingCalendarStartDate.diff(momentDate.startOf('week'), 'days');
  const extraDays = listingCalendarEndDate
    .endOf('week')
    .diff(listingCalendarEndDate, 'days');

  return [
    ...getExtraCalendarDays(momentDate, pastDays).map(getBlockeDay),
    ...calendarData,
    ...getExtraCalendarDays(momentDate, extraDays, false).map(getBlockeDay),
  ];
}
