import AuthRoute from 'appkit/routes/auth';
import EmberObject from '@ember/object';

export default class SignalDashboardRoute extends AuthRoute {
  async model() {
    const dashboardModel = this.modelFor('dashboard');

    return EmberObject.create({
      user: dashboardModel.user,
    });
  }

  async afterModel(model) {
    if (!model.user.signalCustomerId) this.transitionTo('/dashboard/signal/welcome');
  }
}
