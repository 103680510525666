import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import moment from 'moment';
import { getOwner } from '@ember/application';

export function getMonthlyDay(day) {
  const dayWithMonthly = {
    factors: day.monthlyFactors || [],
    price: day.monthlyPrice,
    priceUser: day.monthlyPriceUser,
    priceModeled: day.monthlyPriceModeled,
    minPriceUser: day.monthlyMinPriceUser,
    maxPriceUser: day.monthlyMaxPriceUser,
    minStayUser: null,
    maxStayUser: null,
  };

  return Object.assign({}, day, dayWithMonthly);
}

export function createListingDate(day, listing, bpStore) {
  let ld = bpStore.createRecord('listingDate', day);
  ld.set('listing', listing);

  let reservations = [];

  if (ld.get('reservationIds')) {
    reservations = ld
      .get('reservationIds')
      .map(reservationId => bpStore.peekRecord('reservation', reservationId));
  }

  ld.set('reservations', reservations);

  return ld;
}

function getMonthlySeasons(data, listing) {
  let seasonsData = data || [];

  let monthlySeasons = listing.set(
    'monthlySeasons',
    seasonsData.map((season, index) => {
      return EmberObject.create({
        id: index,
        startDate: moment(season.startDate),
        endDate: moment(season.endDate),
        monthlyEnabled: true,
      });
    })
  );

  return monthlySeasons;
}

@classic
export default class ListingRoute extends AuthRoute {
  @service featureFlag;
  @service listingActions;
  @service alert;
  @service intl;
  @service router;
  @service embed;

  titleToken = null;
  monthlyFeatureFlagEnabled = false;
  fromDashboard = false;

  async afterModel(model) {
    // this.templateName = 'dashboard.pricing.listing';
    this.controllerName = 'listing';

    if (!model.listing.onProgram) {
      this.alert.error(
        this.intl.t('pricing.listing.alertToOffProgramListing', {
          listingTitle: model.listing.title,
        }),
        { timeout: 10000 }
      );
      this.transitionTo('dashboard.pricing.index');
    }
  }

  async beforeModel(transition) {
    this.monthlyFeatureFlagEnabled = await this.featureFlag.evaluate(
      'monthly-pricing-v-1',
      false
    );

    this.fromDashboard = transition.from?.name === 'dashboard.pricing.index.index';
  }

  // Disable listing level template and render subroute only
  embedRenderSubroute(transition) {
    transition.followRedirects().then(() => {
      if (this.embed.shouldDelegateSubRoute(this.router.currentRouteName)) {
        this.templateName = 'empty';
      } else {
        this.templateName = 'dashboard.pricing.listing';
      }
      this.render();
    });
  }

  @action
  willTransition(transition) {
    this.embedRenderSubroute(transition);
    this.controller.set('fromDashboard', false);
    return true;
  }

  activate(transition) {
    this.embedRenderSubroute(transition);
    const listingController = getOwner(this).lookup('controller:listing');
    if (!listingController.calendarEditExpanded) {
      listingController.toggleCalendarEditExpanded();
    }
  }

  setupController(controller, model) {
    controller.set('fromDashboard', this.fromDashboard);
    controller.set('monthlyFeatureFlagEnabled', this.monthlyFeatureFlagEnabled);

    // Reset listing notes temporary state
    controller.set('notes', '');
    controller.set('showEditNotes', false);

    controller.set('model', model);
    controller.set(
      'links',
      EmberObject.create({
        calendar: 'dashboard.pricing.listing.calendar',
        chart: 'dashboard.pricing.listing.chart',
        stats: 'dashboard.pricing.listing.stats',
        marketData: 'dashboard.pricing.listing.market-data',
        nearby: 'dashboard.pricing.listing.nearby',
        customize: 'dashboard.pricing.listing.customize',
      })
    );

    this.listingActions.setup({
      listing: model.listing,
      calendar: model.calendar,
      monthlyCalendar: model.monthlyCalendar,
    });

    return controller;
  }

  resetController(controller, isExiting, _transition) {
    if (isExiting) {
      controller.set('showMonthlyCalendar', false);
    }
  }

  model(params) {
    const dashboardModel = this.modelFor('dashboard');

    return this.ajax._get(`/api/listings/${params.id}`).then(data => {
      set(this, 'titleToken', data.listing.title);
      let listing = this.bpStore.createRecord('listing', data.listing);
      let params = window.hashParams || {};
      if (params.basePrice) {
        listing.set('basePrice', Number(params.basePrice));
        delete params.basePrice;
      }
      if (params.minPrice) {
        listing.set('minPrice', Number(params.minPrice));
        delete params.minPrice;
      }

      listing.set('permissions', data.permissions);
      listing.set('pricedAt', data.pricedAt);
      listing.set('reservations', data.reservations);

      listing.set(
        'channelListings',
        data.channelListings.map(cl => {
          let obj = this.bpStore.createRecord('channelListing', cl);
          obj.set('masterListing', listing);
          // Should always have the managed account loaded since this is a nested route
          // of the dashboard.
          obj.set(
            'managedAccount',
            this.bpStore.peekRecord('account', cl.managedAccountId)
          );
          return obj;
        })
      );

      let primary = this.bpStore.peekRecord(
        'channelListing',
        listing.get('primaryChannelListingId')
      );
      listing.set('primaryChannelListing', primary);

      let reservations = data.reservations.map(reservation =>
        this.bpStore.createRecord('reservation', reservation)
      );

      let calendar = listing.set(
        'calendar',
        data.calendar.map(day => {
          return createListingDate(day, listing, this.bpStore);
        })
      );

      let monthlyCalendar = listing.set(
        'monthlyCalendar',
        data.calendar.map(day => {
          const monthlyDay = getMonthlyDay(day);
          return createListingDate(monthlyDay, listing, this.bpStore);
        })
      );

      return EmberObject.create({
        listing: listing,
        listings: dashboardModel.listings,
        calendar: calendar,
        monthlyCalendar: monthlyCalendar,
        reservations: reservations,
        managedAccounts: data.managedAccounts,
        monthlySeasons: getMonthlySeasons(data.monthlySeasons, listing),
      });
    });
  }
  @action
  toggleForm() {
    return;
  }
}
