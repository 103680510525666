import Ajax from 'appkit/services/ajax';

export default {
  name: 'ajax',
  after: 'initialize-models',

  initialize: function (app) {
    app.register('ajax:main', Ajax, { singleton: true });

    app.inject('route', 'ajax', 'ajax:main');
    app.inject('beyondmodel', 'ajax', 'ajax:main');
    app.inject('controller', 'ajax', 'ajax:main');
    app.inject('component', 'ajax', 'ajax:main');
  },
};
