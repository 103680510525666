import Transform from '@ember-data/serializer/transform';

// Generic array transform that defaults to empty array
export default class ArrayTransform extends Transform {
  deserialize(serialized) {
    return serialized ?? [];
  }

  serialize(deserialized) {
    return deserialized;
  }
}
