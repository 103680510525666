import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { action } from '@ember/object';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';

@classic
@classNames('bulk-updates-error-messages')
export default class AppBulkEditErrorMessage extends Component {
  @tracked
  errorMessageDetails = false;

  @action
  toggleErrorMessageDetails() {
    if (this.errorMessageDetails) {
      this.errorMessageDetails = false;
    } else {
      this.errorMessageDetails = true;
    }
  }

  @action
  removingAllSelectedListings(arg) {
    this.removeAllSelectedListings(arg);
  }

  @action
  addingSelectedListing(listing, option) {
    this.addSelectedListing(listing, option);
  }
}
