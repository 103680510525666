import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { CHANNEL } from 'appkit/lib/channel_configuration';
import { inject as service } from '@ember/service';
import { getCalculatePosition } from '../../../../../components/bp/select/utils';
import selectBaseTheme from '../../../../../components/bp/select/base.theme';
import getThemeForOptions, {
  getClassesForTheme,
} from '../../../../../components/bp/theme';
import classNames from 'classnames';

const tabs = [
  {
    key: 'overview',
    value: 0,
    charts: [
      {
        key: 'totalRevenue',
        component: 'insights/charts/volume/total',
      },
      {
        key: 'paidOccupancy',
        component: 'insights/charts/occupancy',
      },
      {
        key: 'adr',
        component: 'insights/charts/adr',
      },
      {
        key: 'revPAN',
        component: 'insights/charts/rev-pan',
      },
      {
        key: 'paidOccupancyPAN',
        component: 'insights/charts/available-occupancy',
      },
      {
        key: 'medianBookingLeadTimeByCheckinDate',
        component: 'insights/charts/median-booking-lead-time',
      },
      {
        key: 'cancellationsByStayDate',
        component: 'insights/charts/cancellations/by-stay-date',
      },
    ],
  },
  {
    key: 'bookingDate',
    value: 1,
    charts: [
      {
        key: 'revenueByBookingDate',
        component: 'insights/charts/volume/by-booked-date',
      },
      {
        key: 'reservationsByBookingDate',
        component: 'insights/charts/bookings/by-booked-date',
      },
      {
        key: 'cancellationsByBookedDate',
        component: 'insights/charts/cancellations/by-booked-date',
      },
      {
        key: 'cancellationsByCanceledDate',
        component: 'insights/charts/cancellations/by-canceled-date',
      },
    ],
  },
  {
    key: 'checkinDate',
    value: 2,
    charts: [
      {
        key: 'revenueByCheckinDate',
        component: 'insights/charts/volume/by-stay-date',
      },
      {
        key: 'reservationsByCheckinDate',
        component: 'insights/charts/bookings/by-stay-date',
      },
      {
        key: 'medianStayLengthByCheckinDate',
        component: 'insights/charts/median-stay-length',
      },
    ],
  },
  {
    key: 'perListing',
    value: 3,
    charts: [
      {
        key: 'listingCount',
        component: 'insights/charts/listing-count',
      },
      {
        key: 'reservationsByBookingDatePerListing',
        component: 'insights/charts/bookings/by-booked-date-per-listing',
      },
      {
        key: 'totalRevenuePerListing',
        component: 'insights/charts/rev-par',
      },
    ],
  },
];

@classic
export default class IndexController extends Controller {
  @service featureFlag;
  @service staffView;
  @service router;
  @service intl;
  @tracked selectedTab = 0;
  @tracked showSidebar = false;
  @tracked tabs = tabs;
  @tracked dropdownArrowClasses;
  @tracked showLensTip = true;
  @tracked selectedRoute = this.currentRoute;
  @tracked dashboardEnabled = false;
  @tracked ownerDashboardEnabled = false;

  get selectedTabCharts() {
    return this.tabs.find(t => t.value === this.selectedTab).charts ?? [];
  }

  get isStaff() {
    return this.staffView.isStaffView;
  }

  @computed('model.accounts')
  get _userChannels() {
    let managedAccounts = this.get('model.accounts');
    let channelNames = managedAccounts.map(ma => ma.channel);
    return Array.from(new Set(channelNames));
  }

  @computed
  get routes() {
    const routes = [
      {
        route: 'dashboard.insights.stats',
        value: 'dashboard',
        label: this.intl.t('insights.dashboard'),
        new: this.intl.t('common.new'),
      },

      {
        route: 'dashboard.insights.comp-sets.listings',
        value: 'listing-view',
        label: this.intl.t('insights.compSets.navListing'),
      },
      {
        route: 'dashboard.insights.comp-sets.sets',
        value: 'comp-sets',
        label: this.intl.t('insights.compSets.navComps'),
        new: this.intl.t('common.new'),
      },
      {
        route: 'dashboard.insights.portfolio.charts.index',
        value: 'chart-view',
        label: this.intl.t('insights.compSets.navChart'),
      },
    ];

    if (this.ownerDashboardEnabled) {
      routes.push({
        route: 'dashboard.insights.owner-dashboard',
        value: 'owner-dashboard',
        label: this.intl.t('insights.ownerDashboard.navOwnerDashboard'),
        new: this.intl.t('common.new'),
        flag: 'owners-report-react',
      });
    }
    return routes;
  }

  @computed
  get currentRoute() {
    const currentPath = this.router.currentRouteName;
    return this.routes.find(l => l.route === currentPath);
  }

  get hasManyServices() {
    return this._userChannels.length > 1;
  }

  get clientServices() {
    return this._userChannels.filter(e => ![CHANNEL.AIRBNB, CHANNEL.VRBO].includes(e))
      .length > 0
      ? 'PMS'
      : 'Airbnb';
  }

  get locale() {
    return this.get('intl.locale');
  }

  @tracked visibility = {};

  @action
  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  // Dropdown - Group By
  get dropdownClasses() {
    const theme = getThemeForOptions(selectBaseTheme);
    return getClassesForTheme(theme.dropdown);
  }

  calculatePosition = getCalculatePosition(({ classes }) => {
    this.dropdownArrowClasses = classNames(classes);
  });

  get supportsCancelAt() {
    let channelsSupportsCancelAt = [
      CHANNEL.AIRBNB,
      CHANNEL.ESCAPIA,
      CHANNEL.DIRECT,
      CHANNEL.FAKE,
      CHANNEL.GUESTY,
      CHANNEL.HOSTAWAY,
      CHANNEL.HOSTFULLY,
      CHANNEL.LIVEREZ_DIRECT,
      CHANNEL.TRACK,
      CHANNEL.VRM,
      CHANNEL.SUPERCONTROL,
    ];

    return this._userChannels.some(e => channelsSupportsCancelAt.includes(e));
  }

  get csvURL() {
    return `/api/insights/account-stats?token=${this.currentUser.token}`;
  }

  get displayError() {
    let errors = this.get('model.errors');
    return errors === undefined;
  }

  async init() {
    this.set('selectedBedrooms', []);
    this.set('selectedBedroomsDisplay', []);
    this.set('_timeframeCache', {});
    super.init(...arguments);

    this.ownerDashboardEnabled =
      (await this.featureFlag.evaluate('owners-report-react', false)) && this.isStaff;
  }

  _timeframeCache = null;

  @action
  isChartVisible(chartName, name) {
    return this.get(`visibility.${chartName.dasherize()}.${name.dasherize()}`);
  }

  @action
  onChartVisibilityChange(chartName, name, value) {
    const chart = chartName.dasherize();
    const legendName = name.dasherize();

    if (!Object.prototype.hasOwnProperty.call(this.visibility, chart)) {
      this.set(`visibility.${chart}`, {});
    }

    this.set(`visibility.${chart}.${legendName}`, value);
  }

  @action
  onCloseLensTip() {
    this.showLensTip = false;
  }

  @action
  print() {
    window.print();
  }

  @action
  changeRoute(option) {
    this.router.transitionTo(option.route);
  }
}
