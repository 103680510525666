import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ModalDialog\n  @containerClass='bg-white text-gray-800 border border-solid border-gray-200 rounded p-8'\n  @overlayClass='{{if this.styleOverlay 'bg-gray-500'}}'\n>\n  <div class='text-gray-800'>\n    {{t 'admin.beyondPricingSays'}}\n  </div>\n\n  <div class='text-gray-500 text-sm mt-6'>\n    <div>\n      {{t 'admin.deleteCredentialWarning' emails=this.emails}}\n    </div>\n    <div>\n      {{t 'admin.deleteCredentialInfo'}}\n    </div>\n  </div>\n\n  <div class='flex flex-row-reverse mt-4'>\n    <button\n      type='button'\n      class='btn btn-error bg px-6 py-1 ml-3'\n      {{on 'click' (fn @confirmDelete true)}}\n    >\n      {{t 'common.continue'}}\n    </button>\n    <button\n      type='button'\n      class='btn btn-gray-outline px-6 py-1 ml-3'\n      {{on 'click' (fn @confirmDelete false)}}\n    >\n      {{t 'common.cancel'}}\n    </button>\n  </div>\n</ModalDialog>", {"contents":"<ModalDialog\n  @containerClass='bg-white text-gray-800 border border-solid border-gray-200 rounded p-8'\n  @overlayClass='{{if this.styleOverlay 'bg-gray-500'}}'\n>\n  <div class='text-gray-800'>\n    {{t 'admin.beyondPricingSays'}}\n  </div>\n\n  <div class='text-gray-500 text-sm mt-6'>\n    <div>\n      {{t 'admin.deleteCredentialWarning' emails=this.emails}}\n    </div>\n    <div>\n      {{t 'admin.deleteCredentialInfo'}}\n    </div>\n  </div>\n\n  <div class='flex flex-row-reverse mt-4'>\n    <button\n      type='button'\n      class='btn btn-error bg px-6 py-1 ml-3'\n      {{on 'click' (fn @confirmDelete true)}}\n    >\n      {{t 'common.continue'}}\n    </button>\n    <button\n      type='button'\n      class='btn btn-gray-outline px-6 py-1 ml-3'\n      {{on 'click' (fn @confirmDelete false)}}\n    >\n      {{t 'common.cancel'}}\n    </button>\n  </div>\n</ModalDialog>","moduleName":"appkit/components/user-credentials/confirm-delete-modal.hbs","parseOptions":{"srcName":"appkit/components/user-credentials/confirm-delete-modal.hbs"}});
import Component from '@glimmer/component';

export default class ConfirmDeleteModalComponent extends Component {
  get emails() {
    const { credentials, selectedCredentials } = this.args;

    return credentials
      .filter(c => selectedCredentials.isSelected(c))
      .map(c => c.email)
      .sort()
      .join(', ');
  }
}
