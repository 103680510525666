import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import { getOwner } from '@ember/application';
import { later } from '@ember/runloop';

@classic
export default class IndexRoute extends AuthRoute {
  @service('list-control') listControl;

  beforeModel() {
    const bookingReviewController = getOwner(this).lookup(
      'controller:dashboard.pricing.index.booking-review.index'
    );
    bookingReviewController.set('showPriceHistoryTable', false);
  }

  async model(params) {
    this.set('listControl.viewingListingId', parseInt(params.id));
    const data = await this.ajax._get(`/api/listings/${params.id}/permissions`);
    let listing = this.bpStore.peekRecord('listing', parseInt(params.id));
    listing.set('permissions', data.permissions);

    const dashboardModel = this.modelFor('dashboard.pricing.index');
    const credential = dashboardModel.credential;
    const bookingReviewListings = dashboardModel.bookingReviewListings;
    const bookingReviews = dashboardModel.bookingReviews;

    return EmberObject.create({
      listing: listing,
      credential: credential,
      bookingReviewListings: bookingReviewListings,
      bookingReviews: bookingReviews,
    });
  }

  activate() {
    const bookingReviewController = getOwner(this).lookup(
      'controller:dashboard.pricing.index.booking-review.index'
    );
    later(
      this,
      function () {
        bookingReviewController.set('isVisible', true);
      },
      100
    );
  }

  deactivate() {
    // Only reset the list control listing id if it's the same that we previously selected
    if (this.listControl.viewingListingId === this.controller.model.listing.id) {
      this.listControl.set('viewingListingId', null);
    }

    const bookingReviewController = getOwner(this).lookup(
      'controller:dashboard.pricing.index.booking-review.index'
    );
    bookingReviewController.set('isVisible', false);
  }

  renderTemplate() {
    this.render('dashboard/pricing/index/booking-review/index', {
      into: 'dashboard/pricing/index',
      outlet: 'side-panel',
    });
  }
}
