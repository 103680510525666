import Service, { inject as service } from '@ember/service';
import * as zoid from 'zoid/dist/zoid.frameworks';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import { observes } from '@ember-decorators/object';
import ENV from 'appkit/config/environment';

export default class EmbedService extends Service {
  @service router;
  @service featureFlag;
  @service beyondI18n;
  @service staffView;

  @tracked route;
  @tracked isEmbedded = false;
  @tracked delegateRoutes = [];
  @tracked delegateSubRoutes = [];
  @tracked breakpoint = '';

  @tracked transitionName = null;
  @tracked transitionFromReact = false;

  get hideTopNav() {
    return (
      this.shouldDelegateSubRoute(this.router.currentRouteName) ||
      this.shouldDelegateSubRoute(this.transitionName)
    );
  }

  initialize() {
    if (ENV.environment !== 'test') {
      zoid.create({
        tag: 'ember-app',
        exports: ({ getExports }) => {
          return {
            logout: () => {
              return getExports().then(exports => {
                return exports.logout();
              });
            },
          };
        },
      });

      window.xprops?.export({
        logout: () => {
          this.currentUser.clear();
        },
      });
    }

    if (!window.xprops) {
      return;
    }

    // Start by clearing the session if we're embedded
    this.currentUser.clear();

    // Set top level domain as document domain
    // Needed for posthog to work with iframes
    const domainParts = document.domain.split('.');
    document.domain = domainParts.slice(-2).join('.');

    this.isEmbedded = true;

    if (window.xprops.auth) {
      this.login(window.xprops.auth);
    }

    if (window.xprops.locale) {
      this.beyondI18n.patchLocale(window.xprops.locale);
    }

    if (window.xprops.breakpoint) {
      this.breakpoint = window.xprops.breakpoint;
    }

    if (window.xprops.viewAsClient != null && this.currentUser.isImpersonating) {
      this.staffView.toggleStaffView(window.xprops.viewAsClient);
    }

    this.setupRouting();
  }

  setupRouting() {
    this.delegateRoutes = window.xprops.delegateRoutes || [];
    this.delegateSubRoutes = window.xprops.delegateSubRoutes || [];

    // initial navigation
    if (window.xprops.route) {
      setTimeout(() => {
        this.route = window.xprops.route;
        this.reactTransitionTo(this.route);
      }, 0);
    }

    // notify React of route changes
    this.router.on('routeDidChange', transition => {
      if (this.isParked(transition)) return;
      // If the transition was aborted we don't want to notify React
      // Ex: this happens when navigating to a route causes an error
      if (transition.isAborted) return;
      this.onRouteChanged(this.router.currentURL, transition.to.name);
    });

    // abort transitions if they are supposed to be delegated to React
    // does not abort if the transition was started from React
    this.router.on('routeWillChange', transition => {
      if (this.isParked(transition)) return;
      if (this.tryDelegate(transition)) {
        transition.abort();
      }
    });

    // Listen to route changes from React
    window.xprops.onProps(
      ({ locale, route, reload, breakpoint, viewAsClient, auth }) => {
        // make sure the session is up to date
        this.login(auth);

        const oldRoute = this.route;
        this.route = route;

        // Trigger a transition if route changes or a reload is forced
        if (route !== oldRoute || reload) {
          this.reactTransitionTo(route);
        }

        // Update the locale if it changed
        if (locale && locale !== this.beyondI18n.locale) {
          this.beyondI18n.updateLocale(locale);

          // reload
          getOwner(this).lookup('route:dashboard').refresh();
        }

        // Update the breakpoint if it changed
        if (breakpoint && this.breakpoint !== breakpoint) {
          this.breakpoint = breakpoint;
        }

        // Update staff view if it changed
        if (viewAsClient != null && this.staffView.viewAsClient !== viewAsClient) {
          this.staffView.toggleStaffView(window.xprops.viewAsClient);
        }
      }
    );
  }

  onRouteChanged(route, name) {
    if (this.route !== route) {
      window.xprops.onRouteChanged(route, name);
    }
  }

  onTransitionEnded(route) {
    window.xprops.onTransitionEnded?.(route);
  }

  invalidateQuery(queryKey) {
    window.xprops.onInvalidateQuery?.(queryKey);
  }

  invalidateListingQuery(listingId) {
    this.invalidateQuery(['listings', 'detail', String(listingId)]);
  }

  czCaptureEvent(eventName, description) {
    window.xprops?.onCzCaptureEvent?.(eventName, description);
  }

  // transition to a route triggered from React
  reactTransitionTo(route) {
    this.transitionFromReact = true;
    const routeName = this.router.recognize(route)?.name;
    this.transitionName = routeName;
    this.router
      .transitionTo(route)
      .followRedirects()
      .then(() => {
        this.transitionFromReact = false;
        this.transitionName = null;
        this.onTransitionEnded(this.router.currentRouteName);
      });
  }

  //TODO: analytics?
  login(auth) {
    // If it's already authenticated ignore
    if (auth.token === this.currentUser.token) {
      return;
    }

    // Store as cookie for Safari
    if (/apple/i.test(navigator.vendor)) {
      const host = document.location.host.split(':')[0];
      const topDomain =
        host.split('.').length > 2 ? host.split('.').slice(1).join('.') : host;
      document.cookie = `appsession=${JSON.stringify(
        auth
      )};domain=.${topDomain};path=/;max-age=31536000`;
    }

    // Make sure we clear the current user first
    this.currentUser.clear();

    this.currentUser.setProperties({
      username: auth.email,
      token: auth.token,
      isStaff: auth.isStaff,
      isSuper: auth.isSuper,
      isRelayEnabled: auth.isRelayEnabled,
      userId: auth.id,
      hasDisconnectedAccounts: auth.hasDisconnectedAccounts,
    });

    // Set up launch darkly after login
    this.featureFlag.initClient({
      email: auth.ldEmail,
      ldSecureHash: auth.ldSecureHash,
      userId: this.currentUser.userId,
    });

    // Store LaunchDarkly configs in local storage
    this.currentUser.setProperties({
      ldEmail: this.featureFlag.email,
      ldKey: this.featureFlag.ldKey,
      ldSecureHash: this.featureFlag.ldSecureHash,
    });
  }

  /**
   * Try to delegate a route do react
   * Returns true if the route was delegated false otherwise
   */
  tryDelegate(transition) {
    const { name } = transition.to;

    // Check if the transition is supposed to be delegated to React
    if (this.shouldDelegateRoute(name)) {
      // The transition was triggered from the React side so we don't need to do anything
      if (this.transitionFromReact) {
        return false;
      }

      const path = this.transitionToUrl(transition);
      this.onRouteChanged(path, transition.to.name);

      return true;
    }
    return false;
  }

  @action
  openDrawer() {
    window.xprops.onOpenDrawer();
  }

  // eslint-disable-next-line ember/no-observers
  @observes('staffView.viewAsClient')
  onViewAsClientChanged() {
    if (this.isEmbedded) {
      window.xprops.onViewAsClient?.(this.staffView.viewAsClient);
    }
  }

  /**
   * Utility functions
   */

  isParked(transition) {
    return transition.to.name === 'dashboard.parked';
  }

  shouldDelegateUrl(url) {
    const routeName = this.urlToRouteName(url);
    return this.shouldDelegateRoute(routeName);
  }

  shouldDelegateRoute(name) {
    return this.isEmbedded && !!this.delegateRoutes.find(r => r === name);
  }

  shouldDelegateSubRoute(name) {
    return this.isEmbedded && !!this.delegateSubRoutes.find(r => r === name);
  }

  // WARNING: this is using the private API of the router to get the route
  // name from the URL.
  urlToRouteName(url) {
    return this.router._router._routerMicrolib.recognize(url).name;
  }

  routeNameToUrl(name, params = {}, queryParams = {}) {
    return params && Object.keys(params).length
      ? this.router.urlFor(name, params, { queryParams })
      : this.router.urlFor(name, { queryParams });
  }

  transitionToUrl(transition) {
    const name = transition.to.name;
    // Get params for all parent routes so we can use them to generate the final url
    const params = transition.routeInfos.reduce((acc, cur) => {
      for (const key in cur.params) {
        acc[key] = cur.params[key];
      }
      return acc;
    }, {});

    return this.routeNameToUrl(name, params, transition.to.queryParams);
  }
}
