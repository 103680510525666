import AuthRoute from 'appkit/routes/auth';

export default class CredentialsRoute extends AuthRoute {
  titleToken = 'Team';

  afterModel(user) {
    if (!user.credential.get('isAdministrator') === true) {
      return this.replaceWith('dashboard.user.summary');
    }
  }
}
