import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class AirbnbListingsRoute extends AuthRoute {
  templateName = 'data.heatmap';

  model() {
    let cluster = this.modelFor('data.cluster');
    let url = `/admin/api/clusters/${cluster.get('id')}/airbnb/listings`;

    return this.ajax._get(url);
  }
}
