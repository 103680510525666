import getThemeForOptions, { getClassesForTheme } from './index';
import baseTheme from './base.theme';
import { VARIANTS, SIZES, COLORS } from './constants';

const Themeable = (
  superclass,
  {
    theme = baseTheme,
    variant = VARIANTS.CONTAINED,
    color = COLORS.BRAND,
    size = SIZES.MD,
  } = {}
) =>
  class extends superclass {
    get theme() {
      if (!theme) {
        console.error('No theme found for', this.constructor.name);
      }
      return getThemeForOptions(theme, this.themeOptions);
    }

    get themeOptions() {
      return {
        variant: this.variant,
        color: this.color,
        size: this.size,
      };
    }

    get classes() {
      return this.getClassesForTheme(this.theme.root);
    }

    getClassesForTheme(theme) {
      return getClassesForTheme(theme);
    }

    get color() {
      return this.args.color ?? color;
    }

    get variant() {
      return this.args.variant ?? variant;
    }

    get size() {
      return this.args.size ?? size;
    }
  };

export default Themeable;
