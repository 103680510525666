/* eslint-disable ember/no-observers */
import { modifier } from 'ember-modifier';
import { next } from '@ember/runloop';

export default modifier((element, [target, visibleClasses, hiddenClasses]) => {
  const visible = visibleClasses.split(' ');
  const hidden = hiddenClasses.split(' ');

  let observer;

  next(function () {
    const targetEl = document.querySelector(target);
    if (!targetEl) return;

    const observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].intersectionRatio === 0) {
          visible.forEach(v => targetEl.classList.remove(v));
          hidden.forEach(h => targetEl.classList.add(h));
        } else if (entries[0].intersectionRatio === 1) {
          visible.forEach(v => targetEl.classList.add(v));
          hidden.forEach(h => targetEl.classList.remove(h));
        }
      },
      {
        threshold: [0, 1],
      }
    );
    observer.observe(element);
  });

  return () => {
    observer?.unobserve(element);
  };
});
