import ENV from 'appkit/config/environment';

export default {
  error(_message) {
    if (['error', 'info'].includes(ENV.logLevel)) {
      console.error.apply(this, arguments);
    }
  },

  info(_message) {
    if (ENV.logLevel === 'info') {
      console.log.apply(this, arguments);
    }
  },
};
