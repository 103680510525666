import { SIZES } from './constants';

// Sizes (height)
// xxs - 16px
// xs - 24px
// sm - 32px
// md - 36px
// lg - 40px
// xl - 48px
export const heightClasses = () => ({
  [SIZES.XXS]: ['h-4'],
  [SIZES.XS]: ['h-6'],
  [SIZES.SM]: ['h-8'],
  [SIZES.MD]: ['h-9'],
  [SIZES.LG]: ['h-10'],
  [SIZES.XL]: ['h-12'],
});

export const fontSizeClasses = () => ({
  [SIZES.XXS]: ['leading-3', 'text-xs'],
  [SIZES.XS]: ['leading-4', 'text-xs'],
  [SIZES.SM]: ['leading-4', 'text-sm'],
  [SIZES.MD]: ['leading-5', 'text-sm'],
  [SIZES.LG]: ['leading-6', 'text-base'],
  [SIZES.XL]: ['leading-6', 'text-base'],
});

export const iconSizeClasses = () => ({
  [SIZES.XXS]: ['h-3', 'w-3'],
  [SIZES.XS]: ['h-4', 'w-4'],
  [SIZES.SM]: ['h-4', 'w-4'],
  [SIZES.MD]: ['h-5', 'w-5'],
  [SIZES.LG]: ['h-6', 'w-6'],
  [SIZES.XL]: ['h-6', 'w-6'],
});

export const spacingClasses = ({ border }) => ({
  [SIZES.XXS]: ['px-2', border ? 'py-1b' : 'py-1'],
  [SIZES.XS]: ['px-2', border ? 'py-1b' : 'py-1'],
  [SIZES.SM]: ['px-2', border ? 'py-2b' : 'py-2'],
  [SIZES.MD]: ['px-3', border ? 'py-2b' : 'py-2'],
  [SIZES.LG]: ['px-3', border ? 'py-2b' : 'py-2'],
  [SIZES.XL]: ['px-4', border ? 'py-3b' : 'py-3'],
});

export default {
  heightClasses,
  fontSizeClasses,
  spacingClasses,
  iconSizeClasses,
};
