import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import ENV from 'appkit/config/environment';
import { inject as service } from '@ember/service';

@classic
export default class LogoutRoute extends Route {
  @service userleap;
  @service kustomer;
  @service hog;

  beforeModel() {
    this.userleap.logout();
    this.kustomer.logout();
    this.currentUser.clear();
    this.hog.logout();
    localStorage.removeItem('appearance');
    localStorage.removeItem('mode');

    if (ENV.environment === 'test') {
      // This alternative behavior is required because if we run
      // tests with the --server it will change the url of the
      // window and tests won't reload.
      this.transitionTo('application');
    } else {
      // This completly resets the app state when logging off.
      window.location = '/';
    }
  }
}
