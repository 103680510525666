import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Bp::Input::Symbol\n  @symbol='{{currency-symbol @currency}}'\n  @append={{this.append}}\n  @onInput={{@onInput}}\n  @onChange={{@onChange}}\n  @hasError={{@hasError}}\n  @isDirty={{@isDirty}}\n  @toggleIsFocused={{@toggleIsFocused}}\n  @containerClass={{@containerClass}}\n  class='text-right'\n  ...attributes\n  {{input-match '^\\d*$'}}\n/>", {"contents":"<Bp::Input::Symbol\n  @symbol='{{currency-symbol @currency}}'\n  @append={{this.append}}\n  @onInput={{@onInput}}\n  @onChange={{@onChange}}\n  @hasError={{@hasError}}\n  @isDirty={{@isDirty}}\n  @toggleIsFocused={{@toggleIsFocused}}\n  @containerClass={{@containerClass}}\n  class='text-right'\n  ...attributes\n  {{input-match '^\\d*$'}}\n/>","moduleName":"appkit/components/bp/input/currency.hbs","parseOptions":{"srcName":"appkit/components/bp/input/currency.hbs"}});
import Component from '@glimmer/component';
import { CurrencyUtil } from 'appkit/lib/currency';

export default class BpInputCurrencyComponent extends Component {
  get append() {
    return !CurrencyUtil.getSymbol(this.args.currency).isPrefixed;
  }
}
