import { helper } from '@ember/component/helper';

export const translateOrForced = ([isForced, original]) => {
  if (isForced) {
    return isForced;
  }
  return original;
};

export default helper(translateOrForced);
