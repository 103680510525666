import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('app-message')
export default class AppMessage extends Component {
  // -- Parameters ---------------------------------------------------------------------
  // @type {String}
  message = null;
}
