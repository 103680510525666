import classic from 'ember-classic-decorator';
import EmberObject, { action } from '@ember/object';
import Controller from '@ember/controller';
import Util from 'appkit/lib/util';

@classic
export default class SummaryController extends Controller {
  posting = false;
  errors = null;

  init() {
    this.errors = EmberObject.create({});
    super.init(...arguments);
  }

  @action
  nextInputOrSubmit() {
    // TODO: work out where this event global actually comes from, and how
    // we're supposed to get access to it from the handler.
    return Util.nextInputOrSubmit.call(this, window.event);
  }
}
