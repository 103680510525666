import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';
import { ChannelUtil } from 'appkit/lib/channel';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  // relay visibility related

  availabilitySyncIsSupportedForSelectedChannels: computed(
    'selectedListings.length',
    function () {
      const relayAvailabilitySupportedChannels = ChannelUtil.getRelayAvailabilitySupported();
      return this.selectedListings.some(sl =>
        relayAvailabilitySupportedChannels.includes(sl.primaryChannelListing.channel)
      );
    }
  ),

  reservationSyncIsSupportedForSelectedChannels: computed(
    'selectedListings.length',
    function () {
      const relayReservationSupportedChannels = ChannelUtil.getRelayReservationSupported();
      return this.selectedListings.some(sl =>
        relayReservationSupportedChannels.includes(sl.primaryChannelListing.channel)
      );
    }
  ),
});
