import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='register-interest-form' {{did-insert this.initForm}}>\n</div>", {"contents":"<div class='register-interest-form' {{did-insert this.initForm}}>\n</div>","moduleName":"appkit/components/signal/register-interest-form.hbs","parseOptions":{"srcName":"appkit/components/signal/register-interest-form.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { getPMSList } from '../../lib/upsell/signal';

export default class RegisterInterestForm extends Component {
  constructor() {
    super(...arguments);
  }

  @action
  initForm() {
    if (localStorage.getItem('signalRegisterInterestSubmitted') !== this.args.user.id) {
      // eslint-disable-next-line
      hbspt.forms.create({
        region: 'na1',
        portalId: '4339927',
        formId: 'de5f6351-1035-4dff-bffc-35585f9e556b',
        sfdcCampaignId: '701f4000000Rj1yAAC',
        target: '.register-interest-form',
        onFormReady: form => {
          const emailInput = form.find('[name=email]')[0];
          emailInput.value = this.args.user.email;
          emailInput.dispatchEvent(new Event('change'));

          const select = form.find('[name=property_management_software]')[0];
          const PMS = getPMSList();
          let ev = new Event('change');
          for (let i = 0; i < this.args.accounts.length; i++) {
            if (PMS.indexOf(this.args.accounts[i].channel) > -1) {
              select.value = this.args.accounts[i].channelConfig.label;
              select.dispatchEvent(ev);
              break;
            }
          }
        },
        onFormSubmit: () => {
          this.args.onSubmit();
        },
      });
    }
  }
}
