import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { getOwner } from '@ember/application';
import Controller from '@ember/controller';
import { CurrencyUtil } from 'appkit/lib/currency';
import moment from 'moment';
import downloadFile from '../../../helpers/download-file';
import { tracked } from '@glimmer/tracking';

class DownloadTransactions {
  @tracked startDate;
  @tracked endDate;

  get isDownloadButtonEnabled() {
    return this.startDate != null && this.endDate != null;
  }
}

@classic
export default class StatementsController extends Controller {
  @service
  alert;

  @service
  featureFlag;

  @service
  intl;

  @alias('model.billing')
  billing;

  downloadTransactions = new DownloadTransactions();
  displayBillingPeriods = false;
  @tracked shouldProcessTax = false;

  init() {
    super.init(...arguments);

    this.featureFlag.evaluate('show-billing-periods-ui', false).then(value => {
      set(this, 'displayBillingPeriods', value);
    });
    this.featureFlag.evaluate('should-process-tax', false).then(enabled => {
      this.shouldProcessTax = enabled;
    });
  }

  @computed('model.billingNextRunAt')
  get nextBillingDate() {
    const billingNextRunAt = this.model.billingNextRunAt;
    if (!billingNextRunAt) {
      return 'N/A';
    }
    return moment(billingNextRunAt).format('MMM D, YYYY');
  }

  @computed('billing.trialStartDate', 'biling.isInTrial')
  get shouldPresentTrialMessaging() {
    if (this.model.isSkipTrialInformationEnabled) {
      return false;
    }
    return this.billing.isInTrial || !this.billing.trialStartDate;
  }

  @computed('billing.trialStartDate', 'biling.isInTrial')
  get trialMessageDescription() {
    if (this.billing.trialStartDate === null) {
      return this.intl.t('admin.enableListingToStartTrial');
    }
    return this.intl.t('admin.trialEndDate');
  }

  @computed('billing.{trialStartDate,trialEndDate}')
  get trialMessage() {
    if (this.billing.trialStartDate === null) {
      return '';
    }
    const trial_end_date = moment(this.billing.trialEndDate);
    return trial_end_date.format('MMM D, YYYY');
  }

  // All the available billing currencies to choose from
  @computed
  get sortedBillingCurrencies() {
    let out = [];
    for (let code of CurrencyUtil.getBillingCurrencies()) {
      out.push({
        code: code,
        label: code + ' - ' + CurrencyUtil.getLabel(code),
      });
    }
    return out;
  }

  @computed('billing.{runningBalance,taxBalance}')
  get subTotalBalance() {
    const { runningBalance, taxBalance } = this.billing;

    return this.shouldProcessTax ? runningBalance - taxBalance : runningBalance;
  }

  // The selected currency
  // It is initialized on the route where the controller is set up.
  selectedBillingCurrency = null;

  // Action when the selected billing currency changes
  // eslint-disable-next-line ember/no-observers
  @observes('selectedBillingCurrency')
  watchSelectedBillingCurrency() {
    let modelBillingCurrency = this.get('model.billingCurrency');
    let selectedBillingCurrency = this.selectedBillingCurrency;

    if (selectedBillingCurrency === modelBillingCurrency) {
      return;
    }

    // Make the request to update the currency
    this.ajax
      ._post('/api/user/update_billing_currency', {
        currency: selectedBillingCurrency,
      })
      .then(
        () => {
          // Billing currency is changed
          this.set('model.billingCurrency', selectedBillingCurrency);
          getOwner(this).lookup('route:dashboard.user').refresh();
        },
        error => {
          const alert = this.alert;
          // Billing currency is set back to the original billing currency
          this.set('selectedBillingCurrency', modelBillingCurrency);
          let errorMessage =
            error.message || 'The currency could not be saved. Please contact support.';
          alert.error(errorMessage, { timeout: 10000 });
        }
      );
  }

  @action
  downloadRangeSelected(dates) {
    this.downloadTransactions.startDate = dates.start;
    this.downloadTransactions.endDate = dates.end;
  }

  @action
  download(statement) {
    window.open(
      `/dashboard/user/invoices/${statement.id}`,
      'invoice',
      'left=200, top=200, width=1000, height=1000, location=no, status=no'
    );
  }

  @action
  downloadPayment(payment) {
    window.open(
      `/dashboard/user/payments/${payment.id}`,
      'invoice',
      'left=200, top=200, width=1000, height=1000, location=no, status=no'
    );
  }

  @action
  downloadStatements() {
    const { startDate, endDate } = this.downloadTransactions;
    const formatDate = date => date.format('YYYY-MM-DD');
    const queryParams = `from=${formatDate(startDate)}&to=${formatDate(endDate)}`;
    const filename = `transactions-${formatDate(startDate)}/${formatDate(endDate)}.csv`;

    downloadFile(
      `/api/billing/transactions/download?${queryParams}`,
      filename,
      this.ajax
    );
  }

  @action
  hidePopover(popover) {
    const hide = popover.actions.hide.bind(popover);
    hide();
  }
}
