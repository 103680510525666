import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    imported=this.imported\n    importedListing=this.importedListing\n    selectedListing=this.selectedListing\n    actions=(hash\n      onImportListing=this.onImportListing onListingSelected=this.onListingSelected\n    )\n  )\n}}", {"contents":"{{yield\n  (hash\n    imported=this.imported\n    importedListing=this.importedListing\n    selectedListing=this.selectedListing\n    actions=(hash\n      onImportListing=this.onImportListing onListingSelected=this.onListingSelected\n    )\n  )\n}}","moduleName":"appkit/components/app-import-listing-details-container.hbs","parseOptions":{"srcName":"appkit/components/app-import-listing-details-container.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Customize from 'appkit/bp-models/customize';
import { ValueRange } from 'appkit/lib/value_range';

export default class AppImportListingDetailsContainer extends Component {
  @service ajax;
  @service alert;
  @tracked importedListing;
  @tracked
  selectedListing = this.args.referralListing ?? null;
  @tracked imported;
  @service intl;

  @action
  onListingSelected(listing) {
    if (listing !== this.selectedListing) {
      this.imported = false;
    }
    this.selectedListing = listing;
    this.args.onSelectedListingChanged(listing, null);
  }

  @action
  async onImportListing(listing) {
    const url = `/api/listings/${listing.id}/customize`;
    const data = await this.ajax._get(url);
    // This is very hacky... hopefully we can solve this with Ember Data
    for (const field of Object.keys(data)) {
      if (!field.endsWith('Ranges')) {
        continue;
      }
      data[field] = data[field].map(row => ValueRange.create(row));
    }

    let intl = this.intl;

    const customize = Customize.create({ ...data, intl });
    customize.minStayRanges = customize.minStayRanges.map((r, i) =>
      ValueRange.create({
        ...r,
        minStayLocked: data.minStayRanges[i].minStayLocked,
      })
    );

    this.importedListing = {
      listing: listing,
      settings: customize,
    };

    this.importedListing.settings.basePrice = listing.basePrice;
    this.importedListing.settings.currency = listing.currency;
    this.imported = true;
    this.args.onSelectedListingChanged(listing, this.importedListing.settings);
  }
}
