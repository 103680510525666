import BaseModel from 'appkit/bp-models/base_model';
import moment from 'moment';
import { computed, set } from '@ember/object';
import { alias } from '@ember/object/computed';

export default BaseModel.extend({
  init() {
    this._super(arguments);
    this._EDITABLE = ['projectionName', 'dealStatus'];
  },

  id: null,
  _createdAt: null,
  _updatedAt: null,
  deletedAt: null,
  projectionName: null,
  projectionTemplate: null,
  projectionTemplateId: null,
  address: null,
  city: null,
  state: null,
  zipcode: null,
  country: null,
  beds: null,
  revenueEstimate: null,
  dealStatus: null,
  selected: false,
  displayNameFieldOutline: false,
  referenceListingId: null,
  referenceListing: null,
  clusterId: null,
  // company info
  companyNotes: null,
  companyWebsite: null,
  companyPhone: null,
  companyEmail: null,
  companyAddress: null,
  companyCity: null,
  companyState: null,
  companyZipcode: null,
  companyCountry: null,
  seasons: computed({
    get() {
      return [];
    },
  }),
  _pmFee: null,
  expenses: computed({
    get() {
      return [];
    },
  }),
  website: null,
  phone: null,
  email: null,

  pmFee: computed('_pmFee', {
    get() {
      if (!this._pmFee) {
        return this._pmFee;
      }
      if (this._pmFee.toString().split('.')[1]?.length > 2) {
        return parseFloat(this._pmFee).toFixed(2);
      }
      return this._pmFee;
    },
    set(key, value) {
      set(this, '_pmFee', value);
      return this.pmFee;
    },
  }),

  createdAt: computed('_createdAt', {
    get() {
      return moment(this._createdAt).format('MMM DD, YYYY');
    },
    set(key, value) {
      set(this, '_createdAt', value);
      return this.createdAt;
    },
  }),

  updatedAt: computed('_updatedAt', {
    get() {
      return moment(this._updatedAt).format('MMM DD, YYYY');
    },
    set(key, value) {
      set(this, '_updatedAt', value);
      return this.updatedAt;
    },
  }),

  status: alias('dealStatus'),
});
