import Service from '@ember/service';
// import logger from 'appkit/lib/logger';

const COLOR_ACCESSIBILITY_THEMES = {
  Default: 'default',
  Deuteranopia: 'deuteranopia',
  Protanopia: 'protanopia',
  Tritanopia: 'tritanopia',
};

export default class Accessibility extends Service {
  accessibilitySettingOptions = {
    colors: [
      COLOR_ACCESSIBILITY_THEMES.Default,
      COLOR_ACCESSIBILITY_THEMES.Deuteranopia,
      COLOR_ACCESSIBILITY_THEMES.Protanopia,
      COLOR_ACCESSIBILITY_THEMES.Tritanopia,
    ],
  };

  applyAccessibilitySettings(user) {
    // logger.info('BEGIN: applyAccessibilitySettings', user);
    if (!user.accessibilitySettings) {
      return;
    }
    this.applyColorAccessibilitySettings(user);
    // logger.info('END: applyAccessibilitySettings', user);
  }

  /**
   * Applies any color-accessibility options.
   * This is accomplished by applying a css class to the body tag of the page.
   */
  applyColorAccessibilitySettings(user) {
    const colorAccessibilityClass = `color-accessibility-${user.accessibilitySettings.colorScheme?.value}`;
    const isClassAlreadyApplied = document.body.classList.contains(
      colorAccessibilityClass
    );
    if (!isClassAlreadyApplied) {
      document.body.classList.add(colorAccessibilityClass);
    }
  }
}
