import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';
function chartSeries(name, data, visible, tooltip, axis) {
  let series = {
    name: name,
    data: data,
  };
  if (tooltip === '$') {
    series.tooltip = { valueDecimals: 2, valuePrefix: '$' };
  }
  if (tooltip === '%') {
    series.tooltip = { valueDecimals: 2, valueSuffix: '%' };
  }
  if (!visible) {
    series.visible = false;
  }
  if (axis) {
    series.yAxis = axis;
  }
  return series;
}

@classic
export default class PricingController extends Controller {
  compareId = null;
  loadingCompareData = false;
  compareData = [];

  chartOptions = {
    rangeSelector: {
      selected: 5,
    },

    yAxis: [
      {
        labels: {
          format: '${value}',
        },
        title: {
          text: 'Price (USD)',
        },
      },
      {
        title: {
          text: 'Number',
        },
      },
    ],
    legend: {
      padding: 8,
      enabled: true,
      itemDistance: 10,
    },
    navigator: { baseSeries: 0 },
    // Commenting this out to fix a crash in highcharts, don't know cause
    // plotOptions: {
    //   series: {
    //     showInNavigator: true
    //   }
    // }
  };

  // Have to stick with highcharts 4.2.7 to add a series. Right now it breaks
  // with setting the data on the navigator. I have lost the errors, but it
  // will be complaining about pointStart. I think it's this issue:
  // https://github.com/highcharts/highcharts/issues/5936
  @computed('model.{prices,counts}', 'compareData')
  get chartData() {
    let out = [
      chartSeries('Price 0 Bedroom', this.get('model.prices.price0Bed'), false, '$'),
      chartSeries('Price 1 Bedroom', this.get('model.prices.price1Bed'), true, '$'),
      chartSeries('Price 2 Bedroom', this.get('model.prices.price2Bed'), true, '$'),
      chartSeries('Price 3 Bedroom', this.get('model.prices.price3Bed'), true, '$'),
      chartSeries('Price 4 Bedroom', this.get('model.prices.price4Bed'), false, '$'),
      chartSeries('Price 5 Bedroom', this.get('model.prices.price5Bed'), false, '$'),
      chartSeries('Price 6 Bedroom', this.get('model.prices.price6Bed'), false, '$'),
      chartSeries('Price 7 Bedroom', this.get('model.prices.price7Bed'), false, '$'),
      chartSeries('Price 8 Bedroom', this.get('model.prices.price8Bed'), false, '$'),
      chartSeries('Price 9 Bedroom', this.get('model.prices.price9Bed'), false, '$'),
      chartSeries('Price 10 Bedroom', this.get('model.prices.price10Bed'), false, '$'),
      chartSeries('Price 11 Bedroom', this.get('model.prices.price11Bed'), false, '$'),
      chartSeries('0 Bedroom (num)', this.get('model.counts.count0Bed'), false, 1),
      chartSeries('1 Bedroom (num)', this.get('model.counts.count1Bed'), false, 1),
      chartSeries('2 Bedroom (num)', this.get('model.counts.count2Bed'), false, 1),
      chartSeries('3 Bedroom (num)', this.get('model.counts.count3Bed'), false, 1),
      chartSeries('4 Bedroom (num)', this.get('model.counts.count4Bed'), false, 1),
      chartSeries('5 Bedroom (num)', this.get('model.counts.count5Bed'), false, 1),
      chartSeries('6 Bedroom (num)', this.get('model.counts.count6Bed'), false, 1),
      chartSeries('7 Bedroom (num)', this.get('model.counts.count7Bed'), false, 1),
      chartSeries('8 Bedroom (num)', this.get('model.counts.count8Bed'), false, 1),
      chartSeries('9 Bedroom (num)', this.get('model.counts.count9Bed'), false, 1),
      chartSeries('10 Bedroom (num)', this.get('model.counts.count10Bed'), false, 1),
      chartSeries('11+ Bedroom (num)', this.get('model.counts.count11Bed'), false, 1),
    ];

    if (this.site === 'airbnb') {
      out.splice(
        0,
        0,
        chartSeries('Price Rooms', this.get('model.prices.priceRooms'), false)
      );
      out.splice(
        13,
        0,
        chartSeries('Rooms (num)', this.get('model.counts.countRooms'), false, 1)
      );
    }
    let compareData = this.compareData || [];
    if (compareData.length) {
      if (this.site === 'airbnb') {
        out.push(
          chartSeries(`Compare ${this.compareId} Rooms`, compareData[12], false)
        );
      }
      out.push(
        chartSeries(`Compare ${this.compareId} 0 Bed`, compareData[0], false, '$'),
        chartSeries(`Compare ${this.compareId} 1 Bed`, compareData[1], true, '$'),
        chartSeries(`Compare ${this.compareId} 2 Bed`, compareData[2], true, '$'),
        chartSeries(`Compare ${this.compareId} 3 Bed`, compareData[3], true, '$'),
        chartSeries(`Compare ${this.compareId} 4 Bed`, compareData[4], false, '$'),
        chartSeries(`Compare ${this.compareId} 5 Bed`, compareData[5], false, '$'),
        chartSeries(`Compare ${this.compareId} 6 Bed`, compareData[6], false, '$'),
        chartSeries(`Compare ${this.compareId} 7 Bed`, compareData[7], false, '$'),
        chartSeries(`Compare ${this.compareId} 8 Bed`, compareData[8], false, '$'),
        chartSeries(`Compare ${this.compareId} 9 Bed`, compareData[9], false, '$'),
        chartSeries(`Compare ${this.compareId} 10 Bed`, compareData[10], false, '$'),
        chartSeries(`Compare ${this.compareId} 11 Bed`, compareData[11], false, '$')
      );
    }

    return out;
  }

  _loadComparison(url) {
    let filterCols = (arr, col) =>
      arr.filter(row => row[col]).map(row => [row[0], row[col]]);

    this.set('loadingCompareData', true);
    this.ajax._get(url).then(data => {
      // Just compare pct for now...
      this.set('compareData', [
        filterCols(data.availability, 1),
        filterCols(data.availability, 2),
        filterCols(data.availability, 3),
        filterCols(data.availability, 4),
        filterCols(data.availability, 5),
        filterCols(data.availability, 6),
        filterCols(data.availability, 7),
        filterCols(data.availability, 8),
        filterCols(data.availability, 9),
        filterCols(data.availability, 10),
        filterCols(data.availability, 11),
        filterCols(data.availability, 12),
        filterCols(data.availability, 13),
      ]);
      this.set('loadingCompareData', false);
    });
  }

  @action
  compareMarket(id) {
    this.set('compareId', id);
    let url = `/admin/api/markets/${id}/${this.site}/pricing`;
    this._loadComparison(url);
  }

  @action
  compareCluster(id) {
    this.set('compareId', id);
    let url = `/admin/api/clusters/${id}/${this.site}/pricing`;
    this._loadComparison(url);
  }
}
