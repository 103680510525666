import { computed } from '@ember/object';
import BaseModel from 'appkit/bp-models/base_model';

export default BaseModel.extend({
  metricName: '',

  init() {
    this._super(arguments);
    this.set('notifications', this.notifications ?? []);
  },

  notification_count: computed('_notification_count', function () {
    return this.notifications.length;
  }),

  classSelected: computed('itemsVisible', function () {
    if (this.itemsVisible) {
      return 'row-selected';
    }
    return '';
  }),
});
