import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import $ from 'jquery';
import { scheduleOnce } from '@ember/runloop';
import Component from '@ember/component';

@classic
@classNames('app-sticky')
export default class AppSticky extends Component {
  didInsertElement() {
    super.didInsertElement(...arguments);

    scheduleOnce('afterRender', this, this.afterRenderHook);
  }

  afterRenderHook() {
    $(document).on('touchmove.sticky', this._onScroll.bind(this));
    $(window).on('scroll.sticky', this._onScroll.bind(this));
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);

    $(window).off('scroll.sticky');
    $(document).off('touchmove.sticky');
  }

  _onScroll() {
    let scrollTop = $(window).scrollTop();
    let visible = $(this.element).is(':visible');

    if (scrollTop >= 140 && !visible) {
      $(this.element).slideDown('fast');
      this.element.style.display = 'block';
    }

    if (scrollTop < 140 && visible) {
      this.element.style.display = 'none';
    }
  }
}
