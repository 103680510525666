import classic from 'ember-classic-decorator';
import { classNames, classNameBindings } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import $ from 'jquery';
import Component from '@ember/component';

@classic
@classNames('app-search-listings')
@classNameBindings('isFirstSearch')
export default class AppSearchListings extends Component {
  // -- Computed Properties ------------------------------------------------------------
  @computed('hasLastSearchParamsCache')
  get isFirstSearch() {
    if (!this.hasLastSearchParamsCache) {
      return 'is-first-search';
    }
    return '';
  }

  // -- Lifecycle Hooks ----------------------------------------------------------------
  didInsertElement() {
    this._clickedPageHandler = this._clickedPage.bind(this);
    this.element.addEventListener('click', this._clickedPageHandler);
  }

  willDestroy() {
    this.element.removeEventListener('click', this._clickedPageHandler);
  }

  @action
  onClickedSearch() {
    this.clickedSearch();
  }

  // -- Private Functions --------------------------------------------------------------
  _clickedPage(e) {
    if ($(e.target).parents().hasClass('date-picker')) {
      return;
    }
    if ($(e.target).parents().hasClass('date-picker-popup')) {
      return;
    }
    if ($(e.target).parents().hasClass('date-wrap')) {
      return;
    }
    this.set('showDatePicker', false);
  }

  // -- Overrides  ---------------------------------------------------------------------
}
