import { VARIANTS, COLORS, baseTheme } from '../theme';
const { fontSizeClasses, spacingClasses } = baseTheme;

export const defaultClasses = () => [
  'inline-flex',
  'items-center',
  'cursor-pointer',
  'focus:outline-none',
  'disabled:cursor-not-allowed',
  'disabled:opacity-50',
  'font-semibold',
  'justify-center',
];

export const variantClasses = () => ({
  [VARIANTS.CONTAINED]: {
    [COLORS.ALL]: 'text-white',
    [COLORS.BRAND]: ['bg-brand-500', 'hover:bg-brand-600'],
    [COLORS.GRAY]: ['bg-gray-500', 'hover:bg-gray-600'],
    [COLORS.SUCCESS]: ['bg-success-500', 'hover:bg-success-600'],
    [COLORS.ERROR]: ['bg-error-400'],
    [COLORS.BLACK]: ['bg-black'],
  },
  [VARIANTS.OUTLINED]: {
    [COLORS.ALL]: ['border', 'bg-white'],
    [COLORS.BRAND]: [
      'border-brand-400',
      'text-brand-500',
      'hover:bg-brand-500',
      'hover:text-white',
    ],
    [COLORS.GRAY]: ['border-gray-300', 'text-gray-500', 'hover:bg-gray-200'],
    [COLORS.SUCCESS]: [
      'border-success-500',
      'text-success-600',
      'hover:border-transparent',
      'hover:bg-success-500',
      'hover:text-white',
    ],
    [COLORS.ERROR]: [
      'border-error-400',
      'text-error-400',
      'hover:border-transparent',
      'hover:bg-error-400',
      'hover:text-white',
    ],
  },
  [VARIANTS.TEXT]: {
    [COLORS.WHITE]: ['text-white'],
    [COLORS.BRAND]: ['text-brand-500', 'hover:bg-brand-200'],
    [COLORS.GRAY]: ['text-gray-500', 'hover:bg-gray-100'],
    [COLORS.SUCCESS]: ['text-success-600', 'hover:bg-success-100'],
    [COLORS.ERROR]: ['text-error-400', 'hover:bg-error-100'],
  },
});

export default {
  root: {
    defaultClasses,
    fontSizeClasses,
    spacingClasses,
    variantClasses,
  },
};
