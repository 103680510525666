// Add startsWith and endsWith to String objects because they aren't
// a standard yet (though it is in ES6) and they don't exist on safari or IE yet.
import $ from 'jquery';
import Ajax from 'appkit/services/ajax';
import logger from 'appkit/lib/logger';

export const isAppDomain = () => window.location.host.startsWith('app.');

// Email Address Regular Expression That 99.99% Works. https://emailregex.com/
const VALID_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

if (typeof Math.clamp !== 'function') {
  Math.clamp = function (x, lower, upper) {
    return Math.min(upper, Math.max(lower, x));
  };
}

if (typeof String.prototype.startsWith !== 'function') {
  String.prototype.startsWith = function (str) {
    return this.slice(0, str.length) === str;
  };
}

if (typeof String.prototype.endsWith !== 'function') {
  String.prototype.endsWith = function (str) {
    return this.slice(-str.length) === str;
  };
}

if (typeof Array.prototype.includes !== 'function') {
  Array.prototype.includes = function (searchElement /*, fromIndex*/) {
    if (this === null) {
      throw new TypeError('Array.prototype.includes called on null or undefined');
    }

    let O = Object(this);
    let len = parseInt(O.length, 10) || 0;
    if (len === 0) {
      return false;
    }
    let n = parseInt(arguments[1], 10) || 0;
    let k;
    if (n >= 0) {
      k = n;
    } else {
      k = len + n;
      if (k < 0) {
        k = 0;
      }
    }
    let currentElement;
    while (k < len) {
      currentElement = O[k];
      if (
        searchElement === currentElement ||
        (searchElement !== searchElement && currentElement !== currentElement)
      ) {
        // NaN !== NaN
        return true;
      }
      k++;
    }
    return false;
  };
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const Util = {
  // No referrer redirects are hard.
  // http://stackoverflow.com/questions/5033300/stop-link-from-sending-referrer-to-destination/14274099
  noreferrerHref(url) {
    let meta = encodeURIComponent(
      `
      <!DOCTYPE html>
      <html>
        <head>
          <meta http-equiv="refresh" content="0;URL='${url}'" />
        </head>
      </html>
    `
        .split(/\s+/g)
        .join(' ')
    );

    return `data:text/html;charset=utf-8,${meta}`;
  },

  // Deep serializing and deserializing of json keys from snake_case to
  // camelCase and vise-versa. Beforehand, object properties that came from the
  // backend had to be snake_case because that's how they were received. This
  // allows us to forget about the boundary of where the params came from.
  //
  // Ember-data uses serializer objects to abstract this a bit. Since we have
  // control over both front and back end, it seems like the cleanest option.
  _camelizeKeys(value) {
    let out = {};
    for (let k of Object.keys(value)) {
      let newKey;
      if (k.match(/^\d+-/)) {
        // Digits with dashes (e.g. in dates) shouldn't be camelized
        newKey = k;
      } else if (k.match(/^-\d+/)) {
        // Negative numbers need to keep the leading dash
        newKey = k;
      } else {
        newKey = k.camelize();
      }
      out[newKey] = Util.camelizer(value[k]);
    }
    return out;
  },

  camelizer(data) {
    if (data === null) {
      return null;
    }

    let typeString = Object.prototype.toString.call(data);

    if (typeString === '[object Array]') {
      return data.map(Util.camelizer);
    }
    if (typeString === '[object Object]') {
      return Util._camelizeKeys(data);
    }
    return data;
  },

  _decamelizeKeys(value) {
    let out = {};
    for (let k of Object.keys(value)) {
      out[k.decamelize()] = Util.decamelizer(value[k]);
    }
    return out;
  },
  decamelizer(data) {
    if (data === null) {
      return null;
    }

    let typeString = Object.prototype.toString.call(data);

    if (typeString === '[object Array]') {
      return data.map(Util.decamelizer);
    }
    if (typeString === '[object Object]') {
      return Util._decamelizeKeys(data);
    }
    return data;
  },

  isEmailInvalid(email, verify = true, intl) {
    if (!(email && email.length > 0)) {
      const error = intl
        ? intl.t('validation.required', { entity: intl.t('glossary.email') })
        : 'Email must be present';
      return Promise.resolve(error);
    }

    if (!VALID_EMAIL.test(email)) {
      const error = intl
        ? intl.t('validation.invalid', { entity: intl.t('glossary.email') })
        : 'Invalid email address';
      return Promise.resolve(error);
    }

    if (!verify) {
      return Promise.resolve();
    }

    // Retain simple validation above as it happens immediately, then use MailGun as secondary
    return Ajax.create()
      ._post('/api/check_email', { email: email })
      .then(data => {
        if (data.status === 'ok') {
          return false;
        } else if (data.status === 'error') {
          return intl
            ? intl.t('validation.invalid', { entity: intl.t('glossary.email') })
            : 'Invalid email address';
        }
        return intl ? intl.t('validation.generic') : 'There was an error';
      })
      .catch(err => {
        logger.error(err);
        return Promise.resolve(false);
      });
  },

  getHashParams() {
    let params = {};
    if (window.location.hash.length === 0) {
      return {};
    }

    let query = window.location.hash.slice(1);
    for (let pair of query.split('&')) {
      let [key, value] = pair.split('=');
      key = decodeURIComponent(key);
      value = decodeURIComponent(value);
      params[key] = value;
    }
    return params;
  },

  // Action helper for forms - when the user presses enter, go to next element,
  // if on the last input, submit the form.
  nextInputOrSubmit(event) {
    if (event === undefined) {
      // On firefox we don't have an `event` global variable,
      // This solve redundant errors, but we need to catch the event also on firefox somehow
      return;
    }
    // TODO: this should check for `input type="button"` as well
    let nextInput = $(event.target).nextAll('input');
    let nextButton = $(event.target).nextAll('button');

    if (nextInput.length) {
      nextInput.focus();
    } else {
      nextButton.click();
    }
  },

  escapeRegExpPattern(string) {
    if (!string) {
      return '';
    }
    // Escape user input to use in RegExp so that parentheses work for example
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  },

  getMultiScripts(arr) {
    let _arr = $.map(arr, function (scr) {
      return $.getScript(scr);
    });

    _arr.push(
      $.Deferred(function (deferred) {
        $(deferred.resolve);
      })
    );

    return $.when.apply($, _arr);
  },

  isObjectEmpty(obj) {
    if (!obj) {
      return false;
    }
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  },

  /**
   * TODO: Use a dependency like crypto-js for SHA1 encoding.  However, SHA1 is no longer considered
   * cryptographically secure. Libraries may deprecate it in the future or projects may be targetted
   * for including libraries which make use of SHA1 dependencies.
   * @param {String} r a string which will be encoded to SHA1
   */
  SHA1Encode(r) {
    function o(r, o) {
      return (r << o) | (r >>> (32 - o));
    }
    function e(r) {
      var o,
        e = '';
      for (o = 7; o >= 0; o--) e += ((r >>> (4 * o)) & 15).toString(16);
      return e;
    }
    var t,
      a,
      h,
      n,
      C,
      c,
      f,
      d,
      A,
      u = new Array(80),
      g = 1732584193,
      i = 4023233417,
      s = 2562383102,
      S = 271733878,
      m = 3285377520,
      p = (r = (function (r) {
        r = r.replace(/\r\n/g, '\n');
        for (var o = '', e = 0; e < r.length; e++) {
          var t = r.charCodeAt(e);
          t < 128
            ? (o += String.fromCharCode(t))
            : t > 127 && t < 2048
            ? ((o += String.fromCharCode((t >> 6) | 192)),
              (o += String.fromCharCode((63 & t) | 128)))
            : ((o += String.fromCharCode((t >> 12) | 224)),
              (o += String.fromCharCode(((t >> 6) & 63) | 128)),
              (o += String.fromCharCode((63 & t) | 128)));
        }
        return o;
      })(r)).length,
      l = new Array();
    for (a = 0; a < p - 3; a += 4)
      (h =
        (r.charCodeAt(a) << 24) |
        (r.charCodeAt(a + 1) << 16) |
        (r.charCodeAt(a + 2) << 8) |
        r.charCodeAt(a + 3)),
        l.push(h);
    switch (p % 4) {
      case 0:
        a = 2147483648;
        break;
      case 1:
        a = (r.charCodeAt(p - 1) << 24) | 8388608;
        break;
      case 2:
        a = (r.charCodeAt(p - 2) << 24) | (r.charCodeAt(p - 1) << 16) | 32768;
        break;
      case 3:
        a =
          (r.charCodeAt(p - 3) << 24) |
          (r.charCodeAt(p - 2) << 16) |
          (r.charCodeAt(p - 1) << 8) |
          128;
    }
    for (l.push(a); l.length % 16 != 14; ) l.push(0);
    for (
      l.push(p >>> 29), l.push((p << 3) & 4294967295), t = 0;
      t < l.length;
      t += 16
    ) {
      for (a = 0; a < 16; a++) u[a] = l[t + a];
      for (a = 16; a <= 79; a++)
        u[a] = o(u[a - 3] ^ u[a - 8] ^ u[a - 14] ^ u[a - 16], 1);
      for (n = g, C = i, c = s, f = S, d = m, a = 0; a <= 19; a++)
        (A = (o(n, 5) + ((C & c) | (~C & f)) + d + u[a] + 1518500249) & 4294967295),
          (d = f),
          (f = c),
          (c = o(C, 30)),
          (C = n),
          (n = A);
      for (a = 20; a <= 39; a++)
        (A = (o(n, 5) + (C ^ c ^ f) + d + u[a] + 1859775393) & 4294967295),
          (d = f),
          (f = c),
          (c = o(C, 30)),
          (C = n),
          (n = A);
      for (a = 40; a <= 59; a++)
        (A =
          (o(n, 5) + ((C & c) | (C & f) | (c & f)) + d + u[a] + 2400959708) &
          4294967295),
          (d = f),
          (f = c),
          (c = o(C, 30)),
          (C = n),
          (n = A);
      for (a = 60; a <= 79; a++)
        (A = (o(n, 5) + (C ^ c ^ f) + d + u[a] + 3395469782) & 4294967295),
          (d = f),
          (f = c),
          (c = o(C, 30)),
          (C = n),
          (n = A);
      (g = (g + n) & 4294967295),
        (i = (i + C) & 4294967295),
        (s = (s + c) & 4294967295),
        (S = (S + f) & 4294967295),
        (m = (m + d) & 4294967295);
    }
    return (A = e(g) + e(i) + e(s) + e(S) + e(m)).toLowerCase();
  },
};

// TODO: drop this, make everything import.
window.Util = Util;
export default Util;
