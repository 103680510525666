import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { set, action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { getOwner } from '@ember/application';
// eslint-disable-next-line ember/no-mixins
import filtersMixin from 'appkit/mixins/dashboard-filter';
import $ from 'jquery';
import moment from 'moment';
import { displayErrors } from 'appkit/lib/display_errors';
import { STATUS_MAPPING } from 'appkit/bp-models/booking_review_listing';

@classic
@classNames('app-search')
export default class AppSearch extends Component.extend(filtersMixin) {
  @service alert;
  @service('list-control') listControl;
  @service('booking-review') bookingReview;
  model = null;

  @computed('model.listings.@each.statisticsUpdatedAt')
  get lastLoadedDate() {
    // remove null
    const statisticsUpdatedAt = this.model.listings.filter(l => l.statisticsUpdatedAt);

    // exit if all statisticsUpdatedAt are empty
    if (statisticsUpdatedAt.length === 0) {
      return null;
    }

    // convert dates to moment object
    const statisticsUpdatedAtMoment = statisticsUpdatedAt.map(l =>
      moment(l.statisticsUpdatedAt)
    );

    // get latest update with contextual display
    const lastLoadedDate = moment.max(statisticsUpdatedAtMoment).calendar();

    return lastLoadedDate;
  }

  @action
  clickedToggleFilter() {
    if ($('.side-nav.show').length) {
      $('.side-nav').removeClass('show');
    }
  }

  @action
  reload() {
    getOwner(this).lookup('route:dashboard').refresh();
  }

  @action
  refreshStats() {
    this.bookingReview.refreshStats();
  }

  @action
  submitBartReport(use_custom_values) {
    this.bookingReview.submitBartReport(use_custom_values);
  }

  @action
  toggleRunReviewState() {
    this.bookingReview.set(
      'displayBookingReviewSubHeader',
      !this.bookingReview.isRunningReview
    );
    this.bookingReview.toggleRunReviewState();
  }

  _getMostRecentBookingReview(bookingReviews) {
    const bookingReviewsSorted = bookingReviews
      .slice()
      .map(br => {
        const date = moment(br.createdAt).format('YYYY-MM-DD HH:mm:ss');
        br['createdAt'] = date;
        return br;
      })
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return bookingReviewsSorted[0];
  }

  @computed('model.bookingReviews.[]')
  get mostRecentBookingReview() {
    if (!this.model.bookingReviews?.length) {
      return null;
    }
    return this._getMostRecentBookingReview(this.model.bookingReviews);
  }

  @computed('mostRecentBookingReview.completedAt')
  get hasUncompletedBookingReview() {
    return this.mostRecentBookingReview?.completedAt === null;
  }

  @computed(
    'model.bookingReviewListings.@each.status',
    'hasUncompletedBookingReview',
    'mostRecentBookingReview.id'
  )
  get displayRunReviewButton() {
    const mostRecentBookingReviewId = this.mostRecentBookingReview?.id;
    const currentBookingReviewListings = (
      this.model.bookingReviewListings || []
    ).filter(brl => brl.bookingReviewId === mostRecentBookingReviewId);

    if (currentBookingReviewListings.length === 0) {
      return true;
    }

    const allReviewCompleted = currentBookingReviewListings.every(
      brl => brl.status !== STATUS_MAPPING.SUGGESTED && brl.status !== null
    );

    if (this.hasUncompletedBookingReview && allReviewCompleted) {
      return false;
    }

    return true;
  }

  @action
  async cancelBookingReview() {
    if (!this.hasUncompletedBookingReview) {
      return;
    }

    const cancellingBookingReviewId = this.mostRecentBookingReview.id;
    let url = `/api/booking_reviews/${cancellingBookingReviewId}/cancel`;

    try {
      await this.ajax._put(url, {});
    } catch (errors) {
      displayErrors({ errors: errors, alert: this.alert });
      return;
    }

    const mostRecentBookingReview = this._getMostRecentBookingReview(
      this.model.bookingReviews
    );

    set(mostRecentBookingReview, 'completedAt', new Date());
    this.model.bookingReviewListings
      ?.filter(brl => brl.bookingReviewId === mostRecentBookingReview.id)
      ?.forEach(brl => set(brl, 'status', STATUS_MAPPING.CANCELED));

    this.router.transitionTo('dashboard.pricing.index');
  }
}
