import baseButtonTheme from './base.theme';
import { SIZES, mergeTheme, baseTheme } from '../theme';
const { iconSizeClasses } = baseTheme;

const defaultClasses = mergeTheme(baseButtonTheme.root.defaultClasses, () => [
  'rounded',
]);

// Icons
const iconDefaultClasses = () => ['fill-current', 'stroke-current'];
const startIconSpacingClasses = () => ({
  [SIZES.ALL]: ['-ml-0.5', 'mr-1'],
});

const endIconSpacingClasses = () => ({
  [SIZES.ALL]: ['-mr-1', 'ml-1'],
});

export default {
  root: {
    ...baseButtonTheme.root,
    defaultClasses,
  },
  icon: {
    defaultClasses: iconDefaultClasses,
    iconSizeClasses,
  },
  startIcon: {
    defaultClasses: iconDefaultClasses,
    spacingClasses: startIconSpacingClasses,
    iconSizeClasses,
  },
  endIcon: {
    defaultClasses: iconDefaultClasses,
    spacingClasses: endIconSpacingClasses,
    iconSizeClasses,
  },
};
