import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class='relay-welcom-img-container flex flex-col mb-10 items-center relative relay-welcome-image-1-container'\n>\n  <p class='font-semibold text-r-blue text-center text-blue-900 mb-3'>\n    {{t 'relay.pricesCustomizationsAvailability'}}\n  </p>\n  {{#if this.isEscapia}}\n    <img class='h-auto' src='assets/relay-welcome-1.svg' alt='' role='presentation' />\n  {{else}}\n    <img class='h-auto' src='assets/relay-welcome-3.svg' alt='' role='presentation' />\n  {{/if}}\n\n  {{#if this.isEscapia}}\n    <p class='font-semibold text-r-blue text-center text-blue-900 mt-3'>\n      {{t 'glossary.reservations'}}\n    </p>\n  {{/if}}\n</div>", {"contents":"<div\n  class='relay-welcom-img-container flex flex-col mb-10 items-center relative relay-welcome-image-1-container'\n>\n  <p class='font-semibold text-r-blue text-center text-blue-900 mb-3'>\n    {{t 'relay.pricesCustomizationsAvailability'}}\n  </p>\n  {{#if this.isEscapia}}\n    <img class='h-auto' src='assets/relay-welcome-1.svg' alt='' role='presentation' />\n  {{else}}\n    <img class='h-auto' src='assets/relay-welcome-3.svg' alt='' role='presentation' />\n  {{/if}}\n\n  {{#if this.isEscapia}}\n    <p class='font-semibold text-r-blue text-center text-blue-900 mt-3'>\n      {{t 'glossary.reservations'}}\n    </p>\n  {{/if}}\n</div>","moduleName":"appkit/components/relay/register-interest/welcome-left-image.hbs","parseOptions":{"srcName":"appkit/components/relay/register-interest/welcome-left-image.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { getPMSList } from '../../../lib/show-relay-register-interest';
import { CHANNEL } from '../../../lib/channel_configuration';

export default class WelcomeLeftImageComponent extends Component {
  @tracked isEscapia;
  @tracked currentPMS = '';

  constructor() {
    super(...arguments);

    const PMS = getPMSList(this.args.supercontrolFFEnabled, this.args.trackFFEnabled);

    for (let i = 0; i < this.args.accounts.length; i++) {
      if (PMS.indexOf(this.args.accounts[i].channel) > -1) {
        if (this.args.accounts[i].channel === 'escapia') this.isEscapia = true;
        this.currentPMS = this.args.accounts[i].channelConfig.label;
        if (this.args.accounts[i].channel === CHANNEL.LIVEREZ_DIRECT)
          this.currentPMS = 'Liverez';
        break;
      }
    }
  }
}
