import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import AuthRoute from 'appkit/routes/auth';
import { action } from '@ember/object';

@classic
export default class BeyondAirbnbAvailabilityRoute extends AuthRoute {
  templateName = 'data.highcharts-stock';
  controllerName = 'data.beyond-airbnb-availability';

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('site', 'beyond-airbnb');
    let cluster = this.modelFor('data.cluster');
    controller.set('pageTitle', `Beyond Airbnb Availability -- ${cluster.get('name')}`);
  }

  async model() {
    // Request Beyond and Airbnb availability together on one graph;
    let cluster = this.modelFor('data.cluster');
    let airbnb_url = `/admin/api/clusters/${cluster.id}/airbnb_v2/availability`;
    let beyond_url = `/admin/api/clusters/${cluster.id}/beyond/availability`;
    let beyondResponse = await this.ajax._get(beyond_url);
    let airbnbResponse = await this.ajax._get(airbnb_url);

    let arrangeLongestByKey = longest => {
      let arrangeByKey = {};
      longest.data.forEach(dataSet => {
        let date = dataSet[0];
        arrangeByKey[date] = [...dataSet];
      });
      return arrangeByKey;
    };

    // Sort Beyond and Airbnb data by date and merge
    let mergeBeyondAndAirbnbData = (beyondResponse, airbnbResponse) => {
      let beyondLen = beyondResponse.availability.length;
      let airbnbLen = airbnbResponse.availability.length;
      let shortest =
        beyondLen >= airbnbLen
          ? { label: 'airbnb', data: airbnbResponse.availability }
          : { label: 'beyond', data: beyondResponse.availability };
      let longest =
        beyondLen <= airbnbLen
          ? { label: 'airbnb', data: airbnbResponse.availability }
          : { label: 'beyond', data: beyondResponse.availability };

      let longestToDict = arrangeLongestByKey(longest);

      let mergedAirbnbBeyond = { airbnb: [], beyond: [] };
      for (let i = 0; i < shortest.data.length; i++) {
        if (longestToDict[shortest.data[i][0]]) {
          mergedAirbnbBeyond[shortest.label].push(shortest.data[i]);
          mergedAirbnbBeyond[longest.label].push(longestToDict[shortest.data[i][0]]);
        }
      }
      return mergedAirbnbBeyond;
    };

    let beyondAirbnbData = mergeBeyondAndAirbnbData(beyondResponse, airbnbResponse);

    let filterCols = (arr, col) =>
      arr.filter(row => row[col]).map(row => [row[0], row[col]]);

    // Display Beyond availability data and Airbnb's availabilityPlusCalendarEndAndDetectBlocksPc
    return EmberObject.create({
      beyondAvailabilityNum: filterCols(beyondAirbnbData['beyond'], 2),
      beyondAvailabilityPct: filterCols(beyondAirbnbData['beyond'], 3),
      beyondAvailableEnabledPct: filterCols(beyondAirbnbData['beyond'], 4),
      beyondTotalEnabledNum: filterCols(beyondAirbnbData['beyond'], 11),
      beyondTotalListedNum: filterCols(beyondAirbnbData['beyond'], 12),
      airbnbAvailabilityPct: filterCols(beyondAirbnbData['airbnb'], 3),
      airbnbAvailabilityPlusCalendarEndAndDetectBlocksPct: beyondAirbnbData[
        'airbnb'
      ].map(row => [row[0], (100 * (row[2] + row[12] + row[14])) / row[15]]),
    });
  }

  @action
  compareCluster(id) {
    this.controller.send('compareCluster', id);
  }

  @action
  compareMarket(id) {
    this.controller.send('compareMarket', id);
  }
}
