import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

@classic
export default class AccountsController extends Controller {
  @service refresh;
  @service featureFlag;
  @service intl;

  reasonsToDisable = [
    {
      id: 'STOP_RENTING',
      label: this.intl.t('pricing.listing.reasonsToDisable.stopRenting'),
    },
    {
      id: 'PAUSED_LISTING',
      label: this.intl.t('pricing.listing.reasonsToDisable.pausedListing', {
        count: 10,
      }),
    },
    {
      id: 'COMPETITION',
      label: this.intl.t('pricing.listing.reasonsToDisable.competition'),
    },
    {
      id: 'COST_ROI',
      label: this.intl.t('pricing.listing.reasonsToDisable.costRoi'),
    },
    {
      id: 'OTHER',
      label: this.intl.t('pricing.listing.reasonsToDisable.other'),
    },
  ];

  async init() {
    super.init(...arguments);
  }

  @computed('model.canEditMarkupAccountIds')
  get canEditMarkups() {
    let canEditMarkupAccountIds = this.get('model.canEditMarkupAccountIds').length > 0;
    let isRelayGrandfathered = this.get('user.isGrandfatheredRelayUser');
    return canEditMarkupAccountIds && isRelayGrandfathered;
  }

  @action
  updateMarkup(accountId, markup) {
    this.get('model.accounts')
      .find(account => account.id === accountId)
      .set('markup', markup);
  }

  @action
  async refreshSingleAccount(accountId, accountName) {
    const totalListings = this.get('model.accounts').find(
      account => account.id === accountId
    ).channelListings.length;
    // to disable button while loading
    this.get('model.accounts')
      .find(account => account.id === accountId)
      .set('loading', true);

    const accountPromise = this.refresh.refreshAccount(
      accountId,
      accountName,
      totalListings
    );

    accountPromise.then(() => {
      this.get('model.accounts')
        .find(account => account.id === accountId)
        .set('loading', false);
    });
  }

  @action
  onAccountDeletion(account, reasons) {
    this.send('deleteAccount', account, reasons);
  }
}
