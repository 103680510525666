import Controller, { inject as controller } from '@ember/controller';
import { action, computed, set } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CalendarController extends Controller {
  @controller('listing')
  listingController;

  @service featureFlag;
  @service intl;

  @tracked calendarStartDate = moment().locale(this.intl.locale);
  @tracked showLoadPastMonthsButton = true;

  constructor() {
    super(...arguments);

    this.calendarStartDate = this.calendarStartDate.startOf('month');
  }

  @action
  monthyPricingChanged(monthlyEnabled) {
    this.model.listing.set('monthlyEnabled', monthlyEnabled);
  }

  @computed(
    'intl.locale',
    'listing',
    'calendar.[]',
    'listing.monthlySeasons.[]',
    'calendarStartDate'
  )
  get years() {
    const calendar = this.model.monthlyCalendar ?? [];

    let years = [];
    let addActive = true;
    for (const day of calendar) {
      if (
        day.get('date').locale(this.intl.locale).isBefore(this.calendarStartDate, 'day')
      ) {
        continue;
      }
      let year = moment(day.date).locale('en').format('YYYY');
      const month = moment(day.date).locale('en').format('MMM');

      if (years.length === 0 || !years.some(y => y.year === year)) {
        years = [
          ...years,
          {
            year: year,
            months: [{ month: month, active: addActive }],
          },
        ];
      } else {
        let currYear = years.find(y => y.year === year);
        if (!currYear.months.some(m => m.month === month)) {
          set(currYear, 'months', [
            ...currYear.months,
            { month: month, active: false },
          ]);
        }
      }
      addActive = false;
    }
    return years;
  }

  @action
  scrollToMonth(year, month) {
    const el = document.getElementById(`${year}-${month}`);
    document.getElementById('mainScrollElement').scrollTo({
      behavior: 'smooth',
      top: el.offsetTop - 200,
    });
  }

  @action
  viewPreviousMonths() {
    const days = this.model.monthlyCalendar ?? [];

    let nextStartDate = this.calendarStartDate.clone().subtract(12, 'months');
    if (nextStartDate.isBefore(days[0].get('date'), 'day')) {
      this.calendarStartDate = days[0].get('date');
      this.showLoadPastMonthsButton = false;
    } else {
      this.calendarStartDate = nextStartDate;
    }
  }

  @action
  scrollToNavigation() {
    setTimeout(() => {
      let monthEl = document.querySelector(
        `.button-${this.calendarStartDate
          .clone()
          .locale('en')
          .format('MMM')}-${this.calendarStartDate.clone().locale('en').format('YYYY')}`
      );
      if (monthEl) monthEl.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }, 200);
  }
}
