import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardOwnerResourceIndexRoute extends Route {
  @service ownerResource;

  setupController(controller) {
    super.setupController(...arguments);
    this.ownerResource.resetService();

    return controller;
  }
}
