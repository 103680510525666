import classic from 'ember-classic-decorator';
import AuthRoute from 'appkit/routes/auth';

@classic
export default class IndexRoute extends AuthRoute {
  controllerName = 'listing.nearby.inner';
  templateName = 'listing/nearby/inner';

  afterModel() {
    return this.replaceWith('dashboard.pricing.listing.nearby.airbnb');
  }
}
